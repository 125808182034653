import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { useEffect, useState } from 'react';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { POST, endpoints, estateEndpoints, getNextValidAction, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import {
    ADDENDUM_FOR_CHANGE_IN_OWNERSHIP,
    ADDENDUM_OF_CHANGE,
    ALLOTTEE,
    APPLY_INDENTURE,
    DEED_OF_EXTENSION,
    DEED_OF_SURRENDER,
    LEASE_DEED,
    TENANT_ID,
    TRIPARTITE_DEED
} from '../../../../utils/constants';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { DEED_TYPE, INDENTURE_APPLICATION_DETAILS, INDENTURE_DETAILS, PLOT_DETAILS_TABLE_LD } from './LeaseDeed.const';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { cross, check } from '../../../../utils/Logo';
import Loading from '../../../../components/portalComponents/Loading.component';
import { Card } from 'react-bootstrap';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { DEED_TYPE_OPTIONS } from '../../../../utils/formikValidation/FormikValidation';
import { getNextActions } from '../EstateManagement.controller';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { indentureApplicationhInitialValues } from './LeaseDeed.controller';
import { useTranslation } from 'react-i18next';

export default function LeaseDeedApplication() {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    let navigate = useNavigate();
    const [plotData, setPlotData] = useState([]);
    const [plotRowData, setPlotRowData] = useState<any>(null);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [selectedPlotData, setSelectedPlotData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dropdownData, setDropdownData] = useState<any>({});
    const [filteredPlotRowData, setFilteredPlotRowData] = useState<any>(null);

    const [searchParams] = useSearchParams();
    const location: any = useLocation();
    const type = searchParams.get('type');
    const plotIdSelect = location?.state?.plotId || null;
    const applicationNumber = searchParams.get('applicationNumber');
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [deedDetailsResponse, setDeedDetailsResponse] = useState<any>(null);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: DEED_TYPE_OPTIONS
    });

    const { values, setValues, setFieldValue } = formik;

    const onPlotSelect = (selectedPlot: any) => {
        console.log('selectedPlots', selectedPlot);
        setSelectedPlotDataId(selectedPlot.id);
        setSelectedPlotData(selectedPlot);
    };

    const plotDetailsTableColumns = PLOT_DETAILS_TABLE_LD.map((val: any, index: number) => {
        if (index === 0) {
            return { ...val, onRadioClick: onPlotSelect, selectedValue: selectedPlotDataId };
        }
        return val;
    });

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        if (!applicationNumber && plotRowData) {
            if (plotIdSelect) {
                const selectedPlotObject = plotRowData?.find((item: any) => item.id === plotIdSelect);
                onPlotSelect(selectedPlotObject);
            }

            if (type === LEASE_DEED && plotRowData) {
                setFieldValue('deedType', { code: 'LEASE_DEED', name: 'Lease Deed' });
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'ALLOTMENT' && item.userType === 'ALLOTTEE'));
            } else if (type === TRIPARTITE_DEED && plotRowData) {
                setFieldValue('deedType', { name: 'Tripartite Deed', code: 'TRIPARTITE_DEED' });
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'TRANSFER'));
            } else if (type === ADDENDUM_OF_CHANGE && plotRowData) {
                setFieldValue('deedType', { name: 'Addendum', code: 'TRANSFER_ADDENDUM' });
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'SPECIAL_TRANSFER'));
            } else if (type === DEED_OF_SURRENDER && plotRowData) {
                setFieldValue('deedType', { name: 'Deed of Surrender', code: 'DEED_OF_SURRENDER' });
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'SURRENDER'));
            } else if (type === DEED_OF_EXTENSION && plotRowData) {
                setFieldValue('deedType', { name: 'Deed of Extension', code: 'DEED_OF_EXTENSION' });
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'EXTENSION'));
            } else if (type === ADDENDUM_FOR_CHANGE_IN_OWNERSHIP && plotRowData) {
                setFieldValue('deedType', { name: 'Addendum of Change', code: 'ADDENDEM_OF_CHANGE' });
                console.log('plotRowData', plotRowData);
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE'));
            }
        }
    }, [type, plotRowData]);

    useEffect(() => {
        if (!applicationNumber) {
            fetchAllotteeDetails();
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onClickSubmit = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            deedType: values.deedType?.code,
            plotId: selectedPlotData?.id || null,
            additionalDetails: {
                allotmentOrderId: selectedPlotData?.allotmentId || null
            }
        };
        try {
            await DEED_TYPE_OPTIONS.validate(values, { abortEarly: false });
            if (selectedPlotData) {
                try {
                    await POST(endpoints.leaseDeedCreate, payload);
                    setModalDetails({ show: true, title: 'Success', para: `Application for ${values.deedType?.name} submitted successfully`, img: check, type: 'success', reUpload: 'OK' });
                } catch (err: any) {
                    let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: `Application for ${values.deedType?.name} Failed`, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            } else {
                setModalDetails({ show: true, title: 'Error!', para: 'Please select plots!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log(values);

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    setIsLoading(true);
                    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
                    const nextActions = await getNextActions(applicationNumber);
                    setHasValidAction(nextActions?.nextValidAction?.length > 0);
                    const { data: deedSearchRes } = await POST(endpoints.leaseDeedSearch + `?applicationNumbers=${applicationNumber}`, { RequestInfo: requestInfo() });
                    const deedDetails = deedSearchRes?.deedDetails[0];
                    setDeedDetailsResponse(deedDetails);
                    let { data: plotData } = await POST(estateEndpoints.plotSearch + `?plotUuids=${deedDetails?.plotId}`, { RequestInfo: requestInfo() });
                    const { data: allotmentData } = await POST(endpoints.allotmentDetailsSearch + `?entityIds=${entityId}&type=ALLOTTEE,TRANSFEREE,LESSEE&spvEnabled=false`, {
                        RequestInfo: requestInfo()
                    });
                    const deedtypeDropdownData = {
                        deedType: [
                            { code: 'LEASE_DEED', name: 'Lease Deed' },
                            { code: 'TRIPARTITE_DEED', name: 'Tripartite Deed' },
                            { code: 'ADDENDUM', name: 'Addendum' },
                            { code: 'DEED_OF_SURRENDER', name: 'Deed of Surrender' },
                            { code: 'DEED_OF_EXTENSION', name: 'Deed of Extension' },
                            { code: 'ADDENDEM_OF_CHANGE', name: 'Addendum of Change' }
                        ]
                    };
                    const deedType = getDropdownSelectedObject(deedDetails?.deedType, deedtypeDropdownData['deedType'] || []);
                    const filteredAllotmentDetails = allotmentData?.allotmentDetails?.filter(
                        (val: any) =>
                            val.allotmentType === 'ALLOTMENT' ||
                            val.allotmentType === 'TRANSFER' ||
                            val.allotmentType === 'SUB_LEASE' ||
                            val.allotmentType === 'SURRENDER' ||
                            val.allotmentType === 'EXTENSION'
                    );
                    const appliedAllotmentData = filteredAllotmentDetails?.filter((val: any) => val.entityRefDetails.plotId === plotData?.plots[0]?.uuid);
                    const appliedPlotData = [
                        {
                            plotArea: plotData?.plots[0]?.plotAreaSqm,
                            plotAllotmentDate: appliedAllotmentData[0]?.allotmentDate,
                            estateName: plotData?.plots[0]?.estateDetails.estateName,
                            phase: plotData?.plots[0]?.phase,
                            plotNo: plotData?.plots[0]?.plotNo,
                            id: plotData?.plots[0]?.uuid,
                            userType: appliedAllotmentData[0]?.entityRefDetails.type,
                            allotmentType: appliedAllotmentData[0]?.allotmentType
                        }
                    ];

                    const indetureValues = await indentureApplicationhInitialValues(deedSearchRes?.deedDetails[0]);

                    setFilteredPlotRowData(appliedPlotData);
                    setSelectedPlotDataId(plotData?.plots[0]?.uuid);
                    setValues({ ...values, ...indetureValues, deedType: deedType, isUpdatable: nextActions?.nextValidAction?.length > 0 });
                    setIsLoading(false);
                } catch (err: any) {
                    setIsLoading(false);
                    let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: `Application for ${values.deedType?.name} Failed`, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, []);

    const fetchAllotteeDetails = async () => {
        try {
            setIsLoading(true);
            const allotmentData = await POST(endpoints.validApplication + `?type=${APPLY_INDENTURE}`, { RequestInfo: requestInfo() });
            const userType = allotmentData?.data.allotmentDetails?.map((val: any) => val.allotmentType);
            const filteredUserType = userType?.filter(
                (val: any) =>
                    val === 'ALLOTMENT' ||
                    val === 'TRANSFER' ||
                    val === 'SUB_LEASE' ||
                    val === 'SURRENDER' ||
                    val === 'EXTENSION' ||
                    val === 'SPECIAL_TRANSFER' ||
                    val === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE'
            );
            const uniqueUserType = filteredUserType?.filter((val: any, index: number) => filteredUserType.indexOf(val) === index);
            const deedType: any = [];
            uniqueUserType?.map((val: any) => {
                if (val === 'ALLOTMENT') {
                    deedType.push({ name: 'Lease Deed', code: 'LEASE_DEED' });
                } else if (val === 'TRANSFER') {
                    deedType.push({ name: 'Tripartite Deed', code: 'TRIPARTITE_DEED' });
                } else if (val === 'SUB_LEASE') {
                    deedType.push({ name: 'Addendum', code: 'ADDENDUM' });
                } else if (val === 'SURRENDER') {
                    deedType.push({ name: 'Deed of Surrender', code: 'DEED_OF_SURRENDER' });
                } else if (val === 'EXTENSION') {
                    deedType.push({ name: 'Deed of Extension', code: 'DEED_OF_EXTENSION' });
                } else if (val === 'SPECIAL_TRANSFER') {
                    deedType.push({ name: 'Addendum', code: 'TRANSFER_ADDENDUM' });
                } else if (val === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE') {
                    deedType.push({ name: 'Addendum of Change', code: 'ADDENDEM_OF_CHANGE' });
                }
            });
            setDropdownData({ deedType: deedType?.flat() });
            if (uniqueUserType?.length === 1) {
                if (uniqueUserType[0] === 'ALLOTMENT' && allotmentData?.data.allotmentDetails?.some((obj: any) => obj.entityRefDetails?.type === 'ALLOTTEE')) {
                    setValues({ ...values, deedType: { name: 'Lease Deed', code: 'LEASE_DEED' } });
                } else if (uniqueUserType[0] === 'TRANSFER') {
                    setValues({ ...values, deedType: { name: 'Tripartite Deed', code: 'TRIPARTITE_DEED' } });
                } else if (uniqueUserType[0] === 'SUB_LEASE') {
                    setValues({ ...values, deedType: { name: 'Addendum', code: 'ADDENDUM' } });
                } else if (uniqueUserType[0] === 'SURRENDER') {
                    setValues({ ...values, deedType: { name: 'Deed of Surrender', code: 'DEED_OF_SURRENDER' } });
                } else if (uniqueUserType[0] === 'EXTENSION') {
                    setValues({ ...values, deedType: { name: 'Deed of Extension', code: 'DEED_OF_EXTENSION' } });
                } else if (uniqueUserType[0] === 'SPECIAL_TRANSFER') {
                    setValues({ ...values, deedType: { name: 'Addendum', code: 'TRANSFER_ADDENDUM' } });
                } else if (uniqueUserType[0] === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE') {
                    setValues({ ...values, deedType: { name: 'Addendum of Change', code: 'ADDENDEM_OF_CHANGE' } });
                }
            }
            if (allotmentData?.data?.allotmentDetails?.length > 0) {
                let plotIds = allotmentData?.data?.allotmentDetails?.map((item: any) => item.entityRefDetails.plotId);
                plotIds = plotIds.join(',');
                let plotData = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });
                let tempRowData: any = [];
                allotmentData?.data?.allotmentDetails.forEach((item: any) => {
                    let plotDataTable = plotData?.data?.plots.find((val: any) => val.uuid === item.entityRefDetails.plotId);
                    if (plotDataTable) {
                        let tempRow: any = {
                            plotArea: plotDataTable.plotAreaSqm,
                            plotAllotmentDate: item.allotmentDate,
                            estateName: plotDataTable.estateDetails.estateName,
                            phase: plotDataTable.phase,
                            plotNo: plotDataTable.plotNo,
                            id: plotDataTable.uuid,
                            userType: item.entityRefDetails.type,
                            allotmentType: item.allotmentType,
                            allotmentId: item.uuid
                        };
                        tempRowData.push(tempRow);
                    }
                });
                setPlotRowData(tempRowData);
                const filteredTempRowData = tempRowData?.filter(
                    (val: any) =>
                        val.allotmentType === 'ALLOTMENT' ||
                        val.allotmentType === 'TRANSFER' ||
                        val.allotmentType === 'SUB_LEASE' ||
                        val.allotmentType === 'SURRENDER' ||
                        val.allotmentType === 'EXTENSION' ||
                        val.allotmentType === 'SPECIAL_TRANSFER'
                );
                // deedType.length === 1 && setFilteredPlotRowData(filteredTempRowData?.filter((val: any) => val.userType !== 'LESSEE'));
                if (deedType.length === 1) {
                    if (deedType[0]?.code === 'LEASE_DEED') {
                        setFilteredPlotRowData(filteredTempRowData?.filter((val: any) => val.userType !== 'LESSEE'));
                    } else {
                        setFilteredPlotRowData(filteredTempRowData);
                    }
                }
            }
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const customHandleChange = (e: any, field: string) => {
        if (field === 'deedType') {
            if (e.value === 'LEASE_DEED') {
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'ALLOTMENT' && item.userType === 'ALLOTTEE'));
            } else if (e.value === 'TRIPARTITE_DEED') {
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'TRANSFER'));
            } else if (e.value === 'ADDENDUM') {
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'SUB_LEASE'));
            } else if (e.value === 'DEED_OF_SURRENDER') {
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'SURRENDER'));
            } else if (e.value === 'DEED_OF_EXTENSION') {
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'EXTENSION'));
            } else if (e.value === 'TRANSFER_ADDENDUM') {
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'SPECIAL_TRANSFER'));
            } else if (e.value === 'ADDENDEM_OF_CHANGE') {
                setFilteredPlotRowData(plotRowData?.filter((item: any) => item.allotmentType === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE'));
            } else if (e.label === 'Please Select') {
                setFilteredPlotRowData([]);
            } else {
                setFilteredPlotRowData(plotRowData);
            }
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/enterprisedashboard');
    };

    const onClose = () => {
        navigate(-1);
    };

    const onRegister = () => {
        navigate('/goa-idc/estate/register-indenture', { state: { deedSearchResponse: deedDetailsResponse } });
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={'Application for Indentures'} additionalClass='sub-text-style' />
                        <div className='col-12 form-section'>
                            <FormikProvider value={formik}>
                                <Card className='card_border'>
                                    <Card.Body>
                                        {DEED_TYPE.map((val: any) => (
                                            <GenericFormCard {...val} dropdownOptions={dropdownData} customHandleChange={customHandleChange} />
                                        ))}
                                        <div className='col-12 px-2 py-2'>
                                            <GenericActionTable columns={plotDetailsTableColumns} hideSearchBar={true} hideExportBtn={true} rowData={filteredPlotRowData} />
                                        </div>

                                        <div className='mt-4 '>{applicationNumber && INDENTURE_APPLICATION_DETAILS.map((val: any) => <GenericFormCard {...val} />)}</div>
                                    </Card.Body>
                                </Card>
                            </FormikProvider>
                        </div>
                    </div>
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                            {hasValidAction ? (
                                deedDetailsResponse?.applicationStatus === 'EXECUTED' ? (
                                    <GenericButtons text={t('REGISTER', 'Register')} variant='primary' handleClick={onRegister} />
                                ) : (
                                    <GenericButtons text={t('APPLY', 'Apply')} variant='primary' handleClick={onClickSubmit} />
                                )
                            ) : (
                                <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onClose} />
                            )}
                        </div>
                    </footer>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                </>
            )}
        </>
    );
}
