import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import RenderNotes from './RenderNotes.component';
import EmailNotification from './RenderEmailUpdation.component';
import { Card } from 'react-bootstrap';
import ProgressBar from '../../components/moduleCommonFormik/ProgressBar';
import PaymentSubmissionStatus from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import { POST, getProgressBarData, requestInfo } from '../../services/modulesServices/userManagement.api';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import { getFeeDetailsList } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.controller';
import GenericPayment from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/GenericPayment';
import GenericPaymentStatus from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/GenericPaymentStatus';
import { InitiateConstSteps } from './ConstructionLicensePage.const';
import PaymentDetails from '../modules/EstateManagement/Allotment/PaymentDetails';
import { paymentColumnsForTC } from '../modules/EstateManagement/Allotment/Allotment.const';

interface ConstructionLicenseSubPageProps {
    activeStepper: string;
    setEditorContent: Dispatch<SetStateAction<string>>;
    editorContent: string;
    applicationNumber: string;
    previewData: any;
    bpaData: any;
    sanctionFee: any;
    setFormValues: Dispatch<
        SetStateAction<
            {
                emailId: string;
            }[]
        >
    >;
    formValues: {
        emailId: string;
    }[];
    paymentRowData: any;
}

export default function ConstructionLicenseSubPage(props: ConstructionLicenseSubPageProps) {
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [auditTrial, setAuditTrial] = useState<any>(null);
    const [gatewayData, setGatewayData] = useState<any>([]);

    const handleContentChange = (content: string) => {
        props.setEditorContent(content);
    };

    const fetchGatewayData = async () => {
        try {
            const { data } = await POST(`/egov-mdms-service/v1/_search`, {
                RequestInfo: requestInfo(),
                MdmsCriteria: {
                    tenantId: 'ga',
                    moduleDetails: [
                        {
                            moduleName: 'egov-pg-service',
                            masterDetails: [
                                {
                                    name: 'Gateway',
                                    filter: '[?(@.active == true)]'
                                }
                            ]
                        }
                    ]
                }
            });
            let feeMasterDetails = data?.['MdmsRes']?.['egov-pg-service']?.['Gateway'];
            setGatewayData(feeMasterDetails || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchGatewayData();
        try {
            if (!!props?.applicationNumber) getProgressBarData(props?.applicationNumber).then(res => setProgressBarDetails(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [props?.applicationNumber]);

    const renderNotesDetails = () => {
        const notesStep = InitiateConstSteps.find(step => step.code === 'NOTES');
        if (notesStep && notesStep.isActive) {
            return (
                <>
                    <p className='font-inter-reg' style={{ fontWeight: 500, fontSize: '20px', lineHeight: '32px', letterSpacing: '-2%', color: '#45852E' }}>
                        Notes Details
                    </p>
                    <RenderNotes onContentChange={handleContentChange} editorContent={props.editorContent} isEditable={props?.bpaData?.editable} />
                </>
            );
        }
        return null; // or any default component to render if the step is not active
    };

    const renderNotificationDetails = () => {
        const notificationStep = InitiateConstSteps.find(step => step.code === 'NOTIFICATION_DETAILS');
        if (notificationStep && notificationStep.isActive) {
            return <EmailNotification formValues={props?.formValues} setFormValues={props?.setFormValues} isEditable={props?.bpaData?.editable} />;
        }
        return null; // or any default component to render if the step is not active
    };

    const getAuditData = () => {
        if (!props?.applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.getAuditTrail(payload, props?.applicationNumber, 'ga')
            .then(res => {
                setAuditTrial(res?.data?.ProcessInstances);
            })
            .catch(err => console.log(err));
    };

    const renderPayment = () => {
        const SANCTION_DETAILS = {
            txnId: props?.paymentRowData?.transactionNumber || '',
            auditDetails: { createdTime: props?.paymentRowData?.transactionDate || '' },
            txnStatus: 'SUCCESS',
            additionalDetails: { paymentReceiptDocRefId: props?.paymentRowData?.receiptId || '' }
        };

        // Function to format numbers with commas
        const formatNumberWithCommas = (number: any) => {
            return number.toLocaleString('en-IN');
        };

        // Format the BPAPaymentDetails array
        const formattedPaymentDetails = (props?.paymentRowData?.BPAPaymentDetails || []).map(
            (
                detail: {
                    principalAmount: any;
                    cgstPrincipal: any;
                    sgstPrincipal: any;
                    interestAmount: any;
                    penaltyAmount: any;
                    cgstPenalty: any;
                    sgstPenalty: any;
                    totalDueAmount: any;
                    paidAmount: any;
                },
                index: number
            ) => ({
                ...detail,
                index: index + 1,
                principalAmount: formatNumberWithCommas(detail.principalAmount),
                cgstPrincipal: formatNumberWithCommas(detail.cgstPrincipal),
                sgstPrincipal: formatNumberWithCommas(detail.sgstPrincipal),
                interestAmount: formatNumberWithCommas(detail.interestAmount),
                penaltyAmount: formatNumberWithCommas(detail.penaltyAmount),
                cgstPenalty: formatNumberWithCommas(detail.cgstPenalty),
                sgstPenalty: formatNumberWithCommas(detail.sgstPenalty),
                totalDueAmount: formatNumberWithCommas(detail.totalDueAmount),
                paidAmount: formatNumberWithCommas(detail.paidAmount)
            })
        );

        return (
            <>
                {/* <Card className='card_border my-2'>
                    {SANCTION_DETAILS.map((val: any) => (
                        <Card.Body>
                            <GenericPayment {...val} />
                        </Card.Body>
                    ))}
                </Card> */}

                <PaymentDetails
                    paymentData={formattedPaymentDetails}
                    // title=''
                    selectedGateway={''}
                    setSelectedGateway={() => {}}
                    isPaid={props?.paymentRowData?.isPaymentRecived}
                    columns={paymentColumnsForTC}
                    gatewayData={gatewayData}
                    transactionDetails={SANCTION_DETAILS}
                    totalLabel={'totalDueAmount'}
                    hideTitle={true}
                    setCalculatedTotal={() => {}}
                />
                {/* <GenericPaymentStatus status='Success' /> */}
                {/* <GenericPopupModal children={<PaymentSuccessPopUp />} isVisible={visible} primaryAction={() => { }} secondaryAction={handelVisible} primaryBtnTitle="Download Invoice" secondaryBtnTitle='Cancel' size="xl" /> */}
            </>
        );
    };

    useEffect(() => {
        getAuditData();
    }, []);

    const applicationData = {
        applicationNo: props?.applicationNumber,
        status: props?.bpaData?.status,
        data: auditTrial,
        isRoleMD: true,
        bpaData: props?.bpaData
    };
    return (
        <>
            {!!props.applicationNumber && (
                <Card className='card_border my-2'>
                    <Card.Body>
                        <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                    </Card.Body>
                </Card>
            )}
            {/* {props.activeStepper === 'NOTES' && renderNotesDetails()} */}
            {/* {props.activeStepper === 'NOTIFICATION_DETAILS' && renderNotificationDetails()} */}
            {props?.activeStepper === 'PAYMENT' && renderPayment()}
        </>
    );
}
