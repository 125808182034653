import { ErrorMessage, Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { formOptionsDTO } from './GenericFormCard.constants';
import { useTranslation } from 'react-i18next';
import CalendarIcon from '../../assets/logos/calander.svg';
// import { DatepickerField } from './DatePicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import ToolTipDetails from '../ToolTipFile';
import { convertDateFormatddMMyyyytoyyyyMMdd } from '../../features/modules/EstateManagement/LeaseDeed/LeaseDeed.controller';

interface selectFieldProps {
    titleCode?: any;
    field: string;
    title: string;
    type?: string;
    options?: formOptionsDTO[];
    isRequired?: boolean;
    index?: any;
    handleOnAssignmentChange?: (index: number, field: string, value: any) => void;
    additionalSection?: string;
    module?: string;
    isDisabled?: boolean;
    enableFutureDate?: boolean;
    condition1?: (val: any, index: number) => boolean;
    onChangeAction?: (date: any, field: string) => void;
    toolTipDetails?: any;
    showTimeSelect?: boolean;
    disablePastDate?: boolean;
    disableCondition?: (val: any, index: number) => boolean;
}

export default function GenericCommonDatePicker(props: selectFieldProps) {
    const { handleOnAssignmentChange, index, toolTipDetails, additionalSection, field, module, isDisabled, enableFutureDate, disablePastDate, condition1, showTimeSelect, disableCondition } = props;
    const { t } = useTranslation();
    const { values, setValues, errors, touched, handleChange, setFieldTouched } = useFormikContext<any>();

    const [isDisable, setIsDisable] = useState(false);
    const [isRequired, setIsRequired] = useState(false);

    useEffect(() => {
        if (module === 'usermanagement') {
            if (additionalSection && values?.assignments[index]?.isCurrentAssignment?.name === 'Yes' && props.field === 'toDate') {
                setIsDisable(true);
            } else setIsDisable(false);
        }

        if (condition1 && condition1(values, index)) {
            setIsDisable(false);
            setIsRequired(true);
        } else if (condition1 && !condition1(values, index)) {
            setIsDisable(true);
            setIsRequired(false);
        }

        if (disableCondition && disableCondition(values, index)) {
            setIsDisable(false);
        } else if (disableCondition && !disableCondition(values, index)) {
            setIsDisable(true);
        }
    }, [values, errors]);

    const handleOnDateChange = (e: any) => {
        if (additionalSection) {
            handleOnAssignmentChange && handleOnAssignmentChange(index, props.field, e);
        } else handleChange(e);
    };
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const validFutureDates = ['toDate', 'validTo'];

    useEffect(() => {
        if (additionalSection && values[additionalSection]?.[index]?.[field]) {
            values[additionalSection]?.[index]?.[field] && setSelectedDate(new Date(values[additionalSection]?.[index]?.[field]));
        } else {
            _.get(values, field)
                ? _.get(values, field).includes('/')
                    ? setSelectedDate(new Date(convertDateFormatddMMyyyytoyyyyMMdd(_.get(values, field))))
                    : setSelectedDate(new Date(_.get(values, field)))
                : setSelectedDate('');
        }
    }, [field, values]);

    return (
        <>
            {!additionalSection ? (
                <>
                    <div className='d-flex'>
                        <label htmlFor={props.field} className='pb-2' title={props.title}>
                            {props.titleCode ? t(props.titleCode) : props.title}
                        </label>
                        {(props.isRequired || isRequired) && <span className='mandatory-indicator'>*</span>}
                        {toolTipDetails && <ToolTipDetails toolTipDetails={toolTipDetails} />}
                    </div>
                    <DatePicker
                        dateFormat={showTimeSelect ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
                        showIcon
                        toggleCalendarOnIconClick
                        icon={
                            <img
                                className={`date-picker-icon ${_.get(touched, field) && _.get(errors, field) ? 'pe-4' : _.get(touched, field) && !_.get(errors, field) ? 'pe-4' : ' '}`}
                                style={{ right: _.get(touched, field) && props.isRequired ? '' : 10 }}
                                src={CalendarIcon}
                                alt=''
                            />
                        }
                        placeholderText={showTimeSelect ? 'dd/mm/yyyy hh:mm' : 'dd/mm/yyyy'}
                        className={`form-control was-validated date-picker ${_.get(touched, field) && _.get(errors, field) ? 'is-invalid' : ''}  ${
                            _.get(touched, field) && props.isRequired && !_.get(errors, field) && values.isUpdatable ? 'is-valid' : ''
                        }`}
                        maxDate={(!validFutureDates.includes(props.field) && !enableFutureDate && new Date()) || null}
                        minDate={disablePastDate ? new Date() : null}
                        selected={selectedDate}
                        onChange={(date: any) => {
                            setSelectedDate(date);
                            let formattedDate = date ? new Date(date)?.getFullYear() + '-' + String(date?.getMonth() + 1).padStart(2, '0') + '-' + String(date?.getDate()).padStart(2, '0') : null;

                            if (showTimeSelect) {
                                formattedDate = formattedDate
                                    ? formattedDate +
                                      ' ' +
                                      String(date?.getHours())?.padStart(2, '0') +
                                      ':' +
                                      String(date?.getMinutes())?.padStart(2, '0') +
                                      ':' +
                                      String(date?.getSeconds())?.padStart(2, '0')
                                    : '';
                            }
                            const newData = _.set(values, field, formattedDate);
                            setValues((prev: any) => ({ ...prev, ...newData }));
                            props.onChangeAction && props.onChangeAction(date, field);
                        }}
                        onBlur={() => {
                            setFieldTouched(`${field}`, true);
                        }}
                        disabled={isDisabled || !values.isUpdatable || isDisable}
                        showTimeSelect={showTimeSelect}
                    />
                    <ErrorMessage name={props.field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                </>
            ) : (
                <>
                    <label htmlFor={props.field} className='pb-2'>
                        {props.titleCode ? t(props.titleCode) : props.title}
                    </label>
                    {props.isRequired && <span className='mandatory-indicator'>*</span>}

                    <DatePicker
                        dateFormat='dd/MM/yyyy'
                        showIcon
                        toggleCalendarOnIconClick
                        icon={<img className='date-picker-icon' style={{ right: (touched as any)?.[additionalSection]?.[index]?.[field] ? 30 : 10 }} src={CalendarIcon} alt='' />}
                        placeholderText='dd/mm/yyyy'
                        className={`form-control was-validated date-picker ${
                            (touched as any)?.[additionalSection]?.[index]?.[field] && (errors as any)?.[additionalSection]?.[index]?.[field] ? 'is-invalid' : ''
                        }  ${
                            (touched as any)?.[additionalSection]?.[index]?.[field] && props.isRequired && !(errors as any)?.[additionalSection]?.[index]?.[field] && values.isUpdatable
                                ? 'is-valid'
                                : ''
                        }`}
                        maxDate={(!validFutureDates.includes(props.field) && !enableFutureDate && new Date()) || null}
                        disabled={isDisable || !values.isUpdatable || props.isDisabled}
                        onBlur={() => {
                            setFieldTouched(`${additionalSection}[${index}].${props.field}`, true);
                        }}
                        selected={selectedDate}
                        onChange={(date: any) => {
                            setSelectedDate(date);
                            date = new Date(date)
                                .toLocaleDateString('en-GB', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit'
                                })
                                .toString()
                                .split('/')
                                .reverse()
                                .join('-');
                            handleOnDateChange(date);
                            props.onChangeAction && props.onChangeAction(date, field);
                        }}
                    />
                    <ErrorMessage name={`${additionalSection}[${index}].${props.field}`}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                </>
            )}
        </>
    );
}
