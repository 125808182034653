import React, { useState, useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import HeaderComponent from '../../../components/header/Header.component';
import FooterComponent from '../../../components/portalComponents/Footer.component';
import { Link } from 'react-router-dom';
import { POST, endpoints, getFileUrl, requestInfo } from '../../../services/modulesServices/userManagement.api';
import ScrollButton from '../../../components/ScrollToTop';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import GenericExportDropdown from '../../../components/export/GenericExport';
import { selectUser } from '../../../redux/authSlice';
import Select from 'react-select';
import DashboardCards from './DashboardCards';
import './Plot.css';
import CustomCard from './CustomCard';
import arrowUp from '../../../assets/logos/ArrowUp2.png';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Loading from '../../../components/portalComponents/Loading.component';

export default function NewDashboardScreen() {
    const [newDashboardDetails, setNewDashboardDetails] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [colorState, setColorState] = useState([0, 0, 0, 0]);
    const [selectedFY, setSelectedFY] = useState('2024-25'); // default fiscal year

    const [localState, setLocalState] = useReducer(
        (prevState: any, newState: any) => {
            return { ...prevState, ...newState };
        },
        {
            totalApplications: '0',
            totalGrantedApplications: '0',
            onPercentageAvg: '0%',
            totalFeeIncured: '0cr',
            avgCompScore: '0%'
        }
    );

    const { totalApplications, totalGrantedApplications, onPercentageAvg, totalFeeIncured, avgCompScore } = localState;

    // const onClickDownloadItem = async () => {
    //     try {
    //         const { data } = await POST(endpoints.getCommonDocument + `?documentCategories=IDC_PORTAL&documentNames=Goa_IDC_Regulations_2023`, { RequestInfo: {} });
    //         const fileUrl = await getFileUrl(data.documentDetails[0].documentReferenceId);
    //         window.open(fileUrl, '_blank');
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    // function formatINR(feeIncured: any) {
    //     if (feeIncured >= 10000000) {
    //         return (feeIncured / 10000000).toFixed(2) + ' cr';
    //     } else if (feeIncured >= 100000) {
    //         return (feeIncured / 100000).toFixed(2) + ' L';
    //     } else if (feeIncured >= 1000) {
    //         return (feeIncured / 1000).toFixed(2) + ' k';
    //     } else {
    //         return feeIncured.toString();
    //     }
    // }

    function formatINR(feeIncured: any) {
        return (feeIncured / 10000000).toFixed(2) + ' cr';
    }

    // Function to convert fiscal year to date range and epoch format
    const convertFiscalYearToEpoch = (fiscalYear: any) => {
        const yearStart = parseInt(fiscalYear.split('-')[0]);
        const fromDate = new Date(yearStart, 3, 1).getTime(); // April 1st of the start year
        const toDate = new Date(yearStart + 1, 2, 31).getTime(); // March 31st of the next year
        return { fromDate, toDate };
    };

    useEffect(() => {
        (async () => {
            const { fromDate, toDate } = convertFiscalYearToEpoch(selectedFY);
            console.log('fiscal year to date range>>>', fromDate, toDate);
            const payload = {
                // RequestInfo: {
                //     apiId: 'Rainmaker',
                //     action: '',
                //     did: 1,
                //     key: '',
                //     msgId: '20170310130900|en_IN',
                //     requesterId: '',
                //     ver: '.01',
                //     authToken: '6bced33f-0757-4b97-a921-ce0309a8f28e',
                //     userInfo: {
                //         id: 10,
                //         userName: null,
                //         name: null,
                //         type: 'EMPLOYEE',
                //         mobileNumber: null,
                //         emailId: null,
                //         designation: 'MD',
                //         roles: [
                //             {
                //                 name: null,
                //                 code: 'EMP_CREATOR',
                //                 tenantId: 'ga'
                //             }
                //         ],
                //         uuid: '5b502ac1-5941-4d30-8a5e-251435efe591'
                //     }
                // },
                RequestInfo: requestInfo(),
                // RequestInfo: {},
                fromDate: fromDate,
                toDate: toDate
            };

            try {
                setIsLoading(true);
                console.log(payload);
                const res = await POST(endpoints.newDashboardDetails, payload);
                console.log('response>>', res);
                const totalApp = res?.data?.cardList?.reduce((acc: any, card: any) => acc + card.totalApplication, 0).toString();
                const totalGA = res?.data?.cardList?.reduce((acc: any, card: any) => acc + card.grantedApplication, 0).toString();
                // const compScoreAvg = (res?.data?.cardList?.reduce((acc: any, card: any) => acc + card.complianceScore, 0) / res.data.cardList.length).toFixed(2) + '%';
                const compScoreAvg = Math.round(res?.data?.cardList?.reduce((acc: any, card: any) => acc + card.complianceScore, 0) / res.data.cardList.length) + '%';

                // const totalPercentageAvg = (res?.data?.cardList?.reduce((acc: any, card: any) => acc + card.onTimePercentage, 0) / res.data.cardList.length).toFixed(2) + '%';
                const totalPercentageAvg = Math.round(res?.data?.cardList?.reduce((acc: any, card: any) => acc + card.onTimePercentage, 0) / res.data.cardList.length) + '%';

                const feeIncured = res?.data?.cardList?.reduce((acc: any, card: any) => acc + card.feeIncurred, 0);
                const totalFI = formatINR(feeIncured);
                setLocalState({
                    ...localState,
                    totalApplications: totalApp,
                    totalGrantedApplications: totalGA,
                    onPercentageAvg: totalPercentageAvg,
                    totalFeeIncured: totalFI,
                    avgCompScore: compScoreAvg
                });
                // setNewDashboardDetails(res?.data);
                setNewDashboardDetails(res?.data?.cardList || []);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
        })();
    }, [selectedFY]);

    console.log(newDashboardDetails, 'newDAshboardDetails>>>');
    // Format card names to capitalized case
    const formattedNewDash = newDashboardDetails.map((card: any) => ({
        ...card,
        cardName: _.startCase(card.cardName)
    }));

    const options = [
        // { value: '2022-23', label: 'FY 2022-23' },
        // { value: '2023-24', label: 'FY 2023-24' },
        { value: '2024-25', label: 'FY 2024-25' }
    ];

    const classes = {
        control: (state: any) => 'border border-1 py-3 px-4',
        option: (state: any) => (state.isFocused ? 'fw-bold p-2 fy-options rounded-3' : 'fw-bold p-2'),
        menu: (state: any) => 'dashboard-select-menu-style bg-white border mt-2 rounded-3 text-center',
        singleValue: (state: any) => 'fw-bold'
    };

    const handleFYChange = (selectedOption: any) => {
        setSelectedFY(selectedOption.value);
    };

    const xyz = [
        { id: 1, name: 'Total Applications', count: totalApplications, bgcolor: '#B7CF53' },
        { id: 1, name: 'Approved Applications', count: totalGrantedApplications, bgcolor: '#80B036' },
        { id: 1, name: 'Approved On Time', count: onPercentageAvg, bgcolor: '#45852E' },
        { id: 1, name: 'Fee Incured', count: totalFeeIncured, bgcolor: '#323E4A' }
    ];

    useEffect(() => {
        const getColourValues = (percentValue: any) => {
            // Ensure percentValue is within the range [60, 100]
            percentValue = Math.max(0, Math.min(100, percentValue)); // Initialize array with four segments
            // const arr = [0, 0, 0, 0]; // Calculate the values for each segment
            const effectivePercent = percentValue - 60;

            //total range to distribute across the segments
            const totalRange = 40; //from 60 to 100

            //each segment represents 10% increment
            const segmentValue = totalRange / 4; //10
            //initialize array for four segments
            const arr = [0, 0, 0, 0];

            //Distribute the effective percentage across segments
            for (let i = 0; i < 4; i++) {
                if (effectivePercent >= (i + 1) * segmentValue) {
                    arr[i] = 25; //full segment
                } else if (effectivePercent > i * segmentValue) {
                    arr[i] = ((effectivePercent - i * segmentValue) / segmentValue) * 25;
                }
            }
            return arr;
        };
        const colourArr = getColourValues(avgCompScore);
        setColorState(colourArr);

        console.log(colourArr);
    }, []);

    console.log('dashboard Details>>', newDashboardDetails);
    console.log(JSON.parse(JSON.stringify(newDashboardDetails)), 'Parse data>>');

    function getRating(compScore: any) {
        const score = parseFloat(compScore);
        if (score >= 91 && score <= 100) {
            return 'A+';
        } else if (score >= 81 && score <= 90) {
            return 'A';
        } else if (score >= 71 && score <= 80) {
            return 'B+';
        } else if (score >= 61 && score <= 70) {
            return 'B';
        } else {
            return 'C';
        }
    }

    const rating = getRating(avgCompScore);

    function renderGradeBackgroundColor(rating: any) {
        if (rating === 'A+' || rating === 'A') {
            return '#E4EEE0'; // Light green for A+ and A
        } else if (rating === 'B' || rating === 'B+') {
            return '#F4EED9'; // Light yellow for B and B+
        } else if (rating === 'C') {
            return '#FFE6D3'; // Light orange for C
        } else {
            return '#FFE6D3'; // Default to light orange if no match
        }
    }

    return (
        <>
            {!!isLoading && <Loading />}

            <HeaderComponent />
            <div className='px-custom font-inter' style={{ margin: '0px', backgroundColor: '#F5F5F5' }}>
                <div style={{ marginBottom: '45px', paddingTop: '2rem' }}>
                    <p style={{ fontWeight: 500, fontSize: '48px', lineHeight: '76.8px', letterSpacing: -2 }}>{'Realtime Compliance Dashboard'}</p>
                </div>
                <div className='row align-items-center'>
                    <div className='col-12 col-md-6 d-flex mb-3'>
                        <Select options={options} onChange={handleFYChange} unstyled={true} classNames={classes} defaultValue={{ value: '2024-25', label: 'FY 2024-25' }} />
                        {/* <Select options={options} unstyled={true} classNames={classes} defaultValue={{ value: 'option1', label: 'Option 1' }} className='ms-3' /> */}
                    </div>
                    {/* <div className='col-12 col-md-6 d-flex justify-content-md-end mb-3'>
                        <GenericExportDropdown ClassName='custom-rectangular-button' />
                    </div> */}
                </div>

                <div className='row my-3'>
                    <div className='col-12 d-flex justify-content-between pe-0'>
                        {xyz?.map((item: any, index: number) => {
                            console.log('item', item);
                            return <DashboardCards key={item.id} name={item.name} count={item.count} bgcolor={item.bgcolor} index={index} />;
                        })}
                    </div>
                </div>

                <div className='row my-3' style={{ height: '9.3rem', backgroundColor: '#ffffff', marginLeft: '0.2rem' }}>
                    <div className='col mb-3'>
                        <div className='content-box custom-height p-3'>
                            <p className='dashboard-card-style'>{'Overall Compliances Score'}</p>
                            <div className='d-flex align-items-center'>
                                <p className='num-per mb-0 me-2'>{avgCompScore}</p>
                                {/* <span className='py-1 px-2 rounded-pill footer cursor-pointer d-flex align-items-center' style={{ background: '#E4EEE0', fontSize: '1rem' }}>
                                    <img src={arrowUp} className='arrow_icon' alt='' />
                                    <span style={{ color: '#45852E' }}>{'2%'}</span>
                                </span> */}
                            </div>
                        </div>
                    </div>
                    <div className='col-6 mb-3'>
                        <div className='content-box' style={{ marginTop: '1rem' }}>
                            {/* Labels Wrapper */}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: '2rem', // Adjust as needed
                                    position: 'relative',
                                    zIndex: 10 // Ensure it's above other elements
                                }}
                            >
                                <span className='label-style' style={{ color: `${rating === 'C' ? '#323E4A' : '#9B9B9B'}` }}>
                                    C
                                </span>
                                <span className='label-style' style={{ paddingLeft: '0.5rem', color: `${rating === 'B' ? '#323E4A' : '#9B9B9B'}` }}>
                                    B
                                </span>
                                <span className='label-style' style={{ paddingLeft: '1rem,', color: `${rating === 'B+' ? '#323E4A' : '#9B9B9B'}` }}>
                                    B+
                                </span>
                                <span className='label-style' style={{ color: `${rating === 'A' ? '#323E4A' : '#9B9B9B'}` }}>
                                    A
                                </span>
                                <span className='label-style' style={{ color: `${rating === 'A+' ? '#323E4A' : '#9B9B9B'}` }}>
                                    A+
                                </span>
                            </div>

                            <div
                                style={{
                                    position: 'relative',
                                    height: '1rem', //extended height as per requirement
                                    backgroundColor: '#fffff', // Default background for light brown
                                    borderRadius: '0px',
                                    overflow: 'hidden' // To ensure bars stay within bounds
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        zIndex: 1
                                    }}
                                >
                                    <div className='static-background-style'></div>
                                    <div className='static-background-style'></div>
                                    <div className='static-background-style'></div>
                                    <div className='static-background-style' style={{ borderRight: '2px solid #E9E9E9' }}></div>
                                </div>
                            </div>

                            <div
                                style={{
                                    position: 'relative',
                                    height: '3rem',
                                    backgroundColor: '#f9f9f9',
                                    borderRadius: '0px',
                                    overflow: 'hidden' // To ensure bars stay within bounds
                                }}
                            >
                                {/* Lighter background color layer */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        zIndex: 1
                                    }}
                                >
                                    <div className='static-background' style={{ backgroundColor: '#FFD3D3' }}></div>
                                    <div className='static-background' style={{ backgroundColor: '#FEDFCF' }}></div>
                                    <div className='static-background' style={{ backgroundColor: '#F4EED9' }}></div>
                                    <div className='static-background' style={{ backgroundColor: '#E4EEE0', borderRight: '2px solid #E9E9E9' }}></div>
                                </div>

                                {/* Actual ProgressBar */}
                                <ProgressBar
                                    style={{
                                        position: 'relative',
                                        height: '100%',
                                        borderRadius: '0px',
                                        backgroundColor: 'transparent',
                                        zIndex: 2 // Ensure it's on top
                                    }}
                                >
                                    <ProgressBar style={{ background: 'rgba(251, 153, 153, 1)' }} now={colorState[0]} key={1} />
                                    <ProgressBar style={{ background: 'rgba(255, 188, 154, 1)' }} now={colorState[1]} key={2} />
                                    <ProgressBar style={{ background: 'rgba(249, 224, 138, 1)' }} now={colorState[2]} key={3} />
                                    <ProgressBar style={{ background: '#96DD7A' }} now={colorState[3]} key={4} />
                                </ProgressBar>
                            </div>
                        </div>
                    </div>
                    <div className='col d-flex justify-content-center align-items-center'>
                        <div className='content-box custom-height p-3' style={{ width: '250px', height: '100px', marginLeft: '75px', background: `${renderGradeBackgroundColor(rating)}` }}>
                            <div>
                                <p className='m-0 overall-rate'>{'Overall Rating'}</p>
                                <p className='m-0 overall-rate-grade' style={{ marginTop: '0px', marginBottom: '0rem' }}>
                                    {rating}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: '48px', marginBottom: '18px' }}>
                    <p className='app-style' style={{ paddingLeft: '5px' }}>
                        {'Applications'}
                    </p>
                    <div className='row my-2'>
                        {formattedNewDash?.map((item: any, idx: any) => (
                            <div className={`col-12 col-sm-6 col-md-6 col-lg-6 mb-4 ${idx % 2 === 0 ? 'ps-0' : ''}`} key={idx} style={{ width: '50%' }}>
                                <CustomCard
                                    title={item.cardName}
                                    application={item.totalApplication}
                                    approved={item.grantedApplication}
                                    percentage={item.onTimePercentage}
                                    feeInc={formatINR(item.feeIncurred)}
                                    compScore={item.complianceScore}
                                    footerColor='#E6E8E9'
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <FooterComponent />
            <ScrollButton type='public' />
        </>
    );
}

const svgToDataUrl = (color: any) => `
  data:image/svg+xml;base64,${btoa(`
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25 8V21C25 21.2652 24.8947 21.5196 24.7071 21.7071C24.5196 21.8946 24.2652 22 24 22C23.7348 22 23.4804 21.8946 23.2929 21.7071C23.1054 21.5196 23 21.2652 23 21V10.4137L8.70751 24.7075C8.51987 24.8951 8.26537 25.0006 8.00001 25.0006C7.73464 25.0006 7.48015 24.8951 7.29251 24.7075C7.10487 24.5199 6.99945 24.2654 6.99945 24C6.99945 23.7346 7.10487 23.4801 7.29251 23.2925L21.5863 9H11C10.7348 9 10.4804 8.89464 10.2929 8.70711C10.1054 8.51957 10 8.26522 10 8C10 7.73478 10.1054 7.48043 10.2929 7.29289C10.4804 7.10536 10.7348 7 11 7H24C24.2652 7 24.5196 7.10536 24.7071 7.29289C24.8947 7.48043 25 7.73478 25 8Z'
        fill='${color}'
      />
    </svg>
  `)}
`;

// const newDash = [
//     {
//         cardName: 'allotment',
//         totalApplication: 100,
//         grantedApplication: 29,
//         onTimePercentage: 100,
//         feeIncurred: 139888721,
//         complianceScore: 26
//     },
//     {
//         cardName: 'transfer',
//         totalApplication: 48,
//         grantedApplication: 7,
//         onTimePercentage: 33,
//         feeIncurred: 400000,
//         complianceScore: 12
//     },
//     {
//         cardName: 'special transfer',
//         totalApplication: 100,
//         grantedApplication: 6,
//         onTimePercentage: 100,
//         feeIncurred: 0,
//         complianceScore: 6
//     },
//     {
//         cardName: 'sub lease',
//         totalApplication: 82,
//         grantedApplication: 10,
//         onTimePercentage: 100,
//         feeIncurred: 0,
//         complianceScore: 12
//     },
//     {
//         cardName: 'mortgage',
//         totalApplication: 2,
//         grantedApplication: 1,
//         onTimePercentage: 100,
//         feeIncurred: 10000,
//         complianceScore: 50
//     },
//     {
//         cardName: 'extension',
//         totalApplication: 31,
//         grantedApplication: 2,
//         onTimePercentage: 100,
//         feeIncurred: 90000,
//         complianceScore: 6
//     },
//     {
//         cardName: 'occupancy certificate',
//         totalApplication: 2,
//         grantedApplication: 1,
//         onTimePercentage: 100,
//         feeIncurred: 0,
//         complianceScore: 50
//     },
//     {
//         cardName: 'construction license',
//         totalApplication: 10,
//         grantedApplication: 10,
//         onTimePercentage: 0,
//         feeIncurred: 800000,
//         complianceScore: 100
//     },
//     {
//         cardName: 'technical clearance',
//         totalApplication: 23,
//         grantedApplication: 14,
//         onTimePercentage: 100,
//         feeIncurred: 38293850,
//         complianceScore: 60
//     },
//     {
//         cardName: 'change of name trade ownership',
//         totalApplication: 100,
//         grantedApplication: 20,
//         onTimePercentage: 75,
//         feeIncurred: 0,
//         complianceScore: 20
//     }
// ];

// Format card names to capitalized case
// const formattedNewDash = newDash.map(card => ({
//     ...card,
//     cardName: _.startCase(card.cardName)
// }));
