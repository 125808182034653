import React, { useEffect } from 'react';
import './LoginPage.css';
import { logo } from '../../utils/Logo';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sigin_image1 } from '../../utils/Images';
import { useState } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { NavLink } from 'react-router-dom';
import LoginSubPage from './LoginSubPage.wrapper';
import { getStringResource } from '../../i18n/config';
// import { createLogin, getOtp, updatePassword } from "../../services/modulesServices/login.api";
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/authSlice';
import PasswordChangeModal from './PasswordChangeModal';
import { getNavigateTo, isAuthenticated, navigateTo } from '../../utils/utils';
import { POST, createLogin, getMenuData, getOtp } from '../../services/modulesServices/userManagement.api';
import warning from '../../assets/logos/Warning2.png';
import Loading from '../../components/portalComponents/Loading.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import { useTranslation } from 'react-i18next';
import cross from '../../assets/logos/close-icon.png';
import axios from 'axios';
import PopUps from '../../components/PopUps';

export default function LoginPage() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();

    const [loginType, setLoginType] = useState(2);
    const [userLoginData, setUserLoginData] = useState({ tenantId: 'ga' });
    const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showIsAuthorizedModal, setShowIsAuthorizedModal] = useState(false);
    const [userType, setUserType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [modalDetails, setModalDetails] = useState({});

    const dispatch = useDispatch();
    const history = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isAuthenticated()) {
            navigate('/goa-idc/');
        }
        loadCaptchaEnginge(6);
    }, []);

    const handleOnInputChange = e => {
        setUserLoginData({ ...userLoginData, [e.target.name]: e.target.value });
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    // Function to handle login type change
    const handleLoginTypeChange = e => {
        setLoginType(+e.target.value);
    };

    const getPath = localStorage.getItem('routeTo');

    const redirectTo = (authenticatedUserMenu, userType) => {
        const requestPath = authenticatedUserMenu.data?.validMenu?.[getPath];
        const routeTo = getNavigateTo(userType);

        if (getPath && !requestPath) {
            setShowIsAuthorizedModal(true);
        } else navigate(routeTo);
    };
    const doSubmit = async () => {
        let user_captcha = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha) === true) {
            // if (user_captcha === 'QWERTY') {
            setIsLoading(true);
            loadCaptchaEnginge(6);
            document.getElementById('user_captcha_input').value = '';

            try {
                setIsLoading(true);
                let res;
                const userType = loginType === 1 ? 'EMPLOYEE' : loginType === 2 ? 'ENTERPRISE' : 'EXTERNAL_EMPLOYEE';

                if (loginType === 1 || loginType === 3) {
                    res = await createLogin(userLoginData, userType);
                } else {
                    const requestBody = { ...userLoginData, userType: 'ENTERPRISE' };
                    res = await POST('/user/enterprise/_login', requestBody);
                }
                setUserType(res.data.UserRequest.type);
                localStorage.setItem('token', JSON.stringify(res.data.access_token));
                localStorage.setItem('type', JSON.stringify(res.data.UserRequest.type));
                localStorage.setItem('user', JSON.stringify(res.data.UserRequest));
                dispatch(setUser(res.data));
                setIsLoading(false);
                const authenticatedUserMenu = await getMenuData();
                redirectTo(authenticatedUserMenu, res.data.UserRequest.type);
            } catch (err) {
                setIsLoading(false);
                console.log('error', err);
                const errMsg = err.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                if (err.response?.data.errorCode === 'ERR_PASSWORD_EXPIRED') {
                    setShowPasswordChangeModal(true);
                } else {
                    setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
            setIsLoading(false);
        } else {
            let errMsg = t('CAPTCHA_NOT_MATCH', 'Captcha Does Not Match');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            document.getElementById('user_captcha_input').value = '';
        }
    };

    const onForgotPasswordClick = userType => {
        navigate('/goa-idc/resetpassword', { state: { userType } });
    };

    return (
        <>
            {!!isLoading && <Loading />}
            <PasswordChangeModal
                titleCode='CHANGE_PASSWORD'
                title={t('CHANGE_PASSWORD', 'Change Password')}
                show={showPasswordChangeModal}
                handleClose={() => setShowPasswordChangeModal(false)}
                data={userLoginData}
                setShowPasswordChangeModal={setShowPasswordChangeModal}
            />

            <StatusAlertPopup
                show={showIsAuthorizedModal}
                handleSubmit={() => navigate(getNavigateTo(userType))}
                handleClose={() => setShowIsAuthorizedModal(false)}
                title={t('UNAUTHORIZED_ACTION', 'Unauthorized Action')}
                para={t('AUTHORIZATION_FAILED', 'Your account lack the necessary authorization to access this particular information.')}
                reUpload='Go to Dashboard'
                img={warning}
            />
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />

            <div className='loginPage bg-light'>
                <div className='row column-gap-5 gx-0    '>
                    <div className='col-lg-4 login-container'>
                        <div className=''>
                            <div className='row full-height '>
                                <div className='col-md-5 pe-0 bg-white w-100 '>
                                    <div className='form-left h-100 py-5 px-3'>
                                        <div className='my-form bg-white font-inter-reg mx-2'>
                                            <div className='mb-5 d-flex align-items-center'>
                                                <div>
                                                    <Link to='/goa-idc/' className='col-1 px-4 py-3 back_arrow  me-3 '></Link>
                                                </div>
                                                <p className='fw-bolder text-black d-inline fs-1 m-0'>{t('LOG_IN', 'Log In')}</p>
                                            </div>
                                            <div class='text-center'>
                                                <div className='mb-3 mt-4 d-flex  align-items-center row'>
                                                    <div
                                                        className='d-inline-flex align-items-center col-sm-auto justify-content-center px-4'
                                                        data-toggle='tooltip'
                                                        data-placement='top'
                                                        title='“Enterprise” means an Industrial Undertaking or a Commercial Undertaking'
                                                    >
                                                        <input type='radio' id='enterpriseLogin' name='loginType' value={2} checked={loginType === 2} onChange={handleLoginTypeChange} />
                                                        <label htmlFor='enterpriseLogin' id='enterpriseLogin' className='custom-radio-label fw-bolder font-12 text-black me-0 cursor-pointer'>
                                                            {t('ENTERPRISE', 'Enterprise')}
                                                        </label>
                                                    </div>
                                                    <div
                                                        className='d-inline-flex align-items-center col-sm-auto border-top-0 border border-bottom-0 border-1 border-secondary justify-content-center px-4'
                                                        data-toggle='tooltip'
                                                        data-placement='top'
                                                        title='Goa-IDC Employee'
                                                    >
                                                        <input type='radio' id='employeeLogin' name='loginType' value={1} checked={loginType === 1} onChange={handleLoginTypeChange} />
                                                        <label htmlFor='employeeLogin' id='employeeLogin' className='custom-radio-label fw-bolder font-12 text-black me-0 cursor-pointer'>
                                                            {t('EMPLOYEE', 'Employee')}
                                                        </label>
                                                    </div>

                                                    <div
                                                        className='d-inline-flex align-items-center col-sm-auto justify-content-center px-4'
                                                        data-toggle='tooltip'
                                                        data-placement='top'
                                                        title={t('ARCHITECT_AFFILIATED', 'Architect / Engineer affiliated to Goa-IDC')}
                                                    >
                                                        <input type='radio' id='architectLogin' name='loginType' value={3} checked={loginType === 3} onChange={handleLoginTypeChange} />
                                                        <label htmlFor='architectLogin' id='architectLogin' className='custom-radio-label fw-bolder font-12 text-black me-0 cursor-pointer'>
                                                            {t('ARCHITECT', 'Architect / Engineer')}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='py-2 fw-bold text-black credentials-title'>{t('ENTER_CREDENTIALS', 'Enter Credentials')}</div>
                                            {loginType === 1 ? (
                                                <>
                                                    <label className='form-label'>{t('USERNAME', 'Username')}</label>
                                                    <input
                                                        type='text'
                                                        placeholder={t('USER_EMAIL_MOB', 'Username or Email or Mobile no.')}
                                                        className='form-control login'
                                                        onChange={handleOnInputChange}
                                                        name='username'
                                                    />
                                                    <label className='form-label'>{t('PASSWORD', 'Password')}</label>
                                                    <div className='password-input-group'>
                                                        <input
                                                            type={showPassword ? 'text' : 'password'}
                                                            placeholder='****'
                                                            className='form-control login'
                                                            onChange={handleOnInputChange}
                                                            name='password'
                                                        />
                                                        {showPassword ? (
                                                            <i className='fa fa-eye password-eye-btn' onClick={handleTogglePassword} aria-hidden='true'></i>
                                                        ) : (
                                                            <i className='fa fa-eye-slash password-eye-btn' aria-hidden='true' onClick={handleTogglePassword}></i>
                                                        )}
                                                        <div className='resend-otp' onClick={() => onForgotPasswordClick('EMPLOYEE')}>
                                                            {t('FORGOT_PASSWORD', 'Forgot Password?')}
                                                        </div>
                                                    </div>
                                                    <label className='form-label'>{t('CAPTCHA', 'Captcha')}</label>
                                                    <div className='d-flex'>
                                                        <div className='w-50'>
                                                            <input
                                                                type='text'
                                                                placeholder={t('ENTER_CAPTCHA', 'Enter captcha')}
                                                                id='user_captcha_input'
                                                                name='user_captcha_input'
                                                                className='form-control login'
                                                            />
                                                        </div>
                                                        <div className='w-50 px-2 captcha-container  d-flex justify-content-center align-items-center font-20'>
                                                            {/* QWERTY */}
                                                            <LoadCanvasTemplate reloadColor='blue' reloadText="<div class='reload-icon' title='Refresh Captcha'>&#x21bb;</div>" />
                                                        </div>
                                                    </div>
                                                    <button type='submit' className='btn login-btn btn-color mt-3' onClick={doSubmit}>
                                                        {t('LOGIN', 'Login')}
                                                    </button>
                                                </>
                                            ) : loginType === 2 ? (
                                                <>
                                                    <label className='form-label'>{t('USERNAME', 'Username')}</label>
                                                    <input type='email' onChange={handleOnInputChange} name='username' placeholder={t('EMAIL_MOB', 'Email')} className='form-control login' />
                                                    <label className='form-label'>{t('PASSWORD', 'Password')}</label>
                                                    <div className='password-input-group'>
                                                        <input
                                                            type={showPassword ? t('TEXT', 'text') : t('PASSWORD', 'password')}
                                                            placeholder='****'
                                                            className='form-control login'
                                                            onChange={handleOnInputChange}
                                                            name='password'
                                                        />
                                                        {showPassword ? (
                                                            <i className='fa fa-eye password-eye-btn' onClick={handleTogglePassword} aria-hidden='true'></i>
                                                        ) : (
                                                            <i className='fa fa-eye-slash password-eye-btn' aria-hidden='true' onClick={handleTogglePassword}></i>
                                                        )}
                                                        <div className='resend-otp' onClick={() => onForgotPasswordClick('ENTERPRISE')}>
                                                            {t('FORGOT_PASSWORD', 'Forgot Password?')}
                                                        </div>
                                                    </div>
                                                    <label className='form-label'>{t('CAPTCHA', 'Captcha')}</label>
                                                    <div className='d-flex'>
                                                        <div className='w-50'>
                                                            <input
                                                                type='text'
                                                                placeholder={t('ENTER_CAPTCHA', 'Enter captcha')}
                                                                id='user_captcha_input'
                                                                name='user_captcha_input'
                                                                className='form-control login'
                                                            />
                                                        </div>
                                                        <div className='w-50 px-2 captcha-container  d-flex justify-content-center align-items-center font-20'>
                                                            {/* QWERTY */}
                                                            <LoadCanvasTemplate reloadColor='blue' reloadText="<div class='reload-icon' title='Refresh Captcha'>&#x21bb;</div>" />
                                                        </div>
                                                    </div>
                                                    <p className='mt-4 fw-bold text-start ' style={{ color: '#45852e' }}>
                                                        <NavLink to='/goa-idc/usermanagement/enterprisecreation'>{t('NEW_USER_SIGN_UP', 'New user? sign up')}</NavLink>
                                                    </p>

                                                    <button type='submit' className='btn login-btn btn-color mt-3' onClick={doSubmit}>
                                                        {t('LOGIN', 'Login')}
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <label className='form-label'>{t('USERNAME', 'Username')}</label>
                                                    <input
                                                        type='text'
                                                        placeholder={t('USER_EMAIL_MOB', 'Username or Email or Mobile no.')}
                                                        className='form-control login'
                                                        onChange={handleOnInputChange}
                                                        name='username'
                                                    />
                                                    <label className='form-label'>{t('PASSWORD', 'Password')}</label>
                                                    <div className='password-input-group'>
                                                        <input
                                                            type={showPassword ? t('TEXT', 'text') : t('PASSWORD', 'password')}
                                                            placeholder='****'
                                                            className='form-control login'
                                                            onChange={handleOnInputChange}
                                                            name='password'
                                                        />
                                                        {showPassword ? (
                                                            <i className='fa fa-eye password-eye-btn' onClick={handleTogglePassword} aria-hidden='true'></i>
                                                        ) : (
                                                            <i className='fa fa-eye-slash password-eye-btn' aria-hidden='true' onClick={handleTogglePassword}></i>
                                                        )}
                                                        <div className='resend-otp' onClick={() => onForgotPasswordClick('EXTERNAL_EMPLOYEE')}>
                                                            {t('FORGOT_PASSWORD', 'Forgot Password?')}
                                                        </div>
                                                    </div>
                                                    <label className='form-label'>{t('CAPTCHA', 'Captcha')}</label>
                                                    <div className='d-flex'>
                                                        <div className='w-50'>
                                                            <input
                                                                type='text'
                                                                placeholder={t('ENTER_CAPTCHA', 'Enter captcha')}
                                                                id='user_captcha_input'
                                                                name='user_captcha_input'
                                                                className='form-control login'
                                                            />
                                                        </div>
                                                        <div className='w-50 px-2 captcha-container d-flex justify-content-center align-items-center font-20'>
                                                            {/* QWERTY */}
                                                            <LoadCanvasTemplate reloadColor='blue' reloadText="<div class='reload-icon' title='Refresh Captcha'>&#x21bb;</div>" />
                                                        </div>
                                                    </div>
                                                    <p className='mt-4 fw-bold text-start ' style={{ color: '#45852e' }}>
                                                        <NavLink to='/goa-idc/usermanagement/architectCreation'>{t('NEW_USER_SIGN_UP', 'New user? sign up')}</NavLink>
                                                    </p>

                                                    <button type='submit' className='btn login-btn btn-color mt-3' onClick={doSubmit}>
                                                        {getStringResource(t('LOGIN', 'Login'))}
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-7 me-auto mt-3  outer_part align-self-center  '>
                        <LoginSubPage />
                    </div>
                </div>
            </div>
        </>
    );
}
