// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TechnicalClearencePayment_Payemnt_Heading__LtMxf {
    color: #45852E;

}

.TechnicalClearencePayment_status__LRWjb .TechnicalClearencePayment_approve__FlqWZ {
    height: 32px;
    border-radius: 16px;
    background: #f3f9f0;
    color: #238e1a;
    font-family: Karla;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.28px;
}

.TechnicalClearencePayment_status__LRWjb .TechnicalClearencePayment_initiate__BQf92 {
    height: 32px;
    border-radius: 16px;
    background: #e1f2f9;
    color: #00639a;
    font-family: Karla;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.28px;
}

.TechnicalClearencePayment_status__LRWjb .TechnicalClearencePayment_pending__ZqDw1 {
    height: fit-content;
    border-radius: 16px;
    background: #f9f7d7;
    color: #9a7500;
    font-family: Karla;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.28px;
}`, "",{"version":3,"sources":["webpack://./src/features/technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/TechnicalClearencePayment.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;AAElB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B","sourcesContent":[".Payemnt_Heading {\n    color: #45852E;\n\n}\n\n.status .approve {\n    height: 32px;\n    border-radius: 16px;\n    background: #f3f9f0;\n    color: #238e1a;\n    font-family: Karla;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 110%;\n    letter-spacing: -0.28px;\n}\n\n.status .initiate {\n    height: 32px;\n    border-radius: 16px;\n    background: #e1f2f9;\n    color: #00639a;\n    font-family: Karla;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 110%;\n    letter-spacing: -0.28px;\n}\n\n.status .pending {\n    height: fit-content;\n    border-radius: 16px;\n    background: #f9f7d7;\n    color: #9a7500;\n    font-family: Karla;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 110%;\n    letter-spacing: -0.28px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Payemnt_Heading": `TechnicalClearencePayment_Payemnt_Heading__LtMxf`,
	"status": `TechnicalClearencePayment_status__LRWjb`,
	"approve": `TechnicalClearencePayment_approve__FlqWZ`,
	"initiate": `TechnicalClearencePayment_initiate__BQf92`,
	"pending": `TechnicalClearencePayment_pending__ZqDw1`
};
export default ___CSS_LOADER_EXPORT___;
