import React, { useEffect, useState } from 'react';
import {
    DISCONNECTION_CONNECTION_DETAILS_FORM_1,
    DISCONNECTION_CONNECTION_DETAILS_FORM_FOR_EMP,
    WATER_CONNECTION_FORM_STEPS,
    WATER_CONNECTION_FORM_STEPS_APPROVE,
    modifyMDMSDataList,
    modifyPlotList,
    paymentPayloadforWaterConnection
} from './WaterConnection.const';
import HeaderComponent from '../../components/header/Header.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToINR, fetchFeeData, fetchGatewayData, getOptions, getQueryParams, paymentCheck, paymentPayload } from '../modules/EstateManagement/EstateManagement.controller';
import { BPArequestInfo, POST, POST2, devUrl, endpoints, getNextValidAction, getProgressBarData, getUserDataOnRefresh, requestInfo } from '../../services/modulesServices/userManagement.api';
import { check, cross } from '../../utils/Logo';
import { WATER_CONNECTION_VALIDATION } from '../../utils/formikValidation/FormikValidation';
import { toast } from 'react-toastify';
import { extractFileUrl, getFirstErrorKey, getNavigateTo } from '../../utils/utils';
import { RazorpayOptions } from '../modules/UserManagement/types';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { Card, CardBody, Image } from 'react-bootstrap';
import Loading from '../../components/portalComponents/Loading.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import GenericBackButton from '../../components/UI components/Buttons/GenericBackButton';
import SideCard from '../../components/moduleCommonFormik/SideCardGenericComponent';
import { Field, FormikProvider, useFormik } from 'formik';
import PaymentDetails from '../modules/EstateManagement/Allotment/PaymentDetails';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import PaymentSubmissionStatus from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../components/moduleCommonFormik/ProgressBar';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import axios from 'axios';
import { CONSTANT_DATA_FIELD, KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../utils/constants';
import { ThreeDots } from 'react-loader-spinner';
import attachmentIcon from '../../assets/logos/attachment.svg';
import { QuestionnaireTable } from '../orderCompletionScreen/QuestionnaireTable';
import { QUESTIONNAIRE_DOCUMENT_COLUMN, QUESTIONNAIRE_DOCUMENT_COLUMN_DEFAULT } from '../orderCompletionScreen/OrderCompletionFormPage.const';

const WaterConnectionDisconnection = () => {
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [transferorDetails, setTransferorDetails] = useState<any>({});
    const [transferDetails, setTransferDetails] = useState<any>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [waterConnectionData, setWaterConnectionData] = useState<any>(null);
    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    let navigate = useNavigate();
    const [paymentData, setPaymentData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [allotmentDetails, setAllotmentDetails] = useState<any>(null);
    const [plotDetails, setPlotDetails] = useState<any>(null);
    const [pipeDiameterData, setPipeDiameterData] = useState<any>([]);
    const [waterTypesData, setWaterTypesData] = useState<any>([]);
    const [waterCharges, setWaterCharges] = useState<any>(0);
    const [isEditable, setIsEditable] = useState(true);
    const [plotList, setPlotList] = useState<any>([]);
    const [certificateData, setCertificateData] = useState<any>([]);
    const [searchParams] = useSearchParams();
    const [selectedCertificate, setSelectedCertificate] = useState<any>(null);
    const [selectedOneCert, setSelectedOneCert] = useState<any>(null);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [questionnaireDetails, setquestionnaireDetails] = useState<any>([]);
    const [questionnaireData, setQuestionnaireData] = useState<any>([]);
    const [auditTrial, setAuditTrial] = useState<any>(null);
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [isLoadingForDoc, setIsLoadingForDoc] = useState<boolean>(false);
    const [subLeaseResponse, setSubLeaseResponse] = useState<any>([]);
    const stepsDetails = !!questionnaireData.length ? WATER_CONNECTION_FORM_STEPS_APPROVE : WATER_CONNECTION_FORM_STEPS;
    const [waterConnectionApplicationNo, setWaterConnectionApplicationNo] = useState<any>([]);

    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    let applicationNo = searchParams.get('applicationNumber') || '';

    const formik: any = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            isSpecialProject: false,
            connectionType: 'DISCONNECT_CONNECTION'
        },
        onSubmit: value => {},
        validationSchema: WATER_CONNECTION_VALIDATION[`page${activeIndex}`]
    });
    const { values, setValues, errors, setFieldValue, setFieldTouched } = formik;

    const onCertSelect = (selectedValue: any) => {
        setSelectedOneCert(selectedValue?.connectionDetails?.uuid);
        setSelectedCertificate(selectedValue);
    };

    const CONNECTION_COLUMNS = [
        {
            colName: 'Select',
            fieldName: 'radio',
            onRadioClick: onCertSelect,
            selectedValue: selectedOneCert,
            clsName: 'col-sm-1',
            id: 0
        },
        {
            colName: 'Application No',
            fieldName: 'connectionDetails.connectionNo',
            clsName: 'col-sm-3',
            id: 1
        },
        {
            colName: 'Description',
            fieldName: 'connectionDetails.applicationDetails.waterType',
            clsName: 'col-sm-3',
            id: 2
        },
        {
            colName: 'Connection Date',
            fieldName: 'connectionDetails.connectionDate',
            clsName: 'col-sm-2',
            id: 3
        },
        {
            colName: 'Monthly Consumption (in m3)',
            fieldName: 'connectionDetails.applicationDetails.monthlyWaterConsumptionM3',
            clsName: 'col-sm-3',
            id: 4
        }
    ];

    const CERTIFICATES_COLUMNS = [
        {
            colName: 'Select',
            fieldName: 'radio',
            onRadioClick: onCertSelect,
            selectedValue: selectedOneCert,
            clsName: 'col-sm-1',
            id: 0
        },
        {
            colName: 'Application No',
            fieldName: 'applicationNumber',
            clsName: 'col-sm-3 column-text-start',
            id: 2
        },
        {
            colName: 'Document Name',
            fieldName: 'documentName',
            clsName: 'col-sm-3',
            id: 3
        },
        {
            colName: 'Document Type',
            fieldName: 'documentType',
            clsName: 'col-sm-3',
            id: 4
        }
    ];

    const handleStepClick = (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };
    const checkRole = async () => {
        try {
            const { data } = await getUserDataOnRefresh();
            if (data) {
                if (data.type === 'EMPLOYEE') {
                    navigate(`/goa-idc/e/waterconnection?applicationNumber=${applicationNo}&tenantId=ga`);
                }
            }
        } catch (error) {}
    };

    useEffect(() => {
        setquestionnaireDetails(questionnaireData);
    }, [questionnaireData]);

    useEffect(() => {
        if (applicationNo) {
            const fetchWaterDetails = async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.waterConnectionSearch + `?applicationNo=${applicationNo}`, { RequestInfo: requestInfo() });
                    setWaterConnectionData(data[0]?.applicationDetails);
                    values.plotNo = data[0]?.applicationDetails?.plotDetails?.uuid;
                    setIsEditable(data[0]?.applicationDetails?.applicationStatus === 'DRAFT');
                    getPaymentData(data[0]?.applicationDetails?.uuid);
                    getPlotData();
                    fetchDetails(data[0]?.applicationDetails?.plotDetails?.uuid);
                    setCertificateData(data[0]?.applicationDetails?.documents);
                    // console.log(data[0]?.applicationDetails?.documents, 'documents');

                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    console.error('Error fetching data:', error);
                }
            };
            if (applicationNo) {
                setIsEditable(false);
                fetchWaterDetails();
                getActionsData();
                checkRole();
            }
        }
    }, [applicationNo]);

    useEffect(() => {
        try {
            if (!!applicationNo) {
                getAuditData();
                getQuestionnaires(applicationNo);
                getProgressBarData(applicationNo).then(res => setProgressBarDetails(res.data));
            }
        } catch (e) {
            console.log(e);
        }
    }, [applicationNo]);

    const getQuestionnaires = (val: any) => {
        setIsLoading(true);
        if (!!val) {
            TechnicalServices.searchQuestionnaire(val, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    setIsLoading(false);
                    setQuestionnaireData(response?.data?.backQuestionnaires);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    const getAuditData = () => {
        if (!applicationNo) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.getAuditTrail(payload, applicationNo, 'ga')
            .then(res => {
                setAuditTrial(res?.data?.ProcessInstances);
            })
            .catch(err => console.log(err));
    };
    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const { data } = await POST(endpoints.searchSubLease + `?lesseeEntityIds=${entityId}&plotIds=${values?.plotNo}`, { RequestInfo: requestInfo() });
            setSubLeaseResponse(data?.subLeaseDetails[0]);
            console.log(data, 'MYDATA');
            setIsLoading(false);
        })();

        const fetchPipeDiameter = async () => {
            const { data } = await POST('/egov-mdms-service/v1/_search', {
                RequestInfo: requestInfo(),
                MdmsCriteria: {
                    tenantId: 'ga',
                    moduleDetails: [
                        {
                            moduleName: 'ws-services-masters',
                            masterDetails: [
                                {
                                    name: 'PipeDiameter',
                                    filter: '[?(@.active == true)]'
                                }
                            ]
                        }
                    ]
                }
            });
            let pipeDiameter = data?.['MdmsRes']?.['ws-services-masters']?.['PipeDiameter'];
            const modifiedPipeDia = modifyMDMSDataList(pipeDiameter, 'size', 'code') || [];
            setPipeDiameterData(modifiedPipeDia);
            console.log(pipeDiameter, 'PIPE_DIAMETER');
        };

        const fetchWaterTypes = async () => {
            const { data } = await POST('/egov-mdms-service/v1/_search', {
                RequestInfo: requestInfo(),
                MdmsCriteria: {
                    tenantId: 'ga',
                    moduleDetails: [
                        {
                            moduleName: 'ws-services-masters',
                            masterDetails: [
                                {
                                    name: 'WaterType',
                                    filter: '[?(@.active == true)]'
                                }
                            ]
                        }
                    ]
                }
            });
            let waterTypes = data?.['MdmsRes']?.['ws-services-masters']?.['WaterType'];

            const modifiedPipeDia = modifyMDMSDataList(waterTypes, 'code', 'name') || [];
            setWaterTypesData(modifiedPipeDia);
        };

        if (values?.plotNo) {
            fetchPipeDiameter();
            fetchWaterTypes();
        }
    }, [values?.plotNo]);

    const fetchDetails = (plotID: any) => {
        fetchAllotmentDetails(plotID);
        fetchPlotDetails();
        if (!applicationNo) fetchUserCertificates();
    };

    const fetchAllotmentDetails = async (plotID: any) => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.allotmentDetailsSearch + `?plotIds=${plotID}&entityIds=${entityId}`, { RequestInfo: requestInfo() });
            setAllotmentDetails(data.allotmentDetails[0]);
            // values.plotNo = plotDetails?.plotNo;
            values.allotmentOrderNo = data.allotmentDetails[0]?.allotmentId;
            values.allotmentOrderDate = data.allotmentDetails[0]?.allotmentDate;

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const fetchPlotDetails = async () => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.entityRefDetails + `?isPlotDetailsRequired=true&types=ALLOTTEE,LESSEE,TRANSFEROR&entityIds=${entityId}`, { RequestInfo: requestInfo() });
            const filteredData = data.entityRefDetails.filter((val: any) => val?.plotId === values?.plotNo)[0];
            setPlotDetails(filteredData?.plotDetails);
            console.log(filteredData);
            values.estateName = filteredData?.plotDetails?.estateDetails?.estateName;
            values.plotType = filteredData?.plotDetails?.plotType;
            values.phase = filteredData?.plotDetails?.phase;
            if (!!applicationNo) values.plotNo = filteredData?.plotDetails?.plotNo;

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const fetchUserCertificates = async () => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.getAllWaterConnection + `?entityId=${entityId}&plotId=${values?.plotNo}&connectionStatus=ACTIVE`, { RequestInfo: requestInfo() });
            const updatedData = data.map((item: any) => {
                return {
                    ...item,
                    id: item.connectionDetails?.uuid
                };
            });
            setCertificateData(updatedData);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (values?.plotNo && !applicationNo) {
            fetchDetails(values?.plotNo);
        }
    }, [values?.plotNo]);

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const fetchPayDetails = (data: any) => {
        return [
            {
                paymentType: '',
                principalAmount: +data?.adjustedSecurityDeposit,
                cgstPrincipal: null,
                sgstPrincipal: null,
                totalDueAmount: +data?.adjustedSecurityDeposit,
                feeTypeDisplayName: 'Adjusted Security Deposit',
                cgstAmount: data?.cgst === 0 ? '--' : data?.cgst,
                sgstAmount: data?.sgst === 0 ? '--' : data?.sgst,
                amount: convertToINR(data?.adjustedSecurityDeposit),
                total: convertToINR(data?.adjustedSecurityDeposit),
                index: 1
            }
        ];
    };

    useEffect(() => {
        const fetchWaterTypes = async () => {
            const apiurl = endpoints.waterChargesByDiameter;
            const { data } = await POST(`${apiurl}?diaSize=${values?.connectionPipeDia}`, {
                RequestInfo: BPArequestInfo()
            });
            setWaterCharges(data?.waterConnectionFeeMasters[0]?.rate || 0);
            console.log(data, 'WATER_CHARGES');
        };

        if (!!values?.connectionPipeDia) fetchWaterTypes();
    }, [values?.connectionPipeDia]);

    const getPlotData = async () => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.validateWaterConnection + `?leasseeId=${entityId}&applicationType=DISCONNECT_CONNECTION`, { RequestInfo: requestInfo() });
            // const { data } = await POST(`/idc-estate-service/entity-reference-details/_search?isPlotDetailsRequired=true&entityIds=${entityId}&types=${'LESSEE'}`, { RequestInfo: requestInfo() });
            if (data?.entityRefDetails?.entityRefDetails?.length > 0) {
                setPlotList(data?.entityRefDetails?.entityRefDetails);
            }
        } catch (e: any) {}
        setIsLoading(false);
    };

    useEffect(() => {
        getPlotData();
    }, []);

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    useEffect(() => {
        (async () => {
            if (activeIndex === 2) {
                try {
                    const payCheck = await paymentCheck('PROCESSING_FEE', values.plotUuid, transferDetails?.applicationDetails.uuid);
                    console.log(payCheck);
                    setIsPaid(payCheck?.isPaid);
                    setPaymentTried(payCheck?.paymentTried);
                    setPaymentData(payCheck?.paymentData);
                    setTotalPaidAmount(payCheck?.totalPaidAmount);
                    setTransactionDetails(payCheck?.transactionDetails);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    const feeData = await fetchFeeData('TRANSFER', 'PROCESSING_FEE', values.plotUuid);
                    setPaymentData(feeData?.feeMasterDetails);
                    setTotalPaidAmount(feeData?.totalPaidAmount);
                    setPaymentIsLoading(false);
                } catch (e) {
                    console.log(e);
                    setPaymentIsLoading(false);
                }
            }
        })();
    }, [activeIndex]);

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const lastSubmit = (isSaveOnly?: boolean, status?: string, comment?: string) => {
        if (activeIndex === 1 && !!applicationNo) {
            setActiveIndex(activeIndex + 1);
            return;
        }
        if (activeIndex === 2) {
            // onPay();
            setActiveIndex(activeIndex + 1);
            return;
        }
        if (activeIndex === 3 && (!applicationNo || waterConnectionData?.applicationStatus === 'DRAFT')) {
            onLastSubmit();
            return;
        }
        if (activeIndex === 3 && !!applicationNo) {
            // alert(1);
            if (activeIndex === stepsDetails.length) {
                // alert(2);
                navigateToDashboard();
                return;
            } else {
                // alert(3);
                setActiveIndex(activeIndex + 1);
                return;
            }
        }
        if (activeIndex === 4 && !!applicationNo) {
            navigateToDashboard();
            return;
        }
        onSubmit();
    };

    const onSubmit = async (isSaveOnly?: boolean) => {
        if (isEditable && isEditable !== undefined) {
            // if (activeIndex === 3 && isPaid) {
            //     // setActiveIndex(4);
            //     return;
            // }
            setIsLoading(true);
            const payload: any = {
                RequestInfo: BPArequestInfo(),
                applicationDetails: {
                    leasseeDetails: {
                        uuid: entityId
                    },
                    plotDetails: {
                        uuid: values?.plotNo
                    },
                    applicationType: 'DISCONNECT_CONNECTION',
                    applicationDate: values?.allotmentOrderDate,
                    waterType: selectedCertificate?.connectionDetails?.applicationDetails?.waterType,
                    pipeDiaMm: +values?.connectionPipeDia,
                    monthlyWaterConsumptionM3: +values?.monthlyConsumption,
                    waterConnectionCharge: +waterCharges,
                    documents: selectedCertificate?.connectionDetails?.applicationDetails?.documents || [],
                    existingConnectionDetails: selectedCertificate?.connectionDetails
                }
            };

            if (!!values?.subLessee) {
                payload.subLeasseeDetails = {
                    uuid: values?.subLessee
                };
            }

            const apiEndPoint = endpoints.createWaterConnection;

            try {
                // await WATER_CONNECTION_VALIDATION[`page${activeIndex}`]?.validate(values, { abortEarly: false, context: values });

                try {
                    const { data } = await POST(apiEndPoint, payload);
                    setWaterConnectionData(data?.applicationDetails);
                    setWaterConnectionApplicationNo(data?.applicationDetails?.applicationNo);
                    setIsLoading(false);
                    // if (activeIndex === 1) {
                    //     toast('Application Created', {
                    //         position: toast.POSITION.TOP_RIGHT,
                    //         className: 'foo-bar',
                    //         type: 'success'
                    //     });
                    // } else if (activeIndex === 2) {
                    //     toast('Data saved successfully', {
                    //         position: toast.POSITION.TOP_RIGHT,
                    //         className: 'foo-bar',
                    //         type: 'success'
                    //     });
                    // }
                    !isSaveOnly && onChangeNextIndex();
                    if (activeIndex === 4) {
                        setModalDetails({ show: true, title: 'Success!', para: 'Application assigned successfully', img: check, type: 'success', reUpload: 'OK' });
                    }
                } catch (error: any) {
                    setIsLoading(false);
                    let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            } catch (e) {
                let errorField = getFirstErrorKey(errors);
                setIsLoading(false);
                if (errorField) {
                    const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                    await setFieldTouched(errorField, true, true);
                    let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                    getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const onLastSubmit = async (isSaveOnly?: boolean) => {
        if (!values?.waterAgreement) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please submit the agreement', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setIsLoading(true);
        const payload: any = {
            RequestInfo: BPArequestInfo(),
            waterApplicationRequest: {
                applicationDetails: waterConnectionData
            },
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: 'WATER_CONNECTION_DISCONNECTION',
                    businessId: waterConnectionData?.applicationNo,
                    moduleName: 'Water Connection Disconnection',
                    action: 'CREATE',
                    comment: 'Created'
                }
            ]
        };

        const apiEndPoint = endpoints.waterConnectionInitiate;

        try {
            const successMessageWCApplicationCreated = `Application${waterConnectionData?.applicationNo ? ' Number ' + waterConnectionData?.applicationNo : ''} created Successfully`;
            const { data } = await POST(apiEndPoint, payload);
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Success!', para: successMessageWCApplicationCreated, img: check, type: 'success', reUpload: 'OK' });
        } catch (error: any) {
            setIsLoading(false);
            let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getActionsData = () => {
        if (!!applicationNo) {
            setIsLoading(true);

            getNextValidAction(applicationNo)
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        console.log(response?.data);
                        // setActionsData(response?.data);
                    }

                    setIsLoading(false);
                })
                .catch((error: any) => {
                    setIsLoading(false);
                });
        }
    };

    // useEffect(() => {
    //     (async () => {
    //         setIsLoading(true);
    //         const queryParams = getQueryParams(searchParams);
    //         if (queryParams?.['applicationNo']) {
    //             if (queryParams?.['plotId']) {
    //                 setPaymentIsLoading(true);
    //                 getTransactionUpdate(queryParams, waterConnectionData?.applicationDetails.uuid);
    //             }
    //         }
    //         setIsLoading(false);
    //     })();
    // }, [location.search]);

    const onPay = async () => {
        setIsLoading(true);
        setTotalPaidAmount(waterConnectionData?.totalWaterConnectionCharge);
        const totalPaidAmount = waterConnectionData?.totalWaterConnectionCharge;
        let url = window.location.origin + `goa-idc/waterconnection?stepper=${activeIndex}&applicationNumber=${applicationNo}`;
        const periodTo = new Date(waterConnectionData?.auditDetails?.createdTime).setFullYear(new Date(waterConnectionData?.auditDetails?.createdTime).getFullYear() + 1);
        const payload = paymentPayloadforWaterConnection(
            waterConnectionData,
            values?.plotNo,
            totalPaidAmount,
            url,
            'Water Connection Application Fees',
            selectedGateway,
            waterConnectionData.auditDetails.createdTime,
            periodTo,
            fetchPayDetails(waterConnectionData)
        );

        try {
            if (!totalPaidAmount) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            if (!selectedGateway) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            if (waterConnectionData?.applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, getUser, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    console.log(data.additionalDetails);
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
                setIsLoading(false);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getPaymentData = async (appNumber: any) => {
        try {
            const data = await POST(endpoints.paymentDetailsSearch + `?applicationIds=${appNumber || waterConnectionData?.uuid}`, { RequestInfo: requestInfo() });
            if (data?.data?.payments.length) {
                let paymentDetails = data?.data?.payments[0]?.payment;
                setTransactionDetails({
                    txnId: paymentDetails?.transactionNumber || '',
                    auditDetails: { createdTime: paymentDetails?.transactionDate || '' },
                    txnStatus: paymentDetails?.paymentStatus || '',
                    invoiceRefId: data?.data?.payments[0]?.invoiceRefId || ''
                });
                let payment = data?.data?.payments?.map((val: any, index: number) => ({
                    feeTypeDisplayName: val.paymentTypeDisplayName,
                    amount: convertToINR(val.principalAmount),
                    cgstAmount: val.cgstPrincipal === 0 ? '--' : convertToINR(val.cgstPrincipal),
                    sgstAmount: val.sgstPrincipal === 0 ? '--' : convertToINR(val.sgstPrincipal),
                    total: convertToINR(val.paidAmount),
                    index: index + 1
                }));
                setPaymentRowData(payment);
            }
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getTransactionUpdate = async (params: any, applicationId: any) => {
        try {
            const paid: any = await paymentCheck('PROCESSING_FEE', params['plotId'], applicationId);
            if (!paid.isPaid) {
                setIsLoading(true);
                const payload = { RequestInfo: requestInfo() };
                const { data } = await POST2(endpoints.transactionUpdate, payload, params);
                if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                    setTransactionDetails(data?.Transaction?.[0]);
                    if (data?.Transaction?.[0]?.txnId) {
                        try {
                            const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                            if (resp.data.status === 'SUCCESSFUL') {
                                setModalDetails({ show: true, title: 'Success!', para: 'Payment successful', img: check, type: 'success', reUpload: 'OK' });

                                setIsPaid(true);
                            } else setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        } catch (e: any) {
                            setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        }
                    }
                } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                    setModalDetails({
                        show: true,
                        title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                        para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    // window.location.reload();
                }
                setIsLoading(false);
                setPaymentIsLoading(false);
            }
        } catch (e: any) {
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Transaction Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleModalClose = (type: string) => {
        setModalDetails({ show: false });
        if (type === 'success' && (activeIndex === 3 || activeIndex === 4)) {
            navigate('/goa-idc/enterprisedashboard');
        }
        if (modalDetails.para === 'Report submitted successfully') {
            navigateToDashboard();
        }
    };

    const onCheckHandler = (checkedItems: any) => {
        setSelectedCertificate(checkedItems);
    };

    const renderProgressBarStatus = () => {
        const applicationData = {
            applicationNo: applicationNo,
            status: waterConnectionData?.applicationStatus,
            data: auditTrial,
            isRoleMD: true,
            bpaData: waterConnectionData
        };

        return (
            <Card className='card_border my-2'>
                <Card.Body>
                    <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                </Card.Body>
            </Card>
        );
    };

    const updateQuestionnaire = () => {
        questionnaireDetails.forEach((val: any) => {
            console.log(values[`ans_${val?.id}`]);
            val['answer'] = values[`ans_${val?.id}`];
        });
        console.log(questionnaireDetails);
        const remainingQuestionCount = questionnaireDetails.filter((val: any) => !val?.answer || val?.answer === null);
        if (remainingQuestionCount.length > 0) {
            setModalDetails({
                show: true,
                title: 'Error!',
                para: t('POPUP_MSG_QUESTIONNAIRE_ERROR', 'Please respond to the queries raised by the Field Manager in the Questionnaire section'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            return;
        }
        questionnaireDetails.forEach((val: any) => {
            val.lesseeReplyStatus = true;
        });

        let payload: any = {
            RequestInfo: BPArequestInfo(),
            backQuestionnaires: questionnaireDetails
        };

        TechnicalServices.manageQuestionnaire(payload, true)
            .then((response: any) => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Success!', para: 'Report submitted successfully', img: check, type: 'success', reUpload: 'OK' });
                // navigateToDashboard();
                // submitForm(selectedAction);
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const renderActionforQuestion = () => {
        if (!!questionnaireData.length && !!waterConnectionData?.lesseeReplyPendding) {
            return (
                <>
                    <GenericButtons type='' variant='' text='Close' handleClick={() => navigateToDashboard()} />
                    <GenericButtons type='primary' variant='primary' text='Submit' handleClick={() => updateQuestionnaire()} />
                </>
            );
        } else {
            return <GenericButtons type='' variant='' text='Close' handleClick={() => navigateToDashboard()} />;
        }
    };

    const removeFile = (id: any, current_id: any) => {
        setIsLoadingForDoc(true);
        let myallDcosArray_New = questionnaireDetails;

        myallDcosArray_New.forEach((val: any) => {
            if (val.id === current_id) {
                const filteredData = val?.documents?.filter((val: any) => val.rowId !== id);
                val.documents = filteredData;
            }
        });
        setquestionnaireDetails(myallDcosArray_New);
        setIsLoadingForDoc(false);
        setFieldValue('Docs', myallDcosArray_New);
        renderQuestionnaireDetails();
    };

    const renderQuestionnaireDetails = () => {
        const downloadFile = (id: any) => {
            if (!id) return;
            axios
                .get(devUrl + `/filestore/v1/files/url?tenantId=ga&fileStoreIds=${id}`)
                .then((response: any) => {
                    window.open(response?.data?.fileStoreIds[0]?.url);
                })
                .catch(error => {});
        };

        const handleOnChange = async (e: any, current_id: any) => {
            let myallDcosArray = questionnaireDetails;
            const file: any = e.target.files && e.target.files[0];
            try {
                if (!file) {
                    console.error('No file selected.');
                    return;
                }
                setIsLoadingForDoc(true);
                const response: any = await axios.post(
                    devUrl + '/filestore/v1/files',
                    {
                        file: file,
                        tenantId: 'ga',
                        tag: file.name,
                        module: 'hrms-upload'
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );
                const fileResponseUrl = await axios.get(devUrl + '/filestore/v1/files/url', {
                    params: {
                        tenantId: 'ga',
                        fileStoreIds: response.data.files[0].fileStoreId
                    }
                });
                const fileData = {
                    documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
                    fileSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
                    referenceId: fileResponseUrl?.data?.fileStoreIds[0].id,
                    documentUrl: extractFileUrl(fileResponseUrl.data.fileStoreIds[0].url),
                    createdDate: fileResponseUrl?.data?.fileStoreIds[0].uploadedDate,
                    rowId: fileResponseUrl?.data?.fileStoreIds[0].id,
                    fileStoreId: fileResponseUrl?.data?.fileStoreIds[0].id
                };
                setIsLoadingForDoc(false);
                // props.setQuestionnaireData(
                myallDcosArray.forEach((val: any) => {
                    if (val.id === current_id) val.documents.push(fileData);
                });
                setquestionnaireDetails(myallDcosArray);
                // );
            } catch (e) {
                setIsLoadingForDoc(false);
            }
        };
        return (
            <>
                {!!questionnaireDetails &&
                    questionnaireDetails.map((val: any, ind: number) => {
                        values[`que_${val?.id}`] = val?.question;
                        if (!!val?.answer) values[`ans_${val?.id}`] = val?.answer;
                        const myArray = {
                            formData: [
                                {
                                    title: 'Question',
                                    field: `que_${val?.id}`,
                                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                                    isRequired: true,
                                    isDisabled: true,
                                    titleCode: `OC_LBL_ENTERPRISE_NAMEs ${ind + 1}`,
                                    class: 'me-2'
                                },
                                {
                                    title: 'Answer',
                                    field: `ans_${val?.id}`,
                                    type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                                    isRequired: true,
                                    titleCode: 'Your Feedback',
                                    class: 'me-2',
                                    isDisabled: !!val?.lesseeReplyStatus
                                }
                            ]
                        };

                        return (
                            <>
                                <Card key={ind}>
                                    <CardBody>
                                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 my-2'>
                                            <input type='checkbox' id={`${val?.id}_${ind}`} name={`${val?.id}_${ind}`} defaultChecked={false} />
                                            <label htmlFor={`${val?.id}_${ind}`} className='d-flex collapsible_box_label align-items-center justify-content-between text-black  fw-bold  '>
                                                {t('', `Correction Report ${ind + 1}`)}
                                            </label>
                                            <div className='form_fields px-2'>
                                                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 '>
                                                    <GenericFormCard {...myArray} key={ind} />
                                                </div>
                                                <div className='mt-3'>
                                                    {!val?.lesseeReplyStatus && (
                                                        <div className='d-flex justify-content-center align-self-center mb-4'>
                                                            {isLoadingForDoc ? (
                                                                <ThreeDots
                                                                    visible={true}
                                                                    height='80'
                                                                    width='80'
                                                                    color='#45852e'
                                                                    radius='9'
                                                                    ariaLabel='three-dots-loading'
                                                                    wrapperStyle={{}}
                                                                    wrapperClass=''
                                                                />
                                                            ) : (
                                                                <div className='col-11 note-attachfile text-center my-1 p-2 mt-2  mb-2 justify-content-center align-items-center noc_add_fields'>
                                                                    <Image src={attachmentIcon} alt='Attach Document Icon' /> <span className='cursor-pointer'>{t('', 'Upload Document')}</span>
                                                                    <input className='uploadBox upload-document cursor-pointer' type='file' onChange={e => handleOnChange(e, val?.id)} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                    {!!val?.documents.length && (
                                                        <QuestionnaireTable
                                                            key={ind}
                                                            column={!!val?.lesseeReplyStatus ? QUESTIONNAIRE_DOCUMENT_COLUMN_DEFAULT : QUESTIONNAIRE_DOCUMENT_COLUMN}
                                                            tableRowData={val?.documents || []}
                                                            additionalMethod={() => {}}
                                                            deleteOwnershipDetails={id => removeFile(id, val?.id)}
                                                            updateOwnershipDataStatus={(data: any) => downloadFile(data?.rowId ?? data?.fileStoreId)}
                                                            isDisabled={false}
                                                            hideSearchBar
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </>
                        );
                    })}
                {!questionnaireDetails.length && (
                    <Card>
                        <CardBody>
                            <p className='text-center fw-bold pt-2'>No Data Available</p>
                        </CardBody>
                    </Card>
                )}
            </>
        );
    };

    const ConnectionDetails = () => {
        if (applicationNo) {
            values.connectionType = waterConnectionData?.applicationType;
            values.waterType = waterConnectionData?.waterType;
            values.connectionPipeDia = waterConnectionData?.pipeDiaMm;
            values.monthlyConsumption = waterConnectionData?.monthlyWaterConsumptionM3;
            values.estateName = plotDetails?.estateDetails?.estateName;
            values.plotNo = plotDetails?.plotNo;
            values.plotType = plotDetails?.plotType;
            values.phase = plotDetails?.phase;
            values.isUpdatable = false;
        } else {
            values.isUpdatable = true;
        }
        const connection_details_formData = applicationNo ? DISCONNECTION_CONNECTION_DETAILS_FORM_FOR_EMP : DISCONNECTION_CONNECTION_DETAILS_FORM_1;
        // const connection_details_formData_1 = applicationNo ? CONNECTION_DETAILS_FORM_FOR_EMP_SECOND : CONNECTION_DETAILS_FORM_2;

        connection_details_formData[0].formData.map((val: any, id: any) => {
            if (val?.field === 'plotNo') {
                val.options = modifyPlotList(plotList);
            }
            if (val?.field === 'subLessee') {
                val.isDisabled = !(subLeaseResponse?.length > 0);
                val.options = subLeaseResponse || [];
            }
        });

        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            {connection_details_formData?.map(val => (
                                <GenericFormCard {...val} />
                            ))}
                            <span className='formTitle ms-2 mb-4'>Existing Connection Details</span>
                            <div className='pb-2'>
                                <GenericActionTable
                                    columns={applicationNo ? CERTIFICATES_COLUMNS : CONNECTION_COLUMNS}
                                    searchBarPlaceholder={'SEARCH_BY_PLOT'}
                                    rowData={certificateData}
                                    hideExportBtn={true}
                                    checkType={'singleCheck'}
                                    itemsPerPage={10}
                                    onCheckHandler={(row: any) => {
                                        onCheckHandler(row);
                                    }}
                                    hideSearchBar
                                />
                            </div>

                            {/* {connection_details_formData_1?.map(val => (
                                <GenericFormCard {...val} />
                            ))} */}
                            {/* <div>
                                <span className='formTitle ms-2 mb-4'>Existing Connection Details</span>
                                <GenericActionTable
                                    hideSearchBar={true}
                                    columns={columnsforWC}
                                    rowData={() => {}}
                                    itemsPerPage={5}
                                    highlightRow={true}
                                    totalRow={'total'}
                                    hideFooter={true}
                                    totalRowText={''}
                                />
                            </div>
                            {UPGRADED_CONNECTION_DETAILS_FORM?.map(val => (
                                <GenericFormCard {...val} />
                            ))} */}
                        </Card.Body>
                    </Card>
                </>
            </div>
        );
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('', 'Water Connection')}
                            bottomSubText={waterConnectionApplicationNo ? `Type : Water Disconnection, No : ${applicationNo || waterConnectionApplicationNo}` : ''}
                            additionalClass={waterConnectionApplicationNo && 'sub-text-style'}
                        />
                        <div className='col-3 stickyCard-section font-inter'>
                            <div className='stickyCard'>
                                <Card className={`card_border hiddenNav ${isVisible ? '' : 'scrolledNav'}`}>
                                    <Card.Body>
                                        <div className='d-flex align-items-center'>
                                            <GenericBackButton />
                                            <div className='ps-1'>
                                                <Card.Text className='font-inter-med p-0 fs-5' style={{ marginBottom: -4 }}>
                                                    {t('', 'Water Connection')}
                                                </Card.Text>
                                                {waterConnectionApplicationNo && (
                                                    <Card.Subtitle className='sub-text-style text-nav font-inter p-0 m-0'>{`Type : Water Disconnection, No : ${
                                                        applicationNo || waterConnectionApplicationNo
                                                    }`}</Card.Subtitle>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard steps={stepsDetails} visited={visited} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            <FormikProvider value={formik}>
                                {!!applicationNo && waterConnectionData?.applicationStatus !== 'DRAFT' && renderProgressBarStatus()}
                                {activeIndex === 1 && ConnectionDetails()}
                                {activeIndex === 2 &&
                                    (!paymentIsLoading ? (
                                        <PaymentDetails
                                            paymentData={paymentRowData.length ? paymentRowData : fetchPayDetails(waterConnectionData)}
                                            selectedGateway={selectedGateway}
                                            setSelectedGateway={setSelectedGateway}
                                            isPaid={paymentRowData.length ? true : false}
                                            gatewayData={waterConnectionData?.applicationStatus === 'APPROVED' ? gatewayData : []}
                                            transactionDetails={transactionDetails}
                                            title={'Water Connection Fee'}
                                            // isPaymentOption={!!(waterConnectionData?.applicationStatus === 'APPROVED')}
                                            isPaymentOption={false}
                                        ></PaymentDetails>
                                    ) : (
                                        <Loading />
                                    ))}
                                {activeIndex === 3 && (
                                    <Card className='card_border my-2'>
                                        <Card.Body>
                                            <div className='row ps-2 py-2'>
                                                <div className='formTitle m-0 fw-bold'>
                                                    {t('', 'Acknowledgement')}
                                                    {/* <span className='text-danger'>*</span> */}
                                                </div>
                                            </div>
                                            <div className='row d-flex m-0'>
                                                <p className='fw-bold'>The Lessee does hereby affirm and declare that:</p>

                                                {/* <span className='checkmark'></span> */}
                                                <span className='font-inter' style={{ fontWeight: '600' }}>
                                                    <p>1. I/We will carry out pipeline work from the connection point to the shed as directed by the Engineer-in-charge of the Corporation.</p>
                                                    <p>
                                                        2. I/We will provide the water meter, approved by the Engineer-In-Charge of the Corporation and all other necessary fittings will be provided at
                                                        my/our own cost. I/we also undertake to repair these fittings if the situation warrants.
                                                    </p>
                                                    <p>3. I/We will keep a duplicate key with the in charge of the Estate and will have no objection for taking water meter reading daily.</p>
                                                    <p>4. I/We will also undertake to pay the water consumption charges every month before 10th of next month.</p>
                                                    <p>
                                                        5. I/We will make necessary drainage arrangements including soak pit etc. in the plot or my/our shed, before the water connection is given to
                                                        my/our shed to the satisfaction of the Engineer-in-charge.
                                                    </p>
                                                    <p>
                                                        6. I/We understand that the water supply will be disconnected by the Corporation if the unit is in the arrears of water charges for more than
                                                        TWO months.
                                                    </p>
                                                    <p>7. I/We agree to pay the security deposit and connection charges as required by the Corporation.</p>
                                                    <p>
                                                        8. I/We will pay the water supply charges at the rates decided time to time. We are aware that the present rate is Rs. 45/-(Rupees forty Five
                                                        only) per cubic meter.
                                                    </p>
                                                    <p>
                                                        9. The monthly consumption of water will be 250 m<sup>3</sup> for the said connection. (Copy of Project report attached).
                                                    </p>
                                                    <p>10. Electromagnetic type digital water meter (B/class) will be provided by us.</p>
                                                </span>
                                                <Card.Body>
                                                    <div className={`ps-2 d-flex gap-2 border my-3 rounded success`} style={{ fontSize: '16px', padding: '12px' }}>
                                                        <div className='fw-bold'>
                                                            <Field
                                                                type='checkbox'
                                                                name={'waterAgreement'}
                                                                id={'waterAgreement'}
                                                                className={`mx-4`}
                                                                checked={!isEditable ? true : values['waterAgreement']}
                                                                disabled={!isEditable}
                                                            />
                                                        </div>
                                                        <span className='fw-medium'>I will abide all the statement mentioned above.</span>
                                                    </div>
                                                </Card.Body>
                                            </div>
                                            {/* {!!props?.previewData?.lesseeDigitalSignFlage || props?.eSignFlag ? (
                                            <>
                                                <div className='ps-2 pt-2 font-14 font-inter fw-bold m-0'>Digitally Signed</div>
                                                <div className='ps-2 pb-2 font-14 font-inter'>{eSignedDocumentData?.esignedDate}</div>
                                                <div onClick={() => downloadApplicationDocument()} className='ps-2 d-flex align-items-center'>
                                                    <Image src={download} height={14} />
                                                    <div className='ps-2 font-14' style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>
                                                        Download Application
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <GenericButtons text='Sign the document' ClassName='px-3' handleClick={props?.signDocument} />
                                        )} */}
                                        </Card.Body>
                                    </Card>
                                )}
                                {activeIndex === 4 && renderQuestionnaireDetails()}
                            </FormikProvider>
                        </div>
                    </div>

                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {/* {activeIndex !== stepsDetails.length && !!isEditable && <GenericButtons text={t('SAVE', 'Save')} handleClick={() => onSubmit(true)} ClassName='me-3' />} */}
                                <GenericButtons
                                    text={
                                        !!isEditable && activeIndex !== stepsDetails.length && activeIndex === 1
                                            ? `Save & Next`
                                            : activeIndex !== stepsDetails.length
                                            ? isEditable
                                                ? `Next`
                                                : `Next`
                                            : isEditable
                                            ? `Submit`
                                            : !!questionnaireData.length && !!waterConnectionData?.lesseeReplyPendding
                                            ? ``
                                            : `Close`
                                    }
                                    variant='primary'
                                    handleClick={() => lastSubmit()}
                                />
                                {!!questionnaireData.length && !!waterConnectionData?.lesseeReplyPendding && activeIndex === 4 && renderActionforQuestion()}
                            </div>
                        </div>
                    </footer>
                </>
            )}
        </>
    );
};

export default WaterConnectionDisconnection;
