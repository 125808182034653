import React, { useEffect, useState } from 'react';
import PopUps from '../../../../components/PopUps';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { Card, Form } from 'react-bootstrap';
import RunningAccountBillCorrespondence from './RunningAccountBillCorrespondence';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../EstateManagement/LeaseDeed/LeaseDeed.const';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import { check, cross } from '../../../../utils/Logo';
import { getFormattedReclaimableTable, measurmentInitialField, taxTypes } from './RaBill.const';
import { tenderDetails } from '../NIT screens/NITscreen.const';
import { loaDetailValues } from '../LOAScreens/Loa.const';
import { HeaderInitialValues } from '../ContractCreationScreens/ContractCreation.const';
import { v4 as uuidv4 } from 'uuid';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { TENANT_ID } from '../../../../utils/constants';
import { entityDocumentData, getHistory, getSuccessMessage, onAttachDocument, strToNum } from '../../EstateManagement/EstateManagement.controller';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { MB_AND_BILL_MEASUREMENT_VALIDATION, MB_AND_BILL_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { convertDateToDDmmYYYY, getFirstErrorKey } from '../../../../utils/utils';
import { getPlotDetails } from '../../EstateManagement/Allotment/Allotment.controller';
import { setDprHeaderData } from '../DPR creation screen/DPR.const';
import { EsignModal } from '../../../commonPages/EsignModal';

const RunningAccountBillWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [resData, setResData] = useState<any>(null);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [abstractData, setAbstractData] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [newMeasurmentData, setNewMeasurmentData] = useState([]);
    const [raBillRowData, setRaBillRowData] = useState([]);
    const [reminderPopUp, setReminderPopUp] = useState<boolean>(false);
    const [content, setContent] = useState('');
    const [mopContent, setMopContent] = useState('');
    const [tableData, setTableData] = useState<any>([]);
    const [addPopUp, setAddPopUp] = useState<boolean>(false);
    const [itemMeasured, setItemMeasured] = useState<boolean>(false);
    const [payload, setPayload] = useState<any>(null);
    const [reclaimableRowData, setReclaimableRowData] = useState<any>([]);
    const [taxRowData, setTaxRowData] = useState<any>([]);
    const [previousReclaimableRowData, setPreviousReclaimableRowData] = useState<any>([]);
    const [refId, setRefId] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [allBills, setAllBills] = useState<any>([]);
    const [billNo, setBillNo] = useState<any>([]);

    const totalPages = 3;
    const reqInfo = { RequestInfo: requestInfo() };

    const { t } = useTranslation();
    let user = localStorage.getItem('currentUserAssignment');
    const [searchParams] = useSearchParams();
    const applicationNumber: any = searchParams.get('applicationNumber');
    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const navigate = useNavigate();
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const action: any = searchParams.get('action');
    const eSignTried: boolean = JSON.parse(searchParams.get('eSignTried') || 'false');
    const success: boolean = JSON.parse(searchParams.get('success') || 'true');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            measurementEntry: [measurmentInitialField],
            esignOption: 'DSC',
            totalRoyaltyOnMinerals: '0',
            alreadyPaidRoyaltyAmount: '0',
            adjustedRoyaltyAmount: '0',
            isFinalBill: false
        },
        onSubmit: () => {},
        validationSchema: addPopUp ? MB_AND_BILL_MEASUREMENT_VALIDATION : MB_AND_BILL_VALIDATION
    });
    const { values, setValues, errors, setFieldTouched, setTouched, setFieldValue } = formik;

    useEffect(() => {
        dprDropdownDataOptions()
            .then((dropdownOption: any) => {
                setDropdownData(dropdownOption);
            })
            .catch(e => {
                console.log(e);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            });

        (async () => {
            await SearchRaBillDetails();
            // await raBillTypeDropDown();

            if (applicationNumber) {
                try {
                    const nextActions = await POST(endpoints.dprValidActions + `?businessId=${applicationNumber}&tenantId=${TENANT_ID}`, {
                        RequestInfo: requestInfo()
                    });
                    setNextValidAction(nextActions.data);
                    setFieldValue('isUpdatable', !isViewOnly ? nextActions.data.isUpdatable : !isViewOnly);

                    const historyData = await getHistory(applicationNumber);
                    setHistoryDetails(historyData);
                } catch (e) {
                    console.log(e);
                }
            }
        })();
    }, []);

    useEffect(() => {
        if ((activeIndex === 2 && documentDetails) || isViewOnly) {
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails?.length - ind}` };
                });
                setTableData(responseTableData);
            };

            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                // setIsLoading(true);
                const { data } = await POST(endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber}&businessService=${nextValidAction?.businessService}`, {
                    RequestInfo: requestInfo()
                });
                setDocumentDetails(data?.documents);

                const relatedApplicationDocument = await POST(endpoints.worksRelatedDocument + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                setRelatedApplicationDocument(relatedApplicationDocument);

                // setIsLoading(false);
            } catch (e: any) {
                console.log(e);
                // setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (nextValidAction) {
            getCorrespondenceDocument();
        }
    }, [nextValidAction]);

    // const raBillTypeDropDown = async () => {
    //     try {
    //         const { data } = await POST(endpoints.raBillType + `?contractApplicationNos=${applicationNumber}`, reqInfo);
    //         const RaBillDropDown = data?.billTypeList.map((val: any) => {
    //             return { name: val, code: val };
    //         });
    //         setBillName(data);
    //         setDropdownData((prev: any) => ({ ...prev, billType: RaBillDropDown }));
    //     } catch (e) {}
    // };
    const SearchRaBillDetails = async () => {
        try {
            const { data } = await POST(endpoints.searchRaBill + `?applicationNos=${applicationNumber}`, reqInfo);
            const resData = data.raBillDetails[0];
            setResData(resData);
            setContent(resData.content);
            setMopContent(resData.mopContent);
            setIsLoading(false);

            // for getting existing bills from contract number
            const {
                data: { raBillDetails }
            } = await POST(endpoints.searchRaBill + `?contractApplicationNos=${resData.contractDetails.applicationNo}&isSortedCreatedDateRequired=true`, {
                RequestInfo: requestInfo()
            });
            const currentBillIndex = raBillDetails.findIndex((item: any) => item.applicationNo === applicationNumber);
            const billsTillNow = raBillDetails.slice(0, currentBillIndex + 1);

            setAllBills(billsTillNow);
            const previousBillTotaMeasuredValue = billsTillNow.length > 1 ? billsTillNow[billsTillNow.length - 2]?.totalMeasuredValue : 0;

            setPreviousReclaimableRowData(
                resData.previousReclaimableDeduction
                    ? [
                          {
                              name: { code: 'SECURITY_DEPOSITE', name: 'Security Deposite' },
                              type: { code: 'AMOUNT', name: 'Amount' },
                              rate: null,
                              amount: resData.previousReclaimableDeduction
                          }
                      ]
                    : []
            );
            // setFieldValue('billNo', billsTillNow.length);
            setFieldValue('previousBillTotaMeasuredValue', previousBillTotaMeasuredValue);
            setFieldValue('compensation', data.raBillDetails[0].contractDetails.contractExtensionDetails?.compensationAmount);
            setFieldValue('isCompensationAdjusted', data.raBillDetails[0].contractDetails.contractExtensionDetails?.isCompensationAdjusted);
        } catch (e: any) {
            console.log(e);
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (resData) {
                    setIsLoading(true);
                    const abstractData = resData?.contractDetails?.workOrderDetails?.loaDetails?.dprHeaderDetails?.dprItemModelList;
                    const abstractRowData = abstractData?.map((val: any) => {
                        const measType = getDropdownSelectedObject(val?.measurementType, dropdownData['measurementType']);

                        return {
                            ...val,
                            itemNo: val.itemOrderNo,
                            gsrItemNo: val?.gsrMasterDetailsModel?.itemNo,
                            cost: val?.itemCost,
                            itemType: val?.gsrMasterDetailsModel?.itemNo ? 'Scheduled' : 'Non-Scheduled',
                            measurementType: measType?.name,
                            rowId: val?.uuid
                        };
                    });
                    setAbstractData(abstractRowData);
                    const measuredItem = resData?.measuredRaBillItemDetails?.map((val: any) => {
                        const measType = getDropdownSelectedObject(val?.dprItemDetails?.measurementType, dropdownData['measurementType']);
                        return {
                            ...val,
                            itemNo: val.dprItemDetails?.itemOrderNo,
                            itemDesc: val?.dprItemDetails?.itemDesc,
                            unitRate: val?.dprItemDetails?.unitRate,
                            gsrItemNo: val?.dprItemDetails?.gsrMasterDetailsModel?.itemNo,
                            measurementType: measType?.name,
                            rowId: val.uuid
                        };
                    });
                    setNewMeasurmentData(measuredItem || []);
                    const raBillRowData = resData?.pendingRaBillItemDetails?.map((val: any) => {
                        const measType = getDropdownSelectedObject(val?.dprItemDetails?.measurementType, dropdownData['measurementType']);
                        const newUuid = uuidv4();
                        return {
                            ...val,
                            itemNo: val.dprItemDetails?.itemOrderNo,
                            gsrItemNo: val?.dprItemDetails?.gsrMasterDetailsModel?.itemNo,
                            cost: val?.dprItemDetails?.itemCost,
                            itemType: val?.dprItemDetails?.gsrMasterDetailsModel?.itemNo ? 'Scheduled' : 'Non-Scheduled',
                            itemDesc: val?.dprItemDetails?.itemDesc,
                            unitRate: val?.dprItemDetails?.unitRate,
                            type: measType?.name,
                            totalQuantity: val?.dprItemDetails?.totalQuantity,
                            rowId: newUuid
                        };
                    });
                    setRaBillRowData(raBillRowData);
                    const initData = await setDprHeaderData(resData?.contractDetails?.workOrderDetails?.loaDetails?.dprHeaderDetails, dropdownData);
                    const headerInitialValue = await tenderDetails(resData?.contractDetails?.workOrderDetails?.loaDetails, dropdownData);
                    const loaInitValue = await loaDetailValues(resData?.contractDetails?.workOrderDetails?.loaDetails);
                    const contractFieldsInitVal = await HeaderInitialValues(resData?.contractDetails);
                    const worksInitValue = {
                        workOrderNumber: resData?.contractDetails?.workOrderDetails?.workOrderNo,
                        workOrderDate: resData?.contractDetails?.workOrderDetails?.workOrderDate,
                        ...contractFieldsInitVal
                    };

                    const paidRoyaltyDoc = resData.paidRoyaltyRefDocId ? await entityDocumentData(resData.paidRoyaltyRefDocId) : null;
                    const reclaimableRow = resData.billDeductionDetails?.filter((item: any) => item.isReclaimable);
                    const formattedReclaimableTable = getFormattedReclaimableTable(reclaimableRow, dropdownData);
                    setReclaimableRowData(formattedReclaimableTable);

                    if (resData.billDeductionDetails.length) {
                        const taxData = resData.billDeductionDetails.filter((item: any) => !item.isReclaimable);
                        const taxRows = getFormattedReclaimableTable(taxData, taxTypes);
                        setTaxRowData(taxRows);
                    }

                    let calculations = getTotalMeasurementCalculations(measuredItem);
                    setValues((prev: any) => ({
                        ...prev,
                        ...resData,
                        ...headerInitialValue,
                        ...loaInitValue,
                        ...worksInitValue,
                        ...initData,
                        ...calculations,
                        paidRoyaltyDoc: paidRoyaltyDoc
                        // billNo: values.billNo || resData.billNo
                    }));

                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        })();
    }, [resData]);

    useEffect(() => {
        if (allBills.length && resData) {
            // setFieldValue('billNo', allBills.length);
            setBillNo(allBills.length);
        }
    }, [allBills, resData, values.totalMeasuredValue]);

    const getTotalMeasurementCalculations = (rowData: any) => {
        const totalMeasuredValueBeforeAdjustment = rowData.reduce((acc: any, curr: any) => (acc = acc + +curr.measuredValue), 0);

        const contractorQuoteDeviationAmount = +resData?.contractDetails?.workOrderDetails?.loaDetails?.deviationPercentage * 0.01 * totalMeasuredValueBeforeAdjustment;

        const subTotal =
            resData?.contractDetails?.workOrderDetails?.loaDetails?.bidAmountDeviation === 'Above'
                ? +totalMeasuredValueBeforeAdjustment + +contractorQuoteDeviationAmount
                : +totalMeasuredValueBeforeAdjustment - +contractorQuoteDeviationAmount;

        const rebateAmount = +resData?.contractDetails?.rebatePercentage * 0.01 * subTotal || 0;

        const totaMeasuredValue = subTotal - rebateAmount;
        // const netValueOfWork = values.previousBillTotaMeasuredValue ? +totaMeasuredValue - +values.previousBillTotaMeasuredValue : totaMeasuredValue;

        return {
            totalMeasuredValueBeforeAdjustment: +totalMeasuredValueBeforeAdjustment.toFixed(2),
            contractorQuoteDeviationAmount: +contractorQuoteDeviationAmount.toFixed(2),
            subTotal: +subTotal.toFixed(2),
            lessRebateAmount: +rebateAmount.toFixed(2),
            totalMeasuredValue: +totaMeasuredValue.toFixed(2)
            // netValueOfWork: +netValueOfWork.toFixed(2)
        };
    };

    useEffect(() => {
        const totalCurrBillAmount = reclaimableRowData.reduce((acc: any, curr: any) => (acc = acc + strToNum(curr.amount)), 0);
        setFieldValue('totalReclaimableDeductionAmount', +totalCurrBillAmount.toFixed(2));

        const totalNonReclaimable = taxRowData.reduce((acc: any, curr: any) => (acc = acc + strToNum(curr.amount)), 0);
        setFieldValue('totalNonReclaimableDeductionAmount', +totalNonReclaimable.toFixed(2));

        const netPayable = +values.passedBillAmount - (+totalNonReclaimable + +totalCurrBillAmount);
        setFieldValue('totalUpToDatePaymentSinceLastBill', +netPayable.toFixed(2));

        setFieldValue('totalUpToDatePayment', +netPayable.toFixed(2) + +values.totalUpToDatePaymentTillLastBill);
    }, [reclaimableRowData, taxRowData]);

    useEffect(() => {
        if (values?.isFinalBill && billNo == '0') {
            setFieldValue(`billName`, `First & Final Bill`);
        } else if (values?.isFinalBill && billNo != '0') {
            setFieldValue(`billName`, `Running Bill ${+billNo} & Final Bill`);
        } else {
            setFieldValue(`billName`, `Running Bill ${+billNo}`);
        }
    }, [values.isFinalBill, billNo]);

    useEffect(() => {
        if (note && action && nextValidAction && signedFileStoreId) {
            setFieldValue('notes', note);
            if (eSignTried && !success) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('E_SIGN_FAILED', 'e-Sign Failed'), img: cross, type: 'error', reUpload: 'OK' });
            } else if (success) {
                handelPrimaryAction({ action: action }, note);
            }
        }
    }, [nextValidAction]);

    const signDocument = () => {
        const eSignParam = new URLSearchParams(searchParams);
        eSignParam.delete('note');
        eSignParam.delete('action');
        eSignParam.delete('eSignTried');
        eSignParam.delete('signedFileStoreId');
        eSignParam.delete('success');
        let url = window.location.origin + `/goa-idc/running-account-bill?${eSignParam.toString()}&note=${values.notes}&action=${actionAfterEsign}&eSignTried=true`;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
    };

    console.log(formik);

    const handleNextPage = async () => {
        try {
            const { data } = await POST(endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber}&businessService=${nextValidAction?.businessService}`, {
                RequestInfo: requestInfo()
            });
            setDocumentDetails(data?.documents);
        } catch (e) {}
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { replace: true, state: { row } });
    };
    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        if (applicationNumber) {
            try {
                const docs: any =
                    file.referenceId &&
                    (await onAttachDocument(
                        applicationNumber,
                        file?.referenceId,
                        designationName,
                        nextValidAction ? nextValidAction?.businessService : null,
                        historyDetails.length ? historyDetails[0].id : null,
                        true
                    ));
                setDocumentDetails(docs.documents);
                setDocumentsUuid([...documentsUuid, docs.uuid]);
                setActiveIndex(2);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const InitiateMbAndBill = async (initiatePayload: any) => {
        try {
            !signedFileStoreId && (await MB_AND_BILL_VALIDATION.validate(values, { abortEarly: false, context: values }));
            try {
                const response = await POST(endpoints.worksWorkflowInitiate, initiatePayload);
                if (response) {
                    const successMessage = getSuccessMessage(action ? action : initiatePayload?.ProcessInstances?.[0]?.action, applicationNumber);
                    setModalDetails({ show: true, title: 'Success', para: successMessage, img: check, type: 'success', reUpload: 'OK' });
                }
            } catch (e: any) {
                console.log(e);
                let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            console.log(validationError);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const handelPrimaryAction = async (action: any, comment: any) => {
        const balanceAmountIsNonZero = newMeasurmentData.some((val: any) => {
            return val.balanceQuantity != 0;
        });
        console.log(balanceAmountIsNonZero);

        const initiatePayload = {
            ...reqInfo,
            raBillDetailRequest: {
                raBillDetails: !signedFileStoreId
                    ? {
                          ...resData,
                          ...values,
                          mopDocReferenceId: signedFileStoreId ? signedFileStoreId : resData?.mopDocReferenceId,
                          rebateAmount: values.lessRebateAmount,
                          paidRoyaltyRefDocId: values.paidRoyaltyDoc?.docReferenceId,
                          contractClosureDate: values.contractClosureDate ? convertDateToDDmmYYYY(values.contractClosureDate) : null,
                          netPayableAmount: values?.totalUpToDatePaymentSinceLastBill,
                          totalDeductionAmount: +(+values?.totalNonReclaimableDeductionAmount + +values?.totalReclaimableDeductionAmount).toFixed(2),
                          isMOPDocGenerationRequired: resData.status === 'RA_BILL_GENERATED' && values.isUpdatable,
                          netValueOfWork: +(+values.totalMeasuredValue - +values.previousBillTotaMeasuredValue).toFixed(2),
                          billNo: billNo
                      }
                    : resData
            },
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: action?.action,
                    notes: values.notes || note,
                    comment: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean)
        };
        setPayload(initiatePayload);
        if (!values.notes && !note) {
            setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        if (nextValidAction?.isUpdatable && resData.status === 'PENDING') {
            if (values.isFinalBill && (resData?.pendingRaBillItemDetails.length > 0 || balanceAmountIsNonZero)) {
                setItemMeasured(true);
                setReminderPopUp(true);
                return;
            }
            // else if (values.billType && !balanceAmountIsZero) {
            //     setItemMeasured(true);
            //     setReminderPopUp(true);
            //     return;
            // }
        }

        if ((!signedFileStoreId && action.isEsignRequired) || !success) {
            await RAUpdate(true);
            setActionAfterEsign(action.action);
            setEsignModal(true);
            return;
        }
        // values?.isUpdatable && (await RAUpdate(true));

        await InitiateMbAndBill(initiatePayload);
    };

    const RAUpdate = async (isEsignUpdate?: boolean) => {
        const reclaimable = reclaimableRowData.map((item: any) => ({
            ...item,
            name: item.name.code,
            type: item.type.code,
            billId: resData.uuid,
            amount: strToNum(item.amount),
            isReclaimable: true
        }));
        const nonReclaimable = taxRowData.map((item: any) => ({
            ...item,
            name: item.name.code,
            type: item.type.code,
            billId: resData.uuid,
            amount: strToNum(item.amount),
            isReclaimable: false
        }));

        const payload = {
            ...reqInfo,
            raBillDetails: {
                ...resData,
                ...values,
                billDeductionDetails: [...reclaimable, ...nonReclaimable],
                rebateAmount: values.lessRebateAmount,
                paidRoyaltyRefDocId: values.paidRoyaltyDoc?.docReferenceId,
                isMOPDocGenerationRequired: resData.status === 'RA_BILL_GENERATED' && values.isUpdatable,
                contractClosureDate: values.contractClosureDate ? convertDateToDDmmYYYY(values.contractClosureDate) : null,
                netPayableAmount: values?.totalUpToDatePaymentSinceLastBill,
                totalDeductionAmount: +(+values?.totalNonReclaimableDeductionAmount + +values?.totalReclaimableDeductionAmount).toFixed(2),
                netValueOfWork: +(+values.totalMeasuredValue - +values.previousBillTotaMeasuredValue).toFixed(2),
                billNo: billNo
            }
        };
        setTouched({});
        try {
            if (!previousReclaimableRowData.length && !reclaimableRowData.length && resData.status === 'RA_BILL_GENERATED' && values.isUpdatable) {
                throw new Error('Validation failed: Reclaimable row data is not present.');
            }

            await MB_AND_BILL_VALIDATION.validate(values, { abortEarly: false, context: values });
            try {
                const { data } = await POST(endpoints.updateRaBill, payload);
                setResData(data.raBillDetails[0]);
                setRefId(data.raBillDetails[0]?.mopDocReferenceId);
                setContent(data.raBillDetails[0].content);
                setMopContent(data.raBillDetails[0].mopContent);
                isEsignUpdate !== true && setModalDetails({ show: true, title: 'Success', para: 'Data Saved Successfully', img: check, type: 'save success', reUpload: 'OK' });
            } catch (e: any) {
                console.log(e);
                let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                console.log(errorField);
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={isViewOnly ? t('MB_BILL_VIEW', 'MB & Bill View') : t('MB_AND_BILL', 'MB & Bill')}
                            additionalClass='sub-text-style'
                            bottomSubText={`${isViewOnly ? `` : `Type : MB & Bill Application,`} No : ${applicationNumber} `}
                        />

                        <FormikProvider value={formik}>
                            {!isViewOnly ? (
                                <div className='col-sm-3 mt-3 stickyCard-section '>
                                    <div className='stickyCard' style={{ top: '15%' }}>
                                        <GenericHeader
                                            text={t('MB_AND_BILL', 'MB & Bill')}
                                            sideHeader={true}
                                            additionalClass='sub-text-style'
                                            bottomSubText={`Type : MB & Bill Application, No : ${applicationNumber} `}
                                        />

                                        <NotingWrapper
                                            designation={designation}
                                            applicationNumber={applicationNumber}
                                            history={historyDetails}
                                            disable={!nextValidAction?.nextValidAction?.length}
                                            handleOnFileUpload={handleOnFileUpload}
                                            nextValidAction={nextValidAction}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}

                            <div className={`${isViewOnly ? 'col-sm-12' : 'col-sm-9'} mt-3 form-section`}>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} isViewOnly={isViewOnly} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <RunningAccountBillCorrespondence
                                                t={t}
                                                dropdownData={dropdownData}
                                                abstractData={abstractData}
                                                newMeasurmentData={newMeasurmentData}
                                                raBillRowData={raBillRowData}
                                                setRaBillRowData={setRaBillRowData}
                                                applicationNumber={applicationNumber}
                                                searchRaBill={SearchRaBillDetails}
                                                setReminderPopUp={setReminderPopUp}
                                                content={content}
                                                setContent={setContent}
                                                setModalDetails={setModalDetails}
                                                addPopUp={addPopUp}
                                                setAddPopUp={setAddPopUp}
                                                isViewOnly={isViewOnly}
                                                tableData={tableData}
                                                onRowClick={onRowClick}
                                                isUpdatable={nextValidAction?.isUpdatable}
                                                reclaimableRowData={reclaimableRowData}
                                                setReclaimableRowData={setReclaimableRowData}
                                                taxRowData={taxRowData}
                                                setTaxRowData={setTaxRowData}
                                                resData={resData}
                                                previousReclaimableRowData={previousReclaimableRowData}
                                                setPreviousReclaimableRowData={setPreviousReclaimableRowData}
                                                setMopContent={setMopContent}
                                                mopContent={mopContent}
                                                setIsLoading={setIsLoading}
                                                billNo={billNo}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tableData} additionalMethod={onRowClick} />
                                        )}
                                        {activeIndex === 3 && (
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            {!isViewOnly ? (
                                <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                    <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                        <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                    </span>
                                    Page {activeIndex} of {totalPages}
                                    <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                        <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                    </span>
                                </div>
                            ) : (
                                <></>
                            )}

                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    {!isViewOnly ? (
                        <GenericFooterComponent
                            activeIndex={0}
                            stepperLength={0}
                            handleOnPrimaryAction={handelPrimaryAction}
                            onSubmit={() => {}}
                            nextValidAction={nextValidAction}
                            extraButton={nextValidAction?.isUpdatable ? 'Save' : false}
                            extraButtonAction={RAUpdate}
                        />
                    ) : (
                        <></>
                    )}

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <GenericPopupModal
                        secondaryAction={() => {
                            setReminderPopUp(false);
                            setItemMeasured(false);
                        }}
                        primaryAction={() => {
                            setReminderPopUp(false);
                            itemMeasured ? InitiateMbAndBill(payload && payload) : RAUpdate();
                        }}
                        isVisible={reminderPopUp}
                        title='Save Confirmation'
                        size='md'
                        children={
                            <div style={{ background: '#F8F8F8' }}>
                                <div className='mx-4 my-3'>
                                    <div className='py-4'>
                                        <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                            {itemMeasured
                                                ? t('ITEM_NOT_MEASURED', 'All the items are not measured. Do you want to proceed?')
                                                : t('SAVE_CHANGES', 'Looks like you have some unsaved changes. Would you like to save them before proceeding with the new data?')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        primaryBtnTitle='Yes'
                        secondaryBtnTitle='No'
                        // onClose={() => setReminderPopUp(false)}
                    />
                </>
            )}
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
        </>
    );
};

export default RunningAccountBillWorkflow;
