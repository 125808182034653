import { Card, Form } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CHECKLIST_TABLE, paymentColumns } from '../../Allotment/Allotment.const';
import { t } from 'i18next';
import {
    CHANGE_DETAILS,
    CHANGE_IN_NAME,
    DROPDOWN_CHANGE,
    MISC_CORRESPONDENCE_CARD,
    MISCELLANEOUS_SELF_DECLARATION_LIST,
    OWNERSHIP_CHANGE_DOCUMENTS,
    TRADE_CHANGE_DOCUMENTS
} from '../Miscellaneous.cons';
import { useEffect, useState } from 'react';
import LesseeDetails from '../../SubLease/LesseeDetails';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import { useFormikContext } from 'formik';
import GenericOwnerShipDetails from '../../../../../components/Ownership/GenericOwnership';
import ProposedActivityComponent from '../../Allotment/ProposedActivityDetails';
import Declaration from '../../Allotment/Declaration';
import { WorkflowPaymentDetails } from '../../WorkflowPaymentDetails';
import Editor from '../../../../../components/ContentEditor';
import { PLOT_TABLE_COLUMNS } from '../../SpecialTransfer/SpecialTransfer.const';

export const MiscellaneousWorkflowCorrespondence = ({
    content,
    setContent,
    searchResponse,
    checkListRowData,
    controllingOwnership,
    ownershipDetails,
    setOwnershipDetails,
    setShowOwnershipModal,
    showOwnershipModal,
    nicDetails,
    setNicDetails,
    showPopUp,
    setShowPopUp,
    isChecked,
    transactionDetails,
    paymentRowData,
    nextValidAction,
    selectedPlotDataId,
    updatedRowData,
    column
}: any) => {
    const [openSection, setOpenSection] = useState<string>('lesseeDetails');

    const { values } = useFormikContext<any>();

    const changesContent =
        content && Array.isArray(content)
            ? content?.map((item: any, index: number) => ({
                  title: `F${index + 1} - Change Order Notes - ${index + 1}`,
                  field: `changeOrderNotesDetails${index + 1}`
              }))
            : [];

    console.log('changesContent', changesContent);

    const correspondence = [...MISC_CORRESPONDENCE_CARD, ...changesContent];

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const renderLesseeDetails = () => {
        return (
            <>
                <Card.Body>
                    <LesseeDetails />
                </Card.Body>
            </>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <Card.Body>
                    <div className='ps-2 py-2 d-flex justify-content-between'>
                        <div className='formTitle m-0'>{t('PLOT_DETAILS', ' Plot Details')}</div>
                    </div>
                    <div className='pb-2'>
                        <GenericActionTable columns={column} searchBarPlaceholder={'SEARCH_BY_PLOT'} rowData={updatedRowData} hideExportBtn={true} />
                    </div>
                </Card.Body>
            </>
        );
    };

    const renderChangeDetails = () => {
        return (
            <>
                <Card.Body>
                    {CHANGE_DETAILS?.map((val: any) => (
                        <GenericFormCard {...val} dropdownOptions={{ natureOfChange: DROPDOWN_CHANGE }} />
                    ))}

                    {searchResponse?.applicationSubType === 'CHANGE_IN_OWNERSHIP_INTEREST' ? (
                        <>
                            <GenericOwnerShipDetails
                                showOwnershipModal={showOwnershipModal}
                                setShowOwnershipModal={setShowOwnershipModal}
                                setOwnershipDetails={setOwnershipDetails}
                                ownershipDetails={ownershipDetails}
                                hideCard={true}
                                isDisabled={true}
                                controllingOwnershipResponse={controllingOwnership}
                                isWorkflow={true}
                            />
                            <Card.Body>
                                {OWNERSHIP_CHANGE_DOCUMENTS?.map((val: any) => (
                                    <GenericFormCard {...val} />
                                ))}
                            </Card.Body>
                        </>
                    ) : searchResponse?.applicationSubType === 'CHANGE_IN_NAME' ? (
                        <>
                            {CHANGE_IN_NAME?.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}
                        </>
                    ) : searchResponse?.applicationSubType === 'CHANGE_IN_TRADE' ? (
                        <>
                            <ProposedActivityComponent isDisabled={true} nicDetails={nicDetails} setNicDetails={setNicDetails} showPopUp={showPopUp} setShowPopUp={setShowPopUp} />
                            {TRADE_CHANGE_DOCUMENTS?.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                </Card.Body>
            </>
        );
    };

    const renderDeclarationDetails = () => {
        return (
            <>
                <Card.Body>
                    <Declaration
                        isChecked={isChecked}
                        hideCard={true}
                        declarationList={MISCELLANEOUS_SELF_DECLARATION_LIST}
                        fileStoreId={searchResponse?.signedConsolidatedDocRefId}
                        applicantName={'Lessee'}
                        isDisabled={true}
                    ></Declaration>
                </Card.Body>
            </>
        );
    };

    const renderPaymentDetails = () => {
        return (
            <>
                <div className='px-4 py-4'>
                    <WorkflowPaymentDetails transactionDetails={transactionDetails} paymentColumns={paymentColumns} paymentRowData={paymentRowData} />
                </div>
            </>
        );
    };

    const renderChangeMultiNoteDetails = (item: any, index: number) => {
        const setCustomContent = (data: any) => {
            let updatedContent = content?.filter((contentItem: any) => contentItem.plotId !== item.plotId);
            updatedContent.splice(index, 0, { ...item, content: data });
            setContent(updatedContent);
        };

        console.log('content', content);

        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setCustomContent} content={content[index].content} readOnly={!nextValidAction?.isUpdatable} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };
    console.log('column', column);

    const renderChangeNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setContent} content={content} readOnly={!nextValidAction?.isUpdatable} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row  my-3 px-3 py-2'>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                {t('CHECK_LIST', 'Condition/Check list')}
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {correspondence?.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A - Lessee Details' && openSection === 'lesseeDetails' && renderLesseeDetails()}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                {val.title === 'C - Change Details' && openSection === 'changeDetails' && renderChangeDetails()}
                                {val.title === 'D - Self Declaration' && openSection === 'selfDeclaration' && renderDeclarationDetails()}
                                {val.title === 'E - Payment Details' && openSection === 'paymentDetails' && renderPaymentDetails()}
                                {Array.isArray(content) &&
                                    content?.map((item: any, index: number) => {
                                        return (
                                            val.title === `F${index + 1} - Change Order Notes - ${index + 1}` &&
                                            openSection === `changeOrderNotesDetails${index + 1}` &&
                                            renderChangeMultiNoteDetails(item, index)
                                        );
                                    })}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};
