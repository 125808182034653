import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Button, Card } from 'react-bootstrap';
import { LESSEE_DETAILS, DOCUMENTS_DETAILS, OCCUPANCY_DETAILS, PLOT_DETAILS, FIELD_INSPECTION_DETAILS } from '../OrderCompletionFormPage.const';
import { Field, useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import PaymentSubmissionStatus from '../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../components/moduleCommonFormik/ProgressBar';
import GenericPopupModal from '../../../components/moduleCommonFormik/GenericModalPopup.component';
import CLASelectionTable from '../CLASelectionTable.component';
import { getCLAList } from '../OrderCompletionFormPage.controller';
import { RoleInterface } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import EmailNotification from '../../constructionLicense/RenderEmailUpdation.component';

interface OrderCompletionFormSubPageProps {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    index: number;
    // setFormikValues: React.Dispatch<any>;
    employeeList: any;
    formData: any;
    previewData: any;
    applicationNumber?: string;
    appStatus: boolean;
    showProcess: boolean;
    activeStepper: string;
    allchecked: any;
    setAllChecked: React.Dispatch<any>;
    bpaData: any;
    isCancelBtn: boolean;
    setShowProcess: React.Dispatch<React.SetStateAction<boolean>>;
    setEditorContent: Dispatch<SetStateAction<string>>;
    setIsSelectedBPA: React.Dispatch<React.SetStateAction<string>>;
    editorContent: string;
    setFormValues: Dispatch<
        SetStateAction<
            {
                emailId: string;
            }[]
        >
    >;
    formValues: {
        emailId: string;
    }[];
    allBPADetails: any;
    allEdcrDetails: any;
}

export default function FieldInspectionSubPage(props: OrderCompletionFormSubPageProps) {
    const { values, resetForm, setValues, setFieldValue } = useFormikContext<any>();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(true);
    const [auditTrial, setAuditTrial] = useState<any>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const [isMultiLessee, setIsMultiLessee] = useState<boolean>(false);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const isRoleMD = !user?.roles?.filter((role: RoleInterface) => role.code === 'MD').length;
    const navigate = useNavigate();

    function handleChange(e: any) {
        if (e.target.checked) {
            props?.setAllChecked([...props?.allchecked, e?.target?.value]);
        } else {
            props?.setAllChecked(props?.allchecked.filter((item: any) => item !== e.target.value));
        }
    }

    useEffect(() => {
        if (!!props?.allBPADetails) setIsMultiLessee(props?.allBPADetails.length >= 0);
    }, [props?.allBPADetails]);

    // useEffect(() => {
    //     try {
    //         if (!!props?.applicationNumber) getProgressBarData(props?.applicationNumber).then((res) => setProgressBarDetails(res.data));
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }, [props?.applicationNumber]);

    const proceedToBPA = () => {
        props.setIsSelectedBPA(values?.edcrNo);
        setIsMultiLessee(false);
    };

    const handelIsLesseeVisible = () => {
        setIsMultiLessee(false);
        navigate(-1);
    };

    // const getAuditData = () => {
    //     if (!props?.applicationNumber) return;
    //     const payload = { RequestInfo: requestInfo() };
    //     TechnicalServices.getAuditTrail(payload, props?.applicationNumber, "ga")
    //         .then((res) => {
    //             setAuditTrial(res?.data?.ProcessInstances);
    //         })
    //         .catch((err) => console.log(err));
    // }

    const clearData = () => {
        resetForm();
        // setValues(CLEARANCE_INITIAL_DATA);
    };

    // useEffect(() => {
    //     getAuditData();
    //     return () => { clearData() }
    // }, []);

    useEffect(() => {
        if (values?.occupancyType === 'FULL_OCCUPANCY') {
            setFieldValue('occupancy', '100');
        } else {
            setFieldValue('occupancy', '');
        }
    }, [values?.occupancyType]);

    const renderBuildingPlanDetails = () => {
        let modifiedArray = PLOT_DETAILS;
        if (!!props?.appStatus) {
            modifiedArray.map((v: any) => {
                v.formData.map((h: any) => (h.isDisabled = true));
            });
        }
        return (
            <>
                {modifiedArray.map(val => (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <GenericFormCard {...val} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const renderNotificationDetails = () => {
        return <EmailNotification formValues={props?.formValues} setFormValues={props?.setFormValues} isEditable={props?.bpaData?.editable} />;
    };

    const renderOccupancyDetails = () => {
        const occupancy_Details: any = FIELD_INSPECTION_DETAILS;
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {occupancy_Details.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderDeclarationDetails = () => {
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        <div className='row ps-2 py-2'>
                            <div className='formTitle m-0'>
                                {'Self Declaration'}
                                <span className='text-danger'>*</span>
                            </div>
                        </div>
                        <div className='row d-flex m-0'>
                            {/* <Button variant="bg-light outline-success btn btn-light font-karla-med p-10" onClick={() => { setIsShow(true) }} style={{ borderColor: "#45852e", color: "#45852e", width: "max-content", borderRadius: 8 }} >
                                Sign the Document
                            </Button> */}
                            <p className='fw-bold'>The Lessee does hereby affirm and declare that:</p>

                            <label className='mb-3 checkbox-container'>
                                {props.appStatus ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={props.appStatus} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='1' name='step_1' onChange={handleChange} className='me-2' />
                                )}
                                <span className='checkmark'></span>
                                <span>
                                    The information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed
                                    herein.
                                </span>
                            </label>
                            <label className='mb-3 checkbox-container'>
                                {props.appStatus ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={props.appStatus} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='2' className='me-2' name='step_2' onChange={handleChange} />
                                )}
                                <span className='checkmark'></span>
                                <span>
                                    It has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation
                                    proceedings.
                                </span>
                            </label>
                            <label className='mb-3 checkbox-container'>
                                {props.appStatus ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={props.appStatus} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='3' name='step_3' onChange={handleChange} className='me-2' />
                                )}
                                <span className='checkmark'></span>
                                <span>
                                    The technical clearance, construction license, as the case may be, shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the
                                    information submitted under or along with this application.
                                </span>
                            </label>
                        </div>
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderDocumentDetails = () => {
        let modifiedArray = DOCUMENTS_DETAILS;
        if (!!props?.appStatus) {
            modifiedArray.map((v: any) => {
                v.formData.map((h: any) => (h.isDisabled = true));
            });
        }
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {modifiedArray.map(val => (
                            <GenericFormCard {...{ ...val }} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderBPASelection = () => {
        if (!props.allBPADetails || props.allBPADetails.length <= 0)
            return (
                <>
                    <p className='text-center fw-bold pt-2'>No Data Available</p>
                </>
            );
        const bpaList = getCLAList(props?.allBPADetails);
        return (
            <CLASelectionTable
                data={bpaList}
                selectedRow={values?.edcrNo}
                onSelectRow={row => {
                    setFieldValue('edcrNo', row?.value);
                    setFieldValue('percentage', row?.appliedCount);
                }}
            />
        );
    };
    const applicationData = {
        applicationNo: props?.applicationNumber,
        status: props?.previewData?.status,
        data: auditTrial,
        isRoleMD: true,
        bpaData: props?.bpaData,
        pendingWith: 'Manager'
    };
    return (
        <>
            {!!props.applicationNumber && (
                <Card className='card_border my-2'>
                    <Card.Body>
                        <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                    </Card.Body>
                </Card>
            )}
            {props?.activeStepper === 'INSPECTION_DETAILS' && renderOccupancyDetails()}
            {props?.activeStepper === 'NOTIFICATION_DETAILS' && renderNotificationDetails()}
        </>
    );
}
