import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';

import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import { QUERY_DETAILS, QUERY_DETAILS_SECOND, QUERY_ENTRY_CORRESPONDENCE, QUERY_RESPONSE_SECOND, QUERY_TABLE_COLUMNS } from '../Query.const';
import { OwnershipTable } from '../../modules/UserManagement/EnterpriseModule/OwnershipTable';
import PlotDetails from '../../modules/Migration/pages/PlotDetails';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import { useTranslation } from 'react-i18next';

const QueryEntryCorrespondence = ({
    dropdownData,
    dropdownDataPlot,
    setDropdownDataPlot,
    disabled,
	tabelDocData,
	showDocument,
	applicationStatus
}: any) => {
    const { t } = useTranslation();
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSection, setOpenSection] = useState<string>('queryDetails');

    const { values, touched, errors, setFieldValue } = useFormikContext<any>();

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const workflowCorrespondence = applicationStatus === 'APPROVED'
        ? [
              ...QUERY_ENTRY_CORRESPONDENCE,
              {
                  title: 'B - Response',
                  field: 'response'
              }
          ]
        : QUERY_ENTRY_CORRESPONDENCE;

    const renderPlotDetails = () => {
        return (
            <>
                    <div className='formTitle ps-2 my-2'>{'Plot Details'}</div>
                <PlotDetails dropdownData={dropdownDataPlot} plotOtherDetails={false}  isFieldDisable={disabled} setDropdownData={setDropdownDataPlot} hideCard={true} />
            </>
        );
    };
    const queryDetails = () => {
        return (
            <>
                <div className='px-3'>
                    {QUERY_DETAILS.map((val: any) => (
                        <GenericFormCard {...val} dropdownOptions={dropdownData} />
                    ))}

                    {values.queryType?.code === 'PLOT' && renderPlotDetails()}

                    <div className=' py-2 d-flex flex-column justify-content-between'>
                        <div className='ps-2 formTitle m-0'>{t('QUERY_DETAILS', 'Query Details')}</div>
                        {QUERY_DETAILS_SECOND.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                        <div>
                            <OwnershipTable tableRowData={tabelDocData} column={QUERY_TABLE_COLUMNS} hideSearchBar={true} additionalMethod={showDocument} />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const renderResponse = () => {
        return (
            <>
                <div className='px-3'>

                {QUERY_RESPONSE_SECOND.map((val: any) => (
                    <GenericFormCard {...val} />
                ))}
				</div>
            </>
        );
    };
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {workflowCorrespondence.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A - Query Details' && openSection === 'queryDetails' && queryDetails()}
                                {val.title === 'B - Response' && openSection === 'response' && renderResponse()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default QueryEntryCorrespondence;
