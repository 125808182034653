import { FormikProvider, useFormik } from 'formik';
import { SetStateAction, useEffect, useState } from 'react';
import { Card, CardSubtitle, Form } from 'react-bootstrap';
import '../../features/modules/EstateManagement/EstateManagement.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import HeaderComponent from '../../components/header/Header.component';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import GenericBackButton from '../../components/UI components/Buttons/GenericBackButton';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import SideCard from '../../components/moduleCommonFormik/SideCardGenericComponent';
import ScrollToTop from '../../components/ScrollToTop';
import { LEASE_DEED_STEPS_DETAILS_EMPLOYEE_LOGIN, LEASE_DEED_STEPS_DETAILS_ENTERPRISE_LOGIN, LESSEE_DETAILS, PLOT_DETAILS } from '../modules/EstateManagement/NOC/NOCManagement.const';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { USER_TYPE } from '../../utils/constants';
import { OpenFile, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { LeaseDeedForwardPopUp } from './LeaseDeedForwardPopUp';
import { leaseDeedValidation } from '../../utils/formikValidation/FormikValidation';
import { getLeaseDeedInitialData } from '../modules/EstateManagement/EstateManagement.controller';
import { POST, endpoints, devUrl, getProgressBarData, requestInfo } from '../../services/modulesServices/userManagement.api';
import GenericFooterComponent from '../../components/moduleCommonFormik/GenericFooter.component';
import Editor from '../../components/ContentEditor';
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import Loading from '../../components/portalComponents/Loading.component';
import GenericEmailIDNotification from '../../components/moduleCommonFormik/GenericEmailIDNotification.component';
import GenericFileUploadField from '../../components/moduleCommonFormik/GenericFileUpload.component';
import PaymentSubmissionStatus from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../components/moduleCommonFormik/ProgressBar';

export default function LeaseDeed() {
    const { t } = useTranslation();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const deedType = searchParams.get('deedType');
    const leaseApplicationNo = searchParams.get('applicationNumber');
    const navigate = useNavigate();

    const allotteDtails = LESSEE_DETAILS;
    const getUserType = JSON.parse(localStorage.getItem('type') || '');
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userDesignationMD = getUserDetails.designation === 'MD';
    const userDesignationLA = getUserDetails.designation === 'LA';

    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [applicationNo, setApplicationNo] = useState<number | string>('');
    const [resData, setResData] = useState<any>({});
    const [progressBarData, setProgressBarData] = useState<any>(null);
    const [isChecked, setIsChecked] = useState(false);
    const [content, setContent] = useState('');
    const approvedStatus = getUserType === USER_TYPE.EMP && userDesignationLA && resData.applicationStatus === 'APPROVED';

    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [commentValue, setCommentValue] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [formValues, setFormValues] = useState([{ emailId: '' }, { emailId: '' }]);

    const stepsForMD = [
        ...LEASE_DEED_STEPS_DETAILS_ENTERPRISE_LOGIN,
        {
            titleCode: 'PREVIEW',
            name: 'Preview',
            index: 4,
            isActive: false
        },
        {
            titleCode: 'REGISTRATION_PREVIEW',
            name: 'Registration preview',
            index: 5,
            isActive: false
        },
        {
            titleCode: 'NOTIFICATION_DETAILS',
            name: 'Notification Details',
            index: 6,
            isActive: false
        }
    ];
    const leaseDeedSteps =
        getUserType === USER_TYPE.ENTERPRISE
            ? LEASE_DEED_STEPS_DETAILS_ENTERPRISE_LOGIN
            : userDesignationMD
            ? stepsForMD
            : approvedStatus
            ? [
                  ...LEASE_DEED_STEPS_DETAILS_ENTERPRISE_LOGIN,
                  {
                      titleCode: 'DOCUMENTS',
                      name: 'Documents',
                      index: 4,
                      isActive: false
                  }
              ]
            : LEASE_DEED_STEPS_DETAILS_EMPLOYEE_LOGIN;

    const plotDetails = {
        plotNo: 'M1',
        estateName: 'MAPUSA',
        phase: 'I',
        propertyType: 'Industrial',
        plotArea: '1600'
    };
    const formik = useFormik<any>({
        initialValues: { isUpdatable: true },
        onSubmit: value => {},
        validationSchema: leaseDeedValidation
    });
    const { values, setValues, setFieldValue } = formik;

    useEffect(() => {
        if (!leaseApplicationNo) {
            const getLeasePlotDetails = async () => {
                try {
                    const initData = await getLeaseDeedInitialData(plotDetails, resData);
                    setValues((prev: any) => ({
                        ...prev,
                        ...initData
                    }));
                } catch (err: any) {
                    console.log(err);
                }
            };
            getLeasePlotDetails();
        }
    }, [resData]);

    useEffect(() => {
        if (leaseApplicationNo) {
            const leaseSearchData = async () => {
                try {
                    const nextAction = await POST(endpoints.nextValidAction + `?businessId=${leaseApplicationNo}&tenantId=ga`, { RequestInfo: requestInfo() });
                    setNextValidAction(nextAction.data);

                    const response = await POST(endpoints.leaseDeedSearch + `?applicationNumber=${leaseApplicationNo}`, { RequestInfo: requestInfo() });
                    setResData(response.data.deedDetails[0]);
                    setContent(response.data.deedDetails[0].content);

                    const resInitData = await getLeaseDeedInitialData(response.data.deedDetails[0].plotDetails, response.data.deedDetails[0]);
                    setValues((prev: any) => ({
                        ...prev,
                        ...resInitData
                    }));

                    if (userDesignationMD) {
                        const notificationEmailValues = response.data.deedDetails[0].additionalDetails.notificationDetails.emailIds.map((values: any) => {
                            return { emailId: values };
                        });
                        setFormValues(notificationEmailValues);
                    }
                } catch (e) {
                    console.log(e);
                }
            };
            leaseSearchData();
        }
    }, [leaseApplicationNo]);

    const onSubmit = async (changeIndex: boolean) => {
        // let pdfContent;
        // if (!isEqual(content, resData.content)) {
        //     pdfContent = content && `<!DOCTYPE html><html><body>${content.trim()}</body></html>`;
        // }
        if (activeIndex === 4 && isEqual(content, resData.content)) {
            toast(t('PLEASE_UPDATE_DRAFT_DEED', 'Please update the Draft Deed'), {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                type: 'warning'
            });
            return;
        }
        const isfinal = activeIndex === LEASE_DEED_STEPS_DETAILS_ENTERPRISE_LOGIN.length;
        let payload = {
            RequestInfo: requestInfo(),
            content: resData.content,
            applicationNumber: applicationNo || leaseApplicationNo,
            isFinalSubmit: isfinal
        };
        try {
            if (!applicationNo && !leaseApplicationNo) {
                setIsLoading(true);
                POST(`/idc-legal-service/legal/v1/deed/_create`, { RequestInfo: requestInfo(), deedType: 'LEASE_DEED' })
                    .then((response: any) => {
                        if (response.data) {
                            !isfinal && setApplicationNo(response.data.deedDetails[0].applicationNumber);
                            setResData(response.data.deedDetails[0]);
                            setIsLoading(false);
                        }
                        toast(t('APPLICATION_CREATED', 'Application created'), {
                            position: toast.POSITION.TOP_RIGHT,
                            className: 'foo-bar',
                            type: 'success'
                        });

                        changeIndex && onClickNext();
                    })
                    .catch(err => {
                        setIsLoading(false);
                        console.log(err);
                    });
            } else {
                setIsLoading(true);
                POST(`/idc-legal-service/legal/v1/deed/_update`, payload)
                    .then(res => {
                        if (res.data) {
                            setIsLoading(false);
                            if (isfinal) {
                                setIsLoading(false);

                                toast(t('ACTION_SUCCESS', 'Action Successful!'), {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar',
                                    type: 'success'
                                });
                                navigate('/goa-idc/enterprisedashboard');
                            } else {
                                setResData(res.data.deedDetails[0]);
                                setContent(res.data.deedDetails[0].content);
                                toast(t('DATA_SAVED', 'Data saved'), {
                                    position: toast.POSITION.TOP_RIGHT,
                                    className: 'foo-bar',
                                    type: 'success'
                                });
                                changeIndex && onClickNext(!!res.data.deedDetails[0].attachmentRefId);
                            }
                        }
                    })
                    .catch(err => {
                        setIsLoading(false);
                        toast(t('ACTION_UNSUCCESS', 'Action Unsuccessful!'), {
                            position: toast.POSITION.TOP_RIGHT,
                            className: 'foo-bar',
                            type: 'error'
                        });
                    });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleScroll = () => {
        if (window.scrollY > 110) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            if (activeIndex === 4 && !resData.attachmentRefId) {
                toast(t('PLEASE_UPDATE_DRAFT_DEED', 'Please update the Draft Deed'), {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo-bar',
                    type: 'warning'
                });
                return;
            }
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                toast(t('FILL_REQUIRED_DETAILS', 'Fill in all the required details'), {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo-bar',
                    type: 'warning'
                });
            }
        } else setActiveIndex(index);
    };

    const onClickNext = (isPrevLoad: boolean = false) => {
        if (activeIndex < leaseDeedSteps.length && activeIndex !== leaseDeedSteps.length) {
            // if (userDesignationMD ? activeIndex < leaseDeedSteps.length + 1 && activeIndex !== leaseDeedSteps.length + 1 : activeIndex < leaseDeedSteps.length && activeIndex !== leaseDeedSteps.length) {
            if (activeIndex === 4 && isPrevLoad !== true && !resData.attachmentRefId) {
                toast(t('PLEASE_UPDATE_DRAFT_DEED', 'Please update the Draft Deed'), {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo-bar',
                    type: 'warning'
                });
                return;
            }

            setActiveIndex(activeIndex + 1);
        }
        // if (userDesignationMD && activeIndex === 3) {
        //     setActiveIndex(5);
        // }
    };

    const onChangePrevIndex = () => {
        // const stepperLength = userDesignationMD ? leaseDeedSteps.length + 1 : leaseDeedSteps.length;
        const stepperLength = leaseDeedSteps.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
        }
        // if (userDesignationMD && activeIndex === 5) {
        //     setActiveIndex(3);
        // }
    };

    const handleLabelClick = (e: any) => {
        e.preventDefault();
        setIsChecked(!isChecked);
    };

    const handleOnPrimaryAction = async (selectedAction: any, comment: any) => {
        const emailRecepients = formValues.map((values: any) => {
            return values.emailId;
        });
        let payload = {
            RequestInfo: requestInfo(),
            isStateUpdatable: 'true',
            legalDeedRequest: [
                {
                    deedType: resData?.deedType,
                    content: content,
                    applicationNumber: resData?.applicationNumber
                }
            ],
            notificationEmailRecepients: selectedAction?.action === 'FORWARD' ? emailRecepients : undefined,
            signedDeedDocumentReferenceId: values.documents?.referenceId,
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: nextValidAction?.businessService,
                    businessId: leaseApplicationNo,
                    moduleName: nextValidAction?.moduleName,
                    action: selectedAction?.action,
                    comment: comment
                }
            ]
        };
        if (!comment) {
            toast('Please fill in the comment box!', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                type: 'warning'
            });
            return;
        }
        try {
            const response = await POST(`/idc-legal-service/legal/v1/deed/workflow/process/_initiate`, payload);
            const nameOfReceiver = response.data?.ProcessInstances[0]?.assignes?.[0]?.name;
            const msg = nameOfReceiver ? `${t('ACTION_SUCCESS_SENT', 'Action Successful! Application send to')} ${nameOfReceiver}` : `${t('ACTION_SUCCESS', 'Action Successful!')}`;
            toast(msg, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                type: 'success'
            });
            navigate('/goa-idc/dashboard');
        } catch (err) {
            toast(t('ACTION_UNSUCCESS', 'Action Unsuccessful! Try again'), {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                type: 'error'
            });
            console.log(err);
        }
    };
    const handleCommentValue = (data: any) => {
        setCommentValue(data);
    };

    const handleEmailValues = (emails: any) => {
        if (!userDesignationMD) setFormValues(emails);
    };

    useEffect(() => {
        try {
            if (!!approvedStatus) {
                getProgressBarData(leaseApplicationNo).then(res => setProgressBarData(res?.data));
            }
        } catch (err) {
            console.log(err);
        }
    }, [approvedStatus, leaseApplicationNo]);

    const leaseDeedPlotDetails = () => {
        const statusData = {
            applicationNo: resData?.applicationNumber,
            status: resData?.applicationStatus,
            pendingWith: 'self',
            data: progressBarData?.auditTrails
        };
        return (
            <>
                {!!approvedStatus && (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <PaymentSubmissionStatus data={statusData} progressBarDetails={progressBarData} children={<ProgressBar progressBarDetails={progressBarData} />} />
                        </Card.Body>
                    </Card>
                )}
                <Card className='card_border'>
                    <Card.Body>
                        {PLOT_DETAILS.map((val: any, id: number) => (
                            <GenericFormCard {...val} key={id} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const leaseDeedAllotteDetails = () => {
        return (
            <>
                <Card className='card_border'>
                    <Card.Body>
                        {allotteDtails.map((val: any, id: number) => (
                            <GenericFormCard {...val} {...val} key={id} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const leaseDeedSelfDeclarations = () => {
        return (
            <>
                <Card className='card_border'>
                    <Card.Body className='ps-4 ms-2 pt-4 mt-2'>
                        <h3 className='leaseDeed_Self_Declaration_Heading common_style_lease mb-3'>
                            {`${t('SELF_DECLARATION', 'Self Declaration')}`}
                            <span className='text-danger'>*</span>
                        </h3>
                        <p className='leaseDeed_Self_Declaration_paragraph common_style_lease'>{`${t('THE_APPLICANT_DOES_HEREBY', 'The applicant does hereby affirm and declare that')}`}:</p>
                        <div className='d-flex'>
                            <input
                                type='checkbox'
                                name='leaseDeedSelfDeclarations'
                                id='leaseDeedSelfDeclarations'
                                checked={leaseApplicationNo ? true : isChecked}
                                onChange={e => setIsChecked(e.target.checked)}
                            />
                            <label htmlFor='leaseDeedSelfDeclarations' className='leaseDeed_Self_Declaration_label common_style_lease_paragraph' onClick={handleLabelClick}>
                                (i){' '}
                                {`${t(
                                    'THE_INFORMATION_PROVIDED_ABOVE',
                                    'the information provided above and in the enclosed documents is true and correct to the best of the Lessee’s knowledge and belief'
                                )}`}
                                ;
                            </label>
                        </div>
                        <div className='mt-4 col-7 '>
                            <p className='leaseDeed_Self_Declaration_Signature common_style_lease_paragraph mb-1'>{`${t(
                                'NAME_&_DIGITAL_SIGNATURE',
                                'Name & Digital Signature of the Transferor/authorized person of the Transferor'
                            )}`}</p>
                            <GenericButtons text={'Sign the Document'} ClassName='px-4 ' />
                        </div>
                        <div className='leaseDeed_Self_Declaration_note mt-4'>
                            <span className='text-danger'>*</span>
                            {`${t('THE_APPLICANT_NEEDS_TO_PRODUCE', 'The Applicant needs to produce the original copy of signed Self-Declaration to the Goa IDC during signing of Lease Deed')}`}
                        </div>
                    </Card.Body>
                </Card>
            </>
        );
    };

    const leaseDeedDocuments = () => {
        return (
            <Card className='card_border py-3 ps-2'>
                <Card.Body>
                    <CardSubtitle className='lease_documents_field mb-3'>Documents</CardSubtitle>
                    <GenericFileUploadField isDisabled={false} field='documents' title='Upload the Signed Lease Deed' titleCode={'UPLOAD_THE_SIGNED_LEASE_DEED'} isRequired={true} />
                </Card.Body>
            </Card>
        );
    };

    const leaseDeedDraftDeed = () => {
        // return <RenderNotes onContentChange={() => {}} editorContent={''} />;
        return (
            <Card className='card_border'>
                <Card.Body>
                    <Editor setContent={setContent} content={content} />
                </Card.Body>
            </Card>
        );
    };

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            // `file.name` is the URL of opened file
            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            return `lease_deed.pdf`;
        }
    });

    const { DownloadButton } = getFilePluginInstance;
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Download: () => (
            <>
                <DownloadButton />
            </>
        )
    });

    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar
    });
    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    const pdfUrl = devUrl + `/filestore/v1/files/id?tenantId=ga&fileStoreId=${resData?.attachmentRefId}`;

    const leaseDeedPreview = () => {
        return (
            <Card className='card_border'>
                <Card.Body>
                    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
                        <Viewer
                            fileUrl={pdfUrl}
                            plugins={[
                                // Register plugins
                                getFilePluginInstance,
                                defaultLayoutPluginInstance
                            ]}
                            withCredentials={false}
                        />
                    </Worker>
                </Card.Body>
            </Card>
        );
    };

    const leaseDeedRegPreview = () => {
        return (
            <Card className='card_border'>
                <Card.Body>
                    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
                        <Viewer
                            fileUrl={pdfUrl}
                            plugins={[
                                // Register plugins
                                getFilePluginInstance,
                                defaultLayoutPluginInstance
                            ]}
                            withCredentials={false}
                        />
                    </Worker>
                </Card.Body>
            </Card>
        );
    };

    const leaseDeedNotificationDetails = () => {
        return (
            <Card className='card_border p-4'>
                <Card.Body>
                    <CardSubtitle className='lease_email_notification'>Email ID</CardSubtitle>
                    <GenericEmailIDNotification emailIdsData={formValues} getEmailIds={handleEmailValues} isRequired={true} isEditable={userDesignationMD ? false : true} />
                </Card.Body>
            </Card>
        );
    };
    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />

            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader text={t('APPLICATION_OF_LEASE_DEED', 'Application of Lease Deed')} subText={`${t('HOME_LEGAL', 'HOME / LEGAL')}`} registrationNo={applicationNo || leaseApplicationNo} />
                <div className='col-3 stickyCard-section'>
                    <div className='stickyCard' style={{ top: '110px' }}>
                        <Card className={`card_border  hiddenNav  ${isVisible ? '' : 'scrolledNav'}`}>
                            <Card.Body className=''>
                                <div className='d-flex align-items-center'>
                                    <GenericBackButton />
                                    <div className='ps-1'>
                                        <Card.Subtitle className='text-muted text-nav'>{t('HOME_LEGAL', 'HOME / LEGAL')} </Card.Subtitle>
                                        <Card.Text className='fs-5 form-header-text'>
                                            {t('APPLICATION_OF_LEASE_DEED', 'Application of Lease Deed')} <span>{applicationNo || leaseApplicationNo}</span>
                                        </Card.Text>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='card_border p-3'>
                            <Card.Body>
                                <Card.Text className=' d-flex flex-column  row-gap-3'>
                                    <SideCard steps={leaseDeedSteps} activeIndex={activeIndex} handleStepClick={handleStepClick} />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className='card_border note'>
                            <Card.Body>
                                <Card.Text>
                                    <span>
                                        <i className='fa fa-exclamation-circle' aria-hidden='true'></i>
                                    </span>
                                    <b> {t('NOTE', 'Note :')} </b>
                                    {t('PLEASE_PROVIDE_ALL_DETAILS', 'Please provide all the details.')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className='col-9 form-section'>
                    <FormikProvider value={formik}>
                        <Form>
                            {activeIndex === 1 && leaseDeedPlotDetails()}
                            {activeIndex === 2 && leaseDeedAllotteDetails()}
                            {activeIndex === 3 && leaseDeedSelfDeclarations()}
                            {activeIndex === 4 && (!!approvedStatus ? leaseDeedDocuments() : userDesignationMD ? leaseDeedPreview() : leaseDeedDraftDeed())}
                            {activeIndex === 5 && (!userDesignationMD ? leaseDeedPreview() : leaseDeedRegPreview())}
                            {activeIndex === 6 && (!userDesignationMD ? leaseDeedRegPreview() : leaseDeedNotificationDetails())}
                            {activeIndex === 7 && leaseDeedNotificationDetails()}
                        </Form>
                    </FormikProvider>
                </div>
            </div>
            <ScrollToTop />
            {getUserType === USER_TYPE.ENTERPRISE ? (
                <GenericFooterComponent
                    onCancel={() => {}}
                    stepperLength={LEASE_DEED_STEPS_DETAILS_ENTERPRISE_LOGIN.length}
                    activeIndex={activeIndex}
                    onSubmit={onSubmit}
                    onClickPrev={onChangePrevIndex}
                    disable={activeIndex === LEASE_DEED_STEPS_DETAILS_ENTERPRISE_LOGIN.length && !isChecked}
                />
            ) : getUserType === USER_TYPE.EMP && userDesignationMD ? (
                <GenericFooterComponent
                    onSubmit={() => {}}
                    onCancel={() => {}}
                    stepperLength={leaseDeedSteps.length}
                    activeIndex={activeIndex}
                    onClickPrev={onChangePrevIndex}
                    nextValidAction={nextValidAction}
                    onClickNext={onClickNext}
                    handleOnPrimaryAction={handleOnPrimaryAction}
                    applicationNo={leaseApplicationNo}
                />
            ) : (
                <GenericFooterComponent
                    onSubmit={onSubmit}
                    onCancel={() => {}}
                    stepperLength={leaseDeedSteps.length}
                    activeIndex={activeIndex}
                    onClickPrev={onChangePrevIndex}
                    nextValidAction={nextValidAction}
                    onClickNext={onClickNext}
                    showOnLastPage={true}
                    handleOnPrimaryAction={handleOnPrimaryAction}
                    childrenContent={<LeaseDeedForwardPopUp name='Managing Director' receivedChildData={handleCommentValue} />}
                    commentValue={commentValue}
                    applicationNo={leaseApplicationNo}
                    applicationType={'deed'}
                    disable={formValues[0].emailId.length === 0 && formValues[1].emailId.length === 0 ? true : false}
                />
            )}
        </>
    );
}
