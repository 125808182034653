export const STEPS_DATA = [
    {
        key: 'Data Extraction from CAD drawing in progress',
        status: true
    },
    {
        key: 'Data Extraction completed',
        status: true
    },
    {
        key: 'Validation of Drawing data against Bye Laws in progress',
        status: true
    },
    {
        key: 'Validation completed',
        status: true
    },
    {
        key: 'Bye Law compliance check in progress',
        status: true
    },
    {
        key: 'Approach Road',
        status: true
    },
    {
        key: 'Ground Coverage',
        status: true
    },
    {
        key: 'Parking',
        status: true
    },
]