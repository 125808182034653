import React, { useEffect, useMemo, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
// import './index.css';
interface IEditorProps {
    // editorContent?: any;
    setContent: (content: any) => void;
    content: string;
    readOnly?: boolean;
}

const Editor = (props: IEditorProps) => {
    const { setContent, content, readOnly } = props;
    const editor = useRef(null);

    useEffect(() => {
        setContent(content);
    }, []);

    const config = useMemo(
        () => ({
            readonly: readOnly,
            placeholder: '',
            hidePoweredByJodit: true,
            showCharsCounter: false,
            showWordsCounter: false,
            cleanHTML: {
                fillEmptyParagraph: false
            },
            buttons: [
                'source',
                '|',
                'bold',
                'strikethrough',
                'underline',
                'italic',
                '|',
                'ul',
                'ol',
                '|',
                'outdent',
                'indent',
                '|',
                'font',
                'fontsize',
                'brush',
                'paragraph',
                '|',
                'table',
                'link',
                '|',
                'align',
                'undo',
                'redo',
                '|',
                'hr',
                // 'eraser',
                'copyformat',
                '|',
                // 'symbol',
                // 'fullsize',
                'print'
            ]
        }),
        []
    );

    const handleOnBlur = (newContent: string) => {
        let cleanedContent = newContent;
        // let cleanedContent = newContent.replace(/<br>/g, '<br/>').replace(/<br\s*style="page-break-before:always;\s*clear:both;">/g, '<div style="page-break-before:always; clear:both;"></div>');
        if (!cleanedContent.includes('<!DOCTYPE html>')) {
            cleanedContent = cleanedContent && `<!DOCTYPE html><html><body>${cleanedContent.trim()}</body></html>`;
        }

        setContent(cleanedContent);
    };

    return (
        <>
            <div style={{ height: '500px', zIndex: 999 }} className='overflow-auto'>
                <JoditEditor value={content} ref={editor} config={config} onBlur={handleOnBlur} />
            </div>
        </>
    );
};

export default Editor;
