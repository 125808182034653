import axios from 'axios';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import i18next from 'i18next';
import { devUrl } from './services/modulesServices/userManagement.api';
// import axiosInstance from './helpers/Axios';

var userinfo = window.localStorage.getItem('userData');
const TransformArrayToObj = traslationList => {
    return traslationList.reduce(
        // eslint-disable-next-line
        (obj, item) => ((obj[item.code] = item.message), obj),
        {}
    );
    // return trasformedTraslation;
};
const loadResources = async locale => {
    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const body = {
        RequestInfo: {}
    };
    return await axios
        .post(`${devUrl}/localization/messages/v1/_search?locale=${locale}&tenantId=ga&module=cs`, body, axiosConfig)
        .then(response => {
            let locales = TransformArrayToObj(response.data?.messages);
            i18next.addResources(locale, 'translations', locales);
            return locales;
            // {"cs.foo": "Login"};
        })
        .catch(error => {
            console.log(error);
        });
};

const backendOptions = {
    loadPath: '{{lng}}|{{ns}}',
    request: (options, url, payload, callback) => {
        try {
            const [lng] = url.split('|');
            loadResources(lng).then(response => {
                callback(null, {
                    data: response,
                    status: 200
                });
            });
        } catch (e) {
            console.error(e);
            callback(null, {
                status: 500
            });
        }
    }
};

i18next
    .use(LanguageDetector)
    .use(backend)
    .use(initReactI18next)
    .init({
        backend: backendOptions,
        lng: localStorage.getItem('i18nextLng') || 'en_IN',
        fallbackLng: false,
        // localStorage.getItem("i18nextLng") || "tr",
        debug: false,
        // preload: ['en_IN'],
        load: 'currentOnly',
        // load:"languageOnly",
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true
        }
    });

export default i18n;
