import GenericActionTable from '../../../components/moduleCommonFormik/GenericActionTable';
import { timestampToDate } from './Allotment/Allotment.controller';
import { downloadPaymentReceipt } from './EstateManagement.controller';
import fileDown from '../../../assets/logos/download_green.svg';
import { Image } from 'react-bootstrap';

export const WorkflowPaymentDetails = ({ transactionDetails, paymentColumns, paymentRowData }: any) => {
    return (
        <>
            <GenericActionTable
                hideSearchBar={true}
                columns={paymentColumns}
                rowData={paymentRowData}
                itemsPerPage={5}
                highlightRow={true}
                totalRow={'total'}
                hideFooter={true}
                totalRowText={'Total Paid Amount (in INR)'}
            />
            <div className='payment-done'>
                <div className='row ps-3 pt-3'>
                    <div className='details ps-2 col-12'>
                        <div className='detail col-3'>
                            <div className='head'>Transaction ID</div>
                            <div className='content'>{transactionDetails?.payment?.transactionNumber || transactionDetails?.txnId}</div>
                        </div>
                        <div className='detail col-3'>
                            <div className='head'>Status</div>
                            <div className='content pill pill-success'>{transactionDetails ? 'Paid' : 'Failed'}</div>
                        </div>
                        <div className='detail col-3'>
                            <div className='head'>Payment Date</div>
                            <div className='content'>{timestampToDate(transactionDetails?.auditDetails?.createdTime)}</div>
                        </div>
                        {transactionDetails?.invoiceRefId ? (
                            <div className='detail col-3'>
                                <div className='head'>Receipt</div>
                                <div className='content link d-flex gap-1 align-items-center' onClick={() => downloadPaymentReceipt(transactionDetails?.invoiceRefId)}>
                                    <Image src={fileDown} height={20} />
                                    Download
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
