import { Card } from 'react-bootstrap';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import {
    DOWNPAYMENT_DETAILS,
    DOWNPAYMENT_DETAILS_II,
    FULL_PAYMENT_DETAILS,
    HOUSE_TAX_APPLICABLE,
    HOUSE_TAX_NOT_PAID,
    HOUSE_TAX_PAID,
    HOUSE_TAX_PAYMENT,
    INSTALLMENT_SCHEDULE_TABLE,
    LAND_PREMIUM_PAYMENT,
    LEASE_RENT_NOT_PAID,
    LEASE_RENT_PAID,
    LEASE_RENT_PAYMENT,
    WATER_CHARGE,
    WATER_CHARGES_NOT_PAID,
    WATER_CHARGES_PAID,
    WATER_CHARGES_PAYMENT,
    WATER_CHARGE_NOT_PAID,
    paymentDetails
} from '../Migration.const';
import { useEffect, useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import calculator_logo from '../../../../assets/logos/calculator.svg';
import { CGST_SGST } from '../../../../utils/constants';
import { installmentCalculation } from '../Migration.controller';
import { cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import DeleteIcon from '../../../../assets/logos/delete.svg';
import _ from 'lodash';

export const PaymentDetails = ({ installmentTableRowData, setInstallmentTableRowData, showInstallmentTable, setShowInstallmentTable, paymentDisableCheck, dropdownData, isUpdate }: any) => {
    const { values, setValues } = useFormikContext<any>();
    const [fullPaymentDetails, setFullPaymentDetails] = useState<any>(FULL_PAYMENT_DETAILS);
    const [landPremiumDetails, setLandPremiumDetails] = useState<any>(LAND_PREMIUM_PAYMENT);
    const [downpaymentDetails, setDownpaymentDetails] = useState<any>(DOWNPAYMENT_DETAILS);
    const [downpaymentDetailsII, setDownpaymentDetailsII] = useState<any>(DOWNPAYMENT_DETAILS_II);
    const [leaseRentPayment, setLeaseRentPayment] = useState<any>(LEASE_RENT_PAYMENT);
    const [leaseRentPaid, setLeaseRentPaid] = useState<any>(LEASE_RENT_PAID);
    const [leaseRentNotPaid, setLeaseRentNotPaid] = useState<any>(LEASE_RENT_NOT_PAID);
    const [houseTaxApplicable, setHouseTaxApplicable] = useState<any>(HOUSE_TAX_APPLICABLE);
    const [houseTaxPayment, setHouseTaxPayment] = useState<any>(HOUSE_TAX_PAYMENT);
    const [houseTaxPaid, setHouseTaxPaid] = useState<any>(HOUSE_TAX_PAID);
    const [houseTaxNotPaid, setHouseTaxNotPaid] = useState<any>(HOUSE_TAX_NOT_PAID);
    const [waterChargesPayment, setWaterChargesPayment] = useState<any>(WATER_CHARGES_PAYMENT);
    const [waterChargesPaid, setWaterChargesPaid] = useState<any>(WATER_CHARGES_PAID);
    const [waterChargesNotPaid, setWaterChargesNotPaid] = useState<any>(WATER_CHARGES_NOT_PAID);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [modalDetails, setModalDetails] = useState({});
    useEffect(() => {
        if (paymentDisableCheck || isUpdate) {
            const updateDetails = (detailsArray: any) =>
                detailsArray.map((item: any) => ({
                    ...item,
                    formData: item.formData.map((val: any) => ({ ...val, isDisabled: true }))
                }));

            setLandPremiumDetails(updateDetails(landPremiumDetails));
            setFullPaymentDetails(updateDetails(fullPaymentDetails));
            setDownpaymentDetails(updateDetails(downpaymentDetails));
            setDownpaymentDetailsII(updateDetails(downpaymentDetailsII));
            setLeaseRentPayment(updateDetails(leaseRentPayment));
            setLeaseRentPaid(updateDetails(leaseRentPaid));
            setLeaseRentNotPaid(updateDetails(leaseRentNotPaid));
            setHouseTaxApplicable(updateDetails(houseTaxApplicable));
            setHouseTaxPayment(updateDetails(houseTaxPayment));
            setHouseTaxPaid(updateDetails(houseTaxPaid));
            setHouseTaxNotPaid(updateDetails(houseTaxNotPaid));
            setWaterChargesPayment(updateDetails(waterChargesPayment));
            setWaterChargesPaid(updateDetails(waterChargesPaid));
            setWaterChargesNotPaid(updateDetails(waterChargesNotPaid));
        }
    }, [paymentDisableCheck]);

    const calculateInstallment = () => {
        const rowData = installmentCalculation(values);
        setInstallmentTableRowData(rowData);
        setShowInstallmentTable(true);
    };

    const onChangeAction = (e: any, field: string) => {
        if (
            (field === 'totalLandPremiumAmount' ||
                field === 'totalPaidLandPremiumPrincipalAmount' ||
                field === 'totalNumberOfInstallment' ||
                field === 'numberOfRemainingInstallment' ||
                field === 'nextInstallmentDate' ||
                field === 'previousInterestRate') &&
            (e || e?.target?.value)
        ) {
            setShowInstallmentTable(false);
        }
        if (field === 'landPremiumPenaltyAmount') {
            let tempSum =
                +(values.landPremiumArrearPrincipleAmount || 0) +
                +(values.landPremiumArrearInterestAmount || 0) +
                +(values.landPremiumArrearPenaltyAmount || 0) +
                +(values.cgstOnLandPremiumArrearPenalty || 0) +
                +(values.sgstOnLandPremiumArrearPenalty || 0);
            if (e.target.value) {
                setShowInstallmentTable(false);
                const cgstOnLandPremiumPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sgstOnLandPremiumPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sumPenaltyArrearGst = (+tempSum + +e.target.value * (CGST_SGST * 2 + 1)).toFixed(2).toString();
                setValues({ ...values, cgstOnLandPremiumPenaltyAmount, sgstOnLandPremiumPenaltyAmount, landPremiumPenaltyAmount: e.target.value, sumPenaltyArrearGst });
            } else {
                const cgstOnLandPremiumPenaltyAmount = '';
                const sgstOnLandPremiumPenaltyAmount = '';
                const sumPenaltyArrearGst = (+tempSum).toFixed(2).toString();
                setValues({ ...values, cgstOnLandPremiumPenaltyAmount, sgstOnLandPremiumPenaltyAmount, landPremiumPenaltyAmount: e.target.value, sumPenaltyArrearGst });
            }
        }
        if (field === 'landPremiumArrearInterestAmount') {
            let tempSum =
                +(values.landPremiumPenaltyAmount || 0) +
                +(values.cgstOnLandPremiumPenaltyAmount || 0) +
                +(values.sgstOnLandPremiumPenaltyAmount || 0) +
                +(values.landPremiumArrearPrincipleAmount || 0) +
                +(values.landPremiumArrearPenaltyAmount || 0) +
                +(values.cgstOnLandPremiumArrearPenalty || 0) +
                +(values.sgstOnLandPremiumArrearPenalty || 0);
            if (e.target.value) {
                setShowInstallmentTable(false);
                const sumPenaltyArrearGst = (+tempSum + +e.target.value).toFixed(2).toString();
                setValues({ ...values, landPremiumArrearInterestAmount: e.target.value, sumPenaltyArrearGst });
            } else {
                const sumPenaltyArrearGst = (+tempSum).toFixed(2).toString();
                setValues({ ...values, landPremiumArrearInterestAmount: e.target.value, sumPenaltyArrearGst });
            }
        }
        if (field === 'landPremiumArrearPenaltyAmount') {
            let tempSum =
                +(values.landPremiumPenaltyAmount || 0) +
                +(values.cgstOnLandPremiumPenaltyAmount || 0) +
                +(values.sgstOnLandPremiumPenaltyAmount || 0) +
                +(values.landPremiumArrearPrincipleAmount || 0) +
                +(values.landPremiumArrearInterestAmount || 0);
            if (e.target.value) {
                setShowInstallmentTable(false);
                const cgstOnLandPremiumArrearPenalty = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sgstOnLandPremiumArrearPenalty = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sumPenaltyArrearGst = (+tempSum + +e.target.value * (CGST_SGST * 2 + 1)).toFixed(2).toString();
                setValues({ ...values, cgstOnLandPremiumArrearPenalty, sgstOnLandPremiumArrearPenalty, landPremiumArrearPenaltyAmount: e.target.value, sumPenaltyArrearGst });
            } else {
                const cgstOnLandPremiumArrearPenalty = '';
                const sgstOnLandPremiumArrearPenalty = '';
                const sumPenaltyArrearGst = (+tempSum).toFixed(2).toString();
                setValues({ ...values, cgstOnLandPremiumArrearPenalty, sgstOnLandPremiumArrearPenalty, landPremiumArrearPenaltyAmount: e.target.value, sumPenaltyArrearGst });
            }
        }
        if (field === 'landPremiumArrearPrincipleAmount') {
            let tempSum =
                +(values.landPremiumPenaltyAmount || 0) +
                +(values.cgstOnLandPremiumPenaltyAmount || 0) +
                +(values.sgstOnLandPremiumPenaltyAmount || 0) +
                +(values.landPremiumArrearInterestAmount || 0) +
                +(values.landPremiumArrearPenaltyAmount || 0) +
                +(values.cgstOnLandPremiumArrearPenalty || 0) +
                +(values.sgstOnLandPremiumArrearPenalty || 0);
            if (e.target.value) {
                setShowInstallmentTable(false);
                const sumPenaltyArrearGst = (+tempSum + +e.target.value).toFixed(2).toString();
                setValues({ ...values, sumPenaltyArrearGst, landPremiumArrearPrincipleAmount: e.target.value });
            } else {
                const sumPenaltyArrearGst = (+tempSum).toFixed(2).toString();
                setValues({ ...values, sumPenaltyArrearGst, landPremiumArrearPrincipleAmount: e.target.value });
            }
        }
        if ((field === 'totalLandPremiumAmount' || field === 'totalPaidLandPremiumPrincipalAmount') && e.target.value) {
            let totalLandPremiumAmount = field === 'totalLandPremiumAmount' ? +e.target.value : +values.totalLandPremiumAmount;
            let totalPaidLandPremiumPrincipalAmount = field === 'totalPaidLandPremiumPrincipalAmount' ? +e.target.value : +values.totalPaidLandPremiumPrincipalAmount;

            if (!isNaN(totalPaidLandPremiumPrincipalAmount)) {
                if (totalLandPremiumAmount > totalPaidLandPremiumPrincipalAmount) {
                    const landPremiumBalanceAmount = (totalLandPremiumAmount - totalPaidLandPremiumPrincipalAmount).toString();
                    setValues({
                        ...values,
                        landPremiumBalanceAmount,
                        totalLandPremiumAmount: totalLandPremiumAmount.toString(),
                        totalPaidLandPremiumPrincipalAmount:
                            isNaN(totalPaidLandPremiumPrincipalAmount) || totalPaidLandPremiumPrincipalAmount === 0 ? '' : totalPaidLandPremiumPrincipalAmount.toString()
                    });
                } else {
                    setValues({
                        ...values,
                        totalLandPremiumAmount: totalLandPremiumAmount.toString(),
                        totalPaidLandPremiumPrincipalAmount: ''
                    });
                    setModalDetails({
                        show: true,
                        title: 'Error!',
                        para: 'Total paid Land Premium principal amount cannot be greater than Total Land Premium amount',
                        img: cross,
                        type: 'error',
                        reUpload: 'OK',
                        resetPaidPremium: true
                    });
                }
            }
        }
        if (field === 'leaseRentPrincipalAmount') {
            let tempSum =
                +(values.leaseRentPenaltyAmount || 0) +
                +(values.cgstOnLeaseRentPenaltyAmount || 0) +
                +(values.sgstOnLeaseRentPenaltyAmount || 0) +
                +(values.leaseRentArrearPrincipalAmount || 0) +
                +(values.cgstOnLeaseRentArrearPrincipalAmount || 0) +
                +(values.sgstOnLeaseRentArrearPrincipalAmount || 0) +
                +(values.leaseRentArrearPenaltyAmount || 0) +
                +(values.cgstOnLeaseRentArrearPenaltyAmount || 0) +
                +(values.sgstOnLeaseRentArrearPenaltyAmount || 0);
            if (e.target.value) {
                const cgstOnLeaseRentPrincipleAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sgstOnLeaseRentPrincipleAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const leaseRentTotalDueAmmount = (+tempSum + +e.target.value * (CGST_SGST * 2 + 1)).toFixed(2).toString();
                setValues({ ...values, cgstOnLeaseRentPrincipleAmount, sgstOnLeaseRentPrincipleAmount, leaseRentTotalDueAmmount, leaseRentPrincipalAmount: e.target.value });
            } else {
                const cgstOnLeaseRentPrincipleAmount = '';
                const sgstOnLeaseRentPrincipleAmount = '';
                const leaseRentTotalDueAmmount = (+tempSum).toFixed(2).toString();
                setValues({ ...values, cgstOnLeaseRentPrincipleAmount, sgstOnLeaseRentPrincipleAmount, leaseRentTotalDueAmmount, leaseRentPrincipalAmount: e.target.value });
            }
        }
        if (field === 'leaseRentPenaltyAmount') {
            let tempSum =
                +(values.leaseRentPrincipalAmount || 0) +
                +(values.cgstOnLeaseRentPrincipleAmount || 0) +
                +(values.sgstOnLeaseRentPrincipleAmount || 0) +
                +(values.leaseRentArrearPrincipalAmount || 0) +
                +(values.cgstOnLeaseRentArrearPrincipalAmount || 0) +
                +(values.sgstOnLeaseRentArrearPrincipalAmount || 0) +
                +(values.leaseRentArrearPenaltyAmount || 0) +
                +(values.cgstOnLeaseRentArrearPenaltyAmount || 0) +
                +(values.sgstOnLeaseRentArrearPenaltyAmount || 0);
            if (e.target.value) {
                const cgstOnLeaseRentPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sgstOnLeaseRentPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const leaseRentTotalDueAmmount = (+tempSum + +e.target.value * (CGST_SGST * 2 + 1)).toFixed(2).toString();
                setValues({ ...values, cgstOnLeaseRentPenaltyAmount, sgstOnLeaseRentPenaltyAmount, leaseRentTotalDueAmmount, leaseRentPenaltyAmount: e.target.value });
            } else {
                const cgstOnLeaseRentPenaltyAmount = '';
                const sgstOnLeaseRentPenaltyAmount = '';
                const leaseRentTotalDueAmmount = (+tempSum).toFixed(2).toString();
                setValues({ ...values, cgstOnLeaseRentPenaltyAmount, sgstOnLeaseRentPenaltyAmount, leaseRentTotalDueAmmount, leaseRentPenaltyAmount: e.target.value });
            }
        }
        if (field === 'leaseRentArrearPrincipalAmount') {
            let tempSum =
                +(values.leaseRentPrincipalAmount || 0) +
                +(values.cgstOnLeaseRentPrincipleAmount || 0) +
                +(values.sgstOnLeaseRentPrincipleAmount || 0) +
                +(values.leaseRentPenaltyAmount || 0) +
                +(values.cgstOnLeaseRentPenaltyAmount || 0) +
                +(values.sgstOnLeaseRentPenaltyAmount || 0) +
                +(values.leaseRentArrearPenaltyAmount || 0) +
                +(values.cgstOnLeaseRentArrearPenaltyAmount || 0) +
                +(values.sgstOnLeaseRentArrearPenaltyAmount || 0);
            if (e.target.value) {
                const cgstOnLeaseRentArrearPrincipalAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sgstOnLeaseRentArrearPrincipalAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const leaseRentTotalDueAmmount = (+tempSum + +e.target.value * (CGST_SGST * 2 + 1)).toFixed(2).toString();
                setValues({ ...values, cgstOnLeaseRentArrearPrincipalAmount, sgstOnLeaseRentArrearPrincipalAmount, leaseRentTotalDueAmmount, leaseRentArrearPrincipalAmount: e.target.value });
            } else {
                const cgstOnLeaseRentArrearPrincipalAmount = '';
                const sgstOnLeaseRentArrearPrincipalAmount = '';
                const leaseRentTotalDueAmmount = (+tempSum).toFixed(2).toString();
                setValues({ ...values, cgstOnLeaseRentArrearPrincipalAmount, sgstOnLeaseRentArrearPrincipalAmount, leaseRentTotalDueAmmount, leaseRentArrearPrincipalAmount: e.target.value });
            }
        }
        if (field === 'leaseRentArrearPenaltyAmount') {
            let tempSum =
                +(values.leaseRentPrincipalAmount || 0) +
                +(values.cgstOnLeaseRentPrincipleAmount || 0) +
                +(values.sgstOnLeaseRentPrincipleAmount || 0) +
                +(values.leaseRentPenaltyAmount || 0) +
                +(values.cgstOnLeaseRentPenaltyAmount || 0) +
                +(values.sgstOnLeaseRentPenaltyAmount || 0) +
                +(values.leaseRentArrearPrincipalAmount || 0) +
                +(values.cgstOnLeaseRentArrearPrincipalAmount || 0) +
                +(values.sgstOnLeaseRentArrearPrincipalAmount || 0);
            if (e.target.value) {
                const cgstOnLeaseRentArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sgstOnLeaseRentArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const leaseRentTotalDueAmmount = (+tempSum + +e.target.value * (CGST_SGST * 2 + 1)).toFixed(2).toString();
                setValues({ ...values, cgstOnLeaseRentArrearPenaltyAmount, sgstOnLeaseRentArrearPenaltyAmount, leaseRentTotalDueAmmount, leaseRentArrearPenaltyAmount: e.target.value });
            } else {
                const cgstOnLeaseRentArrearPenaltyAmount = '';
                const sgstOnLeaseRentArrearPenaltyAmount = '';
                const leaseRentTotalDueAmmount = (+tempSum).toFixed(2).toString();
                setValues({ ...values, cgstOnLeaseRentArrearPenaltyAmount, sgstOnLeaseRentArrearPenaltyAmount, leaseRentTotalDueAmmount, leaseRentArrearPenaltyAmount: e.target.value });
            }
        }
        if (field === 'houseTaxPrincipalAmount') {
            let tempSum =
                +(values.houseTaxPenaltyAmount || 0) +
                +(values.cgstOnHouseTaxPenaltyAmount || 0) +
                +(values.sgstOnHouseTaxPenaltyAmount || 0) +
                +(values.houseTaxArrearPrincipalAmount || 0) +
                +(values.houseTaxArrearPenaltyAmount || 0) +
                +(values.cgstOnHouseTaxArrearPenaltyAmount || 0) +
                +(values.sgstOnHouseTaxArrearPenaltyAmount || 0);
            if (e.target.value) {
                const houseTaxTotalDueAmmount = (+tempSum + +e.target.value).toFixed(2).toString();
                setValues({ ...values, houseTaxTotalDueAmmount, houseTaxPrincipalAmount: e.target.value });
            } else {
                const houseTaxTotalDueAmmount = (+tempSum).toFixed(2).toString();
                setValues({ ...values, houseTaxTotalDueAmmount, houseTaxPrincipalAmount: e.target.value });
            }
        }
        if (field === 'houseTaxPenaltyAmount') {
            let tempSum =
                +(values.houseTaxPrincipalAmount || 0) +
                +(values.houseTaxArrearPrincipalAmount || 0) +
                +(values.houseTaxArrearPenaltyAmount || 0) +
                +(values.cgstOnHouseTaxArrearPenaltyAmount || 0) +
                +(values.sgstOnHouseTaxArrearPenaltyAmount || 0);
            if (e.target.value) {
                const cgstOnHouseTaxPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sgstOnHouseTaxPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const houseTaxTotalDueAmmount = (+tempSum + +e.target.value * (CGST_SGST * 2 + 1)).toFixed(2).toString();
                setValues({ ...values, cgstOnHouseTaxPenaltyAmount, sgstOnHouseTaxPenaltyAmount, houseTaxTotalDueAmmount, houseTaxPenaltyAmount: e.target.value });
            } else {
                const cgstOnHouseTaxPenaltyAmount = '';
                const sgstOnHouseTaxPenaltyAmount = '';
                const houseTaxTotalDueAmmount = (+tempSum).toFixed(2).toString();
                setValues({ ...values, cgstOnHouseTaxPenaltyAmount, sgstOnHouseTaxPenaltyAmount, houseTaxTotalDueAmmount, houseTaxPenaltyAmount: e.target.value });
            }
        }
        if (field === 'houseTaxArrearPrincipalAmount') {
            let tempSum =
                +(values.houseTaxPrincipalAmount || 0) +
                +(values.houseTaxPenaltyAmount || 0) +
                +(values.cgstOnHouseTaxPenaltyAmount || 0) +
                +(values.sgstOnHouseTaxPenaltyAmount || 0) +
                +(values.houseTaxArrearPenaltyAmount || 0) +
                +(values.cgstOnHouseTaxArrearPenaltyAmount || 0) +
                +(values.sgstOnHouseTaxArrearPenaltyAmount || 0);
            if (e.target.value) {
                const houseTaxTotalDueAmmount = (+tempSum + +e.target.value).toFixed(2).toString();
                setValues({ ...values, houseTaxTotalDueAmmount, houseTaxArrearPrincipalAmount: e.target.value });
            } else {
                const houseTaxTotalDueAmmount = (+tempSum).toFixed(2).toString();
                setValues({ ...values, houseTaxTotalDueAmmount, houseTaxArrearPrincipalAmount: e.target.value });
            }
        }
        if (field === 'houseTaxArrearPenaltyAmount') {
            let tempSum =
                +(values.houseTaxPrincipalAmount || 0) +
                +(values.houseTaxPenaltyAmount || 0) +
                +(values.cgstOnHouseTaxPenaltyAmount || 0) +
                +(values.sgstOnHouseTaxPenaltyAmount || 0) +
                +(values.houseTaxArrearPrincipalAmount || 0);
            if (e.target.value) {
                const cgstOnHouseTaxArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const sgstOnHouseTaxArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
                const houseTaxTotalDueAmmount = (+tempSum + +e.target.value * (CGST_SGST * 2 + 1)).toFixed(2).toString();
                setValues({ ...values, cgstOnHouseTaxArrearPenaltyAmount, sgstOnHouseTaxArrearPenaltyAmount, houseTaxTotalDueAmmount, houseTaxArrearPenaltyAmount: e.target.value });
            } else {
                const cgstOnHouseTaxArrearPenaltyAmount = '';
                const sgstOnHouseTaxArrearPenaltyAmount = '';
                const houseTaxTotalDueAmmount = (+tempSum).toFixed(2).toString();
                setValues({ ...values, cgstOnHouseTaxArrearPenaltyAmount, sgstOnHouseTaxArrearPenaltyAmount, houseTaxTotalDueAmmount, houseTaxArrearPenaltyAmount: e.target.value });
            }
        }

        let newValues = _.cloneDeep(values);
        let currentField = field.split('.').slice(-1)[0];
        let index = +field.split('.')[1];
        let currentPath = newValues.migrationWaterCharge[index];

        if (currentField === 'waterChargesPrincipalAmount') {
            let tempSum =
                +(currentPath.waterChargesPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.waterChargesArrearPrincipalAmount || 0) +
                +(currentPath.waterChargesArrearPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesArrearPenaltyAmount || 0);
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value).toFixed(2).toString();
            _.set(newValues, `migrationWaterCharge[${index}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `migrationWaterCharge[${index}].waterChargesPrincipalAmount`, e.target.value);
            setValues(newValues);
        }
        if (currentField === 'waterChargesPenaltyAmount') {
            let tempSum =
                +(currentPath.waterChargesPrincipalAmount || 0) +
                +(currentPath.waterChargesArrearPrincipalAmount || 0) +
                +(currentPath.waterChargesArrearPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesArrearPenaltyAmount || 0);
            const cgstOnWaterChargesPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const sgstOnWaterChargesPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value * (2 * CGST_SGST + 1)).toFixed(2).toString();
            _.set(newValues, `migrationWaterCharge[${index}].cgstOnWaterChargesPenaltyAmount`, cgstOnWaterChargesPenaltyAmount);
            _.set(newValues, `migrationWaterCharge[${index}].sgstOnWaterChargesPenaltyAmount`, sgstOnWaterChargesPenaltyAmount);
            _.set(newValues, `migrationWaterCharge[${index}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `migrationWaterCharge[${index}].waterChargesPenaltyAmount`, e.target.value);
            setValues(newValues);
        }
        if (currentField === 'waterChargesArrearPrincipalAmount') {
            let tempSum =
                +(currentPath.waterChargesPrincipalAmount || 0) +
                +(currentPath.waterChargesPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.waterChargesArrearPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesArrearPenaltyAmount || 0);
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value).toFixed(2).toString();
            _.set(newValues, `migrationWaterCharge[${index}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `migrationWaterCharge[${index}].waterChargesArrearPrincipalAmount`, e.target.value);
            setValues(newValues);
        }
        if (currentField === 'waterChargesArrearPenaltyAmount') {
            let tempSum =
                +(currentPath.waterChargesPrincipalAmount || 0) +
                +(currentPath.waterChargesPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.waterChargesArrearPrincipalAmount || 0);
            const cgstOnWaterChargesArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const sgstOnWaterChargesArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value * (2 * CGST_SGST + 1)).toFixed(2).toString();
            _.set(newValues, `migrationWaterCharge[${index}].cgstOnWaterChargesArrearPenaltyAmount`, cgstOnWaterChargesArrearPenaltyAmount);
            _.set(newValues, `migrationWaterCharge[${index}].sgstOnWaterChargesArrearPenaltyAmount`, sgstOnWaterChargesArrearPenaltyAmount);
            _.set(newValues, `migrationWaterCharge[${index}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `migrationWaterCharge[${index}].waterChargesArrearPenaltyAmount`, e.target.value);
            setValues(newValues);
        }
    };

    useEffect(() => {
        if (
            values.totalLandPremiumAmount &&
            values.totalPaidLandPremiumPrincipalAmount &&
            values.totalNumberOfInstallment &&
            values.numberOfRemainingInstallment &&
            values.nextInstallmentDate &&
            values.previousInterestRate &&
            parseInt(values.totalNumberOfInstallment) <= 10 &&
            parseInt(values.numberOfRemainingInstallment) <= 10
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [
        values.totalLandPremiumAmount,
        values.totalPaidLandPremiumPrincipalAmount,
        values.totalNumberOfInstallment,
        values.numberOfRemainingInstallment,
        values.nextInstallmentDate,
        values.previousInterestRate
    ]);

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            {paymentDetails.map((val: any) => (
                <Card className='card_border'>
                    <Card.Body>
                        <GenericFormCard {...val} />
                    </Card.Body>
                </Card>
            ))}

            {landPremiumDetails.map((val: any, index: number) => (
                <Card className='card_border' key={index}>
                    <Card.Body>
                        <GenericFormCard module='usermanagement' {...val} />
                        {values.landPremiumDue === false ? (
                            <>
                                {fullPaymentDetails.map((val: any) => (
                                    <GenericFormCard module='usermanagement' {...val} />
                                ))}
                            </>
                        ) : (
                            values.landPremiumDue === true && (
                                <>
                                    {downpaymentDetails.map((innerVal: any) => (
                                        <GenericFormCard module='usermanagement' {...innerVal} onChangeAction={onChangeAction} />
                                    ))}
                                    {downpaymentDetailsII.map((innerVal: any) => (
                                        <GenericFormCard module='usermanagement' {...innerVal} onChangeAction={onChangeAction} />
                                    ))}
                                    {!showInstallmentTable ? (
                                        <button type='button' className='col-sm-12 py-2 rounded controlling_ownership_button' onClick={calculateInstallment} disabled={disabled}>
                                            <img src={calculator_logo} alt='calculator' className='me-2' /> Generate installment schedule
                                        </button>
                                    ) : (
                                        <OwnershipTable column={INSTALLMENT_SCHEDULE_TABLE} tableRowData={installmentTableRowData} hideSearchBar={true} />
                                    )}
                                </>
                            )
                        )}
                    </Card.Body>
                </Card>
            ))}

            <Card className='card_border'>
                <Card.Body>
                    {leaseRentPayment.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}

                    {values.leaseRentPaymentStatus === false ? (
                        <>
                            {leaseRentPaid.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}
                        </>
                    ) : (
                        <>
                            {leaseRentNotPaid.map((val: any, id: number) => {
                                return <GenericFormCard {...val} onChangeAction={onChangeAction} />;
                            })}
                        </>
                    )}
                </Card.Body>
            </Card>

            <Card className='card_border'>
                <Card.Body>
                    {houseTaxApplicable?.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}
                    {values?.houseTaxApplicable ? (
                        <>
                            {houseTaxPayment.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}

                            {values.houseTaxPaymentStatus === false ? (
                                <>
                                    {houseTaxPaid.map((val: any) => (
                                        <GenericFormCard {...val} />
                                    ))}
                                </>
                            ) : (
                                <>
                                    {houseTaxNotPaid.map((val: any, id: number) => {
                                        return <GenericFormCard {...val} onChangeAction={onChangeAction} />;
                                    })}
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </Card.Body>
            </Card>

            {values.waterConnection ? (
                <Card className='card_border'>
                    <Card.Body>
                        {waterChargesPayment.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}

                        {
                            <>
                                <FieldArray name='migrationWaterCharge'>
                                    {({ remove, push }) => (
                                        <div>
                                            {values.migrationWaterCharge?.length > 0 &&
                                                values.migrationWaterCharge.map((data: any, index: number) => (
                                                    <div className='row' key={index}>
                                                        <Card.Body>
                                                            <div className='form_fields'>
                                                                {index !== 0 && values.isUpdatable && (
                                                                    <div className='d-flex justify-content-end mt-2' style={{ position: 'absolute', right: '5%' }}>
                                                                        <img
                                                                            className='delete-icon'
                                                                            style={{ cursor: 'pointer', zIndex: 100 }}
                                                                            onClick={() => remove(index)}
                                                                            src={DeleteIcon}
                                                                            alt='delete'
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className='ps-2 py-2 d-flex justify-content-between'>
                                                                    <div className='formTitle m-0'>{`Water Charge - ${index + 1}`}</div>
                                                                </div>
                                                                {values.waterChargesPaymentStatus === true
                                                                    ? waterChargesNotPaid.map((val: any, id: number) => {
                                                                          return (
                                                                              <GenericFormCard
                                                                                  {...val}
                                                                                  fieldPath={`migrationWaterCharge.${index}`}
                                                                                  index={index}
                                                                                  onChangeAction={onChangeAction}
                                                                                  dropdownOptions={dropdownData}
                                                                              />
                                                                          );
                                                                      })
                                                                    : waterChargesPaid.map((val: any) => (
                                                                          <GenericFormCard {...val} fieldPath={`migrationWaterCharge.${index}`} index={index} dropdownOptions={dropdownData} />
                                                                      ))}
                                                            </div>
                                                        </Card.Body>
                                                    </div>
                                                ))}
                                            {values.migrationWaterCharge.length < 2 && values.isUpdatable && (
                                                <button
                                                    className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center add_fields'
                                                    type='button'
                                                    onClick={() => push(values.waterChargesPaymentStatus === true ? WATER_CHARGE_NOT_PAID : WATER_CHARGE)}
                                                >
                                                    + Add Water Charge details
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </FieldArray>
                            </>
                        }
                    </Card.Body>
                </Card>
            ) : (
                <></>
            )}
        </>
    );
};
