import React, { useEffect, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import { ARCHITECT_DETAILS, BUILDING_PLAN_DETAILS, DOCUMENTS_DETAILS, LESSEE_DETAILS, PLOT_DETAILS, pdfUrl } from './ScrutinyFormPage.const';
import { Field, useFormikContext } from 'formik';
import { Viewer, Worker, OpenFile, ScrollMode } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ScrutinySteps from '../StepsForPopUp/Steps';
import ScruitnyPopupModal from './scrutinyPopUp/ScruitnyModalPopup.component';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import moment from 'moment';
import { getMDMSDataList, mdmsSearchJson } from './ScrutinyFormPage.controller';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { t } from 'i18next';
import fileDown from '../../assets/logos/download.svg';
import { CONSTANT_DATA_FIELD } from '../../utils/constants';
import GenericPopupModal from '../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../components/fileviewer/GenericPDFViewer';
import ToolTipDetails from '../../components/ToolTipFile';

interface BuildingSubPage {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    index: number;
    employeeList: any;
    formData: any;
    showPopup: boolean;
    activeStepper: string;
    isView: boolean;
    viewData: any;
    edcrNumber: string;
}

export default function ScrutinyFormSubPage(props: BuildingSubPage) {
    const { values } = useFormikContext<any>();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [planBlobData, setPlanBlobData] = useState<any>(null);
    const [mdmsList, setMdmsList] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    let userLocal = JSON.parse(localStorage.getItem('user') || '{}');

    const pdfUrlToBase64 = async (url: any) => {
        var bodyFormData = new FormData();
        bodyFormData.append('url', url);
        let data = '';
        TechnicalServices.getScrutinyFile(bodyFormData)
            .then((response: any) => {
                // handle the response
                if (response?.data?.base64 !== '') {
                    setPlanBlobData(`data:application/pdf;base64, ${response?.data?.base64}`);
                    return;
                }
                setPlanBlobData('https://sujog-dev.odisha.gov.in/filestore/v1/files/id?fileStoreId=9450d85e-1768-49a5-93fb-32a8fffa98c2&tenantId=od');
            })
            .catch(error => {});
    };

    const getmdmsSearch = async () => {
        const payload = mdmsSearchJson();
        TechnicalServices.mdmsSearch(payload)
            .then((res: any) => {
                // handle the response
                if (res?.data) {
                    if (res?.data?.MdmsRes?.BPA?.ServiceType) {
                        setMdmsList(getMDMSDataList(res?.data?.MdmsRes?.BPA?.ServiceType));
                    }
                }
            })
            .catch(error => {});
    };

    useEffect(() => {
        getmdmsSearch();
    }, []);

    useEffect(() => {
        if (props?.formData?.edcrDetail) pdfUrlToBase64(props?.formData?.edcrDetail[0].planReport);
    }, [props?.formData?.edcrDetail]);

    useEffect(() => {
        if (props?.viewData?.additionalDetails?.planReport) pdfUrlToBase64(props?.viewData?.additionalDetails?.planReport);
    }, [props?.viewData]);

    const handleClose = () => {
        setIsShow(false);
    };

    const renderBuildingPlanDetails = () => {
        const buildingPlanJSON = BUILDING_PLAN_DETAILS;
        buildingPlanJSON[0].formData.map((val, id) => {
            if (val?.field === 'serviceType') {
                val.options = mdmsList;
            }
        });
        return (
            <Card className='card_border'>
                <Card.Body>
                    {BUILDING_PLAN_DETAILS.map(val => (
                        <GenericFormCard {...val} />
                    ))}
                    {/* <label htmlFor={'generateSitePlanPDF'} className='pb-4 file-form-label d-flex align-items-center'>
                        <Field type='checkbox' name={'generateSitePlanPDF'} id={'generateSitePlanPDF'} className={`mx-4`} checked={values['generateSitePlanPDF']} disabled={false} />
                        Is SitePlan Required
                    </label> */}
                    {/* <label className='ms-3 mb-1'>{t('SC_LBL_IS_PLAN_REQUIRED', 'The site plan is mandatory for sharing the scrutiny report with the Lessee')}</label>
                    <div className='d-flex'>
                        <label htmlFor={'generateSitePlanPDF'} className='file-form-label d-flex align-items-center'>
                            <Field type='radio' name='generateSitePlanPDF' id='generateSitePlanPDF' value='true' className='ms-4' checked />
                            Yes
                        </label>
                        <label htmlFor={'generateSitePlanPDF'} className='file-form-label d-flex align-items-center'>
                            <Field type='radio' name='generateSitePlanPDF' id='generateSitePlanPDF' value='false' className='ms-4' />
                            No
                        </label>
                    </div> */}
                </Card.Body>
            </Card>
        );
    };

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            // `file.name` is the URL of opened file
            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            return `scrutiny_report.pdf`;
        }
    });

    const { DownloadButton } = getFilePluginInstance;
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Download: () => (
            <>
                <DownloadButton />
            </>
        )
    });

    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar
    });
    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    const renderBildingPlanReportDetails = () => {
        return (
            <>
                <Card className='card_border '>
                    <Card.Body>
                        <div className='row col-sm-12'>
                            <div className='row col-sm-3'>
                                <label>{t('ARC_LBL_SERVICE_TYPE', 'Service Type')}</label>
                                <div className='fw-bold'>
                                    {t(
                                        !!props?.isView ? props?.viewData?.serviceType : props?.formData?.edcrDetail[0]?.applicationSubType,
                                        String(!!props?.isView ? props?.viewData?.serviceType : props?.formData?.edcrDetail[0]?.applicationSubType)
                                    )}
                                </div>
                            </div>
                            <div className='row col-sm-3'>
                                <label>{t('ARC_DOC_LBL_PLAN_FILE', 'Plan File')}</label>
                                <div className='fw-bold'>
                                    <a href={!!props?.isView ? props?.viewData?.additionalDetails?.dxfFile : props?.formData?.edcrDetail && props?.formData?.edcrDetail[0].dxfFile}>
                                        {t('ARC_DOC_PLAN_FILE', 'Download Building Plan')}{' '}
                                    </a>
                                </div>
                            </div>
                            <div className='row col-sm-3'>
                                <label>{t('ARC_DOC_LBL_SITE_PLAN_FILE', 'Site Plan')}</label>
                                <div className='fw-bold'>
                                    <a href={!!props?.isView ? props?.viewData?.additionalDetails?.sitePlan : props?.formData?.edcrDetail && props?.formData?.edcrDetail[0].sitePlan}>
                                        {t('ARC_DOC_SITE_PLAN_FILE', 'Download Site Plan')}{' '}
                                    </a>
                                </div>
                            </div>
                            <div className='row col-sm-3'>
                                <label>{t('ARC_DOC_LBL_SCRUTINY_STATUS', 'Scrutiny Status')}</label>
                                <div
                                    className={
                                        props?.viewData?.additionalDetails?.status === 'Accepted' || props?.formData?.edcrDetail[0]?.status === 'Accepted'
                                            ? 'text-success fw-bold'
                                            : 'text-danger fw-bold'
                                    }
                                >
                                    {!!props?.isView ? props?.viewData?.additionalDetails?.status : props?.formData?.edcrDetail[0]?.status || 'Not Accepted'}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='card_border my-2 card_bg'>
                    <Card.Body>
                        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
                            <Viewer
                                fileUrl={planBlobData || pdfUrl}
                                plugins={[
                                    // Register plugins
                                    getFilePluginInstance,
                                    defaultLayoutPluginInstance
                                ]}
                                withCredentials={false}
                            />
                        </Worker>
                    </Card.Body>
                </Card>
            </>
        );
    };

    const viewDocument = (id: string) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(id);
    };

    const renderLesseeShare = () => {
        const documentNamePrefix = userLocal?.firstName;
        console.log(values?.additionalDetails, 'TESTING');
        // Map through ARCHITECT_DETAILS to update documentNamePrefix
        const updatedArchitectDetails = ARCHITECT_DETAILS.map(detail => {
            detail.formData.forEach(field => {
                if (field.type === CONSTANT_DATA_FIELD.NEW_FILE_FIELD && field.entityFileData && field.entityFileData.documentNamePrefix) {
                    field.entityFileData.documentNamePrefix = documentNamePrefix;
                }
            });
            return detail;
        });

        const onDownload = (url: any) => {
            window.open(url);
        };

        const getCurrentDate = () => {
            const today = new Date();
            const day = String(today.getDate()).padStart(2, '');
            const month = String(today.getMonth() + 1).padStart(2, '');
            const year = today.getFullYear();
            return `${day}/${month}/${year}`;
        };

        const formatFileSize = (size: number) => {
            if (size < 1024 * 1024) {
                return `${Math.floor(size / 1024)} KB`;
            } else {
                return `${Math.floor(size / (1024 * 1024))} MB`;
            }
        };

        const currentDate = getCurrentDate();
        console.log(values?.additionalDetails);

        const toolTipDetails = {
            position: 'bottom',
            content: '<p>This is system generated site plan from the scrutinized building plan</p>'
            // linkUrl: 'https://www.pollution.com',
            // linkText: 'Website Link'
        };

        return (
            <>
                <Card className='card_border '>
                    <Card.Body>
                        <div className='mb-2'>
                            {LESSEE_DETAILS.map((val, key) => (
                                <GenericFormCard {...{ ...val }} key={key} />
                            ))}
                        </div>
                        <div className='mb-2'>
                            {PLOT_DETAILS.map((val, key) => (
                                <GenericFormCard {...{ ...val }} key={key} />
                            ))}
                        </div>
                        <div className='mb-2'>
                            {updatedArchitectDetails.map((val, key) => (
                                <GenericFormCard {...val} key={key} />
                            ))}
                        </div>
                        <div className='ps-3 me-3'>
                            {!!values?.additionalDetails?.sitePlan && (
                                <>
                                    <div className='d-flex'>
                                        Site Plan <span className=' text-danger '>*</span>
                                        <ToolTipDetails toolTipDetails={toolTipDetails} />
                                    </div>

                                    <div
                                        className={`btn btn-none fileUploadBox py-2 ps-2 p-4 m-1`}
                                        style={{
                                            borderRadius: '12px',
                                            justifyContent: 'space-between',
                                            backgroundColor: '#45852E',
                                            height: '5rem',
                                            width: '49%' // Adjusts width based on resolution
                                        }}
                                    >
                                        <div className='d-flex align-items-center ms-0 ps-0' onClick={() => onDownload(values?.additionalDetails?.sitePlan)}>
                                            <div className='ms-2 text-start' style={{ padding: '8px' }}>
                                                <span>
                                                    <div
                                                        className='file-name uploaded-file-name text-white text-nowrap text-truncate'
                                                        style={{ fontSize: '14px', lineHeight: '18px', marginBottom: '10px' }}
                                                        title={'SitePlan.pdf'}
                                                    >
                                                        {`${values?.name}_SITEPLAN.PDF`}
                                                    </div>
                                                    <div className='text-white' style={{ fontSize: '12px', lineHeight: '18px' }}>
                                                        {'4 MB'} . {currentDate}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='me- d-flex'>
                                            <Image src={fileDown} height={20} onClick={() => onDownload(values?.additionalDetails?.sitePlan)} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </Card.Body>
                </Card>
                <div style={{ marginBottom: '5rem' }}></div>
            </>
        );
    };

    return (
        <>
            {props?.activeStepper === 'PLAN_SCRUTINY' && !props?.showPopup && renderBuildingPlanDetails()}
            {props?.activeStepper === 'REPORT_SCRUTINY' && renderBildingPlanReportDetails()}
            {props?.activeStepper === 'LESSEE_SHARE' && renderLesseeShare()}
            {props?.activeStepper === 'PLAN_SCRUTINY' && !!props?.showPopup && (
                <ScruitnyPopupModal isVisible={props?.showPopup} primaryAction={() => {}} size='xl' secondaryAction={() => {}} display='d-none' children={ScrutinySteps(props?.formData || [])} />
            )}

            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={'PDF Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        <GenericPDFViewer fileStoreId={modalData} />
                    </div>
                </GenericPopupModal>
            )}
        </>
    );
}
