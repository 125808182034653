import { useEffect, useRef, useState } from 'react';
import { isAuthenticated } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/authSlice';
import UserAssignmentMenu from './UserAssignmentMenu';
import '../portalComponents/commonStyles.scss';
import './UserHeader.css';

interface IProfileSectionprops {
    headerStyles: string;
}

function ProfileSection(props: IProfileSectionprops) {
    const { headerStyles } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [initials, setInitials] = useState('');
    const [username, setUsername] = useState('');

    const userData = useSelector(selectUser);
    const currentUserAssignment = JSON.parse(localStorage.getItem('currentUserAssignment') ?? 'null');
    const userType = JSON.parse(localStorage.getItem('type') || '{}');

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (userData?.firstName) {
            let initialLetters = userData?.firstName?.[0].toUpperCase() + (userData?.lastName ? userData.lastName[0].toUpperCase() : '');
            // setUsername(userData?.firstName + ' ' + userData?.lastName)
            setUsername(userData?.firstName);
            setInitials(initialLetters);
        } else setUsername('');
    }, [userData]);

    useEffect(() => {
        const handler = (event: any) => {
            if (dropdownOpen && ref.current && !ref.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdownOpen]);

    const onMouseEnter = () => {
        setDropdownOpen(true);
    };

    const onMouseLeave = () => {
        setDropdownOpen(false);
    };

    const closeDropdown = () => {
        dropdownOpen && setDropdownOpen(false);
    };

    return (
        <>
            {isAuthenticated() && (
                <>
                    <div
                        onClick={closeDropdown}
                        ref={ref}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        className={`col-1 ps-1 py-1  d-inline-flex flex-row  profile align-items-center justify-content-around ${headerStyles}`}
                    >
                        <span className='span DP'>{initials}</span>
                        <div className='col-7 lh-sm my-1'>
                            <div className=' fw-bold  '>{username}</div>
                            {userType === 'EMPLOYEE' && (
                                <>
                                    <div className='text-sm'>{currentUserAssignment?.designation?.name}</div>
                                    <div className='text-sm'>{currentUserAssignment?.department?.name}</div>
                                </>
                            )}
                        </div>
                        {dropdownOpen && (
                            <UserAssignmentMenu isMultiRole={userData?.assignments?.length > 1} user={userData?.assignments} setDropdownOpen={setDropdownOpen} isDropdownOpen={dropdownOpen} />
                        )}
                    </div>
                </>
            )}
        </>
    );
}

ProfileSection.defaultProps = {
    isHeader: true
};

export default ProfileSection;
