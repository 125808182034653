import React, { useEffect, useState, useRef } from 'react';
import { DASHBOARD_PAYMENT_DETAIL, firstTimePaymentDetails, getInstallmentData, getPaymentStatus, sortByDueDate } from './Dashboard.const';
import viewAllIcon from '../../../../assets/logos/view all.svg';
import right from '../../../../assets/logos/black right arrow.svg';
import left_paginatioin_arrow from '../../../../assets/logos/left paginatioin arrow .svg';
import right_paginatioin_arrow from '../../../../assets/logos/right paginatioin arrow .svg';
import { Link } from 'react-router-dom';
import { POST, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { convertToINR } from '../../EstateManagement/EstateManagement.controller';
import { convertDateFormatddMMyyyytoyyyyMMdd } from '../../EstateManagement/LeaseDeed/LeaseDeed.controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const PaymentSection = (props: any) => {
    const { t } = useTranslation();
    const { iconProps, currentPlotDetails } = props;
    const userId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    const [paymentCardIndex, setPaymentCardIndex] = useState<any>(null);
    const [paymentTypes, setPaymentTypes] = useState<any>(DASHBOARD_PAYMENT_DETAIL);
    const [hide, setHide] = useState<boolean>(false);
    const [showInstallmentIcon, setShowInstallmentIcon] = useState<boolean>(false);
    const [scrollLeftValue, setScrollLeftValue] = useState<boolean>(false);
    const [allotmentRes, setAllotmentRes] = useState<any>(null);
    const scrollRef = useRef<any>(null);
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;

    const handleIconClick = (index: any) => {
        setPaymentCardIndex(paymentCardIndex === index ? null : index);
    };
    // console.log(scrollLeftValue)
    useEffect(() => {
        (async () => {
            try {
                if (currentPlotDetails?.plotId) {
                    const { data } = await POST(endpoints.allotmentDetailsSearch + `?plotIds=${currentPlotDetails?.plotId}&entityIds=${entityId}`, { RequestInfo: requestInfo() });
                    setAllotmentRes(data.allotmentDetails[0]);
                    // setDocumentList(documentData?.allotmentDetails);

                    const {
                        data: { estateApplicationFeeDetails }
                    } = await POST(endpoints.feeDetailsSearch + `?feeTypes=LAND_PREMIUM&entityIds=${userId}&plotIds=${currentPlotDetails?.plotId}`, {
                        RequestInfo: requestInfo()
                    });

                    const {
                        data: { paymentDetailsResponse }
                    } = await POST(`/idc-estate-service/scheduled-payment-details/_search?plotIds=${currentPlotDetails?.plotId}&entityIds=${userId}`, {
                        RequestInfo: requestInfo()
                    });

                    const paymentData = DASHBOARD_PAYMENT_DETAIL.map((item: any) => {
                        if (item.code === 'LAND_PREMIUM') {
                            const currDataType = estateApplicationFeeDetails.find((details: any) => details.feeType === 'LAND_PREMIUM'); // only needed for 1st time payment & total payable
                            const data = sortByDueDate(paymentDetailsResponse.filter((details: any) => details.paymentType === 'LAND_PREMIUM'));
                            const leaseData = sortByDueDate(paymentDetailsResponse.filter((details: any) => details.paymentType === 'LEASE_RENT'));

                            const newPayment = !data.length && currentPlotDetails.type === 'ALLOTTEE';
                            let showPaymentIcon = false;
                            let status = getPaymentStatus(data, 'LAND_PREMIUM', newPayment);
                            const installmentData = !newPayment
                                ? getInstallmentData(data, 'LAND_PREMIUM', currDataType?.amount, status.status)
                                : firstTimePaymentDetails(leaseData, currDataType?.amount);

                            const allPaid = data.length ? !data.find((item: any) => !item.paidAmount) : false;
                            console.log('allPaid', allPaid);

                            if (currentPlotDetails.type === 'ALLOTTEE' && data.find((item: any) => item.paidAmount)) {
                                showPaymentIcon = false;
                            } else if (data.find((item: any) => !item.paidAmount)) {
                                showPaymentIcon = true;
                            }

                            setShowInstallmentIcon(data.length > 1);
                            return {
                                ...item,
                                ...status,
                                // totalInr: currDataType?.amount || '--', // fee details not neede
                                totalInr:
                                    !allPaid && (status.status === 'DUE' || status.status === 'OVERDUE')
                                        ? !newPayment
                                            ? installmentData.find((installmentItem: any) => {
                                                  console.log('installmentItem', installmentItem);
                                                  return installmentItem.titleCode === 'NEXT_DUE';
                                              })?.amount || '--'
                                            : currDataType?.amount
                                        : '--',
                                installments: installmentData,
                                showPaymentIcon: data.length && showPaymentIcon,
                                newPayment: newPayment ? true : false
                            };
                        } else if (item.code === 'LEASE_RENT') {
                            const data = sortByDueDate(paymentDetailsResponse.filter((details: any) => details.paymentType === 'LEASE_RENT'));
                            const status = getPaymentStatus(data, 'LEASE_RENT');
                            console.log('installments', getInstallmentData(data, 'LEASE_RENT'));

                            return {
                                ...item,
                                ...status,
                                totalInr: getInstallmentData(data, 'LEASE_RENT').find((item: any) => item.titleCode === 'NEXT_DUE')?.amount || '--',
                                installments: getInstallmentData(data, 'LEASE_RENT'),
                                showPaymentIcon: data.length && data.find((item: any) => !item.paidAmount) ? true : false,
                                newPayment: data.length && currentPlotDetails.type === 'ALLOTTEE' && (data.find((item: any) => item.paidAmount) ? false : true)
                            };
                        } else if (item.code === 'HOUSE_TAX') {
                            const data = sortByDueDate(paymentDetailsResponse.filter((details: any) => details.paymentType === 'HOUSE_TAX'));
                            const status = getPaymentStatus(data, 'HOUSE_TAX');
                            return {
                                ...item,
                                ...status,
                                totalInr: getInstallmentData(data, 'HOUSE_TAX').find((item: any) => item.titleCode === 'NEXT_DUE')?.amount || '--',
                                installments: getInstallmentData(data, 'HOUSE_TAX'),
                                showPaymentIcon: data.length && data.find((item: any) => !item.paidAmount) ? true : false
                            };
                        } else if (item.code === 'WATER_CHARGES') {
                            const data = sortByDueDate(paymentDetailsResponse.filter((details: any) => details.paymentType === 'WATER_CHARGES'));
                            const status = getPaymentStatus(data, 'WATER_CHARGES');
                            return {
                                ...item,
                                ...status,
                                totalInr: getInstallmentData(data, 'WATER_CHARGES').find((item: any) => item.titleCode === 'NEXT_DUE')?.amount || '--',
                                installments: getInstallmentData(data, 'WATER_CHARGES'),
                                showPaymentIcon: data.length && data.find((item: any) => !item.paidAmount) ? true : false
                            };
                        }
                    });

                    console.log(paymentData);
                    setPaymentTypes(paymentData);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, [currentPlotDetails?.plotId]);

    useEffect(() => {
        if (paymentCardIndex !== 0 && paymentCardIndex !== null) {
            const addTimeOut = setTimeout(() => {
                const getErrorField = document.querySelector('.payment-card-parents');
                getErrorField?.scrollBy({
                    left: 250,
                    behavior: 'smooth'
                });
            }, 600);
            return () => {
                clearTimeout(addTimeOut);
            };
        }
    }, [paymentCardIndex]);

    const handleScroll = () => {
        const element = scrollRef.current;
        if (element) {
            const isAtExtremeLeft = element.scrollLeft === 0;
            console.log(isAtExtremeLeft);
            setScrollLeftValue(isAtExtremeLeft);
        }
    };

    useEffect(() => {
        const element = scrollRef.current;
        if (element) {
            element.addEventListener('scroll', handleScroll);
            handleScroll();
        }
    }, []);

    const handleLeftPagination = () => {
        const getErrorField = document.querySelector('.payment-card-parents');
        getErrorField?.scrollBy({
            left: -280,
            behavior: 'smooth'
        });
    };

    const handleRightPagination = () => {
        const getErrorField = document.querySelector('.payment-card-parents');
        getErrorField?.scrollBy({
            left: 280,
            behavior: 'smooth'
        });
    };

    console.log('paymentTypes', paymentTypes);
    return (
        <>
            <div className='col-12 p-4 rounded-4 bg-white payment-details border border-1 '>
                <div className='d-flex justify-content-between pe-3'>
                    <span className='payment-heading font-20 fw-semibold lh-25 dashboard-text-color '>Payments</span>
                    {paymentCardIndex !== null && (
                        <span>
                            <FontAwesomeIcon
                                icon={faChevronLeft}
                                className={`me-4 cursor pointer ${!scrollLeftValue && paymentCardIndex !== null ? 'icon-active-color' : 'icon-inactive-color'}`}
                                onClick={handleLeftPagination}
                            />
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={`cursor pointer ${scrollLeftValue && paymentCardIndex !== null ? 'icon-active-color' : 'icon-inactive-color'}`}
                                onClick={handleRightPagination}
                            />
                        </span>
                    )}
                </div>
                <div className='row gy-3 payment-card-parents pt-1 flex-lg-nowrap overflow-x-auto  ' ref={scrollRef}>
                    {paymentTypes?.map((val: any, ind: number) => (
                        <div className={`col-lg-3 pt-3 payment-cards ${paymentCardIndex === ind ? 'col-lg-6 col-md-6 col-sm-6' : ''}  col-md-5 col-sm-5  `}>
                            <div className={` d-flex  border-rad-12 p-3 payment-card${ind + 1}`} style={{ height: '227px' }}>
                                <div className={` d-flex justify-content-between flex-column ${paymentCardIndex === ind ? 'w-50' : 'w-100'}`}>
                                    <div className='p-1'>
                                        <div className='d-flex justify-content-between align-items-start'>
                                            <span className='bg-white icon-background p-2 rounded-2 '>
                                                <img src={val?.icon} alt='' style={{ height: '24px' }} />
                                            </span>
                                            {val.showPaymentIcon || val.newPayment ? (
                                                <Link
                                                    to={
                                                        !val.newPayment
                                                            ? `/goa-idc/estate/payment?type=${val.code}&plotId=${currentPlotDetails?.plotId}&applicationNumber=${allotmentRes?.allotmentId}&applicationId=${allotmentRes?.applicationId}`
                                                            : `/goa-idc/estate/order?plotId=${currentPlotDetails?.plotId}&applicationNumber=${allotmentRes?.allotmentId}&applicationId=${allotmentRes?.applicationId}&activeIndex=2`
                                                    }
                                                    className=' rounded-pill px-2 '
                                                    style={{ border: '.75px solid #0763B8', color: '#0763B8' }}
                                                >
                                                    Pay <img src={viewAllIcon} alt='' />
                                                </Link>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        <div className=' cursor-pointer font-18 fw-semibold mt-3'>{val?.title}</div>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <div className={`fw-500 font-14 lh-14 mb-1 font-color-${ind + 1}`}>{val?.totalpay}</div>
                                        <div className={`font-22 font-inter-med lh-24 mb-1 font-color-${ind + 1}`}>
                                            {typeof val?.totalInr === 'number' ? convertToINR(val?.totalInr) : val?.totalInr}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center mt-1'>
                                            <span className={` d-flex align-items-center  rounded-pill px-2  ${val?.status?.toLowerCase()}`} style={{ padding: '2px' }}>
                                                <img
                                                    className='me-1'
                                                    src={val?.status === 'PAID' ? iconProps[0] : val?.status === 'DUE' ? iconProps[1] : val?.status === 'OVERDUE' ? iconProps[2] : ''}
                                                    alt=''
                                                />
                                                {val?.statusName} {val.status === 'DUE' ? val.installments?.find((item: any) => item.titleCode === 'NEXT_DUE').date : ''}
                                            </span>
                                            <span
                                                className={`cursor-pointer ${paymentCardIndex === ind ? 'rotate-class transition-class' : 'rotate-reverse transition-class'}`}
                                                onClick={() => handleIconClick(ind)}
                                            >
                                                <img src={svgToDataUrl(ind === 0 || ind === 1 ? '#000' : '#fff')} alt='' />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {paymentCardIndex === ind && (
                                    <div className='bg-white text-center  px-3 py-2 rounded-2 ms-3 w-50'>
                                        {val?.installments?.map((instVal: any, instInd: number) =>
                                            !instVal.hide ? (
                                                <div className={` ${instInd === val?.installments.length - 1 ? 'border-bottom-0' : 'border-bottom'}`} style={{ paddingBottom: '8px' }}>
                                                    <div className='fw-500 font-14 lh-14  text-start' style={{ color: '#70777E', padding: '7px 0px 4px 0px' }}>
                                                        {instVal?.title} &nbsp; {instVal?.date}
                                                    </div>
                                                    <div className='font-16 lh-21 text-start  font-inter-med  text-dark '>{instVal?.amount}</div>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                        {ind === 0 && showInstallmentIcon ? (
                                            <Link
                                                to={`/goa-idc/estate/payment?type=${val.code}&plotId=${currentPlotDetails?.plotId}&applicationNumber=${allotmentRes?.allotmentId}&applicationId=${allotmentRes?.applicationId}`}
                                                className=' position-relative '
                                            >
                                                {t('INSTALLMENTS', 'Installments')} <img src={viewAllIcon} alt='' />
                                            </Link>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default PaymentSection;

const svgToDataUrl = (color: any) => `
  data:image/svg+xml;base64,${btoa(`
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5306 6.5306L1.5306 11.5306C1.3897 11.6715 1.19861 11.7506 0.999348 11.7506C0.80009 11.7506 0.608994 11.6715 0.468098 11.5306C0.327201 11.3897 0.248047 11.1986 0.248047 10.9993C0.248047 10.8001 0.327201 10.609 0.468098 10.4681L4.93747 5.99997L0.469348 1.5306C0.399583 1.46083 0.344243 1.37801 0.306486 1.28686C0.26873 1.19571 0.249297 1.09801 0.249297 0.999348C0.249297 0.900686 0.26873 0.802989 0.306486 0.711837C0.344243 0.620685 0.399583 0.537863 0.469348 0.468098C0.539113 0.398333 0.621936 0.342993 0.713088 0.305236C0.80424 0.26748 0.901936 0.248047 1.0006 0.248047C1.09926 0.248047 1.19696 0.26748 1.28811 0.305236C1.37926 0.342993 1.46208 0.398333 1.53185 0.468098L6.53185 5.4681C6.60169 5.53786 6.65707 5.62072 6.69481 5.71193C6.73256 5.80313 6.75193 5.9009 6.75182 5.99961C6.7517 6.09832 6.7321 6.19604 6.69414 6.28715C6.65618 6.37827 6.6006 6.461 6.5306 6.5306Z" fill='${color}'/>
</svg>
  `)}
`;
