import React from 'react';
import { useFormikContext } from 'formik';
import Switch from 'react-switch';
import './GenericStyles.scss';
import { useTranslation } from 'react-i18next';
interface textFieldProps {
    isRequired?: boolean;
    titleCode?: any;
    field: string;
    title: string;
    type?: string;
    isDisabled?: boolean;
    toolTipDetails?: any;
    onChangeAction?: (e: any, field: string) => void;
}
const ToggleSwitch = (props: textFieldProps) => {
    const { setFieldValue, values } = useFormikContext<any>();
    const { t } = useTranslation();

    const handleChange = (checked: any) => {
        setFieldValue(props?.field, checked);
    };

    return (
        <div className='d-flex'>
            <label htmlFor={props.field} className='pb-1' style={{ width: '180px' }}>
                {props.titleCode ? t(props.titleCode) : props.title}
                {props.isRequired && <span className='mandatory-indicator'>*</span>}
            </label>
            <div>
                <Switch checked={values[props?.field]} onChange={handleChange} {...props} disabled={props?.isDisabled} />
            </div>
        </div>
    );
};

export default ToggleSwitch;
