import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { FormDataDTO } from '../../../../utils/constants';

export const PLOT_DETAILS: any[] = [
    {
        titleCode: 'PLOT_DETAILS',
        title: 'Plot Details',
        formData: [
            {
                title: 'Plot Number',
                titleCode: 'PLOT_NUMBER',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDivider: true,
                isDisabled: true
            },
            {
                title: 'Estate Name',
                titleCode: 'ESTATE_NAME',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Phase',
                titleCode: 'PHASE',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Property Type',
                titleCode: 'PROPERTY_TYPE',
                field: 'propertyType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Plot Area (Sq.m.)',
                titleCode: 'PLOT_AREA',
                field: 'plotArea',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false,
                isDisabled: true
            }
        ]
    }
];

export const LESSEE_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Entity Name',
                titleCode: 'ENTITY_NAME',
                field: 'entityName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Email ID',
                titleCode: 'EMAIL_ID',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Mobile Number',
                titleCode: 'MOBILE_NUMBER',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false,
                isDisabled: true
            },

            {
                title: 'PAN No.',
                titleCode: 'PAN_NO',
                field: 'panNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'POSTAL_ADDRESS',
        title: 'Postal Address',
        formData: [
            {
                title: 'Address Line 1',
                titleCode: 'ADDRESS_LINE_1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Address Line 2 ',
                titleCode: 'ADDRESS_LINE_2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                titleCode: 'PIN_CODE',
                field: 'pinCode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'District',
                titleCode: 'DISTRICT',
                field: 'district',
                isDisabled: true,
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'State',
                titleCode: 'STATE',
                field: 'state',
                isDisabled: true,
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Country',
                titleCode: 'COUNTRY',
                field: 'country',
                isDisabled: true,
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            }
        ]
    }
];

export const stepsDetails = [
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'LESSEE_DETAILS',
        name: 'Lessee Details',
        index: 2,
        isActive: true
    }
];

export const stepsDetailsView = [
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'LESSEE_DETAILS',
        name: 'Lessee Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'CLARIFICATION',
        name: 'Clarification',
        index: 3,
        isActive: true
    }
];

export const FIRE_HEALTH_NOC = [
    {
        formData: [
            {
                titleCode: 'NOC_TYPE',
                title: 'NOC Type',
                field: `nocType`,
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-12 px-1 '
            },
            {
                titleCode: 'COMMENTS',
                title: 'Comments',
                field: 'comments',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-sm-12 px-1 '
            }
        ]
    }
];

export const LEASE_DEED_FORWARD_COMMENT = [
    {
        formData: [
            {
                titleCode: 'COMMENTS',
                title: 'Comments',
                field: 'leaseDeedcomments',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-sm-12 px-1 '
            }
        ]
    }
];

export const LEASE_DEED_STEPS_DETAILS_ENTERPRISE_LOGIN = [
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'ALLOTTEE_DETAILS',
        name: 'Allottee Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'SELF_DECLARATIONS',
        name: 'Self Declarations',
        index: 3,
        isActive: false
    }
];
export const LEASE_DEED_STEPS_DETAILS_EMPLOYEE_LOGIN = [
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'ALLOTTEE_DETAILS',
        name: 'Allottee Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'SELF_DECLARATIONS',
        name: 'Self Declarations',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'DRAFT_DEED',
        name: 'Draft Deed',
        index: 4,
        isActive: false
    },
    {
        titleCode: 'PREVIEW',
        name: 'Preview',
        index: 5,
        isActive: false
    },
    {
        titleCode: 'REGISTRATION_PREVIEW',
        name: 'Registration preview',
        index: 6,
        isActive: false
    },
    {
        titleCode: 'NOTIFICATION_DETAILS',
        name: 'Notification Details',
        index: 7,
        isActive: false
    }
];
