import { isDisabled } from '@testing-library/user-event/dist/utils';
import { formFieldDTO } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import _ from 'lodash';
import { entityDocumentData } from '../../EstateManagement/EstateManagement.controller';

export const SEARCH_LOA_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'LOA_NUMBER',
                title: 'Enter LOA Number',
                field: 'loaNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5',
                isRequired: true
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'estate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];

export const PBG_POPUP_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'PBG_AMOUNT',
                title: 'PBG Amount',
                field: 'amount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            // {
            //     titleCode: 'PBG_VALIDITY',
            //     title: 'PBG Validity',
            //     field: 'pbgValidity',
            //     type: CONSTANT_DATA_FIELD.DATE_FIELD,
            //     isRequired: true
            // },
            {
                titleCode: 'PBG_REF_NO',
                title: 'PG No.',
                field: 'pgNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'PBG_ACCOUNT_NO',
                title: 'Bank Name',
                field: 'bankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'PBG_DOCUMENTS',
                title: 'PBG Documents',
                field: 'documentPbg',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPE.OTHER
                }
            }
        ]
    }
];

//table
export const LOA_DATA_COLUMN: any = [
    {
        titleCode: '',
        colName: '',
        fieldName: 'radio',
        clsName: 'column-text-start col-1'
    },
    {
        titleCode: 'LOA_NO',
        colName: 'LOA No',
        fieldName: 'loaNumber',
        clsName: 'column-text-start col-3'
    },
    {
        titleCode: 'PROJECT_NAME',
        colName: 'Project Name',
        fieldName: 'projectName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'INDUSTRIAL_NAME',
        colName: 'Industrial Estate Name',
        fieldName: 'industrialName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'PROJECT_TYPE',
        colName: 'Project Type',
        fieldName: 'projectType',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'ESTIMATE_AMOUNT',
        colName: 'Estimate Amount',
        fieldName: 'estimateAmount',
        clsName: 'column-text-start col-3'
    }
];

//table

export const PBG_DATA_COLUMN: any = [
    {
        titleCode: 'PBG_REF_NO',
        colName: 'PG No.',
        fieldName: 'pgNo',
        cellstyle: { width: '200px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'PBG_AMOUNT',
        colName: 'PBG Amount',
        fieldName: 'amount',
        cellstyle: { width: '250px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'PBG_ACCOUNT_NO',
        colName: 'Bank Name',
        fieldName: 'bankName',
        cellstyle: { width: '250px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'DOCUMENTS',
        colName: 'Documents',
        fieldName: 'docName',
        cellstyle: { width: '250px' },
        clsName: 'column-text-start'
        // type: 'document'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        cellstyle: { marginLeft: 'auto' },
        actions: ['edit', 'view', 'delete']
    }
];

//Correspondence
export const LOA_CORRESPONDENCE = [
    {
        title: '(A) LOA Details',
        field: 'loaDetails'
    },
    {
        title: '(B) PBG Details',
        field: 'pbgDetails'
    }
];

//pop-up fields
export const LOA_FIELDS_I: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Project Name',
                field: 'projectName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-12',
                isDisabled: true
            },
            {
                title: 'Bid Price',
                field: 'bidPrice',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Details of Awarded Bidder',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'LOA Number',
                field: 'loaNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'LOA Issuance date',
                field: 'loaIssuanceDate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            }
        ]
    }
];

export const LOA_FIELDS_II = [
    {
        titleCode: 'ADDITIONAL_DETAILS',
        title: 'Additional Details',
        formData: [
            {
                title: 'LOA Acceptance Date',
                field: 'loaAcceptanceDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true
            },
            {
                title: 'Stipulated Date Of Commencement',
                field: 'stipulatedDateCommencement',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true
            },
            {
                title: 'Stipulated Date Of Completion',
                field: 'stipulatedDateCompletion',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true
            },
            {
                titleCode: 'SIGNED_COPY_OF_LOA',
                title: 'Signed copy of LOA',
                field: 'documentLoa',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPE.OTHER
                },
                clsName: 'col-sm-6 me-2',
                isRequired: true
            }
        ]
    }
];

export const workOrderDetailValues = async (resData: any, applicationNumber?: any) => {
    const workOrderInitValues: any = {};

    workOrderInitValues['projectName'] = resData?.loaDetails?.dprHeaderDetails?.projectName;
    workOrderInitValues['bidPrice'] = resData?.loaDetails?.bidPrice;
    workOrderInitValues['name'] = resData?.loaDetails?.bidderDetails?.name;
    workOrderInitValues['loaNo'] = resData?.loaDetails?.loaNo;
    workOrderInitValues['loaIssuanceDate'] = resData?.loaDetails?.loaIssuanceDate;
    workOrderInitValues['loaAcceptanceDate'] = resData?.loaAcceptanceDate;
    workOrderInitValues['stipulatedDateCommencement'] = resData?.stipulatedDateCommencement;
    workOrderInitValues['stipulatedDateCompletion'] = resData?.stipulatedDateCompletion;
    workOrderInitValues['documentLoa'] = resData?.loaSignedDocRefId ? await entityDocumentData(resData?.loaSignedDocRefId) : null;
    return workOrderInitValues;
};

export const setWoPbgData = async (resData: any) => {
    const data = resData
        ? await Promise.all(
              resData?.map(async (item: any) => {
                  const docDetails = item?.pbgDocRefId && (await entityDocumentData(item?.pbgDocRefId));
                  return {
                      ...item,
                      amount: item?.amount,
                      pgNo: item?.pgNo,
                      securityValidity: item?.securityValidity,
                      bankName: item?.bankName,
                      //   docName: item?.pbgDocRefId,
                      docName: docDetails?.documentName || null,
                      rowId: item.uuid
                  };
              })
          )
        : [];
    return data;
};

export const WORK_ORDER_APPLICATION_SEARCH = [
    {
        titleCode: 'SERIAL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'column-text-start col-1'
    },
    {
        titleCode: 'WO_NO',
        colName: 'Work Order No.',
        fieldName: 'woNumber',
        clsName: 'column-text-start col-2',
        isClickable: true
    },
    {
        titleCode: 'VENDOR_NAME',
        colName: 'Vendor Name',
        fieldName: 'vendorName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'PROJECT_NAME',
        colName: 'Project Name',
        fieldName: 'projectName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'INDUSTRIAL_NAME',
        colName: 'Industrial Estate Name',
        fieldName: 'industrialName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'PROJECT_TYPE',
        colName: 'Project Type',
        fieldName: 'projectType',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'ESTIMATE_AMOUNT',
        colName: 'Estimate Amount',
        fieldName: 'estimateAmount',
        clsName: 'column-text-start col-1'
    }
];

export const WO_APPLICATION_SEARCH: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'WO_NUMBER',
                title: 'Work Order Number',
                field: 'woNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5',
                isRequired: true
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'estate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];
