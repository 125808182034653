/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Image } from 'react-bootstrap';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { arrowUp, envelope, globe, map, phone, user } from '../../utils/Logo';
import { logo_img } from '../../utils/Images';
import HeaderComponent from '../../components/header/Header.component';
import FooterComponent from '../../components/portalComponents/Footer.component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const address = ' Goa Industrial Development Corporation, Panaji, Goa - 403 001'.replace(/ /g, '+');
const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;

const Contact = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <HeaderComponent />
            <div className='vh-100' style={{ background: '#F5F5F6' }}>
                <div className='container py-5 pt-5 '>
                    <div className='row '>
                        <div className=' text-start font-inter-md ms-4 ps-5 mb-3 breadcrumbs'>
                            <span style={{ color: '#0763B8' }}>
                                {' '}
                                <Link to='/goa-idc/'> {t('HOME', 'Home')} </Link>
                            </span>
                            / {t('CONTACT_US', 'Contact Us')}
                        </div>
                        <div className=' text-start font-inter-md ms-4 ps-5'>
                            <h2 className='font-inter-md' style={{ fontSize: '48px' }}>
                                {t('CONTACT_US', 'Contact Us')}
                            </h2>
                        </div>
                        <div className='col-sm-12   '>
                            <div className='row font-karla-reg d-flex justify-content-evenly   '>
                                <div className={`col-sm-3 mt-3 px-4 py-3 bg-white  text-start`} style={{ border: '1px solid #E0E0E0' }}>
                                    <div className='text-white fs-1 fw-bold'>
                                        <Image src={phone} height={64} width={64} />
                                    </div>
                                    <div className='fs-6 fw-bold pt-3'>{t('PHONE', 'Phone')}</div>
                                    <div className={`cardSubText_1 pt-2`}>
                                        <a href='tel:+91 0832 2437470' className='text-decoration-none' style={{ color: '#272727' }}>
                                            +91 8322437470-73
                                        </a>
                                    </div>
                                </div>
                                <div className={`col-sm-3 mt-3 px-4 py-3 bg-white  text-start`} style={{ border: '1px solid #E0E0E0' }}>
                                    <div className='text-white fs-1 fw-bold'>
                                        {/* <Link to='mailto:goa-idc@goa.gov.in'> */}
                                        <Image src={envelope} height={64} width={64} />
                                        {/* </Link> */}
                                    </div>
                                    <div className='fs-6 fw-bold pt-3'>{t('EMAIL', 'Email')}</div>
                                    <div className={`cardSubText_1 pt-2`}>
                                        <a href='mailto:goa-idc@goa.gov.in' className='text-decoration-none' style={{ color: '#272727' }}>
                                            goa-idc@goa.gov.in
                                        </a>
                                        <br />
                                        <a href='mailto:ithelpdesk-gidc@goa.gov.in' className='text-decoration-none' style={{ color: '#272727' }}>
                                            ithelpdesk-gidc@goa.gov.in
                                        </a>
                                    </div>
                                </div>
                                <div className='col-sm-3 mt-3 px-4 py-3 bg-white text-start' style={{ border: '1px solid #E0E0E0' }}>
                                    <div className='text-white fs-1 fw-bold'>
                                        <Link to={mapsUrl} target='_blank'>
                                            <Image src={map} height={64} width={64} />
                                        </Link>
                                    </div>
                                    <div className='fs-6 fw-bold pt-3'>{t('ADDRESS', 'Address')}</div>
                                    <div className={`cardSubText_1 pt-2`}>{t('GOA_IDC_ADDRESS_LINE2', 'Plot No. 13A-2, EDC Complex Patto Plaza')}</div>
                                    <div className='cardSubText_1'>{t('GOA_IDC_ADDRESS_LINE3', 'Panaji-Goa, 403 001')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    );
};

export default Contact;
