// authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserRequestInterface } from "../features/modules/UserManagement/types";

interface AuthState {
  user: UserRequestInterface;
  token?: string | null;
}

const initialState: AuthState = {
  user: {} as unknown as UserRequestInterface
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserRequestInterface>) => {
      state.user = action.payload;
    },
    clearAuth: (state) => {
      state.token = null;
    }
  }
});

export const { setUser, clearAuth } = authSlice.actions;
export const selectUser = (state: { auth: AuthState }) => state.auth.user;
export const selectToken = (state: { auth: AuthState }) => state.auth.token;

export default authSlice.reducer;
