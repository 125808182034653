import React from "react";
import style from "./MiddleBar.module.css";
import MiddleBarSubComponent from "./MiddleBar.subcomponent";
import { useTranslation } from "react-i18next";

export default function MiddleBarComponent() {
    const { t } = useTranslation();

    return (
        <div>
            <div className="container-fluid" style={{ background: "#323E4A" }}>
                <div className="row">
                    <div className="col-sm-3 p-5">
                        <div className="text-white fa fa-3x fw-bold font-inter-med">{t("FIGURE_1", "96+")}</div>
                        <div className={`${style.subText} font-karla-reg pt-3`}>
                            {t("EODB_DESCRIPTION", "Measures taken to improve the Ease of Doing Business (EoDB) by 8 positions in the last assessment round.")}
                        </div>
                    </div>
                    <div className="col-sm-3 p-5">
                        <div className="text-white fa fa-3x fw-bold font-inter-med">{t("FIGURE_2", "491K")}</div>
                        <div className={`${style.subText} font-karla-reg pt-3`}>
                            {t("PER_CAPITA_DESCRIPTION", "Goa has India’s highest per capita income and is also India’s top tourist destination making it a strong consumer market.")}
                        </div>
                    </div>
                    <div className="col-sm-3 p-5">
                        <div className="text-white fa fa-3x fw-bold font-inter-med">{t("FIGURE_3", "75%")}</div>
                        <div className={`${style.subText} font-karla-reg pt-3`}>
                            {t("URBANIZATION_DESCRIPTION", "Urbanization Rate - Goa features excellent health and educational facilities, making it an attractive workplace.")}
                        </div>
                    </div>
                    <div className="col-sm-3 p-5">
                        <div className="text-white fa fa-3x fw-bold font-inter-med">{t("FIGURE_4", "24")}</div>
                        <div className={`${style.subText} font-karla-reg pt-3`}>
                            {t("INDUSTRIAL_ESTATE_DESCRIPTION", "Goa-IDC manages 24 industrial estates spread across the entire state, ensuring balanced regional development.")}
                        </div>
                    </div>
                </div>
            </div>
            <MiddleBarSubComponent />
        </div>
    );
}
