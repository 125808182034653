import React from 'react'
import { Nav } from 'react-bootstrap'
import HeaderComponent from '../../components/header/Header.component'
import FooterComponent from '../../components/portalComponents/Footer.component'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Disclaimer = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <HeaderComponent />
            <div className='vh-100 font-inter' style={{ background: "#F5F5F6" }}>
                <div className="container py-5 pt-5 " >
                    <div className="row  disclaimer" >
                        <div className=" text-start font-inter-md  mb-2 breadcrumbs"><span style={{ color: "#0763B8" }} ><Link to="/goa-idc/">{t("HOME", "Home")} </Link></span>/ {t("DISCLAIMER", "Disclaimer")}</div>
                        <div className=" text-start font-inter-md mb-4 "><h2 className="" style={{ fontSize: "48px" }}>{t("DISCLAIMER", "Disclaimer")}</h2></div>
                        <div className="col-sm-12 pe-5   " >

                            <p className='m-0 ' >{t("DISCLAIMER_PARA1", `This site is provided by Goa Industrial Development Corporation on an "as is" and "as available" basis. Goa Industrial Development Corporation makes no representations or warranties of any kind, express or implied, as to the operation of this site or the information, content, materials, or products included on this site. You expressly agree that your use of this site is at your sole risk.`)}</p>
                            <p >{t("DISCLAIMER_PARA2", "To the full extent permissible by applicable law ")}<Link className='d-inline text-primary text-decoration-underline' target='_blank' to="https://idc-stage.goa.gov.in/">{t("OUR_WEBSITE", "our website")}</Link> {t("DISCLAIMER_PARA3", " disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. ")}<Link className='d-inline text-primary text-decoration-underline' target='_blank' to="https://idc-stage.goa.gov.in/">{t("OUR_WEBSITE", "our website")}</Link> {t("DISCLAIMER_PARA4", "does not warrant that the site, its servers, or email sent from ")}<Link className='d-inline text-primary text-decoration-underline' target='_blank' to="https://idc-stage.goa.gov.in/">{t("OUR_WEBSITE", "our website")}</Link> {t("DISCLAIMER_PARA5", "are free of virus or other harmful components.")} <Link className='d-inline text-primary text-decoration-underline' target='_blank' to="https://idc-stage.goa.gov.in/">{t("OUR_WEBSITE", "Our website")}</Link>  {t("DISCLAIMER_PARA6", " will not be liable for any damages of any kind arising from the use of this site, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.")}</p>
                        </div>

                    </div>
                </div>
            </div>
            <FooterComponent />

        </>
    )
}

export default Disclaimer
