import { useTranslation } from 'react-i18next';

const WorklistCard = (props: any) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='col-sm-6 col-12 col-md-4 col-lg-2'>
                <div className='col-sm border Estate p-4' style={{ backgroundColor: props.cardStyle, width: '120%' }}>
                    <div className='pb-3 ps-2 d-flex justify-content-start align-items-center '>
                        <img className='Eearth' src={props?.logo1} alt='' />
                        {/* <i className='Eearth fa fa-globe' /> */}
                        <div className={`d-inline ms-3 card-truncate ${props.headingColor}`} title={t(props?.heading)} style={{ fontSize: '20px', width: '90%' }}>
                            {t(props?.heading)}
                        </div>
                    </div>
                    <div className={`row d-flex justify-content-evenly gy-2 gy-sm-0 `}>{props?.children}</div>
                    <div className={`pt-3 ps-2 d-flex flex-wrap column-gap-1 row-gap-1 `}>{props.footer}</div>
                </div>
            </div>
        </>
    );
};

export default WorklistCard;
