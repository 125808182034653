import _ from 'lodash';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';

export const INSPECTION_REPORT_CARD = [
    {
        titleCode: 'A_LESSEE_DETAILS',
        title: 'A - Lessee Details',
        field: 'lesseeDetails'
    },
    {
        titleCode: 'B_PLOT_DETAILS',
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        titleCode: 'C_INSPECTION_DETAILS',
        title: 'C - Inspection Details',
        field: 'inspectionDetails'
    }
];

export const INSPECTION_DETAILS = [
    {
        formData: [
            {
                titleCode: 'INSPECTION_DATE',
                title: 'Inspection Date',
                field: 'inspectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },
            {
                titleCode: 'UNAUTHORIZED_CHANGE',
                title: 'Unauthorized change in activity',
                field: 'unauthorizedChangeInActivity',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                titleCode: 'UNAUTHORIZED_CONSTRUCTION',
                title: 'Unauthorized construction/development',
                field: 'unauthorizedConstruction',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                titleCode: 'UNAUTHORIZED_SUB_LEASE',
                title: 'Unauthorized Sub-Lease',
                field: 'unauthorizedSubLease',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                titleCode: 'ENCROACHMENT',
                title: 'Encroachment',
                field: 'encroachment',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                titleCode: 'OTHER_ISSUE',
                title: 'Any other issue',
                field: 'otherIssue',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-sm-6 me-3'
            },
            {
                titleCode: 'REMARKS',
                title: 'Remarks',
                field: 'remarks',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-sm-12'
            }
        ]
    }
];

export const UNAUTHORIZED_SUB_LEASE = [
    {
        title: 'Unauthorized sub-lease',
        titleCode: 'UNAUTHORIZED_SUB_LEASE',
        formData: [
            {
                titleCode: 'AREA_OF_SUBLEASE',
                title: 'Area of unauthorized Sub-Lease (sqm)',
                field: 'areaOfSubLease',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'DURATION',
                title: 'Duration (in months)',
                field: 'duration',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'PENALTY_INTEREST',
                title: 'Penalty Interest (%)',
                field: 'penaltyInterest',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'ILLEGAL_SUB_LEASE_FEE',
                title: 'Illegal Sub-Lease Fee (INR)',
                field: 'illegalSubleaseFee',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Sub-Lease fee on Unauthorized Sub-Lease area + 8% penalty on the same</p>'
                }
            }
        ]
    }
];

export const UNAUTHORIZED_CONSTRUCTION = [
    {
        titleCode: 'UNAUTHORIZED_CONSTRUCTION',
        title: 'Unauthorized construction/development',
        formData: [
            {
                titleCode: 'UNAUTHORIZED_CONSTRUCTION_FEE',
                title: 'Unauthorized construction Fee (INR)',
                field: 'unauthorizedConstructionFee',
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Enterprise user needs to pay 3 times the CL fee to regularize the illegal construction.</p>'
                },
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            }
        ]
    }
];

export const UPLOADING_DOCUMENT = [
    {
        formData: [
            {
                titleCode: 'UPLOADING_DOCUMENT_FOR',
                title: 'Uploading document for',
                field: 'uploadingDocumentFor',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-6 me-3'
            }
        ]
    }
];

export const UPLOAD_DOCUMENT = [
    {
        formData: [
            {
                titleCode: 'ATTACHMENT',
                title: 'Attachment',
                field: 'attachment',
                type: CONSTANT_DATA_FIELD.MULTI_FILE_FIELD,
                class: 'col-6',
                hideMultiFileValue: true,
                // isRequired: true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'documentPrefix',
                    documentType: '',
                    referenceType: ''
                },
                fileTypes: ['pdf', 'jpg', 'jpeg']
            }
        ]
    }
];

export const TABLE_COLUMN_IR = [
    {
        colName: 'SN',
        titleCode: 'SERIAL_NO',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Document Name',
        titleCode: 'DOCUMENT_NAME',
        fieldName: 'documentName',
        clsName: 'col-sm-5 column-text-start',
        id: 2,
        isClickable: true
    },
    {
        colName: 'Reference',
        titleCode: 'REFERENCE',
        fieldName: 'documentTypeDisplayName',
        clsName: 'col-sm-5',
        id: 3
    },
    {
        colName: 'Action',
        titleCode: 'ACTION',
        fieldName: 'action',
        clsName: 'col-sm-1',
        actions: ['delete'],
        id: 4
    }
];

export const getInspectionDetails = (data: any) => {
    const initialData: any = {};

    initialData['inspectionDate'] = data?.inspectionDate ? `${data?.inspectionDate?.split('/')[2]}-${data?.inspectionDate?.split('/')[1]}-${data?.inspectionDate?.split('/')[0]}` : '';
    initialData['unauthorizedChangeInActivity'] = data?.unauthorizedChangeInActivity;
    initialData['unauthorizedConstruction'] = data?.unauthorizedConstruction;
    initialData['unauthorizedSubLease'] = data?.unauthorizedSubLease;
    initialData['encroachment'] = data?.encroachment;
    initialData['otherIssue'] = data?.otherIssuePresent;
    initialData['remarks'] = data?.remarks;
    initialData['areaOfSubLease'] = data?.unauthorizedSubLeaseArea || '';
    initialData['duration'] = data?.unauthorizedSubLeaseDuration || '';
    initialData['illegalSubleaseFee'] = data?.totalPenaltyAmount || '';
    initialData['unauthorizedConstructionFee'] = data?.unauthorizedConstructionAmount || '';

    return initialData;
};
