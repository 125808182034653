import React, { useEffect, useState } from 'react';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { PROPOSED_ACTIVITY_COLUMN } from './Allotment.const';
import { useTranslation } from 'react-i18next';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import { PROPOSED_ACTIVITY_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { useFormikContext } from 'formik';
import ProposedActivity from './ProposedActivity';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { getFirstErrorKey } from '../../../../utils/utils';
import PopUps from '../../../../components/PopUps';
import { cross } from '../../../../utils/Logo';

const NestedProposedActivityComponent = (props: any) => {
    const { isDisabled, setSubleaseNicDetails, subleaseNicDetails, subleaseId } = props;
    const proposedActivityColumns = PROPOSED_ACTIVITY_COLUMN;
    const { values, setValues, setTouched, setFieldTouched, errors } = useFormikContext<any>();
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState<any>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [openIndex, setOpenIndex] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState({});
    const [showPopUp, setShowPopUp] = useState(false);

    const promoterTooltip = {
        position: 'bottom',
        content: '<p>Promoter Details</p>'
    };

    const addProposedActivity = async () => {
        try {
            await PROPOSED_ACTIVITY_VALIDATION.validate(values, { abortEarly: false });
            setShowPopUp(false);
        } catch (validationError) {
            setFieldTouched('description', true);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                await setFieldTouched(errorField, true, true);
            }
            let errMsg = 'Please fill in all the required details!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        const newUuid = uuidv4();

        const activityPayload: any = {
            proposedActivity: values?.proposedActivity,
            subClassAndsubClassDescription: values?.description?.value + '-' + values?.description?.label,
            description: values?.description,
            subClass: values?.subClass,
            group: values?.group,
            class: values?.class,
            rowId: isEditing ? values.rowId : newUuid,
            nicMstId: values?.nicMstId
        };

        if (isEditing) {
            const updatedData = subleaseNicDetails[subleaseId].map((data: any) => {
                if (data.rowId === isEditing) return activityPayload;
                else return data;
            });
            // setNicDetails([...updatedData]);
            setSubleaseNicDetails({ ...subleaseNicDetails, [subleaseId]: [...updatedData] });
        } else {
            // setNicDetails([...nicDetails, activityPayload]);
            setSubleaseNicDetails({ ...subleaseNicDetails, [subleaseId]: [...subleaseNicDetails[subleaseId], activityPayload] });
        }
        setShowPopUp(false);
    };

    const showForm = () => {
        const val = _.cloneDeep(values);

        setIsEditing(false);
        const keysToDelete = ['proposedActivity', 'description', 'subClass', 'group', 'class'];
        keysToDelete.forEach(key => {
            delete val[key];
        });
        setValues({ ...val });
        setTouched({});
        setShowPopUp(true);
        setDisabled(false);
        setOpenIndex(null);
    };

    const onDeleteData = (rowId = null) => {
        let newData = subleaseNicDetails[subleaseId]?.filter((data: any) => data.rowId !== rowId);
        // setNicDetails(newData);
        setSubleaseNicDetails({ ...subleaseNicDetails, [subleaseId]: newData });
        setShowPopUp(false);
        setIsEditing(false);
    };

    const onEdit = (data: any, status: boolean = false) => {
        const detailObj: any = subleaseNicDetails[subleaseId].find((item: any, _: number) => item.rowId === data.rowId);
        const index: any = subleaseNicDetails[subleaseId].findIndex((item: any, _: number) => item.rowId === data.rowId);
        setOpenIndex(index > -1 ? index + 1 : null);
        if (!detailObj) return;

        const formData = {
            proposedActivity: detailObj.proposedActivity,
            description: detailObj.description,
            subClass: detailObj.subClass,
            group: detailObj.group,
            class: detailObj.class,
            rowId: detailObj.rowId ? detailObj.rowId : null,
            isUpdate: detailObj.rowId ? true : null
        };

        setIsEditing(detailObj.rowId);
        setValues({ ...values, ...formData });
        setShowPopUp(true);
        setDisabled(status);
    };

    const onView = (status: boolean, data: any) => {
        setDisabled(status);
        // const index: any = nicDetails.findIndex((item: any, _: number) => item.rowId === data.rowId);
        // setOpenIndex(index > -1 ? index + 1 : null);
        onEdit(data, status);
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <div className='ownership'>
                {/* <Card> */}
                <div className='row justify-content-between row-gap-4 pe-4 ps-2 align-items-center  '>
                    <div className='col-9'>
                        <p className='ps-2 ownership_heading d-flex'>{t('PROPOSED_ACTIVITY', 'Proposed Activity')}</p>
                    </div>
                    <GenericButtons text={t('ADD', '+ ADD')} handleClick={showForm} variant='primary' disabled={isDisabled} />
                </div>
                <div className='mt-3'>
                    {/* {nicDetails.length > 0 && ( */}
                    <OwnershipTable
                        column={proposedActivityColumns}
                        tableRowData={subleaseNicDetails[subleaseId]}
                        additionalMethod={onEdit}
                        deleteOwnershipDetails={onDeleteData}
                        updateOwnershipDataStatus={onView}
                        hideSearchBar={true}
                        isDisabled={isDisabled}
                        scrollNotRequired={isDisabled}
                    />
                    {/* )} */}
                </div>
                {/* </Card> */}
            </div>
            <GenericPopupModal
                onClose={() => setShowPopUp(false)}
                secondaryAction={() => {}}
                primaryAction={addProposedActivity}
                primaryBtnTitle='Save'
                isPrimaryBtn={!disabled}
                isSecondaryBtn={false}
                size={'xl'}
                isVisible={showPopUp}
                title={`${openIndex ? (disabled ? 'View' : 'Edit') : 'Add'} Proposed Activity - ${openIndex ? openIndex : subleaseNicDetails[subleaseId]?.length + 1}`}
                // title={`${openIndex ? (disabled ? 'View' : 'Edit') : 'Add'} Proposed Activity`}
                children={<ProposedActivity disabled={disabled} />}
            />
        </>
    );
};

export default NestedProposedActivityComponent;
