import React, { useEffect, useState } from 'react';
import './ScrutinyFormPage.css';
import { Button, Card } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Clearance_Form_Initial_Data, VALIDATION_SCRUTINY_FORM } from './ScrutinyFormPage.module';
import { toast } from 'react-toastify';
import ScrutinyFormSubPage from './ScrutinyFormSubPage.wrapper';
import Loading from '../../components/portalComponents/Loading.component';
import GenericFooterComponentScrutiny from './GenericFooterStepperScrutiny.component';
import HeaderComponent from '../../components/header/Header.component';
import { extractFileUrl, getNavigateTo } from '../../utils/utils';
import TechnicalServices, { mainRoute } from '../../services/technicalServices/Technical.service';
import { BPArequestInfo, devUrl, requestInfo } from '../../services/modulesServices/userManagement.api';
import { LesseeShareFormJson } from './ScrutinyFormPage.controller';
import { getFileurl } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.controller';
import SideCard from '../../components/moduleCommonFormik/SideCardGenericComponent';
import GenericBackButton from '../../components/UI components/Buttons/GenericBackButton';
import { useTranslation } from 'react-i18next';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import { check, cross } from '../../utils/Logo';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import GenericFooterComponent from '../../components/moduleCommonFormik/GenericFooterStepper.component';
import axios from 'axios';

export default function ScrutinyFormPage() {
    const { t, i18n } = useTranslation();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const { state } = useLocation();
    const urlParams = new URLSearchParams(window.location.search);
    const edcrNumber = urlParams.get('edcr') || '';
    const [modalDetails, setModalDetails] = useState<any>({});

    const steperDetails = [
        {
            name: 'Plan Scrutiny Details',
            code: 'PLAN_SCRUTINY',
            titleCode: 'PLAN_SCRUTINY',
            index: 1,
            isActive: false
        },
        {
            name: 'Scrutiny Report',
            code: 'REPORT_SCRUTINY',
            titleCode: 'REPORT_SCRUTINY',
            index: 2,
            isActive: false
        }
    ];
    const steperDetailsView = [
        {
            name: 'Scrutiny Report',
            code: 'REPORT_SCRUTINY',
            titleCode: 'REPORT_SCRUTINY',
            index: 1,
            isActive: false
        },
        {
            name: 'Additional Details',
            code: 'LESSEE_SHARE',
            titleCode: 'ARC_BTN_LESSEE_SHARE',
            index: 2,
            isActive: false
        }
    ];

    const [activeIndex, setActiveIndex] = useState<number>(1);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [isLesseeShare, setIsLesseeShare] = useState<boolean>(false);
    const [applId, setAppId] = useState<string>('');
    const [employeeData, setEmployeeData] = useState(null);
    const initialValues = Clearance_Form_Initial_Data;
    const [activeStepper, setActiveStepper] = useState<string>('');
    const [stepsDetails, setStepsDetails] = useState<any>(edcrNumber ? steperDetailsView : steperDetails);
    const [viewData, setViewData] = useState<any>(null);
    const [sitePlanData, setSitePlanData] = useState<any>(null);

    const formik = useFormik<any>({
        // validateOnBlur: true,
        // validateOnChange: true,
        initialValues: initialValues,
        onSubmit: value => {},
        validationSchema: VALIDATION_SCRUTINY_FORM[`step_${activeIndex}`]
    });

    const { values, resetForm, setValues, setFieldValue } = formik;

    useEffect(() => {
        if (!!state) {
            setViewData(state?.rowData);
            viewDefaultLesseeDetails(state?.rowData);
            setFieldValue('isEditableFile', false);
        } else {
            setFieldValue('isEditableFile', true);
        }
        getActiveStepper();
    }, [activeIndex]);

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({ show: false });
    };

    const onChangeNextIndex = async (key: number) => {
        const stepperLength = stepsDetails.length;
        if (activeIndex < stepperLength) {
            try {
                if (activeIndex !== 4) {
                    if (activeStepper === 'PLAN_SCRUTINY') await VALIDATION_SCRUTINY_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
                    if (activeStepper === 'PLAN_SCRUTINY') {
                        setIsLoading(true);
                        /// api call
                        var userinfo = window.localStorage.getItem('user');
                        let edcrRequest = {
                            transactionNumber: 0,
                            edcrNumber: null,
                            planFile: null,
                            tenantId: '',
                            applicantName: '',
                            appliactionType: '',
                            applicationSubType: '',
                            generateSitePlanPDF: false,
                            RequestInfo: {
                                apiId: '',
                                ver: '',
                                ts: '',
                                action: '',
                                did: '',
                                authToken: null,
                                key: '',
                                msgId: '',
                                correlationId: '',
                                userInfo: userinfo !== null ? JSON.parse(userinfo) : null
                            }
                        };
                        var bodyFormData = new FormData();
                        let tenantId = 'ga';
                        let applicantName = '';
                        let appliactionType = 'TECHNICAL_CLEARANCE';
                        let applicationSubType = values?.serviceType;
                        let transactionNumber = new Date().getTime();
                        edcrRequest = { ...edcrRequest, tenantId };
                        edcrRequest = { ...edcrRequest, transactionNumber };
                        edcrRequest = { ...edcrRequest, applicantName };
                        edcrRequest = { ...edcrRequest, appliactionType };
                        edcrRequest = { ...edcrRequest, applicationSubType };
                        bodyFormData.append('edcrRequest', JSON.stringify(edcrRequest));
                        bodyFormData.append('planFile', values?.buildingPlan[0]?.file);
                        bodyFormData.append('generateSitePlanPDF', values?.generateSitePlanPDF || true);
                        bodyFormData.append('origin', mainRoute);
                        TechnicalServices.createScrutinize(bodyFormData)
                            .then(response => {
                                const edcrDetails = response?.data?.edcrDetail[0];
                                setFormData(response?.data);
                                setShowPopup(true);

                                setTimeout(() => {
                                    setShowPopup(false);
                                    if (key === 1) setActiveIndex(activeIndex + 1);
                                    if (!!edcrDetails?.edcrNumber && !!edcrDetails?.sitePlan) getLesseeData(edcrDetails?.planDetail?.planInformation?.lesseeName, edcrDetails);
                                }, 19000);
                                setIsLoading(false);
                            })
                            .catch(error => {
                                // handle errors
                                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
                                setIsLoading(false);
                            });
                    } else {
                        viewData?.edcrNumber ? setActiveIndex(activeIndex + 1) : submitForm(key);
                    }
                }
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details'), img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const setDefaultLesseeDetails = (edcrDetails: any, uuid: string) => {
        const usersData = localStorage.getItem('user');
        const users = usersData !== null ? JSON.parse(usersData) : null;
        const formDetails = edcrDetails?.planDetail?.planInformation;
        const edcrReports = {
            dxfFile: edcrDetails?.dxfFile,
            updatedDxfFile: edcrDetails?.updatedDxfFile,
            planReport: edcrDetails?.planReport,
            dxfFileUUID: edcrDetails?.dxfFile?.split('fileStoreId=')[1],
            updatedDxfFileUUID: edcrDetails?.updatedDxfFile?.split('fileStoreId=')[1],
            planReportUUID: edcrDetails?.planReport?.split('fileStoreId=')[1],
            transactionNumber: edcrDetails?.transactionNumber,
            applicationNumber: edcrDetails?.applicationNumber,
            status: edcrDetails?.status,
            plotNo: formDetails?.plotNo,
            estate: formDetails?.district,
            phase: !!formDetails?.phase ? formDetails?.phase : '',
            emailId: !!formDetails?.emailId ? formDetails?.emailId : 'goa-idctest-enterprise@gmail.com',
            propertyType: !!formDetails?.propertyType ? formDetails?.propertyType : '',
            plotArea: formDetails?.plotArea,
            sitePlan: edcrDetails?.sitePlan,
            sitePlanUUID: edcrDetails?.sitePlan?.split('fileStoreId=')[1]
        };
        setValues({
            ...values,
            name: formDetails?.lesseeName,
            emailId: !!formDetails?.emailId ? formDetails?.emailId : 'goa-idctest-enterprise@gmail.com',
            plotNo: formDetails?.plotNo,
            phase: formDetails?.phase,
            estate: formDetails?.district,
            archName: formDetails?.architectInformation,
            edcrNumber: edcrDetails?.edcrNumber,
            archId: users?.uuid,
            lesseeId: uuid,
            additionalDetails: edcrReports,
            serviceType: edcrDetails?.applicationSubType,
            applicationType: edcrDetails?.appliactionType,
            totalBuitUpArea: formDetails?.totalBuitUpArea,
            additionalDetails2: formDetails?.shareEDCRData,
            SITEPLAN: [],
            DOCUMENT_QUESTIONNAIRE: [],
            DOCUMENT_AFFIDAVIT: [],
            DOCUMENT_POWER_ATTORNEY: [],
            CERTIFICATE_ARCHITECTURE: [],
            CERTIFICATE_LIABILITY: []
        });
    };

    // const sitePlanPDF = async (sitePlanUUID: string) => {
    //     // let sitePlanUUID = edcrDetails?.sitePlan?.split('fileStoreId=')[0] || viewData?.additionalDetails.sitePlanUUID;

    //     if (!!sitePlanUUID) {
    //         const fileResponseUrl = await axios.get(devUrl + '/filestore/v1/files/url', {
    //             params: {
    //                 tenantId: 'ga',
    //                 fileStoreIds: sitePlanUUID
    //             }
    //         });
    //         const fileDate = new Date(fileResponseUrl?.data?.fileStoreIds[0].uploadedDate);

    //         const day = fileDate.getDate(),
    //             month = fileDate.getMonth() + 1,
    //             year = fileDate.getFullYear();

    //         const formattedDate = `${day}/${month}/${year}`;

    //         const sitePlanData = {
    //             documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
    //             docReferenceId: fileResponseUrl?.data?.fileStoreIds[0].id,
    //             documentUrl: extractFileUrl(fileResponseUrl.data.fileStoreIds[0].url),
    //             documentSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
    //             uploadedDate: formattedDate
    //         };
    //         setSitePlanData(sitePlanData);
    //     }
    // };

    // useEffect(() => {
    //     sitePlanPDF(values?.additionalDetails?.sitePlan);
    // }, [!!values?.additionalDetails?.sitePlan]);

    const viewDefaultLesseeDetails = (viewData: any) => {
        setValues({
            name: viewData?.lesseeName,
            emailId: !!viewData?.additionalDetails?.emailId ? viewData?.additionalDetails?.emailId : 'goa-idctest-enterprise@gmail.com',
            plotNo: viewData?.plotNo,
            phase: viewData?.additionalDetails?.phase,
            estate: viewData?.estateName,
            archName: viewData?.archName,
            edcrNumber: viewData?.edcrNumber,
            archId: viewData?.archId,
            serviceType: viewData?.serviceType,
            applicationType: viewData?.applicationType,
            SITEPLAN: getFileurl(viewData?.documents, 'SITEPLAN'),
            DOCUMENT_QUESTIONNAIRE: getFileurl(viewData?.documents, 'DOCUMENT_QUESTIONNAIRE'),
            DOCUMENT_AFFIDAVIT: getFileurl(viewData?.documents, 'DOCUMENT_AFFIDAVIT'),
            DOCUMENT_POWER_ATTORNEY: getFileurl(viewData?.documents, 'DOCUMENT_POWER_ATTORNEY'),
            CERTIFICATE_ARCHITECTURE: getFileurl(viewData?.documents, 'CERTIFICATE_ARCHITECTURE'),
            CERTIFICATE_LIABILITY: getFileurl(viewData?.documents, 'CERTIFICATE_LIABILITY'),
            sitePlan: viewData?.sitePlan,
            sitePlanUUID: viewData?.sitePlan?.split('fileStoreId=')[1],
            additionalDetails: viewData?.additionalDetails
        });
    };

    const getLesseeData = (entity: any, edcrDetails: any) => {
        const formDetails = edcrDetails?.planDetail?.planInformation;
        const usersData = localStorage.getItem('user');
        const users = usersData !== null ? JSON.parse(usersData) : null;

        // if ((formDetails?.architectInformation).toLowerCase() !== (`${users?.firstName} ${users?.lastName}`).toLowerCase()) {
        //     toast("Lessee details not matching with logged in user", {
        //         position: toast.POSITION.TOP_RIGHT,
        //         className: 'foo-bar',
        //         type: "error",
        //     });
        //     return;
        // }

        const payload: any = {
            RequestInfo: BPArequestInfo()
        };

        TechnicalServices.getLesseeSearch(payload, entity)
            .then((response: any) => {
                // handle the response
                if (response?.data) {
                    if (response?.data?.enterpriseEntity) {
                        setIsLesseeShare(true);
                        setStepsDetails([
                            ...steperDetails,
                            {
                                name: 'Additional Details',
                                code: 'LESSEE_SHARE',
                                titleCode: 'ARC_BTN_LESSEE_SHARE',
                                index: 3,
                                isActive: false
                            }
                        ]);

                        setDefaultLesseeDetails(edcrDetails, response?.data?.enterpriseEntity[0]?.uuid);
                    }
                    // setUserData(response?.data?.user[0]);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            });
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const getActiveStepper = () => {
        stepsDetails.forEach((val: any) => {
            if (val?.index === activeIndex) {
                setActiveStepper(val?.code);
            }
        });
    };

    const handleScroll = () => {
        if (window.scrollY > 110) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleStepClick = async (index: number) => {
        try {
            if (activeStepper === 'PLAN_SCRUTINY') await VALIDATION_SCRUTINY_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
            if (activeIndex === 2 && index === 3) return;
            setActiveIndex(index);
            window.scrollTo(0, 0);
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
        }
    };

    const submitForm = (key: number) => {
        navigateToDashboard();
    };

    const LesseeShare = async () => {
        try {
            await VALIDATION_SCRUTINY_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });

            // if (!values?.sitePlanAgreement && !!formData?.edcrDetail[0]?.sitePlan) {
            //     setModalDetails({ show: true, title: 'Error!', para: 'Please verify the siteplan details', img: cross, type: 'error', reUpload: 'OK' });
            //     return;
            // }
            const allDocs = [
                ...values?.CERTIFICATE_ARCHITECTURE,
                ...values?.CERTIFICATE_LIABILITY,
                ...values?.SITEPLAN,
                ...values?.DOCUMENT_QUESTIONNAIRE,
                ...values?.DOCUMENT_AFFIDAVIT,
                ...values?.DOCUMENT_POWER_ATTORNEY
            ];
            setIsLoading(true);
            const uniqueData = allDocs.filter((item, index, self) => index === self.findIndex(t => t.fileStoreId === item.fileStoreId));
            const payload: any = LesseeShareFormJson(values, uniqueData);
            payload.EDCRShare.additionalDetails.isSitePlanVerified = true;
            payload.EDCRShare.totalBuildUpArea = values?.totalBuitUpArea;
            payload.EDCRShare.additionalDetails2 = values?.additionalDetails2;

            TechnicalServices.shareLessee(payload)
                .then((response: any) => {
                    setIsLoading(false);

                    setShowAlert(true);
                })
                .catch(error => {
                    console.log(error, 'ERROR');
                    setIsLoading(false);
                    setModalDetails({
                        show: true,
                        title: 'Error!',
                        para: t(error?.response?.data?.Errors[0]?.code, error?.response?.data?.Errors[0]?.message),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    // toast(error?.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'error'
                    // });
                });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
        }
    };

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const shareScrutinyBtn = () => (
        <>
            {!!isLesseeShare ? (
                <>
                    <GenericButtons text={t('CLOSE', 'Close')} variant='secondary' handleClick={() => submitForm(0)} />
                    <GenericButtons
                        text={`${activeStepper === 'LESSEE_SHARE' ? t('SHARE', 'Share') : t('NEXT', 'Next')}`}
                        variant='primary'
                        handleClick={() => (activeStepper === 'LESSEE_SHARE' ? LesseeShare() : setActiveIndex(activeIndex + 1))}
                    />
                </>
            ) : (
                <>
                    {' '}
                    {stepsDetails.length === 2 && activeStepper === 'REPORT_SCRUTINY' && !edcrNumber && (
                        <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={() => submitForm(0)} />
                    )}
                </>
            )}
        </>
    );

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            {/* {`applicationPage m-auto px-5 py-4 ${edcrNumber ? 'h-100' : activeIndex > 1 ? 'h-100' : 'vh-100'}`} */}
            <div className={`applicationPage m-auto px-5 py-4 ${!edcrNumber && activeIndex === 1 ? 'vh-100' : 'h-100'}`}>
                <GenericHeader subText={''} text={t('ARC_HEADER_SCRUTINY_TOOL', 'Scrutiny Tool')} />
                {/* <div className="col-sm-12">
                    <Card className="card_border">
                        <Card.Body>
                            <div className="d-flex">
                                <GenericBackButton />
                                <div className="ps-2">
                                    <Card.Subtitle className="text-muted text-nav">HOME / OBPAS</Card.Subtitle>
                                    <Card.Text className=" font-inter-med fs-4">
                                        Scrutiny Tool
                                    </Card.Text>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div> */}
                <div className='row d-flex my-4'>
                    <div className='col-lg-3 position-sticky align-self-start' style={{ top: '110px' }}>
                        <div className='stickyCard'>
                            <GenericHeader text={t('ARC_SUBHEADER_SCRUTINY_TOOL', 'Scrutiny Tool')} sideHeader={true} additionalClass='sub-text-style' />
                            <Card className='card_border p-3 mt-2 mb-3 stickyCard align-self-start'>
                                <Card.Body>
                                    <Card.Text className=' d-flex flex-column row-gap-3  '>
                                        <SideCard steps={stepsDetails} handleStepClick={handleStepClick} activeIndex={activeIndex} indexType='alphabets' />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            {/* <Card className='card_border note mb-4'>
                                <Card.Body>
                                    <Card.Text>
                                        <span>
                                            <i className='fa fa-exclamation-circle' aria-hidden='true'></i>
                                        </span>
                                        <b> Note : </b>
                                        {t('PLEASE_PROVIDE_ALL_THE_DETAILS', 'Please provide all the details.')}
                                    </Card.Text>
                                </Card.Body>
                            </Card> */}
                        </div>
                    </div>
                    <div className='col-lg-9 me-auto'>
                        <Formik initialValues={initialValues} onSubmit={value => {}} enableReinitialize validationSchema={VALIDATION_SCRUTINY_FORM}>
                            <FormikProvider value={formik}>
                                <Form>
                                    <ScrutinyFormSubPage
                                        index={activeIndex}
                                        isView={!!state}
                                        viewData={viewData}
                                        activeStepper={activeStepper}
                                        setActiveIndex={setActiveIndex}
                                        employeeList={employeeData}
                                        formData={formData}
                                        showPopup={showPopup}
                                        edcrNumber={edcrNumber}
                                    />
                                </Form>
                            </FormikProvider>
                        </Formik>
                    </div>
                </div>
            </div>
            {
                // <GenericFooterComponentScrutiny
                //     onClickNext={onChangeNextIndex}
                //     secondaryBtnLabel={viewData?.edcrNumber ? 'Next' : activeIndex === 1 ? 'Submit' : 'Close'}
                //     onClickSave={onChangeNextIndex}
                //     onSubmit={() => submitForm(0)}
                //     onCancel={() => navigateToDashboard()}
                //     onClickPrev={onChangePrevIndex}
                //     stepperLength={stepsDetails.length}
                //     activeIndex={activeIndex}
                //     children={shareScrutinyBtn()}
                // />
                <GenericFooterComponent
                    isSaveBtn={false}
                    onClickNext={onChangeNextIndex}
                    onClickSave={onChangeNextIndex}
                    onSubmit={() => submitForm(0)}
                    onCancel={() => navigateToDashboard()}
                    onClickPrev={onChangePrevIndex}
                    stepperLength={stepsDetails.length}
                    activeIndex={activeIndex}
                    secondaryBtnLabel={viewData?.edcrNumber && stepsDetails.length !== activeIndex ? 'Next' : activeIndex === 1 ? 'Submit' : 'Close'}
                    children={shareScrutinyBtn()}
                    isSubmitBtn={activeIndex === 1 || !!edcrNumber}
                />
            }
            <StatusAlertPopup
                title='Success!'
                para={t('POPUP_MSG_APPLICATION_SUCCESSFULLY_SHARED_WITH_LESSEE', 'Scrutiny Report Shared Successfully with lessee')}
                img={check}
                handleClose={() => {
                    setShowAlert(false);
                    navigateToDashboard();
                }}
                handleSubmit={() => {
                    setShowAlert(false);
                    navigateToDashboard();
                }}
                show={showAlert}
            />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.finalSubmit)}
            />
        </>
    );
}
