import React from 'react';
import { person1, person2, person3, person4, person5, person6, person7 } from '../../utils/Images';
import HeaderComponent from '../../components/header/Header.component';
import FooterComponent from '../../components/portalComponents/Footer.component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function WhoIsWho() {
    const { t, i18n } = useTranslation();

    const cardData = [
        {
            imageSrc: person1,
            name: 'Shri Aleixo Reginaldo Lourenco',
            designation: 'Chairman',
            mobileNumber: '+91 98224 85327'
        },
        {
            imageSrc: person2,
            name: 'Shri P. Pravimal Abhishek, IAS',
            designation: 'Managing Director',
            mobileNumber: '+91 97039 34553'
        },
        {
            imageSrc: person3,
            name: 'Smt. Sangeeta Naik',
            designation: 'Special Land Acquisition Officer ',
            mobileNumber: '+91 94226 38383'
        },
        {
            imageSrc: person4,
            name: 'Shri Sohan Uskaikar ,GCS',
            designation: 'General Manager',
            mobileNumber: '+91 85549 90200'
        },
        {
            imageSrc: person7,
            name: 'Shri Subhash Belgaonkar ',
            designation: 'General Manager (Engineering)',
            mobileNumber: '+91 82756 20976'
        },
        {
            imageSrc: person6,
            name: 'Shri Philomeno Pereira',
            designation: 'Chief Accounts Officer ',
            mobileNumber: '+91 88061 62400'
        }
    ];
    return (
        <>
            <HeaderComponent />
            <div className='Whoiswho-container font-inter' style={{ backgroundColor: '#F5F5F6' }}>
                <div className='mx-5 p-5 Privacycss'>
                    <div style={{ width: '764px', height: '14px' }}>
                        <p className='breadcrumbs'>
                            <Link className='text-decoration-none' to='/goa-idc/'>
                                {t('HOME', 'Home')}
                            </Link>{' '}
                            / {t('WHO_IS_WHO', 'Who is Who')}
                        </p>
                    </div>
                    <div className='col-md-8 mb-5' style={{ fontWeight: 400, fontSize: '48px', lineHeight: '67.2px', letterSpacing: '-0.96px' }}>
                        <p>{t('WHO_IS_WHO', 'Who is Who')}</p>
                    </div>
                    <div className='font-karla-med' style={{ padding: '0px', justifyItems: 'flex-start' }}>
                        <div className='row row-gap-4'>
                            <div className='col-12 '>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <div className='card w-100 border-0'>
                                            <img src={person1} alt='' />
                                            <div className='card-info m-2'>
                                                <h2 className='m-2' style={{ fontWeight: 500, fontSize: '18px', lineHeight: '21.6px', color: '#000' }}>
                                                    Shri Aleixo Reginaldo Lourenco
                                                </h2>
                                                <p className='m-2' style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.80)' }}>
                                                    Chairman
                                                </p>
                                                <p style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.70)' }}>
                                                    <i className='fa fa-solid fa-phone mt-4 p-2'></i>+91 98224 85327
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className='card w-100 border-0'>
                                            <img src={person2} alt='' />
                                            <div className='card-info m-2'>
                                                <h2 className='m-2' style={{ fontWeight: 500, fontSize: '18px', lineHeight: '21.6px', color: '#000' }}>
                                                    Shri P. Pravimal Abhishek, IAS
                                                </h2>
                                                <p className='m-2' style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.80)' }}>
                                                    Managing Director
                                                </p>
                                                <p style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.70)' }}>
                                                    <i className='fa fa-solid fa-phone mt-4 p-2'></i>+91 97039 34553
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 '>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <div className='card w-100 border-0'>
                                            <img src={person3} alt='' />
                                            <div className='card-info m-2'>
                                                <h2 className='m-2' style={{ fontWeight: 500, fontSize: '18px', lineHeight: '21.6px', color: '#000' }}>
                                                    Smt. Sangeeta Naik
                                                </h2>
                                                <p className='m-2' style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.80)' }}>
                                                    Special Land Acquisition Officer
                                                </p>
                                                <p style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.70)' }}>
                                                    <i className='fa fa-solid fa-phone mt-4 p-2'></i>+91 94226 38383
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className='card w-100 border-0'>
                                            <img src={person4} alt='' />
                                            <div className='card-info m-2'>
                                                <h2 className='m-2' style={{ fontWeight: 500, fontSize: '18px', lineHeight: '21.6px', color: '#000' }}>
                                                    Shri Sohan Uskaikar ,GCS
                                                </h2>
                                                <p className='m-2' style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.80)' }}>
                                                    General Manager
                                                </p>
                                                <p style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.70)' }}>
                                                    <i className='fa fa-solid fa-phone mt-4 p-2'></i>+91 85549 90200
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className='card w-100 border-0'>
                                            <img src={person7} alt='' />
                                            <div className='card-info m-2'>
                                                <h2 className='m-2' style={{ fontWeight: 500, fontSize: '18px', lineHeight: '21.6px', color: '#000' }}>
                                                    Shri Subhash Belgaonkar
                                                </h2>
                                                <p className='m-2' style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.80)' }}>
                                                    General Manager (Engineering)
                                                </p>
                                                <p style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.70)' }}>
                                                    <i className='fa fa-solid fa-phone mt-4 p-2'></i>+91 82756 20976
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className='card w-100 border-0'>
                                            <img src={person6} alt='' />
                                            <div className='card-info m-2'>
                                                <h2 className='m-2' style={{ fontWeight: 500, fontSize: '18px', lineHeight: '21.6px', color: '#000' }}>
                                                    Shri Philomeno Pereira
                                                </h2>
                                                <p className='m-2' style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.80)' }}>
                                                    Chief Accounts Officer
                                                </p>
                                                <p style={{ fontWeight: 400, fontSize: '14px', lineHeight: '18.2px', color: 'rgba(0, 0, 0, 0.70)' }}>
                                                    <i className='fa fa-solid fa-phone mt-4 p-2'></i>+91 88061 62400
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    );
}
export default WhoIsWho;
