import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { FormikProvider, useFormik } from 'formik';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { devUrl, endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../EstateManagement.controller';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { InspectionReportCorrespondence } from './InspectionReportCorrespondence';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../Allotment/Allotment.const';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericDocViewer from '../../../../components/fileviewer/GenericDocViewer';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import { ILLEGAL_SUB_LEASE_RATE, TENANT_ID } from '../../../../utils/constants';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { getLesseeInitData } from '../SubLease/SubLeaseConst';
import { changeDateFormatToIST, getFirstErrorKey } from '../../../../utils/utils';
import { INSPECTION_REPORT_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import axios from 'axios';
import { getInspectionDetails } from './InspectionReportConst';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import { check, cross } from '../../../../utils/Logo';
import { getEntityDocumentDetails } from '../../Migration/Migration.controller';

export function InspectionReport() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]); //documentsUuid used in esign and payload
    const [activeIndex, setActiveIndex] = useState(1);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [rowData, setRowData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalData, setModalData] = useState<any>(null);
    const [rowDocData, setRowDocData] = useState<any>([]);
    const [searchResponse, setSearchResponse] = useState<any>(null);
    const [openSection, setOpenSection] = useState<string>('lesseeDetails');
    const [documentList, setDocumentList] = useState<any>([]);

    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const [searchParams] = useSearchParams();
    const applicationNumber = decodeURIComponent(searchParams.get('applicationNumber') || '');
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const totalPages = 2;
    let navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            isUpdatable: true,
            unauthorizedChangeInActivity: false,
            unauthorizedConstruction: false,
            unauthorizedSubLease: false,
            encroachment: false,
            otherIssue: false,
            penaltyInterest: ILLEGAL_SUB_LEASE_RATE,
            inspectionDate: '',
            remarks: '',
            areaOfSubLease: '',
            duration: '',
            illegalSubleaseFee: '',
            unauthorizedConstructionFee: '',
            notes: '',
            attachment: []
        },
        onSubmit: () => {},
        validationSchema: INSPECTION_REPORT_VALIDATION
    });

    const { values, setValues, setFieldValue, errors, setFieldTouched } = formik;

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                const { data: processData } = await POST(`${endpoints.workflowSearch}&businessIds=${applicationNumber}`, { RequestInfo: requestInfo() });
                setHistoryDetails(processData.ProcessInstances);

                const nextActions = applicationNumber && (await getNextActions(applicationNumber));
                setNextValidAction(nextActions);

                setFieldValue('isUpdatable', nextActions?.isUpdatable);

                const docs = applicationNumber && (await getWorkflowDocumentSearch(applicationNumber, nextActions?.businessService));
                setDocumentDetails(docs);
            })();
        }
    }, [applicationNumber]);

    useEffect(() => {
        if (documentDetails?.length) {
            const tableData = async () => {
                const reverseDocumentDetails = documentDetails?.reverse();
                const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${ind + 1}` };
                });
                const reverseData = responseTableData.reverse();
                setRowData(reverseData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    const { data: inspectionDetails } = await POST(endpoints.inspectionReportSearch + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    console.log('inspectionDetails', inspectionDetails);
                    setSearchResponse(inspectionDetails?.inspectionDetailsResponseList[0]);
                    setDocumentList(inspectionDetails?.inspectionDetailsResponseList[0]?.inspectionDocDetailsResponseList);
                    const lesseeData = await getLesseeInitData(inspectionDetails?.inspectionDetailsResponseList[0]);
                    const plotData = inspectionDetails?.inspectionDetailsResponseList[0]?.plotDetails;
                    const inspectionData = getInspectionDetails(inspectionDetails?.inspectionDetailsResponseList[0]);

                    setValues((prev: any) => ({
                        ...prev,
                        ...lesseeData,
                        ...inspectionData,
                        estateName: plotData?.estateDetails?.estateName,
                        phase: plotData?.phase,
                        plotNo: plotData?.plotNo,
                        plotType: plotData?.plotType,
                        plotRate: plotData?.plotRate
                    }));
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [applicationNumber]);

    const fetchDocumentDetails = async (docRefId: any) => {
        try {
            const docData = await getEntityDocumentDetails({ docReferenceId: docRefId });
            return docData;
        } catch (error) {
            console.error('Error fetching document details:', error);
            return {};
        }
    };

    const getReference = (code: string) => {
        let docType = { displayName: '', code: '' };
        switch (code) {
            case 'UNAUTHORIZED_CHANGE_IN_ACTIVITY':
                docType = { displayName: 'Annexure 1', code: 'ANNEXURE_1' };
                break;
            case 'UNAUTHORIZED_CONSTRUCTION_DEVELOPMENT':
                docType = { displayName: 'Annexure 2', code: 'ANNEXURE_2' };
                break;
            case 'UNAUTHORIZED_SUB_LEASE':
                docType = { displayName: 'Annexure 3', code: 'ANNEXURE_3' };
                break;
            case 'ENCROACHMENT':
                docType = { displayName: 'Annexure 4', code: 'ANNEXURE_4' };
                break;
            case 'OTHER_ISSUE':
                docType = { displayName: 'Annexure 5', code: 'ANNEXURE_5' };
                break;
            default:
                docType = { displayName: 'Choice not defined', code: 'CHOICE_NOT_DEFINED' };
                break;
        }
        return docType;
    };

    const getTableData = async () => {
        const searchtableData = searchResponse
            ? await Promise.all(
                  documentList?.map(async (val: any) => {
                      const docData = await fetchDocumentDetails(val?.docRefId);
                      return { ...val, documentName: docData?.documentName, documentUrl: docData?.documentUrl };
                  })
              )
            : [];
        let uploadedTableData: any = [];
        // if (searchResponse) {
        //     searchtableData = searchResponse ? await Promise.all(documentList?.map(async (val: any) => {
        //         const docData = await fetchDocumentDetails(val?.docRefId);
        //         return { ...val, documentName: docData?.documentName, documentUrl: docData?.documentUrl };
        //     });):[]
        // }
        if (values?.attachment?.length) {
            uploadedTableData = values?.attachment?.map((val: any, index: number) => {
                return {
                    documentName: val?.documentName,
                    documentTypeDisplayName: getReference(val?.documentName?.split('.')[0])?.displayName,
                    documentType: getReference(val?.documentName?.split('.')[0])?.code,
                    docRefId: val?.docReferenceId,
                    inspectionId: searchResponse?.uuid,
                    documentUrl: val?.documentUrl
                };
            });
        }
        const allTableData = [...searchtableData, ...uploadedTableData];

        if (allTableData?.length) {
            const temnpRowData = allTableData?.map((val: any, index: number) => ({ ...val, serialNo: index + 1 }));
            setRowDocData(temnpRowData);
        }

        setFieldValue('uploadingDocumentFor', '');
    };

    useEffect(() => {
        getTableData();
    }, [values?.attachment, searchResponse, documentList]);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            if (applicationNumber) {
                const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction?.businessService, historyDetails[0].id));
                setDocumentDetails(docs.documents);
                setDocumentsUuid([...documentsUuid, docs.uuid]);
                setActiveIndex(2);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string, isSaveOnly: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && !isSaveOnly) {
            navigate('/goa-idc/dashboard');
        } else if (type === 'success' && isSaveOnly) {
            window.location.reload();
        }
    };

    const onRowClick = async (row: any) => {
        const extension = (row?.docFileName || row?.documentName)?.split('.').pop().toLowerCase();

        setModalData(row);

        if (extension === 'pdf') {
            setIsPDF(true);
        } else {
            setIsPDF(false);
        }

        setIsFilePreviewModal(!isFilePreviewModal);
    };

    const onCancel = () => {
        navigate(-1);
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            // formik.setTouched({ back: true });
        }
    };

    const handleNextPage = async () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };
    const handlePrevPage = () => {
        if (activeIndex >= 2) {
            setActiveIndex(prevInd => prevInd - 1);
        }
    };

    const onSectionChange = (currentSection: string) => {
        setOpenSection(currentSection);
    };

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    useEffect(() => {
        if (note && action && searchResponse && nextValidAction) {
            getTableData();
            onHandlePrimaryAction({ action: action }, '');
        }
    }, [searchResponse, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        const userRequestInfo = requestInfo();
        try {
            setIsLoading(true);
            let approveEsignRes = await POST(endpoints.inspectionReportApprove, payload);
            const refId = approveEsignRes.data.entityRefDocuments[0].docReferenceId;
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.map((item: any) => `${item.uuid}=${item.docReferenceId}`);

            console.log('refId', refId, approveEsignRes.data.entityRefDocuments, 'data', approvedEsignData);

            // return;

            if (window.location.protocol !== 'http:') {
                let url =
                    window.location.origin +
                    `/goa-idc/estate/inspection-report?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
                        ','
                    )}&action=${action}`;
                url = btoa(url);
                const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }`;
                await axios.get(esignUrl);

                window.open(esignUrl, '_self');
            } else {
                let url =
                    window.location.origin +
                    `/goa-idc/estate/inspection-report?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
                        ','
                    )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
                console.log('url', url, documentsUuid);
                window.open(url, '_self');
            }
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const compare = (arr1: any, arr2: any) => {
        const set1 = new Set(arr1);
        const set2 = new Set(arr2);
        const missingElements = [...set2].filter(item => !set1.has(item));
        const isSubset = missingElements?.length === 0;
        return { isSubset, missingElements };
    };

    const errorMessage = (annexure: any) => {
        switch (annexure) {
            case 'ANNEXURE_1':
                return 'Please upload document for Unauthorized change in activity';
            case 'ANNEXURE_2':
                return 'Please upload document for Unauthorized construction/development';
            case 'ANNEXURE_3':
                return 'Please upload document for Unauthorized Sub-Lease';
            case 'ANNEXURE_4':
                return 'Please upload document for Encroachment';
            case 'ANNEXURE_5':
                return 'Please upload document for Other issue';
            default:
                return 'Annexure not defined!!!';
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const { inspectionDocDetailsResponseList, ...rest } = searchResponse;

        const checkDocuments = [
            values?.unauthorizedChangeInActivity || searchResponse?.unauthorizedChangeInActivity ? 'ANNEXURE_1' : undefined,
            values?.unauthorizedConstruction || searchResponse?.unauthorizedConstruction ? 'ANNEXURE_2' : undefined,
            values?.unauthorizedSubLease || searchResponse?.unauthorizedSubLease ? 'ANNEXURE_3' : undefined,
            values?.encroachment || searchResponse?.encroachment ? 'ANNEXURE_4' : undefined,
            values?.otherIssue || searchResponse?.otherIssuePresent ? 'ANNEXURE_5' : undefined
        ].filter(Boolean);

        const tempDocumentList = rowDocData?.length ? rowDocData : searchResponse?.inspectionDocDetailsResponseList?.length ? searchResponse?.inspectionDocDetailsResponseList : [];
        const tempUploadedDocument = tempDocumentList?.map((val: any) => val?.documentType);
        const uploadedDocument = tempUploadedDocument?.filter((val: any, index: number) => tempUploadedDocument.indexOf(val) === index);
        const checkDoc = compare(uploadedDocument, checkDocuments);

        console.log('checkDocuments', checkDocuments, rowDocData, uploadedDocument, checkDoc);

        let payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            inspectionDetailsRequest:
                designation === 'FM_SITE'
                    ? {
                          ...rest,
                          inspectionDate: values?.inspectionDate && changeDateFormatToIST(values?.inspectionDate),
                          unauthorizedChangeInActivity: values?.unauthorizedChangeInActivity,
                          unauthorizedConstruction: values?.unauthorizedConstruction,
                          unauthorizedSubLease: values?.unauthorizedSubLease,
                          otherIssuePresent: values?.otherIssue,
                          encroachment: values?.encroachment,
                          remarks: values?.remarks,
                          unauthorizedSubLeaseArea: values?.unauthorizedSubLease ? +values?.areaOfSubLease : 0,
                          unauthorizedSubLeaseDuration: values?.unauthorizedSubLease ? +values?.duration : 0,
                          penaltyInterest: +values?.penaltyInterest,
                          totalPenaltyAmount: values?.unauthorizedSubLease ? +values?.illegalSubleaseFee : 0,
                          unauthorizedConstructionAmount: values?.unauthorizedConstruction ? +values?.unauthorizedConstructionFee : 0,
                          inspectionDocDetailsRequestList: tempDocumentList,
                          isDocumentRequired: status?.action === 'FORWARD_TO_ARM'
                      }
                    : { ...rest, inspectionDocDetailsRequestList: inspectionDocDetailsResponseList },
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            signedDocDetails:
                approvedEsign?.split(',').map((item: any) => {
                    const esignData = item.split('=');
                    return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                }) || null
        };

        try {
            await INSPECTION_REPORT_VALIDATION.validate(values, { abortEarly: false });

            if (!checkDoc?.isSubset) {
                const errMsg = errorMessage(checkDoc.missingElements[0]);
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            if (!values?.notes && !note) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            if (designation === 'FM_SITE' && !signedFileStoreId && status.isEsignRequired) {
                esign(payload, status.action);
                return;
            }

            try {
                setIsLoading(true);
                await POST(endpoints.inspectionReportInitiateWorkflow, payload);
                const successMessage = getSuccessMessage(status?.action, applicationNumber);
                setModalDetails({ show: true, title: t('SUCCESS', 'Success'), para: successMessage, img: check, type: 'success', reUpload: 'OK' });
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e: any) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            onSectionChange('inspectionDetails');
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill all the required details', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const updateNotes = async () => {
        const { inspectionDocDetailsResponseList, ...rest } = searchResponse;
        const payload = {
            RequestInfo: requestInfo(),
            ...rest,
            inspectionDate: values?.inspectionDate && changeDateFormatToIST(values?.inspectionDate),
            unauthorizedChangeInActivity: values?.unauthorizedChangeInActivity,
            unauthorizedConstruction: values?.unauthorizedConstruction,
            unauthorizedSubLease: values?.unauthorizedSubLease,
            otherIssuePresent: values?.otherIssue,
            encroachment: values?.encroachment,
            remarks: values?.remarks,
            unauthorizedSubLeaseArea: values?.unauthorizedSubLease ? +values?.areaOfSubLease : 0,
            unauthorizedSubLeaseDuration: values?.unauthorizedSubLease ? +values?.duration : 0,
            penaltyInterest: +values?.penaltyInterest,
            totalPenaltyAmount: values?.unauthorizedSubLease ? +values?.illegalSubleaseFee : 0,
            unauthorizedConstructionAmount: values?.unauthorizedConstruction ? +values?.unauthorizedConstructionFee : 0,
            inspectionDocDetailsRequestList: rowDocData,
            isDocumentRequired: true
        };

        try {
            await POST(endpoints.inspectionReportUpdate, payload);
            setModalDetails({ show: true, title: t('SUCCESS', 'Success'), para: 'Data saved successfully', img: check, type: 'success', reUpload: 'OK', isSaveOnly: true });
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('formik', formik, designation);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={t('INSPECTION_REPORT', 'Inspection Report')}
                            additionalClass='sub-text-style'
                            bottomSubText={applicationNumber ? `Type : ${t('INSPECTION', 'Inspection')}, No : ${applicationNumber}` : ''}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('INSPECTION_REPORT', 'Inspection Report')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={applicationNumber ? `Type :  ${t('INSPECTION', 'Inspection')}, No : ${applicationNumber}` : ''}
                                    />

                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={!nextValidAction?.nextValidAction?.length}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper designation={designation} applicationNumber={applicationNumber} nextValidAction={nextValidAction} />

                                <Card className='card_border p-1'>
                                    {activeIndex === 1 && (
                                        <InspectionReportCorrespondence
                                            onRowClick={onRowClick}
                                            rowDocData={rowDocData}
                                            setRowDocData={setRowDocData}
                                            searchResponse={searchResponse}
                                            openSection={openSection}
                                            setOpenSection={setOpenSection}
                                            nextValidAction={nextValidAction}
                                            documentList={documentList}
                                            setDocumentList={setDocumentList}
                                        />
                                    )}
                                    {activeIndex === 2 && (
                                        <div className='p-4'>
                                            <GenericActionTable hideSearchBar={true} columns={CORRESPONDENCE_TABLE} rowData={rowData} additionalMethod={onRowClick} />
                                        </div>
                                    )}
                                </Card>
                            </div>

                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                        </FormikProvider>
                    </div>

                    <GenericFooterComponent
                        onCancel={onCancel}
                        onClickPrev={onChangePrevIndex}
                        activeIndex={activeIndex}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={() => {}}
                        applicationNo={applicationNumber || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                        extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                        extraButtonAction={updateNotes}
                    />

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly)}
                    />

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData?.docRefId} documentName={modalData?.docFileName || modalData?.documentName} />}
                                {!isPDF && <GenericImageViewer imageUrl={modalData?.documentUrl} />}
                                {/* {!isPDF && <GenericDocViewer url={modalData.documentUrl} extension={'docx'} fileName={modalData.indenture} />} */}
                            </div>
                        </GenericPopupModal>
                    )}
                </>
            )}
        </>
    );
}
