import React, { useEffect, useState } from "react";
import ContactUsComponent from "../../components/portalComponents/ContactUs.component";
import FooterComponent from "../../components/portalComponents/Footer.component";
import HeaderComponent from "../../components/header/Header.component";
import MapInfoComponent from "../../components/portalComponents/MapSection.component";
import MiddleBarComponent from "../../components/portalComponents/MiddleBar.component";
import SliderComponent from "../../components/portalComponents/Slider.component";
import SocialFeedsComponent from "../../components/portalComponents/SocialFeeds.component";
import { Image } from "react-bootstrap";
import ScrollToTop from "../../components/ScrollToTop";


export default function MainPage() {

    const [showAlert, setShowAlert] = useState(false);

    function handleclick() {
        setShowAlert(false);
    }

    //TO DO

    // useEffect(() => {
    //     const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
    //     let setTiming: any;
    //     if (!hasVisitedBefore) {
    //         setTiming = setTimeout(() => {
    //             setShowAlert(true);
    //             localStorage.setItem('hasVisitedBefore', 'true');
    //         }, 1000);
    //     }
    //     return () => clearTimeout(setTiming);
    // }, []);

    return (<>
        {/* <GenericPopupModal title="" display="d-none" isVisible={showAlert} secondaryAction={handleclick} isCentered={true} class='advertise-modal' primaryAction={() => { }} children={<img src={advertisementPopup} width='100%' height='100%' alt="" />} /> */}
        <div className="position-relative">
            <HeaderComponent />
            <SliderComponent />
            <MiddleBarComponent />
            <MapInfoComponent />
            <SocialFeedsComponent />
            <ContactUsComponent />
            <FooterComponent />
            <ScrollToTop type='public' />
        </div>
    </>)


}

