import React, { useEffect, useState } from 'react';
import { RENEWAL_CONSTRUCTION_LICENSE_FORM, RENEWAL_CONSTRUCTION_LICENSE_FORM_STEPS, RENEWAL_CONSTRUCTION_LICENSE_FORM_STEPS_NO_PAY } from './renewalOfConstructionLicense.const';
import HeaderComponent from '../../components/header/Header.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getOptions } from '../modules/EstateManagement/EstateManagement.controller';
import {
    BPArequestInfo,
    POST,
    devUrl,
    endpoints,
    getNextValidAction,
    getProgressBarData,
    getUserDataOnRefresh,
    hdfcPaymentCheckout,
    requestInfo
} from '../../services/modulesServices/userManagement.api';
import { check, cross, warning } from '../../utils/Logo';
import { RazorpayOptions } from '../modules/UserManagement/types';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { Card } from 'react-bootstrap';
import Loading from '../../components/portalComponents/Loading.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import GenericBackButton from '../../components/UI components/Buttons/GenericBackButton';
import SideCard from '../../components/moduleCommonFormik/SideCardGenericComponent';
import { FormikProvider, useFormik } from 'formik';
import PaymentDetails from '../modules/EstateManagement/Allotment/PaymentDetails';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { download } from '../../utils/Logo';
import { Image } from 'react-bootstrap';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import { esignForDocuments } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.controller';
import { getNavigateTo } from '../../utils/utils';
import { getCLList, RenewalCLFormJson } from './renewalOfConstructionLicense.controller';
import { paymentColumnsForTC } from '../modules/EstateManagement/Allotment/Allotment.const';
import PaymentSubmissionStatus from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../components/moduleCommonFormik/ProgressBar';
import { CONSTANT_DATA_FIELD, KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../utils/constants';

const RenewalOfConstructionLicense = () => {
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [transferDetails, setTransferDetails] = useState<any>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [allchecked, setAllChecked] = React.useState<any>([]);

    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    let navigate = useNavigate();

    const [modalDetails, setModalDetails] = useState<any>({});
    const urlParams = new URLSearchParams(window.location.search);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [eSignFlag, setESignFlag] = useState<boolean>(false);
    const [previewData, setPreviewData] = useState<any>(null);
    const [allCLData, setCLData] = useState<any>([]);
    const [activeStepper, setActiveStepper] = useState<string>('CONSTRUCTION_LICENSE');
    const [BPAData, setBPAData] = useState<any>(null);
    const applicationNumber = decodeURIComponent(urlParams.get('applicationNumber') || '');
    const stepperIndex = urlParams.get('stepper') || '';
    const signedFileStoreId = urlParams.get('signedFileStoreId') || '';
    const [paymentRowData, setPaymentRowData] = useState<any>(null);
    const [searchParams] = useSearchParams();
    let applicationNo = searchParams.get('applicationNumber');
    const transactionStatus = urlParams.get('transactionStatus') || '';
    const [isTransaction, setIsTransaction] = useState<boolean>(false);
    const [transactionId, setTransactionId] = useState<boolean>(false);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [auditTrial, setAuditTrial] = useState<any>(null);
    const stepsDetails = !!paymentRowData?.BPAPaymentDetails?.length ? RENEWAL_CONSTRUCTION_LICENSE_FORM_STEPS : RENEWAL_CONSTRUCTION_LICENSE_FORM_STEPS_NO_PAY;
    const formik: any = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            isSpecialProject: false
        },
        onSubmit: value => {}
    });
    const { values } = formik;

    const checkRole = async () => {
        try {
            const { data } = await getUserDataOnRefresh();
            if (data) {
                if (data.type === 'EMPLOYEE') {
                    navigate(`/goa-idc/obpas/e/renewal/ConstructionLicense?applicationNumber=${applicationNumber}&tenantId=ga`);
                }
            }
        } catch (error) {}
    };

    useEffect(() => {
        checkRole();
        getCLRenewalData();
    }, []);

    useEffect(() => {
        getActiveStepper();
    }, [activeIndex]);

    useEffect(() => {
        searchBPA(applicationNumber);
    }, [applicationNumber]);

    const getActiveStepper = () => {
        stepsDetails.forEach(val => {
            if (val?.index === activeIndex) {
                setActiveStepper(val?.code);
            }
        });
    };
    console.log(previewData, 'expirationDate');
    useEffect(() => {
        if (values?.constructionLicenseNo && !applicationNumber) {
            const selectedCLApplication = allCLData.filter((val: any) => val?.applicationNo === values?.constructionLicenseNo);
            const filteredData = selectedCLApplication[0];
            setPreviewData(filteredData);
            values.phase = filteredData?.additionalDetails?.phase;
            values.plotNumber = filteredData?.additionalDetails?.plotNo;
            values.plotType = filteredData?.additionalDetails?.propertyType;
            values.nameOfIndustrialEstate = filteredData?.additionalDetails?.estate;
            const fileDate = new Date(filteredData?.additionalDetails?.validUpTo);
            const day = fileDate.getDate(),
                month = fileDate.getMonth() + 1,
                year = fileDate.getFullYear();
            values.expirationDate = `${day}/${month}/${year}`;
            console.log(values.expirationDate);
        }
    }, [values?.constructionLicenseNo]);

    const handleStepClick = (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const searchBPA = (val: any) => {
        setIsLoading(true);
        if (!!val) {
            TechnicalServices.getBpaSearchForRefNo(val, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    // handle the response
                    setIsLoading(false);
                    if (!response?.data?.BPA.length) {
                        setPreviewData(null);
                        setBPAData(null);
                        return;
                    }
                    setPreviewData(response?.data?.BPA[0]);
                    setBPAData(response?.data?.BPA[0]);

                    if (val) {
                        const filteredData = response?.data?.BPA[0];
                        console.log('filteredData', filteredData);
                        values.constructionLicenseNo = filteredData?.refCLApplication;
                        values.phase = filteredData?.additionalDetails?.plots[0]?.phase;
                        values.plotNumber = filteredData?.additionalDetails?.plots[0]?.plotNo;
                        values.plotType = filteredData?.additionalDetails?.plots[0]?.plotType;
                        values.nameOfIndustrialEstate = filteredData?.additionalDetails?.plots[0]?.estateDetails.estateName;
                        const fileDate = new Date(filteredData?.validUpTo);
                        const day = fileDate.getDate(),
                            month = fileDate.getMonth() + 1,
                            year = fileDate.getFullYear();

                        const formattedDate = `${day}/${month}/${year}`;
                        values.expirationDate = formattedDate;
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const renderPayment = () => {
        const SANCTION_DETAILS = {
            txnId: paymentRowData?.transactionNumber || '',
            auditDetails: { createdTime: paymentRowData?.transactionDate || '' },
            txnStatus: 'SUCCESS',
            additionalDetails: { paymentReceiptDocRefId: paymentRowData?.receiptId || '' }
        };

        // Function to format numbers with commas
        const formatNumberWithCommas = (number: any) => {
            return number.toLocaleString('en-IN');
        };

        // Format the BPAPaymentDetails array
        const formattedPaymentDetails = (paymentRowData?.BPAPaymentDetails || []).map(
            (
                detail: {
                    principalAmount: any;
                    cgstPrincipal: any;
                    sgstPrincipal: any;
                    interestAmount: any;
                    penaltyAmount: any;
                    cgstPenalty: any;
                    sgstPenalty: any;
                    totalDueAmount: any;
                    paidAmount: any;
                },
                index: number
            ) => ({
                ...detail,
                index: index + 1,
                principalAmount: formatNumberWithCommas(detail.principalAmount),
                cgstPrincipal: formatNumberWithCommas(detail.cgstPrincipal),
                sgstPrincipal: formatNumberWithCommas(detail.sgstPrincipal),
                interestAmount: formatNumberWithCommas(detail.interestAmount),
                penaltyAmount: formatNumberWithCommas(detail.penaltyAmount),
                cgstPenalty: formatNumberWithCommas(detail.cgstPenalty),
                sgstPenalty: formatNumberWithCommas(detail.sgstPenalty),
                totalDueAmount: formatNumberWithCommas(detail.totalDueAmount),
                paidAmount: formatNumberWithCommas(detail.paidAmount)
            })
        );
        return (
            <>
                <div className='my-2'>
                    <PaymentDetails
                        paymentData={formattedPaymentDetails}
                        title={t('TC_CARD_HEADER_PAYMENT_BREAKDOWN', 'Payment Breakdown')}
                        selectedGateway={selectedGateway}
                        setSelectedGateway={setSelectedGateway}
                        isPaid={paymentRowData?.isPaymentRecived}
                        columns={paymentColumnsForTC}
                        gatewayData={gatewayData}
                        transactionDetails={SANCTION_DETAILS}
                        totalLabel={'totalDueAmount'}
                    />
                </div>
            </>
        );
    };

    const loadRazorpay = () => {
        const script = document.createElement('script');
        script.src = hdfcPaymentCheckout();
        script.async = true;
        document.body.appendChild(script);
    };

    useEffect(() => {
        getPaymentData(previewData?.applicationNo);
        fetchGatewayData();
    }, [activeStepper === 'PAYMENT']);

    useEffect(() => {
        loadRazorpay();
    }, []);

    const onPay = async (key?: any) => {
        if (!selectedGateway) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });
            // toast('Please select a payment gateway', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'warning' });
            return;
        }
        try {
            TechnicalServices.generateDemand(applicationNumber, selectedGateway, { RequestInfo: BPArequestInfo() }).then(async (response: any) => {
                const payload_for = { RequestInfo: BPArequestInfo() };
                TechnicalServices.fetchBillDetails(values?.appId || applicationNumber, payload_for)
                    .then(async res => {
                        if (res?.data) {
                            const billings = res?.data?.Bill[0];
                            const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
                            setIsLoading(true);
                            let url =
                                window.location.origin +
                                `/goa-idc/obpas/renewal/ConstructionLicense?applicationNumber=${previewData?.applicationNo || applicationNumber}&tenantId=ga&transactionStatus=1&stepper=3`;

                            const payload = {
                                RequestInfo: requestInfo(),
                                applicationType: 'Technical Clearance',
                                applicationStatus: null,
                                applicationId: values?.appId || applicationNumber,
                                tenantId: 'ga',
                                auditDetails: {
                                    createdBy: 323,
                                    lastModifiedBy: 323
                                },
                                Transaction: {
                                    tenantId: 'ga',
                                    billId: billings?.id,
                                    txnAmount: billings?.totalAmount,
                                    module: 'Construction License',
                                    taxAndPayments: billings?.billDetails[0]?.billAccountDetails.map((obj: any) => ({
                                        amountPaid: obj?.amount,
                                        billId: billings?.id
                                    })),
                                    consumerCode: values?.appId || applicationNumber,
                                    additionalDetails: { paymentReceiptDetails: paymentRowData?.paymentReceiptDetails },
                                    productInfo: 'Construction License',
                                    gateway: selectedGateway,
                                    user: userInfo,
                                    callbackUrl: url
                                }
                            };
                            try {
                                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS' || selectedGateway === 'ICICI') {
                                    if ((values?.appId || applicationNumber) && selectedGateway && billings?.totalAmount > 0) {
                                        const { data } = await POST(endpoints.initiateTransaction, payload);
                                        const data1 = data?.Transaction;
                                        if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                                            const options: RazorpayOptions = getOptions(
                                                data1.txnAmount,
                                                getUser,
                                                'description',
                                                data1?.additionalDetails?.ORDER_ID,
                                                data1.callbackUrl,
                                                KEY_ID[selectedGateway]
                                            );
                                            const rzp1: any = (window as any).Razorpay(options);
                                            rzp1.open();
                                        } else if (selectedGateway === 'ICICI') {
                                            console.log(data1.additionalDetails);
                                            setTimeout(() => {
                                                navigate(
                                                    `/goa-idc/icici-payment?walletClientCode=${data1.additionalDetails.walletClientCode}&walletRequestMessage=${data1.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                                                );
                                            }, 500);
                                            console.log(data1, 'oldDATA___1');
                                            return;
                                        } else if (data1 && data1.redirectUrl) {
                                            window.open(data1.redirectUrl, '_self');
                                        }
                                        setIsLoading(false);
                                    }
                                } else {
                                    if ((values?.appId || applicationNumber) && selectedGateway) {
                                        TechnicalServices.addTransaction(payload, false, '')
                                            .then(res => {
                                                if (res?.data?.Transaction) {
                                                    window.open(res?.data?.Transaction?.redirectUrl, '_self');
                                                }
                                            })
                                            .catch((e: any) => {
                                                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                                                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                                                setIsLoading(false);
                                            });
                                        setIsLoading(false);
                                    }
                                }
                            } catch (e: any) {
                                setIsLoading(false);

                                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                            }
                        }
                    })
                    .catch(err => {
                        setModalDetails({ show: true, title: 'Error!', para: err?.response?.data?.Errors[0]?.message, img: cross, type: 'error', reUpload: 'OK' });
                    });
            });
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!!transactionStatus) {
            const payload = { RequestInfo: BPArequestInfo() };
            // const redirectUrl = urlParams
            const retainList = ['msg'];
            for (const key of [...urlParams.keys()]) {
                if (retainList.includes(key)) {
                    urlParams.delete(key);
                }
            }
            TechnicalServices.addTransaction(payload, true, urlParams)
                .then((data: any) => {
                    if (data) {
                        if (data?.data?.Transaction[0]?.txnStatus === 'FAILURE') {
                            setTransactionId(false);
                            setModalDetails({ show: true, title: 'Error!', para: 'Payment failed', img: cross, type: 'error', reUpload: 'OK' });
                        } else {
                            TechnicalServices.updatePayment(data?.data?.Transaction[0]?.consumerCode, new Date().getSeconds(), data?.data?.Transaction[0]?.txnId, payload).then((data: any) => {
                                setIsTransaction(true);
                                setTransactionId(true);
                                getPaymentData();
                            });
                        }
                    }
                })
                .catch(() => {});
        }
    }, [transactionStatus]);

    useEffect(() => {
        try {
            if (!!applicationNumber) getProgressBarData(applicationNumber).then(res => setProgressBarDetails(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [applicationNumber]);

    const getPaymentData = async (appId = null) => {
        if (!applicationNumber && !appId) return;
        try {
            const payload = { RequestInfo: BPArequestInfo() };
            TechnicalServices.searchPaymentDetails(appId || applicationNumber, payload)
                .then(res => {
                    if (res?.data) {
                        setPaymentRowData(res?.data);
                    } else {
                        throw new Error('No data found');
                    }
                })
                .catch(error => {
                    let errorMessage = 'Failed to retrieve payment data';
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        errorMessage += `: ${error.response.data.message || error.response.statusText}`;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage += ': No response received from server';
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage += `: ${error.message}`;
                    }
                    setModalDetails({ show: true, title: 'Error!', para: errorMessage, img: cross, type: 'error', reUpload: 'OK' });
                });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Unexpected error occurred', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const getActionsData = () => {
        if (!!applicationNo) {
            setIsLoading(true);

            getNextValidAction(applicationNo)
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        console.log(response?.data);
                        // setActionsData(response?.data);
                    }

                    setIsLoading(false);
                })
                .catch((error: any) => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            console.log(stepperIndex, previewData?.lesseeDigitalSignFlage);
            if (!!stepperIndex && stepperIndex === '2' && previewData && !previewData?.lesseeDigitalSignFlage) {
                setActiveIndex(2);
                setActiveStepper('SELF_DECLARATION');
                setAllChecked([{}, {}, {}, {}, {}]);
                submitForm('eSign', signedFileStoreId);
                setTimeout(() => {
                    setAllChecked([{}, {}, {}, {}, {}]);
                }, 1000);
            }
            if (!!stepperIndex && stepperIndex === '3' && previewData) {
                setActiveIndex(3);
                setActiveStepper('PAYMENT');
                setAllChecked([{}, {}, {}, {}, {}]);
                setTimeout(() => {
                    setAllChecked([{}, {}, {}, {}, {}]);
                }, 1000);
            }
        }, 1500);
    }, [previewData]);

    const getCLRenewalData = () => {
        const payload = { RequestInfo: BPArequestInfo() };
        TechnicalServices.getRenewalCL(payload)
            .then(res => {
                if (res?.data?.BPA && res?.data?.BPA?.length > 0) {
                    setCLData(res?.data?.BPA || []);
                } else {
                    if (!applicationNumber) setModalDetails({ show: true, title: 'Warning!', para: 'No Construction license data available', img: warning, type: 'warning', reUpload: 'OK' });
                }
            })
            .catch(err => {
                setModalDetails({ show: true, title: 'Warning!', para: err?.response?.data?.Errors[0]?.message, img: warning, type: 'warning', reUpload: 'OK' });
            });
    };

    const submitForm = async (action?: any, storeId?: any) => {
        if (((activeStepper === 'CONSTRUCTION_LICENSE' && !!BPAData) || (activeStepper === 'SELF_DECLARATION' && !!paymentRowData?.BPAPaymentDetails.length)) && action !== 'eSign') {
            setActiveIndex(activeIndex + 1);
            return;
        }

        if (activeIndex === stepsDetails.length && previewData?.status !== 'APPL_STATUS_CL_RENEWAL_INITIATE_PENDING' && previewData?.status !== 'Draft') navigateToDashboard();

        if (!previewData?.applicationNo) {
            setModalDetails({
                show: true,
                title: 'Error!',
                para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            return;
        }

        if (!!previewData?.lesseeDigitalSignFlage) allchecked.length = 5;

        if (activeStepper === 'SELF_DECLARATION' && allchecked.length !== 5) {
            setModalDetails({ show: true, title: 'Declaration', para: t('CHECK_DECLARATION_PROCEED', 'Please check all declarations and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeStepper === 'SELF_DECLARATION' && !previewData?.lesseeDigitalSignFlage && action !== 'eSign') {
            setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        setIsLoading(true);
        if (activeStepper === 'CONSTRUCTION_LICENSE' && !BPAData) {
            action = 'INITIATE';
        }
        const payload: any = RenewalCLFormJson(values, previewData, !!previewData?.accountId);
        payload.BPA.status = previewData?.status;

        payload.BPA.workflow = null;
        if (activeStepper === 'CONSTRUCTION_LICENSE' && !BPAData) {
            payload.BPA.isCLRenewalApplication = true;
            payload.BPA.refCLApplication = previewData?.applicationNo;
            payload.BPA.id = null;
        }
        payload.BPA.notes = {
            id: null, // Assuming it should have an ID, set as null or any specific value if needed
            content: values?.notes || '',
            notificationDetails: null // Assuming no specific notification details
        };

        if (action === 'eSign') {
            payload.BPA.lesseeDigitalSignFlage = true;
            payload.BPA.digitalSignedDoc = storeId;
        }

        if (activeStepper === 'PAYMENT' && !!paymentRowData?.isPaymentRecived) {
            payload.BPA.workflow = {
                action: 'ACT_CL_INITIATE',
                assignes: null,
                notes: values?.notes,
                varificationDocuments: null
            };
        }

        if (
            activeIndex === 2 &&
            !!previewData?.lesseeDigitalSignFlage &&
            (!previewData?.status || previewData?.status === 'APPL_STATUS_CL_RENEWAL_INITIATE_PENDING' || previewData?.status === 'Draft')
        ) {
            payload.BPA.workflow = {
                action: 'ACT_CL_INITIATE',
                assignes: null,
                notes: values?.notes,
                varificationDocuments: null
            };
        }

        payload.BPA.additionalDetails.validUpTo = previewData?.validUpTo ? previewData?.validUpTo : previewData?.additionalDetails?.validUpTo;
        TechnicalServices.createTechnicalClearance(payload)
            .then((response: any) => {
                setIsLoading(false);

                if (response?.data?.BPA[0]?.status === 'APPL_STATUS_CL_INITIATED') {
                    setModalDetails({ show: true, title: 'Success', para: t('RENEWAL_APP_SUBMIT', 'Application Submitted Successfully'), img: check, type: 'success', reUpload: 'OK' });
                    return;
                }
                setPreviewData(response?.data?.BPA[0]);
                setBPAData(response?.data?.BPA[0]);
                getPaymentData(response?.data?.BPA[0]?.applicationNo);
                if (action === 'eSign') {
                    setESignFlag(true);
                    return;
                }

                if (activeStepper !== 'PAYMENT' && activeIndex !== stepsDetails?.length) setActiveIndex(activeIndex + 1);
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const fetchGatewayData = async () => {
        try {
            const { data } = await POST(`/egov-mdms-service/v1/_search`, {
                RequestInfo: requestInfo(),
                MdmsCriteria: {
                    tenantId: 'ga',
                    moduleDetails: [
                        {
                            moduleName: 'egov-pg-service',
                            masterDetails: [
                                {
                                    name: 'Gateway',
                                    filter: '[?(@.active == true)]'
                                }
                            ]
                        }
                    ]
                }
            });
            let feeMasterDetails = data?.['MdmsRes']?.['egov-pg-service']?.['Gateway'];
            setGatewayData(feeMasterDetails || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const signDocument = async () => {
        if (activeStepper === 'SELF_DECLARATION' && allchecked.length !== 3) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            const payload = { RequestInfo: BPArequestInfo() };
            TechnicalServices.digitalSignDocument(previewData?.applicationNo, payload)
                .then(res => {
                    if (res?.data) {
                        esignSelf(res?.data?.fileStoreId);
                        // submitForm('eSign', res?.data?.fileStoreId);
                    }
                })
                .catch((e: any) => {
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                });

            // setESignedDocumentData(data?.data);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const esignSelf = async (fileStoreId: string) => {
        const redirectURL = `/goa-idc/obpas/renewal/ConstructionLicense?${searchParams.toString()}&applicationNumber=${BPAData?.applicationNo || applicationNumber}&note=${
            values.notes
        }&docsUuid=${fileStoreId}&stepper=2`;

        let url = window.location.origin + redirectURL;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${fileStoreId}&callbackUrl=${url}`);
        // esignForDocuments(fileStoreId, redirectURL);
    };

    const RenewalConstruactionLicense = () => {
        const renewalConstructionLicense = RENEWAL_CONSTRUCTION_LICENSE_FORM;
        renewalConstructionLicense[0].formData.map((val: any) => {
            if (val?.field === 'constructionLicenseNo') {
                val.type = BPAData ? CONSTANT_DATA_FIELD.TEXT_FIELD : CONSTANT_DATA_FIELD.BPA_SELECT_FIELD;
                val.options = getCLList(allCLData);
                if (!!previewData?.lesseeDigitalSignFlage) val.isDisabled = true;
            }
        });
        if (!!previewData) {
            console.log(previewData?.validUpTo, 'EXPIRE_DATE123');
            const fileDate = new Date(previewData?.validUpTo ? previewData?.validUpTo : previewData?.additionalDetails?.validUpTo);
            const day = fileDate.getDate(),
                month = fileDate.getMonth() + 1,
                year = fileDate.getFullYear();
            values.expirationDate = `${day}/${month}/${year}`;
            console.log(values.expirationDate, 'EXPIRE_DATE');
        }

        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            {renewalConstructionLicense?.map(val => (
                                <GenericFormCard {...val} />
                            ))}
                            {/* <div>
                                <span className='formTitle ms-2 mb-4'>Existing Connection Details</span>
                                <GenericActionTable
                                    hideSearchBar={true}
                                    columns={columnsforWC}
                                    rowData={() => {}}
                                    itemsPerPage={5}
                                    highlightRow={true}
                                    totalRow={'total'}
                                    hideFooter={true}
                                    totalRowText={''}
                                />
                            </div>
                            {UPGRADED_CONNECTION_DETAILS_FORM?.map(val => (
                                <GenericFormCard {...val} />
                            ))} */}
                        </Card.Body>
                    </Card>
                </>
            </div>
        );
    };

    function handleChange(e: any) {
        if (e.target.checked) {
            setAllChecked([...allchecked, e?.target?.value]);
        } else {
            setAllChecked(allchecked.filter((item: any) => item !== e.target.value));
        }
    }

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({ show: false });
        if (type === 'warning') {
            navigateToDashboard();
        }
        if (type === 'success') {
            navigateToDashboard();
        }
        if (!!final) navigateToDashboard();
    };

    const getAuditData = () => {
        if (!applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.getAuditTrail(payload, applicationNumber, 'ga')
            .then(res => {
                setAuditTrial(res?.data?.ProcessInstances);
            })
            .catch(err => console.log(err));
    };

    const downloadApplicationDocument = () => {
        const fileStoreId = previewData?.digitalSignedDoc;
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${fileStoreId}`;
        window.open(downloadFileUrl);
    };

    useEffect(() => {
        getAuditData();
        loadRazorpay();
    }, []);

    const renderDeclarationDetails = () => {
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        <div className='row ps-2 py-2'>
                            <div className='formTitle m-0'>
                                {t('RCL_CARD_HEADER_SELF_DECLARATION', 'Self-Declaration')}
                                {/* <span className='text-danger'>*</span> */}
                            </div>
                        </div>
                        <div className='row d-flex m-0'>
                            <p className='' style={{ fontWeight: 600 }}>
                                The Lessee does hereby affirm and declare that:
                            </p>

                            <label className='mb-3 checkbox-container'>
                                {!!previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='1' name='step_1' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                                )}
                                <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span style={{ fontWeight: 600 }}>
                                    (i) The information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed
                                    herein.
                                </span>
                            </label>

                            <label className='mb-3 checkbox-container'>
                                {!!previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='2' name='step_2' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                                )}
                                <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span style={{ fontWeight: 600 }}>
                                    (ii) It has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation
                                    proceedings.
                                </span>
                            </label>

                            <label className='mb-3 checkbox-container'>
                                {!!previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='3' name='step_3' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                                )}
                                <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span style={{ fontWeight: 600 }}>
                                    (iii) The renewal of the aforementioned construction license shall be subject to all rules and regulations applicable to the original building plan. In the event of
                                    non-compliance with these requirements, the construction license shall be deemed null and void.
                                </span>
                            </label>

                            {/* <label className='mb-3 checkbox-container'>
                                {!!previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='4' name='step_4' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                                )}
                                <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span style={{ fontWeight: 600 }}>(iv) the Lessee agrees to pay annual Sub-Lease Fee to Corporation as defined under the Regulations; and</span>
                            </label>

                            <label className='mb-3 checkbox-container'>
                                {!!previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='5' name='step_5' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                                )}
                                <span className={`${previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span style={{ fontWeight: 600 }}>(v) it is not in breach of any term and condition of the Lease Deed.</span>
                            </label>  */}
                        </div>
                        {!!previewData?.lesseeDigitalSignFlage || eSignFlag ? (
                            <>
                                <div className='ps-2 pt-2 font-14 font-inter fw-bold m-0'>Digitally Signed</div>
                                <div className='ps-2 pb-2 font-14 font-inter'>{}</div>
                                <div onClick={() => downloadApplicationDocument()} className='ps-2 d-flex align-items-center'>
                                    <Image src={download} height={14} />
                                    <div className='ps-2 font-14' style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>
                                        Download Application
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='mt-3 col-7 '>
                                <p className='leaseDeed_Self_Declaration_Signature common_style_lease_paragraph mb-1 ms-1'>{`${t(
                                    'NAME_&_DIGITAL_SIGNATURE',
                                    'Name & Digital Signature of the Transferor/authorized person of the Transferor'
                                )}`}</p>
                                <GenericButtons text={'Sign the Document'} handleClick={signDocument} ClassName='px-4 ' />
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderProgressBarStatus = () => {
        const applicationData = {
            applicationNo: applicationNumber,
            status: previewData?.status,
            data: auditTrial,
            isRoleMD: true,
            bpaData: previewData
        };

        return (
            <Card className='card_border my-2'>
                <Card.Body>
                    <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                </Card.Body>
            </Card>
        );
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                    <div className='applicationPage employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('RCL_HEADER_APPLICATION_FOR_RENEWAL_OF_CL', 'Renewal of Construction License')}
                            bottomSubText={`Type : Construction License, No : ${previewData?.applicationNo}`}
                            additionalClass={'sub-text-style'}
                        />
                        <div className='col-3 stickyCard-section font-inter'>
                            <div className='stickyCard'>
                                <Card className={`card_border hiddenNav ${isVisible ? '' : 'scrolledNav'}`}>
                                    <Card.Body>
                                        <div className='d-flex align-items-center'>
                                            <GenericBackButton />
                                            <div className='ps-1'>
                                                <Card.Text className='font-inter-med p-0 fs-5' style={{ marginBottom: -4 }}>
                                                    {t('', '')}
                                                </Card.Text>
                                                {transferDetails && (
                                                    <Card.Subtitle className='sub-text-style text-nav font-inter p-0 m-0'>{`Type : Extension, No : ${transferDetails?.applicationDetails?.applicationNo}`}</Card.Subtitle>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard steps={stepsDetails} visited={visited} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            <FormikProvider value={formik}>
                                {!!applicationNumber && previewData?.status !== 'APPL_STATUS_CL_RENEWAL_INITIATE_PENDING' && previewData?.status !== 'Draft' && renderProgressBarStatus()}
                                {activeIndex === 1 && RenewalConstruactionLicense()}
                                {activeIndex === 2 && renderDeclarationDetails()}
                                {activeIndex === 3 && renderPayment()}
                            </FormikProvider>
                        </div>
                    </div>

                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== stepsDetails.length &&
                                    (previewData?.status === 'APPL_STATUS_CL_RENEWAL_INITIATE_PENDING' || previewData?.status === 'Draft' || !previewData?.status) && (
                                        <GenericButtons text={t('SAVE', 'Save')} handleClick={() => submitForm()} ClassName='me-3' />
                                    )}
                                <GenericButtons
                                    text={
                                        activeIndex === 3 && !paymentRowData?.isPaymentRecived
                                            ? `Pay Now`
                                            : activeIndex !== stepsDetails.length &&
                                              (previewData?.status === 'APPL_STATUS_CL_RENEWAL_INITIATE_PENDING' || previewData?.status === 'Draft' || !previewData?.status)
                                            ? `Save & Next`
                                            : activeIndex !== stepsDetails.length && previewData?.status !== 'APPL_STATUS_CL_RENEWAL_INITIATE_PENDING' && previewData?.status !== 'Draft'
                                            ? 'Next'
                                            : (activeIndex === 2 && !previewData?.lesseeDigitalSignFlage) ||
                                              ((!previewData?.status || previewData?.status === 'APPL_STATUS_CL_RENEWAL_INITIATE_PENDING' || previewData?.status === 'Draft') &&
                                                  !!previewData?.lesseeDigitalSignFlage)
                                            ? `Submit`
                                            : `Close`
                                    }
                                    variant='primary'
                                    handleClick={() => (activeIndex === 3 && !paymentRowData?.isPaymentRecived ? onPay() : submitForm())}
                                />
                            </div>
                        </div>
                    </footer>
                </>
            )}
        </>
    );
};

export default RenewalOfConstructionLicense;
