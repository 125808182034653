import React, { useEffect, useState } from 'react';
import './TechnicalClearanceFormPage.css';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import activeArrow from '../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../assets/logos/page left arrow.svg';
import { Form } from 'react-bootstrap';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Clearance_Form_Initial_Data, VALIDATION_CLEARANCE_FORM, stepsDetailsForTechnicalClearance, stepsDetailsForTechnicalClearanceWithout } from './TechnicalClearanceFormPage.module';
import { TechnicalClearanceFormJson, esignForDocuments, getDocsData, getFileurl } from './TechnicalClearanceFormPage.controller';
import { toast } from 'react-toastify';
import Loading from '../../../components/portalComponents/Loading.component';
import { check, close, cross, warning } from '../../../utils/Logo';
import TechnicalClearanceFormSubPage from './m_TechnicalClearanceFormSubPage.wrapper';
import TechnicalServices from '../../../services/technicalServices/Technical.service';
import { BPArequestInfo } from '../../../services/modulesServices/userManagement.api';
import { BPA_ACTIONS_DETAILS } from './TechnicalClearanceFormPage.const';
import HeaderComponent from '../../../components/header/Header.component';
import { PAYMENT_DATA } from './TechnicalClearencePayment/TechnicalClearencePayment';
import GenericPayment from './TechnicalClearencePayment/GenericPayment';
import { getNavigateTo } from '../../../utils/utils';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericPopupModal from '../../../components/moduleCommonFormik/GenericModalPopup.component';
import { useTranslation } from 'react-i18next';
import StatusAlertPopup from '../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericHeader from '../../../components/moduleCommonFormik/GenericHeader';
import ScrollToTop from '../../../components/ScrollToTop';
import NotingComponent from '../../../components/Workflow/NotingComponent';
import { DocumentInfo } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import GenericActionTable from '../../../components/moduleCommonFormik/GenericActionTable';
import { getHistory, onAttachDocument } from '../../modules/EstateManagement/EstateManagement.controller';
import GenericPDFViewer from '../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../components/fileviewer/GenericImageViewer';
import GenericFooterComponent from '../../../components/moduleCommonFormik/GenericFooterStepper.component';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/authSlice';
import { CorrespondenceWrapper, NotingWrapper } from '../../../components/Workflow/NotingWrapper';
import { ESIGN_COLUMNS } from '../../modules/EstateManagement/Transfer/Transfer.const';

export default function M_TechnicalClearanceFormPage() {
    const initialValues = Clearance_Form_Initial_Data;
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [activeStepper, setActiveStepper] = useState<string>('LESSEE_DETAITLS');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showStatus, setShowStatus] = useState<boolean>(false);
    const [showProcess, setShowProcess] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [applId, setAppId] = useState<string>('');
    const [bodydata, setBodydata] = useState<string>('');
    const [employeeData, setEmployeeData] = useState(null);
    const [previewData, setPreviewData] = useState<any>(null);
    const [actionsData, setActionsData] = useState<any>(null);
    const [appStatus, setAppStatus] = useState<any>(false);
    const [isCancelBtn, setCancelBtn] = useState<any>(false);
    const [isShowActionPopup, setIsShowActionPopup] = useState<boolean>(false);
    const [bpaData, setBpaData] = useState<any>(null);
    const [allBPADetails, setAllBPADetails] = useState<any>(null);
    const [allEdcrDetails, setAllEdcrDetails] = useState<any>(null);
    const [selectedAction, setSelectedAction] = useState<any>('');
    const [allchecked, setAllChecked] = React.useState<any>([]);
    var userinfo = window.localStorage.getItem('user');
    const user_uuid = !!userinfo && JSON.parse(userinfo).uuid;
    let stepperDetails = !!appStatus ? stepsDetailsForTechnicalClearanceWithout : stepsDetailsForTechnicalClearance;
    const [payVisible, setPayVisible] = useState<boolean>(false);
    const [isLesseeEmpty, setIsLesseeEmpty] = useState<boolean>(false);
    const [selectedBPA, setIsSelectedBPA] = useState<string>('');
    const [applicationFee, setApplicationFee] = useState<any>(null);
    const [sanctionFee, setSanctionFee] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentsDetails] = useState<any>([]);
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [comment, setcomment] = useState('');
    const [tabelData, setTabelData] = useState<any>([]);
    const [certificatesData, setCertificatesData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const userData = useSelector(selectUser);
    const userName = `${userData?.firstName ? userData?.firstName : ''} ${userData?.lastName ? userData?.lastName : ''}`;
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNumber = decodeURIComponent(urlParams.get('applicationNumber') || '');
    let user = localStorage.getItem('currentUserAssignment');
    const { t, i18n } = useTranslation();
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const [content, setContent] = useState('');
    const [modalDetails, setModalDetails] = useState<any>({});
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    const [searchParams] = useSearchParams();
    const stepperIndex = urlParams.get('stepper') || '';
    const documentType = urlParams.get('documentType') || '';
    const signedFileStoreId = urlParams.get('signedFileStoreId') || '';
    const [esignRowdata, setEsignRowdata] = useState<any>([]);
    const [isEsignPending, setIsEsignPending] = useState<any>(true);

    const formik = useFormik<any>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: initialValues,
        onSubmit: value => {},
        validationSchema: VALIDATION_CLEARANCE_FORM[`step_${activeIndex}`],
        validateOnMount: true
    });
    const { values, resetForm, setFieldValue } = formik;

    if (previewData?.status === 'Pending Application Fee Payment') {
        stepperDetails = [
            ...stepsDetailsForTechnicalClearance,
            {
                name: 'Payment',
                code: 'PAYMENT',
                index: 5,
                isActive: false,
                titleCode: 'PAYMENT'
            }
        ];
    }

    const handleClose = () => {
        setShowPopup(false);
        setShowStatus(false);
        setIsShowActionPopup(false);
    };

    const generateCertificates = () => {
        TechnicalServices.generateCertificate({ RequestInfo: BPArequestInfo(), applicationNo: applicationNumber, provisionalCertificateContent: content }).then((response: any) => {
            if (!!response?.data?.documents.length) {
                const approvedDocData = response?.data?.documents;
                const tableData = approvedDocData?.map((item: any, index: number) => {
                    if (!item?.digitalSignRequired) {
                        return {
                            ...item,
                            serialNo: index + 1,
                            order: item?.documentName,
                            applicationNo: item?.applicationNumber,
                            verification: 'DONE',
                            newDocRefID: item?.fileStoreId
                        };
                    } else {
                        return { ...item, serialNo: index + 1, order: item?.documentName, applicationNo: item?.applicationNumber, verification: `PENDING`, newDocRefID: item?.fileStoreId };
                    }
                });
                setEsignRowdata(tableData);
                setCertificatesData(response?.data?.documents);
                const esignPending = tableData.filter((item: any) => item.verification !== 'DONE');
                setIsEsignPending(!!esignPending.length);
                // esignSelf(response?.data?.documents[0]?.fileStoreId);
            } else {
                console.log('No Documents Found');
            }
        });
    };

    useEffect(() => {
        if (!!actionsData?.nextValidAction.filter((val: any) => val?.action === 'ACT_TC_APPROVE').length) {
            generateCertificates();
        }
    }, [actionsData]);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, '', null));
            getDocuments();
            setActiveIndex(2);
        } catch (e) {}
    };

    const handleSubmit = () => {
        setShowPopup(false);
        setShowStatus(false);
        if (activeStepper === 'DECLARATION') navigateToDashboard();
    };

    const handleStatusSubmit = () => {
        setShowStatus(false);
        navigateToDashboard();
    };

    const getHistoryData = async () => {
        if (applicationNumber) {
            try {
                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
                // toast(t('SOMETHING_WENT_WRONG', 'Something went wrong!'), {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
            }
        }
    };

    const getDocuments = async () => {
        if (applicationNumber) {
            try {
                const historyData: any = await TechnicalServices.searchAllDocuments(applicationNumber, { RequestInfo: BPArequestInfo() });
                setDocumentsDetails(historyData?.data?.documents);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    useEffect(() => {
        if (activeIndex === 2 && documentDetails) {
            const reverseDocumentDetails = documentDetails;
            const tableData = async () => {
                const responseTableData = reverseDocumentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${ind + 1}` };
                });
                const reverseData = responseTableData;
                setTabelData(reverseData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        getDocuments();
        getHistoryData();
        getPaymentData();
    }, []);

    const getActionsData = () => {
        if (!!applicationNumber) {
            setIsLoading(true);

            TechnicalServices.getBpaActions(applicationNumber, {
                RequestInfo: BPArequestInfo()
            })
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        setActionsData(response?.data);
                        response?.data?.nextValidAction?.forEach((val: any) => {
                            if (val?.action === 'Pay') {
                                setSelectedAction(val?.action);
                                setPayVisible(true);
                                return;
                            }
                        });
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const fetchPayEstimation = (data: any, type: string) => {
        if (!!applicationNumber) {
            setIsLoading(true);
            const payload = {
                RequestInfo: BPArequestInfo(),
                CalulationCriteria: [
                    {
                        applicationNo: applicationNumber,
                        riskType: 'High',
                        feeType: type,
                        tenantId: 'ga',
                        applicationType: 'TECHNICAL_CLEARANCE',
                        serviceType: 'NEW_CONSTRUCTION'
                    }
                ]
            };
            TechnicalServices.fetchEstimatePayDetails(payload)
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        if (type === 'ApplicationFee') setApplicationFee(response?.data?.Calculations && response?.data?.Calculations[0]);
                        if (type === 'SanctionFee') setSanctionFee(response?.data?.Calculations && response?.data?.Calculations[0]);
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const getPreviewDetails = () => {
        setIsLoading(true);
        if (!!applicationNumber) {
            TechnicalServices.getBpaSearch(applicationNumber, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    // handle the response
                    setIsLoading(false);
                    if (!response?.data?.BPA.length) {
                        setShowStatus(true);
                        setBodydata('No Application Found');
                        return;
                    }
                    setPreviewData(response?.data?.BPA[0]);
                    setContent(response?.data?.BPA[0]?.provisionalCertificateContent);

                    console.log(response?.data?.BPA[0]);
                    fetchInitialData(response?.data?.BPA[0]);
                    fetchPayEstimation(response?.data?.BPA[0], 'ApplicationFee');
                    fetchPayEstimation(response?.data?.BPA[0], 'SanctionFee');
                    const bpaValue = response?.data?.BPA[0];
                    if (!((bpaValue?.status === null || bpaValue?.status === 'Draft') && bpaValue?.createdBy === user_uuid)) setBpaData(response?.data?.BPA[0]);
                    setAppStatus((bpaValue?.status === null || bpaValue?.status === 'Draft') && bpaValue?.createdBy === user_uuid ? false : true);
                    // setAppStatus(bpaValue?.editable);
                    getActionsData();
                    // toast(t('DATA_FETCHED_SUCCESS', 'Data Fetched Successfully!'), {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'success'
                    // });
                })
                .catch(error => {
                    setShowStatus(true);
                    setBodydata('No Application Found');
                    setIsLoading(false);
                });
        } else {
            setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('SOMETHING_WENT_WRONG', 'Something Went Wrong!'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'error'
            // });
            setIsLoading(false);
        }
    };

    const onActionClick = (action: string) => {
        // setFieldValue('notes', '');
        setSelectedAction(action);
        if (action === 'Pay') {
            setPayVisible(true);
            return;
        }
        submitForm(0, action);
    };

    useEffect(() => {
        setTimeout(() => {
            if (!!stepperIndex && stepperIndex === 'Esign' && !!bpaData) {
                TechnicalServices.generateCertificate({ RequestInfo: BPArequestInfo(), applicationNo: applicationNumber, provisionalCertificateContent: content }).then((response: any) => {
                    if (!!response?.data?.documents.length) {
                        const approvedDocData = response?.data?.documents;
                        values.notes = urlParams.get('note');
                        const selectedDocument = approvedDocData.filter((val: any) => val?.documentType === documentType);
                        if (selectedDocument.length) {
                            const signedDocument = selectedDocument[0];
                            signedDocument.fileStoreId = signedFileStoreId;
                            signedDocument.applicationNumber = applicationNumber;
                            submitEsignDocument(signedDocument, 'ACT_TC_APPROVE');
                        }
                    } else {
                        console.log('No Documents Found');
                    }
                });
            }
        }, 500);
    }, [bpaData]);

    const esignSelf = async (fileStoreId: string, docType: string) => {
        const redirectURL = `/goa-idc/obpas/e/technicalClearance?${searchParams.toString()}&note=${values.notes}&docsUuid=${fileStoreId}&stepper=Esign&documentType=${docType}`;

        let url = window.location.origin + redirectURL;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${fileStoreId}&callbackUrl=${url}`);
        // esignForDocuments(fileStoreId, redirectURL);
    };

    const submitEsignDocument = (document: any, action?: string) => {
        setIsLoading(true);
        const allDocs = [
            ...values?.CERTIFICATE_ARCHITECTURE,
            ...values?.CERTIFICATE_LIABILITY,
            ...values?.SITEPLAN,
            ...values?.DOCUMENT_QUESTIONNAIRE,
            ...values?.DOCUMENT_AFFIDAVIT,
            ...values?.DOCUMENT_POWER_ATTORNEY
        ];

        const uniqueData = allDocs.filter((item, index, self) => index === self.findIndex(t => t.fileStoreId === item.fileStoreId));
        values.documents = uniqueData;
        const payload: any = TechnicalClearanceFormJson(values, bpaData, !!bpaData?.accountId);

        payload.BPA.edcrNumber = formData?.edcrNumber || payload?.BPA?.edcrNumber || null;
        if (!!bpaData?.accountId) {
            payload.BPA.documents = getDocsData(uniqueData, values?.appId);
        }

        payload.BPA.notes = {
            id: null, // Assuming it should have an ID, set as null or any specific value if needed
            content: values?.notes || '',
            notificationDetails: null // Assuming no specific notification details
        };

        payload.BPA.provisionalCertificateContent = content;

        if (action === 'ACT_TC_APPROVE') {
            payload.BPA.documents.push(document);
        }

        TechnicalServices.createTechnicalClearance(payload)
            .then((response: any) => {
                setIsLoading(false);
                let url = window.location.origin + `/goa-idc/obpas/e/technicalClearance?applicationNumber=${applicationNumber}`;
                window.open(url, '_self');
                generateCertificates();
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const submitForm = (key: number, action?: string, isEsign?: boolean, type?: any) => {
        if (!!appStatus && !action) {
            navigateToDashboard();
            return;
        }

        if (!!action && action === 'ACT_TC_APPROVE' && !!isEsignPending) {
            setModalDetails({ show: true, title: 'Error!', para: t('OC_MSG_CHECK_DOCUMENT_FOR_ESIGN', 'Please Esign the pending documents!'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (!!action && !values?.notes) {
            setModalDetails({ show: true, title: 'Error!', para: t('TC_MSG_CHECK_NOTING', 'Please add the noting and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeStepper === 'DECLARATION' && allchecked.length !== 3 && !appStatus) {
            setModalDetails({ show: true, title: 'Error!', para: t('CHECK_DECLARATION_PROCEED', 'Please check all declarations and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        setIsLoading(true);
        const allDocs = [
            ...values?.CERTIFICATE_ARCHITECTURE,
            ...values?.CERTIFICATE_LIABILITY,
            ...values?.SITEPLAN,
            ...values?.DOCUMENT_QUESTIONNAIRE,
            ...values?.DOCUMENT_AFFIDAVIT,
            ...values?.DOCUMENT_POWER_ATTORNEY
        ];
        const uniqueData = allDocs.filter((item, index, self) => index === self.findIndex(t => t.fileStoreId === item.fileStoreId));
        values.documents = uniqueData;
        const payload: any = TechnicalClearanceFormJson(values, bpaData, !!bpaData?.accountId);

        payload.BPA.edcrNumber = formData?.edcrNumber || payload?.BPA?.edcrNumber || null;
        if (!!bpaData?.accountId) {
            payload.BPA.documents = getDocsData(uniqueData, values?.appId);
        }

        if (activeStepper === 'DECLARATION') {
            payload.BPA.workflow = {
                action: 'SUBMIT',
                assignes: null,
                notes: null,
                varificationDocuments: null,
                uploadedUserRole: designationName
            };
        } else {
            payload.BPA.workflow = null;
        }

        if (!!selectedAction || !!action)
            payload.BPA.workflow = {
                action: selectedAction || action,
                assignes: null,
                notes: values?.notes,
                varificationDocuments: null,
                uploadedUserRole: designationName
            };

        payload.BPA.notes = {
            id: null, // Assuming it should have an ID, set as null or any specific value if needed
            content: values?.notes || '',
            notificationDetails: null // Assuming no specific notification details
        };

        payload.BPA.provisionalCertificateContent = content;

        // if (action === 'ACT_TC_APPROVE' && !!isEsign) {
        //     payload.BPA.documents.push({
        //         id: null,
        //         applicationNumber: applicationNumber,
        //         documentType: 'TECHNICAL_CLEARANCE_ORDER',
        //         fileStoreId: signedFileStoreId,
        //         tenantId: 'ga',
        //         documentName: 'TECHNICAL_CLEARANCE_ORDER.pdf',
        //         documentUid: '',
        //         additionalDetails: {}
        //     });
        // }

        TechnicalServices.createTechnicalClearance(payload)
            .then((response: any) => {
                setIsLoading(false);
                if (action === 'Initiate Construction Licence') {
                    navigate(`/goa-idc/obpas/constructionLicense?applicationNumber=${response?.data?.BPA[0]?.applicationNo}&tenantId=ga`);
                    return;
                }
                if (action === 'Pay') {
                    navigate('/goa-idc/obpas/paymentSkip');
                    return;
                }

                // toast(`${t('APPLICATION', 'Application')} ${!!values?.id ? t('SAVED', 'Saved') : t('CREATED', 'Created')} ${t('SUCCESSFULLY', 'Successfully!')}`, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'success'
                // // });
                // let actionLocalisationKey = 'POPUP_MSG_' + action;
                // console.log('actionLocalisationKey', actionLocalisationKey);
                const successMessageTCEmpApproved = `Application${applicationNumber ? ' Number ' + (applId || applicationNumber) : ''} approved Successfully`;
                const successMessageTCEmpForwarded = `Application${applicationNumber ? ' Number ' + (applId || applicationNumber) : ''} forwarded Successfully`;

                if (action === 'ACT_TC_APPROVE') {
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_TC_APPROVE', 'Technical Clearance Application Approved Successfully'),
                        para: successMessageTCEmpApproved,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applId || applicationNumber}</b>
                        //     </div>
                        // )
                    });
                    return;
                }

                if (action === 'ACT_TC_FIS_FORWARD') {
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_TC_FIS_FORWARD', 'Technical Clearance Application Forwarded Successfully'),
                        para: successMessageTCEmpForwarded,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applId || applicationNumber}</b>
                        //     </div>
                        // )
                    });
                }
                // setModalDetails({ show: true, title: '', para: t(actionLocalisationKey, 'Localisation is not given for Action'), img: check, type: 'success', reUpload: 'OK' });

                setAppId(response?.data?.BPA[0]?.applicationNo);
                setBpaData(response?.data?.BPA[0]);
                fetchInitialDocuments(response?.data?.BPA[0]);
                values.appId = response?.data?.BPA[0]?.applicationNo;
                values.id = response?.data?.BPA[0]?.id;

                if (activeStepper === 'DECLARATION') setShowPopup(true);
                if (key == 1) setActiveIndex(activeIndex + 1);
                if (!!selectedAction) {
                    setIsShowActionPopup(false);
                    getPreviewDetails();
                }
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
                // toast(error?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'error'
                // });
            });
    };

    const renderActionBtn = () =>
        actionsData?.nextValidAction.map((val: any) => {
            // eslint-disable-next-line array-callback-return
            if (val?.action === 'Submit') return;
            return (
                <>
                    <Button
                        variant='disabled outline-success new_btn btn btn-light me-3 font-karla-med p-10 fs-6'
                        style={{ borderColor: '#45852e', color: '#FFF', backgroundColor: '#45852e', width: 'max-content', borderRadius: 8 }}
                        onClick={() => onActionClick(val?.action)}
                    >
                        {/* {val?.action.replace(/_/g, ' ')} */}
                        {t(val?.action, 'Localisation Not Working')}
                    </Button>
                </>
            );
        });

    const renderActionForm = () => {
        return (
            <>
                {BPA_ACTIONS_DETAILS.map(val => (
                    <Card.Body>
                        <GenericFormCard {...val} />
                    </Card.Body>
                ))}
            </>
        );
    };

    const getActiveStepper = () => {
        stepperDetails.forEach(val => {
            if (val?.index === activeIndex) {
                setActiveStepper(val?.code);
            }
        });
    };

    const checkRelaod = () => {
        const reloadCount = sessionStorage.getItem('count') || '1';
        if (!!reloadCount && +reloadCount < 2) {
            sessionStorage.setItem('count', reloadCount + 1);
            window.location.reload();
        } else {
            sessionStorage.removeItem('count');
            getTechnicalClearanceData();
        }
    };

    const handelVisible = () => {
        setPayVisible(false);
    };
    const handelIsLesseeVisible = () => {
        setIsLesseeEmpty(false);
        navigate(-1);
    };

    useEffect(() => {
        getActiveStepper();
    }, [activeIndex]);

    useEffect(() => {
        if (!!applicationNumber) getPreviewDetails();
        if (!applicationNumber) checkRelaod();
        setTimeout(() => {
            // getUserData();
        }, 500);
    }, [applicationNumber]);

    const handleScroll = () => {
        if (window.scrollY > 110) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const DOC_TABLE_COLUMN = [
        {
            colName: 'SN',
            fieldName: 'serialNo',
            clsName: 'col-sm-2'
        },
        {
            colName: 'Uploaded By',
            fieldName: 'uploadedUserRole',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Date & Time',
            fieldName: 'uploadedDate',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Document',
            fieldName: 'docFileName',
            clsName: 'col-sm-2',

            isClickable: true
        }
    ];

    const ESIGN_COLUMNS_NEW = [
        {
            colName: 'SN',
            fieldName: 'serialNo',
            clsName: 'col-sm-1',
            id: 1
        },
        {
            colName: 'Order No.',
            fieldName: 'order',
            clsName: 'col-sm-7 column-text-start',
            isClickable: true,
            id: 2
        },
        {
            colName: 'Verification',
            fieldName: 'verification',
            clsName: 'col-sm-2',
            id: 3
        },
        {
            colName: 'Actions',
            fieldName: 'action',
            clsName: 'col-sm-1',
            actions: 'other',
            actionText: 'Verify'
        }
    ];

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({ show: false });
    };

    const onRowClick = (row: any) => {
        console.log(row, 'additionalMethod={onRowClick}');
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const getPaymentData = async () => {
        try {
            const payload = { RequestInfo: BPArequestInfo() };
            TechnicalServices.searchPaymentDetails(applicationNumber, payload)
                .then(res => {
                    if (res?.data) {
                        setPaymentRowData(res?.data);
                    } else {
                        throw new Error('No data found');
                    }
                })
                .catch(error => {
                    let errorMessage = 'Failed to retrieve payment data';
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        errorMessage += `: ${error.response.data.message || error.response.statusText}`;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage += ': No response received from server';
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage += `: ${error.message}`;
                    }
                    setModalDetails({ show: true, title: 'Error!', para: errorMessage, img: cross, type: 'error', reUpload: 'OK' });
                    // toast(errorMessage, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'error'
                    // });
                });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Unexpected error occurred', img: cross, type: 'error', reUpload: 'OK' });
            // toast('Unexpected error occurred', {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'error'
            // });
        }
    };

    const getTechnicalClearanceData = () => {
        const payload = { RequestInfo: BPArequestInfo() };
        TechnicalServices.applyTechnicalClearance(payload)
            .then(res => {
                if (res?.data) {
                    setAllBPADetails(res?.data?.BPA);
                    setAllEdcrDetails(res?.data?.sharedEdcr);
                    if (res?.data?.BPA.length > 0) {
                        fetchInitialData(res?.data?.BPA[0]);
                        setFormData(res?.data?.BPA[0]);
                    }
                }
            })
            .catch(err => {
                setIsLesseeEmpty(true);
                setModalDetails({ show: true, title: 'Error!', para: err?.response?.data?.Errors[0]?.message, img: cross, type: 'error', reUpload: 'OK' });
                // toast(err?.response?.data?.Errors[0]?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'error'
                // });
            });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (allBPADetails) {
            const mainBPADetails = allBPADetails.filter((val: any) => val?.edcrNumber === selectedBPA);
            fetchInitialData(mainBPADetails[0]);
            setFormData(mainBPADetails[0]);
        }
    }, [selectedBPA]);

    const fetchInitialDocuments = (preData: any) => {
        values.SITEPLAN = getFileurl(preData?.documents, 'SITEPLAN');
        values.DOCUMENT_QUESTIONNAIRE = getFileurl(preData?.documents, 'DOCUMENT_QUESTIONNAIRE');
        values.DOCUMENT_AFFIDAVIT = getFileurl(preData?.documents, 'DOCUMENT_AFFIDAVIT');
        values.DOCUMENT_POWER_ATTORNEY = getFileurl(preData?.documents, 'DOCUMENT_POWER_ATTORNEY');
        values.CERTIFICATE_ARCHITECTURE = getFileurl(preData?.documents, 'CERTIFICATE_ARCHITECTURE');
        values.CERTIFICATE_LIABILITY = getFileurl(preData?.documents, 'CERTIFICATE_LIABILITY');
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const fetchInitialData = (preData: any) => {
        values.name = preData?.lesseeDetails?.name;
        values.gender = preData?.lesseeDetails?.gender;
        values.phone = preData?.lesseeDetails?.phoneNo;
        values.panNo = preData?.lesseeDetails?.pan;
        values.email = preData?.lesseeDetails?.email;
        values.addressLine1 = preData?.lesseeDetails?.address?.addressLine1 || '';
        values.addressLine2 = preData?.lesseeDetails?.address?.addressLine2 || '';
        values.country = preData?.lesseeDetails?.address?.country;
        values.state = preData?.lesseeDetails?.address?.state;
        values.district = preData?.lesseeDetails?.address?.district;
        values.pincode = preData?.lesseeDetails?.address?.pincode;
        values.id = preData?.id;
        values.appId = preData?.applicationNo;
        values.archName = preData?.archName;
        values.archId = preData?.archRegId;
        values.serviceType = preData?.serviceType || 'NEW_CONSTRUCTION';
        values.licenseeType = '';
        values.CouncilArcNo = '';
        values.buildingPlan = [];
        values.token = '';
        values.cert = '';
        values.pin = '';
        values.documents = preData?.documents;
        values.forwardTo = preData?.lesseeDetails?.name;
        values.comments = '';
        values.notes = '';
        values.additionalDetails = preData?.additionalDetails;
        values.SITEPLAN = getFileurl(preData?.documents, 'SITEPLAN');
        values.DOCUMENT_QUESTIONNAIRE = getFileurl(preData?.documents, 'DOCUMENT_QUESTIONNAIRE');
        values.DOCUMENT_AFFIDAVIT = getFileurl(preData?.documents, 'DOCUMENT_AFFIDAVIT');
        values.DOCUMENT_POWER_ATTORNEY = getFileurl(preData?.documents, 'DOCUMENT_POWER_ATTORNEY');
        values.CERTIFICATE_ARCHITECTURE = getFileurl(preData?.documents, 'CERTIFICATE_ARCHITECTURE');
        values.CERTIFICATE_LIABILITY = getFileurl(preData?.documents, 'CERTIFICATE_LIABILITY');
        values.isEditableFile = preData?.editable;
        values.edcrNumber = preData?.edcrNumber;
        values.lesseeDetails = preData?.lesseeDetails;
        values.accountId = preData?.accountId;
    };

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const handleOtherAction = async (row: any) => {
        esignSelf(row?.newDocRefID, row?.documentType);
        console.log(row);
    };

    const gotoSkipProcess = () => {
        submitForm(0, selectedAction);
    };
    const totalPages = 2;
    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <StatusAlertPopup
                img={activeStepper === 'DECLARATION' ? check : !!formData?.edcrNumber ? check : cross}
                title={'Application Submitted Successfully'}
                para={''}
                fontsize={'fontsize'}
                children={
                    <div>
                        <span className='fw-bold font-14'>Your Application Number</span>: <b className='font-15 text-success'>{applId}</b>
                    </div>
                }
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                show={showPopup}
            />
            <StatusAlertPopup title='' para={bodydata} img={close} handleClose={handleStatusSubmit} handleSubmit={handleStatusSubmit} show={showStatus} />
            <div className='applicationPage m-auto px-5 py-4'>
                <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                    <GenericHeader
                        text={t('TC_EMP_HEADER_APPLICATION_FOR_TECHNICAL_CLEARANCE', 'Application for Technical Clearance')}
                        additionalClass='sub-text-style'
                        onActionClick={navigateToDashboard}
                        bottomSubText={`File : ${values?.name}, Type : ${t('TC_EMP_HEADER_TYPE', 'Technical Clearance')}, No : ${applicationNumber}`}
                    />
                    <Formik initialValues={initialValues} onSubmit={value => {}} validationSchema={VALIDATION_CLEARANCE_FORM}>
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section'>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('TC_EMP_SUBHEADER_APPLICATION_FOR_TECHNICAL_CLEARANCE', 'Application for Technical Clearance')}
                                        sideHeader={true}
                                        onActionClick={navigateToDashboard}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : ${t('TC_EMP_SUBHEADER_TYPE', 'Technical Clearance')}, No : ${applicationNumber}`}
                                    />
                                    <NotingWrapper
                                        text={comment}
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        // disable={!values.isUpdatable}
                                        handleOnFileUpload={handleOnFileUpload}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section'>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={{ businessService: previewData?.businessService }} />
                                {activeIndex === 1 && esignRowdata.length ? (
                                    <Card className='card_border p-1 mb-3'>
                                        <Card className='checklist-outer mb-4 mx-3'>
                                            <div className='row  my-3 px-3 py-2'>
                                                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                                                    <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                                                    <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                                        {t('E_VERIFICATION', 'e-Verification')}
                                                    </label>
                                                    <div className='form_fields px-2'>
                                                        <GenericActionTable
                                                            columns={ESIGN_COLUMNS_NEW}
                                                            hideSearchBar={true}
                                                            rowData={esignRowdata}
                                                            handleOtherAction={handleOtherAction}
                                                            additionalMethod={onRowClick}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Card>
                                ) : (
                                    <></>
                                )}
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <TechnicalClearanceFormSubPage
                                                applicationNumber={applicationNumber}
                                                index={activeIndex}
                                                activeStepper={activeStepper}
                                                setActiveIndex={setActiveIndex}
                                                employeeList={employeeData}
                                                formData={formData}
                                                previewData={previewData}
                                                appStatus={appStatus}
                                                showProcess={showProcess}
                                                allchecked={allchecked}
                                                setAllChecked={setAllChecked}
                                                bpaData={bpaData}
                                                isCancelBtn={isCancelBtn}
                                                setShowProcess={setShowProcess}
                                                allBPADetails={allBPADetails}
                                                allEdcrDetails={allEdcrDetails}
                                                setIsSelectedBPA={setIsSelectedBPA}
                                                applicationFee={applicationFee}
                                                sanctionFee={sanctionFee}
                                                paymentRowData={paymentRowData}
                                                content={content}
                                                setContent={setContent}
                                            />
                                        )}

                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={DOC_TABLE_COLUMN} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                        <GenericPopupModal
                                            title=''
                                            subTitle={`Application No: ${previewData?.applicationNo}`}
                                            isVisible={isShowActionPopup}
                                            primaryBtnTitle={selectedAction?.replace(/_/g, ' ')}
                                            secondaryAction={handleClose}
                                            primaryAction={() => {
                                                submitForm(0, selectedAction);
                                            }}
                                            secondaryBtnTitle='Close'
                                            children={renderActionForm()}
                                        />
                                    </Form>
                                </Card>
                            </div>
                        </FormikProvider>
                    </Formik>
                    <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                        <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                            <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === 2 && `rotate-class`}`} />
                        </span>
                        Page {activeIndex} of {totalPages}
                        <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                            <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                        </span>
                    </div>
                </div>
            </div>
            {
                <GenericFooterComponent
                    onClickNext={() => {}}
                    onSubmit={() => {}}
                    onCancel={() => navigateToDashboard()}
                    onClickPrev={() => {}}
                    stepperLength={0}
                    activeIndex={activeIndex}
                    children={renderActionBtn()}
                    isSaveBtn={false}
                    isSubmitBtn={false}
                />
            }
            {
                <GenericPopupModal
                    children={
                        <>
                            <Card className='card_border my-2'>
                                {PAYMENT_DATA.map((val: any) => (
                                    <Card.Body>
                                        <GenericPayment {...val} />
                                    </Card.Body>
                                ))}
                            </Card>
                        </>
                    }
                    isVisible={payVisible}
                    title='Payment Details'
                    secondaryAction={handelVisible}
                    primaryBtnTitle='Pay'
                    primaryAction={gotoSkipProcess}
                    secondaryBtnTitle='Cancel'
                    size='xl'
                />
            }
            <ScrollToTop />
            <StatusAlertPopup
                img={cross}
                title={'Failed to find the lessee'}
                para={''}
                fontsize={'fontsize'}
                scrutiny=''
                scrutinyPara={'scrutinyPara'}
                S_number=''
                reUpload='Close'
                handleClose={handelIsLesseeVisible}
                handleSubmit={handelIsLesseeVisible}
                show={isLesseeEmpty}
            />
            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId || modalData.newDocRefID} />}
                        {!isPDF && <GenericImageViewer />}
                    </div>
                </GenericPopupModal>
            )}

            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => {
                    handleModalClose(modalDetails.type);
                    navigateToDashboard();
                }}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => {
                    handleModalClose(modalDetails.type, modalDetails?.finalSubmit);
                    if (modalDetails.type === 'success') {
                        navigateToDashboard();
                    }
                }}
                children={modalDetails.children}
            />
        </>
    );
}
