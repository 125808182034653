import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ScrollToTop from '../../../../components/ScrollToTop';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import Loading from '../../../../components/portalComponents/Loading.component';
import { POST, POST2, devUrl, endpoints, getProgressBarData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { KEY_ID, TENANT_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../../utils/constants';
import { ALLOTMENT_FORM_VALIDATION, OWNERSHIP_VALIDATION, PROPOSED_ACTIVITY_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { getFirstErrorKey, getNavigateTo } from '../../../../utils/utils';
import { dropdownDataOptions, getFormatedOwnershipDetails } from '../../Migration/Migration.controller';
import PlotDetails from '../../Migration/pages/PlotDetails';
import { IdocumentTemplate, RazorpayOptions } from '../../UserManagement/types';
import { autoPopulatePlotDetails, fetchFeeData, fetchGatewayData, getNextActions, getOptions, getQueryParams, loadRazorpay, paymentCheck } from '../EstateManagement.controller';
import { APPLICANT_DETAILS_I, APPLICANT_DETAILS_II, DECLARATION_DETAILS, ENTERPRISE_DETAILS, PROJECT_DETAILS, PROMOTER_DETAILS, paymentColumns, paymentOptions, stepsDetails } from './Allotment.const';
import { allotmentPayload, getEnterpriseData, getEntityData, setEnterpriseDocData, setEntityDocData, getFormatedNICDetails, getInitPlotData } from './Allotment.controller';
import ApplicantDetails from './ApplicantDetails';
import Declaration from './Declaration';
import EnterpriseDetails from './EnterpriseDetails';
import PaymentDetails from './PaymentDetails';
import { cross, check } from '../../../../utils/Logo';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { TemplateCard } from '../TemplatesCard';
import { toast } from 'react-toastify';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';
import axios from 'axios';
import { handleUpdates } from '../../UserManagement/UserMangement.controller';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { EsignModal } from '../../../commonPages/EsignModal';
export interface DropdownData {
    plotNo: string[];
    phase: string[];
    plotType: string[];
    estateName: string[];
}

export default function CreateAllotment(props: any) {
    const location: any = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const searchParams: any = new URLSearchParams(location.search);
    const [searchParamsList] = useSearchParams();

    const ipb = searchParams.get('ipb');
    const auction = searchParams.get('auction');
    const applicationNumber = searchParams.get('applicationNumber') || searchParams.get('applicationNo');
    const signedFileStoreId = searchParams.get('signedFileStoreId');

    const [advertisementNumber, setAdvertisementNumber] = useState<any>('');
    const [plotId, setPlotId] = useState<any>('');
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visitedIndex, setVisitedIndex] = useState<number>(1);
    const [steps, setSteps] = useState<any>(stepsDetails);
    const [formType, setFormType] = useState<any>('IDC');
    const [documents, setDocuments] = useState<any>([]);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [documentTemplate, setDocumentTemplate] = useState<any>([]);
    const [filesSelection, setFilesSelection] = useState<any>([]);
    const [isChecked, setIsChecked] = useState([false, false, false]);
    const [addButton, setAddButton] = useState(false);
    const [showDownloadBox, setShowDownloadBox] = useState(false);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [calculated, setCalculated] = useState<boolean>(false);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [dropdownDataPlot, setDropdownDataPlot] = useState<DropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });
    const [searchApplicationResponse, setSearchApplicationResponse] = useState<any>({});
    const [applicationNo, setApplicationNo] = useState<any>('');
    const [applicationType, setApplicationType] = useState<any>('');
    const [allotmentDate, setAllotmentDate] = useState<any>(null);
    const [estateResponse, setEstateResponse] = useState<any>({});
    const [modalDetails, setModalDetails] = useState<any>({});
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [nicDetails, setNicDetails] = useState<any>([]);
    const [paymentData, setPaymentData] = useState<any>([]);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [isPaid, setIsPaid] = useState<any>(false);
    const [isApplicantRole, setIsApplicantRole] = useState<any>(true);
    const [paymentTried, setPaymentTried] = useState<any>(false);
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [showPopUp, setShowPopUp] = useState(false);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [applicationDetailsII, setApplicationDetailsII] = useState<any>(APPLICANT_DETAILS_II);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>(null);

    const queryParams = getQueryParams(searchParamsList);
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            specialProject: false,
            confirmation: false,
            applicantGst: false,
            udhyam: false,
            isStartup: false,
            womenEntrepreneur: false,
            localPerson: false,
            priorExperience: false,
            copyOnCheck: [],
            enterpriseCategory: '',
            esignOption: 'DSC'
        },
        onSubmit: value => {},
        validationSchema: !showOwnershipModal && !showPopUp ? ALLOTMENT_FORM_VALIDATION[`page${activeIndex}`] : showPopUp ? PROPOSED_ACTIVITY_VALIDATION : OWNERSHIP_VALIDATION
    });

    const { values, setValues, setTouched, setFieldValue, setFieldTouched, errors, resetForm } = formik;

    const applicantDetailsI = APPLICANT_DETAILS_I.map((val: any) => ({
        ...val,
        formData: val.formData?.map((field: any) => ({ ...field, isDisabled: true }))
    }));

    console.log();

    useEffect(() => {
        const checkRoles = (roles: any, roleNames: any) => {
            for (let i = 0; i < roles.length; i++) {
                if (!roleNames.includes(roles[i].code)) {
                    return false;
                }
            }
            return true;
        };
        const getDropdowns = async () => {
            const dropdownlist: any = await dropdownDataOptions();
            const roles = JSON.parse(localStorage.getItem('user') || '{}').roles;
            const { projectType, ...rest } = dropdownlist;
            const projectTypeFiltered = roles?.every((val: any) => val.code !== 'LESSEE') ? projectType?.filter((item: any) => item.code !== 'EXPANSION') : projectType;

            if (ipb) {
                setDropdownData({ ...rest, projectType: projectTypeFiltered });
            } else {
                const dropdownDataFiltered = { ...rest, pollutionCategory: dropdownlist?.pollutionCategory?.filter((item: any) => item.isOnlyForSpecialProject === false) };
                setDropdownData({ ...dropdownDataFiltered, projectType: projectTypeFiltered });
            }
            setApplicantData({ ...rest, projectType: projectTypeFiltered });
        };
        const isEnterprise = checkRoles(user?.roles || [], ['ENTERPRISE', 'APPLICANT']);
        setIsApplicantRole(isEnterprise);
        getDropdowns();
    }, [ipb]);

    useEffect(() => {
        (async () => {
            try {
                if (plotId && activeIndex === 5) {
                    const payCheck = await paymentCheck('PROCESSING_FEE,SECURITY_DEPOSIT', plotId, queryParams.applicationId || values?.applicationId);
                    setIsPaid(payCheck?.isPaid);
                    setPaymentTried(payCheck?.paymentTried);
                    setPaymentData(payCheck?.paymentData);
                    setTotalPaidAmount(payCheck?.totalPaidAmount);
                    setTransactionDetails(payCheck?.transactionDetails);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    const feeData = await fetchFeeData('ALLOTMENT', `PROCESSING_FEE${auction ? '' : ',SECURITY_DEPOSIT'}`, plotId);
                    setPaymentData(feeData?.feeMasterDetails);
                    setTotalPaidAmount(feeData?.totalPaidAmount);
                }
            } catch (e: any) {
                console.log(e);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                setPaymentIsLoading(false);
            }
        })();
    }, [activeIndex, plotId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const { data } = await POST('/idc-estate-service/plot/_metadata-search?plotStatus=VACANT', { RequestInfo: requestInfo() });

                setDropdownDataPlot({
                    plotNo: [],
                    phase: [],
                    plotType: [],
                    estateName: data.estateNames || []
                });
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setPlotData();
    }, [plotId]);

    useEffect(() => {
        if (applicationNumber && dropdownData) {
            setApplicationNo(applicationNumber);
            setApplicationType('ALLOTMENT');
            setAllotmentData(applicationNumber, dropdownData);
            (async () => {
                const nextActions = await getNextActions(applicationNumber);
                setHasValidAction(nextActions?.nextValidAction?.length > 0);
                setValues({ ...values, isUpdatable: nextActions?.nextValidAction?.length > 0 });
                if (nextActions?.nextValidAction?.length === 0) {
                    setIsChecked([true, true, true]);
                }
                setNextValidAction(nextActions);
            })();

            if (!!applicationNumber)
                getProgressBarData(applicationNumber)
                    .then(res => setProgressBarDetails(res.data))
                    .catch(e => console.log(e));
        }
    }, [applicationNumber, dropdownData]);

    console.log('progress bar details', progressBarDetails);

    useEffect(() => {
        const setpaymentQueryData = async (queryparams: any) => {
            setPaymentIsLoading(true);
            setPlotId(queryparams?.['plotId'] || '');
            if (formType === 'IDC') setAdvertisementNumber(queryparams?.['advertisementNumber'] || '');
            setActiveIndex(+queryparams?.['activeIndex']);
            if (visitedIndex < +queryparams?.['activeIndex']) setVisitedIndex(+queryparams?.['activeIndex']);
            setApplicationNo(queryparams?.['applicationNo'] || queryparams?.['applicationNumber'] || '');
            const dropdownlist = await dropdownDataOptions();
            setAllotmentData(queryparams?.['applicationNo'] || queryparams?.['applicationNumber'], dropdownlist);
            setIsChecked([true, true, true]);
            let params = { ...queryparams };
            setPaymentTried(true);
            // setIsPaid(null);
            getTransactionUpdate(params);
        };

        let formType = 'IDC';
        // setActiveIndex(1);
        // setVisitedIndex(1);
        if (queryParams?.['auction'] === 'true') {
            setAdvertisementNumber('');
            setPlotId('');
            setSteps(stepsDetails);
            formType = 'AUCTION';
            setFormType('AUCTION');
        } else if (queryParams?.['ipb'] === 'true') {
            setAdvertisementNumber('');
            // setPlotId('');
            setPlotId(values.plotId);
            setSteps(stepsDetails);
            formType = 'IPB';
            setFormType('IPB');
        } else {
            setAdvertisementNumber(location?.state?.advertisementNumber || '');
            setPlotId(location?.state?.plotId || '');
            setSteps(stepsDetails);
            formType = 'IDC';
            setFormType('IDC');
        }
        if (queryParams?.['applicationNo']) {
            setpaymentQueryData(queryParams);
        }
    }, [location.search]);

    useEffect(() => {
        loadRazorpay();
    }, []);

    useEffect(() => {
        if (values?.category?.code) {
            if (values?.category?.code === 'PROP_FIRM') {
                if (ownershipDetails?.length) {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: ownershipDetails[0]?.name }));
                } else {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: '' }));
                }
            } else {
                setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.applicantName }));
            }
        }
    }, [values?.category?.code, ownershipDetails, values?.applicantName]);

    const setAllotmentData = async (appNo: any, dropdownData: any) => {
        try {
            setIsLoading(true);
            if (appNo) {
                const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${appNo}`, { RequestInfo: requestInfo() });
                if (data.estateApplications.length > 0) {
                    let allotmentData = data.estateApplications?.[0];
                    const res1 = await getEntityData(allotmentData?.enterpriseEntity, dropdownData);
                    const res2 = allotmentData?.enterpriseDetails && (await getEnterpriseData(allotmentData?.enterpriseDetails, dropdownData));
                    const plotInitData = getInitPlotData(allotmentData?.appliedPlotDetails);
                    setSearchApplicationResponse(allotmentData.enterpriseEntity || {});
                    setEstateResponse(allotmentData || {});
                    setAllotmentDate(allotmentData?.auditDetails.createdTime);
                    setAdvertisementNumber(allotmentData?.advertisement?.advNo || null);
                    setFileStoreId(allotmentData?.signedConsolidatedDocRefId);
                    setValues((prev: any) => ({
                        ...prev,
                        ...res1,
                        ...res2,
                        ...plotInitData,
                        isAdvertisement: !ipb && !auction ? true : false,
                        applicationId: allotmentData.uuid
                    }));
                    const newOwnership = await getFormatedOwnershipDetails(allotmentData?.enterpriseEntity?.owners, dropdownData);
                    setOwnershipDetails(newOwnership);
                    setControllingOwnershipResponse(allotmentData?.enterpriseEntity?.owners);
                    const newProposed = await getFormatedNICDetails(allotmentData?.enterpriseDetails?.nicEnterpriseMappings || [], dropdownData);
                    setNicDetails(newProposed);
                    setPlotId(allotmentData?.appliedPlotDetails?.uuid);
                }
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    console.log('formik', formik);

    const setApplicantData = async (dropdownData: any) => {
        try {
            setIsLoading(true);
            const userId = JSON.parse(localStorage.getItem('user') || '{}')?.uuid;
            const { data } = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&userUuid=${userId}`, { RequestInfo: requestInfo() });
            if (data.enterpriseEntity.length > 0) {
                const res = await getEntityData(data.enterpriseEntity[0], dropdownData);
                setSearchApplicationResponse(data.enterpriseEntity[0]);
                setValues((prev: any) => ({
                    ...prev,
                    ...res,
                    specialProject: ipb ? true : false,
                    isAdvertisement: !ipb && !auction ? true : false,
                    isAuction: auction ? true : false
                }));
                const newOwnership = await getFormatedOwnershipDetails(data?.enterpriseEntity[0].owners, dropdownData);
                setOwnershipDetails(newOwnership);
                setControllingOwnershipResponse(data?.enterpriseEntity[0].owners);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const setPlotData = async () => {
        try {
            if (plotId) {
                setIsLoading(true);
                const plotData = await autoPopulatePlotDetails(plotId);
                setValues({ ...values, ...plotData, enterpriseCategory: plotData?.enterpriseCategory });
                console.log('plotData', plotData);
                let dummyData = { ...values, ...plotData };
                console.log('dummyData', dummyData);
                // const res1 = await POST(`/idc-estate-service/plot/_search?plotUuids=${plotId}`, { RequestInfo: requestInfo() });
                // const data = res1.data.plots[0];
                // const convertForm = (value: any) => {
                //     return { name: value || '', code: value || '' };
                // };
                // setValues({
                //     ...values,
                //     estateName: convertForm(data.estateDetails.estateName),
                //     phase: convertForm(data.phase),
                //     // plotNo: {convertForm(data.plotNo)},
                //     plotNo: { name: data.plotNo, code: data.plotNo },
                //     plotType: convertForm(data.plotType),
                //     plotRate: data.plotRate,
                //     plotArea: data.plotAreaSqm,
                //     enterpriseCategory: convertForm(data.plotType)
                // });
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success' && final) {
            navigate('/goa-idc/enterprisedashboard');
        }
    };

    const showModal = () => {
        setAddButton(true);
    };

    const getSelectedDocuments = (documents: IdocumentTemplate[]) => {
        setFilesSelection(documents);
    };

    const handleOnAssignmentChange = (index: number, field: string, value: any) => {};

    const signDocument = async () => {
        let url =
            window.location.origin +
            `/goa-idc/estate/allotment?activeIndex=${activeIndex}&applicationNo=${applicationNo}&advertisementNumber=${advertisementNumber}&${
                formType === 'IPB' ? 'ipb=true' : formType === 'AUCTION' ? 'auction=true' : ''
            }`;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${eSignedDocumentData.filestoreId}&callbackUrl=${url}`);
    };

    const handleClickSignDocument = () => {
        if (activeIndex === 4 && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setEsignModal(true);
    };

    // const signDocument = async () => {
    // 	if (activeIndex === 4 && !isChecked.every((e: boolean) => e)) {
    // 		setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
    // 		return;
    // 	}

    // 	try {
    // 		const userRequestInfo = requestInfo();
    // 		if (window.location.protocol !== 'http:') {
    // 			let url =
    // 				window.location.origin +
    // 				`/goa-idc/estate/allotment?activeIndex=${activeIndex}&applicationNo=${applicationNo}&advertisementNumber=${advertisementNumber}&${
    // 					formType === 'IPB' ? 'ipb=true' : formType === 'AUCTION' ? 'auction=true' : ''
    // 				}`;
    // 			url = btoa(url);
    // 			const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${eSignedDocumentData?.filestoreId}&accessToken=${userRequestInfo.authToken}&userType=${
    // 				userRequestInfo.userType
    // 			}${userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''}`;
    // 			await axios.get(esignUrl);

    // 			window.open(esignUrl, '_self');
    // 		} else {
    // 			let url =
    // 				window.location.origin +
    // 				`/goa-idc/estate/allotment?activeIndex=${activeIndex}&applicationNo=${applicationNo}&signedFileStoreId=${
    // 					eSignedDocumentData.filestoreId
    // 				}&advertisementNumber=${advertisementNumber}&${formType === 'IPB' ? 'ipb=true' : formType === 'AUCTION' ? 'auction=true' : ''}`;

    // 			window.open(url, '_self');
    // 		}
    // 	} catch (e) {
    // 		console.log('esign error', e);
    // 	}

    // 	// let url = window.location.origin + `/goa-idc/estate/allotment`;
    // 	// const formData = new FormData();
    // 	// formData.append('authType', '1');
    // 	// formData.append('callBackUrl', url);
    // 	// formData.append('fileStoreId', '3bee5050-f883-4d15-8fa8-47fcaaeab6a1');
    // 	// const headers = {
    // 	//     'Content-Type': 'application/json'
    // 	// };
    // 	// try {
    // 	// const response = await axios.post(
    // 	//     `https://20.0.129.210:9090/idc-doc-esign-services/v1/generateESignRequest?authType=1&callBackUrl=${url}&fileStoreId=3bee5050-f883-4d15-8fa8-47fcaaeab6a1`,
    // 	//     { RequestInfo: requestInfo() },
    // 	//     { headers }
    // 	// );
    // 	//     let payload = {
    // 	//         eSignRequest:
    // 	//             '<?xml version="1.0" encoding="UTF-8"?><Esign AuthMode="1" aspId="GIDC-900" ekycId="" ekycIdType="A" responseSigType="pkcs7" responseUrl="https://20.0.129.210:9090/idc-doc-esign-services/v1/handleResponse?newFilename=6c91377a-33cd-4410-81b9-ec1eeece9705&amp;originalFilename=Identity Proof&amp;callBackUrl=http://localhost:3000/goa-idc/estate/allotment" sc="Y" ts="2024-06-06T15:44:56" txn="1156067504" ver="2.1"><Docs><InputHash docInfo="My Document" hashAlgorithm="SHA256" id="1">88572ac7620784b6bafa5d195d254c5efe8db873619b230994ef0e85bd17aa1e</InputHash></Docs><Signature xmlns="http://www.w3.org/2000/09/xmldsig#"><SignedInfo><CanonicalizationMethod Algorithm="http://www.w3.org/TR/2001/REC-xml-c14n-20010315"/><SignatureMethod Algorithm="http://www.w3.org/2000/09/xmldsig#rsa-sha1"/><Reference URI=""><Transforms><Transform Algorithm="http://www.w3.org/2000/09/xmldsig#enveloped-signature"/></Transforms><DigestMethod Algorithm="http://www.w3.org/2001/04/xmlenc#sha256"/><DigestValue>8p/XzXdwYxi0qSxLYTiYl2xyOD0zgdlrmx/QeeekuU8=</DigestValue></Reference></SignedInfo><SignatureValue>c5GqZt13j2BE7Y85IJ6h+tf7HWL2yzTmI1OCbAFgSnIWvv+wN7JS9s7ZW9MDtAt/uoVs4ao83ROx\ngU7FUJ8+ZGt/cdKGuzJCKws46wxmSQkG+qNG17KWypfjrrEE+srF4JIpeKWDbzfCXwzsx2Ge0ch8\nSYkl6Tse0JPlxO7+rjsVLkmXp9tbNaV7Hwsdba6Gc8zjFNp09nXmVAFtExZO0/cQej0XF2jb8DtT\nbqdfKNTiyp9ZSLOBonPRdrx44RhFUXmHxU7dcZAbfFpzy0fgm9JMLAvGqPxDVOKRA/aZJK5pBYAk\npqr1jSE4NS0x6QC0nqpx2ClcvX1/ivBvcWsuCA==</SignatureValue></Signature></Esign>',
    // 	//         aspTxnID: '1156067504',
    // 	//         contentType: 'application/xml'
    // 	//     };

    // 	//     const headers = {
    // 	//         Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
    // 	//         //     'Accept-Encoding': 'gzip, deflate, br, zstd',
    // 	//         //     'Accept-Language': 'en-US,en;q=0.9',
    // 	//         //     'Cache-Control': 'max-age=0',
    // 	//         //     Connection: 'keep-alive',
    // 	//         //     'Content-Length': 1975,
    // 	//         'Content-Type': 'application/x-www-form-urlencoded',
    // 	//         //     Host: 'es-staging.cdac.in',
    // 	//         Origin: 'https://localhost:9090',
    // 	//         Referer: 'https://localhost:9090/'
    // 	//         //     'Sec-Fetch-Dest': 'document',
    // 	//         //     'Sec-Fetch-Mode': 'navigate',
    // 	//         //     'Sec-Fetch-Site': 'cross-site',
    // 	//         //     'Sec-Fetch-User': '?1',
    // 	//         //     'Upgrade-Insecure-Requests': '1',
    // 	//         //     'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36',
    // 	//         //     'sec-ch-ua': '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"',
    // 	//         //     'sec-ch-ua-mobile': '?0',
    // 	//         //     'sec-ch-ua-platform': '"Windows"'
    // 	//     };
    // 	//     const res = await axios.post('https://es-staging.cdac.in/esignlevel2/2.1/form/signdoc', payload, { headers });
    // 	//     console.log('res', res);
    // 	//     // console.log('Response:', response);
    // 	// } catch (error) {
    // 	//     console.error('Error uploading files', error);
    // 	// }
    // 	// console.log(`let's sign the document`);
    // };

    useEffect(() => {
        (async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                if (signedFileStoreId) {
                    const { data } = await POST(endpoints.consolidatedDocESign, payload);
                    // setESignedDocumentData(data);
                    !data.isEsignRequired && setIsChecked([true, true, true]);
                    const consolidDocSearch = await POST(endpoints.consolidatedDocSearch, payload);
                    consolidDocSearch?.data.filestoreId && setESignedDocumentData(consolidDocSearch?.data);
                }
            } catch (e: any) {
                console.log(e);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [signedFileStoreId]);

    useEffect(() => {
        const fetchESignStatus = async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNo || applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                const { data } = await POST(endpoints.consolidatedDocSearch, payload);
                data.filestoreId && setESignedDocumentData(data);
                !data.isEsignRequired && setIsChecked([true, true, true]);
            } catch (e: any) {
                console.log(e);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        if (activeIndex === 4 && !signedFileStoreId) {
            fetchESignStatus();
        }
    }, [activeIndex]);

    const renderDeclarationDetails = () => {
        const declarationList = [
            '(i) the information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed herein.',
            '(ii) it has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy or liquidation proceeding.',
            '(iii) The provision allotment order or the lease dead,as the case may be,shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the information submitted under or along with this application.'
        ];
        return (
            <>
                <Declaration
                    isChecked={isChecked}
                    declarationList={declarationList}
                    handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                    setDocuments={setDocuments}
                    signDocument={handleClickSignDocument}
                    eSignedDocumentData={eSignedDocumentData}
                    isDisabled={!hasValidAction}
                    fileStoreId={fileStoreId}
                ></Declaration>
            </>
        );
    };

    console.log('valuesss', formik);

    const getTransactionUpdate = async (params: any) => {
        try {
            if (params.plotId) {
                const paid: any = await paymentCheck('PROCESSING_FEE,SECURITY_DEPOSIT', params.plotId, params.applicationId || values?.applicationId);
                if (!paid.isPaid) {
                    const payload = { RequestInfo: requestInfo() };
                    const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                    if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                        setTransactionDetails(data?.Transaction?.[0]);
                        if (data?.Transaction?.[0]?.txnId) {
                            try {
                                const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                                if (resp.data.status === 'SUCCESSFUL') {
                                    setModalDetails({ show: true, title: 'Success!', para: 'Payment successful', img: check, type: 'success', reUpload: 'OK' });
                                    setIsPaid(true);
                                } else {
                                    setIsPaid(false);
                                }
                            } catch (e: any) {
                                console.log(e);
                                setIsPaid(false);
                                setPaymentIsLoading(false);
                            }
                        }
                    } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                        setModalDetails({
                            show: true,
                            title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                            para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                            img: cross,
                            type: 'error',
                            reUpload: 'OK'
                        });
                        // window.location.reload();
                    } else {
                        setIsPaid(false);
                    }
                }
            }
            setIsLoading(false);
            setPaymentIsLoading(false);
        } catch (e: any) {
            console.log(e);
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Transaction Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onPay = async () => {
        setPaymentIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/allotment?${
                formType === 'IPB' ? 'ipb=true&' : formType === 'AUCTION' ? 'auction=true&' : ''
            }plotId=${plotId}&activeIndex=${activeIndex}&advertisementNumber=${advertisementNumber}&applicationNo=${applicationNo}&applicationId=${values.applicationId}`;
        const oneYearLaterDate = new Date(allotmentDate);
        oneYearLaterDate.setFullYear(oneYearLaterDate.getFullYear() + 1);
        const periodTo = oneYearLaterDate.getTime();
        const payload = {
            RequestInfo: requestInfo(),
            applicationNumber: applicationNo || applicationNumber,
            plotId: plotId,
            totalPaidAmount: +totalPaidAmount,
            callbackUrl: url,
            productInfo: 'Allotment Application Fees',
            gateway: selectedGateway,
            periodFrom: allotmentDate,
            periodTo: periodTo,
            payNowPaymentDetails: paymentData.map((obj: any) => ({
                scheduledPaymentDetails: {
                    paymentType: obj.feeType,
                    principalAmount: obj.principalAmount,
                    cgstPrincipal: obj.cgstPrincipal,
                    sgstPrincipal: obj.sgstPrincipal,
                    totalDueAmount: ((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2),
                    paidAmount: ((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2)
                }
            })),
            applicationId: values.applicationId
        };
        try {
            if (!totalPaidAmount) {
                setPaymentIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (!selectedGateway) {
                setPaymentIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, user, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    console.log(data.additionalDetails);
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
            }
        } catch (e: any) {
            console.log(e);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setPaymentIsLoading(false);
            setModalDetails({ show: true, title: t('PAYMENT_FAILED', 'Payment Initiation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const lastSubmit = (isSaveOnly?: boolean, status?: any, comment?: string) => {
        if (!isPaid) onPay();
        else onSubmit(isSaveOnly, status, comment);
    };

    const onSubmit = async (isSaveOnly?: boolean, status?: any, comment?: string) => {
        const isFinalSubmit = activeIndex === steps?.length ? true : false;
        const payload = allotmentPayload(values, advertisementNumber, plotId, formType, nicDetails, ownershipDetails, activeIndex, searchApplicationResponse, estateResponse, isFinalSubmit);
        let data;

        const currOwnership = ownershipDetails
            .filter((item: any) => !item.isDelete)
            .reduce((accumulator: number, subItem: any) => {
                return accumulator + subItem.ownershipInterest;
            }, 0);

        console.log(currOwnership);

        try {
            await ALLOTMENT_FORM_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });

            if (activeIndex === 1 && currOwnership != 100 && values?.category?.code !== 'LTD_COMPANY') {
                setModalDetails({ show: true, title: 'Error!', para: 'Your ownership interest does not add upto 100%', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            if (activeIndex === 1 && !calculated) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please calculate controlling ownership', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (activeIndex === 3 && (!nicDetails || !nicDetails?.length)) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please add atleast 1 proposed activity', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (activeIndex === 4 && !isChecked.every((e: boolean) => e)) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }

            if (activeIndex === 4 && eSignedDocumentData?.isEsignRequired) {
                setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            try {
                setIsLoading(true);
                if (!values.applicationId) {
                    data = await POST(endpoints.createAllotment, payload);
                } else {
                    data = await POST(endpoints.updateAllotment, payload);
                }
                !isSaveOnly && onChangeNextIndex();
                setIsLoading(false);
            } catch (e: any) {
                console.log(e);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setIsLoading(false);
                setModalDetails({ show: true, title: `Application${applicationNo ? ' Number ' + applicationNo : ''} for Allotment Failed`, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
            if (data) {
                setPlotId(plotId ? plotId : payload.appliedPlotId);
                setApplicationNo(data.data.estateApplications?.[0].applicationNo || '');
                setApplicationType(data.data.estateApplications?.[0].applicationType || '');
                setSearchApplicationResponse(data.data.estateApplications?.[0].enterpriseEntity || {});
                setEstateResponse(data.data.estateApplications?.[0] || {});
                setAllotmentDate(data.data.estateApplications?.[0]?.auditDetails.createdTime);
                const entityDocs = await setEntityDocData(data.data.estateApplications?.[0]?.enterpriseEntity?.documents || []);
                const enterpriseDocs = await setEnterpriseDocData(data.data.estateApplications?.[0]?.enterpriseDetails?.documents || []);
                setValues({
                    ...values,
                    applicationId: data.data.estateApplications?.[0].uuid,
                    ...entityDocs,
                    ...enterpriseDocs
                });
                if (isFinalSubmit) {
                    setModalDetails({
                        show: true,
                        title: 'Success!',
                        para: `Application${applicationNo ? ' Number ' + applicationNo : ''} for Allotment submitted successfully`,
                        finalSubmit: true,
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                    });
                } else toast('Action successful', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
            }
        } catch (validationError) {
            console.log(validationError);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('application type', applicationType);

    const onChangeNextIndex = async () => {
        try {
            if (activeIndex < steps.length) {
                setActiveIndex(activeIndex + 1);
            }
            if (visitedIndex < activeIndex + 1) setVisitedIndex(activeIndex + 1);
            window.scrollTo(0, 0);
        } catch (e) {
            console.log(e);
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = steps.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex && !advertisementNumber) {
            try {
                await ALLOTMENT_FORM_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                console.log(e);
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onChangeAction = (e: any, field: string) => {
        const { authorizedPersonFirstname, authorizedPersonLastName, pmobileNumber, alternateMobileNumber, authorizedEmailId, ...rest } = values;
        if (field === 'authorizedPersonFirstname') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: e.target.value,
                authorizedPersonLastName: authorizedPersonLastName,
                pmobileNumber: pmobileNumber,
                alternateMobileNumber: alternateMobileNumber,
                authorizedEmailId: authorizedEmailId
            });
        } else if (field === 'authorizedPersonLastName') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: authorizedPersonFirstname,
                authorizedPersonLastName: e.target.value,
                pmobileNumber: pmobileNumber,
                alternateMobileNumber: alternateMobileNumber,
                authorizedEmailId: authorizedEmailId
            });
        } else if (field === 'pmobileNumber') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: authorizedPersonFirstname,
                authorizedPersonLastName: authorizedPersonLastName,
                pmobileNumber: e.target.value,
                alternateMobileNumber: alternateMobileNumber,
                authorizedEmailId: authorizedEmailId
            });
        } else if (field === 'alternateMobileNumber') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: authorizedPersonFirstname,
                authorizedPersonLastName: authorizedPersonLastName,
                pmobileNumber: pmobileNumber,
                alternateMobileNumber: e.target.value,
                authorizedEmailId: authorizedEmailId
            });
        } else if (field === 'authorizedEmailId') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: authorizedPersonFirstname,
                authorizedPersonLastName: authorizedPersonLastName,
                pmobileNumber: pmobileNumber,
                alternateMobileNumber: alternateMobileNumber,
                authorizedEmailId: e.target.value
            });
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    const resetPlot = () => {
        setValues({ ...values, estateName: '', phase: '', plotNo: '', plotType: '', plotRate: '', plotArea: '' });
    };

    console.log('dropdowndata', dropdownData);

    const status: any = progressBarDetails?.progressStatus.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNo,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        // isRoleMD: isRoleMD,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    useEffect(() => {
        const updatedDetails = handleUpdates(values, applicationDetailsII, APPLICANT_DETAILS_II, 'Address Details', 'Mailing/Correspondence address of the Entity');
        setApplicationDetailsII(updatedDetails);
    }, [values?.isPermanentPincodeDetailsPresent, values?.isPincodeDetailsPresent, values?.copyOnCheck]);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.finalSubmit)}
                    />
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('APPLICATION_ALLOTMENT_FORM', `Application for Allotment via ${ipb ? 'IPB' : auction ? 'Auction' : 'Advertisement'}`)}
                            bottomSubText={applicationNo ? `Type : ${applicationType}, No : ${applicationNo}` : ``}
                            additionalClass='sub-text-style'
                        />
                        <div className='col-3 stickyCard-section'>
                            <div className='stickyCard'>
                                <GenericHeader
                                    text={t('APPLICATION_ALLOTMENT_FORM', `Application for Allotment via ${ipb ? 'IPB' : auction ? 'Auction' : 'Advertisement'}`)}
                                    sideHeader={true}
                                    additionalClass='sub-text-style'
                                    bottomSubText={applicationNo ? `Type : ${applicationType}, No : ${applicationNo}` : ``}
                                />

                                <Card className='card_border'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard steps={steps} activeIndex={activeIndex} visitedIndex={visitedIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>

                                {/* {templateLinks?.length > 0 && (
                            // <Card className='card_border'>
                            //     <Card.Body>
                            //         <Card.Title>Templates</Card.Title>
                            //         {templateLinks.map((link: any) => (
                            //             <div>
                            //                 <div
                            //                     onClick={() => {
                            //                         handleOnDownloadTemplate(link.documentReferenceId);
                            //                     }}
                            //                     style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}
                            //                 >
                            //                     {link.documentName}
                            //                 </div>
                            //             </div>
                            //         ))}
                            //     </Card.Body>
                            // </Card>
                            )} */}
                                <TemplateCard category={`ENTITY_REGISTRATION, ENTERPRISE_DETAILS`} />
                            </div>
                        </div>

                        <div className='col-9 form-section'>
                            {!!applicationNumber && applicationData.status !== 'Draft' && (
                                <Card className='card_border my-2'>
                                    <Card.Body>
                                        <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                                    </Card.Body>
                                </Card>
                            )}
                            <FormikProvider value={formik}>
                                <Form>
                                    {activeIndex === 1 && (
                                        <Card className='card_border'>
                                            <ApplicantDetails
                                                dropdownOptions={dropdownData}
                                                setOwnershipDetails={setOwnershipDetails}
                                                controllingOwnershipResponse={controllingOwnershipResponse}
                                                setControllingOwnershipResponse={setControllingOwnershipResponse}
                                                ownershipDetails={ownershipDetails}
                                                setCalculated={setCalculated}
                                                isDisabled={!isApplicantRole}
                                                showOwnershipModal={showOwnershipModal}
                                                setShowOwnershipModal={setShowOwnershipModal}
                                                applicantDetails1={applicantDetailsI}
                                                applicantDetails2={applicationDetailsII}
                                                promoterDetails={PROMOTER_DETAILS}
                                                ownershipDisable={true}
                                                onChangeAction={onChangeAction}
                                                scrollNotRequired={true}
                                                skipFullOwnershipPercent={values?.category?.code === 'LTD_COMPANY'}
                                            ></ApplicantDetails>
                                        </Card>
                                    )}
                                    {activeIndex === 2 && (
                                        <PlotDetails
                                            dropdownData={dropdownDataPlot}
                                            setDropdownData={setDropdownDataPlot}
                                            isFieldDisable={formType === 'IDC' || !hasValidAction}
                                            auction={auction}
                                            ipb={ipb}
                                        ></PlotDetails>
                                    )}
                                    {activeIndex === 3 && (
                                        <Card className='card_border'>
                                            <EnterpriseDetails
                                                enterpriseDetails={ENTERPRISE_DETAILS}
                                                enterpriseProjectDetails={PROJECT_DETAILS}
                                                declarationDetails={DECLARATION_DETAILS}
                                                dropdownOptions={dropdownData}
                                                handleOnAssignmentChange={handleOnAssignmentChange}
                                                nicDetails={nicDetails}
                                                setNicDetails={setNicDetails}
                                                showPopUp={showPopUp}
                                                setShowPopUp={setShowPopUp}
                                                isDisabled={!hasValidAction}
                                            ></EnterpriseDetails>
                                        </Card>
                                    )}
                                    {activeIndex === 4 && renderDeclarationDetails()}
                                    {activeIndex === 5 &&
                                        (!paymentIsLoading ? (
                                            <PaymentDetails
                                                paymentData={paymentData}
                                                selectedGateway={selectedGateway}
                                                setSelectedGateway={setSelectedGateway}
                                                isPaid={isPaid}
                                                gatewayData={gatewayData}
                                                transactionDetails={transactionDetails}
                                            ></PaymentDetails>
                                        ) : (
                                            <Loading />
                                        ))}
                                </Form>
                                <GenericPopupModal
                                    title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                    primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                    secondaryBtnTitle={t('CLOSE', 'Close')}
                                    isSecondaryBtn={true}
                                    secondaryAction={() => setEsignModal(false)}
                                    primaryAction={signDocument}
                                    isVisible={esignModal}
                                    children={<EsignModal />}
                                />
                            </FormikProvider>
                        </div>
                    </div>
                    <ScrollToTop />
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {hasValidAction ? (
                                    <>
                                        {activeIndex === 2 && (ipb || auction) && <GenericButtons text={t('RESET', 'Reset')} handleClick={() => resetPlot()} ClassName='me-3' />}
                                        {activeIndex !== steps.length && (
                                            <GenericButtons
                                                text={t('SAVE', 'Save')}
                                                handleClick={() => {
                                                    onSubmit(true);
                                                }}
                                                ClassName='me-3'
                                            />
                                        )}
                                        <GenericButtons
                                            text={activeIndex !== steps.length ? `Save & Next` : isPaid === null ? '' : !isPaid ? (paymentTried ? `Retry Payment` : `Pay Now`) : t('SUBMIT', 'Submit')}
                                            variant='primary'
                                            handleClick={() => (activeIndex !== steps.length ? onSubmit() : lastSubmit && lastSubmit())}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {activeIndex === steps.length ? (
                                            <>
                                                <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onCancel} />
                                            </>
                                        ) : (
                                            <>
                                                <GenericButtons text={t('NEXT', 'Next')} variant='primary' handleClick={onChangeNextIndex} />
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </footer>
                </>
            )}
        </>
    );
}
