import { Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { POST, devUrl, requestInfo } from '../../../services/modulesServices/userManagement.api';

export const TemplateCard = (props: any) => {
    const [templateLinks, setTemplateLinks] = useState<any>([]);

    useEffect(() => {
        const fetchTemplateData = async () => {
            if (props?.docReferenceId) {
                setTemplateLinks([{ documentReferenceId: props?.docReferenceId, documentName: 'Latest GSR Items' }]);
            } else if (props?.category) {
                try {
                    const { data } = await POST(`/egov-common-masters/idc/document/_search?documentCategories=${props.category}`, { RequestInfo: requestInfo() });
                    setTemplateLinks(data.documentDetails || []);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setTemplateLinks([]);
                }
            }
        };
        fetchTemplateData();
    }, [props?.docReferenceId]);

    const handleOnDownloadTemplate = async (refId?: string) => {
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${refId}`;
        // window.open(downloadFileUrl);
        window.location.href = downloadFileUrl;
    };

    return (
        <>
            {templateLinks?.length > 0 && (
                <Card className='card_border font-inter'>
                    <Card.Body>
                        <Card.Title className='py-2'>Downloads</Card.Title>
                        {templateLinks.map((link: any) => (
                            <div>
                                <p
                                    onClick={() => {
                                        handleOnDownloadTemplate(link.documentReferenceId);
                                    }}
                                >
                                    <span style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>{link.documentName}</span>
                                    <span className='text-black text-decoration-none' style={{ textDecoration: 'none' }}>
                                        &nbsp; {link.documentType ? `(${link.documentType})` : ''}
                                    </span>
                                </p>
                            </div>
                        ))}
                    </Card.Body>
                </Card>
            )}
        </>
    );
};
