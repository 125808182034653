import { CONSTANT_DATA_FIELD } from '../../../../../utils/constants';

export const ADVERTISEMENT_DETAILS: any[] = [
    {
        title: 'Advertisement',
        formData: [
            {
                title: 'Date of Publication',
                field: 'publicationDate',
                isRequired: true,
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-6 me-3',
                enableFutureDate: true,
                disablePastDate: true
            },
            {
                title: 'Submission Start Date',
                field: 'submissionStartDate',
                isRequired: true,
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                showTimeSelect: true,
                enableFutureDate: true,
                disablePastDate: true
            },
            {
                title: 'Submission End Date',
                field: 'submissionEndDate',
                isRequired: true,
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                showTimeSelect: true,
                enableFutureDate: true,
                disablePastDate: true
            }
        ]
    }
];

export const ADVERTISEMENT_COLUMNS_GM = [
    {
        colName: 'Plot No.',
        fieldName: 'plotNo',
        clsName: 'col-sm-2 column-text-start ',
        isClickable: false
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateDetails.estateName',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Plot Type',
        fieldName: 'plotType',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotAreaSqm',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Action',
        fieldName: 'action',
        actions: ['delete'],
        clsName: 'col-sm-2 column-text-start'
    }
];

export const ADVERTISEMENT_COLUMNS_NOT_GM = [
    {
        colName: 'Plot No.',
        fieldName: 'plotNo',
        clsName: 'col-sm-2 column-text-start ',
        isClickable: false
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateDetails.estateName',
        clsName: 'col-sm-3 column-text-start'
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col-sm-3 column-text-start'
    },
    {
        colName: 'Plot Type',
        fieldName: 'plotType',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotAreaSqm',
        clsName: 'col-sm-2 column-text-start'
    }
];

export const ADVERTISEMENT_COLUMNS1 = [
    {
        colName: 'Plot No.',
        fieldName: 'plotNo',
        clsName: 'col-sm-2 column-text-start',
        isClickable: false
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateDetails.estateName',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Plot Type',
        fieldName: 'plotType',
        clsName: 'col-sm-3 column-text-start'
    },
    {
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotAreaSqm',
        clsName: 'col-sm-2 column-text-start'
    }
];
