import { Card } from 'react-bootstrap';
import GenericBackButton from '../UI components/Buttons/GenericBackButton';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface IGenericHeaderProps {
    subText?: string;
    bottomSubText?: any;
    text: any;
    registrationNo?: any;
    otherDetails?: any;
    actionButton?: any;
    additionalClass?: string;
    sideHeader?: boolean;
    sideSubText?: string;
    onActionClick?: () => void;
}

const GenericHeader = (props: IGenericHeaderProps) => {
    const navigate = useNavigate();
    const { subText, bottomSubText, text, registrationNo, otherDetails, actionButton, additionalClass, sideHeader, sideSubText, } = props;

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const checkActionClick = () => {
    if(!!props?.onActionClick) {
        return props?.onActionClick(); 
    }
    return navigate(-1);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            {!sideHeader ? (
                <div className='col-sm-12'>
                    <Card className='card_border'>
                        <Card.Body>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <GenericBackButton onActionClick={checkActionClick}/>
                                    <div className='ps-2'>
                                        {subText ? (
                                            <Card.Subtitle className={` text-nav font-inter ${additionalClass ? additionalClass : 'text-muted'}`} style={{ marginTop: '4px' }}>
                                                {subText}
                                            </Card.Subtitle>
                                        ) : (
                                            ''
                                        )}
                                        <Card.Text className='font-inter-med fs-4 m-0'>
                                            {text}
                                            {registrationNo ? <span> - {registrationNo} </span> : ''}
                                            <span className='font-inter-med font-green font-16 fw-500 '>
                                                {sideSubText ? (
                                                    <>
                                                        {' '}
                                                        <span className='font-black'> &#40;</span>
                                                        {sideSubText}
                                                        <span className='font-black'>&#41;</span>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </Card.Text>

                                        {bottomSubText ? (
                                            <Card.Subtitle className={`text-nav font-inter p-0 m-0  ${additionalClass ? additionalClass : 'text-muted'}`}>{bottomSubText}</Card.Subtitle>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                                <div>{otherDetails}</div>
                            </div>
                            {actionButton}
                        </Card.Body>
                    </Card>
                </div>
            ) : (
                <Card className={`card_border hiddenNav ${isVisible ? '' : 'scrolledNav'}`}>
                    <Card.Body>
                        <div className='d-flex align-items-center'>
                            <GenericBackButton />
                            <div className='ps-1'>
                                <Card.Text className={`font-inter-med fs-5`}>
                                    {text} <p className={`text-nav font-inter p-0 m-0 ${additionalClass ? additionalClass : 'text-muted'}`}>{bottomSubText}</p>
                                </Card.Text>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

export default GenericHeader;
