import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import _ from 'lodash';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
export const TRANSFER_FORM_STEPS = [
    // {
    //     titleCode: 'TRANSFEROR_DETAILS',
    //     name: 'Transferor Details',
    //     index: 1,
    //     isActive: true
    // },
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'TRANSFER_DETAILS',
        name: 'Transfer Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'PAYMENT',
        name: 'Payment',
        index: 3,
        isActive: false
    },

    {
        titleCode: 'ASSIGN TRANSFERRE',
        name: 'Assign Transferee',
        index: 4,
        isActive: false
    }
];

export const LESSEE_DETAILS_SEARCH: FormDataDTO[] = [
    {
        title: 'Name',
        formData: [
            {
                title: 'Name',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Email ID',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Postal Address',
        title: 'Postal Address',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                field: 'pinCode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State/UT',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const TRANSFER_DETAILS_SEARCH: any[] = [
    {
        titleCode: 'TRANSFER_DETAILS',
        title: 'Transfer Details',
        formData: [
            {
                title: 'Remaining duration of Lease Term',
                titleCode: 'REMAINING_DURATION',
                field: 'termReamainingDuration',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Transferor is making a Transfer of Special Project allotted through Goa-IPB',
                titleCode: 'TRANSFER_SPECIAL_PROJECT',
                field: 'isSpecialProject',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isDisabled: true
            },
            {
                title: 'Lease Deed/Tripartite Deed/Deed of Extension',
                titleCode: 'DEED_DOCUMENT',
                field: 'leaseDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            },
            {
                title: 'NOC from Goa-IPB',
                titleCode: 'NOC_FROM_GOA_IPB',
                field: 'nocFromGoaIpb',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                // hideTitle: true,
                renderCondition: (value: any) => _.get(value, `isSpecialProject`) === true,
                entityFileData: {
                    documentNamePrefix: 'name',
                    documentType: DOCUMENT_TYPE.NOC_SPECIAL_PROJECT,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                isRequired: true
            },
            {
                title: 'Occupancy Certificate',
                titleCode: 'OCCUPANCY_CERTIFICATE',
                field: 'occupancyCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const transferDeclarationList = [
    {
        code: 'TRANSFEROR_DECLARATION_I',
        label: '(i) I have utilized the Built-Up area such that it covers at least 30% of the Plot Area, as required by applicable regulations.',
        disabled: true
    },
    {
        code: 'TRANSFEROR_DECLARATION_II',
        label: '(ii) I have paid the entire amount of Land Premium associated with the transfer, as required by the authorities.',
        disabled: true
    },
    {
        code: 'TRANSFEROR_DECLARATION_III',
        label: '(iii) The transfer is not being made to a Disqualified Entity, as defined by the relevant legal provisions.',
        disabled: false
    },
    {
        code: 'TRANSFEROR_DECLARATION_IV',
        label: '(iv) The transfer does not involve the movement of assets from an Industrial Undertaking to a Commercial Undertaking, ensuring compliance with statutory requirements.',
        disabled: false
    },
    {
        code: 'TRANSFEROR_DECLARATION_V',
        label: '(v) I am not expressly prohibited or barred by any court, tribunal, or financial authority from proceeding with this transfer.',
        disabled: false
    }
];

export const ASSIGN_TRANSFEREE_SEARCH: FormDataDTO[] = [
    {
        titleCode: 'Search',
        title: 'Search',
        formData: [
            {
                title: 'Name',
                field: 'assignTransfereeName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false
            },
            {
                title: 'Mobile No',
                field: 'assignTransfereeMobileNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD
            },
            {
                title: 'Email ID',
                field: 'assignTransfereeEmail',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false
            }
        ]
    }
];

export const PAYMENT_DETAILS: any[] = [
    {
        titleCode: 'PAYMENT_DETAILS',
        title: 'Payment Details',
        formData: [
            {
                title: 'Payment Gateway',
                field: 'paymentGateway',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            }
        ]
    }
];

export const transferInitialValues = {
    isBuiltUpAreaUtilised: false,
    isIndustrialCommercial: false,
    isSpecialTransfer: false,
    isTransferProhibited: false,
    isTransfereeDisqualified: false,
    transferOrLandPremium: false
};

export const PLOT_DETAILS: any[] = [
    {
        titleCode: 'PLOT_DETAILS',
        title: 'Plot Details',
        formData: [
            {
                title: 'Name of Industrial Estate',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            },
            {
                title: 'Plot No',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            }
        ]
    }
];

export const TRANSFER_CORRESPONDENCE_CARD = [
    {
        title: 'A1 - Transferee Details',
        field: 'transfereeDetails'
    },
    {
        title: 'A2 - Transferor Details',
        field: 'transferorDetails'
    },
    {
        title: 'A3 - Transfer Details',
        field: 'transferDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Enterprise Details',
        field: 'enterpriseDetails'
    },
    {
        title: 'D - Transferee Self Declaration',
        field: 'selfDeclaration'
    },
    {
        title: 'E - Payment Details',
        field: 'paymentDetails'
    },
    {
        title: 'F - Revised Lease Rent',
        field: 'revisedLeaseRentDetails'
    },
    {
        title: 'G - Transfer Order Notes',
        field: 'transferOrderNotesDetails'
    }
];

export const REVISED_LEASE_RENT = [
    {
        formData: [
            {
                title: 'Plot Rate (INR/Sq.m.)',
                titleCode: 'PLOT_RATE',
                field: 'plotRate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                showINR: true
            },
            {
                title: 'Plot Area (Sq.m.)',
                titleCode: 'PLOT_AREA',
                field: 'plotArea',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Revised Lease Rent (in INR)',
                titleCode: 'REVISED_LEASE_RENT',
                field: 'revisedLeaseRent',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                showINR: true
            }
        ]
    }
];

export const assignTransferreeColumns = [
    // {
    //     colName: '',
    //     fieldName: 'radio',
    //     clsName: 'col-sm-1',
    //     onRadioClick: onTransfereeSelect,
    //     selectedValue: selectedOneTransferee
    // },
    {
        colName: 'Name',
        fieldName: 'name',
        clsName: 'col-sm-3'
    },
    {
        colName: 'Email ID',
        fieldName: 'email',
        clsName: 'col-sm-4'
    },
    {
        colName: 'Mobile Number',
        fieldName: `phoneNumber`,
        clsName: 'col-sm-3'
    }
];

export const SPECIAL_TRANSFER_CORRESPONDENCE_CARD = [
    {
        title: 'A1 - Transferee Details',
        field: 'transfereeDetails'
    },
    {
        title: 'A2 - Transferor Details',
        field: 'transferorDetails'
    },
    {
        title: 'A3 - Special Transfer Details',
        field: 'transferDetails'
    },
    {
        title: 'B - Self Declaration',
        field: 'selfDeclaration'
    },
    {
        title: 'C - Payment Details',
        field: 'paymentDetails'
    }
];

export const SPECIAL_TRANSFER_CORRESPONDENCE_CARD_FILTERED = [
    {
        title: 'A1 - Transferor Details',
        field: 'transferorDetails'
    },
    {
        title: 'A2 - Special Transfer Details',
        field: 'transferDetails'
    },
    {
        title: 'B - Self Declaration',
        field: 'selfDeclaration'
    },
    {
        title: 'C - Payment Details',
        field: 'paymentDetails'
    }
];

export const ESIGN_COLUMNS = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Order No.',
        fieldName: 'order',
        clsName: 'col-sm-7 column-text-start',
        id: 2
    },
    {
        colName: 'Verification',
        fieldName: 'verification',
        clsName: 'col-sm-2',
        id: 3
    },
    {
        colName: 'Actions',
        fieldName: 'action',
        clsName: 'col-sm-1',
        actions: 'other',
        actionText: 'Verify'
    }
];
