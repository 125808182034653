import { BPArequestInfo, requestInfo } from '../../services/modulesServices/userManagement.api';

export const OrderCompletionFormJson = (values: any, extValue: any, isUpdate: boolean) => {
    var userinfo = window.localStorage.getItem('user');
    console.log(isUpdate);
    if (!isUpdate) {
        return {
            RequestInfo: BPArequestInfo(),
            BPA: {
                id: values?.id ? values?.id : null,
                tenantId: 'ga',
                applicationNo: values?.id ? values?.appId : null,
                approvalNo: null,
                edcrNumber: null,
                riskType: 'High',
                businessService: 'BPA1',
                applicationType: 'NEW',
                serviceType: values?.serviceType,
                approvalDate: null,
                validUpTo: null,
                applicationDate: null,
                status: null,
                accountId: null,
                lesseeId: null,
                archRegId: values?.archId,
                archName: values?.archName,
                noteId: null,
                notes: null,
                lesseeDetails: {
                    id: null,
                    name: values?.name,
                    gender: values?.gender,
                    phoneNo: values?.phone,
                    pan: values?.panNo,
                    address: {
                        id: null,
                        tenantId: 'ga.goa',
                        pincode: values?.pincode,
                        district: values?.district,
                        state: values?.state,
                        country: values?.country,
                        addressLine1: values?.addressLine1,
                        addressLine2: values?.addressLine2
                    }
                },
                documents: values.documents,
                workflow: null
            }
        };
    } else {
        const merged = Object.assign({}, extValue);

        for (const key of Object.keys(values)) {
            if (key in merged) {
                merged[key] = values[key];
            }
        }
        merged.archRegId = values?.archRegId || extValue?.archRegId;
        const modifiedPayload = {
            RequestInfo: BPArequestInfo(),
            BPA: merged
        };
        return modifiedPayload;
    }
};

export const createInspectionFormJson = (values: any, extValue: any, isUpdate: boolean) => {
    if (!isUpdate) {
        return {
            RequestInfo: BPArequestInfo(),
            FieldInspection: {
                id: null,
                applicationNo: values?.appId,
                scheduledDate: new Date(values?.inspDate).getTime(),
                scheduledTime: `${values?.inspTime}:00`,
                requestForReschedule: values?.rescheduleRequest,
                lesseeAcepted: values?.isLesseeAccept,
                employeeAccepted: values?.isEmployAccept,
                requestForRescheduleData: values?.isRequestForSchedule
                    ? {
                          scheduledDate: new Date(values?.toDate).getTime(),
                          scheduledTime: `${values?.inspTime}:00`,
                          comment: values?.comment,
                          requestDate: new Date(values?.toDate).getTime()
                      }
                    : null,
                inspectionDate: null,
                inspectionTime: null,
                planApprovalNo: null,
                planApprovalDate: null,
                isactive: true,
                isClosed: false,
                inspectionCheckListData: null,
                documents: null
            }
        };
    } else {
        const merged = Object.assign({}, extValue);

        for (const key of Object.keys(values)) {
            if (key in merged) {
                merged[key] = values[key];
            }
        }
        merged.id = extValue?.id;
        const modifiedPayload = {
            RequestInfo: BPArequestInfo(),
            FieldInspection: merged
        };
        return modifiedPayload;
    }
};

export const getDocsData = (values: any, appId: string) => {
    const referredArray: any = [];
    if (values && !!values.length) {
        values.map((val: any, ind: number) =>
            referredArray.push({
                id: val?.id || null,
                applicationNumber: appId,
                documentype: val?.documentype,
                fileStoreId: val?.fileStoreId,
                tenantId: 'ga',
                documentName: val?.documentName,
                documentUid: val?.documentUid || '',
                additionalDetails: {}
            })
        );

        return referredArray;
    }
};

export const getFileurl = (allDocs: any, key: string) => {
    const filteredDocs = allDocs.filter((element: any) => element.documentype === key);
    const newArray: any = [];
    filteredDocs.forEach((ele: any) => {
        newArray.push({
            id: ele?.id,
            applicationNumber: ele?.applicationNumber,
            documentype: ele?.documentype,
            fileStoreId: ele?.fileStoreId,
            tenantId: 'ga',
            documentName: ele?.documentName,
            documentUid: ele?.documentUid,
            additionalDetails: {}
        });
    });
    console.log(newArray, key);
    return newArray;
};

export const PayloadJson = () => {
    var userinfo = window.localStorage.getItem('userData');
    return {
        RequestInfo: {
            apiId: '1',
            ver: '1',
            ts: null,
            action: 'create',
            did: 'jh',
            key: '',
            msgId: 'gfcfc',
            correlationId: 'wefiuweiuff897',
            authToken: window.localStorage.getItem('authToken'),
            userInfo: userinfo !== null ? JSON.parse(userinfo) : null
        }
    };
};

export const getCLAList = (data: any) => {
    let newList: any = [];
    if (!!data && !!data.length) {
        data.map((val: any) => {
            newList.push({
                name: `${val?.constructionLicenseNo}`,
                title: ` ${val?.constructionLicenseNo}`,
                value: val?.constructionLicenseNo,
                code: val?.constructionLicenseNo,
                subcategory: [],
                // Add additional fields
                dcrNumber: val?.constructionLicenseNo,
                estate: val?.estate,
                plotNumber: val?.plotNo,
                appliedCount: val?.appliedPercentage,
                sharedDate: new Date(val?.approvalDate).toISOString().split('T')[0]
            });
        });
        return newList;
    }
};

export const revertStringBasedOnMatchForOC = (inputString: any) => {
    // Define patterns and corresponding revert strings
    const patterns = [
        { pattern: 'Generate Completion Order & Forward', revertString: 'Forward' },
        { pattern: 'Approve CO', revertString: 'Approve' },
        { pattern: 'Approve OC', revertString: 'Approve' },
        { pattern: 'Upload NOC And Forward', revertString: 'Forward' },
        { pattern: 'Digital Sign (OC)', revertString: 'Digital Sign' },
        { pattern: 'Digital Sign (CO)', revertString: 'Digital Sign' }
    ];

    // Iterate over patterns to find a match
    for (let { pattern, revertString } of patterns) {
        if (pattern === inputString) {
            return revertString; // Return the revertString if pattern matches
        }
    }

    return inputString; // Return the original inputString if no match found
};
