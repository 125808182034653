import React, { useEffect, useState } from 'react';
import ScrollToTop from '../../../../components/ScrollToTop';
import { FormikProvider, useFormik } from 'formik';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../Allotment/Allotment.const';
import { useTranslation } from 'react-i18next';
import { Card, Form } from 'react-bootstrap';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { getHistory, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../EstateManagement.controller';
import { dropdownDataOptions } from '../../Migration/Migration.controller';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PLOT_DETAILS, getPlotDetailsPayload, plotInitialValues, setPlotDetails } from './Plotmaster.const';
import PlotMasterCorrespondence from './Plot master Workflow/PlotMasterCorrespondence';
import { estateDropdown } from '../Estate_Master/master.const';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import { check, cross } from '../../../../utils/Logo';
import { POST, endpoints, estateEndpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { TENANT_ID } from '../../../../utils/constants';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';

const PlotUpdate = ({ data }: any) => {
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const applicationNumber = searchParams.get('applicationNumber') || '';
    const estateUuid = searchParams.get('estateUuid') || '';
    const estateName = searchParams.get('estateName') || '';

    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const userObject = localStorage.getItem('user');
    const userType = userObject ? String(JSON.parse(userObject)?.type) : '';

    const location = useLocation();

    // const data = location?.state?.data || null;
    const disable = location?.state?.disable || null;
    const sendBack = location?.state?.sendBack || null;

    console.log('data', data);

    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [searchResponse, setSearchResponse] = useState<any>(null);
    const [tabelData, setTabelData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [applicationNo, setApplicationNo] = useState<any>('');

    const totalPages = applicationNumber ? 2 : 1;

    const NEW_PLOT_DETAILS = PLOT_DETAILS.map((detail: any) => ({
        ...detail,
        formData: detail.formData.map((item: any, ind: number) => {
            if (item.field === 'plotAreaSqm' && data.length > 1) {
                return { ...item, class: 'col-sm-3', isDisabled: true };
            } else return { ...item, class: 'col-sm-3' };
        })
    }));

    const { t } = useTranslation();

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            emails: [{ emailId: '' }],
            plotDetails: [{ ...plotInitialValues }]
        },
        onSubmit: () => {},
        validationSchema: null
    });
    const { values, setValues, errors, setFieldValue } = formik;

    useEffect(() => {
        console.log('values', values);
        const totalPlotArea = data.reduce((acc: any, curr: any) => acc + +curr.plotAreaSqm, 0);
        let tempPlotDetails: any;
        values.plotDetails?.map(
            (val: any, index: number) =>
                (tempPlotDetails = [
                    ...values.plotDetails.slice(0, index),
                    {
                        ...val,
                        taluka: { code: data[0].plotDetails.villageDetails[0].talukaCode, name: data[0].plotDetails.villageDetails[0].talukaName },
                        estateName: { code: estateName || values.plotDetails[0]?.estateDetails?.estateName, name: estateName || values.plotDetails[0]?.estateDetails?.estateName },
                        plotAreaSqm: data.length > 1 ? totalPlotArea : ''
                    }
                ])
        );
        setValues((prev: any) => ({ ...prev, plotDetails: tempPlotDetails }));
    }, [values.plotDetails?.length, applicationNumber]);

    useEffect(() => {
        applicationNumber && setFieldValue('isUpdatable', nextValidAction?.isUpdatable);
    }, [nextValidAction]);

    useEffect(() => {
        (async () => {
            try {
                const dropdownOption = await estateDropdown(userType);
                setDropdownData(dropdownOption || []);

                const nextActions = await getNextActions(applicationNumber);
                setNextValidAction(nextActions);

                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
            } catch (e) {
                console.log(e);
            }
        })();
    }, [applicationNumber]);

    useEffect(() => {
        (async () => {
            if (dropdownData && applicationNumber) {
                try {
                    setIsLoading(true);
                    const { data }: any = await POST(`/idc-estate-service/master-application/_search?applicationNo=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setSearchResponse(data[0]);

                    if (!data[0]) return;
                    const formData = await Promise.all(data[0].newPlotDetails.map(async (item: any) => await setPlotDetails(item, dropdownData, data.length === 1 ? 'SPLIT' : 'MERGE')));
                    const updatedValues = { plotDetails: formData };
                    setValues((prev: any) => ({ ...prev, ...updatedValues }));
                    setIsLoading(false);
                } catch (e) {
                    console.log(e);
                }
            }
        })();
    }, [dropdownData, applicationNumber]);

    useEffect(() => {
        if (activeIndex === 2 && documentDetails) {
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails.length - ind}` };
                });
                setTabelData(responseTableData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs.documents);
            setDocumentsUuid([...documentsUuid, docs.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    const handleNextPage = async () => {
        const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction.businessService);
        setDocumentDetails(docs);
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };
    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        console.log('payload', searchResponse, { ...searchResponse, newPlotDetails: values.plotDetails.map((item: any) => getPlotDetailsPayload(item, estateUuid, dropdownData)) });
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.filter(Boolean),
            masterApplicationRequest: { ...searchResponse }
            // masterApplicationRequest: { ...searchResponse, newPlotDetails: values.plotDetails.map((item: any) => getPlotDetailsPayload(item, estateUuid, dropdownData)) }
        };

        // if (!comment && !status.isEmployeeAssignee) {
        //     setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        //     return;
        // }

        if (!values.notes) {
            if (!comment) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ENTER_NOTES', 'Please enter notes!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }

        if (searchResponse.existingPlotDetails.length === 1 && values.plotDetails.length === 1) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ADD_ATLEAST_2_PLOTS', 'Please add atleast 2 plots'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, applicationNumber);
            // designation !== 'DGM' ? `Master Application Created Successfully Application Number : ${applicationNumber}` : `Plot Created Successfully`;
            setModalDetails({ show: true, title: 'Success!', para: successMessage, img: check, type: 'success', reUpload: 'OK', finalSubmit: true });
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log(formik);

    const onSubmit = async () => {
        if (data.length === 1 && values.plotDetails.length === 1) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ADD_ATLEAST_2_PLOTS', 'Please add atleast 2 plots'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        const payload = {
            RequestInfo: requestInfo(),
            refType: 'PLOT',
            type: data.length === 1 ? 'SPLIT' : 'MERGE',
            existingEstateDetails: null,
            newEstateDetails: null,
            existingPlotDetails: data.map((item: any) => getPlotDetailsPayload(item, estateUuid, dropdownData)),
            newPlotDetails: values.plotDetails.map((item: any) => getPlotDetailsPayload(item, estateUuid, dropdownData)),
            status: null
        };

        try {
            // await PLOT_REGISTRY_VALIDATION.validate(values, { abortEarly: false, context: values });
            try {
                const { data } = await POST(endpoints.estateCreation, payload);
                setApplicationNo(data.applicationNo);
                const successMessage = `Master Application Initiated${data.applicationNo ? ' Number ' + data.applicationNo : ''} Successfully`;
                setModalDetails({ show: true, title: 'Success!', para: successMessage, img: check, type: 'success', reUpload: 'OK', finalSubmit: true });
            } catch (err: any) {
                console.log(err);
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (err: any) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string, finalSubmit?: true) => {
        setModalDetails({});
        if (type === 'success' && finalSubmit) {
            !applicationNumber ? navigate(`/goa-idc/estate/plot-registry-workflow?plotUpdate=true&applicationNumber=${applicationNo}`) : navigate('/goa-idc/dashboard');
        }
    };

    const onActionClick = () => {
        if (sendBack) {
            navigate('/goa-idc/estate/plot-registry', { state: { estateNameReturn: values?.plotDetails[0]?.estateName?.code } });
        } else {
            navigate(-1);
        }
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const onChangeAction = async (e: any, field: string) => {
        let currentField = field.split('.').slice(-1)[0];
        let index = +field.split('.')[1];
        console.log('ccccccccc', currentField, index, values.plotDetails[index][`${currentField}`]);
        if (currentField === 'taluka') {
            const { data } = await POST(`/idc-estate-service/estate-master-details/taluka/_search?talukaCodes=${e.value}&talukaNames=${e.label}`, {
                RequestInfo: requestInfo()
            });

            const options =
                data.talukaMasterDetails.length && data.talukaMasterDetails[0].villageMasterDetails.map((item: any) => ({ code: item.villageCode, name: item.villageName, uuid: item.uuid }));
            setDropdownData({ ...dropdownData, village: options });
            console.log({ ...dropdownData, village: options });
            // setFieldValue('village', []);
        }
    };

    console.log(formik);
    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={t('PLOT_MASTER_REGISTRY', 'Plot Master Registry')}
                            additionalClass='sub-text-style'
                            bottomSubText={applicationNumber ? `Application No : ${applicationNumber}` : ''}
                            onActionClick={onActionClick}
                        />

                        {applicationNumber ? (
                            <FormikProvider value={formik}>
                                <div className='col-sm-3 mt-3 stickyCard-section '>
                                    <div className='stickyCard' style={{ top: '15%' }}>
                                        <GenericHeader
                                            text={t('PLOT_MASTER_REGISTRY', 'Plot Master Registry')}
                                            sideHeader={true}
                                            additionalClass='sub-text-style'
                                            bottomSubText={`Application No : ${applicationNumber}`}
                                        />
                                        <NotingWrapper
                                            applicationNumber={applicationNumber}
                                            history={historyDetails}
                                            handleOnFileUpload={handleOnFileUpload}
                                            // designation={designation}
                                            // disable={!nextValidAction?.nextValidAction.length}
                                            nextValidAction={nextValidAction}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-9 mt-3 form-section '>
                                    <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />

                                    <Card className='card_border p-1'>
                                        <Form>
                                            {activeIndex === 1 && (
                                                <PlotMasterCorrespondence
                                                    PLOT_DETAILS={PLOT_DETAILS}
                                                    plotDetails={values.plotDetails}
                                                    dropdownData={dropdownData}
                                                    existingPlotDetails={searchResponse?.existingPlotDetails}
                                                    onChangeAction={onChangeAction}
                                                />
                                            )}
                                            {activeIndex === 2 && (
                                                <div className='p-4'>
                                                    <GenericActionTable hideSearchBar={true} columns={CORRESPONDENCE_TABLE} rowData={tabelData} additionalMethod={onRowClick} />
                                                </div>
                                            )}
                                        </Form>
                                    </Card>
                                </div>
                                <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                    <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                        <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                    </span>
                                    Page {activeIndex} of {totalPages}
                                    <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                        <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                    </span>
                                </div>
                            </FormikProvider>
                        ) : (
                            <FormikProvider value={formik}>
                                <div className='col-sm-12 mt-3 form-section '>
                                    <Card className='card_border p-1'>
                                        <Form>
                                            {activeIndex === 1 && (
                                                <PlotMasterCorrespondence
                                                    PLOT_DETAILS={NEW_PLOT_DETAILS}
                                                    plotDetails={values.plotDetails}
                                                    dropdownData={dropdownData}
                                                    existingPlotDetails={searchResponse?.existingPlotDetails}
                                                    onChangeAction={onChangeAction}
                                                />
                                            )}
                                        </Form>
                                    </Card>
                                </div>
                            </FormikProvider>
                        )}
                    </div>
                    <ScrollToTop />
                    {applicationNumber ? (
                        <GenericFooterComponent
                            onCancel={() => {}}
                            onClickPrev={onChangePrevIndex}
                            activeIndex={activeIndex}
                            handleOnPrimaryAction={onHandlePrimaryAction}
                            onSubmit={() => {}}
                            applicationNo={applicationNumber || null}
                            nextValidAction={nextValidAction}
                            stepperLength={0}
                        />
                    ) : (
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    <GenericButtons text={t('SUBMIT', 'Submit')} variant='primary' handleClick={onSubmit} />
                                </div>
                            </div>
                        </footer>
                    )}

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? t('PDF_VIEWER', 'PDF Viewer') : t('IMAGE_VIEWER', 'Image Viewer')}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
                    />
                </>
            )}
        </>
    );
};

export default PlotUpdate;
