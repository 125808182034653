import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { Card } from 'react-bootstrap';
import { CONTRACT_CLOSURE_SEARCH, CONTRACT_CLOSURE_SEARCH_TABLE } from './ContractClosure.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { FormikProvider, useFormik } from 'formik';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { useNavigate } from 'react-router-dom';
import { endpoints, estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { check, cross } from '../../../../utils/Logo';

const ContarctClosure = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [selectedId, setSelectedId] = useState<any>(null);
    const [selectedObject, setSelectedObject] = useState<any>(null);
    const [rowData, setRowData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});

    let navigate = useNavigate();

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {}
    });

    const { values, setValues } = formik;

    useEffect(() => {
        (async () => {
            try {
                const { data } = await POST(estateEndpoints.estateSearch, { RequestInfo: requestInfo() });
                const estateValues = data?.estateMasterDetails?.map((val: any) => {
                    return { name: val?.estateName, code: val?.uuid };
                });

                setDropdownData({ ...dropdownData, industrialEstate: estateValues });
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    const onContractSelect = (selectedItem: any) => {
        console.log('selectedItem', selectedItem);
        setSelectedId(selectedItem.id);
        setSelectedObject(selectedItem);
    };

    const contractClosureSearchTable = CONTRACT_CLOSURE_SEARCH_TABLE.map((val: any, index: number) => {
        if (index === 0) {
            return { ...val, onRadioClick: onContractSelect, selectedValue: selectedId };
        }
        return val;
    });

    const onSearchContract = async () => {
        try {
            const queryParams = new URLSearchParams();
            queryParams.append('status', 'APPROVED');
            if (values?.contract) queryParams.append('applicationNos', values?.contract);
            if (values?.industrialEstate) queryParams.append('estateId', values?.industrialEstate?.code);
            const searchContractResponse = await POST(endpoints.searchContract + `?${queryParams.toString()}`, { RequestInfo: requestInfo() });
            console.log('searchContractResponse', searchContractResponse);

            const templTableData = searchContractResponse?.data?.contractModelList?.map((val: any, index: any) => {
                return {
                    id: index + 1,
                    contractNo: val?.applicationNo,
                    vendorName: val?.workOrderDetails?.loaDetails?.bidderDetails?.name,
                    projectName: val?.workOrderDetails?.loaDetails?.worksTenderDetails?.dprHeaderDetails?.projectName,
                    industrialName: val?.workOrderDetails?.loaDetails?.worksTenderDetails?.dprHeaderDetails?.estateMasterDetails?.estateName,
                    projectType: val?.workOrderDetails?.loaDetails?.worksTenderDetails?.dprHeaderDetails?.projectType,
                    estimateAmount: val?.workOrderDetails?.loaDetails?.worksTenderDetails?.dprHeaderDetails?.estimatedTotalCost,
                    applicationNo: val?.applicationNo
                };
            });

            setRowData(templTableData);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onInitiateClosure = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            contractClosureDetails: {
                contractApplicationNo: selectedObject?.applicationNo
            }
        };

        try {
            const createContractClosureResponse = await POST(endpoints.createContractClosure, payload);
            setModalDetails({
                show: true,
                title: 'Error!',
                para: t('CONTRACT_CLOSURE_INITIATED', 'Contract Closure Initiated Successfully'),
                img: check,
                type: 'success',
                reUpload: 'OK',
                applicationNo: createContractClosureResponse?.data?.contractClosureDetails?.[0]?.applicationNo
            });
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string, applicationNo: string) => {
        setModalDetails({});
        if (type === 'success') {
            navigate(`/goa-idc/contract-closure/workflow?applicationNumber=${applicationNo}`);
        }
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <FormikProvider value={formik}>
                            <GenericHeader text={t('CONTRACT_CLOSURE', `Contract Closure`)} />
                            <div className='col-12 form-section'>
                                <Card className='card_border'>
                                    <Card.Body>
                                        {CONTRACT_CLOSURE_SEARCH.map((val: any) => (
                                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                        ))}
                                        <div className='d-flex justify-content-end mb-5 pe-2'>
                                            <GenericButtons
                                                text={t('RESET', 'Reset')}
                                                variant='secondary'
                                                handleClick={() => {
                                                    setValues({ ...values, contract: '', financialYear: '', industrialEstate: '' });
                                                }}
                                            />
                                            <GenericButtons text={t('SEARCH', 'Search')} variant='primary' disabled={false} handleClick={onSearchContract} />
                                        </div>
                                    </Card.Body>
                                </Card>
                                {rowData?.length ? (
                                    <Card className='card_border'>
                                        <Card.Body>
                                            <GenericActionTable columns={contractClosureSearchTable} hideSearchBar={true} hideExportBtn={true} rowData={rowData} />
                                        </Card.Body>
                                    </Card>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </FormikProvider>
                    </div>
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                <GenericButtons text={t('INITIATE_CLOSURE', 'Initiate Closure')} variant='primary' handleClick={() => onInitiateClosure()} />
                            </div>
                        </div>
                    </footer>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.applicationNo)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.applicationNo)}
                    />
                    ;
                </>
            )}
        </>
    );
};

export default ContarctClosure;
