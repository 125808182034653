import React from 'react';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { OCCASION } from '../../utils/formikValidation/FormikValidation';

interface ICreateHolidayProps {
    setOccasion: (e: any) => void;
    // receivedChildData(comments: any): unknown;
    // name?: string;
}
export const CreateHolidayPopup = (props: ICreateHolidayProps) => {
    const { setOccasion } = props;
    // const { t } = useTranslation();

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},

        validationSchema: OCCASION
    });
    const { handleChange } = formik;

    const handleOnChange = (e: any) => {
        setOccasion(e.target.value);
        handleChange(e);
    };
    // console.log(formik);

    return (
        <>
            <div className='container' style={{ background: '#F5F5F6' }}>
                <div className='row px-2 py-2 justify-content-center  '>
                    <FormikProvider value={formik}>
                        <Form>
                            <label htmlFor='occassion' className='pb-2 ps-1'>
                                Occassion <span className='mandatory-indicator'>*</span>
                            </label>

                            <Field name='occassion' className='form-control mb-2' onChange={handleOnChange} />
                            <ErrorMessage name='comments' component='div' className='text-danger font-12' />
                        </Form>
                    </FormikProvider>
                </div>
            </div>
        </>
    );
};
