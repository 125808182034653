import React from 'react';
import { about_us } from '../../utils/Images';
import HeaderComponent from '../../components/header/Header.component';
import FooterComponent from '../../components/portalComponents/Footer.component';
import { Link } from 'react-router-dom';
import { POST, endpoints, getFileUrl } from '../../services/modulesServices/userManagement.api';
import ScrollButton from '../../components/ScrollToTop';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function AboutUs() {
    const { t, i18n } = useTranslation();

    const onClickDownloadItem = async () => {
        try {
            const { data } = await POST(endpoints.getCommonDocument + `?documentCategories=IDC_PORTAL&documentNames=Goa_IDC_Regulations_2023`, { RequestInfo: {} });
            const fileUrl = await getFileUrl(data.documentDetails[0].documentReferenceId);
            window.open(fileUrl, '_blank');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <HeaderComponent />

            <div className='mx-5 p-5 font-inter'>
                <div className='my-2'>
                    <p className='breadcrumbs'>
                        <Link to='/goa-idc/'>{t('HOME', 'Home')}</Link> / {t('ABOUT_US', 'About Us')}
                    </p>
                </div>
                <div className='col-md-12' style={{ fontWeight: 500, fontSize: '48px', lineHeight: '76.8px', letterSpacing: -1 }}>
                    {t('ABOUT_GOA_INDUSTRIAL_CORPORATION', 'About Goa Industrial Development Corporation')}
                </div>
                <div className='my-4'>
                    <img src={about_us} className='img-fluid my-4' style={{ width: '100vw', height: 367 }}></img>
                </div>
                <div className='my-4' style={{ fontWeight: 400, fontSize: 18 }}>
                    <div className='my-2'>
                        <p>
                            {t(
                                'ABOUT_GOA_IDC_PARA1',
                                'The Goa Industrial Development Corporation (hereinafter referred to as the “Corporation”) is a statutory body established by the Government of Goa under the Goa Industrial Development Act, 1965 (hereinafter referred to as the “Act”). Its purpose is to secure and assist in the rapid and orderly establishment and organization of industries in the State of Goa.'
                            )}
                        </p>
                    </div>
                    <div className='my-2'>
                        <p style={{ fontWeight: 700 }}>{t('ABOUT_GOA_IDC_PARA2', 'The primary functions of the Corporation include:')}</p>
                        <p>
                            {t('ABOUT_GOA_IDC_PARA3', '(a) Establishing and managing industrial estates at locations designated by the State Government. ')}
                            <br />
                            {t(
                                'ABOUT_GOA_IDC_PARA4',
                                '(b) Developing industrial areas designated by the State Government and making them available to industrial enterprises on a long-term lease basis. '
                            )}
                            <br />
                            {t(
                                'ABOUT_GOA_IDC_PARA5',
                                "(c) Undertaking schemes or works, either jointly or as an agency with other corporate bodies, institutions, or the Government, in furtherance of the Corporation's objectives and all related matters."
                            )}
                        </p>
                    </div>
                    <div className='my-2'>
                        <p>{t('ABOUT_GOA_IDC_PARA6', 'Currently, the Corporation manages 24 industrial estates in Goa, covering an area of 1,80,09,312 m².')}</p>
                    </div>
                    <div className='my-2'>
                        <p>
                            {t(
                                'ABOUT_GOA_IDC_PARA7',
                                'Under Section 37A of the Act, the State Government has declared all 24 industrial estates as notified areas, where the provisions of laws relating to local authorities regarding building control, tax levies, and collections do not apply. The Corporation is tasked with performing the functions of a local authority within these areas.'
                            )}
                        </p>
                        <p>
                            {t(
                                'ABOUT_GOA_IDC_PARA8',
                                'Furthermore, under Section 37B of the Act, all planning and development functions within the industrial estates are executed by the Corporation, making it the single point of authority for industries to obtain leasehold rights over plots and various permissions for construction, occupancy, and amenities like water supply and roads.'
                            )}
                        </p>
                        <p>
                            {t(
                                'ABOUT_GOA_IDC_PARA9',
                                'Section 51 of the Act empowers the Corporation to create regulations for the management of Government lands post-development. Pursuant to this, the Corporation has enacted the Goa Industrial Development Corporation (Allotment, Transfer and Sub-Lease) Regulations, 2023 (hereinafter referred to as the “Regulations”).'
                            )}
                        </p>
                    </div>
                </div>
                <div className='mt-5' style={{ fontWeight: 400, fontSize: 20 }}>
                    <p>
                        {t('FOR_MORE_INFORMATION', 'For more information : ')}
                        <span style={{ color: 'blue', cursor: 'pointer' }} onClick={onClickDownloadItem}>
                            {t('DOWNLOAD_GOA_IDC_REGULATION', 'Download Goa-IDC Regulations')}
                        </span>
                    </p>
                </div>
            </div>
            <div>
                <FooterComponent />
            </div>
            <ScrollButton type='public' />
        </>
    );
}
