// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperPage {
    height: 100vh;
    background-color: rgba(199, 208, 191, 0.08) !important;
}`, "",{"version":3,"sources":["webpack://./src/routes/RedirectPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sDAAsD;AAC1D","sourcesContent":[".wrapperPage {\n    height: 100vh;\n    background-color: rgba(199, 208, 191, 0.08) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
