import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { requestInfo } from '../../services/modulesServices/userManagement.api';
import { BASE_URL_LOCAL, EMUDRA_LOCALHOST_URL, TENANT_ID } from '../../utils/constants';
import GenericCommonSelector from '../../components/moduleCommonFormik/GenericCommonSelector.component';
import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../components/header/Header.component';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { Card } from 'react-bootstrap';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { DSC_REGISTRATION_FIELDS } from '../modules/EstateManagement/DSCRegistration/DscRegistration.const';
import { t } from 'i18next';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import Loading from '../../components/portalComponents/Loading.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import { check, cross } from '../../utils/Logo';
import { getNavigateTo } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

export const EmudraPage = () => {
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [dropdownData, setDropdownData] = useState({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const navigate = useNavigate();

    const userType = localStorage.getItem('type') ? JSON.parse(localStorage.getItem('type') as any) : '';

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {}
    });
    const { values, setValues, errors, setFieldValue, setFieldTouched, setTouched } = formik;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const tokenInput = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_getTokenInput', { RequestInfo: requestInfo(), tenantId: TENANT_ID, responseData: '' });
                console.log(tokenInput);
                const listToken = await axios.post(EMUDRA_LOCALHOST_URL + '/DSC/ListToken', {
                    encryptionKeyId: tokenInput.data.input.encryptionKeyId,
                    encryptedRequest: tokenInput.data.input.encryptedRequest
                });
                const getTokens = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_getTokens', {
                    RequestInfo: requestInfo(),
                    tenantId: TENANT_ID,
                    responseData: listToken.data.responseData
                });
                const tokenDropdown = getTokens.data.tokens.map((item: any) => ({ code: item, name: item }));
                setDropdownData({ ...dropdownData, token: tokenDropdown });
                setIsLoading(false);
            } catch (err: any) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const onChange = async (e: any, field: string) => {
        try {
            if (field === 'token') {
                setIsLoading(true);
                const getInputCertificate = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_getInputCertificate', {
                    RequestInfo: requestInfo(),
                    tokenDisplayName: values.token?.code,
                    responseData: ''
                });
                const listCertificate = await axios.post(EMUDRA_LOCALHOST_URL + '/DSC/ListCertificate', {
                    encryptionKeyId: getInputCertificate.data.input.encryptionKeyId,
                    encryptedRequest: getInputCertificate.data.input.encryptedRequest
                });
                const getCerticate = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_getCertificate', {
                    RequestInfo: requestInfo(),
                    responseData: listCertificate.data.responseData,
                    tokenDisplayName: values.token?.code
                });
                const certifcateDropdown = getCerticate.data.certificates.map((item: any) => ({ code: item.keyId, name: item.commonName, certificateDate: item.certificateDate }));
                setDropdownData({ ...dropdownData, certificate: certifcateDropdown });
                setIsLoading(false);
            }
        } catch (e: any) {
            console.log(e);
            setIsLoading(false);
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message || 'Something went wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOnClick = async () => {
        try {
            setIsLoading(true);
            const dataSignedInput = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_dataSignInput', {
                RequestInfo: requestInfo(),
                tokenDisplayName: values.token.code,
                keyId: values.certificate.code,
                keyStorePassPhrase: values.keyStorePassPhrase || ''
            });
            const pkcsSign = await axios.post(EMUDRA_LOCALHOST_URL + '/DSC/PKCSSign', {
                encryptionKeyId: dataSignedInput.data.input.encryptionKeyId,
                encryptedRequest: dataSignedInput.data.input.encryptedRequest
            });
            const dataEsign = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_dataSign', { RequestInfo: requestInfo(), responseData: pkcsSign.data.responseData });
            setIsLoading(false);
            if (dataEsign?.data?.responseMessage.includes('Error:')) {
                setModalDetails({ show: true, title: 'Error!', para: dataEsign?.data?.responseMessage, img: cross, type: 'error', reUpload: 'OK' });
            } else {
                setModalDetails({
                    show: true,
                    title: t('SUCCESS', 'Success!'),
                    para: dataEsign?.data?.responseMessage,
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
            }

        } catch (e: any) {
            console.log('err', e);
            setIsLoading(false);
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message || t('DSC_REGISTRATION_UNSUCCESSFUL_MSG', 'DSC Registration failed');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({ show: false });
        if (type === 'success') {
            navigate(getNavigateTo(userType));
        }
    };

    console.log(formik);

    return (
        <>
            {isLoading ? <Loading /> : <></>}
            {/* <HeaderComponent /> */}
            {/* <div className='employee-creation-container bg-light m-auto px-5 py-4 row'> */}
            {/* <div className='formHeading mb-2'>{t('DSC_REGISTRATION', 'DSC Registration')}</div> */}

            {/* <div className='col-12 form-section'> */}
            <FormikProvider value={formik}>
                <Card className='card_border'>
                    <Card.Body>
                        {DSC_REGISTRATION_FIELDS?.map((val: any) => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} customHandleChange={onChange} />
                        ))}
                    </Card.Body>
                </Card>
            </FormikProvider>
            {/* </div> */}
            <footer className='footer fixed-bottom footerBtnPatch'>
                <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                    <GenericButtons text={t('REGISTER', 'Register')} disabled={!values.certificate} variant='primary' handleClick={handleOnClick} />
                </div>
            </footer>
            {/* </div> */}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
        </>
    );
};
