import { FormikProvider, useFormik } from 'formik';
import GenericFileUploadField from '../../../../components/moduleCommonFormik/GenericFileUpload.component';
import { DOCDETAILSOBJECT } from '../../UserManagement/types';
import { useEffect, useState } from 'react';
import { formatedDocumentDetails } from '../../../../utils/utils';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { useTranslation } from 'react-i18next';
import PopUps from '../../../../components/PopUps';
import { cross } from '../../../../utils/Logo';

interface clarificationHistoryProps {
    clarificationHistoryDetails: any;
}

export const NocClarificationModal = (props: clarificationHistoryProps) => {
    const { clarificationHistoryDetails } = props;
    const [nocDocumentDetails, setNocDocumentDetails] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState({});
    const formik = useFormik<DOCDETAILSOBJECT>({
        initialValues: {},
        onSubmit: () => {}
    });
    const { t } = useTranslation();

    useEffect(() => {
        const nocDocumentFileStoreIds = clarificationHistoryDetails
            .flatMap((val: any) => val.comments.flatMap((msg: any) => msg.replies.map((com: any) => com.nocDocumentFileStoreId)))
            .filter((id: any) => id !== null);

        const fetchData = async () => {
            try {
                const updatedDetails = await nocDocDetails(nocDocumentFileStoreIds);
                setNocDocumentDetails(updatedDetails);
            } catch (error) {
                let errMsg = t('ERROR_WHILE_FETCHING_DATA', 'Error while fetching data');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        fetchData();
    }, []);

    const nocDocDetails = async (nocDocumentFileStoreIds: any) => {
        const documentDetailsArray: any = {};
        try {
            const nocData = await formatedDocumentDetails(nocDocumentFileStoreIds.join(), true);
            nocData.forEach((doc: DocumentInfo) => (documentDetailsArray[`docDetails_${doc.referenceId}`] = doc));
            formik.setValues(documentDetailsArray);
            if (clarificationHistoryDetails.length > 0) {
                const updatedDetails = clarificationHistoryDetails.map((val: any) => ({
                    ...val,
                    comments: val.comments.map((msg: any, id: number) => ({
                        ...msg,
                        replies: msg.replies.map((com: any, id: number) => {
                            const matchedDocItem = nocData.find((docItem: any) => com.nocDocumentFileStoreId === docItem.referenceId);
                            if (matchedDocItem) {
                                return {
                                    ...com,
                                    documentDetails: { ...matchedDocItem }
                                };
                            }
                            return com;
                        })
                    }))
                }));
                return updatedDetails;
            }
        } catch (error) {
            let errMsg = t('ERROR_WHILE_FETCHING_DATA', 'Error while fetching data');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <FormikProvider value={formik}>
                <div className='clarification-body font-inter'>
                    {clarificationHistoryDetails.map((val: any, id: number) => (
                        <div key={id}>
                            <div className='formTitle font-18'>{t(val.nocType)}</div>
                            <div>
                                {val.comments.map((msg: any, id: number) => (
                                    <div className='d-flex justify-content-between mb-4'>
                                        <div className='col-sm-3 p-0'>
                                            <p className='fw-500' style={{ fontSize: '14px' }}>
                                                {msg.intialClarificationDate}
                                            </p>
                                        </div>
                                        <div className='col-sm-9 p-0 mb-3'>
                                            <div>
                                                <p className='mb-0'>
                                                    <span className='formTitle font-14'>{msg.commentedBy}</span> - <i className='text-muted fornt-14'>{msg.createdTime}</i>
                                                </p>
                                                <p className='mb-1'>{msg.comment}</p>
                                            </div>
                                            {msg.replies.map((com: any, id: number) => (
                                                <>
                                                    <div>
                                                        <p className='mb-0'>
                                                            <span className='formTitle font-14'>{com.commentedBy}</span> - <i className='text-muted font-14'>{com.createdTime}</i>
                                                        </p>
                                                        <p className='mb-1'>{com.comment}</p>
                                                        <div className={`${val?.class ? val?.class : 'col-sm-6'}`}>
                                                            {com.nocDocumentFileStoreId ? <GenericFileUploadField field={`docDetails_${com.nocDocumentFileStoreId}`} title='nocDocument' /> : null}
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {clarificationHistoryDetails.length > 1 ? <hr /> : <></>}
                        </div>
                    ))}
                </div>
            </FormikProvider>
        </>
    );
};
