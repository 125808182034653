import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../../../../components/header/Header.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { Card, Form } from 'react-bootstrap';
import NotingComponent from '../../../../../components/Workflow/NotingComponent';
import { useEffect, useState } from 'react';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { POST, devUrl, endpoints, estateEndpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import { dropdownDataOptions, getEntityDocumentDetails, getFormatedOwnershipDetails } from '../../../Migration/Migration.controller';
import { AllotmentWorkflowCorrespondence } from './AllotmentWorkflowCorrespondence';
import ScrollToTop from '../../../../../components/ScrollToTop';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../Allotment.const';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import { TENANT_ID } from '../../../../../utils/constants';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import { getAllotmentWorkflowInitialData } from '../Allotment.controller';
import { useTranslation } from 'react-i18next';
import { convertToINR, getCheckList, getHistory, getNextActions, getSuccessMessage, getVacantPlots, getWorkflowDocumentSearch, onAttachDocument } from '../../EstateManagement.controller';
import { PlotDropdownData } from '../../../UserManagement/types';
import Loading from '../../../../../components/portalComponents/Loading.component';
import { check, cross } from '../../../../../utils/Logo';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import CorrespondenceDocDownload from '../../../../../components/Workflow/CorrespondenceDocDownload';
import NotingDocDownload from '../../../../../components/Workflow/NotingDocDownload';
import { title } from 'process';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import axios from 'axios';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import { ALLOTMENT_WORKFLOW_VALIDATION } from '../../../../../utils/formikValidation/FormikValidation';
import { getFirstErrorKey } from '../../../../../utils/utils';
import { EsignModal } from '../../../../commonPages/EsignModal';

export default function AllotmentWorkflow() {
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(1);
    const [searchParams] = useSearchParams();
    const allotmentAppNo: any = decodeURIComponent(searchParams.get('applicationNumber') || '');

    const auctionType = searchParams.get('auction');
    const advType = searchParams.get('adv');
    const signedFileStoreId = searchParams.get('signedFileStoreId');

    const totalPages = allotmentAppNo ? 2 : 1;
    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';

    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [dropdownDataPlot, setDropdownDataPlot] = useState<PlotDropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [isChecked, setIsChecked] = useState([true, true, true]);
    const [tabelData, setTabelData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    let navigate = useNavigate();
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [nicDetails, setNicDetails] = useState<any>([]);
    const [applicationNo, setApplicationNo] = useState<string>('');
    const [applicationType, setApplicationType] = useState<string>('');
    const [estateApplicationsData, setEstateApplicationsData] = useState<any>(null);
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [checklistTableData, setChecklistTableData] = useState();
    const [vacantPlotData, setVacantPlotData] = useState<any>([]);
    const [rowData, setRowData] = useState([]);
    const [scoringMatrixResponse, setScoringMatrixResponse] = useState([]);
    const [content, setContent] = useState('');
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [error, setError] = useState<string>('');
    const [isSamePlot, setIsSamePlot] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>(null);
    const [openSection, setOpenSection] = useState<string>('applicantDetails');
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [refId, setRefId] = useState<any>('');
    const [approvedEsignData, setApprovedEsignData] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);

    const { t } = useTranslation();

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            emails: [{ emailId: '' }],
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: ALLOTMENT_WORKFLOW_VALIDATION
    });
    const { values, setValues, errors, setFieldTouched } = formik;

    useEffect(() => {
        if (values?.category?.code) {
            if (values?.category?.code === 'PROP_FIRM') {
                if (ownershipDetails?.length) {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: ownershipDetails[0]?.name }));
                } else {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: '' }));
                }
            } else {
                setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.applicantName }));
            }
        }
    }, [values?.category?.code, ownershipDetails, values?.applicantName]);

    useEffect(() => {
        const ApplicanAndPlotDetails = async () => {
            if (allotmentAppNo && dropdownData) {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${allotmentAppNo}`, { RequestInfo: requestInfo() });
                    const estateData = data.estateApplications[0];
                    setEstateApplicationsData({ ...estateData, attachmentRefId: signedFileStoreId ? signedFileStoreId : data.estateApplications[0].attachmentRefId });
                    const historyData = await getHistory(allotmentAppNo);
                    setHistoryDetails(historyData);
                    const AllotmentInitData = await getAllotmentWorkflowInitialData(estateData, dropdownData);

                    setValues((prev: any) => ({
                        ...prev,
                        ...AllotmentInitData,
                        applicationSubType: estateData.applicationSubType,
                        highestBidRate: estateData.plotRate === 0 ? '' : estateData.plotRate,
                        isAuction: auctionType ? true : false
                    }));
                    setContent(estateData.content);
                    setFileStoreId(estateData?.signedConsolidatedDocRefId);

                    const ownershipTableDetails = await getFormatedOwnershipDetails(estateData?.enterpriseEntity?.owners, dropdownData);
                    setOwnershipDetails(ownershipTableDetails);
                    setControllingOwnershipResponse(estateData?.enterpriseEntity?.owners);
                    setNicDetails(
                        estateData.enterpriseDetails?.nicEnterpriseMappings?.map((val: any) => ({
                            proposedActivity: val.proposedActivity,
                            subClassAndsubClassDescription: `${val.nicMasterDetails.subClassCode}-${val.nicMasterDetails.subClassDescription}`
                        }))
                    );
                    setApplicationNo(estateData.applicationNo);
                    setApplicationType(estateData.applicationType);

                    if (estateData?.status !== 'PENDING' || (estateData?.status === 'PENDING' && designation === 'GM')) {
                        if (estateData.assignedPlotDetails) {
                            setVacantPlotData([
                                {
                                    allotedEstateName: estateData.assignedPlotDetails?.estateDetails?.estateName,
                                    allotedPlotType: estateData.assignedPlotDetails?.plotType,
                                    allotedPhase: estateData.assignedPlotDetails?.phase,
                                    allotedPlotNo: estateData.assignedPlotDetails?.plotNo,
                                    allotedUuid: estateData.assignedPlotDetails?.uuid,
                                    allotedPlotRate: estateData.assignedPlotDetails?.plotRate,
                                    allotedPlotArea: estateData.assignedPlotDetails?.plotAreaSqm
                                }
                            ]);
                        } else {
                            const res = await POST(estateEndpoints.plotSearch + `?plotStatus=VACANT,ADVERTISED,APPLIED`, { RequestInfo: requestInfo() });
                            let plots;
                            if (res && res.data && res.data.plots.length > 0) {
                                plots = res.data.plots.map((e: any) => {
                                    e.id = e.uuid;
                                    return e;
                                });
                            }
                            // const data: any = await getVacantPlots('VACANT,ADVERTISED,APPLIED');
                            let plotDataArray = plots?.map((plot: any) => ({
                                allotedEstateName: plot?.estateDetails?.estateName,
                                allotedPlotType: plot?.plotType,
                                allotedPhase: plot?.phase,
                                allotedPlotNo: plot?.plotNo,
                                allotedUuid: plot?.uuid,
                                allotedPlotRate: plot?.plotRate,
                                allotedPlotArea: plot?.plotAreaSqm
                            }));
                            setVacantPlotData(plotDataArray);
                        }
                    }

                    if ((designation === 'GM' && estateData?.status === 'PENDING') || (designation === 'AM' && estateData?.status === 'SCRUTINY_DONE')) {
                        setDisabled(false);
                    }
                    setIsLoading(false);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    setIsLoading(false);
                }
            }
        };
        ApplicanAndPlotDetails();
    }, [dropdownData]);

    useEffect(() => {
        const getChecklistTableData = async () => {
            try {
                const checklistData = await getCheckList(
                    estateApplicationsData?.enterpriseEntity.uuid,
                    estateApplicationsData?.assignedPlotDetails?.uuid || estateApplicationsData?.appliedPlotDetails?.uuid,
                    nextValidAction.businessService
                );
                setChecklistTableData(checklistData);

                const docs = await getWorkflowDocumentSearch(allotmentAppNo, nextValidAction.businessService);
                setDocumentDetails(docs);
            } catch (e) {
                console.log(e);
            }
        };
        estateApplicationsData && getChecklistTableData();
    }, [nextValidAction, estateApplicationsData]);

    useEffect(() => {
        if (!estateApplicationsData?.assignedPlotDetails) {
            (async () => {
                try {
                    const dropdownOption = await dropdownDataOptions();
                    setDropdownData(dropdownOption);

                    const nextActions = await getNextActions(allotmentAppNo);
                    setNextValidAction(nextActions);

                    // const isEsignRequired = nextActions.nextValidAction.find((item: any) => item.isEsignRequired);
                    // setESignRequired(isEsignRequired);
                    // console.log('esign', isEsignRequired);

                    // const data: any = await getVacantPlots();
                    // let plotDataArray = data?.map((plot: any) => ({
                    //     allotedEstateName: plot?.estateDetails?.estateName,
                    //     allotedPlotType: plot?.plotType,
                    //     allotedPhase: plot?.phase,
                    //     allotedPlotNo: plot?.plotNo,
                    //     allotedUuid: plot?.uuid,
                    //     allotedPlotRate: plot?.plotRate,
                    //     allotedPlotArea: plot?.plotAreaSqm
                    // }));
                    // setVacantPlotData(plotDataArray);
                } catch (e: any) {
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, []);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(allotmentAppNo, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs.documents);
            setDocumentsUuid([...documentsUuid, docs.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    console.log('ddddddddddd', documentDetails);

    useEffect(() => {
        if (activeIndex === 2 && documentDetails) {
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails.length - ind}` };
                });
                setTabelData(responseTableData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    const handleNextPage = async () => {
        const docs = await getWorkflowDocumentSearch(allotmentAppNo, nextValidAction.businessService);
        setDocumentDetails(docs);
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const functionReturnError = (err: string) => {
        if (err) {
            setError(err);
        } else {
            setError('');
        }
    };

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    useEffect(() => {
        if (note && action && estateApplicationsData && nextValidAction) {
            onHandlePrimaryAction({ action: action }, '');
        }
    }, [estateApplicationsData, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);

            setRefId(approveEsignRes.data.entityRefDocuments[0].docReferenceId);
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.map((item: any) => `${item.uuid}=${item.docReferenceId}`);
            setApprovedEsignData(approvedEsignData);
            setEsignModal(true);
            setActionAfterEsign(action);

            console.log('refId', refId, approveEsignRes.data.entityRefDocuments, 'data', approvedEsignData);

            // return;

            // if (window.location.protocol !== 'http:') {
            //     let url =
            //         window.location.origin +
            //         `/goa-idc/estate/allotment/workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
            //             ','
            //         )}&action=${action}`;
            //     url = btoa(url);
            //     const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
            //         userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
            //     }`;
            //     await axios.get(esignUrl);

            //     window.open(esignUrl, '_self');
            // } else {
            //     let url =
            //         window.location.origin +
            //         `/goa-idc/estate/allotment/workflow?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
            //             ','
            //         )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
            //     console.log('url', url, documentsUuid);
            //     window.open(url, '_self');
            // }
        } catch (e) {
            console.log('esign error', e);
        }
    };

    const signDocument = () => {
        let url =
            window.location.origin +
            `/goa-idc/estate/allotment/workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
                ','
            )}&action=${actionAfterEsign}`;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
    };

    const onSectionChange = (currentSection: string) => {
        setOpenSection(currentSection);
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        console.log('action', status?.action);
        const { enterpriseDetails, ...rest } = estateApplicationsData;

        const enterpriseDocs =
            designation === 'AM' && estateApplicationsData?.status === 'PENDING'
                ? enterpriseDetails?.documents?.map((docs: any) => {
                      if (docs.documentType === 'EMD_DOCUMENT') {
                          if (docs.docReferenceId !== values?.emdAmountSupportingDocument?.docReferenceId) {
                              return { ...docs, docReferenceId: values?.emdAmountSupportingDocument?.docReferenceId };
                          } else {
                              return docs;
                          }
                      } else {
                          return docs;
                      }
                  })
                : enterpriseDetails?.documents;
        if (!enterpriseDetails) {
            setModalDetails({ show: true, title: 'Error!', para: 'Enterprise details is missing', img: cross, type: 'error', reUpload: 'Ok' });
            return;
        }
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: allotmentAppNo,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            estateApplicationRequest: {
                ...rest,
                enterpriseDetails: {
                    ...enterpriseDetails,
                    emdAmount: +values.emdAmount?.toString().replace(/,/g, '') || estateApplicationsData.enterpriseDetails.emdAmount,
                    documents: enterpriseDocs
                    // [...(enterpriseDetails && enterpriseDetails?.documents), emdDoc]
                },
                appliedPlotId: estateApplicationsData?.appliedPlotDetails.uuid,
                assignedPlotId: estateApplicationsData?.assignedPlotDetails?.uuid || values.allotedPlotId,
                plotRate: +values.highestBidRate?.toString().replace(/,/g, '') || estateApplicationsData.plotRate,
                content: content,
                isUpdateEnterpriseDetails:
                    (designation === 'AM' && auctionType) ||
                    (designation === 'GM' && estateApplicationsData?.appliedPlotDetails.uuid !== values.allotedPlotId && estateApplicationsData?.status === 'PENDING')
                        ? true
                        : false
            },
            signedDocDetails:
                approvedEsign?.split(',').map((item: any) => {
                    const esignData = item.split('=');
                    return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                }) || null
        };

        if (error) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter valid marks in the Scoring Matrix', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (estateApplicationsData.applicationSubType === 'AUCTION' && designation === 'AM' && !values.highestBidRate) {
            onSectionChange('plotDetails');
            await setFieldTouched('highestBidRate', true, true);
            let getErrorField: any = document.querySelector(`[name=highestBidRate]`);
            getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter highest bid rate under Plot Details!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (designation === 'GM' && advType && estateApplicationsData.status === 'PENDING' && !values.allotedPlotId && status?.action !== 'DISQUALIFY') {
            onSectionChange('plotDetails');
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: 'Please update the allotted plot details', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (designation === 'AM' && auctionType && !values.emdAmount) {
            onSectionChange('enterpriseDetails');
            await setFieldTouched('emdAmount', true, true);
            let getErrorField: any = document.querySelector(`[name=emdAmount]`);
            getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter EMD Amount under Enterprise Details', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (designation === 'AM' && auctionType && !values.emdAmountSupportingDocument) {
            onSectionChange('enterpriseDetails');
            await setFieldTouched('emdAmountSupportingDocument', true, true);
            let getErrorField: any = document.querySelector(`[name=emdAmountSupportingDocument]`);
            getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter supporting document for EMD Amount', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        // if (!comment && !status.isEmployeeAssignee && !note) {
        //     setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        //     return;
        // }

        if (!values.notes && !note) {
            if (!comment) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }

        if (!signedFileStoreId && status.isEsignRequired) {
            esign(payload, status.action);
            return;
        }

        try {
            setIsLoading(true);
            const res = await POST(endpoints.estateWorkflowInitiate, payload);
            if (res.data) {
                if (designation === 'GM' && estateApplicationsData?.status === 'PENDING' && estateApplicationsData.applicationSubType === 'IDC') {
                    updateScoringMatrix();
                    setIsLoading(false);
                }
                const successMessage = getSuccessMessage(status?.action, allotmentAppNo);
                setModalDetails({ show: true, title: 'Success!', para: successMessage, img: check, type: 'success', reUpload: 'OK', finalSubmit: true });
            }
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        if (estateApplicationsData) {
            const searchScoringMatrix = async () => {
                try {
                    let res;
                    res = await POST(`/idc-estate-service/scoring-matrix/_search?applicationNumber=${allotmentAppNo}`, { RequestInfo: requestInfo() });
                    // let createData = null;
                    if (res.data.scores?.length === 0) {
                        res = await POST(`/idc-estate-service/scoring-matrix/_create?applicationNumber=${allotmentAppNo}`, { RequestInfo: requestInfo() });
                    }
                    setScoringMatrixResponse(res.data.scores);

                    const scoringMatrixRowData = res.data.scores?.map((val: any) => ({
                        particulars: val.masterScoreDetails.scoringAttribute,
                        maxMarks: val.masterScoreDetails.maxScore,
                        marks: val.systemGeneratedScore,
                        committeMarks: val.score
                    }));

                    setRowData(scoringMatrixRowData);
                } catch (e) {
                    console.log(e);
                }
            };
            if (designation === 'GM' && advType && estateApplicationsData.status === 'PENDING' && estateApplicationsData.applicationSubType === 'IDC') {
                searchScoringMatrix();
            }
            getPaymentData();
        }
    }, [estateApplicationsData]);

    const updateScoringMatrix = async () => {
        scoringMatrixResponse.map((val: any) =>
            rowData.map((item: any) => {
                if (val.masterScoreDetails.scoringAttribute === item.particulars) {
                    val.score = item.committeMarks;
                }
            })
        );
        const payload = {
            RequestInfo: requestInfo(),
            scores: scoringMatrixResponse,
            applicationNumber: allotmentAppNo
        };
        try {
            await POST('/idc-estate-service/scoring-matrix/_update', payload);

            // toast(t('DATA_SAVED', 'Data Saved'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'success'
            // });
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getPaymentData = async () => {
        try {
            const data = await POST(
                endpoints.paymentDetailsSearch +
                    `?paymentTypes=PROCESSING_FEE,SECURITY_DEPOSIT&entityIds=${estateApplicationsData.enterpriseEntity.uuid}&plotIds=${estateApplicationsData.appliedPlotDetails.uuid}`,
                { RequestInfo: requestInfo() }
            );
            setTransactionDetails(data?.data?.payments[0]);
            let payment = data?.data?.payments?.map((val: any, index: number) => ({
                feeTypeDisplayName: val.paymentTypeDisplayName,
                amount: convertToINR(val.principalAmount),
                cgstAmount: val.cgstPrincipal === 0 ? '--' : convertToINR(val.cgstPrincipal),
                sgstAmount: val.sgstPrincipal === 0 ? '--' : convertToINR(val.sgstPrincipal),
                total: convertToINR(val.paidAmount),
                index: index + 1
            }));
            setPaymentRowData(payment);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const updatePlotRate = async () => {
        if (scoringMatrixResponse?.length) {
            if (error) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please enter valid marks in the Scoring Matrix', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            updateScoringMatrix();
            // return;   incase of update scoring , update allotment should also called
        }

        const payload = {
            RequestInfo: requestInfo(),
            ...estateApplicationsData,
            appliedPlotId: estateApplicationsData?.appliedPlotDetails?.uuid,
            assignedPlotId: estateApplicationsData?.assignedPlotDetails?.uuid || values.allotedPlotId,
            plotRate: +values.highestBidRate?.toString().replace(/,/g, '') || null,
            content: content
        };

        try {
            const { data } = await POST(endpoints.updateAllotment, payload);
            setContent(data.estateApplications[0].content);

            setModalDetails({ show: true, title: 'Success!', para: 'Data saved successfully', img: check, type: 'success', reUpload: 'OK' });
        } catch (err: any) {
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string, msg: string, finalSubmit: boolean) => {
        setModalDetails({});
        if (type === 'success' && finalSubmit) navigate('/goa-idc/dashboard');
    };

    const handleSamePlotCheck = (e: any) => {
        if (e.target.checked) {
            setValues({
                ...values,
                allotedEstateName: values.estateName,
                allotedPlotType: values.plotType,
                allotedPhase: values.phase,
                allotedPlotNo: values.plotNo,
                allotedUuid: values.uuid,
                allotedPlotRate: values.plotRate,
                allotedPlotArea: values.plotArea,
                allotedPlotId: values.sameAsAboveAllottedPlotId
            });
            setIsSamePlot(true);
        } else {
            setValues({
                ...values,
                allotedEstateName: '',
                allotedPlotType: '',
                allotedPhase: '',
                allotedPlotNo: '',
                allotedUuid: '',
                allotedPlotRate: '',
                allotedPlotArea: '',
                allotedPltId: ''
            });
            setIsSamePlot(false);
        }
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={'Application for Allotment'}
                            additionalClass='sub-text-style'
                            bottomSubText={`File : ${estateApplicationsData?.enterpriseEntity?.entityName}, Type : Allotment, No : ${allotmentAppNo}`}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader text={'Application for Allotment'} sideHeader={true} additionalClass='sub-text-style' bottomSubText={`Type : Allotment, No : ${allotmentAppNo}`} />
                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={allotmentAppNo}
                                        history={historyDetails}
                                        disable={!nextValidAction?.nextValidAction.length}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                {/* <div className='d-flex justify-content-between align-items-center'>
                                    <p className='correspondence-heading my-1'>Correspondence</p>
                                    <CorrespondenceDocDownload applicationNumber={allotmentAppNo} />
                                </div> */}

                                <CorrespondenceWrapper designation={designation} applicationNumber={allotmentAppNo} nextValidAction={nextValidAction} />

                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <AllotmentWorkflowCorrespondence
                                                dropdownData={dropdownData}
                                                dropdownDataPlot={dropdownDataPlot}
                                                setDropdownDataPlot={setDropdownDataPlot}
                                                ownershipDetails={ownershipDetails}
                                                setOwnershipDetails={setOwnershipDetails}
                                                isChecked={isChecked}
                                                handleCheckboxChange={handleCheckboxChange}
                                                nicDetails={nicDetails}
                                                setNicDetails={setNicDetails}
                                                controllingOwnership={controllingOwnershipResponse}
                                                checkListRowData={checklistTableData}
                                                vacantPlotData={vacantPlotData}
                                                rowData={rowData}
                                                setRowData={setRowData}
                                                applicationNumber={allotmentAppNo}
                                                status={estateApplicationsData?.status}
                                                content={content}
                                                setContent={setContent}
                                                paymentRowData={paymentRowData}
                                                disabled={disabled}
                                                designation={designation}
                                                auctionType={auctionType}
                                                fileStoreId={fileStoreId}
                                                functionReturnError={functionReturnError}
                                                readOnly={!nextValidAction?.isUpdatable}
                                                handleSamePlotCheck={handleSamePlotCheck}
                                                setIsSamePlot={setIsSamePlot}
                                                isSamePlot={isSamePlot}
                                                transactionDetails={transactionDetails}
                                                openSection={openSection}
                                                setOpenSection={setOpenSection}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <div className='p-4'>
                                                <GenericActionTable hideSearchBar={true} columns={CORRESPONDENCE_TABLE} rowData={tabelData} additionalMethod={onRowClick} />
                                            </div>
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === 2 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    <GenericFooterComponent
                        onCancel={onCancel}
                        onClickPrev={onChangePrevIndex}
                        activeIndex={activeIndex}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={() => {}}
                        applicationNo={allotmentAppNo || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                        extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                        extraButtonAction={updatePlotRate}
                    />

                    {/* {(designation === 'MD' && signedFileStoreId) || designation !== 'MD' ? (
                        <GenericFooterComponent
                            onCancel={onCancel}
                            onClickPrev={onChangePrevIndex}
                            activeIndex={activeIndex}
                            handleOnPrimaryAction={onHandlePrimaryAction}
                            onSubmit={() => {}}
                            applicationNo={allotmentAppNo || null}
                            nextValidAction={nextValidAction}
                            stepperLength={0}
                            extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                            extraButtonAction={updatePlotRate}
                        />
                    ) : (
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    <GenericButtons text={`Esign`} variant='primary' handleClick={() => esign()} />
                                </div>
                            </div>
                        </footer>
                    )} */}

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.para, modalDetails.finalSubmit)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.para, modalDetails.finalSubmit)}
                    />
                </>
            )}
        </>
    );
}
