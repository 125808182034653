import _ from 'lodash';
import { AUTHORIZED_PERSON_TITLE, CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';

export const stepsDetails = [
    {
        titleCode: 'APPLICANT_DETAILS',
        name: 'Applicant Details',
        index: 1,
        isActive: true
    }
];

export const APPLICANT_DETAILS_2 = [
    {
        formData: [
            {
                title: 'Email ID',
                field: 'subLeaseEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
                // isDisabled: true
            },
            {
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
                // isDisabled: true
            },
            {
                title: 'PAN No',
                field: 'applicantPanNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
                // conditionalClass: (values: any) => (_.get(values, `applicantGst`) === false ? 'me-3' : '')
            },
            {
                title: 'PAN Card',
                titleCode: 'PAN Card',
                field: 'applicantPancard',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                class: 'col-sm-6 d-block',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.PAN,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            }
        ]
    },
    {
        titleCode: 'Address of the Applicant',
        title: 'Address Details',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-6'
            },
            {
                title: 'State U/T',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Mailing/Correspondence address of the Applicant',
        title: 'Mailing/Correspondence address of the Entity',
        isSameAsAbove: true,
        isRequired: true,
        formData: [
            {
                title: 'Address Line 1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2 ',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'District',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'State U/T',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'Country',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Details of the Authorized Person',
        title: "Authorized Person's Details",
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                title: 'First Name',
                field: 'authorizedPersonFirstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Last Name',
                field: 'authorizedPersonLastName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Primary Mobile Number',
                field: 'pmobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
                // isDisabled: true
            },
            {
                title: 'Alternative Mobile Number',
                field: 'alternateMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false
            },

            {
                title: 'Email ID',
                field: 'authorizedEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
                // isDisabled: true
            },

            {
                title: 'The letter / resolution / power of attorney authorising the authorised person',
                titleCode: 'Please attach the letter/resolution/power of attorny ',
                field: 'letter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'authorizedPersonFirstname',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                }
            }
        ]
    }
];
