import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { PLOT_INFO } from './Dashboard.const';
import { Link, useNavigate } from 'react-router-dom';
import check from '../../../../assets/logos/check cir xxl.svg';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { minusCircle, checkCircle, warningCircle, crossCircle } from '../../../../utils/Logo';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { currentDate_ddMMyyyy, getEpoch } from '../../../../utils/utils';

export const PlotInfoSection = (props: any) => {
    const { currentPlotDetails, allDocuments, coExpiryDate, deedExecutedDate, builtUpPercentage, builtUpArea, migartedPlot } = props;
    const closeCard = useRef<any>(null);
    const [showCard, setShowCard] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [addButton, setAddButton] = useState(false);
    const [currentDate, setCurrentDate] = useState<any>(null);
    const [card, setCard] = useState<any>(['DOWNLOADS', 'QUERY']);

    // let card = ['DOWNLOADS', 'QUERY'];

    useEffect(() => {
        const handleOutsideClick = (e: any) => {
            if (closeCard.current && !closeCard.current.contains(e.target)) {
                setShowCard(false);
            }
        };

        const currDate = currentDate_ddMMyyyy();
        setCurrentDate(currDate);
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (migartedPlot && !card.includes('VIEW_MIGRATED_DATA')) {
            setCard([...card, 'VIEW_MIGRATED_DATA']);
        } else {
            const cardData = [...card];
            const filteredData = cardData.filter((item: any) => item !== 'VIEW_MIGRATED_DATA');
            // cardData.slice(0, ind);
            setCard(filteredData);
        }
    }, [migartedPlot]);

    const handleCardClick = (e: any) => {
        setShowCard(!showCard);
        e.stopPropagation();
    };

    const handleOnCardClick = (cardText: any) => {
        switch (cardText) {
            case 'DOWNLOADS':
                navigate('/goa-idc/document-downloads', { state: { plotId: currentPlotDetails?.plotId } });
                break;
            case 'WATER':
                navigate('/goa-idc/obpas/waterconnection');
                break;
            case 'QUERY':
                navigate('/goa-idc/query-entry');
                break;
            case 'VIEW_MIGRATED_DATA':
                navigate(`/goa-idc/migration/migrationform?plotId=${currentPlotDetails?.plotId}`);
                break;
            default:
                console.log('Unknown button');
        }
    };

    console.log('curr', currentPlotDetails);

    const clickCertificateOperation = () => {
        if (allDocuments?.deedDetails?.length) {
            navigate('/goa-idc/estate/commercial-application/apply', { state: { data: currentPlotDetails } });
        } else {
            setAddButton(true);
        }
    };

    const getDueDate = (inputDate: string) => {
        const dates = inputDate?.split('/');
        const day = dates[0];
        const month = dates[1];
        const year = +month <= 3 ? (+dates[2] + 4).toString() : +dates[2] + 5;

        const formattedDate = `31/03/${year}`;
        return formattedDate;
    };

    const expiryDueDate = deedExecutedDate && getDueDate(deedExecutedDate);

    const currentDateEpoch = currentDate && getEpoch(currentDate);
    const coExpiryDateEpoch = coExpiryDate && getEpoch(coExpiryDate);
    const expiryDueDateEpoch = expiryDueDate && getEpoch(expiryDueDate);

    console.log('expiryDueDate', expiryDueDate, deedExecutedDate);

    return (
        <>
            <div className='col-12 p-3 rounded-4 bg-white plot-details border border-1 '>
                <div className='row p-2 gx-2'>
                    <span className='col-7'>
                        <div className='plot-info-heading d-flex justify-content-between align-items-center '>
                            <span className='fw-bold font-20 dashboard-text-color lh-25'>{t('PLOT INFO', 'Plot Info')}</span>
                        </div>
                        <div className='plot-info-details d-flex justify-content-between mt-2 pt-1'>
                            {PLOT_INFO.map((val: any, index: number) => (
                                <div>
                                    <div className='info-title fw-400 font-14 mb-2' style={{ lineHeight: '14.4px', color: '#70777E' }}>
                                        {val?.title}
                                    </div>
                                    <div className='info fw-semibold font-16 dashboard-text-color lh-19 '>
                                        {val?.value === 'builtUpArea' ? builtUpArea || 0 : _.get(currentPlotDetails, val?.value) || '-'}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </span>
                    <div className='col-1 '>
                        <div className='border-1 d-flex justify-content-center h-100 border-end cursor-pointer'>
                            <span
                                className='three-dot cursor-pointer position-relative d-flex justify-content-center'
                                ref={closeCard}
                                onClick={e => handleCardClick(e)}
                                style={{ width: '1rem', height: '1rem' }}
                            >
                                <FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#45852e' }} />
                                {showCard && (
                                    <span
                                        className={`option-list bg-white border-rad-12 py-3 position-absolute end-100 z-1`}
                                        onClick={e => e.stopPropagation()}
                                        ref={closeCard}
                                        style={{ minWidth: '10.5rem', boxShadow: '2px 4px 16px 0px #0000001F' }}
                                    >
                                        {card?.map((cardText: any, index: number) => (
                                            <div className='px-3 py-2 fw-500 font-16 dashboard-text-color lh-22' onClick={() => handleOnCardClick(cardText)}>
                                                {t(cardText)}
                                            </div>
                                        ))}
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>

                    <span className='buttons col-2 ps-3'>
                        <div className=' bg-light h-100 d-flex align-items-center border-rad-12 justify-content-between px-2 ms-auto'>
                            <span className='font-28 project-primary-color fw-500 font-inter'>{builtUpPercentage}%</span>
                            <span className='ms-2 font-16 font-inter fw-400'>Built-up Percentage</span>
                        </div>
                    </span>

                    <span className='buttons col-2 ps-3'>
                        <div className=' bg-light h-100 d-flex align-items-center border-rad-12 justify-content-between px-2 ms-auto'>
                            <span>
                                <img
                                    src={
                                        !allDocuments?.deedDetails?.length
                                            ? minusCircle
                                            : !coExpiryDate
                                            ? currentDateEpoch <= expiryDueDateEpoch
                                                ? warningCircle
                                                : crossCircle
                                            : currentDateEpoch <= coExpiryDateEpoch
                                            ? checkCircle
                                            : crossCircle
                                    }
                                    alt='check'
                                    style={{ height: '3.5rem', width: '3.5rem' }}
                                />
                            </span>
                            <span className='ms-2 '>
                                <div className='project-primary-color fw-semibold font-18 lh-24 ' onClick={clickCertificateOperation} style={{ cursor: 'pointer' }}>
                                    {t('CERTIFICATE_OF_CO', 'Certificate of CO')}
                                </div>
                                <div className='dashboard-text-color fw-500 font-14 lh-19'>
                                    {!allDocuments?.deedDetails?.length
                                        ? '--'
                                        : !coExpiryDate
                                        ? currentDateEpoch <= expiryDueDateEpoch
                                            ? `Due Date: ${expiryDueDate}`
                                            : `Due Date: ${expiryDueDate}`
                                        : currentDateEpoch <= coExpiryDateEpoch
                                        ? `Validity: ${coExpiryDate}`
                                        : `Validity: ${coExpiryDate}`}
                                </div>
                                {/*{t('UPLOADED', 'Uploaded')}*/}
                            </span>
                        </div>
                        {/* <Link
                            className=' bg-light mb-4 d-flex align-items-center border-rad-12 justify-content-between px-3 ms-auto'
                            to={`/goa-idc/obpas/waterconnection?plotIds=${currentPlotDetails?.plotId}&entityIds=${currentPlotDetails?.entityId}`}
                        >
                            New Water Connection
                        </Link> */}
                    </span>
                </div>
            </div>
            <GenericPopupModal
                title='Disclaimer'
                size='md'
                primaryAction={() => {
                    setAddButton(false);
                }}
                secondaryAction={() => {}}
                isVisible={addButton}
                children={
                    <div>
                        <div className='mx-4 my-3'>
                            <div className='mt-4'>
                                <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                    {t('COMMERCIAL_DISCLAIMER', 'Certificate of Commercial Operation can be uploaded after the execution of Lease Deed')}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                primaryBtnTitle='OK'
                isSecondaryBtn={false}
                isSecondaryDisabled
                onClose={() => setAddButton(false)}
            />
        </>
    );
};
