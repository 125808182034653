import advImg from '../../assets/logos/AppWindow.svg';
import ipbImg from '../../assets/logos/Buildings.svg';
import aucImg from '../../assets/logos/Gavel.svg';

import newConImg from '../../assets/logos/LinkSimple.svg';
import upgImg from '../../assets/logos/ArrowFatLineUp.svg';
import discImg from '../../assets/logos/LinkBreak.svg';

import empRegImg from '../../assets/logos/Stamp.svg';
import actvDeacImg from '../../assets/logos/PlusMinus.svg';
import empMappingImg from '../../assets/logos/UserFocus.svg';
import srchImg from '../../assets/logos/searchUser.svg';

export const PLOT_SCREEN_JSON = [
    {
        titleCode: 'ALLOTMENT_VIA_ADVERTISEMENT',
        btnTitle: 'Allotment via Advertisement',
        btnURL: '/goa-idc/estate/apply-for-plot',
        logo: advImg
    },
    {
        titleCode: 'ALLOTMENT_VIA_IPB',
        btnTitle: 'Allotment via IPB',
        btnURL: '/goa-idc/estate/allotment?ipb=true',
        logo: ipbImg
    },
    {
        titleCode: 'ALLOTMENT_VIA_AUCTION',
        btnTitle: 'Allotment via Auction',
        btnURL: '/goa-idc/estate/allotment?auction=true',
        logo: aucImg
    }
];

export const WATER_CONNECTION_SCREEN_JSON = [
    {
        titleCode: 'NEW_CONNECTION',
        btnTitle: 'New Connection',
        btnURL: '/goa-idc/waterconnection',
        logo: newConImg
    },
    {
        titleCode: 'UPGRADATION',
        btnTitle: 'Upgradation',
        btnURL: '/goa-idc/waterconnection/upgradation',
        logo: upgImg
    },
    {
        titleCode: 'DISCONNECTION',
        btnTitle: 'Disconnection',
        btnURL: '/goa-idc/waterconnection/disconnection',
        logo: discImg
    }
];

export const USER_MANAGEMENT_SCREEN_JSON = [
    {
        titleCode: 'EMPLOYEE_REGISTRATION',
        btnTitle: 'Employee registration',
        btnURL: '/goa-idc/usermanagement/employeecreation',
        logo: empRegImg
    },
    {
        titleCode: 'EMPLOYEE_ACTIVATE_DEACTIVATE',
        btnTitle: 'Activate/Deactivate Employee',
        btnURL: '/goa-idc/empsearch?active_deactive=true',
        logo: actvDeacImg
    },
    {
        titleCode: 'EMPLOYEE_SEARCH_UPDATE',
        btnTitle: 'Employee Search and Update',
        btnURL: '/goa-idc/empsearch',
        logo: srchImg
    },
    {
        titleCode: 'ESTATE_LEVEL_MAPPING',
        btnTitle: 'Estate Level Mapping',
        btnURL: '/goa-idc/employee-mapping',
        logo: empMappingImg
    }
];
