import { FieldArray, useFormikContext } from 'formik';
import { Card } from 'react-bootstrap';
import { CONSTRUCTION_LICENSE, CONSTRUCTION_LICENSE_DETAILS, CompletionOrder_OccypancyPercent, PUSH_CLOC, TECHNICAL_CLEARANCE, TECHNICAL_CLEARANCE_DETAILS } from '../Migration.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import DeleteIcon from '../../../../assets/logos/delete.svg';
import { useEffect } from 'react';
import GenericCommonRadioField from '../../../../components/moduleCommonFormik/GenericCommonRadioField.component';
import { options } from '../../EstateManagement/SubLease/SubLeaseConst';

const ConstructionLicenseDetails = ({ dropdownData, setDropdownData, isUpdate }: any) => {
    const { values } = useFormikContext<any>();

    useEffect(() => {
        let technicalClearanceOrderNumber: any = [];
        values?.technicalClearance.map((val: any, _: number) => {
            technicalClearanceOrderNumber.push({ name: val.orderNo, code: val.orderNo });
        });

        let constructionLicenseOrderNumber: any = [];
        values?.constructionLicense.map((val: any, _: number) => {
            constructionLicenseOrderNumber.push({ name: val.orderNo, code: val.orderNo });
        });

        setDropdownData({
            ...dropdownData,
            technicalClearanceOrderNumber: technicalClearanceOrderNumber,
            constructionLicenseOrderNumber: constructionLicenseOrderNumber
        });
    }, [values.technicalClearance, values.constructionLicense]);

    return (
        <>
            <Card className='card_border'>
                <Card.Body>
                    <div className='col-6 p-3'>
                        <GenericCommonRadioField title='Technical Clearance Order' field='technicalClearanceOrder' options={options} isDisabled={isUpdate} />
                    </div>
                    {values.technicalClearanceOrder === true && (
                        <form>
                            <FieldArray name='technicalClearance'>
                                {({ insert, remove, push }) => (
                                    <div>
                                        {values.technicalClearance?.length > 0 &&
                                            values.technicalClearance.map((data: any, index_t: number) => (
                                                <div className='row' key={index_t}>
                                                    <div className='col-sm-12 collapsible_box align-items-center pb-2'>
                                                        <input type='checkbox' id={`technicalClearance.${index_t}`} name={`technicalClearance.${index_t}`} defaultChecked={true} />
                                                        <label htmlFor={`technicalClearance.${index_t}`} className='d-flex collapsible_box_label align-items-center justify-content-between'>
                                                            Technical Clearance - {index_t + 1}
                                                        </label>
                                                        <div className='form_fields'>
                                                            {index_t !== 0 && values?.isUpdatable && (
                                                                <div className='d-flex justify-content-end'>
                                                                    <img style={{ cursor: 'pointer', zIndex: 1 }} onClick={() => remove(index_t)} src={DeleteIcon} alt='delete' />
                                                                </div>
                                                            )}
                                                            {TECHNICAL_CLEARANCE_DETAILS.map((val: any, _: number) => (
                                                                <GenericFormCard fieldPath={`technicalClearance.${index_t}`} index={index_t} {...val} dropdownOptions={dropdownData} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        {values?.isUpdatable && (
                                            <button className='col-sm-12 mt-2 mb-4 d-flex justify-content-center align-items-center add_fields' type='button' onClick={() => push(TECHNICAL_CLEARANCE)}>
                                                + Add Technical Clearance details
                                            </button>
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </form>
                    )}
                </Card.Body>
            </Card>
            <Card className='card_border'>
                <Card.Body>
                    <div className='col-6 p-3'>
                        <GenericCommonRadioField title='Construction License Order' field='constructionLicenseOrder' options={options} isDisabled={isUpdate} />
                    </div>
                    {values.constructionLicenseOrder === true && (
                        <form>
                            <FieldArray name='constructionLicense'>
                                {({ insert, remove, push }) => (
                                    <div>
                                        {values.constructionLicense?.length > 0 &&
                                            values.constructionLicense.map((data: any, index_c: number) => (
                                                <div className='row' key={index_c}>
                                                    <div className='col-sm-12 collapsible_box align-items-center pb-2'>
                                                        <input type='checkbox' id={`constructionLicense.${index_c}`} name={`constructionLicense.${index_c}`} defaultChecked={true} />
                                                        <label htmlFor={`constructionLicense.${index_c}`} className='d-flex collapsible_box_label align-items-center justify-content-between'>
                                                            Construction License - {index_c + 1}
                                                        </label>
                                                        <div className='form_fields'>
                                                            {index_c !== 0 && values?.isUpdatable && (
                                                                <div className='d-flex justify-content-end'>
                                                                    <img style={{ cursor: 'pointer', zIndex: 1 }} onClick={() => remove(index_c)} src={DeleteIcon} alt='delete' />
                                                                </div>
                                                            )}
                                                            {CONSTRUCTION_LICENSE_DETAILS.map((val: any, _: number) => (
                                                                <GenericFormCard fieldPath={`constructionLicense.${index_c}`} index={index_c} {...val} dropdownOptions={dropdownData} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        {values?.isUpdatable && (
                                            <button
                                                className='col-sm-12 mt-2 mb-4 d-flex justify-content-center align-items-center add_fields'
                                                type='button'
                                                onClick={() => push(CONSTRUCTION_LICENSE)}
                                            >
                                                + Add Construction License details
                                            </button>
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </form>
                    )}
                </Card.Body>
            </Card>
            <Card className='card_border'>
                <Card.Body>
                    <div className='col-6 p-3'>
                        <GenericCommonRadioField title='Completion Order & Occupancy Certificate' field='coocOrder' options={options} isDisabled={isUpdate} />
                    </div>
                    {values.coocOrder === true && (
                        <form>
                            <FieldArray name='cooc'>
                                {({ remove, insert, push }) => (
                                    <div>
                                        {values.cooc?.length > 0 &&
                                            values.cooc.map((data: any, index: number) => (
                                                <div className='row' key={index}>
                                                    <div className='col-sm-12 collapsible_box align-items-center pb-2'>
                                                        <input type='checkbox' id={`cooc.${index}`} name={`cooc.${index}`} defaultChecked={true} />
                                                        <label htmlFor={`cooc.${index}`} className='d-flex collapsible_box_label align-items-center justify-content-between'>
                                                            Completion Order & Occupancy Certificate - {index + 1}
                                                        </label>
                                                        <div className='form_fields'>
                                                            {index !== 0 && values?.isUpdatable && (
                                                                <div className='d-flex justify-content-end'>
                                                                    <img style={{ cursor: 'pointer', zIndex: 1 }} onClick={() => remove(index)} src={DeleteIcon} alt='delete' />
                                                                </div>
                                                            )}
                                                            {CompletionOrder_OccypancyPercent.map((val: any, _: number) => (
                                                                <GenericFormCard fieldPath={`cooc.${index}`} index={index} {...val} dropdownOptions={dropdownData} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        {values?.isUpdatable && (
                                            <button className='col-sm-12 mt-2 mb-4 d-flex justify-content-center align-items-center add_fields' type='button' onClick={() => push(PUSH_CLOC)}>
                                                + Add Completion Order & Occupancy Certificate details
                                            </button>
                                        )}
                                    </div>
                                )}
                            </FieldArray>
                        </form>
                    )}
                </Card.Body>
            </Card>
        </>
    );
};

export default ConstructionLicenseDetails;
