import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';

import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import { ARCHITECT_CORRESPONDENCE } from '../ArchitectData';
import { CHECKLIST_TABLE } from '../../../EstateManagement/Allotment/Allotment.const';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { ARCHITECT_DOCS_DETAILS, LICENSEE_NEW_DETAILS, PERSONAL_DETAILS } from '../../UserManagement.const';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';

const ArchitectCorrespondence = ({ dropdownData }: any) => {
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userDesignationGM = getUserDetails.designation === 'GM';
    const [openSection, setOpenSection] = useState<string>('personalDetails');
    const [documents, setDocuments] = useState<any>([]);

    const { values, touched, errors, setFieldValue } = useFormikContext<any>();

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const renderpersonalDetails = () => {
        return (
            <div className='px-3'>
                {PERSONAL_DETAILS.map((val, id: number) => (
                    <GenericFormCard module='usermanagement' {...val} dropdownOptions={dropdownData} />
                ))}
            </div>
        );
    };

    const renderLicenseDetails = () => {
        return (
            <div className='px-3'>
                {LICENSEE_NEW_DETAILS.map((val: any) => (
                    <GenericFormCard module='usermanagement' {...val} dropdownOptions={dropdownData} />
                ))}
            </div>
        );
    };

    const renderDocuments = () => {
        return (
            <div className='px-3'>
                {ARCHITECT_DOCS_DETAILS.map(val => (
                    <GenericFormCard module='usermanagement' {...val} setDocuments={setDocuments} documents={documents} />
                ))}
            </div>
        );
    };
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {ARCHITECT_CORRESPONDENCE.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A - Personal Details' && openSection === 'personalDetails' && renderpersonalDetails()}
                                {val.title === 'B - License Details' && openSection === 'licenseDetails' && renderLicenseDetails()}
                                {val.title === 'C - Documents' && openSection === 'documents' && renderDocuments()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default ArchitectCorrespondence;
