import React, { useState } from 'react';
import { Button, Card, CardBody } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { getNavigateTo } from '../../utils/utils';
import GenericEmailIDNotification from '../../components/moduleCommonFormik/GenericEmailIDNotification.component';
import { InitiateConstSteps } from './ConstructionLicensePage.const';

interface EmailNotificationProps {
    formValues: { emailId: string }[];
    setFormValues: React.Dispatch<React.SetStateAction<{ emailId: string }[]>>;
    isEditable?: boolean;
}

export default function EmailNotification(props: EmailNotificationProps) {
    const getEmailIds = (emails: any) => {
        props.setFormValues(emails);
    };

    return (
        <>
            <Card className='card_border my-1'>
                <div className='mx-3 mt-4 mb-3 px-3 py-3'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-10'>
                                    <p className='font-inter-reg' style={{ fontWeight: 500, fontSize: '20px', lineHeight: '32px', letterSpacing: '-2%', color: '#45852E' }}>
                                        Notification Details
                                    </p>
                                </div>
                            </div>
                            <div className='col-12'>
                                <GenericEmailIDNotification getEmailIds={(data: any) => getEmailIds(data)} emailIdsData={props?.formValues} isEditable={props?.isEditable} />
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}
