import { useTranslation } from 'react-i18next';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { Card } from 'react-bootstrap';
import { DEED_REGISTRATION_DETAILS, DEED_TYPE, PLOT_DETAILS_TABLE_RD } from './LeaseDeed.const';
import { useEffect, useState } from 'react';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { FormikProvider, useFormik } from 'formik';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { cross, check, warning } from '../../../../utils/Logo';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { POST, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { REGISTERED_DEED_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { changeDateFormatToIST, currentTime, getFirstErrorKey } from '../../../../utils/utils';
import { LEASE_DEED } from '../../../../utils/constants';

export function RegisterDeeds() {
    const { t } = useTranslation();
    const [cardIndex, setCardIndex] = useState<number>(1);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dropdownData, setDropdownData] = useState<any>({});
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [selectedPlotData, setSelectedPlotData] = useState<any>({});
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    const [deedRowData, setDeedRowData] = useState<any>(null);
    const [filteredDeedRowData, setFilteredDeedRowData] = useState<any>(null);
    const location: any = useLocation();
    const deedSearchResponse = location?.state?.deedSearchResponse || null;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: REGISTERED_DEED_VALIDATION
    });
    const { values, setValues, setFieldTouched } = formik;
    const navigate = useNavigate();

    const onPlotSelect = (selectedPlot: any) => {
        console.log('selectedPlots', selectedPlot);
        setSelectedPlotDataId(selectedPlot.id);
        setSelectedPlotData(selectedPlot);
    };

    useEffect(() => {
        if (deedSearchResponse && deedRowData) {
            if (deedSearchResponse?.deedType === LEASE_DEED) {
                setValues({ ...values, deedType: { name: 'Lease Deed', code: 'LEASE_DEED' } });
                setFilteredDeedRowData(deedRowData.filter((item: any) => item.deedType === 'LEASE_DEED'));
            }
            onPlotSelect({ ...deedSearchResponse, id: deedSearchResponse?.plotId });
        }
    }, [deedSearchResponse, deedRowData]);

    const plotDetailsTableColumns = PLOT_DETAILS_TABLE_RD.map((val: any, index: number) => {
        if (index === 0) {
            return { ...val, onRadioClick: onPlotSelect, selectedValue: selectedPlotDataId };
        }
        return val;
    });

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const leaseDeedSearchResponse = await POST(endpoints.leaseDeedSearch + `?entityIds=${entityId}&status=EXECUTED`, { RequestInfo: requestInfo() });
                const leaseType = leaseDeedSearchResponse?.data?.deedDetails?.map((val: any) => val.deedType);
                const uniqueLeaseType = leaseType?.filter((val: any, index: number) => leaseType.indexOf(val) === index);
                const leaseDeedOptions: any = [];
                const leaseDeedType = uniqueLeaseType?.map((val: any) => {
                    if (val === 'LEASE_DEED') {
                        leaseDeedOptions.push({ name: 'Lease Deed', code: 'LEASE_DEED' });
                    } else if (val === 'TRIPARTITE_DEED') {
                        leaseDeedOptions.push({ name: 'Tripartite Deed', code: 'TRIPARTITE_DEED' });
                    } else if (val === 'ADDENDEM_OF_CHANGE' || val === 'TRANSFER_ADDENDUM') {
                        leaseDeedOptions.push({ name: 'Addendum', code: 'ADDENDUM' });
                    } else if (val === 'DEED_OF_SURRENDER') {
                        leaseDeedOptions.push({ name: 'Deed of Surrender', code: 'DEED_OF_SURRENDER' });
                    } else if (val === 'DEED_OF_EXTENSION') {
                        leaseDeedOptions.push({ name: 'Deed of Extension', code: 'DEED_OF_EXTENSION' });
                    }
                    return leaseDeedOptions;
                });
                setDropdownData({ deedType: leaseDeedOptions?.flat() });
                if (uniqueLeaseType?.length === 1) {
                    if (uniqueLeaseType[0] === 'LEASE_DEED') {
                        setValues({ ...values, deedType: { name: 'Lease Deed', code: 'LEASE_DEED' } });
                    } else if (uniqueLeaseType[0] === 'TRIPARTITE_DEED') {
                        setValues({ ...values, deedType: { name: 'Tripartite Deed', code: 'TRIPARTITE_DEED' } });
                    } else if (uniqueLeaseType[0] === 'ADDENDEM_OF_CHANGE' || uniqueLeaseType[0] === 'TRANSFER_ADDENDUM') {
                        setValues({ ...values, deedType: { name: 'Addendum', code: 'ADDENDUM' } });
                    } else if (uniqueLeaseType[0] === 'DEED_OF_SURRENDER') {
                        setValues({ ...values, deedType: { name: 'Deed of Surrender', code: 'DEED_OF_SURRENDER' } });
                    } else if (uniqueLeaseType[0] === 'DEED_OF_EXTENSION') {
                        setValues({ ...values, deedType: { name: 'Deed of Extension', code: 'DEED_OF_EXTENSION' } });
                    }
                }
                let tempRowData: any = [];
                leaseDeedSearchResponse?.data?.deedDetails?.map((val: any) => {
                    let tempRow: any = {
                        ...val,
                        plotNo: val.plotDetails?.plotNo,
                        phase: val.plotDetails?.phase,
                        estateName: val.plotDetails?.estateDetails.estateName,
                        deedExecutionDate: val.deedExecutionDate?.split(' ')[0],
                        id: val.plotDetails?.uuid
                    };
                    tempRowData.push(tempRow);
                });
                setDeedRowData(tempRowData);
                console.log('leaseDeedOptions', leaseDeedOptions);
                leaseDeedOptions.length === 1 && setFilteredDeedRowData(tempRowData);
                setIsLoading(false);
            } catch (err: any) {
                setIsLoading(false);
                let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    const customHandleChange = (e: any, field: string) => {
        if (field === 'deedType') {
            if (e.value === 'LEASE_DEED') {
                setFilteredDeedRowData(deedRowData.filter((item: any) => item.deedType === 'LEASE_DEED'));
            } else if (e.value === 'TRIPARTITE_DEED') {
                setFilteredDeedRowData(deedRowData.filter((item: any) => item.deedType === 'TRIPARTITE_DEED'));
            } else if (e.value === 'ADDENDUM') {
                setFilteredDeedRowData(deedRowData.filter((item: any) => item.deedType === 'ADDENDEM_OF_CHANGE' || item.deedType === 'TRANSFER_ADDENDUM'));
            } else if (e.value === 'DEED_OF_SURRENDER') {
                setFilteredDeedRowData(deedRowData.filter((item: any) => item.deedType === 'DEED_OF_SURRENDER'));
            } else if (e.value === 'DEED_OF_EXTENSION') {
                setFilteredDeedRowData(deedRowData.filter((item: any) => item.deedType === 'DEED_OF_EXTENSION'));
            } else if (e.label === 'Please Select') {
                setFilteredDeedRowData([]);
            } else {
                setFilteredDeedRowData(deedRowData);
            }
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/enterprisedashboard');
    };

    const onClickSubmit = async () => {
        const { applicationStatus, ...updatedSelectedPlotData } = selectedPlotData;
        let payload = {
            RequestInfo: requestInfo(),
            ...updatedSelectedPlotData,
            deedRegisterDate: values.deedRegistrationDate && changeDateFormatToIST(values.deedRegistrationDate) + ' ' + currentTime(),
            registeredIndentureRefId: values?.copyOfRegisteredDeed?.docReferenceId && values?.copyOfRegisteredDeed?.docReferenceId,
            applicationStatus: applicationStatus
        };

        try {
            await REGISTERED_DEED_VALIDATION.validate(values, { abortEarly: false });
            try {
                if (selectedPlotData) {
                    setIsLoading(true);
                    await POST(endpoints.leaseDeedUpdate, payload);
                    setIsLoading(false);
                    setModalDetails({ show: true, title: 'Success', para: 'Indenture registered successfully', img: check, type: 'success', reUpload: 'OK' });
                } else {
                    setModalDetails({ show: true, title: 'Error!', para: 'Please select plots!', img: cross, type: 'error', reUpload: 'OK' });
                }
            } catch (err: any) {
                setIsLoading(false);
                let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (err: any) {
            let errorField = getFirstErrorKey(err);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('values', values);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={t('REGISTER_INDENTURES', 'Register Indentures')} />
                        <div className='col-12 form-section'>
                            <FormikProvider value={formik}>
                                <Card className='card_border'>
                                    <Card.Body>
                                        {DEED_TYPE.map((val: any) => (
                                            <GenericFormCard {...val} dropdownOptions={dropdownData} customHandleChange={customHandleChange} />
                                        ))}
                                    </Card.Body>
                                    <Card.Body>
                                        <div className='formTitle m-0 ps-2 py-2 d-flex justify-content-between'>{t('INDENTURE_DETAILS', 'Indenture Details')}</div>
                                        <GenericActionTable columns={plotDetailsTableColumns} hideSearchBar={true} hideExportBtn={true} rowData={filteredDeedRowData} />
                                    </Card.Body>
                                    <Card.Body>
                                        <div className='formTitle m-0 ps-2 py-2 d-flex justify-content-between'>{t('INDENTURE_REGISTRATION_DETAILS', 'Deed Registration details')}</div>
                                        {DEED_REGISTRATION_DETAILS.map((val: any) => (
                                            <GenericFormCard {...val} />
                                        ))}
                                    </Card.Body>
                                </Card>
                            </FormikProvider>
                        </div>
                    </div>
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                            <GenericButtons text={t('SUBMIT', 'Submit')} variant='primary' handleClick={onClickSubmit} />
                        </div>
                    </footer>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                </>
            )}
        </>
    );
}
