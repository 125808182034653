import React, { useEffect, useState } from 'react';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import NitCorrespondence from './NitCorrespondence';
import { Card, Form } from 'react-bootstrap';
import { tenderDetails } from './NITscreen.const';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { getHistory, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../../EstateManagement/EstateManagement.controller';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { check, cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import { NIT_SCREEN_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { convertDateTimeToISOFormate, getFirstErrorKey } from '../../../../utils/utils';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../EstateManagement/LeaseDeed/LeaseDeed.const';
import { EsignModal } from '../../../commonPages/EsignModal';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';

const NitWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [content, setContent] = useState('');
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [tabelData, setTabelData] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [resData, setResData] = useState<any>(null);
    const [estCost, setEstCost] = useState<number>(0);
    const [dropdownValue, setDropdownValue] = useState(null);
    const [modalData, setModalData] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [reminderPopUp, setReminderPopUp] = useState<boolean>(false);
    const [refId, setRefId] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [dprTableData, setDprTableData] = useState<any>([]);
    const [tenderType, setTenderType] = useState<any>(null);
    const [openSection, setOpenSection] = useState<string>('dprDetails');
    const [eSignTry, setESignTry] = useState<boolean>(false);

    const totalPages = 3;
    const reqInfo = { RequestInfo: requestInfo() };

    const { t } = useTranslation();
    const location: any = useLocation();
    const navigate = useNavigate();
    const tenderResData = location?.state?.tenderDetails;
    const dprDetailsTableData = location?.state?.dprDetailsTableData;
    // const tenderType = location?.state?.tenderType;
    let user = localStorage.getItem('currentUserAssignment');
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userUuid = getUserDetails?.uuid;
    const [searchParams] = useSearchParams();
    const applicationNumber: any = searchParams.get('applicationNumber');
    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const action: any = searchParams.get('action');
    const eSignTried: boolean = JSON.parse(searchParams.get('eSignTried') || 'false');
    const success: boolean = JSON.parse(searchParams.get('success') || 'true');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: NIT_SCREEN_VALIDATION
    });
    const { values, setValues, errors, setFieldTouched, setFieldValue, setTouched } = formik;

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);

                const dropdownOption: any = await dprDropdownDataOptions();

                const { data } = await POST(endpoints.dprValidActions + `?businessId=${applicationNumber || tenderResData?.applicationNo}&tenantId=ga`, {
                    RequestInfo: requestInfo()
                });
                setNextValidAction(data);
                setFieldValue('isUpdatable', isViewOnly ? !isViewOnly : data?.isUpdatable);

                if (data) {
                    await getCorrespondenceDocument(data);
                }

                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);

                if (dropdownOption && (applicationNumber || tenderResData?.applicationNo) && !resData) {
                    const { data } = await POST(endpoints.nitSearch + `?applicationNos=${applicationNumber || tenderResData?.applicationNo}`, reqInfo);
                    setResData(data?.worksTenderDetails[0]);
                    setTenderType(data?.worksTenderDetails[0]?.tenderType);

                    const fieldsInitialValues = await tenderDetails(
                        data?.worksTenderDetails[0],
                        dropdownOption,
                        applicationNumber || tenderResData?.applicationNo || data?.worksTenderDetails[0]?.applicationNo
                    );
                    setValues((prevVal: any) => ({ ...prevVal, ...fieldsInitialValues, tenderType: data?.worksTenderDetails[0]?.tenderType }));
                    setEstCost(data?.worksTenderDetails[0]?.dprHeaderDetails?.estimatedTotalCost);
                    setRefId(data?.worksTenderDetails[0]?.docReferenceId);
                    setContent(data?.worksTenderDetails[0]?.content);

                    const tempTableData = data?.worksTenderDetails?.[0]?.dprHeaderDetails?.map((val: any, index: number) => {
                        const projectType = getDropdownSelectedObject(val?.projectType, dropdownOption['projectType'] || []);
                        return {
                            dprNo: val?.dprNo,
                            projectName: val?.projectName,
                            industrialName: val?.estateMasterDetails?.estateName,
                            projectType: projectType?.name,
                            estimateAmount: val?.estimatedTotalCost?.toFixed(2),
                            id: val?.uuid,
                            emdValue: val?.worksDprTenderMappingDetails?.emdCost,
                            costOfTender: val?.worksDprTenderMappingDetails?.totalTenderFormCost,
                            serialNo: index + 1
                        };
                    });

                    setDprTableData(tempTableData);
                    setRefId(data?.worksTenderDetails[0]?.docReferenceId);

                    if (data?.worksTenderDetails[0]) {
                        setDropdownValue(dropdownOption);
                    }
                }

                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    useEffect(() => {
        if (note && action && nextValidAction && resData) {
            setFieldValue('notes', note);
            if (eSignTried && !success && !eSignTry) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('E_SIGN_FAILED', 'e-Sign Failed'), img: cross, type: 'error', reUpload: 'OK' });
            } else if (success) {
                handlePrimaryAction({ action: action }, note);
            }
        }
    }, [nextValidAction, resData]);

    const signDocument = () => {
        const eSignParam = new URLSearchParams(searchParams);
        eSignParam.delete('note');
        eSignParam.delete('action');
        eSignParam.delete('eSignTried');
        eSignParam.delete('signedFileStoreId');
        eSignParam.delete('success');
        let url = window.location.origin + `/goa-idc/notice-inviting-tender?${eSignParam.toString()}&note=${values.notes}&action=${actionAfterEsign}&eSignTried=true`;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
    };

    const getCorrespondenceDocument = async (nextAction?: any) => {
        try {
            setIsLoading(true);
            const { data } = await POST(
                endpoints.worksCorrespondenceDocument +
                    `?businessId=${applicationNumber || tenderResData?.applicationNo}&businessService=${nextAction !== undefined ? nextAction?.businessService : 'TENDER'}`,
                {
                    RequestInfo: requestInfo()
                }
            );
            setDocumentDetails(data?.documents);
            if (nextAction !== undefined) {
                const relatedApplicationDocument = await POST(endpoints.worksRelatedDocument + `?applicationNos=${applicationNumber || tenderResData?.applicationNo}`, { RequestInfo: requestInfo() });
                setRelatedApplicationDocument(relatedApplicationDocument);
            }
            setIsLoading(false);
        } catch (e: any) {
            console.log(e);
            setIsLoading(false);
            let errMsg = e?.response?.data?.errorMessage || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const commonApiCall = async (endpoint: string, payload: any) => {
        try {
            const { data } = await POST(endpoint, payload);
            return data;
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const payload = {
        worksTenderDetailsModel: {
            ...resData,
            ...tenderResData,
            content: content,
            bidOpenStartDate: convertDateTimeToISOFormate(values?.bidOpenStartDate ? values?.bidOpenStartDate : resData?.bidOpenStartDate),
            onlineApplicationLastDate: convertDateTimeToISOFormate(values?.lastDateOfOnlineApplication ? values?.lastDateOfOnlineApplication : resData?.onlineApplicationLastDate),
            tenderDocumentAvailabilityDate: convertDateTimeToISOFormate(values?.tenderDocumentAvailabilityDate ? values?.tenderDocumentAvailabilityDate : resData?.tenderDocumentAvailabilityDate),
            tenderSubmissionEndDate: convertDateTimeToISOFormate(values?.submissionEndDate ? values?.submissionEndDate : resData?.tenderSubmissionEndDate),
            publicationDate: convertDateTimeToISOFormate(values?.publicationDate ? values?.publicationDate : resData?.publicationDate),
            docReferenceId: signedFileStoreId ? signedFileStoreId : resData?.docReferenceId,
            dprNumbers: resData?.dprHeaderDetails?.map((val: any) => val?.dprNo)
        }
    };

    const onUpdateNit = async (isEsignUpdate?: boolean) => {
        const updatePayload = {
            ...reqInfo,
            worksTenderDetailsModel: { ...payload.worksTenderDetailsModel, isTsDocUpdateRequired: isEsignUpdate ? isEsignUpdate : false, isDocumentRequired: true }
        };
        try {
            await NIT_SCREEN_VALIDATION.validate(values, { abortEarly: false, context: values });

            const response: any = await commonApiCall(endpoints.nitUpdate, updatePayload);
            if (response) {
                setResData(response?.worksTenderDetails[0]);
                setTenderType(response?.worksTenderDetails[0]?.tenderType);
                setRefId(response?.worksTenderDetails[0]?.docReferenceId);
                isEsignUpdate && setESignTry(true);
                !isEsignUpdate && setModalDetails({ show: true, title: 'Success', para: 'Data Saved Successfully', img: check, type: 'save success', reUpload: 'OK' });
                setContent(response?.worksTenderDetails?.[0]?.content);
                setTouched({});
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const primaryAction = async () => {
        await onUpdateNit();
    };

    const handlePrimaryAction = async (status: any, comment: string) => {
        const initiatePayload = {
            ...reqInfo,
            worksTenderDetailsRequest: { ...payload },
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber ? applicationNumber : tenderResData?.applicationNo,
                    moduleName: nextValidAction?.moduleName,
                    action: action ? action : status?.action,
                    notes: values.notes || note,
                    comment: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean)
        };
        if (!values.notes && !note) {
            setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if ((!signedFileStoreId && status.isEsignRequired) || !success) {
            await onUpdateNit(status.isEsignRequired);
            setActionAfterEsign(status.action);
            setEsignModal(true);
            return;
        }
        try {
            await NIT_SCREEN_VALIDATION.validate(values, { abortEarly: false, context: values });
            const response = await commonApiCall(endpoints.worksWorkflowInitiate, initiatePayload);
            if (response) {
                const successMessage = getSuccessMessage(action ? action : nextValidAction?.nextValidAction[0]?.action, applicationNumber);
                setModalDetails({ show: true, title: 'Success', para: successMessage, img: check, type: 'success', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            setOpenSection('tenderDetails');
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any =
                file.referenceId &&
                (await onAttachDocument(
                    applicationNumber ? applicationNumber : tenderResData?.applicationNo,
                    file?.referenceId,
                    designationName,
                    nextValidAction?.businessService,
                    historyDetails?.length ? historyDetails[0].id : null,
                    true
                ));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    useEffect(() => {
        try {
            const tableData = async () => {
                const responseTableData = documentDetails.length
                    ? documentDetails?.map((data: any, ind: number) => {
                          return { ...data, serialNo: `C${documentDetails.length - ind}` };
                      })
                    : [];
                setTabelData(responseTableData);
            };
            if (activeIndex === 2 || !!isViewOnly) {
                tableData();
            }
        } catch (e: any) {
            console.log(e);
        }
    }, [documentDetails, activeIndex]);

    const handleNextPage = async () => {
        try {
            const { data } = await POST(
                endpoints.worksCorrespondenceDocument +
                    `?businessId=${applicationNumber || tenderResData?.applicationNo}&businessService=${nextValidAction !== undefined ? nextValidAction?.businessService : 'TENDER'}`,
                {
                    RequestInfo: requestInfo()
                }
            );
            setDocumentDetails(data?.documents);
        } catch (e) {
            console.log(e);
        }
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };
    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };
    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };
    console.log('formik', formik);
    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={
                                isViewOnly
                                    ? t('NIT_VIEW_HEADING', 'Notice Inviting Tender (NIT) View')
                                    : tenderType === 'NORMAL'
                                    ? t('NIT_HEADING', 'Notice Inviting Tender (NIT)')
                                    : tenderType === 'SHORT'
                                    ? t('SHORT_NIT_HEADING', 'Notice Inviting Short Tender (NIT)')
                                    : ''
                            }
                            additionalClass='sub-text-style'
                            bottomSubText={`Type : ${tenderType === 'NORMAL' ? `${t('TENDER', 'Tender')}` : `${t('SHORT_TENDER', 'Short Tender')}`}, No : ${
                                applicationNumber ? applicationNumber : tenderResData?.applicationNo
                            } `}
                        />

                        <FormikProvider value={formik}>
                            {!isViewOnly ? (
                                <>
                                    <div className='col-sm-3 mt-3 stickyCard-section '>
                                        <div className='stickyCard' style={{ top: '15%' }}>
                                            <GenericHeader
                                                text={
                                                    isViewOnly
                                                        ? t('NIT_VIEW_HEADING', 'Notice Inviting Tender (NIT) View')
                                                        : tenderType === 'NORMAL'
                                                        ? t('NIT_HEADING', 'Notice Inviting Tender (NIT)')
                                                        : tenderType === 'SHORT'
                                                        ? t('SHORT_NIT_HEADING', 'Notice Inviting Short Tender (NIT)')
                                                        : ''
                                                }
                                                sideHeader={true}
                                                additionalClass='sub-text-style'
                                                bottomSubText={`${isViewOnly ? '' : `Type : ${tenderType === 'NORMAL' ? `${t('TENDER', 'Tender')}` : `${t('SHORT_TENDER', 'Short Tender')}`} ,`} No : ${
                                                    applicationNumber ? applicationNumber : tenderResData?.applicationNo
                                                } `}
                                            />

                                            <NotingWrapper
                                                designation={designation}
                                                applicationNumber={applicationNumber}
                                                history={historyDetails}
                                                // disable={!nextValidAction?.nextValidAction?.length}
                                                handleOnFileUpload={handleOnFileUpload}
                                                nextValidAction={nextValidAction}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            <div className={`${!isViewOnly ? 'col-sm-9' : 'col-sm-12'} mt-3 form-section `}>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} isViewOnly={isViewOnly} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <NitCorrespondence
                                                dprDetailsTableData={dprTableData}
                                                content={content}
                                                setContent={setContent}
                                                tenderData={tenderResData}
                                                readOnly={!nextValidAction?.isUpdatable || isViewOnly}
                                                setModalDetails={setModalDetails}
                                                t={t}
                                                responseData={resData}
                                                setReminderPopUp={setReminderPopUp}
                                                isViewOnly={isViewOnly}
                                                tableData={tabelData}
                                                onRowClick={onRowClick}
                                                tenderType={tenderType}
                                                openSection={openSection}
                                                setOpenSection={setOpenSection}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                        {activeIndex === 3 && (
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            {isViewOnly ? (
                                <></>
                            ) : (
                                <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                    <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                        <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                    </span>
                                    Page {activeIndex} of {totalPages}
                                    <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                        <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                    </span>
                                </div>
                            )}
                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    {!isViewOnly ? (
                        <GenericFooterComponent
                            activeIndex={0}
                            stepperLength={0}
                            handleOnPrimaryAction={handlePrimaryAction}
                            onSubmit={() => {}}
                            nextValidAction={nextValidAction}
                            extraButton={applicationNumber ? false : 'Save'}
                            extraButtonAction={primaryAction}
                        />
                    ) : (
                        <></>
                    )}
                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                    <GenericPopupModal
                        secondaryAction={() => {
                            setReminderPopUp(false);
                        }}
                        primaryAction={() => {
                            setReminderPopUp(false);
                            primaryAction();
                        }}
                        isVisible={reminderPopUp}
                        title='Save Confirmation'
                        size='md'
                        children={
                            <div>
                                <div className='mx-4 my-3'>
                                    <div className='mt-4'>
                                        <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                            {t('SAVE_CHANGES', 'Looks like you have some unsaved changes. Would you like to save them before proceeding with the new data?')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        primaryBtnTitle='Yes'
                        secondaryBtnTitle='No'
                        // onClose={() => setReminderPopUp(false)}
                    />
                </>
            )}
        </>
    );
};

export default NitWorkflow;
// http://20.0.129.245:8080/idc-works-service/works/workflow/_action?businessId=TENDER/2024-25/00136&tenantId=ga&amount=0
