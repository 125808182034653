import _ from 'lodash';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';

export const LEASE_DEED_APPLICATION = [
    {
        formData: [
            {
                title: 'User Name',
                titleCode: 'USER_NAME',
                field: 'allotteeName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Email ID',
                tileCode: 'EMAIL_ID',
                field: 'emailID',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Phone No',
                tileCode: 'PHONE_NO',
                field: 'phoneNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                isDisabled: true
            }
        ]
    },
    {
        title: 'Address of the User',
        titleCode: 'ADDRESS_OF_USER',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                field: 'pinCode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const LEASE_DEED_CORRESPONDENCE_CARD = [
    {
        title: 'A - User Details',
        field: 'userDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Indenture Notes',
        field: 'indentureNotes'
    }
    // {
    //     title: 'D - Notification Details',
    //     field: 'notificationDetails'
    // }
];

export const REGISTERED_INDENTURE_CORRESPONDENCE_CARD = [
    {
        title: 'A - User Details',
        field: 'userDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Indenture Details',
        field: 'indentureDetails'
    }
];

export const CORRESPONDENCE_TABLE = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Uploaded By',
        fieldName: 'uploadedUserRole',
        clsName: 'col-sm-3 column-text-start',
        id: 2
    },
    {
        colName: ' Date & Time',
        fieldName: 'uploadedDate',
        clsName: 'col-sm-4 column-text-start',
        id: 3
    },
    {
        colName: ' Document',
        fieldName: 'docFileName',
        clsName: 'col-sm-4 column-text-start',
        isClickable: true,
        id: 4
    }
];

export const CHECKLIST_TABLE = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Condition',
        fieldName: 'checklistMessage',
        clsName: 'col-sm-7',
        id: 2
    },
    {
        colName: 'Status',
        fieldName: 'statusDisplayName',
        clsName: 'col-sm-4',
        id: 3
    }
];

export const PLOT_DETAILS_TABLE_LD = [
    {
        colName: '',
        fieldName: 'radio',
        clsName: 'col-sm-1 column-text-start',
        id: 1
    },
    {
        colName: 'Plot No',
        fieldName: 'plotNo',
        clsName: 'col-sm-2 column-text-start',
        id: 2
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col-sm-2 column-text-start',
        id: 3
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateName',
        clsName: 'col-sm-2 column-text-start',
        id: 4
    },
    {
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotArea',
        clsName: 'col-sm-2 column-text-start',
        id: 5
    },
    {
        colName: 'Allotment Date',
        fieldName: 'plotAllotmentDate',
        clsName: 'col-sm-3 column-text-start',
        id: 6
    }
];

export const REGISTER_DEED_STEPS = [
    {
        titleCode: 'LEASE_DEED',
        name: 'Lease Deed',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'TRIPARTITE_DEED',
        name: 'Tripartite Deed',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'ADDENDUM',
        name: 'Addendum',
        index: 3,
        isActive: true
    },
    {
        titleCode: 'DEED_OF_SURRENDER',
        name: 'Deed of Surrender',
        index: 4,
        isActive: true
    },
    {
        titleCode: 'DEED_OF_EXTENSION',
        name: 'Deed of Extension',
        index: 5,
        isActive: true
    }
];

export const DEED_REGISTRATION_DETAILS = [
    {
        formData: [
            {
                titleCode: 'INDENTURE_REGISTRATION_DATE',
                title: 'Indenture Registration Date',
                field: 'deedRegistrationDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                titleCode: 'COPY_OF_REGISTERED_INDENTURE',
                title: 'Copy of Registered Indenture',
                field: 'copyOfRegisteredDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'Registered_Indenture',
                    documentType: '',
                    referenceType: ''
                }
            }
        ]
    }
];

export const DEED_TYPE = [
    {
        formData: [
            {
                titleCode: 'TYPE',
                title: 'Type',
                field: 'deedType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            }
        ]
    }
];

export const CORRESPONDENCE_TABLE_APP_FORM = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Application Type',
        fieldName: 'applicationType',
        clsName: 'col-sm-5 column-text-start',
        id: 2
    },
    {
        colName: 'Application No.',
        fieldName: 'applicationNo',
        clsName: 'col-sm-4 column-text-start',
        isClickable: true,
        id: 3
    }
];

export const EXECUTED_DEED = [
    {
        formData: [
            {
                titleCode: 'EXECUTED_INDENTURE_DATE',
                title: 'Executed Indenture Date',
                field: 'executedLeaseDeedDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                titleCode: 'SIGNED_INDENTURE',
                title: 'Signed Indenture',
                field: 'signedLeaseDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'Signed_Indenture',
                    documentType: '',
                    referenceType: ''
                }
            }
        ]
    }
];

export const PLOT_DETAILS_TABLE_RD = [
    {
        colName: '',
        fieldName: 'radio',
        clsName: 'col-sm-1 column-text-start'
    },
    {
        colName: 'Plot No',
        fieldName: 'plotNo',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col-sm-3 column-text-start'
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateName',
        clsName: 'col-sm-3 column-text-start'
    },
    {
        colName: 'Indenture Execution  Date',
        fieldName: 'deedExecutionDate',
        clsName: 'col-sm-3 column-text-start'
    }
];

export const INDENTURE_TABLE = [
    {
        colName: 'SN',
        // titleCode: 'SERIAL_NO',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Indenture',
        // titleCode: 'INDENTURE',
        fieldName: 'indenture',
        clsName: 'col-sm-3 column-text-start',
        id: 2,
        isClickable: true
    },
    {
        colName: 'Version',
        // titleCode: 'VERSION',
        fieldName: 'version',
        clsName: 'col-sm-2 column-text-start',
        id: 3
    },
    // {
    //     colName: 'Remarks',
    //     // titleCode: 'REMARKS',
    //     fieldName: 'remarks',
    //     clsName: 'col-sm-3 column-text-start',
    //     id: 4
    // },
    {
        colName: 'Uploaded By',
        // titleCode: 'UPLOADED_BY',
        fieldName: 'uploadedUserRole',
        clsName: 'col-sm-3 column-text-start',
        id: 5
    },
    {
        colName: 'Uploaded Date - Time',
        // titleCode: 'UPLOADED_DATE_TIME',
        fieldName: 'uploadedDateTime',
        clsName: 'col-sm-3 column-text-start',
        id: 6
    }
];

export const INDENTURE_UPLOADS = [
    {
        formData: [
            {
                title: 'Indenture',
                titleCode: 'INDENTURE',
                field: 'indenture',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Indenture',
                    documentType: '',
                    referenceType: ''
                },
                fileTypes: ['doc', 'docx']
            }
            // {
            //     title: 'Remarks',
            //     titleCode: 'REMARKS',
            //     field: 'remarks',
            //     type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD
            // }
        ]
    }
];

export const INDENTURE_DETAILS = [
    {
        formData: [
            {
                titleCode: 'EXECUTED_INDENTURE_DATE',
                title: 'Executed Indenture Date',
                field: 'executedIndentureDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'EXECUTED_INDENTURE',
                title: 'Executed Indenture',
                field: 'executedIndenture',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'REGISTERED_INDENTURE_DATE',
                title: 'Registered Indenture Date',
                field: 'registeredIndentureDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'REGISTERED_INDENTURE',
                title: 'Registered Indenture',
                field: 'registeredIndenture',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const INDENTURE_APPLICATION_DETAILS = [
    {
        formData: [
            {
                titleCode: 'EXECUTED_INDENTURE_DATE',
                title: 'Executed Indenture Date',
                field: 'executedIndentureDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                renderCondition: (values: any) => _.get(values, `executedIndentureDate`) !== ''
            },
            {
                titleCode: 'EXECUTED_INDENTURE',
                title: 'Executed Indenture',
                field: 'executedIndenture',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true,
                renderCondition: (values: any) => _.get(values, `executedIndenture`) !== ''
            },
            {
                titleCode: 'REGISTERED_INDENTURE_DATE',
                title: 'Registered Indenture Date',
                field: 'registeredIndentureDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                renderCondition: (values: any) => _.get(values, `registeredIndentureDate`) !== ''
            },
            {
                titleCode: 'REGISTERED_INDENTURE',
                title: 'Registered Indenture',
                field: 'registeredIndenture',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true,
                renderCondition: (values: any) => _.get(values, `registeredIndenture`) !== ''
            }
        ]
    }
];
