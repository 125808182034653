import React, { useEffect, useState } from 'react';
import PopUps from '../../components/PopUps';
import HeaderComponent from '../../components/header/Header.component';
import Loading from '../../components/portalComponents/Loading.component';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { Card, Form } from 'react-bootstrap';
import SideCard from '../../components/moduleCommonFormik/SideCardGenericComponent';
import { MORTGAGE_STEPS_DETAILS } from '../modules/Mortgage Lessee/Mortgage.const';
import {
    GRIEVANCE_STEPS_DETAILS,
    QUERY_DETAILS,
    QUERY_DETAILS_SECOND,
    QUERY_RESPONSE_SECOND,
    QUERY_STEPS_DETAILS,
    QUERY_TABLE_COLUMNS,
    setQueryApplicationInitValue,
    setQueryInitValue
} from './Query.const';
import { FormikProvider, useFormik } from 'formik';
import { dropdownDataOptions } from '../modules/Migration/Migration.controller';
import ScrollToTop from '../../components/ScrollToTop';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { useTranslation } from 'react-i18next';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { PLOT_DETAILS_TABLE } from '../modules/EstateManagement/SubLease/SubLeaseConst';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import { endpoints, estateEndpoints, getProgressBarData, POST, requestInfo } from '../../services/modulesServices/userManagement.api';
import { OwnershipTable } from '../modules/UserManagement/EnterpriseModule/OwnershipTable';
import GenericPopupModal from '../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../components/fileviewer/GenericPDFViewer';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PaymentSubmissionStatus from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../components/moduleCommonFormik/ProgressBar';
import { QUERY_ENTRY_VALIDATION } from '../../utils/formikValidation/FormikValidation';
import { getFirstErrorKey } from '../../utils/utils';
import { check, cross } from '../../utils/Logo';
import { toast } from 'react-toastify';
import { CONSTANT_DATA_FIELD } from '../../utils/constants';
import { entityDocumentData } from '../modules/EstateManagement/LeaseDeed/LeaseDeed.controller';
import { getDropdownSelectedObject } from '../modules/UserManagement/UserMangement.controller';

const QueryEntry = () => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [tableRowData, setTableRowData] = useState([]);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [tabelData, setTabelData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [queryData, setQueryData] = useState<any>(null);

    const location: any = useLocation();
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    const applicationNumber: any = searchParams.get('applicationNumber');
    const grievanceRedressal: any = searchParams.get('grievanceRedressal');

    const formik = useFormik<any>({
        initialValues: { isUpdatable: true },
        onSubmit: (value: any) => {},
        validationSchema: QUERY_ENTRY_VALIDATION
    });
    const { values, setValues, setFieldTouched, errors, setFieldValue } = formik;
    const onPlotSelect = (selectedPlot: any) => {
        setSelectedPlotDataId(selectedPlot.uuid);
        setValues((prev: any) => ({ ...prev, plotUuid: selectedPlot.uuid }));
    };

    // setting plot columns with funtion
    const plotDetailsTableColumns = PLOT_DETAILS_TABLE.map((val: any, index: number) => {
        if (index === 0) {
            return {
                ...val,
                onRadioClick: onPlotSelect,
                selectedValue: selectedPlotDataId
            };
        }
        return val;
    });

    useEffect(() => {
        dropdownDataOptions()
            .then((dropdownOption: any) => {
                setDropdownData(dropdownOption);
            })
            .catch(error => {});
    }, []);

    useEffect(() => {
        const setValuesOfQueryType = async () => {
            if (!grievanceRedressal) {
                setValues((prev: any) => ({
                    ...prev,
                    queryType: {
                        code: 'GENERAL',
                        name: 'General'
                    }
                }));
            }
        };
        setValuesOfQueryType();
    }, []);

    useEffect(() => {
        const getPlotData = async () => {
            try {
                if (!applicationNumber) {
                    setIsLoading(true);
                    const { data } = await POST(`/idc-estate-service/entity-reference-details/_search?entityIds=${entityId}&types=${'LESSEE'}`, { RequestInfo: requestInfo() });
                    if (data?.entityRefDetails.length > 0) {
                        let getPlotIds = data?.entityRefDetails.map((data: any) => data?.plotId);
                        const plotIds = getPlotIds.join(',');
                        if (!getPlotIds) return;

                        const { data: plotData } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                        const plotRowData = plotData?.plots?.map((value: any) => ({
                            estateName: value?.estateDetails?.estateName,
                            plotType: value?.plotType,
                            phase: value?.phase,
                            plotNo: value?.plotNo,
                            plotAreaSqm: value?.plotAreaSqm,
                            uuid: value?.uuid,
                            id: value?.uuid
                        }));
                        setTableRowData(plotRowData);
                    }
                }
            } catch (e: any) {}
            setIsLoading(false);
        };
        getPlotData();
    }, []);

    const onChangePrevIndex = () => {
        const stepperLength = MORTGAGE_STEPS_DETAILS.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    useEffect(() => {
        const setFileDataInTable = values?.attachment?.map((val: any) => {
            return {
                dateAndTime: val?.uploadedDate,
                documentName: val?.documentName,
                rowId: val?.docReferenceId
            };
        });
        setTabelData(setFileDataInTable);
    }, [values?.attachment]);

    const deleteTableRow = (rowId: any) => {
        try {
            const updatedData = tabelData.filter((value: any) => value?.rowId !== rowId);
            const updatedAttachmentValue = values?.attachment?.filter((value: any) => value?.docReferenceId !== rowId);
            setTabelData(updatedData);
            setValues((prev: any) => ({ ...prev, attachment: updatedAttachmentValue }));
        } catch (e) {}
    };
    const onView = (status: boolean, data: any) => {
        try {
            setIsFilePreviewModal(!isFilePreviewModal);
            setModalData(data);
        } catch (e) {}
    };

    const tableColumn = !applicationNumber
        ? [
              ...QUERY_TABLE_COLUMNS,
              {
                  colName: 'Action',
                  type: 'action',
                  clsName: 'fixed-action-column fixed-action-column-bg',
                  actions: ['view', 'delete']
              }
          ]
        : QUERY_TABLE_COLUMNS;

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.searchQueryEntry + `?queryNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    if (data) {
                        setSelectedPlotDataId(data?.queryDetails[0]?.plotId);
                        setQueryData(data?.queryDetails[0]);
                        await setApplicationResponseData(data?.queryDetails[0]);
                        await setTableDocData(data?.queryDetails[0]?.queryDocDetails);
                        const { data: plotData } = await POST(estateEndpoints.plotSearch + `?plotUuids=${data?.queryDetails[0]?.plotId}`, { RequestInfo: requestInfo() });

                        const plotRowData = plotData?.plots?.map((value: any) => ({
                            estateName: value?.estateDetails?.estateName,
                            plotType: value?.plotType,
                            phase: value?.phase,
                            plotNo: value?.plotNo,
                            plotAreaSqm: value?.plotAreaSqm,
                            uuid: value?.uuid,
                            id: value?.uuid
                        }));
                        setTableRowData(plotRowData);
                    }
                    setIsLoading(false);
                } catch (e: any) {
                    console.log(e);
                    setIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || 'Something Went Wrong';
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [location.search, dropdownData]);

    const setApplicationResponseData = async (queryResponse: any) => {
        try {
            if (queryResponse) {
                const queryInitValue = await setQueryApplicationInitValue(queryResponse, dropdownData);
                setValues((prev: any) => ({
                    ...prev,
                    ...queryInitValue
                }));
            }
        } catch (e) {}
    };
    const setTableDocData = async (docData: any) => {
        const filteredDocData = docData.filter((val: any) => val?.docType === 'QUERY');
        const tableRowData = await Promise.all(
            filteredDocData.map(async (val: any) => {
                const docDetails = val?.docRefId && (await entityDocumentData(val?.docRefId));
                return {
                    documentName: docDetails?.[0]?.documentName,
                    dateAndTime: docDetails?.[0]?.uploadedDate,
                    rowId: docDetails?.[0]?.docReferenceId
                };
            })
        );
        setTabelData(tableRowData);
    };
    useEffect(() => {
        if (!!applicationNumber)
            getProgressBarData(applicationNumber)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
    }, [applicationNumber, dropdownData]);

    const status: any = progressBarDetails?.progressStatus.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNumber,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    const handleSubmit = async () => {
        if (!applicationNumber) {
            try {
                await QUERY_ENTRY_VALIDATION.validate(values, { abortEarly: false, context: values });
                try {
                    const queryDocDetails = values.attachment.map((val: any) => ({
                        docRefId: val?.docReferenceId,
                        docType: 'QUERY'
                    }));
                    const payload = {
                        RequestInfo: requestInfo(),
                        uuid: '',
                        entityId: entityId,
                        plotId: selectedPlotDataId ? selectedPlotDataId : null,
                        queryType: values.queryType.code,
                        division: values.division.code,
                        title: values.title,
                        description: values.description,
                        queryDocDetails: queryDocDetails.length && [...queryDocDetails]
                    };
                    const response = POST(endpoints.createQueryEntry, payload);
                    setModalDetails({
                        show: true,
                        title: 'Success',
                        para: t('QUERY_SUBMITED_SUCCESSFULLY', 'Query Submited Successfully!'),
                        navigateUrl: '/goa-idc/enterprisedashboard',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                    });
                } catch (e: any) {
                    console.log(e);
                    let errMsg = e?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                    setIsLoading(false);
                    setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            } catch (validationError) {
                let errorField = getFirstErrorKey(errors);
                if (errorField) {
                    const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                    await setFieldTouched(errorField, true, true);
                    let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                    getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else {
            navigate('/goa-idc/enterprisedashboard');
        }
    };

    const onDocnameClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
    };

    const renderPlotTable = () => {
        return (
            <>
                <div className='px-3 py-2 d-flex flex-column justify-content-between'>
                    <div className='formTitle font-18 m-0'>{t('PLOT_DETAILS', 'Plot Details')}</div>
                    <GenericActionTable rowData={tableRowData} columns={plotDetailsTableColumns} searchBarPlaceholder={'SEARCH_BY_PLOT'} />
                </div>
            </>
        );
    };

    const queryDetailsFirst = applicationNumber
        ? QUERY_DETAILS
        : QUERY_DETAILS.map((val: any) => ({
              ...val,
              formData: [
                  ...val.formData.map((value: any) => {
                      if (!grievanceRedressal) {
                          if (value?.field === 'queryType') {
                              return {
                                  ...value,
                                  isDisabled: true
                              };
                          }
                          return {
                              ...value,
                              isDisabled: false
                          };
                      } else {
                          if (value?.field === 'queryType') {
                              return grievanceRedressal
                                  ? {
                                        titleCode: 'GRIEVANCE_TYPE',
                                        title: 'Grievance Type',
                                        field: 'queryType',
                                        type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                                        isRequired: true
                                    }
                                  : value;
                          }
                          return {
                              ...value,
                              isDisabled: false
                          };
                      }
                  })
              ]
          }));
    
    const updatedQueryDetails = applicationNumber
        ? QUERY_DETAILS_SECOND
        : QUERY_DETAILS_SECOND.map((val: any) => ({
              ...val,
              formData: [
                  ...val.formData.map((value: any) => ({
                      ...value,
                      isDisabled: false
                  })),
                  {
                      titleCode: 'ATTACHMENT',
                      title: 'Attachment',
                      field: 'attachment',
                      type: CONSTANT_DATA_FIELD.MULTI_FILE_FIELD,
                      class: 'col-12',
                      hideMultiFileValue: true,
                      isRequired: true
                  }
              ]
          }));

    const queryResponse = QUERY_RESPONSE_SECOND.map((val: any) => ({
        ...val,
        formData: [
            ...val.formData.map((value: any) => ({
                ...value,
                isDisabled: true
            }))
        ]
    }));
    const queryDetails = () => {
        return (
            <>
                {queryData?.queryResponse && (
                    <Card className='card_border p-3'>
                        <Card.Body>
                            <div className='ps-2 formTitle m-0'>{t('RESPONSE', 'Response')}</div>

                            {queryResponse.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}
                        </Card.Body>
                    </Card>
                )}
                <Card className='card_border p-3'>
                    <Card.Body>
                        {queryDetailsFirst.map((val: any) => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}

                        {values.queryType?.code === 'PLOT' && renderPlotTable()}

                        <div className=' py-2 d-flex flex-column justify-content-between'>
                            <div className='ps-2 formTitle m-0'>{grievanceRedressal ? t('GRIEVANCE_DETAILS', 'Grievance Details') : t('QUERY_DETAILS', 'Query Details')}</div>
                            {updatedQueryDetails.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}
                            <div>
                                <OwnershipTable
                                    tableRowData={tabelData}
                                    column={tableColumn}
                                    hideSearchBar={true}
                                    deleteOwnershipDetails={deleteTableRow}
                                    additionalMethod={onDocnameClick}
                                    updateOwnershipDataStatus={onView}
                                />
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </>
        );
    };
    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={grievanceRedressal ? t('GRIEVANCE_REDRESSAL', 'Grievance Redressal') : t('QUERY_ENTRY', 'Query Entry')}
                            bottomSubText={applicationNumber && `Type : Query, No: ${applicationNumber}`}
                            additionalClass='sub-text-style'
                        />
                        <div className='col-3 stickyCard-section'>
                            <div className='stickyCard' style={{ top: '110px' }}>
                                <GenericHeader
                                    text={t('QUERY_ENTRY', 'Query Entry')}
                                    sideHeader={true}
                                    additionalClass='sub-text-style'
                                    bottomSubText={applicationNumber && `Type : Query, No: ${applicationNumber}`}
                                />
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column  row-gap-3'>
                                            <SideCard steps={grievanceRedressal ? GRIEVANCE_STEPS_DETAILS : QUERY_STEPS_DETAILS} activeIndex={activeIndex} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            {!!applicationNumber && applicationData.status !== 'Draft' && (
                                <Card className='card_border my-2'>
                                    <Card.Body>
                                        <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                                    </Card.Body>
                                </Card>
                            )}
                            <FormikProvider value={formik}>
                                <Form>{activeIndex === 1 && queryDetails()}</Form>
                            </FormikProvider>
                        </div>
                    </div>
                    <ScrollToTop />
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                <GenericButtons text={applicationNumber ? t('CLOSE', 'Close') : t('SUBMIT', 'Submit')} variant='primary' handleClick={handleSubmit} />
                            </div>
                        </div>
                    </footer>

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={'PDF Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                <GenericPDFViewer fileStoreId={modalData.rowId} documentName={modalData.documentName} />
                            </div>
                        </GenericPopupModal>
                    )}
                </>
            )}
        </>
    );
};

export default QueryEntry;
