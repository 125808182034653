import { dropdownOptionsInterface } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';

export const ESTATE_REGISTRY_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Estate Code',
                field: 'estateCode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Estate Name',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Taluka',
                field: 'taluka',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Survey No.',
                field: 'survayNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            }
        ]
    }
];

export const ADVERTISEMENT_TABLE_COLUMNS: any = [
    {
        colName: 'singleCheck',
        fieldName: 'checkbox',
        clsName: 'col-sm-1'
    },
    {
        colName: 'Plot No.',
        fieldName: 'plotNo',
        clsName: 'col-sm-1 ',
        isClickable: true
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateName',
        clsName: 'col-sm-1'
    },
    {
        colName: 'Plot Type',
        fieldName: 'plotType',
        clsName: 'col-sm-1'
    },
    {
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotArea',
        clsName: 'col-sm-1'
    },
    {
        colName: 'Plot Rate(INR/Sq.m.)',
        fieldName: 'plotRate',
        clsName: 'col-sm-2'
    },
    {
        colName: 'No. of Plots',
        fieldName: 'noOfPlots',
        clsName: 'col-sm-1'
    },
    {
        colName: 'Actions',
        fieldName: 'action',
        clsName: 'col-sm-1',
        actions: 'edit_delete'
    }
];
export const VACANTPLOT_TABLE_COLUMNS: any = [
    {
        colName: 'multiCheck',
        fieldName: 'checkbox',
        clsName: 'col-sm-1 '
    },
    {
        colName: 'Plot No.',
        fieldName: 'plotNo',
        clsName: 'col-sm-1 column-text-start',
        isClickable: false
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateDetails.estateName',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col-sm-2 column-text-start'
    },

    {
        colName: 'Plot Type',
        fieldName: 'plotType',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotAreaSqm',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Plot Rate (INR/Sq.m.)',
        fieldName: 'plotRate',
        clsName: 'col-sm-2 column-text-start'
    }
];

export const ESTATE_REGISTRY_FIELDOFFICE_TABLE_COLUMNS: any = [
    {
        colName: 'Field Office Name',
        fieldName: 'fieldOfficeName',
        clsName: 'col-sm-3'
    },
    {
        colName: 'Manager Name',
        fieldName: 'managerName',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Address',
        fieldName: 'address',
        clsName: 'col-sm-4'
    },
    {
        colName: 'Action',
        fieldName: 'action',
        clsName: 'col-sm-3',
        actions: 'edit'
    }
];
export const ESTATE_FIELDOFFICE_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Field Office Address',
                field: 'officeAddress',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: true
            },
            {
                title: "Field Manager's Name",
                field: 'managerName',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Mobile Number',
                field: 'phoneNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            }
        ]
    }
];
