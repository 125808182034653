import { Formik, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { ARCHITECT_CREATE_FORM, EMPLOYEE_CREATE_APPLICATION_FORM } from '../../../../utils/formikValidation/FormikValidation';
import {
    ARCHITECT_DOCS_DETAILS,
    ASSIGNMENT_DETAILS,
    EDUCATION_DETAILS,
    INITIAL_SELECT_DATA,
    LICENSEE_NEW_DETAILS,
    PERSONAL_DETAILS,
    stepsDetails,
    stepsDetailsForArchitect
} from '../UserManagement.const';
import '../UserManagement.scss';
import GenericPreview from '../moduleComponents/PreviewPage.component';
import {
    POST,
    createEmployee,
    endpoints,
    getArchitectData,
    getDropdownOptions,
    getEmployeeData,
    getFileUrl,
    getLocation,
    getProgressBarData,
    requestInfo,
    submitAction,
    updateEmployee
} from '../../../../services/modulesServices/userManagement.api';
import ScrollToTopButton from '../../../../components/ScrollToTop';
import _ from 'lodash';
import { check, cross } from '../../../../utils/Logo';
import { getArcInitialData, handleUpdates } from '../UserMangement.controller';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import { DocumentInfo, RoleInterface } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import HeaderComponent from '../../../../components/header/Header.component';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
import { useTranslation } from 'react-i18next';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { toast } from 'react-toastify';

export default function ArchitectCreationPage() {
    const { t, i18n } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [assignmentFormData, setAssignmentFormData] = useState(ASSIGNMENT_DETAILS);
    const [qualificationFormData, setQualificationFormData] = useState(EDUCATION_DETAILS);
    const [documents, setDocuments] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>([]);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [applId, setAppId] = useState<string>('');
    const navigate = useNavigate();
    const [modalDetails, setModalDetails] = useState<any>({});
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [userRegistrationNo, setUserRegistrationNo] = useState<number | string>('');
    const [employeePayload, setEmployeePayload] = useState<any>({});
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);

    const location = useLocation();
    const rowData = location?.state?.rowData || null;

    const searchParams = new URLSearchParams(location.search);
    const applicationNo = searchParams.get('applicationNumber');
    let userData = localStorage.getItem('currentUserAssignment');
    let designation = userData ? String(JSON.parse(userData)?.designation?.code) : null;

    const personalDetails = !designation
        ? PERSONAL_DETAILS?.map((item: any) => ({
              ...item,
              formData: item.formData?.map((val: any) => {
                  if (val.field === 'phoneNo' || val.field === 'emailId') {
                      return { ...val, isNoLogin: true };
                  } else {
                      return { ...val };
                  }
              })
          }))
        : PERSONAL_DETAILS;

    const [updatedPersonalDetails, setupdatedPersonalDetails] = useState<any>(personalDetails);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleSubmit = () => {
        setShowPopup(false);
        navigate(-1);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let formIsUpdatable: boolean = true;
    useEffect(() => {
        const fetchEmployeeData = async () => {
            if (applicationNo && dropdownData) {
                try {
                    const nextAction = await POST(endpoints.nextValidAction + `?businessId=${applicationNo}&tenantId=ga`, { RequestInfo: requestInfo() });
                    setNextValidAction(nextAction.data);
                    formIsUpdatable = nextAction.data.isUpdatable;

                    const response = await getArchitectData(applicationNo);
                    const initData = await getArcInitialData(response.data.Employees[0], dropdownData);
                    setUserRegistrationNo(response.data.Employees[0].user.registrationNumber);
                    setEmployeePayload(response.data.Employees[0]);

                    try {
                        await getDoumentsData(response);
                    } catch (e) {
                        console.log(e);
                    }

                    setValues((prev: any) => ({
                        ...prev,
                        ...initData,
                        isUpdatable: formIsUpdatable,
                        underProcess: true
                    }));
                } catch (err: any) {
                    let errMsg = err.response?.data.errorMessage || t('AN_ERROR_OCCURRED', 'An error occurred');
                    setModalDetails({ show: true, title: 'ERROR', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
        };
        fetchEmployeeData();
    }, [rowData, dropdownData]);

    const getDoumentsData = async (res: any) => {
        let docs = res.data.Employees[0].documents;
        if (Array.isArray(docs) && docs.length) {
            const previewData: any = await Promise.all(
                docs?.map(async (item: any) => {
                    let documentUrl = await getFileUrl(item.referenceId);
                    // let documentUrl = 'https://' + documentUrls.split("https://").filter(Boolean)[0].slice(0, -1)
                    return { ...item, documentUrl };
                })
            );
            setDocuments(previewData);
        }
    };

    useEffect(() => {
        getDropdownOptions()
            .then(res => {
                const tempDropdown = {
                    ...res,
                    licenseeType: [
                        { code: 'Architect', name: 'Architect' },
                        { code: 'Engineer', name: 'Engineer' }
                    ]
                };
                setDropdownData(tempDropdown);
            })
            .catch(err => console.log(err));
    }, []);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},
        validationSchema: !formIsUpdatable ? null : ARCHITECT_CREATE_FORM[`page${activeIndex}`]
        // validateOnMount: false,
        // validateOnBlur: true,
        // validateOnChange: true
    });

    const { values, setValues, setFieldValue } = formik;

    useEffect(() => {
        const updatedDetails = handleUpdates(values, updatedPersonalDetails, personalDetails, 'Present Address', 'Permanent Address');
        setupdatedPersonalDetails(updatedDetails);
    }, [values?.isPermanentPincodeDetailsPresent, values?.isPincodeDetailsPresent, values?.copyOnCheck]);

    useEffect(() => {
        try {
            // getAuditData()
            if (!!rowData) getProgressBarData(applicationNo).then(res => setProgressBarDetails(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [rowData]);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const isRoleMD = !user?.roles?.filter((role: RoleInterface) => role.code === 'MD').length;

    const renderProfileDetails = () => {
        const status: any = progressBarDetails?.progressStatus.find((item: any) => item.isCurrent === true);
        const applicationData = {
            applicationNo: applicationNo,
            status: status?.stage,
            data: progressBarDetails?.auditTrails,
            isRoleMD: isRoleMD,
            bpaData: '',
            pendingWith: status?.actor
        };

        return (
            <>
                {!!applicationNo && (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                        </Card.Body>
                    </Card>
                )}
                {updatedPersonalDetails.map((val: any, id: number) => (
                    <Card className='card_border' key={id}>
                        <Card.Body>
                            <GenericFormCard module='usermanagement' {...val} dropdownOptions={dropdownData} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const renderBasicDetails = () => {
        // let LicenseDetails = LICENSEE_NEW_DETAILS;
        // let formsData = LICENSEE_NEW_DETAILS[0]?.formData.map((val: any, ind: any) => {
        //     if (val?.field === 'licenseeType') {
        //         val.options = INITIAL_SELECT_DATA?.TechnicalPerson;
        //     }
        //     return val;
        // });
        // LicenseDetails[0].formData = formsData;

        return (
            <>
                {LICENSEE_NEW_DETAILS.map((val: any) => (
                    <Card className='card_border mb-4'>
                        <Card.Body>
                            <GenericFormCard module='usermanagement' {...val} dropdownOptions={dropdownData} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const renderDocumentsDetails = () => {
        return (
            <>
                {ARCHITECT_DOCS_DETAILS.map(val => (
                    <Card className='card_border'>
                        <Card.Body>
                            <GenericFormCard module='usermanagement' {...val} setDocuments={setDocuments} documents={documents} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const renderPreview = () => {
        const allSections = [
            { 'Personal Details': PERSONAL_DETAILS, titleCode: 'PERSONAL_DETAILS' },
            { 'License Details': LICENSEE_NEW_DETAILS, titleCode: 'LICENSEE_NEW_DETAILS' },
            { 'Document Details': ARCHITECT_DOCS_DETAILS, titleCode: 'DOCUMENT_DETAILS' }
        ];
        return (
            <>
                {allSections.map((val, index) => (
                    <Card className='card_border'>
                        <Card.Body>
                            <GenericPreview
                                details={Object.values(val)}
                                sectionTitle={Object.keys(val)}
                                documents={[
                                    ...documents,
                                    { ...values?.adhar, referenceId: values?.adhar?.docReferenceId, documentId: values?.adhar?.docReferenceId },
                                    { ...values?.licenseCopy, referenceId: values?.licenseCopy?.docReferenceId, documentId: values?.licenseCopy?.docReferenceId }
                                ]}
                                titleCode={val.titleCode}
                            />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const onSubmit = async () => {
        const val = _.cloneDeep(values);
        const isFinal = !rowData && activeIndex === stepsDetailsForArchitect.length;

        const docs = documents.map((item: DocumentInfo) => {
            const { documentSize, lastModified, uploadedDate, documentUrl, ...doc } = item;
            return doc;
        });

        const allDocs =
            values?.adhar && values?.licenseCopy
                ? [
                      ...docs,
                      { ...values?.adhar, referenceId: values?.adhar?.docReferenceId, documentId: values?.adhar?.docReferenceId },
                      { ...values?.licenseCopy, referenceId: values?.licenseCopy?.docReferenceId, documentId: values?.licenseCopy?.docReferenceId }
                  ]
                : docs;

        let payload = {
            RequestInfo: requestInfo(),
            Employees: [
                {
                    employeeStatus: 'ACTIVE',
                    employeeType: 'TEMPORARY',
                    // dateOfAppointment: new Date().getTime(),
                    IsActive: true,
                    tenantId: 'ga',
                    licenseDetail: {
                        technicalPersonCode: values?.licenseeType?.name || 'Architect',
                        councilOfArchitectNo: values?.CouncilArcNo,
                        validFrom: new Date(values?.validFrom).getTime(),
                        validTo: new Date(values?.validTo).getTime()
                    },
                    documents: allDocs,
                    user: {
                        salutation: values.firstnamesalutation || '',
                        firstName: values.firstname || '',
                        lastName: values.lastname || '',
                        dob: new Date(values.dob).getTime(),
                        mobileNumber: values.phoneNo || '',
                        emailId: values.emailId || '',
                        gender: values?.gender?.code || '',
                        permanentAddress1: values.peraddress1 || '',
                        permanentAddress2: values.peraddress2 || '',
                        permanentDistrict: values.perdistrict || '',
                        permanentState: values.perstate || '',
                        permanentCountry: values.percountry || '',
                        permanentPinCode: values.perpincode || '',
                        correspondenceAddress1: values.address1 || '',
                        correspondenceAddress2: values.address2 || '',
                        correspondenceDistrict: values.district || '',
                        correspondenceState: values.state || '',
                        correspondenceCountry: values.country || '',
                        correspondencePinCode: values.pincode || '',
                        pan: values.documentNo || '',
                        tenantId: 'ga',
                        registrationNumber: userRegistrationNo ? userRegistrationNo : undefined,
                        roles: [
                            {
                                code: 'ARCHITECT',
                                name: 'BPA_ARCHITECT_Creator',
                                tenantId: 'ga'
                            }
                        ]
                    },
                    isFinalSubmit: isFinal,
                    otpReferenceId: !designation ? values?.phoneNoOtpReferenceId : undefined
                }
            ]
        };

        // const { RequestInfo, Employees } = tempPayload;
        // const payload = !designation ? { ...Employees[0], otpReferenceId: values?.phoneNoOtpReferenceId } : tempPayload;

        setEmployeePayload(payload);

        try {
            if (!rowData && activeIndex < stepsDetailsForArchitect.length) {
                await ARCHITECT_CREATE_FORM[`page${activeIndex}`].validate(values, { abortEarly: false });
            }

            const createApi = !designation ? endpoints.architectCreateNoLogin : endpoints.employeeCreation;
            const updateApi = !designation ? endpoints.architectUpdateNoLogin : endpoints.employeeUpdation;

            if (!userRegistrationNo) {
                createEmployee(payload, createApi)
                    .then((res: any) => {
                        if (res.data) {
                            !isFinal && setUserRegistrationNo(res.data.Employees[0].user.registrationNumber);
                            setFieldValue('underProcess', true);
                            toast(t('ARCHITECT_CREATION_SUCCESS', 'Architect Creation Successful'), { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                        }
                        onChangeNextIndex();
                    })
                    .catch(err => {
                        console.log(err);
                        let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                        setModalDetails({ show: true, title: t('ARCHITECT_CREATION_FAILED', 'Architect Creation Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    });
            } else {
                updateEmployee(payload, updateApi)
                    .then(res => {
                        if (res.data) {
                            if (isFinal) {
                                setModalDetails({
                                    show: true,
                                    title: t('ARCHITECT_REGISTRATION_SUCCESSFUL', 'Architect Registration Successful'),
                                    para: 'Application for Architect Registration submitted successfully',
                                    img: check,
                                    type: 'success',
                                    reUpload: 'OK'
                                });
                            } else {
                                toast(t('DATA_SAVED', 'Data saved'), { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                                onChangeNextIndex();
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                        setModalDetails({ show: true, title: t('ARCHITECT_REGISTRATION_FAILED', 'Architect Resgistration Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    });
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetailsForArchitect.length;

        try {
            if (activeIndex < stepperLength) {
                if (!rowData) {
                    await ARCHITECT_CREATE_FORM[`page${activeIndex}`].validate(values, { abortEarly: false });
                }

                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            } else {
                alert('Fill in all the required details');
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetailsForArchitect.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex && !rowData) {
            try {
                await ARCHITECT_CREATE_FORM[`page${activeIndex}`].validate(values, { abortEarly: false });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        const url = !designation ? '/goa-idc/' : '/goa-idc/dashboard';
        if (type === 'success') navigate(url);
    };

    const handleOnPrimaryAction = async (selectedAction: any, comment: string) => {
        const payload = {
            processInstances: [
                {
                    tenantId: 'ga',
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNo,
                    moduleName: nextValidAction?.moduleName,
                    action: selectedAction?.action,
                    comment: comment
                }
            ],
            Employees: [employeePayload],
            isStateUpdatable: nextValidAction?.isUpdatable
        };

        if (!comment) {
            setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            const res = await submitAction(payload);
            const msg = res.data.processInstances[0]?.assignes?.[0]?.name
                ? `${t('ACTION_SUCCESS_SENT', 'Action Successful! Application send to')} ${res.data.processInstances[0]?.assignes[0]?.name}`
                : `${t('ACTION_SUCCESS', 'Action Successful!')}`;
            setModalDetails({ show: true, title: 'SUCCESS', para: msg, img: check, type: 'success', reUpload: 'OK' });
            navigate('/goa-idc/dashboard');
        } catch (e) {
            console.log(e);
            let errMsg = t('ACTION_UNSUCCESS', 'Action Unsuccessful! Try again');
            setModalDetails({ show: true, title: 'ERROR', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            // setAlertMessage("Action failed. Please try again");
        }
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader
                    text={t('ARCHITECT_ONBOARDING', 'Architect Onboarding')}
                    bottomSubText={rowData ? `No : ${applicationNo}` : userRegistrationNo ? `No : ${userRegistrationNo}` : ''}
                    additionalClass={'sub-text-style'}
                />

                <div className='col-3 stickyCard-section'>
                    <div className='stickyCard'>
                        <GenericHeader
                            text={t('ARCHITECT_ONBOARDING', 'Architect Onboarding')}
                            sideHeader={true}
                            additionalClass='sub-text-style'
                            bottomSubText={rowData ? `No : ${applicationNo}` : userRegistrationNo ? `No : ${userRegistrationNo}` : ''}
                        />
                        {/* <Card className={`card_border hiddenNav ${isVisible ? '' : 'scrolledNav'}`}>
                            <Card.Body>
                                <div className='d-flex align-items-end'>
                                    <GenericBackButton />
                                    <div className='ps-1'>
                                        <Card.Subtitle className='text-muted text-nav'>{t('HOME_USER_MANAGEMENT', 'HOME / USER MANAGEMENT')}</Card.Subtitle>
                                        <Card.Text className='font-inter-med fs-4'>
                                            {t('ARCHITECT_ONBOARDING', 'Architect Onboarding')}{' '}
                                            {rowData ? <span> - {rowData.applicationNo}</span> : userRegistrationNo ? <span> - {userRegistrationNo}</span> : ''}
                                        </Card.Text>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card> */}
                        <Card className='card_border p-3'>
                            <Card.Body>
                                <Card.Text className=' d-flex flex-column row-gap-3'>
                                    <SideCard steps={stepsDetailsForArchitect} activeIndex={activeIndex} handleStepClick={handleStepClick} />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        {/* <Card className="card_border note">
              <Card.Body>
                <Card.Text>
                  <span>
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                  </span>
                  <b> Note : </b>Please provide all the details.
                </Card.Text>
              </Card.Body>
            </Card> */}
                    </div>
                </div>
                <div className='col-9 form-section'>
                    <FormikProvider value={formik}>
                        <Form>
                            {activeIndex === 1 && renderProfileDetails()}
                            {activeIndex === 2 && renderBasicDetails()}
                            {activeIndex === 3 && renderDocumentsDetails()}
                            {activeIndex === 4 && renderPreview()}
                        </Form>
                    </FormikProvider>
                </div>
            </div>
            <ScrollToTopButton />
            <GenericFooterComponent
                onClickNext={onChangeNextIndex}
                onCancel={() => navigate(-1)}
                onClickPrev={onChangePrevIndex}
                stepperLength={stepsDetailsForArchitect.length}
                activeIndex={activeIndex}
                onSubmit={onSubmit}
                nextValidAction={nextValidAction}
                applicationNo={applicationNo || null}
                handleOnPrimaryAction={handleOnPrimaryAction}
            />
        </>
    );
}
