import React from 'react';
import './Profile.scss';
import editIcon from '../../assets/logos/PencilSimpleLine.svg';
import { DocumentDetails } from '../moduleCommonFormik/GenericFormCard.constants';
import { pro_photo } from '../../utils/Images';

interface IProfileProps {
    name: string;
    designation: string;
    profilePhoto?: DocumentDetails;
    onEditProfilePhoto: (e: any) => void;
    showEditIcon: any;
}
const ProfileHeader = (props: IProfileProps) => {
    const handleOnImageClick = async () => {
        window.open(props?.profilePhoto?.documentUrl, '_blank');
    };

    return (
        <>
            <div className='container'>
                <div className='row ps-3 pt-4 mb-4'>
                    <div className='col-sm-8 d-flex flex-column ps-0 justify-content-center  ms-lg-3 ms-xl-0  ms-md-4 ms-sm-5 ps-0 ps-md-3 ps-sm-3 ms-3 '>
                        <h1 className='profileName m-0'>{props?.name}</h1>
                        <p className='profileDesignation m-0'>{props?.designation}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileHeader;
