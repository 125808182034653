import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Button, Card, CardBody, Form, Image } from 'react-bootstrap';
import {
    LESSEE_DETAILS,
    DOCUMENTS_DETAILS,
    OCCUPANCY_DETAILS,
    PLOT_DETAILS,
    INSPECTION_ACTIVITY_COLUMN,
    NOC_DOCUMENTS_DETAILS,
    FIELD_INSPECTION_DETAILS,
    FIELD_INSPECTION_DETAILS_II,
    FIELD_INSPECTION_DETAILS_DEFAULT,
    QUESTIONNAIRE_DETAILS,
    QUESTIONNAIRE_DETAILS_ACTIVITY_COLUMN,
    PART_OCCUPANCY_DETAILS,
    INSPECTION_ACTIVITY_COLUMN_DEFAULT
} from './OrderCompletionFormPage.const';
import { Field, FieldArray, useFormikContext } from 'formik';
import { POST, devUrl, getProgressBarData, requestInfo } from '../../services/modulesServices/userManagement.api';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import { CLEARANCE_INITIAL_DATA } from './OrderCompletionFormPage.module';
import { RoleInterface } from '../../components/moduleCommonFormik/GenericFormCard.constants';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import GenericPopupModal from '../../components/moduleCommonFormik/GenericModalPopup.component';
import { useNavigate } from 'react-router-dom';
import CLASelectionTable from './CLASelectionTable.component';
import { getCLAList } from './OrderCompletionFormPage.controller';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import { CHECKLIST_TABLE_TC } from '../modules/EstateManagement/Allotment/Allotment.const';
import Editor from '../../components/ContentEditor';
import { OwnershipTable } from '../modules/UserManagement/EnterpriseModule/OwnershipTable';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { t } from 'i18next';
import attachmentIcon from '../../assets/logos/attachment.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import DeleteIconFloor from '../../assets/logos/deleteButton.svg';
import { check, download, file_upload } from '../../utils/Logo';
import { DIGITAL_SIGN_DETAILS } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.const';
import axios from 'axios';
import { extractFileUrl } from '../../utils/utils';
import { ThreeDots } from 'react-loader-spinner';
import BpaCommonSelector from '../../components/moduleCommonFormik/BpaCommonSelector.component';
import GenericCommonTextField from '../../components/moduleCommonFormik/GenericCommonTextField.component';
import { FileUploader } from 'react-drag-drop-files';

interface OrderCompletionFormSubPageProps {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    index: number;
    employeeList: any;
    formData: any;
    previewData: any;
    applicationNumber?: string;
    appStatus: boolean;
    showProcess: boolean;
    activeStepper: string;
    allchecked: any;
    setAllChecked: React.Dispatch<any>;
    bpaData: any;
    isCancelBtn: boolean;
    setShowProcess: React.Dispatch<React.SetStateAction<boolean>>;
    setEditorContent: Dispatch<SetStateAction<string>>;
    setIsSelectedBPA: React.Dispatch<React.SetStateAction<string>>;
    editorContent: string;
    setFormValues: Dispatch<
        SetStateAction<
            {
                emailId: string;
            }[]
        >
    >;
    formValues: {
        emailId: string;
    }[];
    searchBPA: (val: any) => void;
    allBPADetails: any;
    allEdcrDetails: any;
    fieldInspection: any;
    onActionClick: () => void;
    actionsData: any;
    onAcceptRequest: () => void;
    openSection: string;
    setOpenSection: React.Dispatch<React.SetStateAction<string>>;
    openInspectionForm: () => void;
    inspectionReport: [];
    reportDocsData: [];
    setReportDocsData: React.Dispatch<any>;
    renderQuestionnaireDetails: () => React.JSX.Element;
    questionnaireDetails: any;
    content: any;
    setContent: React.Dispatch<React.SetStateAction<string>>;
}

export default function M_OrderCompletionFormSubPage(props: OrderCompletionFormSubPageProps) {
    const { values, resetForm, setValues, setFieldValue } = useFormikContext<any>();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [auditTrial, setAuditTrial] = useState<any>(null);
    const [isMultiLessee, setIsMultiLessee] = useState<boolean>(false);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const isRoleMD = !user?.roles?.filter((role: RoleInterface) => role.code === 'MD').length;
    const navigate = useNavigate();
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [checklistTableData, setChecklistTableData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    function handleChange(e: any) {
        if (e.target.checked) {
            props?.setAllChecked([...props?.allchecked, e?.target?.value]);
        } else {
            props?.setAllChecked(props?.allchecked.filter((item: any) => item !== e.target.value));
        }
    }

    useEffect(() => {
        if (!!props?.allBPADetails) setIsMultiLessee(props?.allBPADetails.length >= 0);
    }, [props?.allBPADetails]);

    useEffect(() => {
        try {
            if (!!props?.applicationNumber) getProgressBarData(props?.applicationNumber).then(res => setProgressBarDetails(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [props?.applicationNumber]);

    const proceedToBPA = () => {
        props.setIsSelectedBPA(values?.edcrNo);
        setIsMultiLessee(false);
    };

    const handelIsLesseeVisible = () => {
        setIsMultiLessee(false);
        navigate(-1);
    };

    const handleClose = () => {
        setIsShow(false);
    };

    const getAuditData = () => {
        if (!props?.applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.getAuditTrail(payload, props?.applicationNumber, 'ga')
            .then(res => {
                setAuditTrial(res?.data?.ProcessInstances);
            })
            .catch(err => console.log(err));
    };

    const clearData = () => {
        resetForm();
        setValues(CLEARANCE_INITIAL_DATA);
    };

    useEffect(() => {
        getAuditData();
        // return () => {
        //     clearData();
        // };
    }, []);

    useEffect(() => {
        if (values?.occupancyType === 'FULL_OCCUPANCY') {
            setFieldValue('occupancy', '100');
        } else {
            setFieldValue('occupancy', '');
        }
    }, [values?.occupancyType]);

    const renderBuildingPlanDetails = () => {
        let modifiedArray = PLOT_DETAILS;
        if (!!props?.appStatus) {
            modifiedArray.map((v: any) => {
                v.formData.map((h: any) => (h.isDisabled = true));
            });
        }
        return (
            <>
                {modifiedArray.map(val => (
                    // <Card className='card_border my-2'>
                    //     <Card.Body>
                    <GenericFormCard {...val} />
                    //     </Card.Body>
                    // </Card>
                ))}
            </>
        );
    };

    const renderBasicDetails = () => {
        const applicationData = {
            applicationNo: props?.applicationNumber,
            status: props?.previewData?.status,
            data: auditTrial,
            isRoleMD: isRoleMD,
            bpaData: props?.bpaData
        };

        return (
            <>
                {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                {LESSEE_DETAILS.map((val, key) => (
                    <GenericFormCard {...{ ...val }} key={key} />
                ))}
                {/* </Card.Body>
                </Card> */}
            </>
        );
    };

    const renderOccupancyDetails = () => {
        const occupancy_Details: any = OCCUPANCY_DETAILS;
        values.occupancy = props.previewData?.ocPercentage;
        if (!!props.previewData?.ocPercentage) {
            values.occupancyType = props.previewData?.ocPercentage == 100 ? 'FULL_OCCUPANCY' : 'PART_OCCUPANCY';
            occupancy_Details[0].formData[0].options[0].isDisabled = true;
            occupancy_Details[0].formData[0].options[1].isDisabled = true;
        } else {
            occupancy_Details[0].formData[0].options[0].isDisabled = true;
            occupancy_Details[0].formData[1].isDisabled = values?.occupancyType !== 'PART_OCCUPANCY';
        }

        const part_Occupancy_Details: any = PART_OCCUPANCY_DETAILS;
        part_Occupancy_Details.forEach((v: any) => {
            v.formData.map((h: any) => (h.isDisabled = props.previewData?.status !== 'APPL_STATUS_OC_SUBMISSION_PENDING' && props.previewData?.applicationType !== 'CONSTRUCTION_LICENCE'));
        });
        return (
            <>
                {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                {!!props?.previewData && occupancy_Details.map((val: any) => <GenericFormCard {...val} />)}
                <div>
                    <b>Obtained Occupancy : {values?.percentage}%</b>
                </div>
                {!!props?.previewData && values?.occupancyType === 'PART_OCCUPANCY' && part_Occupancy_Details.map((val: any) => <GenericFormCard {...val} />)}

                {(values?.occupancyType === 'PART_OCCUPANCY' || values?.percentage < 100) && blockComponent()}
                {/* </Card.Body>
                </Card> */}
            </>
        );
    };

    const blockComponent = () => {
        const floorTypes = [
            { name: 'Ground Floor', value: 'groundFloor', code: 'groundFloor', label: 'Ground Floor', title: 'Ground Floor', subcategory: [] },
            { name: 'Below Ground', value: 'belowFloor', code: 'belowFloor', label: 'Below Ground', title: 'Below Ground', subcategory: [] },
            { name: 'Above Ground', value: 'aboveFloor', code: 'aboveFloor', label: 'Above Ground', title: 'Above Ground', subcategory: [] },
            { name: 'Stilt Floor', value: 'stiltFloor', code: 'stiltFloor', label: 'Stilt Floor', title: 'Stilt Floor', subcategory: [] }
        ];
        return (
            <>
                <Form>
                    <FieldArray name='blocks'>
                        {({ push, remove }) => (
                            <>
                                {!!values?.blocks &&
                                    !!values?.blocks.length &&
                                    values?.blocks.map((block: any, blockIndex: any) => (
                                        <div className='mt-3'>
                                            {/* <Card className='card_border my-2'>
                                             <Card.Body> */}
                                            <div key={blockIndex}>
                                                <span className='font-inter' style={{ fontWeight: 600, fontSize: '22px', lineHeight: '32px', color: '#3a7425', marginLeft: '10px' }}>
                                                    Block - {blockIndex + 1}
                                                </span>
                                                <FieldArray name={`blocks.${blockIndex}.floors`}>
                                                    {({ push, remove }) => (
                                                        <>
                                                            {block.floors.map((floor: any, floorIndex: any) => (
                                                                <div className='mt-3'>
                                                                    {/* <Card className='card_border my-2'>
                                                                     <Card.Body> */}
                                                                    <div key={floorIndex}>
                                                                        <span className='font-inter' style={{ fontWeight: 600, fontSize: '16px', lineHeight: '25.6px', marginLeft: '10px' }}>
                                                                            Floor - {floorIndex + 1}
                                                                        </span>
                                                                        <div className='d-flex justify-content-between mt-2'>
                                                                            <div className='col-sm-3 px-2 py-3'>
                                                                                <BpaCommonSelector
                                                                                    title='Floor Type'
                                                                                    options={floorTypes}
                                                                                    isDisabled
                                                                                    // isRequired={true}
                                                                                    field={`blocks.${blockIndex}.floors.${floorIndex}.floorType`}
                                                                                />
                                                                            </div>
                                                                            <div className='col-sm-3 px-2 py-3'>
                                                                                <GenericCommonTextField
                                                                                    title='Floor Number'
                                                                                    isDisabled
                                                                                    // isRequired={true}
                                                                                    field={`blocks.${blockIndex}.floors.${floorIndex}.floorNumber`}
                                                                                />
                                                                            </div>
                                                                            {/* </div>
                                                                        <div className='d-flex justify-content-between'> */}
                                                                            <div className='col-sm-3 px-2 py-3'>
                                                                                <GenericCommonTextField
                                                                                    title='Floor Area'
                                                                                    // isRequired={true}
                                                                                    isDisabled
                                                                                    field={`blocks.${blockIndex}.floors.${floorIndex}.floorArea`}
                                                                                />
                                                                            </div>
                                                                            <div className='col-sm-3 px-2 py-3'>
                                                                                <>
                                                                                    <GenericCommonTextField
                                                                                        title='Built Up Area'
                                                                                        // isRequired={true}
                                                                                        isDisabled
                                                                                        field={`blocks.${blockIndex}.floors.${floorIndex}.builtUpArea`}
                                                                                    />
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* </Card.Body>
                                                                </Card> */}
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </div>
                                            {/* </Card.Body>
                                         </Card> */}
                                        </div>
                                    ))}
                            </>
                        )}
                    </FieldArray>
                </Form>
            </>
        );
    };

    const downloadApplicationDocument = () => {
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${props?.previewData?.digitalSignedDoc}`;
        window.open(downloadFileUrl);
    };

    const renderDigitalSign = () => {
        return (
            <>
                {DIGITAL_SIGN_DETAILS.map(val => (
                    <Card.Body>
                        <GenericFormCard {...val} />
                    </Card.Body>
                ))}
            </>
        );
    };

    const renderDeclarationDetails = () => {
        return (
            <>
                <div className='ms-2 mt-2'>
                    {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                    <div className='row ps-2 py-2'>
                        {/* <div className='formTitle m-0'>
                                {'Self Declaration'}
                                <span className='text-danger'>*</span>
                            </div> */}
                    </div>
                    <div className='row d-flex m-0'>
                        {/* <Button variant="bg-light outline-success btn btn-light font-karla-med p-10" onClick={() => { setIsShow(true) }} style={{ borderColor: "#45852e", color: "#45852e", width: "max-content", borderRadius: 8 }} >
                                Sign the Document
                            </Button> */}
                        <p className='fw-bold'>The Lessee does hereby affirm and declare that:</p>

                        <label className='mb-3 checkbox-container'>
                            {props.appStatus ? (
                                <input type='checkbox' id='myCheckbox' className='me-2' checked={props.appStatus} style={{ opacity: '0' }} />
                            ) : (
                                <input type='checkbox' id='myCheckbox' value='1' name='step_1' onChange={handleChange} className='me-2' />
                            )}
                            <span className={`${props.appStatus ? 'checkmarkDefault' : 'checkmark'} `}></span>
                            <span>
                                (i) The information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed
                                herein.
                            </span>
                        </label>
                        <label className='mb-3 checkbox-container'>
                            {props.appStatus ? (
                                <input type='checkbox' id='myCheckbox' className='me-2' checked={props.appStatus} style={{ opacity: '0' }} />
                            ) : (
                                <input type='checkbox' id='myCheckbox' value='2' className='me-2' name='step_2' onChange={handleChange} />
                            )}
                            <span className={`${props.appStatus ? 'checkmarkDefault' : 'checkmark'} `}></span>
                            <span>
                                (ii) It has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation
                                proceedings.
                            </span>
                        </label>
                        <label className='mb-3 checkbox-container'>
                            {props.appStatus ? (
                                <input type='checkbox' id='myCheckbox' className='me-2' checked={props.appStatus} style={{ opacity: '0' }} />
                            ) : (
                                <input type='checkbox' id='myCheckbox' value='3' name='step_3' onChange={handleChange} className='me-2' />
                            )}
                            <span className={`${props.appStatus ? 'checkmarkDefault' : 'checkmark'} `}></span>
                            <span>
                                (iii) The technical clearance, construction license, as the case may be, shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the
                                information submitted under or along with this application.
                            </span>
                        </label>
                    </div>
                    {!!props?.previewData?.lesseeDigitalSignFlage ? (
                        <>
                            <div className='ps-2 pt-2 font-14 font-inter fw-bold m-0'>Digitally Signed</div>
                            <div className='ps-2 pb-2 font-14 font-inter'></div>
                            <div onClick={() => downloadApplicationDocument()} className='ps-2 d-flex align-items-center'>
                                <Image src={download} height={14} />
                                <div className='ps-2 font-14' style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>
                                    Download Application
                                </div>
                            </div>
                        </>
                    ) : (
                        <GenericButtons text='Sign the document' ClassName='px-3' />
                    )}
                    {/* </Card.Body>
                </Card> */}
                </div>
                <GenericPopupModal
                    title='Digital Signature'
                    isVisible={isShow}
                    primaryBtnTitle='Sign the Documents'
                    secondaryAction={handleClose}
                    primaryAction={() => {}}
                    secondaryBtnTitle='Close'
                    children={renderDigitalSign()}
                />
            </>
        );
    };

    const renderDocumentDetails = () => {
        let modifiedArray = DOCUMENTS_DETAILS;
        if (!!props?.appStatus) {
            modifiedArray.map((v: any) => {
                v.formData.map((h: any) => (h.isDisabled = true));
            });
        }
        return (
            <>
                {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                {modifiedArray.map(val => (
                    <GenericFormCard {...{ ...val }} />
                ))}
                {/* </Card.Body>
                </Card> */}
            </>
        );
    };

    const renderNOCDocumentDetails = () => {
        let modifiedArray = NOC_DOCUMENTS_DETAILS;
        values.isEditableFile = true;
        // if (!!props?.appStatus) {
        modifiedArray.map((v: any) => {
            v.formData.map((h: any) => (h.isDisabled = props?.actionsData?.nextValidAction.filter((val: any) => val?.action === 'ACT_ARM_NOC_FORWARD').length === 0));
        });
        // }

        return (
            <>
                {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                {modifiedArray.map(val => (
                    <GenericFormCard {...{ ...val }} />
                ))}
                {/* </Card.Body>
                </Card> */}
            </>
        );
    };

    const fetchGatewayData = async () => {
        try {
            const { data } = await POST(`/egov-mdms-service/v1/_search`, {
                RequestInfo: requestInfo(),
                MdmsCriteria: {
                    tenantId: 'ga',
                    moduleDetails: [
                        {
                            moduleName: 'egov-pg-service',
                            masterDetails: [
                                {
                                    name: 'Gateway',
                                    filter: '[?(@.active == true)]'
                                }
                            ]
                        }
                    ]
                }
            });
            let feeMasterDetails = data?.['MdmsRes']?.['egov-pg-service']?.['Gateway'];
            setGatewayData(feeMasterDetails || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getCheckListData = () => {
        if (!props?.applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.bpaCheckList(payload, props?.applicationNumber, 'WF_BPA_OC')
            .then(res => {
                const checklistDataWithIndex = res?.data?.checklistValidationDetails?.map((item: any, index: any) => ({
                    ...item,
                    index: index + 1
                }));
                setChecklistTableData(checklistDataWithIndex);
            })
            .catch(err => console.log(err));
    };

    const handleOnChange = async (e: any) => {
        const file: any = e.target.files && e.target.files[0];

        try {
            if (!file) {
                console.error('No file selected.');
                return;
            }
            setIsLoading(true);
            const response: any = await axios.post(
                devUrl + '/filestore/v1/files',
                {
                    file: file,
                    tenantId: 'ga',
                    tag: file.name,
                    module: 'hrms-upload'
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            const fileResponseUrl = await axios.get(devUrl + '/filestore/v1/files/url', {
                params: {
                    tenantId: 'ga',
                    fileStoreIds: response.data.files[0].fileStoreId
                }
            });
            const fileData = {
                documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
                fileSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
                referenceId: fileResponseUrl?.data?.fileStoreIds[0].id,
                documentUrl: extractFileUrl(fileResponseUrl.data.fileStoreIds[0].url),
                uploadedDate: fileResponseUrl?.data?.fileStoreIds[0].uploadedDate,
                rowId: fileResponseUrl?.data?.fileStoreIds[0].id,
                createdDate: fileResponseUrl?.data?.fileStoreIds[0].uploadedDate,
                fileStoreId: fileResponseUrl?.data?.fileStoreIds[0].id
            };
            setIsLoading(false);
            props?.setReportDocsData([...props?.reportDocsData, fileData]);

            // props.handleOnFileUpdate(fileData);
        } catch (e) {
            setIsLoading(false);
        }
        // console.log(e.currentTarget.value.slice(12));
    };
    const removeFile = (id: any) => {
        const filteredData = props?.reportDocsData.filter((val: any) => val.rowId !== id);
        props?.setReportDocsData(filteredData);
    };

    const downloadFile = (id: any) => {
        axios
            .get(devUrl + `/filestore/v1/files/url?tenantId=ga&fileStoreIds=${id}`)
            .then((response: any) => {
                const documentName = response?.data?.fileStoreIds[0]?.tag;
                fetch(devUrl + `/filestore/v1/files/id?tenantId=ga&fileStoreId=${id}`, {
                    headers: {
                        'Content-Type': 'application/pdf'
                    }
                })
                    .then(response => {
                        return response.arrayBuffer().then(blob => {
                            const url = window.URL.createObjectURL(new Blob([blob]));
                            const link: any = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', `${documentName}`);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        });
                    })

                    .catch(error => console.error('Error downloading file:', error));
            })
            .catch(error => {});
    };

    useEffect(() => {
        if (
            !!props?.actionsData?.actionValidation &&
            props?.actionsData?.actionValidation['ACT_OC_SUBMIT_INSPECTION_REPORT']?.includes('validateInspectionReport') &&
            props?.fieldInspection?.planApprovalNo
        ) {
            const approvaldate = new Date(props?.fieldInspection?.planApprovalDate);
            const approvaldateFor = String(approvaldate.getDate()).padStart(2, '0') + '/' + String(approvaldate.getMonth() + 1).padStart(2, '0') + '/' + new Date(approvaldate).getFullYear();

            values.planapprovedDate = approvaldateFor;
            setTimeout(() => {
                setFieldValue('planapprovedDate', approvaldateFor);
            }, 2000);

            values.planapprovedNo = props?.fieldInspection?.planApprovalNo || values.planapprovedNo;
            values.appliedforoccupancy = values?.occupancy < 100;
            values.clvalidation = true;
        }
    }, [props?.fieldInspection, props?.actionsData]);

    const renderCheckList = () => {
        let formattedDate;
        if (!!props?.fieldInspection) {
            const date = new Date(props?.fieldInspection?.scheduledDate);
            formattedDate = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + new Date(date).getFullYear();
        }
        const inspectionDetails = props?.fieldInspection;
        if (!!formattedDate) values.inspDate = formattedDate;
        if (props?.fieldInspection?.scheduledTime) values.inspTime = props?.fieldInspection?.scheduledTime;
        const rescheduleData = FIELD_INSPECTION_DETAILS_DEFAULT;
        rescheduleData[0].title = 'Rescheduled inspection details';
        if (!!props?.fieldInspection?.requestForReschedule && !!props?.fieldInspection?.requestForRescheduleData?.scheduledTime) {
            const scheduledate = new Date(props?.fieldInspection?.requestForRescheduleData?.scheduledDate);
            const formattedDateFor = String(scheduledate.getDate()).padStart(2, '0') + '/' + String(scheduledate.getMonth() + 1).padStart(2, '0') + '/' + new Date(scheduledate).getFullYear();
            values.sheduleDate = formattedDateFor;
            values.sheduleTime = props?.fieldInspection?.requestForRescheduleData?.scheduledTime;
        }
        let modifiedArray = FIELD_INSPECTION_DETAILS;
        if (!!inspectionDetails?.requestForReschedule || !!inspectionDetails?.lesseeAcepted) {
            modifiedArray.map((v: any) => {
                v.formData.map((h: any) => (h.isDisabled = true));
            });
        }
        const reportform = FIELD_INSPECTION_DETAILS_II;
        values.planapprovedNo = props?.fieldInspection?.planApprovalNo || values?.planapprovedNo;
        values.appliedforoccupancy = values?.occupancy < 100;
        values.clvalidation = true;
        reportform.map((v: any) => {
            v.formData.map((h: any) => {
                if (h.field === 'planapprovedNo' && !!values?.planapprovedNo) h.isDisabled = true;
                if (h.field === 'planapprovedDate' && !!values?.planapprovedDate) h.isDisabled = true;
                if (h.field === 'clvalidation' || h.field === 'appliedforoccupancy') h.isDisabled = true;
            });
        });

        // {
        //     !!inspectionDetails &&
        //         inspectionDetails.map((val: any, ind: number) => {
        //             const modifiedFieldInspectionTable = INSPECTION_ACTIVITY_COLUMN;
        //             modifiedFieldInspectionTable.forEach((element: any) => {
        //                 if (element.colName == 'Action' && !!val?.scheduledTime) {
        //                     element.actions = ['view'];
        //                 }
        //                 if (element.colName == 'Action' && !val?.scheduledTime) {
        //                     element.actions = ['view', 'delete'];
        //                 }
        //             });
        //         });
        // }
        return (
            <>
                <div className='col-sm-12 pb-3 align-items-center pb-2 '>
                    {/* <Card className='card_border my-2'>
                        <Card.Body> */}
                    {modifiedArray.map(val => (
                        <Card.Body>
                            <GenericFormCard {...val} />
                        </Card.Body>
                    ))}

                    {!!inspectionDetails?.requestForReschedule &&
                        props?.fieldInspection?.requestForRescheduleData &&
                        rescheduleData.map(val => (
                            <Card.Body>
                                <GenericFormCard {...val} />
                            </Card.Body>
                        ))}

                    {!!inspectionDetails?.lesseeAcepted && inspectionDetails?.requestForRescheduleData !== null && (
                        <div className={`ps-2 d-flex gap-2 border my-3 rounded warning`} style={{ fontSize: '16px', padding: '12px' }}>
                            <div className='fw-bold'>
                                <img alt='' height={16} src={check} />
                            </div>
                            <span className='fw-medium'>The inspection request has been accepted by the Lessee.</span>
                        </div>
                    )}

                    {!!inspectionDetails?.requestForReschedule && inspectionDetails?.requestForRescheduleData !== null && (
                        <Card.Body>
                            <div className={`ps-2 d-flex gap-2 border my-3 rounded warning`} style={{ fontSize: '16px', padding: '12px' }}>
                                <div className='fw-bold'>
                                    <img alt='' height={16} src={check} />
                                </div>
                                <span className='fw-medium'>The lessee has rescheduled the inspection.</span>
                            </div>
                        </Card.Body>
                    )}

                    {!!props?.actionsData?.actionValidation && props?.actionsData?.actionValidation['ACT_OC_SUBMIT_INSPECTION_REPORT']?.includes('validateInspectionReport') && (
                        <>
                            <div className='col-sm-12 collapsible_box collapsible_box_checklist align-items-center my-2 mx-2 '>
                                {/* <Card className='card_border my-2'>
                                    <Card.Body> */}
                                {reportform.map((val, key) => (
                                    <GenericFormCard {...{ ...val }} key={key} />
                                ))}
                                {/* </Card.Body>
                                </Card> */}
                            </div>
                            {isLoading ? (
                                <ThreeDots visible={true} height='80' width='80' color='#45852e' radius='9' ariaLabel='three-dots-loading' wrapperStyle={{}} wrapperClass='' />
                            ) : (
                                <div
                                    className='col-12 note-attachfile text-center my-1 p-2 mt-2  mb-2 justify-content-center align-items-center noc_add_fields'
                                    style={{ margin: '0 auto', width: '97%' }}
                                >
                                    <Image src={attachmentIcon} alt='Attach Document Icon' /> <span className='cursor-pointer'>{t('FM_ADD_DOCUMENT', 'Add Document for Field inspection')}</span>
                                    <input className='uploadBox upload-document cursor-pointer' style={{ height: '50px' }} type='file' onChange={handleOnChange} />
                                </div>
                            )}

                            <div className='mt-3'>
                                <OwnershipTable
                                    column={INSPECTION_ACTIVITY_COLUMN}
                                    tableRowData={props?.reportDocsData}
                                    additionalMethod={() => {}}
                                    deleteOwnershipDetails={id => removeFile(id)}
                                    updateOwnershipDataStatus={(isView: any, data: any) => downloadFile(data?.rowId)}
                                    isDisabled={false}
                                    hideSearchBar
                                />
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    };

    const renderInspectionReport = () => {
        let formattedDate;
        if (!!props?.fieldInspection) {
            const date = new Date(props?.fieldInspection?.scheduledDate);
            formattedDate = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + new Date(date).getFullYear();
        }
        if (!!formattedDate) values.inspDate = formattedDate;
        if (props?.fieldInspection?.scheduledTime) values.inspTime = props?.fieldInspection?.scheduledTime;
        const inspectionRepoerts = props?.inspectionReport.filter((val: any) => !!val?.isClosed);
        return (
            <>
                {!!inspectionRepoerts &&
                    inspectionRepoerts.map((val: any, ind) => {
                        const additionalReport = val?.inspectionCheckListData;
                        values.planapprovedNo = additionalReport?.planapprovedNo;
                        values.clvalidation = additionalReport?.clvalidation;
                        values.notvalidreason = additionalReport?.notvalidreason;
                        values.appliedforoccupancy = additionalReport?.appliedforoccupancy;
                        values.clearedthedump = additionalReport?.clearedthedump;
                        values.sscertificatesubmission = additionalReport?.sscertificatesubmission;
                        values.ccsubmission = additionalReport?.ccsubmission;
                        // values.otherremark = additionalReport?.otherremark;
                        const date = new Date(additionalReport?.planapprovedDate);
                        formattedDate = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + new Date(date).getFullYear();
                        values.planapprovedDate = formattedDate;

                        return (
                            <>
                                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 my-2'>
                                    <input type='checkbox' id={`${val?.id}_${ind}`} name={`${val?.id}_${ind}`} defaultChecked={false} />
                                    <label htmlFor={`${val?.id}_${ind}`} className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold  '>
                                        {t('OC_EMP_REPORT_HEADING', `Inspection Report - ${ind + 1}`)}
                                    </label>
                                    <div className='form_fields px-2'>
                                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 '>
                                            {FIELD_INSPECTION_DETAILS_II.map((val, key) => (
                                                <GenericFormCard {...{ ...val }} key={key} />
                                            ))}
                                        </div>
                                        <div className='mt-3'>
                                            <OwnershipTable
                                                column={INSPECTION_ACTIVITY_COLUMN_DEFAULT}
                                                tableRowData={val?.documents || []}
                                                additionalMethod={() => {}}
                                                updateOwnershipDataStatus={(isView: any, data: any) => downloadFile(data?.fileStoreId)}
                                                isDisabled={false}
                                                hideSearchBar
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                {!!inspectionRepoerts && inspectionRepoerts.length === 0 && <p className='text-center fw-bold pt-2'>Inspection report data not available</p>}
            </>
        );
    };

    useEffect(() => {
        fetchGatewayData();
        getAuditData();
        getCheckListData();
    }, []);

    const renderBPASelection = () => {
        if (!props.allBPADetails || props.allBPADetails.length <= 0)
            return (
                <>
                    <p className='text-center fw-bold pt-2'>No Data Available</p>
                </>
            );
        const bpaList = getCLAList(props?.allBPADetails);
        return (
            <CLASelectionTable
                data={bpaList}
                selectedRow={values?.edcrNo}
                onSelectRow={row => {
                    setFieldValue('edcrNo', row?.value);
                    setFieldValue('percentage', row?.appliedCount);
                }}
            />
        );
    };
    const applicationData = {
        applicationNo: props?.applicationNumber,
        status: props?.previewData?.status,
        data: auditTrial,
        isRoleMD: true,
        bpaData: props?.bpaData,
        pendingWith: 'Manager'
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={props?.setContent} content={props?.previewData?.provisionalCertificateContent} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    const skipActionReport = ['ACT_OC_SCHEDULE_APPOINTMENT', 'ACT_OC_SUBMIT_INSPECTION_REPORT'];

    const renderCorrectionDetails = () => {
        return (
            <>
                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 my-2'>
                    <input type='checkbox' id='Primary Assignment' name='assignments' defaultChecked={true} />
                    <label htmlFor='Primary Assignment' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold  '>
                        {t('OC_EMP_REPORT_HEADING', `Correction Report`)}
                    </label>
                    <div className='form_fields px-2'>
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 '>
                            {QUESTIONNAIRE_DETAILS.map((val, key) => (
                                <GenericFormCard {...{ ...val }} key={key} />
                            ))}
                        </div>
                    </div>
                    <div className='mt-3'>
                        <OwnershipTable
                            column={QUESTIONNAIRE_DETAILS_ACTIVITY_COLUMN}
                            tableRowData={props?.reportDocsData}
                            additionalMethod={() => {}}
                            deleteOwnershipDetails={id => removeFile(id)}
                            updateOwnershipDataStatus={(isView: any, data: any) => downloadFile(data?.rowId)}
                            isDisabled={false}
                            hideSearchBar
                        />
                    </div>
                </div>
            </>
        );
    };

    console.log(props?.inspectionReport?.length, 'IR length');
    console.log(props?.inspectionReport, 'IR');
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <CardBody>
                    <div className='row'>
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 '>
                            <input type='checkbox' id='Primary Assignment' name='assignments' defaultChecked={true} />
                            <label htmlFor='Primary Assignment' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold  '>
                                {t('OC_EMP_CONDITION_CHECKLIST_HEADING', 'Condition/Check list')}
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE_TC} hideSearchBar={true} hideFooter={true} rowData={checklistTableData} />
                            </div>
                        </div>

                        {[
                            { id: 'OCCUPANCY_DETAILS', label: 'Occupancy Details', render: renderOccupancyDetails },
                            { id: 'LESSEE_DETAILS', label: 'Lessee Details', render: renderBasicDetails },
                            { id: 'PLOT_DETAILS', label: 'Plot Details', render: renderBuildingPlanDetails },
                            { id: 'DOCUMENTS', label: 'Documents', render: renderDocumentDetails },
                            { id: 'DECLARATION', label: 'Self Declaration', render: renderDeclarationDetails },
                            ...(props?.actionsData?.nextValidAction && props?.actionsData?.nextValidAction.filter((val: any) => skipActionReport.includes(val.action))?.length > 0
                                ? [{ id: 'INSPECTION', label: 'Field Inspection', render: renderCheckList }]
                                : []),
                            ...(props?.inspectionReport?.length > 0 ? [{ id: 'INSPECTION_REPORT', label: 'Field Inspection Report', render: renderInspectionReport }] : []),
                            ...(props?.actionsData?.nextValidAction &&
                            (props?.actionsData?.nextValidAction.filter((val: any) => val.action === 'ACT_ARM_NOC_FORWARD').length > 0 ||
                                (values?.FIRE_NOC?.length && values?.FACTORIES_AND_BOILERS_NOC?.length))
                                ? [{ id: 'NOC', label: 'NOC Details', render: renderNOCDocumentDetails }]
                                : []),
                            ...(props?.questionnaireDetails.length > 0 ? [{ id: 'CORRECTION', label: 'Questionnaire', render: props?.renderQuestionnaireDetails }] : []),
                            {
                                id: 'NOTES',
                                label: !!props?.previewData?.isCOIssused || props?.previewData?.isCOIssused === true ? 'Occupancy Certificate' : 'Completion Order',
                                render: renderNoteDetails
                            }
                        ].map((section, index) => (
                            <div key={section.id} className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                <input type='checkbox' id={`${section.id}_Checkbox`} name={section.id} checked={props?.openSection === section.id} onChange={() => props?.setOpenSection(section.id)} />
                                <label
                                    htmlFor={`${section.id}_Checkbox`}
                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {`${String.fromCharCode(65 + index)} - ${t(`OC_EMP_HEADING_${section.id.toUpperCase()}`, `${section.label}`)}`}
                                </label>
                                {props?.openSection === section.id && section.render()}
                            </div>
                        ))}
                    </div>
                </CardBody>
            </Card>

            <GenericPopupModal
                children={renderBPASelection()}
                isPrimaryDisabled={!values?.edcrNo}
                isVisible={isMultiLessee}
                title='Select CL Application'
                primaryAction={proceedToBPA}
                secondaryAction={handelIsLesseeVisible}
                primaryBtnTitle='Proceed'
                secondaryBtnTitle='Cancel'
                size='lg'
            />
        </>
    );
}
