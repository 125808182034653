import { useTranslation } from 'react-i18next';
import './Plot.css';

const DashboardCards = (props: any) => {
    const { t } = useTranslation();
    console.log('props>>', props);
    // Determine if the text should be white
    const isWhiteText = props.name.includes('%') || props.name.includes('cr') || props.count.includes('%') || props.count.includes('cr');
    const feeIncu = props.name.includes('Fee');

    return (
        <div className={`col-12 col-sm-6 col-md-4 col-lg-3 mb-3 ${props?.index !== 0 ? 'ps-3' : ''}`}>
            <div className='border Estate p-4' style={{ backgroundColor: props.bgcolor, borderRadius: '0px', height: '11rem' }}>
                <div className='ps-2 d-flex flex-column justify-content-start align-items-start'>
                    <div>
                        <p className='card-dash-title' style={{ marginBottom: '10px', color: isWhiteText ? '#fff' : '#2C3136', paddingRight: feeIncu ? '200px' : '150px' }}>
                            {props.name}
                        </p>
                    </div>
                    <div>
                        <p className='card-dash' style={{ color: isWhiteText ? '#fff' : '#2C3136' }}>
                            {props.count}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardCards;
