import { isDisabled } from '@testing-library/user-event/dist/utils';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../utils/constants';
import { DOCUMENT_TYPES_MGR } from '../modules/Migration/Migration.const';

export const LESSEE_DETAILS: FormDataDTO[] = [
    {
        title: 'Lessee Details',
        formData: [
            {
                title: 'Enterprise Name',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_ENTERPRISE_NAME'
            },
            {
                title: 'Email ID',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_EMAILID'
            },

            {
                title: 'Phone Number',
                field: 'phone',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_PHONENO'
            },

            {
                title: 'PAN No.',
                field: 'panNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_PANNO'
            }
        ]
    },
    {
        title: 'Address',
        formData: [
            {
                title: 'Address Line 1',
                field: 'addressLine1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_ADDRESSLINE1'
            },
            {
                title: 'Address Line 2',
                field: 'addressLine2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                titleCode: 'OC_LBL_ADDRESSLINE2'
            },
            {
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_PINCODE'
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_DISTRICT'
            },
            {
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_STATE'
            },
            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_COUNTRY'
            }
        ]
    }
];

export const OCCUPANCY_DETAILS: FormDataDTO[] = [
    {
        title: 'Occupancy Details',
        formData: [
            {
                // title: 'Type of Occupancy',
                title: '',
                field: 'occupancyType',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                // isRequired: true,
                class: 'lg',
                // titleCode: 'OC_LBL_TYPES_OF_OCCUPANCY',
                options: [
                    {
                        name: 'Full Occupancy',
                        title: 'Full Occupancy',
                        value: 'FULL_OCCUPANCY',
                        titleCode: 'OC_LBL_FULL_OCCUPANCY',
                        subcategory: []
                    },
                    {
                        name: 'Part Occupancy',
                        title: 'Part Occupancy',
                        value: 'PART_OCCUPANCY',
                        titleCode: 'OC_LBL_PART_OCCUPANCY',
                        subcategory: []
                    }
                ]
            },
            {
                title: 'Occupancy Percent',
                field: 'occupancy',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_OCCUPANCY_PERCENT',
                isDisabled: true
            }
        ]
    }
];

export const PART_OCCUPANCY_DETAILS: FormDataDTO[] = [
    {
        title: '',
        formData: [
            {
                title: 'FAR',
                field: 'far',
                type: 'number',
                isRequired: true,
                titleCode: 'OC_LBL_OCCUPANCY_FAR'
            },
            {
                title: 'Coverage Area',
                field: 'coverageArea',
                type: 'number',
                isRequired: true,
                titleCode: 'OC_LBL_OCCUPANCY_COVERAGE_AREA'
            },
            {
                title: 'Coverage Percentage',
                field: 'coveragePercent',
                type: 'number',
                isRequired: true,
                titleCode: 'OC_LBL_OCCUPANCY_COVERAGE_PERCENT'
            }
        ]
    }
];

export const FIELD_INSPECTION_DETAILS: any[] = [
    {
        title: 'Inspection Details',
        titleCode: 'OC_CARD_HEADER_REQUIRED_INSPECTION_DETAILS',
        formData: [
            {
                title: 'Inspection Date',
                field: 'inspDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true,
                disablePastDate: true
            },
            {
                title: 'Inspection Time',
                field: 'inspTime',
                type: CONSTANT_DATA_FIELD.TIME_FIELD,
                isRequired: true
            }
        ]
    }
];

export const FIELD_INSPECTION_DETAILS_DEFAULT: FormDataDTO[] = [
    {
        title: 'Inspection Details',
        titleCode: 'OC_CARD_HEADER_REQUIRED_INSPECTION_DETAILS_NEW',
        formData: [
            {
                title: 'Inspection Date',
                field: 'sheduleDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                title: 'Inspection Time',
                field: 'sheduleTime',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const FIELD_INSPECTION_DETAILS_II: any[] = [
    {
        title: 'Field Inspection Report',
        titleCode: 'OC_CARD_HEADER_REQUIRED_DO_INSPECTION_REPORT',
        formData: [
            {
                titleCode: 'OC_EMP_INSP_PLAN_APPROVED_NO',
                title: 'Plan Approved No.',
                field: 'planapprovedNo',
                class: 'col-sm-6',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'OC_EMP_INSP_PLAN_APRROVED_DATE',
                title: 'Plan Approved Date',
                field: 'planapprovedDate',
                class: 'col-sm-6',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true,
                disablePastDate: true
            },
            {
                titleCode: 'OC_EMP_INSP_CONSTRUCTION_LICENSE',
                title: 'Construction License',
                field: 'clvalidation',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                class: 'col-sm-6',
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Whether Construction License valid or not?</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                },
                isDisabled: true
            },
            {
                titleCode: 'OC_EMP_INSP_IF_NOT_DETAILS_THE_REASON_THEREOF',
                title: 'If not detail the reason thereof',
                field: 'notvalidreason',
                class: 'col-sm-6',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: true
            },
            {
                titleCode: 'OC_EMP_INSP_DEBRIS_AND_GARBAGE_DUMMP',
                title: 'debris and garbage dump',
                field: 'clearedthedump',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                class: 'col-sm-6',
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Has the applicant cleared the debris and garbage dump on roads & around the unit or not?</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            },
            {
                titleCode: 'OC_EMP_INSP_OCCUPANCY_TYPE',
                title: 'Occupancy Type',
                field: 'appliedforoccupancy',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                class: 'col-sm-6',
                isDisabled: true,
                options: [
                    { value: true, title: 'Part Occupancy' },
                    { value: false, title: 'Full Occupancy' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Whether party have applied for part occupancy or full occupancy (Mention the area for which occupancy to be given)</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            },
            {
                titleCode: 'OC_EMP_INSP_STRUCTURAL_STABILTIY_CERT',
                title: 'Structural Stability Certificate',
                field: 'sscertificatesubmission',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                class: 'col-sm-6',
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Structural Stability Certificate submitted or not?</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            },
            {
                titleCode: 'OC_EMP_INSP_COMPLETION_CERT',
                title: 'Completion Certificate',
                field: 'ccsubmission',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                class: 'col-sm-6',
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Completion Certificate submitted or not?</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            }
            // {
            //     titleCode: 'OC_EMP_INSP_ANY_OTHER_REMARK',
            //     title: 'Any other remarks',
            //     field: 'otherremark',
            //     class: 'col-sm-6',
            //     type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
            //     isRequired: true
            // }
            // {
            //     titleCode: 'OC_EMP_INSP_UPLD_FIELD_INSPECT',
            //     title: 'Field Inspection Document',
            //     field: 'FIELD_INSP_SITEPLAN',
            //     type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
            //     isRequired: true,
            //     class: 'lg',
            //     width: 'half'
            // }
            // {
            //     titleCode: 'OC_EMP_INSP_UPLD_FIELD_INSPECT',
            //     title: 'Field Inspection Document',
            //     field: 'FIELD_INSP_SITEPLAN',
            //     type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
            //     isRequired: true,
            //     class: 'lg',
            //     width: 'half'
            // }
            // {
            //     titleCode: 'Field Inspection Document - 2',
            //     title: 'Field Inspection Document - 2',
            //     field: 'FIELD_INSP_SITEPLAN_OTHER',
            //     type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
            //     isRequired: true,
            //     class: 'lg',
            //     width: 'half'
            // }
        ]
    }
];

export const INSPECTION_ACTIVITY_COLUMN_TEMP: any = [
    {
        colName: 'Appointment Date',
        fieldName: 'scheduledDate',
        cellstyle: { width: '250px' },
        id: 1
    },
    {
        colName: 'Appointment Time',
        fieldName: 'scheduledTime',
        cellstyle: { width: '250px' },
        id: 1
    },

    {
        colName: 'Is Lessee Accepted',
        fieldName: 'lesseeAcepted',
        cellstyle: { width: '250px' },
        id: 1
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['', 'view']
    }
];

// Function to determine the appropriate width based on screen size
function getResponsiveCellStyleForFieldInspection(fieldName: string) {
    const screenWidth = window.innerWidth;

    // if (screenWidth <= 1920) {
    // Styles for screens 1920px and above
    // Styles for screens smaller than 1920px
    // switch (fieldName) {
    //     case 'serialNo':
    //         return { width: '80px' };
    //     case 'documentName':
    //         return { width: '250px' };
    //     case 'fileSize':
    //         return { width: '200px' };
    //     case 'createdDate':
    //         return { width: '200px' };
    //     case 'action':
    //         return { width: '200px' };
    // }

    switch (fieldName) {
        case 'serialNo':
            return { width: '80px' };
        case 'documentName':
            return { width: '300px' };
        case 'fileSize':
            return { width: '180px' };
        case 'createdDate':
            return { width: '200px' };
        case 'action':
            return { width: '220px' };
    }
}

// Function to determine the appropriate width based on screen size
function getResponsiveCellStyleForQuestionnaire(fieldName: string) {
    const screenWidth = window.innerWidth;

    // if (screenWidth <= 1920) {
    //     // Styles for screens 1920px and above
    //     switch (fieldName) {
    //         case 'serialNo':
    //             return { width: '155px' };
    //         case 'documentName':
    //             return { width: '315px' };
    //         case 'fileSize':
    //             return { width: '265px' };
    //         case 'createdDate':
    //             return { width: '265px' };
    //         case 'action':
    //             return { width: '265px' };
    //     }
    // } else if (screenWidth <= 1366) {
    //     // Styles for screens smaller than 1920px
    //     switch (fieldName) {
    //         case 'serialNo':
    //             return { width: '80px' };
    //         case 'documentName':
    //             return { width: '250px' };
    //         case 'fileSize':
    //             return { width: '200px' };
    //         case 'createdDate':
    //             return { width: '200px' };
    //         case 'action':
    //             return { width: '200px' };
    //     }
    // } else {
    switch (fieldName) {
        case 'serialNo':
            return { width: '100px' };
        case 'documentName':
            return { width: '300px' };
        case 'fileSize':
            return { width: '220px' };
        case 'createdDate':
            return { width: '230px' };
        case 'action':
            return { width: '240px' };
    }
    // }
}
export const INSPECTION_ACTIVITY_COLUMN: any = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        // cellstyle: getResponsiveCellStyleForFieldInspection('serialNo'),
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Description',
        fieldName: 'documentName',
        // cellstyle: getResponsiveCellStyleForFieldInspection('documentName'),
        id: 1,
        clsName: 'col-sm-3'
    },
    {
        colName: 'Size',
        fieldName: 'fileSize',
        // cellstyle: getResponsiveCellStyleForFieldInspection('fileSize'),
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Date',
        fieldName: 'createdDate',
        // cellstyle: getResponsiveCellStyleForFieldInspection('createdDate'),
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'col-sm-3 fixed-action-column fixed-action-column-bg ',
        actions: ['download', 'delete']
        // cellstyle: getResponsiveCellStyleForFieldInspection('action')
    }
];

export const INSPECTION_ACTIVITY_COLUMN_DEFAULT: any = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        // cellstyle: getResponsiveCellStyleForFieldInspection('serialNo'),
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Description',
        fieldName: 'documentName',
        // cellstyle: getResponsiveCellStyleForFieldInspection('documentName'),
        id: 1,
        clsName: 'col-sm-3'
    },
    {
        colName: 'Size',
        fieldName: 'fileSize',
        // cellstyle: getResponsiveCellStyleForFieldInspection('fileSize'),
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Date',
        fieldName: 'createdDate',
        // cellstyle: getResponsiveCellStyleForFieldInspection('createdDate'),
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'col-sm-3 fixed-action-column fixed-action-column-bg',
        actions: ['download']
        // cellstyle: { width: '200px' },
    }
];

export const QUESTIONNAIRE_DOCUMENT_COLUMN: any = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('serialNo'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Description',
        fieldName: 'documentName',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('documentName'),
        type: 'text',
        clsName: 'col-sm-3',
        id: 1
    },
    {
        colName: 'Size',
        fieldName: 'fileSize',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('fileSize'),
        type: 'text',
        clsName: 'col-sm-2',
        id: 1
    },
    {
        colName: 'Date',
        fieldName: 'createdDate',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('createdDate'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'col-sm-3 fixed-action-column fixed-action-column-bg',
        actions: ['view', 'delete']
        // cellstyle: getResponsiveCellStyleForFieldInspection('action')
    }
];

export const QUESTIONNAIRE_DOCUMENT_COLUMN_DEFAULT: any = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('serialNo'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Description',
        fieldName: 'documentName',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('documentName'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-3'
    },
    {
        colName: 'Size',
        fieldName: 'fileSize',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('fileSize'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Date',
        fieldName: 'createdDate',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('createdDate'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'col-sm-3 fixed-action-column fixed-action-column-bg',
        actions: ['view']
        // cellstyle: getResponsiveCellStyleForQuestionnaire('action')
    }
];

export const EMP_QUESTIONNAIRE_DOCUMENT_COLUMN: any = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('serialNo'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Description',
        fieldName: 'documentName',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('documentName'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-3'
    },
    {
        colName: 'Size',
        fieldName: 'fileSize',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('fileSize'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Date',
        fieldName: 'createdDate',
        // cellstyle: getResponsiveCellStyleForQuestionnaire('createdDate'),
        type: 'text',
        id: 1,
        clsName: 'col-sm-2'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'col-sm-2 fixed-action-column fixed-action-column-bg',
        actions: ['view']
        // cellstyle: getResponsiveCellStyleForQuestionnaire('action')
    }
];

export const INSPECTION_ACTIVITY_COLUMN_LESSEE: any = [
    {
        colName: 'Appointment Date',
        fieldName: 'scheduledDate',
        cellstyle: { width: '250px' },
        id: 1
    },
    {
        colName: 'Appointment Time',
        fieldName: 'scheduledTime',
        cellstyle: { width: '250px' },
        id: 1
    },

    {
        colName: 'Is Lessee Accepted',
        fieldName: 'lesseeAcepted',
        cellstyle: { width: '250px' },
        id: 1
    },
    {
        colName: 'Action',
        type: 'action',
        cellstyle: { width: '520px' },
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: []
    }
];

// export const BUILDING_PLAN_DETAILS: FormDataDTO[] = [{
//   title: "Scrutiny Details",
//   formData: [

//     {
//       title: "Service Type",
//       field: "serviceType",
//       type: CONSTANT_DATA_FIELD.SELECT_FIELD,
//       isRequired: true,
//       options: [
//         {
//           name: "New Construction",
//           title: "New Construction",
//           value: "NEW_CONSTRUCTION",
//           subcategory: [],
//         },
//         {
//           name: "Addition and Alteration",
//           title: "Addition and Alteration",
//           value: "ADDITION_ALTERATION",
//           subcategory: [],
//         },
//         {
//           name: "Reconstruction",
//           title: "Reconstruction",
//           value: "RE_CONSTRUCTION",
//           subcategory: [],
//         },
//       ]
//     },
//     {
//       title: "Building Plan",
//       field: "buildingPlan",
//       type: CONSTANT_DATA_FIELD.MULTI_FILE_FIELD,
//       class: "lg",
//       isRequired: true,
//       size: "10",
//       fileTypes: ["DXF"],

//     },
//   ]
// }];

export const DOCUMENTS_DETAILS: FormDataDTO[] = [
    {
        title: '',
        formData: [
            {
                titleCode: 'OC_UPLD_CONSTRUCTION_LICENSE_ORDER',
                title: 'Construction License Order',
                field: 'CONSTRUCTION_LICENSE_ORDER',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
                    documentType: DOCUMENT_TYPES_MGR.ORDER_COMPLETION_DOCUMENTS.CONSTRUCTION_LICENSE_ORDER
                }
            },
            {
                titleCode: 'OC_UPLD_TECHNICAL_CLEARANCE_ORDER',
                title: 'Technical Clearance Order',
                field: 'TECHNICAL_CLEARANCE_ORDER',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
                    documentType: DOCUMENT_TYPES_MGR.ORDER_COMPLETION_DOCUMENTS.TECHNICAL_CLEARANCE_ORDER
                }
            }
        ]
    },
    {
        title: 'Required Documents',
        // titleCode: 'OC_CARD_HEADER_REQUIRED_DOC',
        formData: [
            {
                titleCode: 'OC_UPLD_COMPLETION_CERT_BY_ARCHITECH',
                title: 'Completion certificate',
                field: 'COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
                    documentType: DOCUMENT_TYPES_MGR.ORDER_COMPLETION_DOCUMENTS.COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER
                },
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Completion certificate issued by Architect/Engineer</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            },
            {
                titleCode: 'OC_UPLD_REVISED_PLANS',
                title: 'Revised plans',
                field: 'REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: false,
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
                    documentType: DOCUMENT_TYPES_MGR.ORDER_COMPLETION_DOCUMENTS.REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION
                },
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Revised plans incorporating deviations made during the execution (if applicable)</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            },
            {
                titleCode: 'OC_UPLD_STRUCTURAL_STABILITY_CERT',
                title: 'Structural Stability Certificate',
                field: 'STRUCTURAL_STABILITY_CERTIFICATE',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
                    documentType: DOCUMENT_TYPES_MGR.ORDER_COMPLETION_DOCUMENTS.STRUCTURAL_STABILITY_CERTIFICATE
                },
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Structural Stability Certificate</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            }
        ]
    }
];

export const NOC_DOCUMENTS_DETAILS: FormDataDTO[] = [
    {
        title: '',
        formData: [
            {
                titleCode: 'OC_EMP_NOC_UPLD_FIRE_NOC',
                title: 'Fire NOC',
                field: 'FIRE_NOC',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPES_MGR.ORDER_COMPLETION_NOC_DOCUMENTS.FIRE_NOC
                }
            },
            {
                titleCode: 'OC_EMP_NOC_UPLD_FACTORIES_BOILERS_NOC',
                title: 'Factories and Boilers NOC',
                field: 'FACTORIES_AND_BOILERS_NOC',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPES_MGR.ORDER_COMPLETION_NOC_DOCUMENTS.FACTORIES_AND_BOILERS_NOC
                }
            }
        ]
    }
];

export const PLOT_DETAILS: FormDataDTO[] = [
    {
        title: 'Plot Details',
        // titleCode: 'OC_CARD_HEADER_PLOT_DETAILS',
        formData: [
            {
                title: 'Estate Name',
                field: 'estate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_ESTATE',
                isRequired: true
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_PHASE',
                isRequired: true
            },
            {
                title: 'Plot No',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_PLOTNO',
                isRequired: true
            },
            {
                title: 'Property Type',
                field: 'propertyType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_PROPERTY_TYPE',
                isRequired: true
            },
            // {
            //     title: 'Plot Rate (INR/Sq.m.)',
            //     field: 'plotRate',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     titleCode: 'OC_LBL_PLOT_RATE'
            // },
            {
                title: 'Plot Area (Sq.m.)',
                field: 'plotArea',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_PLOT_AREA'
            }
        ]
    }
];

export const BPA_ACTIONS_DETAILS: FormDataDTO[] = [
    {
        title: '',
        formData: [
            {
                title: 'Comments',
                field: 'comments',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: false,
                class: 'long'
            }
        ]
    }
];

export const QUESTIONNAIRE_DETAILS: FormDataDTO[] = [
    {
        title: 'Query Details',
        formData: [
            {
                title: 'Question',
                field: 'questionQuery',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                titleCode: 'OC_LBL_ENTERPRISE_NAMEs',
                class: 'me-2'
            },
            {
                title: 'Answer',
                field: 'answerQuery',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: true,
                titleCode: 'Your Feedback',
                class: 'me-2'
            }
        ]
    }
];

export const QUESTIONNAIREPOPUP_DETAILS: FormDataDTO[] = [
    {
        title: 'Query Details',
        formData: [
            {
                title: 'Query 1',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                // isDisabled: true,
                titleCode: 'OC_LBL_ENTERPRISE_NAMEs',
                class: 'me-2'
            }
        ]
    }
];

export const QUESTIONNAIRE_DETAILS_ACTIVITY_COLUMN: any = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '80px' },
        id: 1
    },
    {
        colName: 'Description',
        fieldName: 'documentName',
        cellstyle: { width: '250px' },
        id: 1
    },

    {
        colName: 'Size',
        fieldName: 'documentSize',
        cellstyle: { width: '200px' },
        id: 1
    },

    {
        colName: 'Date',
        fieldName: 'uploadedDate',
        cellstyle: { width: '200px' },
        id: 1
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['view', 'delete'],
        cellstyle: { width: '200px' }
    }
];
