import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const GenericImageViewer = (props: any) => {
    const url = 'https://www.planetware.com/wpimages/2020/01/india-in-pictures-beautiful-places-to-photograph-amer-fort-jaipur.jpg';
    const { imageUrl = url } = props;

    return (
        <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                    <TransformComponent>
                        <img src={imageUrl} alt='test' height={500} style={{ width: '100%' }} />
                    </TransformComponent>
                </>
            )}
        </TransformWrapper>
    );
};

export default GenericImageViewer;
