import React, { useEffect, useState } from 'react';
import { BILL_GENERATION_DETAILS_FORM_EMP, BILL_HISTORY_DETAILS_FORM_EMP, INSTALLATION_DETAILS, modifyPlotList, WATER_CONNECTION_FORM_STEPS } from './WaterConnection.const';
import HeaderComponent from '../../components/header/Header.component';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { convertToINR, fetchLeaseDurationDoc, getOptions, getQueryParams, paymentCheck, paymentPayload } from '../modules/EstateManagement/EstateManagement.controller';
import { BPArequestInfo, POST, POST2, endpoints, estateEndpoints, getNextValidAction, requestInfo } from '../../services/modulesServices/userManagement.api';
import { check, cross, user } from '../../utils/Logo';
import { TRANSFER_VALIDATION } from '../../utils/formikValidation/FormikValidation';
import { toast } from 'react-toastify';
import { getFirstErrorKey, getNavigateTo } from '../../utils/utils';
import { RazorpayOptions } from '../modules/UserManagement/types';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { Button, Card, CardBody } from 'react-bootstrap';
import Loading from '../../components/portalComponents/Loading.component';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { Formik, FormikProvider, useFormik } from 'formik';
import PaymentDetails from '../modules/EstateManagement/Allotment/PaymentDetails';
import { CorrespondenceWrapper, NotingWrapper } from '../../components/Workflow/NotingWrapper';
import { Clearance_Form_Initial_Data } from '../scrutinyFlowScreen/ScrutinyFormPage.module';
import GenericFooterComponent from '../../components/moduleCommonFormik/GenericFooterStepper.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import moment from 'moment';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../utils/constants';

const M_WaterConnectionBillGeneration = () => {
    const initialValues = Clearance_Form_Initial_Data;
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [transferorDetails, setTransferorDetails] = useState<any>({});
    const [transferDetails, setTransferDetails] = useState<any>(null);
    const [selectedTransfereeDetails, setSelectedTransfereeDetails] = useState<any>({});
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [waterConnectionData, setWaterConnectionData] = useState<any>([]);
    const [rowData, setRowData] = useState(null);
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;

    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    let navigate = useNavigate();
    const stepsDetails = WATER_CONNECTION_FORM_STEPS;
    const [paymentData, setPaymentData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [openSection, setOpenSection] = useState<string>('CONNECTION_DETAILS');
    const [actionsData, setActionsData] = useState<any>(null);

    const [searchParams] = useSearchParams();
    const location: any = useLocation();
    const [comment, setcomment] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNumber = urlParams.get('applicationNumber') || '';
    const [allotmentDetails, setAllotmentDetails] = useState<any>(null);
    const [plotDetails, setPlotDetails] = useState<any>(null);
    const [paymentRowData, setPaymentRowData] = useState<any>([]);

    const [plotList, setPlotList] = useState<any>([]);
    const [certificateData, setCertificateData] = useState<any>([]);
    const [selectedCertificate, setSelectedCertificate] = useState<any>(null);
    const [selectedOneCert, setSelectedOneCert] = useState<any>(null);
    // let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    const [waterConnectionBillData, setWaterConnectionBillData] = useState<any>([]);
    const [waterConnectionBillHistoryData, setWaterConnectionBillHistoryData] = useState<any>([]);
    const [billHistory, setBillHistory] = useState<any>([]);
    const [meterReadingfromCalculator, setMeterReadingfromCalculator] = useState<any>([]);
    const [meterNumber, setMeterNumber] = useState<any>([]);

    const formik: any = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            isSpecialProject: false
        },
        onSubmit: value => {}
        // validationSchema: TRANSFER_VALIDATION[`page${activeIndex}`]
    });
    const { values, setValues, errors, setFieldTouched } = formik;

    const handleStepClick = (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const CERTIFICATES_COLUMNS = [
        {
            colName: 'Select',
            fieldName: 'radio',
            selectedValue: selectedOneCert,
            clsName: 'col-sm-1',
            id: 0
        },
        {
            colName: 'SN',
            fieldName: 'index',
            clsName: 'col-sm-1',
            id: 1
        },
        {
            colName: 'Application No',
            fieldName: 'applicationNumber',
            clsName: 'col-sm-3 column-text-start',
            id: 2
        },
        {
            colName: 'Document Name',
            fieldName: 'documentName',
            clsName: 'col-sm-3',
            id: 3
        },
        {
            colName: 'Document Type',
            fieldName: 'documentType',
            clsName: 'col-sm-3',
            id: 4
        }
    ];

    const BILL_HISTORY_COLUMNS = [
        {
            colName: 'SN',
            fieldName: 'index',
            clsName: 'col-sm-1',
            cellstyle: { width: '250px' },
            id: 0
        },
        {
            colName: 'Bill No',
            fieldName: 'billNo',
            clsName: 'col-sm-2',
            // cellstyle: { width: '250px'column-text-start },
            id: 1
        },
        {
            colName: 'Date',
            fieldName: 'currentReadingDate',
            clsName: 'col-sm-1',
            // cellstyle: { width: '250px' },
            id: 2
        },
        {
            colName: 'Last meter reading',
            fieldName: 'lastReading',
            clsName: 'col-sm-2',
            // cellstyle: { width: '250px' },
            id: 3
        },
        {
            colName: 'Current meter reading',
            fieldName: 'currentReading',
            clsName: 'col-sm-2',
            // cellstyle: { width: '250px' },
            id: 3
        },
        {
            colName: 'Payment Status',
            fieldName: 'paymentStatus',
            clsName: 'col-sm-2',
            // cellstyle: { width: '250px' },
            id: 3
        },
        {
            colName: 'Bill Amount (in INR)',
            fieldName: 'monthlyWaterCharges',
            clsName: 'col-sm-2',
            // cellstyle: { width: '250px' },
            id: 3
        }
    ];
    useEffect(() => {
        if (applicationNumber) {
            getActionsData();
        }
    }, [applicationNumber]);

    const fetchDetails = (plotID: any, entityId: any) => {
        fetchAllotmentDetails(plotID, entityId);
        fetchPlotDetails(entityId);
    };

    const fetchAllotmentDetails = async (plotID: any, entityId: any) => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.allotmentDetailsSearch + `?plotIds=${plotID}&entityIds=${entityId}`, { RequestInfo: requestInfo() });
            setAllotmentDetails(data.allotmentDetails[0]);
            // values.plotNo = plotDetails?.plotNo;
            values.allotmentOrderNo = data.allotmentDetails[0]?.allotmentId;
            values.allotmentOrderDate = data.allotmentDetails[0]?.allotmentDate;

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const fetchPlotDetails = async (entityId: any) => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.entityRefDetails + `?isPlotDetailsRequired=true&types=ALLOTTEE,LESSEE,TRANSFEROR&entityIds=${entityId}`, { RequestInfo: requestInfo() });
            setPlotDetails(data.entityRefDetails[0]?.plotDetails);
            values.estate = data.entityRefDetails[0]?.plotDetails?.estateDetails?.estateName;
            values.plotType = data.entityRefDetails[0]?.plotDetails?.plotType;
            values.phase = data.entityRefDetails[0]?.plotDetails?.phase;

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (applicationNumber) {
            const fetchWaterBillDetails = async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.waterConnectionBillSearch + `?billNo=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setWaterConnectionBillData(data);
                    setMeterNumber(data?.meterReadings[0].meterNo);
                    console.log('data:', data);
                    values.plotNo = data?.meterReadings[0]?.connectionDetails?.plotDetails?.uuid;
                    fetchDetails(data?.meterReadings[0]?.connectionDetails?.plotDetails?.uuid, data?.meterReadings[0]?.connectionDetails?.leasseeDetails?.uuid);
                    // setCertificateData(data[0]?.applicationDetails?.documents);
                    // getPaymentData(data[0]?.applicationDetails?.uuid);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    console.error('Error fetching data:', error);
                }
            };
            if (applicationNumber) {
                fetchWaterBillDetails();
                // getActionsData();
            }
        }
    }, [applicationNumber]);

    useEffect(() => {
        if (applicationNumber) {
            const fetchWaterBillHistoryDetails = async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.waterConnectionBillSearch + `?billNo=${applicationNumber}&isHistoryRequired=true&limit=11`, { RequestInfo: requestInfo() });
                    setWaterConnectionBillHistoryData(data);
                    console.log('data:', data);
                    values.plotNo = data[0]?.applicationDetails?.plotDetails?.uuid;
                    // getPlotData(data[0]?.applicationDetails?.leasseeDetails?.uuid);
                    // fetchDetails(data[0]?.applicationDetails?.plotDetails?.uuid, data[0]?.applicationDetails?.leasseeDetails?.uuid);
                    // Add index to each entry in meterReadingsHistory
                    const meterReadingsWithIndex = data?.meterReadingsHistory?.map((entry: any, index: any) => ({
                        ...entry,
                        index: index + 1
                    }));

                    setBillHistory(meterReadingsWithIndex);
                    // getPaymentData(data[0]?.applicationDetails?.uuid);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    console.error('Error fetching data:', error);
                }
            };
            if (applicationNumber) {
                fetchWaterBillHistoryDetails();
                // getActionsData();
            }
        }
    }, [applicationNumber]);

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const lastSubmit = (isSaveOnly?: boolean, status?: string, comment?: string) => {
        if (activeIndex === 3 && !isPaid) onPay();
        else onSubmit(isSaveOnly);
    };

    const onSubmit = async (isSaveOnly?: boolean) => {
        if (activeIndex === 4 && !selectedTransfereeDetails?.item) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select at least one asignee', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 1 && !values.plotUuid) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select plot!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeIndex === 3 && isPaid) {
            setActiveIndex(4);
            return;
        }

        const payload: any = {
            RequestInfo: requestInfo(),
            isBuiltUpAreaUtilised: values.isBuiltUpAreaUtilised,
            isTransferProhibited: values.isTransferProhibited,
            isTransfereeDisqualified: values.isTransfereeDisqualified,
            isIndustrialCommercial: values.isIndustrialCommercial,
            plotId: values.plotUuid || null,
            isSpecialTransfer: false,
            transferorDetails,
            transfereeDetails: selectedTransfereeDetails?.item || null,
            nocSpecialProjectDocument: values.nocFromGoaIpb?.docReferenceId
        };

        if (transferDetails) {
            payload['uuid'] = transferDetails.uuid;
            payload['applicationDetails'] = transferDetails.applicationDetails;
            payload['status'] = transferDetails.status;
            payload['auditDetails'] = transferDetails.auditDetails;
        }

        if (activeIndex === 4 && selectedTransfereeDetails?.item) {
            payload['isTransferorFinalSubmit'] = true;
        }

        const apiEndPoint = transferDetails ? endpoints.transferUpdate : endpoints.transferCreate;

        try {
            await TRANSFER_VALIDATION[`page${activeIndex}`]?.validate(values, { abortEarly: false, context: values });
            try {
                const { data } = await POST(apiEndPoint, payload);
                setTransferDetails(data?.transferDetails[0]);
                if (activeIndex === 1) {
                    toast('Application Created', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                } else if (activeIndex === 2) {
                    toast('Data saved successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
                }
                !isSaveOnly && onChangeNextIndex();
                if (activeIndex === 4) {
                    setModalDetails({ show: true, title: 'Success!', para: 'Application assigned successfully', img: check, type: 'success', reUpload: 'OK' });
                }
            } catch (error: any) {
                let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            console.log(errors, e);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const fetchPayDetails = (data: any) => {
        return [
            {
                paymentType: '',
                principalAmount: +data?.securityDeposit,
                cgstPrincipal: null,
                sgstPrincipal: null,
                totalDueAmount: +data?.securityDeposit,
                feeTypeDisplayName: 'Security Deposit',
                cgstAmount: '0',
                sgstAmount: '0',
                amount: convertToINR(data?.securityDeposit),
                total: convertToINR(data?.securityDeposit),
                index: 1
            },
            {
                paymentType: '',
                principalAmount: +data?.waterConnectionCharge,
                cgstPrincipal: null,
                sgstPrincipal: null,
                totalDueAmount: +data?.waterConnectionCharge,
                feeTypeDisplayName: 'Water Connection Charge',
                cgstAmount: data?.cgst,
                sgstAmount: data?.sgst,
                amount: convertToINR(data?.waterConnectionCharge),
                total: convertToINR(data?.totalWaterConnectionCharge),
                index: 2
            }
        ];
    };

    const onPay = async () => {
        setIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/transfer?plotId=${values.plotUuid}&activeIndex=${activeIndex}&applicationNo=${transferDetails?.applicationDetails?.applicationNo}&entityId=${transferorDetails.uuid}`;
        const periodTo = new Date(transferDetails?.auditDetails?.createdTime).setFullYear(new Date(transferDetails?.auditDetails?.createdTime).getFullYear() + 1);
        const payload = paymentPayload(
            transferDetails?.applicationDetails,
            values.plotUuid,
            totalPaidAmount,
            url,
            'Transfer Application Fees',
            selectedGateway,
            transferDetails.auditDetails.createdTime,
            periodTo,
            paymentData
        );

        try {
            if (!totalPaidAmount) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (!selectedGateway) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (transferDetails?.applicationDetails?.applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);
                const data1 = data;
                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, getUser, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    console.log(data1.additionalDetails);
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data1.additionalDetails.walletClientCode}&walletRequestMessage=${data1.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
                setIsLoading(false);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            //Fetch Transfer Lessee Details
            try {
                const { data } = getUser.uuid && (await POST(endpoints.searchExistingUser + `?userUuid=${getUser.uuid}`, { RequestInfo: requestInfo() }));
                const transferor = data?.enterpriseEntity[0];
                if (transferor) {
                    setTransferorDetails(transferor);
                    const { entityName, phoneNumber, email } = transferor;
                    const postalAddress = transferor?.addresses?.find((address: any) => address.addressType === 'PERMANENT') || {};
                    const lesseeDataObj = { name: entityName, email, phoneNumber, ...postalAddress };
                    setValues({ ...values, ...lesseeDataObj });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data: plotData } = await POST(endpoints.entityRefDetails + `?entityIds=${transferorDetails.uuid}&types=LESSEE`, { RequestInfo: requestInfo() });
                if (plotData?.entityRefDetails?.length) {
                    let plotIds = plotData?.entityRefDetails?.map((item: any) => item.plotId);
                    plotIds = plotIds.join(',');

                    if (!plotIds) return;
                    let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                    let plotDataArray = data?.plots?.map((plot: any) => ({
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid
                    }));
                    setRowData(plotDataArray);
                    // setPlotData(plotDataArray);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (transferorDetails.uuid) fetchData();
    }, [transferorDetails]);

    useEffect(() => {
        const fetchData = async () => {
            const detailsData = await fetchLeaseDurationDoc(values.plotUuid, transferorDetails.uuid);

            setValues((prevState: any) => ({
                ...prevState,
                ...detailsData
            }));
        };

        values.plotUuid && transferorDetails && fetchData();
    }, [values.plotUuid, transferorDetails]);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleModalClose = (type: string) => {
        setModalDetails({ show: false });
        if (type === 'success') {
            navigate('/goa-idc/dashboard');
        }
    };
    const ConnectionDetails = () => {
        values.industrialEstate = plotDetails?.estateDetails?.estateName;
        values.plotNo = plotDetails?.plotNo;
        values.allotmentOrderNo = allotmentDetails?.allotmentId;
        values.allotmentOrderDate = allotmentDetails?.allotmentDate;
        values.waterType = waterConnectionBillData?.waterType;
        values.connectionPipeDia = waterConnectionBillData?.pipeDiaMm;
        values.monthlyConsumption = waterConnectionBillData?.monthlyWaterConsumptionM3;
        values.connectionType = waterConnectionBillData?.applicationType;

        // Add checks to ensure meterReadings exists and has at least one item
        const meterReading = waterConnectionBillData?.meterReadings?.[0] || {};
        values.lastReading = meterReading.lastReading || 0;
        const connection_details_formData = applicationNumber ? BILL_GENERATION_DETAILS_FORM_EMP : BILL_GENERATION_DETAILS_FORM_EMP;
        // const connection_details_formData_1 = applicationNumber ? CONNECTION_DETAILS_FORM_FOR_EMP_SECOND : CONNECTION_DETAILS_FORM_2;
        // console.log('WaterConnectionData', waterConnectionData);
        // console.log('WaterConnection Last Readind', waterConnectionData?.meterReadings[0].lastReading);
        // meterReadings[0].lastReading
        connection_details_formData[0].formData.map((val: any, id: any) => {
            if (val?.field === 'plotNo') {
                val.options = modifyPlotList(plotList);
            }
        });
        return (
            <div>
                <>
                    {/* <Card className='card_border'>
                        <Card.Body> */}
                    {connection_details_formData?.map(val => (
                        <GenericFormCard {...val} />
                    ))}
                </>
            </div>
        );
    };

    const WaterBillHistory = () => {
        // const connection_details_formData = applicationNumber ? BILL_HISTORY_DETAILS_FORM_EMP : BILL_HISTORY_DETAILS_FORM_EMP;

        return (
            <>
                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 '>
                    <input type='checkbox' id='Primary Assignment' name='assignments' defaultChecked={true} />
                    <label htmlFor='Primary Assignment' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold  '>
                        {t('', 'Water Bill History')}
                    </label>
                    <div className='form_fields px-2'>
                        {/* {connection_details_formData?.map(val => (
                            <GenericFormCard {...val} />
                        ))} */}
                        <div className='pb-2'>
                            <GenericActionTable
                                columns={BILL_HISTORY_COLUMNS}
                                searchBarPlaceholder={'SEARCH_BY_PLOT'}
                                rowData={billHistory}
                                hideExportBtn={true}
                                checkType={'singleCheck'}
                                itemsPerPage={5}
                                hideSearchBar
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    const renderInstallation = () => {
        const installDetails = INSTALLATION_DETAILS;
        return (
            <div>
                <>
                    {installDetails?.map(val => (
                        <GenericFormCard {...val} />
                    ))}
                </>
            </div>
        );
    };

    const onSectionChange = (currentSection: string) => {
        setOpenSection(currentSection);
    };

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const renderPayment = () => {
        return !paymentIsLoading ? (
            <PaymentDetails
                paymentData={paymentRowData.length ? paymentRowData : fetchPayDetails(waterConnectionData)}
                selectedGateway={''}
                setSelectedGateway={setSelectedGateway}
                isPaid={paymentRowData.length ? true : false}
                gatewayData={[]}
                transactionDetails={transactionDetails}
                title={'Water Connection Fee'}
                isVisiblePaid={true}
                hideTitle={true}
                isCardDisable={true}
            ></PaymentDetails>
        ) : (
            <Loading />
        );
    };

    const onActionClick = (action: string) => {
        if (action === 'GENERATE_BILL') {
            updateMeterReading(action);
            return;
        }
    };

    useEffect(() => {
        if (!!values?.currentReading && !!values?.meterReadingDate) getmeterBasedData();
    }, [values?.currentReading, values?.meterReadingDate]);

    const onLastSubmit = async (action: string) => {
        if (!values?.notes) {
            setModalDetails({ show: true, title: 'Error!', para: t('TC_MSG_CHECK_NOTING', 'Please add the noting and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setIsLoading(true);
        const payload: any = {
            RequestInfo: BPArequestInfo(),
            waterApplicationRequest: {
                applicationDetails: waterConnectionData
            },
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: 'WATER_CONNECTION',
                    businessId: waterConnectionData?.applicationNo,
                    moduleName: 'Water Connection',
                    action: action,
                    comment: values?.notes
                }
            ]
        };

        const apiEndPoint = endpoints.waterConnectionInitiate;

        try {
            const { data } = await POST(apiEndPoint, payload);
            console.log(data);
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Success!', para: 'Application updated successfully', img: check, type: 'success', reUpload: 'OK' });
        } catch (error: any) {
            setIsLoading(false);
            let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const renderActionBtn = () => {
        // const nextMarkAsCompleteAction = actionMark;
        return (
            <>
                {actionsData?.nextValidAction
                    .map((val: any, index: number) => {
                        return (
                            <GenericButtons
                                key={val?.action}
                                variant={actionsData?.nextValidAction.length > 0 && index === 0 ? 'primary' : ''}
                                text={t(val?.action, `${(val?.action).replace(/_/g, ' ')}`)}
                                handleClick={() => onActionClick(val?.action)}
                                ClassName={'variant'}
                            />
                        );
                    })
                    .reverse()}
            </>
        );
    };
    const submitMeterReading = async (action: string) => {
        setIsLoading(true);
        const payload: any = {
            RequestInfo: BPArequestInfo(),
            meterReadingRequest: {
                meterReading: meterReadingfromCalculator
            },
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: 'WATER_CONNECTION_BILLING',
                    businessId: applicationNumber,
                    moduleName: 'Water Connection Billing',
                    action: action,
                    comment: values?.notes,
                    notes: values?.notes
                }
            ]
        };

        payload.meterReadingRequest.meterReading.currentReading = values?.currentReading;
        payload.meterReadingRequest.meterReading.currentReadingDate = values?.meterReadingDate;
        payload.meterReadingRequest.meterReading.monthlyWaterCharges = values.monthlyWaterCharges;

        const apiEndPoint = endpoints.waterConnectionInitiate;

        try {
            const { data } = await POST(apiEndPoint, payload);
            console.log(data);
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Success!', para: 'Water Bill generated successfully', img: check, type: 'success', reUpload: 'OK' });
        } catch (error: any) {
            setIsLoading(false);
            let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getActionsData = () => {
        if (!!applicationNumber) {
            setIsLoading(true);

            getNextValidAction(applicationNumber)
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        console.log(response?.data);
                        setActionsData(response?.data);
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const getmeterBasedData = async () => {
        const payload: any = {
            RequestInfo: requestInfo(),
            meterReading: waterConnectionBillHistoryData?.meterReadings[0]
        };

        payload.meterReading.currentReading = values?.currentReading;
        payload.meterReading.currentReadingDate = values?.meterReadingDate;

        try {
            const data = await POST(endpoints.calculationByMeterReading, payload);
            setMeterReadingfromCalculator(data?.data?.meterReading);
            console.log(data?.data?.meterReading?.monthlyWaterCharges);
            values.monthlyWaterCharges = data?.data?.meterReading?.monthlyWaterCharges;
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const updateMeterReading = async (action: any) => {
        if (!values?.currentReading || !values?.meterReadingDate || !values?.monthlyWaterCharges) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill the required data', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        if (!values?.notes) {
            setModalDetails({ show: true, title: 'Error!', para: t('TC_MSG_CHECK_NOTING', 'Please add the noting and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        const meterReading = waterConnectionBillData?.meterReadings?.[0] || {};
        values.lastReading = meterReading.lastReading || 0;

        if (values.currentReading <= values.lastReading) {
            setModalDetails({ show: true, title: 'Error!', para: 'Current reading must be greater than the last reading.', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        const payload: any = {
            RequestInfo: requestInfo(),
            meterReading: meterReadingfromCalculator
        };

        payload.meterReading.currentReading = values?.currentReading;
        payload.meterReading.currentReadingDate = values?.meterReadingDate;
        payload.meterReading.monthlyWaterCharges = values?.monthlyWaterCharges;
        try {
            const data = await POST(endpoints.meterReadingUpdate, payload);
            if (data) {
                submitMeterReading(action);
            }
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
            {/* <StatusAlertPopup para={bodydata} img={cross} handleClose={handleClose} handleSubmit={handleStatusSubmit} show={showStatus} title={''} /> */}
            <div className='applicationPage m-auto px-5 py-4'>
                <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                    <GenericHeader
                        // subText={`Permit No. : ${values?.refApplication || applicationNumber || 'NA'}`}
                        text={t('', 'Water-Bill Generation')}
                        additionalClass='sub-text-style'
                        // onActionClick={() => navigateToDashboard()}
                        bottomSubText={`Meter No : ${meterNumber || ''}, Connection No :${applicationNumber || 'NA'}`}
                    />
                    <Formik initialValues={initialValues} onSubmit={value => {}}>
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('', 'Water-Bill Generation')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : ${t('', 'Water Connection')}, Connection No : ${applicationNumber || 'NA'}`}
                                    />

                                    <NotingWrapper text={comment} disable={true} />
                                    {/* <p className='noting-heading my-1'>{t('OC_EMP_NOTING_HEADING', 'Noting')}</p>

                                    <NotingComponent history={historyDetails} text={comment} handleOnFileUpload={handleOnFileUpload} /> */}
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper
                                    applicationNumber={applicationNumber}
                                    //  designation={designation}
                                    nextValidAction={{}}
                                />

                                <Card className='checklist-outer mb-4 mx-3'>
                                    <CardBody>{ConnectionDetails()}</CardBody>
                                </Card>
                                <Card className='checklist-outer mb-4 mx-3'>
                                    <CardBody>{WaterBillHistory()}</CardBody>
                                </Card>
                            </div>
                        </FormikProvider>
                    </Formik>
                    <GenericFooterComponent
                        // onClickNext={() => onActionClick('GENERATE_BILL')}
                        // onSubmit={() => onActionClick('GENERATE_BILL')}
                        onClickNext={() => {}}
                        onSubmit={() => {}}
                        onCancel={() => navigateToDashboard()}
                        onClickPrev={() => {}}
                        stepperLength={0}
                        activeIndex={activeIndex}
                        isSaveBtn={false}
                        isSubmitBtn={false}
                        children={renderActionBtn()}
                    />
                </div>
            </div>
        </>
    );
};
export default M_WaterConnectionBillGeneration;
