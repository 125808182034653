import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEditable } from '@testing-library/user-event/dist/utils';
import StatusAlertPopup from './StatusAlertPopup';
import { cross } from '../../utils/Logo';

interface emailNotification {
    getEmailIds: (data: any) => void;
    emailIdsData: { emailId: string }[];
    isEditable?: boolean;
    isRequired?: boolean;
    // field: string;
}

export default function GenericEmailIDNotification(props: emailNotification) {
    const { t } = useTranslation();
    const [formValues, setFormValues] = useState(props?.emailIdsData ? props?.emailIdsData : [{ emailId: '' }]);
    const [errorMsg, setErrorMsg] = useState('');
    const [modalDetails, setModalDetails] = useState<any>({});

    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
    const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const newFormValues = [...formValues];
        newFormValues[index] = { ...newFormValues[index], [name]: value };
        setFormValues(newFormValues);
        props?.getEmailIds(newFormValues);

        if (validateEmail(e.target.value)) {
            setErrorMsg('');
        }
    };
    const handleModalClose = (type: string, msg?: string) => {
        setModalDetails({});
        // if (type === 'success') {
        //     if (msg === 'Existing user data erased') {
        //         window.location.reload();
        //     } else {
        //         navigate('/goa-idc/dashboard');
        //     }
        // }
    };
    const addFormFields = () => {
        if (!props?.isEditable) return;
        const lastIndex = formValues.length - 1;
        const lastEmail = formValues[lastIndex].emailId.trim();

        if (!lastEmail || !validateEmail(lastEmail)) {
            let errMsg = t('VALID_EMAIL', 'Please enter a valid email address before adding a new one.');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });

            return;
        }

        for (let i = 0; i < lastIndex; i++) {
            if (formValues[i].emailId.trim() === lastEmail) {
                let errMsg = `${t('DIFFERENT_EMAIL', 'Please enter a different email address.')} ${t('EMAIL', 'Email')} ${i + 1} ${t('SAME_ADDRESS', 'has the same address.')}`;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
        }

        setFormValues([...formValues, { emailId: '' }]);
    };

    const validateEmail = (email: string) => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    const editField = (index: number) => {
        const newFormValues = [...formValues];
        newFormValues[index].emailId = '';
        setFormValues(newFormValues);
        inputRefs.current[index]?.focus();
    };

    const removeFormFields = (index: number) => {
        if (formValues.length === 1) {
            alert(t('AT_LEAST_ONE_REQUIRED', 'At least one email field is required.'));
            return;
        }
        const newFormValues = [...formValues];
        newFormValues.splice(index, 1);
        setFormValues(newFormValues);
    };

    const getErrors = (e: any) => {
        if (!validateEmail(e.target.value)) {
            setErrorMsg('Please enter valid email');
        } else setErrorMsg('');
    };

    return (
        <>
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.para)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.para)}
            />
            <div className='form-inline'>
                <div className='row my-3'>
                    {formValues.map((element: any, index: number) => (
                        <div className='col-6 mb-3' key={index}>
                            <div className='mb-1 font-inter-reg'>
                                <label style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                    {t('EMAIL', 'Email ')} {index + 1} {index === 0 && props?.isRequired && <span className={props?.isRequired ? 'mandatory-indicator' : 'd-none'}>*</span>}
                                </label>
                            </div>
                            <div className='d-flex justify-content-start align-items-center gx-1'>
                                <div style={{ width: '85%' }}>
                                    <input
                                        ref={ref => (inputRefs.current[index] = ref)}
                                        className='p-2 rounded form-control'
                                        style={{ width: '100%' }}
                                        type='email'
                                        name='emailId'
                                        disabled={!props?.isEditable || false}
                                        value={element.emailId}
                                        onChange={e => handleChange(index, e)}
                                        onBlur={getErrors}
                                    />
                                    {errorMsg && <span className='text-danger font-12'>{errorMsg}</span>}
                                </div>
                                {/* <div className='ps-1'>
                                    {index ? (
                                        <button
                                            className='px-3 py-2 button remove rounded '
                                            style={{ border: '0px', background: '#E4F0FF', height: '50px' }}
                                            type='button'
                                            onClick={() => editField(index)}
                                            disabled={!props?.isEditable || false}
                                        >
                                            <i className='fa fa-edit' />
                                        </button>
                                    ) : (
                                        <button
                                            className='px-3 py-2 button remove rounded '
                                            style={{ border: '0px', background: '#E4F0FF', height: '50px' }}
                                            type='button'
                                            onClick={() => editField(index)}
                                            disabled={!props?.isEditable || false}
                                        >
                                            <i className='fa fa-edit' />
                                        </button>
                                    )}
                                </div> */}
                                <div className='ps-1'>
                                    {formValues.length > 1 && (
                                        <button
                                            className='px-4 py-2 button remove rounded '
                                            style={{ border: '0px', background: '#c4464429', height: '50px' }}
                                            type='button'
                                            onClick={() => removeFormFields(index)}
                                            disabled={!props?.isEditable || false}
                                        >
                                            <i className='fa fa-trash' />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div
                className='col-12 mt-2 p-2 text-center'
                style={{ border: 'dashed 2px #45852e80', borderRadius: '8px', backgroundColor: '#ecf2ea' }}
                onClick={() => addFormFields()}
                aria-disabled={!props?.isEditable}
            >
                <button
                    style={{ fontWeight: 600, fontSize: '16px', lineHeight: '25.6px', border: '0px', background: 'none', color: '#000000B2' }}
                    className='button add'
                    type='button'
                    disabled={!props?.isEditable}
                >
                    + {t('EMAIL_ID', 'Email ID')}
                </button>
            </div>
        </>
    );
}

GenericEmailIDNotification.defaultProps = {
    isEditable: true
};
