import React, { useEffect } from 'react';
import { ICICI_PAYMENT_URL } from '../../utils/constants';

const ICICIPaymentPage = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const walletClientCode = urlParams.get('walletClientCode');
        const walletRequestMessage = urlParams.get('walletRequestMessage');

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = ICICI_PAYMENT_URL;

        const input1 = document.createElement('input');
        input1.type = 'hidden';
        input1.name = 'walletClientCode';
        input1.value = walletClientCode || ''; // default value

        const input2 = document.createElement('input');
        input2.type = 'hidden';
        input2.name = 'walletRequestMessage';
        input2.value = walletRequestMessage || ''; // default value

        form.appendChild(input1);
        form.appendChild(input2);

        document.body.appendChild(form);
        form.submit();
    }, []);

    return <div className='font-inter'>Processing your payment...</div>;
};

export default ICICIPaymentPage;
