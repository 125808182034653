import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { paymentColumns, paymentOptions } from './Allotment.const';
import { useState } from 'react';
import fileDown from '../../../../assets/logos/download_green.svg';
import fileDown2 from '../../../../assets/logos/download_gray.svg';
import { useTranslation } from 'react-i18next';

import { Image } from 'react-bootstrap';
import { timestampToDate } from './Allotment.controller';
import { devUrl } from '../../../../services/modulesServices/userManagement.api';

export default function PaymentDetails(props: any) {
    const { t } = useTranslation();
    const payOptions: any = paymentOptions;
    const pay = (ele: any) => {
        props?.setSelectedGateway(ele.code);
    };

    const dloadReceipt = async (refId?: string) => {
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${refId}`;
        window.location.href = downloadFileUrl;
    };

    console.log('tsxStatus', props?.transactionDetails, props?.transactionDetails?.txnStatus);

    const content = (
        <>
            <div className='ps-2 py-2 d-flex justify-content-between'>{!props?.hideTitle && <div className='formTitle m-0'>{props?.title || 'Allotment Application Fees'}</div>}</div>
            <div className='mb-4'>
                <GenericActionTable
                    columns={props?.columns ? props?.columns : paymentColumns}
                    rowData={props?.paymentData}
                    hideSearchBar={true}
                    itemsPerPage={5}
                    highlightRow={true}
                    totalRow={props?.totalLabel || 'total'}
                    hideFooter={true}
                    totalRowText={props?.isPaid ? 'Total Paid Amount (in INR)' : 'Total Payable Amount (in INR)'}
                    totalData={props?.totalData}
                    additionalMethod={props?.additionalMethod}
                    selectedItems={props?.selectedItems}
                    onCheckHandler={props?.onCheckHandler}
                    checkType={props?.checkType}
                />
            </div>
            {!props?.isPaid && (
                <div className='payment-options'>
                    {!!props?.isPaymentOption && (
                        <div>
                            <div className='ps-2 py-2 d-flex justify-content-between'>
                                <div className='formTitle m-0'>{'Payment Options'}</div>
                            </div>
                            <div className='d-flex gap-3'>
                                {props?.gatewayData?.map((ele: any) => (
                                    <div
                                        className={`flex-grow-0 payment-option ${props?.selectedGateway === ele.code ? 'active' : ''}`}
                                        onClick={() => {
                                            pay(ele);
                                        }}
                                    >
                                        <img alt={ele.value} src={payOptions[ele.code].img} style={{ maxHeight: payOptions[ele.code].height }}></img>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {props?.isPaid && props?.isVisiblePaid && props?.transactionDetails && (
                <div className='payment-done'>
                    <div className='ps-2 py-2 d-flex justify-content-between'>
                        <div className='formTitle m-0'>{'Payment Done'}</div>
                    </div>
                    <div className='row ps-3'>
                        <div className='details ps-2 col-12'>
                            <div className='detail'>
                                <div className='head'>Transaction ID</div>
                                <div className='content'>{props?.transactionDetails?.txnId}</div>
                            </div>
                            <div className='detail'>
                                <div className='head'>Status</div>
                                <div className='content pill pill-success'>
                                    {props?.transactionDetails?.txnStatus === 'SUCCESS' ? 'Paid' : props?.transactionDetails?.txnStatus === 'DEPOSITED' ? 'Paid' : 'Failed'}
                                </div>
                            </div>
                            <div className='detail'>
                                <div className='head'>Payment Date</div>
                                <div className='content'>{timestampToDate(props?.transactionDetails?.auditDetails?.createdTime)}</div>
                            </div>
                            {props.transactionDetails?.additionalDetails?.paymentReceiptDocRefId || props.transactionDetails?.invoiceRefId ? (
                                <div className='detail'>
                                    <div className='head'>Receipt</div>
                                    <div
                                        className='content link d-flex gap-1 align-items-center'
                                        onClick={() => dloadReceipt(props.transactionDetails?.additionalDetails?.paymentReceiptDocRefId || props.transactionDetails?.invoiceRefId)}
                                    >
                                        <Image src={fileDown} height={20} />
                                        {t('DOWNLOAD', 'Download')}
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    return (
        <>
            {!props?.isCardDisable ? (
                <Card className='card_border font-inter'>
                    <Card.Body>{content}</Card.Body>
                </Card>
            ) : (
                content
            )}
        </>
    );
}

PaymentDetails.defaultProps = {
    isVisiblePaid: true,
    isCardDisable: false,
    isPaymentOption: true
};
