import { Card } from 'react-bootstrap';
import { CONTRACT_CLOSURE_CORRESPONDENCE, CONTRACT_CLOSURE_FORM } from '../ContractClosure.const';
import { useState } from 'react';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import { t } from 'i18next';

export const ContractClosureWorkflowCorrespondence = ({ dropdownData }: any) => {
    const [openSection, setOpenSection] = useState<string>('contractClosure');

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {CONTRACT_CLOSURE_CORRESPONDENCE.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val?.titleCode ? t(val?.titleCode) : val?.title}
                                </label>
                                {val.title === 'A - Contract Closure' && openSection === 'contractClosure' && (
                                    <>
                                        <Card.Body>
                                            {CONTRACT_CLOSURE_FORM.map((val: any) => (
                                                <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                            ))}
                                        </Card.Body>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};
