import _ from 'lodash';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { classNames } from '@react-pdf-viewer/core';

export const CONTENT_MANAGEMENT_TYPE = [
    {
        formData: [
            {
                title: 'Type',
                titleCode: 'TYPE',
                field: 'type',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            }
        ]
    }
];

export const CONTENT_MANAGEMENT_DETAILS = [
    {
        formData: [
            {
                titleCode: 'TENDER_DESCRIPTION',
                title: 'Tender Description',
                field: 'caption',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: true
            },
            {
                titleCode: 'TENDER_OPEN_DATE',
                title: 'Tender Open Date',
                field: 'openDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true
                // isDisabled: true
            },
            {
                titleCode: 'TENDER_PUBLISH_DATE',
                title: 'Tender Publsih Date',
                field: 'publishDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                // showTimeSelect: true,
                enableFutureDate: true
                // disablePastDate: true
            },
            {
                titleCode: 'TENDER_CLOSE_DATE',
                title: 'Tender Close Date',
                field: 'bidCloseDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true
            },
            {
                titleCode: 'EXPIRY_DATE',
                title: 'Expiry Date',
                field: 'expiryDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true
                // disablePastDate: true
            },
            {
                titleCode: 'TENDER_DOCUMENT',
                clsName: 'col-sm-6',
                title: 'Tender Document',
                field: 'document',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: '',
                    referenceType: ''
                }
            }
        ]
    }
];

export const CONTENT_MANAGEMENT_INSTAGRAM_DETAILS = [
    {
        formData: [
            {
                titleCode: 'INSTAGRAM_POST',
                title: 'Photo',
                clsName: 'col-sm-6',
                field: 'socialPost',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: '',
                    referenceType: ''
                },
                fileTypes: ['jpeg', 'png'],
                class: 'col-sm-12'
            }
        ]
    }
];

export const CONTENT_MANAGEMENT_LINKEDIN_DETAILS = [
    {
        formData: [
            {
                titleCode: 'LINKEDIN_POST',
                title: 'Photo',
                field: 'socialPost',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: '',
                    referenceType: ''
                },
                fileTypes: ['jpeg', 'png'],
                class: 'col-sm-12'
            }
        ]
    }
];

export const CONTENT_MANAGEMENT_SITEPLAN_DETAILS = [
    {
        formData: [
            {
                titleCode: 'ESTATE',
                title: 'Estate',
                field: 'estate',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-12'
            },
            {
                titleCode: 'SITEPLAN_DOCUMENT',
                title: 'Upload Site Plan',
                field: 'sitePlanDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'documentName',
                    documentType: 'IDC_PORTAL_SITEPLAN',
                    referenceType: 'IDC_PORTAL_SITEPLAN'
                },
                class: 'col-sm-12'
            }
        ]
    }
];

export const CONTENT_MANAGEMENT_WHATSNEW_DETAILS = [
    {
        formData: [
            {
                titleCode: 'Add Content',
                title: 'Add Content',
                field: 'content',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-sm-12'
            },
            {
                title: 'Upload Document',
                field: 'document',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPE.OTHER,
                    referenceType: ''
                },
                class: 'col-sm-12'
            }
        ]
    }
];

export const CONTENT_MANAGEMENT_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '60px' },
        // class: 'col-sm-4',
        clsName: 'column-text-start',
        id: 1
    },
    {
        titleCode: 'TENDER DESCRIPTION',
        colName: 'Tender Description',
        fieldName: 'caption',
        cellstyle: { width: '400px' },
        // class: 'col-sm-4',
        clsName: 'column-text-start',
        type: 'text',
        id: 2
    },
    {
        titleCode: 'DOCUMENT',
        colName: 'Document',
        fieldName: 'docDetails',
        cellstyle: { width: '300px' },
        // class: 'col-sm-4',
        clsName: 'column-text-start',
        type: 'document',
        // isClickable: true,
        id: 3
    },
    {
        titleCode: 'TENDER_PUBLISH_DATE',
        colName: 'Tender Publish Date',
        fieldName: 'publishDate',
        cellstyle: { width: '200px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'TENDER DATE',
        colName: 'Tender Date',
        fieldName: 'createdDate',
        cellstyle: { width: '200px' },
        // class: 'col-sm-4',
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'TENDER_OPEN_DATE',
        colName: 'Tender Open Date',
        fieldName: 'openDate',
        cellstyle: { width: '200px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'TENDER_CLOSE_DATE',
        colName: 'Tender Close Date',
        fieldName: 'bidCloseDate',
        cellstyle: { width: '200px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['edit', 'delete']
    }
];

export const CONTENT_MANAGEMENT_INSTAGRAM_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '100px' },
        clsName: 'column-text-start',
        id: 1
    },
    {
        titleCode: 'DOCUMENT',
        colName: 'Document',
        fieldName: 'docDetails',
        cellstyle: { width: '600px' },
        clsName: 'column-text-start',
        type: 'document'
    },
    {
        titleCode: 'DATE_AND_TIME',
        colName: 'Date and Time',
        fieldName: 'createdDate',
        cellstyle: { width: '620px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['delete']
    }
];

export const CONTENT_MANAGEMENT_LINKEDIN_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '100px' },
        clsName: 'column-text-start',
        id: 1
    },
    {
        titleCode: 'DOCUMENT',
        colName: 'Document',
        fieldName: 'docDetails',
        cellstyle: { width: '600px' },
        clsName: 'column-text-start',
        type: 'document',
        id: 2
    },
    {
        titleCode: 'DATE_AND_TIME',
        colName: 'Date and Time',
        fieldName: 'createdDate',
        cellstyle: { width: '620px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 3
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['delete']
    }
];

export const CONTENT_MANAGEMENT_SITEPLAN_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '60px' },
        clsName: 'column-text-start',
        id: 1
    },
    {
        titleCode: 'ESTATE',
        colName: 'Estate',
        fieldName: 'documentNameReference',
        cellstyle: { width: '350px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 2
    },
    {
        titleCode: 'DOCUMENT',
        colName: 'Document',
        fieldName: 'docDetails',
        cellstyle: { width: '500px' },
        clsName: 'column-text-start',
        type: 'document',
        id: 2
    },
    {
        titleCode: 'DATE_AND_TIME',
        colName: 'Date and Time',
        fieldName: 'createdDate',
        cellstyle: { width: '430px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 3
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['edit', 'delete']
    }
];

export const CONTENT_MANAGEMENT_WHATSNEW_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '60px' },
        clsName: 'column-text-start',
        id: 1
    },
    {
        titleCode: 'DESCRIPTION',
        colName: 'Description',
        fieldName: 'content',
        cellstyle: { width: '800px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 2
    },
    {
        titleCode: 'DATE_AND_TIME',
        colName: 'Date and Time',
        fieldName: 'createdDate',
        cellstyle: { width: '300px' },
        clsName: 'column-text-start ps-3',
        type: 'text',
        id: 3
    },
    {
        titleCode: 'DOCUMENT',
        colName: 'Document',
        fieldName: 'docDetails',
        cellstyle: { width: '300px' },
        clsName: 'column-text-start',
        type: 'document',
        id: 4
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['edit', 'delete']
    }
];
