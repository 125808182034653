import React from 'react';
import { about_us } from '../../utils/Images';
import HeaderComponent from '../../components/header/Header.component';
import FooterComponent from '../../components/portalComponents/Footer.component';
import { Link } from 'react-router-dom';
import { POST, endpoints, getFileUrl } from '../../services/modulesServices/userManagement.api';
import ScrollButton from '../../components/ScrollToTop';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Card } from 'react-bootstrap';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { MyCalendar } from '../../components/calender/Calender';

export default function HolidayList() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <HeaderComponent />

            <div className='holiday-list-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader text={t('HOLIDAY_LIST', 'Holiday List')} />
                <div className='col-12'>
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <MyCalendar />
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <ScrollButton type='public' />
        </>
    );
}
