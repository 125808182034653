import _ from 'lodash';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';

export const SEARCH_DPR_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'DPR_APPLICATION_NUMBER',
                title: 'Enter DPR Application Number',
                field: 'dprApplicationNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5',
                isRequired: true
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'industrialEstate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];

export const TENDER_DETAILS_FIELDS: any[] = [
    {
        formData: [
            {
                titleCode: 'TENDER_DOCUMENT_AVAILABILITY_DATE',
                title: 'Tender Document Availability Date',
                field: 'tenderDocumentAvailabilityDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                showTimeSelect: true,
                enableFutureDate: true,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'NORMAL'
            },
            {
                titleCode: 'APPLICATION_RECEIPT_END_DATE',
                title: 'Application Receipt End Date',
                field: 'tenderDocumentAvailabilityDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                showTimeSelect: true,
                enableFutureDate: true,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'SHORT'
            },
            {
                titleCode: 'LAST_DATE_OF_ONLINE_SUBMISSION',
                title: 'Last date of online application',
                field: 'lastDateOfOnlineApplication',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                showTimeSelect: true,
                enableFutureDate: true,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'NORMAL'
            },
            {
                titleCode: 'BLANK_TENDER_FORM_ISSUE_END_DATE',
                title: 'Blank Tender Form Issue End Date',
                field: 'lastDateOfOnlineApplication',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                showTimeSelect: true,
                enableFutureDate: true,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'SHORT'
            },
            {
                titleCode: 'SUBMISSION_END_DATE',
                title: 'Submission End Date',
                field: 'submissionEndDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                showTimeSelect: true,
                enableFutureDate: true,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'NORMAL'
            },
            {
                titleCode: 'COMPLETE_SHORT_TENDER_SUBMISSION_END_DATE',
                title: 'Complete Short Tender Submission End Date',
                field: 'submissionEndDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                showTimeSelect: true,
                enableFutureDate: true,
                renderCondition: (values: any) => _.get(values, `tenderType`) === 'SHORT'
            },
            {
                titleCode: 'BID_OPENING_DATE',
                title: 'Bid Opening Date',
                field: 'bidOpenStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                showTimeSelect: true,
                enableFutureDate: true
            }
        ]
    }
];

export const DPR_DATA_COLUMN: any = [
    {
        titleCode: '',
        colName: '',
        fieldName: 'radio',
        clsName: 'column-text-start'
    },
    {
        titleCode: 'DPR_NO',
        colName: 'DPR No',
        fieldName: 'dprNo',
        clsName: 'column-text-start col-3'
    },
    {
        titleCode: 'PROJECT_NAME',
        colName: 'Project Name',
        fieldName: 'projectName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'INDUSTRIAL_NAME',
        colName: 'Industrial Name',
        fieldName: 'industrialName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'PROJECT_TYPE',
        colName: 'Project Type',
        fieldName: 'projectType',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'ESTIMATE_AMOUNT',
        colName: 'Estimate Amount',
        fieldName: 'estimateAmount',
        clsName: 'column-text-start col-3'
    }
];

export const NIT_CORRESPONDENCE = [
    {
        title: '(A) DPR Details',
        field: 'dprDetails'
    },
    {
        title: '(B) Tender Details',
        field: 'tenderDetails'
    }
];

export const tenderDetails = async (resData: any, dropdownvalue: any, applicationNumber?: any) => {
    const tenderInitValue: any = {};
    const tenderDetails: any = {};

    tenderDetails['bidOpenStartDate'] = resData?.bidOpenStartDate;
    tenderDetails['submissionEndDate'] = resData?.tenderSubmissionEndDate;
    tenderDetails['tenderDocumentAvailabilityDate'] = resData?.tenderDocumentAvailabilityDate;
    tenderDetails['lastDateOfOnlineApplication'] = resData?.onlineApplicationLastDate;
    tenderDetails['publicationDate'] = resData?.publicationDate;
    tenderDetails['tenderType'] = resData?.tenderType;
    return applicationNumber ? { ...tenderDetails, ...tenderInitValue } : tenderInitValue;
};

export const NIT_APPLICATION_SEARCH_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'TENDER_APPLICATION_NUMBER',
                title: 'Enter Tender Application No.',
                field: 'tenderNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5',
                isRequired: true
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'estate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];

export const NIT_APPLICATION_SEARCH_TABLE: any = [
    {
        titleCode: 'SERIAL_NO',
        colName: 'S.N.',
        fieldName: 'serialNo',
        clsName: 'column-text-start col-1'
    },
    {
        titleCode: 'TENDER_NO',
        colName: 'Tender No',
        fieldName: 'tenderNo',
        clsName: 'column-text-start col-3',
        isClickable: true
    },
    {
        titleCode: 'TENDER_DOCUMENT_AVAILABILITY_DATE',
        colName: 'Tender Document Availability Date',
        fieldName: 'tenderDocumentAvailabilityDate',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'LAST_DATE_OF_ONLINE_SUBMISSION',
        colName: 'Last Date of Online Application',
        fieldName: 'lastDateOfOnlineApplication',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'SUBMISSION_END_DATE',
        colName: 'Submission End Date',
        fieldName: 'tenderSubmissionEndDate',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'BID_OPENING_DATE',
        colName: 'Bid Opening Date',
        fieldName: 'bidOpenStartDate',
        clsName: 'column-text-start col-2'
    }
];

export const DPR_DETAILS_TABLE_COLUMN: any = [
    {
        titleCode: 'SERIAL_NO',
        colName: 'S.N.',
        fieldName: 'serialNo',
        clsName: 'column-text-start serial-no'
    },
    {
        titleCode: 'DPR_NO',
        colName: 'DPR No',
        fieldName: 'dprNo',
        clsName: 'column-text-start dpr-column'
    },
    {
        titleCode: 'PROJECT_NAME',
        colName: 'Project Name',
        fieldName: 'projectName',
        clsName: 'column-text-start dpr-column'
    },
    {
        titleCode: 'INDUSTRIAL_NAME',
        colName: 'Industrial Name',
        fieldName: 'industrialName',
        clsName: 'column-text-start dpr-column'
    },
    {
        titleCode: 'PROJECT_TYPE',
        colName: 'Project Type',
        fieldName: 'projectType',
        clsName: 'column-text-start dpr-column'
    },
    {
        titleCode: 'EMD_VALUE',
        colName: 'EMD Value (INR)',
        fieldName: 'emdValue',
        clsName: 'column-text-end dpr-column'
    },
    {
        titleCode: 'COST_OF_TENDER',
        colName: 'Cost of Tender (in INR)',
        fieldName: 'costOfTender',
        clsName: 'column-text-end dpr-column'
    },
    {
        titleCode: 'ESTIMATE_AMOUNT',
        colName: 'Estimate Amount',
        fieldName: 'estimateAmount',
        clsName: 'column-text-end dpr-column'
    }
];
