import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CONSTANT_DATA_FIELD, TENANT_ID } from '../../../../utils/constants';
import { FormikProvider, useFormik } from 'formik';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericCommonSelector from '../../../../components/moduleCommonFormik/GenericCommonSelector.component';
import { POST, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import { useTranslation } from 'react-i18next';

export const Payment = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [disablePrimary, setDisablePrimary] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState({});
    const [redirectUrl, setRedirectUrl] = useState<string>('');
    const formik = useFormik<any>({
        initialValues: {
            paymentGateway: null,
            isUpdatable: true
        },
        onSubmit: () => {}
    });
    const { values } = formik;
    const PAYMENTGATEWAY = [
        {
            title: t('PAYMENT_GATEWAY2', 'Payment Gateway'),
            titleCode: 'PAYMENT_GATEWAY',
            type: CONSTANT_DATA_FIELD.SELECT_FIELD,
            field: 'paymentGateway',
            isRequired: true,
            isDisabled: false
        }
    ];
    const handleOnClick = () => {
        setShowModal(true);
    };

    const dropdownData = {
        paymentGateway: [
            {
                code: 'PNB',
                name: 'PNB'
            },
            {
                code: 'HDFC',
                name: 'HDFC'
            },
            {
                code: 'AXIS',
                name: 'AXIS'
            },
            {
                code: 'ICICI',
                name: 'ICICI'
            }
        ]
    };
    const renderPaymentGateway = () => {
        return (
            <FormikProvider value={formik}>
                <Form>
                    {PAYMENTGATEWAY.map((val: any, id: number) => (
                        <GenericCommonSelector {...val} dropdownOptions={dropdownData} />
                    ))}
                </Form>
            </FormikProvider>
        );
    };
    const handlePayments = () => {
        let payload = {
            RequestInfo: requestInfo(),
            Transaction: {
                tenantId: TENANT_ID,
                txnAmount: 1,
                module: 'BPA',
                billId: '231d0307-3f0f-4a0b-97b8-2c5778090df7',
                productInfo: 'Payment COllection',
                gateway: values.paymentGateway.name,
                callbackUrl: 'http://localhost:3000/goa-idc/dummy-payment-status',
                taxAndPayments: [
                    {
                        taxAmount: 1,
                        amountPaid: 1,
                        billId: '231d0307-3f0f-4a0b-97b8-2c5778090df7'
                    }
                ],
                consumerCode: 'GIDC/2024/021'
            }
        };

        POST(endpoints.initiateTransaction, payload)
            .then(res => {
                // toast('Payment successful', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                setRedirectUrl(res.data.Transaction.redirectUrl);
                setRedirect(true);
            })
            .catch(e => {
                setModalDetails({ show: true, title: 'Error!', para: t('PAYMENT_FAIL', 'Payment failed'), img: cross, type: 'error', reUpload: 'OK' });
            });
    };
    useEffect(() => {
        const payment = values?.paymentGateway || {};
        if (Object.keys(payment).length !== 0) {
            setDisablePrimary(false);
        }
    }, [values.paymentGateway]);
    useEffect(() => {
        if (redirect === true) {
            window.location.href = redirectUrl;
        }
    }, [redirectUrl]);

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <GenericButtons text={'Make Payment'} handleClick={handleOnClick} />
            <GenericPopupModal
                title='Payment'
                size='l'
                primaryBtnTitle='Pay'
                primaryAction={handlePayments}
                isPrimaryDisabled={disablePrimary}
                secondaryBtnTitle='Close'
                secondaryAction={() => {
                    setShowModal(false);
                }}
                isVisible={showModal}
                children={renderPaymentGateway()}
                onClose={() => {
                    setShowModal(false);
                }}
                class='clarification-modal'
            />
        </>
    );
};
