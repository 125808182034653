import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { entityDocumentData } from '../EstateManagement.controller';

export const MISCELLANEOUS_SIDE_CARD = [
    {
        titleCode: 'CHANGE_DETAILS',
        name: 'Change Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        index: 2,
        isActive: false
    },
    {
        titleCode: 'PAYMENT',
        name: 'Payment Details',
        index: 3,
        isActive: false
    }
];

export const CHANGE_DETAILS = [
    {
        formData: [
            {
                titleCode: 'NATURE_OF_CHANGE',
                title: 'Nature of Change',
                field: 'natureOfChange',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            }
        ]
    }
];

export const DROPDOWN_CHANGE = [
    {
        name: 'Change in Ownership Interest',
        code: 'CHANGE_IN_OWNERSHIP_INTEREST'
    },
    {
        name: 'Change in Name ',
        code: 'CHANGE_IN_NAME'
    },
    {
        name: 'Change in Trade',
        code: 'CHANGE_IN_TRADE'
    }
];

export const CHANGE_IN_NAME = [
    {
        formData: [
            {
                titleCode: 'UPDATED_ENTERPRISE_NAME',
                title: 'New name of the Lessee',
                field: 'updatedEnterpriseName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Certificate from Registrar of Firms/Companies',
                titleCode: 'CERTIFICATE_FROM_REGISTRAT',
                field: 'certificateFromRegistrarOfFirms',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'Certificate from Registrar of Firms/Companies',
                    documentType: DOCUMENT_TYPE.APPROVAL_OF_REGISTRAR,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            }
        ]
    }
];

export const MISCELLANEOUS_SELF_DECLARATION_LIST = [
    '(i) the information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed herein.',
    '(ii) it has cleared all outstanding amounts due to the Corporation; and',
    '(iii) it is not in breach of any term and condition of the Lease Deed.'
];

export const OWNERSHIP_CHANGE_DOCUMENTS = [
    {
        formData: [
            {
                title: 'Memorandum of Association',
                titleCode: 'MEMORANDUM_OF_ASSOCIATION',
                field: 'memorandumOfAssociation',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'Memorandum of Association',
                    documentType: DOCUMENT_TYPE.MEMORANDUM_OF_ASSOCIATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            },
            {
                title: 'Approval of Registrar of Companies',
                titleCode: 'APPROVAL_OF_REGISTRAR',
                field: 'approvalOfRegistrarOfCompanies',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'Approval of Registrar of Companies',
                    documentType: DOCUMENT_TYPE.APPROVAL_OF_REGISTRAR,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            }
        ]
    }
];

export const TRADE_CHANGE_DOCUMENTS = [
    {
        formData: [
            {
                title: 'Detailed project report',
                titleCode: 'Please attach the detailed project report',
                field: 'enterpriseProjectReport',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Detailed Project Report',
                    documentType: DOCUMENT_TYPE.DETAILED_PROJECT_REPORT,
                    referenceType: ''
                },
                isRequired: true
            },
            {
                title: 'Udyam Registration Certificate',
                titleCode: 'Please attach Udhyam Registration Certificate',
                field: 'RegCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'UDYAM Registration Certificate',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION,
                    referenceType: DOCUMENT_TYPE.OTHER
                }
            }
        ]
    }
];

export const miscellaneousApplication = async (data: any, dropdownData: any) => {
    const documentData = await entityDocumentData(data?.requestDetails.documents);
    let initialData: any = {};
    initialData['natureOfChange'] = getDropdownSelectedObject(data?.requestDetails.type, dropdownData['natureOfChange'] || []);
    initialData['approvalOfRegistrarOfCompanies'] = documentData?.find((doc: any) => doc.documentType === 'APPROVAL_OF_REGISTRAR');
    initialData['memorandumOfAssociation'] = documentData?.find((doc: any) => doc.documentType === 'MEMORANDUM_OF_ASSOCIATION');
    initialData['enterpriseProjectReport'] = documentData?.find((doc: any) => doc.documentType === 'DETAILED_PROJECT_REPORT');
    initialData['RegCertificate'] = documentData?.find((doc: any) => doc.documentType === 'UDYAMREGISTRATION');
    initialData['certificateFromRegistrarOfFirms'] = documentData?.find((doc: any) => doc.documentType === 'APPROVAL_OF_REGISTRAR');
    return initialData;
};

export const MISC_CORRESPONDENCE_CARD = [
    {
        title: 'A - Lessee Details',
        field: 'lesseeDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Change Details',
        field: 'changeDetails'
    },
    {
        title: 'D - Self Declaration',
        field: 'selfDeclaration'
    },
    {
        title: 'E - Payment Details',
        field: 'paymentDetails'
    }
];

export const WORKFLOW_PLOT_TABLE_COLUMNS = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Plot No',
        fieldName: 'plotNo',
        clsName: 'col',
        id: 2
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col',
        id: 3
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateName',
        clsName: 'col',
        id: 4
    },
    {
        colName: 'Plot Area (Sqm)',
        fieldName: 'plotAreaSqm',
        clsName: 'col',
        id: 5
    }
];
