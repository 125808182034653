import { isDisabled } from '@testing-library/user-event/dist/utils';
import { CONSTANT_DATA_FIELD } from '../../utils/constants';

export const RENEWAL_CONSTRUCTION_LICENSE_FORM_STEPS = [
    {
        titleCode: 'CONSTRUCTION_LICENSE',
        name: 'Construction License',
        code: 'CONSTRUCTION_LICENSE',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        code: 'SELF_DECLARATION',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'PAYMENT',
        name: 'Payment',
        code: 'PAYMENT',
        index: 3,
        isActive: true
    }
];

export const RENEWAL_CONSTRUCTION_LICENSE_FORM_STEPS_NO_PAY = [
    {
        titleCode: 'CONSTRUCTION_LICENSE',
        name: 'Construction License',
        code: 'CONSTRUCTION_LICENSE',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        code: 'SELF_DECLARATION',
        index: 2,
        isActive: true
    }
];

export const RENEWAL_CONSTRUCTION_LICENSE_FORM: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Construction License No.',
                field: 'constructionLicenseNo',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isRequired: true,
                class: 'col-6'
                // isDisabled: true
                // width: 'half',
            },
            {
                title: 'Expiration Date',
                field: 'expirationDate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-6',
                enableFutureDate: true,
                disablePastDate: true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Expiration Date</p>'
                }
            }
        ]
    },
    {
        title: 'Plot Details',
        titleCode: 'PLOT_DETAILS',
        formData: [
            {
                title: 'Name of Industrial Estate',
                field: 'nameOfIndustrialEstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                // isRequired: true,
                isDisabled: true,
                class: 'col-sm-6'
                // width: 'half'
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                // isRequired: true,
                isDisabled: true,
                options: [],
                class: 'col-sm-6'
                // width: 'half'
            },
            {
                title: 'Plot Number',
                field: 'plotNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                // isRequired: true,
                isDisabled: true,
                class: 'col-sm-6'
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                // isRequired: true,
                isDisabled: true,
                class: 'col-6'
            }
        ]
    }
];
