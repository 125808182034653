import { ErrorMessage, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Form, Image } from 'react-bootstrap';
import './../GenericStyles.scss';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DocumentInfo, EnterpriseFileEnum, FileEnum } from '../GenericFormCard.constants';
import { TENANT_ID, USER_TYPE } from '../../../utils/constants';
import { devUrl, endpoints } from '../../../services/modulesServices/userManagement.api';
import { extractFileUrl } from '../../../utils/utils';
import { cross, file_upload } from '../../../utils/Logo';
import PopUps from '../../PopUps';
import ToolTipDetails from '../../ToolTipFile';
import GenericPopupModal from '../GenericModalPopup.component';
import GenericPDFViewer from '../../fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../fileviewer/GenericImageViewer';
import fileDown from '../../../assets/logos/download.svg';
import trashLogo from '../../../assets/logos/trash-white.svg';
import GenericDocViewer from '../../fileviewer/GenericDocViewer';

interface textFieldProps {
    titleCode?: any;
    field: string;
    title: string;
    setDocuments?: any;
    documents?: any;
    module?: string;
    isRequired?: boolean;
    fileType?: string;
    additionalData?: any;
    isDisabled?: boolean;
    condition1?: (val: any, index: any) => boolean;
    index?: any;
    entityFileData?: any;
    hideTitle?: boolean;
    hideFileSize?: boolean;
    toolTipDetails?: any;
    hideMultiFileValue?: boolean;
    fileTypes?: any;
}

export default function GenericFileUploadFieldMulti(props: textFieldProps) {
    const { setDocuments, documents, module, title, toolTipDetails, field, fileType, additionalData, isDisabled, condition1, index, entityFileData, hideFileSize, fileTypes } = props;
    const { t } = useTranslation();

    const { values, setFieldValue, handleChange, setValues } = useFormikContext<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [isRequired, setIsRequired] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [extension, setExtension] = useState<any>('');

    const userType = JSON.parse(localStorage.getItem('type') || '{}');

    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    let user = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        if (condition1 && condition1(values, index)) {
            setIsRequired(true);
        } else if (condition1 && !condition1(values, index)) {
            setIsRequired(false);
        }
    }, [field, values]);

    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) return;

        let newFilesArray: any[] = [];

        for (let i = 0; i < files.length; i++) {
            let customFile, customFileName;
            const file: any = files[i];
            const fileExtensionType = file?.type;
            const extension: string = file?.name.split('.').pop()?.toLowerCase() || '';
            setExtension(extension);

            customFileName = userType === USER_TYPE.EMP && FileEnum[title] === 'ADDRESS_PROOF' ? `${values?.empId}_AddressProof.${extension}` : file.name;
            customFile = userType === USER_TYPE.EMP && FileEnum[title] === 'ADDRESS_PROOF' ? new File([file], customFileName, { type: fileExtensionType }) : file;

            if (entityFileData) {
                customFileName = entityFileData.documentNamePrefix
                    ? `${_.get(values, entityFileData.documentNamePrefix) || entityFileData.documentNamePrefix}${entityFileData.documentType ? '_' : ''}${entityFileData.documentType}.${extension}`
                    : file.name;
                customFile = new File([file], customFileName, { type: fileExtensionType });
            }

            try {
                // if (!(fileExtensionType === 'application/pdf')) {
                //     let errMsg = t('INVALID_DOCUMENT_TYPE', 'Invalid document type');
                //     setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                //     return;
                // }

                if (!file) {
                    console.error('No file selected.');
                    return;
                }

                if (!fileTypes && fileExtensionType !== 'application/pdf') {
                    let errMsg = t('INVALID_DOCUMENT_TYPEE', `Please upload ${!fileTypes ? 'PDF' : fileTypes.join(',')} files only`);
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    e.target.value = ''; // Clear the file input
                    return;
                } else if (fileTypes && !fileTypes?.includes(extension)) {
                    let errMsg = t('INVALID_DOCUMENT_TYPEE', `Please upload ${!fileTypes ? 'PDF' : fileTypes.join(',')} files only`);
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    e.target.value = ''; // Clear the file input
                    return;
                }

                if (extension !== 'pdf') {
                    setIsPDF(false);
                } else setIsPDF(true);

                setIsLoading(true);

                const response: any = await axios.post(
                    devUrl + '/filestore/v1/files',
                    {
                        file: customFile,
                        tenantId: 'ga',
                        tag: customFileName,
                        module: 'hrms-upload',
                        requestInfo: JSON.stringify({
                            userInfo: {
                                uuid: user?.uuid ?? 'SYSTEM'
                            }
                        })
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                );

                const fileResponseUrl = await axios.get(devUrl + endpoints.fileStoreApi, {
                    params: {
                        tenantId: 'ga',
                        fileStoreIds: response.data.files[0].fileStoreId
                    }
                });
                const fileDate = new Date(fileResponseUrl?.data?.fileStoreIds[0].uploadedDate);

                const day = fileDate.getDate(),
                    month = fileDate.getMonth() + 1,
                    year = fileDate.getFullYear();

                const formattedDate = `${day}/${month}/${year}`;

                const fileData = {
                    documentName: fileResponseUrl?.data?.fileStoreIds[0].tag,
                    documentType: entityFileData?.documentType,
                    referenceType: entityFileData?.referenceType,
                    docReferenceId: fileResponseUrl?.data?.fileStoreIds[0].id,
                    documentUrl: extractFileUrl(fileResponseUrl.data.fileStoreIds[0].url),
                    documentSize: fileResponseUrl?.data?.fileStoreIds[0].fileSize,
                    uploadedDate: formattedDate
                };

                newFilesArray.push(fileData);
            } catch (error) {
                setIsLoading(false);
                let errMsg = t('FILE_UPLOAD_UNSUCCESSFUL', 'File upload unsuccessful');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
        }

        setFieldValue(field, [...(_.get(values, field) || []), ...newFilesArray]);
        setIsLoading(false);
    };

    const onDeleteFile = (file: DocumentInfo) => {
        const val = _.cloneDeep(values);
        const updatedFiles = val[field]?.filter((item: DocumentInfo) => item.docReferenceId !== file.docReferenceId);
        setFieldValue(field, updatedFiles);
        const docs = documents?.filter((item: DocumentInfo) => item.referenceId !== file.referenceId);
        setDocuments && setDocuments(docs);
    };

    const downloadUploadedFile = async (id: string, documentName: string) => {
        const fileExtension: string = documentName.split('.').pop()?.toLowerCase() || '';

        let contentType = '';
        if (fileExtension === 'pdf') {
            contentType = 'application/pdf';
        } else if (fileExtension === 'jpeg' || fileExtension === 'jpg') {
            contentType = 'image/jpeg';
        }

        fetch(devUrl + `/filestore/v1/files/id?tenantId=ga&fileStoreId=${id}`, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(response => {
                return response.arrayBuffer().then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link: any = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${documentName}`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
            })

            .catch(error => console.error('Error downloading file:', error));
    };

    const viewDocument = (id: string, name: string) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData({ id: id, name: name });
        setIsPDF(isPDF);
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <div className='d-flex justify-content-center flex-column'>
                {isLoading ? (
                    <ThreeDots visible={true} height='80' width='80' color='#45852e' radius='9' ariaLabel='three-dots-loading' wrapperStyle={{}} wrapperClass='' />
                ) : (
                    <Form.Group style={{ width: '100%' }}>
                        {!props.hideTitle && (
                            <div className='d-flex'>
                                <Form.Label
                                    className='file-form-label pb-2'
                                    style={props.title?.length > 60 ? { maxWidth: '95%' } : { width: 'fit-content' }}
                                    title={props.titleCode ? t(props.titleCode) : props.title}
                                >
                                    {props.titleCode ? t(props.titleCode, props.title) : props.title}
                                </Form.Label>
                                {(props.isRequired || isRequired) && <span className='mandatory-indicator'>*</span>}
                                {toolTipDetails && <ToolTipDetails toolTipDetails={toolTipDetails} />}
                            </div>
                        )}
                        <div className=''>
                            <div className={`btn btn-none  fileUploadBox ${field === '' ? 'py-3' : 'p-3'}`} style={{ backgroundColor: '#ecf2e9', width: `${field === '' ? '60%' : '100%'}` }}>
                                <>
                                    <div className='d-flex justify-content-center align-items-center font-10 fw-thin'>
                                        <input className='uploadBox' name={props.field} type='file' id={field} onChange={handleImageChange} disabled={!values.isUpdatable || isDisabled} multiple />
                                        <Image src={file_upload} height={40} />
                                    </div>

                                    <div>
                                        <div className='file-name text-truncate' title={`Upload ${props.title}`}>
                                            {t('UPLOAD', `Upload ${props.title}`)}
                                        </div>

                                        <div style={{ fontSize: 10 }} className='text-muted'>
                                            &nbsp; {`only ${props.fileTypes ? fileTypes.join(', ') : '.pdf'} file`} <br />
                                            {!hideFileSize && t('MAX_SIZE', '2 MB max size')}
                                        </div>
                                    </div>
                                </>
                            </div>

                            {!props?.hideMultiFileValue &&
                                _.get(values, field)?.map((file: DocumentInfo, index: number) => (
                                    <div
                                        key={index}
                                        className={`btn btn-none fileUploadBox p-3 my-2`}
                                        style={{ borderRadius: '8px', justifyContent: 'space-between', backgroundColor: '#45852E', cursor: 'default' }}
                                    >
                                        <div className='d-flex align-items-center mx-0'>
                                            <div onClick={() => viewDocument(file?.docReferenceId, file?.documentName)} className='text-start cursor-pointer'>
                                                <div className='file-name uploaded-file-name mt-2 text-nowrap text-truncate ' title={file.documentName}>
                                                    {file.documentName}
                                                </div>

                                                <div className='mt-2 uploaded-file-timing text-white' style={{ display: 'flex' }}>
                                                    <div className='uploaded-file-timing'>{file.documentSize || file.fileSize}</div>
                                                    <li className='uploaded-file-timing ms-2'>{file.uploadedDate}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex cursor-pointer'>
                                            <Image src={fileDown} height={20} onClick={() => downloadUploadedFile(file.referenceId || file.docReferenceId, file.documentName)} />
                                            {values.isUpdatable && !isDisabled ? <Image src={trashLogo} height={20} className='ms-4 ' onClick={() => onDeleteFile(file)} /> : ''}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </Form.Group>
                )}
                <ErrorMessage name={props.field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                {modalData && isFilePreviewModal && (
                    <GenericPopupModal
                        title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                        primaryAction={() => {}}
                        secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                        isVisible={isFilePreviewModal}
                        isPrimaryBtn={false}
                        isSecondaryBtn={false}
                        size={'xl'}
                        class='file-viewer-popup'
                    >
                        <div className='popup-children'>
                            {isPDF && <GenericPDFViewer fileStoreId={modalData.id} documentName={modalData.name} />}
                            {['png', 'jpg'].includes(extension) && <GenericImageViewer imageUrl={_.get(values, field).documentUrl} />}
                            {['docx', 'doc'].includes(extension) && <GenericDocViewer url={_.get(values, field).documentUrl} extension={extension} fileName={_.get(values, field).documentName} />}
                        </div>
                    </GenericPopupModal>
                )}
            </div>
        </>
    );
}
