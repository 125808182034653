import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import GenericSelectorWithSearch from '../../../../components/moduleCommonFormik/GenericSelectorWithSearch';
import GenericCommonTextField from '../../../../components/moduleCommonFormik/GenericCommonTextField.component';
import { PLOT_DETAILS } from './Transfer.const';

const AssignedPlotDetails = ({ isFieldDisable, plotData = [] }: any) => {
    const { values, setValues } = useFormikContext<any>();
    const [dropdownData, setDropdownData] = useState<any>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });
    const plotDetails = [
        {
            formData: [
                {
                    title: 'Plot Rate (INR/Sq.m.)',
                    field: 'plotRate',
                    type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                    showInr: true,
                    isDisabled: true
                },
                {
                    title: 'Plot Area (Sq.m.)',
                    field: 'plotArea',
                    type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                    isDisabled: true
                }
            ]
        }
    ];
    const onChangeDropdown = async (e: any, field: string) => {
        if (!e.value && field === 'estateName') {
            const estateNames: any[] = [];
            plotData.forEach((plot: any) => {
                const obj = { code: plot.estateName, name: plot.estateName };
                if (!estateNames.find(obj => obj.code === plot.estateName)) estateNames.push(obj);
            });

            setDropdownData({ ...dropdownData, plotNo: [], phase: [], plotType: [], estateName: estateNames });
            setValues({ ...values, estateName: null, phase: null, plotNo: null, plotType: null, plotId: null });
        }
        if (!e.value && field === 'phase') {
            setDropdownData({ ...dropdownData, plotNo: [], plotType: [] });
            setValues({ ...values, phase: null, plotNo: null, plotType: null, plotId: null });
        }
        if (!e.value && field === 'plotNo') {
            setDropdownData({ ...dropdownData, plotType: [] });
            setValues({ ...values, plotNo: null, plotType: null });
        }
        if (!e.value) return;

        const valueObj = { code: e.value, name: e.value };
        if (field === 'estateName') {
            let data = plotData.filter((plot: any) => {
                return plot.estateName === e.value;
            });
            const options: any[] = [];
            data.forEach((item: any) => {
                const obj = { code: item.phase, name: item.phase };
                if (!options.find(obj => obj.code === item.phase)) options.push(obj);
            });

            setDropdownData({ ...dropdownData, phase: options, plotNo: [], plotType: [] });
            setValues({ ...values, estateName: valueObj, phase: null, plotNo: null, plotType: null, plotId: null });
        } else if (field === 'phase') {
            let data = plotData.filter((plot: any) => {
                return plot.phase === e.value && plot.estateName === values.estateName.code;
            });
            const options: any[] = [];
            data.forEach((item: any) => {
                const obj = { code: item.plotNo, name: item.plotNo };
                if (!options.find(obj => obj.code === item.plotNo)) options.push(obj);
            });
            setDropdownData({ ...dropdownData, plotNo: options, plotType: [] });
            setValues({ ...values, phase: valueObj, plotNo: null, plotType: null, plotId: null });
        } else if (field === 'plotNo') {
            let data = plotData.filter((plot: any) => plot.plotNo === e.value && plot.estateName === values.estateName.code && plot.phase === values.phase.code);
            let obj;
            const options: any[] = [];
            data.forEach((item: any) => {
                obj = { code: item.plotType, name: item.plotType };
                if (!options.find(obj => obj.code === item.plotType)) options.push(obj);
            });
            setDropdownData({ ...dropdownData, plotType: options });
            setValues({
                ...values,
                plotNo: valueObj,
                plotType: obj,
                plotId: data[0]?.plotUuid,
                plotRate: data[0]?.plotRate,
                plotArea: data[0].plotArea
            });
        }
        // else if (field === 'plotType') {
        //     let data = plotData.find((plot: any) => plot.plotType === e.value);
        //     setValues({ ...values, plotType: valueObj });
        // }
    };

    useEffect(() => {
        if ((values?.estateApplicationId && values?.entityRefDetailsId) || isFieldDisable) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFieldDisable]);

    console.log('Values', values);

    useEffect(() => {
        const estateNames: any[] = [];

        if (plotData?.length === 1) {
            let onePlot = plotData[0];
            onePlot = {
                estateName: { code: onePlot.estateName, name: onePlot.estateName },
                phase: { code: onePlot.phase, name: onePlot.phase },
                plotNo: { code: onePlot.plotNo, name: onePlot.plotNo },
                plotType: { code: onePlot.plotType, name: onePlot.plotType },
                plotId: onePlot.plotUuid,
                plotRate: onePlot.plotRate,
                plotArea: onePlot.plotArea
            };
            setValues({ ...values, ...onePlot });
            return;
        }

        plotData.forEach((plot: any) => {
            const obj = { code: plot.estateName, name: plot.estateName };
            if (!estateNames.find(obj => obj.code === plot.estateName)) estateNames.push(obj);
        });

        setDropdownData({
            plotNo: [],
            phase: [],
            plotType: [],
            estateName: estateNames
        });
    }, [plotData]);

    return (
        <div className='row d-flex m-0'>
            {PLOT_DETAILS[0]?.formData.map((formField: any, index: number) => {
                return (
                    <div className={`py-3 col-sm-6`} key={index}>
                        <GenericSelectorWithSearch
                            field={formField.field}
                            title={formField.title}
                            isRequired={formField.isRequired}
                            isDisabled={formField.isDisabled || isFieldDisable}
                            dropdownOptions={dropdownData}
                            customHandleChange={onChangeDropdown}
                        />
                    </div>
                );
            })}
            {plotDetails[0]?.formData.map((val: any, index: number) => (
                <div className={`py-3 col-sm-6`} key={index}>
                    <GenericCommonTextField {...val} />
                </div>
            ))}
        </div>
    );
};

export default AssignedPlotDetails;
