import React, { useEffect, useState } from 'react';
import '../OrderCompletionFormPage.scss';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Clearance_Form_Initial_Data, VALIDATION_ORDER_COMPLETION_FORM, stepsDetailsForFieldInspection, stepsDetailsForOrderCompletion } from '../OrderCompletionFormPage.module';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getNavigateTo } from '../../../utils/utils';
import Loading from '../../../components/portalComponents/Loading.component';
import HeaderComponent from '../../../components/header/Header.component';
import ModalStatusPopup from '../../../components/popup/Popup.status.generic';
import GenericBackButton from '../../../components/UI components/Buttons/GenericBackButton';
import SideCard from '../../../components/moduleCommonFormik/SideCardGenericComponent';
import GenericPopupModal from '../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericFooterComponent from '../../../components/moduleCommonFormik/GenericFooterStepper.component';
import FieldInspectionSubPage from './FieldInspectionSubPage.component';
import { check, close, cross } from '../../../utils/Logo';
import StatusAlertPopup from '../../../components/moduleCommonFormik/StatusAlertPopup';

export default function FieldInspectionMainPage() {
    const { t, i18n } = useTranslation();
    const initialValues = Clearance_Form_Initial_Data;
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [activeStepper, setActiveStepper] = useState<string>('INSPECTION_DETAILS');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showStatus, setShowStatus] = useState<boolean>(false);
    const [showProcess, setShowProcess] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [bodydata, setBodydata] = useState<string>('');
    const [employeeData, setEmployeeData] = useState(null);
    const [previewData, setPreviewData] = useState<any>(null);
    const [appStatus, setAppStatus] = useState<any>(false);
    const [isCancelBtn, setCancelBtn] = useState<any>(false);
    const [isShowActionPopup, setIsShowActionPopup] = useState<boolean>(false);
    const [bpaData, setBpaData] = useState<any>(null);
    const [selectedAction, setSelectedAction] = useState<any>('');
    const [allchecked, setAllChecked] = React.useState<any>([]);
    const [editorContent, setEditorContent] = useState('');
    const [formValues, setFormValues] = useState([{ emailId: '' }]);
    var userinfo = window.localStorage.getItem('user');
    const user_uuid = !!userinfo && JSON.parse(userinfo).uuid;
    let stepperDetails = stepsDetailsForFieldInspection;
    const [payVisible, setPayVisible] = useState<boolean>(false);
    const [allBPADetails, setAllBPADetails] = useState<any>(null);
    const [isLesseeEmpty, setIsLesseeEmpty] = useState<boolean>(false);
    const [selectedBPA, setIsSelectedBPA] = useState<string>('');
    const [allEdcrDetails, setAllEdcrDetails] = useState<any>(null);
    const urlParams = new URLSearchParams(window.location.search);
    const [actionsData, setActionsData] = useState<any>(null);
    const applicationNumber = urlParams.get('applicationNumber') || '';
    const [modalDetails, setModalDetails] = useState<any>({});
    const formik = useFormik<any>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: { initialValues, isUpdatable: true },
        onSubmit: value => {},
        validationSchema: VALIDATION_ORDER_COMPLETION_FORM[`step_${activeIndex}`],
        validateOnMount: true
    });
    const { values, resetForm, setFieldValue } = formik;

    const handleClose = () => {
        setShowPopup(false);
        setShowStatus(false);
        setIsShowActionPopup(false);
    };

    const handleSubmit = () => {
        setShowPopup(false);
        setShowStatus(false);
        if (activeStepper === 'DECLARATION') navigateToDashboard();
    };

    const handleStatusSubmit = () => {
        setShowStatus(false);
        navigateToDashboard();
    };

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success') window.location.reload();
    };

    const onChangeNextIndex = async (key: number) => {
        setCancelBtn(false);
        const checkWithoutValidation = ['DOCUMENTS'];
        const stepperLength = stepperDetails.length;
        if (activeIndex < stepperLength) {
            if (!!appStatus) {
                setActiveIndex(activeIndex + 1);
                return;
            }

            // Check if the active stepper is OCCUPANCY_DETAILS
            if (activeStepper === 'OCCUPANCY_DETAILS') {
                // Get the values of Type of Occupancy and Occupancy Percent from formik values
                const typeOfOccupancy = values?.occupancyType;
                const occupancyPercent = values?.occupancy;
                const totalPercentage = 100 - Number(values?.percentage);

                // Check if both Type of Occupancy and Occupancy Percent are not filled
                if (!typeOfOccupancy && !occupancyPercent) {
                    setModalDetails({ show: true, title: 'Error!', para: 'Please fill Type of Occupancy and Occupancy Percent fields!', img: cross, type: 'error', reUpload: 'OK' });
                    // toast('Please fill Type of Occupancy and Occupancy Percent fields!', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'warning'
                    // });
                    return;
                }

                // Check if Type of Occupancy is not filled
                if (!typeOfOccupancy) {
                    setModalDetails({ show: true, title: 'Error!', para: 'Please select Type of Occupancy!', img: cross, type: 'error', reUpload: 'OK' });
                    // toast('Please select Type of Occupancy!', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'warning'
                    // });
                    return;
                }

                // Check if Occupancy Percent is not filled, not a number, or not between 0 and 100
                if (!occupancyPercent) {
                    setModalDetails({ show: true, title: 'Error!', para: 'Please fill Occupancy Percent!', img: cross, type: 'error', reUpload: 'OK' });
                    // toast('Please fill Occupancy Percent!', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'warning'
                    // });
                    return;
                } // Check if Occupancy Percent is not a number
                else if (isNaN(Number(occupancyPercent))) {
                    setModalDetails({ show: true, title: 'Error!', para: 'Occupancy Percent must be a number!', img: cross, type: 'error', reUpload: 'OK' });
                    // toast('Occupancy Percent must be a number!', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'warning'
                    // });
                    return;
                }

                // Check if Occupancy Percent is not between 0 and 100
                if (Number(occupancyPercent) < 0 || Number(occupancyPercent) > totalPercentage) {
                    setModalDetails({ show: true, title: 'Error!', para: `Occupancy Percent must be between 1 and ${totalPercentage}!`, img: cross, type: 'error', reUpload: 'OK' });
                    // toast(`Occupancy Percent must be between 1 and ${totalPercentage}!`, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'warning'
                    // });
                    return;
                }
            }

            try {
                if (checkWithoutValidation.includes(activeStepper)) await VALIDATION_ORDER_COMPLETION_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
                if (activeStepper === 'LESSEE_DETAITLS' && !!previewData) {
                    setActiveIndex(activeIndex + 1);
                    return;
                }
                if (activeStepper === 'INSPECTION_DETAILS' && (!values?.toDate || !values?.inspTime)) {
                    setModalDetails({ show: true, title: 'Error!', para: 'Please fill the Inspection Date & Time.', img: cross, type: 'error', reUpload: 'OK' });
                    // toast('Please fill the Inspection Date & Time.', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'error'
                    // });
                    return;
                } else {
                    setModalDetails({ show: true, title: 'Success!', para: 'Successfully filled.', img: check, type: 'success', reUpload: 'OK' });
                    // toast('Successfully filled.', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'success'
                    // });
                    return setActiveIndex(activeIndex + 1);
                }
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
                // toast(t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
            }
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepperDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const handleStepClick = async (index: number) => {
        if (!!appStatus) {
            setActiveIndex(index);
            return;
        }

        // Check if the active stepper is OCCUPANCY_DETAILS
        if (activeStepper === 'OCCUPANCY_DETAILS') {
            // Get the values of Type of Occupancy and Occupancy Percent from formik values
            const typeOfOccupancy = values?.occupancyType;
            const occupancyPercent = values?.occupancy;

            // Check if both Type of Occupancy and Occupancy Percent are not filled
            if (!typeOfOccupancy && !occupancyPercent) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill Type of Occupancy and Occupancy Percent fields!', img: cross, type: 'error', reUpload: 'OK' });
                // toast('Please fill Type of Occupancy and Occupancy Percent fields!', {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
                return;
            }

            // Check if Type of Occupancy is not filled
            if (!typeOfOccupancy) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please select Type of Occupancy!', img: cross, type: 'error', reUpload: 'OK' });
                // toast('Please select Type of Occupancy!', {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
                return;
            }

            // Check if Occupancy Percent is not filled or not a number
            if (!occupancyPercent) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill Occupancy Percent!', img: cross, type: 'error', reUpload: 'OK' });
                // toast('Please fill Occupancy Percent!', {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
                return;
            }
            // Check if Occupancy Percent is not a number
            else if (isNaN(Number(occupancyPercent))) {
                setModalDetails({ show: true, title: 'Error!', para: 'Occupancy Percent must be a number!', img: cross, type: 'error', reUpload: 'OK' });
                // toast('Occupancy Percent must be a number!', {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
                return;
            }

            // Check if Occupancy Percent is not between 0 and 100
            if (Number(occupancyPercent) < 0 || Number(occupancyPercent) > 100) {
                setModalDetails({ show: true, title: 'Error!', para: 'Occupancy Percent must be between 1 and 100!', img: cross, type: 'error', reUpload: 'OK' });
                // toast('Occupancy Percent must be between 1 and 100!', {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
                return;
            }
        }

        try {
            // await VALIDATION_CLEARANCE_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
            setActiveIndex(index);
            window.scrollTo(0, 0);
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
        }
    };

    const submitForm = (action?: string) => {};

    const getActiveStepper = () => {
        stepperDetails.forEach((val: any) => {
            if (val?.index === activeIndex) {
                setActiveStepper(val?.code);
            }
        });
    };

    useEffect(() => {
        getActiveStepper();
        values.isEditableFile = true;
    }, [activeIndex]);

    const handleScroll = () => {
        if (window.scrollY > 110) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (!!applicationNumber) {
            setIsSelectedBPA(applicationNumber);
            return;
        }
    }, []);

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            {/* <ModalStatusPopup para={bodydata} img={close} handleClose={handleClose} handleSubmit={handleStatusSubmit} show={showStatus} /> */}
            <div className='applicationPage m-auto px-5 py-4'>
                <div className='col-sm-12'>
                    <Card className='card_border'>
                        <Card.Body>
                            <div className='d-flex'>
                                <GenericBackButton />
                                <div className='ps-2'>
                                    {/* <Card.Subtitle className="text-muted text-nav">HOME / OBPAS</Card.Subtitle> */}
                                    <Card.Text className='font-inter-med fs-4 mb-0'>
                                        Field Inspection :{' '}
                                        <span className=' font-inter-med fs-4 text-secondary' style={{ color: '#858585' }}>
                                            <a
                                                style={{ color: '#858585' }}
                                                href={`/goa-idc/obpas/constructionLicense?applicationNumber=${values?.refApplication || applicationNumber}&tenantId=ga`}
                                                target='_blank'
                                            >
                                                {values?.refApplication || applicationNumber || 'GoaBPA001'}
                                            </a>
                                        </span>
                                    </Card.Text>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                <div className='row d-flex my-4'>
                    <div className='col-lg-3 align-self-start stickyCard-section'>
                        <div className='stickyCard' style={{ top: '110px' }}>
                            <Card className={`card_border my-2 hiddenNav ${isVisible ? '' : 'scrolledNav'}`}>
                                <Card.Body>
                                    <div className='d-flex align-items-end'>
                                        <GenericBackButton />
                                        <div className='ps-2'>
                                            {/* <Card.Subtitle className="text-muted text-nav">HOME / OBPAS</Card.Subtitle> */}
                                            <Card.Text className='font-inter-med fs-5 mb-0'>
                                                Field Inspection :{' '}
                                                <span className=' font-inter-med fs-6 text-secondary'>
                                                    <a
                                                        style={{ color: '#858585' }}
                                                        href={`/goa-idc/obpas/constructionLicense?applicationNumber=${values?.refApplication || applicationNumber}&tenantId=ga`}
                                                        target='_blank'
                                                    >
                                                        {values?.refApplication || applicationNumber || 'GoaBPA001'}
                                                    </a>
                                                </span>
                                            </Card.Text>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card className='card_border p-3 mb-4'>
                                <Card.Body>
                                    <Card.Text className=' d-flex flex-column row-gap-3  '>
                                        <SideCard steps={stepperDetails} handleStepClick={handleStepClick} activeIndex={activeIndex} />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            {/* <Card className='card_border note mb-4'>
                                <Card.Body>
                                    <Card.Text className='font-14'>
                                        <span>
                                            <i className='fa fa-exclamation-circle' aria-hidden='true'></i>
                                        </span>
                                        <b> Note : </b>if there is any information important to the user before submitting to be displayed here.
                                    </Card.Text>
                                </Card.Body>
                            </Card> */}
                        </div>
                    </div>
                    <div className='col-lg-9 me-auto mb-5'>
                        <Formik initialValues={initialValues} onSubmit={value => {}} validationSchema={VALIDATION_ORDER_COMPLETION_FORM}>
                            <FormikProvider value={formik}>
                                <Form>
                                    <FieldInspectionSubPage
                                        index={activeIndex}
                                        activeStepper={activeStepper}
                                        setActiveIndex={setActiveIndex}
                                        employeeList={employeeData}
                                        setEditorContent={setEditorContent}
                                        editorContent={editorContent}
                                        formData={formData}
                                        previewData={previewData}
                                        appStatus={appStatus}
                                        showProcess={showProcess}
                                        allchecked={allchecked}
                                        setAllChecked={setAllChecked}
                                        bpaData={bpaData}
                                        isCancelBtn={isCancelBtn}
                                        setShowProcess={setShowProcess}
                                        setFormValues={setFormValues}
                                        formValues={formValues}
                                        allBPADetails={allBPADetails}
                                        allEdcrDetails={allEdcrDetails}
                                        setIsSelectedBPA={setIsSelectedBPA}
                                        applicationNumber={applicationNumber}
                                    />
                                    <GenericPopupModal
                                        title=''
                                        subTitle={`Application No: ${previewData?.applicationNo}`}
                                        isVisible={isShowActionPopup}
                                        primaryBtnTitle={selectedAction?.replace(/_/g, ' ')}
                                        secondaryAction={handleClose}
                                        primaryAction={() => {
                                            submitForm(selectedAction);
                                        }}
                                        secondaryBtnTitle='Close'
                                    />
                                </Form>
                            </FormikProvider>
                        </Formik>
                    </div>
                </div>
            </div>
            {
                <GenericFooterComponent
                    isSaveBtn={!appStatus && !(activeIndex === stepperDetails.length)}
                    onClickNext={onChangeNextIndex}
                    onClickSave={onChangeNextIndex}
                    onSubmit={() => submitForm()}
                    onCancel={() => navigateToDashboard()}
                    onClickPrev={onChangePrevIndex}
                    stepperLength={stepperDetails.length}
                    activeIndex={activeIndex}
                    secondaryBtnLabel={
                        !!appStatus && activeIndex === stepperDetails.length ? 'Close' : !appStatus && activeIndex === stepperDetails.length ? 'Submit' : !!appStatus ? 'Next' : 'Save & Next'
                    }
                />
            }

            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => {
                    handleModalClose(modalDetails.type, modalDetails?.finalSubmit);
                }}
            />
        </>
    );
}
