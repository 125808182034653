import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import _ from 'lodash';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { entityDocumentData } from '../../EstateManagement/EstateManagement.controller';

export const DPR_CORRESPONDENCE = [
    {
        title: 'A - Header',
        field: 'header'
    },
    {
        title: 'B - Measurements',
        field: 'measurements'
    },
    {
        title: 'C - Abstract',
        field: 'abstract'
    },
    {
        title: 'D - Contingencies',
        field: 'contingencies'
    }
];

export const HEADER_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Project Name',
                field: 'projectName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-12'
            },
            {
                title: 'Estate',
                field: 'estate',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Project Type',
                field: 'projectType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Nature of Work',
                field: 'natureOfWork',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Period Consumed (No. of Days)',
                field: 'periodConsumed',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            }
        ]
    }
];

export const MEASUREMENT_DETAILS_I = [
    {
        formData: [
            {
                title: 'Cost Type',
                field: 'gsrMarketRate',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: 'GSR', title: 'GSR' },
                    { value: 'MarketRate', title: 'Market Rate' }
                ]
            },
            {
                title: 'Measurement Type',
                field: 'lbdOrNonlbd',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: 'LBD', title: 'LBD' },
                    { value: 'Non-LBD', title: 'Non-LBD' }
                ]
            },
            {
                titleCode: 'GSR_NAME',
                title: 'GSR Name',
                field: 'gsrName',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) !== 'MarketRate',
                isRequired: true
            },
            {
                titleCode: 'GSR_YEAR',
                title: 'GSR Year',
                field: 'gsrYear',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) !== 'MarketRate',
                isRequired: true
            },
            {
                titleCode: 'GSR_ITEM_NO',
                title: 'GSR Item No',
                field: 'gsrItemNo',
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) !== 'MarketRate',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },

            {
                titleCode: 'ITEM_TYPE',
                title: 'Item Type',
                field: 'itemType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            }
            // {
            //     titleCode: 'ITEM_DESCRIPTION',
            //     title: 'Item Description',
            //     field: 'itemDesc',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true,
            //     class: 'col-sm-12'
            //     // isDisabled: true,
            //     // disableCondition: (values: any) => _.get(values, `gsrMarketRate`) === 'MarketRate'
            // }
        ]
    }
];

export const MEASUREMENT_DETAILS_II = [
    {
        formData: [
            {
                titleCode: 'GSR_UNIT_RATE',
                title: 'GSR Unit Rate (INR)',
                field: 'gsrUnitRate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) !== 'MarketRate',
                isDisabled: true,
                isRequired: true
            },
            {
                titleCode: 'MV_UNIT_RATE',
                title: 'Unit Rate(INR)',
                field: 'mvUnitRate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) === 'MarketRate',
                isRequired: true
            },
            {
                titleCode: 'UNIT_OF_MEASUREMENT',
                title: 'Unit of Measurement',
                field: 'unitOfMeasurement',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                // isDisabled: true,
                disableCondition: (values: any, index: any) => _.get(values, `gsrMarketRate`) === 'MarketRate',
                isRequired: true
            },
            {
                titleCode: 'ITEM_NO',
                title: 'Item No.',
                field: 'itemNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'MEASUREMENT_TYPE',
                title: 'Measurement Type',
                field: 'measurementType',
                renderCondition: (values: any) => !_.get(values, `lbdOrNonlbd`) || _.get(values, `lbdOrNonlbd`) === 'LBD',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'REMARKS',
                title: 'Remarks',
                field: 'remarks',
                // renderCondition: (values: any) => !_.get(values, `lbdOrNonlbd`) || _.get(values, `lbdOrNonlbd`) === 'LBD',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                clsName: 'min-height-85 col-sm-6 me-2'
            },
            {
                titleCode: 'SUPPORTING_DOCUMENT',
                title: 'Supporting Document',
                field: 'document',
                renderCondition: (values: any) => !_.get(values, `lbdOrNonlbd`) || _.get(values, `lbdOrNonlbd`) === 'LBD',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPE.OTHER
                },
                clsName: 'col-sm-6 me-2'
            },
            {
                titleCode: 'SUPPORTING_DOCUMENT',
                title: 'Supporting Document',
                field: 'document',
                renderCondition: (values: any) => !_.get(values, `lbdOrNonlbd`) || _.get(values, `lbdOrNonlbd`) === 'Non-LBD',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPE.OTHER
                },
                clsName: 'col-sm-6 me-2',
                fileTypes: ['pdf', 'xlsx']
            }
        ]
    }
];

export const MEASUREMENT_DETAILS = [
    {
        formData: [
            {
                title: 'Cost Type',
                field: 'gsrMarketRate',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: 'GSR', title: 'GSR' },
                    { value: 'MarketRate', title: 'Market Rate' }
                ]
            },
            {
                title: 'Measurement Type',
                field: 'lbdOrNonlbd',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: 'LBD', title: 'LBD' },
                    { value: 'Non-LBD', title: 'Non-LBD' }
                ]
            },
            {
                titleCode: 'GSR_NAME',
                title: 'GSR Name',
                field: 'gsrName',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) !== 'MarketRate',
                isRequired: true
            },
            {
                titleCode: 'GSR_YEAR',
                title: 'GSR Year',
                field: 'gsrYear',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) !== 'MarketRate',
                isRequired: true
            },
            {
                titleCode: 'GSR_ITEM_NO',
                title: 'GSR Item No.',
                field: 'gsrItemNo',
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) !== 'MarketRate',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },

            {
                titleCode: 'ITEM_TYPE',
                title: 'Item Type',
                field: 'itemType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'ITEM_DESCRIPTION',
                title: 'Item Description',
                field: 'itemDesc',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-12'
                // isDisabled: true,
                // disableCondition: (values: any) => _.get(values, `gsrMarketRate`) === 'MarketRate'
            },
            {
                titleCode: 'GSR_UNIT_RATE',
                title: 'GSR Unit Rate (INR)',
                field: 'gsrUnitRate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) !== 'MarketRate',
                isDisabled: true,
                isRequired: true
            },
            {
                titleCode: 'MV_UNIT_RATE',
                title: 'Unit Rate(INR)',
                field: 'mvUnitRate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                renderCondition: (values: any) => _.get(values, `gsrMarketRate`) === 'MarketRate',
                isRequired: true
            },
            {
                titleCode: 'UNIT_OF_MEASUREMENT',
                title: 'Unit of Measurement',
                field: 'unitOfMeasurement',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                // isDisabled: true,
                disableCondition: (values: any, index: any) => _.get(values, `gsrMarketRate`) === 'MarketRate',
                isRequired: true
            },
            {
                titleCode: 'ITEM_NO',
                title: 'Item No.',
                field: 'itemNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'MEASUREMENT_TYPE',
                title: 'Measurement Type',
                field: 'measurementType',
                renderCondition: (values: any) => !_.get(values, `lbdOrNonlbd`) || _.get(values, `lbdOrNonlbd`) === 'LBD',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'REMARKS',
                title: 'Remarks',
                field: 'remarks',
                // renderCondition: (values: any) => !_.get(values, `lbdOrNonlbd`) || _.get(values, `lbdOrNonlbd`) === 'LBD',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                clsName: 'min-height-85 col-sm-6 me-2'
            },
            {
                titleCode: 'SUPPORTING_DOCUMENT',
                title: 'Supporting Document',
                field: 'document',
                renderCondition: (values: any) => !_.get(values, `lbdOrNonlbd`) || _.get(values, `lbdOrNonlbd`) === 'LBD',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPE.OTHER
                },
                clsName: 'col-sm-6 me-2'
            },
            {
                titleCode: 'SUPPORTING_DOCUMENT',
                title: 'Supporting Document',
                field: 'document',
                renderCondition: (values: any) => !_.get(values, `lbdOrNonlbd`) || _.get(values, `lbdOrNonlbd`) === 'Non-LBD',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: '',
                    documentType: DOCUMENT_TYPE.OTHER
                },
                clsName: 'col-sm-6 me-2',
                fileTypes: ['pdf', 'xlsx']
            }
        ]
    }
];

export const DPR_MEASUREMENT = [
    {
        formData: [
            {
                titleCode: 'NOS',
                title: 'Nos',
                field: 'nos',
                isRequired: true,
                disableCondition: (values: any, index: any) => _.get(values, `lbdOrNonlbd`) === 'LBD',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'width-20'
            },
            {
                titleCode: 'LENGTH',
                title: 'Length (in meters)',
                field: 'length',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                disableCondition: (values: any, index: any) => _.get(values, `lbdOrNonlbd`) === 'LBD',
                class: 'width-20',
                isRequired: true
            },
            {
                titleCode: 'BREADTH',
                title: 'Breadth (in meters)',
                field: 'breadth',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                disableCondition: (values: any, index: any) => _.get(values, 'measurementType.code') !== 'RUNNING_METER' && _.get(values, `lbdOrNonlbd`) === 'LBD',
                class: 'width-20',
                isRequired: true
            },
            {
                titleCode: 'DEPTH',
                title: 'Depth (in meters)',
                field: 'depth',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                disableCondition: (values: any, index: any) => _.get(values, 'measurementType.code') === 'VOLUME' && _.get(values, `lbdOrNonlbd`) === 'LBD',
                class: 'width-20'
            },
            {
                titleCode: 'QUANTITY',
                title: 'Quantity',
                field: 'quantity',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                disableCondition: (values: any, index: any) => _.get(values, `lbdOrNonlbd`) !== 'LBD',
                isRequired: true,
                class: 'width-20'
            }
        ]
    }
];

// export const CONTINGENCIES_POPUP_FIELDS: FormDataDTO[] = [
//     {
//         formData: [
//             {
//                 title: 'Cost Head',
//                 field: 'contingenciesCostHead',
//                 type: CONSTANT_DATA_FIELD.SELECT_FIELD,
//                 isRequired: true
//             },
//             {
//                 title: 'Percentage',
//                 field: 'contingenciesPercentage',
//                 type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
//                 isRequired: true
//             },
//         ]
//     }
// ];

export const MEASUREMENT_TABLE_COLUMNS: any = [
    {
        titleCode: 'ITEM_NO',
        colName: 'Item No.',
        fieldName: 'itemNo',
        cellstyle: { width: '10%' },
        // class: 'col-sm-4',
        clsName: 'column-text-start'
    },
    {
        titleCode: 'GSR_NUMBER',
        colName: 'Gsr No.',
        fieldName: 'gsrItemNo',
        cellstyle: { width: '10%' },
        // class: 'col-sm-4',
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'ITEM_DESC',
        colName: 'Item Description',
        fieldName: 'itemDesc',
        cellstyle: { width: '20%' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'UNIT_RATE_INR',
        colName: 'Unit Rate (INR)',
        fieldName: 'unitRate',
        cellstyle: { width: '20%' },
        clsName: 'column-text-end',
        type: 'text'
    },
    {
        titleCode: 'MEASUREMENT TYPE',
        colName: 'Measurement Type',
        fieldName: 'measurementType',
        cellstyle: { width: '20%' },
        clsName: 'column-text-start',
        type: 'text',
        id: 5
    },
    {
        titleCode: 'ITEM TYPE',
        colName: 'Item Type',
        fieldName: 'itemType',
        cellstyle: { width: '20%' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'TOTAL_QUANTITY',
        colName: 'Total Quantity',
        fieldName: 'totalQuantity',
        cellstyle: { width: '10%' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['edit', 'view', 'delete']
    }
];

export const CONTINGENCIES_COLUMN = [
    {
        colName: 'Cost Head',
        fieldName: 'costHead',
        cellstyle: { width: '40%' },
        clsName: 'column-text-start'
        // id: 1,
    },
    {
        colName: 'Percentage (in %)',
        fieldName: 'percentage',
        cellstyle: { width: '30%' },
        clsName: 'column-text-start'
    },
    {
        colName: 'Cost',
        fieldName: 'cost',
        cellstyle: { width: '30%' },
        clsName: 'column-text-end'
    }
    // {
    //     colName: 'Action',
    //     type: 'action',
    //     clsName: 'fixed-action-column fixed-action-column-bg',
    //     actions: ['view', 'edit', 'delete']
    // }
];

export const ABSTRACT_TABLE_COLUMNS: any = [
    {
        titleCode: 'ITEM_NO',
        colName: 'Item No.',
        fieldName: 'itemNo',
        cellstyle: { width: '100px' },
        // class: 'col-sm-4',
        clsName: 'column-text-start'
    },
    {
        titleCode: 'GSR_NUMBER',
        colName: 'Gsr No.',
        fieldName: 'gsrItemNo',
        cellstyle: { width: '100px' },
        // class: 'col-sm-4',
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'ITEM_DESC',
        colName: 'Item Description',
        fieldName: 'itemDesc',
        cellstyle: { width: '150px' },
        // class: 'col-sm-4',
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'UNIT_RATE_INR',
        colName: 'Unit Rate (INR)',
        fieldName: 'unitRate',
        cellstyle: { width: '140px' },
        clsName: 'column-text-end',
        type: 'text',
        id: 4
    },
    {
        titleCode: 'MEASUREMENT TYPE',
        colName: 'Measurement Type',
        fieldName: 'measurementType',
        cellstyle: { width: '180px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 5
    },
    {
        titleCode: 'ITEM TYPE',
        colName: 'Item Type',
        fieldName: 'itemType',
        cellstyle: { width: '100px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'TOTAL_QUANTITY',
        colName: 'Total Quantity',
        fieldName: 'totalQuantity',
        cellstyle: { width: '100px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'COST',
        colName: 'Cost',
        fieldName: 'cost',
        cellstyle: { width: '100px' },
        clsName: 'column-text-end',
        type: 'text',
        id: 11
    }
];

export const PENDING_TABLE_COLUMNS: any = [
    ...ABSTRACT_TABLE_COLUMNS.slice(0, 4),
    {
        titleCode: 'MEASUREMENT TYPE',
        colName: 'Measurement Type',
        fieldName: 'type',
        cellstyle: { width: '180px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 5
    },
    ...ABSTRACT_TABLE_COLUMNS.slice(6, 8)
];

export const initialMeasurements = {
    length: '',
    breadth: '',
    depth: '',
    nos: '',
    quantity: ''
};

export const setDprHeaderData = async (resData: any, dropdownOptions: any) => {
    console.log('Response>>', resData);
    console.log(dropdownOptions);
    let initialData: any = {};
    initialData['projectName'] = resData?.projectName;
    initialData['estate'] = { code: resData?.estateMasterDetails?.uuid, name: resData?.estateMasterDetails?.estateName };
    initialData['projectType'] = getDropdownSelectedObject(resData?.projectType, dropdownOptions['projectType'] || []);
    initialData['natureOfWork'] = getDropdownSelectedObject(resData?.natureOfWork, dropdownOptions['natureOfWork'] || []);
    initialData['periodConsumed'] = resData?.periodConsumed;

    return initialData;
};

export const setDprMeasurmentData = async (resData: any, dropdownOptions: any) => {
    const data = resData
        ? await Promise.all(
              resData?.map(async (item: any) => ({
                  ...item,
                  itemNo: item.itemOrderNo,
                  lbdOrNonlbd: item.lbdMeasurementType,
                  document: item.documentRefId ? await entityDocumentData(item.documentRefId) : null,
                  cost: item.itemCost,
                  gsrYear: item.gsrMasterDetailsModel?.gsrYear,
                  gsrName: item.gsrMasterDetailsModel?.gsrName,
                  gsrItemNo: item?.gsrMasterDetailsModel?.itemNo,
                  // gsrItemNo: getDropdownSelectedObject(item.gsrMasterDetailsModel.itemNo, dropdownOptions['gsrItemNo'] || []),
                  itemDesc: item.itemDesc,
                  gsrUnitRate: item.gsrMasterDetailsModel?.gsrUnitRate,
                  measurements: item.dprItemMeasurementDetailsModelList,
                  measurementType: getDropdownSelectedObject(item.measurementType, dropdownOptions['measurementType'] || [])?.name,
                  measurementTypeCode: getDropdownSelectedObject(item.measurementType, dropdownOptions['measurementType'] || [])?.code,
                  itemType: item?.gsrMasterDetailsModel?.gsrMaster ? 'Scheduled' : 'Non-Scheduled',
                  gsrMarketRate: item?.gsrMasterDetailsModel?.gsrMaster ? 'GSR' : 'MarketRate',
                  rowId: item.uuid,
                  gsrMasterDetailsModel: item.gsrMasterDetailsModel,
                  mvUnitRate: item.unitRate
                  // lbdOrNonlbd: item?.lbdMeasurementType
              }))
          )
        : [];
    return data;
};

export const DPR_SEARCH_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'DPR_APPLICATION_NUMBER',
                title: 'Enter DPR Application Number',
                field: 'dprApplicationNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5'
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'industrialEstate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            },
            {
                titleCode: 'STATUS',
                title: 'Status',
                field: 'status',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];

export const DPR_SEARCH_TABLE: any = [
    {
        titleCode: 'SERIAL_NO',
        colName: 'S.N.',
        fieldName: 'serialNo',
        clsName: 'column-text-start dpr-serail-no'
    },
    {
        titleCode: 'DPR_NO',
        colName: 'DPR No',
        fieldName: 'dprNo',
        clsName: 'column-text-start dpr-search-column',
        isClickable: true
    },
    {
        titleCode: 'PROJECT_NAME',
        colName: 'Project Name',
        fieldName: 'projectName',
        clsName: 'column-text-start dpr-search-column'
    },
    {
        titleCode: 'INDUSTRIAL_NAME',
        colName: 'Industrial Name',
        fieldName: 'industrialName',
        clsName: 'column-text-start dpr-search-column'
    },
    {
        titleCode: 'PROJECT_TYPE',
        colName: 'Project Type',
        fieldName: 'projectType',
        clsName: 'column-text-start dpr-search-column'
    },
    {
        titleCode: 'ESTIMATE_AMOUNT',
        colName: 'Estimate Amount',
        fieldName: 'estimateAmount',
        clsName: 'column-text-start dpr-search-column'
    },
    {
        titleCode: 'STATUS',
        colName: 'Status',
        fieldName: 'stat',
        clsName: 'column-text-start dpr-search-column'
    }
];
