import { isDisabled } from '@testing-library/user-event/dist/utils';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../utils/constants';
import { requestInfo } from '../../services/modulesServices/userManagement.api';
import { MORTGAGE_LESSEE_DETAILS } from '../modules/Mortgage Lessee/Mortgage.const';

export const WATER_CONNECTION_FORM_STEPS = [
    {
        titleCode: 'CONNECTION_DETAILS',
        name: 'Connection Details',
        code: 'CONNECTION_DETAILS',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'PAYMENT',
        name: 'Payment',
        code: 'PAYMENT',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'ACKNOWLDGEMENT',
        name: 'Acknowldgement',
        code: 'ACKNOWLDGEMENT',
        index: 3,
        isActive: true
    }
];

export const WATER_LESSEE_DETAILS: FormDataDTO[] = [...MORTGAGE_LESSEE_DETAILS.slice(0, 2)];

export const lesseInitialValue = async (response: any) => {
    const initialValue: any = {};
    initialValue['name'] = response?.leasseeDetails?.entityName;
    initialValue['emailId'] = response?.leasseeDetails?.email;
    initialValue['phoneNumber'] = response?.leasseeDetails?.phoneNumber;
    initialValue['address1'] = response?.leasseeDetails?.addresses[1]?.address1;
    initialValue['address2'] = response?.leasseeDetails?.addresses[1]?.address2;
    initialValue['pinCode'] = response?.leasseeDetails?.addresses[1]?.pinCode;
    initialValue['district'] = response?.leasseeDetails?.addresses[1]?.district;
    initialValue['state'] = response?.leasseeDetails?.addresses[1]?.state;
    initialValue['country'] = response?.leasseeDetails?.addresses[1]?.country;

    return initialValue;
};

export const WATER_CONNECTION_FORM_STEPS_APPROVE = [
    {
        titleCode: 'CONNECTION_DETAILS',
        name: 'Connection Details',
        code: 'CONNECTION_DETAILS',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'PAYMENT',
        name: 'Payment',
        code: 'PAYMENT',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'ACKNOWLDGEMENT',
        name: 'Acknowldgement',
        code: 'ACKNOWLDGEMENT',
        index: 3,
        isActive: true
    },
    {
        name: 'Questionnaire',
        code: 'QUESTIONNAIRE',
        titleCode: 'QUESTIONNAIRE',
        index: 4,
        isActive: true
    }
];

export const CONNECTION_DETAILS_FORM_1: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Plot Number',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isDisabled: false,
                class: 'col-6',
                isRequired: true,
                options: []
                // width: 'half'
            },
            {
                title: 'Estate Name',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-6',
                isDisabled: true,
                // width: 'half',
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Industrial Estate</p>'
                }
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Sub-Lessee',
                field: 'subLessee',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                // isRequired: true,
                class: 'col-6'
                // width: 'half'
            }
        ]
    }
];

export const CONNECTION_DETAILS_FORM_2: any[] = [
    {
        title: 'Connection Parameter',
        titleCode: 'Connection Parameter',
        formData: [
            {
                title: 'Connection Type',
                field: 'connectionType',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isRequired: true,
                isDisabled: true,
                options: [
                    {
                        name: 'New Connection',
                        title: 'New Connection',
                        value: 'NEW_CONNECTION',
                        subcategory: []
                    }
                ],
                class: 'col-sm-6'
                // width: 'half'
            },
            {
                title: 'Water Type',
                field: 'waterType',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isRequired: true,
                options: [],
                class: 'col-sm-6'
                // width: 'half'
            },
            {
                title: 'Connection Pipe dia (in mm)',
                field: 'connectionPipeDia',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'Monthly Consumption (in m\u00B3)',
                field: 'monthlyConsumption',
                type: 'number',
                isRequired: true,
                class: 'col-sm-6'
            }
        ]
    }
];

export const CONNECTION_DETAILS_FORM_FOR_EMP: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Plot Number',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isDisabled: true,
                class: 'col-6',
                isRequired: true
                // width: 'half'
            },
            {
                title: 'Estate Name',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-6',
                isDisabled: true,
                // width: 'half',
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Industrial Estate</p>'
                }
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Sub-Lessee',
                field: 'subLessee',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            }
        ]
    }
];

export const CONNECTION_DETAILS_FORM_FOR_EMP_SECOND: any[] = [
    {
        title: 'Connection Parameter',
        titleCode: 'Connection Parameter',
        formData: [
            {
                title: 'Connection Type',
                field: 'connectionType',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isRequired: true,
                isDisabled: true,
                options: [
                    {
                        name: 'New Connection',
                        title: 'New Connection',
                        value: 'NEW_CONNECTION',
                        subcategory: []
                    },
                    {
                        name: 'Upgrade Connection',
                        title: 'Upgrade Connection',
                        value: 'UPGRADE_CONNECTION',
                        subcategory: []
                    }
                ],
                class: 'col-sm-6'
                // width: 'half'
            },
            {
                title: 'Water Type',
                field: 'waterType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-sm-6'
                // width: 'half'
            },
            {
                title: 'Connection Pipe dia (in mm)',
                field: 'connectionPipeDia',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'Monthly Consumption (in m\u00B3)',
                field: 'monthlyConsumption',
                isDisabled: true,
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-6'
            }
        ]
    }
];

export const UPGRADATION_CONNECTION_DETAILS_FORM_1: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Plot Number',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isDisabled: false,
                class: 'col-6',
                isRequired: true,
                options: []
                // width: 'half'
            },
            {
                title: 'Estate Name',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-6',
                isDisabled: true,
                // width: 'half',
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Industrial Estate</p>'
                }
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Sub-Lessee',
                field: 'subLessee',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                // isRequired: true,
                class: 'col-6'
                // width: 'half'
            }
        ]
    },
    {
        title: 'Connection Parameter',
        titleCode: 'Connection Parameter',
        formData: [
            {
                title: 'Connection Type',
                field: 'connectionType',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                // isRequired: true,
                isDisabled: true,
                options: [
                    {
                        name: 'Upgrade Connection',
                        title: 'Upgrade Connection',
                        value: 'UPGRADE_CONNECTION',
                        subcategory: []
                    }
                ],
                class: 'col-sm-6'
                // width: 'half'
            }
        ]
    }
];

export const UPGRADATION_CONNECTION_DETAILS_FORM_FOR_EMP: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Plot Number',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-6',
                isRequired: true,
                options: []
                // width: 'half'
            },
            {
                title: 'Estate Name',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-6',
                isDisabled: true,
                // width: 'half',
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Industrial Estate</p>'
                }
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Sub-Lessee',
                field: 'subLessee',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                // isRequired: true,
                class: 'col-6'
                // width: 'half'
            }
        ]
    },
    {
        title: 'Connection Parameter',
        titleCode: 'Connection Parameter',
        formData: [
            {
                title: 'Connection Type',
                field: 'connectionType',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                // isRequired: true,
                isDisabled: true,
                options: [
                    {
                        name: 'Upgrade Connection',
                        title: 'Upgrade Connection',
                        value: 'UPGRADE_CONNECTION',
                        subcategory: []
                    }
                ],
                class: 'col-sm-6'
                // width: 'half'
            }
        ]
    }
];

export const UPGRADED_CONNECTION_DETAILS_FORM_2: any[] = [
    {
        title: 'Upgrade Connection Details',
        titleCode: 'Upgrade Connection Details',
        formData: [
            {
                title: 'Connection Pipe dia (in mm)',
                field: 'connectionPipeDia',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                // isRequired: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Monthly Consumption (in m\u00B3)',
                field: 'monthlyConsumption',
                type: 'number',
                // isRequired: true,
                class: 'col-6'
                // width: 'half'
            }
        ]
    }
];
export const UPGRADED_CONNECTION_DETAILS_FORM_FOR_EMP_SECOND: any[] = [
    {
        title: 'Upgrade Connection Details',
        titleCode: 'Upgrade Connection Details',
        formData: [
            {
                title: 'Connection Pipe dia (in mm)',
                field: 'connectionPipeDia',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                // isRequired: true,
                class: 'col-6',
                isDisabled: true
                // width: 'half'
            },
            {
                title: 'Monthly Consumption (in m\u00B3)',
                field: 'monthlyConsumption',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                // isRequired: true,
                class: 'col-6',
                isDisabled: true
                // width: 'half'
            }
        ]
    }
];

export const INSTALLATION_DETAILS: any[] = [
    {
        title: '',
        titleCode: '',
        formData: [
            {
                title: 'Connection installed',
                field: 'connectionStatus',
                type: CONSTANT_DATA_FIELD.TOGGLE_FIELD,
                isRequired: true,
                class: 'col-sm-12'
                // width: 'half'
            },
            {
                title: 'Meter Number',
                field: 'meterNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Connection Date',
                field: 'connectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-6',
                enableFutureDate: true
                // width: 'half'
            }
        ]
    }
];

export const DISCONNECTION_CONNECTION_DETAILS_FORM_1: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Plot Number',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isDisabled: false,
                class: 'col-6',
                isRequired: true,
                options: []
                // width: 'half'
            },
            {
                title: 'Estate Name',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-6',
                isDisabled: true,
                // width: 'half',
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Industrial Estate</p>'
                }
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Sub-Lessee',
                field: 'subLessee',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                // isRequired: true,
                class: 'col-6'
                // width: 'half'
            }
        ]
    },
    {
        title: 'Connection Parameter',
        titleCode: 'Connection Parameter',
        formData: [
            {
                title: 'Connection Type',
                field: 'connectionType',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                // isRequired: true,
                isDisabled: true,
                options: [
                    {
                        name: 'Disconnection Connection',
                        title: 'Disconnection Connection',
                        value: 'DISCONNECT_CONNECTION',
                        subcategory: []
                    }
                ],
                class: 'col-sm-6'
                // width: 'half'
            }
        ]
    }
];
export const DISCONNECTION_CONNECTION_DETAILS_FORM_FOR_EMP: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Plot Number',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-6',
                isRequired: true,
                options: []
                // width: 'half'
            },
            {
                title: 'Estate Name',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-6',
                isDisabled: true,
                // width: 'half',
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Industrial Estate</p>'
                }
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Sub-Lessee',
                field: 'subLessee',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                // isRequired: true,
                isDisabled: true,

                class: 'col-6'
                // width: 'half'
            }
        ]
    },
    {
        title: 'Connection Parameter',
        titleCode: 'Connection Parameter',
        formData: [
            {
                title: 'Connection Type',
                field: 'connectionType',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                // isRequired: true,
                isDisabled: true,
                options: [
                    {
                        name: 'Disconnection Connection',
                        title: 'Disconnection Connection',
                        value: 'DISCONNECT_CONNECTION',
                        subcategory: []
                    }
                ],
                class: 'col-sm-6'
                // width: 'half'
            }
        ]
    }
];

export const DISCONNECTION_CONNECTION_DETAILS_FORM_2: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Disconnection Date',
                field: 'disconnectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: false,
                class: 'col-6',
                isRequired: true,
                options: [],
                enableFutureDate: true
                // width: 'half'
            },
            {
                title: 'Last Meter Reading',
                field: 'lastReading',
                type: 'number',
                isRequired: true,
                class: 'col-6',
                isDisabled: false
                // width: 'half',
            }
        ]
    }
];
export const DISCONNECTION_CONNECTION_DETAILS_FORM_FOR_EMP_SECOND: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Disconnection Date',
                field: 'disconnectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                class: 'col-6',
                isRequired: false,
                options: []
                // width: 'half'
            },
            {
                title: 'Last Meter Reading',
                field: 'lastReading',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                class: 'col-6',
                isDisabled: true
                // width: 'half',
            }
        ]
    }
];

export const UPGRADATION_CONNECTION_DETAILS_FORM_2: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Existing Connection Removal Date',
                field: 'disconnectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: false,
                class: 'col-6',
                isRequired: true,
                options: [],
                enableFutureDate: true
                // disablePastDate: true
                // width: 'half'
            },
            {
                title: 'Last Reading',
                field: 'lastReading',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-6',
                isDisabled: false
                // width: 'half',
            }
        ]
    },
    {
        title: '',
        titleCode: '',
        formData: [
            {
                title: 'Connection installed',
                field: 'connectionStatus',
                type: CONSTANT_DATA_FIELD.TOGGLE_FIELD,
                isRequired: true,
                class: 'col-sm-12'
                // width: 'half'
            },
            // {
            //     title: 'Meter Number',
            //     field: 'meterNo',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true,
            //     class: 'col-6'
            //     // width: 'half'
            // },
            {
                title: 'Connection Date',
                field: 'connectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-6',
                enableFutureDate: true
                // disablePastDate: true
                // width: 'half'
            }
        ]
    }
];
export const UPGRADATION_CONNECTION_DETAILS_FORM_FOR_EMP_SECOND: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Existing Connection Removal Date',
                field: 'disconnectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: false,
                class: 'col-6',
                isRequired: true,
                options: [],
                enableFutureDate: true
                // width: 'half'
            },
            {
                title: 'Last Reading',
                field: 'lastReading',
                type: 'number',
                isRequired: true,
                class: 'col-6',
                isDisabled: false
                // width: 'half',
            }
        ]
    },
    {
        title: 'New Connection Details',
        titleCode: 'New Connection Details',
        formData: [
            {
                title: 'Connection installed',
                field: 'connectionStatus',
                type: CONSTANT_DATA_FIELD.TOGGLE_FIELD,
                isRequired: true,
                class: 'col-sm-12'
                // width: 'half'
            },
            // {
            //     title: 'Meter Number',
            //     field: 'meterNo',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true,
            //     class: 'col-6'
            //     // width: 'half'
            // },
            {
                title: 'Connection Date',
                field: 'connectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-6',
                // width: 'half',
                enableFutureDate: true
            }
        ]
    }
];

export const billPaymentColumns = [
    {
        colName: 'SN',
        fieldName: 'index',
        clsName: 'col-sm-1 my-2',
        isClickable: false
    },
    {
        colName: 'Bill No',
        fieldName: 'billNo',
        clsName: 'col-sm-2 column-text-start my-2 '
    },
    {
        colName: 'Date',
        fieldName: 'date',
        clsName: 'col-sm-2 my-2 column-text-end '
    },
    {
        colName: 'Last meter reading',
        fieldName: 'lastMeterReading',
        clsName: 'col-sm-2 my-2 column-text-end '
    },
    {
        colName: 'Current meter reading',
        fieldName: 'currentMeterReading',
        clsName: 'col-sm-2 my-2 column-text-end '
    },

    {
        colName: 'Bill Amount (in INR)',
        fieldName: 'total',
        clsName: 'col-sm-3 my-2 mx-2 column-text-end'
    }
];

export const BILL_GENERATION_DETAILS_FORM: any[] = [
    {
        title: '',
        formData: [
            {
                title: 'Plot No',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-4',
                isRequired: false
                // width: 'half'
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true,
                class: 'col-4'
                // width: 'half'
            },
            {
                title: 'Estate',
                field: 'estate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                class: 'col-4',
                isDisabled: true
                // width: 'half',
                // toolTipDetails: {
                //     position: 'bottom',
                //     content: '<p>Industrial Estate</p>'
                // }
            },
            {
                title: 'Sub-Lessee',
                field: 'subLessee',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true,
                class: 'col-4'
                // width: 'half'
            }
        ]
    }
];

export const BILL_GENERATION_DETAILS_FORM_EMP: any[] = [
    {
        title: 'Meter Readings',
        titleCode: '',
        formData: [
            {
                title: 'Last Reading',
                field: 'lastReading',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-6',
                isRequired: false
                // width: 'half'
            },
            {
                title: 'Current Reading',
                field: 'currentReading',
                type: 'number',
                isRequired: true,
                isDisabled: false,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Meter Reading Date',
                field: 'meterReadingDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-6',
                isDisabled: false,
                // width: 'half',
                enableFutureDate: true
            },
            {
                title: 'Monthly Water Charges (INR)',
                field: 'monthlyWaterCharges',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            }
        ]
    },
    {
        title: 'Plot Details',
        titleCode: 'Plot Details',
        formData: [
            {
                title: 'Plot No',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-6',
                isRequired: false
                // width: 'half'
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            },
            {
                title: 'Estate',
                field: 'estate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                class: 'col-6',
                isDisabled: true
                // width: 'half',
            },
            {
                title: 'Sub-Lessee',
                field: 'subLessee',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true,
                class: 'col-6'
                // width: 'half'
            }
        ]
    }
];

export const BILL_HISTORY_DETAILS_FORM_EMP: any[] = [
    {
        title: '',
        titleCode: '',
        formData: [
            {
                title: 'From Date',
                field: 'billGenerationDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
                // enableFutureDate: true,
                // disablePastDate: true
            },
            {
                title: 'To Date',
                field: 'billGenerationDated',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
                // enableFutureDate: true,
                // disablePastDate: true
            }
        ]
    }
];
export const columnsforWC = [
    {
        colName: 'Sl No.',
        fieldName: 'ind',
        clsName: 'col-sm-1 my-2 mx-2',
        // isClickable: false,
        cellstyle: { width: '250px' }
    },
    {
        colName: 'Description',
        fieldName: 'description',
        clsName: 'col-sm-2 text-start my-2 ',
        cellstyle: { width: '250px' }
    },
    {
        colName: 'Monthly Consumption (in m3)',
        fieldName: 'monthlyConsumption',
        clsName: 'col-sm-2 my-2 column-text-end ',
        cellstyle: { width: '250px' }
    },
    {
        colName: 'Connection Pipe dia (in mm)',
        fieldName: 'connectionPipeDia',
        clsName: 'col-sm-2 my-2 column-text-end ',
        cellstyle: { width: '250px' }
    }
];

export const modifyMDMSDataList = (data: any, valueKey: any, titleKey: any) => {
    let newList: any = [];
    if (!!data.length) {
        data.map((val: any) => {
            newList.push({
                name: val[titleKey],
                title: val[titleKey],
                value: val[valueKey],
                code: val[valueKey],
                subcategory: []
            });
        });
        return newList;
    }
};

export const modifyPlotList = (data: any) => {
    let newList: any = [];
    if (!!data.length) {
        data.map((val: any) => {
            newList.push({
                name: val?.plotDetails?.plotNo,
                title: val?.plotDetails?.plotNo,
                value: val?.plotDetails?.uuid,
                code: val?.plotDetails?.uuid,
                subcategory: []
            });
        });
        return newList;
    }
};

export const paymentPayloadforWaterConnection = (
    applicationDetails: any,
    plotUuid: any,
    totalPaidAmount: any,
    url: any,
    info: any,
    selectedGateway: any,
    periodFrom: any,
    periodTo: any,
    paymentData: any
) => {
    return {
        RequestInfo: requestInfo(),
        applicationNumber: applicationDetails?.applicationNo,
        plotId: plotUuid,
        totalPaidAmount: +totalPaidAmount,
        callbackUrl: url,
        productInfo: info,
        gateway: selectedGateway,
        periodFrom: periodFrom,
        periodTo: periodTo,
        payNowPaymentDetails: paymentData.map((obj: any) => ({
            scheduledPaymentDetails: {
                paymentType: obj.paymentType,
                principalAmount: +obj.principalAmount,
                cgstPrincipal: +obj.cgstAmount,
                sgstPrincipal: +obj.sgstAmount,
                totalDueAmount: parseFloat(((+obj.principalAmount || 0) + (+obj.cgstAmount || 0) + (+obj.sgstAmount || 0)).toFixed(2)),
                paidAmount: parseFloat(((+obj.principalAmount || 0) + (+obj.cgstAmount || 0) + (+obj.sgstAmount || 0)).toFixed(2))
            }
        })),

        applicationId: applicationDetails?.uuid
    };
};

export const paymentPayloadforWaterBill = (
    applicationDetails: any,
    plotUuid: any,
    totalPaidAmount: any,
    url: any,
    info: any,
    selectedGateway: any,
    periodFrom: any,
    periodTo: any,
    paymentData: any
) => {
    return {
        RequestInfo: requestInfo(),
        applicationNumber: applicationDetails?.billNo,
        plotId: plotUuid,
        totalPaidAmount: +totalPaidAmount,
        callbackUrl: url,
        gateway: selectedGateway,
        periodFrom: periodFrom,
        periodTo: periodTo,
        payNowPaymentDetails: paymentData.map((obj: any) => ({
            scheduledPaymentDetails: {
                paymentType: obj.paymentType,
                principalAmount: +obj.principalAmount,
                cgstPrincipal: +obj.cgstAmount,
                sgstPrincipal: +obj.sgstAmount,
                totalDueAmount: parseFloat(((+obj.principalAmount || 0) + (+obj.cgstAmount || 0) + (+obj.sgstAmount || 0)).toFixed(2)),
                paidAmount: parseFloat(((+obj.principalAmount || 0) + (+obj.cgstAmount || 0) + (+obj.sgstAmount || 0)).toFixed(2))
            }
        })),

        applicationId: applicationDetails?.uuid,
        paymentType: 'WATER_CHARGES',
        productInfo: 'Monthly Water Charges'
    };
};
