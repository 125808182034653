import React from 'react';

import underConstructionimg from '../../assets/images/underConstructionimg.png'
import HeaderComponent from '../../components/header/Header.component';
import FooterComponent from '../../components/portalComponents/Footer.component';

export default function UnderConstruction() {
    return (
        <>
            <HeaderComponent />
            <div className='my-5 d-flex flex-column font-inter align-items-center justify-content-center'>
                <div>
                    <img className='mt-5' src={underConstructionimg} height={200} width={200} alt="underConstruction img" style={{ imageResolution: 'from-image' }} />
                </div>
                <div className='my-5 d-flex flex-column align-items-center justify-content-center '>
                    <div className='mb-0'>
                        <p className='font-inter-med' style={{ fontWeight: 500, fontSize: 64, lineHeight: '89.6px', letterSpacing: '-1.28px', color: '#45852E' }}>Under Construction!</p>
                    </div>
                    <div>
                        <p className='font-karla-med' style={{ fontWeight: 500, fontSize: '20px', lineHeight: '32px', letterSpacing: '-0.4px', color: '#000' }}>We’ll finish this page shortly.</p>
                    </div>
                    <div>
                        <a className="btn font-karla-reg" href="/goa-idc/" role="button" style={{ width: '257px', padding: '18px, 48px, 18px, 48px', color: '#000', background: '#80B036', fontWeight: 400, fontSize: '20px', lineHeight: '32px', letterSpacing: '-0.4px' }}>Back to Home</a>
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}