import { ErrorMessage, Field, Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
import HeaderComponent from '../../../../components/header/Header.component';
import arrowImage from '../../../../assets/logos/backArrow.svg';
import { useTranslation } from 'react-i18next';
import './Dashboard.scss';
import { POST, endpoints, getDropdownOptions, getUserRoles, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { dropdownOptions } from '../types';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { useNavigate } from 'react-router-dom';
import './Dashboard.scss';
import { SEARCH_TABLE_COLUMNS_DATA } from '../UserManagement.const';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { check, cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';

const EmployeeSearch = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const active_deactive = urlParams.get('active_deactive') || false;

    const [dropdownData, setDropdownData] = useState<any>([]);
    const [rolesDropdownData, setRolesDropdownData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState({});
    const [rowData, setRowData] = useState([]);
    const columns = [
        {
            colName: 'Employee Name',
            fieldName: 'user.firstName',
            fieldName2: 'user.lastName',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Employee ID',
            fieldName: 'code',
            clsName: 'col-sm-2',
            isClickable: true
        },
        {
            colName: 'Designation',
            fieldName: `assignments[0].designation`,
            clsName: 'col-sm-3'
        },
        {
            colName: 'Status',
            fieldName: 'employeeStatus',
            clsName: `col-sm-3 bg`,
            bg: 'employeeStatus'
        }
    ];

    const initialData = {
        employeeId: '',
        employeeFirstName: '',
        employeeLastName: '',
        employeeDepartment: '',
        employeeDesignation: '',
        employeeRole: '',
        employeeStatus: ''
    };
    const formik = useFormik<any>({
        initialValues: {},
        onSubmit: async (values, actions) => {
            const mapValues = Object.entries(values).filter(([key, value]) => value !== '');
            try {
                const queryParams = new URLSearchParams();
                if (values.employeeId) queryParams.append('codes', values.employeeId);
                if (values.employeeRole) queryParams.append('roles', values.employeeRole);
                if (values.employeeDepartment) queryParams.append('departments', values.employeeDepartment);
                if (values.employeeDesignation) queryParams.append('designations', values.employeeDesignation);
                if (values.employeeFirstName) queryParams.append('firstName', values.employeeFirstName);
                if (values.employeeLastName) queryParams.append('lastName', values.employeeLastName);
                if (values.employeeStatus) queryParams.append('employeeStatuses', values.employeeStatus);

                const { data } = await POST(endpoints.searchEmployee + `&${queryParams.toString()}`, { RequestInfo: requestInfo() });
                setRowData(data.Employees);
            } catch (error) {
                let msg = t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                setModalDetails({ show: true, title: 'Error!', para: msg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    });

    const { setValues } = formik;

    useEffect(() => {
        (async () => {
            try {
                const dropdownOptions = await getDropdownOptions();
                const { data } = await getUserRoles();
                setRolesDropdownData(data);
                setDropdownData(dropdownOptions);
            } catch (error) {
                console.error('Error fetching dropdown options:', error);
            }
        })();
    }, []);

    const getNewRowData = () => {
        const newRowData =
            Array.isArray(rowData) &&
            rowData?.map((data: any) => {
                const primaryAssignment = data.assignments?.find((item: any) => item?.isPrimary);

                if (data.assignments) {
                    dropdownData?.designation.map((designationDdata: any) => {
                        if (designationDdata.code === primaryAssignment?.designation) {
                            primaryAssignment.designation = designationDdata.name;
                        }
                    });
                    data.assignments = [primaryAssignment];
                }
                if (data.employeeStatus) {
                    dropdownData?.status.map((statusData: any) => {
                        if (statusData.code === data.employeeStatus) {
                            data.employeeStatus = statusData.name;
                        }
                    });
                }
                return data;
            });

        return newRowData;
    };

    const onRowClick = async (rowData: any) => {
        const { data } = await POST(endpoints.searchEmployee + `&registrationNumber=${rowData.user.registrationNumber}`, { RequestInfo: requestInfo() });
        navigate('/goa-idc/empdetails', { state: { data, active_deactive } });
    };

    const handleOnReset = () => {
        setValues(initialData);
        setRowData([]);
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <HeaderComponent />
            <div className='container search_application_parents'>
                <div className='row mt-4'>
                    <div className='col-sm-12'>
                        <GenericHeader text={active_deactive ? t('EMPLOYEE_REACTIVATION_DEACTIVATION', 'Employee Reactivation/Deactivation') : t('EMPLOYEE_SEARCH', 'Employee Search')} />

                        <Card className='card_border mt-4 py-2'>
                            <Card.Body>
                                {/* <Formik initialValues={SEARCH_APPLICATION_INITIAL_DATA} validationSchema={EMPLOYEE_SEARCH_VALIDATION} onSubmit={() => { }}> */}
                                <FormikProvider value={formik}>
                                    <Form>
                                        <div className='row gx-3 gy-4 px-3 py-1 '>
                                            <div className='col-sm-4 d-flex flex-column'>
                                                <label htmlFor='employeeId' className='search_application_label'>
                                                    {t('EMPLOYEE_ID', 'Employee ID')}
                                                </label>
                                                <Field name='employeeId' type='text' placeholder='' className='form-control' />
                                            </div>
                                            <div className='col-sm-4 d-flex flex-column'>
                                                <label htmlFor='employeeFirstName' className='search_application_label'>
                                                    {t('FIRST_NAME', 'FirstName')}
                                                </label>
                                                <Field name='employeeFirstName' type='text' placeholder='' className='form-control' />
                                            </div>
                                            <div className='col-sm-4 d-flex flex-column'>
                                                <label htmlFor='employeeLastName' className='search_application_label'>
                                                    {t('LAST_NAME', 'Last Name')}
                                                </label>
                                                <Field name='employeeLastName' type='text' placeholder='' className='form-control' />
                                            </div>
                                            <div className='col-sm-4 d-flex flex-column '>
                                                <label htmlFor='employeeDepartment' className='search_application_label'>
                                                    {t('DEPARTMENT', 'Department')}
                                                </label>
                                                <Field name='employeeDepartment' className='form-control' as='select'>
                                                    <option value='' disabled selected hidden>
                                                        {t('SELECT', 'Select')}
                                                    </option>
                                                    {dropdownData?.department?.map((data: any, index: number) => (
                                                        <option value={data?.code}>{data?.name}</option>
                                                    ))}
                                                </Field>
                                                {/* <ErrorMessage name="employeeStatus">{(msg) => <span className="text-danger font-12">{msg}</span>}</ErrorMessage> */}
                                            </div>
                                            <div className='col-sm-4 d-flex flex-column '>
                                                <label htmlFor='employeeDesignation' className='search_application_label'>
                                                    {t('DESIGNATION', 'Designation')}
                                                </label>
                                                <Field name='employeeDesignation' className='form-control' as='select'>
                                                    <option value='' disabled selected hidden>
                                                        {t('SELECT', 'Select')}
                                                    </option>
                                                    {dropdownData?.designation?.map((data: any, index: number) => (
                                                        <option value={data?.code}>{data?.name}</option>
                                                    ))}
                                                </Field>
                                                {/* <ErrorMessage name="employeeStatus">{(msg) => <span className="text-danger font-12">{msg}</span>}</ErrorMessage> */}
                                            </div>
                                            <div className='col-sm-4 d-flex flex-column '>
                                                <label htmlFor='employeeRole' className='search_application_label'>
                                                    {t('ROLE', 'Role')}
                                                </label>
                                                <Field name='employeeRole' className='form-control' as='select'>
                                                    <option value='' disabled selected hidden>
                                                        {t('SELECT', 'Select')}
                                                    </option>
                                                    {rolesDropdownData?.roles?.map((data: any, index: number) => (
                                                        <option value={data?.code}>{data?.name}</option>
                                                    ))}
                                                </Field>
                                                {/* <ErrorMessage name="employeeStatus">{(msg) => <span className="text-danger font-12">{msg}</span>}</ErrorMessage> */}
                                            </div>
                                            <div className='col-sm-4 d-flex flex-column '>
                                                <label htmlFor='employeeStatus' className='search_application_label'>
                                                    {t('STATUS', 'Status')}
                                                </label>
                                                <Field name='employeeStatus' className='form-control' as='select'>
                                                    <option value='' disabled selected hidden>
                                                        {t('SELECT', 'Select')}
                                                    </option>
                                                    {dropdownData?.status?.map((data: any, index: number) => (
                                                        <option value={data?.code}>{data?.name}</option>
                                                    ))}
                                                </Field>
                                                {/* <ErrorMessage name="employeeStatus">{(msg) => <span className="text-danger font-12">{msg}</span>}</ErrorMessage> */}
                                            </div>

                                            <div className='col-sm-12  d-flex justify-content-end mt-0 '>
                                                <GenericButtons text={t('RESET', 'Reset')} ClassName='me-3' type='reset' handleClick={handleOnReset} />
                                                <GenericButtons disabled={!Object.values(formik.values).length} type='submit' text={t('SUBMIT', 'Submit')} variant='primary' ClassName='me-3' />
                                            </div>
                                        </div>
                                    </Form>
                                </FormikProvider>
                                {/* </Formik> */}
                            </Card.Body>
                        </Card>
                    </div>

                    <div className='col-sm-12'>
                        <Card className='card_border my-4 py-2'>
                            <Card.Body>
                                <h3 className='Employee_search_result'>Search Result</h3>
                                <GenericActionTable columns={columns} rowData={getNewRowData()} additionalMethod={onRowClick} hideSearchBar={true} />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeeSearch;
