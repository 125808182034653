import { DocumentDetails, dropdownOptionsInterface } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { git_logo } from '../../../../utils/Logo';
import { formatedDocumentDetails } from '../../../../utils/utils';
import { getPlotDetails } from '../../EstateManagement/Allotment/Allotment.controller';
import { getDocumentDetails, getDocumentDetailsID, getDocumentFileName, getDropdownSelectedObject } from '../UserMangement.controller';

export const ARCHITECT_DATA = [
    {
        obpas: 'OBPAS',
        designNo: '198',
        status: 'Design Submitted'
    },
    {
        obpas: 'OBPAS',
        designNo: '167',
        status: 'Approved Design'
    },
    {
        obpas: 'OBPAS',
        designNo: '031',
        status: 'Pending Design'
    }
];

export const ARCHITECT_CORRESPONDENCE = [
    {
        title: 'A - Personal Details',
        field: 'personalDetails'
    },
    {
        title: 'B - License Details',
        field: 'licenseDetails'
    },
    {
        title: 'C - Documents',
        field: 'documents'
    }
];

export const getArcInitialValue = async (res: any, dropdownOptions: dropdownOptionsInterface) => {
    console.log('res', res);
    const refIDs = res?.documents?.length && res.documents?.map((docItem: any) => docItem?.referenceId);
    console.log('refIDs', refIDs);
    const documentData = refIDs && (await formatedDocumentDetails(refIDs.join(), true));
    console.log('documents', documentData);

    const initialData: any = {};
    initialData['firstnamesalutation'] = res?.user?.salutation;
    initialData['firstname'] = res?.user?.firstName;
    initialData['lastname'] = res?.user?.lastName;
    initialData['phoneNo'] = +res?.user?.mobileNumber;
    initialData['emailId'] = res?.user?.emailId;
    initialData['gender'] = getDropdownSelectedObject(res.user?.gender, dropdownOptions['gender'] || []);
    initialData['dob'] = new Date(res?.user?.dob)?.toISOString()?.split('T')[0];
    initialData['address1'] = res?.user?.correspondenceAddress1;
    initialData['address2'] = res?.user?.correspondenceAddress2;
    initialData['pincode'] = res?.user?.correspondencePinCode;
    initialData['district'] = res?.user?.correspondenceDistrict;
    initialData['state'] = res?.user?.correspondenceState;
    initialData['country'] = res?.user?.correspondenceCountry;
    initialData['peraddress1'] = res?.user?.permanentAddress1;
    initialData['peraddress2'] = res?.user?.permanentAddress2;
    initialData['perpincode'] = res?.user?.permanentPinCode;
    initialData['perdistrict'] = res?.user?.permanentDistrict;
    initialData['perstate'] = res?.user?.permanentState;
    initialData['percountry'] = res?.user?.permanentCountry;
    initialData['validFrom'] = new Date(res?.licenseDetail?.validFrom)?.toISOString()?.split('T')[0];
    initialData['validTo'] = new Date(res?.licenseDetail?.validTo)?.toISOString()?.split('T')[0];
    initialData['licenseeType'] = getPlotDetails(res?.licenseDetail?.technicalPersonCode);
    initialData['CouncilArcNo'] = res?.licenseDetail?.councilOfArchitectNo;
    initialData['adhar'] = documentData && getDocumentDetails(documentData, getDocumentDetailsID(res?.documents, 'IDENTITY_PROOF', 'ARCH'));
    initialData['licenseCopy'] = documentData && getDocumentDetails(documentData, getDocumentDetailsID(res?.documents, 'LICENSE_COPY', 'ARCH'));

    return initialData;
};
