import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { PasswordChangeInterface } from './LoginPage.types';
import { Formik, Field, Form, ErrorMessage, useFormik, FormikProvider } from 'formik';
import { PasswordChangeSchema } from '../../utils/formikValidation/FormikValidation';
import { useNavigate } from 'react-router-dom';
import { passwordUpdateNoLogin, updatePassword } from '../../services/modulesServices/userManagement.api';
import { useTranslation } from 'react-i18next';
import PopUps from '../../components/PopUps';
import { check, cross } from '../../utils/Logo';

interface ModalProps {
    titleCode?: any;
    handleClose(): void;
    show: boolean;
    img?: any;
    title: string;
    para?: string;
    fontsize?: string;
    reUpload?: string;
    handleSubmit?: (e: any) => void;
    type?: string;
    displayInput?: boolean;
    data?: any;
    setShowPasswordChangeModal: (e: any) => void;
}

function PasswordChangeModal(props: ModalProps) {
    const { t, i18n } = useTranslation();
    const { show, handleClose, title, fontsize, para, data, setShowPasswordChangeModal, handleSubmit, type, titleCode } = props;
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [modalDetails, setModalDetails] = useState({});
    console.log('modal Password', modalDetails);
    let navigate = useNavigate();

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleTogglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleToggleOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

    const onSubmitChangePassword = async () => {
        if (type === 'forgotPassword') {
            const payload = {
                otpReferenceId: data.otpReference,
                identity: data.identity,
                newPassword: values.newPassword,
                type: data.userType,
                tenantId: 'ga'
            };
            passwordUpdateNoLogin(payload)
                .then(res => {
                    let msg = t('PASSWORD_UPDATED_SUCCESS', 'Password updated successfully');
                    setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'Update success', reUpload: 'OK' });
                    navigate('/goa-idc/login');
                })
                .catch(e => console.log(e));
            return;
        }
        // TODO api call
        else if (!Object.keys(errors).length && values.newPassword !== values.oldPassword) {
            try {
                await updatePassword(data?.username, values);
                let msg = t('PASSWORD_UPDATED_SUCCESS', 'Password updated successfully');
                setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'Update success', reUpload: 'OK' });
                setShowPasswordChangeModal(false);
                // navigate('/goa-idc/login');
                window.location.href = '/goa-idc/login';
            } catch (e) {
                setShowPasswordChangeModal(true);
                let errMsg = t('PASSWORD_UPDATE_FAILED', 'Password change failed');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } else {
            let errMsg = t('OLD_PASSWORD_SAME', 'New password cannot be same as old password');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            setShowPasswordChangeModal(true);
        }
    };

    const formik = useFormik<any>({
        initialValues: {},
        onSubmit: onSubmitChangePassword,
        validationSchema: PasswordChangeSchema
    });

    const { values, errors } = formik;

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <div className={`password-change ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
                <div className='custom-close-button' onClick={handleClose}></div>
                <FormikProvider value={formik}>
                    <Form>
                        <Modal show={show} onHide={handleClose} backdrop='static' centered dialogClassName='password-change-modal'>
                            <div className='contents m-5'>
                                <Modal.Title className='fs-5 mb-4'>{titleCode ? t(titleCode) : title}</Modal.Title>
                                <Modal.Body>
                                    <p className={`${fontsize}`}>{para}</p>

                                    {type !== 'forgotPassword' && (
                                        <>
                                            <label className='mt-3'>{t('OLD_PASSWORD', 'Old Password')}</label>
                                            <div className='password-input-group'>
                                                <Field type={showOldPassword ? 'text' : 'password'} className='form-control password-change-input' name='oldPassword' />
                                                {showOldPassword ? (
                                                    <i className='fa fa-eye password-eye-btn' onClick={handleToggleOldPassword} aria-hidden='true'></i>
                                                ) : (
                                                    <i className='fa fa-eye-slash password-eye-btn' aria-hidden='true' onClick={handleToggleOldPassword}></i>
                                                )}
                                            </div>
                                            <ErrorMessage name='oldPassword' component='div' className='text-danger font-12' />
                                        </>
                                    )}

                                    <label className='mt-3'>{t('NEW_PASSWORD', 'New Password')}</label>
                                    <div className='password-input-group'>
                                        <Field type={showPassword ? 'text' : 'password'} className='form-control password-change-input' name='newPassword' />

                                        {showPassword ? (
                                            <i className='fa fa-eye password-eye-btn' onClick={handleTogglePassword} aria-hidden='true'></i>
                                        ) : (
                                            <i className='fa fa-eye-slash password-eye-btn' aria-hidden='true' onClick={handleTogglePassword}></i>
                                        )}
                                    </div>
                                    <ErrorMessage name='newPassword' component='div' className='text-danger font-12' />

                                    <label className='mt-3'>{t('CONFIRM_NEW_PASSWORD', 'Confirm New Password')}</label>
                                    <div className='password-input-group'>
                                        <Field type={showPassword2 ? 'text' : 'password'} className='form-control password-change-input' name='confirmNewPassword' />

                                        {showPassword2 ? (
                                            <i className='fa fa-eye password-eye-btn' onClick={handleTogglePassword2} aria-hidden='true'></i>
                                        ) : (
                                            <i className='fa fa-eye-slash password-eye-btn' aria-hidden='true' onClick={handleTogglePassword2}></i>
                                        )}
                                    </div>
                                    <ErrorMessage name='confirmNewPassword' component='div' className='text-danger font-12' />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='btn' onClick={onSubmitChangePassword} className={'success-modal'}>
                                        {t('SUBMIT', 'Submit')}
                                    </Button>
                                </Modal.Footer>
                            </div>
                        </Modal>
                    </Form>
                </FormikProvider>
            </div>
        </>
    );
}

export default PasswordChangeModal;
