import React, { useEffect, useState } from 'react';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import HeaderComponent from '../../../../components/header/Header.component';
import { OwnershipTable } from '../EnterpriseModule/OwnershipTable';
// import { ContentManagementTable } from './ContentManagementTable';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { POST, devUrl, endpoints, fetchDropdownData, getFileUrl, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { useLocation, useNavigate } from 'react-router-dom';
import { extractFileUrl, getFirstErrorKey, getNavigateTo } from '../../../../utils/utils';

// import { fetchDropdownData } from '../../../../services/modulesServices/userManagement.api';
import {
    CONTENT_MANAGEMENT_DETAILS,
    CONTENT_MANAGEMENT_TABLE_COLUMNS,
    CONTENT_MANAGEMENT_TYPE,
    CONTENT_MANAGEMENT_INSTAGRAM_TABLE_COLUMNS,
    CONTENT_MANAGEMENT_LINKEDIN_TABLE_COLUMNS,
    CONTENT_MANAGEMENT_SITEPLAN_TABLE_COLUMNS,
    CONTENT_MANAGEMENT_INSTAGRAM_DETAILS,
    CONTENT_MANAGEMENT_WHATSNEW_TABLE_COLUMNS,
    CONTENT_MANAGEMENT_SITEPLAN_DETAILS,
    CONTENT_MANAGEMENT_WHATSNEW_DETAILS,
    CONTENT_MANAGEMENT_LINKEDIN_DETAILS
} from './ContentManagement.const';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Card } from 'react-bootstrap';
import { entityDocumentData } from '../../EstateManagement/EstateManagement.controller';
import _ from 'lodash';
import moment from 'moment';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { cross, DeleteWarning } from '../../../../utils/Logo';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { TENDER_VALIDATION_SCHEMA } from '../../../../utils/formikValidation/FormikValidation';
import DeletePopUpModal from '../../../../components/moduleCommonFormik/DeletePopUp.component';
import { useTranslation } from 'react-i18next';

export default function ContentManagement() {
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [dropSitePlan, setDropSitePlan] = useState<any>(null);
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any>(null);
    const [edit, setEdit] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<any>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [contentManagementSitePlanDetails, setContentManagementSitePlanDetails] = useState<any>(CONTENT_MANAGEMENT_SITEPLAN_DETAILS);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [rowDelData, setRowDelData] = useState<any>({});

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const searchParams: any = new URLSearchParams(location.search);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: TENDER_VALIDATION_SCHEMA
    });
    const { values, setValues, setFieldValue, setFieldTouched, errors } = formik;

    console.log('values', values);

    useEffect(() => {
        const dropdownPayload = {
            RequestInfo: {},
            MdmsCriteria: {
                tenantId: 'ga',
                moduleDetails: [
                    {
                        moduleName: 'common-masters',
                        masterDetails: [
                            {
                                name: 'ContentManagementType',
                                filter: '[?(@.active == true)]'
                            }
                        ]
                    }
                ]
            }
        };
        fetchDropdownData(dropdownPayload)
            .then((res: any) => {
                const dropdownOptions = {
                    type: res['common-masters']['ContentManagementType']
                };
                setDropdownData(dropdownOptions);
                setValues((preVal: any) => ({ ...preVal, type: { name: 'Tender', code: 'TENDER' } }));
            })
            .catch((err: any) => console.log(err));
        // if (values?.type?.code) {
        //     fetchTableData();
        // }
    }, []);

    const fetchTableData = async () => {
        try {
            if (values?.type?.code === 'TENDER') {
                const tenderResponse = await POST('/egov-common-masters/tender/_search', { RequestInfo: requestInfo() });

                const tenData = (tenderResponse?.data?.tenderDetails).map((item: any) => ({
                    ...item,
                    docDetails: { docReferenceId: item.documentReferenceId, documentName: item.documentName }
                }));
                setTableData(tenData);
            } else {
                const { data } = await POST(`/egov-common-masters/idc/document/_search?documentCategories=${values?.type?.code}`, { RequestInfo: requestInfo() });
                // const tempTableData = data?.documentDetails?.length ? data?.documentDetails?.map((val: any, index: number) => ({ ...val, serialNo: index + 1 })) : [];
                const resData = (data?.documentDetails).map((item: any) => ({ ...item, docDetails: { docReferenceId: item.documentReferenceId, documentName: item.documentName } }));
                setTableData(resData);

                if (values?.type?.code === 'IDC_PORTAL_SITEPLAN' && data?.unUsedEstateList) {
                    setDropSitePlan({ estate: data?.unUsedEstateList });
                }
            }
        } catch (error) {}
    };
    useEffect(() => {
        fetchTableData();
    }, [values?.type?.code, location.search]);

    const onClickDownloadItem = async (rowData: any) => {
        try {
            const url = await getFileUrl(rowData.documentReferenceId);
            window.open(url, '_blank');
        } catch (e) {
            let errMsg = 'Something went wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getDocumentDetail = (values: any, tableData: any) => {
        let documentReferenceId = '';
        let documentName = '';
        let content = '';

        if (values?.type?.code === 'INSTAGRAM' || values?.type?.code === 'LINKEDIN') {
            documentReferenceId = values?.socialPost?.docReferenceId;
            documentName = values?.socialPost?.documentName;
        }
        else if (values?.type?.code === 'IDC_PORTAL_SITEPLAN') {
            documentReferenceId = values?.sitePlanDocument?.docReferenceId;
            documentName = values?.sitePlanDocument?.documentName;
        } else if (values?.type?.code === 'WHATS_NEW') {
            content = values?.content || 'No comments';
            documentReferenceId = values?.document?.docReferenceId;
            documentName = values?.document?.documentName ? values?.document?.documentName : values?.currentData?.documentName;
        }

        return {
            documentReferenceId: documentReferenceId || '',
            documentName: documentName || '',
            documentCategory: values?.type?.code || '',
            content: content,
            documentType: values?.type?.code === 'WHATS_NEW' ? 'TEXT' : ''
        };
    };

    const getTenderDetail = (values: any) => {
        let documentRefId, documentName, caption, openDate, bidCloseDate, publishDate, expiryDate;

        if (values?.type?.code === 'TENDER') {
            documentRefId = values?.document?.docReferenceId;
            documentName = values?.document?.documentName ? values?.document?.documentName : values?.currentData?.documentName;
            caption = values?.caption;
            openDate = values?.openDate ? moment(values.openDate).format('DD/MM/YYYY') : '';
            // openDate = values?.openDate;
            bidCloseDate = values?.bidCloseDate ? moment(values.bidCloseDate).format('DD/MM/YYYY') : '';
            publishDate = values?.publishDate ? moment(values.publishDate).format('DD/MM/YYYY') : '';
            expiryDate = values?.expiryDate ? moment(values.expiryDate).format('DD/MM/YYYY') : '';
        }
        return {
            caption: caption,
            documentReferenceId: documentRefId,
            documentName: documentName,
            openDate: openDate,
            publishDate: publishDate,
            bidCloseDate: bidCloseDate,
            expiryDate: expiryDate
        };
    };

    const onSave = async () => {
        const documentDetailsObject = getDocumentDetail(values, tableData);
        const tenderDetailsObject = getTenderDetail(values);

        let tenderPayload = {
            RequestInfo: requestInfo(),
            removeDocumentFlag: false,
            tenderDetail: { ...tenderDetailsObject, id: values?.currentData?.id }
        };

        let payload = {
            RequestInfo: requestInfo(),
            documentDetail: { ...documentDetailsObject, id: values?.currentData?.id },
            removeDocumentFlag: false
        };
        try {
            if (values?.type?.code === 'TENDER') {
                await TENDER_VALIDATION_SCHEMA.validate(values, { abortEarly: false });
            }
            try {
                if (edit && values?.type?.code === 'TENDER') {
                    await POST(endpoints.updateTender, tenderPayload);
                    setShowPopUp(!showPopUp);
                    fetchTableData();
                    afterSave();
                } else if (edit) {
                    await POST(endpoints.updatePost, payload);
                    setShowPopUp(!showPopUp);
                    fetchTableData();
                    afterSave();
                } else if (values?.type?.code === 'TENDER') {
                    await POST(endpoints.createTenderPost, tenderPayload);
                    setShowPopUp(!showPopUp);
                    fetchTableData();
                    afterSave();
                } else {
                    await POST(endpoints.createPost, payload);
                    setShowPopUp(!showPopUp);
                    fetchTableData();
                    afterSave();
                }
            } catch (e: any) {
                console.log('error', e);
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const returnFormattedDate = (dateString: any) => {
        const [day, month, year] = dateString.split('/');
        const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        return formattedDate;
    };

    const onDeleteData = async (rowDataId: any, rowData?: any) => {
        setShowDeleteModal(true);
        setRowDelData(rowData);
    };

    const getDocumentDetails = async (docRefId: any, docType: any) => {
        const documentData = (await entityDocumentData([{ docReferenceId: docRefId, documentType: docType }]))[0];
        return { ...documentData, documentName: documentData?.tag };
    };

    const onEdit = async (rowData: any) => {
        setEdit(true);

        const docData = await getDocumentDetails(rowData?.documentReferenceId, rowData?.documentType);
        const docRefName = rowData?.documentNameReference;
        const tempContentManagementSitePlanDetails = CONTENT_MANAGEMENT_SITEPLAN_DETAILS?.map((val: any) => ({
            ...val,
            formData: val?.formData?.map((item: any) => {
                return {
                    ...item,
                    isDisabled: item.field === 'estate' ? true : false
                };
            })
        }));

        setContentManagementSitePlanDetails(tempContentManagementSitePlanDetails);

        // setValues((prev: any) => ({ ...prev, socialPost: docData }));
        setValues((prev: any) => ({
            ...prev,
            content: rowData?.content,
            caption: rowData?.caption,
            expiryDate: rowData?.expiryDate ? returnFormattedDate(rowData?.expiryDate) : '',
            openDate: rowData?.openDate ? returnFormattedDate(rowData?.openDate) : '',
            bidCloseDate: rowData?.bidCloseDate ? returnFormattedDate(rowData?.bidCloseDate) : '',
            publishDate: rowData?.publishDate ? returnFormattedDate(rowData?.publishDate) : '',
            estate: { code: rowData?.documentNameReference, name: rowData?.documentNameReference },
            currentData: rowData,
            docDetails: { docReferenceId: rowData?.docDetails?.docReferenceId, documentName: rowData?.docDetails?.documentName },
            // socialPost: docData,
            document: docData,
            sitePlanDocument: null
        }));

        setShowPopUp(!showPopUp);
        // getDocumentDetails();
    };

    const afterSave = () => {
        const val = _.cloneDeep(values);
        const keysToDelete = ['socialPost', 'document', 'caption', 'expiryDate', 'openDate', 'bidCloseDate', 'publishDate', 'estate', 'content'];
        keysToDelete.forEach(key => {
            delete val[key];
        });
        setValues({ ...val });
    };

    const onClickPopUp = () => {
        setEdit(false);
        setContentManagementSitePlanDetails(CONTENT_MANAGEMENT_SITEPLAN_DETAILS);
        const val = _.cloneDeep(values);
        const keysToDelete = ['socialPost', 'document', 'caption', 'expiryDate', 'openDate', 'bidCloseDate', 'publishDate', 'estate', 'content'];
        keysToDelete.forEach(key => {
            delete val[key];
        });
        setValues({ ...val });
        setShowPopUp(true);
    };

    const renderTableData = () => {
        return (
            <>
                {values?.type?.code === 'TENDER' ? (
                    <div className='row px-2'>
                        {/* <div className='col-12'> */}
                        <OwnershipTable
                            column={CONTENT_MANAGEMENT_TABLE_COLUMNS}
                            tableRowData={tableData}
                            additionalMethod={onEdit}
                            // additionalMethod={(docId: string) => onEdit(docId)}
                            hideSearchBar={false}
                            renderButton={renderButton}
                            onClickPopUp={onClickPopUp}
                            searchBarPlaceholder={'Search'}
                            deleteOwnershipDetails={onDeleteData}
                            itemsPerPage={5}
                        />
                        {/* </div> */}
                    </div>
                ) : values?.type?.code === 'INSTAGRAM' ? (
                    <div className='row px-2'>
                        <OwnershipTable
                            column={CONTENT_MANAGEMENT_INSTAGRAM_TABLE_COLUMNS}
                            itemsPerPage={5}
                            tableRowData={tableData}
                            additionalMethod={onEdit}
                            hideSearchBar={false}
                            renderButton={renderButton}
                            onClickPopUp={onClickPopUp}
                            searchBarPlaceholder={'Search'}
                            deleteOwnershipDetails={onDeleteData}
                        />
                    </div>
                ) : values?.type?.code === 'LINKEDIN' ? (
                    <div className='row px-2'>
                        <OwnershipTable
                            column={CONTENT_MANAGEMENT_LINKEDIN_TABLE_COLUMNS}
                            tableRowData={tableData}
                            // additionalMethod={onEdit}
                            hideSearchBar={false}
                            renderButton={renderButton}
                            onClickPopUp={onClickPopUp}
                            searchBarPlaceholder={'Search'}
                            deleteOwnershipDetails={onDeleteData}
                            itemsPerPage={5}
                        />
                    </div>
                ) : values?.type?.code === 'IDC_PORTAL_SITEPLAN' ? (
                    <div className='row px-2'>
                        <OwnershipTable
                            column={CONTENT_MANAGEMENT_SITEPLAN_TABLE_COLUMNS}
                            tableRowData={tableData}
                            additionalMethod={onEdit}
                            hideSearchBar={false}
                            renderButton={renderButton}
                            onClickPopUp={onClickPopUp}
                            searchBarPlaceholder={'Search'}
                            deleteOwnershipDetails={onDeleteData}
                            itemsPerPage={5}
                        />
                    </div>
                ) : values?.type?.code === 'WHATS_NEW' ? (
                    <div className='row px-2'>
                        <OwnershipTable
                            column={CONTENT_MANAGEMENT_WHATSNEW_TABLE_COLUMNS}
                            tableRowData={tableData}
                            additionalMethod={onEdit}
                            hideSearchBar={false}
                            renderButton={renderButton}
                            onClickPopUp={onClickPopUp}
                            searchBarPlaceholder={'Search'}
                            deleteOwnershipDetails={onDeleteData}
                            itemsPerPage={5}
                        />
                    </div>
                ) : (
                    <div className='row px-2'>
                        <OwnershipTable column={CONTENT_MANAGEMENT_TABLE_COLUMNS} hideSearchBar={false} renderButton={renderButton} onClickPopUp={onClickPopUp} searchBarPlaceholder={'Search'} />
                    </div>
                )}
            </>
        );
    };
    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };
    const renderButton = () => {
        // setEdit(false);
        return (
            <>
                {values?.type?.code === 'TENDER'
                    ? '+ Tender'
                    : values?.type?.code === 'INSTAGRAM'
                    ? '+ Instagram'
                    : values?.type?.code === 'LINKEDIN'
                    ? '+ Linkedin'
                    : values?.type?.code === 'IDC_PORTAL_SITEPLAN'
                    ? '+ Site Plan'
                    : values?.type?.code === 'WHATS_NEW'
                    ? "+ What's New"
                    : '+ Add'}
            </>
        );
    };
    const dfd = {
        background: '#F5F5F6'
    };

    const handleModalDelete = async (rowData: any) => {
        const documentDetailsObject = getDocumentDetail(values, tableData);

        let payload;

        if (values?.type?.code === 'TENDER') {
            const tenderDetailsObject = getTenderDetail(values);
            payload = {
                RequestInfo: requestInfo(),
                tenderDetail: { ...tenderDetailsObject, id: rowData?.id },
                removeDocumentFlag: true
            };
        } else {
            payload = {
                RequestInfo: requestInfo(),
                documentDetail: { ...documentDetailsObject, id: rowData?.id },
                removeDocumentFlag: true
            };
        }

        try {
            if (values?.type?.code === 'TENDER') {
                await POST(endpoints.updateTender, payload);
                fetchTableData();
                setModalDetails({ show: false });
            } else {
                await POST(endpoints.updatePost, payload);
                fetchTableData();
                setModalDetails({ show: false });
            }
            setShowDeleteModal(false);
        } catch (e: any) {
            console.error('Error during delete:', e);
        }
    };

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({ show: false });
    };
    const PopupForm = () => {
        return (
            <>
                {values?.type?.code === 'TENDER'
                    ? CONTENT_MANAGEMENT_DETAILS.map((val: any) => {
                          return (
                              <Card className='mb-1' style={{ background: '#F5F5F6', borderRadius: '0px' }}>
                                  <Card.Body>
                                      <GenericFormCard styles={dfd} {...val} />
                                  </Card.Body>
                              </Card>
                          );
                          // return <GenericFormCard {...val} />;
                      })
                    : values?.type?.code === 'INSTAGRAM'
                    ? CONTENT_MANAGEMENT_INSTAGRAM_DETAILS.map((val: any) => {
                          return (
                              <Card className='mb-1' style={{ background: '#F5F5F6', borderRadius: '0px' }}>
                                  <Card.Body>
                                      <GenericFormCard styles={dfd} {...val} />
                                  </Card.Body>
                              </Card>
                          );
                          // return <GenericFormCard {...val} />;
                      })
                    : values?.type?.code === 'LINKEDIN'
                    ? CONTENT_MANAGEMENT_LINKEDIN_DETAILS.map((val: any) => {
                          return (
                              <Card className='mb-1' style={{ background: '#F5F5F6', borderRadius: '0px' }}>
                                  <Card.Body>
                                      <GenericFormCard styles={dfd} {...val} />
                                  </Card.Body>
                              </Card>
                          );
                          // return <GenericFormCard {...val} />;
                      })
                    : values?.type?.code === 'IDC_PORTAL_SITEPLAN'
                    ? contentManagementSitePlanDetails.map((val: any) => {
                          return (
                              <Card className='mb-1' style={{ background: '#F5F5F6', borderRadius: '0px' }}>
                                  <Card.Body>
                                      <GenericFormCard styles={dfd} {...val} dropdownOptions={dropSitePlan} />
                                  </Card.Body>
                              </Card>
                          );
                          // return <GenericFormCard {...val} />;
                      })
                    : values?.type?.code === 'WHATS_NEW'
                    ? CONTENT_MANAGEMENT_WHATSNEW_DETAILS.map((val: any) => {
                          return (
                              <Card className='mb-1' style={{ background: '#F5F5F6', borderRadius: '0px' }}>
                                  <Card.Body>
                                      <GenericFormCard styles={dfd} {...val} />
                                  </Card.Body>
                              </Card>
                          );
                          // return <GenericFormCard {...val} />;
                      })
                    : ''}
            </>
        );
    };

    useEffect(() => {
        if (values?.estate?.code) {
            setFieldValue('documentName', values?.estate?.code);
        }
    }, [values?.estate?.code]);

    const renderPopupTitle = () => {
        return (
            <>
                {values?.type?.code === 'TENDER'
                    ? `${!edit ? 'Add' : 'Edit'} Tender`
                    : values?.type?.code === 'INSTAGRAM'
                    ? `${!edit ? 'Add' : 'Edit'} Instagram Post`
                    : values?.type?.code === 'LINKEDIN'
                    ? 'Add Linkedin Post'
                    : values?.type?.code === 'IDC_PORTAL_SITEPLAN'
                    ? `${!edit ? 'Add' : 'Edit'} Site Plan`
                    : values?.type?.code === 'WHATS_NEW'
                    ? `${!edit ? 'Add' : 'Edit'} Whats New Content`
                    : '+ Add'}
            </>
        );
    };
    const popupSize = () => {
        return values?.type?.code === 'TENDER' ? 'lg' : 'md';
    };

    const ghg = () => {
        navigate('/goa-idc/dashboard');
    };

    return (
        <>
            <HeaderComponent />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader text={'Content Management'} />
                <div className='col-12 form-section'>
                    <FormikProvider value={formik}>
                        <Card className='card_border'>
                            <Card.Body style={{ marginBottom: '-22px' }}>
                                <div className='row my-0'>
                                    {CONTENT_MANAGEMENT_TYPE.map((val: any) => {
                                        return <GenericFormCard {...val} dropdownOptions={dropdownData} styles={{ marginBottom: '3px' }} />;
                                    })}
                                </div>
                            </Card.Body>
                            <Card.Body className='mt-0'>
                                <div className='row'>
                                    <div className='mt-1 col-12 me-2'>{renderTableData()}</div>
                                </div>
                            </Card.Body>
                        </Card>
                        <GenericPopupModal
                            size={popupSize()}
                            isVisible={showPopUp}
                            primaryAction={onSave}
                            secondaryAction={() => setShowPopUp(false)}
                            primaryBtnTitle={edit ? 'Update' : 'Save'}
                            secondaryBtnTitle={edit ? 'Close' : ''}
                            title={renderPopupTitle()}
                            children={PopupForm()}
                        />
                        {/* <StatusAlertPopup
                            title={modalDetails.title}
                            para={modalDetails.para}
                            img={modalDetails.img}
                            show={modalDetails.show}
                            handleClose={() => handleModalClose(modalDetails.type)}
                            type={modalDetails.type}
                            reUpload={modalDetails.reUpload}
                            handleSubmit={() => handleModalDelete(modalDetails?.rowData)}
                            isDelete={modalDetails.isDelete}
                            rowData={modalDetails.rowData}
                        /> */}
                        <DeletePopUpModal
                            secondaryAction={() => {
                                setShowDeleteModal(false);
                            }}
                            primaryAction={() => {
                                handleModalDelete(rowDelData);
                            }}
                            isVisible={showDeleteModal}
                            title={'Delete File?'}
                            img={DeleteWarning}
                            primaryBtnTitle={t('DELETE', 'Delete')}
                            secondaryBtnTitle={t('CANCEL', 'Cancel')}
                            children={
                                <>
                                    <div className='p-4' style={{ backgroundColor: '#F5F5F6' }}>
                                        <div className='font-16 fw-bold lh-22'>
                                            Are you sure you want to delete this file? <br />
                                            You can't undo this action!
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </FormikProvider>
                </div>
            </div>

            <footer className='footer fixed-bottom footerBtnPatch'>
                <div className='d-flex justify-content-end container me-3' style={{ padding: 15 }}>
                    <GenericButtons text={'Close'} variant='primary' handleClick={ghg} />
                </div>
            </footer>
        </>
    );
}

// useEffect(() => {
//     const typeDropdown = [
//         { name: 'Tender', code: 'Tender' },
//         { name: 'Linkedin', code: 'Linkedin' },
//         { name: 'Instagram', code: 'Instagram' },
//         { name: 'Site Plan', code: 'SitePlan' },
//         { name: "What's New", code: 'WhatNew' }
//     ];

//     setDropdownData({ type: typeDropdown });
//     setFieldValue('type', { name: 'Tender', code: 'Tender' });
// }, []);
