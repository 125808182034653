import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { HEADER_FIELDS_II } from '../ContractCreationScreens/ContractCreation.const';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { HEADER_FIELDS } from '../DPR creation screen/DPR.const';

import Editor from '../../../../components/ContentEditor';
import { RABILL_CORRESPONDENCE } from '../RA Bill/RaBill.const';
import { EXT_CORRESPONDENCE, EXTENSION } from '../ContractClosure/ContractClosure.const';

const ExtensionCorrespondence = (props: any) => {
    const {
        abstractData,
        dropdownData,
        t,
        newMeasurmentData,
        setNewMeasurmentData,
        raBillRowData,
        setRaBillRowData,
        applicationNumber,
        searchRaBill,
        setReminderPopUp,
        setContent,
        content,
        openSection,
        setOpenSection,
        resData,
        designation
    } = props;

    const { values, setValues, setTouched, touched, setFieldTouched, errors, setFieldValue } = useFormikContext<any>();

    const onSectionChange = async (currentSection: string) => {
        if (currentSection === 'notes' && values.isUpdatable && Object.keys(touched).length) {
            setReminderPopUp(true);
        }

        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else {
            setOpenSection(currentSection);
        }
    };
    console.log(values);

    const renderHeaderDetails = () => {
        const dprDetails = HEADER_FIELDS.map((val: any) => {
            return {
                ...val,
                formData: [
                    ...val.formData.map((value: any) => {
                        return { ...value, isDisabled: true, isRequired: false };
                    })
                ]
            };
        });

        const HeaderFieldsII = HEADER_FIELDS_II.map((val: any) => {
            return {
                ...val,
                formData: [
                    ...val.formData.map((value: any) => {
                        return { ...value, isDisabled: true, isRequired: false };
                    }),
                    {
                        titleCode: 'SIGNED_COPY_OF_CONTRACT',
                        title: 'Signed copy of Contract',
                        field: 'signedCopyofContract',
                        type: CONSTANT_DATA_FIELD.FILE_FIELD,
                        entityFileData: {
                            documentNamePrefix: 'Signed copy of Contract',
                            documentType: ''
                        },
                        isDisabled: true
                    }
                ]
            };
        });

        return (
            <>
                <Card.Body>
                    {dprDetails.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                    {HeaderFieldsII.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const renderExtensionDetails = () => {
        const compensationAmt = [
            { formData: [{ title: 'Compensation Amount', titleCode: 'COMPENSATION_AMOUNT', field: 'compensationAmount', type: CONSTANT_DATA_FIELD.NUMERIC_FIELD, isRequired: true, showInr: true }] }
        ];
        const extension = designation === 'FM_SITE' ? EXTENSION : EXTENSION.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: true })) }));
        return (
            <>
                <Card.Body>
                    {extension.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                    {designation !== 'FM_SITE' && compensationAmt.map((val: any) => <GenericFormCard {...val} />)}
                </Card.Body>
            </>
        );
    };

    // const renderTechnicalSanctionDetails = () => {
    //     return (
    //         <>
    //             <Form.Group>
    //                 <div className='col-lg-12 my-1 px-4 py-4'>
    //                     <Editor setContent={setContent} content={content} readOnly={!values.isUpdatable} />
    //                 </div>
    //             </Form.Group>
    //         </>
    //     );
    // };
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {EXT_CORRESPONDENCE.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === '(A) Header' && openSection === 'headerDetails' && renderHeaderDetails()}
                                {val.title === '(B) Extension Details' && openSection === 'extensionDetails' && renderExtensionDetails()}
                                {/* {val.title === '(E) Notes' && openSection === 'notes' && renderTechnicalSanctionDetails()} */}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default ExtensionCorrespondence;
