import { title } from 'process';
import landIcon from '../../../../assets/logos/land premium.svg';
import leaseIcon from '../../../../assets/logos/lease rent.svg';
import houseIcon from '../../../../assets/logos/house tax.svg';
import waterIcon from '../../../../assets/logos/water charge.svg';
import { convertDateFormatddMMyyyytoyyyyMMdd } from '../../EstateManagement/LeaseDeed/LeaseDeed.controller';
import { convertToINR } from '../../EstateManagement/EstateManagement.controller';
import { changeDateFormatToIST } from '../../../../utils/utils';
import { getDateRangeForPayment } from '../../EstateManagement/Payment/EstatePayment.const';

export function sortByDueDate(array: any) {
    return array.sort((a: any, b: any) => {
        const dateA: any = new Date(a.dueDate);
        const dateB: any = new Date(b.dueDate);
        return dateB - dateA;
    });
}

export const getPaymentStatus = (data: any, type?: string, newPayment?: boolean) => {
    if (type === 'LAND_PREMIUM' && newPayment) {
        return { status: 'DUE', statusName: 'Due' };
    }

    if ((type === 'LAND_PREMIUM' || type === 'LEASE_RENT') && !data.length) {
        return { status: '--', statusName: '--' };
    } else if (!data.length) {
        return { status: 'NA', statusName: 'NA' };
    }

    const reverseData = data.reverse();
    const currRecord = reverseData?.find((item: any) => !item.paymentDate);

    if (currRecord && currRecord.dueDate && new Date(convertDateFormatddMMyyyytoyyyyMMdd(currRecord.dueDate)) < new Date()) {
        const diff = getDiffInDays(new Date(convertDateFormatddMMyyyytoyyyyMMdd(currRecord.dueDate)), new Date());
        console.log(new Date(convertDateFormatddMMyyyytoyyyyMMdd(currRecord.dueDate)), new Date());
        return { status: 'OVERDUE', statusName: `Overdue by ${diff} days` };
    } else if (type !== 'LAND_PREMIUM' && !currRecord) {
        return { status: 'PAID', statusName: 'Paid' };
    } else if (type !== 'LAND_PREMIUM' && currRecord.dueDate && !currRecord.paymentDate) {
        return { status: 'DUE', statusName: 'Due' };
    } else if (currRecord && currRecord.dueDate && getDiffInDays(new Date(), new Date(convertDateFormatddMMyyyytoyyyyMMdd(currRecord.dueDate))) <= 60) {
        return { status: 'DUE', statusName: 'Due' };
    } else {
        return { status: 'PAID', statusName: 'Paid' };
    }
};

export const getDiffInDays = (date1: any, date2: any) => {
    // Calculate the difference in milliseconds
    const diffInMs = date2 - date1;

    // Convert milliseconds to days
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
};

export const getInstallmentData = (data: any, type: string, totalPayableLandPremium?: string, status?: string) => {
    const totalPaid = data.reduce((acc: any, curr: any) => {
        if (curr.paymentDate) {
            return acc + (curr.paidAmount || 0);
        }
        return acc;
    }, 0);

    const lastPaid = data.find((item: any) => item.paymentDate)?.paidAmount;
    const lastPaidDate = data.find((item: any) => item.paymentDate)?.paymentDate;

    let nextDue = data.find((item: any) => !item.paymentDate)?.totalDueAmount;
    let allNextDue;
    if (type !== 'LAND_PREMIUM') {
        allNextDue = data.reduce((acc: any, curr: any) => {
            if (!curr.paymentDate) {
                return acc + curr.totalDueAmount;
            }
            return acc;
        }, 0);
    } else {
        allNextDue = data.reduce((acc: any, curr: any) => {
            if (!curr.paymentDate && getDateRangeForPayment(curr.dueDate) !== 'FUTURE_DATE') {
                return acc + curr.totalDueAmount;
            }
            return acc;
        }, 0);
    }

    let nextDueDate = data.find((item: any) => !item.paymentDate)?.dueDate;

    const installments = [
        {
            title: 'Last Paid',
            titleCode: 'LAST_PAID',
            amount: lastPaid && typeof lastPaid === 'number' ? convertToINR(lastPaid?.toFixed(2)) : '--',
            date: lastPaid ? changeDateFormatToIST(lastPaidDate) : ''
        },
        {
            title: 'Next Due',
            titleCode: 'NEXT_DUE',
            // amount: nextDue && typeof nextDue === 'number' ? convertToINR(nextDue?.toFixed(2)) : '--',
            amount: allNextDue && typeof allNextDue === 'number' ? convertToINR(allNextDue?.toFixed(2)) : '--',
            date: nextDue ? changeDateFormatToIST(nextDueDate) : '',
            hide: true
        }
    ];

    return type !== 'LAND_PREMIUM'
        ? installments
        : [
              {
                  title: 'Total Payable',
                  titleCode: 'TOTAL_PAYABLE',
                  amount: typeof totalPayableLandPremium === 'number' ? convertToINR(totalPayableLandPremium) : '--'
              },
              {
                  title: 'Total Paid',
                  titleCode: 'TOTAL_PAID',
                  amount: typeof totalPaid === 'number' ? (!totalPaid ? '--' : convertToINR(totalPaid?.toFixed(2))) : '--'
              },
              ...installments
          ];
};

export const firstTimePaymentDetails = (data: any, totalAmt?: string) => {
    const reverseData = data.reverse();
    let nextDue = reverseData.find((item: any) => !item.paymentDate)?.totalDueAmount;
    let nextDueDate = reverseData.find((item: any) => !item.paymentDate)?.dueDate;

    return [
        {
            title: 'Total Payable',
            titleCode: 'TOTAL_PAYABLE',
            amount: typeof totalAmt === 'number' ? convertToINR(totalAmt) : '--'
        },
        {
            title: 'Total Paid',
            titleCode: 'TOTAL_PAID',
            amount: '--'
        },
        {
            title: 'Last Paid',
            titleCode: 'LAST_PAID',
            amount: '--',
            date: ''
        },
        {
            title: 'Next Due',
            titleCode: 'NEXT_DUE',
            amount: convertToINR(totalAmt) || '--',
            date: nextDue ? changeDateFormatToIST(nextDueDate) : '',
            hide: true
        }
    ];
};

export const PLOT_LIST = [
    {
        title: 'plot-1'
    },
    {
        title: 'plot-2'
    },
    {
        title: 'plot-3'
    },
    {
        title: 'plot-4'
    }
];

export const DASHBOARD_DOCUMNET_DETAILS = [
    {
        title: 'Default Timeline Extension Order',
        date: '25/03/2019',
        smallCard: ['Download', 'Apply for SPV', 'Payment', 'Apply for Lease Deed']
    },
    {
        title: 'Default Timeline Extension Order',
        date: '25/03/2019',
        smallCard: ['Download-1', 'Apply for SPV-2', 'Payment-3', 'Apply for Lease Deed-4']
    },
    {
        title: 'Default Timeline Extension Order',
        date: '25/03/2019',
        smallCard: ['Download-2', 'Apply for SPV-2', 'Payment-3', 'Apply for Lease Deed-4']
    },
    {
        title: 'Default Timeline Extension Order',
        date: '25/03/2019'
    },
    {
        title: 'Default Timeline Extension Order',
        date: '25/03/2019'
    }
];

export const PLOT_INFO = [
    {
        title: 'Plot No.',
        value: 'plotDetails.plotNo'
    },
    {
        title: 'Plot Area (sqm)',
        value: 'plotDetails.plotAreaSqm'
    },
    {
        title: 'Phase',
        value: 'plotDetails.phase'
    },
    {
        title: 'Estate',
        value: 'plotDetails.estateDetails.estateName'
    },
    {
        title: 'Lease Term End Date',
        value: 'leaseTermEndDate'
    },
    {
        title: 'Built-up Area (sqm)',
        value: 'builtUpArea'
    }
];
export const DASHBOARD_PAYMENT_DETAIL = [
    {
        icon: landIcon,
        code: 'LAND_PREMIUM',
        title: 'Land Premium',
        totalpay: 'Payable (INR)',
        totalInr: '--',
        status: 'PAID',
        installments: null
    },
    {
        icon: leaseIcon,
        code: 'LEASE_RENT',
        title: 'Lease Rent',
        totalpay: 'Payable (INR)',
        totalInr: '--',
        status: 'OVERDUE'
    },
    {
        icon: houseIcon,
        code: 'HOUSE_TAX',
        title: 'House Tax',
        totalpay: 'Payable (INR)',
        totalInr: '--',
        status: 'DUE'
    },
    {
        icon: waterIcon,
        code: 'WATER_CHARGES',
        title: 'Water Charges',
        totalpay: 'Payable (INR)',
        totalInr: '--',
        status: 'NA'
    }
];
export const DASHBOARD_SUB_LEASE_DETAILS = [
    {
        subsec: [
            {
                title: 'Sub-Lease Charges',
                cost: '10,000',
                status: 'DUE'
            },
            {
                title: 'Water Charges',
                cost: '2,500',
                status: 'DUE'
            }
        ]
    },
    {
        subsec: [
            {
                title: 'Sub-Lease Charges',
                cost: '10,000',
                status: 'PAID'
            },
            {
                title: 'Water Charges',
                cost: '2,500',
                status: 'PAID'
            }
        ]
    }
];
