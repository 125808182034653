import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import ArrowLeft from '../../../assets/logos/ArrowLeft.png';

interface GenericButtonProps {
    onActionClick?: () => void;
}

const GenericBackButton = (props: GenericButtonProps) => {
    const navigate = useNavigate();

    return (
        <>
            <Button className='mx-2 border-0 text-black back_sqaure new_btn rounded-4' style={{ backgroundColor: '#3A7425', borderRadius: '12px' }} onClick={() => props?.onActionClick ? props?.onActionClick() : navigate(-1)}>
                <img src={ArrowLeft} alt='arrow' />
            </Button>
        </>
    );
};

export default GenericBackButton;
