import { useState } from 'react';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { INITIATE_EXTENSION_ENTITY_TABLE, INITIATE_EXTENSION_SEARCH } from '../Extension.const';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import { FormikProvider, useFormik } from 'formik';
import { Card } from 'react-bootstrap';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { cross } from '../../../../../utils/Logo';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { useNavigate } from 'react-router-dom';
import { POST, endpoints, estateEndpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { TENANT_ID } from '../../../../../utils/constants';
import { useTranslation } from 'react-i18next';

export default function ExtensionInitiateByEmployee() {
    const { t} = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    let navigate = useNavigate();
    const [tableRowData, setTableRowData] = useState<any>([]);
    const [selectedPlotData, setSelectedPlotData] = useState<any>(null);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {}
    });

    const { values, setValues } = formik;

    const onPlotSelect = (selectedPlot: any) => {
        setSelectedPlotDataId(selectedPlot.id);
        setSelectedPlotData(selectedPlot);
    };

    const plotDetailsTableColumns = INITIATE_EXTENSION_ENTITY_TABLE.map((val: any, index: number) => {
        if (index === 0) {
            return {
                ...val,
                onRadioClick: onPlotSelect,
                selectedValue: selectedPlotDataId
            };
        }
        return val;
    });

    const handleReset = () => {
        setValues({ ...values, entityName: '', mobileNumber: '', emailId: '' });
        setSelectedPlotDataId(null);
        setTableRowData([]);
    };

    const handleSearch = async () => {
        try {
            if (!values.entityName && !values.mobileNumber && !values.emailId) {
                setModalDetails({ show: true, title: 'Error!', para: t('TO_SEARCH','Please enter at least one field to search'), img: cross, type: 'error', reUpload: 'Ok' });
                return;
            }
            setIsLoading(true);
            const queryParams = new URLSearchParams();
            if (values.entityName) queryParams.append('entityName', values.entityName);
            if (values.mobileNumber) queryParams.append('phoneNumber', values.mobileNumber);
            if (values.emailId) queryParams.append('email', values.emailId);
            const { data } = await POST(endpoints.searchExistingUser + `?${queryParams.toString()}`, { RequestInfo: requestInfo() });
            // const allotmentDetails = await POST(endpoints.allotmentDetailsSearch + `?entityIds=${data?.enterpriseEntity[0].uuid}`, { RequestInfo: requestInfo() });
            // const filteredObject = allotmentDetails?.data.allotmentDetails.filter((val: any) => val.entityRefDetails.type === 'ALLOTTEE');
            // let plotIds = filteredObject?.map((item: any) => item.entityRefDetails.plotId);
            // plotIds = plotIds.join(',');
            // let plotData = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });
            if (data?.enterpriseEntity?.length === 0) {
                setModalDetails({ show: true, title: 'Error!', para: t('NO_RECORD_FOUND','No Record Found'), img: cross, type: 'error', reUpload: 'Ok' });
            } else {
                setTableRowData(data?.enterpriseEntity);
            }
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onSubmit = async () => {
        let payload = {
            RequestInfo: requestInfo(),
            applicationType: 'TIMELINE_EXTENSION',
            applicationSubType: null,
            appliedPlotId: null,
            parentOrderNumber: null,
            modificationDetails: {
                uuid: null,
                requestDetails: {
                    documents: [
                        {
                            documentType: null,
                            fileStoreId: null
                        }
                    ],
                    orderNo: null,
                    expiryDate: null,
                    reason: null
                }
            },
            enterpriseEntity: { ...tableRowData[0] }
        };

        if (tableRowData.length === 0) {
            setModalDetails({ show: true, title: 'Error!', para: t('SEARCH_ENTERPRISE','Please search an Enterprise'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (!selectedPlotData) {
            setModalDetails({ show: true, title: 'Error!', para: t('SELECT_ENTERPRISE','Please select an Enterprise'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            const { data } = await POST(endpoints.createAllotment, payload);
            navigate(`/goa-idc/estate/extensionworkflow?applicationNumber=${data.estateApplications[0].applicationNo}&tenantId=${TENANT_ID}`, { state: { estateData: tableRowData[0] } });
            console.log('dataaaaaa', data);
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('APPLICATION_SUBMISSION_FAILED','Application submission failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
        // navigate('/goa-idc/estate/initiate-extension/workflow');
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/enterprisedashboard');
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                        <GenericHeader text={'Application for Timeline Extension'} additionalClass='sub-text-style' />
                        <FormikProvider value={formik}>
                            <div className='col-12 form-section pt-4'>
                                <Card className='card_border'>
                                    <Card.Body>
                                        {INITIATE_EXTENSION_SEARCH.map((val: any) => {
                                            return <GenericFormCard {...val} />;
                                        })}
                                        <div className='d-flex justify-content-end mb-5'>
                                            <GenericButtons text={t('RESET','Reset')} variant='secondary' handleClick={handleReset} />
                                            <GenericButtons text={t('SEARCH','Search')} variant='primary' disabled={false} handleClick={handleSearch} />
                                        </div>
                                        <GenericActionTable columns={plotDetailsTableColumns} hideExportBtn={true} searchBarPlaceholder={'SEARCH_ENTITY'} rowData={tableRowData} />
                                    </Card.Body>
                                </Card>
                            </div>
                        </FormikProvider>
                    </div>
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                                <GenericButtons text={t('INITIATE_EXTENSION','Initiate Extension')} variant='primary' handleClick={onSubmit} />
                            </div>
                        </div>
                    </footer>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                </>
            )}
        </>
    );
}
