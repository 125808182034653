import { useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { TRANSFER_TABLE } from './Transferee.const';

export const TransferDetailsTable = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>([]);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='ps-5 pt-3'>
                        <p className='mb-0 font-16 fw-normal'>Welcome</p>
                        <p className='font-28 fw-bold fs-3'>SampleName</p>

                        <GenericActionTable columns={TRANSFER_TABLE} hideExportBtn={true} rowData={rowData} searchBarPlaceholder={'Search by Application Number, Application Type'} />
                    </div>
                </>
            )}
        </>
    );
};
