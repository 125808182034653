import { Card, Form } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CHECKLIST_TABLE, paymentColumns } from '../../Allotment/Allotment.const';
import { EXTENSION_DETAILS_FORM, extensionDeclarationList, LEASE_EXTENSION_CORRESPONDENCE_TAB } from '../LeaseExtension.const';
import { useState } from 'react';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import Declaration from '../../Allotment/Declaration';
import { WorkflowPaymentDetails } from '../../WorkflowPaymentDetails';
import PlotDetails from '../../../Migration/pages/PlotDetails';
import Editor from '../../../../../components/ContentEditor';
import LesseeDetails from '../../SubLease/LesseeDetails';
import { t } from 'i18next';

export default function LeaseExtensionWorkflowCorrespondence({
    checkListRowData,
    isChecked,
    fileStoreId,
    transactionDetails,
    paymentRowData,
    dropdownDataPlot,
    setDropdownDataPlot,
    content,
    setContent,
    readOnly
}: any) {
    const [openSection, setOpenSection] = useState<string>('lesseeDetails');

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const renderLesseeDetails = () => {
        return (
            <>
                <Card.Body>
                    <LesseeDetails />
                </Card.Body>
            </>
        );
    };

    const renderplotDetails = () => {
        return (
            <>
                <Card.Body>
                    <PlotDetails dropdownData={dropdownDataPlot} isFieldDisable={true} setDropdownData={setDropdownDataPlot} hideCard={true} />{' '}
                </Card.Body>
            </>
        );
    };

    const renderExtensionDetails = () => {
        return (
            <>
                <Card.Body>
                    {EXTENSION_DETAILS_FORM?.map(val => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const renderDeclarationDetails = () => {
        return (
            <>
                <Declaration isChecked={isChecked} hideCard={true} declarationList={extensionDeclarationList} fileStoreId={fileStoreId} applicantName={'Lessee'} isDisabled={true}></Declaration>
            </>
        );
    };

    const renderPaymentDetails = () => {
        return (
            <>
                <div className='px-4 py-4'>
                    <WorkflowPaymentDetails transactionDetails={transactionDetails} paymentColumns={paymentColumns} paymentRowData={paymentRowData} />
                </div>
            </>
        );
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Card.Body>
                        <Form.Group>
                            <div className='col-lg-12 my-1 px-4 py-4'>
                                <Editor setContent={setContent} content={content} readOnly={readOnly} />
                            </div>
                        </Form.Group>
                    </Card.Body>
                </div>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                {t('CHECK_LIST', 'Condition/Check list')}
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {LEASE_EXTENSION_CORRESPONDENCE_TAB.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.titleCode ? t(val.titleCode) : val.title}
                                </label>
                                {val.title === 'A - Lessee Details' && openSection === 'lesseeDetails' && renderLesseeDetails()}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderplotDetails()}
                                {val.title === 'C - Extension Details' && openSection === 'extensionDetails' && renderExtensionDetails()}
                                {val.title === 'D - Self Declaration' && openSection === 'selfDeclaration' && renderDeclarationDetails()}
                                {val.title === 'E - Payment Details' && openSection === 'paymentDetails' && renderPaymentDetails()}
                                {val.title === 'F - Extension of Lease Term Notes' && openSection === 'leaseTermExtensionNotes' && renderNoteDetails()}
                                {/* {val.title === 'F - Rejection Order' && openSection === 'provisionalAllotmentNotes' && renderNoteDetails()} */}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
}
