import React, { useEffect, useState } from 'react';
// import HeaderComponent from '../../../components/header/Header.component';
// import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
// import { Card } from 'react-bootstrap';
// import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
// import { t } from 'i18next';
// import { EXTENSION_DETAILS_FORM, LEASE_EXTENSION_FORM_STEPS } from './LeaseExtension.const';
// import { FormikProvider, useFormik } from 'formik';
// import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
// import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
// import { POST, POST2, endpoints, estateEndpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
// import './LeaseExtension.scss';
// import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
// import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
// import { getFirstErrorKey } from '../../../../utils/utils';
// import PaymentDetails from '../Allotment/PaymentDetails';
// import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
// import Loading from '../../../../components/portalComponents/Loading.component';
// import { fetchFeeData, fetchGatewayData, fetchLeaseDurationDoc, getOptions, getQueryParams, loadRazorpay, paymentCheck, paymentPayload } from '../EstateManagement.controller';
// import { getEntityDocumentDetails } from '../../Migration/Migration.controller';
// import { toast } from 'react-toastify';
// import { check, cross } from '../../../../utils/Logo';
// import { TRANSFER_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
// import { PLOT_DETAILS_TABLE } from '../SubLease/SubLeaseConst';
// import { RazorpayOptions } from '../../UserManagement/types';
import { LESSEE_DETAILS, PLOT_DETAILS, BANK_NOC_FORM_STEPS, MORTGAGE_DETAILS, BANK_LETTER_DETAILS } from './BankNOC.const';
import HeaderComponent from '../../components/header/Header.component';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { fetchFeeData, fetchGatewayData, fetchLeaseDurationDoc, getOptions, getQueryParams, paymentCheck, paymentPayload } from '../modules/EstateManagement/EstateManagement.controller';
import { POST, POST2, endpoints, estateEndpoints, requestInfo } from '../../services/modulesServices/userManagement.api';
import { check, cross, user } from '../../utils/Logo';
import { TRANSFER_VALIDATION } from '../../utils/formikValidation/FormikValidation';
import { toast } from 'react-toastify';
import { getFirstErrorKey } from '../../utils/utils';
import { RazorpayOptions } from '../modules/UserManagement/types';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { Card, CardBody } from 'react-bootstrap';
import Loading from '../../components/portalComponents/Loading.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import GenericBackButton from '../../components/UI components/Buttons/GenericBackButton';
import SideCard from '../../components/moduleCommonFormik/SideCardGenericComponent';
import { Formik, FormikProvider, useFormik } from 'formik';
import PaymentDetails from '../modules/EstateManagement/Allotment/PaymentDetails';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { CorrespondenceWrapper, NotingWrapper } from '../../components/Workflow/NotingWrapper';
import { Clearance_Form_Initial_Data } from '../scrutinyFlowScreen/ScrutinyFormPage.module';
import EmailNotification from '../constructionLicense/RenderEmailUpdation.component';
import { KEY_ID } from '../../utils/constants';

const M_BankNOC = () => {
    const initialValues = Clearance_Form_Initial_Data;
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [transferorDetails, setTransferorDetails] = useState<any>({});
    const [transferDetails, setTransferDetails] = useState<any>(null);
    const [selectedTransfereeDetails, setSelectedTransfereeDetails] = useState<any>({});
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [searchTransfereeResponse, setSearchTransfereeResponse] = useState<any>([]);
    const [rowData, setRowData] = useState(null);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);

    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    let navigate = useNavigate();
    const stepsDetails = BANK_NOC_FORM_STEPS;
    const [paymentData, setPaymentData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [openSection, setOpenSection] = useState<string>('LESSEE_DETAITLS');

    const [searchParams] = useSearchParams();
    const location: any = useLocation();
    const [comment, setcomment] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNumber = urlParams.get('applicationNumber') || '';

    // let designation = user ? String(JSON.parse(user)?.designation?.code) : '';

    const formik: any = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            isSpecialProject: false
        },
        onSubmit: value => {}
        // validationSchema: TRANSFER_VALIDATION[`page${activeIndex}`]
    });
    const { values, setValues, errors, setFieldTouched } = formik;

    const handleStepClick = (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const onPay = async () => {
        setIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/transfer?plotId=${values.plotUuid}&activeIndex=${activeIndex}&applicationNo=${transferDetails?.applicationDetails?.applicationNo}&entityId=${transferorDetails.uuid}`;
        const periodTo = new Date(transferDetails?.auditDetails?.createdTime).setFullYear(new Date(transferDetails?.auditDetails?.createdTime).getFullYear() + 1);
        const payload = paymentPayload(
            transferDetails?.applicationDetails,
            values.plotUuid,
            totalPaidAmount,
            url,
            'Transfer Application Fees',
            selectedGateway,
            transferDetails.auditDetails.createdTime,
            periodTo,
            paymentData
        );

        try {
            if (!totalPaidAmount) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (!selectedGateway) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (transferDetails?.applicationDetails?.applicationNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, getUser, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
                setIsLoading(false);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            //Fetch Transfer Lessee Details
            try {
                const { data } = getUser.uuid && (await POST(endpoints.searchExistingUser + `?userUuid=${getUser.uuid}`, { RequestInfo: requestInfo() }));
                const transferor = data?.enterpriseEntity[0];
                if (transferor) {
                    setTransferorDetails(transferor);
                    const { entityName, phoneNumber, email } = transferor;
                    const postalAddress = transferor?.addresses?.find((address: any) => address.addressType === 'PERMANENT') || {};
                    const lesseeDataObj = { name: entityName, email, phoneNumber, ...postalAddress };
                    setValues({ ...values, ...lesseeDataObj });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let { data: plotData } = await POST(endpoints.entityRefDetails + `?entityIds=${transferorDetails.uuid}&types=LESSEE`, { RequestInfo: requestInfo() });
                if (plotData?.entityRefDetails?.length) {
                    let plotIds = plotData?.entityRefDetails?.map((item: any) => item.plotId);
                    plotIds = plotIds.join(',');

                    if (!plotIds) return;
                    let { data } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotIds}`, { RequestInfo: requestInfo() });

                    let plotDataArray = data?.plots?.map((plot: any) => ({
                        estateName: plot?.estateDetails?.estateName,
                        plotType: plot?.plotType,
                        phase: plot?.phase,
                        plotNo: plot?.plotNo,
                        plotAreaSqm: plot?.plotAreaSqm,
                        uuid: plot?.uuid,
                        id: plot?.uuid
                    }));
                    setRowData(plotDataArray);
                    // setPlotData(plotDataArray);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (transferorDetails.uuid) fetchData();
    }, [transferorDetails]);

    useEffect(() => {
        const fetchData = async () => {
            const detailsData = await fetchLeaseDurationDoc(values.plotUuid, transferorDetails.uuid);

            setValues((prevState: any) => ({
                ...prevState,
                ...detailsData
            }));
        };

        values.plotUuid && transferorDetails && fetchData();
    }, [values.plotUuid, transferorDetails]);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleModalClose = (type: string) => {
        setModalDetails({ show: false });
        if (type === 'success' && activeIndex === 4) {
            navigate('/goa-idc/enterprisedashboard');
        }
    };

    const onPlotSelect = (selectedPlot: any) => {
        setSelectedPlotDataId(selectedPlot.uuid);
        setValues({ ...values, plotUuid: selectedPlot.uuid });
    };

    console.log(formik);

    // const plotDetailsTableColumns = PLOT_DETAILS_TABLE.map((val: any, index: number) => {
    //     if (index === 0) {
    //         return {
    //             ...val,
    //             onRadioClick: onPlotSelect,
    //             selectedValue: selectedPlotDataId
    //         };
    //     }
    //     return val;
    // });

    const renderBasicDetails = () => {
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {LESSEE_DETAILS.map((val, key) => (
                            <GenericFormCard {...{ ...val }} key={key} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {PLOT_DETAILS.map((val, key) => (
                            <GenericFormCard {...{ ...val }} key={key} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderMortgageDetails = () => {
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {MORTGAGE_DETAILS.map((val, key) => (
                            <GenericFormCard {...{ ...val }} key={key} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderBankLetterDetails = () => {
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {BANK_LETTER_DETAILS.map((val, key) => (
                            <GenericFormCard {...{ ...val }} key={key} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderNotificationDetails = () => {
        return (
            <></>
            // const notificationStep = InitiateConstSteps.find(step => step.code === 'NOTIFICATION_DETAILS');
            // if (notificationStep && notificationStep.isActive) {
            //     return <EmailNotification formValues={props?.formValues} setFormValues={props?.setFormValues} isEditable={props?.bpaData?.editable} />;
            // }
            // return null;
        );
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    {/* <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={() => {}} content={props?.previewData?.provisionalCertificateContent} />
                        </div>
                    </Form.Group> */}
                </div>
            </>
        );
    };

    const onSectionChange = (currentSection: string) => {
        setOpenSection(currentSection);
    };

    const renderPayment = () => {
        return !paymentIsLoading ? (
            <PaymentDetails
                paymentData={paymentData}
                selectedGateway={selectedGateway}
                setSelectedGateway={setSelectedGateway}
                isPaid={isPaid}
                gatewayData={gatewayData}
                transactionDetails={transactionDetails}
                title={'Water Connection Fee'}
            ></PaymentDetails>
        ) : (
            <Loading />
        );
    };

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            {/* <StatusAlertPopup para={bodydata} img={cross} handleClose={handleClose} handleSubmit={handleStatusSubmit} show={showStatus} title={''} /> */}
            <div className='applicationPage m-auto px-5 py-4'>
                <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                    <GenericHeader
                        // subText={`Permit No. : ${values?.refApplication || applicationNumber || 'NA'}`}
                        text={t('', 'Bank NOC')}
                        additionalClass='sub-text-style'
                        // onActionClick={() => navigateToDashboard()}
                        bottomSubText={`File : ${values?.name}, Type : ${t('', 'Bank NOC')}, No :${'NA'}`}
                    />
                    <Formik initialValues={initialValues} onSubmit={value => {}}>
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('', 'Bank NOC')}
                                        sideHeader={true}
                                        // onActionClick={() => navigateToDashboard()}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : ${t('', 'Bank NOC')}, No : ${'NA'}`}
                                    />

                                    <NotingWrapper
                                        text={comment}
                                        // designation={designation}
                                        // applicationNumber={applicationNumber}
                                        // history={historyDetails}
                                        disable={!values.isUpdatable}
                                        // handleOnFileUpload={handleOnFileUpload}
                                    />
                                    {/* <p className='noting-heading my-1'>{t('OC_EMP_NOTING_HEADING', 'Noting')}</p>

                                    <NotingComponent history={historyDetails} text={comment} handleOnFileUpload={handleOnFileUpload} /> */}
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper
                                    applicationNumber={applicationNumber}
                                    //  designation={designation}
                                    nextValidAction={{}}
                                />

                                <Card className='checklist-outer mb-4 mx-3'>
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                <input
                                                    type='checkbox'
                                                    id='LESSEE_DETAILS_Checkbox'
                                                    name='LESSEE_DETAILS'
                                                    checked={openSection === 'LESSEE_DETAILS'}
                                                    onChange={() => onSectionChange('LESSEE_DETAILS')}
                                                />
                                                <label
                                                    htmlFor='LESSEE_DETAILS'
                                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                >
                                                    {t('', 'A - Lessee Details')}
                                                </label>
                                                {openSection === 'LESSEE_DETAILS' && renderBasicDetails()}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                <input
                                                    type='checkbox'
                                                    id='PLOT_DETAILS_Checkbox'
                                                    name='PLOT_DETAILS'
                                                    checked={openSection === 'PLOT_DETAILS'}
                                                    onChange={() => onSectionChange('PLOT_DETAILS')}
                                                />
                                                <label
                                                    htmlFor='PLOT_DETAILS'
                                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                >
                                                    {t('', 'B - Plot Details')}
                                                </label>
                                                {openSection === 'PLOT_DETAILS' && renderPlotDetails()}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                <input
                                                    type='checkbox'
                                                    id='MORTGAGE_DETAILS_Checkbox'
                                                    name='MORTGAGE_DETAILS'
                                                    checked={openSection === 'MORTGAGE_DETAILS'}
                                                    onChange={() => onSectionChange('MORTGAGE_DETAILS')}
                                                />
                                                <label
                                                    htmlFor='MORTGAGE_DETAILS'
                                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                >
                                                    {t('', 'C - Mortgage Details')}
                                                </label>
                                                {openSection === 'MORTGAGE_DETAILS' && renderMortgageDetails()}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                <input
                                                    type='checkbox'
                                                    id='BANK_LETTER_DETAILS_Checkbox'
                                                    name='BANK_LETTER_DETAILS'
                                                    checked={openSection === 'BANK_LETTER_DETAILS'}
                                                    onChange={() => onSectionChange('BANK_LETTER_DETAILS')}
                                                />
                                                <label
                                                    htmlFor='BANK_LETTER_DETAILS'
                                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                >
                                                    {t('', 'D - Bank Letter Details')}
                                                </label>
                                                {openSection === 'BANK_LETTER_DETAILS' && renderBankLetterDetails()}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                <input
                                                    type='checkbox'
                                                    id='NOTIFICATION_DETAILS_Checkbox'
                                                    name='NOTIFICATION_DETAILS'
                                                    checked={openSection === 'NOTIFICATION_DETAILS'}
                                                    onChange={() => onSectionChange('NOTIFICATION_DETAILS')}
                                                />
                                                <label
                                                    htmlFor='NOTIFICATION_DETAILS'
                                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                >
                                                    {t('', 'E - Notification Details')}
                                                </label>
                                                {openSection === 'NOTIFICATION_DETAILS' && renderNotificationDetails()}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                                                <input
                                                    type='checkbox'
                                                    id='NOC_ORDER_DETAILS_Checkbox'
                                                    name='NOC_ORDER_DETAILS'
                                                    checked={openSection === 'NOC_ORDER_DETAILS'}
                                                    onChange={() => onSectionChange('NOC_ORDER_DETAILS')}
                                                />
                                                <label
                                                    htmlFor='NOC_ORDER_DETAILS'
                                                    className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                                >
                                                    {t('', 'F - NOC Order Details')}
                                                </label>
                                                {openSection === 'NOC_ORDER_DETAILS' && renderNoteDetails()}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </FormikProvider>
                    </Formik>
                    <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                        <span className='col-3 rounded-circle pagination-right-arrow' onClick={() => {}}>
                            {/* <img src={activeMainIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeMainIndex === 2 && `rotate-class`}`} /> */}
                        </span>
                        {/* Page {activeMainIndex} of {totalPages} */}
                        <span className='col-3 rounded-circle pagination-left-arrow' onClick={() => {}}>
                            {/* <img src={activeMainIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeMainIndex === totalPages && `rotate-class`}`} /> */}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};
export default M_BankNOC;
