import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { useTranslation } from 'react-i18next';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import { GSR_BOOK_POPUP_FIELDS, GSR_BOOK_TABLE_COLUMNS } from './GSR.const';
import downloadIcon from '../../../../assets/logos/down xls.svg';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import { GSR_BOOK_DETAILS_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import PopUps from '../../../../components/PopUps';
import { getFirstErrorKey } from '../../../../utils/utils';
import { check, cross, DeleteWarning } from '../../../../utils/Logo';
import { v4 as uuidv4 } from 'uuid';
import { devUrl, endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { useLocation } from 'react-router-dom';
import DeletePopUpModal from '../../../../components/moduleCommonFormik/DeletePopUp.component';
import icon from '../../../../assets/logos/import icon.svg';
import axios from 'axios';
import Loading from '../../../../components/portalComponents/Loading.component';

const GsrMasterDetails = () => {
    const { t } = useTranslation();
    const [gsrPopUp, setGsrPopUp] = useState(false);
    const [gsrBookData, setGsrBookData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isEditing, setIsEditing] = useState<any>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [openIndex, setOpenIndex] = useState<any>(null);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [rowDelId, setRowDelId] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const location = useLocation();
    const reqInfo = { RequestInfo: requestInfo() };
    const gsrData = location?.state?.data;
    const userUuid = JSON.parse(localStorage.getItem('user') || '{}')?.uuid;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: gsrPopUp ? GSR_BOOK_DETAILS_VALIDATION : null
    });
    const { values, setValues, errors, setTouched, setFieldValue, setFieldTouched } = formik;

    const downloadTemplate = async () => {
        try {
            axios({
                url: devUrl + endpoints.downloadGsrTemplate,
                method: 'POST',
                data: reqInfo,
                responseType: 'blob'
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'GSR details excel template.xlsx';
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error('Error while downloading the file:', error);
        }
    };

    const serachGsr = async () => {
        const { data } = await POST(endpoints.searchGsr + `&gsrNames=${gsrData?.gsrName}&isGsrMasterDetailsRequired=true&gsrYears=${gsrData?.gsrYear}`, reqInfo);
        const gsrBookResData = data?.gsrMasterDetailsModel.map((val: any, ind: number) => ({
            gsrItemNo: val?.itemNo,
            gsrUnitRate: val?.gsrUnitRate,
            gsrUnit: val?.unitOfMeasurement,
            ItemDesc: val?.itemDesc,
            rowId: val?.uuid,
            gsrMaster: val?.gsrMaster,
            isActive: val?.isActive,
            auditDetails: val?.auditDetails,
            serialNo: ind + 1
        }));
        setGsrBookData(gsrBookResData);
    };
    useEffect(() => {
        serachGsr();
    }, []);

    const gsrBookFields = fieldDisabled
        ? GSR_BOOK_POPUP_FIELDS.map((val: any) => ({
              ...val,
              formData: [
                  ...val.formData.map((value: any) => ({
                      ...value,
                      isDisabled: true
                  }))
              ]
          }))
        : GSR_BOOK_POPUP_FIELDS;

    const addGsrBookDetails = () => {
        const val = _.cloneDeep(values);

        setIsEditing(false);
        const keysToDelete = ['gsrItemNo', 'gsrUnitRate', 'gsrUnit', 'itemDescriptioin'];
        keysToDelete.forEach(key => {
            delete val[key];
        });
        setValues({ ...val });
        setTouched({});
        setGsrPopUp(true);
        setDisabled(false);
        setOpenIndex(null);
    };

    const updatedGsrData = {
        gsrName: gsrData?.gsrName,
        gsrYear: gsrData?.gsrYear,
        uuid: gsrData?.rowId,
        isActive: gsrData?.isActive,
        auditDetails: gsrData?.auditDetails
    };

    const commonUpdatePayload = (data?: any) => {
        const updatePayload = {
            ...reqInfo,
            gsrMasterDetails: {
                uuid: data ? data?.rowId : values?.rowId,
                gsrYear: gsrData?.gsrYear,
                gsrName: gsrData?.gsrName,
                itemNo: data ? data?.gsrItemNo : values?.gsrItemNo,
                itemDesc: data ? data?.ItemDesc : values?.itemDescriptioin,
                unitOfMeasurement: data ? data?.gsrUnit : values?.gsrUnit,
                gsrUnitRate: data ? data?.gsrUnitRate : values?.gsrUnitRate,
                isActive: data ? false : values?.isActive,
                gsrMaster: data ? data?.gsrMaster : values?.gsrMaster,
                auditDetails: data ? data?.auditDetails : values?.auditDetails
            }
        };
        return updatePayload;
    };

    const handlePrimaryAction = async () => {
        const updatePayload = commonUpdatePayload();
        const payload = {
            ...reqInfo,
            gsrMasterDetails: {
                gsrMaster: updatedGsrData,
                itemNo: values?.gsrItemNo,
                itemDesc: values?.itemDescriptioin,
                unitOfMeasurement: values?.gsrUnit,
                gsrUnitRate: +values?.gsrUnitRate
            }
        };
        try {
            await GSR_BOOK_DETAILS_VALIDATION.validate(values, { abortEarly: false });
            try {
                if (!isEditing) {
                    await POST(endpoints.createGsr, payload);
                    setModalDetails({ show: true, title: 'Success', para: t('ITEM_ADDED', 'GSR Item Added Successfully'), img: check, type: 'added', reUpload: 'OK' });
                } else {
                    await POST(endpoints.updateGsr, updatePayload);
                    setModalDetails({ show: true, title: 'Success', para: t('ITEM_UPDATED', 'GSR Item Updated Successfully'), img: check, type: 'added', reUpload: 'OK' });
                }
                serachGsr();
                setGsrPopUp(false);
            } catch (err: any) {
                let errMsg = err?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            setFieldTouched('gsrItemNo', true);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                await setFieldTouched(errorField, true, true);
            }
            let errMsg = 'Please fill in all the required details!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        const newUuid = uuidv4();
        const gsrRowData: any = {
            gsrItemNo: values?.gsrItemNo,
            gsrUnitRate: values?.gsrUnitRate,
            gsrUnit: values?.gsrUnit,
            ItemDesc: values?.itemDescriptioin,
            rowId: isEditing ? values.rowId : newUuid,
            isActive: true,
            serialNo: gsrBookData?.length + 1
        };
        if (isEditing) {
            const updatedData = gsrBookData.map((data: any) => {
                if (data.rowId === isEditing) return gsrRowData;
                else return data;
            });
            setGsrBookData([...updatedData]);
        } else {
            setGsrBookData([...gsrBookData, gsrRowData]);
        }
        setGsrPopUp(false);
    };

    const onEdit = (data: any, status: boolean = false) => {
        const detailObj: any = gsrBookData.find((item: any, _: number) => item.rowId === data.rowId);
        const index: any = gsrBookData.findIndex((item: any, _: number) => item.rowId === data.rowId);
        setOpenIndex(index > -1 ? index + 1 : null);
        if (!detailObj) return;

        const formData = {
            gsrItemNo: detailObj?.gsrItemNo,
            gsrUnitRate: detailObj?.gsrUnitRate,
            gsrUnit: detailObj?.gsrUnit,
            itemDescriptioin: detailObj?.ItemDesc,
            rowId: detailObj.rowId ? detailObj.rowId : null,
            gsrMaster: detailObj.gsrMaster,
            isActive: detailObj?.isActive,
            auditDetails: detailObj?.auditDetails
        };

        setIsEditing(detailObj.rowId);
        setValues({ ...values, ...formData });
        setGsrPopUp(true);
        setDisabled(status);
    };

    const onView = (status: boolean, data: any) => {
        setDisabled(status);

        setFieldDisabled(true);
        onEdit(data, status);
    };

    const onDeleteItem = async (rowId: any) => {
        setShowDeleteModal(true);
        setRowDelId(rowId);
    };

    const handleDelete = async (rowId: any) => {
        setShowDeleteModal(false);
        try {
            const dataToDelete = gsrBookData.find((val: any) => val?.rowId === rowId);
            const deletePayload = commonUpdatePayload(dataToDelete);
            await POST(endpoints.updateGsr, deletePayload);
            setShowDeleteModal(false);

            serachGsr();
            setShowDeleteModal(false);

            setModalDetails({ show: true, title: 'Success', para: t('ITEM_DELETED', 'GSR Item Deleted Successfully'), img: check, type: 'Delete', reUpload: 'OK' });
        } catch (err: any) {
            let errMsg = err?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const handleImport = async (e: any) => {
        const file = e.target.files[0]; // Capture the file

        const formData: any = new FormData();
        formData.append('gsrYears', gsrData.gsrYear);
        formData.append('gsrNames', gsrData.gsrName);
        formData.append('isActive', gsrData.isActive);
        formData.append('userUuid', userUuid);
        formData.append('file', file);

        try {
            setIsLoading(true);
            const { data } = await axios.post(devUrl + endpoints.gsrImport, formData, {});
            setIsLoading(false);
            if (data?.gsrMasterDetailsModel?.length) {
                setModalDetails({ show: true, title: 'Success', para: t('FILE_UPLOADED', 'File Uploaded Successfully'), img: check, type: 'upload success', reUpload: 'OK' });

                serachGsr();
            } else {
                setModalDetails({
                    show: true,
                    title: 'Error!',
                    para: t('NO_DATA_FOUND', 'No Data Found'),
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
            }
        } catch (err: any) {
            setIsLoading(false);
            let errMsg = err?.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const ImportButton = () => {
        return (
            <>
                <div id='export_button' className={`d-inline-flex flex-row align-items-center justify-content-around stickyCard`} style={{ zIndex: '999' }}>
                    <input type='file' className=' position-absolute h-100 opacity-0 cursor-pointer' style={{ width: '90%' }} onChange={handleImport} />
                    <button onClick={() => {}} type={'button'} className={`Button_medium Export_button bg-white`}>
                        <img alt='' className='me-2' src={icon} />
                        <span>Import</span>
                    </button>
                </div>
            </>
        );
    };

    console.log('gsrBookData', gsrBookData);
    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={t('GSR_HEADING', `${gsrData?.gsrName}-${gsrData?.gsrYear}`)} additionalClass='sub-text-style' />
                        <FormikProvider value={formik}>
                            <div className='mb-3'>
                                <div className='gsr-scrren-download-section rounded-2 p-3 d-flex justify-content-between mt-4'>
                                    <span className='lh-22 font-14 fw-500'>Use this template for importing data through excel sheet.</span>
                                    <span className=' text-decoration-underline lh-22 font-14 fw-bold cursor-pointer' onClick={downloadTemplate}>
                                        {' '}
                                        <img src={downloadIcon} className='me-2' alt='icon' />
                                        Download .xls template
                                    </span>
                                </div>
                            </div>
                            <OwnershipTable
                                column={GSR_BOOK_TABLE_COLUMNS}
                                tableRowData={gsrBookData}
                                extraButton={<ImportButton />}
                                searchBarPlaceholder={'Search'}
                                addButton='+ Add'
                                onClickPopUp={addGsrBookDetails}
                                additionalMethod={onEdit}
                                updateOwnershipDataStatus={onView}
                                deleteOwnershipDetails={onDeleteItem}
                                gsrTitle={`${gsrData?.gsrName}-${gsrData?.gsrYear}`}
                            />
                            <GenericPopupModal
                                size={'lg'}
                                title={`${gsrData?.gsrName}-${gsrData?.gsrYear}`}
                                secondaryAction={() => {
                                    setFieldDisabled(false);
                                    setGsrPopUp(false);
                                }}
                                isPrimaryBtn={!fieldDisabled ? true : false}
                                primaryBtnTitle='Save'
                                primaryAction={handlePrimaryAction}
                                children={
                                    <>
                                        {gsrBookFields.map((val: any) => (
                                            <GenericFormCard {...val} styles={{ background: '#F5F5F6', padding: '10px 16px' }} />
                                        ))}
                                    </>
                                }
                                isVisible={gsrPopUp}
                            />
                            <DeletePopUpModal
                                secondaryAction={() => {
                                    setShowDeleteModal(false);
                                }}
                                primaryAction={() => {
                                    handleDelete(rowDelId);
                                }}
                                isVisible={showDeleteModal}
                                title={'Delete File?'}
                                img={DeleteWarning}
                                primaryBtnTitle={t('DELETE', 'Delete')}
                                secondaryBtnTitle={t('CANCEL', 'Cancel')}
                                children={
                                    <>
                                        <div className='p-4' style={{ backgroundColor: '#F5F5F6' }}>
                                            <div className='font-16 fw-bold lh-22'>
                                                Are you sure you want to delete this file? <br />
                                                You can't undo this action!
                                            </div>
                                        </div>
                                    </>
                                }
                            />
                        </FormikProvider>
                    </div>
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                </>
            )}
        </>
    );
};

export default GsrMasterDetails;
