import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { NextValidActionApiResponse, NextValidActionItem } from './GenericFormCard.constants';
import GenericPopupModal from './GenericModalPopup.component';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../UI components/Buttons/GenericButtons';
import { ErrorMessage, Field, Formik, FormikProvider, useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { COMMENTS } from '../../utils/formikValidation/FormikValidation';
import '../moduleCommonFormik/GenericStyles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface FooterPropsData {
    onClickNext?(): void;
    onCancel?(): void;
    onClickPrev?(): void;
    stepperLength: number;
    activeIndex: number;
    onSubmit(type?: boolean): void;
    nextValidAction?: NextValidActionApiResponse;
    applicationNo?: number | string | null;
    handleOnPrimaryAction?: (data: any, comment: string) => void;
    disable?: boolean;
    showOnLastPage?: boolean;
    childrenContent?: ReactElement;
    commentValue?: any;
    applicationType?: string;
    hideSaveBtn?: boolean;
    extraButtonAction?: any;
    extraButton?: any;
}

export default function GenericFooterComponent(props: FooterPropsData) {
    const { t } = useTranslation();
    const {
        nextValidAction,
        activeIndex,
        childrenContent,
        commentValue,
        stepperLength,
        showOnLastPage,
        applicationNo,
        onClickNext,
        onSubmit,
        handleOnPrimaryAction,
        applicationType,
        hideSaveBtn,
        extraButton,
        extraButtonAction
    } = props;
    const [lastPage, setLastPage] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState(false);
    const [toShow, setToShow] = useState<any>(null);
    const [selectedAction, setSelectedAction] = useState<NextValidActionItem | null>(null);
    const [toShowPopUp, setToShowPopUp] = useState<any>(null);
    const [validActions, setValidActions] = useState<any>(null);
    const [showBackOptionsBtn, setShowBackOptionsBtn] = useState<boolean>(false);
    const [backOptions, setBackOptions] = useState<any>([]);
    const [displayButtons, setDisplayButtons] = useState<any>([]);
    const btnRef = useRef<any>(null);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},
        validationSchema: COMMENTS
    });

    useEffect(() => {
        setLastPage(activeIndex === stepperLength);
    }, [activeIndex, stepperLength]);

    useEffect(() => {
        const onlyBackOptions = nextValidAction?.nextValidAction.filter((item: any) => item?.action.includes('SEND_BACK') || item?.action.includes('RETURN_TO'));
        setBackOptions(onlyBackOptions);

        const actionsOptions: any = nextValidAction?.nextValidAction
            .filter((item: any) => {
                return !item?.action.includes('SEND_BACK') && !item?.action.includes('RETURN_TO');
            })
            ?.reverse();
        setDisplayButtons(actionsOptions);

        const reversedActions = [...(nextValidAction?.nextValidAction || [])].reverse();
        setValidActions(reversedActions);
    }, [nextValidAction]);

    useEffect(() => {
        const handleOutsideClick = (e: any) => {
            if (btnRef.current && !btnRef.current.contains(e.target)) {
                setShowBackOptionsBtn(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const onNextActionBtnClick = async (e: any, actionItem: NextValidActionItem) => {
        console.log('actionitem', actionItem);
        try {
            if (!actionItem.isEmployeeAssignee && nextValidAction?.businessService !== 'LEGAL_DEED') {
                setShowAlert(prev => true);
                // await COMMENTS.validate(formik.values, { abortEarly: false });
            } else {
                handleOnPrimaryAction && handleOnPrimaryAction(actionItem, '');
            }
            setSelectedAction(actionItem);
            setToShowPopUp(actionItem.action);
            nextValidAction?.isUpdatable && onSubmit();
        } catch (e) {}
    };
    const renderActionForm = () => {
        return (
            <FormikProvider value={formik}>
                <form>
                    <div className=' px-2   '>
                        <label htmlFor='comments ' className='pb-2 ps-1'>
                            Comments <span className='mandatory-indicator'>*</span>
                        </label>
                        <Field name='comments' as='textarea' className='form-control mb-2' style={{ height: '100px' }} />
                        <ErrorMessage name='comments' component='div' className='text-danger font-12' />
                    </div>
                </form>
            </FormikProvider>
        );
    };
    return (
        <>
            <footer className='footer_common fixed-bottom footerBtnPatch'>
                <div className='d-flex'>
                    <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                        {props.activeIndex !== 1 && !nextValidAction && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={props.activeIndex === 1 ? props?.onCancel : props.onClickPrev} />}
                    </div>

                    <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                        {extraButton ? <GenericButtons text={t('SAVE', extraButton)} handleClick={extraButtonAction} /> : <></>}

                        {applicationType === 'deed'
                            ? activeIndex === 4 && !lastPage && <GenericButtons text={t('SAVE_AND_NEXT', 'Save & Next')} handleClick={() => onSubmit(true)} />
                            : !nextValidAction && (
                                  <>
                                      {!lastPage && !hideSaveBtn && <GenericButtons text={t('SAVE', 'Save')} handleClick={() => onSubmit()} />}
                                      <GenericButtons
                                          text={`${lastPage && !nextValidAction ? t('SUBMIT', 'Submit') : t('SAVE_AND_NEXT', 'Save & Next')}`}
                                          disabled={props?.disable}
                                          variant='primary'
                                          handleClick={() => onSubmit(true)}
                                          ClassName={nextValidAction && lastPage ? 'd-none' : ''}
                                      />
                                  </>
                              )}

                        {/* {((nextValidAction && !nextValidAction?.isUpdatable) || applicationType === 'deed') && !lastPage && <GenericButtons text={t('NEXT', 'Next')} handleClick={onClickNext} />} */}
                        {applicationType === 'deed' && !lastPage && <GenericButtons text={t('NEXT', 'Next')} handleClick={onClickNext} />}

                        {backOptions?.length ? (
                            <div className='position-relative' ref={btnRef}>
                                <GenericButtons
                                    text={
                                        <>
                                            {t('REVERT', 'Revert')} <FontAwesomeIcon icon={faChevronUp} />
                                        </>
                                    }
                                    handleClick={() => {
                                        setShowBackOptionsBtn(!showBackOptionsBtn);
                                    }}
                                    disabled={props?.disable}
                                />

                                <div className={`position-absolute showBackBtns ${showBackOptionsBtn ? 'visible' : ''}`} style={{ bottom: '3rem' }}>
                                    {nextValidAction && showBackOptionsBtn && (
                                        <div className='send-back-card justify-content-center'>
                                            {backOptions?.map((item: NextValidActionItem, index: number) => (
                                                <>
                                                    <div className='  font-16 dropdown-text cursor-pointer font-inter' onClick={e => onNextActionBtnClick(e, item)}>
                                                        <div>{t(item?.action)}</div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}

                        {nextValidAction &&
                            displayButtons?.map((item: NextValidActionItem, index: number) => (
                                <>
                                    {showOnLastPage && !lastPage ? (
                                        <></>
                                    ) : (
                                        <GenericButtons
                                            variant={index === displayButtons.length - 1 ? 'primary' : ''}
                                            text={t(item?.action)}
                                            handleClick={e => onNextActionBtnClick(e, item)}
                                            disabled={props?.disable}
                                        />
                                    )}
                                    <GenericPopupModal
                                        title=''
                                        subTitle={`Application No: ${applicationNo}`}
                                        isVisible={item?.action === toShowPopUp ? showAlert : false}
                                        primaryBtnTitle='Submit'
                                        secondaryAction={() => setShowAlert(false)}
                                        primaryAction={() => handleOnPrimaryAction && handleOnPrimaryAction(selectedAction, commentValue ? commentValue : formik.values.comments)}
                                        secondaryBtnTitle='Close'
                                        children={childrenContent ? childrenContent : renderActionForm()}
                                    />
                                </>
                            ))}
                    </div>
                </div>
            </footer>
        </>
    );
}
