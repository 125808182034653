import { BPArequestInfo, requestInfo } from '../../services/modulesServices/userManagement.api';

export const RenewalCLFormJson = (values: any, extValue: any, isUpdate: boolean) => {
    var userinfo = window.localStorage.getItem('user');
    console.log(isUpdate);
    if (!isUpdate) {
        return {
            RequestInfo: BPArequestInfo(),
            BPA: {
                id: values?.id ? values?.id : null,
                tenantId: 'ga',
                applicationNo: values?.id ? values?.appId : null,
                approvalNo: null,
                edcrNumber: null,
                riskType: 'High',
                businessService: 'BPA1',
                applicationType: 'NEW',
                serviceType: values?.serviceType,
                approvalDate: null,
                validUpTo: null,
                applicationDate: null,
                status: null,
                accountId: null,
                lesseeId: null,
                archRegId: values?.archName,
                archName: values?.archName,
                noteId: null,
                notes: null,
                lesseeDetails: {
                    id: null,
                    name: values?.name,
                    gender: values?.gender,
                    phoneNo: values?.phone,
                    pan: values?.panNo,
                    address: {
                        id: null,
                        tenantId: 'ga.goa',
                        pincode: values?.pincode,
                        district: values?.district,
                        state: values?.state,
                        country: values?.country,
                        addressLine1: values?.addressLine1,
                        addressLine2: values?.addressLine2
                    }
                },
                documents: values.documents,
                workflow: null
            }
        };
    } else {
        const merged = Object.assign({}, extValue);

        for (const key of Object.keys(values)) {
            if (key in merged) {
                merged[key] = values[key];
            }
        }
        merged.archRegId = values?.archName || extValue?.archRegId;
        const modifiedPayload = {
            RequestInfo: BPArequestInfo(),
            BPA: merged
        };
        return modifiedPayload;
    }
};

export const createInspectionFormJson = (values: any, extValue: any, isUpdate: boolean) => {
    if (!isUpdate) {
        return {
            RequestInfo: BPArequestInfo(),
            FieldInspection: {
                id: null,
                applicationNo: values?.appId,
                scheduledDate: new Date(values?.inspDate).getTime(),
                scheduledTime: `${values?.inspTime}:00`,
                requestForReschedule: values?.rescheduleRequest,
                lesseeAcepted: values?.isLesseeAccept,
                employeeAccepted: values?.isEmployAccept,
                requestForRescheduleData: values?.isRequestForSchedule
                    ? {
                          scheduledDate: new Date(values?.toDate).getTime(),
                          scheduledTime: `${values?.inspTime}:00`,
                          comment: values?.comment,
                          requestDate: new Date(values?.toDate).getTime()
                      }
                    : null,
                inspectionDate: null,
                inspectionTime: null,
                planApprovalNo: null,
                planApprovalDate: null,
                isactive: true,
                isClosed: false,
                inspectionCheckListData: null,
                documents: null
            }
        };
    } else {
        const merged = Object.assign({}, extValue);

        for (const key of Object.keys(values)) {
            if (key in merged) {
                merged[key] = values[key];
            }
        }
        merged.id = extValue?.id;
        const modifiedPayload = {
            RequestInfo: BPArequestInfo(),
            FieldInspection: merged
        };
        return modifiedPayload;
    }
};

export const getCLList = (data: any) => {
    let newList: any = [];
    if (!!data && !!data.length) {
        data.map((val: any) => {
            newList.push({
                name: `${val?.applicationNo}`,
                title: ` ${val?.applicationNo}`,
                value: val?.applicationNo,
                code: val?.applicationNo,
                subcategory: []
            });
        });
        return newList;
    }
};
