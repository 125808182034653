import React from 'react';
import style from './ContactUs.module.css';
import { Image } from 'react-bootstrap';
import '../../features/StepsForPopUp/Steps.css';

import { envelope, map, phone } from '../../utils/Logo';
import { logo_img } from '../../utils/Images';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ContactUsComponent() {
    const { t } = useTranslation();
    const address = ' Goa Industrial Development Corporation, Panaji, Goa - 403 001';
    const formattedAddress = address.replace(/ /g, '+');
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;

    return (
        <div id='contactUS' className='contactus-container'>
            <div className='container-fluid py-5 pt-5 ' style={{ background: '#F5F5F6' }}>
                <div className='row '>
                    <div className='ps-5 text-start font-inter-md'>
                        <h2 className='font-inter-md'>{t('REACH_US', 'Reach Us')}</h2>
                    </div>
                    <div className='col-sm-3  '>
                        <div className=' mega ms-5 mt-3'>
                            <div className='container'>
                                <div className='row noMargin'>
                                    <div className={`${style.square}`}>
                                        <Link target='_blank' to='https://www.linkedin.com/company/goa-industrial-development-corporation-gidc/?originalSubdomain=in'>
                                            <i className={`fa fa-linkedin ${style.iconCenter}`} />
                                        </Link>
                                    </div>
                                    <div className={` ${style.square}`}>
                                        <Link target='_blank' to='https://twitter.com/goa_idc'>
                                            <i className={`fa fa-twitter ${style.iconCenter}`} />
                                        </Link>
                                    </div>
                                </div>
                                <div className='row noMargin'>
                                    <div className={` p-0 d-flex ${style.square_change}`}>
                                        <span className={`${style.face_icon} ${style.face_icon1}`}></span>
                                        <span className={`${style.face_icon} ${style.face_icon2}`}></span>
                                        <span className={`${style.face_icon} ${style.face_icon3}`}></span>
                                        <img src={logo_img} className={`w-100 h-100 ${style.face_image}`} alt='' />
                                    </div>
                                    <div className={`${style.square}`}>
                                        <Link target='_blank' to='https://www.instagram.com/goa_idc/'>
                                            {' '}
                                            <i className={`fa fa-instagram ${style.iconCenter}`} />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-8    '>
                        <div className='row font-karla-reg d-flex justify-content-between  '>
                            <div className={`col-sm-4 mt-3 ps-4 ${style.border_contact}  text-start`}>
                                <div className='text-white fs-1 fw-bold'>
                                    <Image src={phone} height={64} width={64} />
                                </div>
                                <div className='fs-6 fw-bold pt-3'>{t('PHONE', 'Phone')}</div>
                                <div className={style.cardSubText_1}>
                                    <a href='tel:+91 0832 2437470' className='text-decoration-none' style={{ color: '#272727' }}>
                                        +91 8322437470
                                    </a>
                                </div>
                            </div>
                            <div className={`col-sm-4 mt-3 ps-5 ${style.border_contact}  text-start`}>
                                <div className='text-white fs-1 fw-bold'>
                                    {/* <Link to='mailto:goa-idc@goa.gov.in'> */}
                                    <Image src={envelope} height={64} width={64} />
                                    {/* </Link> */}
                                </div>
                                <div className='fs-6 fw-bold pt-3'>{t('EMAIL', 'Email')}</div>
                                <div className={`${style.cardSubText_1} pt-2`}>
                                    <a href='mailto:goa-idc@goa.gov.in' className='text-decoration-none' style={{ color: '#272727' }}>
                                        goa-idc@goa.gov.in
                                    </a>
                                    <br />
                                    <a href='mailto:ithelpdesk-gidc@goa.gov.in' className='text-decoration-none' style={{ color: '#272727' }}>
                                        ithelpdesk-gidc@goa.gov.in
                                    </a>
                                </div>
                            </div>
                            <div className='col-sm-4 mt-3 ps-5  text-start'>
                                <div className='text-white fs-1 fw-bold'>
                                    <Link to={mapsUrl} target='_blank'>
                                        <Image src={map} height={64} width={64} />
                                    </Link>
                                </div>
                                <div className='fs-6 fw-bold pt-3'>{t('ADDRESS', 'Address')}</div>
                                <div className={`${style.cardSubText_1} pt-2`}>{t('GOA_IDC_ADDRESS_LINE2', 'Plot No. 13A-2, EDC Complex Patto Plaza')}</div>
                                <div className={style.cardSubText_1}>{t('GOA_IDC_ADDRESS_LINE3', 'Panaji-Goa, 403 001')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
