import React, { useEffect, useState } from 'react';
import '../../features/modules/UserManagement/EnterpriseModule/EnterpriseStyles.scss';
import style from '../../features/modules/UserManagement/moduleComponents/table.module.css';
import _ from 'lodash';
import editIcon from '../../assets/icons/edit.svg';
import viewIcon from '../../assets/icons/view.svg';
import deleteIcon from '../../assets/icons/delete.svg';
import GenericExportDropdown from '../../components/export/GenericExport';
import { useTranslation } from 'react-i18next';
import GenericPopupModal from '../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../components/fileviewer/GenericImageViewer';
import { Link } from 'react-router-dom';
import ToolTipDetails from '../../components/ToolTipFile';

interface QuestionnaireTableProps {
    tableRowData?: any;
    additionalMethod?: any;
    updateOwnershipDataStatus?: any;
    column?: any;
    isDisabled?: boolean;
    setTotalOwnershipPercentage?: (e: any) => void;
    deleteOwnershipDetails?: (e: any) => void;
    hideSearchBar?: boolean;
    searchBarPlaceholder?: any;
    showIndex?: true;
    scrollNotRequired?: boolean;
    checkType?: string;
    onCheckHandler?: ((selectedPlot: any, column: any) => void) | any;
    selectedItems?: any;
}

export const QuestionnaireTable = (props: QuestionnaireTableProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchedData, setSearchedData] = useState([]);
    const totalLengthOfData = props?.tableRowData?.length;
    const [itemsPerPage, setItemPerPage] = useState(10);
    const { t } = useTranslation();
    const [sortedColumn, setSortedColumn] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<string>('');
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>({});
    const [isPDF, setIsPDF] = useState(true);
    const [searchedText, setSearchedText] = useState<string>('');
    const [selected, setSelected] = useState<any[]>([]);

    console.log(props?.tableRowData, 'QuestionnaireTable');
    const sortByColumn = (column: string) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedColumn(column);
            setSortOrder('asc');
        }
    };

    const showSortedColumnIcon = (columnName: string) => {
        return sortedColumn === columnName ? (
            sortOrder === 'asc' ? (
                <i className='fa fa-long-arrow-up table-arrow' aria-hidden='true' />
            ) : (
                <i className='fa fa-long-arrow-down table-arrow' aria-hidden='true'></i>
            )
        ) : (
            <></>
        );
    };

    useEffect(() => {
        if (props.showIndex) {
            const data = props?.tableRowData?.map((item: any, id: number) => ({ ...item, index: id + 1 }));
            setSearchedData(data);
        } else {
            const updatedRowData = props?.tableRowData?.filter((item: any, _: number) => !item['isDelete']);
            setSearchedData(updatedRowData);
        }
        setCurrentPage(1);
    }, [props?.tableRowData]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchedData;
    console.log(currentItems, 'currentItems');
    const totalPages = Math.ceil(props?.tableRowData?.length / itemsPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage: any) => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage: any) => Math.max(prevPage - 1, 1));
    };
    const onRowClick = (event: any, rowData: any, isView: boolean = false) => {
        props?.additionalMethod && props?.additionalMethod(rowData);
        isView && props.updateOwnershipDataStatus && props.updateOwnershipDataStatus(rowData);
    };

    const viewDocument = (id: string, name: string) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData({ id: id, name: name });
        setIsPDF(isPDF);
    };
    const onRowCheck = (event: any, id: any, col: any) => {
        if (props?.checkType === 'multiCheck') {
            const selectedIndex: any = selected.indexOf(id as never);
            let newSelected: any = [];
            const NOCHECK_ITEM = -1;
            if (selectedIndex === NOCHECK_ITEM) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
            setSelected(newSelected);
            console.log(newSelected);
            props?.onCheckHandler(newSelected, col);
        } else {
            const selectedIndex: any = selected.indexOf(id as never);
            let newSelected: any = [];
            const NOCHECK_ITEM = -1;
            if (selectedIndex === NOCHECK_ITEM) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            }
            setSelected(newSelected);
            props?.onCheckHandler(newSelected, col);
        }
    };
    const isSelected = (id: any) => {
        return selected.indexOf(id as never) !== -1;
    };
    return (
        <>
            <div className='container font-inter'>
                <table className='table_outer_area '>
                    {/* <div style={props.scrollNotRequired ? {} : { overflowX: 'scroll', borderRadius: '16px 16px 0px 0px', scrollbarWidth: 'thin' }}> */}
                    <div style={{ overflowX: 'auto', borderRadius: '16px 16px 0px 0px', scrollbarWidth: 'thin' }}>
                        <thead className='table_header_part '>
                            <tr className='py-3 text-start ps-4 d-flex align-items-center'>
                                {props?.column.map(
                                    (data: any) =>
                                        !(data.type === 'action' && props?.isDisabled) && (
                                            <th
                                                style={data.cellstyle}
                                                className={`${data.clsName} table-column-seprator cursor-pointer ${data.toolTipDetails ? ' d-flex justify-content-end' : ''}`}
                                                onClick={() => sortByColumn(data.fieldName)}
                                            >
                                                {data.colName}
                                                {showSortedColumnIcon(data.fieldName)}
                                                {data.toolTipDetails && <ToolTipDetails toolTipDetails={data.toolTipDetails} />}
                                            </th>
                                        )
                                )}
                            </tr>
                        </thead>
                        <tbody className='table_body_part  '>
                            {!totalLengthOfData ? (
                                <div className='text-center fw-bold mt-3'>
                                    <h5>Sorry, we couldn't find any results</h5>
                                </div>
                            ) : (
                                props?.tableRowData?.map((rowData: any, rowIndex: number) => {
                                    const rowId = rowData.id;
                                    const isItemSelected = isSelected(rowId);

                                    return (
                                        <>
                                            <tr className='py-3 text-start ps-4  d-flex   '>
                                                {props?.column.map((data: any) => (
                                                    <>
                                                        {data.type !== 'action' ? (
                                                            <>
                                                                {data.fieldName === 'serialNo' ? (
                                                                    <td style={data?.cellstyle} className={`${data.clsName}  ${data.type === 'document' && 'documentStyle'} tabel_data px-3`}>
                                                                        {rowIndex + 1}
                                                                    </td>
                                                                ) : data.fieldName === 'checkbox' ? (
                                                                    <td className={`${data.clsName}`} style={data?.cellstyle}>
                                                                        <input
                                                                            type='checkbox'
                                                                            key={Math.random()}
                                                                            checked={isItemSelected || rowData.isChecked}
                                                                            name='row-check'
                                                                            onChange={event => onRowCheck(event, rowId, rowData)}
                                                                            disabled={rowData.isDisabled}
                                                                            className={rowData.isChecked ? 'default-checked' : ''}
                                                                        />
                                                                    </td>
                                                                ) : (
                                                                    <td
                                                                        style={data?.cellstyle}
                                                                        className={`${data.clsName} ${data?.isClickable && 'navigateStyle'} ${
                                                                            data.type === 'document' && 'documentStyle'
                                                                        } tabel_data px-3`}
                                                                        onClick={data?.isClickable && (e => onRowClick(e, rowData))}
                                                                    >
                                                                        {data.type === 'document' ? (
                                                                            <Link
                                                                                to=''
                                                                                onClick={event => {
                                                                                    event.preventDefault();
                                                                                    viewDocument(_.get(rowData, data.fieldName)?.docReferenceId, _.get(rowData, data.fieldName)?.documentName);
                                                                                }}
                                                                            >
                                                                                {_.get(rowData, data.fieldName)?.documentName}
                                                                            </Link>
                                                                        ) : (
                                                                            rowData[data?.fieldName]
                                                                        )}
                                                                    </td>
                                                                )}
                                                            </>
                                                        ) : (
                                                            !props?.isDisabled && (
                                                                <td className='fixed-action-column tabel_data'>
                                                                    <div className='table-actions'>
                                                                        {data.actions.includes('edit') && (
                                                                            <img
                                                                                src={editIcon}
                                                                                alt='edit'
                                                                                className='table-action-icon'
                                                                                onClick={e => {
                                                                                    onRowClick(e, rowData);
                                                                                }}
                                                                            />
                                                                        )}

                                                                        {data.actions.includes('view') && (
                                                                            <img
                                                                                src={viewIcon}
                                                                                alt='view'
                                                                                className='table-action-icon'
                                                                                onClick={e => {
                                                                                    onRowClick(e, rowData, true);
                                                                                }}
                                                                            />
                                                                        )}

                                                                        {data.actions.includes('delete') && (
                                                                            <img
                                                                                src={deleteIcon}
                                                                                alt='delete'
                                                                                className='table-action-icon'
                                                                                onClick={() => {
                                                                                    console.log(rowData);

                                                                                    props.deleteOwnershipDetails && props.deleteOwnershipDetails(rowData.rowId);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            )
                                                        )}
                                                    </>
                                                ))}
                                            </tr>
                                        </>
                                    );
                                })
                            )}
                        </tbody>
                    </div>
                    <tfoot className='table_footer_part py-2 d-flex justify-content-between  px-4 '>
                        <p> Entries found : {totalLengthOfData}</p>
                        <div>
                            <button onClick={handlePrevPage} disabled={currentPage === 1} className={`${style.arrow}`}>
                                <i className='fa fa-arrow-left' aria-hidden='true'></i>
                            </button>

                            <span className={` mx-1 ${style.pagenumber}`}>{currentPage}</span>

                            <button onClick={handleNextPage} disabled={currentPage === totalPages} className={`${style.arrow}`}>
                                <i className='fa fa-arrow-right' aria-hidden='true'></i>
                            </button>
                        </div>
                    </tfoot>
                </table>
            </div>
            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData?.id} documentName={modalData?.name} />}
                        {!isPDF && <GenericImageViewer />}
                    </div>
                </GenericPopupModal>
            )}
        </>
    );
};
