// MainComponent.js
import React from 'react';
import HeaderComponent from '../../components/header/Header.component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PlotDetails = () => {

    const { t, i18n } = useTranslation();
    return (
        <>
            <HeaderComponent />
            <div className='mx-5 p-5 font-inter'>
                <div className='my-2'>
                    <p className='breadcrumbs'><Link to="/goa-idc/"> {t("HOME", "Home")}</Link> / {t("GOA_INDUSTRIAL_LAND_BANK", "Goa Industrial Land Bank")}</p>
                </div>

                <div className='col-md-8' style={{ fontWeight: 500, fontSize: '48px', lineHeight: '76.8px', letterSpacing: -1, marginBottom: '32px' }}>
                    {t("GOA_INDUSTRIAL_LAND_BANK", "Goa Industrial Land Bank")}
                </div>
                <iframe
                    title="Subpage"
                    src="https://water.ncog.gov.in/Goa/map.html"
                    width='100%'
                    height="600"
                />
            </div>
        </>
    );
}

export default PlotDetails;
