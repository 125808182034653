import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Notification.scss';
import viewall from '../../assets/logos/greenarrow.svg';
import { Card } from 'react-bootstrap';
import { POST, requestInfo } from '../../services/modulesServices/userManagement.api';
import { useDispatch } from 'react-redux';
import { setNotificationCount } from '../../redux/notificationSlice';

const Notification = (props: any) => {
    const { notificationData, setShowNotification } = props;
    const [notificationMapdata, setNotificationMapdata] = useState(notificationData?.userNotificationDetails);
    const userNotificationId = notificationData?.userNotificationDetails.map((data: any, index: number) => data.id);
    const dispatch = useDispatch();

    const notificationSeen = async (notificationId: number) => {
        try {
            const { data } = await POST(`/idc-notification-service/usernotification/details/_markasread?userNotificationIds=${notificationId}`, { RequestInfo: requestInfo() });
            dispatch(setNotificationCount(data.unreadCount));
            setShowNotification(false);
        } catch (error) {
            // alert('need to login again');
        }
    };

    const markAllAsRead = async () => {
        try {
            const { data } = await POST(`/idc-notification-service/usernotification/details/_markasread?userNotificationIds=${userNotificationId}`, { RequestInfo: requestInfo() });
            setNotificationMapdata(data.userNotificationDetails.slice(0, 4));
            dispatch(setNotificationCount(data.unreadCount));
        } catch (error) {
            // alert('need to login again');
        }
    };

    return (
        <>
            <Card className='border notification_card '>
                <Card.Title className='ps-4 my-3 notification_title d-flex justify-content-between align-items-center     '>
                    Notifications{' '}
                    <span className='notification_mark_all text-decoration-underline me-4 ' onClick={markAllAsRead}>
                        Mark all as read
                    </span>
                </Card.Title>
                <div className='container notification_parent'>
                    <div className='row px-3  ' style={{ maxHeight: '350px', overflowY: 'scroll' }}>
                        {notificationMapdata?.map((data: any, index: number) => (
                            // <div className='d-flex justify-content-center align-items-center position-relative h-75  '  >
                            // <span className={`${data.isRead ? '' : 'active_notification'} `}></span>
                            <Link to={data.notificationDetails.navUrl}>
                                <div className={`col-sm-12 d-inline-block  notification pt-2 `} key={index} onClick={() => notificationSeen(data.id)}>
                                    <h1 className={`notification_heading text-start   `} style={{ color: `${data.isRead ? '#000000B2' : '#000000'}` }}>
                                        {data.notificationDetails.module}
                                    </h1>
                                    <p className='notification_details mb-2' style={{ color: `${data.isRead ? '#3E3E3E' : '#000000'}`, fontWeight: `${data.isRead ? '400' : 'bolder'}` }}>
                                        {data.notificationDetails.message}
                                    </p>
                                    <p className='notification_timing text-end m-0 '>{data.auditDetails.createdDate}</p>
                                </div>
                            </Link>
                            // {/* </div> */ }
                        ))}
                    </div>
                    <div className=' d-flex justify-content-center  '>
                        <Link className=' text-center py-3 notification_viewall text-decoration-underline ' to='/goa-idc/viewNotification'>
                            {' '}
                            View All <img className='ms-2' src={viewall} alt='viewall' />
                        </Link>
                    </div>
                </div>
            </Card>
        </>
    );
};

export default Notification;
