import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CHECKLIST_TABLE, PLOT_DETAILS } from '../../Allotment/Allotment.const';
import { t } from 'i18next';
import { CO_CORRESPONDENCE_CARD, EXISTING_CO_DETAILS, NEW_CO_DETAILS } from '../CommercialOperation.const';
import { useState } from 'react';
import LesseeDetails from '../../SubLease/LesseeDetails';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';

export function CommercialOperationCorrespondence({ checkListRowData, response }: any) {
    const [openSection, setOpenSection] = useState<string>('lesseeDetails');

    const plotData = [{ formData: PLOT_DETAILS[0]?.formData }];

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const renderLesseeDetails = () => {
        return (
            <>
                <Card.Body>
                    <LesseeDetails />
                </Card.Body>
            </>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <Card.Body>
                    {plotData?.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const renderCertificateDetails = () => {
        return (
            <>
                <Card.Body>
                    {response?.previousCommercialOpsDetailsExpiryDate ? EXISTING_CO_DETAILS?.map((val: any) => <GenericFormCard {...val} />) : <></>}
                    {NEW_CO_DETAILS?.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row  my-3 px-3 py-2'>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                {t('CHECK_LIST', 'Condition/Check list')}
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {CO_CORRESPONDENCE_CARD?.map((val: any) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.titleCode ? t(val.titleCode) : val.title}
                                </label>
                                {val.title === 'A - Lessee Details' && openSection === 'lesseeDetails' && renderLesseeDetails()}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                {val.title === 'C - Certificate of Commercial Operation' && openSection === 'certificateDetails' && renderCertificateDetails()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
}
