import React, { useState } from 'react';
import { SUB_LESSEE_DETAILS_A3, subLeaseDetailsDeclarationList } from './SubLeaseConst';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { useFormikContext } from 'formik';
import PopUps from '../../../../components/PopUps';
import { getDiffBWDates } from '../../../../utils/utils';

const LeaseDetails = ({ isCheckedSubLeaseDetails, setIsCheckedSubLeaseDetails, handleCheckboxChange }: any) => {
    const { values, setValues } = useFormikContext<any>();
    const [modalDetails, setModalDetails] = useState<any>({});

    const onChangeAction = (e: any, field: string) => {
        let durationSubLease = getDiffBWDates(values.subLeaseStartDate, values.subLeaseEndDate);
        if (field === 'subLeaseStartDate' || field === 'subLeaseEndDate') {
            if (durationSubLease !== 'NaN Year(s) NaN Month(s)' && durationSubLease !== false) {
                setValues({ ...values, durationSubLease });
            }
        }

        if (field === 'disqualifiedEntity') {
            setValues({ ...values, disqualifiedEntity: false });
        }
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            {SUB_LESSEE_DETAILS_A3.map((val: any, id: number) => (
                <GenericFormCard {...val} onChangeAction={onChangeAction} />
            ))}

            <div className='ps-2 py-2 d-flex justify-content-between'>
                <div className='formTitle m-0'>
                    {`Sub-Lease Detail's Declaration`} <span className='text-danger'>*</span>
                </div>
            </div>

            <div className='ps-2 d-flex flex-column fw-medium'>
                <p className='mt-3 font-inter'>The Lessee does hereby affirm and declare that:</p>
                {subLeaseDetailsDeclarationList.map((item, index) => (
                    <label key={index} className='mb-3 d-flex gap-1'>
                        <input
                            type='checkbox'
                            checked={isCheckedSubLeaseDetails[index]}
                            onChange={() => handleCheckboxChange(index)}
                            disabled={item.disabled || !values.isUpdatable}
                            className={item.disabled || !values.isUpdatable ? 'default-checked' : ''}
                        />
                        <div className='flex-grow-0 font-inter'>{item.label}</div>
                    </label>
                ))}
            </div>
        </>
    );
};

export default LeaseDetails;
