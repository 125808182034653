import React from 'react';

export const ProgressCircleCheck = ({ color = '#3A7425' }) => (
    <svg width='27' height='26' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M13.75 0C11.1788 0 8.66543 0.762437 6.52759 2.1909C4.38975 3.61935 2.72351 5.64968 1.73957 8.02512C0.755633 10.4006 0.498189 13.0144 0.999797 15.5362C1.50141 18.0579 2.73953 20.3743 4.55762 22.1924C6.3757 24.0105 8.69208 25.2486 11.2138 25.7502C13.7356 26.2518 16.3495 25.9944 18.7249 25.0104C21.1003 24.0265 23.1307 22.3603 24.5591 20.2224C25.9876 18.0846 26.75 15.5712 26.75 13C26.7464 9.5533 25.3756 6.24882 22.9384 3.81163C20.5012 1.37445 17.1967 0.00363977 13.75 0ZM19.4575 10.7075L12.4575 17.7075C12.3646 17.8005 12.2543 17.8742 12.1329 17.9246C12.0115 17.9749 11.8814 18.0008 11.75 18.0008C11.6186 18.0008 11.4885 17.9749 11.3671 17.9246C11.2457 17.8742 11.1354 17.8005 11.0425 17.7075L8.04251 14.7075C7.85486 14.5199 7.74945 14.2654 7.74945 14C7.74945 13.7346 7.85486 13.4801 8.04251 13.2925C8.23015 13.1049 8.48464 12.9994 8.75001 12.9994C9.01537 12.9994 9.26987 13.1049 9.45751 13.2925L11.75 15.5863L18.0425 9.2925C18.1354 9.19959 18.2457 9.12589 18.3671 9.07561C18.4885 9.02532 18.6186 8.99944 18.75 8.99944C18.8814 8.99944 19.0115 9.02532 19.1329 9.07561C19.2543 9.12589 19.3646 9.19959 19.4575 9.2925C19.5504 9.38541 19.6241 9.49571 19.6744 9.6171C19.7247 9.7385 19.7506 9.8686 19.7506 10C19.7506 10.1314 19.7247 10.2615 19.6744 10.3829C19.6241 10.5043 19.5504 10.6146 19.4575 10.7075Z'
            fill={color}
        />
    </svg>
);

export const ProgressCircle = ({ color = '#3A7425' }) => (
    <svg width='27' height='26' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M13.5 0C10.9288 0 8.41543 0.762437 6.27759 2.1909C4.13975 3.61935 2.47351 5.64968 1.48957 8.02512C0.505633 10.4006 0.248189 13.0144 0.749797 15.5362C1.25141 18.0579 2.48953 20.3743 4.30762 22.1924C6.1257 24.0105 8.44208 25.2486 10.9638 25.7502C13.4856 26.2518 16.0995 25.9944 18.4749 25.0104C20.8503 24.0265 22.8807 22.3603 24.3091 20.2224C25.7376 18.0846 26.5 15.5712 26.5 13C26.4964 9.5533 25.1256 6.24882 22.6884 3.81163C20.2512 1.37445 16.9467 0.00363977 13.5 0ZM13.5 24C11.3244 24 9.19767 23.3549 7.38873 22.1462C5.57979 20.9375 4.16989 19.2195 3.33733 17.2095C2.50477 15.1995 2.28693 12.9878 2.71137 10.854C3.13581 8.72022 4.18345 6.7602 5.72183 5.22183C7.26021 3.68345 9.22022 2.6358 11.354 2.21136C13.4878 1.78692 15.6995 2.00476 17.7095 2.83733C19.7195 3.66989 21.4375 5.07979 22.6462 6.88873C23.8549 8.69767 24.5 10.8244 24.5 13C24.4967 15.9164 23.3367 18.7123 21.2745 20.7745C19.2123 22.8367 16.4164 23.9967 13.5 24Z'
            fill={color}
        />
    </svg>
);

export const ProgressRectangleBar = ({ color = '', width = '151', secondaryColor = 'red', gradWidth = '20%' }) => {
    const gradientId = `grad-${Math.random().toString()}`;
    return (
        <svg width={width} height='4' xmlns='http://www.w3.org/2000/svg'>
            <defs>
                <linearGradient id={gradientId} x1='0%' y1='0%' x2='100%' y2='0%'>
                    <stop offset='0%' style={{ stopColor: color, stopOpacity: 1 }} />
                    <stop offset={gradWidth} style={{ stopColor: secondaryColor, stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            <rect x='0' width={1500} height='4' rx='2' fill={`url(#${gradientId})`} />
        </svg>
    );
};

export const ProgressWarningCircle = () => (
    <svg width='27' height='26' viewBox='0 0 27 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M13.5 0C10.9288 0 8.41543 0.762437 6.27759 2.1909C4.13975 3.61935 2.47351 5.64968 1.48957 8.02512C0.505633 10.4006 0.248189 13.0144 0.749797 15.5362C1.25141 18.0579 2.48953 20.3743 4.30762 22.1924C6.1257 24.0105 8.44208 25.2486 10.9638 25.7502C13.4856 26.2518 16.0995 25.9944 18.4749 25.0104C20.8503 24.0265 22.8807 22.3603 24.3091 20.2224C25.7376 18.0846 26.5 15.5712 26.5 13C26.4964 9.5533 25.1256 6.24882 22.6884 3.81163C20.2512 1.37445 16.9467 0.00363977 13.5 0ZM13.5 24C11.3244 24 9.19767 23.3549 7.38873 22.1462C5.57979 20.9375 4.16989 19.2195 3.33733 17.2095C2.50477 15.1995 2.28693 12.9878 2.71137 10.854C3.13581 8.72022 4.18345 6.7602 5.72183 5.22183C7.26021 3.68345 9.22022 2.6358 11.354 2.21136C13.4878 1.78692 15.6995 2.00476 17.7095 2.83733C19.7195 3.66989 21.4375 5.07979 22.6462 6.88873C23.8549 8.69767 24.5 10.8244 24.5 13C24.4967 15.9164 23.3367 18.7123 21.2745 20.7745C19.2123 22.8367 16.4164 23.9967 13.5 24ZM12.5 14V7C12.5 6.73478 12.6054 6.48043 12.7929 6.29289C12.9804 6.10536 13.2348 6 13.5 6C13.7652 6 14.0196 6.10536 14.2071 6.29289C14.3946 6.48043 14.5 6.73478 14.5 7V14C14.5 14.2652 14.3946 14.5196 14.2071 14.7071C14.0196 14.8946 13.7652 15 13.5 15C13.2348 15 12.9804 14.8946 12.7929 14.7071C12.6054 14.5196 12.5 14.2652 12.5 14ZM15 18.5C15 18.7967 14.912 19.0867 14.7472 19.3334C14.5824 19.58 14.3481 19.7723 14.074 19.8858C13.7999 19.9993 13.4983 20.0291 13.2074 19.9712C12.9164 19.9133 12.6491 19.7704 12.4393 19.5607C12.2296 19.3509 12.0867 19.0836 12.0288 18.7926C11.971 18.5017 12.0007 18.2001 12.1142 17.926C12.2277 17.6519 12.42 17.4176 12.6667 17.2528C12.9133 17.088 13.2033 17 13.5 17C13.8978 17 14.2794 17.158 14.5607 17.4393C14.842 17.7206 15 18.1022 15 18.5Z'
            fill='#BC9633'
        />
    </svg>
);

export const ProgressErrorCircle = () => (
    <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9964 9.5533 24.6256 6.24882 22.1884 3.81163C19.7512 1.37445 16.4467 0.00363977 13 0ZM17.7075 16.2925C17.8004 16.3854 17.8741 16.4957 17.9244 16.6171C17.9747 16.7385 18.0006 16.8686 18.0006 17C18.0006 17.1314 17.9747 17.2615 17.9244 17.3829C17.8741 17.5043 17.8004 17.6146 17.7075 17.7075C17.6146 17.8004 17.5043 17.8741 17.3829 17.9244C17.2615 17.9747 17.1314 18.0006 17 18.0006C16.8686 18.0006 16.7385 17.9747 16.6171 17.9244C16.4957 17.8741 16.3854 17.8004 16.2925 17.7075L13 14.4137L9.70751 17.7075C9.6146 17.8004 9.5043 17.8741 9.3829 17.9244C9.26151 17.9747 9.1314 18.0006 9.00001 18.0006C8.86861 18.0006 8.7385 17.9747 8.61711 17.9244C8.49572 17.8741 8.38542 17.8004 8.29251 17.7075C8.1996 17.6146 8.12589 17.5043 8.07561 17.3829C8.02533 17.2615 7.99945 17.1314 7.99945 17C7.99945 16.8686 8.02533 16.7385 8.07561 16.6171C8.12589 16.4957 8.1996 16.3854 8.29251 16.2925L11.5863 13L8.29251 9.7075C8.10486 9.51986 7.99945 9.26536 7.99945 9C7.99945 8.73464 8.10486 8.48014 8.29251 8.2925C8.48015 8.10486 8.73464 7.99944 9.00001 7.99944C9.26537 7.99944 9.51987 8.10486 9.70751 8.2925L13 11.5863L16.2925 8.2925C16.3854 8.19959 16.4957 8.12589 16.6171 8.07561C16.7385 8.02532 16.8686 7.99944 17 7.99944C17.1314 7.99944 17.2615 8.02532 17.3829 8.07561C17.5043 8.12589 17.6146 8.19959 17.7075 8.2925C17.8004 8.38541 17.8741 8.49571 17.9244 8.6171C17.9747 8.7385 18.0006 8.8686 18.0006 9C18.0006 9.1314 17.9747 9.2615 17.9244 9.3829C17.8741 9.50429 17.8004 9.61459 17.7075 9.7075L14.4138 13L17.7075 16.2925Z'
            fill='#C44744'
        />
    </svg>
);
