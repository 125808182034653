import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { ALLOTED_PLOT_DETAILS } from '../Allotment.const';
import GenericSelectorWithSearch from '../../../../../components/moduleCommonFormik/GenericSelectorWithSearch';
import { CONSTANT_DATA_FIELD } from '../../../../../utils/constants';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericCommonTextField from '../../../../../components/moduleCommonFormik/GenericCommonTextField.component';

const AllotedPlotDetails = ({ isFieldDisable, setIsSamePlot, plotData = [] }: any) => {
    const { values, setValues } = useFormikContext<any>();
    const [dropdownData, setDropdownData] = useState<any>({
        allotedPlotNo: [],
        allotedPhase: [],
        allotedPlotType: [],
        allotedEstateName: []
    });

    const plotDetails = [
        {
            formData: [
                {
                    title: 'Plot Rate (INR/Sq.m.)',
                    field: 'allotedPlotRate',
                    type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                    showInr: true,
                    isDisabled: true
                },
                {
                    title: 'Plot Area (Sq.m.)',
                    field: 'allotedPlotArea',
                    type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                    isDisabled: true
                }
            ]
        }
    ];
    const onChangeDropdown = async (e: any, field: string) => {
        if (!e.value && field === 'allotedEstateName') {
            const allotedEstateNames: any[] = [];
            plotData.forEach((plot: any) => {
                const obj = { code: plot.allotedEstateName, name: plot.allotedEstateName };
                if (!allotedEstateNames.find(obj => obj.code === plot.allotedEstateName)) allotedEstateNames.push(obj);
            });

            setDropdownData({ ...dropdownData, allotedPlotNo: [], allotedPhase: [], allotedPlotType: [], allotedEstateName: allotedEstateNames });
            setValues({ ...values, allotedEstateName: null, allotedPhase: null, allotedPlotNo: null, allotedPlotType: null, allotedPlotId: null });
        }

        if (!e.value && field === 'allotedPhase') {
            setDropdownData({ ...dropdownData, allotedPlotNo: [], allotedPlotType: [] });
            setValues({ ...values, allotedPhase: null, allotedPlotNo: null, allotedPlotType: null, allotedPlotId: null });
        }

        if (!e.value && field === 'allotedPlotNo') {
            setDropdownData({ ...dropdownData, allotedPlotType: [] });
            setValues({ ...values, allotedPlotNo: null, allotedPlotType: null });
        }

        if (!e.value) return;

        const valueObj = { code: e.value, name: e.value };
        if (field === 'allotedEstateName') {
            let data = plotData.filter((plot: any) => {
                return plot.allotedEstateName === e.value;
            });
            const options: any[] = [];
            data.forEach((item: any) => {
                const obj = { code: item.allotedPhase, name: item.allotedPhase };
                if (!options.find(obj => obj.code === item.allotedPhase)) options.push(obj);
            });

            setDropdownData({ ...dropdownData, allotedPhase: options, allotedPlotNo: [], allotedPlotType: [] });
            setValues({ ...values, allotedEstateName: valueObj, allotedPhase: null, allotedPlotNo: null, allotedPlotType: null, allotedPlotId: null });
        } else if (field === 'allotedPhase') {
            let data = plotData.filter((plot: any) => {
                return plot.allotedPhase === e.value && plot.allotedEstateName === values.allotedEstateName.code;
            });
            const options: any[] = [];
            data.forEach((item: any) => {
                const obj = { code: item.allotedPlotNo, name: item.allotedPlotNo };
                if (!options.find(obj => obj.code === item.allotedPlotNo)) options.push(obj);
            });
            setDropdownData({ ...dropdownData, allotedPlotNo: options, allotedPlotType: [] });
            setValues({ ...values, allotedPhase: valueObj, allotedPlotNo: null, allotedPlotType: null, allotedPlotId: null });
        } else if (field === 'allotedPlotNo') {
            let data = plotData.filter((plot: any) => plot.allotedPlotNo === e.value && plot.allotedEstateName === values.allotedEstateName.code && plot.allotedPhase === values.allotedPhase.code);
            const options: any[] = [];
            let obj;
            data.forEach((item: any) => {
                obj = { code: item.allotedPlotType, name: item.allotedPlotType };
                if (!options.find(obj => obj.code === item.allotedPlotType)) options.push(obj);
            });
            setDropdownData({ ...dropdownData, allotedPlotType: options });
            setValues({
                ...values,
                allotedPlotNo: valueObj,
                allotedPlotType: obj,
                allotedPlotId: data[0]?.allotedUuid,
                allotedPlotRate: data[0]?.allotedPlotRate,
                allotedPlotArea: data[0].allotedPlotArea
            });
        }
        // else if (field === 'allotedPlotType') {
        //     let data = plotData.find((plot: any) => plot.allotedPlotType === e.value);
        //     setValues({ ...values, allotedPlotType: valueObj });
        // }
        setIsSamePlot && setIsSamePlot(false);
    };

    useEffect(() => {
        if ((values?.estateApplicationId && values?.entityRefDetailsId) || isFieldDisable) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFieldDisable]);

    console.log('Values', values);

    useEffect(() => {
        const allotedEstateNames: any[] = [];

        if (plotData?.length === 1) {
            let onePlot = plotData[0];
            onePlot = {
                allotedEstateName: { code: onePlot.allotedEstateName, name: onePlot.allotedEstateName },
                allotedPhase: { code: onePlot.allotedPhase, name: onePlot.allotedPhase },
                allotedPlotNo: { code: onePlot.allotedPlotNo, name: onePlot.allotedPlotNo },
                allotedPlotType: { code: onePlot.allotedPlotType, name: onePlot.allotedPlotType },
                allotedPlotId: onePlot.allotedUuid,
                allotedPlotRate: onePlot.allotedPlotRate,
                allotedPlotArea: onePlot.allotedPlotArea
            };
            setValues({ ...values, ...onePlot });
            return;
        }

        plotData.forEach((plot: any) => {
            const obj = { code: plot.allotedEstateName, name: plot.allotedEstateName };
            if (!allotedEstateNames.find(obj => obj.code === plot.allotedEstateName)) allotedEstateNames.push(obj);
        });

        setDropdownData({
            allotedPlotNo: [],
            allotedPhase: [],
            allotedPlotType: [],
            allotedEstateName: allotedEstateNames
        });
    }, [plotData]);

    return (
        <div className='row d-flex m-0'>
            {ALLOTED_PLOT_DETAILS[0]?.formData.map((formField: any, index: number) => {
                return (
                    <div className={`py-3 col-sm-6`} key={index}>
                        <GenericSelectorWithSearch
                            field={formField.field}
                            title={formField.title}
                            isRequired={formField.isRequired}
                            isDisabled={formField.isDisabled || isFieldDisable}
                            dropdownOptions={dropdownData}
                            customHandleChange={onChangeDropdown}
                        />
                    </div>
                );
            })}
            {plotDetails[0]?.formData.map((val: any, index: number) => (
                <div className={`py-3 col-sm-6`} key={index}>
                    <GenericCommonTextField {...val} />
                </div>
            ))}
        </div>
    );
};

export default AllotedPlotDetails;
