import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { FormikProvider, useFormik } from 'formik';
import { Card } from 'react-bootstrap';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { deedsColumns, downloadParam, orderColumns, otherDocsColumn, searchParam } from '../UserManagement.const';
import { useLocation, useNavigate } from 'react-router-dom';
import { cross, warning } from '../../../../utils/Logo';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { devUrl, endpoints, estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { dateToEpoch, dateToEpochWithTime, epochToDate, getFirstErrorKey } from '../../../../utils/utils';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import { useTranslation } from 'react-i18next';
import { DOCUMENT_DOWNLOAD_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';

export default function DocumentDownloads() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [orderRowData, setOrderRowData] = useState<any>([]);
    const [deedRowData, setDeedRowData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalData, setModalData] = useState<any>(null);
    const [otherDocsRowData, setOtherDocsRowData] = useState<any>([]);

    const location: any = useLocation();
    const plotId = location?.state?.plotId || null;
    const plotData = location?.state?.plotData || null;
    const navigate = useNavigate();
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    const { t } = useTranslation();
    const [dropdownDataPlot, setDropdownDataPlot] = useState<any>({
        plotNo: [],
        phase: [],
        estate: []
    });

    const [searchPlotId, setSearchPlotId] = useState<any>(plotId);

    useEffect(() => {
        let plotNoDropdown: any = [],
            phaseDropdown: any = [],
            estateDropdown: any = [];
        if (plotData.length) {
            plotNoDropdown = plotData?.map((val: any) => ({ name: val?.plotDetails?.plotNo, code: val?.plotDetails?.plotNo }));
            phaseDropdown = plotData?.map((val: any) => ({ name: val?.plotDetails?.phase, code: val?.plotDetails?.phase }));
            estateDropdown = plotData?.map((val: any) => ({ name: val?.plotDetails?.estateDetails?.estateName, code: val?.plotDetails?.estateDetails?.estateName }));
        }
        setDropdownDataPlot({
            plotNo: plotNoDropdown,
            phase: phaseDropdown,
            estate: estateDropdown
        });
    }, [plotData]);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: DOCUMENT_DOWNLOAD_VALIDATION
    });
    const { values, setValues, setFieldTouched, errors } = formik;

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                if (searchPlotId) {
                    const { data: allDocuments } = await POST(endpoints.dashboardDocumentSearch + `?entityIds=${entityId}&plotIds=${searchPlotId}`, { RequestInfo: requestInfo() });

                    const { data: otherDocuments } = await POST(endpoints.otherDocumentSearch + `?entityIds=${entityId}&plotIds=${searchPlotId}`, { RequestInfo: requestInfo() });
                    const tempOtherDocsColumn = otherDocuments?.documents?.length ? otherDocuments?.documents?.map((val: any) => ({ ...val, uploadedDate: val?.uploadedDate?.split(' ')[0] })) : [];
                    setOtherDocsRowData(tempOtherDocsColumn);

                    const { data: plotDetails } = await POST(estateEndpoints.plotSearch + `?plotUuids=${searchPlotId}`, { RequestInfo: requestInfo() });
                    setValues({
                        ...values,
                        plotNo: { name: plotDetails?.plots[0].plotNo, code: plotDetails?.plots[0].plotNo },
                        estate: { name: plotDetails?.plots[0].estateDetails.estateName, code: plotDetails?.plots[0].estateDetails.estateName },
                        phase: { name: plotDetails?.plots[0].phase, code: plotDetails?.plots[0].phase }
                    });

                    // const {
                    //     data: { deedDetails }
                    // } = await POST(endpoints.leaseDeedSearch + `?entityIds=${entityId}&plotIds=${searchPlotId}`, { RequestInfo: requestInfo() });
                    let tempDeedData: any = [];
                    const filteredDeedDetails = allDocuments?.deedDetails?.filter((val: any) => val.applicationStatus !== 'PENDING');
                    filteredDeedDetails?.map((val: any, index: number) => {
                        const tempDeedDocument = val?.registeredIndentureRefId
                            ? [
                                  {
                                      indentureType: t(val?.deedType),
                                      document: `Executed Indenture - ${t(val.deedType)}`,
                                      date: val?.deedExecutionDate?.split(' ')[0],
                                      //   serialNo: +index * 2 + 1,
                                      docRefId: val?.executedIndentureRefId,
                                      sortingTime: dateToEpochWithTime(val?.deedExecutionDate)
                                  },
                                  {
                                      indentureType: t(val?.deedType),
                                      document: `Registered Indenture - ${t(val.deedType)}`,
                                      date: val?.deedRegisterDate?.split(' ')[0],
                                      //   serialNo: +index * 2 + 2,
                                      docRefId: val?.registeredIndentureRefId,
                                      sortingTime: dateToEpochWithTime(val?.deedRegisterDate)
                                  }
                              ]
                            : [
                                  {
                                      indentureType: t(val?.deedType),
                                      document: `Executed Indenture - ${t(val.deedType)}`,
                                      date: val?.deedExecutionDate?.split(' ')[0],
                                      //   serialNo: +index + 1,
                                      docRefId: val?.executedIndentureRefId,
                                      sortingTime: dateToEpochWithTime(val?.deedExecutionDate)
                                  }
                              ];
                        tempDeedData = [...tempDeedData, ...tempDeedDocument];
                    });

                    const sortedDeedData = [...tempDeedData].sort((a, b) => a.sortingTime - b.sortingTime).reverse();
                    const indexedDeedData = sortedDeedData?.map((val: any, index: number) => ({ ...val, serialNo: index + 1 }));
                    setDeedRowData(indexedDeedData);

                    // const { data: allotmentDocument } = await POST(endpoints.allotmentDetailsSearch + `?plotIds=${searchPlotId}&entityIds=${entityId}`, { RequestInfo: requestInfo() });
                    let tempOrderData: any = [];
                    allDocuments?.allotmentDetails?.map((val: any) => {
                        const tempOrderDocument = {
                            orderType: val?.documents[0]?.documentTypeDisplayName,
                            orderNo: val?.allotmentId,
                            date: epochToDate(val?.documents[0]?.auditDetails?.createdTime),
                            // serialNo: index + 1,
                            docRefId: val?.documents[0]?.docReferenceId,
                            sortingTime: val?.documents[0]?.auditDetails?.createdTime
                        };
                        tempOrderData.push(tempOrderDocument);
                    });

                    let temBpaData: any = [];
                    allDocuments?.bpaDocs?.map((val: any) => {
                        const tempBpaDocument = {
                            orderType: val?.documentType,
                            orderNo: val?.applicationNumber,
                            date: epochToDate(val?.createdTime),
                            // serialNo: index + 1,
                            docRefId: val?.fileStoreId,
                            sortingTime: val?.createdTime
                        };
                        temBpaData.push(tempBpaDocument);
                    });

                    const combinedData = [...tempOrderData, ...temBpaData];
                    const sortedData = [...combinedData].sort((a, b) => a.sortingTime - b.sortingTime).reverse();

                    const indexedData = sortedData?.map((val: any, index: any) => ({ ...val, serialNo: index + 1 }));
                    setOrderRowData(indexedData);
                }
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [searchPlotId]);

    const onReset = () => {
        setValues({ ...values, plotNo: '', estate: '', phase: '' });
    };

    const onSearch = async () => {
        try {
            if (!values?.estate?.name && !values?.phase?.name && !values?.plotNo?.name) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: 'Please fill in all the required fields', type: 'error', img: cross, reUpload: 'OK' });
                return;
            }
            setIsLoading(true);
            const { data: plotIdData } = await POST(
                estateEndpoints.plotSearch +
                    `?estateNames=${encodeURIComponent(values?.estate?.name)}&phase=${encodeURIComponent(values?.phase?.name)}&plotNos=${encodeURIComponent(values?.plotNo?.name)}`,
                {
                    RequestInfo: requestInfo()
                }
            );
            if (plotIdData?.plots?.[0]?.uuid !== searchPlotId) {
                setOrderRowData([]);
                setDeedRowData([]);
                setOtherDocsRowData([]);
                setSearchPlotId(plotIdData?.plots[0]?.uuid);
            }
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onResetDownload = () => {
        setValues({ ...values, payment: '', fromDate: '', toDate: '' });
    };

    const onDownload = async () => {
        try {
            await DOCUMENT_DOWNLOAD_VALIDATION.validate(values, { abortEarly: false, context: values });
            try {
                const fromDate = dateToEpoch(values.fromDate);
                const toDate = dateToEpoch(values.toDate);
                setIsLoading(true);

                const { data: paymentDetails } = await POST(endpoints.paymentDetailsSearch + `?entityIds=${entityId}&plotIds=${plotId}&fromDate=${fromDate}&toDate=${toDate}`, {
                    RequestInfo: requestInfo()
                });

                if (paymentDetails?.payments?.length) {
                    const refIds = Array.from(new Set(paymentDetails?.payments?.map((val: any) => val.invoiceRefId))).join(',');
                    const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${refIds}`;
                    window.open(downloadFileUrl);
                } else {
                    setModalDetails({ show: true, title: 'Warning!', para: 'No payment receipts found for the selected period.', img: warning, type: 'warning', reUpload: 'OK' });
                }

                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: 'Please fill in the required details', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/');
    };

    const onRowClick = (row: any) => {
        if (row?.docRefId) {
            setIsFilePreviewModal(!isFilePreviewModal);
            setModalData(row);
            setIsPDF(isPDF);
        } else {
            setModalDetails({ show: true, title: 'Error!', para: 'Document data not found', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const customHandleChange = (e: any, field: any) => {
        if (field === 'plotNo') {
            const filteredData = plotData?.filter((val: any) => val?.plotDetails?.plotNo === e?.value);
            setValues((prevValues: any) => ({
                ...prevValues,
                phase: { name: filteredData?.[0]?.plotDetails?.phase, code: filteredData?.[0]?.plotDetails?.phase },
                estate: { name: filteredData?.[0]?.plotDetails?.estateDetails.estateName, code: filteredData?.[0]?.plotDetails?.estateDetails.estateName }
            }));
        }
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={'Downloads'} />
                        <FormikProvider value={formik}>
                            <div className='px-2 mx-1 pe-3'>
                                <Card className='card_border'>
                                    <Card.Body>
                                        {searchParam.map(val => (
                                            <GenericFormCard {...val} dropdownOptions={dropdownDataPlot} customHandleChange={customHandleChange} />
                                        ))}
                                        <div className='d-flex justify-content-end pe-2 pb-3'>
                                            <GenericButtons text={t('RESET', 'Reset')} handleClick={onReset} />
                                            <GenericButtons text={t('SEARCH', 'Search')} handleClick={onSearch} variant='primary' />
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card_border'>
                                    <Card.Body>
                                        <div className='ps-2 py-2 d-flex justify-content-between'>
                                            <div className='formTitle m-0 font-weight-bold'>Deeds</div>
                                        </div>
                                        <div className='ps-2'>
                                            <GenericActionTable columns={deedsColumns} rowData={deedRowData} hideSearchBar={true} itemsPerPage={5} additionalMethod={onRowClick} />
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card_border'>
                                    <Card.Body>
                                        <div className='ps-2 py-2 d-flex justify-content-between'>
                                            <div className='formTitle m-0 font-weight-bold'>Order</div>
                                        </div>
                                        <div className='ps-2'>
                                            <GenericActionTable columns={orderColumns} rowData={orderRowData} hideSearchBar={true} itemsPerPage={5} additionalMethod={onRowClick} />
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card_border'>
                                    <Card.Body>
                                        <div className='ps-2 py-2 d-flex justify-content-between'>
                                            <div className='formTitle m-0 font-weight-bold'>Other Documents</div>
                                        </div>
                                        <div className='ps-2'>
                                            <GenericActionTable
                                                columns={otherDocsColumn}
                                                rowData={otherDocsRowData}
                                                searchBarPlaceholder='Search Document'
                                                itemsPerPage={5}
                                                additionalMethod={onRowClick}
                                            />
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card_border'>
                                    <Card.Body>
                                        {downloadParam.map(val => (
                                            <GenericFormCard {...val} />
                                        ))}
                                        <div className='d-flex justify-content-end pe-2 pb-3'>
                                            <GenericButtons text={'Reset'} handleClick={onResetDownload} />
                                            <GenericButtons text={'Download'} handleClick={onDownload} variant='primary' />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </FormikProvider>
                    </div>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.orderType} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                </>
            )}
        </>
    );
}
