import { useEffect, useState } from 'react';
import { Card, CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EXTENSION_TAB, EXTWORKFLOW_DETAILS } from './Extension.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import Editor from '../../../../components/ContentEditor';
import AllotedPlotDetails from '../Allotment/Workflow/AllotedPlotDetails';
import ApplicantDetailsWorkflow from '../ApplicantDetailsWorkflow';

interface ICorrespondenceProps {
    content?: any;
    setContent?: any;
    extensionData?: any;
    dropdownData?: any;
    designation?: any;
    nextValidAction?: any;
    vacantPlotData?: any;
    dropdownDataExtension?: any;
}

const ExtCorrespondence = (props: ICorrespondenceProps) => {
    const { content, setContent, extensionData, dropdownData, designation, nextValidAction, vacantPlotData, dropdownDataExtension } = props;

    const [openSection, setOpenSection] = useState<string>('userDetails');
    const { t } = useTranslation();
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [updatedWorkFlowDetails, setWorkFlowDetails] = useState<any>(EXTWORKFLOW_DETAILS);

    useEffect(() => {
        if (designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE') {
            setIsDisabled(false);
        }
        const UPDATED_WORKFLOW_DETAILS = EXTWORKFLOW_DETAILS.map((val: any) => ({
            ...val,
            formData: val.formData.map((data: any) => {
                if (designation === 'AM' && nextValidAction?.businessService === 'EST_EXTENSION_TIMELINE_EMPLOYEE') {
                    const { isDisabled, ...newData } = data;
                    return newData;
                }
                return data;
            })
        }));
        setWorkFlowDetails(UPDATED_WORKFLOW_DETAILS);
    }, [designation, nextValidAction]);

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const extensionTab =
        extensionData?.applicationType === 'TIMELINE_EXTENSION' && extensionData?.applicationSubType === 'ALLOTMENT'
            ? EXTENSION_TAB
            : extensionData?.applicationType === 'DEFAULT_TIMELINE_EXTENSION'
            ? [
                  { title: 'A - User Details', field: 'userDetails' },
                  { title: 'B - Plot Details', field: 'plotDetails' },
                  { title: 'C - Default Notice Notes', field: 'defaultNoticeNotes' }
              ]
            : [...EXTENSION_TAB, { title: 'D - Timeline Extension Order Notes', field: 'timelineExtensionOrderNotes' }];

    const renderApplicantDetails = () => {
        return <ApplicantDetailsWorkflow dropdownOptions={dropdownData} />;
    };

    const renderExtDetails = () => {
        return (
            <>
                {updatedWorkFlowDetails.map((val: any) => (
                    <GenericFormCard {...val} dropdownOptions={dropdownDataExtension} />
                ))}
            </>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <AllotedPlotDetails plotData={vacantPlotData} type={'allotedPlot'} isFieldDisable={isDisabled} />
            </>
        );
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1'>
                            <Editor setContent={setContent} content={content} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <CardBody>
                    <div className='row'>
                        {extensionTab.map((val: any, index: number) => {
                            return (
                                <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                    <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                    <label
                                        htmlFor={`${val.field}Form`}
                                        className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                    >
                                        {val.title}
                                    </label>
                                    {val.title === 'A - User Details' && openSection === 'userDetails' && renderApplicantDetails()}
                                    {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                    {val.title === 'C - Timeline Extension Details' && openSection === 'timelineExtensionDetails' && renderExtDetails()}
                                    {((val.title === 'D - Timeline Extension Order Notes' && openSection === 'timelineExtensionOrderNotes') ||
                                        (val.title === 'C - Default Notice Notes' && openSection === 'defaultNoticeNotes')) &&
                                        renderNoteDetails()}
                                </div>
                            );
                        })}
                    </div>
                </CardBody>
            </Card>
        </>
    );
};
export default ExtCorrespondence;
