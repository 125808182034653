import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FormikProvider, useFormik, Form } from 'formik';
import { POST, estateEndpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { PLOT_DETAILS, MASTER_TABLE_COLUMNS, SEARCH_DETAILS, MASTER_TABLE_COLUMNS_VACANT } from './Plotmaster.const';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { PLOT_MASTER_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { check, cross } from '../../../../utils/Logo';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../../../components/portalComponents/Loading.component';
import { estateDropdown } from '../Estate_Master/master.const';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
interface ICorrespondenceProps {
    checkListData?: any;
    noting?: any;
}

const PlotMaster = (props: ICorrespondenceProps) => {
    const [addButton, setAddButton] = useState(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [openIndex, setOpenIndex] = useState<any>(null);
    const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState<boolean>(true);
    const [isEditing, setIsEditing] = useState<any>(false);
    const { t } = useTranslation();
    const [rowData, setRowData] = useState<any>([]);
    const [estateName, setEstateName] = useState<any>([]);
    const [estateID, setEstateID] = useState<string | undefined>(undefined);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [btnText, setBtnText] = useState('');
    const [selectedPlot, setSelectedPlot] = useState<any>([]);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const user = localStorage.getItem('user');
    const designation = user ? String(JSON.parse(user)?.designation) : '';
    const location: any = useLocation();
    const estateNameReturn = location?.state?.estateNameReturn || null;
    const [dropdownData, setDropdownData] = useState<any>(null);
    const userType = user ? String(JSON.parse(user)?.type) : '';
    const routeTo = localStorage.getItem('routeTo') || '';

    const initialData = {
        estateName: '',
        isUpdatable: true
    };

    const handleOnReset = () => {
        setValues(initialData);
        setRowData([]);
        setTouched({});
    };

    useEffect(() => {
        (async () => {
            const dropdownOption = await estateDropdown(userType);
            setDropdownData(dropdownOption || []);
        })();
    }, []);

    const masterTableColumn =
        routeTo !== 'PLOT_REGISTRY'
            ? MASTER_TABLE_COLUMNS_VACANT
            : designation === 'ITM'
            ? [
                  {
                      colName: '',
                      fieldName: 'checkbox',
                      cellstyle: { width: '2%', padding: '13px 0px;' },
                      clsName: 'text-start py-3',
                      id: 1
                  },
                  ...MASTER_TABLE_COLUMNS,
                  {
                      colName: 'Action',
                      type: 'action',
                      clsName: 'fixed-action-column fixed-action-column-bg',
                      cellstyle: { width: '12%' },
                      actions: ['edit', 'view']
                  }
              ]
            : [
                  ...MASTER_TABLE_COLUMNS,
                  {
                      colName: 'Action',
                      type: 'action',
                      clsName: 'fixed-action-column fixed-action-column-bg',
                      cellstyle: { width: '12%' },
                      actions: ['view']
                  }
              ];

    useEffect(() => {
        if (estateNameReturn) {
            if (estateNameReturn) {
                setValues({ ...values, estateName: { code: estateNameReturn, name: estateNameReturn } });
            }
            handleSubmit();
        }
    }, [location.search, dropdownData]);

    // const getPlotType = (plotTypeCode: string) => {
    //     const plotType = dropdownData
    //         ? dropdownData.plotType?.find((val: any) => {
    //               if (val.code === plotTypeCode) {
    //                   return val;
    //               } else {
    //                   return plotTypeCode;
    //               }
    //           })
    //         : plotTypeCode;

    //     return plotType?.name || plotType;
    // };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const queryParams = new URLSearchParams();
            if (values.estateName || estateNameReturn) queryParams.append('estateNames', values.estateName.code || estateNameReturn);

            const { data } = await POST(estateEndpoints.estateSearch + `?${queryParams.toString()}`, { RequestInfo: requestInfo() });
            const estateUUID = data.estateMasterDetails[0]?.uuid;
            setFieldValue('estateUuid', data.estateMasterDetails[0]?.uuid);

            if (estateUUID) {
                setEstateID(estateUUID);
                console.log('dropdownData', dropdownData);

                const plotres = await POST(
                    estateEndpoints.plotSearch +
                        `?${queryParams.toString()}&${routeTo === 'PLOT_REGISTRY' ? 'isOwnerDetailsRequired=true' : 'plotStatus=VACANT'}${routeTo !== 'PLOT_REGISTRY' ? '&isPublic=true' : ''}`,
                    {
                        RequestInfo: requestInfo()
                    }
                );

                if (plotres.data.plots.length === 0) {
                    handleOnReset();
                    setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('NO_PLOT_FOUND', 'No Plots Found'), img: cross, type: 'error', reUpload: 'OK' });
                } else {
                    const modifiedPlots = plotres.data.plots.map((plot: any) => {
                        return {
                            ...plot,
                            id: plot?.uuid,
                            plotAreaSqm: plot.plotAreaSqm.toFixed(2),
                            plotRate: plot.plotRate.toFixed(2),
                            plotType: getDropdownSelectedObject(plot?.plotType, dropdownData?.['plotType']).name,
                            status: plot?.plotDetails?.plotStatus
                        };
                    });
                    setRowData(modifiedPlots);
                }
            } else {
                handleOnReset();
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('NO_PLOT_FOUND', 'No Plots Found'), img: cross, type: 'error', reUpload: 'OK' });
            }
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            let errMsg = error.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const formik = useFormik<any>({
        initialValues: initialData,
        onSubmit: value => {},
        validationSchema: PLOT_MASTER_VALIDATION
    });

    const { values, setValues, setTouched, setFieldValue } = formik;

    useEffect(() => {
        setIsSearchButtonDisabled(!values.estateName.code);
    }, [values.estateName.code]);

    const handleModalClose = (type: string) => {
        setModalDetails({});
    };

    const onView = (status: boolean, data: any) => {
        onEdit(data, true);
    };

    const onEdit = (data: any, isViewOnly?: boolean) => {
        const detailObj: any = rowData.find((item: any) => item.uuid === data.uuid);
        const index: any = rowData.findIndex((item: any) => item.uuid === data.uuid);
        setOpenIndex(index > -1 ? index + 1 : null);
        navigate(`/goa-idc/estate/new-plot-registry`, { state: { data: detailObj, disable: isViewOnly, viewEdit: true } });
        // setAddButton(true);
        // if (!detailObj) return;

        // const formData = {
        //     ...detailObj,
        //     uuid: detailObj.uuid ? detailObj.uuid : null,
        //     isWaterAvailable: detailObj.waterAvailable,
        //     isRoadAvailable: detailObj.roadAvailable,
        //     isElectricityAvailable: detailObj.electricityAvailable,
        //     plotType: { name: detailObj.plotType, code: detailObj.plotType }
        // };

        // setIsActive(detailObj.isActive);
        // setIsEditing(detailObj.uuid);

        // const updatedValues = { ...values, ...formData, isUpdatable: detailObj.isActive };
        // setValues(updatedValues);
    };

    const fetchEstateDropdownData = async () => {
        try {
            const res3 = await POST('/idc-estate-service/plot/_metadata-search', { RequestInfo: requestInfo() });
            const estateNames = res3.data.estateNames.map((estate: any) => ({ name: estate.value, code: estate.code }));
            setEstateName(estateNames);
        } catch (err: any) {
            let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        fetchEstateDropdownData();
        if (rowData.length > 0) {
            window.scrollBy({
                top: 250,
                behavior: 'smooth'
            });
        }
    }, [rowData]);

    const onCheckHandler = (checkedItems: any[], col: any) => {
        setSelectedItems(checkedItems);
        let matchedObjects = checkedItems.map(id => rowData.find((obj: any) => obj.id === id));
        setSelectedPlot(matchedObjects);
        if (checkedItems.length === 1) {
            setBtnText('Split');
        } else setBtnText('Merge');
    };

    const handleOnRedirect = () => {
        navigate(`/goa-idc/estate/plot-registry-workflow?plotUpdate=true&estateName=${values.estateName.name}&estateUuid=${values.estateUuid}`, { state: { data: selectedPlot, sendBack: true } });
    };

    const onAdd = () => {
        navigate(`/goa-idc/estate/new-plot-registry?${values.estateName?.name ? `estateName=${values.estateName.name}` : ''}`);
    };

    const onActionClick = () => {
        navigate('/goa-idc/dashboard');
    };

    console.log(formik, rowData);
    return (
        <>
            <HeaderComponent />
            {isLoading ? <Loading /> : <></>}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
            <FormikProvider value={formik}>
                <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                    <div className='col-sm-12'>
                        <GenericHeader
                            text={routeTo === 'PLOT_REGISTRY' ? t('PLOT_MASTER_REGISTRY', 'Plot Master Registry') : t('VACANT_PLOT_DETAILS', 'Vacant Plot Details')}
                            onActionClick={onActionClick}
                        />
                        <Form>
                            <Card className='card_border'>
                                <Card.Body>
                                    <div className='row gx-3 gy-4 px-3 pt-1'>
                                        <div className='col-sm-4 d-flex flex-column'>
                                            {SEARCH_DETAILS.map((val, id: number) => (
                                                <GenericFormCard {...val} dropdownOptions={{ estateName: estateName }} />
                                            ))}
                                        </div>
                                        <div className='d-flex justify-content-end  container me-3 '>
                                            <GenericButtons text={t('RESET', 'Reset')} disabled={isSearchButtonDisabled} handleClick={handleOnReset} />
                                            <GenericButtons type='button' text={t('SEARCH', 'Search')} variant='primary' disabled={isSearchButtonDisabled} handleClick={handleSubmit} />{' '}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Form>
                        <Card className='card_border'>
                            <Card.Body>
                                <div className='d-flex justify-content-start'>
                                    <h4>
                                        <span style={{ color: '#3A7425', paddingLeft: '25px', fontFamily: 'Inter, sans-serif' }}>
                                            <label>{t('PLOTS', 'Plots')}</label>
                                        </span>
                                    </h4>
                                </div>

                                <div className='employee-creation-container position-relative m-auto px-3 py-3 row'>
                                    {designation === 'ITM' && <GenericButtons text={t('ADD', '+ Add')} handleClick={onAdd} variant='primary' ClassName='estate-add-btn' />}
                                    <OwnershipTable
                                        column={masterTableColumn}
                                        tableRowData={rowData}
                                        additionalMethod={onEdit}
                                        updateOwnershipDataStatus={onView}
                                        searchBarPlaceholder={'Search by Plot No, Phase, Plot Type...'}
                                        showIndex={true}
                                        onCheckHandler={onCheckHandler}
                                        selectedItems={selectedItems}
                                        checkType={`multiCheck`}
                                    />
                                </div>

                                {/* <GenericPopupModal
                                    onClose={() => setAddButton(false)}
                                    secondaryAction={() => {}}
                                    primaryAction={addEstate}
                                    primaryBtnTitle='Save'
                                    isSecondaryBtn={false}
                                    size={'xl'}
                                    isVisible={addButton}
                                    title={`${openIndex ? (isEditing ? 'Edit' : 'Add') : 'Add'} Plot`}
                                    children={
                                        <div className='col-12'>
                                            <Card>
                                                <Card.Body>
                                                    {isEditing ? (
                                                        <div className='col-12 font-inter' style={{ display: 'flex', alignItems: 'center', marginLeft: '13px', fontSize: '14px' }}>
                                                            {isActive ? 'Active :' : 'Inactive :'} &nbsp; &nbsp;
                                                            <div
                                                                style={{
                                                                    width: '60px',
                                                                    height: '30px',
                                                                    backgroundColor: isActive ? ' #45852e' : '#ccc',
                                                                    borderRadius: '15px',
                                                                    position: 'relative',
                                                                    cursor: 'pointer'
                                                                }}
                                                                // onClick={handleToggleChange}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: '26px',
                                                                        height: '26px',
                                                                        backgroundColor: '#fff',
                                                                        borderRadius: '50%',
                                                                        position: 'absolute',
                                                                        top: '2px',
                                                                        left: isActive ? '30px' : '2px',
                                                                        transition: 'left 0.3s ease-in-out'
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {renderEstateDetails()}
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    }
                                /> */}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </FormikProvider>
            {!!btnText && selectedItems.length > 0 && designation === 'ITM' && (
                <footer className='footer fixed-bottom footerBtnPatch'>
                    <div className='d-flex'>
                        <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                            <GenericButtons text={btnText} variant='primary' handleClick={handleOnRedirect} />
                        </div>
                    </div>
                </footer>
            )}
        </>
    );
};

export default PlotMaster;
