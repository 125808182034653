import myData from "./en/locale.json";

export const locale = (new_code: string) => {
  const localeData = myData?.messages;
  let commonLabel = new_code;
  localeData.forEach((val, ind) => {
    if (val?.code === new_code) {
      commonLabel = val?.message;
    }
  });
  return commonLabel;
};

export const getStringResource = (new_code: string) => {
  const localeData = myData?.messages
  let commonLabel = new_code;
  localeData.forEach((val, ind) => {
     if (val?.code === new_code) {
        commonLabel = val?.message;
     }
  })
  return commonLabel;
};
