import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import toolTip from '../assets/logos/tooltip.svg';
import toolTip_white from '../assets/logos/tooltip_white.svg';
import { Link, useNavigate } from 'react-router-dom';

interface ItoolTipProps {
    toolTipDetails?: any;
}

const ToolTipDetails = (props: ItoolTipProps) => {
    const { toolTipDetails } = props;
    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, tooltipRef, triggerRef, visible, ...popperProps } = usePopperTooltip({
        closeOnOutsideClick: true,
        delayHide: 100,
        placement: toolTipDetails?.position,
        trigger: 'hover'
    });

    return (
        <div ref={setTriggerRef} className=' position-relative'>
            <img src={toolTipDetails?.variant?.color === 'white' ? toolTip_white : toolTip} alt='' height={toolTipDetails?.variant?.height || 16} className='ms-2 position-relative' />
            {visible && (
                <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container tooltip-style' })}>
                    <div {...getArrowProps({ className: 'tooltip-arrow popover-arrow' })} />
                    <div className=' tooltip-content text-start mb-2' dangerouslySetInnerHTML={{ __html: toolTipDetails?.content }} />
                    {toolTipDetails?.linkText && (
                        <Link to={toolTipDetails?.linkUrl} target='_blank' className='mb-2 position-fixed bottom-0 text-decoration-underline'>
                            {toolTipDetails?.linkText}
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
};

export default ToolTipDetails;
