import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ModalProps {
    para?: string;
    fontsize?: string;
    type?: string;
}

const ForgotPassword = (props: ModalProps) => {
    const { t, i18n } = useTranslation();
    const { fontsize, para, type } = props;
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    let navigate = useNavigate();

    const handleToggleOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };
    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleTogglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };
    return (
        <>
            <p className={`${fontsize}`}>{para}</p>

            {type !== "forgotPassword" && (
                <>
                    <label className="mt-3">{t("OLD_PASSWORD", "Old Password")}</label>
                    <div className="password-input-group col-6">
                        <Field type={showOldPassword ? t('TEXT',"text") : t('PASSWORD',"password")} className="form-control password-change-input" name="oldPassword" />
                        {showOldPassword ? (
                            <i className="fa fa-eye password-eye-btn" onClick={handleToggleOldPassword} aria-hidden="true"></i>
                        ) : (
                            <i className="fa fa-eye-slash password-eye-btn" aria-hidden="true" onClick={handleToggleOldPassword}></i>
                        )}
                    </div>
                    <ErrorMessage name="oldPassword" component="div" className="text-danger font-12" />
                </>
            )}

            <label className="mt-3">{t("NEW_PASSWORD", "New Password")}</label>
            <div className="password-input-group col-6">
                <Field type={showPassword ? "text" : "password"} className="form-control password-change-input" name="newPassword" />

                {showPassword ? (
                    <i className="fa fa-eye password-eye-btn" onClick={handleTogglePassword} aria-hidden="true"></i>
                ) : (
                    <i className="fa fa-eye-slash password-eye-btn" aria-hidden="true" onClick={handleTogglePassword}></i>
                )}
            </div>
            <ErrorMessage name="newPassword" component="div" className="text-danger font-12" />

            <label className="mt-3">{t("CONFIRM_NEW_PASSWORD", "Confirm New Password")}</label>
            <div className="password-input-group col-6">
                <Field type={showPassword2 ? t('TEXT',"text") : t('PASSWORD',"password")} className="form-control password-change-input" name="confirmNewPassword" />

                {showPassword2 ? (
                    <i className="fa fa-eye password-eye-btn" onClick={handleTogglePassword2} aria-hidden="true"></i>
                ) : (
                    <i className="fa fa-eye-slash password-eye-btn" aria-hidden="true" onClick={handleTogglePassword2}></i>
                )}
            </div>
            <ErrorMessage name="confirmNewPassword" component="div" className="text-danger font-12" />
        </>
    );
};

export default ForgotPassword;
