import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import { FieldArray } from 'formik';
import { TENANT_ID } from '../../../utils/constants';
import {
    PROMOTER_DETAILS1,
    BANK_DETAILS,
    APPLICANT_DETAILS,
    APPLICANT_DETAILS_SEARCH,
    MIGRATION_OTHERS_DETAILS,
    MIGRATION_FORM_STEPS,
    PLOTACTIONTYPE,
    TYPEALLOTMENT,
    TYPETRANSFER,
    TYPEEXTENSION,
    BANK_NOC_DOC,
    MIGRATION_SUBLEASE,
    IS_EXTENSION,
    MORTGAGE_DETAILS,
    mortgageMigrationRequest,
    MORTGAGE_BANK_DETAILS,
    initialValues,
    SUB_LEASE_FEE_PAYMENT,
    MIGRATION_WATER_CONNCETION,
    WATER_CONNECTION_FIELDS,
    migrationWaterConnection
} from './Migration.const';
import ScrollToTopButton from '../../../components/ScrollToTop';
import SideCard from '../../../components/moduleCommonFormik/SideCardGenericComponent';
import HeaderComponent from '../../../components/header/Header.component';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../../../components/UI components/Buttons/GenericButtons';
import { SubLease } from './SubLease';
import { POST, devUrl, endpoints, requestInfo } from '../../../services/modulesServices/userManagement.api';
import GenericBackButton from '../../../components/UI components/Buttons/GenericBackButton';
import { MIGRATION_FORM_VALIDATION, OWNERSHIP_VALIDATION } from '../../../utils/formikValidation/FormikValidation';
import GenericHeader from '../../../components/moduleCommonFormik/GenericHeader';
import GenericOwnerShipDetails from '../../../components/Ownership/GenericOwnership';
import GenericFooterComponent from '../../../components/moduleCommonFormik/GenericFooter.component';
import { dropdownDataOptions, getFormatedOwnershipDetails, getInstallmentScheduleTable, getMigrationData, migrationPayload, populateMigrationData } from './Migration.controller';
import { check, cross } from '../../../utils/Logo';
import StatusAlertPopup from '../../../components/moduleCommonFormik/StatusAlertPopup';
import PlotDetails from './pages/PlotDetails';
import DeleteIcon from '../../../assets/logos/delete.svg';
import ConstructionLicenseDetails from './pages/ConstructionLicenseDetails';
import { PaymentDetails } from './pages/PaymentDetails';
import { EnterpriseDetails } from './pages/EnterpriseDetails';
import { getFirstErrorKey } from '../../../utils/utils';
import { SublLeasePayment } from './pages/SubLeasePayment';
import { getFormatedNICDetails } from '../EstateManagement/Allotment/Allotment.controller';
import Loading from '../../../components/portalComponents/Loading.component';
import { handleUpdates } from '../UserManagement/UserMangement.controller';

interface DropdownData {
    plotNo: string[];
    phase: string[];
    plotType: string[];
    estateName: string[];
}

export default function MigrationForm() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [documents, setDocuments] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [lastStep, setLastStep] = useState<boolean>(false);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [visited, setVisited] = useState(false);
    // const [initialFormikData, setInitialFormikData] = useState<any>();
    const [showCompletionOrderTab, setShowCompletionOrderTab] = useState<boolean>(false);
    const [searchApplicationResponse, setSearchApplicationResponse] = useState<any>([]);
    const [paymentDisableCheck, setPaymentDisableCheck] = useState<any>(null);
    const [mortgageDropdownData, setMortgageDropdownData] = useState<any>(null);
    const [finalOwnerShipDetails, setFinalOwnerShipDetails] = useState({
        applicantDetails: []
    });
    const [applicationDetails, setApplicationDetails] = useState<any>(APPLICANT_DETAILS);
    const [otherDetailIfscData, setOtherDetailIfscData] = useState<any>(null);
    const [editMigrationResponse, setEditMigrationResponse] = useState<any>({});

    const onOwnershipDetailsChange = (key: string, value: any) => {
        setFinalOwnerShipDetails({ ...finalOwnerShipDetails, [key]: value });
    };

    const [dropdownDataPlot, setDropdownDataPlot] = useState<DropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });
    const [installmentTableRowData, setInstallmentTableRowData] = useState<any>([]);
    const [showInstallmentTable, setShowInstallmentTable] = useState<boolean>(false);
    const [stepsDetails, setStepsDetails] = useState<any>(MIGRATION_FORM_STEPS);
    const [plotActionType, setPlotActionType] = useState<any>(PLOTACTIONTYPE);
    const [existingSubleaseEntityDetailsResponse, setExistingSubleaseEntityDetailsResponse] = useState<any>([]);
    const [response, setResponse] = useState<any>({});
    const [showPopUp, setShowPopUp] = useState(false);
    const [nicDetails, setNicDetails] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [subleaseNicDetails, setSubleaseNicDetails] = useState<any>({ 0: [] });
    const [ifscData, setIfscData] = useState<any>(null);
    const [compareValues, setCompareValues] = useState<any>(null);

    const [searchParams] = useSearchParams();

    const applicationNumber = searchParams.get('applicationNumber');
    const plotId = searchParams.get('plotId');
    const userId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    const type = JSON.parse(localStorage.getItem('type') || '');
    const entityId = searchParams.get('entityId');
    const isUpdate = searchParams.get('isUpdate') === 'true';

    const formik: any = useFormik<any>({
        initialValues: initialValues,
        onSubmit: value => {},
        validationSchema: !showOwnershipModal ? MIGRATION_FORM_VALIDATION[`page${activeIndex}`] : OWNERSHIP_VALIDATION,
        enableReinitialize: true
    });

    const { values, setValues, setFieldValue, errors, setFieldTouched } = formik;

    const applicationDetailsSearch = isUpdate
        ? APPLICANT_DETAILS_SEARCH?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) }))
        : APPLICANT_DETAILS_SEARCH;

    const initializeMigrationData = async (options: any) => {
        if (options && plotId) {
            try {
                setLoading(true);
                const { data } = await POST(endpoints.searchMigration + `?plotIds=${plotId}&entityIds=${userId ? userId : entityId}`, { RequestInfo: requestInfo() });
                setEditMigrationResponse(data?.estateMigrationData[0]);
                const res = await populateMigrationData(data?.estateMigrationData[0], options);

                setValues((prev: any) => ({
                    ...prev,
                    ...res,
                    isUpdatable: isUpdate ? true : false,
                    searchResponse: data?.estateMigrationData[0]
                }));
                const newOwnership = await getFormatedOwnershipDetails(data?.estateMigrationData[0].applicantDetails.owners, options);
                const newProposed = await getFormatedNICDetails(data?.estateMigrationData[0]?.enterpriseDetails.nicEnterpriseMappings || [], options);
                setOwnershipDetails(newOwnership);
                setControllingOwnershipResponse(data?.estateMigrationData[0].applicantDetails.owners);
                setNicDetails(newProposed);

                if (data?.estateMigrationData[0]?.subLeaseDetails) {
                    const subleaseNicDetail = await Promise.all(
                        data?.estateMigrationData[0]?.subLeaseDetails?.map(async (val: any, ind: number) => {
                            const updatedNicDetails = await getFormatedNICDetails(val?.subLeaseEnterpriseDetails?.nicEnterpriseMappings, options);
                            console.log(updatedNicDetails);
                            return {
                                [ind]: updatedNicDetails
                            };
                        })
                    );
                    const nicTableData = subleaseNicDetail?.map((val: any, ind: number) => val[ind]);
                    setSubleaseNicDetails(nicTableData);

                    console.log(nicTableData);
                }

                const installmentScheduleData = data.estateMigrationData[0]?.paymentDetails?.payments?.filter((val: any) => val.paymentType === 'LAND_PREMIUM').slice(1);
                if (installmentScheduleData?.length) {
                    const tempRowData = getInstallmentScheduleTable(installmentScheduleData);
                    setShowInstallmentTable(true);
                    setInstallmentTableRowData(tempRowData);
                }
                setLoading(false);
            } catch (e: any) {
                console.log(e);
                setLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };
    console.log('subllllllllllllllllll', subleaseNicDetails);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await POST('/idc-estate-service/plot/_metadata-search', { RequestInfo: requestInfo() });
                console.log('Plot Data', data);
                setDropdownDataPlot({
                    plotNo: data.plotNoDetails || [],
                    phase: data.phases || [],
                    plotType: data.plotTypes || [],
                    estateName: data.estateNames || []
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const steps = lastStep
            ? [
                  ...MIGRATION_FORM_STEPS,
                  {
                      titleCode: 'SUB_LEASE_DETAILS',
                      name: 'Sub-Lease Details',
                      index: 7,
                      isActive: true
                  }
              ]
            : MIGRATION_FORM_STEPS;
        setStepsDetails(steps);
    }, [lastStep]);

    useEffect(() => {
        dropdownDataOptions()
            .then(dropdownOption => {
                setDropdownData(dropdownOption);
                initializeMigrationData(dropdownOption);
            })
            .catch(error => {});
    }, []);

    const paymentPending = (existingSubleaseEntityDetailsResponse: any) => {
        let step = [...stepsDetails];
        const isSamePlotFlag = existingSubleaseEntityDetailsResponse?.isSamePlotApplicationExist ?? false;
        let subLeaseData = [];

        if (isSamePlotFlag && existingSubleaseEntityDetailsResponse.existingSubleaseEntityDetails.length !== 0) {
            step.splice(
                2,
                4,
                {
                    titleCode: 'SUB_LEASE_DETAILS',
                    name: 'Payment Details',
                    index: 3,
                    isActive: true
                },
                {
                    titleCode: 'SUB_LEASE_DETAILS',
                    name: 'Sub-Lease Details',
                    index: 4,
                    isActive: true
                }
            );
            subLeaseData = existingSubleaseEntityDetailsResponse.existingSubleaseEntityDetails.map((val: any) => ({ ...SUB_LEASE_FEE_PAYMENT, ...val }));
        } else if (isSamePlotFlag && existingSubleaseEntityDetailsResponse.existingSubleaseEntityDetails.length === 0) {
            step.splice(2, 4, {
                titleCode: 'SUB_LEASE_DETAILS',
                name: 'Payment Details',
                index: 3,
                isActive: true
            });
        }
        setValues({
            ...values,
            subLeaseFeePayment: subLeaseData,
            estateApplicationId: existingSubleaseEntityDetailsResponse.entityRefDetails.applicationId,
            entityRefDetailsId: existingSubleaseEntityDetailsResponse.entityRefDetails.uuid,
            isSamePlotApplicationExist: isSamePlotFlag
        });
        setStepsDetails(step);
    };

    const handleSubmit = async () => {
        const payloadBody = migrationPayload(
            values,
            ownershipDetails,
            activeIndex,
            searchApplicationResponse,
            existingSubleaseEntityDetailsResponse,
            nicDetails,
            subleaseNicDetails,
            response,
            editMigrationResponse,
            isUpdate
        );
        let data: any;
        const payload = {
            RequestInfo: requestInfo(),
            estateMigrationData: payloadBody
        };

        console.log('migrationpayload', payloadBody, Object.keys(subleaseNicDetails).length, values?.subLeaseDetailsdata);
        try {
            await MIGRATION_FORM_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });

            if (activeIndex === 3 && (!nicDetails || !nicDetails?.length) && values?.isSamePlotApplicationExist === false) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please add atleast 1 proposed activity', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            // if (activeIndex === 7 && subleaseNicDetails && Object.keys(subleaseNicDetails).length !== values?.subLeaseDetailsdata?.length) {
            //     setModalDetails({ show: true, title: 'Error!', para: 'Please add atleast 1 proposed activity', img: cross, type: 'error', reUpload: 'OK' });
            //     return;
            // }

            try {
                if (!isUpdate && (!values.migrationApplicationNumber || !values.entityUserUuid)) {
                    data = await POST(endpoints.createMigration, payload);
                } else {
                    //if (!(activeIndex === 2 && values.estateApplicationId))
                    data = await POST(endpoints.updateMigration, payload);
                    // if (activeIndex === 2) {
                    //     setExistingSubleaseEntityDetailsResponse(data.data.estateMigrationData[0].existingSubleaseEntityDetails);
                    //     // data.data.estateMigrationData[0].entityRefDetails.paymentPending && setIsPaymentPending(true);
                    //     paymentPending(data.data.estateMigrationData[0]);
                    // }
                }
                setResponse(data.data.estateMigrationData[0]);
                onChangeNextIndex();
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
            if (data) {
                data.data.estateMigrationData[0]?.paymentDetails && setPaymentDisableCheck(payload.estateMigrationData.paymentDetails);

                if (activeIndex === 1) {
                    const getSearchApplicant = await POST(endpoints.searchExistingUser + `?uuids=${data.data.estateMigrationData[0].applicantDetails.uuid}`, { RequestInfo: requestInfo() });
                    const initialData = await getMigrationData(getSearchApplicant.data.enterpriseEntity[0], dropdownData);
                    setSearchApplicationResponse(getSearchApplicant?.data.enterpriseEntity[0]);

                    setValues({
                        ...values,
                        ...initialData,
                        migrationApplicationNumber: data.data.estateMigrationData[0].migrationApplicationNumber,
                        estateMigrationDataUuid: data.data.estateMigrationData[0].uuid,
                        entityUserUuid: data.data.estateMigrationData[0].applicantDetails.user.uuid,
                        entityUuid: data.data.estateMigrationData[0].applicantDetails.uuid
                    });

                    const newOwnership = await getFormatedOwnershipDetails(data?.data?.estateMigrationData[0]?.applicantDetails.owners, dropdownData);
                    setOwnershipDetails(newOwnership);
                    setControllingOwnershipResponse(data?.data?.estateMigrationData[0]?.applicantDetails.owners);
                } else if (activeIndex > 2) {
                    let allotmentType =
                        values.plotActionType?.code === 'TRANSFER'
                            ? 'transferMigrationRequest'
                            : values.plotActionType?.code === 'EXTENSION'
                            ? 'extensionMigrationRequest'
                            : values.plotActionType?.code === 'ALLOTMENT'
                            ? 'allotmentMigrationRequest'
                            : '';

                    setValues({
                        ...values,
                        enterpriseDetailsUuid: data?.data?.estateMigrationData[0]?.enterpriseDetails?.uuid,
                        allotmentUuid: data?.data?.estateMigrationData[0]?.otherDetails?.[`${allotmentType}`]?.allotmentRequest?.uuid,
                        deedAppNo: data?.data?.estateMigrationData[0]?.otherDetails?.[`${allotmentType}`]?.deedMigrationRequest?.applicationNumber,
                        transferDeedAppNo: data?.data?.estateMigrationData[0]?.otherDetails?.transferMigrationRequest?.leaseDeedMigrationRequestForTransfer?.applicationNumber,
                        obpasDetailsUuid: data?.data?.estateMigrationData[0]?.obpasDetails?.id,
                        plotId: data?.data?.estateMigrationData[0]?.plotId
                    });
                }
                if (activeIndex !== stepsDetails.length) {
                    toast('Action successful', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                }
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            console.log('error', validationError);
        }
    };

    useEffect(() => {
        if (values.deedAppNo || isUpdate) {
            const updatedPlotActionType = plotActionType.map((item: any) => ({
                ...item,
                formData: item.formData.map((formItem: any) => ({
                    ...formItem,
                    isDisabled: true
                }))
            }));
            setPlotActionType(updatedPlotActionType);
        }
    }, [values.deedAppNo]);

    const downloadFile = async () => {
        try {
            const fileStoreIdsResponse = await POST(endpoints.migrationDocumentSearch + `?plotId=${values?.plotId}&entityId=${values?.entityUuid}`, { RequestInfo: requestInfo() });
            const fileStoreIds = fileStoreIdsResponse?.data?.documentReferenceIds?.join();
            console.log('filestoreIds', fileStoreIds);
            const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${fileStoreIds}`;
            window.open(downloadFileUrl);
            navigate('/goa-idc/dashboard');
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string) => {
        if (type === 'success') {
            downloadFile();
        }
        setModalDetails({});
    };

    useEffect(() => {
        if (values.sublease === true) {
            setLastStep(true);
        } else setLastStep(false);
    }, [values.sublease]);

    useEffect(() => {
        if (values?.executedLeaseDeed || values?.registeredLaseDeed) {
            setShowCompletionOrderTab(true);
        } else {
            setShowCompletionOrderTab(false);
        }
    }, [values.executedLeaseDeed, values.registeredLaseDeed]);

    useEffect(() => {
        if (values?.plotType?.code === 'Commercial') {
            setValues({ ...values, enterpriseCategory: { code: 'Commercial', name: 'Commercial Undertaking' } });
        } else if (values?.plotType?.code === 'Industrial') {
            setValues({ ...values, enterpriseCategory: { code: 'Industrial', name: 'Industrial Undertaking' } });
        }
    }, [values.plotType]);

    const [time, setTime] = useState<any>(0);

    const onClickSearchCall = async () => {
        try {
            const queryParams = new URLSearchParams();
            if (values.applicantName) queryParams.append('entityName', values.applicantName);
            if (values.emailId) queryParams.append('email', values.emailId);
            if (values.phoneNumber) queryParams.append('phoneNumber', values.phoneNumber);
            if (values.applicantPanNo) queryParams.append('panNumber', values.applicantPanNo);

            if (values.applicantName || values.emailId || values.phoneNumber || values.applicantPanNo) {
                const { data } = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&${queryParams.toString()}`, { RequestInfo: requestInfo() });
                if (data.enterpriseEntity.length > 0) {
                    const res = await getMigrationData(data.enterpriseEntity[0], dropdownData);
                    setSearchApplicationResponse(data.enterpriseEntity[0]);

                    setValues((prev: any) => ({
                        ...prev,
                        ...res
                    }));
                    const newOwnership = await getFormatedOwnershipDetails(data?.enterpriseEntity[0].owners, dropdownData);
                    setOwnershipDetails(newOwnership);
                    setControllingOwnershipResponse(data?.enterpriseEntity[0].owners);
                } else {
                    setModalDetails({ show: true, title: 'Error!', para: 'Application not found', img: cross, type: 'error', reUpload: 'OK' });
                }
            } else {
                setModalDetails({ show: true, title: 'Error!', para: 'Please enter search parameters', img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        if (values?.category?.code) {
            if (values?.category?.code === 'PROP_FIRM') {
                if (values?.ownership?.length || ownershipDetails?.length) {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.ownership[0]?.ownershipName || ownershipDetails[0]?.name }));
                } else {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: '' }));
                }
            } else {
                setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.applicantName }));
            }
        }
    }, [values?.category?.code, values?.ownership, values?.applicantName, ownershipDetails]);

    useEffect(() => {
        const updatedDetails = handleUpdates(values, applicationDetails, APPLICANT_DETAILS, 'Address of the Enterprise', 'Mailing/Correspondence address of the Enterprise');
        setApplicationDetails(updatedDetails);
    }, [values?.isPermanentPincodeDetailsPresent, values?.isPincodeDetailsPresent, values?.copyOnCheck]);

    const renderApplicantDetails = () => {
        const handleSearchIFSC = (e: any) => {
            if (e.length === 11) {
                if (time) clearTimeout(time);
                setTime(
                    setTimeout(async () => {
                        try {
                            const { data } = await POST(`${endpoints.getBankDetails}?ifsc=${e}`, { RequestInfo: requestInfo() });
                            setIfscData(data);
                            setValues({
                                ...values,
                                bankName: data?.bank || '',
                                branchAddress: data?.address || '',
                                ifscCode: e
                            });
                        } catch (error) {
                            toast(t('Failed to retrieve bank details, Please check IFSC code'), {
                                position: toast.POSITION.TOP_RIGHT,
                                className: 'foo-bar',
                                type: 'warning'
                            });
                            setValues({
                                ...values,
                                bankName: '',
                                branchAddress: '',
                                ifscCode: e
                            });
                        }
                    }, 800)
                );
            } else {
                setValues({
                    ...values,
                    bankName: '',
                    branchAddress: '',
                    ifscCode: e
                });
            }
        };

        const NEW_BANK_DETAILS =
            ifscData !== null && ifscData?.address === '' && ifscData?.bank === ''
                ? BANK_DETAILS[0]?.formData.map((val: any, index: number) => {
                      if (index === 3 || index === 4) {
                          return { ...val, isDisabled: false };
                      }
                      return val;
                  })
                : BANK_DETAILS[0]?.formData;
        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            {applicationDetailsSearch?.map(val => (
                                <GenericFormCard {...val} dropdownOptions={dropdownData} />
                            ))}
                            {values?.isUpdatable ? (
                                <div className='d-flex justify-content-end mb-5'>
                                    <GenericButtons text={'Search Application'} variant='primary' handleClick={onClickSearchCall} />
                                </div>
                            ) : (
                                <></>
                            )}

                            {applicationDetails?.map((val: any) => (
                                <GenericFormCard {...val} dropdownOptions={dropdownData} />
                            ))}
                        </Card.Body>
                    </Card>
                </>
                <div>
                    <GenericOwnerShipDetails
                        showOwnershipModal={showOwnershipModal}
                        setShowOwnershipModal={setShowOwnershipModal}
                        changeOwnershipDetails={(values: any) => onOwnershipDetailsChange('applicantDetails', values)}
                        values={values}
                        dropdownData={dropdownData}
                        setControllingOwnershipResponse={setControllingOwnershipResponse}
                        controllingOwnershipResponse={controllingOwnershipResponse}
                        allOwnerShipDetails={finalOwnerShipDetails}
                        setOwnershipDetails={setOwnershipDetails}
                        ownershipDetails={ownershipDetails}
                        isDisabled={!values.isUpdatable}
                        skipFullOwnershipPercent={values?.category?.code === 'LTD_COMPANY'}
                    />
                </div>
                <Card className='card_border'>
                    <Card.Body>
                        <div className='ps-2 py-2 d-flex justify-content-between'>
                            <div className='formTitle m-0'>{'Bank Account Details'}</div>
                        </div>
                        <div className='row d-flex m-0'>
                            {NEW_BANK_DETAILS.map((formField: any, index: number) => {
                                return (
                                    <div className={formField.class ? `py-3 ${formField.class}` : 'py-3 col-sm-6'} key={index}>
                                        <label htmlFor={formField.field} className='pb-2 file-form-label'>
                                            {formField.title}
                                            {formField.isRequired && <span className='mandatory-indicator'> *</span>}
                                        </label>
                                        <Field
                                            name={formField.field}
                                            type={'text'}
                                            id={formField.field}
                                            className={formField.class ? `form-control was-validated ${formField.class}` : 'form-control was-validated'}
                                            onChange={(e: any) => {
                                                const newValue =
                                                    formField.type === 'number'
                                                        ? e.target.value.replace(/[^0-9.-]/g, '')
                                                        : formField.field === 'ifscCode'
                                                        ? e.target.value.toUpperCase()
                                                        : e.target.value;
                                                setFieldValue(formField.field, newValue);
                                                formField.field === 'ifscCode' && handleSearchIFSC(newValue);
                                            }}
                                            value={_.get(values, formField.field)}
                                            disabled={formField.isDisabled || !values.isUpdatable}
                                        />
                                        <ErrorMessage name={formField.field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                                    </div>
                                );
                            })}
                        </div>
                    </Card.Body>
                </Card>
                <>
                    {PROMOTER_DETAILS1.map(val => (
                        <Card className='card_border'>
                            <Card.Body>
                                <GenericFormCard module='usermanagement' {...val} dropdownOptions={dropdownData} setDocuments={setDocuments} documents={documents} />
                            </Card.Body>
                        </Card>
                    ))}
                </>
            </div>
        );
    };

    useEffect(() => {
        const handleDateUpdate = (dateString: string) => {
            if (dateString) {
                const date = new Date(dateString);
                date.setDate(date.getDate() + 1);
                return date.toISOString().slice(0, 10);
            }
            return null;
        };

        const updatedEndDate = handleDateUpdate(values?.leaseTermEndDate);
        const updatedEndDateTransfer = handleDateUpdate(values?.leaseTermEndDateTransfer);

        setValues({
            ...values,
            leaseTermStartDateExtension: updatedEndDate || updatedEndDateTransfer || ''
        });
    }, [values.leaseTermEndDate, values.leaseTermEndDateTransfer]);

    const mortgageDropdownOptions = [
        { name: 'First charge', code: 'FIRST_CHARGE' },
        { name: 'Second charge', code: 'SECOND_CHARGE' }
    ];

    useEffect(() => {
        let selectedOptions: string[] = [];
        values.mortgageMigrationRequests.forEach((val: any, index: number) => {
            if (val.mortgageType?.code) {
                selectedOptions.push(val.mortgageType?.code.toString().toLowerCase());
            }
        });

        const filteredDropdownData: any = [];
        const allDropDownOptions = JSON.parse(JSON.stringify(mortgageDropdownOptions));
        allDropDownOptions.forEach((option: any) => {
            if (!selectedOptions.includes(option.code.toString().toLowerCase())) {
                filteredDropdownData.push(option);
            }
        });
        const filteredOption = { mortgageType: filteredDropdownData };
        setMortgageDropdownData(filteredOption);
    }, [values]);

    const renderMigrationOtherDetails = () => {
        const handleSearchIFSCMortgage = (e: any, index: number, mortIndex: number) => {
            if (e.length === 11) {
                if (time) clearTimeout(time);
                setTime(
                    setTimeout(async () => {
                        try {
                            const { data } = await POST(`${endpoints.getBankDetails}?ifsc=${e}`, { RequestInfo: requestInfo() });
                            setOtherDetailIfscData(data);
                            setValues({
                                ...values,
                                mortgageMigrationRequests: values.mortgageMigrationRequests.map((mItem: any, ind: number) =>
                                    ind === mortIndex
                                        ? {
                                              ...mItem,
                                              bankMortgageMigrationRequests: mItem.bankMortgageMigrationRequests.map((item: any, i: number) =>
                                                  i === index ? { ...item, bankIFSC: e, bankName: data.bank } : item
                                              )
                                          }
                                        : mItem
                                )
                            });
                        } catch (error) {
                            toast(t('Failed to retrieve bank details, Please check IFSC code'), {
                                position: toast.POSITION.TOP_RIGHT,
                                className: 'foo-bar',
                                type: 'warning'
                            });

                            setValues({
                                ...values,
                                mortgageMigrationRequests: values.mortgageMigrationRequests.map((mItem: any, ind: number) =>
                                    ind === mortIndex
                                        ? {
                                              ...mItem,
                                              bankMortgageMigrationRequests: mItem.bankMortgageMigrationRequests.map((item: any, i: number) =>
                                                  i === index ? { ...item, bankIFSC: e, bankName: '' } : item
                                              )
                                          }
                                        : mItem
                                )
                            });
                        }
                    }, 800)
                );
            } else {
                setValues({
                    ...values,
                    mortgageMigrationRequests: values.mortgageMigrationRequests.map((mItem: any, ind: number) =>
                        ind === mortIndex
                            ? {
                                  ...mItem,
                                  bankMortgageMigrationRequests: mItem.bankMortgageMigrationRequests.map((item: any, i: number) => (i === index ? { ...item, bankIFSC: e, bankName: '' } : item))
                              }
                            : mItem
                    )
                });
            }
        };

        const isExtension = isUpdate ? IS_EXTENSION?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) })) : IS_EXTENSION;
        const migrationOtherDetails = isUpdate
            ? MIGRATION_OTHERS_DETAILS?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) }))
            : MIGRATION_OTHERS_DETAILS;
        const migrationWaterConnectionDetails = isUpdate
            ? MIGRATION_WATER_CONNCETION?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) }))
            : MIGRATION_WATER_CONNCETION;
        const migrationSublease = isUpdate ? MIGRATION_SUBLEASE?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) })) : MIGRATION_SUBLEASE;
        const typeAllotment = isUpdate
            ? TYPEALLOTMENT?.map((val: any) => ({
                  ...val,
                  formData: val?.formData?.map((item: any) => {
                      if (item.field === 'provisionalAllotmentOrderNumber') {
                          return { ...item, isDisabled: true };
                      } else {
                          return { ...item };
                      }
                  })
              }))
            : TYPEALLOTMENT;
        const typeTransfer = isUpdate
            ? TYPETRANSFER?.map((val: any) => ({
                  ...val,
                  formData: val?.formData?.map((item: any) => {
                      if (item?.field === 'transferOrderNumber') {
                          return { ...item, isDisabled: true };
                      } else {
                          return { ...item };
                      }
                  })
              }))
            : TYPETRANSFER;

        const typeExtension = isUpdate
            ? TYPEEXTENSION?.map((val: any) => ({
                  ...val,
                  formData: val?.formData?.map((item: any) => {
                      if (item?.field === 'extensionOrderNumber') {
                          return { ...item, isDisabled: true };
                      } else {
                          return { ...item };
                      }
                  })
              }))
            : TYPEEXTENSION;

        return (
            <>
                <Card className='card_border'>
                    <Card.Body>
                        {plotActionType.map((val: any, _: number) => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}
                        {values.plotActionType?.code === 'ALLOTMENT' ? (
                            <>
                                {typeAllotment.map((val: any, _: number) => (
                                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                        {values.plotActionType?.code === 'TRANSFER' ? (
                            <>
                                {typeTransfer.map((val: any, _: number) => (
                                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </Card.Body>
                </Card>
                <Card className='card_border'>
                    <Card.Body>
                        {isExtension.map((val: any, _: number) => (
                            <GenericFormCard {...val} />
                        ))}
                        {values?.isExtension === true ? (
                            <>
                                {typeExtension.map((val: any, _: number) => (
                                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </Card.Body>
                </Card>
                <Card className='card_border'>
                    <Card.Body>
                        {migrationOtherDetails.map((val: any) => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}
                        {values.mortgageStatus === true ? (
                            <>
                                <FieldArray name='mortgageMigrationRequests'>
                                    {({ remove, push }) => (
                                        <div>
                                            {values.mortgageMigrationRequests?.length > 0 &&
                                                values.mortgageMigrationRequests.map((data: any, index: number) => (
                                                    <div className='row' key={index}>
                                                        <Card.Body>
                                                            <div className='form_fields'>
                                                                {index !== 0 && values.isUpdatable && (
                                                                    <div className='d-flex justify-content-end mt-2' style={{ position: 'absolute', right: '5%' }}>
                                                                        <img
                                                                            className='delete-icon'
                                                                            style={{ cursor: 'pointer', zIndex: 100 }}
                                                                            onClick={() => remove(index)}
                                                                            src={DeleteIcon}
                                                                            alt='delete'
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className='ps-2 py-2 d-flex justify-content-between'>
                                                                    <div className='formTitle m-0'>{`Mortgage details - ${index + 1}`}</div>
                                                                </div>
                                                                {MORTGAGE_DETAILS.map((val: any, _: number) => (
                                                                    <GenericFormCard
                                                                        fieldPath={`mortgageMigrationRequests.${index}`}
                                                                        {...val}
                                                                        prefixIndex={`mortgageMigrationRequests.${index}`}
                                                                        index={index}
                                                                        dropdownOptions={mortgageDropdownData}
                                                                    />
                                                                ))}
                                                                <FieldArray name={`mortgageMigrationRequests[${index}].bankMortgageMigrationRequests`}>
                                                                    {({ remove, push }) => (
                                                                        <div>
                                                                            {values.mortgageMigrationRequests[index].bankMortgageMigrationRequests?.length > 0 &&
                                                                                values.mortgageMigrationRequests[index].bankMortgageMigrationRequests.map((data: any, bank_index: number) => (
                                                                                    <div className='row' key={bank_index}>
                                                                                        <Card.Body>
                                                                                            <div
                                                                                                className='form_fields pt-3 pb-3'
                                                                                                style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '25px', position: 'relative' }}
                                                                                            >
                                                                                                {bank_index !== 0 && values.isUpdatable && (
                                                                                                    <div className='' style={{ position: 'absolute', right: '3%', bottom: '63%' }}>
                                                                                                        <img
                                                                                                            className='delete-icon'
                                                                                                            style={{ cursor: 'pointer', zIndex: 100 }}
                                                                                                            onClick={() => remove(bank_index)}
                                                                                                            src={DeleteIcon}
                                                                                                            alt='delete'
                                                                                                        />
                                                                                                    </div>
                                                                                                )}
                                                                                                <div className='row d-flex m-0'>
                                                                                                    {MORTGAGE_BANK_DETAILS[0]?.formData.map((formField: any, id: number) => (
                                                                                                        <div className={`py-3 col-sm-6`} key={id}>
                                                                                                            <label
                                                                                                                htmlFor={`mortgageMigrationRequests[${index}].bankMortgageMigrationRequests.${bank_index}.${formField.field}`}
                                                                                                                className='pb-2 file-form-label'
                                                                                                            >
                                                                                                                {formField.title} - {bank_index + 1}
                                                                                                                {formField.isRequired && <span className='mandatory-indicator'> *</span>}
                                                                                                            </label>

                                                                                                            <Field
                                                                                                                name={`mortgageMigrationRequests[${index}].bankMortgageMigrationRequests.${bank_index}.${formField.field}`}
                                                                                                                type={'text'}
                                                                                                                id={`mortgageMigrationRequests[${index}].bankMortgageMigrationRequests.${bank_index}.${formField.field}`}
                                                                                                                className={` form-control was-validated`}
                                                                                                                onChange={(e: any) => {
                                                                                                                    const newValue =
                                                                                                                        formField.type === 'number'
                                                                                                                            ? parseInt(e.target.value)
                                                                                                                            : formField.field === 'bankIFSC'
                                                                                                                            ? e.target.value.toUpperCase()
                                                                                                                            : e.target.value;
                                                                                                                    setFieldValue(
                                                                                                                        `mortgageMigrationRequests[${index}].bankMortgageMigrationRequests.${bank_index}.${formField.field}`,
                                                                                                                        newValue
                                                                                                                    );
                                                                                                                    formField.field === 'bankIFSC' &&
                                                                                                                        handleSearchIFSCMortgage(newValue, bank_index, index);
                                                                                                                }}
                                                                                                                value={_.get(
                                                                                                                    values,
                                                                                                                    `mortgageMigrationRequests[${index}].bankMortgageMigrationRequests.${bank_index}.${formField.field}`
                                                                                                                )}
                                                                                                                disabled={
                                                                                                                    (values.mortgageMigrationRequests[index].bankMortgageMigrationRequests[bank_index]
                                                                                                                        ?.bankIFSC?.length >= 11 &&
                                                                                                                    values.mortgageMigrationRequests[index].bankMortgageMigrationRequests[bank_index]
                                                                                                                        .bankName === '' &&
                                                                                                                    otherDetailIfscData !== null &&
                                                                                                                    otherDetailIfscData?.bank === ''
                                                                                                                        ? false
                                                                                                                        : false) || !values.isUpdatable
                                                                                                                }
                                                                                                            />
                                                                                                            <ErrorMessage
                                                                                                                name={`mortgageMigrationRequests[${index}].bankMortgageMigrationRequests.${bank_index}.${formField.field}`}
                                                                                                            >
                                                                                                                {msg => <span className='text-danger font-12'>{msg}</span>}
                                                                                                            </ErrorMessage>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        </Card.Body>
                                                                                    </div>
                                                                                ))}
                                                                            {values?.mortgageMigrationRequests[index]?.isPariPassu && values.isUpdatable && (
                                                                                <button
                                                                                    className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center noc_add_fields'
                                                                                    type='button'
                                                                                    onClick={() => push({ bankIFSC: '', bankName: '', uuid: null })}
                                                                                >
                                                                                    + Add Bank details
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </FieldArray>

                                                                {BANK_NOC_DOC.map((val: any, _: number) => (
                                                                    <GenericFormCard
                                                                        fieldPath={`mortgageMigrationRequests.${index}`}
                                                                        {...val}
                                                                        prefixIndex={`mortgageMigrationRequests.${index}`}
                                                                        index={index}
                                                                        dropdownOptions={dropdownData}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </Card.Body>
                                                    </div>
                                                ))}
                                            {mortgageDropdownData.mortgageType.length > 0 && values.isUpdatable && (
                                                <button
                                                    className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center add_fields'
                                                    type='button'
                                                    onClick={() => push(mortgageMigrationRequest)}
                                                >
                                                    + Add Mortgage details
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </FieldArray>
                            </>
                        ) : (
                            <></>
                        )}
                    </Card.Body>
                </Card>
                <Card className='card_border'>
                    <Card.Body>
                        {migrationWaterConnectionDetails.map((val: any) => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}
                        {!!values.waterConnection && (
                            <>
                                <FieldArray name='migrationWaterConnection'>
                                    {({ remove, push }) => (
                                        <div>
                                            {values.migrationWaterConnection?.length > 0 &&
                                                values.migrationWaterConnection.map((data: any, index: number) => (
                                                    <div className='row' key={index}>
                                                        <Card.Body>
                                                            <div className='form_fields'>
                                                                {index !== 0 && values.isUpdatable && (
                                                                    <div className='d-flex justify-content-end mt-2' style={{ position: 'absolute', right: '5%' }}>
                                                                        <img
                                                                            className='delete-icon'
                                                                            style={{ cursor: 'pointer', zIndex: 100 }}
                                                                            onClick={() => remove(index)}
                                                                            src={DeleteIcon}
                                                                            alt='delete'
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className='ps-2 py-2 d-flex justify-content-between'>
                                                                    <div className='formTitle m-0'>{`Water Connection - ${index + 1}`}</div>
                                                                </div>
                                                                {WATER_CONNECTION_FIELDS.map((val: any, _: number) => (
                                                                    <GenericFormCard
                                                                        fieldPath={`migrationWaterConnection.${index}`}
                                                                        {...val}
                                                                        prefixIndex={`migrationWaterConnection.${index}`}
                                                                        index={index}
                                                                        dropdownOptions={dropdownData}
                                                                    />
                                                                ))}
                                                            </div>
                                                        </Card.Body>
                                                    </div>
                                                ))}
                                            {values.migrationWaterConnection.length < 2 && values.isUpdatable && (
                                                <button
                                                    className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center add_fields'
                                                    type='button'
                                                    onClick={() => push(migrationWaterConnection)}
                                                >
                                                    + Add Water Connection details
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </FieldArray>
                            </>
                        )}
                    </Card.Body>
                </Card>
                <Card className='card_border'>
                    <Card.Body>
                        {migrationSublease.map((val: any) => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                await MIGRATION_FORM_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            } else if (activeIndex === stepperLength) {
                setModalDetails({
                    show: true,
                    title: 'File submitted successfully',
                    para: 'Please click on download button to download the file',
                    img: check,
                    type: 'success',
                    btnLabel: 'Download',
                    reUpload: 'Download'
                });
                // navigate('/goa-idc/dashboard');
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
            let newValues = _.cloneDeep(values);
            setCompareValues(newValues);
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const onClickNext = () => {
        console.log('type', type);
        const stepperLength = stepsDetails.length;
        if (activeIndex < stepperLength) {
            setActiveIndex(activeIndex + 1);
            window.scrollTo(0, 0);
        } else if (activeIndex === stepperLength) {
            if (type === 'EMPLOYEE') {
                navigate('/goa-idc/migration/application-search');
            } else {
                navigate('/goa-idc/enterprisedashboard');
            }
        }
    };

    const handleStepClick = async (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                await MIGRATION_FORM_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                const isSame = _.isEqual(compareValues, values);
                if (values.isUpdatable) {
                    if (isSame) {
                        setActiveIndex(index);
                        window.scrollTo(0, 0);
                    } else {
                        setModalDetails({ show: true, title: 'Error!', para: 'Please click Save & Next!', img: cross, type: 'error', reUpload: 'OK' });
                        return;
                    }
                } else {
                    setActiveIndex(index);
                    window.scrollTo(0, 0);
                }
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else {
            setActiveIndex(index);
            let newValues = _.cloneDeep(values);
            setCompareValues(newValues);
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    console.log('formik', formik);
    console.log('dropdown', dropdownData);

    useEffect(() => {
        let waterChargeMeterNo: any = [];

        values?.migrationWaterConnection.map((val: any, ind: number) => waterChargeMeterNo.push({ name: val?.meterNo, code: val?.meterNo }));
        setDropdownData({
            ...dropdownData,
            waterChargeMeterNo: waterChargeMeterNo
        });
    }, [values.migrationWaterConnection]);

    return (
        <>
            <HeaderComponent />
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('APPLICATION_MIGRATION_FORM', 'Application Migration Form')}
                            bottomSubText={`${values.migrationApplicationNumber ? `Migration application number - ${values.migrationApplicationNumber}` : ''}`}
                        />
                        <div className='col-3 stickyCard-section font-inter'>
                            <div className='stickyCard'>
                                <Card className={`card_border hiddenNav ${isVisible ? '' : 'scrolledNav'}`}>
                                    <Card.Body>
                                        <div className='d-flex align-items-center'>
                                            <GenericBackButton />
                                            <div className='ps-1'>
                                                <Card.Text className='font-inter-med fs-5'>{t('APPLICATION_MIGRATION_FORM', 'Application Migration Form')}</Card.Text>
                                                {values.migrationApplicationNumber && (
                                                    <Card.Subtitle className='text-muted text-nav font-inter p-0 m-0'>{`Migration application number - ${values.migrationApplicationNumber}`}</Card.Subtitle>
                                                )}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard steps={stepsDetails} visited={visited} activeIndex={activeIndex} handleStepClick={handleStepClick} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <StatusAlertPopup
                            title={modalDetails.title}
                            para={modalDetails.para}
                            img={modalDetails.img}
                            show={modalDetails.show}
                            handleClose={() => handleModalClose(modalDetails.type)}
                            type={modalDetails.type}
                            reUpload={modalDetails.reUpload}
                            handleSubmit={() => handleModalClose(modalDetails.type)}
                            btnLabel={modalDetails.btnLabel}
                        />
                        <div className='col-9 form-section'>
                            <FormikProvider value={formik}>
                                <Form>
                                    {activeIndex === 1 && renderApplicantDetails()}
                                    {activeIndex === 2 && (
                                        <PlotDetails dropdownData={dropdownDataPlot} setDropdownData={setDropdownDataPlot} isFieldDisable={!values.isUpdatable} isUpdate={isUpdate} />
                                    )}
                                    {activeIndex === 3 && !values.isSamePlotApplicationExist && (
                                        <EnterpriseDetails
                                            dropdownData={dropdownData}
                                            setDocuments={setDocuments}
                                            documents={documents}
                                            nicDetails={nicDetails}
                                            setNicDetails={setNicDetails}
                                            showPopUp={showPopUp}
                                            setShowPopUp={setShowPopUp}
                                        />
                                    )}
                                    {activeIndex === 3 && values.isSamePlotApplicationExist && (
                                        <PaymentDetails
                                            paymentDisableCheck={paymentDisableCheck}
                                            installmentTableRowData={installmentTableRowData}
                                            setInstallmentTableRowData={setInstallmentTableRowData}
                                            showInstallmentTable={showInstallmentTable}
                                            setShowInstallmentTable={setShowInstallmentTable}
                                            dropdownData={dropdownData}
                                        />
                                    )}
                                    {activeIndex === 4 && !values.isSamePlotApplicationExist && renderMigrationOtherDetails()}
                                    {activeIndex === 4 && values.isSamePlotApplicationExist && <SublLeasePayment />}
                                    {activeIndex === 6 && (
                                        <PaymentDetails
                                            paymentDisableCheck={paymentDisableCheck}
                                            installmentTableRowData={installmentTableRowData}
                                            setInstallmentTableRowData={setInstallmentTableRowData}
                                            showInstallmentTable={showInstallmentTable}
                                            setShowInstallmentTable={setShowInstallmentTable}
                                            dropdownData={dropdownData}
                                            isUpdate={isUpdate}
                                        />
                                    )}
                                    {activeIndex === 5 && <ConstructionLicenseDetails dropdownData={dropdownData} setDropdownData={setDropdownData} isUpdate={isUpdate} />}
                                    {activeIndex === 7 && !!lastStep && (
                                        <SubLease
                                            dropdownOptions={dropdownData}
                                            dropdownData={dropdownDataPlot}
                                            setDropdownData={setDropdownDataPlot}
                                            setSubleaseNicDetails={setSubleaseNicDetails}
                                            subleaseNicDetails={subleaseNicDetails}
                                            isUpdate={isUpdate}
                                        />
                                    )}
                                    {/* {activeIndex === 7 && <SublLeasePayment />} */}
                                </Form>
                            </FormikProvider>
                        </div>
                    </div>

                    {!values.isUpdatable ? (
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                    {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                                </div>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    <GenericButtons text={stepsDetails.length === activeIndex ? t('CLOSE', 'Close') : t('NEXT', 'Next')} handleClick={onClickNext} />
                                </div>
                            </div>
                        </footer>
                    ) : (
                        <GenericFooterComponent
                            onClickNext={onChangeNextIndex}
                            onCancel={() => navigate(-1)}
                            onClickPrev={onChangePrevIndex}
                            stepperLength={stepsDetails.length}
                            activeIndex={activeIndex}
                            onSubmit={handleSubmit}
                            hideSaveBtn={true}
                        />
                    )}
                </>
            )}

            <ScrollToTopButton />
        </>
    );
}
