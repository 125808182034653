export interface formFieldDTO {
    titleCode?: string;
    field: string;
    title: string;
    type?: string;
    options?: formOptionsDTO[];
    class?: string;
    id?: number | string;
    fileType?: string;
    module?: string;
    isDivider?: boolean;
    isRequired?: boolean;
    size?: string;
    fileTypes?: string[];
    isDisabled?: boolean;
    prefix?: string;
    entityFileData?: any;
    hideTitle?: boolean;
    renderCondition?: (val: any, index: number) => boolean;
    condition1?: (val: any, index: number) => boolean;
    conditionalClass?: (val: any, index?: number) => string;
    multiSelect?: boolean;
    toolTipDetails?: any;
    width?: string;
    showInr?: boolean;
    viewOnly?: boolean;
    hideMultiFileValue?:boolean;
}

export interface formOptionsDTO {
    name?: string;
    value?: string | any;
    code?: string;
    label?: string;
    title?: string;
    titleCode?: string;
    subcategory?: formOptionsDTO[];
    active?: any;
}

export interface dropdownOptionsInterface {
    gender?: formOptionsDTO[];
    empType?: formOptionsDTO[];
    status?: formOptionsDTO[];
    isCurrentAssignment?: formOptionsDTO[];
    department?: formOptionsDTO[];
    designation?: formOptionsDTO[];
    isHOD?: formOptionsDTO[];
    isPrimary?: formOptionsDTO[];
    roles?: any[];
    qualification?: formOptionsDTO[];
    stream?: formOptionsDTO[];
    identityDetailsDropdown?: formOptionsDTO[];
    addressDetailsDropdown?: formOptionsDTO[];
    letterDetailsDropdown?: formOptionsDTO[];
    nationality?: formOptionsDTO[];
    registrationType?: formOptionsDTO[];
    identityDocument?: formOptionsDTO[];
    entitycategory?: formOptionsDTO[];
    reasonForActivation?: formOptionsDTO[];
    nocType?: formOptionsDTO[];
    specialProject?: formOptionsDTO[];
    projectType?: formFieldDTO[];
    expansion?: formFieldDTO[];
    pollutionCategory?: formFieldDTO[];
    waterRequirement?: formFieldDTO[];
    powerRequirement?: formFieldDTO[];
    employement?: formFieldDTO[];
    meansOfFinance?: formFieldDTO[];
    category?: formFieldDTO[];
    enterpriseCategory?: formOptionsDTO[];
    subCategory?: formFieldDTO[];
    experienceCategory?: formOptionsDTO[];
}

export interface formFieldProps {
    phoneNo?: number;
    emailId?: string;
    otp?: number;
    name?: string;
}

export interface Assignments {
    id: string;
    position: number;
    designation: string;
    department: string;
    fromDate: number;
    toDate: number | null;
    govtOrderNumber: string | null;
    tenantid: string;
    reportingTo: string | null;
    auditDetails: {
        createdBy: string;
        createdDate: number;
        lastModifiedBy: string | null;
        lastModifiedDate: number;
    };
    isHOD: boolean;
    isCurrentAssignment: boolean;
    isPrimary: boolean;
    roles: string[];
}

export interface Qualifications {
    tenantid: string;
    qualification: string;
    stream: string;
    yearOfPassing: string;
    university: string;
    remarks: string;
}

export interface DocumentDetails {
    id: string;
    documentName: string;
    documentType: string;
    documentId: string;
    referenceType: string;
    referenceId?: string;
    tenantId: string;
    documentUrl?: string;
    url?: string;
    auditDetails: {
        createdBy: string;
        createdDate: number;
        lastModifiedBy: string | null;
        lastModifiedDate: number;
    };
    docReferenceId?: string;
    fileSize?: string;
}

export interface ResponseInfo {
    apiId: null;
    ver: null;
    ts: null;
    resMsgId: null;
    msgId: null;
    status: null;
}

export interface NextValidActionItem {
    auditDetails: null;
    uuid: string;
    tenantId: string;
    currentState: string;
    action: string;
    nextState: string;
    roles: string[];
    active: null;
    isEmployeeAssignee: boolean;
}

export interface NextValidActionApiResponse {
    ResponseInfo: ResponseInfo;
    businessService: string;
    moduleName: string;
    nextValidAction: NextValidActionItem[];
    isUpdatable: false;
}

export interface DocumentInfo {
    documentName: string;
    documentType?: string;
    documentId?: string;
    referenceType?: string;
    referenceId?: string;
    documentSize?: number;
    lastModified?: string | undefined;
    tenantid?: string;
    documentUrl: string;
    uploadedDate?: any;
    docReferenceId?: any;
    fileSize?: any;
}

export interface RoleInterface {
    code: string;
    name: string;
    tenantId: string;
}

export interface IconMap {
    [key: string]: string;
}

export const FileEnum: { [key: string]: string } = {
    'Joining/Appointment Letter': 'JOINING_APPOINTMENT_LETTER',
    'Address Proof': 'ADDRESS_PROOF',
    'Identity Proof': 'IDENTITY_PROOF',
    'Aadhaar Card': 'IDENTITY_PROOF',
    'License Copy': 'LICENSE_COPY',

    'Letter stating the name of the Proprietorship firm': 'PROPRIETORSHIPFIRM',
    'Identity of the proprietor': 'IDENTITY',
    'Trust Deed': 'TRUST_DEED',
    'Partnership Deed': 'PARTNERSHIP_DEED',
    'Certificate of registration from the Registrar of Firms': 'CERTIFICATE_OF_REGISTRATION_FIRMS',
    'Certificate of incorporation from Registrar of Companies': 'CERTIFICATE_OF_INCORPORATION',
    'Certificate of registration from the Registrar of Societies': 'REGISTRATION_OF_SOCITIES',
    'Copy of relevant notification': 'GOVT_NOTIFICATION',

    'GST Document': 'GST',
    'PAN Card': 'PAN',
    'DPIIT/Government of Goa, Certificate of Recognition Certificate for Startup': 'DPIIT',
    'Udyam Registration Certificate': 'UDYAMREGISTRATION',

    'profile photo': 'PROFILE_PICTURE'
};

export const EnterpriseFileEnum: { [key: string]: string } = {
    'Letter stating the name of the Proprietorship firm': 'PROPRIETORSHIPFIRM',
    'Identity of the proprietor': 'IDENTITY',
    'Trust Deed': 'TRUST_DEED',
    'Partnership Deed': 'PARTNERSHIP_DEED',
    'Certificate of registration from the Registrar of Firms': 'CERTIFICATE_OF_REGISTRATION_FIRMS',
    'Certificate of incorporation from Registrar of Companies': 'CERTIFICATE_OF_INCORPORATION',
    'Certificate of registration from the Registrar of Societies': 'REGISTRATION_OF_SOCITIES',
    'Copy of relevant notification': 'GOVT_NOTIFICATION',

    'GST Document': 'GST',
    'PAN Card': 'PAN',
    'DPIIT/Government of Goa, Certificate of Recognition Certificate for Startup': 'DPIIT',
    'Udyam Registration Certificate': 'UDYAMREGISTRATION'
};

export interface formFieldDTO {
    field: string;
    title: string;
    type?: string;
    options?: formOptionsDTO[];
    class?: string;
    id?: number | string;
    fileType?: string;
    module?: string;
    isRequired?: boolean;
    size?: string;
    fileTypes?: string[];
    isDisabled?: boolean;
    break?: boolean;
}

export interface formFieldProps {
    phoneNo?: number;
    emailId?: string;
    otp?: number;
    name?: string;
}
