// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/karla/Karla-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/karla/Karla-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/inter/Inter-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/inter/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    color: #000;
    font-family: Karla, Inter, 'Segoe UI', 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.32px;
    zoom: 90%;
}

/* 
#root {
  overflow-x: hidden;
} */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'KarlaRegular';
    src: local('KarlaRegular'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'KarlaMedium';
    src: local('KarlaMedium'), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'InterRegular';
    src: local('InterRegular'), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'InterMedium';
    src: local('InterRegular'), url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: normal;
}

.date-picker-icon {
    font-size: 14px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,WAAW;IACX,+CAA+C;IAC/C,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,SAAS;AACb;;AAEA;;;GAGG;;AAEH;IACI,+EAA+E;AACnF;;AAEA;IACI,2BAA2B;IAC3B,sFAA4F;IAC5F,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,qFAA0F;IAC1F,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,sFAA4F;IAC5F,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,sFAA2F;IAC3F,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;AACnB","sourcesContent":["body {\n    margin: 0;\n    color: #000;\n    font-family: Karla, Inter, 'Segoe UI', 'Roboto';\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 160%;\n    letter-spacing: -0.32px;\n    zoom: 90%;\n}\n\n/* \n#root {\n  overflow-x: hidden;\n} */\n\ncode {\n    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n@font-face {\n    font-family: 'KarlaRegular';\n    src: local('KarlaRegular'), url('./assets/fonts/karla/Karla-Regular.ttf') format('truetype');\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: 'KarlaMedium';\n    src: local('KarlaMedium'), url('./assets/fonts/karla/Karla-Medium.ttf') format('truetype');\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: 'InterRegular';\n    src: local('InterRegular'), url('./assets/fonts/inter/Inter-Regular.ttf') format('truetype');\n    font-weight: normal;\n}\n\n@font-face {\n    font-family: 'InterMedium';\n    src: local('InterRegular'), url('./assets/fonts/inter/Inter-Medium.ttf') format('truetype');\n    font-weight: normal;\n}\n\n.date-picker-icon {\n    font-size: 14px;\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
