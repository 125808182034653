import React from "react";
import style from "./MiddleBar.module.css";
import { POST, endpoints, getFileUrl } from "../../services/modulesServices/userManagement.api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function MiddleBarSubComponent() {
    const { t } = useTranslation();

    let navigate = useNavigate();

    const onClickReadMore = async (documentName: string) => {
        try {
            const { data } = await POST(endpoints.getCommonDocument + `?documentCategories=IDC_PORTAL&documentNames=${documentName}`, { RequestInfo: {} });
            const fileUrl = await getFileUrl(data.documentDetails[0].documentReferenceId);
            window.open(fileUrl, "_blank");
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <div style={{ backgroundColor: "#F5F5F6" }} className="font-inter">
                <div className="px-5 pt-5">
                    <div className="row">
                        <div className="col-sm-9 p-3 text-start" style={{ fontSize: "36px" }}>
                            <span style={{ color: "#80B036", fontWeight: "600" }}>{t("GOA", "GOA:")}</span> <span style={{ color: "#80B036" }}>{t("G", "G")}</span>
                            {t("ROWTH", "rowth, ")}
                            <span style={{ color: "#80B036" }}>{t("O", "O")}</span>
                            {t("PPURTUNITIES", "pportunities & ")}
                            <span style={{ color: "#80B036" }}>{t("A", "A")}</span>
                            {t("SPIRATIONS", "spirations ")}
                        </div>
                        {/* <div className="col-sm-9 p-3 text-start" style={{ fontSize: '36px' }}><span style={{ color: '#80B036' }}>G</span>rowth<br /> <span style={{ color: '#80B036' }}>O</span>pportunities <br /> <span style={{ color: '#80B036' }}>A</span>spirations </div> */}
                        <div className="col-sm-3 p-3 fs-4">
                            <div className="font-karla" style={{ color: "#6A6A6A", textAlign: "justify", fontSize: "18px" }}>
                                {t(
                                    "STRATEGICALLY_POSITIONED",
                                    "Strategically positioned along the bustling western coastline of India, Goa boasts a dynamic industrial ecosystem, pulsating with diverse businesses and rich cultural heritage. "
                                )}
                            </div>
                            <br />
                            {/* <div className="" style={{ color: "#000", textAlign: "justify", fontSize: "18px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div> */}
                        </div>
                    </div>
                </div>
                <div className="px-5 ">
                    <div className="row">
                        <div className="col-sm-3 ">
                            <div className={`${style.cardHover} first_card p-5 text-start position-relative`}>
                                <div className={`fw-bold font-inter-med text-start ${style.cardTitle_1}`}>{t("SL_01", "01")}</div>
                                <div className={`text-start pb-2 ${style.cardSubText}`}>{t("INDIA_FIRST_DPG", "India’s first DPG for industry governance")}</div>
                                <div className={`text-start pb-4  ${style.cardSubText_1}`}>
                                    {t("ABOUT_GOA_IDC_DPG", "Goa-IDC has been at the forefront of developing digital public goods, enhancing the ease of doing business")}
                                </div>
                                <div className="text-start  position-absolute bottom-0 mb-5  cursor-pointer read-more">
                                    <Link to="https://www.undp.org/publications/digital-public-goods-sdgs" target="_blank">
                                        {t("READ_MORE", "Read More")}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 ">
                            <div className={`${style.cardHover} second_card p-5 text-start position-relative`}>
                                <div className={`fw-bold font-inter-med ${style.cardTitle_2}`}>{t("SL_02", "02")}</div>
                                <div className={`text-start pb-2 ${style.cardSubText}`}>{t("RADICAL", "Radical and Progressive Regulations")}</div>
                                <div className={`text-start  pb-4 ${style.cardSubText_1}`}>
                                    {t(
                                        "ABOUT_REGULATION",
                                        "With Ease of Doing Business (EoDB) as its core focus, the Goa-IDC Regulations 2023 offer a highly industry-friendly framework for conducting business"
                                    )}
                                </div>
                                <div className="text-start  position-absolute bottom-0 mb-5  cursor-pointer read-more" onClick={() => onClickReadMore("Goa_IDC_Regulations_2023")}>
                                    {t("READ_MORE", "Read More")}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 ">
                            <div className={`${style.cardHover} third_card p-5 text-start position-relative`}>
                                <div className={`fw-bold font-inter-med ${style.cardTitle_3}`}>{t("SL_03", "03")}</div>
                                <div className={`text-start pb-2 ${style.cardSubText}`}>{t("LOGISTICS", "Logistics and Warehousing Policy 2023")}</div>
                                <div className={`text-start  pb-4 ${style.cardSubText_1}`}>
                                    {t("ABOUT_LOGISTICS", "Logistics and warehousing have been granted 'industry' status, aiming to establish Goa as a logistics hub")}
                                </div>
                                <div className="text-start  position-absolute bottom-0 mb-5 cursor-pointer read-more" onClick={() => onClickReadMore("Logistics_and_Warehousing_Policy_2023")}>
                                    {t("READ_MORE", "Read More")}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 ">
                            <div className={`${style.cardHover} fourth_card p-5 text-start position-relative`}>
                                <div className={`fw-bold font-inter-med ${style.cardTitle_4}`}>{t("SL_04", "04")}</div>
                                <div className={`text-start pb-2 ${style.cardSubText}`}>{t("INVEST_OPPURTUNITIES", "Invest in Goa; Invest in Success")}</div>
                                <div className={`text-start  pb-4 ${style.cardSubText_1}`}>
                                    {t("ABOUT_INVEST_OPPURTUNITIES", "Join Goa's thriving industrial ecosystem by discovering a wealth of investment opportunities")}
                                </div>
                                <div className="text-start  position-absolute bottom-0 mb-5 cursor-pointer read-more" onClick={() => navigate("/goa-idc/tender")}>
                                    {t("READ_MORE", "Read More")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
