import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';

export const getCancellationData = (res: any, dropdownData: any) => {
    const initialData: any = {};
    initialData['orderNo'] = res.parentOrderDetails?.allotmentId;
    initialData['orderDate'] = res.parentOrderDetails?.allotmentDate;
    initialData['type'] = getDropdownSelectedObject(res.parentOrderDetails.allotmentType, dropdownData['orderType'] || []);

    return initialData;
};

export const CANCELLATION_TAB = [
    {
        title: 'A - Applicant Details',
        field: 'applicantDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Order Details',
        field: 'cancellationOrderDetails'
    },
    {
        title: 'D - Cancellation Order Notes',
        field: 'cancellationOrderNotes'
    }
];

export const CANCEL_ORDER_DETAILS = [
    {
        // title: 'Advertisement',
        formData: [
            {
                title: 'Type',
                field: 'type',
                isRequired: true,
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-6 me-3',
                isDisabled: true
            },
            {
                title: 'Order no.',
                field: 'orderNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'Order Date',
                field: 'orderDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-6',
                isDisabled: true
            }
        ]
    }
];
