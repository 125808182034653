import React, { useEffect, useState } from 'react';
import { formOptionsDTO } from './GenericFormCard.constants';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useFormikContext } from 'formik';
import _ from 'lodash';
import { InputActionMeta, components } from 'react-select';
import serchIcon from '../../assets/logos/search.svg';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const Control = ({ children, ...props }: any) => {
    return (
        <components.Control {...props}>
            <span>
                <img src={serchIcon} alt='' className='me-2' />
            </span>
            {children}
        </components.Control>
    );
};

const SearchField = (props: any) => {
    const { customHandleChange, dropdownOptions, isRequired, title, field, fetchOptions } = props;
    const { t } = useTranslation();
    const { values, errors, touched, setFieldValue, setFieldTouched, setValues } = useFormikContext<any>();

    let dropdownFieldName = field.split('.').slice(-1)[0];

    const classes = {
        control: (state: any) =>
            `${props?.disabled ? 'search-field-disabled' : ''} form-control was-validated form-border-rad ps-0 ${_.get(touched, field) && _.get(errors, field) && 'is-invalid'}  ${
                _.get(values, field) && isRequired && !_.get(errors, field) && values.isUpdatable && 'is-valid'
            }`,
        placeholder: (state: any) => 'placeholder-style',
        indicatorsContainer: (state: any) => 'd-none',
        option: (state: any) => (state.isFocused ? 'option-on-focus' : 'option-style' || state.isSelected ? 'bg-transparent  option-style text-black' : ''),
        menu: (state: any) => 'menu-style my-0',
        menuList: (state: any) => ' py-0 rounded search-menu-list',
        singleValue: (state: any) => 'option-style',
        loadingMessage: (state: any) => 'search-loading-message'
    };

    const handleChange = (values: any) => {
        setFieldValue(field, { code: values?.value || '', name: values?.label || '' });

        customHandleChange && customHandleChange(values, field);
    };
    return (
        <>
            <div className='d-flex'>
                <label htmlFor={field} className=' file-form-label' style={{ paddingBottom: '3px' }} title={title}>
                    {title}
                </label>
                {isRequired && <span className='mandatory-indicator ms-1'>*</span>}
            </div>
            <AsyncSelect
                loadOptions={fetchOptions}
                name={field}
                id={field}
                classNamePrefix='react-select'
                classNames={classes}
                placeholder=''
                onChange={handleChange}
                unstyled={true}
                isClearable={true}
                onBlur={() => setFieldTouched(field, true)}
                value={{ value: _.get(values, field)?.value || _.get(values, field)?.code, label: _.get(values, field)?.label || _.get(values, field)?.name }}
                components={{ Control }}
                openMenuOnClick={_.get(values, field) !== undefined && true}
                isDisabled={props.disabled}
                isLoading={true}
                onFocus={() => setFieldValue(field, null)}
                loadingMessage={() => `${_.get(values, field) === undefined ? 'Loading...' : 'Type something to get the result'}`}
            />
            <ErrorMessage name={field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
        </>
    );
};

export default SearchField;
