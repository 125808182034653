import { t } from 'i18next';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../utils/constants';
import { DOCUMENT_TYPES_MGR } from '../../modules/Migration/Migration.const';

export const INITIAL_SELECT_DATA = {
    tenantId: 'km',
    moduleName: 'BPA',
    TechnicalPerson: [
        {
            name: 'Architect',
            title: 'Architect',
            code: 'C.A',
            active: true,
            orderNumber: '1',
            description: 'Architect',
            workflow: null,
            document: [],
            subcategory: []
        },
        {
            name: 'LBS',
            title: 'LBS',
            code: 'LBS',
            active: true,
            orderNumber: '2',
            description: 'LBS',
            workflow: 'TPOA',
            document: [],
            subcategory: [
                {
                    name: 'Class l',
                    title: 'Class l',
                    code: 'l',
                    active: true,
                    orderNumber: '1',
                    description: 'LBS l',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class ll',
                    title: 'Class ll',
                    code: 'll',
                    active: true,
                    orderNumber: '2',
                    description: 'LBS ll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class lll',
                    title: 'Class lll',
                    code: 'lll',
                    active: false,
                    orderNumber: '3',
                    description: 'LBS lll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                }
            ]
        },
        {
            name: 'ESE',
            title: 'ESE',
            code: 'ESE',
            active: true,
            orderNumber: '3',
            description: 'ESE',
            workflow: 'TPOA',
            document: [],
            subcategory: [
                {
                    name: 'Class l',
                    title: 'Class l',
                    code: 'l',
                    active: true,
                    orderNumber: '1',
                    description: 'ESE l',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class ll',
                    title: 'Class ll',
                    code: 'll',
                    active: true,
                    orderNumber: '2',
                    description: 'ESE ll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class lll',
                    title: 'Class lll',
                    code: 'lll',
                    active: false,
                    orderNumber: '3',
                    description: 'ESE lll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                }
            ]
        },
        {
            name: 'GTE',
            title: 'GTE',
            code: 'GTE',
            active: true,
            orderNumber: '4',
            description: 'GTE',
            workflow: 'TPOA',
            document: [],
            subcategory: [
                {
                    name: 'Class l',
                    title: 'Class l',
                    code: 'l',
                    active: true,
                    orderNumber: '1',
                    description: 'GTE l',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class ll',
                    title: 'Class ll',
                    code: 'll',
                    active: true,
                    orderNumber: '2',
                    description: 'GTE ll',
                    workflow: 'TPOA',
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class lll',
                    title: 'Class lll',
                    code: 'lll',
                    active: false,
                    orderNumber: '3',
                    description: 'GTE lll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                }
            ]
        },
        {
            name: 'ESR',
            title: 'ESR',
            code: 'ESR',
            active: true,
            orderNumber: '5',
            description: 'ESR',
            workflow: 'TPOA',
            document: [],
            subcategory: [
                {
                    name: 'Class l',
                    title: 'Class l',
                    code: 'l',
                    active: true,
                    orderNumber: '1',
                    description: 'ESR l',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class ll',
                    title: 'Class ll',
                    code: 'll',
                    active: true,
                    orderNumber: '2',
                    description: 'ESR ll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class lll',
                    title: 'Class lll',
                    code: 'lll',
                    active: false,
                    orderNumber: '3',
                    description: 'ESR lll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                }
            ]
        }
    ]
};

export const LESSEE_DETAILS: FormDataDTO[] = [
    {
        title: 'Lessee Details',
        // titleCode: 'TC_CARD_HEADER_LESSEE_DETAILS',
        formData: [
            {
                titleCode: 'TC_LBL_ENTERPRISE_NAME',
                title: 'Enterprise Name',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_EMAILID',
                title: 'Email ID',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_PHONENO',
                title: 'Phone Number',
                field: 'phone',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_PANNO',
                title: 'PAN No.',
                field: 'panNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            }
        ]
    },
    {
        title: 'Address',
        titleCode: 'TC_CARD_HEADER_ADDRESS',
        formData: [
            {
                titleCode: 'TC_LBL_ADDRESSLINE1',
                title: 'Address Line 1',
                field: 'addressLine1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_ADDRESSLINE2',
                title: 'Address Line 2',
                field: 'addressLine2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_PINCODE',
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_DISTRICT',
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_STATE',
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_COUNTRY',
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            }
        ]
    }
];

export const ARCHITECT_DETAILS: FormDataDTO[] = [
    {
        title: 'Architect Details',
        // titleCode: 'TC_CARD_HEADER_ARCHITECH_DETAILS',
        formData: [
            {
                titleCode: 'TC_LBL_ARCHITECH_NAME',
                title: 'Architect Name',
                field: 'archName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-sm-6 me-3'
            },
            {
                titleCode: 'TC_UPLD_ARCHITECT_CERT',
                title: 'Certificate from the architect/engineer',
                field: 'CERTIFICATE_ARCHITECTURE',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                viewOnly: true,
                // class: 'lg',
                // width: 'half',
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
                    documentType: DOCUMENT_TYPES_MGR.TECHNICAL_CLEARANCE_DOCUMENTS.CERTIFICATE_ARCHITECTURE
                },
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Certificate from the architect/engineer who has planned the project and signed the drawing</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            },
            {
                titleCode: 'TC_UPLD_ARCHITECT_STRUCTURAL_CERT',
                title: 'Structural Liability Certificate',
                field: 'CERTIFICATE_LIABILITY',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                // class: 'lg',
                viewOnly: true,
                isRequired: true,
                // width: 'half',
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
                    documentType: DOCUMENT_TYPES_MGR.TECHNICAL_CLEARANCE_DOCUMENTS.CERTIFICATE_LIABILITY
                },
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Structural Liability Certificate from the engineer responsible for structural design of the project</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            }
        ]
    }
];

export const BUILDING_PLAN_DETAILS: FormDataDTO[] = [
    {
        title: 'Scrutiny Details',
        titleCode: 'SCRUTINY_DETAILS',
        formData: [
            {
                titleCode: 'SERVICE_TYPE',
                title: 'Service Type',
                field: 'serviceType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                options: [
                    {
                        titleCode: 'NEW_CONSTRUCTION_',
                        name: 'New Construction',
                        title: 'New Construction',
                        value: 'NEW_CONSTRUCTION',
                        subcategory: []
                    },
                    {
                        titleCode: 'ADDITION_AND_ALTERATION',
                        name: 'Addition and Alteration',
                        title: 'Addition and Alteration',
                        value: 'ADDITION_ALTERATION',
                        subcategory: []
                    },
                    {
                        titleCode: 'RECONSTRUCTION',
                        name: 'Reconstruction',
                        title: 'Reconstruction',
                        value: 'RE_CONSTRUCTION',
                        subcategory: []
                    }
                ]
            },
            {
                titleCode: 'BUILDING_PLAN',
                title: 'Building Plan',
                field: 'buildingPlan',
                type: CONSTANT_DATA_FIELD.MULTI_FILE_FIELD,
                class: 'lg',
                isRequired: true,
                size: '10',
                fileTypes: ['DXF']
            }
        ]
    }
];

export const DOCUMENTS_DETAILS: FormDataDTO[] = [
    {
        title: 'Drawings PDF',
        titleCode: 'TC_CARD_HEADER_DRAWINGS_PDF',
        formData: [
            {
                titleCode: 'TC_UPLD_BUILDING_PLAN_PDF',
                title: 'Submission Building Plan',
                field: 'SITEPLAN',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
                    documentType: DOCUMENT_TYPES_MGR.TECHNICAL_CLEARANCE_DOCUMENTS.SITEPLAN
                }
            }
        ]
    }
    // {
    //     title: 'Documents duly authenticated/signed as prescribed',
    //     titleCode: 'TC_CARD_HEADER_DOCUMENTS_AUTHENTICATED_SIGNED',
    //     formData: [
    //         {
    //             titleCode: 'TC_UPLD_QUESTIONAIRE',
    //             title: 'Questionnaire',
    //             field: 'DOCUMENT_QUESTIONNAIRE',
    //             type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
    //             isRequired: true,
    //             entityFileData: {
    //                 documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
    //                 documentType: DOCUMENT_TYPES_MGR.TECHNICAL_CLEARANCE_DOCUMENTS.DOCUMENT_QUESTIONNAIRE
    //             }
    //         },
    //         {
    //             titleCode: 'TC_UPLD_AFFIDAVIT',
    //             title: 'Affidavit from the owner',
    //             field: 'DOCUMENT_AFFIDAVIT',
    //             type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
    //             isRequired: false,
    //             entityFileData: {
    //                 documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
    //                 documentType: DOCUMENT_TYPES_MGR.TECHNICAL_CLEARANCE_DOCUMENTS.DOCUMENT_AFFIDAVIT
    //             }
    //         },
    //         {
    //             titleCode: 'TC_UPLD_POWER_ATTORNEY',
    //             title: 'Power of Attorney',
    //             field: 'DOCUMENT_POWER_ATTORNEY',
    //             type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
    //             isRequired: false,
    //             entityFileData: {
    //                 documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ENTERPRISE,
    //                 documentType: DOCUMENT_TYPES_MGR.TECHNICAL_CLEARANCE_DOCUMENTS.DOCUMENT_POWER_ATTORNEY
    //             }
    //         }
    //     ]
    // }
];

export const DIGITAL_SIGN_DETAILS: FormDataDTO[] = [
    {
        title: '',
        formData: [
            {
                title: 'Token',
                field: 'token',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,

                class: 'long'
            },
            {
                title: 'Certificate',
                field: 'certi',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,

                class: 'long'
            },
            {
                title: 'PIN',
                field: 'pin',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,

                class: 'long'
            }
        ]
    }
];

export const BPA_ACTIONS_DETAILS: FormDataDTO[] = [
    {
        title: '',
        formData: [
            {
                title: 'Comments',
                titleCode: 'Comments',
                field: 'comments',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: false,
                class: 'long'
            }
        ]
    }
];

export const BPA_SELECT: FormDataDTO[] = [
    {
        title: '',
        formData: [
            {
                title: '',
                field: 'edcrNo',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                class: 'lg',
                options: []
            }
        ]
    }
];
