import { isDisabled } from '@testing-library/user-event/dist/utils';
import { dropdownOptionsInterface, formFieldProps, formOptionsDTO } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import { AUTHORIZED_PERSON_TITLE, CONSTANT_DATA_FIELD, FormDataDTO } from '../../../utils/constants';
import _ from 'lodash';
import { DOCUMENT_TYPE } from '../Migration/Migration.const';

export const PERSONAL_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'FIRST_MIDDLE_NAME',
                title: 'First & Middle Name',
                field: 'firstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'LAST_NAME',
                title: 'Last Name',
                field: 'lastname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'MOBILE_NUMBER',
                title: 'Mobile Number',
                field: 'phoneNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'EMAIL_ID',
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'GENDER',
                title: 'Gender',
                field: 'gender',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'DATE_OF_BIRTH',
                title: 'Date of Birth',
                field: 'dob',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            }
        ]
    },
    {
        titleCode: 'PRESENT_ADDRESS',
        title: 'Present Address',
        formData: [
            {
                titleCode: 'ADDRESS_LINE_1',
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_LINE_2',
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'PIN_CODE',
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'DISTRICT',
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'STATE',
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'COUNTRY',
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            }
        ]
    },
    {
        titleCode: 'PERMANENT_ADDRESS',
        title: 'Permanent Address',
        isSameAsAbove: true,
        formData: [
            {
                titleCode: 'ADDRESS_LINE_1',
                title: 'Address Line 1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_LINE_2',
                title: 'Address Line 2',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'PIN_CODE',
                title: 'Pin Code',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'DISTRICT',
                title: 'District',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'STATE',
                title: 'State',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'COUNTRY',
                title: 'Country',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            }
        ]
    }
];

export const EDUCATION_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'QUALIFICATION',
                title: 'Qualification',
                field: 'qualification',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'STREAM',
                title: 'Stream',
                field: 'stream',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'YEAR_OF_PASSING',
                title: 'Year of Passing',
                field: 'yearOfPassing',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'UNIVERSITY',
                title: 'University',
                field: 'university',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'REMARKS',
                title: 'Remarks',
                field: 'remarks',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            }
        ],
        multisection: 'qualifications'
    }
];

export const PROFESSIONAL_DETAILS: FormDataDTO[] = [
    {
        formData: [
            // {
            //   title: "Employee ID",
            //   field: "empId",
            //   type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //   isRequired: true
            // },
            {
                titleCode: 'APPOINTMENT_DATE',
                title: 'Appointment Date',
                field: 'appointmentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                titleCode: 'EMPLOYMENT_TYPE',
                title: 'Employment Type',
                field: 'empType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'STATUS',
                title: 'Status',
                field: 'status',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            }
        ]
    }
];

export const ASSIGNMENT_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'ASSIGNED_FROM_DATE',
                title: 'Assigned From Date',
                field: 'fromDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ASSIGNED_TO_DATE',
                title: 'Assigned To Date',
                field: 'toDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                renderCondition: (values: any, ind: number) => {
                    return _.get(values, `assignment.${ind}.isCurrentAssignment`)?.name === 'No';
                }
            },
            {
                titleCode: 'CURRENTLY_ASSIGNED_HERE',
                title: 'Currently Assigned Here',
                field: 'isCurrentAssignment',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'DESIGNATION',
                title: 'Designation',
                field: 'designation',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'DEPARTMENT',
                title: 'Department',
                field: 'department',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ROLES',
                title: 'Roles',
                field: 'roles',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                multiSelect: true,
                isRequired: true,
                class: 'col-6 me-1'
            },
            {
                titleCode: 'IS_PRIMARY',
                title: 'Is Primary',
                field: 'isPrimary',
                type: CONSTANT_DATA_FIELD.CHECKBOX_FIELD
                // isRequired: true
            }
        ],
        multisection: 'assignments'
    }
];

export const DOCUMENTS_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'IDENTITY_DETAILS',
                title: 'Identity Details',
                field: 'identityDetailsDropdown',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-4',
                isRequired: true
            },
            {
                titleCode: 'IDENTITY_PROOF',
                title: 'Identity Proof',
                field: 'identityProof',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                class: 'col-sm-5',
                fileType: 'identityDetailsDropdown',
                module: 'Employee',
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'Identity Proof',
                    documentType: ''
                }
            },
            {
                titleCode: 'DOCUMENT_NO',
                title: 'Document No.',
                field: 'documentNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-3',
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_DETAILS',
                title: 'Address Details',
                field: 'addressDetailsDropdown',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-4',
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_PROOF',
                title: 'Address Proof',
                field: 'addressProof',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                class: 'col-sm-5',
                fileType: 'addressDetailsDropdown',
                module: 'Employee',
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'Address Proof',
                    documentType: ''
                }
            },
            {
                titleCode: 'APPOINTMENT_DETAILS',
                title: 'Appointment Details',
                field: 'letterDetailsDropdown',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-4',
                isRequired: true
            },
            {
                titleCode: 'JOINING_APPOINTMENT_LETTER',
                title: 'Joining/Appointment Letter',
                field: 'joiningLetter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                class: 'col-sm-5',
                fileType: 'letterDetailsDropdown',
                module: 'Employee',
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'letterDetailsDropdown.code',
                    documentType: ''
                }
            }
        ]
    }
];

export const stepsDetails = [
    {
        titleCode: 'PERSONAL_DETAILS',
        name: 'Personal Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'EDUCATIONAL_DETAILS',
        name: 'Education Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'PROFESSIONAL_DETAILS',
        name: 'Professional Details',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'ASSIGNMENT_DETAILS',
        name: 'Assignment Details',
        index: 4,
        isActive: false
    },
    {
        titleCode: 'DOCUMENTS',
        name: 'Documents',
        index: 5,
        isActive: false
    },
    {
        titleCode: 'PREVIEW',
        name: 'Preview',
        index: 6,
        isActive: false
    }
];

export const stepsDetailsForArchitect = [
    {
        titleCode: 'PERSONAL_DETAILS',
        name: 'Personal Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'LICENSE_DETAILS',
        name: 'License Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'DOCUMENTS',
        name: 'Documents',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'PREVIEW',
        name: 'Preview',
        index: 4,
        isActive: false
    }
];

export const ARCHITECT_DOCS_DETAILS: FormDataDTO[] = [
    {
        // titleCode: 'REQUIRED_DOCUMENTS',
        // title: 'Required Documents',
        formData: [
            {
                titleCode: 'IDENTITY_PROOF',
                title: 'Identity Proof',
                field: 'adhar',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'firstname',
                    documentType: DOCUMENT_TYPE.IDENTITY,
                    referenceType: DOCUMENT_TYPE.IDENTITY_PROOF
                }
            },
            {
                titleCode: 'LICENSE_COPY',
                title: 'License Copy',
                field: 'licenseCopy',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'firstname',
                    documentType: DOCUMENT_TYPE.LICENSE_COPY,
                    referenceType: DOCUMENT_TYPE.LICENSE_COPY
                }
            }
        ]
    }
];

export const LICENSEE_NEW_DETAILS: FormDataDTO[] = [
    {
        // title: "License Details",
        formData: [
            {
                titleCode: 'TECHNICAL_PERSON_LICENSE_TYPE',
                title: 'Technical Person License Type',
                field: 'licenseeType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'COUNCIL_FOR_ARCHITECTURE_NO',
                title: 'Council for Architecture No.',
                field: 'CouncilArcNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'lg'
            },
            {
                titleCode: 'VALIDATE_FROM',
                title: 'Validate From',
                field: 'validFrom',
                isRequired: true,
                type: CONSTANT_DATA_FIELD.DATE_FIELD
            },
            {
                titleCode: 'VALIDATE_TO',
                title: 'Validate To',
                field: 'validTo',
                isRequired: true,
                type: CONSTANT_DATA_FIELD.DATE_FIELD
            }
        ]
    }
];

export const INITIAL_SELECT_DATA = {
    tenantId: 'km',
    moduleName: 'BPA',
    TechnicalPerson: [
        {
            titleCode: 'ARCHITECT',
            name: 'Architect',
            title: 'Architect',
            code: 'C.A',
            active: true,
            orderNumber: '1',
            description: 'Architect',
            workflow: null,
            document: [],
            subcategory: []
        }
    ]
};

export const PROFILE_STEPS = [
    {
        titleCode: 'PERSONAL_DETAILS',
        name: 'Personal Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'CHANGES_PASSWORD',
        name: 'Change Password',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'DSC_REGISTRATION',
        name: 'DSC Registration',
        index: 3,
        isActive: true
    }
    // {
    //     titleCode: 'e-Mudra KYC_REGISTRATION',
    //     name: 'e-Mudra KYC Registration',
    //     index: 4,
    //     isActive: true
    // }
];
export const PROFILE_PERSONAL_DETAILS = [
    {
        titleCode: 'PERSONAL_DETAILS_HEADING',
        heading: 'Personal Details',
        isEditable: true,
        type: 'personal',
        formData: [
            {
                titleCode: 'NAME',
                title: 'Name',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'Gender',
                title: 'Gender',
                field: 'gender',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isUpdatable: false,
                isDisabled: true
            },
            {
                titleCode: 'PHONE_NO',
                title: 'Phone No.',
                field: 'profilePhoneNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'EMAIL_ID',
                title: 'Email ID',
                field: 'profileEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'DATE_OF_BIRTH',
                title: 'Date of Birth',
                field: 'dob',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'PRESENT_ADDRESS',
        title: 'Present Address',
        heading: 'Address Details',
        isEditable: false,
        type: 'address',
        formData: [
            {
                titleCode: 'ADDRESS_LINE_1',
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'ADDRESS_LINE_2',
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'PIN_CODE',
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'DISTRICT',
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'STATE',
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'COUNTRY',
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            }
        ]
    },
    {
        titleCode: 'PERMANENT_ADDRESS',
        title: 'Permanent Address',
        isSameAsAbove: true,
        type: 'address',
        formData: [
            {
                titleCode: 'ADDRESS_LINE_1',
                title: 'Address Line 1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'ADDRESS_LINE_2',
                title: 'Address Line 2 ',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'PIN_CODE',
                title: 'Pin Code',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'DISTRICT',
                title: 'District',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'STATE',
                title: 'State',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            },
            {
                titleCode: 'COUNTRY',
                title: 'Country',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isEditable: true
            }
        ]
    }
];

export const PROFILE_EDUCATIONAL_DETAILS = [
    {
        formData: [
            {
                titleCode: 'QUALIFICATION',
                title: 'Qualification',
                field: 'qualification',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'STREAM',
                title: 'Stream',
                field: 'stream',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'YEAR_OF_PASSING',
                title: 'Year of Passing',
                field: 'yearOfPassing',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'UNIVERSITY',
                title: 'University',
                field: 'university',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'REMARKS',
                title: 'Remarks',
                field: 'remarks',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const PROFILE_PROFESSIONAL_DETAILS = [
    {
        titleCode: 'PROFESSIONAL_DETAILS_HEADING',
        heading: 'Professional Details',
        formData: [
            {
                titleCode: 'EMPLOYEE_ID',
                title: 'Employee ID',
                field: 'employeeID',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'APPOINTMENT_DATE',
                title: 'Appointment Date',
                field: 'appointmentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'EMPLOYMENT_TYPE',
                title: 'Employment Type',
                field: 'empType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'STATUS',
                title: 'Status',
                field: 'status',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const PROFILE_DOCUMENT_DETAILS = [
    {
        titleCode: 'DOCUMENTS_HEADING',
        heading: 'Documents',
        formData: [
            {
                titleCode: 'IDENTITY_PROOF',
                title: 'Identity Proof',
                field: 'identityProof',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'ADDRESS_PROOF',
                title: 'Address Proof',
                field: 'addressProof',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'JOINING_APPOINTMENT_LETTER',
                title: 'Joining/Appointment Letter',
                field: 'joiningLetter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const PROFILE_ASSIGNMENT_DETAILS = [
    {
        formData: [
            {
                titleCode: 'ASSIGNED_FROM_DATE',
                title: 'Assigned From Date',
                field: 'fromDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'ASSIGNED_TO_DATE',
                title: 'Assigned To Date',
                field: 'toDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'CURRENTLY_ASSIGNED_HERE',
                title: 'Currently Assigned Here',
                field: 'isCurrentAssignment',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'DEPARTMENT',
                title: 'Department',
                field: 'department',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'DESIGNATION',
                title: 'Designation',
                field: 'designation',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const CHANGE_PASSWORD = [
    {
        titleCode: 'CHANGE_PASSWORD',
        title: 'Change Password',
        formData: [
            {
                titleCode: 'OLD_PASSWORD',
                title: 'Old Password',
                field: 'oldPassword',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'NEW_PASSWORD',
                title: 'New Password',
                field: 'newPassword',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'CONFIRM_NEW_PASSWORD',
                title: 'Confirm New Password',
                field: 'confirmNewPassword',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            }
        ]
    }
];

export const ENTERPRISE_STEPS_DETAILS = [
    {
        titleCode: 'ENTERPRISE_DETAILS',
        name: 'Enterprise details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'AUTHORIZED_PERSON',
        name: 'Authorized Person',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'OWNERSHIP_DETAILS',
        name: 'Ownership Details',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'BANK_DETAILS',
        name: 'Bank Details',
        index: 4,
        isActive: false
    }
];

export const ENTERPRISE_DETAILS_I = [
    {
        formData: [
            {
                titleCode: 'ENTERPRISE_DETAILS',
                title: 'Enterprise Name',
                field: 'entityname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'RELEVANT_ENTERPRISE_CATEGORY',
                title: 'Enterprise Category ',
                field: 'entitycategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Letter stating the name of the Proprietorship firm',
                field: 'entityProprietorshipFirm',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `entitycategory`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'entityname',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Identity of the proprietor',
                field: 'entityProprietorIdentity',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `entitycategory`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'entityname',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Trust Deed',
                field: 'trustDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return _.get(value, `entitycategory`)?.code === 'TRUST';
                },
                entityFileData: {
                    documentNamePrefix: 'entityname',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Partnership Deed',
                field: 'partnershipDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `entitycategory`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'entityname',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Certificate of registration from the Registrar of Firms',
                field: 'certificateOfRegistrationFirms',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `entitycategory`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'entityname',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Certificate of incorporation from Registrar of Companies',
                field: 'certificateOfIncorporation',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `entitycategory`)?.code === 'LIMITED_LIABILITY_PARTNERSHIP' || _.get(value, `entitycategory`)?.code === 'LTD_COMPANY',
                entityFileData: {
                    documentNamePrefix: 'entityname',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Certificate of registration from the Registrar of Societies',
                field: 'certificateOfRegistrationSocieties',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `entitycategory`)?.code === 'COOP_SOCIETY',
                entityFileData: {
                    documentNamePrefix: 'entityname',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Copy of relevant notification',
                field: 'copyRelevantNotification',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `entitycategory`)?.code === 'GOV_STAT_CORP',
                entityFileData: {
                    documentNamePrefix: 'entityname',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            }
        ]
    }
];

export const ENTERPRISE_DETAILS_II = [
    {
        formData: [
            {
                titleCode: 'EMAIL_ID',
                title: 'Email ID',
                field: 'entityemailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'PHONE_NO',
                title: 'Phone No.',
                field: 'entityPhoneNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'PAN No',
                field: 'entitypanNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                conditionalClass: (values: any) => (_.get(values, `enterpriseGst`) === false ? 'me-3' : '')
            },
            {
                titleCode: 'PAN_CARD',
                title: 'PAN Card',
                field: 'entitypanCard',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                class: 'col-sm-6 d-block',
                entityFileData: {
                    documentNamePrefix: 'entityname',
                    documentType: DOCUMENT_TYPE.PAN,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            }
        ]
    },
    {
        titleCode: 'Address Details',
        title: 'Address Details',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'State U/T',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            }
        ]
    },
    {
        titleCode: 'Mailing/Correspondence address of the Enterprise',
        title: 'Mailing/Correspondence address of the Enterprise',
        isSameAsAbove: true,
        isRequired: true,
        formData: [
            {
                title: 'Address Line 1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2 ',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'District',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'State U/T',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Country',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            }
        ]
    }
];
// export const ENTITY_DETAILS = [
//     {
//         formData: [
//             {
//                 titleCode: 'ENTERPRISE_DETAILS',
//                 title: 'Enterprise Name',
//                 field: 'entityname',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD,
//                 isRequired: true
//                 // toolTipDetails:{
//                 //     position:'right',
//                 //     content:'<p>Please select the relevant Enterprise category Please select the relevant </p> Enterprise category Please select the relevant Enterprise category Please select the relevant Enterprise category Please select the relevant Enterprise category Please select the relevant Enterprise category Please select the relevant Enterprise category ',
//                 //     linkUrl:'https://www.npmjs.com/package/react-popper-tooltip',
//                 //     linkText:'Google',
//                 // }
//             },
//             {
//                 titleCode: 'RELEVANT_ENTERPRISE_CATEGORY',
//                 title: 'Please select the relevant Enterprise category',
//                 field: 'entitycategory',
//                 type: CONSTANT_DATA_FIELD.SELECT_FIELD,
//                 isRequired: true
//             },
//             [],
//             [],
//             {
//                 titleCode: 'EMAIL_ID',
//                 title: 'Email ID',
//                 field: 'entityemailId',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD,
//                 isRequired: true
//             },
//             {
//                 titleCode: 'PHONE_NO',
//                 title: 'Phone No.',
//                 field: 'entityPhoneNo',
//                 type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
//                 isRequired: true
//             },
//             {
//                 titleCode: 'GST_APPLICABLE',
//                 title: 'GST Applicable',
//                 field: 'gstapplicable',
//                 type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//                 options: [
//                     { value: true, title: 'Yes' },
//                     { value: false, title: 'No' }
//                 ]
//             },
//             {
//                 titleCode: 'GST_NO',
//                 title: 'GST No.',
//                 field: 'entityGstNo',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD,
//                 condition1: (values: any) => _.get(values, `gstapplicable`) === true
//             },
//             {
//                 title: 'GST Document',
//                 field: 'entitygstDocument',
//                 type: CONSTANT_DATA_FIELD.FILE_FIELD,
//                 class: 'col-6 me-3',
//                 // condition1: (values: any) => _.get(values, `gstapplicable`) === true,
//                 renderCondition: (values: any) => _.get(values, `gstapplicable`) === true,
//                 hideTitle: true
//             },
//             {
//                 titleCode: 'PAN_NO',
//                 title: 'PAN No.',
//                 field: 'entitypanNo',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD,
//                 isRequired: true
//             }
//             // {
//             //     titleCode: 'UDHYAM_REGISTERED_ENTITY',
//             //     title: 'Udyam Registered Enity',
//             //     field: 'UdhyamRegisteredEnity',
//             //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//             //     options: [
//             //         { value: true, title: 'Yes' },
//             //         { value: false, title: 'No' }
//             //     ]
//             // },
//             // {
//             //     titleCode: 'STARTUP_CERTIFICATE',
//             //     title: 'DPIIT/Government of Goa, Certificate of Recognition Certificate for Startup',
//             //     field: 'certificateForStartup',
//             //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//             //     options: [
//             //         { value: true, title: 'Yes' },
//             //         { value: false, title: 'No' }
//             //     ]
//             // }
//         ]
//     },
//     {
//         titleCode: 'ADDRESS_DETAILS',
//         title: 'Address Details',
//         formData: [
//             {
//                 titleCode: 'ADDRESS_LINE_1',
//                 title: 'Address Line 1',
//                 field: 'address1',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD,
//                 isRequired: true
//             },
//             {
//                 titleCode: 'ADDRESS_LINE_2',
//                 title: 'Address Line 2',
//                 field: 'address2',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD
//             },
//             {
//                 titleCode: 'PIN_CODE',
//                 title: 'Pin Code',
//                 field: 'pincode',
//                 type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
//                 isRequired: true
//             },
//             {
//                 titleCode: 'DISTRICT',
//                 title: 'District',
//                 field: 'district',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD
//             },
//             {
//                 titleCode: 'STATE',
//                 title: 'State',
//                 field: 'state',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD
//             },
//             {
//                 titleCode: 'COUNTRY',
//                 title: 'Country',
//                 field: 'country',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD
//             }
//         ]
//     },
//     {
//         titleCode: 'MAILING_CORRESPONDENCE_OF_ENTERPRISE',
//         title: 'Mailing/Correspondence details of the Enterprise',
//         isRequired: true,
//         formData: [
//             {
//                 titleCode: 'ADDRESS_LINE_1',
//                 title: 'Address Line 1',
//                 field: 'peraddress1',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD,
//                 isRequired: true
//             },
//             {
//                 titleCode: 'ADDRESS_LINE_2',
//                 title: 'Address Line 2 ',
//                 field: 'peraddress2',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD
//             },
//             {
//                 titleCode: 'PIN_CODE',
//                 title: 'Pin Code',
//                 field: 'perpincode',
//                 type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
//                 isRequired: true
//             },
//             {
//                 titleCode: 'DISTRICT',
//                 title: 'District',
//                 field: 'perdistrict',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD
//             },
//             {
//                 titleCode: 'STATE',
//                 title: 'State',
//                 field: 'perstate',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD
//             },
//             {
//                 titleCode: 'COUNTRY',
//                 title: 'Country',
//                 field: 'percountry',
//                 type: CONSTANT_DATA_FIELD.TEXT_FIELD
//             }
//         ]
//     },
//     // {
//     //     titleCode: 'ENTITY_DOCUMENTS',
//     //     title: 'Entity Documents',
//     //     formData: []
//     // },
//     {
//         titleCode: 'OTHER_DOCUMENTS',
//         title: 'Other Documents',
//         formData: [
//             {
//                 titleCode: 'PAN_CARD',
//                 title: 'PAN Card',
//                 field: 'entitypanCard',
//                 type: CONSTANT_DATA_FIELD.FILE_FIELD,
//                 isRequired: true
//             }
//         ]
//     }
// ];

export const entityDocuments = {
    entityProprietorshipFirm: {
        titleCode: 'PROPRIETORSHIP_FIRM_NAME',
        title: 'Letter stating the name of the Proprietorship firm',
        field: 'entityProprietorshipFirm',
        type: CONSTANT_DATA_FIELD.FILE_FIELD,
        isRequired: true
    },
    entityProprietorIdentity: {
        titleCode: 'PROPRIETOR_IDENTITY',
        title: 'Identity of the proprietor',
        field: 'entityProprietorIdentity',
        type: CONSTANT_DATA_FIELD.FILE_FIELD,
        isRequired: true
    },
    trustDeed: {
        titleCode: 'TRUST_DEED',
        title: 'Trust Deed',
        field: 'trustDeed',
        type: CONSTANT_DATA_FIELD.FILE_FIELD,
        isRequired: true
    },
    partnershipDeed: {
        titleCode: 'PARTNERSHIP_DEED',
        title: 'Partnership Deed',
        field: 'partnershipDeed',
        type: CONSTANT_DATA_FIELD.FILE_FIELD,
        isRequired: true
    },
    certificateOfRegistrationFirms: {
        titleCode: 'REGISTRATION_CERTIFICATE_FIRMS',
        title: 'Certificate of registration from the Registrar of Firms',
        field: 'certificateOfRegistrationFirms',
        type: CONSTANT_DATA_FIELD.FILE_FIELD
    },
    certificateOfIncorporation: {
        titleCode: 'INCORPORATION_CERTIFICATE',
        title: 'Certificate of incorporation from Registrar of Companies',
        field: 'certificateOfIncorporation',
        type: CONSTANT_DATA_FIELD.FILE_FIELD,
        isRequired: true
    },
    certificateOfRegistrationSocieties: {
        titleCode: 'REGISTRATION_CERTIFICATE_SOCIETIES',
        title: 'Certificate of registration from the Registrar of Societies',
        field: 'certificateOfRegistrationSocieties',
        type: CONSTANT_DATA_FIELD.FILE_FIELD,
        isRequired: true
    },
    copyRelevantNotification: {
        titleCode: 'RELEVANT_NOTIFICATION',
        title: 'Copy of relevant notification',
        field: 'copyRelevantNotification',
        type: CONSTANT_DATA_FIELD.FILE_FIELD,
        isRequired: true
    }
};
export const AUTHORIZED_PERSON = [
    {
        titleCode: 'AUTHORIZED_PERSON_DETAILS',
        title: 'Authorized Person’s Details',
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                titleCode: 'FIRST_NAME',
                title: 'First Name',
                field: 'authorizedPersonFirstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'LAST_NAME',
                title: 'Last Name',
                field: 'authorizedPersonLastname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'PRIMARY_MOBILE_NUMBER',
                title: 'Primary Mobile Number',
                field: 'authorizedPersonPrimaryMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'EMAIL_ID',
                title: 'Email ID',
                field: 'authorizedPersonEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ALTERNATIVE_MOBILE_NUMBER',
                title: 'Alternative Mobile Number',
                field: 'authorizedPersonAlternativeMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD
            },
            {
                titleCode: 'LETTER_OF_AUTHORIZATION',
                title: 'Letter of Authorization',
                field: 'authorizedPersonPowerOfAttorneyDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'authorizedPersonFirstname',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                },
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>The letter / resolution / power of attorney authorising the authorised person to apply on behalf of the Applicant</div>'
                }
            }
        ]
    }
];

export const OWNERSHIP_DETAILS = [
    {
        formData: [
            {
                title: 'Individual or Organization ',
                field: 'individualOrOrganization',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: 'Individual', title: 'Individual' },
                    { value: 'Organization', title: 'Organization' }
                ],
                isRequired: true,
                class: 'col-sm-12 px-1 '
            },
            {
                titleCode: 'NAME',
                title: 'Name',
                field: 'ownershipName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-12'
            },
            {
                titleCode: 'OWNERSHIP_INTEREST_percent',
                title: 'Ownership Interest (%)',
                field: 'ownershipInterest',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'CONTACT_NO',
                title: 'Contact No.',
                field: 'ownershipContactNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'GENDER',
                title: 'Gender',
                field: 'ownershipGender',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },
            {
                titleCode: 'ADDRESS_LINE_1',
                title: 'Address Line 1',
                field: 'ownershipAddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_LINE_2',
                title: 'Address Line 2 ',
                field: 'ownershipAddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'PIN_CODE',
                title: 'Pin Code',
                field: 'ownershippincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                prefix: 'ownership'
            },
            {
                titleCode: 'DISTRICT',
                title: 'District',
                field: 'ownershipdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'STATE',
                title: 'State',
                field: 'ownershipstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'COUNTRY',
                title: 'Country',
                field: 'ownershipcountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            // {
            //     titleCode: 'PAN_NO',
            //     title: 'PAN No.',
            //     field: 'ownershipPanNo',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true
            // },
            {
                titleCode: 'HIGHEST_QUALIFICATION',
                title: 'Educational qualification',
                field: 'ownershipHighestQualification',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            },
            {
                // titleCode: 'IS_LOCAL_PERSON',
                title: 'Local Person',
                field: 'isLocalPerson',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
                // class: 'col-sm-6 me-3'
            },
            {
                titleCode: 'Educational Qualification Document',
                title: 'Educational Qualification Document',
                field: 'ownershipEducationDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                hideFileSize: true,
                entityFileData: {
                    documentNamePrefix: 'ownershipName',
                    documentType: DOCUMENT_TYPE.EDUCATION,
                    referenceType: DOCUMENT_TYPE.OWNER
                },
                hideTitle: true,
                renderCondition: (values: any) => _.get(values, `ownershipHighestQualification`)
            },
            {
                title: '',
                type: 'none',
                renderCondition: (values: any) => !_.get(values, `ownershipHighestQualification`) && _.get(values, `isLocalPerson`) === true
            },
            {
                titleCode: 'LOCAL_PERSON_DOCUMENT',
                title: 'Birth Certificate or Residence Certificate',
                field: 'ownershipLocalPersonDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'ownershipName',
                    documentType: DOCUMENT_TYPE.LOCALPERSON,
                    referenceType: DOCUMENT_TYPE.OWNER
                },
                hideFileSize: true,
                renderCondition: (values: any) => _.get(values, `isLocalPerson`) === true,
                isRequired: true,
                hideTitle: true
            }
        ]
    }
];
// export const OTHER_DETAILS = [
//     {
//         titleCode: 'OTHER_DETAILS',
//         title: 'Other Details',
//         formData: [
//             {
//                 titleCode: 'WOMEN_ENTREPRENEUR',
//                 title: 'Whether the Applicant is a Women Entrepreneur',
//                 field: 'womenEntrepreneur',
//                 type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//                 options: [
//                     { value: true, title: 'Yes' },
//                     { value: false, title: 'No' }
//                 ]
//             },
//             {
//                 titleCode: 'LOCAL_PERSON',
//                 title: 'Local Person',
//                 field: 'localPerson',
//                 type: CONSTANT_DATA_FIELD.RADIO_FIELD,
//                 options: [
//                     { value: true, title: 'Yes' },
//                     { value: false, title: 'No' }
//                 ]
//             },
//             {
//                 titleCode: 'WOMEN_ENTREPRENEURSHIP_DOCUMENT',
//                 title: 'Women Entrepreneurship Documents',
//                 field: 'womenEntrepreneurDocuments',
//                 type: CONSTANT_DATA_FIELD.FILE_FIELD,
//                 renderCondition: (value: any) => _.get(value, `womenEntrepreneur`) === true
//             }
//         ]
//     }
// ];

export const BANK_DETAILS = [
    {
        titleCode: 'BANK_ACCOUNT_DETAILS',
        title: 'Bank Account Details',
        formData: [
            {
                titleCode: 'NAME',
                title: 'Name',
                field: 'nameOfAccountHolder',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true,
                class: 'col-sm-12'
            },
            {
                titleCode: 'ACCOUNT_NUMBER',
                title: 'Account Number',
                field: 'accountNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },

            {
                titleCode: 'IFSC_CODE',
                title: 'IFSC Code',
                field: 'ifscCode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'BANK_NAME',
                title: 'Bank Name',
                field: 'bankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'BRANCH_ADDRESS',
                title: 'Branch Address',
                field: 'branchAddress',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const ENTER_OTP = [
    {
        formData: [
            {
                titleCode: 'ENTER_OTP',
                title: 'Enter OTP',
                field: 'otp',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-12 px-1 '
            }
        ]
    }
];

export const WELCOME_BACK = [
    {
        formData: [
            {
                titleCode: 'PREVIOUS_APPLICATION_CONTINUATION',
                title: 'Would you like to continue with previous application found in database for these credentials?',
                field: 'previousApplicationCheck',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-sm-12'
            }
        ]
    }
];

export const OWNERSHIP_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '50px' },
        id: 1,
        isClickable: true
    },
    {
        titleCode: 'NAME',
        colName: 'Name',
        fieldName: 'name',
        cellstyle: { width: '120px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 2
    },
    {
        titleCode: 'OWNERSHIP_INT_PERCENT',
        colName: 'COI (%)',
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Controlling Ownership Interest (%)</div>',
            variant: { color: 'white', height: '20px' }
        },
        fieldName: 'ownershipInterest',
        cellstyle: { width: '120px' },
        clsName: 'column-text-end',
        type: 'text',
        id: 3
    },
    {
        titleCode: 'GENDER',
        colName: 'Gender',
        fieldName: 'genderValue',
        cellstyle: { width: '90px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 4
    },
    {
        titleCode: 'RESIDENTIAL_ADDRESS',
        colName: 'Address',
        fieldName: 'residentialAddress',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 4
    },
    {
        titleCode: 'CONTACT_NO',
        colName: 'Contact No',
        fieldName: 'contactNo',
        cellstyle: { width: '140px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 4
    },
    {
        titleCode: 'HIGHEST_QUALIFICATION',
        colName: 'Education',
        fieldName: 'highestQualificationValue',
        cellstyle: { width: '150px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 4,
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Highest Qualification</div>',
            variant: { color: 'white', height: '20px' }
        }
    },
    {
        titleCode: 'LOCAL_PERSON_DOCUMENT',
        colName: 'Local person',
        fieldName: 'ownershipLocalPersonDocument',
        cellstyle: { width: '150px' },
        clsName: 'column-text-start',
        type: 'document',
        id: 4,
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Local Person Document</div>',
            variant: { color: 'white', height: '20px' }
        }
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['edit', 'view', 'delete']
    }
];

export const CALCULATED_OWNERSHIP_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '50px' },

        id: 1,
        isClickable: true
    },
    {
        titleCode: 'NAME',
        colName: 'Name',
        fieldName: 'name',
        cellstyle: { width: '120px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 2
    },
    {
        titleCode: 'OWNERSHIP_INT_PERCENT',
        colName: 'COI (%)',
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Controlling Ownership Interest (%)</div>',
            variant: { color: 'white', height: '20px' }
        },
        fieldName: 'ownershipInterest',
        cellstyle: { width: '120px' },
        clsName: 'column-text-end',
        type: 'text',
        id: 3
    },
    {
        titleCode: 'GENDER',
        colName: 'Gender',
        fieldName: 'genderValue',
        cellstyle: { width: '90px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 4
    },
    {
        titleCode: 'RESIDENTIAL_ADDRESS',
        colName: 'Address',
        fieldName: 'residentialAddress',
        cellstyle: { width: '220px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 4
    },
    {
        titleCode: 'CONTACT_NO',
        colName: 'Contact No',
        fieldName: 'contactNo',
        cellstyle: { width: '140px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 4
    },
    {
        titleCode: 'HIGHEST_QUALIFICATION',
        colName: 'Education',
        fieldName: 'highestQualificationValue',
        cellstyle: { width: '150px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 4,
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Highest Qualification</div>',
            variant: { color: 'white', height: '20px' }
        }
    },
    {
        titleCode: 'LOCAL_PERSON_DOCUMENT',
        colName: 'Local person',
        fieldName: 'ownershipLocalPersonDocument',
        cellstyle: { width: '150px' },
        clsName: 'column-text-start',
        type: 'document',
        id: 4,
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Local Person Document</div>',
            variant: { color: 'white', height: '20px' }
        }
    }
];

export const DASHBOARD_TABLE_COLUMNS = [
    {
        titleCode: 'APPLICATION_TYPE',
        colName: 'Application Type',
        fieldName: 'applicationType',
        clsName: 'col-sm-2 font-14 column-text-start',
        id: 1
    },
    {
        titleCode: 'ENTITY_NAME',
        colName: 'Entity Name',
        fieldName: 'entityName',
        clsName: 'application-number column-text-start',
        id: 2
    },
    {
        titleCode: 'APPLICATION_NO',
        colName: 'Application No.',
        fieldName: 'applicationNo',
        clsName: 'application-number column-text-start',
        id: 3,
        isClickable: true
    },
    {
        titleCode: 'DATE_OF_SUBMISSION',
        colName: 'Date of Submission',
        fieldName: 'assignedDate',
        clsName: 'col-sm-2 column-text-start',
        id: 4,
        isDate: true
    },
    {
        titleCode: 'STATUS',
        colName: 'Status',
        fieldName: 'status',
        clsName: 'col-sm-2 column-text-start',
        id: 5
    },
    // {
    //     titleCode: 'PENDING_WITH',
    //     colName: 'Pending with',
    //     fieldName: 'currentOwner',
    //     fieldName2: 'currentOwnerDesig',
    //     clsName: 'col-sm-2 column-text-start',
    //     id: 6
    // },
    {
        titleCode: 'PENDENCY',
        colName: 'Pendency',
        fieldName: 'deskPendencyDays',
        clsName: 'col-sm-1 column-text-start',
        id: 6,
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Pendency (No. of Days) </div>',
            variant: { color: 'white' },
            className: 'position-relative'
        }
    }
];

export const DASHBOARD_TABLE_COLUMNS_MD = [
    {
        titleCode: 'APPLICATION_TYPE',
        colName: 'Application Type',
        fieldName: 'applicationType',
        clsName: 'col-sm-2 font-14 column-text-start',
        id: 1
    },
    {
        titleCode: 'ENTITY_NAME',
        colName: 'Entity Name',
        fieldName: 'entityName',
        clsName: 'entity-name-md column-text-start',
        id: 2
    },
    {
        titleCode: 'APPLICATION_NO',
        colName: 'Application No.',
        fieldName: 'applicationNo',
        clsName: 'application-number column-text-start',
        id: 3,
        isClickable: true
    },
    {
        titleCode: 'DATE_OF_SUBMISSION',
        colName: 'Date of Submission',
        fieldName: 'assignedDate',
        clsName: 'date-of-submission-md column-text-start',
        id: 4,
        isDate: true
    },
    {
        titleCode: 'STATUS',
        colName: 'Status',
        fieldName: 'status',
        clsName: 'status-md column-text-start',
        id: 5
    },
    // {
    //     titleCode: 'PENDING_WITH',
    //     colName: 'Pending with',
    //     fieldName: 'currentOwner',
    //     fieldName2: 'currentOwnerDesig',
    //     clsName: 'col-sm-2 column-text-start',
    //     id: 6
    // },
    {
        titleCode: 'DESK_PENDENCY_DAYS',
        colName: 'Desk Pendency Days',
        fieldName: 'deskPendencyDays',
        clsName: 'serach-table-md column-text-start',
        id: 6,
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Desk Pendency (No. of Days) </div>',
            variant: { color: 'white' },
            className: 'position-relative'
        }
    },
    {
        titleCode: 'OVERALL_PENDENCY',
        colName: 'Overall Pendency',
        fieldName: 'overallPendencyDays',
        clsName: 'serach-table-md column-text-start',
        id: 7,
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Oevrall Pendency (No. of Days) </div>',
            variant: { color: 'white' },
            className: 'position-relative'
        }
    }
];

export const DASHBOARD_TABLE_COLUMNS_ENTERPRISE = [
    {
        titleCode: 'APPLICATION_TYPE',
        colName: 'Application Type',
        fieldName: 'applicationType',
        clsName: 'col-sm-2 font-14 column-text-start',
        id: 1
    },
    {
        titleCode: 'APPLICATION_NO',
        colName: 'Application No.',
        fieldName: 'applicationNo',
        clsName: 'application-number column-text-start',
        id: 2,
        isClickable: true
    },
    {
        titleCode: 'DATE_OF_SUBMISSION',
        colName: 'Date of Submission',
        fieldName: 'assignedDate',
        clsName: 'col-sm-2 column-text-start',
        id: 3,
        isDate: true
    },
    {
        titleCode: 'STATUS',
        colName: 'Status',
        fieldName: 'status',
        clsName: 'col-sm-2 column-text-start',
        id: 4
    },
    {
        titleCode: 'PENDING_WITH',
        colName: 'Pending with',
        fieldName: 'currentOwner',
        fieldName2: 'currentOwnerDesig',
        clsName: 'col-sm-2 column-text-start',
        id: 5
    },
    {
        titleCode: 'PENDENCY',
        colName: 'Desk Pendency Days',
        fieldName: 'deskPendencyDays',
        clsName: 'pendency column-text-start',
        id: 6,
        toolTipDetails: {
            position: 'bottom',
            content: '<div>Pendency (No. of Days) </div>',
            variant: { color: 'white' },
            className: 'position-relative'
        }
    }
];

export const TENDOR_TABLE_COLUMNS = [
    {
        titleCode: 'TENDER_DESCRIPTION',
        colName: 'Tender Description',
        fieldName: 'caption',
        clsName: 'col-sm-3',
        id: 1
    },
    {
        titleCode: 'TENDER_DOCUMENT',
        colName: 'Tender Document',
        fieldName: 'documentName',
        clsName: 'col-sm-2',
        id: 2,
        isClickable: true
    },
    {
        titleCode: 'TENDER_OPEN_DATE',
        colName: 'Tender Open Date',
        fieldName: 'openDate',
        clsName: 'col-sm-2',
        id: 3
    },
    {
        titleCode: 'TENDER_PUBLISH_DATE',
        colName: 'Tender Publish Date',
        fieldName: 'publishDate',
        clsName: 'col-sm-2',
        id: 4
    },
    {
        titleCode: 'TENDER_CLOSE_DATE',
        colName: 'Tender Close Date',
        fieldName: 'bidCloseDate',
        clsName: 'col-sm-2',
        id: 4
    }
];

export const SEARCH_TABLE_COLUMNS_DATA = [
    {
        colName: 'Employee Name',
        fieldName: 'user.firstName',
        fieldName2: 'user.lastName',
        clsName: 'col-sm-3'
    },
    {
        colName: 'Employee ID',
        fieldName: 'code',
        clsName: 'col-sm-2',
        isClickable: true
    },
    {
        colName: 'Designation',
        fieldName: 'assignments[0].designation',
        clsName: 'col-sm-3'
    },
    {
        colName: 'Status',
        fieldName: 'employeeStatus',
        clsName: `col-sm-1 bg`,
        bg: 'employeeStatus'
    },
    {
        colName: 'Role',
        fieldName: 'assignments[0].roles[0].name',
        clsName: 'col-sm-3'
    }
];

export const ACTIVATE_EMPLOYEE = [
    {
        formData: [
            {
                titleCode: 'ORDER_NO',
                title: 'Order No.',
                field: 'orderNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'EFFECTIVE_DATE',
                title: 'Effective Date',
                field: 'effectiveDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                isDisabled: false
            },
            {
                titleCode: 'COMMENTS',
                title: 'Comments',
                field: 'remarks',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'JOINING_APPOINTMENT_LETTER',
                title: 'Joining/Appointment Letter',
                field: 'joiningAppointmentLetter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD
            }
        ]
    }
];

export const REASON_FOR_REACTIVATE = {
    titleCode: 'REASON_FOR_REACTIVATE',
    title: 'Reason for Re-Activating',
    field: 'reasonForReActivating',
    type: CONSTANT_DATA_FIELD.SELECT_FIELD,
    isRequired: true,
    isDisabled: false
};

export const REASON_FOR_DEACTIVATE = {
    titleCode: 'REASON_FOR_DEACTIVATE',
    title: 'Reason for Deactivating',
    field: 'reasonForReActivating',
    type: CONSTANT_DATA_FIELD.SELECT_FIELD,
    isRequired: true,
    isDisabled: false
};

export const EMPLOYEE_DETAILS_PAGE = [
    {
        titleCode: 'PERSONAL_DETAILS_HEADING',
        heading: 'Personal Details',
        isEditable: true,
        type: 'personal',
        formData: [
            {
                titleCode: 'FIRST_MIDDLE_NAME',
                title: 'First & Middle Name',
                field: 'firstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'LAST_NAME',
                title: 'Last Name',
                field: 'lastname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'Gender',
                title: 'Gender',
                field: 'gender',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'PHONE_NO',
                title: 'Phone No.',
                field: 'profilePhoneNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                hideOtpBtn: true
            },
            {
                titleCode: 'EMAIL_ID',
                title: 'Email ID',
                field: 'profileEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                hideOtpBtn: true
            },
            {
                titleCode: 'DATE_OF_BIRTH',
                title: 'Date of Birth',
                field: 'dob',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            }
        ]
    },
    {
        titleCode: 'PRESENT_ADDRESS',
        title: 'Present Address',
        heading: 'Address Details',
        isEditable: true,
        type: 'address',
        formData: [
            {
                titleCode: 'ADDRESS_LINE_1',
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_LINE_2',
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'PIN_CODE',
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'DISTRICT',
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'STATE',
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'COUNTRY',
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            }
        ]
    },
    {
        titleCode: 'PERMANENT_ADDRESS',
        title: 'Permanent Address',
        isSameAsAbove: true,
        type: 'address',
        formData: [
            {
                titleCode: 'ADDRESS_LINE_1',
                title: 'Address Line 1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_LINE_2',
                title: 'Address Line 2 ',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'PIN_CODE',
                title: 'Pin Code',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'DISTRICT',
                title: 'District',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'STATE',
                title: 'State',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'COUNTRY',
                title: 'Country',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            }
        ]
    }
];

export const ASSIGNMENT_DETAILS_SEARCH = [
    {
        titleCode: 'ASSIGNMENT_DETAILS_HEADING',
        heading: 'Assignment',
        isEditable: true,
        type: 'assignment',
        multisection: 'assignments',
        formData: [
            {
                titleCode: 'ASSIGNED_FROM_DATE',
                title: 'Assigned From Date',
                field: 'fromDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'ASSIGNED_TO_DATE',
                title: 'Assigned To Date',
                field: 'toDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            },
            {
                titleCode: 'CURRENTLY_ASSIGNED_HERE',
                title: 'Currently Assigned Here',
                field: 'isCurrentAssignment',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'DEPARTMENT',
                title: 'Department',
                field: 'department',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'DESIGNATION',
                title: 'Designation',
                field: 'designation',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'ROLES',
                title: 'Roles',
                field: 'roles',
                type: CONSTANT_DATA_FIELD.MULTI_SELECT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            }
        ]
    }
];

export const EDUCATIONAL_DETAILS_SEARCH = [
    {
        titleCode: 'EDUCATIONAL_DETAILS_HEADING',
        heading: 'Educational Details',
        isEditable: true,
        type: 'educational',
        multisection: 'educational',
        formData: [
            {
                titleCode: 'QUALIFICATION',
                title: 'Qualification',
                field: 'qualification',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'STREAM',
                title: 'Stream',
                field: 'stream',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'YEAR_OF_PASSING',
                title: 'Year of Passing',
                field: 'yearOfPassing',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'UNIVERSITY',
                title: 'University',
                field: 'university',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'REMARKS',
                title: 'Remarks',
                field: 'remarks',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true
            }
        ]
    }
];

export const PROFESSIONAL_DETAILS_SEARCH = [
    {
        titleCode: 'PROFESSIONAL_DETAILS_HEADING',
        heading: 'Professional Details',
        isEditable: true,
        type: 'professional',
        formData: [
            {
                titleCode: 'APPOINTMENT_DATE',
                title: 'Appointment Date',
                field: 'appointmentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'EMPLOYMENT_TYPE',
                title: 'Employment Type',
                field: 'empType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'STATUS',
                title: 'Status',
                field: 'status',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            }
        ]
    }
];

export const DOCUMENTS_DETAILS_SEARCH = [
    {
        titleCode: 'DOCUMENTS_HEADING',
        heading: 'Documents',
        isEditable: true,
        formData: [
            {
                titleCode: 'IDENTITY_PROOF',
                title: 'Identity Proof',
                field: 'identityProof',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_PROOF',
                title: 'Address Proof',
                field: 'addressProof',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                isEditable: true,
                isRequired: true
            },
            {
                titleCode: 'JOINING_APPOINTMENT_LETTER',
                title: 'Joining/Appointment Letter',
                field: 'joiningLetter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                fileType: 'letterDetailsDropdown',
                module: 'Employee',
                isRequired: true,
                isEditable: true
            }
        ]
    }
];
export const NEW_USER_DASBOARD_BUTTON = [
    {
        titleCode: 'APPLY_FOR_PLOT_VIA_ADVERTISEMENT',
        btnTitle: 'Apply for Plot via Advertisement',
        btnURL: '/goa-idc/estate/apply-for-plot'
    },
    {
        titleCode: 'APPLY_FOR_PLOT_VIA_IPB',
        btnTitle: 'Apply for Plot via IPB',
        btnURL: '/goa-idc/estate/allotment?ipb=true'
    },
    {
        titleCode: 'APPLY_FOR_PLOT_VIA_AUCTION',
        btnTitle: 'Apply for Plot via Auction',
        btnURL: '/goa-idc/estate/allotment?auction=true'
    }
];

export const searchParam = [
    {
        formData: [
            { title: 'Plot No', titleCode: 'PLOT_NO', field: 'plotNo', type: CONSTANT_DATA_FIELD.SELECT_FIELD, class: 'col-sm-4' },
            { title: 'Estate', titleCode: 'ESTATE', field: 'estate', type: CONSTANT_DATA_FIELD.SELECT_FIELD, class: 'col-sm-4' },
            { title: 'Phase', titleCode: 'PHASE', field: 'phase', type: CONSTANT_DATA_FIELD.SELECT_FIELD, class: 'col-sm-4' }
        ]
    }
];

export const deedsColumns = [
    { colName: 'SN', fieldName: 'serialNo', clsName: 'col-sm-1' },
    { colName: 'Indenture Type', fieldName: 'indentureType', titleCode: 'INDENTURE_TYPE', clsName: 'col-sm-4 column-text-start' },
    { colName: 'Document', fieldName: 'document', titleCode: 'DOCUMENT', clsName: 'col-sm-4 column-text-start', isClickable: true },
    { colName: 'Date', fieldName: 'date', titleCode: 'DATE', clsName: 'col-sm-3 column-text-start' }
];

export const orderColumns = [
    { colName: 'SN', fieldName: 'serialNo', clsName: 'col-sm-1' },
    { colName: 'Order Type', fieldName: 'orderType', titleCode: 'ORDER_TYPE', clsName: 'col-sm-4 column-text-start' },
    { colName: 'Order No', fieldName: 'orderNo', titleCode: 'ORDER_NO', clsName: 'col-sm-4 column-text-start', isClickable: true },
    { colName: 'Date', fieldName: 'date', titleCode: 'DATE', clsName: 'col-sm-3 column-text-start' }
];

export const otherDocsColumn = [
    { colName: 'SN', fieldName: 'serialNo', clsName: 'col-sm-1' },
    { colName: 'Document Type', fieldName: 'documentType', titleCode: 'DOCUMENT_TYPE', clsName: 'col-sm-4 column-text-start' },
    { colName: 'Document Name', fieldName: 'docFileName', titleCode: 'DOCUMENT_NAME', clsName: 'col-sm-4 column-text-start', isClickable: true },
    { colName: 'Uploaded Date', fieldName: 'uploadedDate', titleCode: 'UPLOADED_DATE', clsName: 'col-sm-3 column-text-start' }
];

export const downloadParam = [
    {
        title: 'Receipts',
        titleCode: 'RECEIPTS',
        formData: [
            // { title: 'Payment', titleCode: 'PAYMENT', field: 'payment', type: CONSTANT_DATA_FIELD.SELECT_FIELD, class: 'col-sm-4' },
            { title: 'From Date', titleCode: 'FROM_DATE', field: 'fromDate', type: CONSTANT_DATA_FIELD.DATE_FIELD, class: 'col-sm-4', isRequired: true },
            { title: 'To Date', titleCode: 'TO_DATE', field: 'toDate', type: CONSTANT_DATA_FIELD.DATE_FIELD, class: 'col-sm-4', isRequired: true }
        ]
    }
];
