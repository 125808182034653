import React from 'react'
import { Card } from 'react-bootstrap'
import style from './TechnicalClearencePayment.module.css'

const GenericPayment = (props: any) => {
  let amounts = props.paymentdata.map((val: any) => val.value)

  let TotalAmount = 0;
  for (let a = 0; a < amounts.length; a++) {
    TotalAmount += parseInt(amounts[a], 10)
  }

  return (
    <>
      <h5 className={` ms-4 mt-3  ${style.Payemnt_Heading}`}>{props.heading}</h5>

      {props.paymentdata.map((val: any) =>
        <>
          <div className="col-12 mb-2 position-relative  ">
            <span className='ms-4'>{val.title}</span>
            <span className='position-absolute start-50 ms-5'><span className='me-5'>:</span>₹ {val.value.toLocaleString('en-IN')}</span>
          </div>
        </>
      )}
      <div className="col-12 mb-2 position-relative  ">
        <span className='ms-4 fw-bolder fs-5'>Total</span>
        <span className='position-absolute fw-bolder fs-5 start-50 ms-5'><span className='me-5'>:</span>₹ {TotalAmount.toLocaleString('en-IN')}</span>
      </div>




    </>
  )
}

export default GenericPayment
