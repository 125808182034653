import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { SEARCH_WO_FIELDS, CONTRACT_DATA_COLUMN } from './ContractCreation.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { Card, CardBody } from 'react-bootstrap';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { getFirstErrorKey } from '../../../../utils/utils';
import { cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import { endpoints, estateEndpoints, getDropdownOptions, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/portalComponents/Loading.component';

const ContractCreationSearchComp = () => {
    const { t } = useTranslation();
    const [modalDetails, setModalDetails] = useState<any>({});
    const [searchData, setSearchData] = useState<[]>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [selectRowId, setSelectRowId] = useState<any>(null);
    const [woNumber, setWoNumber] = useState<string>('');
    const [selectedLoaRowData, setSelectedLoaRowData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userUuid = getUserDetails?.uuid;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {}
    });
    const { values, errors, setValues, setFieldTouched } = formik;

    const toGetEstateDropdown = async () => {
        const { data } = await POST(estateEndpoints.estateSearch, { RequestInfo: requestInfo() });
        const estateDropdown = data?.estateMasterDetails?.map((val: any) => {
            return { name: val?.estateName, code: val?.uuid };
        });
        setDropdownData((prev: any) => ({ ...prev, estate: estateDropdown }));
    };

    useEffect(() => {
        dprDropdownDataOptions()
            .then(res => {
                setDropdownData(res);
            })
            .catch(err => console.log(err));
        toGetEstateDropdown();
    }, []);
    const handleSearchDpr = async () => {
        try {
            if (!values.woNumber && !values.estate?.code) {
                setModalDetails({
                    show: true,
                    title: 'Error!',
                    para: t('WO_OR_ESTATE_REQUIRED', 'Please provide either a Work Order Number or select an Estate.'),
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
                return;
            }
            try {
                setIsLoading(true);
                const { data } = await POST(
                    endpoints.workOrderSearch + `${values.woNumber ? `?applicationNos=${values.woNumber}&` : '?'}estateId=${values?.estate?.code || ''}&status=APPROVED&filterForContractCreate=true`,
                    {
                        RequestInfo: requestInfo()
                    }
                );
                if (!data?.workOrderModelList?.length) {
                    setIsLoading(false);
                    setModalDetails({
                        show: true,
                        title: 'No Data Found',
                        para: t('NO_DATA_AVAILABLE', 'There is no data available for the selected project Type.'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    setSearchData([]);
                    return;
                }
                const tableData =
                    data?.workOrderModelList?.length &&
                    data?.workOrderModelList?.map((val: any) => {
                        const projectType = getDropdownSelectedObject(val?.loaDetails?.dprHeaderDetails?.projectType, dropdownData['projectType'] || []);

                        return {
                            ...val,
                            woNumber: val?.applicationNo,
                            projectName: val?.loaDetails?.dprHeaderDetails?.projectName,
                            industrialName: val?.loaDetails?.dprHeaderDetails?.estateMasterDetails?.estateName,
                            projectType: projectType?.name,
                            estimateAmount: val?.loaDetails?.dprHeaderDetails?.estimatedTotalCost,
                            id: val?.uuid,
                            vendorName: val?.loaDetails?.bidderDetails?.name
                        };
                    });
                setSearchData(tableData);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something Went Wrong'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleRowClick = (row: any) => {
        setSelectRowId(row?.id);
        setWoNumber(row?.applicationNo);
        setSelectedLoaRowData(row);
    };

    const updatedColumn = CONTRACT_DATA_COLUMN?.map((value: any) => {
        if (value?.fieldName === 'radio') {
            return {
                ...value,
                selectedValue: selectRowId,
                onRadioClick: handleRowClick
            };
        } else return value;
    });

    const onClickInitiate = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            contractDetails: {
                workOrderApplicationNo: woNumber
            }
        };
        if (!selectRowId) {
            setModalDetails({ show: true, title: 'Error!', para: t('SELECT_LOA', 'No LOA selected For Work Order'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            const { data } = await POST(endpoints.createContract, payload);
            if (data?.contractModelList?.length) {
                navigate('/goa-idc/contract', { state: { selectedLoaRowData: selectedLoaRowData, contractDetails: data.contractModelList[0] } });
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.Errors?.[0]?.message || e.response?.data?.errorMessage || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const resetLoaFormFields = () => {
        setValues({
            ...values,
            estate: '',
            woNumber: ''
        });
        setSearchData([]);
    };
    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={t('SEARCH_WO_CREATION', `Search Work Order for Contract Creation`)} additionalClass='sub-text-style' />

                        <FormikProvider value={formik}>
                            <div>
                                <Card className='card_border my-4 pb-4 pe-4'>
                                    <CardBody>
                                        {SEARCH_WO_FIELDS.map((val: any) => {
                                            return (
                                                <>
                                                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                                </>
                                            );
                                        })}
                                    </CardBody>
                                    <div className='d-flex justify-content-end'>
                                        <GenericButtons text={'Reset'} handleClick={resetLoaFormFields} />
                                        <GenericButtons text={'Search'} variant='primary' handleClick={handleSearchDpr} />
                                    </div>
                                </Card>
                                <Card className='card_border row mx-1 p-4 pb-0'>
                                    <div className='font-20 project-primary-color lh-base mb-3'>Search Result</div>
                                    <GenericActionTable
                                        columns={updatedColumn}
                                        rowData={searchData}
                                        hideExportBtn={true}
                                        additionalMethod={() => {}}
                                        searchBarPlaceholder={t('WO_SEARCH_PARAM', 'Search by Work Order No., Vendor Name...')}
                                    />
                                </Card>
                            </div>
                        </FormikProvider>
                    </div>
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {<GenericButtons text={t('INITIATE_CONTRACT', 'Initiate Contract')} variant='primary' ClassName='fw-500 px-3 font-14' handleClick={onClickInitiate} />}
                            </div>
                        </div>
                    </footer>
                </>
            )}
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
        </>
    );
};

export default ContractCreationSearchComp;
