import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { PLOT_DETAILS, LESSEE_DETAILS, stepsDetails, stepsDetailsView } from './NOCManagement.const';
import ScrollToTopButton from '../../../../components/ScrollToTop';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { useTranslation } from 'react-i18next';
import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
import { COMMENTS, NOC_CREATION_PAGE } from '../../../../utils/formikValidation/FormikValidation';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import ClarificationSection from './Clarification';
import FireHealthNoc from '../../../../components/Health Noc/FireHealthNoc';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { POST, devUrl, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { fetchDropdownData } from '../../../../services/modulesServices/userManagement.api';
import { TENANT_ID, USER_TYPE } from '../../../../utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { check, cross, warning } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';

export default function NOC() {
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [showPopUp, setShowPopUp] = useState(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isVisited, setIsVisited] = useState<boolean>(false);
    const [isReset, setIsReset] = useState<boolean>(true);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [response, setResponse] = useState<any>([]);
    const [stepperDetails, setStepperDetails] = useState<any>(response.clarificationHistoryDetails?.length > 0 ? stepsDetailsView : stepsDetails);
    const [responseNocDetails, setResponseNocDetails] = useState<any>(null);
    const [responseClarificationHistory, setResponseClarificationHistory] = useState<any>(null);
    const [nocDocumentReferenceIds, setNocDocumentReferenceIds] = useState<any>(null);
    const [nocApplicationNo, setNocApplicationNo] = useState<any>(null);
    const userType = JSON.parse(localStorage.getItem('type') || '{}');
    const [isReply, setIsReply] = useState<any>({});
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [showMarkAsCompleteModal, setShowMarkAsCompleteModal] = useState<boolean>(false);
    const [showDownloadNocButton, setDownloadNocButton] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [modalDetails, setModalDetails] = useState<any>({});
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const applicationNo = searchParams.get('applicationNumber');
    const [replyCountAM, setReplyCountAM] = useState<number>(0);

    const formik = useFormik<any>({
        initialValues: {
            docDetails: [{ documentName: null, documentSize: null, referenceId: null, documentUrl: null, uploadedDate: null }],
            isUpdatable: true,
            replyPayload: {},
            replyCountLessee: 0
        },
        onSubmit: value => {},
        validationSchema: NOC_CREATION_PAGE[`page${activeIndex}`]
    });

    const modalFormik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},
        validationSchema: COMMENTS
    });

    const { values, setValues } = formik;

    const handleModalClose = (type: string, msg?: string) => {
        setModalDetails({});
        if (type === 'success') {
            if (msg === 'Existing user data erased') {
                window.location.reload();
            } else {
                navigate('/goa-idc/');
            }
        }
    };

    const fetchNOCDetails = async () => {
        try {
            const nextAction = await POST(endpoints.nextValidAction + `?businessId=${applicationNo}&tenantId=ga`, { RequestInfo: requestInfo() });
            setNextValidAction(nextAction.data);

            const { data } = await POST(`/noc-services/v2/noc/_search?tenantId=ga&applicationNo=${applicationNo}`, { RequestInfo: requestInfo() });
            if (!data) return;
            setResponse(data);
            const { entityName, email, phoneNumber, panNumber } = data.enterpriseEntity;
            setValues({ ...values, ...data.plotDetails, ...data.enterpriseEntity?.addresses[0], entityName, email, phoneNumber, panNumber });
            setResponseNocDetails(data.nocDetails);
            setResponseClarificationHistory(data.clarificationHistoryDetails);
            setNocDocumentReferenceIds(data.nocDocumentReferenceIds);
            setNocApplicationNo(data.applicationNo);
        } catch (e: any) {
            console.log(e);
            let errMsg = e.response?.data?.errorMessage || 'Something went wrong';

            setModalDetails({ show: true, title: errMsg, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        fetchNOCDetails();
    }, []);

    useEffect(() => {
        if (nocDocumentReferenceIds !== null) {
            if (nocDocumentReferenceIds.length !== 0) {
                setDownloadNocButton(true);
            } else {
                setDownloadNocButton(false);
            }
        }
    }, [response]);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const replyPayload = values?.replyPayload || {};
        if (Object.keys(replyPayload).length > 0) {
            const isCommentEmpty = Object.values(replyPayload).some((payload: any) => payload.comment.trim() === '');

            if (isCommentEmpty) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        }
    }, [values]);

    useEffect(() => {
        let count = 0;
        responseNocDetails?.map((nocDetails: any, index: number) => {
            nocDetails.comments?.map((comments: any, index: number) => {
                if (comments.replies.length === 0 || comments.replies[comments.replies.length - 1]?.commentedBy === 'Area Manager') {
                    count++;
                }
            });
        });
        setReplyCountAM(count);
    }, [response.nocDetails]);

    const handleSubmit = () => {
        const nocWorkflowDetails: any = [];
        const replyPayload = Object.values(values?.replyPayload);
        const action = userType === USER_TYPE.EMP ? 'RETURN_TO_LESSEE' : replyCountAM === values.replyCountLessee ? 'SUBMIT' : 'REPLY';

        let payload: any = {
            RequestInfo: requestInfo(),
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: 'NOC',
                    businessId: response.applicationNo,
                    moduleName: 'No Objection Certificate',
                    action: action
                }
            ]
        };

        replyPayload.map((item: any, index: number) => {
            const matchingNocDetail = response.nocDetails.find((nocDetail: any) => nocDetail.nocType === item.nocType);
            const documentDetails = values[item.docReferenceName];
            const nocId = matchingNocDetail ? matchingNocDetail.id : '';
            const tempVal = {
                nocApplicationNo: response.applicationNo || '',
                nocId: nocId,
                nocType: item.nocType,
                parentCommentId: item.parentId,
                comments: [
                    {
                        comment: item.comment,
                        nocDocuments: [
                            {
                                documentType: documentDetails?.documentType || '',
                                fileStoreId: documentDetails?.referenceId || ''
                            }
                        ]
                    }
                ]
            };
            nocWorkflowDetails.push(tempVal);
        });

        payload = { ...payload, nocWorkflowDetails: nocWorkflowDetails };

        POST(endpoints.initiateNocComment, payload)
            .then(res => {
                let msg = t('ACTION_SUCCESSFUL', 'Action successful');
                setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'success', reUpload: 'OK' });
                setResponseNocDetails(res.data.nocResponse.nocDetails);
                setResponseClarificationHistory(res.data.nocResponse.clarificationHistoryDetails);
            })
            .catch(e => {
                setModalDetails({ show: true, title: 'Error!', para: 'Action unsuccessful', img: cross, type: 'error', reUpload: 'OK' });
            });

        handleReset();
    };

    const renderPlotDetails = () => {
        return (
            <>
                {PLOT_DETAILS.map(val => (
                    <Card className='card_border'>
                        <Card.Body>
                            <GenericFormCard {...val} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const renderLesseeDetails = () => {
        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            {LESSEE_DETAILS.map(val => (
                                <GenericFormCard {...val} />
                            ))}
                        </Card.Body>
                    </Card>
                </>
            </div>
        );
    };

    const renderClarificationDetails = () => {
        return (
            <div>
                <Card className='card_border'>
                    <Card.Body>
                        <ClarificationSection
                            showResetSubmitBtn={showResetSubmitBtn}
                            isReset={isReset}
                            nocDetails={responseNocDetails}
                            clarificationHistoryDetails={responseClarificationHistory}
                            isReply={isReply}
                            setIsReply={setIsReply}
                            applicationNo={nocApplicationNo}
                        />
                    </Card.Body>
                </Card>
            </div>
        );
    };

    const onSubmit = async () => {
        try {
            onChangeNextIndex();
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || 'Please fill in all the required details!';
            setModalDetails({ show: true, title: errMsg, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const onChangeNextIndex = async () => {
        try {
            await NOC_CREATION_PAGE[`page${activeIndex}`].validate(values, { abortEarly: false });
            setActiveIndex(activeIndex + 1);
            window.scrollTo(0, 0);
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepperDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                await NOC_CREATION_PAGE[`page${activeIndex}`].validate(values, { abortEarly: false });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    useEffect(() => {
        if (response.clarificationHistoryDetails?.length > 0) {
            return setStepperDetails([
                ...stepsDetails,
                {
                    titleCode: 'CLARIFICATION',
                    name: 'Clarification',
                    index: 3,
                    isActive: true
                }
            ]);
        }
    }, [response.clarificationHistoryDetails]);

    const showResetSubmitBtn = (isVisited: any, val: any) => {
        setIsVisited(isVisited);
    };

    const handleReset = () => {
        setIsVisited(false);
        setIsReply({});
        setValues({ ...values, replyPayload: {}, replyCountLessee: 0 });
        setDisabled(true);
    };

    useEffect(() => {
        const dropdownPayload = {
            RequestInfo: {},
            MdmsCriteria: {
                tenantId: 'ga',
                moduleDetails: [
                    {
                        moduleName: 'NOC',
                        masterDetails: [
                            {
                                name: 'NocType',
                                filter: '[?(@.source == "COMPLETION_ORDER")]'
                            }
                        ]
                    }
                ]
            }
        };
        fetchDropdownData(dropdownPayload)
            .then((res: any) => {
                const dropdownOptions = {
                    nocType: res['NOC']['NocType']
                };
                setDropdownData(dropdownOptions);
            })
            .catch((err: any) => {
                let errMsg = err.response?.data?.errorMessage || 'Something went wrong';
                setModalDetails({ show: true, title: errMsg, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            });
    }, []);

    const onClose = () => {
        setShowPopUp(false);
    };

    const onMarkAsComplete = async (values: any) => {
        let payload = {
            RequestInfo: requestInfo(),
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: 'NOC',
                    businessId: response.applicationNo,
                    moduleName: 'No Objection Certificate',
                    action: 'MARK_AS_COMPLETE',
                    comment: values.comments
                }
            ]
        };

        try {
            await COMMENTS.validate(values, { abortEarly: false });
            POST(endpoints.initiateNocComment, payload)
                .then(res => {
                    let msg = t('ACTION_SUCCESSFUL', 'Action successful');
                    setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'success', reUpload: 'OK' });
                    onClose();
                    navigate('/goa-idc/dashboard');
                })
                .catch(e => {
                    let msg = t('ACTION_UNSUCCESSFUL', 'Action unsuccessful');
                    setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'success', reUpload: 'OK' });
                });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Comments are required', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const renderActionForm = () => {
        return (
            <FormikProvider value={modalFormik}>
                <form>
                    <div className='mx-2 my-1'>
                        <label htmlFor='comments' className='pb-2'>
                            {t('COMMENTS', 'Comments')}
                        </label>
                        <span className='mandatory-indicator'>*</span>
                        <Field name='comments' as='input' className='form-control' />
                        <ErrorMessage name='comments' component='div' className='text-danger font-12' />
                    </div>
                </form>
            </FormikProvider>
        );
    };

    const handleDownloadNoc = () => {
        const idsUrl = nocDocumentReferenceIds.join(',');
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${idsUrl}&filename=NOC_Documents`;
        window.open(downloadFileUrl);
    };

    useEffect(() => {
        if (userType === USER_TYPE.ENTERPRISE) {
        }
    }, [values.replyPayload]);

    return (
        <>
            <HeaderComponent />
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <div className='col-sm-12'>
                    <GenericHeader
                        bottomSubText={
                            <span style={{ color: 'gray' }}>
                                {' '}
                                {t('APPLICATION_NO', 'Application No')} : {response.applicationNo}
                            </span>
                        }
                        // subText={`${t('HOME', 'Home')} / ${t('OBPAS', 'Obpas')}`}
                        text={
                            <span>
                                {t('FIRE', 'Fire')}/{t('F_AND_B', 'F&B')}/{t('HEALTH_NOC', 'Health NOC')} <span style={{ fontSize: '17px', color: 'gray' }}>({response.sourceReferenceId})</span>
                            </span>
                        }
                    />
                </div>
                <div className='col-3 stickyCard-section   '>
                    <div className='stickyCard' style={{ top: '110px' }}>
                        <Card className={`card_border  hiddenNav  ${isVisible ? '' : 'scrolledNav'}`}>
                            <Card.Body>
                                <div className='d-flex align-items-center'>
                                    <GenericBackButton />
                                    <div className='ps-1'>
                                        {/* <Card.Subtitle className='text-muted text-nav'>{t('HOME_OBPAS', 'Home / OBPAS')}</Card.Subtitle> */}
                                        <Card.Text className='font-inter-med fs-5'>
                                            {t('FIRE', 'Fire')}/{t('F_AND_B', 'F&B')}/{t('HEALTH_NOC', 'Health NOC')}{' '}
                                            <span style={{ fontSize: '12px', color: 'gray', display: 'block' }}>({response.sourceReferenceId})</span>
                                            <span style={{ fontSize: '12px', color: 'gray', display: 'block' }}>
                                                {' '}
                                                {t('APPLICATION_NO', 'Application No')}: {response.applicationNo}
                                            </span>
                                        </Card.Text>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='card_border p-3'>
                            <Card.Body>
                                <Card.Text className=' d-flex flex-column row-gap-3  '>
                                    <SideCard steps={stepperDetails} activeIndex={activeIndex} handleStepClick={handleStepClick} />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        <Card className='card_border note'>
                            <Card.Body>
                                <Card.Text>
                                    <span>
                                        <i className='fa fa-exclamation-circle' aria-hidden='true'></i>
                                    </span>
                                    <b> {t('NOTE', 'Note')}</b>
                                    {t('INFO', 'If there is any information important to the user before submitting to be displayed here.')}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <div className='col-9 form-section'>
                    <FormikProvider value={formik}>
                        <Form>
                            {activeIndex === 1 && renderPlotDetails()}
                            {activeIndex === 2 && renderLesseeDetails()}
                            {activeIndex === 3 && renderClarificationDetails()}
                        </Form>
                    </FormikProvider>
                </div>
            </div>
            <ScrollToTopButton />

            <footer className='footer fixed-bottom footerBtnPatch'>
                <div className='d-flex'>
                    <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                        {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', ' Previous')} handleClick={onChangePrevIndex} />}
                    </div>
                    <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                        {userType === USER_TYPE.EMP && !isVisited && activeIndex === stepperDetails.length && (
                            <GenericButtons text={t('SEND_TO_LESSEE', 'Send to Lessee')} handleClick={() => setShowPopUp(true)} />
                        )}
                        {(userType === USER_TYPE.ENTERPRISE || isVisited) && activeIndex === 3 && (
                            <>
                                <GenericButtons
                                    text={t('RESET', 'Reset')}
                                    handleClick={() => {
                                        handleReset();
                                    }}
                                />
                                <GenericButtons
                                    text={t('SUBMIT', 'Submit')}
                                    handleClick={() => {
                                        handleSubmit();
                                    }}
                                    disabled={disabled}
                                />
                            </>
                        )}
                        {!isVisited && (
                            <>
                                {userType === USER_TYPE.EMP && activeIndex === stepperDetails.length && (
                                    <GenericButtons text={t('MARK_AS_COMPLETE', 'Mark as complete')} handleClick={() => setShowMarkAsCompleteModal(true)} />
                                )}
                                {activeIndex !== stepperDetails.length && <GenericButtons text={t('NEXT', 'Next')} variant='primary' handleClick={onSubmit} />}
                                {userType === USER_TYPE.EMP && activeIndex === stepperDetails.length && showDownloadNocButton && (
                                    <GenericButtons text={t('DOWNLOAD_NOC', 'Download NOC')} variant='primary' handleClick={handleDownloadNoc} />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </footer>
            <GenericPopupModal
                isPrimaryBtn={false}
                isSecondaryBtn={false}
                secondaryAction={() => setShowPopUp(false)}
                primaryAction={() => {}}
                isVisible={showPopUp}
                children={<FireHealthNoc name={t('LESSEE', 'Lessee')} dropdownData={dropdownData} onClose={onClose} response={response} />}
                title={`${t('FIRE', 'Fire')}/${t('F_AND_B', 'F&B')}/${t('HEALTH_NOC', 'Health NOC')}`}
                subTitle={t('CLARIFICATION_REQUIRED', 'Clarification required')}
                size='md'
                display='d-none'
            />
            <GenericPopupModal
                primaryBtnTitle={t('MARK_AS_COMPLETE', 'Mark as complete')}
                primaryAction={() => onMarkAsComplete(modalFormik.values)}
                secondaryBtnTitle={t('CLOSE', 'Close')}
                secondaryAction={() => setShowMarkAsCompleteModal(false)}
                isVisible={showMarkAsCompleteModal}
                children={renderActionForm()}
                title=''
                subTitle={response.applicationNo}
                size='md'
            />
        </>
    );
}
