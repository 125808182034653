import { FormikProvider, useFormik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import GenericCommonTextField from '../../../../components/moduleCommonFormik/GenericCommonTextField.component';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { PROPOSED_ACTIVITY_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import SearchField from '../../../../components/moduleCommonFormik/SearchField';
import { useTranslation } from 'react-i18next';

const ProposedActivity = (props: any) => {
    const {t} = useTranslation();
    const { disabled } = props;
    const [responeData, setResponeData] = useState<any>([]);
    const [dropDownValue, setDropDownValue] = useState();

    const { values, setValues, setFieldValue, errors, setFieldTouched, handleChange } = useFormikContext<any>();

    const PROPOSED_ACTIVITY = [
        {
            formData: [
                {
                    title: 'Group',
                    field: 'group',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                },
                {
                    title: 'Class',
                    field: 'class',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                },
                {
                    title: 'Sub-Class',
                    field: 'subClass',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                }
            ]
        }
    ];
    const fetchOptions = async (e: any) => {
        handleChange(e);
        try {
            const { data } = await POST(`/idc-estate-service/nic-mst-details/_search?&description=${e}&types=SUB_CLASS`, { RequestInfo: requestInfo() });
            setResponeData(data.nicMstDetails);
            return data.nicMstDetails.map((option: any) => ({
                value: option.subClassCode,
                label: option.subClassDescription
            }));
        } catch (e) {
            console.log(e);
        }
    };
    const customHandleChange = (val: any, field: any) => {
        console.log(val);
        if (val) {
            const checkCode = responeData?.find((item: any) => item.subClassCode === (val.value || val.code));
            if (checkCode) {
                setValues({
                    ...values,
                    description: val,
                    subClass: checkCode?.subClassCode || '',
                    class: checkCode?.classCode || '',
                    group: checkCode?.groupCode || '',
                    nicMstId: checkCode?.uuid || null
                });
            }
        } else {
            setValues({
                ...values,
                description: val,
                nicMstId: null,
                subClass: '',
                class: '',
                group: ''
            });
        }
    };
    console.log(values);
    return (
        <>
            <Form className=' bg-light p-4'>
                <div className='row mt-4'>
                    <p className='fs-6 lh-1 fw-semibold ' style={{ fontFamily: 'inter' }}>
                    {t('NIC_CODE', 'National Industrial Classification (NIC)')}  Code{' '}
                    </p>
                    <div className='col-sm-6 mt-3  '>
                        <SearchField
                            dropdownOptions={dropDownValue}
                            fetchOptions={fetchOptions}
                            customHandleChange={customHandleChange}
                            field={'description'}
                            title={'Description'}
                            disabled={disabled}
                            isRequired={true}
                        />
                    </div>
                    {PROPOSED_ACTIVITY[0].formData.map((val: any, ind: number) => (
                        <div className='col-sm-6 mt-3  '>
                            <GenericCommonTextField field={val.field} title={val.title} isDisabled={val.isDisabled} />
                        </div>
                    ))}
                </div>
                <div className='row pt-3'>
                    <div className='col-sm-6'>
                        <GenericCommonTextField field={'proposedActivity'} isRequired={true} title={'Proposed Activity'} isDisabled={disabled} />
                    </div>
                </div>
            </Form>
        </>
    );
};

export default ProposedActivity;
