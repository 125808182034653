import { toast } from 'react-toastify';
import { fetchDropdownData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { formOptionsDTO } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';

export const dprDropdownDataOptions = async () => {
    const dropdownPayload = {
        RequestInfo: requestInfo(),
        MdmsCriteria: {
            tenantId: 'ga',
            moduleDetails: [
                {
                    moduleName: 'works',
                    masterDetails: [
                        {
                            name: 'workProjectType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'workNatureType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'workMeasurementType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'workLBDMeasurementType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'workCostHead',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'contractWorkCategory',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'DeductionType',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'DeductionName',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                }
            ]
        }
    };
    try {
        const res = await fetchDropdownData(dropdownPayload);
        {
            const dropdownOptions = {
                projectType: res['works']['workProjectType'],
                natureOfWork: res['works']['workNatureType'],
                contingenciesCostHead: res['works']['workCostHead'],
                measurementType: res['works']['workLBDMeasurementType'],
                contractWorkCategory: res['works']['contractWorkCategory'],
                type: res['works']['DeductionType'],
                name: res['works']['DeductionName']
            };
            return dropdownOptions;
        }
    } catch (err) {
        toast('Error while fetching dropdown error', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo-bar',
            type: 'error'
        });
    }
};

export const getCalculatedValue = (mainVariable: any[]) => {
    let totalCost: any = 0;
    const toGetTotalCost = mainVariable.map((val: any) => {
        return !val.isDelete ? val?.cost : 0;
    });
    for (let num = 0; num < toGetTotalCost.length; num++) {
        totalCost += +toGetTotalCost[num];
    }
    return totalCost;
};
