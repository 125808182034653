import React from 'react';
import './Button.scss';

interface IGenericButtonsProps {
    disabled?: boolean;
    size?: string;
    text: any;
    variant?: string;
    type?: any;
    ClassName?: string;
    handleClick?: (arg0: any) => any;
}

const GenericButtons = (props: IGenericButtonsProps) => {
    const { disabled, size, text, variant, handleClick, ClassName, type } = props;
    return (
        <>
            {text && (
                <button
                    type={type ? type : 'button'}
                    disabled={disabled}
                    className={`${ClassName} ${size === 'sm' ? 'Button_small' : 'Button_medium'}`}
                    style={{ backgroundColor: `${variant === 'primary' ? '#45852E' : '#fff'}`, color: `${variant === 'primary' ? '#fff' : ''}` }}
                    onClick={handleClick}
                >
                    {text}
                </button>
            )}
        </>
    );
};

export default GenericButtons;
