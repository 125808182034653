import { Card, CardBody } from 'react-bootstrap';
import ApplicantDetails from '../../Allotment/ApplicantDetails';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { useEffect, useState } from 'react';
import { APPLICANT_DETAILS_I, APPLICANT_DETAILS_II, PROMOTER_DETAILS } from '../../Allotment/Allotment.const';
import { POST, endpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { getEntityData } from '../../Allotment/Allotment.controller';
import { TENANT_ID } from '../../../../../utils/constants';
import { dropdownDataOptions, getFormatedOwnershipDetails } from '../../../Migration/Migration.controller';
import { useFormik, useFormikContext } from 'formik';
import { SPV_FORM_VALIDATION } from '../../../../../utils/formikValidation/FormikValidation';
import { APPLICANT_DETAILS_2 } from '../SPV.const';
import { useTranslation } from 'react-i18next';

export const SPVCorrespondence = ({
    ownershipDetails,
    setOwnershipDetails,

    controllingOwnership,
    checkListRowData
}: any) => {
    const { t } = useTranslation();
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const [openSection, setOpenSection] = useState<string>('isAppDetails');
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [searchApplicationResponse, setSearchApplicationResponse] = useState<any>({});
    const [dropdownData, setDropdownData] = useState<any>(null);
    const CHECKLIST_TABLE = [
        {
            colName: 'SN',
            fieldName: 'serialNo',
            clsName: 'col-sm-1',
            id: 1
        },
        {
            colName: 'Condition',
            fieldName: 'checklistMessage',
            clsName: 'col-sm-8 column-text-start',
            id: 2
        },
        {
            colName: 'Status',
            fieldName: 'statusDisplayName',
            clsName: 'col-sm-3',
            id: 3
        }
    ];

    const { values, setValues } = useFormikContext<any>();

    useEffect(() => {
        dropdownDataOptions()
            .then(dropdownOption => {
                setDropdownData(dropdownOption);
                setApplicantData(dropdownOption);
            })
            .catch(error => {});
    }, []);

    const setApplicantData = async (dropdownData: any) => {
        try {
            const userId = JSON.parse(localStorage.getItem('user') || '{}')?.uuid;
            const { data } = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&userUuid=${userId}`, { RequestInfo: requestInfo() });
            if (data.enterpriseEntity.length > 0) {
                const res = await getEntityData(data.enterpriseEntity[0], dropdownData);
                setSearchApplicationResponse(data.enterpriseEntity[0]);
                setValues((prev: any) => ({
                    ...prev,
                    ...res
                }));
                const newOwnership = await getFormatedOwnershipDetails(data?.enterpriseEntity[0].owners, dropdownData);
                console.log('new ownership', newOwnership);
                setOwnershipDetails(newOwnership);
                console.log('ownershipppppppppppp', ownershipDetails);
                setControllingOwnershipResponse(data?.enterpriseEntity[0].owners);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const renderApplicantDetails = () => {
        return (
            <>
                <ApplicantDetails
                    controllingOwnershipResponse={controllingOwnership}
                    dropdownOptions={dropdownData}
                    setOwnershipDetails={setOwnershipDetails}
                    ownershipDetails={ownershipDetails}
                    showOwnershipModal={showOwnershipModal}
                    setShowOwnershipModal={setShowOwnershipModal}
                    applicantDetails1={APPLICANT_DETAILS_I}
                    applicantDetails2={APPLICANT_DETAILS_2}
                    promoterDetails={PROMOTER_DETAILS}
                    isDisabled={!values.isUpdatable}
                    scrollNotRequired={true}
                    isWorkflow={true}
                />
            </>
        );
    };

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                {t('CHECK_LIST', 'Condition/Check list')}
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                        <input type='checkbox' id='appDetailsCheckbox' name='appDetailsForm' checked={openSection === 'isAppDetails'} onChange={() => onSectionChange('isAppDetails')} />
                        <label
                            htmlFor='appDetailsCheckbox'
                            className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                        >
                            {t('A_APPLICATION_DETAILS', 'A - Applicant Details')}
                        </label>

                        {openSection === 'isAppDetails' && renderApplicantDetails()}
                    </div>
                </div>
            </Card>
        </>
    );
};
