import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { POST, endpoints, getEmployeeSearchTableData, getMenuData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/authSlice';
import { HierarchyNode, tableData } from '../types';
import { convertToHierarchyCard } from '../../../../utils/utils';
import DashboardCards from './DashboardCards';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { bellicon, checkcircleicon, dropicon, handcoinsicon, houselineicon, placeholdericon, warningicon } from '../../../../utils/Images';
import { DASHBOARD_TABLE_COLUMNS, DASHBOARD_TABLE_COLUMNS_ENTERPRISE } from '../UserManagement.const';
import { useTranslation } from 'react-i18next';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import NewUserDashboard from '../New Dashboard/NewUserDashboard';
import NewEnterpriseDashboard from '../New Dashboard/NewEnterpriseDashboard';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { cross } from '../../../../utils/Logo';

export default function EnterpriseDashboard() {
    const { t, i18n } = useTranslation();
    const [employeeSearchTableData, setEmployeeSearchTableData] = useState<any>(null);
    const [cardHierarchyData, setCardHierarchyData] = useState<HierarchyNode[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [data, setData] = useState<any>(null);
    const [conditions, setConditions] = useState<any>(null);

    const userData = useSelector(selectUser);
    const navigate = useNavigate();

    const userId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;

    const userName = `${userData?.firstName ? userData?.firstName : ''} ${userData?.lastName ? userData?.lastName : ''}`;

    // const enterpriseDashboardColumn = DASHBOARD_TABLE_COLUMNS?.filter((val: any) => val.fieldName !== 'entityName');

    // const updatedEnterpriseDashboardColumn = enterpriseDashboardColumn?.map((val: any) => {
    //     if (val.fieldName === 'currentOwner' || val.fieldName === 'deskPendencyDays') {
    //         return { ...val, clsName: 'col-sm-2 column-text-start' };
    //     } else {
    //         return val;
    //     }
    // });

    useEffect(() => {
        // setIsLoading(true);
        getEmployeeSearchTableData(userData)
            .then(res => {
                setEmployeeSearchTableData(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            });
    }, [userData]);

    useEffect(() => {
        (async () => {
            try {
                const menuData: any = await getMenuData();
                const cardHierarchicalArray: HierarchyNode[] = menuData.data.cards && convertToHierarchyCard(menuData.data.cards);
                setCardHierarchyData(cardHierarchicalArray);
                console.log(cardHierarchicalArray);
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    const handleRowClick = (rowData: tableData) => {
        const url = rowData.navUrl;
        navigate(url, { state: { rowData } });
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const { data } = await POST(endpoints.entityRefDetails + `?isPlotDetailsRequired=true&entityIds=${userId}`, { RequestInfo: requestInfo() });
                const plotData = data.entityRefDetails.filter((item: any) => codesToCheck.includes(item.type)).reverse();
                plotData && setData(plotData);

                const tempData = data.entityRefDetails.filter((item: any) => codesToCheckForCards.includes(item.type));
                setConditions(tempData);

                // setIsLoading(false);
            } catch (err: any) {
                console.error('Error fetching data:', err);
                setIsLoading(false);
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: errMsg, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/enterprisedashboard');
    };

    const codesToCheck = ['ALLOTTEE', 'LESSEE'];
    const codesToCheckForCards = ['ALLOTTEE', 'LESSEE', 'APPLICANT', 'TRANSFEREE_APPLICANT', 'TRANSFEREE'];

    return (
        <>
            <HeaderComponent />
            {!!isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row new-enterprise-dashboard new-dashboard px-5 pb-3 gx-0 justify-content-center ' style={{ backgroundColor: '#F5F5F5' }}>
                        <div className='col-12 mt-4 d-flex justify-content-between align-items-center ' style={{ height: '50px' }}>
                            <div>
                                <div className='font-16 fw-400 font-inter greeting '>Welcome</div>
                                <div className='font-28  font-inter user-name fw-bold '>{`${userName}`}</div>
                            </div>
                        </div>
                    </div>

                    {!conditions?.length ? (
                        <NewUserDashboard />
                    ) : (
                        <>
                            {userData?.roles?.some((item: any) => codesToCheck.includes(item.code)) && (
                                <NewEnterpriseDashboard data={data} modalDetails={modalDetails} setModalDetails={setModalDetails} />
                            )}
                            <div className='bg-light m-auto px-5 py-4 row enterprise-dashboard'>
                                <GenericActionTable
                                    columns={DASHBOARD_TABLE_COLUMNS_ENTERPRISE}
                                    rowData={employeeSearchTableData?.inboxs}
                                    additionalMethod={handleRowClick}
                                    searchBarPlaceholder='SEARCH_BY'
                                ></GenericActionTable>
                            </div>
                        </>
                    )}
                </>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
        </>
    );
}
