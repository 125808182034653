import * as Yup from 'yup';
import { CONSTANT_REGEX_SAMPLE } from '../../../utils/constants';

export const Clearance_Form_Initial_Data = {
    id: '',
    appId: '',
    name: '',
    gender: '',
    phone: '',
    panNo: '',
    pincode: '',
    district: '',
    state: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    arcName: '',
    certificate2: [],
    certificate1: [],
    serviceType: '',
    licenseeType: '',
    licenseeSubType: '',
    CouncilArcNo: '',
    buildingPlan: [],
    sitePlan: [],
    document1: [],
    document2: [],
    document3: [],
    token: '',
    cert: '',
    pin: '',
    documents: [],
    forwardTo: '',
    comments: '',
    DOCUMENT_QUESTIONNAIRE: [],
    DOCUMENT_AFFIDAVIT: [],
    DOCUMENT_POWER_ATTORNEY: [],
    CERTIFICATE_ARCHITECTURE: [],
    CERTIFICATE_LIABILITY: [],
    SITEPLAN: []
};

export const CLEARANCE_INITIAL_DATA = {
    id: '',
    appId: '',
    name: '',
    gender: '',
    phone: '',
    panNo: '',
    pincode: '',
    district: '',
    state: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    arcName: '',
    certificate2: [],
    certificate1: [],
    serviceType: '',
    licenseeType: '',
    licenseeSubType: '',
    CouncilArcNo: '',
    buildingPlan: [],
    sitePlan: [],
    document1: [],
    document2: [],
    document3: [],
    token: '',
    cert: '',
    pin: '',
    documents: [],
    forwardTo: '',
    comments: '',
    DOCUMENT_QUESTIONNAIRE: [],
    DOCUMENT_AFFIDAVIT: [],
    DOCUMENT_POWER_ATTORNEY: [],
    CERTIFICATE_ARCHITECTURE: [],
    CERTIFICATE_LIABILITY: [],
    SITEPLAN: []
};

export const VALIDATION_CLEARANCE_FORM: { [index: string]: any } = {
    step_10: Yup.object({
        name: Yup.string().label('Name'),
        phone: Yup.string()
            .min(10, 'Phone number should be at least 10 digit')
            .max(10, 'Phone number should not be more than 10 digit')
            .label('Phone number')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number '),
        gender: Yup.string().label('Gender'),
        panNo: Yup.string().required().label('Name'),
        // panNo: Yup.string()
        //   .min(10, 'PAN Number should be at least 10 digit')
        //   .max(10, 'PAN Number should not be more than 10 digit')
        //   .label('PAN Number')
        //   .matches(CONSTANT_REGEX_SAMPLE.ALPHANUMERIC, 'Only alphanumeric characters are allowed'),

        addressLine1: Yup.string().label('Address Line 1'),
        district: Yup.string().label('District'),
        state: Yup.string().label('State'),
        country: Yup.string().label('Country'),
        pincode: Yup.string().min(6, 'Pincode should be at least 6 digit').max(6, 'Pincode should not be more than 6 digit').label('Valid Pincode')
    }),

    step_1: Yup.object({
        archName: Yup.string().required().label('Name'),
        CERTIFICATE_ARCHITECTURE: Yup.array().min(1).required(),
        CERTIFICATE_LIABILITY: Yup.array().min(1).required()
    }),

    step_3: Yup.object({
        SITEPLAN: Yup.array().min(1).required().label('Submission Building Plan'),
        DOCUMENT_QUESTIONNAIRE: Yup.array().min(1).required()
    })
};

export const stepsDetailsForTechnicalClearance = [
    // {
    //     titleCode: 'LESSEE_DETAITLS',
    //     name: 'Lessee Details',
    //     code: 'LESSEE_DETAITLS',
    //     index: 1,
    //     isActive: true
    // },
    {
        titleCode: 'ARCHITECT_DETAITLS',
        name: "Architect's Details",
        code: 'ARCH_DETAITLS',
        index: 1,
        isActive: false
    },
    {
        titleCode: 'BUILDING_PLAN_SCRUTINY_REPORT',
        name: 'Building Plan Scrutiny Report',
        code: 'BUILDING_PLAN_REPORT',
        index: 2,
        isActive: false
    },
    // {
    //     titleCode: 'SUPPORTING_DOCUMENTS',
    //     name: 'Supporting Documents',
    //     code: 'DOCUMENTS',
    //     index: 3,
    //     isActive: false
    // },
    // {
    //   titleCode: "PAYMENT",
    //   name: "Fee details",
    //   code: "PAYMENT",
    //   index: 5,
    //   isActive: false
    // },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        code: 'DECLARATION',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'PAYMENT',
        name: 'Payment Details',
        code: 'PAYMENT',
        index: 4,
        isActive: false
    }
];

export const stepsDetailsForTechnicalClearanceWithout = [
    // {
    //     titleCode: 'LESSEE_DETAITLS',
    //     name: 'Lessee Details',
    //     code: 'LESSEE_DETAITLS',
    //     index: 1,
    //     isActive: true
    // },
    {
        titleCode: 'ARCH_DETAITLS',
        name: "Architect's Details",
        code: 'ARCH_DETAITLS',
        index: 1,
        isActive: false
    },
    {
        titleCode: 'BUILDING_PLAN_SCRUTINY_REPORT',
        name: 'Building Plan Scrutiny Report',
        code: 'BUILDING_PLAN_REPORT',
        index: 2,
        isActive: false
    },
    // {
    //     titleCode: 'SUPPORTING_DOCUMENTS',
    //     name: 'Supporting Documents',
    //     code: 'DOCUMENTS',
    //     index: 3,
    //     isActive: false
    // },
    {
        titleCode: 'PAYMENT',
        name: 'Payment details',
        code: 'PAYMENT',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        code: 'DECLARATION',
        index: 4,
        isActive: false
    }
];

// export const TCTableColumns = [
//     {
//         colName: 'Select',
//         fieldName: 'radio',
//         clsName: 'col-sm-1',
//         onRadioClick: onTransfereeSelect,
//         selectedValue: selectedOneTransferee
//     },
//     {
//         colName: 'DCR Number',
//         fieldName: 'dcrNumber',
//         clsName: 'col-sm-2',
//         isClickable: false
//     },
//     {
//         colName: 'Estate',
//         fieldName: 'estate',
//         clsName: 'col-sm-2'
//     },
//     {
//         colName: 'Plot Number',
//         fieldName: 'plotNumber',
//         clsName: 'col-sm-2'
//     },
//     {
//         colName: 'Architect Name',
//         fieldName: 'architectName',
//         clsName: 'col-sm-2'
//     },
//     {
//         colName: 'Shared Date',
//         fieldName: 'sharedDate',
//         clsName: 'col-sm-2'
//     },
// ];
