import React, { useState, useEffect } from 'react';
import style from '../features/modules/UserManagement/moduleComponents/table.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { ITenderTableData, tableData } from '../modules/UserManagement/types';
import HeaderComponent from '../../components/header/Header.component';
import FooterComponent from '../../components/portalComponents/Footer.component';
import { POST, getFileUrl } from '../../services/modulesServices/userManagement.api';
import { TENDOR_TABLE_COLUMNS } from '../modules/UserManagement/UserManagement.const';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import { useTranslation } from 'react-i18next';
import PopUps from '../../components/PopUps';
import { cross } from '../../utils/Logo';

export default function TenderPage(props: any) {
    const { t, i18n } = useTranslation();
    const [tenderDetails, setTenderDetails] = useState([]);
    const [modalDetails, setModalDetails] = useState({});

    useEffect(() => {
        POST('/egov-common-masters/tender/_search', { RequestInfo: {} })
            .then(res => {
                setTenderDetails(res.data.tenderDetails);
            })
            .catch(err => console.log(err));
    }, []);

    const onClickDownloadItem = async (rowData: ITenderTableData) => {
        try {
            const url = await getFileUrl(rowData.documentReferenceId);
            window.open(url, '_blank');
        } catch (e) {
            let errMsg = t('SOMETHING_WENT_WRONG', 'Something went wrong');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <HeaderComponent />
            <div className='mx-5 p-5 font-inter'>
                <div style={{ width: '764px', height: '14px' }}>
                    <p className='breadcrumbs'>
                        <Link className='text-decoration-none' to='/goa-idc/'>
                            {t('HOME', 'Home')}
                        </Link>{' '}
                        / {t('TENDERS', 'Tenders')}
                    </p>
                </div>
                <div className='col-md-8 mb-3' style={{ fontWeight: 500, fontSize: '48px', lineHeight: '76.8px', letterSpacing: -1 }}>
                    {t('TENDERS', 'Tenders')}
                </div>
                <GenericActionTable columns={TENDOR_TABLE_COLUMNS} rowData={tenderDetails} additionalMethod={onClickDownloadItem} searchBarPlaceholder='SEARCH_TENDER'></GenericActionTable>
                {/* <CommonTable titles={titles} data={tenderDetails} displayRowItem={displayRowItem} placeholder="Search by Description, Attachment no..." /> */}
            </div>

            <FooterComponent />
        </>
    );
}
