import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, FieldArray, Formik, FormikProvider, useFormik } from 'formik';
import GenericCommonTextAreaField from '../moduleCommonFormik/GenericCommonTextAreaField.component';
import { POST, endpoints, requestInfo } from '../../services/modulesServices/userManagement.api';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../UI components/Buttons/GenericButtons';
import { nocPayload } from '../../features/modules/UserManagement/types';
import { useNavigate } from 'react-router-dom';
import { TENANT_ID } from '../../utils/constants';
import * as Yup from 'yup';
import { NOC_COMMENTS } from '../../utils/formikValidation/FormikValidation';
import StatusAlertPopup from '../moduleCommonFormik/StatusAlertPopup';
import { check, cross } from '../../utils/Logo';

interface InocProps {
    name: string;
    dropdownData: any;
    onClose: any;
    response: any;
}
const FireHealthNoc = (props: InocProps) => {
    const { t } = useTranslation();
    const { name, dropdownData, onClose, response } = props;
    const [nocDropdownData, setNocDropdownData] = useState<any>(dropdownData);
    const [modalDetails, setModalDetails] = useState<any>({});
    const navigate = useNavigate();

    const formik = useFormik<any>({
        initialValues: {
            nocWorkflowDetailsInput: [{ nocType: null, comments: null }],
            isUpdatable: true
        },
        validationSchema: NOC_COMMENTS,
        onSubmit: () => {}
    });

    const { values, setValues } = formik;
    console.log('MOdal formik', formik);
    useEffect(() => {
        let selectedOptions: string[] = [];
        values.nocWorkflowDetailsInput.forEach((val: any, index: number) => {
            if (val.nocType) {
                selectedOptions.push(val.nocType.toString().toLowerCase());
            }
        });

        const filteredDropdownData: any = [];
        const allDropDownOptions = JSON.parse(JSON.stringify(dropdownData.nocType));
        allDropDownOptions.forEach((option: any) => {
            if (!selectedOptions.includes(option.code.toString().toLowerCase())) {
                filteredDropdownData.push(option);
            }
        });
        const filteredOption = { nocType: filteredDropdownData };
        console.log('selectedOptions', selectedOptions, filteredOption);
        setNocDropdownData(filteredOption);
    }, [values]);

    const handleModalClose = (type: string, msg?: string) => {
        setModalDetails({});
        if (type === 'success') {
            if (msg === 'Existing user data erased') {
                window.location.reload();
            } else {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const onSendBack = async () => {
        const nocWorkflowDetails: any = [];
        const nocPayloadVal = values?.nocWorkflowDetailsInput || [];

        let payload: nocPayload = {
            RequestInfo: requestInfo(),
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: 'NOC',
                    businessId: response.applicationNo,
                    moduleName: 'No Objection Certificate',
                    action: 'RETURN_TO_LESSEE'
                }
            ]
        };

        nocPayloadVal.map((item: any, index: number) => {
            const matchingNocDetail = response.nocDetails.find((nocDetail: any) => nocDetail.nocType === item.nocType);
            const nocID = matchingNocDetail ? matchingNocDetail.id : '';
            const tempVal = {
                nocApplicationNo: response.applicationNo || '',
                nocId: nocID || '',
                nocType: item.nocType,
                comments: [
                    {
                        comment: item.comments
                    }
                ]
            };
            nocWorkflowDetails.push(tempVal);
        });

        payload = { ...payload, nocWorkflowDetails: nocWorkflowDetails };

        try {
            await NOC_COMMENTS.validate(formik.values, { abortEarly: false });
            POST(endpoints.initiateNocComment, payload)
                .then(res => {
                    let msg = t('ACTION_SUCCESSFUL', 'Action successful');
                    setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'success', reUpload: 'OK' });
                    onClose();
                })
                .catch(e => {
                    let msg = t('ACTION_UNSUCCESSFUL', 'Action unsuccessful');
                    setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'success', reUpload: 'OK' });
                });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Comments are required', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleClick = (push: any) => {
        let selectedOptions: string[] = [];
        values.nocWorkflowDetailsInput.forEach((val: any, index: number) => {
            selectedOptions.push(val.nocType);
        });
        push({ nocType: '', comments: '' });
    };

    return (
        <>
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.para)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.para)}
            />
            <div className='container clarification-body' style={{ background: '#F5F5F6' }}>
                <div className='row px-2 justify-content-center  '>
                    <div className='mb-3'>
                        <p className='noc_heading mb-0 font-12 '>{t('SEND_TO', 'Send to')}</p>
                        <h4 className='noc_title m-0 font-12'>{name}</h4>
                    </div>
                    <>
                        <FormikProvider value={formik}>
                            <FieldArray name='nocWorkflowDetailsInput'>
                                {({ insert, remove, push }) => (
                                    <div>
                                        {values.nocWorkflowDetailsInput.map((subField: any, index: number) => {
                                            let nocTypeOptions = nocDropdownData?.nocType?.length > 0 ? JSON.parse(JSON.stringify(nocDropdownData?.nocType)) : [];
                                            let selectedNocType = dropdownData?.nocType?.find((dD: any) => dD.code === subField.nocType);
                                            const isSelected = nocTypeOptions?.find((x: any) => x.code === selectedNocType?.code);

                                            if (selectedNocType && !isSelected) {
                                                nocTypeOptions.push(selectedNocType);
                                            }
                                            return (
                                                <div key={index}>
                                                    <div className='mb-3'>
                                                        <label htmlFor={`nocWorkflowDetailsInput[${index}].nocType`} className='font-12 pb-2'>
                                                            {t('NOC_TYPE', 'NOC Type')}
                                                            <span className=' text-danger '>*</span>
                                                        </label>
                                                        <Field name={`nocWorkflowDetailsInput[${index}].nocType`} as='select' className={`form-control`} disabled={!values.isUpdatable}>
                                                            <option value='' disabled selected hidden>
                                                                {t('PLEASE_SELECT', 'Please Select')}
                                                            </option>

                                                            {nocTypeOptions.map((val: any) => (
                                                                <option value={val.code}>{val.name}</option>
                                                            ))}
                                                        </Field>
                                                        <span className='text-danger font-12'>
                                                            <ErrorMessage name={`nocWorkflowDetailsInput[${index}].nocType`} />
                                                        </span>
                                                    </div>
                                                    <div className='mb-3'>
                                                        <label htmlFor={`nocWorkflowDetailsInput[${index}].comments`} className='font-12 pb-2'>
                                                            {t('COMMENTS', 'Comments')}
                                                        </label>
                                                        <GenericCommonTextAreaField field={`nocWorkflowDetailsInput[${index}].comments`} title='' isRequired={true} />
                                                    </div>
                                                    <div className='d-flex justify-content-end'>
                                                        <button
                                                            type='button'
                                                            className='col-sm-12 mt-2  mb-2 d-flex justify-content-center align-items-center  noc_add_fields'
                                                            onClick={() => remove(index)}
                                                        >
                                                            - {t('REMOVE_FIELD', 'Remove Field')}
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <button className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center  noc_add_fields' onClick={() => handleClick(push)}>
                                            + {t('ADD_FIELD', 'Add Field')}
                                        </button>
                                    </div>
                                )}
                            </FieldArray>
                        </FormikProvider>
                        {/* )} */}
                    </>
                </div>
            </div>
            <footer className='d-flex justify-content-end  container' style={{ padding: 15 }}>
                <div className='d-flex'>
                    <GenericButtons text={t('CLOSE', 'Close')} handleClick={onClose} />
                    <GenericButtons type='Submit' text={t('SEND', 'Send')} variant='primary' handleClick={onSendBack} />
                </div>
            </footer>
        </>
    );
};

export default FireHealthNoc;
