import React from 'react';

const ArrearBreakdownPopUp = (props: any) => {
    const { rowData } = props;
    console.log('aaaaaaaaaa', rowData);
    return (
        <>
            <div className='col-12 arrear-breakdown p-4 font-inter'>
                <div className='  main fw-500 d-flex font-16 mb-2 justify-content-between'>
                    <span>Arrear Principal</span>
                    <span>{rowData?.arrearPrincipalAmount}</span>
                </div>
                {rowData?.arrearInterestAmount ? (
                    <div className=' sub-main  d-flex font-14 mb-2 justify-content-between'>
                        <span>Interest Amount</span>
                        <span>:</span>
                        <span>{rowData?.arrearInterestAmount}</span>
                    </div>
                ) : (
                    <></>
                )}
                {rowData?.arrearPenaltyAmount ? (
                    <div className=' sub-main  d-flex font-14 mb-2 justify-content-between'>
                        <span>Penalty Amount</span>
                        <span>:</span>
                        <span>{rowData?.arrearPenaltyAmount}</span>
                    </div>
                ) : (
                    <></>
                )}
                {rowData?.arrearcgstPrincipal ? (
                    <div className=' sub-main  d-flex font-14 mb-2 justify-content-between '>
                        <span>CGST on Arrear</span>
                        <span>:</span>
                        <span>{rowData?.arrearcgstPrincipal}</span>
                    </div>
                ) : (
                    <></>
                )}
                {rowData?.arrearsgstPrincipal ? (
                    <div className=' sub-main  d-flex font-14 mb-2 justify-content-between'>
                        <span>SGST on Arrear</span>
                        <span>:</span>
                        <span>{rowData?.arrearsgstPrincipal}</span>
                    </div>
                ) : (
                    <></>
                )}
                {rowData?.arrearcgstPenalty ? (
                    <div className=' sub-main  d-flex font-14 mb-2 justify-content-between '>
                        <span>CGST on Arrear Penalty</span>
                        <span>:</span>
                        <span>{rowData?.arrearcgstPenalty}</span>
                    </div>
                ) : (
                    <></>
                )}
                {rowData?.arrearsgstPenalty ? (
                    <div className=' sub-main  d-flex font-14 mb-2 justify-content-between'>
                        <span>SGST on Arrear Penalty</span>
                        <span>:</span>
                        <span>{rowData?.arrearsgstPenalty}</span>
                    </div>
                ) : (
                    <></>
                )}
                <div className='  total fw-500 d-flex font-16 mb-2 justify-content-between'>
                    <span> Total </span>
                    <span className=''>:</span>
                    <span>{rowData?.totalArrearDueAmount}</span>
                </div>
            </div>
        </>
    );
};

export default ArrearBreakdownPopUp;
