import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../utils/constants';
import { DOCUMENT_TYPE, DOCUMENT_TYPES_MGR } from '../modules/Migration/Migration.const';

export const pdfUrl = [];
export const INITIAL_SELECT_DATA = {
    tenantId: 'km',
    moduleName: 'BPA',
    TechnicalPerson: [
        {
            name: 'Architect',
            title: 'Architect',
            code: 'C.A',
            active: true,
            orderNumber: '1',
            description: 'Architect',
            workflow: null,
            document: [],
            subcategory: []
        },
        {
            name: 'LBS',
            title: 'LBS',
            code: 'LBS',
            active: true,
            orderNumber: '2',
            description: 'LBS',
            workflow: 'TPOA',
            document: [],
            subcategory: [
                {
                    name: 'Class l',
                    title: 'Class l',
                    code: 'l',
                    active: true,
                    orderNumber: '1',
                    description: 'LBS l',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class ll',
                    title: 'Class ll',
                    code: 'll',
                    active: true,
                    orderNumber: '2',
                    description: 'LBS ll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class lll',
                    title: 'Class lll',
                    code: 'lll',
                    active: false,
                    orderNumber: '3',
                    description: 'LBS lll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                }
            ]
        },
        {
            name: 'ESE',
            title: 'ESE',
            code: 'ESE',
            active: true,
            orderNumber: '3',
            description: 'ESE',
            workflow: 'TPOA',
            document: [],
            subcategory: [
                {
                    name: 'Class l',
                    title: 'Class l',
                    code: 'l',
                    active: true,
                    orderNumber: '1',
                    description: 'ESE l',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class ll',
                    title: 'Class ll',
                    code: 'll',
                    active: true,
                    orderNumber: '2',
                    description: 'ESE ll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class lll',
                    title: 'Class lll',
                    code: 'lll',
                    active: false,
                    orderNumber: '3',
                    description: 'ESE lll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                }
            ]
        },
        {
            name: 'GTE',
            title: 'GTE',
            code: 'GTE',
            active: true,
            orderNumber: '4',
            description: 'GTE',
            workflow: 'TPOA',
            document: [],
            subcategory: [
                {
                    name: 'Class l',
                    title: 'Class l',
                    code: 'l',
                    active: true,
                    orderNumber: '1',
                    description: 'GTE l',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class ll',
                    title: 'Class ll',
                    code: 'll',
                    active: true,
                    orderNumber: '2',
                    description: 'GTE ll',
                    workflow: 'TPOA',
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class lll',
                    title: 'Class lll',
                    code: 'lll',
                    active: false,
                    orderNumber: '3',
                    description: 'GTE lll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                }
            ]
        },
        {
            name: 'ESR',
            title: 'ESR',
            code: 'ESR',
            active: true,
            orderNumber: '5',
            description: 'ESR',
            workflow: 'TPOA',
            document: [],
            subcategory: [
                {
                    name: 'Class l',
                    title: 'Class l',
                    code: 'l',
                    active: true,
                    orderNumber: '1',
                    description: 'ESR l',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class ll',
                    title: 'Class ll',
                    code: 'll',
                    active: true,
                    orderNumber: '2',
                    description: 'ESR ll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                },
                {
                    name: 'Class lll',
                    title: 'Class lll',
                    code: 'lll',
                    active: false,
                    orderNumber: '3',
                    description: 'ESR lll',
                    workflow: 'TPOA',
                    validYear: [
                        {
                            code: 1,
                            name: '1 year',
                            active: true
                        },
                        {
                            code: 3,
                            name: '3 year',
                            active: true
                        }
                    ],
                    document: [
                        {
                            code: 'APPL.IDENTITYPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.OWNERIDPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.ADDRESSPROOF',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PANCARD',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.PHOTO',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EDUCATIONALCERTIFICATE',
                            required: true,
                            allow: true,
                            order: 1
                        },
                        {
                            code: 'APPL.EXPERIENCELETTER',
                            required: true,
                            allow: true,
                            order: 1
                        }
                    ],
                    subcategory: []
                }
            ]
        }
    ]
};

export const LESSEE_DETAILS: FormDataDTO[] = [
    {
        title: 'Lessee Details',
        titleCode: 'ARC_CARD_HEADER_LESSEE_DETAILS',
        formData: [
            {
                title: 'Name',
                titleCode: 'ARC_LBL_LESSEE_NAME',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isRequired: true
            },
            {
                title: 'Email ID',
                titleCode: 'ARC_LBL_LESSEE_EMAILID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isRequired: true
            }
            // {
            //     title: 'Estate',
            //     titleCode: 'ARC_LBL_LESSEE_ESTATE',
            //     field: 'estate',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isDisabled: true,
            //     isRequired: true
            // }
        ]
    }
];

export const PLOT_DETAILS: FormDataDTO[] = [
    {
        title: 'Plot Details',
        titleCode: 'ARC_CARD_HEADER_PLOT_DETAILS',
        formData: [
            {
                title: 'Estate',
                titleCode: 'ARC_LBL_LESSEE_ESTATE',
                field: 'estate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                isRequired: true
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'ARC_LBL_PHASE',
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Plot No',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'ARC_LBL_LESSEE_PLOTNO',
                isDisabled: true,
                isRequired: true
            }
        ]
    }
];

export const ARCHITECT_DETAILS: FormDataDTO[] = [
    {
        title: 'Architect Details',
        titleCode: 'ARC_CARD_HEADER_ARCHITECT_DETAILS',
        formData: [
            {
                title: 'Architect Name',
                titleCode: 'ARC_LBL_ARCHITECT_NAME',
                field: 'archName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: false,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Certificate from the architect/engineer',
                titleCode: 'ARC_UPLD_ARCHITECT_CERT',
                field: 'CERTIFICATE_ARCHITECTURE',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                // class: 'sm',
                isDisabled: false,
                // width: 'half',
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ARCHITECT,
                    documentType: DOCUMENT_TYPES_MGR.SCRUTINY_DOCUMENTS.CERTIFICATE_ARCHITECTURE
                },
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Certificate from the architect/engineer who has planned the project and signed the drawing</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            },
            {
                title: 'Structural Liability Certificate',
                titleCode: 'ARC_UPLD_ARCHITECT_STRUCTURAL_CERT',
                field: 'CERTIFICATE_LIABILITY',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                // class: 'sm',
                isRequired: true,
                isDisabled: false,
                // width: 'half',
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ARCHITECT,
                    documentType: DOCUMENT_TYPES_MGR.SCRUTINY_DOCUMENTS.CERTIFICATE_LIABILITY
                },
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Structural Liability Certificate from the engineer responsible for structural design of the project</p>'
                    // linkUrl: 'https://www.pollution.com',
                    // linkText: 'Website Link'
                }
            }
            // {
            //     title: 'Site Plan',
            //     titleCode: 'ARC_UPLD_ARCHITECT_SITEPLAN_CERT',
            //     field: 'SITEPLAN',
            //     type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
            //     // class: 'sm',
            //     isRequired: true,
            //     isDisabled: false,
            //     // width: 'half',
            //     entityFileData: {
            //         documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ARCHITECT,
            //         documentType: DOCUMENT_TYPES_MGR.SCRUTINY_DOCUMENTS.CERTIFICATE_LIABILITY
            //     },
            //     toolTipDetails: {
            //         position: 'bottom',
            //         content: '<p>This is system generated site plan for the scrutinized building plan.</p>'
            //         // linkUrl: 'https://www.pollution.com',
            //         // linkText: 'Website Link'
            //     }
            // }
        ]
    }
];

export const BUILDING_PLAN_DETAILS: FormDataDTO[] = [
    {
        title: 'Plan Scrutiny Details',
        titleCode: 'ARC_CARD_HEADER_PLAN_SCRUTINY_DETAILS',
        formData: [
            {
                title: 'Service Type',
                titleCode: 'ARC_LBL_SERVICE_TYPE',
                field: 'serviceType',
                type: CONSTANT_DATA_FIELD.BPA_SELECT_FIELD,
                isRequired: true,
                options: [
                    // {
                    //   name: "New Construction",
                    //   title: "New Construction",
                    //   value: "NEW_CONSTRUCTION",
                    //   subcategory: [],
                    // },
                    // {
                    //   name: "Addition and Alteration",
                    //   title: "Addition and Alteration",
                    //   value: "ADDITION_ALTERATION",
                    //   subcategory: [],
                    // },
                    // {
                    //   name: "Reconstruction",
                    //   title: "Reconstruction",
                    //   value: "RE_CONSTRUCTION",
                    //   subcategory: [],
                    // },
                ]
            },
            {
                title: 'Building Plan',
                titleCode: 'ARC_LBL_BUILDING_PLAN',
                field: 'buildingPlan',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                class: 'lg',
                isRequired: true,
                size: '30',
                fileTypes: ['DXF'],
                width: 'half',
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ARCHITECT,
                    documentType: DOCUMENT_TYPES_MGR.SCRUTINY_DOCUMENTS.BUILDINGPLAN
                }
            }
        ]
    }
];

export const DOCUMENTS_DETAILS: FormDataDTO[] = [
    {
        title: 'Drawings PDF',
        titleCode: 'ARC_CARD_HEADER_DRAWINGS_PDF',
        formData: [
            {
                title: 'Submission Building Plan',
                titleCode: 'ARC_UPLD_BUILDING_PLAN_PDF',
                field: 'SITEPLAN',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                isDisabled: false,
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ARCHITECT,
                    documentType: DOCUMENT_TYPES_MGR.SCRUTINY_DOCUMENTS.SITEPLAN
                }
            }
        ]
    },
    {
        title: 'Documents duly authenticated/signed as prescribed',
        titleCode: 'ARC_CARD_HEADER_DOCUMENTS_AUTHENTICATED_SIGNED',
        formData: [
            {
                title: 'Questionnaire',
                titleCode: 'ARC_UPLD_QUESTIONAIRE',
                field: 'DOCUMENT_QUESTIONNAIRE',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                isRequired: true,
                isDisabled: false,
                entityFileData: {
                    documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ARCHITECT,
                    documentType: DOCUMENT_TYPES_MGR.SCRUTINY_DOCUMENTS.DOCUMENT_QUESTIONNAIRE
                }
            }
            // {
            //     title: 'Affidavit from the owner',
            //     titleCode: 'ARC_UPLD_AFFIDAVIT',
            //     field: 'DOCUMENT_AFFIDAVIT',
            //     type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
            //     isRequired: false,
            //     isDisabled: false,
            //     entityFileData: {
            //         documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ARCHITECT,
            //         documentType: DOCUMENT_TYPES_MGR.SCRUTINY_DOCUMENTS.DOCUMENT_AFFIDAVIT
            //     }
            // },
            // {
            //     title: 'Power of Attorney',
            //     titleCode: 'ARC_UPLD_POWER_ATTORNEY',
            //     field: 'DOCUMENT_POWER_ATTORNEY',
            //     type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
            //     isRequired: false,
            //     isDisabled: false,
            //     entityFileData: {
            //         documentNamePrefix: DOCUMENT_TYPES_MGR.USER.ARCHITECT,
            //         documentType: DOCUMENT_TYPES_MGR.SCRUTINY_DOCUMENTS.DOCUMENT_POWER_ATTORNEY
            //     }
            // }
        ]
    }
];

export const DIGITAL_SIGN_DETAILS: FormDataDTO[] = [
    {
        title: '',
        formData: [
            {
                title: 'Token',
                field: 'token',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,

                class: 'long'
            },
            {
                title: 'Certificate',
                field: 'certi',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,

                class: 'long'
            },
            {
                title: 'PIN',
                field: 'pin',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,

                class: 'long'
            }
        ]
    }
];

export const SCRUTINY_DASHBOARD_TABLE_COLUMNS: any = [
    {
        colName: 'Lessee Name ',
        fieldName: 'lesseeName',
        clsName: 'col-sm-3 column-text-start'
    },
    {
        colName: 'EDCR Number',
        fieldName: 'edcrNumber',
        clsName: 'col-sm-3 column-text-start',
        isClickable: true
    },
    {
        colName: 'Service Type ',
        fieldName: 'serviceType',
        clsName: 'col-sm-3 column-text-start'
    },
    // {
    //     colName: 'Application Type ',
    //     fieldName: 'applicationType',
    //     clsName: 'col-sm-3'
    // },

    {
        colName: 'Date of Scrutiny',
        fieldName: 'createdTime',
        clsName: 'col-sm-3 column-text-start'
    }
];
