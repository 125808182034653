import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../utils/constants';

export const BANK_NOC_FORM_STEPS = [
    {
        titleCode: 'LESSEE_DETAILS',
        name: 'Lessee Details',
        code: 'LESSEE_DETAILS',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        code: 'PLOT_DETAILS',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'MORTGAGE_DETAILS',
        name: 'Mortgage Details',
        code: 'MORTGAGE_DETAILS',
        index: 3,
        isActive: true
    },
    {
        titleCode: 'BANK_LETTER_DETAILS',
        name: 'Bank Letter Details',
        code: 'BANK_LETTER_DETAILS',
        index: 3,
        isActive: true
    },
    {
        titleCode: 'NOTIFICATION_DETAILS',
        name: 'Notification Details',
        code: 'NOTIFICATION_DETAILS',
        index: 3,
        isActive: true
    },
    {
        titleCode: 'NOC_ORDER_DETAILS',
        name: 'NOC Order Details',
        code: 'NOC_ORDER_DETAILS',
        index: 3,
        isActive: true
    }
];

export const LESSEE_DETAILS: FormDataDTO[] = [
    {
        title: 'Lessee Details',
        titleCode: 'TC_CARD_HEADER_LESSEE_DETAILS',
        formData: [
            {
                titleCode: 'TC_LBL_ENTERPRISE_NAME',
                title: 'Enterprise Name',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_EMAILID',
                title: 'Email ID',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_PHONENO',
                title: 'Phone Number',
                field: 'phone',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_PANNO',
                title: 'PAN No.',
                field: 'panNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            }
        ]
    },
    {
        title: 'Address',
        titleCode: 'TC_CARD_HEADER_ADDRESS',
        formData: [
            {
                titleCode: 'TC_LBL_ADDRESSLINE1',
                title: 'Address Line 1',
                field: 'addressLine1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_ADDRESSLINE2',
                title: 'Address Line 2',
                field: 'addressLine2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_PINCODE',
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_DISTRICT',
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_STATE',
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'TC_LBL_COUNTRY',
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            }
        ]
    }
];

export const PLOT_DETAILS: FormDataDTO[] = [
    {
        title: 'Plot Details',
        // titleCode: 'OC_CARD_HEADER_PLOT_DETAILS',
        formData: [
            {
                title: 'Estate Name',
                field: 'estate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_ESTATE',
                isRequired: true
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_PHASE',
                isRequired: true
            },
            {
                title: 'Plot No',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_PLOTNO',
                isRequired: true
            },
            {
                title: 'Property Type',
                field: 'propertyType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_PROPERTY_TYPE',
                isRequired: true
            },
            // {
            //     title: 'Plot Rate (INR/Sq.m.)',
            //     field: 'plotRate',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     titleCode: 'OC_LBL_PLOT_RATE'
            // },
            {
                title: 'Plot Area (Sq.m.)',
                field: 'plotArea',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: 'OC_LBL_PLOT_AREA'
            }
        ]
    }
];

export const MORTGAGE_DETAILS: FormDataDTO[] = [
    {
        title: 'Mortgage Details',
        // titleCode: 'OC_CARD_HEADER_PLOT_DETAILS',
        formData: [
            {
                title: 'No of Financial Institution/Banks from where loan(s) is(are) sanctioned',
                field: 'noOfFincanicalBanks',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                titleCode: '',
                isRequired: true
            }
        ]
    },
    {
        title: 'Financial Institution/Bank - ',
        // titleCode: 'TC_CARD_HEADER_ADDRESS',
        formData: [
            {
                titleCode: '',
                title: 'IFSC Code',
                field: 'ifscCode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                titleCode: '',
                title: 'Bank Name',
                field: 'bankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: '',
                title: 'Branch Address',
                field: 'branchAddress',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            }
        ]
    }
];

export const BANK_LETTER_DETAILS: FormDataDTO[] = [
    {
        title: 'Bank Letter Details',
        // titleCode: 'OC_CARD_HEADER_PLOT_DETAILS',
        formData: [
            {
                title: 'Upload Bank Letter Document',
                field: 'bankLetterDocument',
                type: CONSTANT_DATA_FIELD.NEW_FILE_FIELD,
                titleCode: '',
                isRequired: false
            }
        ]
    }
];
