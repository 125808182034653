import React, { useEffect, useState } from 'react';
import downloadImg from '../../assets/logos/download_green.svg';
import { POST, devUrl, endpoints, requestInfo } from '../../services/modulesServices/userManagement.api';

interface IDocDownloadProps {
    applicationNumber: string | null;
    nextValidAction: any;
}

const NotingDocDownload = (props: IDocDownloadProps) => {
    const { applicationNumber, nextValidAction } = props;

    const [fileStoreIds, setFileStoreIds] = useState([]);

    useEffect(() => {
        const getFileDetails = async () => {
            try {
                const { data } = await POST(endpoints.notingDocGenerate + `?applicationNumber=${applicationNumber}`, {
                    RequestInfo: requestInfo()
                });
                console.log(data);
                setFileStoreIds(data.documentReferenceId);
            } catch (e) {
                console.log(e);
            }
        };
        getFileDetails();
    }, [applicationNumber]);

    const handleDownloadClick = async () => {
        if (fileStoreIds) {
            try {
                fetch(devUrl + `/filestore/v1/files/download?tenantId=ga&fileStoreIds=${fileStoreIds}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response);
                        return response.arrayBuffer().then(blob => {
                            const url = response.url;
                            const link: any = document.createElement('a');
                            link.href = url;
                            // link.setAttribute('download', `Noting_Document`);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        });
                    })

                    .catch(error => console.error('Error downloading file:', error));
            } catch (e) {
                console.log(e);
            }
        }
    };
    return (
        <>
            <div className='project-primary-color me-2 cursor-pointer' onClick={handleDownloadClick}>
                <img src={downloadImg} alt='' /> Download
            </div>
        </>
    );
};

export default NotingDocDownload;
