export const ConstSteps = [
    {
        name: 'Payment',
        code: 'PAYMENTS',
        index: 1,
        isActive: true
    }
];

export const InitiateConstSteps = [
    {
        titleCode: 'LICENSE_PAYMENT',
        name: 'Payment Details',
        code: 'PAYMENT',
        index: 1,
        isActive: true
    }
    // {
    //     name: 'Notes',
    //     code: 'NOTES',
    //     titleCode: 'NOTES',
    //     index: 2,
    //     isActive: true
    // },
    // {
    //     name: 'Notification Details',
    //     code: 'NOTIFICATION_DETAILS',
    //     titleCode: 'NOTIFICATION_DETAILS',
    //     index: 3,
    //     isActive: true
    // }
];
