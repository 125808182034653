import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormatedOwnershipDetails } from '../../features/modules/Migration/Migration.controller';
import { OwnershipDetails } from '../../features/modules/UserManagement/EnterpriseModule/OwnershipDetails';
import { IOwnershipDetails } from '../../features/modules/UserManagement/types';
import { POST, requestInfo } from '../../services/modulesServices/userManagement.api';
import PopUps from '../PopUps';
import { check, cross } from '../../utils/Logo';

interface GenericOwnerShipDetailsProps {
    values?: any;
    otpReferenceId?: any;
    userRegistrationNo?: string;
    dropdownData?: any;
    changeOwnershipDetails?: (data: boolean) => void;
    showOwnershipModal: boolean;
    setShowOwnershipModal: (data: boolean) => void;
    setControllingOwnershipResponse?: any;
    allOwnerShipDetails?: any;
    index?: number;
    setOwnershipDetails: (e: any) => void;
    ownershipDetails: any;
    controllingOwnershipResponse?: any;
    setCalculated?: (e: any) => void;
    toolTipDetails?: any;
    isDisabled?: boolean;
    hideCard?: boolean;
    scrollNotRequired?: boolean;
    setIsChangeInOwnership?: (e: any) => void;
    skipFullOwnershipPercent?: boolean;
    isWorkflow?: boolean;
}
const GenericOwnerShipDetails = (props: GenericOwnerShipDetailsProps) => {
    const {
        values,
        setOwnershipDetails,
        ownershipDetails,
        otpReferenceId,
        userRegistrationNo,
        dropdownData,
        changeOwnershipDetails,
        showOwnershipModal,
        setShowOwnershipModal,
        setControllingOwnershipResponse,
        controllingOwnershipResponse,
        allOwnerShipDetails,
        toolTipDetails,
        isDisabled,
        index,
        hideCard,
        scrollNotRequired,
        setIsChangeInOwnership,
        isWorkflow
    } = props;
    const { t } = useTranslation();
    const [showCalculatedTable, setShowCalculatedTable] = useState(false);
    const [controllingOwnership, setControllingOwnership] = useState<any>(null);
    const [totalOwnershipPercentage, setTotalOwnershipPercentage] = useState(0);
    const { setValues } = useFormikContext<any>();
    const [modalDetails, setModalDetails] = useState({});
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;

    useEffect(() => {
        const tempControllingOwnership = ownershipDetails?.filter((item: any, _: number) => item.controllingOwnership === true);
        setControllingOwnership(tempControllingOwnership);
    }, [controllingOwnershipResponse]);

    useEffect(() => {
        changeOwnershipDetails && changeOwnershipDetails(ownershipDetails);
    }, [ownershipDetails]);

    useEffect(() => {
        if (!showCalculatedTable) {
            props?.setCalculated && props?.setCalculated(false);
        } else props?.setCalculated && props?.setCalculated(true);
    }, [showCalculatedTable]);

    useEffect(() => {
        const currentOwnership = index !== undefined ? allOwnerShipDetails[index] : ownershipDetails;
        let calculateOwnershipPercentage: number = 0;
        currentOwnership?.map((item: any) => {
            if (!item['isDelete']) calculateOwnershipPercentage = calculateOwnershipPercentage + +item.ownershipInterest;
        });

        setTotalOwnershipPercentage(+calculateOwnershipPercentage.toFixed(2));
    }, [ownershipDetails, allOwnerShipDetails]);

    const calculateOwnership = async () => {
        const currentOwnership = index !== undefined ? allOwnerShipDetails[index] : ownershipDetails;
        const data: IOwnershipDetails[] = [];
        currentOwnership?.map((item: IOwnershipDetails) => {
            const { serialNo, panReferenceId, rowId, ...other }: any = item;
            other['panReferenceId' as string] = panReferenceId?.referenceId;
            if (!(other.isDelete && !other.uuid)) {
                data.push(other);
            }
        });

        const requestBody = {
            RequestInfo: requestInfo(),
            otpReferenceId: values.entityemailIdOtpReferenceId || otpReferenceId || undefined,
            enterpriseEntity: {
                registrationNumber: userRegistrationNo || undefined,
                owners: data,
                uuid: values.entityId || entityId
            }
        };
        try {
            let res = await POST(`/user/enterprise/nologin/ownerships/_calculate`, requestBody);
            let ownershipValues = await getFormatedOwnershipDetails(res.data.entityOwners, dropdownData);
            // ownershipValues = ownershipValues.map((item: IOwnershipDetails) => {
            //     item.rowId = uuidv4();
            //     return item;
            // });
            const tempControllingOwnership = ownershipValues.filter((item: any, _: number) => item.controllingOwnership === true);
            setControllingOwnership(tempControllingOwnership);

            setControllingOwnershipResponse(res?.data?.entityOwners);
            setOwnershipDetails(ownershipValues);
            setShowCalculatedTable(true);
            setIsChangeInOwnership && setIsChangeInOwnership(res?.data?.isOwnershipUpdated);
            setValues({ ...values, womenEntrepreneur: res?.data?.womenEntreprenueur, localPerson: res?.data?.isLocalPerson, ownership: ownershipValues });

            let msg = t('OWNERSHIP_CREATED', 'Controlling ownership calculated');
            setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'Cretion success', reUpload: 'OK' });
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <OwnershipDetails
                setOwnershipDetails={setOwnershipDetails}
                ownershipDetails={ownershipDetails}
                dropdownOptions={dropdownData}
                calculateOwnership={calculateOwnership}
                showCalculatedTable={showCalculatedTable}
                setShowCalculatedTable={setShowCalculatedTable}
                setShowOwnershipModal={setShowOwnershipModal}
                showOwnershipModal={showOwnershipModal}
                controllingOwnership={controllingOwnership}
                totalOwnershipPercentage={totalOwnershipPercentage}
                setTotalOwnershipPercentage={setTotalOwnershipPercentage}
                controllingOwnershipResponse={controllingOwnershipResponse}
                toolTipDetails={toolTipDetails}
                isDisabled={isDisabled}
                hideCard={hideCard}
                scrollNotRequired={scrollNotRequired}
                skipFullOwnershipPercent={props?.skipFullOwnershipPercent}
                isWorkflow={isWorkflow}
            />
        </>
    );
};

export default GenericOwnerShipDetails;
