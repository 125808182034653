import React, { useEffect, useState } from 'react';
import style from './SocialFeeds.module.css';
import { Badge, Card, Image } from 'react-bootstrap';
import './commonStyles.scss';
// import { getSocialMediaData } from "../../services/modulesServices/common.api";
import { POST, getFileUrl, getMultipleFilesUrl } from '../../services/modulesServices/userManagement.api';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function SocialFeedsComponent() {
    // const feedData = [1, 2, 3, 4, 5, 6];

    const { t } = useTranslation();
    const [linkedInImgUrl, setLinkedInImgUrl] = useState<any>([]);
    const [instagramImgUrl, setInstagramImgUrl] = useState<any>([]);
    const [feedData, setFeedData] = useState<any>([]);

    useEffect(() => {
        (async () => {
            try {
                const fetchSocialMediaData = await POST('/egov-common-masters/idc/document/socialmedia/_search', { RequestInfo: {} });

                const linkedInImagesIds = fetchSocialMediaData.data.linkedInContents.map((item: any) => item.documentReferenceId).slice(0, 3);
                const instagramImagesIds = fetchSocialMediaData.data.instagramContents.map((item: any) => item.documentReferenceId).slice(0, 9);

                const linkedInImg = await getMultipleFilesUrl(linkedInImagesIds.toString());
                let linkedInUrlArr = linkedInImg.map((item: any) => 'https://' + item.url.split('https://').filter(Boolean)[0].slice(0, -1));
                setLinkedInImgUrl(linkedInUrlArr);

                const instagramImg = await getMultipleFilesUrl(instagramImagesIds.toString());
                let instaInUrlArr = instagramImg.map((item: any) => 'https://' + item.url.split('https://').filter(Boolean)[0].slice(0, -1));
                setInstagramImgUrl(instaInUrlArr);

                setFeedData(fetchSocialMediaData.data.whatsNewContents);
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    const onClickImg = (url: string) => {
        window.open(url, '_blank');
    };

    const onClickDownloadItem = async (referenceID: number | string) => {
        if (referenceID) {
            const url = await getFileUrl(referenceID);
            window.open(url, '_blank');
        }
    };

    return (
        <div>
            <div className='container-fluid social-container' style={{ background: '#F5F5F6' }}>
                <div className='row'>
                    <div className='col-sm-12 p-5'>
                        <div className='row'>
                            <div className='col-sm-8'>
                                <div className='pb-5  text-start '>
                                    <h2>{t('SOCIAL_FEED', 'Social Feed')}</h2>
                                </div>
                            </div>
                            <div className='col-sm-4'>
                                <div className='pb-5 text-start '>
                                    <h2>{t('WHATS_NEW', "What's New")}</h2>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <Card style={{ borderRadius: 0, minHeight: 500 }}>
                                    <Card.Body>
                                        <div className='row'>
                                            <Card.Title className='col-sm-6 text-start font-inter-reg'>
                                                <i className='fa fa-linkedin-square' aria-hidden='true' /> {t('LINKED_IN', 'LinkedIn')}
                                            </Card.Title>
                                            <Card.Title className='col-sm-6 text-end font-karla-reg' style={{ color: '#45852E', fontSize: 15 }}>
                                                <Link to='https://www.linkedin.com/company/goa-industrial-development-corporation-gidc/?originalSubdomain=in' target='_blank'>
                                                    {t('SEE_ALL', 'See All')}
                                                </Link>
                                            </Card.Title>
                                        </div>
                                        <div className='col-sm-12  '>
                                            <div className='row gx-0 '>
                                                <div className='col-sm-8'>
                                                    <div className={`${style.square_main}`} onClick={() => onClickImg(linkedInImgUrl[0])}>
                                                        <Image src={linkedInImgUrl[0]} height={'100%'} width={'100%'} />
                                                    </div>
                                                </div>
                                                <div className='col-sm-4 ' onClick={() => onClickImg(linkedInImgUrl[1])}>
                                                    <div className={`w-100 ${style.linked_square}`}>
                                                        <Image src={linkedInImgUrl[1]} height={'100%'} width={'100%'} />
                                                    </div>
                                                    <div className={` w-100 ${style.linked_square}`} onClick={() => onClickImg(linkedInImgUrl[2])}>
                                                        <Image src={linkedInImgUrl[2]} height={'100%'} width={'100%'} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div style={{ borderBottom: "1px solid", borderColor: "#ccc" }} className="py-2">
                                            <div className="row">
                                                <div className="col-sm-10 text-start font-karla-md">
                                                    Employers Kind Attention Public disclosure of marks of Recruitment Agencies
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8 text-start py-2 font-karla-reg" style={{ color: "#898989", fontSize: 14 }}>May 2023, 25</div>
                                            </div>
                                        </div> */}
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='col-sm-4'>
                                <Card style={{ borderRadius: 0, minHeight: 500 }}>
                                    <Card.Body>
                                        <div className='row'>
                                            <Card.Title className='col-sm-6 text-start font-inter-reg'>
                                                <i className='fa fa-instagram' aria-hidden='true' /> {t('INSTAGRAM', 'Instagram')}
                                            </Card.Title>
                                            <Card.Title className='col-sm-6 text-end font-karla-reg' style={{ color: '#45852E', fontSize: 15 }}>
                                                <Link to='https://www.instagram.com/goa_idc/' target='_blank'>
                                                    {t('SEE_ALL', 'See All')}
                                                </Link>
                                            </Card.Title>
                                        </div>
                                        <div className='container'>
                                            <div className='noMargin row'>
                                                <div className={`${style.square}`} onClick={() => onClickImg(instagramImgUrl[0])}>
                                                    <Image src={instagramImgUrl[0]} height={'100%'} width={'100%'} />
                                                </div>
                                                <div className={` ${style.square}`} onClick={() => onClickImg(instagramImgUrl[1])}>
                                                    <Image src={instagramImgUrl[1]} height={'100%'} width={'100%'} />
                                                </div>
                                                <div className={` ${style.square}`} onClick={() => onClickImg(instagramImgUrl[2])}>
                                                    <Image src={instagramImgUrl[2]} height={'100%'} width={'100%'} />
                                                </div>
                                            </div>
                                            <div className='noMargin row'>
                                                <div className={` ${style.square}`} onClick={() => onClickImg(instagramImgUrl[3])}>
                                                    <Image src={instagramImgUrl[3]} height={'100%'} width={'100%'} />
                                                </div>
                                                <div className={`${style.square}`} onClick={() => onClickImg(instagramImgUrl[4])}>
                                                    <Image src={instagramImgUrl[4]} height={'100%'} width={'100%'} />
                                                </div>
                                                <div className={` ${style.square}`} onClick={() => onClickImg(instagramImgUrl[5])}>
                                                    <Image src={instagramImgUrl[5]} height={'100%'} width={'100%'} />
                                                </div>
                                            </div>
                                            <div className='row noMargin'>
                                                <div className={` ${style.square}`} onClick={() => onClickImg(instagramImgUrl[6])}>
                                                    <Image src={instagramImgUrl[6]} height={'100%'} width={'100%'} />
                                                </div>
                                                <div className={`${style.square}`} onClick={() => onClickImg(instagramImgUrl[7])}>
                                                    <Image src={instagramImgUrl[7]} height={'100%'} width={'100%'} />
                                                </div>
                                                <div className={` ${style.square}`} onClick={() => onClickImg(instagramImgUrl[8])}>
                                                    <Image src={instagramImgUrl[8]} height={'100%'} width={'100%'} />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className='col-sm-4 marquee-container'>
                                <Card style={{ borderRadius: 0, height: 500 }}>
                                    <Card.Body>
                                        <div className='marquee-text'>
                                            {feedData?.map((val: any) => (
                                                <div style={{ borderBottom: '1px solid', borderColor: '#ccc' }} className='py-2'>
                                                    <div className='row'>
                                                        <div className='col-sm-9 text-start font-karla-md'>
                                                            <div onClick={() => onClickDownloadItem(val.documentReferenceId)}>{val.content}</div>
                                                        </div>
                                                        <div className='col-sm-3 text-end font-karla-reg'>
                                                            <Badge bg='danger'>{t('NEW', 'New')}</Badge>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-sm-8 text-start py-2 font-karla-reg' style={{ color: '#898989', fontSize: 14 }}>
                                                            {val.createdDate}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
