import { Formik } from 'formik';
import * as Yup from 'yup';
import { checkUserAvailability, getLocation, requestInfo } from '../../services/modulesServices/userManagement.api';
import { CONSTANT_REGEX_SAMPLE, USER_TYPE } from '../constants';
import { ErrorsValuesValidationInterface, PreviousValuesValidationInterface } from '../../features/modules/UserManagement/types';
import { strToNum } from '../../features/modules/EstateManagement/EstateManagement.controller';
import { isAfter } from '../utils';

let previousValues: PreviousValuesValidationInterface = {
    email: '',
    phoneNo: '',
    pincode: '',
    perpincode: '',
    entityEmailId: '',
    entityPhoneNo: '',
    mobileNumber: '',
    authorizedPersonEmailId: '',
    authorizedPersonPrimaryMobileNumber: '',
    profileEmailId: '',
    profilePhoneNo: '',
    emailId: '',
    phoneNumber: '',
    pmobileNumber: '',
    authorizedEmailId: '',
    sublesseeEmail: {},
    sublesseePhone: {},
    sublesseeUserEmail: {},
    sublesseeUserPhone: {},
    lesseePhoneNumber: '',
    lesseeEmailId: '',
    entitypanNo: '',
    applicantPanNo: '',
    panNumber: '',
    sublesseePan: {}
};

let errors: ErrorsValuesValidationInterface = {
    email: false,
    phoneNo: false,
    pincode: false,
    perpincode: false,
    entityEmailId: false,
    entityPhoneNo: false,
    mobileNumber: false,
    authorizedPersonEmailId: false,
    authorizedPersonPrimaryMobileNumber: false,
    profileEmailId: false,
    profilePhoneNo: false,
    emailId: false,
    phoneNumber: false,
    pmobileNumber: false,
    authorizedEmailId: false,
    sublesseeEmail: {},
    sublesseePhone: {},
    sublesseeUserEmail: {},
    sublesseeUserPhone: {},
    lesseePhoneNumber: false,
    lesseeEmailId: false,
    entitypanNo: false,
    applicantPanNo: false,
    panNumber: false,
    sublesseePan: {}
};

export const EMPLOYEE_CREATE_APPLICATION_FORM = Yup.object({
    firstname: Yup.string().matches(CONSTANT_REGEX_SAMPLE.ALPHABATEWITHSPACE, 'Enter characters only').required().label('First Name'),

    lastname: Yup.string().matches(CONSTANT_REGEX_SAMPLE.ALPHABATEWITHSPACE, 'Enter characters only').required().label('Last Name'),

    dob: Yup.date().required().label('DOB').max(new Date(), 'Date of birth must be in the past'),

    phoneNo: Yup.string()
        .min(10, 'Phone number should be at least 10 digit')
        .max(10, 'Phone number should not be more than 10 digit')
        .required()
        .label('Mobile number')
        .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number ')
        .test('unique-phone', 'Mobile No. already exists', async function (value, context) {
            if (value !== previousValues.phoneNo) {
                const payload = {
                    RequestInfo: requestInfo(),
                    tenantId: 'ga',
                    mobileNumber: value.toString() || ''
                };
                previousValues.phoneNo = context.parent.phoneNo;
                try {
                    let response: any = value.length === 10 && (await checkUserAvailability(payload));
                    if (response.data.isAvailable) {
                        errors.phoneNo = false;
                        return true;
                    } else {
                        errors.phoneNo = true;
                        return false;
                    }
                } catch (e) {
                    errors.phoneNo = true;
                    return false;
                }
            }
            return !errors.phoneNo;
        }),

    emailId: Yup.string()
        .required()
        .label('Email Id')
        .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
        .test('emailId', 'Email already exists', async function (value, context) {
            if (value !== previousValues.email) {
                const payload = {
                    RequestInfo: requestInfo(),
                    tenantId: 'ga',
                    emailId: value || ''
                };
                previousValues.email = context.parent.emailId;
                try {
                    let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                    if (response.data.isAvailable) {
                        errors.email = false;
                        return true;
                    } else {
                        errors.email = true;
                        return false;
                    }
                } catch (e) {
                    errors.email = true;
                    return false;
                }
            }

            return !errors.email;
        }),

    gender: Yup.object().required().label('Gender'),

    address1: Yup.string().required().label('Address'),

    peraddress1: Yup.string().required().label('Address'),

    // pincode: Yup.string().min(6, "Pincode should be at least 6 digit").max(6, "Pincode should not be more than 6 digit").required().label("Pincode"),
    // perpincode: Yup.string().min(6, "Pincode should be at least 6 digit").max(6, "Pincode should not be more than 6 digit").required().label("Pincode"),

    pincode: Yup.string().required().label('Pincode'),
    district: Yup.string().required().label('District'),
    state: Yup.string().required().label('State'),
    country: Yup.string().required().label('Country'),
    perpincode: Yup.string().required().label('Pincode'),
    perdistrict: Yup.string().required().label('District'),
    perstate: Yup.string().required().label('State'),
    percountry: Yup.string().required().label('Country'),
    qualifications: Yup.array().of(
        Yup.object().shape({
            yearOfPassing: Yup.string().nullable().min(4, 'Year Of Passing should be at least 4 digit').max(4, 'Year Of Passing should not be more than 4 digit').label('year of passing').required(),
            stream: Yup.object().nullable().label('Stream').required(),
            qualification: Yup.object().nullable().label('Qualification').required()
        })
    ),
    appointmentDate: Yup.date().required().label('Appoinment Date').max(new Date(), 'Date should not be in future'),
    empType: Yup.object().required().label('Employee Type'),
    status: Yup.object().required().label('Status'),
    assignment: Yup.array().of(
        Yup.object().shape({
            fromDate: Yup.string().required().label('Date'),
            isCurrentAssignment: Yup.object().required().label('Current Assignment'),
            department: Yup.object().required().label('Department'),
            designation: Yup.object().required().label('Designation'),
            roles: Yup.array()
                .required()
                .test({
                    message: 'Roles are required',
                    test: val => val && val.length > 0
                })
                .label('Roles')
        })
    ),
    identityDetailsDropdown: Yup.object().required().label('Identity Proof'),
    identityProof: Yup.mixed().required().label('Identity Proof'),
    documentNo: Yup.string().required().label('Document No.'),
    addressDetailsDropdown: Yup.object().required().label('Address'),
    addressProof: Yup.mixed().required().label('Address Proof'),
    letterDetailsDropdown: Yup.object().required().label('Letter'),
    joiningLetter: Yup.mixed().required().label('Letter Proof')
});

export const ARCHITECT_CREATE_FORM: { [pageno: string]: any } = {
    page1: Yup.object({
        firstname: Yup.string().trim().matches(CONSTANT_REGEX_SAMPLE.ALPHABATEWITHSPACE, 'Enter alphabets only').required().label('First Name'),

        lastname: Yup.string().trim().matches(CONSTANT_REGEX_SAMPLE.ALPHABATEWITHSPACE, 'Enter alphabets only').required().label('Last Name'),

        dob: Yup.date().required().label('DOB').max(new Date(), 'Date of birth must be in the past'),

        phoneNo: Yup.string()
            .min(10, 'Phone number should be at least 10 digit')
            .max(10, 'Phone number should not be more than 10 digit')
            .required()
            .label('Mobile number')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number ')
            .test('unique-phone', 'Mobile No. already exists', async function (value, context) {
                if (value !== previousValues.phoneNo && context.parent.phoneNoOtpReferenceId) {
                    const payload = {
                        RequestInfo: requestInfo(),
                        tenantId: 'ga',
                        mobileNumber: value.toString() || ''
                    };
                    previousValues.phoneNo = context.parent.phoneNo;
                    try {
                        let response: any = value.length === 10 && (await checkUserAvailability(payload));
                        response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));
                        if (response.data.isAvailable) {
                            errors.phoneNo = false;
                            return true;
                        } else {
                            errors.phoneNo = true;
                            return false;
                        }
                    } catch (e) {
                        errors.phoneNo = true;
                        return false;
                    }
                } else if (value !== previousValues.phoneNo) {
                    return true;
                } else if (context.parent.underProcess) {
                    return true;
                }
                return !errors.phoneNo;
            }),
        emailId: Yup.string()
            .required()
            .label('Email Id')
            .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
            .test('emailId', 'Email already exists', async function (value, context) {
                if (value !== previousValues.emailId && context.parent.emailIdOtpReferenceId) {
                    const payload = {
                        RequestInfo: requestInfo(),
                        tenantId: 'ga',
                        emailId: value || ''
                    };
                    previousValues.emailId = context.parent.emailId;
                    try {
                        let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                        response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));
                        if (response.data.isAvailable) {
                            errors.emailId = false;
                            return true;
                        } else {
                            errors.emailId = true;
                            return false;
                        }
                    } catch (e) {
                        errors.emailId = true;
                        return false;
                    }
                } else if (value !== previousValues.emailId) {
                    return true;
                } else if (context.parent.underProcess) {
                    return true;
                }

                return !errors.emailId;
            }),

        gender: Yup.object().required().label('Gender'),

        address1: Yup.string().required().label('Address'),

        peraddress1: Yup.string().required().label('Address'),

        pincode: Yup.string().required().label('Valid Pincode'),
        district: Yup.string().required().label('District'),
        state: Yup.string().required().label('State'),
        country: Yup.string().required().label('Country'),

        perpincode: Yup.string().required().label('Valid Pincode'),
        perdistrict: Yup.string().required().label('District'),
        perstate: Yup.string().required().label('State'),
        percountry: Yup.string().required().label('Country')
    }),

    page2: Yup.object({
        licenseeType: Yup.object().required().label('Licensee Type'),

        validFrom: Yup.date().required().label('From Date').max(new Date(), 'From Date must be in the past'),

        validTo: Yup.date().nullable().required('To Date is required').min(new Date(), 'To Date must be later than today')
    }),

    page3: Yup.object({
        adhar: Yup.mixed().required().label('Address Proof'),
        licenseCopy: Yup.mixed().required().label('Letter Proof')
    })
};

export const PasswordChangeSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string()
        .min(8, 'Password must be between 8 and 15 characters, and include at least one uppercase letter, one lowercase letter, one number, and one special character (@#$%!)')
        .max(15, 'Password should not be more than 15 digit')
        .required('New Password is required')
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%])(?=\S+$).*/,
            'Password must be between 8 and 15 characters, and include at least one uppercase letter, one lowercase letter, one number, and one special character (@#$%!)'
        ),
    confirmNewPassword: Yup.string()
        .min(8, 'Passwords must match')
        .max(15, 'Password should not be more than 15 digit')
        .required('Confirm New Password is required')
        .oneOf([Yup.ref('newPassword')], 'Passwords must match')
});
export const ESTATE_REGISTRY_VALIDATION: any = {
    estateForm: Yup.object({
        estateCode: Yup.string().required().label('Estate Code'),

        estateName: Yup.string().required().label('Estate Name'),
        state: Yup.object().required().label('State'),
        taluka: Yup.object().required().label('Taluka'),
        district: Yup.object().required().label('District'),
        survayNo: Yup.string().required().label('Survay No')
    })
};

export const EMPLOYEE_UPDATE_VALIDATION = Yup.object({
    profilePhoneNo: Yup.string()
        .min(10, 'Phone number should be at least 10 digit')
        .max(10, 'Phone number should not be more than 10 digit')
        .required()
        .label('Mobile number')
        .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number ')
        .test('unique-phone', 'Mobile No. already exists', async function (value, context) {
            if (value !== previousValues.profilePhoneNo) {
                const payload = {
                    RequestInfo: requestInfo(),
                    tenantId: 'ga',
                    mobileNumber: value.toString() || '',
                    uuid: context.parent.uuid
                };
                previousValues.profilePhoneNo = context.parent.profilePhoneNo;
                try {
                    let response: any = value.length === 10 && (await checkUserAvailability(payload));
                    response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));
                    if (response.data.isAvailable) {
                        errors.profilePhoneNo = false;
                        return true;
                    } else {
                        errors.profilePhoneNo = true;
                        return false;
                    }
                } catch (e) {
                    errors.profilePhoneNo = true;
                    return false;
                }
            } else if (value !== previousValues.profilePhoneNo) {
                return true;
            } else if (context.parent.underProcess) {
                return true;
            }
            return !errors.profilePhoneNo;
        }),
    profileEmailId: Yup.string()
        .required()
        .label('Email Id')
        .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
        .test('emailId', 'Email already exists', async function (value, context) {
            if (value !== previousValues.profileEmailId) {
                const payload = {
                    RequestInfo: requestInfo(),
                    tenantId: 'ga',
                    emailId: value || '',
                    uuid: context.parent.uuid
                };
                previousValues.profileEmailId = context.parent.profileEmailId;
                try {
                    let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                    response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));
                    if (response.data.isAvailable) {
                        errors.profileEmailId = false;
                        return true;
                    } else {
                        errors.profileEmailId = true;
                        return false;
                    }
                } catch (e) {
                    errors.profileEmailId = true;
                    return false;
                }
            } else if (value !== previousValues.profileEmailId) {
                return true;
            } else if (context.parent.underProcess) {
                return true;
            }

            return !errors.profileEmailId;
        }),
    address1: Yup.string().required().label('Address'),
    peraddress1: Yup.string().required().label('Address'),
    pincode: Yup.string().required().label('Pincode'),
    perpincode: Yup.string().required().label('Pincode'),
    appointmentDate: Yup.date().required().label('Appoinment Date').max(new Date(), 'Date should not be in future'),
    empType: Yup.object().required().label('Employee Type'),
    status: Yup.object().required().label('Status'),

    assignments: Yup.array().of(
        Yup.object().shape({
            fromDate: Yup.string().required().label('Date'),

            isCurrentAssignment: Yup.object().required().label('Current Assignment'),

            department: Yup.object().required().label('Department'),

            designation: Yup.object().required().label('Designation'),

            roles: Yup.array()
                .required()
                .test({
                    message: 'Roles are required',
                    test: val => val && val.length > 0
                })
                .label('Roles')
        })
    ),
    educational: Yup.array().of(
        Yup.object().shape({
            qualification: Yup.object().required().label('Qualification'),

            stream: Yup.object().required().label('Stream'),
            yearOfPassing: Yup.string().min(4, 'Passing year should be at least 4 digit').max(4, 'Passing year should not be more than 4 digit').label('Year of Passing'),
            university: Yup.string().required().label('University')
        })
    )
});

export const EMPLOYEE_SEARCH_VALIDATION = Yup.object({
    dateFrom: Yup.date().required('Date is required').nullable(),
    dateTo: Yup.date().required('Date is required').nullable(),
    applicationModules: Yup.string().required('Application module is required')
});

export const EMPLOYEE_PROFILE_VALIDATION = Yup.object({
    profileEmailId: Yup.string()
        .required()
        .label('Email Id')
        .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
        .test('profileEmailId', 'Email already exists', async function (value, context) {
            if (value !== previousValues.profileEmailId && context.parent.profileEmailIdOtpReferenceId) {
                const payload = {
                    RequestInfo: {},
                    tenantId: 'ga',
                    emailId: value || '',
                    type: 'ENTERPRISE'
                };
                previousValues.profileEmailId = context.parent.profileEmailId;
                try {
                    let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                    response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

                    if (response.data.isAvailable || response.data.applicationStatus === 'DRAFT') {
                        errors.profileEmailId = false;
                        return true;
                    } else {
                        errors.profileEmailId = true;
                        return false;
                    }
                } catch (e) {
                    errors.profileEmailId = true;
                    return false;
                }
            } else if (value !== previousValues.profileEmailId) return true;
            return !errors.profileEmailId;
        }),

    profilePhoneNo: Yup.string()
        .min(10, 'Phone number should be at least 10 digit')
        .max(10, 'Phone number should not be more than 10 digit')
        .required()
        .label('Mobile number')
        .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number ')
        .label('Phone Number')
        .test('profilePhoneNo', 'Mob no. already exists', async function (value, context) {
            if (value !== previousValues.profilePhoneNo && context.parent.profilePhoneNoOtpReferenceId) {
                const payload = {
                    RequestInfo: {},
                    tenantId: 'ga',
                    mobileNumber: value.toString() || '',
                    type: 'ENTERPRISE'
                };
                previousValues.profilePhoneNo = context.parent.profilePhoneNo;
                try {
                    let response: any = value.length === 10 && (await checkUserAvailability(payload));
                    response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

                    if (response.data.isAvailable || response.data.applicationStatus === 'DRAFT') {
                        errors.profilePhoneNo = false;
                        return true;
                    } else {
                        errors.profilePhoneNo = true;
                        return false;
                    }
                } catch (e) {
                    errors.profilePhoneNo = true;
                    return false;
                }
            } else if (value !== previousValues.profilePhoneNo) return true;
            return !errors.profilePhoneNo;
        })
});

export const ENTERPRISE_REGISTRATION_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({
        entityname: Yup.string().required().label('Name'),
        entitycategory: Yup.object().required().label('Entity Category'),
        entityemailId: Yup.string()
            .required()
            .label('Email Id')
            .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
            .test('entityemailId', 'Email already exists', async function (value, context) {
                if (value !== previousValues.entityEmailId && context.parent.entityemailIdOtpReferenceId) {
                    const payload = {
                        RequestInfo: {},
                        tenantId: 'ga',
                        emailId: value || '',
                        type: 'ENTERPRISE'
                    };
                    previousValues.entityEmailId = context.parent.entityemailId;
                    try {
                        let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                        response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

                        if (response.data.isAvailable || response.data.applicationStatus === 'DRAFT') {
                            errors.entityEmailId = false;
                            return true;
                        } else {
                            errors.entityEmailId = true;
                            return false;
                        }
                    } catch (e) {
                        errors.entityEmailId = true;
                        return false;
                    }
                } else if (value !== previousValues.entityEmailId) {
                    return true;
                } else if (context.parent.underProcess) {
                    return true;
                }

                return !errors.entityEmailId;
            }),

        entityPhoneNo: Yup.string()
            .min(10, 'Phone number should be at least 10 digit')
            .max(10, 'Phone number should not be more than 10 digit')
            .required()
            .label('Mobile number')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number ')
            .label('Phone Number'),
        // .test('entityemailId', 'Mob no. already exists', async function (value, context) {
        //     if (value !== previousValues.entityPhoneNo && context.parent.entityPhoneNoOtpReferenceId) {
        //         const payload = {
        //             RequestInfo: {},
        //             tenantId: 'ga',
        //             mobileNumber: value.toString() || '',
        //             type: 'ENTERPRISE'
        //         };
        //         previousValues.entityPhoneNo = context.parent.entityPhoneNo;
        //         try {
        //             let response: any = value.length === 10 && (await checkUserAvailability(payload));
        //             response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

        //             if (response.data.isAvailable || response.data.applicationStatus === 'DRAFT') {
        //                 errors.entityPhoneNo = false;
        //                 return true;
        //             } else {
        //                 errors.entityPhoneNo = true;
        //                 return false;
        //             }
        //         } catch (e) {
        //             errors.entityPhoneNo = true;
        //             return false;
        //         }
        //     } else if (value !== previousValues.entityPhoneNo) {
        //         return true;
        //     } else if (context.parent.underProcess) {
        //         return true;
        //     }
        //     return !errors.entityPhoneNo;
        // }),

        entitypanNo: Yup.string()
            .required()
            .label('Pan No')
            .min(10, 'Pan no. should not be less than 10 digit')
            .max(10, 'Pan no. should not be more than 10 digit')
            .matches(CONSTANT_REGEX_SAMPLE.PAN, 'Enter valid PAN number')
            .test('entitypanNo', 'PAN No. already exists', async function (value, context) {
                if (value !== previousValues.entitypanNo && value?.length === 10) {
                    const payload = {
                        RequestInfo: {},
                        tenantId: 'ga',
                        panNumber: value || '',
                        type: 'ENTERPRISE'
                    };
                    previousValues.entitypanNo = context.parent.entitypanNo;
                    try {
                        let response: any = CONSTANT_REGEX_SAMPLE.PAN.test(value) && (await checkUserAvailability(payload));
                        response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

                        if (response.data.isAvailable || response.data.applicationStatus === 'DRAFT') {
                            errors.entitypanNo = false;
                            return true;
                        } else {
                            errors.entitypanNo = true;
                            return false;
                        }
                    } catch (e) {
                        errors.entitypanNo = true;
                        return false;
                    }
                } else if (value !== previousValues.entitypanNo) {
                    return true;
                } else if (context.parent.underProcess) {
                    return true;
                }

                return !errors.entitypanNo;
            }),
        // entityGstNo: Yup.string().label("GST No").min(0, "GST no. should not be less than 15 digit").max(15, "GST no. should not be more than 15 digit").matches(CONSTANT_REGEX_SAMPLE.GSTNO, "Enter valid GST number"),

        address1: Yup.string().required().label('Address'),
        pincode: Yup.string().required().label('Pincode'),
        district: Yup.string().required().label('District'),
        state: Yup.string().required().label('State'),
        country: Yup.string().required().label('Country'),
        peraddress1: Yup.string().required().label('Address'),
        perpincode: Yup.string().required().label('Pincode'),
        perdistrict: Yup.string().required().label('District'),
        perstate: Yup.string().required().label('State'),
        percountry: Yup.string().required().label('Country'),
        // entityGstNo: Yup.string()
        //     .label('GST Number')
        //     .when('gstapplicable', ([gstapplicable], schema) => {
        //         return gstapplicable ? schema.min(15).max(15).required().matches(CONSTANT_REGEX_SAMPLE.GSTNO, 'Enter valid GST Number') : schema.min(0).notRequired();
        //     }),

        entityProprietorshipFirm: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.entitycategory?.code !== 'PROP_FIRM') return true; // Validation disabled
            return !!value;
        }),
        trustDeed: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.entitycategory?.code !== 'TRUST') return true; // Validation disabled
            return !!value;
        }),
        entityProprietorIdentity: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.entitycategory?.code !== 'PROP_FIRM') return true; // Validation disabled
            return !!value;
        }),
        partnershipDeed: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.entitycategory?.code !== 'PARTNERSHIP') return true; // Validation disabled
            return !!value;
        }),
        // certificateOfRegistrationFirms: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
        //   if (this.parent.entitycategory?.code !== 'PARTNERSHIP') return true; // Validation disabled
        //   return !!value
        // }),
        certificateOfIncorporation: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.entitycategory?.code !== 'LIMITED_LIABILITY_PARTNERSHIP' || this.parent.entitycategory?.code !== 'LTD_COMPANY') return true; // Validation disabled
            return !!value;
        }),
        certificateOfRegistrationSocieties: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.entitycategory?.code !== 'COOP_SOCIETY') return true; // Validation disabled
            return !!value;
        }),
        copyRelevantNotification: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.entitycategory?.code !== 'GOV_STAT_CORP') return true; // Validation disabled
            return !!value;
        }),

        entitypanCard: Yup.mixed().required().label('Pan Card')
        // entitygstUndertaking: Yup.mixed().test('conditional-validation', 'GST Non-Availabilty Undertaking is required', function (value) {
        //     if (this.parent.gstapplicable === false) {
        //         return !!value;
        //     } else {
        //         return true;
        //     }
        // }),
        // entitygstDocument: Yup.mixed().test('conditional-validation', 'GST Document is required', function (value) {
        //     if (this.parent.gstapplicable === true) {
        //         return !!value;
        //     } else {
        //         return true;
        //     }
        // })
    }),
    page2: Yup.object({
        authorizedPersonFirstname: Yup.string().required().label('First Name'),
        authorizedPersonLastname: Yup.string().required().label('Last Name'),
        authorizedPersonPrimaryMobileNumber: Yup.string()
            .required()
            .label('Mobile Number')
            .min(10, 'Mobile no. should not be less than 10 digit')
            .max(10, 'Mobile no. should not be more than 10 digit')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number '),
        // .test('authorizedPersonPrimaryMobileNumber', 'Mob no. already exists', async function (value, context) {
        //     if (value !== previousValues.authorizedPersonPrimaryMobileNumber && context.parent.authorizedPersonPrimaryMobileNumberOtpReferenceId) {
        //         const payload = {
        //             RequestInfo: {},
        //             tenantId: 'ga',
        //             mobileNumber: value.toString() || ''
        //         };
        //         previousValues.authorizedPersonPrimaryMobileNumber = context.parent.authorizedPersonPrimaryMobileNumber;
        //         try {
        //             let response: any = value.length === 10 && (await checkUserAvailability(payload));
        //             response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

        //             if (response.data.isAvailable) {
        //                 errors.authorizedPersonPrimaryMobileNumber = false;
        //                 return true;
        //             } else {
        //                 errors.authorizedPersonPrimaryMobileNumber = true;
        //                 return false;
        //             }
        //         } catch (e) {
        //             errors.authorizedPersonPrimaryMobileNumber = true;
        //             return false;
        //         }
        //     } else if (value !== previousValues.authorizedPersonPrimaryMobileNumber) return true;
        //     return !errors.authorizedPersonPrimaryMobileNumber;
        // }),

        authorizedPersonEmailId: Yup.string().required().label('Email Id').matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address'),
        // .test('authorizedPersonEmailId', 'Email already exists', async function (value, context) {
        //     if (value !== previousValues.authorizedPersonEmailId && context.parent.authorizedPersonEmailIdOtpReferenceId) {
        //         const payload = {
        //             RequestInfo: {},
        //             tenantId: 'ga',
        //             emailId: value || ''
        //         };
        //         previousValues.authorizedPersonEmailId = context.parent.authorizedPersonEmailId;
        //         try {
        //             let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
        //             response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

        //             if (response.data.isAvailable) {
        //                 errors.authorizedPersonEmailId = false;
        //                 return true;
        //             } else {
        //                 errors.authorizedPersonEmailId = true;
        //                 return false;
        //             }
        //         } catch (e) {
        //             errors.authorizedPersonEmailId = true;
        //             return false;
        //         }
        //     } else if (value !== previousValues.authorizedPersonEmailId) return true;
        //     return !errors.authorizedPersonEmailId;
        // }),
        authorizedPersonAlternativeMobileNumber: Yup.string()
            .nullable()
            .test('valid-alternate-mobile', 'Please enter valid Alternate Mobile No.', function (value: any) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return /^(null|[0-9]{10})$/.test(value); // Validate against 'null' or 10-digit number
            }),
        authorizedPersonPowerOfAttorneyDocument: Yup.object().required().label('Power Of Attorney Document')
    }),
    page3: Yup.object({
        ownership: Yup.array().min(1, 'Ownership is required')
    }),
    page4: Yup.object({
        accountNo: Yup.string().required().label('Account Number'),
        ifscCode: Yup.string().required().label('IFSC Code').matches(CONSTANT_REGEX_SAMPLE.IFSC, 'Enter valid IFSC Code')
        // bankName: Yup.string().required().label('Bank Name'),
        // branchAddress: Yup.string().required().label('Branch Address')
    })
};
export const NOC_CREATION_PAGE: { [pageno: string]: any } = {
    page2: Yup.object({
        mobile: Yup.string().min(10, 'Phone number should be at least 10 digit').max(10, 'Phone number should not be more than 10 digit').label('Mobile number'),
        email: Yup.string().label('Email Id').matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address'),
        panNo: Yup.string()
            .label('Pan No')
            .min(10, 'Pan no. should not be less than 10 digit')
            .max(10, 'Pan no. should not be more than 10 digit')
            .matches(CONSTANT_REGEX_SAMPLE.PAN, 'Enter valid PAN number'),
        pincode: Yup.string().label('Pin Code')
    })
};

export const DE_RE_ACTIVATION = Yup.object({
    effectiveDate: Yup.date().required('Date is required'),
    reasonForReActivating: Yup.object().required('Reason for re-activating is required')
});

export const COMMENTS = Yup.object().shape({
    comments: Yup.string().required('Comments are required')
});

export const OCCASION = Yup.object().shape({
    comments: Yup.string().required('Occasion is required')
});

export const leaseDeedValidation = Yup.object({
    entityname: Yup.string().required().label('Entity Name')
});

export const NOC_COMMENTS = Yup.object().shape({
    nocWorkflowDetailsInput: Yup.array().of(
        Yup.object().shape({
            nocType: Yup.string().nullable().required('NOC Type is required'),
            comments: Yup.string().nullable().required('Comment is required')
        })
    )
});

export const OWNERSHIP_VALIDATION = Yup.object().shape({
    ownershipName: Yup.string().required().label('Name'),
    ownershipInterest: Yup.string()
        .required()
        .label('Ownership Interest')
        .test('ownership validation', 'Ownership Interest must be between 1-100', function (value, context) {
            const interest = +value;
            if (interest > 100) {
                return false;
            } else {
                return true;
            }
        })
        .test('ownership validation', 'Total Ownership Interest exceeded by 100', function (value, context) {
            console.log('eeeeeee', context.parent.totalOwnership);
            if (Number(context.parent.totalOwnership) + +value > 100) {
                return false;
            } else return true;
        }),

    ownershipContactNo: Yup.string()
        .test('conditionalValidation', 'Enter valid mobile number', function (value) {
            if (this.parent.isMigration === true && (!value || value === null)) {
                return true;
            } else if (this.parent.isMigration === true && value) {
                // if (value === null || value === '' || value === undefined) {
                //     return true; // Null or empty string should fail validation
                // }
                return /^(null|[0-9]{10})$/.test(value);
            } else if (!value) {
                return this.createError({ message: 'Mobile number is a required field' });
            } else {
                return /^[0-9]{10}$/.test(value);
            }
        })
        // .min(10, 'Phone number should be at least 10 digits')
        // .max(10, 'Phone number should not be more than 10 digits')
        .label('Mobile number')
        .nullable(),

    // Yup.string().min(10, 'Phone number should be at least 10 digit').max(10, 'Phone number should not be more than 10 digit').required().label('Mobile number'),
    // ownershipEmail: Yup.string()
    //     .label('Email Id')
    //     .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
    //     .test('conditional-validation', 'Invalid Email Id', function (value) {
    //         if (this.parent.individualOrOrganization === 'Individual') {
    //             if (value && CONSTANT_REGEX_SAMPLE.EMAIL.test(value)) return true;
    //             else return !!value;
    //         } else {
    //             return true;
    //         }
    //     }),

    ownershipGender: Yup.object()
        .label('Gender')
        .test('conditional-validation', 'Gender is a required field', function (value) {
            if (this.parent.individualOrOrganization === 'Individual') {
                return !!value;
            } else {
                return true;
            }
        }),
    ownershipAddress1: Yup.string().required().label('Address'),
    ownershippincode: Yup.string().required().label('Pin Code'),
    ownershipdistrict: Yup.string().required().label('District'),
    ownershipstate: Yup.string().required().label('State'),
    ownershipcountry: Yup.string().required().label('Country'),
    ownershipLocalPersonDocument: Yup.mixed().test('conditional-validation', 'Local Person document is required field', function (value) {
        if (this.parent.isLocalPerson === true) {
            return !!value;
        } else {
            return true;
        }
    })
    // ownershipPanCardDocument: Yup.mixed().required().label('Pan Card')
    // ownershipEducationDocument: Yup.mixed().required().label('Education Document')
    // ownershipLocalPersonDocument: Yup.mixed().required().label('Local Person Document'),
    // ownershipHighestQualification: Yup.object().required().label('Highest qualification')
});

export const ALLOTMENT_FORM_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({
        applicantName: Yup.string().required().label('Name'),
        category: Yup.object().required().label('Category'),
        emailId: Yup.string()
            .required()
            .label('Email Id')
            .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
            .test('emailId', 'Email already exists', async function (value, context) {
                if (value !== previousValues.emailId) {
                    const payload = {
                        RequestInfo: requestInfo(),
                        tenantId: 'ga',
                        emailId: value || '',
                        type: USER_TYPE.ENTERPRISE,
                        uuid: context.parent.uuid
                    };
                    previousValues.emailId = context.parent.emailId;
                    try {
                        let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                        if (response.data.isAvailable) {
                            errors.emailId = false;
                            return true;
                        } else {
                            errors.emailId = true;
                            return false;
                        }
                    } catch (e) {
                        errors.emailId = true;
                        return false;
                    }
                } else if (this.parent.underProcess) {
                    return true;
                }

                return !errors.emailId;
            }),
        phoneNumber: Yup.string()
            .min(10, 'Phone No should be at least 10 digit')
            .max(10, 'Phone No should not be more than 10 digit')
            .required()
            .label('Phone No')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number '),
        // .test('unique-phone', 'Phone No. already exists', async function (value, context) {
        //     if (value !== previousValues.phoneNumber) {
        //         const payload = {
        //             RequestInfo: requestInfo(),
        //             tenantId: 'ga',
        //             mobileNumber: value.toString() || '',
        //             type: USER_TYPE.ENTERPRISE,
        //             uuid: context.parent.uuid
        //         };
        //         previousValues.phoneNumber = context.parent.phoneNumber;
        //         try {
        //             let response: any = value.length === 10 && (await checkUserAvailability(payload));
        //             if (response.data.isAvailable) {
        //                 errors.phoneNumber = false;
        //                 return true;
        //             } else {
        //                 errors.phoneNumber = true;
        //                 return false;
        //             }
        //         } catch (e) {
        //             errors.phoneNumber = true;
        //             return false;
        //         }
        //     } else if (this.parent.underProcess) {
        //         return true;
        //     }
        //     return !errors.phoneNumber;
        // }),
        applicantPanNo: Yup.string()
            .required()
            .label('Pan')
            .min(10, 'Pan no. should not be less than 10 digit')
            .max(10, 'Pan no. should not be more than 10 digit')
            .matches(CONSTANT_REGEX_SAMPLE.PAN, 'Enter valid PAN number')
            .test('applicantPanNo', 'PAN No. already exists', async function (value, context) {
                if (value !== previousValues.applicantPanNo && value?.length === 10) {
                    const payload = {
                        RequestInfo: requestInfo(),
                        tenantId: 'ga',
                        panNumber: value || '',
                        type: 'ENTERPRISE',
                        uuid: context.parent.uuid
                    };
                    previousValues.applicantPanNo = context.parent.applicantPanNo;
                    try {
                        let response: any = CONSTANT_REGEX_SAMPLE.PAN.test(value) && (await checkUserAvailability(payload));
                        response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

                        if (response.data.isAvailable || response.data.applicationStatus === 'DRAFT') {
                            errors.applicantPanNo = false;
                            return true;
                        } else {
                            errors.applicantPanNo = true;
                            return false;
                        }
                    } catch (e) {
                        errors.applicantPanNo = true;
                        return false;
                    }
                } else if (value !== previousValues.applicantPanNo) {
                    return true;
                } else if (context.parent.underProcess) {
                    return true;
                }

                return !errors.applicantPanNo;
            }),
        address1: Yup.string().required().label('Address'),
        pincode: Yup.string().required().label('Pincode'),
        district: Yup.string().required().label('District'),
        state: Yup.string().required().label('State'),
        country: Yup.string().required().label('Country'),

        peraddress1: Yup.string().required().label('Address'),
        perpincode: Yup.string().required().label('Pincode'),
        perdistrict: Yup.string().required().label('District'),
        perstate: Yup.string().required().label('State'),
        percountry: Yup.string().required().label('Country'),

        authorizedPersonFirstname: Yup.string().required().label('First Name'),
        authorizedPersonLastName: Yup.string().required().label('Last Name'),
        pmobileNumber: Yup.string()
            .min(10, 'Phone number should be at least 10 digit')
            .max(10, 'Phone number should not be more than 10 digit')
            .required()
            .label('Mobile number')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number '),
        // .test('unique-phone', 'Phone No. already exists', async function (value, context) {
        // if (value !== previousValues.pmobileNumber) {
        //     const payload = {
        //         RequestInfo: requestInfo(),
        //         tenantId: 'ga',
        //         mobileNumber: value.toString() || '',
        //         // type: USER_TYPE.ENTERPRISE,
        //         uuid: context.parent.userUuid
        //     };
        //     previousValues.pmobileNumber = context.parent.pmobileNumber;
        //     try {
        //         let response: any = value.length === 10 && (await checkUserAvailability(payload));
        //         if (response.data.isAvailable) {
        //             errors.pmobileNumber = false;
        //             return true;
        //         } else {
        //             errors.pmobileNumber = true;
        //             return false;
        //         }
        //     } catch (e) {
        //         errors.pmobileNumber = true;
        //         return false;
        //     }
        // } else if (this.parent.underProcess) {
        //     return true;
        // }
        // return !errors.pmobileNumber;
        // }),
        authorizedEmailId: Yup.string().required().label('Email Id').matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address'),
        // .test('emailId', 'Email already exists', async function (value, context) {
        //     if (value !== previousValues.authorizedEmailId) {
        //         const payload = {
        //             RequestInfo: requestInfo(),
        //             tenantId: 'ga',
        //             emailId: value || '',

        //             uuid: context.parent.userUuid
        //         };
        //         previousValues.authorizedEmailId = context.parent.authorizedEmailId;
        //         try {
        //             let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
        //             if (response.data.isAvailable) {
        //                 errors.authorizedEmailId = false;
        //                 return true;
        //             } else {
        //                 errors.authorizedEmailId = true;
        //                 return false;
        //             }
        //         } catch (e) {
        //             errors.authorizedEmailId = true;
        //             return false;
        //         }
        //     } else if (this.parent.underProcess) {
        //         return true;
        //     }

        //     return !errors.authorizedEmailId;
        // }),
        accountNumber: Yup.string().required().label('Account Number'),
        ifscCode: Yup.string().required().label('IFSC Code'),
        entityProprietorshipFirm: Yup.mixed().test('conditional-validation', '1)	Letter stating the name of the Proprietorship firm is a required field', function (value) {
            if (this.parent.category?.code !== 'PROP_FIRM') return true; // Validation disabled
            return !!value;
        }),
        trustDeed: Yup.mixed().test('conditional-validation', 'Trust Deed is a required field', function (value) {
            if (this.parent.category?.code !== 'TRUST') return true; // Validation disabled
            return !!value;
        }),
        entityProprietorIdentity: Yup.mixed().test('conditional-validation', 'Proprietor Identity is a required field', function (value) {
            if (this.parent.category?.code !== 'PROP_FIRM') return true; // Validation disabled
            return !!value;
        }),
        partnershipDeed: Yup.mixed().test('conditional-validation', 'Partnership Deed is a required field', function (value) {
            if (this.parent.category?.code !== 'PARTNERSHIP') return true; // Validation disabled
            return !!value;
        }),
        certificateOfRegistrationFirms: Yup.mixed().test('conditional-validation', 'Certificate of registration from the Registrar of Firms is a required field', function (value) {
            if (this.parent.category?.code !== 'PARTNERSHIP') return true; // Validation disabled
            return !!value;
        }),
        certificateOfIncorporation: Yup.mixed().test('conditional-validation', 'Certificate of incorporation from Registrar of Companies is a required field', function (value) {
            if (this.parent.category?.code !== 'LIMITED_LIABILITY_PARTNERSHIP' || this.parent.category?.code !== 'LTD_COMPANY') return true; // Validation disabled
            return !!value;
        }),
        certificateOfRegistrationSocieties: Yup.mixed().test('conditional-validation', 'Certificate of registration from the Registrar of Societies is a required field', function (value) {
            if (this.parent.category?.code !== 'COOP_SOCIETY') return true; // Validation disabled
            return !!value;
        }),
        copyRelevantNotification: Yup.mixed().test('conditional-validation', 'Copy of relevant notification is a required field', function (value) {
            if (this.parent.category?.code !== 'GOV_STAT_CORP') return true; // Validation disabled
            return !!value;
        }),
        applicantPancard: Yup.mixed().test('conditional-validation', 'PAN card is required', function (value) {
            return !!value;
        }),
        letter: Yup.mixed()
            .label('The letter / resolution / power of attorney authorising the authorised person')
            .test('conditional-validation', 'The letter / resolution / power of attorney is a required field', function (value) {
                return !!value;
            })
            .nullable(),
        alternateMobileNumber: Yup.string()
            .nullable()
            .test('valid-alternate-mobile', 'Please enter valid Alternate Mobile No.', function (value: any) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return /^(null|[0-9]{10})$/.test(value); // Validate against 'null' or 10-digit number
            }),
        udyamNo: Yup.string()
            .nullable()
            .test('conditional-validation', 'Please enter valid Udyam Registration Number', function (value) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return /^[A-Z]{5}-[A-Z]{2}-\d{2}-\d{7}$/.test(value);
            })
            .test('conditiona-validation-2', 'Udyam Registration Number is a required field', function (value) {
                if (this.parent.udhyam !== true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        RegCertificate: Yup.mixed().test('conditional-validation', 'Udyam Registered Enterprise document is a required field', function (value) {
            if (this.parent.udhyam !== true) return true; // Validation disabled
            return !!value;
        }),
        experienceCategory: Yup.mixed().test('conditional-validation', 'Relevant Experience is a required field', function (value) {
            if (this.parent.priorExperience !== true) {
                return true;
            } else if (this.parent.priorExperience === true) {
                return !!value;
            }
        }),
        experienceCertificate: Yup.mixed().test('conditional-validation', 'Relevant Experience Certificate is required a required field', function (value) {
            if (this.parent.priorExperience !== true) return true; // Validation disabled
            return !!value;
        }),
        DPIITCertificate: Yup.mixed().test('conditional-validation', 'DPIIT/Government of Goa, Certificate of Recognition for Startup is a required field', function (value) {
            if (this.parent.isStartup !== true) return true; // Validation disabled
            return !!value;
        })
    }),
    page2: Yup.object({
        estateName: Yup.object()
            .label('Estate Name')
            .test('conditional-validation', 'Estate Name is a required field', function (value) {
                if (this.parent.isAdvertisement === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        phase: Yup.object()
            .label('Phase')
            .test('conditional-validation', 'Phase is a required field', function (value: any) {
                if (this.parent.isAdvertisement === true) {
                    return true;
                } else {
                    return !!value?.code;
                }
            }),
        plotNo: Yup.object()
            .label('Plot No')
            .test('conditional-validation', 'Plot No is a required field', function (value: any) {
                if (this.parent.isAdvertisement === true) {
                    return true;
                } else {
                    return !!value?.code;
                }
            }),
        plotType: Yup.object()
            .label('Plot Type')
            .test('conditional-validation', 'Plot Type is a required field', function (value: any) {
                if (this.parent.isAdvertisement === true) {
                    return true;
                } else {
                    return !!value?.code;
                }
            })
    }),
    page3: Yup.object({
        subCategory: Yup.array()
            .of(Yup.object().shape({ code: Yup.string().required(), name: Yup.string().required() }))
            .min(1)
            .label('Sub-category')
            .test('conditional-validation', 'Special-category is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        projectType: Yup.object()
            .label('Project Type')
            .test('conditional-validation', 'Project Type is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        applicantGstno: Yup.string()
            .label('GST Number')
            .required()
            .when('applicantGst', ([applicantGst], schema) => {
                return applicantGst ? schema.min(15).max(15) : schema.min(0).notRequired();
            })
            .test('conditional-validation', 'Please enter valid GST Number', function (value) {
                const matchString = value?.substring(2, 12);
                if (this.parent.applicantPanNo !== matchString && this.parent.applicantGst === true) {
                    return false;
                } else {
                    return true;
                }
            }),
        applicantGstdocument: Yup.mixed().test('conditional-validation', 'GST document is a required field', function (value) {
            if (this.parent.applicantGst !== true) return true; // Validation disabled
            return !!value;
        }),
        entitygstUndertaking: Yup.mixed().test('conditional-validation', 'GST Non-Availabilty Undertaking is a required field', function (value) {
            if (this.parent.applicantGst === false) {
                return !!value;
            } else {
                return true;
            }
        }),
        waterRequirement: Yup.object()
            .label('Water requirement')
            .test('conditional-validation', 'Water requirement is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        powerRequirement: Yup.object()
            .label('Power requirement')
            .test('conditional-validation', 'Power requirement is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        pollutionCategory: Yup.object()
            .label('Pollution category')
            .test('conditional-validation', 'Pollution category is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        employement: Yup.object()
            .label('Employment persons')
            .test('conditional-validation', 'Employment persons is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        projectCost: Yup.string()
            .label('Project Cost')
            .test('conditional-validation', 'Project Cost is a required field', function (value: any) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else if (+value <= 0) {
                    return false;
                } else {
                    return !!value;
                }
            }),
        meansOfFinance: Yup.array()
            .label('Means of Finance')
            .test('conditional-validation', 'Means of Finance is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        inPrincipleApproval: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.specialProject !== true) return true; // Validation disabled
            return !!value;
        }),
        detailedProjectReport: Yup.mixed().test('conditional-validation', 'Detailed Project Report is required', function (value) {
            return !!value;
        }),
        file3: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.confirmation !== true) return true; // Validation disabled
            return !!value;
        })
    }),
    page4: Yup.object({}),
    page5: Yup.object({
        /* paymentGateway: Yup.object()
            .label('Payment Gateway')
            .test('conditional-validation', 'Payment Gateway is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }) */
    })
};

export const MIGRATION_FORM_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({
        applicantName: Yup.string().required().label('Name'),
        emailId: Yup.string()
            .required()
            .label('Email Id')
            .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
            .test('emailId', 'Email already exists', async function (value, context) {
                if (value !== previousValues.emailId) {
                    const payload = {
                        RequestInfo: requestInfo(),
                        tenantId: 'ga',
                        emailId: value || '',
                        type: USER_TYPE.ENTERPRISE,
                        uuid: context.parent.entityUuid
                    };
                    previousValues.emailId = context.parent.emailId;
                    try {
                        let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                        if (response.data.isAvailable) {
                            errors.emailId = false;
                            return true;
                        } else {
                            errors.emailId = true;
                            return false;
                        }
                    } catch (e) {
                        errors.emailId = true;
                        return false;
                    }
                } else if (this.parent.underProcess) {
                    return true;
                }

                return !errors.emailId;
            }),
        phoneNumber: Yup.string()
            .label('Phone No')
            .nullable()
            .test('valid-mobile', 'Please enter valid Mobile No.', function (value: any) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return /^(null|[0-9]{10})$/.test(value); // Validate against 'null' or 10-digit number
            }),
        // .min(10, 'Phone No should be at least 10 digit')
        // .max(10, 'Phone No should not be more than 10 digit')
        // .required()
        // .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number'),
        // .test('unique-phone', 'Phone No. already exists', async function (value, context) {
        //     if (value !== previousValues.phoneNumber) {
        //         const payload = {
        //             RequestInfo: requestInfo(),
        //             tenantId: 'ga',
        //             mobileNumber: value.toString() || '',
        //             type: USER_TYPE.ENTERPRISE,
        //             uuid: context.parent.entityUuid
        //         };
        //         previousValues.phoneNumber = context.parent.phoneNumber;
        //         try {
        //             let response: any = value.length === 10 && (await checkUserAvailability(payload));
        //             if (response.data.isAvailable) {
        //                 errors.phoneNumber = false;
        //                 return true;
        //             } else {
        //                 errors.phoneNumber = true;
        //                 return false;
        //             }
        //         } catch (e) {
        //             errors.phoneNumber = true;
        //             return false;
        //         }
        //     } else if (this.parent.underProcess) {
        //         return true;
        //     }
        //     return !errors.phoneNumber;
        // }),

        category: Yup.object().required().label('Lessee category'),
        entityProprietorshipFirm: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.category?.code !== 'PROP_FIRM') return true; // Validation disabled
            return !!value;
        }),
        trustDeed: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.category?.code !== 'TRUST') return true; // Validation disabled
            return !!value;
        }),
        entityProprietorIdentity: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.category?.code !== 'PROP_FIRM') return true; // Validation disabled
            return !!value;
        }),
        partnershipDeed: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.category?.code !== 'PARTNERSHIP') return true; // Validation disabled
            return !!value;
        }),
        certificateOfRegistrationFirms: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.category?.code !== 'PARTNERSHIP') return true; // Validation disabled
            return !!value;
        }),
        certificateOfIncorporation: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.category?.code === 'LIMITED_LIABILITY_PARTNERSHIP' || this.parent.category?.code === 'LTD_COMPANY') return !!value;
            return true;
        }),
        certificateOfRegistrationSocieties: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.category?.code !== 'COOP_SOCIETY') return true; // Validation disabled
            return !!value;
        }),
        copyRelevantNotification: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.category?.code !== 'GOV_STAT_CORP') return true; // Validation disabled
            return !!value;
        }),
        applicantPanNo: Yup.string()
            .required()
            .label('Pan')
            .min(10, 'Pan no. should not be less than 10 digit')
            .max(10, 'Pan no. should not be more than 10 digit')
            .matches(CONSTANT_REGEX_SAMPLE.PAN, 'Enter valid PAN number')
            .test('applicantPanNo', 'PAN No. already exists', async function (value, context) {
                if (value !== previousValues.applicantPanNo && value?.length === 10) {
                    const payload = {
                        RequestInfo: requestInfo(),
                        tenantId: 'ga',
                        panNumber: value || '',
                        type: 'ENTERPRISE',
                        uuid: context.parent.entityUuid
                    };
                    previousValues.applicantPanNo = context.parent.applicantPanNo;
                    try {
                        let response: any = CONSTANT_REGEX_SAMPLE.PAN.test(value) && (await checkUserAvailability(payload));
                        response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

                        if (response.data.isAvailable || response.data.applicationStatus === 'DRAFT') {
                            errors.applicantPanNo = false;
                            return true;
                        } else {
                            errors.applicantPanNo = true;
                            return false;
                        }
                    } catch (e) {
                        errors.applicantPanNo = true;
                        return false;
                    }
                } else if (value !== previousValues.applicantPanNo) {
                    return true;
                } else if (context.parent.underProcess) {
                    return true;
                }

                return !errors.applicantPanNo;
            }),
        applicantPancard: Yup.mixed().required().label('Pan Card'),

        address1: Yup.string().required().label('Address'),
        pincode: Yup.string().required().label('Pincode'),
        district: Yup.string().required().label('District'),
        state: Yup.string().required().label('State'),
        country: Yup.string().required().label('Country'),

        peraddress1: Yup.string().required().label('Address'),
        perpincode: Yup.string().required().label('Pincode'),
        perdistrict: Yup.string().required().label('District'),
        perstate: Yup.string().required().label('State'),
        percountry: Yup.string().required().label('Country'),

        firstName: Yup.string().required().label('First Name'),
        lastName: Yup.string().required().label('Last Name'),
        pmobileNumber: Yup.string()
            .min(10, 'Phone number should be at least 10 digit')
            .max(10, 'Phone number should not be more than 10 digit')
            .required()
            .label('Mobile number')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number '),
        // .test('unique-phone', 'Mobile No. already exists', async function (value, context) {
        //     if (value !== previousValues.pmobileNumber) {
        //         const payload = {
        //             RequestInfo: requestInfo(),
        //             tenantId: 'ga',
        //             mobileNumber: value.toString() || '',
        //             uuid: context.parent.entityUserUuid
        //         };
        //         previousValues.pmobileNumber = context.parent.pmobileNumber;
        //         try {
        //             let response: any = value.length === 10 && (await checkUserAvailability(payload));
        //             if (response.data.isAvailable) {
        //                 errors.pmobileNumber = false;
        //                 return true;
        //             } else {
        //                 errors.pmobileNumber = true;
        //                 return false;
        //             }
        //         } catch (e) {
        //             errors.pmobileNumber = true;
        //             return false;
        //         }
        //     }
        //     return !errors.pmobileNumber;
        // }),
        alternateMobileNumber: Yup.string()
            .nullable()
            .test('valid-alternate-mobile', 'Please enter valid Alternate Mobile No.', function (value: any) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return /^(null|[0-9]{10})$/.test(value); // Validate against 'null' or 10-digit number
            }),
        authorizedEmailId: Yup.string().required().label('Email Id').matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address'),
        letter: Yup.mixed().required().label('Letter/resolution/power of attorney'),
        // .test('authorizedEmailId', 'Email already exists', async function (value, context) {
        //     if (value !== previousValues.authorizedEmailId) {
        //         const payload = {
        //             RequestInfo: requestInfo(),
        //             tenantId: 'ga',
        //             emailId: value || '',
        //             uuid: context.parent.entityUserUuid
        //         };
        //         previousValues.authorizedEmailId = context.parent.authorizedEmailId;
        //         try {
        //             let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
        //             if (response.data.isAvailable) {
        //                 errors.authorizedEmailId = false;
        //                 return true;
        //             } else {
        //                 errors.authorizedEmailId = true;
        //                 return false;
        //             }
        //         } catch (e) {
        //             errors.authorizedEmailId = true;
        //             return false;
        //         }
        //     }

        //     return !errors.authorizedEmailId;
        // }),
        accountNumber: Yup.string().required().label('Account Number'),
        ifscCode: Yup.string().required().label('IFSC Code'),
        udyamNo: Yup.string()
            .nullable()
            .test('conditional-validation', 'Please enter valid Udyam Registration Number', function (value) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return /^[A-Z]{5}-[A-Z]{2}-\d{2}-\d{7}$/.test(value);
            })
            .test('conditiona-validation-2', 'Udyam Registration Number is a required field', function (value) {
                if (this.parent.udhyam !== true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        RegCertificate: Yup.mixed().test('conditional-validation', 'Udyam Registered Enterprise document is a required field', function (value) {
            if (this.parent.udhyam !== true) return true; // Validation disabled
            return !!value;
        }),
        experienceCategory: Yup.mixed().test('conditional-validation', 'Relevant Experience is a required field', function (value) {
            if (this.parent.priorExperience !== true) {
                return true;
            } else if (this.parent.priorExperience === true) {
                return !!value;
            }
        }),
        experienceCertificate: Yup.mixed().test('conditional-validation', 'Relevant Experience Certificate is required a required field', function (value) {
            if (this.parent.priorExperience !== true) return true; // Validation disabled
            return !!value;
        }),
        DPIITCertificate: Yup.mixed().test('conditional-validation', 'DPIIT/Government of Goa, Certificate of Recognition for Startup is a required field', function (value) {
            if (this.parent.isStartup !== true) return true; // Validation disabled
            return !!value;
        })
        // alternateMobileNumber: Yup.string().label('Please enter valid Alternate Mobile No.').matches(CONSTANT_REGEX_SAMPLE.ALT_MOBILE_NUMBER, 'Please enter valid Alternate Mobile No.')
    }),
    page2: ALLOTMENT_FORM_VALIDATION['page2'],
    page3: Yup.object({
        subCategory: Yup.array()
            .of(Yup.object().shape({ code: Yup.string().required(), name: Yup.string().required() }))
            .min(1)
            .label('Sub-category')
            .test('conditional-validation', 'Special-category is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        // subCategoryDetails: Yup.string()
        //     .label('Sub-category Details')
        //     .test('conditional-validation', 'Sub-category Details is a required field', function (value) {
        //         if (this.parent.isSamePlotApplicationExist === true) {
        //             return true;
        //         } else {
        //             return !!value;
        //         }
        //     }),
        // enterpriseClassificationCode: Yup.string().matches(CONSTANT_REGEX_SAMPLE.NIC, 'Enter valid NIC code').label('National Industrial Classification (NIC) Code'),
        // .test('conditional-validation', 'National Industrial Classification (NIC) Code is a required field', function (value) {
        //     if (this.parent.isSamePlotApplicationExist === true) {
        //         return true;
        //     } else {
        //         return !!value;
        //     }
        // }),
        enterpriseSpecialProjectCase: Yup.mixed().test('conditional-validation', 'Document is required', function (value) {
            if (this.parent.specialProject !== true || this.parent.isSamePlotApplicationExist === true) return true; // Validation disabled
            return !!value;
        }),
        projectType: Yup.mixed().test('conditional-validation', 'Project type is a required field', function (value) {
            if (this.parent.isSamePlotApplicationExist === true) {
                return true;
            } else {
                return !!value;
            }
        }),
        applicantGstno: Yup.string()
            .label('GST Number')
            .required()
            .when('applicantGst', ([applicantGst], schema) => {
                return applicantGst ? schema.min(15).max(15) : schema.min(0).notRequired();
            })
            .test('conditional-validation', 'Please enter valid GST Number', function (value) {
                const matchString = value?.substring(2, 12);
                if (this.parent.applicantPanNo !== matchString && this.parent.applicantGst === true) {
                    return false;
                } else {
                    return true;
                }
            }),
        applicantGstdocument: Yup.mixed().test('conditional-validation', 'GST document is a required field', function (value) {
            if (this.parent.applicantGst !== true || this.parent.isSamePlotApplicationExist === true) return true; // Validation disabled
            return !!value;
        }),
        entitygstUndertaking: Yup.mixed().test('conditional-validation', 'GST Non-Availabilty Undertaking is a required field', function (value) {
            if (this.parent.applicantGst === false || this.parent.isSamePlotApplicationExist === true) {
                return !!value;
            } else {
                return true;
            }
        }),

        pollutionCategory: Yup.object()
            .label('Pollution category')
            .test('conditional-validation', 'Pollution category is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        waterRequirement: Yup.object()
            .label('Water requirement')
            .test('conditional-validation', 'Water requirement is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        powerRequirement: Yup.object()
            .label('Power requirement')
            .test('conditional-validation', 'Power requirement is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        employement: Yup.object()
            .label('Employment generation')
            .test('conditional-validation', 'Employment generation is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === true) {
                    return true;
                } else {
                    return !!value;
                }
            })
        // specialProject: Yup.mixed()
        //     .label('Special project category')
        //     .test('conditional-validation', 'Special project category is a required field', function (value) {
        //         if (this.parent.isSamePlotApplicationExist === true) {
        //             return true;
        //         } else {
        //             return !!value;
        //         }
        //     }),
        // totalLandPremiumAmount: Yup.string()
        //     .label('Total Land Premium amount')
        //     .test('conditional-validation', 'Total Land Premium amount is a required field', function (value) {
        //         if (this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // leaseRentIncrementDate: Yup.string()
        //     .label('Next increment date')
        //     .test('conditional-validation', 'Next increment date is a required field', function (value) {
        //         if (this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesLastMeterReading: Yup.string()
        //     .label('Last meter reading')
        //     .test('conditional-validation', 'Last meter reading is a required field', function (value) {
        //         if (this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // totalPaidLandPremiumPrincipalAmount: Yup.string()
        //     .label('Total paid Land Premium principal amount')
        //     .test('conditional-validation', 'Total paid Land Premium principal amount is a required field', function (value) {
        //         if (this.options.context?.landPremiumDue === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // totalNumberOfInstallment: Yup.string()
        //     .label('Total number of installment')
        //     .test('conditional-validation', 'Total number of installments is a required field', function (value) {
        //         if (this.options.context?.landPremiumDue === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     })
        //     .test('max-validation', 'Total number of installment cannot be more than 10', function (value) {
        //         if (this.options.context?.landPremiumDue === true && this.parent.isSamePlotApplicationExist === true) {
        //             if (value !== undefined) {
        //                 return parseInt(value) <= 10;
        //             } else {
        //                 return true;
        //             }
        //         } else {
        //             return true;
        //         }
        //     }),
        // numberOfRemainingInstallment: Yup.string()
        //     .label('Number of installment remaining')
        //     .test('conditional-validation', 'Number of installments remaining is a required field', function (value) {
        //         if (this.options.context?.landPremiumDue === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     })
        //     .test('max-validation', 'Number of installment remaining cannot be more than 10', function (value) {
        //         if (this.options.context?.landPremiumDue === true && this.parent.isSamePlotApplicationExist === true) {
        //             if (value !== undefined) {
        //                 return parseInt(value) <= 10;
        //             } else {
        //                 return true;
        //             }
        //         } else {
        //             return true;
        //         }
        //     }),
        // nextInstallmentDate: Yup.string()
        //     .label('Next installment date')
        //     .test('conditional-validation', 'Next installment date is a required field', function (value) {
        //         if (this.options.context?.landPremiumDue === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // previousInterestRate: Yup.string()
        //     .label('Previous interest rate')
        //     .test('conditional-validation', 'Land Premium interest rate before new Regulation is a required field', function (value) {
        //         if (this.options.context?.landPremiumDue === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // leaseRentPrincipalAmount: Yup.string()
        //     .label('Principal amount')
        //     .test('conditional-validation', 'Principal amount is a required field', function (value) {
        //         if (this.options.context?.leaseRentPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // leaseRentPenaltyAmount: Yup.string()
        //     .label('Default interest')
        //     .test('conditional-validation', 'Default interest is a required field', function (value) {
        //         if (this.options.context?.leaseRentPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // leaseRentDueDate: Yup.string()
        //     .label('Due Date')
        //     .test('conditional-validation', 'Due Date is a required field', function (value) {
        //         if (this.options.context?.leaseRentPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // existingLeaseRentAmount: Yup.string()
        //     .label('Existing Lease Rent amount')
        //     .test('conditional-validation', 'Existing Lease Rent amount is a required field', function (value) {
        //         if (this.options.context?.leaseRentPaymentStatus === false && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // LeaseRentNextDueDate: Yup.string()
        //     .label('Next due date')
        //     .test('conditional-validation', 'Next due date is a required field', function (value) {
        //         if (this.options.context?.leaseRentPaymentStatus === false && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // houseTaxDueDate: Yup.string()
        //     .label('Due Date')
        //     .test('conditional-validation', 'Due Date is a required field', function (value) {
        //         if (this.options.context?.houseTaxPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // houseTaxPrincipalAmount: Yup.string()
        //     .label('Principal amount')
        //     .test('conditional-validation', 'Principal amount is a required field', function (value) {
        //         if (this.options.context?.houseTaxPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // houseTaxPenaltyAmount: Yup.string()
        //     .label('Default interest')
        //     .test('conditional-validation', 'Default interest is a required field', function (value) {
        //         if (this.options.context?.houseTaxPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // existingHouseTaxAmount: Yup.string()
        //     .label('Existing House Tax amount')
        //     .test('conditional-validation', 'Existing House Tax amount is a required field', function (value) {
        //         if (this.options.context?.houseTaxPaymentStatus === false && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // houseTaxNextDueDate: Yup.string()
        //     .label('Next due date')
        //     .test('conditional-validation', 'Next due date is a required field', function (value) {
        //         if (this.options.context?.houseTaxPaymentStatus === false && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesExistingAmount: Yup.string()
        //     .label('Existing water charges amount')
        //     .test('conditional-validation', 'Existing water charges amount is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === false && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesNextDueDate: Yup.string()
        //     .label('Next due date')
        //     .test('conditional-validation', 'Next due date is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === false && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesPrincipalAmount: Yup.string()
        //     .label('Principal amount')
        //     .test('conditional-validation', 'Principal amount is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesPenaltyAmount: Yup.string()
        //     .label('Default interest')
        //     .test('conditional-validation', 'Default interest is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // sgstOnWaterChargesPenaltyAmount: Yup.string()
        //     .label('SGST on default interest')
        //     .test('conditional-validation', 'SGST on default interest is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesDueDate: Yup.string()
        //     .label('Due Date')
        //     .test('conditional-validation', 'Due Date is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === true && this.parent.isSamePlotApplicationExist === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     })
    }),
    page4: Yup.object({
        plotActionType: Yup.object()
            .label('Type')
            .test('conditional-validation', 'Type Number is a required field', function (value) {
                if (this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        provisionalAllotmentOrderNumber: Yup.string()
            .label('Provisional Allotment Order Number')
            .test('conditional-validation', 'Provisional Allotment Order Number is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        allotmentType: Yup.mixed()
            .label('Allotment type')
            .test('conditional-validation', 'Allotment type is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        allotmentDate: Yup.string()
            .label('Allotment date')
            .test('conditional-validation', 'Allotment date is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        isLeaseDeedExecuted: Yup.string()
            .label('Is Lease Deed executed')
            .test('conditional-validation', 'Is Lease Deed executed is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseTermStartDate: Yup.string()
            .label('Lease term Start Date')
            .nullable()
            .test('conditional-validation', 'Lease Term start date is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseTermEndDate: Yup.string()
            .label('Lease term end Date')
            .nullable()
            .test('conditional-validation', 'Lease Term end date is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        isLeaseDeedRegistered: Yup.string()
            .label('Is Lease Deed registered')
            .test('conditional-validation', 'Is Lease Deed registered is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseDeedRegistrationDate: Yup.string()
            .label('Lease Deed registration date')
            .nullable()
            .test('conditional-validation', 'Lease Deed registration date is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this.parent.isLeaseDeedRegistered === 'true' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        registeredLeaseDeed: Yup.mixed()
            .label('Registered Lease Deed')
            .test('conditional-validation', 'Document is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this?.options?.context?.isLeaseDeedRegistered === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        executedLeaseDeed: Yup.mixed()
            .label('Executed Lease Deed')
            .test('conditional-validation', 'Document is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this?.options?.context?.isLeaseDeedExecuted === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        allotmentOrder: Yup.mixed()
            .label('Allotment Order')
            .test('conditional-validation', 'Document is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'ALLOTMENT' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        transferOrderNumber: Yup.string()
            .label('Transfer Order Number')
            .test('conditional-validation', 'Transfer Order Number is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        transferDate: Yup.string()
            .label('Transfer date')
            .nullable()
            .test('conditional-validation', 'Transfer date is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        originalLeasetermStartDate: Yup.string()
            .label('Original Lease Term start date')
            .nullable()
            .test('conditional-validation', 'Original Lease Term start date is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseTermStartDateTransfer: Yup.string()
            .label('Lease term Start Date')
            .nullable()
            .test('conditional-validation', 'Lease Term start date is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseTermEndDateTransfer: Yup.string()
            .label('Lease term end Date')
            .nullable()
            .test('conditional-validation', 'Lease Term end date is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),

        isTripartiteDeedExecuted: Yup.string()
            .label('Is Tripartite Deed executed')
            .test('conditional-validation', 'Is Tripartite Deed executed is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        transferOrder: Yup.mixed()
            .label('Transfer Order')
            .test('conditional-validation', 'Document is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        executedTripartiteDeed: Yup.mixed()
            .label('Executed Tripartite Deed')
            .nullable()
            .test('conditional-validation', 'Document is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this?.options?.context?.isTripartiteDeedExecuted === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        registeredTripartiteDeed: Yup.mixed()
            .label('Registered Tripartite Deed')
            .test('conditional-validation', 'Document is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this?.options?.context?.isTripartiteDeedRegistered === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        isTripartiteDeedRegistered: Yup.string()
            .label('Is Tripartite Deed registered')
            .test('conditional-validation', 'Is Tripartite Deed registered is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        tripartiteDeedRegistrationDate: Yup.string()
            .label('Tripartite Deed registration date')
            .nullable()
            .test('conditional-validation', 'Tripartite Deed registration date is a required field', function (value) {
                if (this.parent.plotActionType?.code === 'TRANSFER' && this.parent.isTripartiteDeedRegistered === 'true' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        extensionOrderNumber: Yup.string()
            .label('Extension Order Number')
            .test('conditional-validation', 'Extension Order Number is a required field', function (value) {
                if (this.parent.isExtension === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        extensionDate: Yup.string()
            .label('Extension date')
            .nullable()
            .test('conditional-validation', 'Extension date is a required field', function (value) {
                if (this.parent.isExtension === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        isDeedOfExtensionExecuted: Yup.string()
            .label('Is Tripartite Deed executed')
            .test('conditional-validation', 'Is Tripartite Deed executed is a required field', function (value) {
                if (this.parent.isExtension === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseTermStartDateExtension: Yup.string()
            .label('Lease Term start date')
            .nullable()
            .test('conditional-validation', 'Lease Term start date is a required field', function (value) {
                if (this.parent.isExtension === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseTermEndDateExtension: Yup.string()
            .label('Lease Term start date')
            .nullable()
            .test('conditional-validation', 'Lease Term start date is a required field', function (value) {
                if (this.parent.isExtension === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        executedDeedOfExtension: Yup.mixed()
            .label('Executed Deed Of Extension')
            .test('conditional-validation', 'Document is a required field', function (value) {
                if (this.parent.isExtension === true && this?.options?.context?.isDeedOfExtensionExecuted === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        isDeedOfExtensionRegistered: Yup.string()
            .label('Is Deed of Extension registered')
            .test('conditional-validation', 'Is Deed of Extension registered is a required field', function (value) {
                if (this.parent.isExtension === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        extensionOrder: Yup.mixed()
            .label('Extension Order')
            .test('conditional-validation', 'Extension Order is a required field', function (value) {
                if (this.parent.isExtension === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        registeredDeedOfExtension: Yup.mixed()
            .label('Registered Deed Of Extension')
            .test('conditional-validation', 'Registered Deed Of Extension is a required field', function (value) {
                if (this.parent.isExtension === true && this?.options?.context?.isDeedOfExtensionRegistered === true && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        deedOfExtensionRegistrationDate: Yup.string()
            .label('Deed of Extension registration date')
            .nullable()
            .test('conditional-validation', 'Deed of Extension registration date is a required field', function (value) {
                if (this.parent.isExtension === true && this.parent.isDeedOfExtensionRegistered === 'true' && this.parent.isSamePlotApplicationExist === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),

        migrationWaterConnection: Yup.array().of(
            Yup.object().shape({
                waterType: Yup.mixed()
                    .label('Water Type')
                    .test('conditional-validation', 'Water Type is a required field', function (value) {
                        if ((this.options.context?.waterConnection === true || this.options.context?.waterConnection === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return typeof value === 'object' && !!Object?.values(value)?.length;
                        } else if (this.options.context?.waterConnection === false || this.options.context?.waterConnection === 'false') {
                            return true;
                        }
                    }),
                meterNo: Yup.string()
                    .label('Meter No.')
                    .test('conditional-validation', 'Meter No. is a required field', function (value) {
                        if ((this.options.context?.waterConnection === true || this.options.context?.waterConnection === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return !!value;
                        } else if (this.options.context?.waterConnection === false || this.options.context?.waterConnection === 'false') {
                            return true;
                        }
                    }),
                connectionDate: Yup.string()
                    .label('Connection Date')
                    .nullable()
                    .test('conditional-validation', 'Connection Date is a required field', function (value) {
                        if ((this.options.context?.waterConnection === true || this.options.context?.waterConnection === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return !!value;
                        } else if (this.options.context?.waterConnection === false || this.options.context?.waterConnection === 'false') {
                            return true;
                        }
                    }),
                monthlyConsumption: Yup.string()
                    .label('Monthly Consumption')
                    .test('conditional-validation', 'Monthly Consumption is a required field', function (value) {
                        if ((this.options.context?.waterConnection === true || this.options.context?.waterConnection === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return !!value;
                        } else if (this.options.context?.waterConnection === false || this.options.context?.waterConnection === 'false') {
                            return true;
                        }
                    }),
                connectionPipeDia: Yup.mixed()
                    .label('Connection Pipe dia (in mm)')
                    .test('conditional-validation', 'Connection Pipe dia (in mm) is a required field', function (value) {
                        if ((this.options.context?.waterConnection === true || this.options.context?.waterConnection === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return typeof value === 'object' && !!Object?.values(value)?.length;
                        } else if (this.options.context?.waterConnection === false || this.options.context?.waterConnection === 'false') {
                            return true;
                        }
                    }),
                securityDeposit: Yup.string()
                    .label('Security Deposit')
                    .test('conditional-validation', 'Security Deposit is a required field', function (value) {
                        if ((this.options.context?.waterConnection === true || this.options.context?.waterConnection === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return !!value;
                        } else if (this.options.context?.waterConnection === false || this.options.context?.waterConnection === 'false') {
                            return true;
                        }
                    })
            })
        ),
        mortgageMigrationRequests: Yup.array().of(
            Yup.object().shape({
                mortgageType: Yup.mixed()
                    .label('Category of Mortgage')
                    .test('conditional-validation', 'Category of Mortgage is a required field', function (value) {
                        if ((this.options.context?.mortgageStatus === true || this.options.context?.mortgageStatus === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return typeof value === 'object' && !!Object?.values(value)?.length;
                        } else if (this.options.context?.mortgageStatus === false || this.options.context?.mortgageStatus === 'false') {
                            return true;
                        }
                    }),
                nocOrderNumber: Yup.string()
                    .label('NOC Order Number')
                    .test('conditional-validation', 'NOC Order Number is a required field', function (value) {
                        if ((this.options.context?.mortgageStatus === true || this.options.context?.mortgageStatus === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return !!value;
                        } else if (this.options.context?.mortgageStatus === false || this.options.context?.mortgageStatus === 'false') {
                            return true;
                        }
                    }),
                nocIssuedDate: Yup.string()
                    .label('NOC date')
                    .nullable()
                    .test('conditional-validation', 'NOC date is a required field', function (value) {
                        if ((this.options.context?.mortgageStatus === true || this.options.context?.mortgageStatus === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return !!value;
                        } else if (this.options.context?.mortgageStatus === false || this.options.context?.mortgageStatus === 'false') {
                            return true;
                        }
                    }),
                isPariPassu: Yup.string()
                    .label('Pari-passu')
                    .test('conditional-validation', 'Pari-passu is a required field', function (value) {
                        if ((this.options.context?.mortgageStatus === true || this.options.context?.mortgageStatus === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return !!value;
                        } else if (this.options.context?.mortgageStatus === false || this.options.context?.mortgageStatus === 'false') {
                            return true;
                        }
                    }),
                bankMortgageMigrationRequests: Yup.array().of(
                    Yup.object().shape({
                        bankIFSC: Yup.string()
                            .label('Bank IFSC Code')
                            .test('conditional-validation', 'Bank IFSC Code is a required field', function (value) {
                                if ((this.options.context?.mortgageStatus === true || this.options.context?.mortgageStatus === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                                    return !!value;
                                } else if (this.options.context?.mortgageStatus === false || this.options.context?.mortgageStatus === 'false') {
                                    return true;
                                }
                            })
                    })
                ),
                nocDocReferenceId: Yup.mixed()
                    .label('NOC documents/Bank letters')
                    .test('conditional-validation', 'NOC documents/Bank letters is a required field', function (value) {
                        if ((this.options.context?.mortgageStatus === true || this.options.context?.mortgageStatus === 'true') && this.options.context?.isSamePlotApplicationExist === false) {
                            return !!value;
                        } else if (this.options.context?.mortgageStatus === false || this.options.context?.mortgageStatus === 'false') {
                            return true;
                        }
                    })
            })
        )
        // subLeaseFeePayment: Yup.array().of(
        //     Yup.object().shape({
        //         principalAmount: Yup.string()
        //             .label('Principlal amount')
        //             .test('conditional-validation', 'Principal amount is a required field', function (value, context) {
        //                 const index = this.path.match(/\d+/g)![0];
        //                 if (
        //                     (this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === true || this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === 'true') &&
        //                     this.options.context?.isSamePlotApplicationExist === true
        //                 ) {
        //                     return !!value;
        //                 } else return true;
        //             }),
        //         penaltyAmount: Yup.string()
        //             .label('Default interest')
        //             .test('conditional-validation', 'Default interest is a required field', function (value, context) {
        //                 const index = this.path.match(/\d+/g)![0];
        //                 if (this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === false || this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === 'false') {
        //                     return true;
        //                 } else if (
        //                     (this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === true || this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === 'true') &&
        //                     this.options.context?.isSamePlotApplicationExist === true
        //                 ) {
        //                     return !!value;
        //                 } else return true;
        //             }),
        //         dueDate: Yup.string()
        //             .label('Due date')
        //             .test('conditional-validation', 'Due date is a required field', function (value, context) {
        //                 const index = this.path.match(/\d+/g)![0];
        //                 if (this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === false || this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === 'false') {
        //                     return true;
        //                 } else if (
        //                     (this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === true || this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === 'true') &&
        //                     this.options.context?.isSamePlotApplicationExist === true
        //                 ) {
        //                     return !!value;
        //                 } else return true;
        //             }),
        //         existingSubLeaseFee: Yup.string()
        //             .label('Existing Sub-Lease Fee')
        //             .test('conditional-validation', 'Existing Sub-Lease Fee is a required field', function (value, context) {
        //                 const index = this.path.match(/\d+/g)![0];
        //                 if (
        //                     (this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === false || this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === 'false') &&
        //                     this.options.context?.isSamePlotApplicationExist === true
        //                 ) {
        //                     return !!value;
        //                 } else return true;
        //             }),
        //         nextDueDate: Yup.string()
        //             .label('Next due date')
        //             .test('conditional-validation', 'Next due date is a required field', function (value, context) {
        //                 const index = this.path.match(/\d+/g)![0];
        //                 if (this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === true || this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === 'true') {
        //                     return true;
        //                 } else if (
        //                     (this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === false || this.options.context?.subLeaseFeePayment[index].subleaseFeeDue === 'false') &&
        //                     this.options.context?.isSamePlotApplicationExist === true
        //                 ) {
        //                     return !!value;
        //                 } else return true;
        //             })
        //     })
        // )
    }),
    page5: Yup.object({
        technicalClearance: Yup.array().of(
            Yup.object().shape({
                orderNo: Yup.string()
                    .nullable()
                    .label('Technical Clearance Order No')
                    .test('conditional-validation', ' Technical Clearance Order No is required field', function (value) {
                        if (this?.options?.context?.technicalClearanceOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                orderDate: Yup.string()
                    .label('Technical Clearance Order Date')
                    .nullable()
                    .test('conditional-validation', ' Technical Clearance Order Date is required field', function (value) {
                        if (this?.options?.context?.technicalClearanceOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                // validUpto: Yup.string()
                //     .label('Valid Upto')
                //     .nullable()
                //     .test('conditional-validation', 'Valid Upto is required field', function (value) {
                //         if (this?.options?.context?.technicalClearanceOrder === true) {
                //             return !!value;
                //         } else {
                //             return true;
                //         }
                //     }),
                serviceType: Yup.mixed()
                    .label('Service Type')
                    .nullable()
                    .test('conditional-validation', 'Service Type is required field', function (value) {
                        if (this?.options?.context?.technicalClearanceOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                documents: Yup.object({
                    TCOrder: Yup.mixed()
                        .label('Technical Clearance Order')
                        .nullable()
                        .test('conditional-validation', ' Technical Clearance Order isrequired field', function (value) {
                            if (this?.options?.context?.technicalClearanceOrder === true) {
                                return !!value;
                            } else {
                                return true;
                            }
                        })
                })
            })
        ),
        constructionLicense: Yup.array().of(
            Yup.object().shape({
                technicalClearanceOrderNumber: Yup.mixed()
                    .label('Technical Clearance Order Number')
                    .nullable()
                    .test('conditional-validation', 'Technical Clearance Order Number is required field', function (value) {
                        if (this?.options?.context?.constructionLicenseOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                orderNo: Yup.string()
                    .label('Construction License Order No')
                    .nullable()
                    .test('conditional-validation', 'Construction License Order No is required field', function (value) {
                        if (this?.options?.context?.constructionLicenseOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                orderDate: Yup.string()
                    .label('Construction License Order Date')
                    .nullable()
                    .test('conditional-validation', 'Construction License Order Date is required field', function (value) {
                        if (this?.options?.context?.constructionLicenseOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                validUpto: Yup.string()
                    .label('Valid Upto')
                    .nullable()
                    .test('conditional-validation', 'Valid Upto is required field', function (value) {
                        if (this?.options?.context?.constructionLicenseOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                builtUpArea: Yup.string()
                    .label('Built-up Area')
                    .nullable()
                    .test('conditional-validation', 'Built-up Area is required field', function (value) {
                        if (this?.options?.context?.constructionLicenseOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                estimatedConstructionCost: Yup.string()
                    .label('Estimated Construction Cost')
                    .nullable()
                    .test('conditional-validation', 'Estimated Construction Cost is required field', function (value) {
                        if (this?.options?.context?.constructionLicenseOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                documents: Yup.object({ CLOrder: Yup.mixed().label('Construction License Order') })
                    .nullable()
                    .test('conditional-validation', 'Construction License Order is required field', function (value) {
                        if (this?.options?.context?.constructionLicenseOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    })
            })
        ),
        cooc: Yup.array().of(
            Yup.object().shape({
                constructionLicenseOrderNumber: Yup.mixed()
                    .label('Construction License Order No')
                    .nullable()
                    .test('conditional-validation', 'Construction License Order No is required field', function (value) {
                        if (this?.options?.context?.coocOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                builtUpArea: Yup.string()
                    .label('Built-up Area')
                    .nullable()
                    .test('conditional-validation', 'Built-up Area is required field', function (value) {
                        if (this?.options?.context?.coocOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                orderNo: Yup.string()
                    .label('Order No')
                    .nullable()
                    .test('conditional-validation', 'Order No is required field', function (value) {
                        if (this?.options?.context?.coocOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                orderDate: Yup.string()
                    .label('Order Date')
                    .nullable()
                    .test('conditional-validation', 'Order Date is required field', function (value) {
                        if (this?.options?.context?.coocOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                // validUpto: Yup.string()
                //     .label('Valid Upto')
                //     .nullable()
                //     .test('conditional-validation', 'Valid Upto is required field', function (value) {
                //         if (this?.options?.context?.coocOrder === true) {
                //             return !!value;
                //         } else {
                //             return true;
                //         }
                //     }),
                documents: Yup.object({ CLOCorder: Yup.mixed().label('Completion Order/Occupancy Certificate') })
                    .nullable()
                    .test('conditional-validation', 'Completion Order/Occupancy Certificate is required field', function (value) {
                        if (this?.options?.context?.coocOrder === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    })
            })
        )
    }),
    page6: Yup.object({
        totalLandPremiumAmount: Yup.string().required().label('Total Land Premium amount'),
        leaseRentIncrementDate: Yup.string().required().label('Next increment date'),
        // waterChargesLastMeterReading: Yup.string().required().label('Last meter reading'),
        totalPaidLandPremiumPrincipalAmount: Yup.string()
            .label('Total paid Land Premium principal amount')
            .nullable()
            .test('conditional-validation', 'Total paid Land Premium principal amount is a required field', function (value) {
                if (this.options.context?.landPremiumDue === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        totalNumberOfInstallment: Yup.string()
            .label('Total number of installment')
            .test('conditional-validation', 'Total number of installments is a required field', function (value) {
                if (this.options.context?.landPremiumDue === true) {
                    return !!value;
                } else {
                    return true;
                }
            })
            .test('max-validation', 'Total umber of installment cannot be more than 10', function (value) {
                if (this.options.context?.landPremiumDue === true) {
                    if (value !== undefined) {
                        return parseInt(value) <= 10;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }),
        numberOfRemainingInstallment: Yup.string()
            .label('Number of installment remaining')
            .test('conditional-validation', 'Number of installments remaining is a required field', function (value) {
                if (this.options.context?.landPremiumDue === true) {
                    return !!value;
                } else {
                    return true;
                }
            })
            .test('max-validation', 'Number of installment remaining cannot be more than 10', function (value) {
                if (this.options.context?.landPremiumDue === true) {
                    if (value !== undefined) {
                        return parseInt(value) <= 10;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            }),
        nextInstallmentDate: Yup.string()
            .label('Next installment date')
            .nullable()
            .test('conditional-validation', 'Next installment date is a required field', function (value) {
                if (this.options.context?.landPremiumDue === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        previousInterestRate: Yup.string()
            .label('Previous interest rate')
            .nullable()
            .test('conditional-validation', 'Land Premium interest rate before new Regulation is a required field', function (value) {
                if (this.options.context?.landPremiumDue === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseRentPrincipalAmount: Yup.string()
            .label('Principal amount')
            .nullable()
            .test('conditional-validation', 'Principal amount is a required field', function (value) {
                if (this.options.context?.leaseRentPaymentStatus === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseRentPenaltyAmount: Yup.string()
            .label('Default interest')
            .nullable()
            .test('conditional-validation', 'Default interest is a required field', function (value) {
                if (this.options.context?.leaseRentPaymentStatus === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        leaseRentDueDate: Yup.string()
            .label('Due Date')
            .nullable()
            .test('conditional-validation', 'Due Date is a required field', function (value) {
                if (this.options.context?.leaseRentPaymentStatus === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        existingLeaseRentAmount: Yup.string()
            .label('Existing Lease Rent amount')
            .nullable()
            .test('conditional-validation', 'Existing Lease Rent amount is a required field', function (value) {
                if (this.options.context?.leaseRentPaymentStatus === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        LeaseRentNextDueDate: Yup.string()
            .label('Next due date')
            .nullable()
            .test('conditional-validation', 'Next due date is a required field', function (value) {
                if (this.options.context?.leaseRentPaymentStatus === false) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        houseTaxDueDate: Yup.string()
            .label('Due Date')
            .nullable()
            .test('conditional-validation', 'Due Date is a required field', function (value) {
                if (this.options.context?.houseTaxPaymentStatus === true && this.options.context?.houseTaxApplicable === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        houseTaxPrincipalAmount: Yup.string()
            .label('Principal amount')
            .nullable()
            .test('conditional-validation', 'Principal amount is a required field', function (value) {
                if (this.options.context?.houseTaxPaymentStatus === true && this.options.context?.houseTaxApplicable === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        houseTaxPenaltyAmount: Yup.string()
            .label('Default interest')
            .nullable()
            .test('conditional-validation', 'Default interest is a required field', function (value) {
                if (this.options.context?.houseTaxPaymentStatus === true && this.options.context?.houseTaxApplicable === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        existingHouseTaxAmount: Yup.string()
            .label('Existing House Tax amount')
            .nullable()
            .test('conditional-validation', 'Existing House Tax amount is a required field', function (value) {
                if (this.options.context?.houseTaxPaymentStatus === false && this.options.context?.houseTaxApplicable === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        houseTaxNextDueDate: Yup.string()
            .label('Next due date')
            .nullable()
            .test('conditional-validation', 'Next due date is a required field', function (value) {
                if (this.options.context?.houseTaxPaymentStatus === false && this.options.context?.houseTaxApplicable === true) {
                    return !!value;
                } else {
                    return true;
                }
            }),
        migrationWaterCharge: Yup.array().of(
            Yup.object().shape({
                waterChargeMeterNo: Yup.mixed()
                    .nullable()
                    .label('Meter No.')
                    .test('conditional-validation', 'Last Meter Reading is a required field', function (value) {
                        if (this.options.context?.waterConnection === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                waterChargesPrincipalAmount: Yup.string()
                    .label('Principal Amount')
                    .nullable()
                    .test('conditional-validation', 'Principal Amount is a required field', function (value) {
                        console.log('this', this);
                        if (this.options.context?.waterChargesPaymentStatus === true && this.options.context?.waterConnection === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                waterChargesPenaltyAmount: Yup.string()
                    .label('Default interest amount')
                    .nullable()
                    .test('conditional-validation', 'Default interest amount is a required field', function (value) {
                        if (this.options.context?.waterChargesPaymentStatus === true && this.options.context?.waterConnection === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                waterChargesDueDate: Yup.string()
                    .label('Due date')
                    .nullable()
                    .test('conditional-validation', 'Due date is a required field', function (value) {
                        if (this.options.context?.waterChargesPaymentStatus === true && this.options.context?.waterConnection === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                waterChargesLastMeterReading: Yup.string()
                    .label('Last Meter Reading')
                    .nullable()
                    .test('conditional-validation', 'Last Meter Reading is a required field', function (value) {
                        if (this.options.context?.waterConnection === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                waterChargeslastMeterReadingDate: Yup.string()
                    .label('Last meter reading date')
                    .nullable()
                    .test('conditional-validation', 'Last meter reading date is a required field', function (value) {
                        if (this.options.context?.waterConnection === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                waterChargesExistingAmount: Yup.string()
                    .label('Existing water charges amount')
                    .nullable()
                    .test('conditional-validation', 'Existing water charges amount is a required field', function (value) {
                        if (this.options.context?.waterChargesPaymentStatus === false && this.options.context?.waterConnection === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                waterChargesNextDueDate: Yup.string()
                    .label('Next due date')
                    .nullable()
                    .test('conditional-validation', 'Next due date is a required field', function (value) {
                        if (this.options.context?.waterChargesPaymentStatus === false && this.options.context?.waterConnection === true) {
                            return !!value;
                        } else {
                            return true;
                        }
                    })
            })
        )
        // waterChargesExistingAmount: Yup.string()
        //     .label('Existing water charges amount')
        //     .test('conditional-validation', 'Existing water charges amount is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === false) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesNextDueDate: Yup.string()
        //     .label('Next due date')
        //     .test('conditional-validation', 'Next due date is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === false) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesPrincipalAmount: Yup.string()
        //     .label('Principal amount')
        //     .test('conditional-validation', 'Principal amount is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesPenaltyAmount: Yup.string()
        //     .label('Default interest')
        //     .test('conditional-validation', 'Default interest is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // sgstOnWaterChargesPenaltyAmount: Yup.string()
        //     .label('SGST on default interest')
        //     .test('conditional-validation', 'SGST on default interest is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     }),
        // waterChargesDueDate: Yup.string()
        //     .label('Due Date')
        //     .test('conditional-validation', 'Due Date is a required field', function (value) {
        //         if (this.options.context?.waterChargesPaymentStatus === true) {
        //             return !!value;
        //         } else {
        //             return true;
        //         }
        //     })
    }),
    page7: Yup.object({
        subLeaseDetailsdata: Yup.array().of(
            Yup.object().shape({
                subLeaseEntityDetails: Yup.object().shape({
                    entityName: Yup.string().required().label('Name'),
                    email: Yup.string()
                        .required()
                        .label('Email Id')
                        .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
                        .test('emailId', 'Email already exists', async function (value, context) {
                            const index = this.path.match(/\d+/g)![0];

                            if (value !== previousValues.sublesseeEmail[index]) {
                                const payload = {
                                    RequestInfo: requestInfo(),
                                    tenantId: 'ga',
                                    emailId: value || '',
                                    uuid: context?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.uuid,
                                    type: 'ENTERPRISE'
                                };
                                previousValues.sublesseeEmail[index] = context.parent.email;
                                try {
                                    let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                                    if (response.data.isAvailable) {
                                        errors.sublesseeEmail[index] = false;
                                        return true;
                                    } else {
                                        errors.sublesseeEmail[index] = true;
                                        return false;
                                    }
                                } catch (e) {
                                    errors.sublesseeEmail[index] = true;
                                    return false;
                                }
                            } else if (context?.options?.context?.subLeaseDetailsdata[index].isSearched) {
                                return true;
                            }

                            return !errors.sublesseeEmail[index];
                        }),
                    panNumber: Yup.string()
                        .required()
                        .label('Pan No')
                        .min(10, 'Pan no. should not be less than 10 digit')
                        .max(10, 'Pan no. should not be more than 10 digit')
                        .matches(CONSTANT_REGEX_SAMPLE.PAN, 'Enter valid PAN number')
                        .test('panNumber', 'PAN No. already exists', async function (value, context) {
                            const index = this.path.match(/\d+/g)![0];
                            if (value !== previousValues.sublesseePan[index] && value?.length === 10) {
                                const payload = {
                                    RequestInfo: requestInfo(),
                                    tenantId: 'ga',
                                    panNumber: value || '',
                                    type: 'ENTERPRISE',
                                    uuid: context?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.uuid
                                };
                                previousValues.sublesseePan[index] = context.parent.panNumber;
                                try {
                                    let response: any = CONSTANT_REGEX_SAMPLE.PAN.test(value) && (await checkUserAvailability(payload));
                                    response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

                                    if (response.data.isAvailable || response.data.applicationStatus === 'DRAFT') {
                                        errors.sublesseePan[index] = false;
                                        return true;
                                    } else {
                                        errors.sublesseePan[index] = true;
                                        return false;
                                    }
                                } catch (e) {
                                    errors.sublesseePan[index] = true;
                                    return false;
                                }
                            } else if (value !== previousValues.sublesseePan[index]) {
                                return true;
                            } else if (context.parent.underProcess || context?.options?.context?.subLeaseDetailsdata[index].isSearched) {
                                return true;
                            }
                            return !errors.sublesseePan[index];
                        }),

                    category: Yup.object().required().label('Applicant category'),

                    documents: Yup.object().shape({
                        entityProprietorshipFirm: Yup.mixed().test('conditional-validation', 'Letter stating the name of the Proprietorship firm is required', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.category?.code !== 'PROP_FIRM') return true; // Validation disabled
                            return !!value;
                        }),
                        trustDeed: Yup.mixed().test('conditional-validation', 'Trust Deed is required', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.category?.code !== 'TRUST') return true; // Validation disabled
                            return !!value;
                        }),
                        entityProprietorIdentity: Yup.mixed().test('conditional-validation', 'Identity of the proprietor is required', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.category?.code !== 'PROP_FIRM') return true; // Validation disabled
                            return !!value;
                        }),
                        partnershipDeed: Yup.mixed().test('conditional-validation', 'Partnership Deed is required', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.category?.code !== 'PARTNERSHIP') return true; // Validation disabled
                            return !!value;
                        }),
                        certificateOfRegistrationFirms: Yup.mixed().test('conditional-validation', 'Certificate of registration from the Registrar of Firms is required', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.category?.code !== 'PARTNERSHIP') return true; // Validation disabled
                            return !!value;
                        }),
                        certificateOfIncorporation: Yup.mixed().test('conditional-validation', 'Certificate of incorporation from Registrar of Companies is required', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (
                                this?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.category?.code === 'LIMITED_LIABILITY_PARTNERSHIP' ||
                                this?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.category?.code === 'LTD_COMPANY'
                            )
                                return !!value;
                            return true;
                        }),
                        certificateOfRegistrationSocieties: Yup.mixed().test('conditional-validation', 'Certificate of registration from the Registrar of Societies is required', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.category?.code !== 'COOP_SOCIETY') return true; // Validation disabled
                            return !!value;
                        }),
                        copyRelevantNotification: Yup.mixed().test('conditional-validation', 'Copy of relevant notification is required', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.category?.code !== 'GOV_STAT_CORP') return true; // Validation disabled
                            return !!value;
                        }),
                        panCard: Yup.mixed().required().label('Pan Card'),
                        authorizedPersonPowerOfAttorneyDocument: Yup.mixed().required().label('Letter of Authorization'),
                        udyamRegistrationCertificate: Yup.mixed()
                            .label('Udyam Registration Certificate')
                            .test('conditional-validation', 'Udyam Registration Certificate is a required field', function (value) {
                                if (this?.options?.context?.subLeaseDetailsdata[0].subLeaseOtherDetails.udhyamRegisteredEntity === true) {
                                    return !!value;
                                } else {
                                    return true;
                                }
                            })
                    }),
                    address: Yup.object().shape({
                        address1: Yup.string().required().label('Address Line 1'),
                        pincode: Yup.string().required().label('Pin Code'),
                        district: Yup.string().required().label('District'),
                        state: Yup.string().required().label('State'),
                        country: Yup.string().required().label('Country')
                    }),
                    permanantAddress: Yup.object().shape({
                        peraddress1: Yup.string().required().label('Address Line 1'),
                        perpincode: Yup.string().required().label('Pin Code'),
                        perdistrict: Yup.string().required().label('District'),
                        perstate: Yup.string().required().label('State'),
                        percountry: Yup.string().required().label('Country')
                    }),
                    phoneNumber: Yup.string()
                        .label('Phone No')
                        .nullable()
                        .test('valid-mobile', 'Please enter valid Mobile No.', function (value: any) {
                            if (value === null || value === '' || value === undefined) {
                                return true; // Null or empty string should fail validation
                            }
                            return /^(null|[0-9]{10})$/.test(value); // Validate against 'null' or 10-digit number
                        }),
                    // .min(10, 'Phone No should be at least 10 digit')
                    // .max(10, 'Phone No should not be more than 10 digit')
                    // .required()
                    // .label('Phone No')
                    // .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number ')
                    // .test('unique-phone', 'Phone No already exists', async function (value, context) {
                    //     const index = this.path.match(/\d+/g)![0];

                    //     if (value !== previousValues.sublesseePhone[index]) {
                    //         const payload = {
                    //             RequestInfo: requestInfo(),
                    //             tenantId: 'ga',
                    //             mobileNumber: value.toString() || ''
                    //         };
                    //         previousValues.sublesseePhone[index] = context.parent.phoneNumber;
                    //         try {
                    //             let response: any = value.length === 10 && (await checkUserAvailability(payload));
                    //             if (response.data.isAvailable) {
                    //                 errors.sublesseePhone[index] = false;
                    //                 return true;
                    //             } else {
                    //                 errors.sublesseePhone[index] = true;
                    //                 return false;
                    //             }
                    //         } catch (e) {
                    //             errors.sublesseePhone[index] = true;
                    //             return false;
                    //         }
                    //     }
                    //     return !errors.sublesseePhone[index];
                    // }),

                    // gstNumber: Yup.string()
                    //     .label('GST No')
                    //     .when('gstApplicable', ([gstApplicable], schema) => {
                    //         return gstApplicable ? schema.min(15).max(15).required() : schema.min(0).notRequired();
                    //     }),
                    user: Yup.object().shape({
                        firstName: Yup.string().required().label('First name'),
                        lastName: Yup.string().required().label('Last name'),
                        alternateMobileNo: Yup.string()
                            .nullable()
                            .test('valid-alternate-mobile', 'Please enter valid Alternate Mobile No.', function (value: any) {
                                if (value === null || value === '' || value === undefined) {
                                    return true; // Null or empty string should fail validation
                                }
                                return /^(null|[0-9]{10})$/.test(value); // Validate against 'null' or 10-digit number
                            }),
                        primaryMobileNo: Yup.string()
                            .nullable()
                            .test('valid-mobile', 'Please enter valid Mobile No.', function (value: any) {
                                if (value === null || value === '' || value === undefined) {
                                    return true; // Null or empty string should fail validation
                                }
                                return /^(null|[0-9]{10})$/.test(value); // Validate against 'null' or 10-digit number
                            }),
                        // .min(10, 'Phone No should be at least 10 digit')
                        // .max(10, 'Phone No should not be more than 10 digit')
                        // .required()
                        // .label('Phone No')
                        // .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number ')
                        // .test('unique-phone', 'Phone No already exists', async function (value, context) {
                        //     const index = this.path.match(/\d+/g)![0];
                        //     if (value !== previousValues.sublesseeUserPhone[index]) {
                        //         const payload = {
                        //             RequestInfo: requestInfo(),
                        //             tenantId: 'ga',
                        //             mobileNumber: value.toString() || ''
                        //         };
                        //         previousValues.sublesseeUserPhone[index] = context.parent.primaryMobileNo;
                        //         try {
                        //             let response: any = value.length === 10 && (await checkUserAvailability(payload));
                        //             if (response.data.isAvailable) {
                        //                 errors.sublesseeUserPhone[index] = false;
                        //                 return true;
                        //             } else {
                        //                 errors.sublesseeUserPhone[index] = true;
                        //                 return false;
                        //             }
                        //         } catch (e) {
                        //             errors.sublesseeUserPhone[index] = true;
                        //             return false;
                        //         }
                        //     }
                        //     return !errors.sublesseeUserPhone[index];
                        // }),
                        // email: Yup.string()
                        //     .required()
                        //     .label('Email Id')
                        //     .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
                        //     .test('emailId', 'Email already exists', async function (value, context) {
                        //         const index = this.path.match(/\d+/g)![0];

                        //         if (value !== previousValues.sublesseeUserEmail[index]) {
                        //             const payload = {
                        //                 RequestInfo: requestInfo(),
                        //                 tenantId: 'ga',
                        //                 emailId: value || ''
                        //             };
                        //             previousValues.sublesseeUserEmail[index] = context.parent.email;
                        //             try {
                        //                 let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                        //                 if (response.data.isAvailable) {
                        //                     errors.sublesseeUserEmail[index] = false;
                        //                     return true;
                        //                 } else {
                        //                     errors.sublesseeUserEmail[index] = true;
                        //                     return false;
                        //                 }
                        //             } catch (e) {
                        //                 errors.sublesseeUserEmail[index] = true;
                        //                 return false;
                        //             }
                        //         }

                        //         return !errors.sublesseeUserEmail[index];
                        //     })
                        email: Yup.string().required().label('Email Id').matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
                    })
                }),
                documents: Yup.object().shape({
                    inPrincipleApproval: Yup.mixed()
                        .label('NOC from Goa-IPB')
                        .nullable()
                        .test('conditional-validation', 'NOC from Goa-IPB is a required field', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index].subLeaseSpecialProject === true) {
                                return !!value;
                            } else {
                                return true;
                            }
                        })
                }),
                subLeaseArea: Yup.string().required().label('Sub-Lease area'),
                subLeaseStartDate: Yup.string().required().label('Sub-Lease start date').nullable(),
                subLeaseEndDate: Yup.string().required().label('Sub-Lease end date').nullable(),
                subLeaseOrder: Yup.mixed().required().label('Sub-Lease Order'),
                subLeaseOtherDetails: Yup.object().shape({
                    udyamNo: Yup.string()
                        .label('Udyam No')
                        .nullable()
                        .test('conditional-validation-2', 'Please enter valid Udyam Registration Number', function (value) {
                            if (value === null || value === '' || value === undefined) {
                                return true; // Null or empty string should fail validation
                            }
                            return /^[A-Z]{5}-[A-Z]{2}-\d{2}-\d{7}$/.test(value);
                        })
                        .test('conditional-value', 'Udyam No is a required field', function (value) {
                            if (this.parent.udhyamRegisteredEntity === true) {
                                return !!value;
                            } else return true;
                        })
                }),
                subLeaseEnterpriseDetails: Yup.object().shape({
                    subCategory: Yup.array()
                        .of(Yup.object().shape({ code: Yup.string().required(), name: Yup.string().required() }))
                        .min(1)
                        .label('Sub-category')
                        .test('conditional-validation', 'Special-category is a required field', function (value) {
                            if (this.parent.isSamePlotApplicationExist === true) {
                                return true;
                            } else {
                                return !!value;
                            }
                        }),
                    applicantGstno: Yup.string()
                        .label('GST No.')
                        .nullable()
                        .test('conditional-validation-gst', 'Enter valid GST Number', function (value) {
                            if (this.parent.applicantGst === true) {
                                if (value?.match(CONSTANT_REGEX_SAMPLE.GSTNO)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else {
                                return true;
                            }
                        })
                        // .matches(CONSTANT_REGEX_SAMPLE.GSTNO, 'Enter valid GST Number')
                        .test('conditional-validation', 'GST No is a required field', function (value) {
                            if (this.parent.applicantGst === true) {
                                return !!value;
                            } else {
                                return true;
                            }
                        })
                        .test('conditional-validation-1', 'Enter valid GST Number', function (value) {
                            const matchString = value?.substring(2, 12);
                            const index = this.path.match(/\d+/g)![0];
                            if (this.parent.applicantGst === true && matchString !== this.options.context?.subLeaseDetailsdata[index].subLeaseEntityDetails.panNumber) {
                                return false;
                            } else {
                                return true;
                            }
                        }),
                    documents: Yup.object().shape({
                        applicantGstdocument: Yup.mixed()
                            .label('GST Document')
                            .test('conditional-validation', 'GST Document is a required field', function (value) {
                                if (this.parent.applicantGst === true) {
                                    return !!value;
                                } else {
                                    return true;
                                }
                            }),
                        entitygstUndertaking: Yup.mixed()
                            .label('GST Undertaking')
                            .test('conditional-validation', 'GST Undertaking is a required field', function (value) {
                                if (this.parent.applicantGst === false) {
                                    return !!value;
                                } else {
                                    return true;
                                }
                            })
                    }),

                    pollutionCategory: Yup.object().required().label('Pollution category'),
                    waterRequirement: Yup.object().required().label('Water requirement'),
                    powerRequirement: Yup.object().required().label('Power requirement'),
                    employement: Yup.object().required().label('Employment generation')
                }),
                paymentDetails: Yup.object().shape({
                    principalAmount: Yup.string()
                        .label('Principal Amount')
                        .test('conditional-validity', 'Principal Amount is a required field', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index]?.subleaseFeeDue === true) {
                                return !!value;
                            } else {
                                return true;
                            }
                        }),
                    penaltyAmount: Yup.string()
                        .label('Default interest amount')
                        .test('conditional-validation', 'Default interest amount is required field', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index]?.subleaseFeeDue === true) {
                                return !!value;
                            } else {
                                return true;
                            }
                        }),
                    dueDate: Yup.string()
                        .label('Due date')
                        .nullable()
                        .test('conditional-validation', 'Due Date is required field', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index]?.subleaseFeeDue === true) {
                                return !!value;
                            } else {
                                return true;
                            }
                        }),
                    existingSubLeaseFee: Yup.string()
                        .label('Existing Sub-Lease fee')
                        .test('conditional-validation', 'Existing Sub-Lease fee is required field', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index]?.subleaseFeeDue === false) {
                                return !!value;
                            } else {
                                return true;
                            }
                        }),
                    nextDueDate: Yup.string()
                        .label('Next due date')
                        .nullable()
                        .test('conditional-validation', 'Next Due Date is required field', function (value) {
                            const index = this.path.match(/\d+/g)![0];
                            if (this?.options?.context?.subLeaseDetailsdata[index]?.subleaseFeeDue === false) {
                                return !!value;
                            } else {
                                return true;
                            }
                        })
                }),
                subLeaseWaterDetails: Yup.array().of(
                    Yup.object().shape({
                        connectionDate: Yup.string()

                            .label('Connection Date')
                            .nullable()
                            .test('conditional-validation', 'Existing water charges amount is a required field', function (value, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                const subleaseWater = context.options.context.subLeaseDetailsdata[index].subleaseWater;
                                if (subleaseWater === true) {
                                    return !!value;
                                } else {
                                    return true;
                                }
                            }),
                        connectionPipeDia: Yup.mixed()

                            .label('Connection Pipe Dia')
                            .test('conditional-validation', 'Existing water charges amount is a required field', function (value: any, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                if (context.options.context.subLeaseDetailsdata[index].subleaseWater === false) {
                                    return true;
                                } else {
                                    return !!value?.code;
                                }
                            }),
                        waterChargeslastMeterReadingDate: Yup.string()
                            .label('Last Meter Reading Date')
                            .nullable()
                            .test('conditional-validation', 'Existing water charges amount is a required field', function (value, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                if (context.options.context.subLeaseDetailsdata[index].subleaseWater === false) {
                                    return true;
                                } else {
                                    return !!value;
                                }
                            }),
                        meterNo: Yup.string()
                            .label('Meter No.')
                            .test('conditional-validation', 'Existing water charges amount is a required field', function (value, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                if (context.options.context.subLeaseDetailsdata[index].subleaseWater === false) {
                                    return true;
                                } else {
                                    return !!value;
                                }
                            }),
                        monthlyConsumption: Yup.string()
                            .label('Monthly Consumption')
                            .test('conditional-validation', 'Existing water charges amount is a required field', function (value, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                if (context.options.context.subLeaseDetailsdata[index].subleaseWater === false) {
                                    return true;
                                } else {
                                    return !!value;
                                }
                            }),
                        securityDeposit: Yup.string()
                            .label('Security Deposite')
                            .test('conditional-validation', 'Existing water charges amount is a required field', function (value, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                if (context.options.context.subLeaseDetailsdata[index].subleaseWater === false) {
                                    return true;
                                } else {
                                    return !!value;
                                }
                            }),
                        waterType: Yup.mixed()
                            .label('Water Type')
                            .test('conditional-validation', 'Existing water charges amount is a required field', function (value: any, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                if (context.options.context.subLeaseDetailsdata[index].subleaseWater === false) {
                                    return true;
                                } else {
                                    return !!value?.code;
                                }
                            }),
                        waterChargesLastMeterReading: Yup.string()
                            .label('Last Meter Reading')
                            .test('conditional-validation', 'Existing water charges amount is a required field', function (value, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                if (context.options.context.subLeaseDetailsdata[index].subleaseWater === false) {
                                    return true;
                                } else {
                                    return !!value;
                                }
                            }),
                        waterChargesExistingAmount: Yup.string()
                            .label('Existing water charges amount')
                            .test('conditional-validation', 'Existing water charges amount is a required field', function (value, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                if (context.parent.subleaseWaterFeeDue === true) {
                                    return true;
                                } else if (context.options.context.subLeaseDetailsdata[index].subleaseWater === true) {
                                    return !!value;
                                } else return true;
                            }),
                        waterChargesNextDueDate: Yup.string()
                            .label('Next due date')
                            .nullable()
                            .test('conditional-validation', 'Next due date is a required field', function (value, context: any) {
                                const index = this.path.match(/\d+/g)![0];
                                if (context.parent?.subleaseWaterFeeDue === true) {
                                    return true;
                                } else if (context.options.context.subLeaseDetailsdata[index].subleaseWater === true) {
                                    return !!value;
                                } else return true;
                            }),

                        waterChargesDueDate: Yup.string()
                            .label('Due Date')
                            .nullable()
                            .test('conditional-validation', 'Due Date is a required field', function (value, context: any) {
                                if (context.parent?.subleaseWaterFeeDue === true) {
                                    return !!value;
                                } else {
                                    return true;
                                }
                            }),
                        waterChargesPrincipalAmount: Yup.string()
                            .label('Principal amount')
                            .test('conditional-validation', 'Principal amount is a required field', function (value, context: any) {
                                if (context.parent?.subleaseWaterFeeDue === true) {
                                    return !!value;
                                } else {
                                    return true;
                                }
                            }),
                        waterChargesPenaltyAmount: Yup.string()
                            .label('Default interest')
                            .test('conditional-validation', 'Default interest is a required field', function (value, context: any) {
                                if (context.parent?.subleaseWaterFeeDue === true) {
                                    return !!value;
                                } else {
                                    return true;
                                }
                            }),
                        sgstOnWaterChargesPenaltyAmount: Yup.string()
                            .label('SGST on default interest')
                            .test('conditional-validation', 'SGST on default interest is a required field', function (value, context: any) {
                                if (context.parent?.subleaseWaterFeeDue === true) {
                                    return !!value;
                                } else {
                                    return true;
                                }
                            })
                    })
                )
            })
        )
    })
};

export const PROPOSED_ACTIVITY_VALIDATION = Yup.object().shape({
    description: Yup.object().required().label('Description'),
    proposedActivity: Yup.string().required().label('Proposed Activity')
});

export const PAYMENT_INSTALLMENT_VALIDATION: { [pageno: string]: any } = {
    page2: Yup.object().shape({
        paymentType: Yup.object().required().label('Payment Type'),
        downPayment: Yup.string()
            .label('Down Payment')
            .test('conditional-validation', 'Down Payment must be greater than or equal to 20% of Amount', function (value: any) {
                if (this.parent.paymentType?.code !== 'installment') {
                    return true;
                } else if (value) {
                    if (+value >= +this.parent.amount / 5) return true;
                    else {
                        return false;
                    }
                } else {
                    return true;
                }
            })
            .test('conditional-validation', 'Down Payment must be less than or equal to Amount', function (value: any) {
                if (this.parent.paymentType?.code !== 'installment') return true;
                else if (value) {
                    if (+value <= +this.parent.amount) return true;
                    else return false;
                } else return true;
            }),
        installments: Yup.object()
            .label('Installments')
            .test('conditional-validation', 'Installments is required', function (value) {
                if (this.parent.paymentType?.code !== 'installment') {
                    return true;
                } else {
                    return !!value;
                }
            })
            .nullable()
    })
};

// export const plotTypeSchema = Yup.object().shape({
//     name: Yup.string().required(),
//     code: Yup.string().required()
// });

export const PLOT_MASTER_VALIDATION = Yup.object().shape({
    estateName: Yup.mixed().required().label('Estate Name'),
    plotNo: Yup.string().required().label('Plot No.'),
    phase: Yup.string().required().label('Phase.'),
    plotType: Yup.mixed().required().label('Plot Type'),
    plotAreaSqm: Yup.string()
        .matches(/^\d*\.?\d+$/, 'Plot Area (in sqm) must be a number')
        .required('Plot Area (in sqm) is required')
        .label('Plot Area (in sqm)'),
    plotRate: Yup.string()
        .matches(/^\d*\.?\d+$/, 'Plot Rate must be a number')
        .required('Plot Rate (in INR) is required')
        .label('Plot Rate (in INR)'),
    plotBoundaryOnOrByNorth: Yup.string().required().label('Plot boundary on or by North'),
    plotBoundaryOnOrByEast: Yup.string().required().label('Plot boundary on or by East'),
    plotBoundaryOnOrByWest: Yup.string().required().label('Plot boundary on or by West'),
    plotBoundaryOnOrBySouth: Yup.string().required().label('Plot boundary on or by South'),
    surveyNo: Yup.string().required().label('Survey No'),
    village: Yup.string().required().label('Village'),
    talukaRevenueOfficeRegistrationNo: Yup.string().required().label('Taluka'),
    landRegistrationNo: Yup.string().required().label('Land Registration No.'),
    isWaterAvailable: Yup.string().required().label('Water availability'),
    isRoadAvailable: Yup.string().required().label('Road availability'),
    isElectricityAvailable: Yup.string().required().label('Electricity availability')
});

export const DEED_TYPE_OPTIONS = Yup.object().shape({
    deedType: Yup.object().required().label('Deed Type')
});

export const EXECUTED_DEED_VALIDATION = Yup.object().shape({
    executedLeaseDeedDate: Yup.string()
        .label('Executed Lease Deed Date')
        .test('conditional-validation', 'Executed Lease Deed Date is a required field', function (value) {
            if (this.parent.designation === 'LA' && this.parent.applicationStatus === 'APPROVED') {
                return !!value;
            } else return true;
        }),
    signedLeaseDeed: Yup.mixed()
        .label('Signed Lease Deed')
        .test('conditional-validation', 'Signed Lease Deed is a required field', function (value) {
            if (this.parent.designation === 'LA' && this.parent.applicationStatus === 'APPROVED') {
                return !!value;
            } else return true;
        })
});
export const REGISTERED_DEED_VALIDATION = Yup.object().shape({
    deedType: Yup.mixed().required().label('Type'),
    deedRegistrationDate: Yup.string().required().label('Deed Registration Date'),
    copyOfRegisteredDeed: Yup.mixed().required().label('Copy of Registered Deed')
});

export const EXTENSION_VALIDATION = Yup.object().shape({
    extensionType: Yup.mixed().required().label('Type of Extension'),
    comments: Yup.string().required().label('Reason for Extension')
});

export const TRANSFER_VALIDATION: { [pageno: string]: any } = {
    page2: Yup.object({
        nocFromGoaIpb: Yup.mixed().test('conditional-validation', 'Noc from Goa-IPB is required', function (value) {
            if (this.parent.isSpecialProject !== true) return true; // Validation disabled
            return !!value;
        })
    }),
    page4: Yup.object({
        assignTransfereeMobileNo: Yup.string()
            .nullable()
            .test('conditional-validatiuon', 'Please enter valid Mobile No.', function (value: any) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return /^(null|[0-9]{10})$/.test(value); // Validate against 'null' or 10-digit number
            }),
        assignTransfereeEmail: Yup.string()
            .nullable()
            .test('conditional-validation', 'Please enter valid Email Id', function (value) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return CONSTANT_REGEX_SAMPLE.EMAIL.test(value);
            })
    })
};

export const SUB_LEASE_LESSEE_VAALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({
        applicantName: Yup.string().required().label('Name'),
        category: Yup.object().required().label('Category'),
        subLeaseEmailId: Yup.string().required().label('Email Id').matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address'),
        phoneNumber: Yup.string()
            .min(10, 'Phone No should be at least 10 digit')
            .max(10, 'Phone No should not be more than 10 digit')
            .required()
            .label('Phone No')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number '),
        applicantPanNo: Yup.string()
            .required()
            .label('Pan')
            .min(10, 'Pan no. should not be less than 10 digit')
            .max(10, 'Pan no. should not be more than 10 digit')
            .matches(CONSTANT_REGEX_SAMPLE.PAN, 'Enter valid PAN number')
            .test('applicantPanNo', 'PAN No. already exists', async function (value, context) {
                if (value !== previousValues.applicantPanNo && value?.length === 10) {
                    const payload = {
                        RequestInfo: requestInfo(),
                        tenantId: 'ga',
                        panNumber: value || '',
                        type: 'ENTERPRISE',
                        uuid: context.parent.uuid
                    };
                    previousValues.applicantPanNo = context.parent.applicantPanNo;
                    try {
                        let response: any = CONSTANT_REGEX_SAMPLE.PAN.test(value) && (await checkUserAvailability(payload));
                        response.data && localStorage.setItem('availabilityCheck', JSON.stringify(response.data));

                        if (response.data.isAvailable || response.data.applicationStatus === 'DRAFT') {
                            errors.applicantPanNo = false;
                            return true;
                        } else {
                            errors.applicantPanNo = true;
                            return false;
                        }
                    } catch (e) {
                        errors.applicantPanNo = true;
                        return false;
                    }
                } else if (value !== previousValues.applicantPanNo) {
                    return true;
                } else if (context.parent.underProcess) {
                    return true;
                }

                return !errors.applicantPanNo;
            }),
        address1: Yup.string().required().label('Address'),
        pincode: Yup.string().required().label('Pincode'),
        district: Yup.string().required().label('District'),
        state: Yup.string().required().label('State'),
        country: Yup.string().required().label('Country'),
        peraddress1: Yup.string().required().label('Address'),
        perpincode: Yup.string().required().label('Pincode'),
        perdistrict: Yup.string().required().label('District'),
        perstate: Yup.string().required().label('State'),
        percountry: Yup.string().required().label('Country'),
        authorizedPersonFirstname: Yup.string().required().label('First Name'),
        authorizedPersonLastName: Yup.string().required().label('Last Name'),
        pmobileNumber: Yup.string()
            .min(10, 'Phone number should be at least 10 digit')
            .max(10, 'Phone number should not be more than 10 digit')
            .required()
            .label('Mobile number')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number '),
        authorizedEmailId: Yup.string().required().label('Email Id').matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address'),
        entityProprietorshipFirm: Yup.mixed().test('conditional-validation', '1)	Letter stating the name of the Proprietorship firm is a required field', function (value) {
            if (this.parent.category?.code !== 'PROP_FIRM') return true; // Validation disabled
            return !!value;
        }),
        trustDeed: Yup.mixed().test('conditional-validation', 'Trust Deed is a required field', function (value) {
            if (this.parent.category?.code !== 'TRUST') return true; // Validation disabled
            return !!value;
        }),
        entityProprietorIdentity: Yup.mixed().test('conditional-validation', 'Proprietor Identity is a required field', function (value) {
            if (this.parent.category?.code !== 'PROP_FIRM') return true; // Validation disabled
            return !!value;
        }),
        partnershipDeed: Yup.mixed().test('conditional-validation', 'Partnership Deed is a required field', function (value) {
            if (this.parent.category?.code !== 'PARTNERSHIP') return true; // Validation disabled
            return !!value;
        }),
        certificateOfRegistrationFirms: Yup.mixed().test('conditional-validation', 'Certificate of registration from the Registrar of Firms is a required field', function (value) {
            if (this.parent.category?.code !== 'PARTNERSHIP') return true; // Validation disabled
            return !!value;
        }),
        certificateOfIncorporation: Yup.mixed().test('conditional-validation', 'Certificate of incorporation from Registrar of Companies is a required field', function (value) {
            if (this.parent.category?.code !== 'LIMITED_LIABILITY_PARTNERSHIP' || this.parent.category?.code !== 'LTD_COMPANY') return true; // Validation disabled
            return !!value;
        }),
        certificateOfRegistrationSocieties: Yup.mixed().test('conditional-validation', 'Certificate of registration from the Registrar of Societies is a required field', function (value) {
            if (this.parent.category?.code !== 'COOP_SOCIETY') return true; // Validation disabled
            return !!value;
        }),
        copyRelevantNotification: Yup.mixed().test('conditional-validation', 'Copy of relevant notification is a required field', function (value) {
            if (this.parent.category?.code !== 'GOV_STAT_CORP') return true; // Validation disabled
            return !!value;
        }),
        applicantPancard: Yup.mixed().test('conditional-validation', 'PAN card is required', function (value) {
            return !!value;
        }),
        letter: Yup.mixed()
            .label('The letter / resolution / power of attorney authorising the authorised person')
            .test('conditional-validation', 'The letter / resolution / power of attorney is a required field', function (value) {
                return !!value;
            })
            .nullable(),
        alternateMobileNumber: Yup.string()
            .nullable()
            .test('valid-alternate-mobile', 'Please enter valid Alternate Mobile No.', function (value: any) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return /^(null|[0-9]{10})$/.test(value); // Validate against 'null' or 10-digit number
            }),
        udyamNo: Yup.string()
            .nullable()
            .test('conditional-validation', 'Please enter valid Udyam Registration Number', function (value) {
                if (value === null || value === '' || value === undefined) {
                    return true; // Null or empty string should fail validation
                }
                return /^[A-Z]{5}-[A-Z]{2}-\d{2}-\d{7}$/.test(value);
            })
            .test('conditiona-validation-2', 'Udyam Registration Number is a required field', function (value) {
                if (this.parent.udhyam !== true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        RegCertificate: Yup.mixed().test('conditional-validation', 'Udyam Registered Enterprise document is a required field', function (value) {
            if (this.parent.udhyam !== true) return true; // Validation disabled
            return !!value;
        })
    }),
    page2: Yup.object({
        // areaOfSubLease: Yup.mixed().required().label('Plan showing the area of Sub-Lease')
    }),
    page3: Yup.object({
        subLeaseStartDate: Yup.mixed().required().label('Sub-Lease start date'),
        subLeaseEndDate: Yup.mixed()
            .required()
            .label('Sub-Lease end date')
            .test('conditional-error', 'Sub-Lease end date cannot be less than or equal to Sub-Lease start date', (value: any, context) => {
                if (new Date(value).getTime() <= new Date(context.parent.subLeaseStartDate).getTime()) {
                    return false;
                } else return true;
            }),
        subLeaseBuiltUpArea: Yup.mixed().required().label('Sub-Lease built up area'),
        nocDocument: Yup.mixed().test('conditional-validation', 'NOC from IPB is a required field', function (value, context) {
            const spProject = context.options.context?.subLeaseSpecialProject;
            if (!spProject) return true; // Validation disabled
            return !!value;
        })
    }),
    page4: ALLOTMENT_FORM_VALIDATION['page3'],
    page5: Yup.object({}),
    page6: Yup.object({})
};

export const SPV_FORM_VALIDATION: { [pageno: string]: any } = {
    page1: SUB_LEASE_LESSEE_VAALIDATION['page1']
};

export const TRANSFEREE_VAALIDATION: { [pageno: string]: any } = {
    page1: ALLOTMENT_FORM_VALIDATION['page1'],
    page2: Yup.object({}),
    page3: Yup.object({}),
    page4: Yup.object({}),
    page5: ALLOTMENT_FORM_VALIDATION['page3'],
    page6: Yup.object({})
};

export const SP_TRANSFER_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({
        specialTransferType: Yup.mixed().required().label('Type of Special Transfer'),
        approvalOfRegistrarOfCompanies: Yup.mixed().test('conditional-validation', 'Approval of Registrar of Companies is a required field', function (value) {
            if (
                this.parent.specialTransferType?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ||
                this.parent.specialTransferType?.code === 'SPL_MERGER_DEMERGER' ||
                this.parent.specialTransferType?.code === 'SPL_CONVERSION_TO_CORPORATE'
            ) {
                return !!value;
            } else {
                return true;
            }
        }),
        memorandumOfAssociation: Yup.mixed().test('conditional-validation', 'Memorandum of Association is a required field', function (value) {
            if (this.parent.specialTransferType?.code === 'SPL_MERGER_DEMERGER' || this.parent.specialTransferType?.code === 'SPL_CONVERSION_TO_CORPORATE') {
                return !!value;
            } else {
                return true;
            }
        }),
        relevantCourtOrder: Yup.mixed().test('conditional-validation', 'Relevant court order is a required field', function (value) {
            if (this.parent.specialTransferType?.code === 'SPL_CONVERSION_TO_CORPORATE' || this.parent.specialTransferType?.code === 'SPL_MERGER_DEMERGER') {
                return !!value;
            } else {
                return true;
            }
        }),
        corporateInsolvencyResolutionPlan: Yup.mixed().test('conditional-validation', 'Corporate insolvency resolution plan is a required field', function (value) {
            if (this.parent.specialTransferType?.code === 'SPL_MERGER_DEMERGER') {
                return !!value;
            } else {
                return true;
            }
        }),
        LLPRegistration: Yup.mixed().test('conditional-validation', 'LLP Registration is a required field', function (value) {
            if (this.parent.specialTransferType?.code === 'SPL_CONVERSION_TO_LLP') {
                return !!value;
            } else {
                return true;
            }
        }),
        deathCertificate: Yup.mixed().test('conditional-validation', 'Death Certificate is a required field', function (value) {
            if (this.parent.specialTransferType?.code === 'SPL_CONVEYANCE_LEASE_RIGHTS') {
                return !!value;
            } else {
                return true;
            }
        }),
        successionCertificate: Yup.mixed().test('conditional-validation', 'Succession Certificate is a required field', function (value) {
            if (this.parent.specialTransferType?.code === 'SPL_CONVEYANCE_LEASE_RIGHTS') {
                return !!value;
            } else {
                return true;
            }
        })
    }),
    page2: Yup.object({
        nocGoaIPB: Yup.mixed().test('conditional-validation', 'NOC from Goa-IPB is required', function (value) {
            if (this.parent.appliedUnderSpecialProject !== true) return true; // Validation disabled
            return !!value;
        })
    }),
    page3: Yup.object({}),
    page4: Yup.object({})
};

export const ALLOTED_PLOT_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({
        allotedEstateName: Yup.object()
            .label('Estate Name')
            .test('conditional-validation', 'Estate Name is a required field', function (value) {
                if (this.parent.isAdvertisement === true) {
                    return true;
                } else {
                    return !!value;
                }
            }),
        allotedPhase: Yup.object()
            .label('Phase')
            .test('conditional-validation', 'Phase is a required field', function (value: any) {
                if (this.parent.isAdvertisement === true) {
                    return true;
                } else {
                    return !!value?.code;
                }
            }),
        allotedPlotNo: Yup.object()
            .label('Plot No')
            .test('conditional-validation', 'Plot No is a required field', function (value: any) {
                if (this.parent.isAdvertisement === true) {
                    return true;
                } else {
                    return !!value?.code;
                }
            }),
        allotedPlotType: Yup.object()
            .label('Plot Type')
            .test('conditional-validation', 'Plot Type is a required field', function (value: any) {
                if (this.parent.isAdvertisement === true) {
                    return true;
                } else {
                    return !!value?.code;
                }
            })
    })
};

export const ESTATE_APPLICATION_VALIDATION = Yup.object().shape({
    district: Yup.object().required('District is required'),
    districtCode: Yup.mixed().required('District Code is required'),
    census2011DistrictCode: Yup.string().required('District Code is required'),
    subDistrict: Yup.string().required('Sub-District is required'),
    village: Yup.array()
        .of(Yup.object().shape({ code: Yup.string().required(), name: Yup.string().required() }))
        .min(1)
        .label('Village'),
    estateName: Yup.string().required('Park Name is required'),
    // landType: Yup.object().required('Land Type is required'),
    // industrialType: Yup.object().required('Park Industrial is required'),
    polutionControlCategoryAllowed: Yup.mixed().required('Pollution Control Category allowed is required'),
    plotRate: Yup.string().required('Plot Rate (in INR) is required'),
    // numberOfPlots: Yup.string().required('Total number of plots is required'),
    // plotSize: Yup.string().required('Average size of plots is required'),
    // allotedPlot: Yup.string().required('Total no. of allotted plots is required'),
    // vacantPlot: Yup.string().required('Total no. of vacant plots is required'),
    totalLandAreaHectares: Yup.string().required('Total Land Area (in hectares) is required'),
    // totalVacantIndustrialAreaHectares: Yup.string().required('Total Vacant Industrial Area (in hectares) is required'),
    sector: Yup.string().required('Sector is required'),
    nearestNationalHighway: Yup.string().required('Nearest National Highway is required'),
    distanceFromNHKm: Yup.string().required('Distance from NH (in Km) is required'),
    nearestStateHighway: Yup.string().required('Nearest State Highway is required'),
    distanceFromSHKm: Yup.string().required('Distance from SH (in Km) is required'),
    nearestRailwayStation: Yup.string().required('Nearest Railway Station is required'),
    distanceFromRailwayStationKm: Yup.string().required('Distance from Railway Station (in Km) is required'),
    nearestAirport: Yup.string().required('Nearest Airport is required'),
    distanceFromAirportKm: Yup.string().required('Distance from Airport (in Km) is required'),
    nearestSeaport: Yup.string().required('Nearest Seaport is required'),
    distanceFromSeaportKm: Yup.string().required('Distance from Seaport (in Km) is required'),
    nearestIcdDryPort: Yup.string().required('Nearest ICD/Dry Port is required'),
    distanceFromDryPortIcdKm: Yup.string().required('Distance from Dry Port/ICD (in Km) is required'),
    distanceFromFieldOfficeKm: Yup.string().required('Field Office Address is required'),
    nearestPoliceStationName: Yup.string().required('Nearest Police Station Name is required'),
    nearestPoliceStationDistanceKm: Yup.string().required('Nearest Police Station, Distance in kms is required'),
    nearestFireStationName: Yup.string().required('Nearest Fire Station Name is required'),
    nearestFireStationDistanceKm: Yup.string().required('Nearest Fire Station, Distance in kms is required'),
    nearestBankName: Yup.string().required('Nearest Bank Name is required'),
    nearestBankDistanceKm: Yup.string().required('Nearest Bank, Distance in kms is required'),
    nearestHospitalName: Yup.string().required('Nearest Hospital Name is required'),
    nearestHospitalDistanceKm: Yup.string().required('Nearest Hospital, Distance in kms is required'),
    // nodalOfficer: Yup.string().required('Name of Nodal Officer for the Park is required'),
    // designation: Yup.string().required('Designation is required'),

    electricityPowerCapacityKva: Yup.mixed().test('conditional-validation', 'Electricity/Power Capacity', function (value) {
        if (this.parent.electricityPowerAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    electricityPowerUtilizationPct: Yup.mixed().test('conditional-validation', 'Electricity/Power Capacity', function (value) {
        if (this.parent.electricityPowerAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    waterCapacityMld: Yup.mixed().test('conditional-validation', 'Water Capacity', function (value) {
        if (this.parent.waterAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    waterUtilizationPct: Yup.mixed().test('conditional-validation', 'Water Utilization', function (value) {
        if (this.parent.waterAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    naturalGasCapacityMbh: Yup.mixed().test('conditional-validation', 'Natural Gas Capacity', function (value) {
        if (this.parent.naturalGasAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    naturalGasUtilizationPct: Yup.mixed().test('conditional-validation', 'Natural Gas Utilization', function (value) {
        if (this.parent.naturalGasAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    stpCapacityMld: Yup.mixed().test('conditional-validation', 'STP Capacity', function (value) {
        if (this.parent.stpAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    stpUtilizationPct: Yup.mixed().test('conditional-validation', 'STP Utilization', function (value) {
        if (this.parent.stpAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    powerSubStationCapacityKva: Yup.mixed().test('conditional-validation', 'Power Sub Station Capacity', function (value) {
        if (this.parent.powerSubStationAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    powerSubStationUtilizationPct: Yup.mixed().test('conditional-validation', 'Power Sub Station Utilization', function (value) {
        if (this.parent.powerSubStationAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    wtpCapacityMld: Yup.mixed().test('conditional-validation', 'WTP Station Capacity', function (value) {
        if (this.parent.wtpAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    wtpUtilizationPct: Yup.mixed().test('conditional-validation', 'WTP Station Utilization', function (value) {
        if (this.parent.wtpAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    swDisposalFacilityCapacityTdp: Yup.mixed().test('conditional-validation', 'S.W. Disposal Facility Capacity', function (value) {
        if (this.parent.swDisposalFacilityAvailability !== true) return true; // Validation disabled
        return !!value;
    }),
    swDisposalFacilityUtilizationPct: Yup.mixed().test('conditional-validation', 'S.W. Disposal Facility Utilization', function (value) {
        if (this.parent.swDisposalFacilityAvailability !== true) return true; // Validation disabled
        return !!value;
    })
    // emailId: Yup.string().required().label('Email Id').matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address'),
    // phoneNumber: Yup.string()
    //     .min(10, 'Phone number should be at least 10 digit')
    //     .max(10, 'Phone number should not be more than 10 digit')
    //     .required()
    //     .label('Mobile number')
    //     .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number ')
});

export const PLOT_REGISTRY_VALIDATION = Yup.object().shape({
    estateName: Yup.mixed().required().label('Estate Name'),
    plotNo: Yup.string().required().label('Plot No'),
    phase: Yup.string().required().label('Phase'),
    plotType: Yup.mixed().required().label('Plot Type'),
    plotAreaSqm: Yup.string().required().label('Plot Area'),
    village: Yup.array()
        .of(Yup.object().shape({ code: Yup.string().required(), name: Yup.string().required() }))
        .min(1)
        .label('Village'),
    plotBoundaryNorth: Yup.string().required().label('Plot boundary by North'),
    plotBoundaryEast: Yup.string().required().label('Plot boundary by East'),
    plotBoundaryWest: Yup.string().required().label('Plot boundary by West'),
    plotBoundarySouth: Yup.string().required().label('Plot boundary by South')
});

export const WATER_CONNECTION_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({
        plotNo: Yup.string().required('Plot Number is a required field'),
        connectionType: Yup.string().required('Connection Type is a required field'),
        waterType: Yup.string().required('Water Type is a required field'),
        connectionPipeDia: Yup.string().required('Connection Pipe dia is a required field'),
        monthlyConsumption: Yup.string().required('Monthly Consumption is a required field')
    })
};

export const WATER_CONNECTION_UPGRADE_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({
        connectionType: Yup.string().required('Connection Type is a required field'),
        connectionPipeDia: Yup.string().required('Connection Pipe dia is a required field'),
        monthlyConsumption: Yup.string().required('Monthly Consumption is a required field')
    })
};
export const MORTGAGE_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({}),
    page2: Yup.object().shape({
        bankDetails: Yup.array().of(
            Yup.object().shape({
                bankIFSC: Yup.string()
                    .label('Bank IFSC')
                    .test('conditional-validation', 'Bank IFSC is required field', function (value) {
                        if (this?.options?.context?.bankDetails?.length) {
                            return !!value;
                        } else {
                            return true;
                        }
                    }),
                bankName: Yup.string().required().label('Bank Name is required'),
                branchAddress: Yup.string().required().label('Branch Address is required')
            })
        ),
        existingMortgageDetails: Yup.array().of(
            Yup.object().shape({
                existingNocDocument: Yup.mixed()
                    .required('Required')
                    .when('existingMortgageDetails', (existingMortgageDetails, schema) => (existingMortgageDetails && existingMortgageDetails.length > 0 ? schema.required('Required') : schema)),
                existingDateOFNoc: Yup.string()
                    .required('Required')
                    .when('existingMortgageDetails', (existingMortgageDetails, schema) => (existingMortgageDetails && existingMortgageDetails.length > 0 ? schema.required('Required') : schema))
            })
        )
    }),
    page3: Yup.object({}),
    page4: Yup.object({})
};

export const MORTGAGE_WF_VALIDATION = Yup.object().shape({
    bankDetails: Yup.array().of(
        Yup.object().shape({
            bankLetter: Yup.mixed().required('Bank Letter Required')
        })
    )
});

export const EXTENSION_LEASE_TERM: { [pageno: string]: any } = {
    page1: Yup.object({}),
    page2: Yup.object({
        extensionDuration: Yup.string()
            .required()
            .label('Duration of Extension')
            .test('conditional-validation-1', 'Duration of Extension should not be less than 1 year', function (value) {
                if (+value < 1) {
                    return false;
                } else {
                    return true;
                }
            })
            .test('conditional-test-2', 'Duration of Extension cannot be more than 65 years', function (value) {
                if (+value > 65) {
                    return false;
                } else {
                    return true;
                }
            })
    }),
    page3: Yup.object({}),
    page4: Yup.object({})
};

export const MISCELLANEOUS_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({
        natureOfChange: Yup.mixed().required().label('Nature of Change'),
        memorandumOfAssociation: Yup.mixed().test('conditional-validation', 'Memorandum of Association is a required document', function (value) {
            if (this.parent.natureOfChange?.code === 'CHANGE_IN_OWNERSHIP_INTEREST') {
                return !!value;
            } else {
                return true;
            }
        }),
        approvalOfRegistrarOfCompanies: Yup.mixed().test('conditional-validation', 'Approval of Registrar of Companies is a required document', function (value) {
            if (this.parent.natureOfChange?.code === 'CHANGE_IN_OWNERSHIP_INTEREST') {
                return !!value;
            } else {
                return true;
            }
        }),
        updatedEnterpriseName: Yup.string()
            .label('Updated Enterprise Name')
            .test('conditional-validation', 'Updated Enterprise Name is a required field', function (value) {
                if (this.parent.natureOfChange?.code === 'CHANGE_IN_NAME') {
                    return !!value;
                } else {
                    return true;
                }
            }),
        certificateFromRegistrarOfFirms: Yup.mixed().test('conditional-validation', 'Certificate from Registrar of Firms/Companies is a required document', function (value) {
            if (this.parent.natureOfChange?.code === 'CHANGE_IN_NAME') {
                return !!value;
            } else {
                return true;
            }
        }),
        enterpriseProjectReport: Yup.mixed().test('conditional-validation', 'Detailed project report is a required document', function (value) {
            if (this.parent.natureOfChange?.code === 'CHANGE_IN_TRADE') {
                return !!value;
            } else {
                return true;
            }
        }),
        RegCertificate: Yup.mixed().test('conditional-validation', 'Udyam Registration Certificate is a required document', function (value) {
            if (this.parent.natureOfChange?.code === 'CHANGE_IN_TRADE') {
                return !!value;
            } else {
                return true;
            }
        })
    }),
    page2: Yup.object({}),
    page3: Yup.object({})
};

export const SURRENDER_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object({}),
    page2: Yup.object().shape({
        surrenderReason: Yup.string().required('Required')
    }),
    page3: Yup.object().shape({
        existingMortgageDetails: Yup.array().of(
            Yup.object().shape({
                existingNocDocument: Yup.mixed()
                    .required('NOC is a Required')
                    .when('existingMortgageDetails', (existingMortgageDetails, schema) => (existingMortgageDetails && existingMortgageDetails.length > 0 ? schema.required('Required') : schema))
            })
        )
    }),
    page4: Yup.object({}),
    page5: Yup.object({})
};

export const COMMERCIAL_OPS_VALIDATION = Yup.object().shape({
    newCertificateOfCO: Yup.mixed().required().label('Certificate of Commercial Operations')
});

export const QUERY_ENTRY_VALIDATION = Yup.object().shape({
    queryType: Yup.object().required().label('Type'),
    division: Yup.object().required().label('Division'),
    title: Yup.string().required().label('Title'),
    description: Yup.string().required().label('Description'),
    attachment: Yup.mixed().required().label('Attachment')
});

export const QUERY_RESPONSE_VALIDATION = Yup.object().shape({
    responseDescription: Yup.string().required().label('Description'),
    responseAttachment: Yup.mixed().required().label('Attachment')
});

export const ALLOTMENT_WORKFLOW_VALIDATION = Yup.object().shape({
    allotedEstateName: Yup.mixed().required().label('Estate Name'),
    allotedPhase: Yup.mixed().required().label('Phase'),
    allotedPlotNo: Yup.mixed().required().label('Plot No'),
    highestBidRate: Yup.string().required().label('Highest Bid Rate'),
    emdAmount: Yup.string().required().label('EMD Amount'),
    emdAmountSupportingDocument: Yup.mixed().required().label('Supporting document for EMD')
});

export const DOCUMENT_DOWNLOAD_VALIDATION = Yup.object().shape({
    fromDate: Yup.string().required().label('From date'),
    toDate: Yup.string().required().label('To date')
});

export const TENDER_VALIDATION_SCHEMA = Yup.object().shape({
    publishDate: Yup.date()
        .required('Publish Date is required')
        .nullable()
        .typeError('Invalid date')
        .test('publishDate', 'Publish Date cannot be greater than Open Date', function (publishDate) {
            const { openDate } = this.parent;
            return !openDate || !publishDate || publishDate <= openDate;
        }),

    openDate: Yup.date().required('Open Date is required').nullable().typeError('Invalid date'),

    bidCloseDate: Yup.date()
        .nullable()
        .typeError('Invalid date')
        .test('bidCloseDate', 'Tender Close Date should be greater than Open Date and Publish Date', function (bidCloseDate) {
            const { openDate, publishDate } = this.parent;
            return (!openDate || !bidCloseDate || bidCloseDate > openDate) && (!publishDate || !bidCloseDate || bidCloseDate > publishDate);
        }),

    expiryDate: Yup.date()
        .nullable()
        .typeError('Invalid date')
        .test('expiryDate', 'Expiry Date should be greater than Open Date and Publish Date', function (expiryDate) {
            const { openDate, publishDate } = this.parent;
            return (!openDate || !expiryDate || expiryDate > openDate) && (!publishDate || !expiryDate || expiryDate > publishDate);
        })
});

export const CONTINGENCIES_VALIDATION = Yup.object().shape({
    contingenciesCostHead: Yup.object().required().label('Cost Head')
});

export const DPR_VALIDATION = Yup.object().shape({
    projectName: Yup.string().required().label('Project Name'),
    estate: Yup.object().required().label('Estate'),
    projectType: Yup.object().required().label('Project Type'),
    natureOfWork: Yup.object().required().label('Nature Of Work'),
    periodConsumed: Yup.string()
        .required()
        .label('Period Consumed')
        .test('conditional-validation-1', 'Period Consumed should not be 0', function (value) {
            return +value >= 1;
        })
});

export const INSPECTION_REPORT_VALIDATION = Yup.object().shape({
    inspectionDate: Yup.string().required().label('InspectionDate'),
    areaOfSubLease: Yup.string()
        .label('Area of Unauthorized Sub-Lease')
        .test('conditional-validation', 'Area of unauthorized Sub-Lease is a required field', function (value) {
            if (this?.parent.unauthorizedSubLease) {
                return !!value;
            } else {
                return true;
            }
        }),
    duration: Yup.string()
        .label('Duration of Unauthorized Sub-Lease')
        .test('conditional-validation', 'Duration of unauthorized Sub-Lease is a required field', function (value) {
            if (this?.parent.unauthorizedSubLease) {
                return !!value;
            } else {
                return true;
            }
        }),
    unauthorizedConstructionFee: Yup.string()
        .label('Unauthorized construction Fee')
        .test('conditional-validation', 'Unauthorized construction Fee is a required field', function (value) {
            if (this?.parent.unauthorizedConstruction) {
                return !!value;
            } else {
                return true;
            }
        })
});

export const MEASUREMENT_VALIDATION = (measurementData: any) =>
    Yup.object().shape({
        gsrItemNo: Yup.mixed()
            .nullable()
            .label('GSR Item no.')
            .test('conditional-validation', 'GSR Item is already used', function (value: any, context: any) {
                if (this?.parent?.gsrMarketRate !== 'MarketRate') {
                    console.log('hhh', measurementData, value);
                    const duplicateItem = measurementData.find(
                        (item: any) => value?.code === item.gsrItemNo && context.options.context.gsrName?.code === item.gsrName && !context.options.context.rowId
                    );
                    if (duplicateItem) {
                        return false;
                    }
                    return true;
                }
                return true;
            }),
        itemDesc: Yup.mixed().required().label('Item Description'),
        itemNo: Yup.string().required().label('Item No.'),
        unitOfMeasurement: Yup.string().required().label('Unit of Measurement'),
        mvUnitRate: Yup.string()
            .test('conditional-validation', 'Unit Rate(INR) is a required field', function (value) {
                if (this?.parent?.gsrMarketRate === 'MarketRate') {
                    return !!value;
                }
                return true;
            })
            .label('Unit Rate(INR)'),
        measurementType: Yup.mixed()
            .label('Measurement Type')
            .test('conditional-validation', 'Measurement Type is a required field', function (value) {
                if (this.parent.lbdOrNonlbd === 'LBD') {
                    return !!value;
                } else {
                    return true;
                }
            }),

        measurements: Yup.array()
            .of(
                Yup.object().shape({
                    length: Yup.string()
                        .test('conditional-validation', 'Length is required and should be greater than 0 and up to 2 decimals', function (value, context: any) {
                            const lbdOrNonlbd = context?.options?.context?.lbdOrNonlbd; // Accessing parent
                            if (lbdOrNonlbd === 'LBD') {
                                // return !!value; // Require value if condition is met
                                const isValid = /^\d+(\.\d{1,2})?$/.test(strToNum(value)); // Checks if value is a number with up to 2 decimals
                                return isValid && strToNum(value) > 0; // Ensure the value is greater than 0
                            }
                            return true;
                        })
                        .nullable()
                        .label('Length (in meters)'),
                    breadth: Yup.string()
                        .test('conditional-validation', 'Breadth is required and should be greater than 0 and up to 2 decimals', function (value: any, context: any) {
                            const lbdOrNonlbd = context?.options?.context?.lbdOrNonlbd;
                            const measurementType = context?.options?.context?.measurementType;
                            if (lbdOrNonlbd === 'LBD' && measurementType?.code !== 'RUNNING_METER') {
                                // return !!value;
                                const isValid = /^\d+(\.\d{1,2})?$/.test(strToNum(value)); // Checks if value is a number with up to 2 decimals
                                return isValid && strToNum(value) > 0; // Ensure the value is greater than 0
                            }
                            return true;
                        })
                        .nullable()
                        .label('Breadth (in meters)'),
                    depth: Yup.string()
                        .test('conditional-validation', 'Depth is required and should be greater than 0 and up to 2 decimals', function (value, context: any) {
                            const lbdOrNonlbd = context?.options?.context?.lbdOrNonlbd;
                            const measurementType = context?.options?.context?.measurementType;
                            if (lbdOrNonlbd === 'LBD' && measurementType?.code === 'VOLUME') {
                                // return !!value;
                                const isValid = /^\d+(\.\d{1,2})?$/.test(strToNum(value)); // Checks if value is a number with up to 2 decimals
                                return isValid && strToNum(value) > 0; // Ensure the value is greater than 0
                            }
                            return true;
                        })
                        .nullable()
                        .label('Depth (in meters)'),
                    nos: Yup.string()
                        .test('conditional-validation', 'Nos is required and should be greater than 0 and should not be in decimals', function (value, context: any) {
                            const lbdOrNonlbd = context?.options?.context?.lbdOrNonlbd;
                            if (lbdOrNonlbd === 'LBD') {
                                // return !!value;
                                const isValid = /^\d+$/.test(strToNum(value)); // Checks if value is a number with up to 2 decimals
                                return isValid && strToNum(value) > 0; // Ensure the value is greater than 0
                            }
                            return true;
                        })
                        .label('Nos')
                        .nullable(),
                    quantity: Yup.string()
                        .required()
                        .label('Quantity')
                        .test('conditional-validation', 'Quantity should be greater than 0 and up to 2 decimals', function (value) {
                            const isValid = /^\d+(\.\d{1,2})?$/.test(value); // Validates 2 decimal places
                            return isValid && strToNum(value) > 0;
                        })
                })
            )
            .label('measurements')
    });

export const MIGRATION_SEARCH_VALIDATION = Yup.object().shape({
    email: Yup.string()
        .label('Email')
        .test('conditional-validation', 'Enter valid email address', function (value) {
            if (value === null || value === '' || value === undefined) {
                return true; // Null or empty string should fail validation
            }
            return CONSTANT_REGEX_SAMPLE.EMAIL.test(value);
        }),
    entitypanNo: Yup.string()
        .label('Pan No')
        .test('conditional-validation', 'Enter valid PAN number', function (value) {
            if (value === null || value === '' || value === undefined) {
                return true; // Null or empty string should fail validation
            }
            return CONSTANT_REGEX_SAMPLE.PAN.test(value);
        })
});

export const GSR_BOOK_DETAILS_VALIDATION = Yup.object().shape({
    gsrItemNo: Yup.string().label('Gsr Item No').required(),
    gsrUnitRate: Yup.string().label('GSR Unit Rate').required(),
    gsrUnit: Yup.string().label('GSR Unit').required(),
    itemDescriptioin: Yup.string().label('Item Descriptioin').required()
});

export const GSR_MASTER_VALIDATION = Yup.object().shape({
    projectType: Yup.object().label('GSR Name').required(),
    gsrYear: Yup.object().label('Year').required()
});

export const DPR_SEARCH_VALIDATION = Yup.object().shape({
    dprApplicationNumber: Yup.string().label('DPR Application Number').required()
});
export const NIT_SCREEN_VALIDATION = Yup.object().shape({
    tenderDocumentAvailabilityDate: Yup.string()
        .label('Tender Document Availability Date')
        .test('conditional-validation-1', 'Tender Document Availability Date is a required field', function (value) {
            if (this?.parent?.tenderType === 'NORMAL') {
                return !!value;
            } else {
                return true;
            }
        })
        .test('conditional-validation-2', 'Application Receipt End Date is a required field', function (value) {
            if (this?.parent?.tenderType === 'SHORT') {
                return !!value;
            } else {
                return true;
            }
        }),
    lastDateOfOnlineApplication: Yup.string()
        .label('Last Date Of Online Application')
        .test('conditional-validation-1', 'Last Date Of Online Application is a required field', function (value) {
            if (this?.parent?.tenderType === 'NORMAL') {
                return !!value;
            } else {
                return true;
            }
        })
        .test('conditional-validation-2', 'Blank Tender Form Issue End Date is a required field', function (value) {
            if (this?.parent?.tenderType === 'SHORT') {
                return !!value;
            } else {
                return true;
            }
        })
        .test('conditional-validation-3', 'Last Date Of Online Application cannot be less than Tender Document Availability Date', function (value: any) {
            if (this?.parent?.tenderType === 'NORMAL' && new Date(value).getTime() <= new Date(this?.parent?.tenderDocumentAvailabilityDate).getTime()) {
                return false;
            } else {
                return true;
            }
        })
        .test('conditional-validation-4', 'Blank Tender Form Issue End Date cannot be less than Application Receipt End Date', function (value: any) {
            if (this?.parent?.tenderType === 'SHORT' && new Date(value).getTime() <= new Date(this?.parent?.tenderDocumentAvailabilityDate).getTime()) {
                return false;
            } else {
                return true;
            }
        }),
    submissionEndDate: Yup.string()
        .label('Submission End Date')
        .test('conditional-validation-1', 'Submission End Date is a required field', function (value) {
            if (this?.parent?.tenderType === 'NORMAL') {
                return !!value;
            } else {
                return true;
            }
        })
        .test('conditional-validation-2', 'Complete Short Tender Submission End Date is a required field', function (value) {
            if (this?.parent?.tenderType === 'SHORT') {
                return !!value;
            } else {
                return true;
            }
        })
        .test('conditional-validation-3', 'Submission End Date cannot be less than Last Date Of Online Application', function (value: any) {
            if (this?.parent?.tenderType === 'NORMAL' && new Date(value).getTime() <= new Date(this?.parent?.lastDateOfOnlineApplication).getTime()) {
                return false;
            } else {
                return true;
            }
        })
        .test('conditional-validation-4', 'Complete Short Tender Submission End Date cannot be less than Blank Tender Form Issue End Date', function (value: any) {
            if (this?.parent?.tenderType === 'SHORT' && new Date(value).getTime() <= new Date(this?.parent?.lastDateOfOnlineApplication).getTime()) {
                return false;
            } else {
                return true;
            }
        }),
    bidOpenStartDate: Yup.string()
        .required()
        .label('Bid Opening Date')
        .test('conditional-validation-1', 'Bid Opening Date cannot be less than Submission End Date', function (value: any) {
            if (this?.parent?.tenderType === 'NORMAL' && new Date(value).getTime() <= new Date(this?.parent?.submissionEndDate).getTime()) {
                return false;
            } else {
                return true;
            }
        })
        .test('conditional-validation-2', 'Bid Opening Date cannot be less than Complete Short Tender Submission End Date', function (value: any) {
            if (this?.parent?.tenderType === 'SHORT' && new Date(value).getTime() <= new Date(this?.parent?.submissionEndDate).getTime()) {
                return false;
            } else {
                return true;
            }
        }),
    publicationDate: Yup.string()
        .label('Date of Publication')
        .test('conditional-validation-1', 'Date of Publication is a required field is a required field', function (value) {
            if (this?.parent?.tenderType === 'NORMAL') {
                return !!value;
            } else {
                return true;
            }
        })
        .test('conditional-validation-2', 'Date of Publication cannot be less than Bid Opening Date', function (value: any) {
            if (this?.parent?.tenderType === 'NORMAL' && new Date(value).getTime() <= new Date(this?.parent?.bidOpenStartDate).getTime()) {
                return false;
            } else {
                return true;
            }
        })
});

export const BIDDER_DETAILS_VALIDATION: { [pageno: string]: any } = {
    page1: Yup.object().shape({
        dprNumber: Yup.object().label('DPR Number').required()
    }),
    page2: Yup.object().shape({
        name: Yup.string().label('Name of Bidder').required(),
        mobileNo: Yup.string()
            .min(10, 'Phone number should be at least 10 digit')
            .max(10, 'Phone number should not be more than 10 digit')
            .required()
            .label('Mobile Number')
            .matches(CONSTANT_REGEX_SAMPLE.NUMERIC),
        emailId: Yup.string().label('Email ID').matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address').required(),
        addressLine1: Yup.string().label('Address Details').required(),
        district: Yup.string().label('District').required(),
        pincode: Yup.string().label('Pin Code').required(),
        state: Yup.string().label('State').required(),
        country: Yup.string().label('Country').required()
    }),
    page3: Yup.object().shape({
        bidPrice: Yup.string().required().label('Bid Amount'),
        bidSubmissionDate: Yup.string().required().label('Bid Submission Date'),
        perfGuaranteeValidityDate: Yup.string()
            .nullable()
            .label('Performance Guarantee validity date')
            .test('conditional-validation', 'Performance Guarantee validity date is a required field', function (value) {
                if (this?.parent?.tenderType === 'NORMAL') {
                    return !!value;
                } else {
                    return true;
                }
            })
    }),
    page4: Yup.object().shape({})
};

export const LOA_VALIDATION = Yup.object().shape({
    bidPrice: Yup.string().label('Bid Amount').required(),
    bidSubmissionDate: Yup.string().label('Bid Submission Date').required(),
    perfGuaranteeAmount: Yup.string().label('Performance Guarantee Amount').required(),
    perfGuaranteeValidityDate: Yup.string().label('Performance Guarantee validity date').required()
    // perfGuaranteeSubmissionDays: Yup.string().label('Performance Guarantee submission time (Days)').required()
});

export const E_SIGN_VALIDATION = Yup.object().shape({
    token: Yup.mixed().required().label('Token'),
    certificate: Yup.mixed().required().label('Certificate'),
    password: Yup.string().required().label('Password')
});

export const WO_DETAILS_VALIDATION = Yup.object().shape({
    loaAcceptanceDate: Yup.string().label('LOA Acceptance Date').required(),
    stipulatedDateCommencement: Yup.string().label('Stipulated Date Of Commencement').required(),
    stipulatedDateCompletion: Yup.string().label('Stipulated Date Of Completion').required(),
    documentLoa: Yup.mixed().label('Signed copy of LOA').required()
});

export const CONTRACT_DETAILS_VALIDATION = Yup.object().shape({
    defectLiabilityPeriodMonths: Yup.string()
        .label('Defect Liability Period Months')
        .nullable()
        .test('conditional-validation', 'Defect Liability Period Months is a required field', function (value) {
            if (this.parent.defectLiability === true) {
                return !!value;
            } else {
                return true;
            }
        }),
    rebatePercentage: Yup.string()
        .label('Rebate Percentage')
        .nullable()
        .test('conditional-validation', 'Rebate Percentage is a required field', function (value) {
            console.log('value', this);
            if (this?.parent?.rebate === true) {
                return !!value;
            }
            return true;
        })
    // signedCopyofContract: Yup.mixed()
    //     .label('Signed copy of Contract')
    //     .nullable()
    //     .test('conditional-validation', 'Signed copy of Contract is a required field', function (value) {
    //         if (this?.parent?.validateSignContract === true) {
    //             return !!value;
    //         }
    //         return true;
    //     })
});

export const WORK_CATEGORY_VALIDATION = Yup.object().shape({
    milestoneNo: Yup.string().label('Milestone No').required(),
    projectStartDate: Yup.string().label('Project Start Date').required(),
    milestoneTargetDate: Yup.string()
        .label('Milestone Target Date')
        .required()
        .test('is-after-projectStartDate', 'Maintenance Period Start Date must be after the Project start date', function (milestoneTargetDate) {
            const { projectStartDate } = this.parent;
            return isAfter(milestoneTargetDate, projectStartDate);
        }),
    monthsfromCommencement: Yup.string().label('Months From Commencement').required(),
    contractWorkCategoryList: Yup.array().of(
        Yup.object().shape({
            contractWorkCategory: Yup.object().required().label('Work Category'),
            value: Yup.string()
                .required('Value is required')
                .required('Value is required')
                .test(
                    'is-valid-number',
                    'Value must be a number',
                    value => !isNaN(Number(value)) // Ensure the value is a number
                )
                .test(
                    'max-100',
                    'Value must be 100 or less',
                    value => Number(value) <= 100 // Check that the number is <= 100
                )
        })
    )
});

export const PBG_DETAILS_VALIDATION = Yup.object().shape({
    pgNo: Yup.string().label('PG No.').required(),
    amount: Yup.string().label('PBG Amount').required(),
    bankName: Yup.string().label('Bank Name').required()
});

export const CONTRACT_CLOSURE_VALIDATION = Yup.object().shape({
    contractPhase: Yup.mixed().label('Contract Phase').required(),
    comments: Yup.string().label('Comments').required(),
    referenceDocument: Yup.mixed().label('Reference Document').required()
});

export const MB_AND_BILL_VALIDATION = Yup.object().shape({
    billName: Yup.string().label('Bill Name').required(),
    contractClosureDate: Yup.string()
        .label('Contract Closure Date')
        .nullable()
        .test('conditional-validation', 'Contract Closure Date is a required field', function (value) {
            if (this.parent.isFinalBill === true) {
                return !!value;
            } else {
                return true;
            }
        })
});
export const MB_AND_BILL_MEASUREMENT_VALIDATION = Yup.object().shape({
    // measurementEntry: Yup.array().of(
    //     Yup.object().shape({
    //         itemNos: Yup.string()
    //             .test('conditional-validation', 'Nos is required and should be greater than 0 and should not be in decimals', function (value, context: any) {
    //                 const isValid = /^\d+$/.test(strToNum(value)); // Checks if value is a number with up to 2 decimals
    //                 return isValid && strToNum(value) > 0 ? true : false; // Ensure the value is greater than 0
    //             })
    //             .label('Nos')
    //             .nullable(),
    //         lengthInMeters: Yup.string().required().label('Length (in meters)'),
    //         breadthInMeters: Yup.string().required().label('Breadth (in meters)'),
    //         depthInMeters: Yup.string().required().label('Depth (in meters)')
    //     })
    // )
    measurementEntry: Yup.array()
        .of(
            Yup.object().shape({
                lengthInMeters: Yup.string()
                    .test('conditional-validation', 'Length is required and should be greater than 0 and up to 2 decimals', function (value, context: any) {
                        const lbdOrNonlbd = context?.options?.context?.lbdOrNonlbd; // Accessing parent
                        if (lbdOrNonlbd === 'LBD') {
                            // return !!value; // Require value if condition is met
                            const isValid = /^\d+(\.\d{1,2})?$/.test(strToNum(value)); // Checks if value is a number with up to 2 decimals
                            return isValid && strToNum(value) > 0; // Ensure the value is greater than 0
                        }
                        return true;
                    })
                    .nullable()
                    .label('Length (in meters)'),
                breadthInMeters: Yup.string()
                    .test('conditional-validation', 'Breadth is required and should be greater than 0 and up to 2 decimals', function (value: any, context: any) {
                        const lbdOrNonlbd = context?.options?.context?.lbdOrNonlbd;
                        const measurementType = context?.options?.context?.type;
                        if (lbdOrNonlbd === 'LBD' && measurementType?.code !== 'RUNNING_METER') {
                            // return !!value;
                            const isValid = /^\d+(\.\d{1,2})?$/.test(strToNum(value)); // Checks if value is a number with up to 2 decimals
                            return isValid && strToNum(value) > 0; // Ensure the value is greater than 0
                        }
                        return true;
                    })
                    .nullable()
                    .label('Breadth (in meters)'),
                depthInMeters: Yup.string()
                    .test('conditional-validation', 'Depth is required and should be greater than 0 and up to 2 decimals', function (value, context: any) {
                        const lbdOrNonlbd = context?.options?.context?.lbdOrNonlbd;
                        const measurementType = context?.options?.context?.type;
                        if (lbdOrNonlbd === 'LBD' && measurementType?.code === 'VOLUME') {
                            // return !!value;
                            const isValid = /^\d+(\.\d{1,2})?$/.test(strToNum(value)); // Checks if value is a number with up to 2 decimals
                            return isValid && strToNum(value) > 0; // Ensure the value is greater than 0
                        }
                        return true;
                    })
                    .nullable()
                    .label('Depth (in meters)'),
                itemNos: Yup.string()
                    .test('conditional-validation', 'Nos is required and should be greater than 0 and should not be in decimals', function (value, context: any) {
                        const lbdOrNonlbd = context?.options?.context?.lbdOrNonlbd;
                        if (lbdOrNonlbd === 'LBD') {
                            // return !!value;
                            const isValid = /^\d+$/.test(strToNum(value)); // Checks if value is a number with up to 2 decimals
                            return isValid && strToNum(value) > 0; // Ensure the value is greater than 0
                        }
                        return true;
                    })
                    .label('Nos')
                    .nullable(),
                itemQuantity: Yup.string()
                    .required()
                    .label('Quantity')
                    .test('conditional-validation', 'Quantity should be greater than 0 and up to 2 decimals', function (value) {
                        const isValid = /^\d+(\.\d{1,2})?$/.test(value); // Validates 2 decimal places
                        return isValid && strToNum(value) > 0;
                    }),
                measurementDate: Yup.string().required().label('Date')
            })
        )
        .label('measurements')
});

export const ENTERPRISE_UPDATE_VALIDATION = Yup.object().shape({
    enterpriseName: Yup.string().required().label('Enterprise Name'),
    emailId: Yup.string()
        .required()
        .label('Email Id')
        .matches(CONSTANT_REGEX_SAMPLE.EMAIL, 'Enter valid email address')
        .test('emailId', 'Email already exists', async function (value, context) {
            if (value !== previousValues.emailId) {
                const payload = {
                    RequestInfo: requestInfo(),
                    tenantId: 'ga',
                    emailId: value || '',
                    type: USER_TYPE.ENTERPRISE,
                    uuid: context.parent.entityUuid
                };
                previousValues.emailId = context.parent.emailId;
                try {
                    let response: any = CONSTANT_REGEX_SAMPLE.EMAIL.test(value) && (await checkUserAvailability(payload));
                    if (response.data.isAvailable) {
                        errors.emailId = false;
                        return true;
                    } else {
                        errors.emailId = true;
                        return false;
                    }
                } catch (e) {
                    errors.emailId = true;
                    return false;
                }
            } else if (this.parent.underProcess) {
                return true;
            }

            return !errors.emailId;
        }),
    phoneNo: Yup.string()
        .min(10, 'Phone number should be at least 10 digit')
        .max(10, 'Phone number should not be more than 10 digit')
        .required()
        .label('Mobile number')
        .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, 'Enter valid Number ')
        .test('unique-phone', 'Mobile No. already exists', async function (value, context) {
            if (value !== previousValues.phoneNo) {
                const payload = {
                    RequestInfo: requestInfo(),
                    tenantId: 'ga',
                    mobileNumber: value.toString() || '',
                    type: USER_TYPE.ENTERPRISE,
                    uuid: context.parent.entityUuid
                };
                previousValues.phoneNo = context.parent.phoneNo;
                try {
                    let response: any = value.length === 10 && (await checkUserAvailability(payload));
                    if (response.data.isAvailable) {
                        errors.phoneNo = false;
                        return true;
                    } else {
                        errors.phoneNo = true;
                        return false;
                    }
                } catch (e) {
                    errors.phoneNo = true;
                    return false;
                }
            }
            return !errors.phoneNo;
        })
});

export const ENTERPRISE_SEARCH_VALIDATION = Yup.object().shape({
    email: Yup.string()
        .label('Email')
        .test('conditional-validation', 'Enter valid email address', function (value) {
            if (value === null || value === '' || value === undefined) {
                return true; // Null or empty string should fail validation
            }
            return CONSTANT_REGEX_SAMPLE.EMAIL.test(value);
        }),
    entitypanNo: Yup.string()
        .label('Pan No')
        .test('conditional-validation', 'Enter valid PAN number', function (value) {
            if (value === null || value === '' || value === undefined) {
                return true; // Null or empty string should fail validation
            }
            return CONSTANT_REGEX_SAMPLE.PAN.test(value);
        }),
    phoneNo: Yup.string()
        .label('Phone Number')
        .test('conditional-validation', 'Enter valid Phone Number', function (value) {
            if (value === null || value === '' || value === undefined) {
                return true;
            }
            return CONSTANT_REGEX_SAMPLE.PHONE.test(value);
        })
});

export const CONTRACT_EXTENSION_VALIDATION = Yup.object().shape({
    extensionDate: Yup.string().required().label('Contract Extension Date'),
    reason: Yup.string().required().label('Reason'),
    supportingDocument: Yup.mixed().required().label('Supporting Document'),
    compensationAmount: Yup.string()
        .label('Compensation Amount')
        .test('conditional-validation', 'Compensation Amount is a required field', function (value) {
            if (this?.options?.context?.designation === 'GM') {
                return !!value;
            } else {
                return true;
            }
        })
});
