import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';

export const DSC_REGISTRATION_FIELDS = [
    {
        formData: [
            {
                title: 'Token',
                titleCode: 'TOKEN',
                field: 'token',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Certificate',
                titleCode: 'CERTIFICATE',
                field: 'certificate',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Password',
                titleCode: 'PASSWORD',
                field: 'keyStorePassPhrase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                subType: CONSTANT_DATA_FIELD.PASSWORD,
                disableCondition: (values: any, index: any) => values.token?.code !== 'Microsoft Windows Store'
            }
        ]
    }
];

export const KYC_REGISTRATION_FIELDS = [
    {
        title: 'e-Mudra KYC Registration',
        titleCode: 'EMUDRA_KYC_REGISTRATION',
        formData: [
            {
                title: 'PAN No',
                titleCode: 'PAN_NO',
                field: 'pan',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Name on Pan',
                titleCode: 'NAME_ON_PAN',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Mobile Number',
                titleCode: 'MOBILE_NUMBER',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false
            },
            {
                title: 'Email ID',
                titleCode: 'EMAIL_ID',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'DATE_OF_BIRTH',
                title: 'Date of Birth',
                field: 'dob',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                titleCode: 'GENDER',
                title: 'Gender',
                field: 'gender',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 1',
                titleCode: 'ADDRESS_LINE_1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                titleCode: 'ADDRESS_LINE_2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                titleCode: 'PINCODE',
                field: 'pinCode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'District',
                titleCode: 'DISTRICT',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State/UT',
                titleCode: 'STATE',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Country',
                titleCode: 'COUNTRY',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        title: 'Documents',
        titleCode: 'DOCUMENTS',
        formData: [
            {
                title: 'Photo',
                titleCode: '',
                field: 'doc1',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true
            },
            {
                title: 'ID Proof',
                titleCode: '',
                field: 'doc2',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true
            },
            {
                title: 'Address Proof',
                titleCode: '',
                field: 'doc3',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true
            }
        ]
    }
];
