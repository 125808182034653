import React from 'react'
import './styles.scss'
import HeaderComponent from '../../components/header/Header.component'
import FooterComponent from '../../components/portalComponents/Footer.component'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


export default function FaqPage() {

    const { t, i18n } = useTranslation();

    return (
        <>
            <HeaderComponent />
            <div className='mx-5 p-5 font-inter'>
                <div className='my-2 breadcrumbs'>
                    <p ><Link className='text-decoration-none' to="/goa-idc/">{t("HOME", "Home")}</Link> / {t("FAQS", "FAQs")}</p>
                </div>
                <div className='col-md-8' style={{ fontWeight: 400, fontSize: '48px', lineHeight: '76.8px', letterSpacing: '-2%' }}>
                    {t("FREQUENTLY_ASKED_QUESTIONS", "Frequently Asked Questions")}
                </div>
            </div>
            <div className='d-flex mx-5 px-5 font-inter ' style={{ fontWeight: 600, fontSize: '18px', lineHeight: '25.2px', letterSpacing: '-1%' }}>
                <div className='col-sm-3 d-flex flex-column me-3'>
                    {/* <div className='p-3' style={{ cursor: 'pointer' }}>Estate</div><hr /> */}

                    <div className='Obpas-check px-3' aria-aria-checked>
                        <input className="FaqRadio" type="checkbox" name='Obpas-check' id='OBPAS' checked />
                        <p style={{ color: '#45852E', cursor: 'pointer' }}>{t("FAQS_OBPAS", "Online Building Plan Approval System (OBPAS)")}</p>
                    </div>
                </div>
                <div className='col-sm-9'>
                    <ul className="accordionFaq" style={{ listStyleType: 'none' }}>
                        <li >
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="first" />
                            <label className=" FaqLabel" htmlFor="first">{t("OBPAS_Q1", "What is an Online Building Plan Approval System (OBPAS)?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A1", "An online Building Plan Approval System (OBPAS) is a web-based platform developed by Goa-IDC that allows architects and lessees to submit building plans electronically for review and approval by the Industrial Planning &amp; Development Committee (IPDC) constituted under Section 37-B of Goa Industrial Development Act, 1965.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="second" />
                            <label className=" FaqLabel" htmlFor="second">{t("OBPAS_Q2", "Can the Architect apply for technical clearance on behalf of the Lessee?")}</label>
                            < div className="content" >
                                <p className='font-karla'>{t("OBPAS_A2", "No, the Architect shall not apply for technical clearance on behalf of the Lessee.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="third" />
                            <label className=" FaqLabel" htmlFor="third">{t("OBPAS_Q3", "In what format should the building plan be prepared?")}</label>
                            < div className="content" >
                                <p className='font-karla'>{t("OBPAS_A3", "The building plan shall be prepared in .dxf format.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="forth" />
                            <label className=" FaqLabel" htmlFor="forth">{t("OBPAS_Q4", "What are the processing fees to be paid for obtaining technical clearance?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A4", `Processing fees for obtaining technical clearance for Industrial Building shall be Rs. 10,000/- plus Rs 5 per m<sup>2</sup> of the area above 1,000m<sup>2</sup> .`)}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="fifth" />
                            <label className=" FaqLabel" htmlFor="fifth">{t("OBPAS_Q5", "Is there any technical support service available for OBPAS?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A5", "Yes, for technical support, the link will be provided shortly.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="sixth" />
                            <label className=" FaqLabel" htmlFor="sixth">{t("OBPAS_Q6", "What is Infrastructure Tax and how is it calculated?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A6", "It is a Tax imposed by the State Government, on any construction to be undertaken by any person on any land (as specified in the Act) under the Goa Tax on Infrastructure Act, 2009 (As Amended). Presently the infrastructure tax for Industries is Rs. 300 per sq. mt. of built-up area.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="seventh" />
                            <label className=" FaqLabel" htmlFor="seventh">{t("OBPAS_Q7", "What is Construction License Fee and how is it calculated?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A7", "A construction license fee is a charge imposed by a local authority for obtaining a permit to conduct construction activities within its jurisdiction. In case of notified Industrial Area/Estate in Goa, the Goa-IDC is the Local Authority. The construction license fee is calculated as 1.5% of the total construction cost.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="eighth" />
                            <label className=" FaqLabel" htmlFor="eighth">{t("OBPAS_Q8", "What is Labour Cess and how is it calculated?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A8I", "It is the Building &amp; Other Construction Workers’ Welfare Cess [BOCWW Cess] which is often referred to as “labour cess” by certain section/individual.")}<br />
                                    {t("OBPAS_A8II", "It is administered under the Building and Other Construction Workers’ Welfare Cess Act, 1996 read with relevant sections of the Building and Other Construction Workers (Regulation of Employment and Conditions of Service) Act, 1996. ")}<br />
                                    {t("OBPAS_A8III", "It is calculated at 1% of the Construction cost.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="ninth" />
                            <label className=" FaqLabel" htmlFor="ninth">{t("OBPAS_Q9", "Is there an exemption of Labour Cess for factory buildings?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A9", "Yes, the factory buildings constructed for industry shall be exempted from payment of the Cess.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="tenth" />
                            <label className=" FaqLabel" htmlFor="tenth">{t("OBPAS_Q10", "What is the relaxation on minimum setback areas for industrial buildings in industrial estates of Goa-IDC?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A10", `In Industrial Estates and Industrial Areas, a minimum of 3.00 mts setback shall be maintained in case the area of the plot does not exceed 1000m<sup>2</sup> and a minimum of 4.5mts for the plot exceeding 1000m<sup>2</sup>.`)}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="eleventh" />
                            <label className=" FaqLabel" htmlFor="eleventh">{t("OBPAS_Q11", "What is a built-up area?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A11", "As per the TCP guidelines, built-up area means all areas which are built upon and essentially forming part of the buildings which includes all areas computed under covered area/floor area as well as all areas specifically exempted under covered area/floor area calculations.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="twelfth" />
                            <label className=" FaqLabel" htmlFor="twelfth">{t("OBPAS_Q12", "What is the minimum built-up area to be constructed by a Lessee?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A12", "The Lessee shall build a minimum built-up area of 30% of the total area of the plot.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="thirteenth" />
                            <label className=" FaqLabel" htmlFor="thirteenth">{t("OBPAS_Q13", "What is the penalty for regularization of unauthorized construction?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A13", "The compounding fees amounting to not less than 3 times of normal license fees will be charged as a penalty for regularization of unauthorized construction.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="fourteenth" />
                            <label className=" FaqLabel" htmlFor="fourteenth">{t("OBPAS_Q14", "Can the building plan be modified after obtaining a construction license?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A14", "Yes, the building plan may be modified after obtaining a construction license.")}</p>
                            </div>
                        </li><hr />
                        <li>
                            <input className="FaqRadio" type="checkbox" name="accordionFaq" id="fifteenth" />
                            <label className=" FaqLabel" htmlFor="fifteenth">{t("OBPAS_Q15", "Can the Lessee apply for a part occupancy certificate?")}</label>
                            <div className="content">
                                <p className='font-karla'>{t("OBPAS_A15", "Yes, the Lessee may apply for a part-occupancy certificate.")}</p>
                            </div>
                        </li><hr />
                    </ul>
                </div>
            </div>
            <div>
                <FooterComponent />
            </div>

        </>
    )
}
