import _ from 'lodash';
import { AUTHORIZED_PERSON_TITLE, CONSTANT_DATA_FIELD, FormDataDTO } from '../../../utils/constants';

export const DOCUMENT_TYPE = {
    ENTITY: 'ENTITY',
    OTHER: 'OTHER',
    AUTHUSER: 'AUTHUSER',
    OWNER: 'OWNER',
    LOCALPERSON: 'LOCALPERSON',
    EDUCATION: 'EDUCATION',
    PAN: 'PAN',
    ENTITY_CATEGORY: {
        PROPRIETORSHIPFIRM: 'PROPRIETORSHIPFIRM',
        IDENTITY: 'IDENTITY',
        TRUST_DEED: 'TRUST_DEED',
        PARTNERSHIP_DEED: 'PARTNERSHIP_DEED',
        CERTIFICATE_OF_REGISTRATION_FIRMS: 'CERTIFICATE_OF_REGISTRATION_FIRMS',
        CERTIFICATE_OF_INCORPORATION: 'CERTIFICATE_OF_INCORPORATION',
        REGISTRATION_OF_SOCITIES: 'REGISTRATION_OF_SOCITIES',
        GOVT_NOTIFICATION: 'GOVT_NOTIFICATION',
        GST: 'GST',
        NON_GST: 'GST_NON_APPLICABILITY'
    },
    OTHER_CATEGORY: {
        UDYAMREGISTRATION: 'UDYAMREGISTRATION',
        DPIIT: 'DPIIT',
        WOMENENTREPRENUEUR: 'WOMENENTREPRENUEUR',
        EXPERIENCED: 'EXPERIENCED'
    },
    AUTH_CATEGORY: {
        POWEROFATTORNEY: 'POWEROFATTORNEY'
    },
    DETAILED_PROJECT_REPORT: 'DETAILED_PROJECT_REPORT',
    IN_PRINCIPAL_APPROVAL: 'IN_PRINCIPAL_APPROVAL',
    MOHA_FDI_UNDERTAKING_APPROVAL: 'MOHA_FDI_UNDERTAKING_APPROVAL',
    SPECIAL_PROJECT: 'SPECIAL_PROJECT',
    SUB_LEASE_ORDER: 'SUB_LEASE_ORDER',
    ALLOTMENT_ORDER: 'PROVISIONAL_ALLOTMENT_ORDER',
    ALLOTMENT: 'ALLOTMENT',
    TRANSFER_ORDER: 'TRANSFER_ORDER',
    TRANSFER: 'TRANSFER',
    EXTENSION_ORDER: 'EXTENSION_ORDER',
    EXTENSION: 'EXTENSION',
    EXECUTED_LD: 'EXECUTED_LD',
    REGISTERED_LD: 'REGISTERED_LD',
    EXECUTED_TD: 'EXECUTED_TD',
    REGISTERED_TD: 'REGISTERED_TD',
    EXECUTED_EXT_LD: 'EXECUTED_EXT_LD',
    REGISTERED_EXT_LD: 'REGISTERED_EXT_LD',
    NOC_SPECIAL_PROJECT: 'NOC_SPECIAL_PROJECT',
    REGISTRATION_OF_SOCITIES: 'REGISTRATION_OF_SOCITIES',
    POWEROFATTORNEY: 'POWEROFATTORNEY',
    PROPRIETORSHIPFIRM: 'PROPRIETORSHIPFIRM',
    IDENTITY: 'IDENTITY',
    GST: 'GST',
    UDYAMREGISTRATION: 'UDYAMREGISTRATION',
    EXPERIENCED: 'EXPERIENCED',
    AREA_OF_SUB_LEASE: 'SUBLEASE_AREA_INFO',
    APPROVAL_OF_REGISTRAR: 'APPROVAL_OF_REGISTRAR',
    MEMORANDUM_OF_ASSOCIATION: 'MEMORANDUM_OF_ASSOCIATION',
    COURT_ORDER: 'COURT_ORDER',
    CORPORATE_INSOLVANCY_RESOLUTION_PLAN: 'CORPORATE_INSOLVANCY_RESOLUTION_PLAN',
    LLP_REGISTRATION: 'LLP_REGISTRATION',
    DEATH_CERTIFICATE: 'DEATH_CERTIFICATE',
    SUCCESSION_CERTIFICATE: 'SUCCESSION_CERTIFICATE',
    EMD_DOC: 'EMD_DOCUMENT',
    NOC: 'NOC',
    LICENSE_COPY: 'LICENSE_COPY',
    IDENTITY_PROOF: 'IDENTITY_PROOF',
    ENTERPRISE: 'ENTERPRISE'
};

export const PLOT_DETAILS: any[] = [
    {
        titleCode: 'PLOT_DETAILS',
        title: 'Plot Details',
        formData: [
            {
                title: 'Name of Industrial Estate',
                titleCode: 'INDUSTRIAL_ESTATE_NAME',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD,
                isRequired: true
            },
            {
                title: 'Phase',
                titleCode: 'PHASE',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD,
                isRequired: true
            },
            {
                title: 'Plot No',
                titleCode: 'PLOT_NO',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD,
                isRequired: true
            },
            {
                title: 'Plot Type',
                titleCode: 'PLOT_TYPE',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const APPLICANT_DETAILS_SEARCH: FormDataDTO[] = [
    {
        title: 'Name',
        formData: [
            {
                title: 'Lessee Name',
                field: 'applicantName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Lessee Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD
                // isRequired: true
            },
            {
                title: 'PAN No',
                field: 'applicantPanNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            }
        ]
    }
];

export const APPLICANT_DETAILS = [
    {
        formData: [
            {
                title: 'Lessee Category',
                field: 'category',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            }
        ]
    },
    {
        // titleCode: 'Enterprise Documents',
        // title: 'Enterprise Documents',
        // renderCondition: (value: any) => {
        //     const categoryValue = _.get(value, `category`)?.code;
        //     return categoryValue !== 'INDIVIDUAL' && !!categoryValue;
        // },
        formData: [
            {
                title: 'Letter stating the name of the Proprietorship firm',
                field: 'entityProprietorshipFirm',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Identity of the proprietor',
                field: 'entityProprietorIdentity',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Trust Deed',
                field: 'trustDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => {
                    return _.get(value, `category`)?.code === 'TRUST';
                },
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Partnership Deed',
                field: 'partnershipDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Certificate of registration from the Registrar of Firms',
                field: 'certificateOfRegistrationFirms',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Certificate of incorporation from Registrar of Companies',
                field: 'certificateOfIncorporation',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'LIMITED_LIABILITY_PARTNERSHIP' || _.get(value, `category`)?.code === 'LTD_COMPANY',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Certificate of registration from the Registrar of Societies',
                field: 'certificateOfRegistrationSocieties',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'COOP_SOCIETY',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Copy of relevant notification',
                field: 'copyRelevantNotification',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'GOV_STAT_CORP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            }
        ]
    },
    {
        formData: [
            {
                title: 'PAN Card',
                titleCode: 'PAN Card',
                field: 'applicantPancard',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.PAN,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            }
        ]
    },

    {
        titleCode: 'Address of the Enterprise',
        title: 'Address of the Enterprise',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State/UT',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Mailing/Correspondence Address of the Enterprise',
        title: 'Mailing/Correspondence address of the Enterprise',
        isSameAsAbove: true,
        isRequired: true,
        formData: [
            {
                title: 'Address Line 1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2 ',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'District',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Country',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Details of Authorized Person',
        title: 'Details of Authorized Person',
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                title: 'First Name',
                field: 'firstName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Last Name',
                field: 'lastName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },

            {
                title: 'Primary Mobile Number',
                field: 'pmobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Alternative Mobile Number',
                field: 'alternateMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false
            },
            {
                title: 'Email ID',
                field: 'authorizedEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'LETTER_OF_AUTHORIZATION',
                title: 'Letter of Authorization',
                field: 'letter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'firstName',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                },
                hideFileSize: true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>The letter / resolution / power of attorney authorising the authorised person to apply on behalf of the Applicant</div>'
                },
                isRequired: true
            }
        ]
    }
];

export const PROMOTER_DETAILS1 = [
    {
        titleCode: 'Other Details',
        title: 'Other Details',
        formData: [
            {
                title: 'Udyam Registered Enterprise',
                titleCode: 'UDYAM_REGISTERED_ENTERPRISE',
                field: 'udhyam',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                title: 'Applicant has prior relevant experience',
                titleCode: 'PRIOR_EXPERIENCE',
                field: 'priorExperience',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>In the case of Partnership, LLP, Private or Public Limited Company, any one of the Promoters may declare prior relevant experience, if any.</p>'
                }
            },
            {
                title: 'Enter Udyam Registration Number',
                titleCode: 'UDYAM_REGISTRATION_NUMBER',
                field: 'udyamNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (values: any) => _.get(values, `udhyam`) === true,
                isRequired: true
                // conditionalClass: value => (_.get(value, `udhyam`) === true && _.get(value, `isStartup`) === false ? 'mb-4' : '')
            },
            {
                title: '',
                field: '',
                type: 'none',
                renderCondition: (values: any) =>
                    (_.get(values, `priorExperience`) === true && _.get(values, `udhyam`) === false) || (_.get(values, `priorExperience`) === false && _.get(values, `udhyam`) === true)
                // class: 'col-sm-6 pb-5'
            },
            {
                title: 'Please select relevant experience',
                titleCode: 'SELECT_RELEVANT_EXPERIENCE',
                field: 'experienceCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                renderCondition: (values: any) => _.get(values, `priorExperience`) === true,
                isRequired: true
            },
            {
                title: 'Udyam Registration Certificate',
                titleCode: 'UDYAM_REGISTRATION_CERTIFICATE',
                field: 'RegCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                renderCondition: (values: any) => _.get(values, `udhyam`) === true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                class: 'pad-y-0 col-sm-6',
                hideFileSize: true
                // conditionalClass: value => (_.get(value, `udhyam`) === true ? 'mb-5' : '')
            },
            {
                title: '',
                field: '',
                type: 'none',
                renderCondition: (values: any) =>
                    (_.get(values, `priorExperience`) === true && _.get(values, `udhyam`) === false) || (_.get(values, `priorExperience`) === false && _.get(values, `udhyam`) === true)
                // class: 'col-sm-6 pb-5'
            },
            {
                titleCode: 'RELEVANT_EXPERIENCE_CERTIFICATE',
                title: 'Prior Relevant Experience Document',
                field: 'experienceCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.EXPERIENCED,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                renderCondition: (values: any) => _.get(values, `priorExperience`) === true,
                class: 'pad-y-0 col-sm-6',
                hideFileSize: true
            },
            {
                title: 'Whether the enterprise is a start-up?',
                titleCode: 'IS_STARTUP',
                field: 'isStartup',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-sm-6 me-3'
            },
            {
                title: 'DPIIT/Government of Goa, Certificate of Recognition Certificate for Startup',
                titleCode: 'DPPIT_CERTIFICATE',
                field: 'DPIITCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                renderCondition: (values: any) => _.get(values, `isStartup`) === true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.DPIIT,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                class: 'col-sm-6',
                hideFileSize: true
            }
        ]
    }
];

export const BANK_DETAILS: FormDataDTO[] = [
    {
        titleCode: 'BANK_DETAILS',
        title: 'Bank Account Details',
        formData: [
            {
                title: 'Name of Account Holder',
                field: 'nameOfAccountHolder',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-12',
                isDisabled: true
            },
            {
                title: 'Account Number',
                field: 'accountNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'IFSC Code',
                field: 'ifscCode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Bank Name',
                field: 'bankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Branch address',
                field: 'branchAddress',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const MIGRATION_FORM_STEPS = [
    {
        titleCode: 'APPLICANT_DETAILS',
        name: "Lessee's Details",
        index: 1,
        isActive: true
    },
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'ENTERPRISE_DETAILS',
        name: 'Enterprise Details',
        index: 3,
        isActive: true
    },
    {
        titleCode: 'OTHER_DETAILS',
        name: 'Other Details',
        index: 4,
        isActive: false
    },

    {
        titleCode: 'Construction License Details',
        name: 'Construction License Details',
        index: 5,
        isActive: false
    },
    {
        titleCode: 'PAYMENT_DETAILS',
        name: 'Payment Details',
        index: 6,
        isActive: false
    }
];

export const subLesseePrimaryDetails = [
    {
        titleCode: 'Sub-Lessee Details',
        title: 'Sub-Lessee Details',
        formData: [
            {
                title: 'Name',
                titleCode: 'NAME',
                field: 'subLeaseEntityDetails.entityName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                disableCondition: (values: any, index: any) =>
                    _.get(values, 'searchResponse.subLeaseDetails')
                        ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length
                        : _.get(values, `subLeaseDetailsdata[${index}].isSearched`)
                        ? false
                        : true
            },
            {
                title: 'Email ID',
                titleCode: 'EMAIL_ID',
                field: 'subLeaseEntityDetails.email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                disableCondition: (values: any, index: any) =>
                    _.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : !_.get(values, `subLeaseDetailsdata[${index}].isSearched`)
            },
            {
                title: 'Phone No',
                titleCode: 'MOB_NO',
                field: 'subLeaseEntityDetails.phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                disableCondition: (values: any, index: any) =>
                    _.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : !_.get(values, `subLeaseDetailsdata[${index}].isSearched`)

                // isRequired: true
            },
            {
                title: 'PAN No',
                titleCode: 'PAN_NO',
                field: 'subLeaseEntityDetails.panNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                upperCase: true,
                disableCondition: (values: any, index: any) =>
                    _.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : !_.get(values, `subLeaseDetailsdata[${index}].isSearched`)
            }
        ]
    }
];

export const subLesseeDetails = [
    {
        // titleCode: 'Sub-Lessee Details',
        // title: 'Sub-Lessee Details',
        formData: [
            {
                title: 'Applicant category',
                titleCode: 'PLEASE_SELECT_THE_RELEVANT_CATEGORY',
                field: 'subLeaseEntityDetails.category',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Letter stating the name of the Proprietorship firm',
                field: 'subLeaseEntityDetails.documents.entityProprietorshipFirm',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Proprietorship Firm',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseEntityDetails.category`).code === 'PROP_FIRM',
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Identity of the proprietor',
                field: 'subLeaseEntityDetails.documents.entityProprietorIdentity',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Proprietor Identity',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseEntityDetails.category`).code === 'PROP_FIRM',
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Trust Deed',
                field: 'subLeaseEntityDetails.documents.trustDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Trust Deed',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseEntityDetails.category`).code === 'TRUST',
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Partnership Deed',
                field: 'subLeaseEntityDetails.documents.partnershipDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Partnership Deed',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseEntityDetails.category`).code === 'PARTNERSHIP',
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Certificate of registration from the Registrar of Firms',
                field: 'subLeaseEntityDetails.documents.certificateOfRegistrationFirms',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Registration Certificate',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseEntityDetails.category`).code === 'PARTNERSHIP',
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Certificate of incorporation from Registrar of Companies',
                field: 'subLeaseEntityDetails.documents.certificateOfIncorporation',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Incorporation Certificate',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                renderCondition: (value: any, index: number) =>
                    _.get(value, `subLeaseDetailsdata.${index}.subLeaseEntityDetails.category`).code === 'LIMITED_LIABILITY_PARTNERSHIP' ||
                    _.get(value, `subLeaseDetailsdata.${index}.subLeaseEntityDetails.category`).code === 'LTD_COMPANY',
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Certificate of registration from the Registrar of Societies',
                field: 'subLeaseEntityDetails.documents.certificateOfRegistrationSocieties',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Registration Certificate-Co-Operative Society',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseEntityDetails.category`).code === 'COOP_SOCIETY',
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Copy of relevant notification',
                field: 'subLeaseEntityDetails.documents.copyRelevantNotification',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Government Notification',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseEntityDetails.category`).code === 'GOV_STAT_CORP',
                hideFileSize: true,
                isRequired: true
            }
        ]
    },
    {
        formData: [
            {
                title: 'PAN Card',
                titleCode: 'PAN_CARD',
                field: 'subLeaseEntityDetails.documents.panCard',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'PAN Card',
                    documentType: DOCUMENT_TYPE.PAN,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true,
                isRequired: true
            }
        ]
    },
    {
        titleCode: 'Address of the Sub-Lessee',
        title: 'Address of the Sub-Lessee',
        formData: [
            {
                titleCode: 'ADDRESS_LINE_1',
                title: 'Address Line 1',
                field: 'subLeaseEntityDetails.address.address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_LINE_2',
                title: 'Address Line 2',
                field: 'subLeaseEntityDetails.address.address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'PIN_CODE',
                title: 'Pin Code',
                field: 'subLeaseEntityDetails.address.pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                prefix: 'address.'
            },
            {
                titleCode: 'DISTRICT',
                title: 'District',
                field: 'subLeaseEntityDetails.address.district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'STATE',
                title: 'State',
                field: 'subLeaseEntityDetails.address.state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'COUNTRY',
                title: 'Country',
                field: 'subLeaseEntityDetails.address.country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            }
        ]
    },
    {
        titleCode: 'Mailing/Correspondence address of the Sub-Lessee',
        title: 'Mailing/Correspondence address of the Sub-Lessee',
        isSameAsAbove: true,
        isRequired: true,
        formData: [
            {
                titleCode: 'ADDRESS_LINE_1',
                title: 'Address Line 1',
                field: 'subLeaseEntityDetails.permanantAddress.peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ADDRESS_LINE_2',
                title: 'Address Line 2 ',
                field: 'subLeaseEntityDetails.permanantAddress.peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'PIN_CODE',
                title: 'Pin Code',
                field: 'subLeaseEntityDetails.permanantAddress.perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                prefix: 'permanantAddress.'
            },
            {
                titleCode: 'DISTRICT',
                title: 'District',
                field: 'subLeaseEntityDetails.permanantAddress.perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'STATE',
                title: 'State',
                field: 'subLeaseEntityDetails.permanantAddress.perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                titleCode: 'COUNTRY',
                title: 'Country',
                field: 'subLeaseEntityDetails.permanantAddress.percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            }
        ]
    },
    {
        titleCode: 'Details of Authorized Person',
        title: 'Details of Authorized Person',
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                titleCode: 'FIRST_NAME',
                title: 'First Name',
                field: 'subLeaseEntityDetails.user.firstName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'LAST_NAME',
                title: 'Last Name',
                field: 'subLeaseEntityDetails.user.lastName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'PRIMARY_MOBILE_NUMBER',
                title: 'Primary Mobile Number',
                field: 'subLeaseEntityDetails.user.primaryMobileNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ALTERNATIVE_MOBILE_NUMBER',
                title: 'Alternative Mobile Number',
                field: 'subLeaseEntityDetails.user.alternateMobileNo',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD
            },
            {
                titleCode: 'EMAIL_ID',
                title: 'Email ID',
                field: 'subLeaseEntityDetails.user.email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'LETTER_OF_AUTHORIZATION',
                title: 'Letter of Authorization',
                field: 'subLeaseEntityDetails.documents.authorizedPersonPowerOfAttorneyDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Letter of Authorization',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                },
                hideFileSize: true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>The letter / resolution / power of attorney authorising the authorised person to apply on behalf of the Applicant</div>'
                },
                isRequired: true
            }
        ]
    },
    {
        titleCode: 'OTHER_DETAILS',
        title: 'Other Details',
        formData: [
            {
                titleCode: 'UDYAM_REGISTERED_ENTERPRISE',
                title: 'Udyam Registered Enity',
                field: 'subLeaseOtherDetails.udhyamRegisteredEntity',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Enter Udyam Registration Number',
                titleCode: 'UDYAM_REGISTRATION_NUMBER',
                field: 'subLeaseOtherDetails.udyamNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseOtherDetails.udhyamRegisteredEntity`) === true,
                isRequired: true,
                class: 'col-sm-6 me-3',
                upperCase: true
                // conditionalClass: value => (_.get(value, `udhyam`) === true && _.get(value, `isStartup`) === false ? 'mb-4' : '')
            },
            {
                titleCode: 'UDYAM_REGISTRATION_CERTIFICATE',
                title: 'Please attach Udyam Registration Certificate',
                field: 'subLeaseEntityDetails.documents.udyamRegistrationCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'UDYAM Registration Certificate',
                    documentType: DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                hideFileSize: true,
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseOtherDetails.udhyamRegisteredEntity`) === true,
                hideTitle: true,
                isRequired: true
            }
        ]
    },
    {
        titleCode: 'Sub-Lease Details',
        title: 'Sub-Lease Details',
        formData: [
            {
                titleCode: 'Start Date',
                title: 'Start Date',
                field: 'subLeaseStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true
            },
            {
                titleCode: 'End Date',
                title: 'End Date',
                field: 'subLeaseEndDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true
            },
            {
                titleCode: 'DURATION_SUB_LEASE',
                title: 'Duration of Sub-Lease(Years)',
                field: 'durationSubLease',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'SUB_LEASE_AREA',
                title: 'Sub-Lease area(sqm)',
                field: 'subLeaseArea',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                // titleCode: 'SUB_LEASE_SPECIAL_PROJECT',
                title: 'Whether the Lessee is making a Sub-Lease of a Special Project allotted through Goa-IPB',
                field: 'subLeaseSpecialProject',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isDisabled: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-sm-6 me-3'
            },
            {
                titleCode: 'NOC from Goa-IPB incase of Special Project',
                title: 'NOC from Goa-IPB incase of Special Project',
                field: 'documents.inPrincipleApproval',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Special Project NOC',
                    documentType: DOCUMENT_TYPE.NOC_SPECIAL_PROJECT,
                    referenceType: ''
                },
                hideFileSize: true,
                renderCondition: (value: any, index: number) => _.get(value, `subLeaseDetailsdata.${index}.subLeaseSpecialProject`) === true,
                isRequired: true,
                class: 'col-sm-6 me-3',
                hideTitle: true
            },
            {
                title: 'Sub-Lease Order',
                field: 'subLeaseOrder',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Sub-Lease Order',
                    documentType: DOCUMENT_TYPE.SUB_LEASE_ORDER,
                    referenceType: ''
                },
                hideFileSize: true,
                isRequired: true
            }
        ]
    }
];

export const subLeaseEnterpriseDetails = [
    {
        titleCode: 'Enterprise Details',
        title: 'Enterprise Details',
        formData: [
            {
                title: 'Category of Enterprise',
                field: 'subLeaseEnterpriseDetails.enterpriseCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                title: 'Sub-category',
                field: 'subLeaseEnterpriseDetails.subCategory',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD,
                multiSelect: true,
                isRequired: true
            }
        ]
    }
];
export const subLeaseEnterpriseDetailsII = [
    {
        formData: [
            {
                title: 'Is GST Applicable?',
                field: 'subLeaseEnterpriseDetails.applicantGst',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                title: 'GST No',
                field: 'subLeaseEnterpriseDetails.applicantGstno',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                condition1: (values: any, ind: number) => _.get(values, `subLeaseDetailsdata.${ind}.subLeaseEnterpriseDetails.applicantGst`) === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Please provide GST specific to the mentioned business activities or trade.</p>'
                },
                upperCase: true
            },
            {
                title: 'GST Document',
                titleCode: 'GST Document',
                field: 'subLeaseEnterpriseDetails.documents.applicantGstdocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (values: any, ind: number) => _.get(values, `subLeaseDetailsdata.${ind}.subLeaseEnterpriseDetails.applicantGst`) === true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GST,
                    referenceType: DOCUMENT_TYPE.ENTERPRISE
                }
            },
            {
                title: 'GST Non-Applicability Undertaking',
                titleCode: 'GST_UNDERTAKING',
                field: 'subLeaseEnterpriseDetails.documents.entitygstUndertaking',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (values: any, ind: number) => _.get(values, `subLeaseDetailsdata.${ind}.subLeaseEnterpriseDetails.applicantGst`) === false,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.NON_GST,
                    referenceType: DOCUMENT_TYPE.ENTERPRISE
                }
            },
            {
                title: 'Pollution category',
                field: 'subLeaseEnterpriseDetails.pollutionCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated water requirement per month (in cubic meter)',
                field: 'subLeaseEnterpriseDetails.waterRequirement',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated power requirement per month (in KVA/HP)',
                field: 'subLeaseEnterpriseDetails.powerRequirement',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated number of persons proposed to be employed',
                field: 'subLeaseEnterpriseDetails.employement',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },

            {
                titleCode: 'Detailed project report',
                title: 'Detailed project report',
                field: 'subLeaseEnterpriseDetails.documents.detailedProjectReport',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Detailed Project Report',
                    documentType: DOCUMENT_TYPE.DETAILED_PROJECT_REPORT,
                    referenceType: ''
                },
                hideFileSize: true
            }
        ]
    }
];

export const ENTERPRISE_DETAILS = [
    {
        title: 'Enterprise Details',
        titleCode: 'Enterprise Details',
        isRequired: true,
        formData: [
            {
                title: 'Category of Enterprise',
                field: 'enterpriseCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isDisabled: true
            },
            {
                title: 'Sub-category',
                field: 'subCategory',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD,
                isRequired: true,
                multiSelect: true
            }
        ]
    }
];

export const ENTERPRISE_DETAILS_MIGRATION = [
    {
        formData: [
            {
                title: 'Whether applied under special project category',
                titleCode: 'APPLIED_UNDER_SPECIAL_PROJECT',
                field: 'specialProject',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            },
            {
                title: 'In-principle approval of Goa - IPB',
                titleCode: 'IN_PRINCIPLE_APPROVAL',
                field: 'enterpriseSpecialProjectCase',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3',
                renderCondition: (values: any) => _.get(values, `specialProject`) === true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.IN_PRINCIPAL_APPROVAL,
                    referenceType: DOCUMENT_TYPE.OTHER
                },
                hideFileSize: true
            },
            {
                title: 'Project Type',
                titleCode: 'PROJECT_TYPE',
                field: 'projectType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Is GST Applicable?',
                field: 'applicantGst',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                title: 'GST No',
                field: 'applicantGstno',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                condition1: (values: any) => _.get(values, `applicantGst`) === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Please provide GST specific to the mentioned business activities or trade.</p>'
                }
            },
            {
                title: 'GST Document',
                titleCode: 'GST Document',
                field: 'applicantGstdocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, `applicantGst`) === true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GST,
                    referenceType: DOCUMENT_TYPE.ENTERPRISE
                }
            },
            {
                title: 'GST Non-Applicability Undertaking',
                titleCode: 'GST_UNDERTAKING',
                field: 'entitygstUndertaking',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, `applicantGst`) === false,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.NON_GST,
                    referenceType: DOCUMENT_TYPE.ENTERPRISE
                }
            },
            {
                title: 'Pollution category',
                field: 'pollutionCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated water requirement per month (in cubic meter)',
                field: 'waterRequirement',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated power requirement per month (in KW/HP)',
                field: 'powerRequirement',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated Number of persons proposed to be employed ',
                field: 'employement',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Detailed project report',
                titleCode: 'Please attach the detailed project report',
                field: 'enterpriseProjectReport',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.DETAILED_PROJECT_REPORT,
                    referenceType: ''
                },
                hideFileSize: true
            }
        ]
    }
];

export const initialSubleaseWater = {
    waterType: {},
    meterNo: '',
    connectionDate: '',
    monthlyConsumption: '',
    connectionPipeDia: {},
    securityDeposit: '',
    subleaseWaterFeeDue: false,
    waterChargesExistingAmount: '',
    waterChargesPrincipalAmount: ''
};

export const initialSubLeaseDetailsData = {
    subLeaseEntityDetails: {
        entityName: '',
        category: '',
        subCategoryDetails: '',
        email: '',
        phoneNumber: null,
        panNumber: '',
        // gstApplicable: false,
        // gstNumber: '',
        panCard: null,
        permanantAddress: {
            peraddress1: '',
            peraddress2: '',
            perpincode: '',
            perdistrict: '',
            perstate: '',
            percountry: ''
        },
        address: {
            address1: '',
            address2: '',
            pincode: '',
            district: '',
            state: '',
            country: ''
        },
        user: {
            firstName: '',
            lastName: '',
            primaryMobileNo: '',
            alternateMobileNo: '',
            email: '',
            authorizedPersonPowerOfAttorneyDocument: null
        }
    },
    subLeaseOtherDetails: {
        udhyamRegisteredEntity: false,
        udyamRegistrationCertificate: null,
        udyamNo: ''
    },
    subLeaseDetails: {
        durationSubLease: '',
        subLeaseArea: '',
        subLeaseSpecialProject: ''
    },

    subLeaseEnterpriseDetails: {
        enterpriseCategory: '',
        subCategory: [],
        nationalIndustrialCode: '',
        pollutionCategory: '',
        waterRequirement: '',
        powerRequirement: '',
        employement: '',
        inPrincipleApproval: null,
        detailedProjectReport: null,
        applicantGst: false,
        applicantGstno: ''
        // applicantGstdocument: undefined,
        // entitygstUndertaking: undefined
    },
    paymentDetails: {
        principalAmount: '',
        cgstPrincipalAmount: '',
        sgstPrincipalAmount: '',
        penaltyAmount: '',
        cgstPenaltyAmount: '',
        sgstPenaltyAmount: '',
        arrearPrincipalAmount: '',
        cgstArrearPrincipalAmount: '',
        sgstArrearPrincipalAmount: '',
        arrearPenaltyAmount: '',
        cgstArrearPenaltyAmount: '',
        sgstArrearPenaltyAmount: '',
        totalDueAmount: '',
        dueDate: '',
        existingSubLeaseFee: '',
        nextDueDate: '',
        lastPaymentDate: '',
        uploadReceipt: ''
    },
    subleaseFeeDue: false,
    subleaseWater: false,
    subLeaseWaterDetails: [initialSubleaseWater]
};

export const LAND_PREMIUM_PAYMENT: FormDataDTO[] = [
    {
        title: ' Land Premium ',
        titleCode: 'LAND_PREMIUM',
        formData: [
            {
                title: 'Land Premium due',
                field: 'landPremiumDue',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-xs-12 col-md-12'
            }
        ]
    }
];

export const FULL_PAYMENT_DETAILS = [
    {
        formData: [
            {
                title: 'Total Land Premium amount (INR)',
                field: 'totalLandPremiumAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Last payment date',
                field: 'landPremiumLastPaymentdate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Payment receipt',
                titleCode: 'Payment receipt',
                field: 'landPremiumPaymentReceipt',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Land Premium payment receipt',
                    documentType: '',
                    referenceType: ''
                },
                class: 'col-sm-4',
                hideFileSize: true
            }
        ]
    }
];

export const DOWNPAYMENT_DETAILS = [
    {
        formData: [
            {
                title: 'Total Land Premium amount (INR)',
                field: 'totalLandPremiumAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Total paid Land Premium principal amount (INR)',
                field: 'totalPaidLandPremiumPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Balance amount (INR)',
                field: 'landPremiumBalanceAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Total number of installments',
                field: 'totalNumberOfInstallment',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Number of installments remaining',
                field: 'numberOfRemainingInstallment',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Land Premium interest rate before new Regulation (%)',
                field: 'previousInterestRate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Next installment date',
                field: 'nextInstallmentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                enableFutureDate: true
            }
        ]
    }
];

export const DOWNPAYMENT_DETAILS_II = [
    {
        formData: [
            {
                title: 'Default interest amount (INR)',
                field: 'landPremiumPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on default interest amount (INR)',
                field: 'cgstOnLandPremiumPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on default interest amount (INR)',
                field: 'sgstOnLandPremiumPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Arrear principal amount (INR)',
                field: 'landPremiumArrearPrincipleAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Arrear interest amount (INR)',
                field: 'landPremiumArrearInterestAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Arrear default interest amount (INR)',
                field: 'landPremiumArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on arrear default interest amount (INR)',
                field: 'cgstOnLandPremiumArrearPenalty',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on arrear default interest amount (INR)',
                field: 'sgstOnLandPremiumArrearPenalty',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Default interest amount + Arrear + GST Amount (INR)',
                field: 'sumPenaltyArrearGst',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            }
        ]
    }
];

export const PLOTACTIONTYPE = [
    {
        formData: [
            {
                title: 'Type',
                field: 'plotActionType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            }
        ]
    }
];

export const TYPEALLOTMENT = [
    {
        formData: [
            {
                title: 'Provisional Allotment Order number',
                field: 'provisionalAllotmentOrderNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Allotment type',
                field: 'allotmentType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Allotment date',
                field: 'allotmentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Is Lease Deed executed?',
                field: 'isLeaseDeedExecuted',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            },
            {
                title: 'Lease Deed execution date',
                field: 'leaseDeedExecutionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                renderCondition: (value: any) => _.get(value, `isLeaseDeedExecuted`) === true
            },
            {
                title: 'Lease Term start date',
                field: 'leaseTermStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Lease Term end date',
                field: 'leaseTermEndDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true,
                isRequired: true
            },
            {
                title: 'Is Lease Deed registered?',
                field: 'isLeaseDeedRegistered',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            },
            {
                title: 'Lease Deed registration date',
                field: 'leaseDeedRegistrationDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `isLeaseDeedRegistered`) === true
            },
            {
                title: 'Allotment Order',
                field: 'allotmentOrder',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ALLOTMENT_ORDER,
                    referenceType: DOCUMENT_TYPE.ALLOTMENT
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Executed Lease Deed',
                field: 'executedLeaseDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.EXECUTED_LD,
                    referenceType: ''
                },
                hideFileSize: true,
                renderCondition: (value: any) => _.get(value, `isLeaseDeedExecuted`) === true,
                isRequired: true
            },
            {
                title: 'Registered Lease Deed',
                field: 'registeredLeaseDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.REGISTERED_LD,
                    referenceType: ''
                },
                hideFileSize: true,
                renderCondition: (value: any) => _.get(value, `isLeaseDeedRegistered`) === true,
                isRequired: true
            },
            {
                title: 'Other document',
                field: 'otherDocumentAllotment',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER,
                    referenceType: ''
                },
                hideFileSize: true
            }
        ]
    }
];

export const TYPETRANSFER = [
    {
        formData: [
            {
                title: 'Transfer Order number',
                field: 'transferOrderNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Transfer date',
                field: 'transferDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Original Lease Term start date',
                field: 'originalLeasetermStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Is Tripartite Deed executed?',
                field: 'isTripartiteDeedExecuted',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            },
            {
                title: 'Tripartite Deed execution date',
                field: 'tripartiteDeedExecutionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                renderCondition: (value: any) => _.get(value, `isTripartiteDeedExecuted`) === true
            },
            {
                title: 'Lease Term start date',
                field: 'leaseTermStartDateTransfer',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Lease Term end date',
                field: 'leaseTermEndDateTransfer',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true,
                isRequired: true
            },
            {
                title: 'Is Tripartite Deed registered?',
                field: 'isTripartiteDeedRegistered',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            },
            {
                title: 'Tripartite Deed registration date',
                field: 'tripartiteDeedRegistrationDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `isTripartiteDeedRegistered`) === true
            },
            {
                title: 'Transfer Order',
                field: 'transferOrder',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.TRANSFER_ORDER,
                    referenceType: DOCUMENT_TYPE.TRANSFER
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Executed Tripartite Deed',
                field: 'executedTripartiteDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.EXECUTED_TD,
                    referenceType: ''
                },
                hideFileSize: true,
                renderCondition: (value: any) => _.get(value, `isTripartiteDeedExecuted`) === true,
                isRequired: true
            },
            {
                title: 'Registered Tripartite Deed',
                field: 'registeredTripartiteDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.REGISTERED_TD,
                    referenceType: ''
                },
                hideFileSize: true,
                renderCondition: (value: any) => _.get(value, `isTripartiteDeedRegistered`) === true,
                isRequired: true
            },
            {
                title: 'Other document',
                field: 'otherDocumentTransfer',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER,
                    referenceType: ''
                },
                hideFileSize: true
            }
        ]
    }
];

export const IS_EXTENSION = [
    {
        formData: [
            {
                title: 'Extension of Lease Term',
                field: 'isExtension',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            }
        ]
    }
];

export const TYPEEXTENSION = [
    {
        formData: [
            {
                title: 'Extension Order number',
                field: 'extensionOrderNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Extension date',
                field: 'extensionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Is Deed of Extension executed?',
                field: 'isDeedOfExtensionExecuted',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            },
            {
                title: 'Deed of Extension execution date',
                field: 'deedOfExtensionExecutionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                renderCondition: (value: any) => _.get(value, `isDeedOfExtensionExecuted`) === true
            },
            {
                title: 'Lease Term start date',
                field: 'leaseTermStartDateExtension',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Lease Term end date',
                field: 'leaseTermEndDateExtension',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true,
                isRequired: true
            },
            {
                title: 'Is Deed of Extension registered?',
                field: 'isDeedOfExtensionRegistered',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            },
            {
                title: 'Deed of Extension registration date',
                field: 'deedOfExtensionRegistrationDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `isDeedOfExtensionRegistered`) === true
            },
            {
                title: 'Extension Order',
                field: 'extensionOrder',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.EXTENSION_ORDER,
                    referenceType: DOCUMENT_TYPE.EXTENSION
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Executed Deed of Extension',
                field: 'executedDeedOfExtension',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.EXECUTED_EXT_LD,
                    referenceType: ''
                },
                hideFileSize: true,
                renderCondition: (value: any) => _.get(value, `isDeedOfExtensionExecuted`) === true,
                isRequired: true
            },
            {
                title: 'Registered Deed of Extension',
                field: 'registeredDeedOfExtension',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.REGISTERED_EXT_LD,
                    referenceType: ''
                },
                hideFileSize: true,
                renderCondition: (value: any) => _.get(value, `isDeedOfExtensionRegistered`) === true,
                isRequired: true
            },
            {
                title: 'Other extension related document',
                field: 'otherDocumentExtension',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.OTHER,
                    referenceType: ''
                },
                hideFileSize: true
            }
        ]
    }
];

export const MIGRATION_WATER_CONNCETION = [
    {
        formData: [
            {
                title: 'Water Connection',
                field: 'waterConnection',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            }
        ]
    }
];

export const WATER_CONNECTION_FIELDS = [
    {
        formData: [
            {
                title: 'Water Type',
                field: 'waterType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Water Connection No.',
                field: 'meterNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                disableCondition: (values: any, index: any) =>
                    _.get(values, 'searchResponse.otherDetails.waterConnectionMigrationDetailsList')
                        ? index + 1 > _.get(values, 'searchResponse.otherDetails.waterConnectionMigrationDetailsList')?.length
                        : true
            },
            {
                title: 'Connection Date',
                field: 'connectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Monthly Consumption (in cubic meter)',
                field: 'monthlyConsumption',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Connection Pipe dia (in mm)',
                field: 'connectionPipeDia',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Security Deposit (INR)',
                field: 'securityDeposit',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            }
        ]
    }
];

export const MIGRATION_SUBLEASE = [
    {
        formData: [
            {
                title: 'Sub-Lease',
                field: 'sublease',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            }
        ]
    }
];

export const MIGRATION_OTHERS_DETAILS = [
    {
        formData: [
            {
                title: 'Ongoing mortgage',
                field: 'mortgageStatus',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            }
        ]
    }
];

export const MORTGAGE_DETAILS = [
    {
        formData: [
            {
                title: 'Category of Mortgage',
                field: 'mortgageType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Pari-passu',
                field: 'isPariPassu',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isRequired: true
            },
            {
                title: 'Goa-IDC NOC Order No.',
                field: 'nocOrderNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                disableCondition: (values: any, index: any) =>
                    _.get(values, 'searchResponse.otherDetails.mortgageMigrationRequests') ? index + 1 > _.get(values, 'searchResponse.otherDetails.mortgageMigrationRequests')?.length : true
            },
            {
                title: 'Goa-IDC issued NOC Date',
                field: 'nocIssuedDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            }
        ]
    }
];

export const MORTGAGE_BANK_DETAILS = [
    {
        formData: [
            {
                title: 'IFSC Code',
                field: 'bankIFSC',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Bank Name',
                field: 'bankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const BANK_NOC_DOC = [
    {
        formData: [
            {
                title: 'NOC documents/Bank letters',
                field: 'nocDocReferenceId',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'NOC Documents',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true,
                isRequired: true
            }
        ]
    }
];

export const TECHNICAL_CLEARANCE_DETAILS = [
    {
        formData: [
            {
                title: 'Architect/Engineer Name',
                field: 'architectName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Architect/Engineer License No',
                field: 'architectLicenseNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false
            },

            {
                title: 'Technical Clearance Order No',
                field: 'orderNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                disableCondition: (values: any, index: any) =>
                    _.get(values, 'searchResponse.obpasDetails.technicalClearance') ? index + 1 > _.get(values, 'searchResponse.obpasDetails.technicalClearance')?.length : true
            },

            {
                title: 'Technical Clearance Order Date',
                field: 'orderDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Valid Upto',
                field: 'validUpto',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true
            },
            {
                title: 'Service Type',
                field: 'serviceType',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD,
                multiSelect: true,
                isRequired: true
            },
            {
                title: 'Certificate from the Architect/Engineer',
                field: 'documents.ArchitectureCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Architecture Certificate',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Structural Liability Certificate from the Engineer',
                field: 'documents.LiabilityCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Liability Certificate',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Building plan submitted',
                field: 'documents.DXForDWG',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'DXF/DWG',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Approved copy of Building Plan',
                field: 'documents.SubmissionPlan',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Submission Plan',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Questionnaire',
                field: 'documents.Questionnaire',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Questionnaire',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Affidavit From The Owner',
                field: 'documents.OwnerAffidavit',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Owner Affidavit',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Power of Attorney',
                field: 'documents.TCPowerofAttorney',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'PowerofAttorney',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Technical Clearance Order',
                field: 'documents.TCOrder',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Technical Clearance Order',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Other Documents',
                field: 'documents.TCOtherDoc',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Other Documents',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            }
        ]
    }
];

export const CONSTRUCTION_LICENSE_DETAILS = [
    {
        formData: [
            {
                title: 'Technical Clearance Order No',
                field: 'technicalClearanceOrderNumber',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Construction License Order No',
                field: 'orderNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                disableCondition: (values: any, index: any) =>
                    _.get(values, 'searchResponse.obpasDetails.constructionLicense') ? index + 1 > _.get(values, 'searchResponse.obpasDetails.constructionLicense')?.length : true
            },
            {
                title: 'Construction License Order Date',
                field: 'orderDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Valid Upto',
                field: 'validUpto',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true,
                isRequired: true
            },
            {
                title: 'Built-up Area (sqm)',
                field: 'builtUpArea',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Estimated Construction Cost (INR)',
                field: 'estimatedConstructionCost',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            // {
            //     title: 'Industry Classification',
            //     field: 'industryClassification',
            //     type: CONSTANT_DATA_FIELD.SELECT_FIELD
            // },
            {
                title: 'Construction License Order',
                field: 'documents.CLOrder',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Construction License Order',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Other Documents',
                field: 'documents.CLOtherDoc',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Other Documents',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            }
        ]
    }
];

export const CompletionOrder_OccypancyPercent = [
    {
        formData: [
            {
                title: 'Construction License Order No',
                field: 'constructionLicenseOrderNumber',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Built-up Area (sqm)',
                field: 'builtUpArea',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Order No',
                field: 'orderNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.obpasDetails.CLOC') ? index + 1 > _.get(values, 'searchResponse.obpasDetails.CLOC')?.length : true)
            },
            {
                title: 'Order Date',
                field: 'orderDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Valid Upto',
                field: 'validUpto',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true
            },
            {
                title: 'Completion Order/Occupancy Certificate',
                field: 'documents.CLOCorder',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'CL/OC order',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true,
                isRequired: true
            },
            {
                title: 'Completion Certificate issued by Architect/Engineer',
                field: 'documents.COCompletionCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Completion Certificate',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Revised plans incorporating deviations made during the execution',
                field: 'documents.CORevisedPlan',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Revised Plan',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Structural Stability Certificate',
                field: 'documents.COStabilityCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Stability Certificate',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Other Documents',
                field: 'documents.COOtherDoc',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Other Doc',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Fire NOC ',
                field: 'documents.fireDoc',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Fire NOC',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'Health NOC ',
                field: 'documents.healthDoc',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Health NOC',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            },
            {
                title: 'F&B NOC',
                field: 'documents.fnbDoc',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'F&B NOC',
                    documentType: '',
                    referenceType: ''
                },
                hideFileSize: true
            }
        ]
    }
];

export const paymentDetails = [
    {
        formData: [
            {
                title: 'Processing Fee Paid',
                field: 'processingFeePaymentStatus',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isDisabled: true
            },
            {
                title: 'Security Deposit Paid',
                field: 'securityDepositPaymentStatus',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                isDisabled: true
            }
        ]
    }
];

export const LEASE_RENT_PAYMENT = [
    {
        titleCode: 'Lease Rent',
        title: 'Lease Rent',
        formData: [
            {
                title: 'Lease Rent due',
                field: 'leaseRentPaymentStatus',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            }
        ]
    }
];

export const LEASE_RENT_PAID = [
    {
        formData: [
            {
                title: 'Existing Lease Rent amount (INR)',
                field: 'existingLeaseRentAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Next due date',
                field: 'LeaseRentNextDueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                enableFutureDate: true
            },
            {
                title: 'Next increment date',
                field: 'leaseRentIncrementDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                enableFutureDate: true
            },
            {
                title: 'Last payment date',
                field: 'leaseRentLastPaymentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Payment receipt',
                field: 'leaseRentPaymentReceipt',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Lease Rent Payment Receipt',
                    documentType: '',
                    referenceType: ''
                },
                class: 'col-sm-4',
                hideFileSize: true
            }
        ]
    }
];

export const LEASE_RENT_NOT_PAID = [
    {
        formData: [
            {
                title: 'Principal amount (INR)',
                field: 'leaseRentPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on principal amount (INR)',
                field: 'cgstOnLeaseRentPrincipleAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on principal amount (INR)',
                field: 'sgstOnLeaseRentPrincipleAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Default interest amount (INR)',
                field: 'leaseRentPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on default interest amount (INR)',
                field: 'cgstOnLeaseRentPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on default interest amount (INR)',
                field: 'sgstOnLeaseRentPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Arrear principal amount (INR)',
                field: 'leaseRentArrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on arrear principal amount (INR)',
                field: 'cgstOnLeaseRentArrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on arrear principal amount (INR)',
                field: 'sgstOnLeaseRentArrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Arrear default interest amount (INR)',
                field: 'leaseRentArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on arrear default interest amount (INR)',
                field: 'cgstOnLeaseRentArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on arrear default interest amount (INR)',
                field: 'sgstOnLeaseRentArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Total due amount (INR)',
                field: 'leaseRentTotalDueAmmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Due Date',
                field: 'leaseRentDueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true,
                class: 'col-sm-4'
            },
            {
                title: 'Next increment date',
                field: 'leaseRentIncrementDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true,
                class: 'col-sm-4'
            },
            {
                title: 'Pendency report',
                field: 'pendencyReport',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Pendency report',
                    documentType: '',
                    referenceType: ''
                },
                class: 'col-sm-4',
                hideFileSize: true
            }
        ]
    }
];

export const HOUSE_TAX_APPLICABLE = [
    {
        titleCode: 'House Tax',
        title: 'House Tax',
        formData: [
            {
                title: 'House Tax Applicable',
                field: 'houseTaxApplicable',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            }
        ]
    }
];

export const HOUSE_TAX_PAYMENT = [
    {
        formData: [
            {
                title: 'House Tax due',
                field: 'houseTaxPaymentStatus',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            }
        ]
    }
];

export const HOUSE_TAX_PAID = [
    {
        formData: [
            {
                title: 'Existing House Tax amount (INR)',
                field: 'existingHouseTaxAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Next due date',
                field: 'houseTaxNextDueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                enableFutureDate: true
            },
            {
                title: 'Last payment date',
                field: 'houseTaxLastPaymentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Payment receipt',
                field: 'houseTaxPaymentReceipt',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'House Payment Receipt',
                    documentType: '',
                    referenceType: ''
                },
                class: 'col-sm-4',
                hideFileSize: true
            }
        ]
    }
];

export const HOUSE_TAX_NOT_PAID = [
    {
        formData: [
            {
                title: 'Due Date',
                field: 'houseTaxDueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true,
                class: 'col-sm-4'
            },
            {
                title: 'Principal amount (INR)',
                field: 'houseTaxPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Default interest amount (INR)',
                field: 'houseTaxPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on default interest amount (INR)',
                field: 'cgstOnHouseTaxPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on default interest amount (INR)',
                field: 'sgstOnHouseTaxPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Arrear principal amount (INR)',
                field: 'houseTaxArrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Arrear default interest amount (INR)',
                field: 'houseTaxArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on arrear default interest amount (INR)',
                field: 'cgstOnHouseTaxArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on arrear default interest amount (INR)',
                field: 'sgstOnHouseTaxArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Total due amount (INR)',
                field: 'houseTaxTotalDueAmmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            }
        ]
    }
];

export const WATER_CHARGES_PAYMENT = [
    {
        titleCode: 'Water Charges',
        title: 'Water Charges',
        formData: [
            {
                title: 'Water Charges due',
                field: 'waterChargesPaymentStatus',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            }
        ]
    }
];

export const WATER_CHARGES_PAID = [
    {
        formData: [
            {
                title: 'Meter No.',
                field: 'waterChargeMeterNo',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Existing water charges amount (INR)',
                field: 'waterChargesExistingAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Next due date',
                field: 'waterChargesNextDueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                enableFutureDate: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Last payment date',
                field: 'waterChargesLastPaymentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Last meter reading',
                field: 'waterChargesLastMeterReading',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Last meter reading Date',
                field: 'waterChargeslastMeterReadingDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Payment receipt',
                field: 'waterChargesPaymentReceipt',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Water Charges payment receipt',
                    documentType: '',
                    referenceType: ''
                },
                class: 'col-sm-4',
                hideFileSize: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            }
        ]
    }
];

export const WATER_CHARGES_NOT_PAID = [
    {
        formData: [
            {
                title: 'Meter No.',
                field: 'waterChargeMeterNo',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Principal amount (INR)',
                field: 'waterChargesPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Default interest amount (INR)',
                field: 'waterChargesPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'CGST on default interest amount (INR)',
                field: 'cgstOnWaterChargesPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'SGST on default interest amount (INR)',
                field: 'sgstOnWaterChargesPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Arrear principal amount (INR)',
                field: 'waterChargesArrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Arrear default interest amount (INR)',
                field: 'waterChargesArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'CGST on arrear default interest amount (INR)',
                field: 'cgstOnWaterChargesArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'SGST on arrear default interest amount (INR)',
                field: 'sgstOnWaterChargesArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Total due amount (INR)',
                field: 'waterChargesTotalDueAmmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Due date',
                field: 'waterChargesDueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Last meter reading',
                field: 'waterChargesLastMeterReading',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Last meter reading Date',
                field: 'waterChargeslastMeterReadingDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            }
        ]
    }
];

export const SUBLEASE_WATER_CHARGES_PAID = [
    {
        formData: [...WATER_CHARGES_PAID[0].formData.slice(1)]
    }
];

export const SUBLEASE_WATER_CHARGES_NOT_PAID = [
    {
        formData: [...WATER_CHARGES_NOT_PAID[0].formData.slice(1)]
    }
];

export const SUB_LEASE_FEE_DUE = [
    {
        title: 'Sub-Lease Fee',
        titleCode: 'Sub-Lease Fee',
        formData: [
            {
                title: 'Sub-Lease Fee due',
                field: 'subleaseFeeDue',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            }
        ]
    }
];

export const SUB_LEASE_WATER = [
    {
        title: 'WATER_CONNECTION',
        titleCode: 'Water Connection',
        formData: [
            {
                title: 'WATER_CONNECTION',
                titleCode: 'Water Connection',
                field: 'subleaseWater',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            }
        ]
    }
];

export const SUB_LEASE_WATER_FIELDS = [
    {
        formData: [
            {
                title: 'Water Type',
                field: 'waterType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Water Connection No.',
                field: 'meterNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Connection Date',
                field: 'connectionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true
            },
            {
                title: 'Monthly Consumption (in cubic meter)',
                field: 'monthlyConsumption',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Connection Pipe dia (in mm)',
                field: 'connectionPipeDia',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Security Deposit (INR)',
                field: 'securityDeposit',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'WATER_CONNECTION_DUE',
                titleCode: 'Water Charges Due',
                field: 'subleaseWaterFeeDue',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            }
        ]
    }
];

export const SUB_LEASE_DUE_TRUE = [
    {
        formData: [
            {
                title: 'Principal amount (INR)',
                field: 'paymentDetails.principalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4',
                isRequired: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'CGST on principle amount (INR)',
                field: 'paymentDetails.cgstPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'SGST on principle amount (INR)',
                field: 'paymentDetails.sgstPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Default interest amount (INR)',
                field: 'paymentDetails.penaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4',
                isRequired: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'CGST on default interest amount (INR)',
                field: 'paymentDetails.cgstPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'SGST on default interest amount (INR)',
                field: 'paymentDetails.sgstPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Arrear principal amount (INR)',
                field: 'paymentDetails.arrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'CGST on arrear principal amount (INR)',
                field: 'paymentDetails.cgstArrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'SGST on arrear principal amount (INR)',
                field: 'paymentDetails.sgstArrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Arrear default interest amount (INR)',
                field: 'paymentDetails.arrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'CGST on arrear default interest amount ((INR)',
                field: 'paymentDetails.cgstArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },

            {
                title: 'SGST on arrear default interest amount (INR)',
                field: 'paymentDetails.sgstArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Total due amount (INR)',
                field: 'paymentDetails.totalDueAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Due date',
                field: 'paymentDetails.dueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-4',
                enableFutureDate: true,
                isRequired: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            }
        ]
    }
];
export const SUB_LEASE_DUE_FALSE = [
    {
        formData: [
            {
                title: 'Existing Sub-Lease Fee (INR)',
                field: 'paymentDetails.existingSubLeaseFee',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4',
                isRequired: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Next due date	',
                field: 'paymentDetails.nextDueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-4',
                enableFutureDate: true,
                isRequired: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Last payment date',
                field: 'paymentDetails.lastPaymentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-4',
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            },
            {
                title: 'Upload receipt',
                field: 'paymentDetails.uploadReceipt',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Sub-Lease fee receipt',
                    documentType: '',
                    referenceType: ''
                },
                class: 'col-sm-4',
                hideFileSize: true,
                disableCondition: (values: any, index: any) => (_.get(values, 'searchResponse.subLeaseDetails') ? index + 1 > _.get(values, 'searchResponse.subLeaseDetails')?.length : true)
            }
        ]
    }
];

export const INSTALLMENT_SCHEDULE_TABLE = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '100px' },
        id: 1
    },
    {
        colName: 'Due date',
        fieldName: 'dueDateddMMyyyy',
        cellstyle: { width: '200px' },
        id: 2
    },
    {
        colName: 'Principal amount',
        fieldName: 'principalAmount',
        cellstyle: { width: '200px' },
        id: 3
    },
    {
        colName: 'Interest',
        fieldName: 'interestAmount',
        cellstyle: { width: '200px' },
        id: 4
    },
    {
        colName: 'Default interest amount + Arrear + GST Amount',
        fieldName: 'installmentPenaltyArrearGst',
        cellstyle: { width: '200px' },
        id: 5
    },
    {
        colName: 'Total installment amount',
        fieldName: 'installmentTotalAmount',
        cellstyle: { width: '200px' },
        id: 6
    }
];

export const landPremium = {
    dueDate: '',
    amount: '',
    interest: '',
    penalty: '',
    totalAmount: '',
    paymentDate: '',
    invoiceDocReferenceId: ''
};

export const PUSH_CLOC = {
    applicationDate: null,
    occupancyPercent: null,
    orderDate: null,
    orderNo: null,
    validUpto: null,
    constructionLicenseOrderNumber: null
};

export const mortgageMigrationRequest = {
    uuid: null,
    mortgageType: {},
    isPariPassu: false,
    nocOrderNumber: '',
    nocIssuedDate: '',
    bankMortgageMigrationRequests: [
        {
            uuid: null,
            bankIFSC: '',
            bankName: ''
        }
    ],
    nocDocReferenceId: ''
};
export const migrationWaterConnection = {
    uuid: null,
    waterType: {},
    meterNo: '',
    connectionDate: '',
    monthlyConsumption: '',
    connectionPipeDia: {},
    securityDeposit: ''
};
export const WATER_CHARGE = {
    waterChargeMeterNo: '',
    waterChargesExistingAmount: '',
    waterChargesNextDueDate: '',
    waterChargesLastPaymentDate: '',
    waterChargesLastMeterReading: '',
    waterChargeslastMeterReadingDate: '',
    waterChargesPaymentReceipt: '',
    waterChargesDueDate: ''
};
export const WATER_CHARGE_NOT_PAID = {
    waterChargeMeterNo: '',
    waterChargesPrincipalAmount: '',
    waterChargesPenaltyAmount: '',
    cgstOnWaterChargesPenaltyAmount: '',
    sgstOnWaterChargesPenaltyAmount: '',
    waterChargesArrearPrincipalAmount: '',
    waterChargesArrearPenaltyAmount: '',
    cgstOnWaterChargesArrearPenaltyAmount: '',
    sgstOnWaterChargesArrearPenaltyAmount: '',
    waterChargesTotalDueAmmount: '',
    waterChargesDueDate: '',
    waterChargesLastMeterReading: '',
    waterChargeslastMeterReadingDate: ''
};

export const TECHNICAL_CLEARANCE = {
    applicationDate: null,
    architectLicenseNo: null,
    architectName: null,
    orderDate: null,
    orderNo: null,
    serviceType: null,
    validUpto: null
};

export const CONSTRUCTION_LICENSE = {
    applicationDate: null,
    orderDate: null,
    orderNo: null,
    validUpto: null,
    technicalClearanceOrderNumber: null,
    builtUpArea: null,
    estimatedConstructionCost: null,
    industryClassification: null
};

export const SUB_LEASE_FEE_PAYMENT = {
    subleaseFeeDue: false,
    entityName: null,
    paymentType: 'SUBLEASE_FEE',
    principalAmount: '',
    cgstPrincipalAmount: '',
    sgstPrincipalAmount: '',
    penaltyAmount: '',
    cgstPenaltyAmount: '',
    sgstPenaltyAmount: '',
    arrearPrincipalAmount: '',
    cgstArrearPrincipalAmount: '',
    sgstArrearPrincipalAmount: '',
    arrearPenaltyAmount: '',
    cgstArrearPenaltyAmount: '',
    sgstArrearPenaltyAmount: '',
    totalDueAmount: '',
    dueDate: '',
    existingSubLeaseFee: '',
    nextDueDate: '',
    lastPaymentDate: ''
};

export const initialValues = {
    isSamePlotApplicationExist: false,
    isUpdatable: true,
    processingFeePaymentStatus: true,
    securityDepositPaymentStatus: true,
    mortgageStatus: false,
    ownership: [],
    subLeaseDetailsdata: [initialSubLeaseDetailsData],
    landPremiumDue: false,
    waterChargesPaymentStatus: false,
    houseTaxPaymentStatus: false,
    leaseRentPaymentStatus: false,
    priorExperience: false,
    mortgageDetails: [
        {
            nocDate: '',
            uploadBankNocDocument: ''
        }
    ],
    individualOrOrganization: 'Individual',
    applicantGst: false,
    udhyam: false,
    dpiit: false,
    sublease: false,
    isExtension: false,
    mortgageMigrationRequests: [mortgageMigrationRequest],
    migrationWaterConnection: [migrationWaterConnection],
    technicalClearance: [TECHNICAL_CLEARANCE],
    technicalClearanceOrder: false,
    constructionLicense: [CONSTRUCTION_LICENSE],
    constructionLicenseOrder: false,
    migrationWaterCharge: [WATER_CHARGE],
    cooc: [PUSH_CLOC],
    coocOrder: false,
    isDeedOfExtensionExecuted: false,
    isDeedOfExtensionRegistered: false,
    isLeaseDeedExecuted: false,
    isLeaseDeedRegistered: false,
    isTripartiteDeedExecuted: false,
    isTripartiteDeedRegistered: false,
    subleaseFeeDue: false,
    landPremiumLastPaymentdate: '',
    totalLandPremiumAmount: '',
    totalPaidLandPremiumPrincipalAmount: '',
    landPremiumBalanceAmount: '',
    totalNumberOfInstallment: '',
    numberOfRemainingInstallment: '',
    previousInterestRate: '',
    nextInstallmentDate: '',
    landPremiumPenaltyAmount: '',
    cgstOnLandPremiumPenaltyAmount: '',
    sgstOnLandPremiumPenaltyAmount: '',
    landPremiumArrearPrincipleAmount: '',
    landPremiumArrearInterestAmount: '',
    landPremiumArrearPenaltyAmount: '',
    cgstOnLandPremiumArrearPenalty: '',
    sgstOnLandPremiumArrearPenalty: '',
    sumPenaltyArrearGst: '',
    existingLeaseRentAmount: '',
    LeaseRentNextDueDate: '',
    leaseRentIncrementDate: '',
    leaseRentLastPaymentDate: '',
    leaseRentPrincipalAmount: '',
    cgstOnLeaseRentPrincipleAmount: '',
    sgstOnLeaseRentPrincipleAmount: '',
    leaseRentPenaltyAmount: '',
    cgstOnLeaseRentPenaltyAmount: '',
    sgstOnLeaseRentPenaltyAmount: '',
    leaseRentArrearPrincipalAmount: '',
    cgstOnLeaseRentArrearPrincipalAmount: '',
    sgstOnLeaseRentArrearPrincipalAmount: '',
    leaseRentArrearPenaltyAmount: '',
    cgstOnLeaseRentArrearPenaltyAmount: '',
    sgstOnLeaseRentArrearPenaltyAmount: '',
    leaseRentTotalDueAmmount: '',
    leaseRentDueDate: '',
    existingHouseTaxAmount: '',
    houseTaxNextDueDate: '',
    houseTaxLastPaymentDate: '',
    houseTaxDueDate: '',
    houseTaxPrincipalAmount: '',
    houseTaxPenaltyAmount: '',
    cgstOnHouseTaxPenaltyAmount: '',
    sgstOnHouseTaxPenaltyAmount: '',
    houseTaxArrearPrincipalAmount: '',
    houseTaxArrearPenaltyAmount: '',
    cgstOnHouseTaxArrearPenaltyAmount: '',
    sgstOnHouseTaxArrearPenaltyAmount: '',
    houseTaxTotalDueAmmount: '',
    waterChargesExistingAmount: '',
    waterChargesNextDueDate: '',
    waterChargesLastPaymentDate: '',
    waterChargesLastMeterReading: '',
    waterChargesPrincipalAmount: '',
    waterChargesPenaltyAmount: '',
    cgstOnWaterChargesPenaltyAmount: '',
    sgstOnWaterChargesPenaltyAmount: '',
    waterChargesArrearPrincipalAmount: '',
    waterChargesArrearPenaltyAmount: '',
    cgstOnWaterChargesArrearPenaltyAmount: '',
    sgstOnWaterChargesArrearPenaltyAmount: '',
    waterChargesTotalDueAmmount: '',
    waterChargesDueDate: '',
    isLocalPerson: false,
    subLeaseFeePayment: [SUB_LEASE_FEE_PAYMENT],
    isMigration: true,
    waterConnection: false,
    isStartup: false,
    specialProject: false,
    houseTaxApplicable: false
};

export const SUB_LEASE_PAYMENT_DUE_TRUE = [
    {
        formData: [
            {
                title: 'Principal amount (INR)',
                field: 'principalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on principle amount (INR)',
                field: 'cgstPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on principle amount (INR)',
                field: 'sgstPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Default interest amount (INR)',
                field: 'penaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on default interest amount (INR)',
                field: 'cgstPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on default interest amount (INR)',
                field: 'sgstPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Arrear principal amount (INR)',
                field: 'arrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on arrear principal amount (INR)',
                field: 'cgstArrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'SGST on arrear principal amount (INR)',
                field: 'sgstArrearPrincipalAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Arrear default interest amount (INR)',
                field: 'arrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'CGST on arrear default interest amount ((INR)',
                field: 'cgstArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },

            {
                title: 'SGST on arrear default interest amount (INR)',
                field: 'sgstArrearPenaltyAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Total due amount (INR)',
                field: 'totalDueAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Due date',
                field: 'dueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                enableFutureDate: true
            }
        ]
    }
];
export const SUB_LEASE_PAYMENT_DUE_FALSE = [
    {
        formData: [
            {
                title: 'Existing Sub-Lease Fee (INR)',
                field: 'existingSubLeaseFee',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-4'
            },
            {
                title: 'Next due date	',
                field: 'nextDueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                class: 'col-sm-4',
                enableFutureDate: true
            },
            {
                title: 'Last payment date',
                field: 'lastPaymentDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Upload receipt',
                field: 'uploadReceipt',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Sub-Lease fee receipt',
                    documentType: '',
                    referenceType: ''
                },
                class: 'col-sm-4',
                hideFileSize: true
            }
        ]
    }
];

export const DOCUMENT_TYPES_MGR = {
    USER: {
        ARCHITECT: 'ARCHITECT',
        ENTERPRISE: 'ENTERPRISE',
        AREAMANAGER: 'AREAMANAGER'
    },
    SCRUTINY_DOCUMENTS: {
        BUILDINGPLAN: 'BUILDINGPLAN',
        CERTIFICATE_ARCHITECTURE: 'CERTIFICATE_ARCHITECTURE',
        CERTIFICATE_LIABILITY: 'CERTIFICATE_LIABILITY',
        SITEPLAN: 'SITEPLAN',
        DOCUMENT_QUESTIONNAIRE: 'DOCUMENT_QUESTIONNAIRE',
        DOCUMENT_AFFIDAVIT: 'DOCUMENT_AFFIDAVIT',
        DOCUMENT_POWER_ATTORNEY: 'DOCUMENT_POWER_ATTORNEY'
    },
    TECHNICAL_CLEARANCE_DOCUMENTS: {
        CERTIFICATE_ARCHITECTURE: 'CERTIFICATE_ARCHITECTURE',
        CERTIFICATE_LIABILITY: 'CERTIFICATE_LIABILITY',
        SITEPLAN: 'SITEPLAN',
        DOCUMENT_QUESTIONNAIRE: 'DOCUMENT_QUESTIONNAIRE',
        DOCUMENT_AFFIDAVIT: 'DOCUMENT_AFFIDAVIT',
        DOCUMENT_POWER_ATTORNEY: 'DOCUMENT_POWER_ATTORNEY'
    },
    ORDER_COMPLETION_DOCUMENTS: {
        CONSTRUCTION_LICENSE_ORDER: 'CONSTRUCTION_LICENSE_ORDER',
        TECHNICAL_CLEARANCE_ORDER: 'TECHNICAL_CLEARANCE_ORDER',
        COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER: 'COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER',
        REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION: 'REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION',
        STRUCTURAL_STABILITY_CERTIFICATE: 'STRUCTURAL_STABILITY_CERTIFICATE'
    },
    ORDER_COMPLETION_NOC_DOCUMENTS: {
        FIRE_NOC: 'FIRE_NOC',
        FACTORIES_AND_BOILERS_NOC: 'FACTORIES_AND_BOILERS_NOC'
    }
};

export const MIGRATION_SEARCH = [
    {
        formData: [
            {
                title: 'Name',
                titleCode: 'NAME',
                field: 'entityName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Email',
                titleCode: 'EMAIL',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'PAN Number',
                titleCode: 'PAN_NO',
                field: 'entitypanNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4'
            }
        ]
    }
];

export const MIGRATION_DATA_EDIT = [
    {
        colName: 'SN',
        titleCode: 'SERIAL_NO',
        fieldName: 'serialNo',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Lessee',
        titleCode: 'LESSEE',
        fieldName: 'lessee',
        clsName: 'col-sm-2 column-text-start',
        id: 2
    },
    {
        colName: 'Application No',
        titleCode: 'APPLICATION_NO',
        fieldName: 'applicationNumber',
        clsName: 'col-sm-2 column-text-start',
        id: 3
    },
    {
        colName: 'Estate',
        titleCode: 'ESTATE',
        fieldName: 'estateName',
        clsName: 'col-sm-2',
        id: 4
    },
    {
        colName: 'Phase',
        titleCode: 'PHASE',
        fieldName: 'phase',
        clsName: 'col-sm-2',
        id: 5
    },
    {
        colName: 'Plot No',
        titleCode: 'PLOT_NO',
        fieldName: 'plotNo',
        clsName: 'col-sm-2',
        id: 6
    },
    {
        colName: 'Action',
        titleCode: 'ACTION',
        fieldName: 'action',
        clsName: 'col-sm-1',
        actions: ['view', 'edit'],
        id: 7
    }
];
