import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { getFormattedReclaimableTable, MOP_CLAIMABLE_POPUP, MOP_CLAIMABLE_TABLE_COLUMN, MOP_FIELDS, MOP_TAX_POPUP, TAX_TABLE_COLUMN, taxTypes } from './RaBill.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import { useTranslation } from 'react-i18next';
import { convertToINR, strToNum } from '../../EstateManagement/EstateManagement.controller';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

export const MOPSection = (props: any) => {
    const { dropdownData, reclaimableRowData, setReclaimableRowData, taxRowData, setTaxRowData, previousReclaimableRowData, resData, isViewOnly } = props;
    const { values, setValues, setTouched, touched, setFieldTouched, errors, setFieldValue } = useFormikContext<any>();
    const { t } = useTranslation();

    const [showReclaimableModal, setShowReclaimableModal] = useState(false);
    const [openIndex, setOpenIndex] = useState(null);
    const [isEditing, setIsEditing] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [showTaxModal, setShowTaxModal] = useState(false);
    const [claimablePopupFields, setClaimablePopupFields] = useState<any>(MOP_CLAIMABLE_POPUP);
    const [taxPopupFields, setTaxPopupFields] = useState<any>(MOP_TAX_POPUP);

    const UPDATED_MOP_CLAIMABLE_TABLE_COLUMN = MOP_CLAIMABLE_TABLE_COLUMN.map((val: any) => {
        if (val?.type === 'action') {
            return {
                ...val,
                actions: values.isUpdatable ? ['edit', 'view'] : ['view']
            };
        }
        return val;
    });

    const UPDATED_TAX_TABLE_COLUMN = TAX_TABLE_COLUMN.map((val: any) => {
        if (val?.type === 'action') {
            return {
                ...val,
                actions: values.isUpdatable ? ['edit', 'view'] : ['view']
            };
        }
        return val;
    });

    useEffect(() => {
        const newFields = disabled
            ? claimablePopupFields.map((item: any) => ({ ...item, formData: item.formData.map((subItem: any) => ({ ...subItem, isDisabled: disabled })) }))
            : MOP_CLAIMABLE_POPUP;
        setClaimablePopupFields(newFields);

        const newTaxFields = disabled ? taxPopupFields.map((item: any) => ({ ...item, formData: item.formData.map((subItem: any) => ({ ...subItem, isDisabled: disabled })) })) : MOP_TAX_POPUP;
        setTaxPopupFields(newTaxFields);
    }, [disabled]);

    useEffect(() => {
        if (resData.billDeductionDetails.length) {
            const taxData = resData.billDeductionDetails.filter((item: any) => !item.isReclaimable);
            const taxRows = getFormattedReclaimableTable(taxData, taxTypes);
            setTaxRowData(taxRows);
        } else generateTaxRowData();
    }, []);

    const classnames = 'font-14 lh-21 fw-500 text-dark-emphasis mb-1';
    const calculatedReclaimableAmount = [
        {
            title: 'Total Reclaimable Deduction Amount Since Previous Bill',
            total: values.totalReclaimableDeductionAmount,
            class: classnames
        }
    ];

    const calculatedNonReclaimableAmount = [
        {
            title: 'Passed Bill Amount(A)',
            total: values.passedBillAmount,
            class: classnames
        },
        {
            title: 'Total Non Reclaimable Deduction Amount(B)',
            total: values.totalNonReclaimableDeductionAmount,
            class: classnames
        },
        {
            title: 'Total Reclaimable Deduction Amount(C)',
            total: values.totalReclaimableDeductionAmount,
            class: classnames
        },
        {
            title: 'Total Deduction Amount (D=B+C)',
            total: +values.totalNonReclaimableDeductionAmount + +values.totalReclaimableDeductionAmount,
            class: classnames
        },
        {
            title: 'Net Payable Amount(E=A-D)',
            total: values.totalUpToDatePaymentSinceLastBill,
            class: classnames
        }
        // {
        //     title: 'Net Payable Amount (in words)',
        //     total: strToNum('').toFixed(2),
        //     class: classnames
        // }
    ];

    const handleOnAdd = () => {
        const val = _.cloneDeep(values);
        setIsEditing('');
        setValues({ ...val, reclaimable: {} });
        setTouched({});
        setShowReclaimableModal(true);
        setDisabled(false);
        setOpenIndex(null);
    };

    const onRowEdit = async (data: any, isViewOnly = false) => {
        const detailObj: any = reclaimableRowData.find((item: any, _: number) => item.uuid === data.uuid || item.rowId === data.rowId);
        const index: any = reclaimableRowData.findIndex((item: any, _: number) => item.uuid === data.uuid || item.rowId === data.rowId);
        setOpenIndex(index > -1 ? index + 1 : null);
        if (!detailObj) return;
        const formData = {
            ...detailObj
        };
        setIsEditing(detailObj.uuid);
        setValues({ ...values, reclaimable: formData });
        setShowReclaimableModal(true);
        setDisabled(isViewOnly);
    };

    const onRowDelete = (rowId: string) => {
        let newData: any = reclaimableRowData?.map((data: any) => {
            if (data.uuid === rowId) {
                data['isDelete'] = true;
            }
            return data;
        });
        setReclaimableRowData(newData);
        setShowReclaimableModal(false);
        setIsEditing('');
    };

    const onRowView = (isViewOnly: boolean, data: any) => {
        setDisabled(isViewOnly);
        onRowEdit(data, isViewOnly);
    };

    const handleOnSave = async () => {
        const newUuid = uuidv4();
        const rowData = { ...values.reclaimable, uuid: values.reclaimable.uuid || newUuid, rowId: values.reclaimable.uuid || newUuid };

        if (isEditing) {
            const updatedData: any = reclaimableRowData.map((data: any) => {
                if (data.rowId === isEditing) {
                    return rowData;
                } else return data;
            });
            setReclaimableRowData([...updatedData]);
        } else {
            setReclaimableRowData([...reclaimableRowData, rowData]);
        }
        setShowReclaimableModal(false);
    };

    const onTaxEdit = async (data: any, isViewOnly = false) => {
        const detailObj: any = taxRowData.find((item: any, _: number) => item.rowId === data.rowId);
        const index: any = taxRowData.findIndex((item: any, _: number) => item.rowId === data.rowId);
        setOpenIndex(index > -1 ? index + 1 : null);
        if (!detailObj) return;
        const formData = {
            ...detailObj
        };
        setIsEditing(detailObj.rowId);
        setValues({ ...values, tax: formData });
        setShowTaxModal(true);
        setDisabled(isViewOnly);
    };

    const onTaxDelete = (rowId: string) => {
        let newData: any = taxRowData?.map((data: any) => {
            if (data.uuid === rowId || data.rowId === rowId) {
                data['isDelete'] = true;
            }
            return data;
        });
        setTaxRowData(newData);
        setShowTaxModal(false);
        setIsEditing('');
    };

    const onTaxView = (isViewOnly: boolean, data: any) => {
        setDisabled(isViewOnly);
        onRowEdit(data, isViewOnly);
    };

    const handleOnTaxSave = async () => {
        const newUuid = values.tax.rowId || values.tax.uuid || uuidv4();
        const rowData = { ...values.tax, uuid: newUuid, rowId: newUuid };

        if (isEditing) {
            const updatedData: any = taxRowData.map((data: any) => {
                if (data.rowId === isEditing) {
                    return rowData;
                } else return data;
            });
            setTaxRowData([...updatedData]);
        } else {
            setTaxRowData([...taxRowData, rowData]);
        }
        setShowTaxModal(false);
    };

    const generateTaxRowData = () => {
        const rowData = [
            {
                name: { code: 'CGST_TDS', name: 'CGST TDS' },
                type: { code: 'RATE', name: 'Rate' },
                rate: '1',
                amount: convertToINR(+values.netValueOfWork * 0.01),
                rowId: uuidv4(),
                isReclaimable: false
            },
            {
                name: { code: 'SGST_TDS', name: 'SGST TDS' },
                type: { code: 'RATE', name: 'Rate' },
                rate: '1',
                amount: convertToINR(+values.netValueOfWork * 0.01),
                rowId: uuidv4(),
                isReclaimable: false
            },
            {
                name: { code: 'INCOME_TAX', name: 'Income tax' },
                type: { code: 'RATE', name: 'Rate' },
                rate: '2',
                amount: convertToINR(+values.netValueOfWork * 0.02),
                rowId: uuidv4(),
                isReclaimable: false
            },
            {
                name: { code: 'LABOR_CESS', name: 'Labor Cess' },
                type: { code: 'RATE', name: 'Rate' },
                rate: '1',
                amount: convertToINR(+values.netValueOfWork * 0.01),
                rowId: uuidv4(),
                isReclaimable: false
            },
            {
                name: { code: 'ROYALTY_ON_MINERALS', name: 'Royalty on Minerals' },
                type: { code: 'AMOUNT', name: 'Amount' },
                rate: null,
                amount: convertToINR(+values.adjustedRoyaltyAmount),
                rowId: uuidv4(),
                isReclaimable: false
            },
            {
                name: { code: 'TCS_ON_ROYALTY', name: 'TCS on Royalty' },
                type: { code: 'RATE', name: 'Rate' },
                rate: '2',
                amount: convertToINR(+values.adjustedRoyaltyAmount * 0.02),
                rowId: uuidv4(),
                isReclaimable: false
            },
            {
                name: { code: 'COMPENSATION_FROM_CONTRACTOR', name: 'Compensation from contractor' },
                type: { code: 'AMOUNT', name: 'Amount' },
                rate: null,
                amount: convertToINR(+values.compensation),
                rowId: uuidv4(),
                isReclaimable: false
            },
            {
                name: { code: 'CGST_ON_COMPENSATION', name: 'CGST on Compensation' },
                type: { code: 'RATE', name: 'Rate' },
                rate: '9',
                amount: convertToINR(+values.compensation * 0.09),
                rowId: uuidv4(),
                isReclaimable: false
            },
            {
                name: { code: 'SGST_ON_COMPENSATION', name: 'SGST on Compensation' },
                type: { code: 'Rate', name: 'Rate' },
                rate: '9',
                amount: convertToINR(+values.compensation * 0.09),
                rowId: uuidv4(),
                isReclaimable: false
            }
        ];

        let newRowData = values.netValueOfWork >= 250000 ? rowData : rowData.slice(2, rowData.length);
        newRowData = values.compensation && !values.isCompensationAdjusted ? newRowData : newRowData.slice(0, newRowData.length - 3);
        newRowData = values?.adjustedRoyaltyAmount ? newRowData : newRowData?.filter((val: any) => val?.name?.code !== 'ROYALTY_ON_MINERALS' && val?.name?.code !== 'TCS_ON_ROYALTY');
        setTaxRowData(newRowData);
    };

    const handleOnChange = (e: any, field: any) => {
        let fieldName = field.split('.').slice(-1)[0];

        if (fieldName === 'rate') {
            const deductionAmt = +values.netValueOfWork * +e.target.value * 0.01;
            setFieldValue(`reclaimable.amount`, +deductionAmt.toFixed(2));
        }

        if (e?.code !== 'RATE' && fieldName === 'type') {
            setFieldValue(`reclaimable.rate`, '');
        }
    };

    const handleOnTaxChange = (e: any, field: any) => {
        let fieldName = field.split('.').slice(-1)[0];

        if (fieldName === 'rate') {
            const deductionAmt = +values.netValueOfWork * +e.target.value * 0.01;
            setFieldValue(`tax.amount`, +deductionAmt.toFixed(2));
        }

        if (e?.code !== 'RATE' && fieldName === 'type') {
            setFieldValue(`tax.rate`, '');
        }
    };

    console.log(values);
    console.log('drop', dropdownData);

    return (
        <>
            <div className='px-3'>
                {MOP_FIELDS.map((val: any) => {
                    return (
                        <>
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        </>
                    );
                })}
            </div>
            <div className='row px-4 py-2'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='font-18 lh-29 fw-semibold' style={{ color: '#3A7425' }}>
                        Reclaimable Deduction
                    </div>
                    {!isViewOnly && <GenericButtons ClassName='my-2 mb-2' text={`+ ${t('RECLAIMABLE_DEDUCTION', 'Reclaimable Deduction')}`} variant='primary' size='sm' handleClick={handleOnAdd} />}
                </div>
                <OwnershipTable
                    column={UPDATED_MOP_CLAIMABLE_TABLE_COLUMN}
                    tableRowData={reclaimableRowData}
                    searchBarPlaceholder={'Search'}
                    hideExportBtn={true}
                    hideSearchBar={true}
                    updateOwnershipDataStatus={onRowView}
                    additionalMethod={onRowEdit}
                    deleteOwnershipDetails={onRowDelete}
                />
                <GenericPopupModal
                    isVisible={showReclaimableModal}
                    primaryAction={handleOnSave}
                    secondaryAction={() => {
                        setShowReclaimableModal(false);
                    }}
                    size={'lg'}
                    secondaryBtnTitle={t('CLOSE', 'Close')}
                    primaryBtnTitle={t('SAVE', 'Save')}
                    isPrimaryBtn={true}
                    title={`${openIndex ? (disabled ? 'View' : 'Edit') : 'Add'} Reclaimable Deduction`}
                    children={
                        <>
                            {claimablePopupFields.map((val: any) => {
                                return (
                                    <>
                                        <GenericFormCard
                                            {...val}
                                            styles={{ background: '#F8F8F8', padding: '10px 16px' }}
                                            customHandleChange={handleOnChange}
                                            onChangeAction={handleOnChange}
                                            dropdownOptions={dropdownData}
                                        />
                                    </>
                                );
                            })}
                        </>
                    }
                />

                {/* <DeletePopUpModal
                    secondaryAction={() => {
                        setShowDeleteModal(false);
                    }}
                    primaryAction={() => {
                        handleModalDelete(rowDelData);
                    }}
                    isVisible={showDeleteModal}
                    title={'Delete File?'}
                    img={DeleteWarning}
                    primaryBtnTitle={t('DELETE', 'Delete')}
                    secondaryBtnTitle={t('CANCEL', 'Cancel')}
                    children={
                        <>
                            <div className='p-4' style={{ backgroundColor: '#F5F5F6' }}>
                                <div className='font-inter fw-bold lh-22'>
                                    Are you sure you want to delete this file? <br />
                                    You can't undo this action!
                                </div>
                            </div>
                        </>
                    }
                /> */}
                <div className='font-18 lh-29 fw-semibold mt-4' style={{ color: '#3A7425' }}>
                    Previous Reclaimable Deduction
                </div>
                <OwnershipTable
                    column={[...MOP_CLAIMABLE_TABLE_COLUMN.slice(0, 2), MOP_CLAIMABLE_TABLE_COLUMN[3]]}
                    tableRowData={previousReclaimableRowData}
                    searchBarPlaceholder={'Search'}
                    hideExportBtn={true}
                    hideSearchBar={true}
                />
                <div>
                    <div className='py-3 mt-3 rounded-2  pe-3 d-flex flex-column align-items-end' style={{ background: '#F4F4F4' }}>
                        {calculatedReclaimableAmount.map((val: any) => (
                            <div className='me-5 w-50 d-flex justify-content-between '>
                                <div className={`${val?.class}`} style={{ width: '100%' }}>
                                    {val?.title}
                                </div>
                                <div className={`${val?.class} d-flex justify-content-between ms-5 w-25`}>
                                    <span>:</span>
                                    {convertToINR(val?.total)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='font-18 lh-29 fw-semibold mt-4' style={{ color: '#3A7425' }}>
                    Non Reclaimable Deduction
                </div>
                <OwnershipTable
                    column={UPDATED_TAX_TABLE_COLUMN}
                    tableRowData={taxRowData}
                    hideExportBtn={true}
                    hideSearchBar={true}
                    updateOwnershipDataStatus={onTaxView}
                    additionalMethod={onTaxEdit}
                    deleteOwnershipDetails={onTaxDelete}
                />
                <GenericPopupModal
                    isVisible={showTaxModal}
                    primaryAction={handleOnTaxSave}
                    secondaryAction={() => {
                        setShowTaxModal(false);
                    }}
                    size={'lg'}
                    secondaryBtnTitle={t('CLOSE', 'Close')}
                    primaryBtnTitle={t('SAVE', 'Save')}
                    isPrimaryBtn={true}
                    title={`${openIndex ? (disabled ? 'View' : 'Edit') : 'Add'} Non Reclaimable Deduction`}
                    children={
                        <>
                            {taxPopupFields.map((val: any) => {
                                return (
                                    <>
                                        <GenericFormCard
                                            {...val}
                                            styles={{ background: '#F8F8F8', padding: '10px 16px' }}
                                            dropdownOptions={dropdownData}
                                            customHandleChange={handleOnTaxChange}
                                            onChangeAction={handleOnTaxChange}
                                        />
                                    </>
                                );
                            })}
                        </>
                    }
                />
                <div>
                    <div className='py-3 mt-3 rounded-2  pe-3 d-flex flex-column align-items-end' style={{ background: '#F4F4F4' }}>
                        {calculatedNonReclaimableAmount.map((val: any) => (
                            <div className='me-5 w-50 d-flex justify-content-between '>
                                <div className={`${val?.class}`}>{val?.title}</div>
                                <div className={`${val?.class} d-flex justify-content-between ms-5 w-25`}>
                                    <span>:</span>
                                    {convertToINR(val?.total)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
