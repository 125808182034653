import { Circles } from "react-loader-spinner";
import "./commonStyles.scss";
export default function Loading() {
    return (
        <div className="overlay">
            <div className="overlay__inner">
                <div className="overlay__content">
                    <Circles height="80" width="80" color="green" ariaLabel="loading" />
                </div>
            </div>
        </div>
    );
}
