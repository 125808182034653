import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import _ from 'lodash';

export const CONTRACT_CLOSURE_SEARCH = [
    {
        formData: [
            {
                title: 'Contract',
                titleCode: 'CONTRACT',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                field: 'contract',
                class: 'col-sm-5'
            },
            {
                title: 'Industrial Estate',
                titleCode: 'INDUSTRIAL_ESTATE',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                field: 'industrialEstate',
                class: 'col-sm-5'
            }
        ]
    }
];

export const CONTRACT_CLOSURE_SEARCH_TABLE = [
    {
        colName: '',
        fieldName: 'radio',
        clsName: 'col-sm-1 column-text-start',
        id: 1
    },
    {
        colName: 'Contract No',
        titleCode: 'CONTRACT_NO',
        fieldName: 'contractNo',
        clsName: 'col-sm-2 column-text-start',
        id: 2
    },
    {
        colName: 'Vendor Name',
        titleCode: 'VENDOR_NAME',
        fieldName: 'vendorName',
        clsName: 'col-sm-2 column-text-start',
        id: 3
    },
    {
        colName: 'Project Name',
        titleCode: 'PROJECT_NAME',
        fieldName: 'projectName',
        clsName: 'col-sm-2 column-text-start',
        id: 4
    },
    {
        colName: 'Industrial Name',
        titleCode: 'INDUSTRIAL_NAME',
        fieldName: 'industrialName',
        clsName: 'col-sm-2 column-text-start',
        id: 5
    },
    {
        colName: 'Project Type',
        titleCode: 'PROJECT_TYPE',
        fieldName: 'projectType',
        clsName: 'col-sm-2 column-text-start',
        id: 6
    },
    {
        colName: 'Estimate Amount',
        titleCode: 'ESTIMATE_AMOUNT',
        fieldName: 'estimateAmount',
        clsName: 'col-sm-1 column-text-start',
        id: 7
    }
];

export const CONTRACT_CLOSURE_CORRESPONDENCE = [
    {
        title: 'A - Contract Closure',
        titleCode: 'A_CONTRACT_CLOSURE',
        field: 'contractClosure'
    }
];

export const CONTRACT_CLOSURE_FORM = [
    {
        formData: [
            {
                title: 'Project Name',
                titleCode: 'PROJECT_NAME',
                field: 'projectName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-12'
            },
            {
                title: 'Estate',
                titleCode: 'ESTATE',
                field: 'estate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Project Type',
                titleCode: 'PROJECT_TYPE',
                field: 'projectType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Nature of Work',
                titleCode: 'NATURE_OF_WORK',
                field: 'natureOfWork',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Estimated Project Durations (in months)',
                titleCode: 'PROJECT_DURATIONS',
                field: 'projectDuration',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        title: 'Contract Details',
        titleCode: 'CONTRACT_DETAILS',
        formData: [
            {
                title: 'Work Order Number',
                titleCode: 'WORK_ORDER_NUMBER',
                field: 'workOrderNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Work Order Date',
                titleCode: 'WORK_ORDER_DATE',
                field: 'workOrderDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                title: 'Agreement Number',
                titleCode: 'AGREEMENT_NUMBER',
                field: 'agreementNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Agreement Date',
                titleCode: 'AGREEMENT_DATE',
                field: 'agreementDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                title: 'Project Start Date',
                titleCode: 'PROJECT_START_DATE',
                field: 'projectStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                class: 'col-sm-6 me-5'
            },
            {
                title: 'Defect Liability',
                titleCode: 'DEFECT_LIABILITY',
                field: 'defectLiability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isDisabled: true,
                options: [
                    { value: true, title: 'Applicable' },
                    { value: false, title: 'Not Applicable' }
                ]
            },
            {
                title: 'Defect Liability Start Date',
                titleCode: 'DEFECT_LIABILITY_START_DATE',
                field: 'defectLiabilityStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                title: 'Defect Liability End Date',
                titleCode: 'DEFECT_LIABILITY_END_DATE',
                field: 'defectLiabilityEndDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                title: 'Defect Liability Period (in Months)',
                titleCode: 'DEFECT_LIABILITY_PERIOD',
                field: 'defectLiabilityPeriodMonths',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'Maintenance Period',
                titleCode: 'MAINTENANCE_PERIOD',
                field: 'maintenancePeriod',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isDisabled: true,
                options: [
                    { value: true, title: 'Applicable' },
                    { value: false, title: 'Not Applicable' }
                ]
            },
            {
                title: 'Maintenance Period Start Date',
                titleCode: 'MAINTENANCE_PERIOD_START_DATE',
                field: 'maintenancePeriodStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                title: 'Maintenance Period End Date',
                titleCode: 'MAINTENANCE_PERIOD_END_DATE',
                field: 'maintenancePeriodEndDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                title: 'Maintenance Period Duration (in Days)',
                titleCode: 'MAINTENANCE_PERIOD_DURATION',
                field: 'maintenancePeriodDays',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'Execution End Date',
                titleCode: 'EXECUTION_END_DATE',
                field: 'executionEndDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                title: 'Total Project Cost',
                titleCode: 'TOTAL_PROJECT_COST',
                field: 'totalProjectCost',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'Signed Copy of Contract',
                titleCode: 'SIGNED_COPY_OF_CONTRACT',
                field: 'signedCopyofContract',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true,
                entityFileData: {
                    documentNamePrefix: 'Signed Contract Document',
                    documentType: '',
                    referenceType: ''
                }
            }
        ]
    },
    {
        title: 'Closure Details',
        titleCode: 'CLOSURE_DETAILS',
        formData: [
            {
                title: 'Contract Phase',
                titleCode: 'CONTRACT_PHASE',
                field: 'contractPhase',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-6 me-5'
            },
            {
                title: 'Comments',
                titleCode: 'COMMENTS',
                field: 'comments',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD
            },
            {
                title: 'Reference Document',
                titleCode: 'REFERENCE_DOCUMENT',
                field: 'referenceDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'projectName',
                    documentType: 'REFERENCE DOCUMENT',
                    referenceType: ''
                }
            }
        ]
    }
];

export const EXTENSION = [
    {
        formData: [
            {
                title: 'Contract Extension Date',
                titleCode: 'CONTRACT_EXTENSION_DATE',
                field: 'extensionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                class: 'col-sm-6 me-2',
                enableFutureDate: true,
                isRequired: true
            },
            {
                title: 'Reason',
                titleCode: 'REASON',
                field: 'reason',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-sm-12',
                disableCondition: (values: any, index: any) => !!_.get(values, 'isUpdatable'),
                isRequired: true
            },
            {
                title: 'Supporting Document',
                titleCode: 'SUPPORTING_DOCUMENT',
                field: 'supportingDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                disableCondition: (values: any, index: any) => !!_.get(values, 'isUpdatable'),
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'Supporting Document',
                    documentType: '',
                    referenceType: ''
                }
            }
        ]
    }
];

export const EXT_CORRESPONDENCE = [
    {
        title: '(A) Header',
        field: 'headerDetails'
    },
    {
        title: '(B) Extension Details',
        field: 'extensionDetails'
    }
];
