import { CGST_SGST, CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { SUB_LEASE_FEE_DUE, SUB_LEASE_PAYMENT_DUE_FALSE, SUB_LEASE_PAYMENT_DUE_TRUE } from '../Migration.const';
import { Card } from 'react-bootstrap';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { FieldArray, useFormikContext } from 'formik';

export const SublLeasePayment = () => {
    const subLeaseEntityName = [
        {
            formData: [
                {
                    title: 'Name',
                    field: 'entityName',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                }
            ]
        }
    ];

    const { values, setValues } = useFormikContext<any>();

    const onChangeAction = (e: any, field: string) => {
        let currentField = field.split('.').slice(-1)[0];
        let index = +field.split('.')[1];
        if (currentField === 'principalAmount') {
            setValues((prevValues: any) => ({
                ...prevValues,
                subLeaseFeePayment: prevValues.subLeaseFeePayment.map((item: any, idx: number) =>
                    idx === index
                        ? {
                              ...item,
                              principalAmount: e.target.value,
                              sgstPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                              cgstPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                              totalDueAmount: (
                                  +(values.subLeaseFeePayment[idx].penaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].arrearPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstArrearPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstArrearPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].arrearPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstArrearPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstArrearPenaltyAmount || 0) +
                                  +e.target.value * (2 * CGST_SGST + 1)
                              )
                                  .toFixed(2)
                                  .toString()
                          }
                        : item
                )
            }));
        } else if (currentField === 'penaltyAmount') {
            setValues((prevValues: any) => ({
                ...prevValues,
                subLeaseFeePayment: prevValues.subLeaseFeePayment.map((item: any, idx: number) =>
                    idx === index
                        ? {
                              ...item,
                              penaltyAmount: e.target.value,
                              sgstPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                              cgstPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                              totalDueAmount: (
                                  +(values.subLeaseFeePayment[idx].principalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].arrearPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstArrearPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstArrearPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].arrearPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstArrearPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstArrearPenaltyAmount || 0) +
                                  +e.target.value * (2 * CGST_SGST + 1)
                              )
                                  .toFixed(2)
                                  .toString()
                          }
                        : item
                )
            }));
        } else if (currentField === 'arrearPenaltyAmount') {
            setValues((prevValues: any) => ({
                ...prevValues,
                subLeaseFeePayment: prevValues.subLeaseFeePayment.map((item: any, idx: number) =>
                    idx === index
                        ? {
                              ...item,
                              arrearPenaltyAmount: e.target.value,
                              sgstArrearPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                              cgstArrearPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                              totalDueAmount: (
                                  +(values.subLeaseFeePayment[idx].principalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].penaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].arrearPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstArrearPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstArrearPrincipalAmount || 0) +
                                  +e.target.value * (2 * CGST_SGST + 1)
                              )
                                  .toFixed(2)
                                  .toString()
                          }
                        : item
                )
            }));
        } else if (currentField === 'arrearPrincipalAmount') {
            setValues((prevValues: any) => ({
                ...prevValues,
                subLeaseFeePayment: prevValues.subLeaseFeePayment.map((item: any, idx: number) =>
                    idx === index
                        ? {
                              ...item,
                              arrearPrincipalAmount: e.target.value,
                              sgstArrearPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                              cgstArrearPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                              totalDueAmount: (
                                  +(values.subLeaseFeePayment[idx].principalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstPrincipalAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].penaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].arrearPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].cgstArrearPenaltyAmount || 0) +
                                  +(values.subLeaseFeePayment[idx].sgstArrearPenaltyAmount || 0) +
                                  +e.target.value * (2 * CGST_SGST + 1)
                              )
                                  .toFixed(2)
                                  .toString()
                          }
                        : item
                )
            }));
        }
    };

    return (
        <>
            <FieldArray name='subLeaseFeePayment'>
                {({ insert, remove, push }) => (
                    <div>
                        {values.subLeaseFeePayment.length > 0 &&
                            values.subLeaseFeePayment.map((subLeaseFeePayment: any, index: number) => (
                                <div className='row' key={index}>
                                    <div className='col-sm-12 collapsible_box  align-items-center pb-2 '>
                                        <input type='checkbox' id={`subLeaseFeePayment${index}`} name={`subLeaseFeePayment${index}`} defaultChecked={true} style={{ display: 'none' }} />
                                        <label htmlFor={`subLeaseFeePayment${index}`} className='d-flex collapsible_box_label align-items-center justify-content-between'>
                                            Sub-Lease Fee - {index + 1}
                                        </label>
                                        <div className='form_fields' style={{ position: 'relative' }}>
                                            <Card className='card_border mb-4'>
                                                <Card.Body>
                                                    {subLeaseEntityName.map((val: any, ind: number) => (
                                                        <GenericFormCard {...val} index={index} fieldPath={`subLeaseFeePayment.${index}`} />
                                                    ))}
                                                    {SUB_LEASE_FEE_DUE.map((val: any, ind: number) => (
                                                        <>
                                                            <GenericFormCard {...val} index={index} fieldPath={`subLeaseFeePayment.${index}`} />
                                                            {!!values.subLeaseFeePayment[index].subleaseFeeDue ? (
                                                                <>
                                                                    {SUB_LEASE_PAYMENT_DUE_TRUE.map((val: any, ind: number) => (
                                                                        <GenericFormCard
                                                                            fieldPath={`subLeaseFeePayment.${index}`}
                                                                            addressPath={`subLeaseFeePayment.${index}`}
                                                                            {...val}
                                                                            index={index}
                                                                            onChangeAction={onChangeAction}
                                                                        />
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {SUB_LEASE_PAYMENT_DUE_FALSE.map((val: any, ind: number) => (
                                                                        <GenericFormCard
                                                                            fieldPath={`subLeaseFeePayment.${index}`}
                                                                            addressPath={`subLeaseFeePayment.${index}`}
                                                                            {...val}
                                                                            index={index}
                                                                            onChangeAction={onChangeAction}
                                                                        />
                                                                    ))}
                                                                </>
                                                            )}
                                                        </>
                                                    ))}
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                )}
            </FieldArray>
        </>
    );
};
