import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import Loading from '../../../../components/portalComponents/Loading.component';
import { cross } from '../../../../utils/Logo';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import Transferee from '../Transferee/Transferee';
import SpecialTransferApplication from '../SpecialTransfer/SpecialTransferApplication';
import Transfer from './Transfer';

export default function TransferMainPage() {
    const [modalDetails, setModalDetails] = useState<any>({});
    let navigate = useNavigate();
    const location: any = useLocation();
    const searchParams: any = new URLSearchParams(location.search);
    const applicationNumber = searchParams.get('applicationNumber');
    const isSpecialTransfer = JSON.parse((searchParams.get('isSpecialTransfer') as any) || null);
    const [isTransferor, setIsTransferor] = useState<boolean>(true);
    const [transferSearch, setIsTransferSearch] = useState<any>(null);

    useEffect(() => {
        (async () => {
            try {
                const entityId = JSON.parse(localStorage.getItem('user') || '{}').entityId;
                const { data: transferSearchResponse } = await POST(endpoints.transferSearch + `?applicationNos= ${applicationNumber}`, { RequestInfo: requestInfo() });
                console.log('details', transferSearchResponse, entityId);
                setIsTransferSearch(transferSearchResponse?.transferDetails[0]);
                const isTransferor = transferSearchResponse?.transferDetails[0]?.transferorDetails?.uuid === entityId;
                setIsTransferor(isTransferor);
                console.log(isTransferor);
                // !isTransferor
                //     ? navigate(`/goa-idc/estate/transferee`)
                //     : transferSearchResponse?.transferDetails[0]?.modificationDetails?.modificationType === 'SPL_TRANSFER'
                //     ? navigate(`/goa-idc/estate/special-transfer?isSpecialTransfer=true&applicationNumber=ESTATE/FORM-ST/2024/2219&tenantId=ga`)
                //     : navigate(`/goa-idc/estate/transfer`);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    const handleModalClose = (type: string, isFinalSubmit?: boolean) => {
        setModalDetails({});
        type === 'success' && isFinalSubmit && navigate('/goa-idc/enterprisedashboard');
    };

    return (
        <>
            {!isTransferor ? <Transferee /> : transferSearch?.modificationDetails?.modificationType === 'SPL_TRANSFER' ? <SpecialTransferApplication /> : <Transfer />}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit)}
            />
        </>
    );
}
