import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';

import { VACANTPLOT_TABLE_COLUMNS } from '../../ApplyForPlot/AdvertisementTable.const';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import { POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import HeaderComponent from '../../../../../components/header/Header.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import ScrollToTopButton from '../../../../../components/ScrollToTop';
import { check, cross } from '../../../../../utils/Logo';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import Loading from '../../../../../components/portalComponents/Loading.component';

interface ICorrespondenceProps {
    checkListData?: any;
    noting?: any;
}

const CreateAdvertisement = (props: ICorrespondenceProps) => {
    const columns: any = VACANTPLOT_TABLE_COLUMNS;
    const { t } = useTranslation();

    const [selectedPlots, setSelectedPlots] = useState([]);

    const [uuId, setuuId] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {}
    });

    const { values, setValues } = formik;

    const getVacantPlots = () => {
        const RequestInfo: any = { RequestInfo: requestInfo() };
        setIsLoading(true);
        POST('/idc-estate-service/plot/_search_vacant_plot', RequestInfo)
            .then(res => {
                if (res && res.data && res.data.plots.length > 0) {
                    res.data.plots.map((e: any) => {
                        e.id = e.uuid;
                    });
                    setRowData(res.data.plots);
                }
                setIsLoading(false);
            })
            .catch((err: any) => {
                setIsLoading(false);
                let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                setModalDetails({ title: t('ERROR','Error!'), show: true, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    useEffect(() => {
        getVacantPlots();
    }, []);

    const onCheckHandler = (checkedItems: any) => {
        setSelectedPlots(checkedItems);
    };

    const onApply = async () => {
        if (selectedPlots && selectedPlots.length > 0) {
            const payload = {
                RequestInfo: requestInfo(),

                plotIds: selectedPlots
            };
            setIsLoading(true);

            POST('/idc-estate-service/advertisement/_create', payload)
                .then(res => {
                    console.log('Advertisement created:', res.data);

                    let msg = 'Advertisement created successfully';
                    setModalDetails({ show: true, title: t('SUCCESS','success!'), para: msg, img: check, type: 'success', reUpload: 'OK' });
                    setValues({
                        ...values,
                        advClosureDate: res.data.advertisementsDetails[0].advertisementsDetails,
                        advDate: res.data.advertisementsDetails[0].advDate
                    });
                    setIsLoading(false);
                    navigate(`/goa-idc/createadvertisement?applicationNumber=${res.data.advertisementsDetails[0].advNo}&tenantId=ga`);
                })

                .catch((err: any) => {
                    let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                    setIsLoading(false);
                    setModalDetails({ title: t('ADVERTISEMENT_FAILED','Advertisement Creation Failed!'), show: true, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                });
        } else {
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/');
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <div className='col-sm-12'>
                            <GenericHeader text={t('GENERATE_ADVERTISEMENT', 'Generate Advertisement')} />
                            <Card className='card_border'>
                                <Card.Body>
                                    <div className='d-flex justify-content-start'>
                                        <h4>
                                            <span style={{ color: '#3A7425', paddingLeft: '15px' }}>
                                                <label>{t('VACANT_PLOTS','List of Vacant Plots')}</label>
                                            </span>
                                        </h4>
                                    </div>

                                    <div className='employee-creation-container m-auto px-3 py-3 row'>
                                        <GenericActionTable
                                            checkType={`multiCheck`}
                                            columns={columns}
                                            rowData={rowData}
                                            onCheckHandler={onCheckHandler}
                                            // hideSearchBar={true}
                                            hideExportBtn={true}
                                            searchBarPlaceholder={'Search by Plot No, Estate Name, Phase'}
                                        ></GenericActionTable>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card className='card_border'></Card>
                        </div>
                    </div>
                    <ScrollToTopButton />
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />

                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                                <GenericButtons text={t('GENERATE_ADVERTISEMENT', 'Generate Advertisement')} variant='primary' handleClick={() => onApply()} disabled={selectedPlots.length === 0} />
                            </div>
                        </div>
                    </footer>
                </>
            )}
        </>
    );
};

export default CreateAdvertisement;
