import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { DPR_DATA_COLUMN, SEARCH_DPR_FIELDS } from './NITscreen.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { Card, CardBody } from 'react-bootstrap';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { getFirstErrorKey } from '../../../../utils/utils';
import { cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import { endpoints, estateEndpoints, getDropdownOptions, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../components/portalComponents/Loading.component';

const DprSearchScreen = () => {
    const { t } = useTranslation();
    const [modalDetails, setModalDetails] = useState<any>({});
    const [searchData, setSearchData] = useState<[]>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [selectRowId, setSelectRowId] = useState<any>(null);
    const [dprNumber, setDprNumber] = useState<string>('');
    const [estateData, setEstateData] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedDpr, setSelectedDpr] = useState<any>([]);
    const [checkedItems, setCheckedItems] = useState<any>([]);

    const navigate = useNavigate();
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userUuid = getUserDetails?.uuid;
    const roles = getUserDetails?.roles?.map((val: any) => val.code);
    const isTender = roles?.includes('FIELD_INSPECTOR_HQ');
    const isShortTender = roles?.includes('FIELD_INSPECTOR_SITE');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: null
    });
    const { values, errors, setFieldTouched, setValues, setFieldValue } = formik;

    const toGetEstateDropdown = async () => {
        try {
            const { data } = await POST(estateEndpoints.estateSearch, { RequestInfo: requestInfo() });
            const estateValues = data?.estateMasterDetails?.map((val: any) => {
                return { estateName: val?.estateName, estateId: val?.uuid };
            });
            setEstateData(estateValues);
            const estateDropdown = estateValues?.map((value: any) => {
                return { name: value?.estateName, code: value?.estateName };
            });
            setDropdownData((prev: any) => ({ ...prev, industrialEstate: estateDropdown }));
        } catch (e: any) {
            let errMsg = e.response?.data?.Errors?.[0]?.message || e.response?.data?.errorMessage || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        dprDropdownDataOptions()
            .then(res => {
                setDropdownData(res);
            })
            .catch(err => console.log(err));
        toGetEstateDropdown();

        const findIndEstate = async () => {
            try {
                setIsLoading(true);
                const estateManagerSearch = await POST(endpoints.managerSearch + `?userUuids=${userUuid}`, { RequestInfo: requestInfo() });
                if (estateManagerSearch?.data?.estateManagers?.[0]?.estateId) {
                    const estateData = await POST(estateEndpoints.estateSearch + `?uuid=${estateManagerSearch?.data?.estateManagers?.[0]?.estateId}`, { RequestInfo: requestInfo() });
                    if (estateData?.data?.estateMasterDetails?.[0]?.estateName) {
                        setFieldValue('industrialEstate', { name: estateData?.data?.estateMasterDetails?.[0]?.estateName, code: estateData?.data?.estateMasterDetails?.[0]?.estateName });
                    }
                }
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.Errors?.[0]?.message || e.response?.data?.errorMessage || 'Something Went Wrong';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (isShortTender) {
            findIndEstate();
        }
    }, []);

    const onReset = () => {
        const initValue = { industrialEstate: '', dprApplicationNumber: '' };
        setValues((prev: any) => ({ ...prev, ...initValue }));
        setSearchData([]);
        setSelectedDpr([]);
    };

    const handleSearchDpr = async () => {
        setSelectedDpr([]);
        const getEstateId = estateData?.find((val: any) => val?.estateName === values?.industrialEstate?.name);
        try {
            try {
                setIsLoading(true);
                const queryParams = new URLSearchParams();
                if (values?.dprApplicationNumber) queryParams.append('dprNos', values.dprApplicationNumber);
                if (getEstateId) queryParams.append('estateIds', getEstateId?.estateId);
                const { data } = await POST(
                    endpoints.dprSearchApplication +
                        `?${queryParams.toString()}&status=APPROVED&filterForTenderCreate=true${isShortTender ? '&maxEstimatedCost=500000' : isTender ? '&minEstimatedCost=500000' : ''}`,
                    {
                        RequestInfo: requestInfo()
                    }
                );
                const tableData =
                    data?.dprHeaderDetails?.length &&
                    data?.dprHeaderDetails?.map((val: any) => {
                        const projectType = getDropdownSelectedObject(val?.projectType, dropdownData['projectType'] || []);
                        return {
                            dprNo: val?.dprNo,
                            projectName: val?.projectName,
                            industrialName: val?.estateMasterDetails?.estateName,
                            projectType: projectType?.name,
                            estimateAmount: val?.estimatedTotalCost?.toFixed(2),
                            id: val?.uuid
                        };
                    });
                setSearchData(tableData);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something Went Wrong'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const handleRowClick = (row: any) => {
        setSelectRowId(row?.id);
        setDprNumber(row?.dprNo);
        setSelectedDpr([row]);
    };
    const tempUpdatedColumn = DPR_DATA_COLUMN?.map((value: any) => {
        if (value?.fieldName === 'radio') {
            return {
                ...value,
                selectedValue: selectRowId,
                onRadioClick: handleRowClick
            };
        } else return value;
    });

    const normalTenderColumn = DPR_DATA_COLUMN?.filter((val: any) => val?.fieldName !== 'radio');

    const updatedColumn = isShortTender
        ? tempUpdatedColumn
        : isTender
        ? [
              {
                  colName: '',
                  fieldName: 'checkbox',
                  clsName: 'col-sm-1 ',
                  checkType: 'multiCheck'
              },
              ...normalTenderColumn
          ]
        : normalTenderColumn;

    const onClickInitiate = async () => {
        const dprObject = isShortTender
            ? selectedDpr
            : isTender
            ? checkedItems?.map((val: any) => {
                  const tempData = searchData?.find((item: any) => item.id === val);
                  return tempData;
              })
            : [];
        const payload = {
            RequestInfo: requestInfo(),
            worksTenderDetailsModel: {
                dprNumbers: dprObject?.map((val: any) => val.dprNo),
                tenderType: isShortTender ? 'SHORT' : isTender ? 'NORMAL' : undefined
            }
        };
        if (!dprObject?.length) {
            setModalDetails({ show: true, title: 'Error!', para: t('SELECT_DPR', 'No DPR selected For NIT'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            const { data } = await POST(endpoints.nitCreate, payload);
            if (data.worksTenderDetails.length) {
                navigate('/goa-idc/notice-inviting-tender', {
                    state: { tenderDetails: data.worksTenderDetails[0], dprDetailsTableData: dprObject }
                });
            }
        } catch (e: any) {
            console.log(e);
            let errMsg = e.response?.data?.Errors?.[0]?.message || e.response?.data?.errorMessage || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onCheckHandler = (checkedItems: any[]) => {
        setCheckedItems(checkedItems);
    };

    const searchDprFields = isTender
        ? SEARCH_DPR_FIELDS
        : SEARCH_DPR_FIELDS?.map((val: any) => ({
              ...val,
              formData: val?.formData?.map((item: any) => {
                  if (item.field === 'industrialEstate') {
                      return { ...item, isDisabled: true };
                  } else {
                      return { ...item };
                  }
              })
          }));

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={isShortTender ? t('SEARCH_DPR_SHORT_TENDER', 'Search DPR for Short Tender Creation') : t('SEARCH_DPR_CREATION', `Search DPR for NIT Creation`)}
                            additionalClass='sub-text-style'
                        />
                        <FormikProvider value={formik}>
                            <div>
                                <Card className='card_border my-4 pb-4 pe-4'>
                                    <CardBody>
                                        {searchDprFields.map((val: any) => {
                                            return (
                                                <>
                                                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                                </>
                                            );
                                        })}
                                    </CardBody>
                                    <div className='d-flex justify-content-end'>
                                        <GenericButtons text={'Reset'} handleClick={onReset} />
                                        <GenericButtons text={'Search'} variant='primary' handleClick={handleSearchDpr} />
                                    </div>
                                </Card>
                                <Card className='card_border row mx-1 p-4 pb-0'>
                                    <div className='font-20 project-primary-color lh-base mb-3'>Search Result</div>
                                    <GenericActionTable
                                        columns={updatedColumn}
                                        rowData={searchData}
                                        hideExportBtn={true}
                                        onCheckHandler={onCheckHandler}
                                        checkType={isShortTender ? '' : 'multiCheck'}
                                        searchBarPlaceholder={t('DPR_SEARCH_PARAM', 'Search by DPR No., Project Name...')}
                                    />
                                </Card>
                            </div>
                        </FormikProvider>
                    </div>
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {<GenericButtons text={t('INITIATE_NIT', 'Initiate NIT')} variant='primary' ClassName='fw-500' handleClick={onClickInitiate} />}
                            </div>
                        </div>
                    </footer>
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                </>
            )}
        </>
    );
};

export default DprSearchScreen;
