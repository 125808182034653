import file_csv from '../assets/logos/file-csv.svg';
import file_excel from '../assets/logos/file-excel.svg';
import file_pdf from '../assets/logos/file-pdf.svg';
export const logo = require('../assets/logos/Logo.png');
export const logo_white = require('../assets/logos/Logo_White.png');
export const opneLogo = require('../assets/logos/open_logo_white.png');
export const greenLogo = require('../assets/logos/open_logo.png');
export const vector = require('../assets/logos/Vector (1).png');
export const scale = require('../assets/logos/Scales.png');
export const envelope = require('../assets/logos/Envelope.png');
export const globe = require('../assets/logos/GlobeSimple.png');
export const map = require('../assets/logos/MapPin.png');
export const phone = require('../assets/logos/PhoneCall.png');
export const user = require('../assets/logos/User.png');
export const arrowUp = require('../assets/logos/ArrowUp.png');
export const right_arrow = require('../assets/logos/right_arrow.png');
export const right_arrow_white = require('../assets/logos/right_arrow_white.png');
export const right_arrow_green = require('../assets/logos/right_arrow_green.png');
export const right_arrow_green2 = require('../assets/logos/right_arrow_green2.png');
export const file_upload = require('../assets/logos//UploadSimple.png');
export const close = require('../assets/logos/close-icon.png');
export const check = require('../assets/logos/verified.png');
export const cross = require('../assets/logos/close-icon.png');
export const file_preview = require('../assets/logos/filePreview.png');
export const setting = require('../assets/logos/setting.png');
export const logout = require('../assets/logos/log-out.png');
export const warning = require('../assets/logos/Warning2.png');
export const DeleteWarning = require('../assets/logos/DeleteWarning.png');

// export const download = require("../assets/logos/downloadIcon.png");
export const pdf = require('../assets/logos/pdfIcon.png');
export const git_logo = require('../assets/logos/git_logo.png');
export const progress_check = require('../assets/logos/progress_check.png');
export const progress_uncheck = require('../assets/logos/Rectangle 29968.png');
export const pdfFormat = require('../assets/logos/Document format.png');
export const jpgFormat = require('../assets/logos/jpgDocument format.png');
export const download = require('../assets/logos/DownloadSimple.png');
export const trash = require('../assets/logos/Trash.png');
export const download_logo = require('../assets/logos/download_logo.png');
export const scrutinyToolIcon = require('../assets/logos/Vector.png');
export const minusCircle = require('../assets/logos/MinusCircle.png');
export const gitCommitBlack = require('../assets/logos/GitCommitBlack.png');
export const ArrowUp = require('../assets/logos/ArrowUp.png');
export const warningCircle = require('../assets/logos/WarningCircle.png');
export const checkCircle = require('../assets/logos/CheckCircle.png');
export const crossCircle = require('../assets/logos/XCircle.png');

export { file_csv, file_excel, file_pdf };
