// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payable-amount-card {
  border: 0.74px solid rgba(69, 133, 46, 0.2392156863);
  background: #e9f9e5;
  padding: 17.78px;
}

.payment-processing-fee-container {
  display: flex;
  justify-content: space-between;
}

.payment-processing-fee-container .fee-container-item {
  flex: 1 1;
}

.payment-processing-fee-container .fee-container-item:nth-child(2) {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/features/modules/EstateManagement/Transfer/Transfer.scss"],"names":[],"mappings":"AAAA;EACI,oDAAA;EACA,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;AACJ;;AAEA;EACI,SAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":[".payable-amount-card {\n    border: 0.74px solid #45852e3d;\n    background: #e9f9e5;\n    padding: 17.78px;\n}\n\n.payment-processing-fee-container {\n    display: flex;\n    justify-content: space-between;\n}\n\n.payment-processing-fee-container .fee-container-item {\n    flex: 1;\n}\n\n.payment-processing-fee-container .fee-container-item:nth-child(2) {\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
