import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { FormikProvider, useFormik } from 'formik';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { Card } from 'react-bootstrap';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { PLOT_DETAILS, setPlotDetails } from '../Plot Master/Plotmaster.const';
import { ESTATE_ADDITIONAL_DETAILS, ESTATE_DETAILS, estateDropdown } from '../Estate_Master/master.const';
import { cross } from '../../../../utils/Logo';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';

export function PlotEstateData() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});

    const location = useLocation();
    const data = location?.state?.data || null;
    let navigate = useNavigate();
    const user = localStorage.getItem('user');
    const userType = user ? String(JSON.parse(user)?.type) : '';

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: false
        },
        onSubmit: () => {}
    });

    const { values, setValues } = formik;

    const NEW_PLOT_DETAILS = PLOT_DETAILS.map((detail: any) => ({ ...detail, formData: detail.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));

    const NEW_ESTATE_DETAILS = ESTATE_DETAILS.map((detail: any) => ({ ...detail, formData: detail.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));

    const ESTATE_DETAILS_UPDATED = NEW_ESTATE_DETAILS.map((detail: any) => ({
        ...detail,
        formData: detail.formData.map((item: any) => {
            if (item.field === 'estateName') {
                return { ...item, type: CONSTANT_DATA_FIELD.SELECT_FIELD };
            } else {
                return item;
            }
        })
    }));

    useEffect(() => {
        (async () => {
            try {
                const dropdownOption = await estateDropdown(userType);
                setDropdownData(dropdownOption);
            } catch (err: any) {
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    const handleModalClose = (type: string, finalSubmit?: true) => {
        setModalDetails({});
        if (type === 'success' && finalSubmit) {
            navigate(`/goa-idc/enterprisedashboard`);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                if (Object.keys(data).length && dropdownData) {
                    const plotMasterData = await setPlotDetails(data.plotResponse, dropdownData);

                    let estateAdditionalDetails = {};
                    const { data: additionalDetails } = await POST(estateEndpoints.estateSearch + `?uuid=${data?.plotResponse?.estateDetails?.uuid}&isPopulateDerivedAttributes=true`, {
                        RequestInfo: requestInfo()
                    });
                    const estateData = additionalDetails?.estateMasterDetails[0].estateDetails;
                    if (estateData) {
                        estateAdditionalDetails = {
                            totalNoOfPlotsDerrived: estateData?.totalNoOfPlots,
                            avgSizeOfPlotsDerrived: estateData?.avgSizeOfPlots ? estateData?.avgSizeOfPlots?.toFixed(2) : '',
                            totalNoOfAllottedPlotsDerrived: estateData?.totalNoOfAllottedPlots,
                            totalNoOfVacantPlotsDerrived: estateData?.totalNoOfVacantPlots,
                            totalVacantIndustrialAreaDerrived: estateData?.totalVacantIndustrialArea ? estateData?.totalVacantIndustrialArea?.toFixed(2) : '',
                            landType: estateData?.landType,
                            industrialType: estateData?.industrialType
                        };
                    }

                    setValues({
                        ...values,
                        ...plotMasterData,
                        ...data?.plotResponse?.estateDetails?.estateDetails,
                        ...estateAdditionalDetails,
                        estateName: { code: data?.plotResponse?.estateDetails.estateName, name: data?.plotResponse?.estateDetails.estateName },
                        polutionControlCategoryAllowed: values?.polutionControlCategoryAllowed?.length ? values?.polutionControlCategoryAllowed : [{ code: 'NA', name: 'NA' }]
                    });
                }
                setIsLoading(false);
            } catch (err: any) {
                setIsLoading(false);
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [data, dropdownData]);

    console.log('formikkkkk', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <div className='col-sm-12'>
                            <GenericHeader text={'Plot Details'} />
                            <FormikProvider value={formik}>
                                <Card className='card_border'>
                                    <Card.Body>
                                        <div className='ps-2 py-2 d-flex justify-content-between'>
                                            <div className='formTitle m-0 ps-1'>Plot Details</div>
                                        </div>
                                        {NEW_PLOT_DETAILS.map((val, index) => (
                                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                        ))}
                                    </Card.Body>
                                </Card>
                                <Card className='card_border'>
                                    <Card.Body>
                                        <div className='ps-2 py-2 d-flex justify-content-between'>
                                            <div className='formTitle m-0 ps-1'>Estate Details</div>
                                        </div>
                                        {ESTATE_DETAILS_UPDATED.map((val, index) => (
                                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                        ))}
                                        {ESTATE_ADDITIONAL_DETAILS?.map((val: any) => (
                                            <GenericFormCard {...val} />
                                        ))}
                                    </Card.Body>
                                </Card>
                            </FormikProvider>
                        </div>
                    </div>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
                    />
                </>
            )}
        </>
    );
}
