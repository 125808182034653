export const EMPLOYEE_CORRESPONDENCE = [
    {
        title: 'A - Personal Details',
        field: 'personalDetails'
    },
    {
        title: 'B - Education Details',
        field: 'educationDetails'
    },
    {
        title: 'C - Professional Details',
        field: 'professionalDetails'
    },
    {
        title: 'D - Assignment Details',
        field: 'assignmentDetails'
    },
    {
        title: 'E - Documents',
        field: 'documents'
    }
];
export const assignment = {
    fromDate: null,
    isCurrentAssignment: null,
    department: null,
    designation: null,
    isPrimary: false,
    toDate: null,
    roles: null
};
export const qualifications = {
    qualification: null,
    stream: null,
    yearOfPassing: null,
    university: null,
    remarks: null
};
export const InitialValues = {
    assignment: [assignment]
    // qualifications: [qualifications]
};
