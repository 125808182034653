// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.min-height-85 {
  min-height: 85px !important;
  height: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/features/modules/Workspace/Workspace.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;EACA,oBAAA;AACJ","sourcesContent":[".min-height-85 {\n    min-height: 85px !important;\n    height: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
