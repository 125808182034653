import React from 'react';
import '../../features/modules/UserManagement/EmployeeModule/Dashboard.scss';
import { useTranslation } from 'react-i18next';

const GenericCard = (props: any) => {
    const { t } = useTranslation();

    return (
        <>
            <div className={`col-lg-4 col-md-4  col-4 cursor-pointer `}>
                <div className='px-4 py-4 gm_design position-relative ' style={{ backgroundColor: props?.CardStyle }} onClick={props.handleOnCardClick}>
                    <div>{props?.children}</div>
                    {/* <p className={`text-start m-0 gm_estate  mb-0`} style={{ color: props?.textColor }}>
                    {t(props?.heading)}
                </p> */}
                    <p className={`text-start mb-3 gm_logo d-flex justify-content-center align-items-center rounded-circle`} style={{ background: '#FFFFFF8F' }}>
                        <img src={props.Cardlogo} alt='' />
                    </p>
                    <h1 className={`text-start m-0 gm_number py-2 gm-dashboard-${props.index + 1}`}>{props?.count}</h1>
                    <p className={`text-start mb-4 gm_status dashboard-text-color gm-dashboard-subtext-${props.index + 1}`}>{t(props?.subheading)}</p>
                </div>
            </div>
        </>
    );
};

export default GenericCard;
