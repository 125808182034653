import React, { useState, useEffect } from 'react';
import ScrollToTop from '../../../../../components/ScrollToTop';
import { FormikProvider, useFormik } from 'formik';
import { Card, Form } from 'react-bootstrap';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { useTranslation } from 'react-i18next';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { SubLeaseWorkflowCorrespondence } from './SubLeaseCorrespondence';
import { PlotDropdownData } from '../../../UserManagement/types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import {
    convertToINR,
    entityDocumentData,
    fetchLeaseDurationDoc,
    getCheckList,
    getHistory,
    getNextActions,
    getSuccessMessage,
    getWorkflowDocumentSearch,
    onAttachDocument,
    paymentCheck
} from '../../EstateManagement.controller';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import { POST, devUrl, endpoints, estateEndpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { TENANT_ID } from '../../../../../utils/constants';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import { check, cross } from '../../../../../utils/Logo';
import { dropdownDataOptions, getFormatedOwnershipDetails } from '../../../Migration/Migration.controller';
import { getDiffBWDates } from '../../../../../utils/utils';
import { getEnterpriseData, getEntityData, getFormatedNICDetails, getInitPlotData } from '../../Allotment/Allotment.controller';
import { getLesseeInitData, getSubleaseDocs } from '../SubLeaseConst';
import { getApplicantInitData } from '../../ApplicantDetailsWorkflow';
import { CORRESPONDENCE_TABLE } from '../../Allotment/Allotment.const';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../LeaseDeed/LeaseDeed.const';
import { DOCUMENT_TYPE } from '../../../Migration/Migration.const';
import axios from 'axios';
import { EsignModal } from '../../../../commonPages/EsignModal';

const SubleaseOrderWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);

    let user = localStorage.getItem('currentUserAssignment');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';

    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [dropdownDataPlot, setDropdownDataPlot] = useState<PlotDropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [isChecked, setIsChecked] = useState([true, true, true]);
    const [tabelData, setTabelData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    let navigate = useNavigate();
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [nicDetails, setNicDetails] = useState<any>([]);
    const [applicationType, setApplicationType] = useState<string>('');
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [checklistTableData, setChecklistTableData] = useState();
    const [vacantPlotData, setVacantPlotData] = useState<any>([]);
    const [rowData, setRowData] = useState([]);
    const [content, setContent] = useState('');
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [subLeaseResponse, setSubLeaseResponse] = useState<any>(null);
    const [subLeaseUuid, setSubLeaseUuid] = useState('');
    const location: any = useLocation();
    const [isCheckedSubLeaseDetails, setIsCheckedSubLeaseDetails] = useState<any>([true, true, true, true]);
    const [transactionDetails, setTransactionDetails] = useState<any>(null);

    const [searchParams] = useSearchParams();
    const applicationNumber: any = decodeURIComponent(searchParams.get('applicationNumber') || '');
    const totalPages = 3;

    const [refId, setRefId] = useState<any>(null);
    const [approvedEsignData, setApprovedEsignData] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: false,
            noting: {},
            text: '',
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: null
    });
    const { values, setValues, errors } = formik;

    const setSubleaseApplicationData = async (res: any, appNo: any, dropdownData: any) => {
        try {
            setIsLoading(true);
            if (appNo) {
                const res1 = await getEntityData(res.subLeaseDetails.subLesseeEntityDetails, dropdownData);
                console.log('res', res1);
                const res2 = await getEnterpriseData(res.subLeaseDetails.subLesseeEnterpriseDetails, dropdownData);
                const docs = await getSubleaseDocs(res.enterpriseEntity);
                const lesseeData = await getLesseeInitData({ enterpriseEntity: res.subLeaseDetails.lesseeEntityDetails }, dropdownData);

                const planForSubLeaseAreaDocRefId =
                    res?.subLeaseDetails.planForSubLeaseAreaDocRefId &&
                    (await entityDocumentData([{ docReferenceId: res?.subLeaseDetails.planForSubLeaseAreaDocRefId, documentType: DOCUMENT_TYPE.AREA_OF_SUB_LEASE }]))[0];

                setIsLoading(false);

                const newOwnership = await getFormatedOwnershipDetails(res?.enterpriseEntity?.owners, dropdownData);
                setOwnershipDetails(newOwnership);
                setControllingOwnershipResponse(res?.enterpriseEntity?.owners);
                const newProposed = await getFormatedNICDetails(res?.enterpriseDetails?.nicEnterpriseMappings || [], dropdownData);
                setNicDetails(newProposed);

                const transferData = await fetchLeaseDurationDoc(res.subLeaseDetails.subLesseeEntityRefDetails.plotId, res?.subLeaseDetails.lesseeEntityDetails.uuid);
                let durationSubLease = getDiffBWDates(res?.subLeaseDetails.subLeaseTermStartDate, res?.subLeaseDetails.subLeaseTermEndDate);
                let plotData = getInitPlotData(res.appliedPlotDetails);

                setValues((prev: any) => ({
                    ...prev,
                    ...res1,
                    ...res2,
                    ...lesseeData,
                    ...docs,
                    applicationId: res.uuid,
                    plotUuid: res.subLeaseDetails.subLesseeEntityRefDetails.plotId,
                    entityId: res?.subLeaseDetails.lesseeEntityDetails.uuid,
                    subLeaseEndDate: res.subLeaseDetails.subLeaseTermEndDate,
                    subLeaseStartDate: res.subLeaseDetails.subLeaseTermStartDate,
                    subLeaseBuiltUpArea: res.subLeaseDetails.area,
                    utilisedBuiltUpArea: res.subLeaseDetails.isBuiltUpAreaUtilised,
                    lesseeDuesPayable: res.subLeaseDetails.isLesseeOutstandingDuePayable,
                    disqualifiedEntity: res.subLeaseDetails.isDisqualifiedEntity,
                    industrialToCommercial: res.subLeaseDetails.isChangeToCommercial,
                    subLeaseSpecialProject: res.subLeaseDetails.isSpecialProject,
                    durationSubLease,
                    lesseeDeedOfExtension: transferData?.leaseDeed,
                    occupancyCertificate: transferData?.occupancyCertificate,
                    ...plotData
                    // areaOfSubLease: { ...planForSubLeaseAreaDocRefId, documentName: planForSubLeaseAreaDocRefId?.tag }
                }));

                // setIsCheckedSubLeaseDetails([transferData?.isBuiltUpAreaUtilised, transferData?.transferOrLandPremium, true, true]);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setSubLeaseResponse(data?.estateApplications[0]);
                    setSubLeaseUuid(data?.estateApplications[0]?.uuid);
                    setContent(data.estateApplications[0].content);
                    setIsLoading(false);
                    setFileStoreId(data?.estateApplications?.[0]?.signedConsolidatedDocRefId);
                } catch (e: any) {
                    console.log(e);
                    setIsLoading(false);
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [location.search]);

    useEffect(() => {
        (async () => {
            try {
                const dropdownOption = await dropdownDataOptions();
                setDropdownData(dropdownOption);

                const nextActions = await getNextActions(applicationNumber);
                setNextValidAction(nextActions);

                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
            } catch (e: any) {
                console.log(e);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    useEffect(() => {
        const reverseDocumentDetails = documentDetails.reverse();
        const tableData = async () => {
            const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${ind + 1}` };
            });
            const reverseData = responseTableData.reverse();
            setTabelData(reverseData);
        };
        if (activeIndex === 2) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                setIsLoading(true);
                const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction.businessService);
                setDocumentDetails(docs);

                // const checklistData = await getCheckList(subLeaseResponse?.lesseeEntityDetails.uuid, subLeaseResponse?.subLesseeEntityRefDetails.plotId, nextValidAction.businessService);
                // setChecklistTableData(checklistData);

                const relatedApplicationDocument = await POST(
                    estateEndpoints.relatedApplicationSearch +
                        `?entityIds=${subLeaseResponse?.subLeaseDetails.lesseeEntityDetails.uuid}&plotIds=${subLeaseResponse?.appliedPlotDetails.uuid}&applicationNos=${applicationNumber}`,
                    { RequestInfo: requestInfo() }
                );
                setRelatedApplicationDocument(relatedApplicationDocument);
                setIsLoading(false);
            } catch (e: any) {
                console.log(e);
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (subLeaseResponse && dropdownData && nextValidAction) {
            getCorrespondenceDocument();
            getPaymentData();
            setSubleaseApplicationData(subLeaseResponse, applicationNumber, dropdownData);
        }
    }, [nextValidAction, subLeaseResponse]);

    const getPaymentData = async () => {
        try {
            // const payCheck = await paymentCheck('PROCESSING_FEE', subLeaseResponse.appliedPlotDetails.uuid, subLeaseResponse?.uuid, subLeaseResponse.enterpriseEntity.uuid);
            const payCheck = await paymentCheck('SUBLEASE_FEE', subLeaseResponse.appliedPlotDetails.uuid, '', subLeaseResponse.subLeaseDetails.subLesseeEntityDetails.uuid);
            setPaymentRowData(payCheck.paymentData);
            setTransactionDetails(payCheck?.transactionDetails);
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const handleModalClose = (type: string, isSaveOnly?: boolean) => {
        setModalDetails({});
        type === 'success' && !isSaveOnly && navigate('/goa-idc/dashboard');
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {
            console.log(e);
        }
    };

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');
    const signedFileStoreId = searchParams.get('signedFileStoreId');

    useEffect(() => {
        if (note && action && subLeaseResponse && nextValidAction) {
            onHandlePrimaryAction({ action: action }, '');
        }
    }, [subLeaseResponse, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        const userRequestInfo = requestInfo();
        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);
            setRefId(approveEsignRes.data.entityRefDocuments[0].docReferenceId);
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.map((item: any) => `${item.uuid}=${item.docReferenceId}`);
            setApprovedEsignData(approvedEsignData);
            setActionAfterEsign(action);
            setEsignModal(true);

            console.log('refId', refId, approveEsignRes.data.entityRefDocuments, 'data', approvedEsignData);

            // return;

            // if (window.location.protocol !== 'http:') {
            // let url =
            //     window.location.origin +
            //     `/goa-idc/estate/sublease-workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
            //         ','
            //     )}&action=${action}`;
            //     url = btoa(url);
            //     const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
            //         userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
            //     }`;
            //     await axios.get(esignUrl);

            //     window.open(esignUrl, '_self');
            // } else {
            //     let url =
            //         window.location.origin +
            //         `/goa-idc/estate/sublease-workflow?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
            //             ','
            //         )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
            //     console.log('url', url, documentsUuid);
            //     window.open(url, '_self');
            // }
        } catch (e) {
            console.log('esign error', e);
        }
    };

    const signDocument = () => {
        let url =
            window.location.origin +
            `/goa-idc/estate/sublease-workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
                ','
            )}&action=${actionAfterEsign}`;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            estateApplicationRequest: {
                ...subLeaseResponse,
                content: content,
                appliedPlotId: subLeaseResponse?.appliedPlotDetails.uuid
            },
            subLeaseDetailsRequest: { subLeaseDetails: [{ ...subLeaseResponse.subLeaseDetails, applicationDetails: subLeaseResponse.subLeaseDetails }], plotId: values?.plotUuid },
            signedDocDetails:
                approvedEsign?.split(',').map((item: any) => {
                    const esignData = item.split('=');
                    return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                }) || null
        };

        if (!values.notes && !note) {
            if (!comment) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }

        if (!signedFileStoreId && status.isEsignRequired) {
            esign(payload, status.action);
            return;
        }

        try {
            await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, applicationNumber);
            setModalDetails({ show: true, title: 'Success', para: successMessage, img: check, type: 'success', reUpload: 'OK' });
        } catch (e: any) {
            console.log(e);
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const updateSubLeaseOrder = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            ...subLeaseResponse,
            content: content,
            appliedPlotId: subLeaseResponse?.appliedPlotDetails.uuid
        };

        try {
            const { data } = await POST(endpoints.updateAllotment, payload);
            setContent(data.estateApplications[0].content);

            setModalDetails({ show: true, title: 'Success!', para: 'Data saved successfully', img: check, type: 'success', reUpload: 'OK', isSaveOnly: true });
        } catch (err: any) {
            console.log(err);
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const handleCheckboxChangeSubLeaseDetails = (i: any) => {
        let checks = isCheckedSubLeaseDetails;
        checks[i] = !isCheckedSubLeaseDetails[i];
        setIsCheckedSubLeaseDetails([...checks]);
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={'Application for Sub-Lessee'} additionalClass='sub-text-style' bottomSubText={`Type : Sub-Lessee, No : ${applicationNumber} `} />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={'Application for Sub-Lessee'}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : Sub-Lessee, No : ${applicationNumber} `}
                                    />

                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={!nextValidAction?.nextValidAction.length}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <SubLeaseWorkflowCorrespondence
                                                dropdownData={dropdownData}
                                                dropdownDataPlot={dropdownDataPlot}
                                                setDropdownDataPlot={setDropdownDataPlot}
                                                ownershipDetails={ownershipDetails}
                                                setOwnershipDetails={setOwnershipDetails}
                                                isChecked={isChecked}
                                                handleCheckboxChange={handleCheckboxChange}
                                                nicDetails={nicDetails}
                                                setNicDetails={setNicDetails}
                                                controllingOwnership={controllingOwnershipResponse}
                                                checkListRowData={checklistTableData}
                                                vacantPlotData={vacantPlotData}
                                                rowData={rowData}
                                                setRowData={setRowData}
                                                applicationNumber={applicationNumber}
                                                status={subLeaseResponse?.status}
                                                content={content}
                                                setContent={setContent}
                                                paymentRowData={paymentRowData}
                                                disabled={disabled}
                                                designation={designation}
                                                fileStoreId={fileStoreId}
                                                isCheckedSubLeaseDetails={isCheckedSubLeaseDetails}
                                                setIsCheckedSubLeaseDetails={setIsCheckedSubLeaseDetails}
                                                handleCheckboxChangeSubLeaseDetails={handleCheckboxChangeSubLeaseDetails}
                                                transactionDetails={transactionDetails}
                                                readOnly={!nextValidAction?.isUpdatable}
                                                subleaseOrder={true}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                        {activeIndex === 3 && (
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>

                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    <GenericFooterComponent
                        activeIndex={activeIndex}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={() => {}}
                        applicationNo={applicationNumber || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                        extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                        extraButtonAction={updateSubLeaseOrder}
                    />

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isSaveOnly)}
                    />
                </>
            )}
        </>
    );
};

export default SubleaseOrderWorkflow;
