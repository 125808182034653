import _ from "lodash";
import { BPArequestInfo } from "../../services/modulesServices/userManagement.api";

export const LesseeShareFormJson = (values: any, allDocs: any) => {
    var userinfo = window.localStorage.getItem('userData')
    return ({
        "RequestInfo": BPArequestInfo(),
        "EDCRShare": {
            "id": null,
            "archId": values?.archId,
            "archName": values?.archName,
            "edcrNumber": values?.edcrNumber,
            "lesseeId": values?.lesseeId,
            "lesseeName": values?.name,
            "plotNo": values?.plotNo,
            "estateName": values?.estate,
            "serviceType": values?.serviceType,
            "applicationType": values?.applicationType,
            "additionalDetails": values?.additionalDetails || {},
            "documents": getDocsData(allDocs, values?.edcrNumber)
        },
    }
    )
}

export const mdmsSearchJson = () => {
    var userinfo = window.localStorage.getItem('userData')
    return ({
        "RequestInfo": BPArequestInfo(),
        "MdmsCriteria": {
            "tenantId": "ga",
            "moduleDetails": [
                {
                    "moduleName": "BPA",
                    "masterDetails": [
                        {
                            "name": "ServiceType",
                            "filter": "$.[?(@.active==true)]"
                        }
                    ]
                }
            ]
        }
    }
    )
}


export const getDocsData = (values: any, edcrNumber: string) => {
    const referredArray: any = [];
    if (values && !!values.length) {
        values.map((val: any, ind: number) =>
            referredArray.push(
                {
                    id: val?.id || null,
                    documentType: val?.documentType,
                    fileStoreId: val?.fileStoreId,
                    documentName: val?.documentName,
                    tenantId: "ga",
                    documentUid: val?.documentUid || "",
                    additionalDetails: {
                    }
                })
        );

        return referredArray;

    }

}

export const PayloadJson = () => {
    var userinfo = window.localStorage.getItem('userData')
    return ({
        "RequestInfo": {
            "apiId": "1",
            "ver": "1",
            "ts": null,
            "action": "create",
            "did": "jh",
            "key": "",
            "msgId": "gfcfc",
            "correlationId": "wefiuweiuff897",
            "authToken": window.localStorage.getItem('authToken'),
            "userInfo": userinfo !== null ? JSON.parse(userinfo) : null,
        },
    })
}


export const getEmployeeList = (data: any) => {
    let empList: any = [];
    if (!!data.length) {
        data.map((val: any) => {
            empList.push({
                name: `${val?.user?.firstName} ${val?.user?.lastName}`,
                title: `${val?.user?.firstName} ${val?.user?.lastName}`,
                value: val?.user?.id,
            })

        })
        return empList;
    }
}

export const getMDMSDataList = (data: any) => {
    let newList: any = [];
    if (!!data.length) {
        data.map((val: any) => {
            newList.push({
                name: val?.title,
                title: val?.title,
                value: val?.code,
                code: val?.code,
                subcategory: [],
            })

        })
        return newList;
    }
}