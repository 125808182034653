import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { POST, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { MassAllotmentPopUp } from './MassAllotmentPopup';
import { massData, tableData } from '../../UserManagement/types';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import { getNavigateTo } from '../../../../utils/utils';
import { check, cross, warning } from '../../../../utils/Logo';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { TENANT_ID } from '../../../../utils/constants';
interface ICorrespondenceProps {
    checkListData?: any;
    noting?: any;
}

const MassAllotment = (props: ICorrespondenceProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [addButton, setAddButton] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [selectedApplication, setSelectedApplication] = useState<string | null>(null);
    const [commentValue, setCommentValue] = useState(undefined);
    const navigate = useNavigate();
    let user = localStorage.getItem('user');
    let uuid = user ? String(JSON.parse(user)?.uuid) : '';
    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {}
    });
    const [comment, setComment] = useState<string>('');
    const advNo = searchParams.get('applicationNumber');
    const { values, setValues } = formik;
    const [rowData, setRowData] = useState<RowData[]>([]);
    const [filteredData, setFilteredData] = useState<RowData[]>([]);
    const TABLE_COLUMNS = [
        {
            colName: 'multiCheck',
            fieldName: 'checkbox',
            clsName: 'col-sm-1'
        },
        {
            colName: 'Applicant Name',
            fieldName: 'applicantName',
            clsName: 'col-sm-2 column-text-start'
        },
        {
            colName: 'Application No',
            fieldName: 'applicationNo',
            clsName: 'col-sm-2 column-text-start',
            isClickable: true
        },
        {
            colName: 'Date of Submission',
            fieldName: 'assignedDate',
            clsName: 'col-sm-1 column-text-start'
        },
        {
            colName: 'Status',
            fieldName: 'status',
            clsName: 'col-sm-2 '
        },
        {
            colName: 'Pending With',
            fieldName: 'currentOwnerDesig',
            clsName: 'col-sm-2 ms-3 column-text-start'
        },
        {
            colName: 'Days Elapsed',
            fieldName: 'dayElapsed',
            clsName: 'col-sm-1 column-text-start'
        }
    ];

    const columns: any = TABLE_COLUMNS;

    const addModal = () => {
        if (selectedRows.length < 1) {
            setModalDetails({ show: true, title: 'Error!', para: t('SELECT_ATLEAST_ONE_APPLICATION','Please select at least one application'), img: cross, reUpload: 'Ok', type: 'error' });
            return;
        }
        setAddButton(true);
    };

    type RowData = {
        applicationNo: string;
        applicationType: string;
        status: string;
        previousStatus: string | null;
        assignedDate: string;
        assignedBy: string;
        assignedByDesig: string;
        currentOwner: string;
        currentOwnerDesig: string;
        navUrl: string;
        dayElapsed: string;
    };

    const onCheckHandler = (applicationNo: any) => {
        setSelectedRows(applicationNo);
    };
    useEffect(() => {
        console.log('selected Rows', selectedRows);
    }, [selectedRows]);
    const getTable = async () => {
        const RequestInfo = { RequestInfo: requestInfo() };
        try {
            const res = await POST(`/egov-workflow-v2/egov-wf/_inbox?businessServices=EST_ADV_ALLOTMENT&assignee=${uuid}`, RequestInfo);

            // Extracting the data from the response
            const data = res.data;
            const applicationNos = data?.inboxs?.map((val: any) => val.applicationNo);
            const { data: searchApplicationRes } = await POST(endpoints.searchAllotment + `?applicationNos=${applicationNos}`, { RequestInfo: requestInfo() });

            // Mapping the data to the format required by the table
            const rowData = data.inboxs.map((item: any) => ({
                applicationNo: item.applicationNo,
                status: item.status,
                previousStatus: item.previousStatus,
                assignedDate: item.assignedDate,
                assignedBy: item.assignedBy,
                assignedByDesig: item.assignedByDesig,
                currentOwner: item.currentOwner,
                currentOwnerDesig: item.currentOwnerDesig,
                navUrl: item.navUrl,
                dayElapsed: item.dayElapsed,
                id: item.applicationNo,
                applicantName: searchApplicationRes?.estateApplications?.filter((val: any) => val.applicationNo === item.applicationNo)?.[0]?.enterpriseEntity?.entityName
            }));

            // Setting the data to the state
            setRowData(rowData);
            setFilteredData(rowData);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getTable();
    }, []);

    const handleSearch = (searchText: string) => {
        const filtered = rowData.filter((item: any) => {
            return item.applicationType.toLowerCase().includes(searchText.toLowerCase()) || item.applicationNo.toLowerCase().includes(searchText.toLowerCase());
        });
        setFilteredData(filtered);
    };

    const onApply = async () => {
        const processInstances = selectedRows.map((item: string) => ({
            tenantId: TENANT_ID,
            businessService: 'EST_ADV_ALLOTMENT',
            businessId: item,
            moduleName: 'Plot Allotment via Advertisement',
            action: 'DISQUALIFY',
            notes: values.comments
        }));
        console.log('processInstance', processInstances);
        const payload = {
            RequestInfo: requestInfo(),
            ProcessInstances: processInstances,
            isUpdatable: false,
            rejectionDocIds: values.file?.map((val: any) => val?.docReferenceId)
        };
        if (!values.comments) {
            setModalDetails({ show: true, title: 'Error!', para: t('ADD_COMMENTS','Please add Comments!'), img: cross, type: 'error', reUpload: 'OK' });

            return;
        }

        POST(endpoints.estateWorkflowInitiate, payload)
            .then(res => {
                console.log('Advertisement created:', res.data);

                setModalDetails({ show: true, title: 'Success!', para: t('APPLICATION_REJECTED_SUCCESSFULLY','Application rejected successfully'), img: check, type: 'success', reUpload: 'OK' });
                const userType = localStorage.getItem('type');
                if (userType) navigate(getNavigateTo(JSON.parse(userType)));
                setValues({
                    ...values
                });
            })
            .catch((err: any) => {
                let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            });
        setAddButton(false);
    };

    const handleCommentValue = (data: any) => {
        setCommentValue(data);
    };

    const handleRowClick = (rowData: any) => {
        navigate(`/goa-idc/estate/allotment/workflow?applicationNumber=${rowData.applicationNo}`);
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/dashboard');
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <div className='col-sm-12'>
                    <GenericHeader
                        text={t('BULK_REJECTION', 'Bulk Rejection')}
                        additionalClass='sub-text-style'
                        bottomSubText={` ${t('Type', `Type : ${'Bulk Rejection of Allotment Application'}`)} ${advNo ? t('', `, No : ${advNo}`) : ''}`}
                    />
                    <Card className='card_border'>
                        <Card.Body>
                            <div className='mt-2'>
                                <GenericActionTable
                                    checkType={`multiCheck`}
                                    columns={columns}
                                    rowData={filteredData}
                                    additionalMethod={handleRowClick}
                                    onCheckHandler={onCheckHandler}
                                    searchBarPlaceholder='SEARCH_BY'
                                    handleSearch={handleSearch}
                                    selectedApplication={selectedApplication}
                                />
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className='card_border'></Card>
                </div>
            </div>

            <footer className='footer fixed-bottom footerBtnPatch'>
                <div className='d-flex'>
                    <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                        <GenericButtons text={t('REJECT', 'Reject')} variant='primary' handleClick={() => addModal()} />
                    </div>
                </div>
            </footer>
            <FormikProvider value={formik}>
                <GenericPopupModal
                    title='Bulk Rejection'
                    size='md'
                    primaryAction={() => {
                        onApply();
                    }}
                    secondaryAction={() => {
                        setAddButton(false);
                    }}
                    isVisible={addButton}
                    children={<MassAllotmentPopUp receivedChildData={handleCommentValue} />}
                    primaryBtnTitle='Reject'
                    secondaryBtnTitle='Close'
                    onClose={() => setAddButton(false)}
                />{' '}
            </FormikProvider>
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
        </>
    );
};

export default MassAllotment;
