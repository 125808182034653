import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import { t } from 'i18next';
import { cross } from '../../../../../utils/Logo';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { useNavigate } from 'react-router-dom';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { EMPLOYEE_MAPPING_TABLE } from './EmployeeMapping.const';
import { endpoints, POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';

export function EmployeeMapping() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [rowData, setRowData] = useState<any>([]);

    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;
    const navigate = useNavigate();

    useEffect(() => {
        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', 'Unauthorized access'),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }

        if (userType === 'EMPLOYEE') {
            (async () => {
                try {
                    setIsLoading(true);
                    const { data: employeeMappingData } = await POST(endpoints.managerMetaSearch + `?isEstateDetailsRequired=true`, { RequestInfo: requestInfo() });
                    console.log('employeeMappingData', employeeMappingData);
                    const tempTableData = employeeMappingData?.estateManagerDetails?.map((val: any, index: number) => {
                        return { ...val, serialNo: index + 1 };
                    });
                    setRowData(tempTableData);
                    setIsLoading(false);
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, []);

    const handleModalClose = (type: string, isUnauthorized: boolean) => {
        setModalDetails({});
        if (type === 'success' && !isUnauthorized) {
            navigate('/goa-idc/dashboard');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            }
        }
    };

    const onEdit = (row: any) => {
        navigate(`/goa-idc/employee-mapping/edit`, { state: { data: row } });
    };

    const onView = (row: any) => {
        navigate(`/goa-idc/employee-mapping/edit`, { state: { data: row, isView: true } });
    };

    return (
        <>
            <HeaderComponent />

            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={t('ESTATE_LEVEL_MAPPING', 'Estate Level Mapping')} />
                        <div className='col-13 form-section'>
                            <Card className='card_border'>
                                <Card.Body>
                                    <div className='p-2'>
                                        <GenericActionTable
                                            columns={EMPLOYEE_MAPPING_TABLE}
                                            rowData={rowData}
                                            searchBarPlaceholder={t('SEARCH_EMPLOYEE_MAPPING_FILTER', 'Search by Industrial Estate...')}
                                            hideExportBtn={true}
                                            handleEditRow={onEdit}
                                            handleView={onView}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </>
            )}

            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
            />
        </>
    );
}
