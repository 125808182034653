import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { Card } from 'react-bootstrap';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { FormikProvider, useFormik } from 'formik';
import { OWNERSHIP_VALIDATION, PROPOSED_ACTIVITY_VALIDATION, TRANSFEREE_VAALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import {
    APPLICANT_DETAILS,
    SP_TRANSFEREE_FORM_STEPS,
    TRANSFEREE_DETAILS_SEARCH,
    TRANSFEREE_FORM_STEPS,
    TRANSFEREE_OTHER_DETAILS,
    TRANSFEROR_DETAILS_SEARCH,
    declarationList,
    initialValues
} from './Transferee.const';
import { POST, devUrl, endpoints, getProgressBarData, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import Declaration from '../Allotment/Declaration';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericOwnerShipDetails from '../../../../components/Ownership/GenericOwnership';
import EnterpriseDetails from '../Allotment/EnterpriseDetails';
import PlotDetails from '../Transfer/AssignedPlotDetails';
import { dropdownDataOptions, getEntityDocumentDetails, getFormatedOwnershipDetails } from '../../Migration/Migration.controller';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getDiffBWDates, getFirstErrorKey } from '../../../../utils/utils';
import processDocuments, { getTransferDetails, getTransfereePayload, getTransferorDetails } from './Transferee.controller';
import PopUps from '../../../../components/PopUps';
import { check, cross } from '../../../../utils/Logo';
import { DECLARATION_DETAILS, ENTERPRISE_DETAILS, PROJECT_DETAILS, PROMOTER_DETAILS } from '../Allotment/Allotment.const';
import { getEnterpriseData, getEntityData, getFormatedNICDetails, getInitPlotData } from '../Allotment/Allotment.controller';
import Loading from '../../../../components/portalComponents/Loading.component';
import { convertDateFormatddMMyyyytoyyyyMMdd } from '../LeaseDeed/LeaseDeed.controller';
import { getTransferBuildUpArea, getTransferCertificate } from '../../../../services/technicalServices/Technical.service';
import ApplicantDetails from '../Allotment/ApplicantDetails';
import { SPECIAL_PROJECT_DOCUMENT, SPECIAL_TRANSFER_DETAILS, SPECIAL_TRANSFER_DOCUMENTS } from '../SpecialTransfer/SpecialTransfer.const';
import { specialTransferDetails } from '../../UserManagement/UserMangement.controller';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { fetchLeaseDurationDoc, getNextActions, getQueryParams } from '../EstateManagement.controller';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';
import { toast } from 'react-toastify';
import axios from 'axios';
import { TRANSFER_DETAILS_SEARCH, transferDeclarationList } from '../Transfer/Transfer.const';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { EsignModal } from '../../../commonPages/EsignModal';

interface PlotDropdownData {
    plotNo: string[];
    phase: string[];
    plotType: string[];
    estateName: string[];
}

const Transferee = () => {
    const location: any = useLocation();
    const navigate = useNavigate();
    const searchParams: any = new URLSearchParams(location.search);

    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [isChecked, setIsChecked] = useState([false, false, false]);
    const [time, setTime] = useState<any>(0);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [plotData, setPlotData] = useState<any>([]);
    const [documents, setDocuments] = useState<any>([]);
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    // const [nicDetails, setNicDetails] = useState<any>([]);
    const [transfereeNicDetails, setTransfereeNicDetails] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [dropdownDataPlot, setDropdownDataPlot] = useState<PlotDropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });
    const [formIsUpdatable, setFormIsUpdatable] = useState(true);
    const [finalOwnerShipDetails, setFinalOwnerShipDetails] = useState({
        applicantDetails: []
    });
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [showPopUp, setShowPopUp] = useState(false);
    const [calculated, setCalculated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [transferDetailsRes, setTransferDetailsRes] = useState<any>(null);
    const [isApplicantRole, setIsApplicantRole] = useState<any>(true);
    const [specialProjectDocument, setSpecialProjectDocument] = useState<any>(SPECIAL_PROJECT_DOCUMENT);
    const [specialTransferDocument, setSpecialTransferDocument] = useState<any>(SPECIAL_TRANSFER_DOCUMENTS);
    const [specialTransferDetail, setSpecialTransferDetails] = useState<any>(SPECIAL_TRANSFER_DETAILS);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [isTransferChecked, setIsTransferChecked] = useState<any>([false, false, false, false, false]);
    const [isChecklistDisabled, setIsChecklistDisabled] = useState<boolean>(false);

    const transfereeSideCardStep = ['A1', 'A2', 'A3', 'B', 'C', 'D'];
    const applicationNumber = searchParams.get('applicationNumber');
    const isSpecialTransfer = JSON.parse((searchParams.get('isSpecialTransfer') as any) || null);
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>(null);

    const [searchParamsList] = useSearchParams();
    const queryParams = getQueryParams(searchParamsList);

    console.log('isSpecialTransfer', isSpecialTransfer);

    const onOwnershipDetailsChange = (key: string, value: any) => {
        setFinalOwnerShipDetails({ ...finalOwnerShipDetails, [key]: value });
    };

    const formik: any = useFormik<any>({
        initialValues: { ...initialValues },
        onSubmit: value => {},
        enableReinitialize: true,
        validationSchema: !formIsUpdatable ? null : !showOwnershipModal && !showPopUp ? TRANSFEREE_VAALIDATION[`page${activeIndex}`] : showPopUp ? PROPOSED_ACTIVITY_VALIDATION : OWNERSHIP_VALIDATION
    });

    const { values, setValues, setFieldValue, errors, setFieldTouched } = formik;

    useEffect(() => {
        const checkRoles = (roles: any, roleNames: any) => {
            for (let i = 0; i < roles.length; i++) {
                if (!roleNames.includes(roles[i].code)) {
                    return false;
                }
            }
            return true;
        };
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const isEnterprise = checkRoles(user?.roles || [], ['ENTERPRISE', 'APPLICANT']);
        setIsApplicantRole(isEnterprise);
        const fetchDropdown = async () => {
            const dropdownOption: any = await dropdownDataOptions();
            setDropdownData(dropdownOption);
        };
        fetchDropdown();

        if (isSpecialTransfer) {
            const updatedsSecialProjectDocument = specialProjectDocument.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: true })) }));
            setSpecialProjectDocument(updatedsSecialProjectDocument);
            const updatedSpecialTransferDocument = specialTransferDocument.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: true })) }));
            setSpecialTransferDocument(updatedSpecialTransferDocument);
            const updatedSpecialTransferDetails = specialTransferDetail.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, isDisabled: true })) }));
            setSpecialTransferDetails(updatedSpecialTransferDetails);
        }

        if (!!applicationNumber)
            getProgressBarData(applicationNumber)
                .then(res => setProgressBarDetails(res.data))
                .catch(e => console.log(e));
    }, []);

    useEffect(() => {
        (async () => {
            const nextActions = await getNextActions(applicationNumber);
            setHasValidAction(nextActions?.nextValidAction?.length > 0);
            if (nextActions?.nextValidAction?.length === 0) {
                setIsChecked([true, true, true]);
            }
            setNextValidAction(nextActions);
        })();
    }, [applicationNumber]);

    const fetchTransferData = async () => {
        try {
            setIsLoading(true);
            const transferSearchResponse = await POST(endpoints.transferSearch + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
            const transferSearch = transferSearchResponse?.data?.transferDetails[0];
            const transferorDetails = getTransferorDetails(transferSearch);
            const transferDetails = await getTransferDetails(transferSearch);
            setFileStoreId(transferSearch?.signedConsolidatedDocRefId);
            setTransferDetailsRes(transferSearch);
            const entityDetails = await getEntityData(transferSearch?.transfereeDetails, dropdownData);
            const plotData = !isSpecialTransfer && transferSearch?.plotDetails && getInitPlotData(transferSearch?.plotDetails[0]);
            const newOwnership = transferSearch?.transfereeDetails?.owners && (await getFormatedOwnershipDetails(transferSearch?.transfereeDetails?.owners, dropdownData));
            setOwnershipDetails(newOwnership);
            setControllingOwnershipResponse(transferSearch?.transfereeDetails?.owners);
            const enterpriseDetails = transferSearch?.transfereeEnterpriseDetails && (await getEnterpriseData(transferSearch.transfereeEnterpriseDetails, dropdownData));
            const newProposed =
                transferSearch?.transfereeEnterpriseDetails?.nicEnterpriseMappings &&
                (await getFormatedNICDetails(transferSearch.transfereeEnterpriseDetails.nicEnterpriseMappings || [], dropdownData));
            newProposed && setTransfereeNicDetails(newProposed);
            setValues({
                ...values,
                ...entityDetails,
                ...transferDetails,
                ...plotData,
                enterpriseCategory: !isSpecialTransfer && {
                    name: transferSearch?.plotDetails?.plotType || '',
                    code: transferSearch?.plotDetails?.plotType || ''
                },
                ...enterpriseDetails,
                plotUuid: !isSpecialTransfer && transferSearch?.plotDetails[0]?.uuid,
                transferorEntityId: transferSearch?.transferorDetails.uuid,
                isUpdatable: hasValidAction,
                ...transferorDetails
            });
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        console.log('dropdownOptions', dropdownData);
        dropdownData && fetchTransferData();
    }, [dropdownData, hasValidAction]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const transfer = values.plotUuid && values.transferorEntityId && (await fetchLeaseDurationDoc(values.plotUuid, values.transferorEntityId));
            const nocFromGoaIpb = transferDetailsRes?.nocSpecialProjectDocument && (await getEntityDocumentDetails({ docReferenceId: transferDetailsRes?.nocSpecialProjectDocument }));
            setIsTransferChecked([
                transfer?.isBuiltUpAreaUtilised,
                transfer?.transferOrLandPremium,
                !transferDetailsRes?.isTransfereeDisqualified,
                !transferDetailsRes?.isIndustrialCommercial,
                !transferDetailsRes?.isTransferProhibited
            ]);
            setValues((prevState: any) => ({
                ...prevState,
                ...transfer,
                nocFromGoaIpb: nocFromGoaIpb
            }));
            setIsLoading(false);
        };
        const fetchSpecialTransfereeData = async () => {
            try {
                const specialTransferInitData = transferDetailsRes?.modificationDetails && dropdownData && (await specialTransferDetails(transferDetailsRes?.modificationDetails, dropdownData));
                let isAppliedUnderSpecialProject;
                if (specialTransferInitData.nocGoaIPB) {
                    isAppliedUnderSpecialProject = true;
                } else {
                    isAppliedUnderSpecialProject = false;
                }
                setValues({ ...values, ...specialTransferInitData, appliedUnderSpecialProject: isAppliedUnderSpecialProject });
            } catch (error) {
                console.log('Error fetching data', error);
            }
        };

        values.plotUuid && values.transferorEntityId && !isSpecialTransfer ? fetchData() : fetchSpecialTransfereeData();
    }, [values.plotUuid, values.transferorEntityId, transferDetailsRes, dropdownData]);

    useEffect(() => {
        let deleteKey = (!values.transfereeEnterpriseDetails?.specialProject && 'inPrincipleApproval') || (!values.transfereeEnterpriseDetails?.declaration && 'declarationDocument') || null;
        try {
            deleteKey && delete values.transfereeEnterpriseDetails?.documents[deleteKey];
        } catch (e) {}
    }, [values?.transfereeEnterpriseDetails?.specialProject, values?.transfereeEnterpriseDetails?.declaration]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const { data } = await POST('/idc-estate-service/plot/_metadata-search', { RequestInfo: requestInfo() });
    //             setDropdownDataPlot({
    //                 plotNo: data.plotNoDetails || [],
    //                 phase: data.phases || [],
    //                 plotType: data.plotTypes || [],
    //                 estateName: data.estateNames || []
    //             });
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    const stepsDetails = !isSpecialTransfer ? TRANSFEREE_FORM_STEPS : SP_TRANSFEREE_FORM_STEPS;

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const handleStepClick = async (index: number) => {
        const stepperLength = stepsDetails.length;
        if (index > activeIndex) {
            try {
                await TRANSFEREE_VAALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    const transfereeDetailsI = TRANSFEREE_DETAILS_SEARCH.map((val: any) => ({
        ...val,
        formData: val.formData?.map((field: any) => ({ ...field, isDisabled: true }))
    }));

    const onChangeAction = (e: any, field: string) => {
        const { authorizedPersonFirstname, authorizedPersonLastName, pmobileNumber, alternateMobileNumber, authorizedEmailId, ...rest } = values;
        if (field === 'authorizedPersonFirstname') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: e.target.value,
                authorizedPersonLastName: authorizedPersonLastName,
                pmobileNumber: pmobileNumber,
                alternateMobileNumber: alternateMobileNumber,
                authorizedEmailId: authorizedEmailId
            });
        } else if (field === 'authorizedPersonLastName') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: authorizedPersonFirstname,
                authorizedPersonLastName: e.target.value,
                pmobileNumber: pmobileNumber,
                alternateMobileNumber: alternateMobileNumber,
                authorizedEmailId: authorizedEmailId
            });
        } else if (field === 'pmobileNumber') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: authorizedPersonFirstname,
                authorizedPersonLastName: authorizedPersonLastName,
                pmobileNumber: e.target.value,
                alternateMobileNumber: alternateMobileNumber,
                authorizedEmailId: authorizedEmailId
            });
        } else if (field === 'alternateMobileNumber') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: authorizedPersonFirstname,
                authorizedPersonLastName: authorizedPersonLastName,
                pmobileNumber: pmobileNumber,
                alternateMobileNumber: e.target.value,
                authorizedEmailId: authorizedEmailId
            });
        } else if (field === 'authorizedEmailId') {
            setValues({
                ...rest,
                letter: null,
                authorizedPersonFirstname: authorizedPersonFirstname,
                authorizedPersonLastName: authorizedPersonLastName,
                pmobileNumber: pmobileNumber,
                alternateMobileNumber: alternateMobileNumber,
                authorizedEmailId: e.target.value
            });
        }
    };

    useEffect(() => {
        if (values?.category?.code) {
            if (values?.category?.code === 'PROP_FIRM') {
                if (ownershipDetails?.length) {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: ownershipDetails[0]?.name }));
                } else {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: '' }));
                }
            } else {
                setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.applicantName }));
            }
        }
    }, [values?.category?.code, ownershipDetails, values?.applicantName]);

    const TransfereeDetails = () => {
        const handleSearchIFSC = (e: any, field: string) => {
            if (field !== 'ifscCode') return;
            e = e.target.value.toUpperCase();
            if (e.length === 11) {
                if (time) clearTimeout(time);
                setTime(
                    setTimeout(async () => {
                        try {
                            const { data } = await POST(`${endpoints.getBankDetails}?ifsc=${e}`, { RequestInfo: requestInfo() });
                            setValues({
                                ...values,
                                bankName: data?.bank || '',
                                branchAddress: data?.address || '',
                                ifscCode: e
                            });
                        } catch (error) {
                            setModalDetails({ show: true, title: 'Error!', para: t('Failed to retrieve bank details, Please check IFSC code'), img: cross, type: 'error', reUpload: 'OK' });
                            setValues({
                                ...values,
                                bankName: '',
                                branchAddress: '',
                                ifscCode: e
                            });
                        }
                    }, 800)
                );
            } else {
                setValues({
                    ...values,
                    bankName: '',
                    branchAddress: '',
                    ifscCode: e
                });
            }
        };

        return (
            <Card className='card_border'>
                <ApplicantDetails
                    controllingOwnershipResponse={controllingOwnershipResponse}
                    dropdownOptions={dropdownData}
                    setOwnershipDetails={setOwnershipDetails}
                    ownershipDetails={ownershipDetails}
                    isDisabled={!isApplicantRole}
                    showOwnershipModal={showOwnershipModal}
                    setShowOwnershipModal={setShowOwnershipModal}
                    applicantDetails1={transfereeDetailsI}
                    applicantDetails2={APPLICANT_DETAILS}
                    promoterDetails={PROMOTER_DETAILS}
                    setCalculated={setCalculated}
                    scrollNotRequired={true}
                    ownershipDisable={true}
                    onChangeAction={onChangeAction}
                />
                {/* <Card.Body>
                    <>
                        {TRANSFEREE_DETAILS_SEARCH?.map(val => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}

                        {APPLICANT_DETAILS?.map(val =>
                            !val.renderCondition || (val.renderCondition && val.renderCondition(values)) ? <GenericFormCard {...val} dropdownOptions={dropdownData} /> : <></>
                        )}
                    </>
                    <GenericOwnerShipDetails
                        showOwnershipModal={showOwnershipModal}
                        setShowOwnershipModal={setShowOwnershipModal}
                        changeOwnershipDetails={(values: any) => onOwnershipDetailsChange('applicantDetails', values)}
                        values={values}
                        dropdownData={dropdownData}
                        controllingOwnershipResponse={controllingOwnershipResponse}
                        setControllingOwnershipResponse={setControllingOwnershipResponse}
                        allOwnerShipDetails={finalOwnerShipDetails}
                        setOwnershipDetails={setOwnershipDetails}
                        ownershipDetails={ownershipDetails}
                        setCalculated={setCalculated}
                    />
                    {BANK_DETAILS?.map(val => (
                        <GenericFormCard {...val} onChangeAction={handleSearchIFSC} dropdownOptions={dropdownData} />
                    ))}
                </Card.Body> */}
            </Card>
        );
    };

    const TransferorDetails = () => {
        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            {TRANSFEROR_DETAILS_SEARCH?.map(val => (
                                <GenericFormCard {...val} dropdownOptions={dropdownData} />
                            ))}
                        </Card.Body>
                    </Card>
                </>
            </div>
        );
    };

    const TransferDetails = () => {
        return (
            <div>
                <>
                    <Card className='card_border'>
                        <Card.Body>
                            {!isSpecialTransfer ? (
                                <>
                                    <div>
                                        <>
                                            {TRANSFER_DETAILS_SEARCH?.map(val => (
                                                <GenericFormCard {...val} />
                                            ))}

                                            <div className='ps-2 py-2 d-flex justify-content-between'>
                                                <div className='formTitle m-0'>
                                                    {'Transferor Self Declaration'} <span className='text-danger'>*</span>
                                                </div>
                                            </div>

                                            <div className='ps-2 d-flex flex-column fw-medium'>
                                                <p className='mt-3 font-inter'>{t('TRANSFEROR_SELF_DECLARATION', 'The Transferor does hereby affirm and declare that')}:</p>
                                                {transferDeclarationList.map((item, index) => (
                                                    <label key={index} className='mb-3 d-flex gap-1'>
                                                        <input
                                                            type='checkbox'
                                                            checked={isTransferChecked[index]}
                                                            onChange={() => handleCheckboxChange(index)}
                                                            disabled={true}
                                                            className={'default-checked'}
                                                        />
                                                        <div className='flex-grow-0 font-inter'>{t(item.code, item.label)}</div>
                                                    </label>
                                                ))}
                                            </div>
                                        </>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {specialTransferDetail.map((val: any) => (
                                        <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                    ))}
                                    {specialTransferDocument.map((val: any) => (
                                        <GenericFormCard {...val} />
                                    ))}
                                    {specialProjectDocument.map((val: any) => (
                                        <GenericFormCard {...val} />
                                    ))}
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </>
            </div>
        );
    };

    const handleOnSubmit = async (isSaveOnly?: boolean) => {
        const isFinal = activeIndex === stepsDetails.length;
        let payload = getTransfereePayload(values, activeIndex, transferDetailsRes, ownershipDetails, isFinal, transfereeNicDetails, isSpecialTransfer);
        console.log('payload', payload);

        const currOwnership = ownershipDetails
            ?.filter((item: any) => !item.isDelete)
            ?.reduce((accumulator: number, subItem: any) => {
                return accumulator + subItem.ownershipInterest;
            }, 0);

        try {
            if (activeIndex <= stepsDetails.length && formIsUpdatable) {
                await TRANSFEREE_VAALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false });
            }

            if (activeIndex === 1 && currOwnership != 100) {
                setModalDetails({ show: true, title: 'Error!', para: 'Your ownership interest does not add upto 100%', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            if (activeIndex === 1 && !calculated) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please calculate controlling ownership', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            if (activeIndex === 5 && !isSpecialTransfer && (!transfereeNicDetails || !transfereeNicDetails?.length)) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please add atleast 1 proposed activity', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if ((activeIndex === 6 || (activeIndex === 4 && isSpecialTransfer)) && !isChecked.every((e: boolean) => e)) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }

            if ((activeIndex === 6 || (activeIndex === 4 && isSpecialTransfer)) && eSignedDocumentData?.isEsignRequired) {
                setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            try {
                const { data } = await POST(endpoints.transferUpdate, payload);
                !isSaveOnly && onChangeNextIndex();
                isFinal &&
                    setModalDetails({ show: true, title: 'Success!', para: 'Application for Transfer submitted successfully', img: check, type: 'success', reUpload: 'OK', isFinalSubmit: isFinal });
                !isFinal &&
                    toast('Data saved successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        className: 'foo-bar',
                        type: 'success'
                    });
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details', img: cross, type: 'error', reUpload: 'Ok' });
            let errorField = getFirstErrorKey(errors);
            console.log(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };

    const signDocument = async () => {
        let url = window.location.origin + `/goa-idc/estate/transferee?activeIndex=${activeIndex}&applicationNumber=${applicationNumber}&isSpecialTransfer=${isSpecialTransfer}`;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${eSignedDocumentData.filestoreId}&callbackUrl=${url}`);
    };

    const handleClickSignDocument = () => {
        if ((activeIndex === 6 || (activeIndex === 4 && isSpecialTransfer)) && !isChecked.every((e: boolean) => e)) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setEsignModal(true);
    };

    useEffect(() => {
        const fetchESignStatus = async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                const { data } = await POST(endpoints.consolidatedDocSearch, payload);
                data.filestoreId && setESignedDocumentData(data);
                !data.isEsignRequired && setIsChecked([true, true, true]);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (activeIndex === 6 || (activeIndex === 4 && isSpecialTransfer) || !signedFileStoreId) {
            fetchESignStatus();
        }
    }, [activeIndex]);

    useEffect(() => {
        (async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: applicationNumber,
                    filestoreId: signedFileStoreId || undefined
                };

                if (signedFileStoreId) {
                    const { data } = await POST(endpoints.consolidatedDocESign, payload);
                    // setESignedDocumentData(data);
                    const consolidatedDocSearch = await POST(endpoints.consolidatedDocSearch, payload);
                    consolidatedDocSearch?.data.filestoreId && setESignedDocumentData(consolidatedDocSearch?.data);
                    !data.isEsignRequired && setIsChecked([true, true, true]);
                }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [signedFileStoreId]);

    const handleModalClose = (type: string, isFinalSubmit?: boolean) => {
        setModalDetails({});
        type === 'success' && isFinalSubmit && navigate('/goa-idc/enterprisedashboard');
    };

    const onCancel = () => {
        navigate(-1);
    };

    const status: any = progressBarDetails?.progressStatus.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNumber,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    useEffect(() => {
        if (queryParams?.['activeIndex']) {
            setActiveIndex(+queryParams?.['activeIndex']);
            setIsChecked([true, true, true]);
            setIsChecklistDisabled(true);
        }
    }, [location.search]);

    console.log('formik', formik);

    return (
        <>
            {/* <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} /> */}
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={
                                transferDetailsRes?.applicationDetails.applicationType === 'TRANSFER'
                                    ? t('TRANSFER_APPLICATION', 'Application for Transfer')
                                    : t('SPECIAL_TRANSFER_APPLICATION', 'Application for Special Transfer')
                            }
                            subText={!applicationNumber ? t('HOME_ESTATE', 'HOME / ESTATE') : ''}
                            bottomSubText={applicationNumber ? `${t('TYPE', 'Type')} : ${t(transferDetailsRes?.applicationDetails.applicationType)}, ${t('NO', 'No')} : ${applicationNumber}` : ''}
                            additionalClass={applicationNumber && 'sub-text-style'}
                        />
                        <div className='col-3 stickyCard-section font-inter'>
                            <div className='stickyCard'>
                                <GenericHeader
                                    text={
                                        transferDetailsRes?.applicationDetails.applicationType === 'TRANSFER'
                                            ? t('TRANSFER_APPLICATION', 'Application for Transfer')
                                            : t('SPECIAL_TRANSFER_APPLICATION', 'Application for Special Transfer')
                                    }
                                    sideHeader={true}
                                    additionalClass='sub-text-style'
                                    bottomSubText={
                                        applicationNumber ? `${t('TYPE', 'Type')} : ${t(transferDetailsRes?.applicationDetails.applicationType)}, ${t('NO', 'No')} : ${applicationNumber}` : ''
                                    }
                                />
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard
                                                steps={stepsDetails}
                                                visited={visited}
                                                activeIndex={activeIndex}
                                                handleStepClick={handleStepClick}
                                                indexType={'custom'}
                                                indexArray={transfereeSideCardStep}
                                            />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            {!!applicationNumber && applicationData.status !== 'Draft' && (
                                <Card className='card_border my-2'>
                                    <Card.Body>
                                        <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                                    </Card.Body>
                                </Card>
                            )}
                            <FormikProvider value={formik}>
                                {activeIndex === 1 && TransfereeDetails()}
                                {activeIndex === 2 && TransferorDetails()}
                                {activeIndex === 3 && TransferDetails()}
                                {activeIndex === 4 && !isSpecialTransfer && (
                                    <>
                                        <Card className='card_border'>
                                            <Card.Body>
                                                <div className='ps-2 py-2 d-flex justify-content-between'>
                                                    <div className='formTitle m-0'>{'Plot Details'}</div>
                                                </div>
                                                <PlotDetails plotData={[]} hideCard={true} isFieldDisable={true} />
                                            </Card.Body>
                                        </Card>
                                    </>
                                )}
                                {activeIndex === 5 && !isSpecialTransfer && (
                                    <Card className='card_border'>
                                        <Card.Body>
                                            <EnterpriseDetails
                                                enterpriseDetails={ENTERPRISE_DETAILS}
                                                enterpriseProjectDetails={PROJECT_DETAILS}
                                                declarationDetails={DECLARATION_DETAILS}
                                                dropdownOptions={dropdownData}
                                                nicDetails={transfereeNicDetails}
                                                setNicDetails={setTransfereeNicDetails}
                                                showPopUp={showPopUp}
                                                setShowPopUp={setShowPopUp}
                                                applicantName={'Transferee'}
                                                fileStoreId={fileStoreId}
                                            ></EnterpriseDetails>
                                        </Card.Body>
                                    </Card>
                                )}
                                {((activeIndex === 6 && !isSpecialTransfer) || (activeIndex === 4 && isSpecialTransfer)) && (
                                    <Declaration
                                        isChecked={isChecked}
                                        declarationList={declarationList}
                                        handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                                        setDocuments={setDocuments}
                                        signDocument={handleClickSignDocument}
                                        eSignedDocumentData={eSignedDocumentData}
                                        applicantName={'Transferee'}
                                        isDisabled={!hasValidAction || isChecklistDisabled}
                                        declarationTitle={!isSpecialTransfer && 'Transferee'}
                                    ></Declaration>
                                )}
                                <GenericPopupModal
                                    title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                    primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                    secondaryBtnTitle={t('CLOSE', 'Close')}
                                    isSecondaryBtn={true}
                                    secondaryAction={() => setEsignModal(false)}
                                    primaryAction={signDocument}
                                    isVisible={esignModal}
                                    children={<EsignModal />}
                                />
                            </FormikProvider>
                        </div>
                    </div>
                    <footer className='footer_common fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                            </div>

                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {hasValidAction ? (
                                    <>
                                        {(activeIndex === 1 || activeIndex === 5) && <GenericButtons text={'Save'} handleClick={() => handleOnSubmit(true)} />}
                                        {activeIndex === 2 || activeIndex === 3 || (activeIndex === 4 && !isSpecialTransfer) ? (
                                            <GenericButtons
                                                text={'Next'}
                                                variant='primary'
                                                handleClick={() => {
                                                    setActiveIndex(activeIndex + 1);
                                                }}
                                            />
                                        ) : (
                                            <GenericButtons text={activeIndex !== stepsDetails.length ? 'Save & Next' : 'Submit'} variant='primary' handleClick={() => handleOnSubmit(false)} />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {activeIndex === stepsDetails.length ? (
                                            <>
                                                <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onCancel} />
                                            </>
                                        ) : (
                                            <>
                                                <GenericButtons text={t('NEXT', 'Next')} variant='primary' handleClick={onChangeNextIndex} />
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </footer>
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.isFinalSubmit)}
                    />
                </>
            )}
        </>
    );
};

export default Transferee;
