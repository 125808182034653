import _ from 'lodash';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { POST, fetchDropdownData, requestInfo } from '../../../../services/modulesServices/userManagement.api';

export const estateDropdown = async (userType: any) => {
    const dropdownPayload = {
        RequestInfo: requestInfo(),
        MdmsCriteria: {
            tenantId: 'ga',
            moduleDetails: [
                {
                    moduleName: 'Estate',
                    masterDetails: [
                        {
                            name: 'PollutionCategory',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'District',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'Village',
                            filter: '[?(@.active == true)]'
                        },
                        {
                            name: 'PlotType',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                }
            ]
        }
    };

    try {
        const res = await fetchDropdownData(dropdownPayload);
        const villageRes = userType && (await POST(`/idc-estate-service/estate-master-details/taluka/_search`, { RequestInfo: requestInfo() }));
        const villageOptions = villageRes
            ? villageRes.data?.talukaMasterDetails?.flatMap((item: any) => {
                  return item.villageMasterDetails.map((subItem: any) => ({ code: subItem.villageCode, name: subItem.villageName, uuid: subItem.uuid }));
              })
            : [];
        const taluka = villageRes ? villageRes.data?.talukaMasterDetails?.map((item: any) => ({ code: item.talukaCode, name: item.talukaName })) : [];

        const res3 = await POST('/idc-estate-service/plot/_metadata-search', { RequestInfo: requestInfo() });
        const estateNames = res3.data.estateNames.map((estate: any) => ({ name: estate.value, code: estate.code }));

        console.log('vvvvvvvvvv', villageOptions);

        const dropdownOptions = {
            polutionControlCategoryAllowed: res['Estate']['PollutionCategory'],
            district: res['Estate']['District'],
            village: villageOptions,
            plotType: res['Estate']['PlotType'],
            taluka: taluka,
            estateName: estateNames
        };

        return dropdownOptions;
    } catch (e) {
        console.log(e);
    }
};

export const ESTATE_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Name of the Park',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'District Name',
                field: 'district',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'District Code',
                field: 'districtCode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Census 2011 District Code',
                field: 'census2011DistrictCode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Sub-District',
                field: 'subDistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Village',
                field: 'village',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                multiSelect: true
            },
            {
                title: 'Pollution Control Category allowed',
                field: 'polutionControlCategoryAllowed',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                toolTipDetails: {
                    position: 'bottom',
                    content:
                        '<div>Applicable as per Water (Prevention and Control of Pollution Act 1974) and Air (Preventiona and Control of Pollution Act 1981). Red category only allowed for special projetcs alloted through Goa IPB.</div>'
                },
                multiSelect: true
            },
            {
                title: 'Plot Rate(in INR)',
                field: 'plotRate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                showInr: true
            },
            // {
            //     title: 'Total no of plots',
            //     field: 'numberOfPlots',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true
            // },
            // {
            //     title: 'Average size of plots',
            //     field: 'plotSize',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true
            // },
            // {
            //     title: 'Total no. of alloted plots',
            //     field: 'allotedPlot',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true
            // },
            // {
            //     title: 'Total no. of vacant plots',
            //     field: 'vacantPlot',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true
            // },
            {
                title: 'Total Land Area (in hectares)',
                field: 'totalLandAreaHectares',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Sector',
                field: 'sector',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest National Highway Name',
                field: 'nearestNationalHighway',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Distance from NH (in Km)',
                field: 'distanceFromNHKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest State Highway Name',
                field: 'nearestStateHighway',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Distance from SH (in Km)',
                field: 'distanceFromSHKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Railway Station Name',
                field: 'nearestRailwayStation',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Distance from Railway Station (in Km) ',
                field: 'distanceFromRailwayStationKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Airport Name',
                field: 'nearestAirport',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Distance from Airport (in Km) ',
                field: 'distanceFromAirportKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Seaport Name',
                field: 'nearestSeaport',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Distance from Seaport (in Km) ',
                field: 'distanceFromSeaportKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest ICD/Dry Port Name',
                field: 'nearestIcdDryPort',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Distance from Dry Port/ICD (in Km)',
                field: 'distanceFromDryPortIcdKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Distance from Field Office (in Km)',
                field: 'distanceFromFieldOfficeKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Police Station Name',
                field: 'nearestPoliceStationName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Police Station, Distance in kms',
                field: 'nearestPoliceStationDistanceKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Fire Station Name',
                field: 'nearestFireStationName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Fire Station, Distance in kms',
                field: 'nearestFireStationDistanceKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Bank Name',
                field: 'nearestBankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Bank, Distance in kms',
                field: 'nearestBankDistanceKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Hospital Name',
                field: 'nearestHospitalName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Nearest Hospital, Distance in kms',
                field: 'nearestHospitalDistanceKm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Type of Land',
                field: 'landType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (values: any) => _.get(values, 'isUpdatable') === false
                // isRequired: true
            },
            {
                title: 'Type of Industrial Park',
                field: 'industrialType',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (values: any) => _.get(values, 'isUpdatable') === false
                // isRequired: true
            },
            {
                title: 'Electricity/Power Availability',
                field: 'electricityPowerAvailability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                break: true
            },
            {
                title: 'Electricity/Power Capacity (in KVA)',
                field: 'electricityPowerCapacityKva',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'electricityPowerAvailability') === true
            },
            {
                title: 'Electricity/Power Utilization (in %)',
                field: 'electricityPowerUtilizationPct',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'electricityPowerAvailability') === true
            },
            {
                title: 'Water Availability',
                field: 'waterAvailability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                break: true
            },
            {
                title: 'Water Capacity (in MLD)',
                field: 'waterCapacityMld',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'waterAvailability') === true
            },
            {
                title: 'Water Utilization (in %)',
                field: 'waterUtilizationPct',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'waterAvailability') === true
            },
            {
                title: 'Natural Gas Availability',
                field: 'naturalGasAvailability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                break: true
            },
            {
                title: 'Natural Gas Capacity (in MBH)',
                field: 'naturalGasCapacityMbh',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'naturalGasAvailability') === true
            },
            {
                title: 'Natural Gas Utilization (in %)',
                field: 'naturalGasUtilizationPct',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'naturalGasAvailability') === true
            },
            {
                title: 'STP Availability',
                field: 'stpAvailability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Sewage Treatment Plant</div>'
                },
                break: true
            },
            {
                title: 'STP Capacity (in MLD)',
                field: 'stpCapacityMld',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'stpAvailability') === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Sewage Treatment Plant</div>'
                }
            },
            {
                title: 'STP Utilization (in %)',
                field: 'stpUtilizationPct',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'stpAvailability') === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Sewage Treatment Plant</div>'
                }
            },
            {
                title: 'Power Sub Station Availability',
                field: 'powerSubStationAvailability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                break: true
            },
            {
                title: 'Power Sub Station Capacity (in KVA)',
                field: 'powerSubStationCapacityKva',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'powerSubStationAvailability') === true
            },
            {
                title: 'Power Sub Station Utilization (in %)',
                field: 'powerSubStationUtilizationPct',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'powerSubStationAvailability') === true
            },
            {
                title: 'WTP Station Availability',
                field: 'wtpAvailability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Water Treatment Plant</div>'
                },
                break: true
            },
            {
                title: 'WTP Station Capacity (in MLD)',
                field: 'wtpCapacityMld',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'wtpAvailability') === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Water Treatment Plant</div>'
                }
            },
            {
                title: 'WTP Station Utilization (in %)',
                field: 'wtpUtilizationPct',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'wtpAvailability') === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Water Treatment Plant</div>'
                }
            },
            {
                title: 'ICT Availability',
                field: 'ictAvailability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Information and Communication Technology</div>'
                },
                break: true
            },
            {
                title: 'ICT Bandwidth (in Mbps)',
                field: 'ictBandwidthMbps',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Information and Communication Technology</div>'
                },
                renderCondition: (values: any) => _.get(values, 'ictAvailability') === true
            },
            {
                title: 'S.W. Disposal Facility Availability',
                field: 'swDisposalFacilityAvailability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Solid Waste Disposal Facility</div>'
                },
                break: true
            },
            {
                title: 'S.W. Disposal Facility Capacity (in TDP)',
                field: 'swDisposalFacilityCapacityTdp',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'swDisposalFacilityAvailability') === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Solid Waste Disposal Facility</div>'
                }
            },
            {
                title: 'S.W. Disposal Facility Utilization (in %)',
                field: 'swDisposalFacilityUtilizationPct',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, 'swDisposalFacilityAvailability') === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<div>Solid Waste Disposal Facility</div>'
                }
            },
            {
                title: 'Electric Pole Availability',
                field: 'electricPoleAvailability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                break: true
            },
            {
                title: 'Accommodation for workers',
                field: 'accommodationForWorkers',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                title: 'Availability of designated Truck parking area',
                field: 'availiabilityOfDesignatedTruckParkingArea',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                isRequired: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            }

            // {
            //     title: 'Name of Nodal Officer for the Park',
            //     field: 'nodalOfficer',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true
            // },
            // {
            //     title: 'Designation',
            //     field: 'designation',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true
            // },
            // {
            //     title: 'Phone No.',
            //     field: 'phoneNumber',
            //     type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
            //     isRequired: true
            // },
            // {
            //     title: 'Email ID',
            //     field: 'emailId',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isRequired: true
            // }
        ]
    }
];

export const AUTOPOPULATE_ESTATE_FIELD = [
    {
        title: 'Total Vacant Industrial Area (in hectares)',
        field: 'totalVacantIndustrialAreaHectares',
        type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
        isRequired: true
    }
];

export const ESTATE_MASTER_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'index',
        cellstyle: { width: '8%' },
        clsName: 'column-text-start',
        id: 1
    },
    {
        titleCode: 'Estate Name',
        colName: 'Estate Name',
        fieldName: 'estateName',
        cellstyle: { width: '32%' },
        type: 'text',
        clsName: 'column-text-start',
        id: 2
    },
    {
        titleCode: 'DISTRICT',
        colName: 'District',
        fieldName: 'district',
        cellstyle: { width: '20%' },
        type: 'text',
        clsName: 'column-text-start',
        id: 7
    },
    {
        titleCode: 'VILLAGE',
        colName: 'Village',
        fieldName: 'village',
        cellstyle: { width: '20%' },
        type: 'text',
        clsName: 'column-text-start',
        id: 5
    },
    {
        titleCode: 'PLOT_RATE',
        colName: 'Plot Rate (in INR)',
        fieldName: 'plotRate',
        cellstyle: { width: '20%' },
        type: 'text',
        clsName: 'column-text-end',
        id: 8
    }
    // {
    //     titleCode: 'ISACTIVE',
    //     colName: 'Status',
    //     fieldName: 'isActiveName',
    //     cellstyle: { width: '200px' },
    //     type: 'text',
    //     clsName: 'column-text-start',
    //     id: 9
    // },
    // {
    //     titleCode: 'LAND_REGISTRATION_NO',
    //     colName: 'Land Registration No',
    //     fieldName: 'landRegistrationNumber',
    //     cellstyle: { width: '200px' },
    //     type: 'text',
    //     clsName: 'column-text-start',
    //     id: 10
    // },
    // {
    //     titleCode: 'PHONE NO.',
    //     colName: 'Phone No.',
    //     fieldName: 'phoneNumber',
    //     cellstyle: { width: '200px' },
    //     type: 'text',
    //     clsName: 'column-text-start',
    //     id: 11
    // },
    // {
    //     titleCode: 'EMAILID',
    //     colName: 'Email ID',
    //     fieldName: 'emailId',
    //     cellstyle: { width: '200px' },
    //     type: 'text',
    //     clsName: 'column-text-start',
    //     id: 12
    // },
];

export const ESTATE_ADDITIONAL_DETAILS = [
    {
        formData: [
            {
                title: 'Total no of plots',
                titleCode: 'TOTAL_NO_OF_PLOTS',
                field: 'totalNoOfPlotsDerrived',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Average size of plots',
                titleCode: 'AVERAGE_SIZE_OF_PLOTS',
                field: 'avgSizeOfPlotsDerrived',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Total no of alloted plots',
                titleCode: 'TOTAL_NO_OF_ALLOTED_PLOTS',
                field: 'totalNoOfAllottedPlotsDerrived',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            {
                title: 'Total no of vacant plots',
                titleCode: 'TOTAL_NO_OF_VACANT_PLOTS',
                field: 'totalNoOfVacantPlotsDerrived',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            },
            // {
            //     title: 'Total Land Area (in hectares)',
            //     titleCode: 'TOTAL_LAND_AREA',
            //     field: 'totalLandAreaHectares',
            //     type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
            //     isDisabled: true,
            //     class: 'col-sm-4'
            // },
            {
                title: 'Total Vacant Industrial Area (in hectares)',
                titleCode: 'TOTAL_VACANT_INDUSTRIAL_AREA',
                field: 'totalVacantIndustrialAreaDerrived',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                class: 'col-sm-4'
            }
            // {
            //     title: 'Sector',
            //     titleCode: 'SECTOR',
            //     field: 'sector',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isDisabled: true,
            //     class: 'col-sm-4'
            // },
            // {
            //     title: 'Name of Nodal Officer for the Park',
            //     titleCode: 'NODAL_OFFICER',
            //     field: 'nodalOfficer',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isDisabled: true,
            //     class: 'col-sm-4'
            // },
            // {
            //     title: 'Designation',
            //     titleCode: 'DESIGNATION',
            //     field: 'designation',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isDisabled: true,
            //     class: 'col-sm-4'
            // },
            // {
            //     title: 'Phone No',
            //     titleCode: 'PHONE_NO',
            //     field: 'phoneNo',
            //     type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
            //     isDisabled: true,
            //     class: 'col-sm-4'
            // },
            // {
            //     title: 'Email ID',
            //     titleCode: 'EMAIL_ID',
            //     field: 'emailId',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     isDisabled: true,
            //     class: 'col-sm-4'
            // }
        ]
    }
];

export const getEstateDetails = (values: any) => {
    let estateDetails: any = {};
    // estateDetails['uuid']=
    //  estateDetails['estateId']=
    estateDetails['isUpdatable'] = values.isUpdatable;
    estateDetails['plotRate'] = +values.plotRate?.toString().replace(/,/g, '');
    estateDetails['districtCode'] = values.districtCode;
    estateDetails['districtName'] = values.district?.name;
    estateDetails['census2011DistrictCode'] = values.census2011DistrictCode;
    estateDetails['subDistrict'] = values.subDistrict;
    estateDetails['villageMasterDetailsList'] = values.villageMasterDetailsList;
    //    estateDetails['landType']=
    //    estateDetails['industrialType']=
    estateDetails['polutionControlCategoryAllowed'] = values.polutionControlCategoryAllowed;
    estateDetails['totalLandAreaHectares'] = +values.totalLandAreaHectares;
    estateDetails['sector'] = values.sector;
    estateDetails['nearestNationalHighway'] = values.nearestNationalHighway;
    estateDetails['distanceFromNHKm'] = +values.distanceFromNHKm;
    estateDetails['nearestStateHighway'] = values.nearestStateHighway;
    estateDetails['distanceFromSHKm'] = +values.distanceFromSHKm;
    estateDetails['nearestRailwayStation'] = values.nearestRailwayStation;
    estateDetails['distanceFromRailwayStationKm'] = +values.distanceFromRailwayStationKm;
    estateDetails['nearestAirport'] = values.nearestAirport;
    estateDetails['distanceFromAirportKm'] = +values.distanceFromAirportKm;
    estateDetails['nearestSeaport'] = values.nearestSeaport;
    estateDetails['distanceFromSeaportKm'] = +values.distanceFromSeaportKm;
    estateDetails['nearestIcdDryPort'] = values.nearestIcdDryPort;
    estateDetails['distanceFromDryPortIcdKm'] = +values.distanceFromDryPortIcdKm;
    //  estateDetails['fieldOfficeName']=
    // estateDetails['fieldOfficeAddress'] = values?.fieldOfficeAddress;
    estateDetails['distanceFromFieldOfficeKm'] = +values?.distanceFromFieldOfficeKm;
    estateDetails['nearestPoliceStationName'] = values.nearestPoliceStationName;
    estateDetails['nearestPoliceStationDistanceKm'] = +values.nearestPoliceStationDistanceKm;
    estateDetails['nearestFireStationName'] = values.nearestFireStationName;
    estateDetails['nearestFireStationDistanceKm'] = +values.nearestFireStationDistanceKm;
    estateDetails['nearestBankName'] = values.nearestBankName;
    estateDetails['nearestBankDistanceKm'] = +values.nearestBankDistanceKm;
    estateDetails['nearestHospitalName'] = values.nearestHospitalName;
    estateDetails['nearestHospitalDistanceKm'] = +values.nearestHospitalDistanceKm;
    estateDetails['electricityPowerAvailability'] = values.electricityPowerAvailability;
    estateDetails['electricityPowerCapacityKva'] = +values.electricityPowerCapacityKva;
    estateDetails['electricityPowerUtilizationPct'] = +values.electricityPowerUtilizationPct;
    estateDetails['waterAvailability'] = values.waterAvailability;
    estateDetails['waterCapacityMld'] = +values.waterCapacityMld;
    estateDetails['waterUtilizationPct'] = +values.waterUtilizationPct;
    estateDetails['naturalGasAvailability'] = values.naturalGasAvailability;
    estateDetails['naturalGasCapacityMbh'] = +values.naturalGasCapacityMbh;
    estateDetails['naturalGasUtilizationPct'] = +values.naturalGasUtilizationPct;
    estateDetails['stpAvailability'] = values.stpAvailability;
    estateDetails['stpCapacityMld'] = +values.stpCapacityMld;
    estateDetails['stpUtilizationPct'] = +values.stpUtilizationPct;
    estateDetails['electricPoleAvailability'] = values.electricPoleAvailability;
    estateDetails['powerSubStationAvailability'] = values.powerSubStationAvailability;
    estateDetails['powerSubStationCapacityKva'] = +values.powerSubStationCapacityKva;
    estateDetails['powerSubStationUtilizationPct'] = +values.powerSubStationUtilizationPct;
    estateDetails['wtpAvailability'] = values.wtpAvailability;
    estateDetails['wtpCapacityMld'] = +values.wtpCapacityMld;
    estateDetails['wtpUtilizationPct'] = +values.wtpUtilizationPct;
    estateDetails['ictAvailability'] = values.ictAvailability;
    estateDetails['ictBandwidthMbps'] = +values.ictBandwidthMbps;
    estateDetails['swDisposalFacilityAvailability'] = values.swDisposalFacilityAvailability;
    estateDetails['swDisposalFacilityCapacityTdp'] = +values.swDisposalFacilityCapacityTdp;
    estateDetails['swDisposalFacilityUtilizationPct'] = +values.swDisposalFacilityUtilizationPct;
    estateDetails['accommodationForWorkers'] = values.accommodationForWorkers;
    estateDetails['availiabilityOfDesignatedTruckParkingArea'] = values.availiabilityOfDesignatedTruckParkingArea;
    //  estateDetails['totalNoOfPlots']=
    //  estateDetails['avgSizeOfPlots']=
    //  estateDetails['totalNoOfAllottedPlots']=
    //  estateDetails['totalNoOfVacantPlots']=
    //  estateDetails['totalVacantIndustrialArea']=
    //  estateDetails['status']=

    return estateDetails;
};
