import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { FieldArray, useFormikContext } from 'formik';
import { LOA_CORRESPONDENCE, LOA_FIELDS_I, LOA_FIELDS_II, PBG_DATA_COLUMN, PBG_POPUP_FIELDS } from './WorkOrder.const';
import Editor from '../../../../components/ContentEditor';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { PBG_DETAILS_VALIDATION, WO_DETAILS_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { getFirstErrorKey } from '../../../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { cross, DeleteWarning } from '../../../../utils/Logo';
import _ from 'lodash';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import DeletePopUpModal from '../../../../components/moduleCommonFormik/DeletePopUp.component';
import { getDocumentDetails } from '../ContractCreationScreens/ContractCreation.const';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';

const WorkOrderCorrespondence = (props: any) => {
    const {
        setContent,
        content,
        readOnly,
        show,
        setShow,
        loaDetailsList,
        loaRowData,
        modalDetails,
        t,
        setModalDetails,
        setReminderPopUp,
        pbgData,
        setPbgData,
        isViewOnly,
        onRowClick,
        tableData,
        isPBGRequired
    } = props;
    const [openSection, setOpenSection] = useState<string>('loaDetails');
    const [isEditing, setIsEditing] = useState<any>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [openIndex, setOpenIndex] = useState<any>(null);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [rowDelId, setRowDelId] = useState<any>(null);

    const { values, touched, errors, setFieldValue, setTouched, setValues, setFieldTouched } = useFormikContext<any>();

    const onSectionChange = async (currentSection: string) => {
        try {
            if ((currentSection === 'pbgDetails' || currentSection === 'workOrderNotes') && !isViewOnly && openSection !== 'pbgDetails' && openSection !== 'workOrderNotes') {
                await WO_DETAILS_VALIDATION.validate(values, { abortEarly: false, context: values });
            }
            if (currentSection === 'workOrderNotes' && Object.keys(touched).length && !isViewOnly && values.isUpdatable) {
                setReminderPopUp(true);
            }
            let previousSection = openSection;
            setOpenSection(previousSection === currentSection ? '' : currentSection);
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const renderLoaDetails = () => {
        const loaFieldsI = isViewOnly
            ? LOA_FIELDS_I.map((val: any) => ({
                  ...val,
                  formData: val.formData.map((item: any) => {
                      if (item.field !== 'projectName') {
                          return { ...item, class: 'col-sm-4' };
                      } else {
                          return { ...item };
                      }
                  })
              }))
            : LOA_FIELDS_I;

        const loaFieldsII = isViewOnly
            ? LOA_FIELDS_II.map((val: any) => ({
                  ...val,
                  formData: val.formData.map((item: any) => {
                      if (item.field !== 'projectName') {
                          return { ...item, class: 'col-sm-4' };
                      } else {
                          return { ...item };
                      }
                  })
              }))
            : LOA_FIELDS_II;
        return (
            <>
                <Card.Body>
                    {loaFieldsI.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                    {loaFieldsII.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const renderWorkOrderNotes = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setContent} content={content} readOnly={readOnly} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    const pbgFields =
        fieldDisabled && values.isUpdatable
            ? PBG_POPUP_FIELDS.map((val: any) => ({
                  ...val,
                  formData: [
                      ...val.formData.map((value: any) => ({
                          ...value,
                          isDisabled: true
                      }))
                  ]
              }))
            : PBG_POPUP_FIELDS;

    const resetPbg = () => {
        setValues({
            ...values,
            accountNo: '',
            refNo: '',
            amount: ''
        });
    };
    const renderPbgDetails = () => {
        const addPbg = () => {
            const val = _.cloneDeep(values);

            setIsEditing(false);
            const keysToDelete = ['bankName', 'pgNo', 'amount', 'documentPbg'];
            keysToDelete.forEach(key => {
                delete val[key];
            });
            setValues({ ...val });
            setTouched({});
            setShow(true);
            setDisabled(false);
            setOpenIndex(null);
        };

        const savePbgRowData = async () => {
            try {
                await PBG_DETAILS_VALIDATION.validate(values, { abortEarly: false, context: values });
                setShow(false);
            } catch (validationError) {
                let errorField = getFirstErrorKey(errors);
                if (errorField) {
                    await setFieldTouched(errorField, true, true);
                }
                let errMsg = 'Please fill in all the required details!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            const newUuid = uuidv4();
            const pbgRowData: any = {
                amount: +values?.amount,
                pgNo: values?.pgNo,
                bankName: values?.bankName,
                docName: values?.documentPbg?.documentName,
                pbgDocRefId: values?.documentPbg?.docReferenceId,
                rowId: isEditing ? values.rowId || values.uuid : newUuid
            };
            if (isEditing) {
                const updatedData = pbgData?.map((data: any) => {
                    if (data.rowId === isEditing) {
                        return { ...data, ...pbgRowData };
                    } else return data;
                });
                setPbgData([...updatedData]);
            } else {
                setPbgData([...pbgData, pbgRowData]);
            }
            setShow(false);
        };

        const onDeletePbgData = async (rowId = null) => {
            setShowDeleteModal(true);
            setRowDelId(rowId);
        };

        const handleModalDelete = async (rowId: any) => {
            try {
                let newData = pbgData?.filter((data: any) => data.rowId !== rowId);
                setPbgData(newData);
                setShow(false);
                setShowDeleteModal(false);
                setIsEditing(false);
            } catch (e: any) {}
        };

        const onPbgDataEdit = async (data: any, status: boolean = false) => {
            const detailObj: any = pbgData.find((item: any, _: number) => item.rowId === data.rowId);
            const index: any = pbgData.findIndex((item: any, _: number) => item.rowId === data.rowId);
            const pbgDocument = detailObj?.pbgDocRefId && (await getDocumentDetails(detailObj?.pbgDocRefId, DOCUMENT_TYPE.OTHER));
            setOpenIndex(index > -1 ? index + 1 : null);
            if (!detailObj) return;
            const formData = {
                // ...detailObj,
                amount: detailObj?.amount,
                pgNo: detailObj?.pgNo,
                bankName: detailObj?.bankName,
                // documentPbg: detailObj?.docName,
                documentPbg: pbgDocument,
                rowId: detailObj.rowId || detailObj.uuid || null
                // uuid: detailObj.uuid || detailObj.rowId || null
            };
            setIsEditing(detailObj.rowId || detailObj.uuid);
            setValues({ ...values, ...formData });
            setShow(true);
            setDisabled(status);
        };

        const onView = (status: boolean, data: any) => {
            setDisabled(status);
            setFieldDisabled(true);
            onPbgDataEdit(data, status);
        };

        const updatedColumns = PBG_DATA_COLUMN.map((col: any) => {
            if (col.type === 'action') {
                return {
                    ...col,
                    actions: values.isUpdatable ? ['edit', 'view', 'delete'] : ['view']
                };
            }
            return col;
        });

        return (
            <div className='px-3 pt-4 text-end '>
                {values.isUpdatable ? <GenericButtons ClassName='mb-3 me-0' text={t('ADD_PBG', '+ Add PBG')} handleClick={addPbg} variant='primary' /> : <></>}

                <OwnershipTable
                    column={updatedColumns}
                    tableRowData={pbgData}
                    hideSearchBar={true}
                    deleteOwnershipDetails={onDeletePbgData}
                    additionalMethod={onPbgDataEdit}
                    updateOwnershipDataStatus={onView}
                />

                <GenericPopupModal
                    isVisible={show}
                    primaryAction={savePbgRowData}
                    secondaryAction={() => {
                        resetPbg();
                        setFieldDisabled(false);
                    }}
                    size={'lg'}
                    primaryBtnTitle={t('Save', 'Save')}
                    secondaryBtnTitle={t('RESET', 'Reset')}
                    isPrimaryBtn={!disabled}
                    isSecondaryBtn={!fieldDisabled ? true : false}
                    title={`${openIndex ? (disabled ? 'View' : 'Edit') : 'Add'} PBG Details`}
                    subTitle={'pbg details'}
                    onClose={() => {
                        setFieldDisabled(false);
                        setShow(false);
                    }}
                    children={
                        <>
                            {pbgFields.map((val: any) => (
                                <GenericFormCard {...val} styles={{ background: '#F5F5F6', padding: '10px 16px' }} />
                            ))}
                        </>
                    }
                />
                <DeletePopUpModal
                    secondaryAction={() => {
                        setShowDeleteModal(false);
                    }}
                    primaryAction={() => {
                        handleModalDelete(rowDelId);
                    }}
                    isVisible={showDeleteModal}
                    title={'Delete File?'}
                    img={DeleteWarning}
                    primaryBtnTitle={t('DELETE', 'Delete')}
                    secondaryBtnTitle={t('CANCEL', 'Cancel')}
                    children={
                        <>
                            <div className='p-4' style={{ backgroundColor: '#F5F5F6' }}>
                                <div className='font-inter fw-bold lh-22'>
                                    Are you sure you want to delete this file? <br />
                                    You can't undo this action!
                                </div>
                            </div>
                        </>
                    }
                />
            </div>
        );
    };

    const renderDocTable = () => {
        return (
            <>
                <div className='px-4 pt-4'>
                    <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tableData} additionalMethod={onRowClick} />
                </div>
            </>
        );
    };

    const WORKS_CORRESPONDENCE_UPDATED = isPBGRequired
        ? [
              ...LOA_CORRESPONDENCE,
              {
                  title: `${isViewOnly ? '(C) Documents' : '(C) Work Order Notes'}`,
                  field: 'workOrderNotes'
              }
          ]
        : [
              {
                  title: '(A) LOA Details',
                  field: 'loaDetails'
              },
              {
                  title: `${isViewOnly ? '(B) Documents' : '(B) Work Order Notes'}`,
                  field: 'workOrderNotes'
              }
          ];

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {WORKS_CORRESPONDENCE_UPDATED.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === '(A) LOA Details' && openSection === 'loaDetails' && renderLoaDetails()}
                                {val.title === '(B) PBG Details' && openSection === 'pbgDetails' && renderPbgDetails()}
                                {(val.title === '(C) Work Order Notes' || val.title === '(B) Work Order Notes') && openSection === 'workOrderNotes' && renderWorkOrderNotes()}
                                {(val.title === '(C) Documents' || val.title === '(B) Documents') && openSection === 'workOrderNotes' && renderDocTable()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default WorkOrderCorrespondence;
