import React from 'react';
import { useTranslation } from 'react-i18next';

function SideCard(props: any) {
    const { t } = useTranslation();
    const { steps, activeIndex, visitedIndex, handleStepClick, visited } = props;
    const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const stepsDest = steps.map((value: any, ind: number) => ({ ...value }));

    return (
        <>
            {stepsDest.map((value: any, key: number) => {
                return (
                    <div
                        onClick={() => handleStepClick(value.index)}
                        className={`col-sm-12 font-inter py-2 side_tab fs-5 ${
                            activeIndex === value.index ? 'active1' : activeIndex > value.index || !!value.isVisited || visitedIndex >= value.index ? 'visited' : 'tabs'
                        }`}
                    >
                        <span className='fs-6 col-1 activeKey  tabsKey d-flex justify-content-center align-items-center visitedKey'>
                            {props?.indexType === 'alphabets' ? alphabets[key] : props?.indexType === 'custom' ? props.indexArray[key] : 0 + '' + value.index}
                        </span>
                        <div className='fs-6 d-inline col-9  ms-2'>{value.titleCode ? t(value.titleCode, value.name) : value.name}</div>
                        {/* <div className="fs-6 d-inline  ms-2">{t(value.titleCode, `${value.name}`)}</div> */}
                    </div>
                );
            })}
        </>
    );
}

export default SideCard;
