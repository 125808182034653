import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';

export const EXISTING_CO_DETAILS = [
    {
        formData: [
            {
                titleCode: 'EXISTING_CERTIFICATE_CO',
                title: 'Existing Certificate of Commercial Operation',
                field: 'existingCertificateOfCO',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: 'COMMERCIAL_CERTIFICATE',
                    referenceType: ''
                }
            },
            {
                titleCode: 'EXPIRY_DATE',
                title: 'Expiry Date',
                field: 'expiryDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const NEW_CO_DETAILS = [
    {
        formData: [
            {
                titleCode: 'NEW_CERTIFICATE_CO',
                title: 'New Certificate of Commercial Operation',
                field: 'newCertificateOfCO',
                isRequired: true,
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: 'COMMERCIAL_CERTIFICATE',
                    referenceType: ''
                }
            }
        ]
    }
];

export const CO_CORRESPONDENCE_CARD = [
    {
        titleCode: 'A_LESSEE_DETAILS',
        title: 'A - Lessee Details',
        field: 'lesseeDetails'
    },
    {
        titleCode: 'B_PLOT_DETAILS',
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        titleCode: 'C_CERTIFICATE_OF_COMM_OPS',
        title: 'C - Certificate of Commercial Operation',
        field: 'certificateDetails'
    }
];
