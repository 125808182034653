// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m-t-50 {
    margin-top: -50px;
}

.m-t-13 {
    margin-top: -13px;
}

.estate-registry-modal .modal.show .modal-dialog {
    transform: translate(0, -50px) !important;
}

.estate-registry-modal .modal-footer {
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    border-radius: 0;
}

.estate-fieldoffice-hdr {
    background: #e0f1da;
    padding: 16px;
    margin: 16px;
    border-radius: 12px;
}`, "",{"version":3,"sources":["webpack://./src/features/modules/EstateManagement/EstateRegistry/EstateListPageStyles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,yFAAyF;IACzF,wEAAwE;IACxE,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".m-t-50 {\n    margin-top: -50px;\n}\n\n.m-t-13 {\n    margin-top: -13px;\n}\n\n.estate-registry-modal .modal.show .modal-dialog {\n    transform: translate(0, -50px) !important;\n}\n\n.estate-registry-modal .modal-footer {\n    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);\n    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);\n    border-radius: 0;\n}\n\n.estate-fieldoffice-hdr {\n    background: #e0f1da;\n    padding: 16px;\n    margin: 16px;\n    border-radius: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
