import React from 'react';
import { Image } from 'react-bootstrap';
import { logo_white } from '../../utils/Logo';
import { map_image } from '../../utils/Images';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import poweredByDigit from '../../assets/logos/poweredByDigit.svg';

export default function FooterComponent(props: any) {
    const { onlyCopyright } = props;
    const { t } = useTranslation();
    const address = ' Goa Industrial Development Corporation, Panaji, Goa - 403 001';
    const formattedAddress = address.replace(/ /g, '+');
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;

    return (
        <div className='footer-container'>
            <div className='container-flex'>
                <footer className='text-center text-lg-start text-white' style={{ backgroundColor: '#323E4A' }}>
                    <div className='px-5 p-4 pb-0'>
                        {!onlyCopyright && (
                            <>
                                <section className=''>
                                    <div className='row'>
                                        <div className='col-md-2 col-lg-2 col-xl-2; mt-2'>
                                            <Image src={logo_white} height={88} width={140} />
                                        </div>

                                        <hr className='w-100 clearfix d-md-none' />

                                        <div className='col-md-2 col-lg-2 col-xl-3 mx-auto mt-3'>
                                            <h6 className='text fw-500 font-inter-md'>{t('USEFUL_LINKS', 'Useful Links')}</h6>
                                            <p>
                                                <a href='https://www.goaipb.goa.gov.in/' target='_blank' className='text-white text-decoration-none font-Karla'>
                                                    {t('GOA_IPB', 'Goa-IPB')}
                                                </a>
                                            </p>
                                            <p>
                                                <a href='https://ifbgoa.goa.gov.in/en/home' target='_blank' className='text-white text-decoration-none font-karla-med'>
                                                    {t('FACTORIES_AND_BOILERS', 'Factories & Boilers')}
                                                </a>
                                            </p>
                                            <p>
                                                <a href='https://dfes.goa.gov.in/' target='_blank' className='text-white text-decoration-none font-karla-med'>
                                                    {t('FIRE_SAFETY_DEPARTMENT', 'Fire safety Department')}
                                                </a>
                                            </p>
                                            <p>
                                                <a href='https://goaspcb.gov.in/' target='_blank' className='text-white text-decoration-none font-karla-med'>
                                                    {t('GOA_STATE_POLLUTION_CONTROL_BOARD', 'Goa State Pollution Control Board')}
                                                </a>
                                            </p>
                                            <p>
                                                <a href='https://goaexcise.gov.in/' target='_blank' className='text-white text-decoration-none font-karla-med'>
                                                    {t('EXCISE', 'Excise')}
                                                </a>
                                            </p>
                                            <p>
                                                <a href='https://tcp.goa.gov.in/' target='_blank' className='text-white text-decoration-none font-karla-med'>
                                                    {t('TOWN_AND_COUNTRY_PLANNING', 'Town and Country Planning Department')}
                                                </a>
                                            </p>
                                        </div>
                                        <hr className='w-100 clearfix d-md-none' />

                                        <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mt-3'>
                                            <h6 className='text  font-inter-med'>{t('HELP', 'Help')}</h6>

                                            <p>
                                                <Link to='/goa-idc/faq' className='text-white text-decoration-none font-karla-med'>
                                                    {t('FAQS', 'FAQs')}
                                                </Link>
                                                {/* <a href="https://tcp.goa.gov.in/" target="_blank" className="text-white text-decoration-none font-karla-med">FAQs</a> */}
                                            </p>
                                            <p>
                                                <Link to='/goa-idc/disclaimer' className='text-white text-decoration-none font-karla-med'>
                                                    {t('DISCLAIMER', 'Disclaimer')}
                                                </Link>
                                            </p>
                                            <p>
                                                <Link to='/goa-idc/terms&conditions' className='text-white text-decoration-none font-karla-med'>
                                                    {t('TERMS_AND_CONDITIONS', 'Terms & Conditions')}
                                                </Link>
                                            </p>
                                            <p>
                                                <Link to='/goa-idc/privacypolicy' className='text-white text-decoration-none font-karla-med'>
                                                    {t('PRIVACY_POLICY', 'Privacy Policy')}
                                                </Link>
                                            </p>
                                        </div>
                                        <hr className='w-100 clearfix d-md-none' />

                                        <div className='col-md-3 col-lg-3 col-xl-3 mx-auto mt-3'>
                                            <h6 className='text  font-inter-med'>{t('LOCATE_US', 'Locate Us')}</h6>
                                            <Link to={mapsUrl} style={{ textDecoration: 'none', color: '#fff' }} target='_blank' rel='noopener noreferrer'>
                                                <Image src={map_image} />
                                                <p className='font-karla-med pt-2'>
                                                    {t('GOA_IDC_ADDRESS_LINE1', ' Goa Industrial Development Corporation ')} <br />
                                                    {t('GOA_IDC_ADDRESS_LINE2', ' Plot No. 13A-2, EDC Complex Patto Plaza ')}
                                                    <br />
                                                    {t('GOA_IDC_ADDRESS_LINE3', ' Panaji, Goa - 403 001 ')}
                                                </p>
                                                <div className='font-karla-med'>{t('OPEN_MAP', 'Open Map')}</div>
                                            </Link>
                                        </div>
                                    </div>
                                </section>

                                <hr className='my-3' />
                            </>
                        )}

                        <section className='p-3 pt-0 pb-4'>
                            <div className='row d-flex align-items-center justify-content-center'>
                                <div className='col-md-12 col-lg-12 d-flex justify-content-between align-items-center'>
                                    <div className='py-3 font-karla-med '>
                                        {t('GOA_IDC_ALL_RIGHTS_RESERVED', '© 2024 Goa Industrial Development Corporation. All rights reserved.')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                        {t('LAST_UPDATED', 'Last Updated : January 25, 2024')}
                                    </div>
                                    <div className='py-3'>
                                        <Image src={poweredByDigit} alt='Powered by Digit' height={35} width={70} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </footer>
            </div>
        </div>
    );
}
