import { ReactElement } from 'react';
import Modal from 'react-bootstrap/Modal';
import GenericButtons from '../UI components/Buttons/GenericButtons';

interface modalProps {
    titleCode?: any;
    // title: string;
    title: any;
    children?: ReactElement;
    secondaryBtnTitle?: string;
    primaryBtnTitle?: string;
    secondaryAction(): void;
    primaryAction(): void;
    isVisible: boolean;
    subTitle?: string;
    size?: any;
    display?: string;
    isCentered?: boolean;
    class?: string;
    isPrimaryBtn?: boolean;
    isSecondaryBtn?: boolean;
    isSecondaryDisabled?: boolean;
    isPrimaryDisabled?: boolean;
    onClose?: () => void;
    image?: any;
}

export default function GenericPopupModal(props: modalProps) {
    return (
        <>
            <Modal
                size={props?.size ? props?.size : 'sm'}
                show={props?.isVisible}
                centered={props.isCentered}
                dialogClassName={props.class}
                onHide={props.onClose ? props.onClose : props.secondaryAction}
                backdrop='static'
            >
                <Modal.Header closeButton>
                    <div>
                        <div className='fw-bold fs-5 font-inter'>{props?.title}</div>
                        <div className='font-15 font-karla'>{props?.subTitle}</div>
                    </div>
                </Modal.Header>
                <Modal.Body className='mb-0'>{props?.children}</Modal.Body>

                <Modal.Footer className={`${props.display}`}>
                    <div className='d-flex justify-content-end  container' style={{ padding: 15 }}>
                        {props?.isSecondaryBtn && (
                            <GenericButtons text={props?.secondaryBtnTitle} size='sm' ClassName='me-3' disabled={props?.isSecondaryDisabled} handleClick={props?.secondaryAction} />
                        )}
                        {props?.isPrimaryBtn && <GenericButtons text={props?.primaryBtnTitle} variant='primary' size='sm' disabled={props?.isPrimaryDisabled} handleClick={props?.primaryAction} />}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

GenericPopupModal.defaultProps = {
    title: '',
    subTitle: '',
    size: 'md',
    isPrimaryBtn: true,
    isSecondaryBtn: true,
    isSecondaryDisabled: false,
    isPrimaryDisabled: false
};
