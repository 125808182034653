import { Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StatusAlertPopup from './StatusAlertPopup';
import { cross } from '../../utils/Logo';

interface CheckboxFieldProps {
    titleCode?: any;
    field: string;
    title: string;
    type?: string;
    isRequired?: boolean;
    index?: any;
    handleOnAssignmentChange?: (index: number, field: string, value: any) => void;
    additionalSection?: string;
    module?: string;
    isDisabled?: boolean;
}

export default function GenericCheckboxField(props: CheckboxFieldProps) {
    const { handleOnAssignmentChange, index, additionalSection, field, isRequired, module } = props;
    const [modalDetails, setModalDetails] = useState<any>({});
    const { t } = useTranslation();

    const { values, errors, touched, setFieldTouched, handleChange, setFieldValue } = useFormikContext<any>();

    const handleModalClose = (type: string, msg?: string) => {
        setModalDetails({});
    };

    const handleOnCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (additionalSection) {
            handleOnAssignmentChange && handleOnAssignmentChange(index, field, e.target.checked);
        } else handleChange(e);

        if (module === 'usermanagement') {
            if (field === 'isPrimary') {
                if (values?.isPrimary !== undefined && e.target.checked === true) {
                    let errMsg = t('PRIMARY_ASSIGNMENT_SINGLE', 'Primary assignment should be single');
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });

                    handleOnAssignmentChange && handleOnAssignmentChange(index, field, false);
                    return;
                }
            }
            if (values?.assignments[index]?.isPrimary === true) {
                setFieldValue('isPrimary', index);
            } else if (values?.assignments[index]?.isPrimary === false && index === values?.isPrimary) {
                setFieldValue('isPrimary', undefined);
            }
        }
    };

    return (
        <>
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.para)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.para)}
            />
            <label htmlFor={field} className='pb-4 file-form-label d-flex align-items-center'>
                {props.titleCode ? t(props.titleCode) : props.title}
                {isRequired && <span className='mandatory-indicator'>*</span>}

                {!additionalSection ? (
                    <>
                        <Field type='checkbox' name={field} className='mx-4' id={props?.field} />
                    </>
                ) : (
                    <>
                        <Field
                            type='checkbox'
                            name={field}
                            id={props?.field}
                            className={`mx-4  ${(touched as any)?.[additionalSection]?.[index]?.[field] && (errors as any)?.[additionalSection]?.[index]?.[field] ? 'is-invalid' : ''}  ${
                                (touched as any)?.[additionalSection]?.[index]?.[field] && props.isRequired && !(errors as any)?.[additionalSection]?.[index]?.[field] ? 'is-valid' : ''
                            }`}
                            onChange={handleOnCheck}
                            onBlur={() => setFieldTouched(`${additionalSection}[${index}].${props.field}`, true)}
                            // value={values[additionalSection][index][field]}
                            checked={values[additionalSection]?.[index]?.[field]}
                            disabled={!values.isUpdatable || props?.isDisabled}
                        />
                        {/* <ErrorMessage name={`${additionalSection}[${index}].${props.field}`}>{(msg) => <span className="text-danger font-12">{msg}</span>}</ErrorMessage> */}
                    </>
                )}
            </label>
        </>
    );
}
