import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import {
    fetchLeaseDurationDoc,
    getCheckList,
    getHistory,
    getNextActions,
    getSuccessMessage,
    getWorkflowDocumentSearch,
    onAttachDocument,
    paymentCheck
} from '../../EstateManagement/EstateManagement.controller';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { Card, Form } from 'react-bootstrap';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../EstateManagement/LeaseDeed/LeaseDeed.const';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import ScrollToTop from '../../../../components/ScrollToTop';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import PopUps from '../../../../components/PopUps';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import { devUrl, endpoints, estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../../utils/Logo';
import MortgageCorrespondence from './MortgageCorrespondence';
import { PlotDropdownData } from '../../UserManagement/types';
import { dropdownDataOptions, getEntityDocumentDetails } from '../../Migration/Migration.controller';
import { setMortgageLesseeData, setMortgagePlotDetails } from '../Mortgage.const';
import axios from 'axios';
import { entityDocumentData } from '../../EstateManagement/LeaseDeed/LeaseDeed.controller';
import { TENANT_ID } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { MORTGAGE_WF_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { getFirstErrorKey } from '../../../../utils/utils';
import { EsignModal } from '../../../commonPages/EsignModal';

const MortgageWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const totalPages = 3;
    const [modalData, setModalData] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [mortgageData, setMortgageData] = useState<any>(null);
    const [dropdownDataPlot, setDropdownDataPlot] = useState<PlotDropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [isChecked, setIsChecked] = useState([true, true, true]);
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [transactionDetails, setTransactionDetails] = useState<any>(null);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [content, setContent] = useState('');
    const [checklistTableData, setChecklistTableData] = useState();
    const [tabelData, setTabelData] = useState<any>([]);
    const [updatedBankMortgageDetail, setUpdatedBankMortgageDetail] = useState<any>([]);

    const [refId, setRefId] = useState<any>(null);
    const [approvedEsignData, setApprovedEsignData] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);

    let navigate = useNavigate();
    const { t } = useTranslation();
    const location: any = useLocation();
    let user = localStorage.getItem('currentUserAssignment');
    const [searchParams] = useSearchParams();
    const applicationNumber: any = decodeURIComponent(searchParams.get('applicationNumber') || '');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: MORTGAGE_WF_VALIDATION
    });
    const { values, setValues, errors, setFieldTouched } = formik;

    // for details
    useEffect(() => {
        if (note && action && mortgageData && nextValidAction) {
            onHandlePrimaryAction({ action: action }, note);
        }
    }, [mortgageData, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        const userRequestInfo = requestInfo();
        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);
            setRefId(approveEsignRes.data.entityRefDocuments[0].docReferenceId);
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.map((item: any) => `${item.uuid}=${item.docReferenceId}`);
            setApprovedEsignData(approvedEsignData);
            setActionAfterEsign(action);
            setEsignModal(true);

            console.log('appr', payload);
            console.log('refId', refId, approveEsignRes.data.entityRefDocuments, 'data', approvedEsignData);
            console.log('appr', approveEsignRes);

            // return;

            // if (window.location.protocol !== 'http:') {
            // let url =
            //     window.location.origin +
            //     `/goa-idc/estate/mortgage-workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
            //         ','
            //     )}&action=${action}`;
            //     url = btoa(url);
            //     const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
            //         userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
            //     }`;
            //     await axios.get(esignUrl);

            //     window.open(esignUrl, '_self');
            // } else {
            //     let url =
            //         window.location.origin +
            //         `/goa-idc/estate/mortgage-workflow?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
            //             ','
            //         )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
            //     console.log('url', url, documentsUuid);
            //     window.open(url, '_self');
            // }
        } catch (e) {
            console.log('esign error', e);
        }
    };

    const signDocument = () => {
        let url =
            window.location.origin +
            `/goa-idc/estate/mortgage-workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
                ','
            )}&action=${actionAfterEsign}`;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
    };

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.searchMortgage + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    if (data) {
                        setMortgageData(data?.MortgageDetails[0]);
                        setContent(data.MortgageDetails[0]?.estateApplications?.content);
                        setFileStoreId(data.MortgageDetails[0]?.estateApplications?.signedConsolidatedDocRefId);
                    }
                    setIsLoading(false);
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [location.search]);
    console.log('mordata', mortgageData);

    useEffect(() => {
        const reverseDocumentDetails = documentDetails?.reverse();
        const tableData = async () => {
            const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${ind + 1}` };
            });
            const reverseData = responseTableData.reverse();
            setTabelData(reverseData);
        };
        if (activeIndex === 2) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                setIsLoading(true);
                const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction.businessService);
                setDocumentDetails(docs);

                const checklistData = await getCheckList(
                    mortgageData?.estateApplications?.enterpriseEntity?.uuid,
                    mortgageData?.estateApplications?.entityRefDetails?.plotId,
                    nextValidAction.businessService
                );
                setChecklistTableData(checklistData);
                console.log(checklistData);

                const relatedApplicationDocument = await POST(
                    estateEndpoints.relatedApplicationSearch +
                        `?entityIds=${mortgageData?.estateApplications?.enterpriseEntity?.uuid}&plotIds=${mortgageData?.entityRefDetails?.plotId}&applicationNos=${applicationNumber}`,
                    { RequestInfo: requestInfo() }
                );
                setRelatedApplicationDocument(relatedApplicationDocument);
                setIsLoading(false);
            } catch (e: any) {
                console.log(e);
                setIsLoading(false);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (mortgageData && nextValidAction) {
            getCorrespondenceDocument();
            getPaymentData();
            setApplicationResponseData(mortgageData, applicationNumber);
        }
    }, [nextValidAction, mortgageData]);

    const setApplicationResponseData = async (mortgageResponse: any, applicationNo: any) => {
        try {
            if (mortgageResponse) {
                // const deedDoc = await fetchLeaseDurationDoc(mortgageResponse?.entityRefDetails?.plotId, mortgageResponse?.estateApplications?.enterpriseEntity?.uuid);
                let data =
                    mortgageResponse?.entityRefDetails?.plotId &&
                    (await POST(endpoints.leaseDeedSearch + `?entityIds=${mortgageResponse?.estateApplications?.enterpriseEntity?.uuid}&plotIds=${mortgageResponse?.entityRefDetails?.plotId}`, {
                        RequestInfo: requestInfo()
                    }));
                let deedDoc: any;
                deedDoc = data?.data?.deedDetails[0]?.executedIndentureRefId && (await getEntityDocumentDetails({ docReferenceId: data?.data?.deedDetails[0]?.executedIndentureRefId }));

                const lesseeDetails = await setMortgageLesseeData(mortgageResponse?.estateApplications?.enterpriseEntity);
                const setPlotDetails = await setMortgagePlotDetails(mortgageResponse?.estateApplications?.assignedPlotDetails);
                const PlotUuid = mortgageData?.entityRefDetails?.plotId;
                const entityId = mortgageData?.estateApplications?.enterpriseEntity?.uuid;

                const currentStatusBankDetasils = await Promise.all(
                    mortgageResponse?.bankMortgageDetails
                        ?.filter((val: any) => val?.status === 'CURRENT')
                        .map(async (value: any) => {
                            const bankLetterDoc = value?.bankRefId && (await entityDocumentData(value?.bankRefId));

                            return {
                                bankIFSC: value?.bankIFSC,
                                bankName: value?.bankDetailsResponse?.bank,
                                branchAddress: value?.bankDetailsResponse?.branch,
                                bankLetter: bankLetterDoc && bankLetterDoc?.[0]
                            };
                        })
                );
                console.log(currentStatusBankDetasils);
                const existingStatusBankDetasils = await Promise.all(
                    mortgageResponse?.bankMortgageDetails
                        ?.filter((val: any) => val?.status === 'EXISTING')
                        .map(async (value: any) => {
                            const NocDoc = value?.nocRefId && (await entityDocumentData(value?.nocRefId));

                            return {
                                existingIfscCode: value?.bankIFSC,
                                existingBankName: value?.bankDetailsResponse?.bank,
                                existingBranchAddress: value?.bankDetailsResponse?.branch,
                                existingDateOFNoc: value?.nocDate,
                                existingNocDocument: NocDoc && NocDoc?.[0]
                            };
                        })
                );

                setValues((prev: any) => ({
                    ...prev,
                    ...lesseeDetails,
                    ...setPlotDetails,
                    lesseeDeedOfExtension: deedDoc,
                    entityId,
                    PlotUuid,
                    bankDetails: currentStatusBankDetasils,
                    existingMortgageDetails: existingStatusBankDetasils,
                    mortgageType: mortgageResponse?.typeDisplayName
                }));
            }
        } catch (e) {}
    };
    console.log(values);

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const getPaymentData = async () => {
        try {
            const payCheck = await paymentCheck(
                'PROCESSING_FEE',
                mortgageData?.entityRefDetails?.plotId,
                mortgageData?.estateApplications?.uuid,
                mortgageData?.estateApplications?.enterpriseEntity?.uuid
            );
            setPaymentRowData(payCheck.paymentData);
            setTransactionDetails(payCheck?.transactionDetails);
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const dropdownOption = await dropdownDataOptions();
                setDropdownData(dropdownOption);

                const nextActions = await getNextActions(applicationNumber);
                setNextValidAction(nextActions);

                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {
            console.log(e);
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            estateMortgageDetailsRequest: {
                ...mortgageData,
                bankMortgageDetails: updatedBankMortgageDetail && [...updatedBankMortgageDetail],
                estateApplications: { ...mortgageData.estateApplications, content: content }
            },
            signedDocDetails:
                approvedEsign?.split(',').map((item: any) => {
                    const esignData = item.split('=');
                    return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                }) || null
        };
        if (!values.notes && !note) {
            if (!comment) {
                setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }
        if (!signedFileStoreId && status.isEsignRequired) {
            esign(payload, status.action);
            return;
        }
        try {
            if (designation === 'AM') {
                await MORTGAGE_WF_VALIDATION.validate(values, { abortEarly: false, context: values });
            }
            try {
                await POST(endpoints.estateWorkflowInitiate, payload);
                const successMessage = getSuccessMessage(status?.action, applicationNumber);
                setModalDetails({ show: true, title: 'Success', para: successMessage, img: check, type: 'success', reUpload: 'OK' });
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            console.log(validationError);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        try {
            const bakRefId = values.bankDetails?.map((value: any) => {
                return value?.bankLetter?.docReferenceId ? value?.bankLetter?.docReferenceId : null;
            });
            const newMortgageBankDetails = mortgageData?.bankMortgageDetails
                ?.filter((value: any) => value?.status === 'CURRENT')
                .map((val: any, ind: number) => {
                    return { ...val, bankRefId: bakRefId?.[ind] };
                });

            const exsMortgageBankDetails = mortgageData?.bankMortgageDetails
                ?.filter((value: any) => value?.status === 'EXISTING')
                .map((val: any, ind: number) => {
                    return val;
                });
            console.log(exsMortgageBankDetails);
            console.log(newMortgageBankDetails);
            setUpdatedBankMortgageDetail([...newMortgageBankDetails, ...exsMortgageBankDetails]);
        } catch (e) {}
    }, [values.bankDetails]);
    console.log('mortgage_', updatedBankMortgageDetail);

    const updateMortgage = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            ...mortgageData,
            bankMortgageDetails: updatedBankMortgageDetail && [...updatedBankMortgageDetail],
            estateApplications: { ...mortgageData.estateApplications, content: content }
            // plotId: values?.plotUuid
        };

        try {
            if (designation === 'AM') {
                await MORTGAGE_WF_VALIDATION.validate(values, { abortEarly: false, context: values });
            }
            try {
                const { data } = await POST(endpoints.updateMortgage, payload);
                setContent(data?.MortgageDetails[0]?.estateApplications?.content);
                // setMortgageData(data?.MortgageDetails[0]);

                setModalDetails({ show: true, title: 'Success!', para: 'Data saved successfully', img: check, type: 'update success', reUpload: 'OK', isSaveOnly: true });
            } catch (err: any) {
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    console.log('m', mortgageData);

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={t('APPLICATION_FOR_MORTGAGE_OR_CREATION_OF_CHARGE', 'Application for Mortgage or Creation of Charge')}
                            additionalClass='sub-text-style'
                            bottomSubText={`Type : Mortgage, No : ${applicationNumber} `}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('APPLICATION_FOR_MORTGAGE_OR_CREATION_OF_CHARGE', 'Application for Mortgage or Creation of Charge')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : Mortgage, No : ${applicationNumber} `}
                                    />

                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={!nextValidAction?.nextValidAction?.length}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <MortgageCorrespondence
                                                dropdownData={dropdownData}
                                                dropdownDataPlot={dropdownDataPlot}
                                                setDropdownDataPlot={setDropdownDataPlot}
                                                applicationNumber={applicationNumber}
                                                bankDetails={values.bankDetails}
                                                existingMortgageDetails={values.existingMortgageDetails}
                                                isChecked={isChecked}
                                                handleCheckboxChange={handleCheckboxChange}
                                                fileStoreId={fileStoreId}
                                                transactionDetails={transactionDetails}
                                                checkListRowData={checklistTableData}
                                                paymentRowData={paymentRowData}
                                                content={content}
                                                setContent={setContent}
                                                readOnly={!nextValidAction?.isUpdatable}
                                                status={mortgageData?.estateApplications?.status}
                                                designation={designation}
                                                disabled={disabled}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                        {activeIndex === 3 && (
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>

                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    <GenericFooterComponent
                        activeIndex={activeIndex}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={() => {}}
                        applicationNo={applicationNumber || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                        extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                        extraButtonAction={updateMortgage}
                        // extraButton={
                        //     scoringMatrixResponse?.length || (auctionType && designation === 'AM') || (advType && designation === 'GM' && estateApplicationsData?.status === 'PENDING') ? 'Save' : null
                        // }
                        // extraButtonAction={updatePlotRate}
                    />

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                </>
            )}
        </>
    );
};

export default MortgageWorkflow;
