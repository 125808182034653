import React, { useEffect, useState } from 'react';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { Card, Form } from 'react-bootstrap';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { getHistory, getSuccessMessage, onAttachDocument } from '../../EstateManagement/EstateManagement.controller';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { check, cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import { convertDateToDDmmYYYY, getFirstErrorKey } from '../../../../utils/utils';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../EstateManagement/LeaseDeed/LeaseDeed.const';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import { CONTRACT_DETAILS_VALIDATION, WORK_CATEGORY_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import ContractCreationCorrespondence from './ContractCreationCorrespondence';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import { tenderDetails } from '../NIT screens/NITscreen.const';
import { loaDetailValues } from '../LOAScreens/Loa.const';
import { extensionInitialValues, getDocumentDetails, HeaderInitialValues, workCategoryInitialFields } from './ContractCreation.const';
import { TENANT_ID } from '../../../../utils/constants';
import { setDprHeaderData } from '../DPR creation screen/DPR.const';

const ContractCreationWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [tabelData, setTabelData] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [resData, setResData] = useState<any>(null);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [reminderPopUp, setReminderPopUp] = useState<boolean>(false);
    const [abstractData, setAbstractData] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [pbgTableData, setPbgTableData] = useState<any>([]);
    const [pmPopUp, setPmPopUp] = useState(false);
    const [milestoneData, setMilestoneData] = useState<any>([]);
    const [bidPrice, setBidPrice] = useState<any>(null);
    const [estimatedCost, setEstimatedCost] = useState<any>(null);
    const [isPBGRequired, setIsPBGRequired] = useState<boolean>(true);

    const totalPages = 3;
    const reqInfo = { RequestInfo: requestInfo() };

    const { t } = useTranslation();
    const location: any = useLocation();
    const contractDetails = location?.state?.contractDetails;
    let user = localStorage.getItem('currentUserAssignment');
    const [searchParams] = useSearchParams();
    const applicationNumber: any = searchParams.get('applicationNumber');
    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const docsUuid = searchParams.get('docsUuid');
    const navigate = useNavigate();

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            contractWorkCategoryList: [workCategoryInitialFields],
            rebate: false,
            defectLiability: false
        },
        onSubmit: () => {},
        validationSchema: pmPopUp ? WORK_CATEGORY_VALIDATION : CONTRACT_DETAILS_VALIDATION
    });
    const { values, setValues, errors, setFieldTouched, setTouched, setFieldValue } = formik;
    const toSetDropDown = () => {
        dprDropdownDataOptions()
            .then((dropdownOption: any) => {
                setDropdownData(dropdownOption);
            })
            .catch(e => {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    useEffect(() => {
        const initialFieldValues = async (data: any) => {
            try {
                setIsLoading(true);
                const abstractData = data?.workOrderDetails?.loaDetails?.dprHeaderDetails?.dprItemModelList;
                const abstractRowData = abstractData.map((val: any) => {
                    return {
                        ...val,
                        itemNo: val.itemOrderNo,
                        gsrItemNo: val?.gsrMasterDetailsModel?.itemNo,
                        cost: val?.itemCost,
                        itemType: val?.gsrMasterDetailsModel?.itemNo ? 'Scheduled' : 'Non-Scheduled'
                    };
                });

                const pbgData = data?.workOrderDetails?.pbgDetailsModelList;
                if (pbgData?.length) {
                    const updatedRowData = await Promise.all(
                        pbgData.map(async (val: any) => {
                            const pbgDocument = val?.pbgDocRefId && (await getDocumentDetails(val?.pbgDocRefId, 'OTHER'));
                            return {
                                ...val,
                                documentPbg: pbgDocument,
                                rowId: val?.uuid,
                                docName: pbgDocument?.documentName
                            };
                        })
                    );
                    setPbgTableData(updatedRowData);
                }

                // setPbgTableData(data?.workOrderDetails?.pbgDetailsModelList);
                setAbstractData(abstractRowData);
                setBidPrice(data?.workOrderDetails?.loaDetails?.bidPrice);
                setEstimatedCost(data?.workOrderDetails?.loaDetails?.bidPrice);
                const headerDetails = await setDprHeaderData(data?.workOrderDetails?.loaDetails?.dprHeaderDetails, dropdownData);
                const headerInitValues = await tenderDetails(data?.workOrderDetails?.loaDetails?.worksTenderDetails, dropdownData);
                const biderInitValues = await loaDetailValues(data?.workOrderDetails?.loaDetails);
                const extensionData = await extensionInitialValues(data?.contractExtensionDetails);
                const worksInitValue = {
                    workOrderNumber: data?.workOrderDetails?.workOrderNo,
                    workOrderDate: data?.workOrderDetails?.workOrderDate,
                    ...biderInitValues
                };

                setValues((prev: any) => ({ ...prev, ...headerInitValues, ...worksInitValue, ...headerDetails, ...extensionData }));
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        };
        if (resData && dropdownData) {
            initialFieldValues(resData);
        }
    }, [dropdownData, resData]);

    const createMilestoneRowData = (data: any) => {
        if (data) {
            const newMilestoneRowData = data?.contractMilestoneList?.map((val: any) => {
                return {
                    ...val,
                    rowId: val.uuid,
                    projectStartDate: data?.projectStartDate,
                    contractWorkCategoryList: val.contractWorkCategoryList.map((value: any) => ({ ...value, contractWorkCategory: { code: value.workCategory, name: value.workCategory } })),
                    monthsFromCommencement: val?.monthsFromCommencement
                };
            });
            setMilestoneData(newMilestoneRowData);
        }
    };

    const OnSearchApplication = async () => {
        try {
            const { data } = await POST(endpoints.searchContract + `?applicationNos=${applicationNumber || contractDetails?.applicationNo}`, reqInfo);
            const contractFieldsInitVal = data?.contractModelList[0] && (await HeaderInitialValues(data?.contractModelList[0]));
            const statusApproved = data?.contractModelList[0]?.status === 'APPROVED';
            setIsPBGRequired(+data?.contractModelList[0]?.workOrderModelList?.loaDetails?.perfGuaranteeAmount > 0);
            setResData(data?.contractModelList[0]);
            createMilestoneRowData(data?.contractModelList[0]);

            setValues((prev: any) => ({
                ...prev,
                ...contractFieldsInitVal,
                totalProjectCost: (+data?.contractModelList[0]?.workOrderDetails?.loaDetails?.bidPrice - +data?.contractModelList[0]?.rebateAmount).toFixed(2),
                isUpdatable: isViewOnly ? !isViewOnly : statusApproved ? true : nextValidAction?.isUpdatable,
                validateSignContract: statusApproved ? true : false
            }));
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message || t('SOMETHING_WENT_WRONG', 'Something went wrong');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getCorrespondenceDocument = async () => {
        try {
            const { data } = await POST(
                endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber || contractDetails?.applicationNo}&businessService=${nextValidAction.businessService}`,
                reqInfo
            );
            setDocumentDetails(data?.documents);

            const relatedApplicationDocument = await POST(endpoints.worksRelatedDocument + `?applicationNos=${applicationNumber || contractDetails?.applicationNo}`, reqInfo);
            setRelatedApplicationDocument(relatedApplicationDocument);
        } catch (e: any) {
            let errMsg = e?.response?.data?.errorMessage || e.response.data?.Errors?.[0]?.message || t('SOMETHING_WENT_WRONG', 'Something went wrong');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const { data } = await POST(endpoints.dprValidActions + `?businessId=${applicationNumber || contractDetails?.applicationNo}&tenantId=ga`, reqInfo);
                setNextValidAction(data);

                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();

        toSetDropDown();
    }, []);

    useEffect(() => {
        if (nextValidAction) {
            OnSearchApplication();
            getCorrespondenceDocument();
        }
    }, [nextValidAction]);

    const updatePayload = {
        contractDetails: {
            ...resData,
            ...contractDetails,
            projectStartDate: convertDateToDDmmYYYY(values.projectStartDate),
            defectLiability: values.defectLiability,
            defectLiabilityPeriodMonths: +values.defectLiabilityPeriodMonths,
            maintenancePeriod: values.maintenancePeriod,
            maintenancePeriodStartDate: convertDateToDDmmYYYY(values.maintenancePeriodStartDate),
            maintenancePeriodEndDate: convertDateToDDmmYYYY(values.maintenancePeriodEndDate),
            maintenancePeriodDays: +values.maintenancePeriodDays,
            executionEndDate: convertDateToDDmmYYYY(values.executionEndDate),
            totalProjectCost: values.totalProjectCost,
            signedContractDocRefId: values?.signedCopyofContract?.docReferenceId || null,
            contractMilestoneList: milestoneData.length ? milestoneData : [],
            rebateAmount: +values?.rebateAmount,
            rebateApplicable: values?.rebate,
            rebatePercentage: values?.rebatePercentage
        }
    };

    const OnUpdate = async () => {
        try {
            await CONTRACT_DETAILS_VALIDATION.validate(values, { abortEarly: false, context: values });
            try {
                const { data } = await POST(endpoints.updateContract, { ...reqInfo, ...updatePayload });
                setResData(data?.contractModelList[0]);
                createMilestoneRowData(data?.contractModelList[0]);
                setModalDetails({ show: true, title: 'Success', para: 'Data Saved Successfully', img: check, type: 'save success', reUpload: 'OK' });
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            console.log(validationError);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handlePrimaryAction = async (action: any, comment: any) => {
        const payload = {
            RequestInfo: requestInfo(),
            contractDetailRequest: { ...updatePayload },
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction.businessService,
                    businessId: applicationNumber ? applicationNumber : contractDetails?.applicationNo,
                    moduleName: nextValidAction?.moduleName,
                    action: action.action,
                    notes: values.notes,
                    comment: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean)
        };
        if (!values.notes) {
            setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            await CONTRACT_DETAILS_VALIDATION.validate(values, { abortEarly: false, context: values });
            try {
                const { data } = await POST(endpoints.worksWorkflowInitiate, payload);
                if (data) {
                    const successMessage = getSuccessMessage(action?.action, applicationNumber);
                    setModalDetails({ show: true, title: 'Success', para: successMessage, img: check, type: 'success', reUpload: 'OK' });
                }
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            console.log(validationError);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any =
                file.referenceId &&
                (await onAttachDocument(
                    applicationNumber ? applicationNumber : contractDetails?.applicationNo,
                    file?.referenceId,
                    designationName,
                    nextValidAction?.businessService,
                    historyDetails?.length ? historyDetails[0].id : null,
                    true
                ));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    useEffect(() => {
        try {
            const tableData = async () => {
                const responseTableData = documentDetails.length
                    ? documentDetails?.map((data: any, ind: number) => {
                          return { ...data, serialNo: `C${documentDetails.length - ind}` };
                      })
                    : [];
                setTabelData(responseTableData);
            };
            if (activeIndex === 2 || !!isViewOnly) {
                tableData();
            }
        } catch (e: any) {
            console.log(e);
        }
    }, [documentDetails, activeIndex]);

    const handleNextPage = async () => {
        try {
            const { data } = await POST(
                endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber || contractDetails?.applicationNo}&businessService=${nextValidAction.businessService}`,
                reqInfo
            );
            setDocumentDetails(data?.documents);
        } catch (e) {
            console.log(e);
        }
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };
    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={isViewOnly ? t('CONTRACT_VIEW', 'Contract View') : t('CONTRACT_HEADING', 'Contract Creation')}
                            additionalClass='sub-text-style'
                            bottomSubText={`${isViewOnly ? `` : `Type : Contract Creation Application,`} No : ${applicationNumber ? applicationNumber : contractDetails?.applicationNo} `}
                        />

                        <FormikProvider value={formik}>
                            {!isViewOnly ? (
                                <>
                                    <div className='col-sm-3 mt-3 stickyCard-section '>
                                        <div className='stickyCard' style={{ top: '15%' }}>
                                            <GenericHeader
                                                text={t('CONTRACT_HEADING', 'Contract Creation')}
                                                sideHeader={true}
                                                additionalClass='sub-text-style'
                                                bottomSubText={`Type : Contract Creation Application, No : ${applicationNumber ? applicationNumber : contractDetails?.applicationNo} `}
                                            />

                                            <NotingWrapper
                                                designation={designation}
                                                applicationNumber={applicationNumber}
                                                history={historyDetails}
                                                // disable={!nextValidAction?.nextValidAction?.length}
                                                handleOnFileUpload={handleOnFileUpload}
                                                nextValidAction={nextValidAction}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            <div className={`${!isViewOnly ? 'col-sm-9' : 'col-sm-12'} mt-3 form-section`}>
                                {isViewOnly ? <></> : <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />}
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <ContractCreationCorrespondence
                                                t={t}
                                                abstractData={abstractData}
                                                dropdownData={dropdownData}
                                                pbgTableData={pbgTableData}
                                                setModalDetails={setModalDetails}
                                                pmPopUp={pmPopUp}
                                                setPmPopUp={setPmPopUp}
                                                milestoneData={milestoneData}
                                                setMilestoneData={setMilestoneData}
                                                applicationStatus={resData?.status}
                                                actionIsUpdatable={isViewOnly ? !isViewOnly : nextValidAction?.isUpdatable}
                                                isViewOnly={isViewOnly}
                                                bidPrice={bidPrice}
                                                tabelData={tabelData}
                                                onRowClick={onRowClick}
                                                estimatedCost={estimatedCost}
                                                isPBGRequired={isPBGRequired}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                        {activeIndex === 3 && (
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                        </FormikProvider>
                    </div>
                    {!isViewOnly ? (
                        <>
                            <GenericFooterComponent
                                activeIndex={0}
                                stepperLength={0}
                                handleOnPrimaryAction={handlePrimaryAction}
                                onSubmit={() => {}}
                                nextValidAction={nextValidAction}
                                extraButton={applicationNumber ? false : 'Save'}
                                extraButtonAction={OnUpdate}
                            />
                        </>
                    ) : (
                        <></>
                    )}

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData?.docReferenceId || modalData?.docRefId} documentName={modalData?.documentName || modalData?.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                    <GenericPopupModal
                        secondaryAction={() => {
                            setReminderPopUp(false);
                        }}
                        primaryAction={() => {
                            setReminderPopUp(false);
                            // primaryAction();
                        }}
                        isVisible={reminderPopUp}
                        title='Save Confirmation'
                        size='md'
                        children={
                            <div>
                                <div className='mx-4 my-3'>
                                    <div className='mt-4'>
                                        <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                            {t('SAVE_CHANGES', 'Looks like you have some unsaved changes. Would you like to save them before proceeding with the new data?')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        primaryBtnTitle='Yes'
                        secondaryBtnTitle='No'
                        // onClose={() => setReminderPopUp(false)}
                    />
                </>
            )}
        </>
    );
};

export default ContractCreationWorkflow;
