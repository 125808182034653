import { requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { CORRESPONDENCE, ENTERPRISE, PERMANENT, TENANT_ID } from '../../../../utils/constants';
import { setEntityDocumentDetails } from '../../Migration/Migration.controller';
import { getApplicantDocuments } from '../Allotment/Allotment.controller';

export const SPVPayload = (values: any, plotId: any, controllingOwnershipResponse: any, estateData: any, allotmentId: any, userObject: any, responseData: any) => {
    const permanentAddress = responseData?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');
    const correspondenceAddress = responseData?.addresses?.find((val: any) => val?.addressType === 'CORRESPONDENCE');

    const docs = getApplicantDocuments(values);
    const SPVData = {
        RequestInfo: requestInfo(),
        applicationType: 'SPV',
        isUpdateEnterpriseEntity: values.isUpdatable,
        appliedPlotId: plotId,
        parentOrderNumber: allotmentId,
        enterpriseEntity: {
            ...(responseData ?? []),
            entityName: values.applicantName,
            category: values?.category?.code,
            email: values?.emailId || values?.subLeaseEmailId,
            phoneNumber: values?.phoneNumber,
            panNumber: values?.applicantPanNo,
            gstApplicable: values?.applicantGst,
            gstNumber: values?.applicantGstno || null,
            addresses: [
                {
                    ...(permanentAddress ?? {}),
                    addressType: PERMANENT,
                    address1: values.address1,
                    address2: values.address2 || null,
                    pinCode: values.pincode,
                    district: values.district,
                    state: values.state,
                    country: values.country
                },
                {
                    ...(correspondenceAddress ?? {}),
                    addressType: CORRESPONDENCE,
                    address1: values.peraddress1,
                    address2: values.peraddress2 || null,
                    pinCode: values.perpincode,
                    district: values.perdistrict,
                    state: values.perstate,
                    country: values.percountry
                }
            ],
            authUsers: [
                {
                    ...(responseData?.authUsers[0] ?? []),
                    firstName: values.authorizedPersonFirstname,
                    lastName: values.authorizedPersonLastName,
                    primaryMobileNo: values.pmobileNumber,
                    alternateMobileNo: values.alternateMobileNumber || null,
                    email: values.authorizedEmailId,
                    documents: docs.filter((item: any) => item.referenceType === 'AUTHUSER')
                }
            ],
            owners: [...(responseData?.owners ?? []), ...controllingOwnershipResponse],
            entityBankDetail: {
                ...(responseData?.entityBankDetail ?? []),
                accountNumber: values.accountNumber,
                ifscCode: values.ifscCode,
                bankName: values.bankName,
                branchAddress: values.branchAddress
            },
            documents: [...(responseData?.documents ?? []), ...docs],
            miscDetails: {
                ...(responseData?.miscDetails ?? []),
                isUdhyamRegistered: values.udhyam,
                isDpiitCertified: values.isStartup,
                isExperienced: values.priorExperience,
                udyamNumber: values.udyamNo,
                experience: values?.experienceCategory?.code
            },
            user: { ...(responseData?.user ?? []), ...userObject }
        }
    };
    return SPVData;
};
