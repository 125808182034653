import { faCircleCheck, faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Steps.css'
import { STEPS_DATA } from './StepsData'
import { Image } from 'react-bootstrap'
import { arrowUp } from '../../utils/Logo'
import { Circles, Oval } from 'react-loader-spinner'

const ScrutinySteps = (props: any) => {
    const edcrDetails = props?.edcrDetail[0];
    const data = [...STEPS_DATA, ...edcrDetails?.planDetail?.planInformation?.shortScrutinyDetails]
    return (
        <>
            <div className='container card p-4'>
                <div className="row   d-flex   justify-content-center column-gap-3">
                    {data.map((value, index) =>
                        <div className={`col-3 d-flex py-3 px-2 bg-white mb-2 steps steps${index + 1}`}>

                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#45852E"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass={`me-3 scrut_loader scrut_loader${index + 1}  position-absolute`}
                            />
                            <span className={`me-3 icons icons${index + 1} `} style={{ color: "#45852E", }}>
                                {value.status ? <FontAwesomeIcon icon={faCircleCheck} size='lg' /> : <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#ff0000", }} size='lg' />}</span>
                            <span>{value.key}</span></div>
                    )
                    }

                </div>
            </div>
        </>
    )
}

export default ScrutinySteps
