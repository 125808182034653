import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ErrorMessage, useFormikContext } from 'formik';
import { dropdownOptionsInterface, formOptionsDTO } from './GenericFormCard.constants';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ISelectorProps } from '../../features/modules/UserManagement/types';
import ToolTipDetails from '../ToolTipFile';

const GenericSelectorWithSearch = (props: ISelectorProps) => {
    const { multiSelect, isDisabled, toolTipDetails, customHandleChange, dropdownOptions, isRequired, titleCode, title, field, index, condition1, isClearable } = props;
    const { t } = useTranslation();
    const { values, errors, touched, setFieldValue, setFieldTouched, setValues } = useFormikContext<any>();
    const [isDisable, setIsDisable] = useState(false);
    const [requiredField, setRequiredField] = useState(false);

    let dropdownFieldName = field.split('.').slice(-1)[0];

    const classes = {
        control: (state: any) =>
            state.isDisabled
                ? 'select-disabled form-control was-validated form-border-rad ps-0 mt-0'
                : `form-control was-validated form-border-rad ps-0 mt-0 ${touched[field] && errors[field] && 'is-invalid'}  ${
                      values[field] && isRequired && !errors[field] && values.isUpdatable && 'is-valid'
                  }`,
        placeholder: (state: any) => 'placeholder-style',
        indicatorContainer: (state: any) => '',
        indicatorSeparator: (state: any) => 'd-none',
        clearIndicator: (state: any) => 'select-clear-indicator',
        dropdownIndicator: (state: any) => 'd-block pe-3 select-dropdown-indicator',
        option: (state: any) => (state.isFocused ? 'option-on-focus' : 'option-style' || state.isSelected ? 'bg-transparent option-style text-black' : ''),
        menu: (state: any) => 'menu-style my-0',
        menuList: (state: any) => 'py-0 rounded',
        singleValue: (state: any) => 'single-option-style ps-0',
        valueContainer: (state: any) => 'ps-0'
    };

    useEffect(() => {
        if (condition1 && condition1(values, index)) {
            setIsDisable(false);
            setRequiredField(true);
        } else if (condition1 && !condition1(values, index)) {
            setIsDisable(true);
            setRequiredField(false);
        }
    }, [values, errors]);

    useEffect(() => {
        console.log(typeof _.get(values, field));
        if (!Array.isArray(_.get(values, field)) && !_.get(values, field)?.code) {
            console.log('field4', field, _.get(values, field));
            setFieldValue(field, '');
        }
    }, [field]);

    const handleChange = (values: any) => {
        if (!!multiSelect) {
            const fieldValue = values.map((val: any) => {
                return { code: val.value, name: val.label };
            });
            setFieldValue(field, fieldValue);
        } else if (!values.value) {
            setFieldValue(field, '');
        } else setFieldValue(field, { code: values?.value || '', name: values?.label || '' });

        customHandleChange && customHandleChange(values, field);
    };

    const fieldOptions =
        !!dropdownOptions &&
        (dropdownOptions as any)[dropdownFieldName]?.map((item: formOptionsDTO) => {
            return { value: item?.code, label: item?.name || item?.value };
        });

    const options = [{ value: null, label: 'Please Select' }, ...fieldOptions];

    let selectedOptions;
    if (multiSelect) {
        const selectedValue = _.get(values, field);
        if (selectedValue) {
            selectedOptions = selectedValue?.map((item: formOptionsDTO) => ({
                value: item.code,
                label: item.name
            }));
        }
    }

    return (
        <>
            <div className='d-flex'>
                <label htmlFor={field} className='pb-2 file-form-label' style={title.length > 50 ? { width: '95%' } : { width: 'fit-content' }} title={title}>
                    {titleCode ? t(titleCode, title) : title}
                </label>
                {(isRequired || requiredField) && <span className='mandatory-indicator'>*</span>}
                {toolTipDetails && <ToolTipDetails toolTipDetails={toolTipDetails} />}
            </div>
            <Select
                name={field}
                id={field}
                options={options}
                isMulti={multiSelect}
                // isClearable={isClearable}
                classNamePrefix='react-select'
                classNames={classes}
                unstyled={true}
                placeholder='Please Select'
                onChange={handleChange}
                onBlur={() => setFieldTouched(field, true)}
                value={!selectedOptions ? (_.get(values, field)?.code ? { value: _.get(values, field)?.code, label: _.get(values, field)?.name } : null) : selectedOptions}
                // value={_.get(values, field)?.code || _.get(values, field)}
                isDisabled={isDisable || isDisabled || !values.isUpdatable}
            />
            <ErrorMessage name={field}>{msg => <span className='text-danger   font-12'>{msg}</span>}</ErrorMessage>
        </>
    );
};

export default GenericSelectorWithSearch;
