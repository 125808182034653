import React, { useEffect, useState } from 'react';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';

const ScoringMatrixComponent = ({ rowData, setRowData, applicationNumber, functionReturnError }: any) => {
    const SCORING_MATRIX_COLUMN = [
        {
            colName: 'Particulars',
            fieldName: 'particulars',
            clsName: 'col-sm-5 column-text-start'
        },
        {
            colName: 'Maximum Marks',
            fieldName: 'maxMarks',
            clsName: 'col-sm-2 column-text-end'
        },
        {
            colName: 'System Marks',
            fieldName: 'marks',
            clsName: 'col-sm-2 column-text-end me-5'
        },
        {
            colName: 'Screening Committee Marks',
            fieldName: 'committeMarks',
            clsName: 'col-sm-2 column-text-end me-5',
            isEditable: true
        }
    ];

    // useEffect(() => {
    //     let totalValue = 0;
    //     rowData.map((item: any) => {
    //         totalValue += item.marks;
    //     });

    //     setTotalMarks(totalValue);
    // }, [rowData]);

    return (
        <>
            <div className='pb-2 px-4 position-relative' style={{ paddingTop: '2rem' }}>
                {/* <div className='mb-2'>
                    <div className='font-karla font-12' style={{ color: '#323E4A' }}>
                        Application No.
                    </div>
                    <div className='font-inter font 14' style={{ color: '#323E4A' }}>
                        {applicationNumber}
                    </div>
                </div> */}
                <GenericActionTable
                    // additionalPdfData={`ApplicatP7gR$0gSion no. ${applicationNumber}`}
                    columns={SCORING_MATRIX_COLUMN}
                    rowData={rowData}
                    setRowData={setRowData}
                    totalRow={'committeMarks'}
                    totalRowText={'Total Marks'}
                    hideSearchBar={true}
                    functionReturnError={functionReturnError}
                />
            </div>
        </>
    );
};

export default ScoringMatrixComponent;
