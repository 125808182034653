// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

/* .btn-primary {
  color: #fff;
  background-color: #00bcd9;
  border-color: #00bcd9;
} */

`, "",{"version":3,"sources":["webpack://./src/features/scrutinyFlowScreen/ScrutinyFormPage.css"],"names":[],"mappings":";;AAEA;;;;GAIG","sourcesContent":["\n\n/* .btn-primary {\n  color: #fff;\n  background-color: #00bcd9;\n  border-color: #00bcd9;\n} */\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
