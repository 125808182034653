import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { ENTERPRISE_DETAILS, ENTERPRISE_DETAILS_MIGRATION } from '../Migration.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import ProposedActivityComponent from '../../EstateManagement/Allotment/ProposedActivityDetails';
import { useFormikContext } from 'formik';

export const EnterpriseDetails = ({ dropdownData, nicDetails, setNicDetails, showPopUp, setShowPopUp }: any) => {
    const { values, setFieldValue } = useFormikContext<any>();

    useEffect(() => {
        setFieldValue('enterpriseCategory', values.plotType);
    }, [values.plotType]);

    return (
        <div className=''>
            <>
                <Card className='card_border'>
                    <Card.Body>
                        {ENTERPRISE_DETAILS.map(val => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}

                        <ProposedActivityComponent isDisabled={!values.isUpdatable} nicDetails={nicDetails} setNicDetails={setNicDetails} showPopUp={showPopUp} setShowPopUp={setShowPopUp} />

                        {ENTERPRISE_DETAILS_MIGRATION?.map(val => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        </div>
    );
};
