import ScrollToTop from '../../../../components/ScrollToTop';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { useTranslation } from 'react-i18next';
import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { advColumns, plotTableColumns } from './Allotment.const';
import { useEffect, useRef, useState } from 'react';
import { POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { Navigate, useNavigate } from 'react-router-dom';
import { convertTableDateFormat } from '../../../../utils/utils';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';

export default function ApplyForPlotAllotment() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const advertisementColumns: any[] = advColumns;
    const [advData, setAdvData] = useState<any>([]);
    const [plotData, setPlotData] = useState<any>([]);
    const [advertisementID, setAdvertisementID] = useState<any>(null);
    const [selectedPlot, setSelectedPlot] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<any>(false);
    const divRef: any = useRef(null);
    const [selectedPlotDataId, setSelectedPlotDataId] = useState<any>(null);
    const [selectedPlotData, setSelectedPlotData] = useState<any>(null);
    const onSubmit = () => {
        navigate('/goa-idc/estate/allotment', { state: { plotId:selectedPlotDataId , advertisementNumber: advertisementID } });
    };
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalData, setModalData] = useState<any>(null);
    


    const onRowClick = (e: any) => {
        setAdvertisementID(e.advNo);
        setPlotData(
            e.advertisementPlotMappings.map((e: any) => {
                return { ...e, id: e.plotResponse.uuid };
            }) || []
        );
        if (divRef.current) {
            setTimeout(() => {
                divRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 500);
        }
    };

    // const onRowCheck = (e: any) => {
    //     setSelectedPlot(e?.[0] || null);
    //     setSelectedPlot(e?.[0] || null);
    //     console.log(e)
    // };

    const onPlotSelect = (selectedPlot: any) => {
        setSelectedPlotDataId(selectedPlot.id);
        setSelectedPlotData(selectedPlot);
        console.log(selectedPlot)
    };

    const plotColumns = plotTableColumns.map((val: any, index: number) => {
        if (index === 0) {
            return {
                ...val,
                onRadioClick: onPlotSelect,
                selectedValue: selectedPlotDataId
            };
        }
        return val;
    });
    const getAdv = async () => {
        try {
            const { data } = await POST(`/idc-estate-service/advertisement/_search?status=OPEN`, { RequestInfo: requestInfo() });
            let advData = data.advertisementsDetails || [];
            advData = advData.map((obj: any) => {
                return {
                    ...obj,
                    publicationDate: convertTableDateFormat(obj.publicationDate),
                    submissionEndDate: convertTableDateFormat(obj.submissionEndDate),
                    submissionStartDate: convertTableDateFormat(obj.submissionStartDate)
                };
            });
            setAdvData(advData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getAdv();
    }, []);

    const onViewDocument = (row: any, field: any) => {
        if (field === 'plotResponse.plotNo') {
            navigate('/goa-idc/estate/apply-for-plot/plot-estate-data', { state: { data: row } });
        } else {
            setIsFilePreviewModal(!isFilePreviewModal);
            setModalData(row);
            setIsPDF(isPDF);
        }
    };

    return (
        <>
            <HeaderComponent />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <div className='col-sm-12'>
                    <GenericHeader text={t('OPEN_ADVERTISEMENTS','Open Advertisements')} />
                </div>
                <div className='col-sm-12'>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <Card className='card_border'>
                            <Card.Body>
                                <GenericActionTable
                                    columns={advertisementColumns}
                                    rowData={advData}
                                    hideSearchBar={true}
                                    itemsPerPage={5}
                                    highlightRow={true}
                                    additionalMethod={(e: any) => {
                                        onRowClick(e);
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    )}
                </div>
                <div className='plotTable' ref={divRef}>
                    {advertisementID && (
                        <div className='col-sm-12'>
                            <Card className='card_border'>
                                <Card.Body>
                                    <div className='ps-2'>
                                        <Card.Title className='font-inter fs-5 py-2 green-text'>{advertisementID} - Plot List</Card.Title>
                                    </div>
                                    <GenericActionTable
                                        columns={plotColumns}
                                        rowData={plotData}
                                        hideSearchBar={true}
                                        itemsPerPage={5}
                                        // onRadioClick={(e: any) => {
                                        //     onRowCheck(e);
                                        // }}
                                        checkType={'singleCheck'}
                                        additionalMethod={onViewDocument}
                                    />
                                </Card.Body>
                            </Card>
                        </div>
                    )}
                </div>
            </div>
            <ScrollToTop />
            <footer className='footer fixed-bottom footerBtnPatch'>
                <div className='d-flex'>
                    <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                        <GenericButtons text={t('APPLY', 'Apply')} variant='primary' handleClick={onSubmit} disabled={selectedPlotDataId ? false : true} />
                    </div>
                </div>
            </footer>
            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData?.estateSitePlanDocRefId || ''} documentName={modalData.plotResponse.estateDetails.estateName} />}
                        {!isPDF && <GenericImageViewer />}
                    </div>
                </GenericPopupModal>
            )}
        </>
    );
}
