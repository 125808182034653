import React, { useEffect, useState } from 'react';
import './OrderCompletionFormPage.scss';
import { Button, Card, CardBody, Image } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray, Formik, FormikProvider, useFormik } from 'formik';
import activeArrow from '../../assets/logos/page arrow.svg';
import inactiveArrow from '../../assets/logos/page left arrow.svg';
import { Clearance_Form_Initial_Data, VALIDATION_ORDER_COMPLETION_FORM, stepsDetailsForOrderCompletion } from './OrderCompletionFormPage.module';
import { toast } from 'react-toastify';
import Loading from '../../components/portalComponents/Loading.component';
import { check, close, cross, warning } from '../../utils/Logo';
import ModalStatusPopup from '../../components/popup/Popup.status.generic';
import { BPA_ACTIONS_DETAILS, EMP_QUESTIONNAIRE_DOCUMENT_COLUMN, FIELD_INSPECTION_DETAILS, FIELD_INSPECTION_DETAILS_II, QUESTIONNAIRE_DOCUMENT_COLUMN } from './OrderCompletionFormPage.const';
import HeaderComponent from '../../components/header/Header.component';
import { getNavigateTo } from '../../utils/utils';
import OrderCompletionFormSubPage from './m_OrderCompletionFormSubPage.wrapper';
import GenericPopupModal from '../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { BPArequestInfo, POST, devUrl, endpoints, requestInfo } from '../../services/modulesServices/userManagement.api';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import { useTranslation } from 'react-i18next';
import { esignForDocuments, getDocsData, getFileurl } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.controller';
import { OrderCompletionFormJson, createInspectionFormJson, revertStringBasedOnMatchForOC } from './OrderCompletionFormPage.controller';
import { APPLICATION_TYPE, COMPLETION_ORDER, CONSTANT_DATA_FIELD, TENANT_ID } from '../../utils/constants';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { DocumentInfo, NextValidActionItem } from '../../components/moduleCommonFormik/GenericFormCard.constants';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import GenericFooterComponent from '../../components/moduleCommonFormik/GenericFooterStepper.component';
import { getHistory, onAttachDocument } from '../modules/EstateManagement/EstateManagement.controller';
import GenericPDFViewer from '../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../components/fileviewer/GenericImageViewer';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { CorrespondenceWrapper, NotingWrapper } from '../../components/Workflow/NotingWrapper';
import { OwnershipTable } from '../modules/UserManagement/EnterpriseModule/OwnershipTable';
import { ThreeDots } from 'react-loader-spinner';
import attachmentIcon from '../../assets/logos/attachment.svg';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function M_OrderCompletionFormPage() {
    const { t, i18n } = useTranslation();
    const initialValues = Clearance_Form_Initial_Data;
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [activeStepper, setActiveStepper] = useState<string>('LESSEE_DETAITLS');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showStatus, setShowStatus] = useState<boolean>(false);
    const [showProcess, setShowProcess] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [bodydata, setBodydata] = useState<string>('');
    const [employeeData, setEmployeeData] = useState(null);
    const [previewData, setPreviewData] = useState<any>(null);
    const [appStatus, setAppStatus] = useState<any>(false);
    const [isCancelBtn, setCancelBtn] = useState<any>(false);
    const [isShowActionPopup, setIsShowActionPopup] = useState<boolean>(false);
    const [isShowInspReport, setIsShowInspReport] = useState<boolean>(false);
    const [bpaData, setBpaData] = useState<any>(null);
    const [selectedAction, setSelectedAction] = useState<any>('');
    const [allchecked, setAllChecked] = React.useState<any>([]);
    const [editorContent, setEditorContent] = useState('');
    const [formValues, setFormValues] = useState([{ emailId: '' }]);
    var userinfo = window.localStorage.getItem('user');
    const user_uuid = !!userinfo && JSON.parse(userinfo).uuid;
    let stepperDetails = !!previewData ? stepsDetailsForOrderCompletion : stepsDetailsForOrderCompletion.filter((val, ind) => ind === 0);
    const [payVisible, setPayVisible] = useState<boolean>(false);
    const [allBPADetails, setAllBPADetails] = useState<any>(null);
    const [isLesseeEmpty, setIsLesseeEmpty] = useState<boolean>(false);
    const [selectedBPA, setIsSelectedBPA] = useState<string>('');
    const [allEdcrDetails, setAllEdcrDetails] = useState<any>(null);
    const urlParams = new URLSearchParams(window.location.search);
    const [actionsData, setActionsData] = useState<any>(null);
    const applicationNumber = decodeURIComponent(urlParams.get('applicationNumber') || '');
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [comment, setcomment] = useState('');
    const [tabelData, setTabelData] = useState<any>([]);
    const [actionValidation, setActionValidation] = useState<any>(null);
    const [documentDetails, setDocumentsDetails] = useState<any>([]);
    const [fieldInspection, setFieldInspectionData] = useState<any>(null);
    const [inspectionReport, setInspectionReportnData] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [openSection, setOpenSection] = useState<string>('OCCUPANCY_DETAILS');
    const [reportDocsData, setReportDocsData] = useState<any>([]);
    const [activeMainIndex, setActiveMainIndex] = useState<number>(1);
    const [questionnaireData, setQuestionnaireData] = useState<any>([]);
    const [tempNotes, setTempNotes] = useState<any>('');
    let user = localStorage.getItem('currentUserAssignment');
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    const [showPopUp, setShowPopUp] = useState(false);
    const [response, setResponse] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [responseNocDetails, setResponseNocDetails] = useState<any>(null);
    const [responseClarificationHistory, setResponseClarificationHistory] = useState<any>(null);
    const [nocDocumentReferenceIds, setNocDocumentReferenceIds] = useState<any>(null);
    const [nocApplicationNo, setNocApplicationNo] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [questions, setQuestions] = useState(['']);
    const [searchParams] = useSearchParams();
    const stepperIndex = urlParams.get('stepper') || '';
    const signedFileStoreId = urlParams.get('signedFileStoreId') || '';
    const [content, setContent] = useState('');
    const [showBackOptionsBtn, setShowBackOptionsBtn] = useState<boolean>(false);

    const formik = useFormik<any>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: initialValues,
        onSubmit: value => {},
        validateOnMount: true
    });
    const { values, resetForm, setFieldValue, setValues } = formik;
    let questionnaireDetails = questionnaireData;
    const handleClose = () => {
        setShowPopup(false);
        setShowStatus(false);
        setIsShowActionPopup(false);
    };

    const handleSubmit = () => {
        setShowPopup(false);
        setShowStatus(false);
        if (activeStepper === 'DECLARATION') navigateToDashboard();
    };

    const handleStatusSubmit = () => {
        setShowStatus(false);
        navigateToDashboard();
    };

    useEffect(() => {
        if (!!values?.notes) setTempNotes(values?.notes);
    }, [values?.notes]);

    useEffect(() => {
        if (activeMainIndex === 2 && documentDetails) {
            const reverseDocumentDetails = documentDetails;
            const tableData = async () => {
                const responseTableData = reverseDocumentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${ind + 1}` };
                });
                const reverseData = responseTableData;
                setTabelData(reverseData);
            };
            if (activeMainIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeMainIndex]);

    const getActionsData = () => {
        if (!!applicationNumber) {
            setIsLoading(true);

            TechnicalServices.getBpaActions(applicationNumber, {
                RequestInfo: BPArequestInfo()
            })
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        setActionsData(response?.data);
                        setActionValidation(response?.data?.actionValidation);
                        response?.data?.nextValidAction?.forEach((val: any) => {
                            if (val?.action === 'Pay') {
                                setSelectedAction(val?.action);
                                setPayVisible(true);
                                return;
                            }
                        });
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const getTechnicalClearanceData = () => {
        const payload = { RequestInfo: BPArequestInfo() };
        TechnicalServices.applyConstructionLicence(payload)
            .then(res => {
                if (res?.data) {
                    setAllBPADetails(res?.data?.ConstructionLicense || []);
                }
            })
            .catch(err => {
                setIsLesseeEmpty(true);
                setModalDetails({ show: true, title: 'Error!', para: err?.response?.data?.Errors[0]?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const getHistoryData = async () => {
        if (applicationNumber) {
            try {
                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const getDocuments = async () => {
        if (applicationNumber) {
            try {
                const historyData: any = await TechnicalServices.searchAllDocuments(applicationNumber, { RequestInfo: BPArequestInfo() });
                setDocumentsDetails(historyData?.data?.documents);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };
    const getQuestionnaires = (val: any) => {
        setIsLoading(true);
        if (!!val) {
            TechnicalServices.searchQuestionnaire(val, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    setIsLoading(false);
                    setQuestionnaireData(response?.data?.backQuestionnaires);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getDocuments();
        getHistoryData();
        searchFieldInspection();
        getQuestionnaires(applicationNumber);
    }, []);

    const searchBPA = (val: any) => {
        setIsLoading(true);
        if (!!val) {
            TechnicalServices.getBpaSearchForRefNo(val, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    // handle the response
                    setIsLoading(false);
                    if (!response?.data?.BPA.length) {
                        setBodydata('No Application Found');
                        setShowStatus(true);
                        setPreviewData(null);
                        return;
                    }
                    setPreviewData(response?.data?.BPA[0]);
                    setFormData(response?.data?.BPA[0]);
                    setContent(response?.data?.BPA[0]?.provisionalCertificateContent);
                    fetchInitialData(response?.data?.BPA[0]);
                    getActionsData();
                    setAppStatus(true);
                    // toast('Data Fetched Successfully!', {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'success'
                    // });
                })
                .catch(error => {
                    setShowStatus(true);
                    setBodydata('No Application Found');
                    setIsLoading(false);
                });
        } else {
            setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
            // toast('Something Went Wrong!!', {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'error'
            // });
            setIsLoading(false);
        }
    };

    const searchFieldInspection = () => {
        values.FIELD_INSP_SITEPLAN = [];
        values.FIELD_INSP_SITEPLAN = [];
        setIsLoading(true);
        if (!!applicationNumber) {
            TechnicalServices.searchFieldInspectionData(applicationNumber, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    setIsLoading(false);
                    const preData = response?.data?.fieldInspections.filter((val: any) => !val?.isClosed)[0];
                    const insp = preData?.inspectionCheckListData;
                    setFieldInspectionData(preData);
                    setInspectionReportnData(response?.data?.fieldInspections);
                    values.planapprovedNo = insp?.planapprovedNo;
                    values.planapprovedDate = new Date(insp?.planapprovedDate).getUTCDate();
                    values.clvalidation = insp?.clvalidation;
                    values.notvalidreason = insp?.notvalidreason;
                    values.appliedforoccupancy = insp?.appliedforoccupancy;
                    values.clearedthedump = insp?.clearedthedump;
                    values.sscertificatesubmission = insp?.sscertificatesubmission;
                    values.ccsubmission = insp?.ccsubmission;
                    // values.otherremark = insp?.otherremark;
                    values.FIELD_INSP_SITEPLAN = getFileurl(preData?.documents, 'FIELD_INSP_SITEPLAN') || [];
                    values.FIELD_INSP_SITEPLAN_OTHER = getFileurl(preData?.documents, 'FIELD_INSP_SITEPLAN_OTHER') || [];
                })
                .catch(error => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    };

    const DOC_TABLE_COLUMN = [
        {
            colName: 'SN',
            fieldName: 'serialNo',
            clsName: 'col-sm-2'
        },
        {
            colName: 'Uploaded By',
            fieldName: 'uploadedUserRole',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Date & Time',
            fieldName: 'uploadedDate',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Document',
            fieldName: 'docFileName',
            clsName: 'col-sm-2',

            isClickable: true
        }
    ];

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const fetchInitialData = (preData: any) => {
        values.name = preData?.lesseeDetails?.name;
        values.gender = preData?.lesseeDetails?.gender;
        values.phone = preData?.lesseeDetails?.phoneNo;
        values.panNo = preData?.lesseeDetails?.pan;
        values.email = preData?.lesseeDetails?.email;
        values.addressLine1 = preData?.lesseeDetails?.address?.addressLine1 || '';
        values.addressLine2 = preData?.lesseeDetails?.address?.addressLine2 || '';
        values.country = preData?.lesseeDetails?.address?.country;
        values.state = preData?.lesseeDetails?.address?.state;
        values.district = preData?.lesseeDetails?.address?.district;
        values.pincode = preData?.lesseeDetails?.address?.pincode;
        values.id = preData?.id;
        values.appId = preData?.applicationNo;
        values.archName = preData?.archName;
        values.archId = preData?.archRegId;
        values.serviceType = preData?.serviceType || 'NEW_CONSTRUCTION';
        values.licenseeType = '';
        values.CouncilArcNo = '';
        values.buildingPlan = [];
        values.token = '';
        values.cert = '';
        values.pin = '';
        values.documents = preData?.documents;
        values.forwardTo = preData?.lesseeDetails?.name;
        values.comments = '';
        values.additionalDetails = preData?.additionalDetails;
        values.TECHNICAL_CLEARANCE_ORDER = getFileurl(preData?.documents, 'TECHNICAL_CLEARANCE_ORDER');
        values.CONSTRUCTION_LICENSE_ORDER = getFileurl(preData?.documents, 'CONSTRUCTION_LICENSE_ORDER');
        values.COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER = getFileurl(preData?.documents, 'COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER');
        values.REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION = getFileurl(preData?.documents, 'REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION');
        values.STRUCTURAL_STABILITY_CERTIFICATE = getFileurl(preData?.documents, 'STRUCTURAL_STABILITY_CERTIFICATE');
        values.FIRE_NOC = getFileurl(preData?.documents, 'FIRE_NOC') || [];
        values.FACTORIES_AND_BOILERS_NOC = getFileurl(preData?.documents, 'FACTORIES_AND_BOILERS_NOC') || [];
        values.isEditableFile = false;
        values.isUpdatable = true;
        values.edcrNumber = preData?.edcrNumber;
        values.plotNo = preData?.additionalDetails?.plotNo;
        values.inspDate = null;
        values.inspTime = '';
        values.estate = preData?.additionalDetails?.estate;
        values.phase = preData?.additionalDetails?.phase;
        values.propertyType = preData?.additionalDetails?.propertyType;
        values.plotArea = preData?.additionalDetails?.plotArea;
        values.refApplication = applicationNumber ? preData?.refApplication : preData?.applicationNo;
        if (!!preData?.ocPercentage) {
            values.percentage = preData?.ocPercentage;
            values.occupancy = preData?.ocPercentage;
        }
        values.blocks = preData?.partOCDetails?.blocks;
        values.far = preData?.partOCDetails?.far;
        values.coverageArea = preData?.partOCDetails?.coverageArea;
        values.coveragePercent = preData?.partOCDetails?.coveragePercent;
    };

    useEffect(() => {
        setTimeout(() => {
            if (!!stepperIndex && stepperIndex === 'ACT_OC_APPROVE' && previewData) {
                values.notes = urlParams.get('note');
                submitForm('ACT_OC_APPROVE', true);
            }
            if (!!stepperIndex && stepperIndex === 'ACT_CO_APPROVE' && previewData) {
                values.notes = urlParams.get('note');
                submitForm('ACT_CO_APPROVE', true);
            }
        }, 1500);
    }, [previewData]);

    const esignSelf = async (fileStoreId: string, action: string) => {
        const redirectURL = `/goa-idc/obpas/e/occupancyCertificate?${searchParams.toString()}&note=${values.notes}&docsUuid=${fileStoreId}&stepper=${action}`;
        let url = window.location.origin + redirectURL;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${fileStoreId}&callbackUrl=${url}`);
        // esignForDocuments(fileStoreId, redirectURL);
    };

    const submitForm = (action?: string, isEsign?: boolean) => {
        const skippedActionsForNoting = ['ACT_OC_SCHEDULE_APPOINTMENT'];

        if (!!appStatus && !action) {
            navigateToDashboard();
            return;
        }
        if (activeMainIndex === 2) values.notes = tempNotes;
        if (!!action && !skippedActionsForNoting.includes(action) && !values?.notes) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please add the noting and proceed', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (!!action && action === 'ACT_OC_APPROVE' && !isEsign) {
            TechnicalServices.generateCertificate({ RequestInfo: BPArequestInfo(), applicationNo: applicationNumber, provisionalCertificateContent: content }).then((response: any) => {
                if (!!response?.data?.documents[0]?.fileStoreId) {
                    esignSelf(response?.data?.documents[0]?.fileStoreId, action);
                } else {
                    setModalDetails({ show: true, title: 'Error!', para: t('OC_MSG_CHECK_DOCUMENT', 'No Document Found!'), img: cross, type: 'error', reUpload: 'OK' });
                }
                return;
            });
            return;
        }

        if (!!action && action === 'ACT_CO_APPROVE' && !isEsign) {
            TechnicalServices.generateCertificate({ RequestInfo: BPArequestInfo(), applicationNo: applicationNumber, provisionalCertificateContent: content }).then((response: any) => {
                if (!!response?.data?.documents[0]?.fileStoreId) {
                    esignSelf(response?.data?.documents[0]?.fileStoreId, action);
                } else {
                    setModalDetails({ show: true, title: 'Error!', para: t('OC_MSG_CHECK_DOCUMENT', 'No Document Found!'), img: cross, type: 'error', reUpload: 'OK' });
                }
                return;
            });
            return;
        }

        if (activeStepper === 'DECLARATION' && allchecked.length !== 3 && !appStatus) {
            setModalDetails({ show: true, title: 'Error!', para: t('CHECK_DECLARATION_PROCEED', 'Please check all declarations and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        setIsLoading(true);
        values.appId = applicationNumber;
        values.id = previewData?.id;
        const allDocs = [
            ...(values?.CONSTRUCTION_LICENSE_ORDER || []),
            ...(values?.TECHNICAL_CLEARANCE_ORDER || []),
            ...(values?.COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER || []),
            ...(values?.REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION || []),
            ...(values?.STRUCTURAL_STABILITY_CERTIFICATE || []),
            ...(values?.FIRE_NOC || []),
            ...(values?.FACTORIES_AND_BOILERS_NOC || [])
        ];
        const uniqueData = allDocs.filter((item, index, self) => index === self.findIndex(t => t.fileStoreId === item.fileStoreId));

        values.documents = uniqueData;

        const payload: any = OrderCompletionFormJson(values, previewData, !!previewData?.accountId);
        payload.BPA.applicationType = 'OCCUPANCY_CERTIFICATE';
        if (!!applicationNumber) payload.BPA.businessService = 'WF_BPA_OC';
        payload.BPA.ocPercentage = previewData?.ocPercentage;
        payload.BPA.documents = getDocsData(uniqueData, values?.appId);
        payload.BPA.id = applicationNumber ? previewData?.id : '';
        payload.BPA.applicationNo = applicationNumber || '';
        payload.BPA.refApplication = values?.refApplication || '';
        payload.BPA.status = previewData?.status;

        if (!!selectedAction || !!action)
            payload.BPA.workflow = {
                action: selectedAction || action,
                assignes: null,
                notes: values?.notes,
                varificationDocuments: null
            };

        payload.BPA.notes = {
            id: null, // Assuming it should have an ID, set as null or any specific value if needed
            content: values?.notes || '',
            notificationDetails: null // Assuming no specific notification details
        };

        payload.BPA.provisionalCertificateContent = content;

        if (action === 'ACT_CO_APPROVE' && !!isEsign) {
            payload.BPA.documents.push({
                id: null,
                applicationNumber: applicationNumber,
                documentType: 'COMPLETION_ORDER',
                fileStoreId: signedFileStoreId,
                tenantId: 'ga',
                documentName: 'COMPLETION_ORDER.pdf',
                documentUid: '',
                additionalDetails: {}
            });
        }

        if (action === 'ACT_OC_APPROVE' && !!isEsign) {
            payload.BPA.documents.push({
                id: null,
                applicationNumber: applicationNumber,
                documentType: 'OCCUPANCY_CERTIFICATE_ORDER',
                fileStoreId: signedFileStoreId,
                tenantId: 'ga',
                documentName: 'OCCUPANCY_CERTIFICATE_ORDER.pdf',
                documentUid: '',
                additionalDetails: {}
            });
        }

        TechnicalServices.createTechnicalClearance(payload)
            .then((response: any) => {
                setIsLoading(false);
                // setModalDetails({ show: true, title: 'Success', para: t('SUCCESS_APPLICATION_UPDATE', 'Application forwarded Successfully!'), img: check, type: 'success', reUpload: 'OK' });

                if (action === 'ACT_OC_SCHEDULE_APPOINTMENT') {
                    const successMessageOCFIScheduled = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_ACT_OC_SCHEDULE_APPOINTMENT',
                        'Field Inspection Scheduled'
                    )}`;

                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_OC_SCHEDULE_APPOINTMENT', 'Field Inspection Scheduled'),
                        para: successMessageOCFIScheduled,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                }

                if (selectedAction === 'ACT_OC_SUBMIT_INSPECTION_REPORT') {
                    const successMessageOCFISubmitted = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_ACT_OC_SUBMIT_INSPECTION_REPORT',
                        'Field Inspection Report Submitted Successfully'
                    )}`;
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_OC_SUBMIT_INSPECTION_REPORT', 'Field Inspection Report Submitted Successfully'),
                        para: successMessageOCFISubmitted,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                }

                if (action === 'ACT_OC_SEND_BACK_LESSEE') {
                    const successMessageOCSendBackToLessee = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_ACT_OC_SEND_BACK_LESSEE',
                        'Field Manager (site) has sent application back to Lessee'
                    )}`;
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_OC_SEND_BACK_LESSEE', 'Field Manager (site) has sent application back to Lessee'),
                        para: successMessageOCSendBackToLessee,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                }

                if (action === 'ACT_CO_OC_LESSEE_FORWARD') {
                    const successMessageOCFIforwarded = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_ACT_CO_OC_LESSEE_FORWARD',
                        'Field Inspection forwarded Successfully'
                    )}`;
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_CO_OC_LESSEE_FORWARD', 'Field Inspection forwarded Successfully'),
                        para: successMessageOCFIforwarded,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                }

                if (action === 'ACT_OC_GENERATE_COMPLETION_ORDER_FORWARD') {
                    const successMessageOCforwardedMS = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_ACT_OC_GENERATE_COMPLETION_ORDER_FORWARD',
                        'Forwarded to Member Secretary for Final Approval'
                    )}`;
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_OC_GENERATE_COMPLETION_ORDER_FORWARD', 'Forwarded to Member Secretary for Final Approval'),
                        para: successMessageOCforwardedMS,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                }
                if (action === 'ACT_CO_APPROVE') {
                    const successMessageOCCompletionOrderApproved = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_ACT_CO_APPROVE',
                        'Completion Order Approved'
                    )}`;
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_CO_APPROVE', 'Completion Order Approved'),
                        para: successMessageOCCompletionOrderApproved,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                    return;
                }
                if (action === 'ACT_OC_SEND_BACK_ARM') {
                    const successMessageOCCompletionOrderAM = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_ACT_OC_SEND_BACK_ARM',
                        'Completion Order Send Back To Area Manager'
                    )}`;
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_OC_SEND_BACK_ARM', 'Completion Order Send Back To Area Manager'),
                        para: successMessageOCCompletionOrderAM,
                        title: '',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                }
                if (action === 'APPL_STATUS_OC_NOC_UPLOAD' || action === 'ACT_ARM_NOC_FORWARD') {
                    const successMessageOCNOC = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_APPL_STATUS_OC_NOC_UPLOAD',
                        'NOC uploaded and application forwarded successfully'
                    )}`;
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_APPL_STATUS_OC_NOC_UPLOAD', 'NOC uploaded and application forwarded successfully'),
                        para: successMessageOCNOC,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                }
                if (action === 'APPL_STATUS_APPL_REVIEW' || action === 'ACT_OC_DGM_FORWARD') {
                    const successMessageOCForwarded = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_ACT_OC_DGM_FORWARD',
                        'Application forwarded successfully'
                    )}`;
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_OC_DGM_FORWARD', 'Application forwarded successfully'),
                        para: successMessageOCForwarded,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                    return;
                }
                if (action === 'APPL_STATUS_OC_PENDING_FOR_APPROVAL' || action === 'ACT_OC_APPROVE') {
                    const successMessageOCApproved = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                        'POPUP_MSG_ACT_OC_APPROVE',
                        'Occupancy Certificate approved successfully'
                    )}`;
                    setModalDetails({
                        show: true,
                        // para: t('POPUP_MSG_ACT_OC_APPROVE', 'Occupancy Certificate approved successfully'),
                        para: successMessageOCApproved,
                        title: 'Success!',
                        img: check,
                        type: 'success',
                        reUpload: 'OK'
                        // children: (
                        //     <div>
                        //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                        //     </div>
                        // )
                    });
                    return;
                }

                // if (action === 'ACT_OC_SCHEDULE_APPOINTMENT' || action === 'ACT_OC_SUBMIT_INSPECTION_REPORT' || action === 'APPL_STATUS_OC_NOC_UPLOAD') navigateToDashboard();
            })
            .catch(error => {
                // setIsLoading(false);
                console.log(error?.message);
                // setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
                // toast(error?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'error'
                // });
            });
    };

    const onAcceptRequest = () => {
        createFieldInspection('accept');
    };

    const createFieldInspection = (action?: string) => {
        setIsLoading(true);

        const payload: any = createInspectionFormJson(values, fieldInspection, !!fieldInspection?.id);

        if (action === 'report') {
            payload.FieldInspection.inspectionCheckListData = {
                planapprovedNo: values?.planapprovedNo,
                planapprovedDate: new Date(values?.planapprovedDate).getTime(),
                clvalidation: values?.clvalidation,
                notvalidreason: values?.notvalidreason,
                appliedforoccupancy: values?.appliedforoccupancy,
                clearedthedump: values?.clearedthedump,
                sscertificatesubmission: values?.sscertificatesubmission,
                ccsubmission: values?.ccsubmission
                // otherremark: values?.otherremark
            };
            payload.FieldInspection.documents = reportDocsData || [];
            payload.FieldInspection.lesseeAcepted = true;
            payload.FieldInspection.isClosed = true;
            payload.FieldInspection.inspectionDate = new Date().getTime();
            payload.FieldInspection.inspectionTime = new Date().getTime();
        }

        if (action === 'accept') {
            payload.FieldInspection.requestForReschedule = false;
            payload.FieldInspection.scheduledDate = payload.FieldInspection.requestForRescheduleData?.scheduledDate;
            payload.FieldInspection.scheduledTime = payload.FieldInspection.requestForRescheduleData?.scheduledTime;
            payload.FieldInspection.lesseeAcepted = true;
            payload.FieldInspection.employeeAccepted = true;
        }

        TechnicalServices.updateFieldInspection(payload, !!fieldInspection?.id)
            .then((response: any) => {
                setIsLoading(false);
                setIsShowActionPopup(false);
                setIsShowInspReport(false);
                // if (action !== 'accept' && action !== 'report')
                //     setModalDetails({ show: true, title: 'Success!', para: t('', 'Inpsection field scheduled successfully'), img: check, type: 'success', reUpload: 'OK' });
                if (!!action && action !== 'accept') {
                    if (action === 'report' && !values?.notes) {
                        setModalDetails({ show: true, title: 'Error!', para: 'Please add the noting and proceed', img: cross, type: 'error', reUpload: 'OK' });
                        return;
                    }
                    submitForm(action);
                }

                searchFieldInspection();
                if (action === 'accept') setModalDetails({ show: true, title: 'Success!', para: t('', 'Schedule request accepted  successfully'), img: check, type: 'success', reUpload: 'OK' });
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
                // toast(error?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'error'
                // });
            });
    };

    const markAsComplete = () => {
        setIsLoading(true);

        TechnicalServices.markAsCompleteFlow(applicationNumber, { RequestInfo: BPArequestInfo() })
            .then((response: any) => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Success!', para: t('', 'Submitted successfully'), img: check, type: 'success', reUpload: 'OK' });
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const openInspectionForm = () => {
        values.isEditableFile = fieldInspection?.inspectionCheckListData === null;
        const insp = fieldInspection?.inspectionCheckListData;
        values.planapprovedNo = insp?.planapprovedNo || '';
        values.planapprovedDate = new Date(insp?.planapprovedDate).getUTCDate();
        values.clvalidation = insp?.clvalidation;
        values.notvalidreason = insp?.notvalidreason;
        values.appliedforoccupancy = insp?.appliedforoccupancy;
        values.clearedthedump = insp?.clearedthedump;
        values.sscertificatesubmission = insp?.sscertificatesubmission;
        values.ccsubmission = insp?.ccsubmission;
        // values.otherremark = insp?.otherremark;
        values.FIELD_INSP_SITEPLAN = getFileurl(fieldInspection?.documents, 'FIELD_INSP_SITEPLAN') || [];
        values.FIELD_INSP_SITEPLAN_OTHER = getFileurl(fieldInspection?.documents, 'FIELD_INSP_SITEPLAN_OTHER') || [];
    };

    const onActionClick = (action: string) => {
        setFieldValue('comments', '');
        setSelectedAction(action);

        if (action === 'ACT_OC_SEND_BACK_LESSEE') {
            setShowPopUp(true);
            return;
        }

        if (action === 'ACT_OC_MARK_AS_COMPLETE') {
            markAsComplete();
            return;
        }

        if (!!actionValidation[action] && actionValidation[action].includes('validateInspectionAppointment')) {
            setIsShowActionPopup(true);
            console.log(values?.inspDate, values?.inspTime);
            if (!!values?.inspDate && !!values?.inspTime) {
                createFieldInspection(action);
                return;
            }

            setModalDetails({ show: true, title: 'Error!', para: t('POPUP_MSG_SCHEDULE_INSP_APPOINTMENT', 'Please schedule the inspection appointment'), img: cross, type: 'error', reUpload: 'OK' });
            window.scrollTo(0, 650);
            setOpenSection('INSPECTION');
            return;
        }
        if (!!actionValidation[action] && actionValidation[action].includes('validateInspectionReport')) {
            if (!!values.planapprovedNo && !!values.planapprovedDate && !!values.clvalidation && !!values.notvalidreason) {
                createFieldInspection('report');
                return;
            }
            setModalDetails({ show: true, title: 'Error!', para: 'Please submit the Inspection Report', img: cross, type: 'error', reUpload: 'OK' });
            window.scrollTo(0, 850);
            setOpenSection('INSPECTION');
            values.isEditableFile = true;
            return;
        }

        // if(!!actionValidation[action] && actionValidation[action].includes('validateInitiateNOC')) {
        //     alert("validateInitiateNOC"); return;
        // }
        if (!!actionValidation[action] && actionValidation[action].includes('validateUploadNOC')) {
            if (!!values?.FIRE_NOC?.length && !!values?.FACTORIES_AND_BOILERS_NOC?.length) {
                submitForm(action);
                return;
            } else {
                setModalDetails({ show: true, title: 'Error!', para: 'Please submit the required NOC Documents', img: cross, type: 'error', reUpload: 'OK' });
                window.scrollTo(0, 850);
                setOpenSection('NOC');
                return;
            }
        }

        submitForm(action);
    };

    const initiateNoc = () => {
        setIsLoading(true);
        let source;
        if (formData?.applicationType === 'OCCUPANCY_CERTIFICATE') {
            source = COMPLETION_ORDER;
        }
        let payload: any = {
            RequestInfo: requestInfo(),
            Noc: {
                tenantId: TENANT_ID,
                applicationType: APPLICATION_TYPE,
                source: source,
                sourceRefId: formData?.applicationNo
            }
        };

        try {
            POST(endpoints.createNoc, payload)
                .then(res => {
                    setModalDetails({ show: true, title: 'Success!', para: 'Action successful', img: check, type: 'success', reUpload: 'OK' });
                    // toast('Action successful', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                    navigate(`/goa-idc/noc?applicationNumber=${res.data.applicationNo}&tenantId=${TENANT_ID}`);
                    setIsLoading(false);
                })
                .catch(e => {
                    setModalDetails({ show: true, title: 'Error!', para: 'Action unsuccessful', img: cross, type: 'error', reUpload: 'OK' });
                    // toast('Action unsuccessful', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'error' });
                    setIsLoading(false);
                });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('Comments are required'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('Comments are required'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
            setIsLoading(false);
        }
        console.log('Initiate NOC', payload);
    };

    const totalPages = true ? 2 : 1;
    const onClose = () => {
        setShowPopUp(false);
    };
    // const { values, setValues } = formik;

    const fetchNOCDetails = async () => {
        try {
            const nextAction = await POST(endpoints.nextValidAction + `?businessId=${applicationNumber}&tenantId=ga`, { RequestInfo: requestInfo() });
            setNextValidAction(nextAction.data);

            const { data } = await POST(`/noc-services/v2/noc/_search?tenantId=ga&applicationNo=${applicationNumber}`, { RequestInfo: requestInfo() });
            if (!data) return;
            setResponse(data);
            const { entityName, email, phoneNumber, panNumber } = data.enterpriseEntity;
            setValues({ ...values, ...data.plotDetails, ...data.enterpriseEntity?.addresses[0], entityName, email, phoneNumber, panNumber });
            setResponseNocDetails(data.nocDetails);
            setResponseClarificationHistory(data.clarificationHistoryDetails);
            setNocDocumentReferenceIds(data.nocDocumentReferenceIds);
            setNocApplicationNo(data.applicationNo);
        } catch (e: any) {
            console.log(e);
            let errMsg = e.response?.data?.errorMessage || 'Something went wrong';

            // setModalDetails({ show: true, title: errMsg, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        fetchNOCDetails();
    }, []);

    const submitQuestionnaire = () => {
        if (!!questions.filter(val => val === '').length) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill the question data', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        setIsLoading(true);
        let questionLists: any = [];
        !!questions.length &&
            questions.map((val, ind) => {
                questionLists.push({
                    applicationNumber: applicationNumber,
                    questionnaireId: ind + 1,
                    question: val,
                    documents: []
                });
                return questionLists;
            });

        let payload: any = {
            RequestInfo: BPArequestInfo(),
            backQuestionnaires: questionLists
        };

        TechnicalServices.manageQuestionnaire(payload, false)
            .then((response: any) => {
                setIsLoading(false);
                setShowPopUp(false);
                const successMessageOCQuestionnaire = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} ${t(
                    'POPUP_MSG_SUCCESS_QUESTIONNAIRE_SUBMITTED',
                    'Questions submitted successfully'
                )}`;
                setModalDetails({
                    show: true,
                    title: 'Success!',
                    // para: t('POPUP_MSG_SUCCESS_QUESTIONNAIRE_SUBMITTED', 'Questions submitted successfully'),
                    para: successMessageOCQuestionnaire,
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                    // children: (
                    //     <div>
                    //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                    //     </div>
                    // )
                });
                // submitForm(selectedAction);
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const downloadFile = (id: any) => {
        console.log(id, 'ID');
        if (!id) return;
        axios
            .get(devUrl + `/filestore/v1/files/url?tenantId=ga&fileStoreIds=${id}`)
            .then((response: any) => {
                window.open(response?.data?.fileStoreIds[0]?.url);
            })
            .catch(error => {});
    };

    const renderQuestionnaireDetails = () => {
        return (
            <>
                {!!questionnaireDetails &&
                    questionnaireDetails.map((val: any, ind: number) => {
                        values[`que_${val?.id}`] = val?.question;
                        if (!!val?.answer) values[`ans_${val?.id}`] = val?.answer;
                        const myArray = {
                            formData: [
                                {
                                    title: 'Question',
                                    field: `que_${val?.id}`,
                                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                                    isRequired: true,
                                    isDisabled: true,
                                    titleCode: `OC_LBL_ENTERPRISE_NAMEs ${ind + 1}`,
                                    class: 'me-2'
                                },
                                {
                                    title: 'Answer',
                                    field: `ans_${val?.id}`,
                                    type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                                    isRequired: true,
                                    titleCode: 'Your Feedback',
                                    class: 'me-2',
                                    isDisabled: !!val?.lesseeReplyStatus
                                }
                            ]
                        };

                        const uploadedTime = questionnaireData[0]?.documents[0]?.createdTime;
                        console.log(uploadedTime);

                        // const uploadedTime = 1723443622459;
                        const dateObject = new Date(uploadedTime);

                        // Convert the date to IST by adding the IST offset
                        const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC +5:30
                        const istDateObject = new Date(dateObject.getTime() + istOffset);

                        // Format the date as DD/MM/YYYY
                        const formattedDate = istDateObject.toLocaleDateString('en-IN');
                        console.log(formattedDate); // Output: "12/08/2024"

                        return (
                            <>
                                {/* <Card>
                                    <CardBody> */}
                                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 my-2'>
                                    <input type='checkbox' id={`${val?.id}_${ind}`} name={`${val?.id}_${ind}`} defaultChecked={false} />
                                    <label htmlFor={`${val?.id}_${ind}`} className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold  '>
                                        {t('OC_EMP_REPORT_HEADING', `Correction Report ${ind + 1}`)}
                                    </label>
                                    <div className='form_fields px-2'>
                                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2 '>
                                            <GenericFormCard {...myArray} key={ind} />
                                        </div>
                                        <div className='mt-3'>
                                            <OwnershipTable
                                                column={EMP_QUESTIONNAIRE_DOCUMENT_COLUMN}
                                                tableRowData={val?.documents || []}
                                                additionalMethod={() => {}}
                                                deleteOwnershipDetails={() => {}}
                                                updateOwnershipDataStatus={(isId: any, data: any) => downloadFile(data?.rowId ?? data?.fileStoreId)}
                                                isDisabled={false}
                                                hideSearchBar
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* </CardBody>
                                </Card> */}
                            </>
                        );
                    })}
                {!questionnaireDetails.length && (
                    // <Card>
                    //     <CardBody>
                    <p className='text-center fw-bold pt-2'>No Data Available</p>
                    //     </CardBody>
                    // </Card>
                )}
            </>
        );
    };

    const renderActionBtn = () => {
        const handleAddField = () => {
            setQuestions([...questions, '']);
        };

        const handleRemoveField = (index: any) => {
            const newQuestions = questions.filter((_, i) => i !== index);
            setQuestions(newQuestions);
        };

        const handleChange = (e: any, index: any) => {
            const newQuestions = questions.map((question, i) => (i === index ? e.target.value : question));
            setQuestions(newQuestions);
        };

        return (
            <>
                {actionsData?.nextValidAction
                    .map((val: any, index: number) => {
                        if (val?.action === 'Submit') return;
                        if (val?.action === 'ACT_OC_SEND_BACK_LESSEE') {
                            return (
                                <>
                                    <div className='position-relative'>
                                        <GenericButtons
                                            text={
                                                <>
                                                    {t('REVERT', 'Revert')} <FontAwesomeIcon icon={faChevronUp} />
                                                </>
                                            }
                                            handleClick={() => {
                                                setShowBackOptionsBtn(!showBackOptionsBtn);
                                            }}
                                        />

                                        <div className={`position-absolute showBackBtns ${showBackOptionsBtn ? 'visible' : ''}`} style={{ bottom: '3rem' }}>
                                            {actionsData?.nextValidAction && showBackOptionsBtn && (
                                                <div className='send-back-card justify-content-center'>
                                                    {actionsData?.nextValidAction
                                                        ?.filter((val: any) => val?.action === 'ACT_OC_SEND_BACK_LESSEE')
                                                        .map((item: NextValidActionItem, index: number) => (
                                                            <>
                                                                <div className='  font-16 dropdown-text cursor-pointer font-inter' onClick={e => onActionClick(val?.action)}>
                                                                    <div>{t(item?.action)}</div>
                                                                </div>
                                                            </>
                                                        ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {/* SAMPLECODE */}
                                    {/* <GenericButtons
                                        key={val?.action}
                                        variant={actionsData?.nextValidAction.length > 0 && index === 0 ? 'primary' : ''}
                                        text={t(val?.action, `${(val?.action).replace(/_/g, ' ')}`)}
                                        handleClick={() => onActionClick(val?.action)}
                                        ClassName={'variant'}
                                    /> */}

                                    {showPopUp && (
                                        <GenericPopupModal
                                            isPrimaryBtn={true}
                                            isSecondaryBtn={true}
                                            secondaryBtnTitle={t('CLOSE', 'Close')}
                                            primaryBtnTitle={t('SEND', 'Send')}
                                            secondaryAction={() => setShowPopUp(false)}
                                            onClose={onClose}
                                            primaryAction={() => {
                                                submitQuestionnaire();
                                            }}
                                            isVisible={showPopUp}
                                            children={
                                                <div className='' style={{ background: '#F5F5F6' }}>
                                                    <div className='my-3 mx-3'>
                                                        {questions.map((question, index) => (
                                                            <div key={index} className='mb-3'>
                                                                <label className='mb-3'>
                                                                    {t('QUESTIONNAIREs', 'Questionnaire/Query')}
                                                                    <span style={{ color: 'red' }}> *</span>
                                                                </label>
                                                                {/* <GenericCommonTextAreaField field={``} title='' isRequired={true} /> */}
                                                                <textarea value={question} onChange={e => handleChange(e, index)} rows={4} style={{ width: '100%' }} />
                                                                <button
                                                                    type='button'
                                                                    className='col-sm-12 mt-2  mb-2 d-flex justify-content-center align-items-center  noc_add_fields'
                                                                    onClick={() => handleRemoveField(index)}
                                                                >
                                                                    - {t('REMOVE_FIELD', 'Remove Field')}
                                                                </button>
                                                            </div>
                                                        ))}
                                                        <button className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center  noc_add_fields' onClick={handleAddField}>
                                                            + {t('ADD_FIELD', 'Add Field')}
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            title={`${t('', 'Completion Order & Occupancy Certificate')}`}
                                            subTitle={t('CLARIFICATION_REQUIRED', 'Clarification required')}
                                            size='xl'
                                            // display='d-none'
                                        />
                                    )}
                                </>
                            );
                        }
                        return (
                            <GenericButtons
                                key={val?.action}
                                variant={actionsData?.nextValidAction.length > 0 && index === 0 ? 'primary' : ''}
                                text={t(val?.action, `${(val?.action).replace(/_/g, ' ')}`)}
                                handleClick={() => onActionClick(val?.action)}
                                ClassName={'variant'}
                            />
                        );
                    })
                    .reverse()}
                {!!fieldInspection?.requestForReschedule && fieldInspection?.requestForRescheduleData !== null && <GenericButtons text={'Accept'} handleClick={() => onAcceptRequest()} />}
            </>
        );
    };

    const renderInspectionList = () => {
        let modifiedArray = FIELD_INSPECTION_DETAILS_II;
        modifiedArray.map((v: any) => {
            v.formData.map((h: any) => (h.isDisabled = fieldInspection?.inspectionCheckListData !== null));
        });
        return (
            <>
                {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                {modifiedArray.map(val => (
                    <GenericFormCard {...{ ...val }} />
                ))}
                {/* </Card.Body>
                </Card> */}
            </>
        );
    };

    const renderFieldInspectionForm = () => {
        return (
            <>
                {FIELD_INSPECTION_DETAILS.map(val => (
                    <Card.Body>
                        <GenericFormCard {...val} />
                    </Card.Body>
                ))}
            </>
        );
    };

    const handleModalClose = (type: string, final?: boolean) => {
        if (modalDetails.para === 'Submitted successfully') {
            window.location.reload();
        } else {
            setModalDetails({ show: false });
            if (type === 'success') {
                navigateToDashboard();
            }
        }
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, '', null));
            getDocuments();
            setActiveMainIndex(2);
        } catch (e) {}
    };

    // useEffect(() => {
    //     if (activeMainIndex === 1) searchBPA(applicationNumber);
    //     // values.isEditableFile = false;
    // }, [activeMainIndex]);

    const handleScroll = () => {
        if (window.scrollY > 110) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (!!applicationNumber) {
            setIsSelectedBPA(applicationNumber);
            return;
        }
        getTechnicalClearanceData();
    }, []);

    useEffect(() => {
        if (selectedBPA) searchBPA(selectedBPA);
    }, [selectedBPA]);

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const handleNextPage = () => {
        if (activeMainIndex < totalPages) setActiveMainIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        fetchInitialData(previewData);
        if (activeMainIndex >= 2) setActiveMainIndex(prevInd => prevInd - 1);
    };

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <StatusAlertPopup para={bodydata} img={cross} handleClose={handleClose} handleSubmit={handleStatusSubmit} show={showStatus} title={''} />
            <div className='applicationPage m-auto px-5 py-4'>
                <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                    <GenericHeader
                        // subText={`Permit No. : ${values?.refApplication || applicationNumber || 'NA'}`}
                        text={t('OC_EMP_HEADER_APPLICATION_FOR_OCCUPANCY_CERTIFICATE', 'Application for Completion Order & Occupancy Certificate')}
                        additionalClass='sub-text-style'
                        onActionClick={() => navigateToDashboard()}
                        bottomSubText={`File : ${values?.name}, Type : ${t('OC_EMP_HEADER_TYPE', 'Completion Order & Occupancy Certificate')}, No :${
                            applicationNumber || values?.refApplication || 'NA'
                        }`}
                    />
                    <Formik initialValues={initialValues} onSubmit={value => {}}>
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('OC_EMP_SUBHEADER_APPLICATION_FOR_OCCUPANCY_CERTIFICATE', 'Application for Completion Order & Occupancy Certificate')}
                                        sideHeader={true}
                                        onActionClick={() => navigateToDashboard()}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : ${t('OC_EMP_SUBHEADER_TYPE', 'Completion Order & Occupancy Certificate')}, No : ${applicationNumber || values?.refApplication || 'NA'}`}
                                    />

                                    <NotingWrapper
                                        text={comment}
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={!values.isUpdatable}
                                        handleOnFileUpload={handleOnFileUpload}
                                    />
                                    {/* <p className='noting-heading my-1'>{t('OC_EMP_NOTING_HEADING', 'Noting')}</p>

                                    <NotingComponent history={historyDetails} text={comment} handleOnFileUpload={handleOnFileUpload} /> */}
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={{ businessService: previewData?.businessService }} />

                                {/* <p className='correspondence-heading my-1'>{t('OC_EMP_CORRESPONDENCE_HEADING', 'Correspondence')}</p> */}
                                <Form>
                                    {activeMainIndex === 1 && (
                                        <OrderCompletionFormSubPage
                                            index={activeIndex}
                                            activeStepper={activeStepper}
                                            setActiveIndex={setActiveIndex}
                                            employeeList={employeeData}
                                            setEditorContent={setEditorContent}
                                            editorContent={editorContent}
                                            formData={formData}
                                            previewData={previewData}
                                            appStatus={appStatus}
                                            showProcess={showProcess}
                                            allchecked={allchecked}
                                            setAllChecked={setAllChecked}
                                            bpaData={bpaData}
                                            isCancelBtn={isCancelBtn}
                                            setShowProcess={setShowProcess}
                                            setFormValues={setFormValues}
                                            formValues={formValues}
                                            searchBPA={(value: any) => searchBPA(value)}
                                            allBPADetails={allBPADetails}
                                            allEdcrDetails={allEdcrDetails}
                                            setIsSelectedBPA={setIsSelectedBPA}
                                            applicationNumber={applicationNumber}
                                            fieldInspection={fieldInspection}
                                            onActionClick={openInspectionForm}
                                            actionsData={actionsData}
                                            onAcceptRequest={onAcceptRequest}
                                            openSection={openSection}
                                            setOpenSection={setOpenSection}
                                            openInspectionForm={openInspectionForm}
                                            inspectionReport={inspectionReport}
                                            reportDocsData={reportDocsData}
                                            setReportDocsData={setReportDocsData}
                                            renderQuestionnaireDetails={renderQuestionnaireDetails}
                                            questionnaireDetails={questionnaireDetails}
                                            content={content}
                                            setContent={setContent}
                                        />
                                    )}

                                    {activeMainIndex === 2 && (
                                        <GenericActionTable columns={DOC_TABLE_COLUMN} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                    )}

                                    <GenericPopupModal
                                        title='Submit Inspection Report'
                                        size='lg'
                                        primaryAction={() => createFieldInspection('report')}
                                        secondaryAction={() => setIsShowInspReport(false)}
                                        isVisible={isShowInspReport}
                                        children={renderInspectionList()}
                                        primaryBtnTitle='Submit'
                                        secondaryBtnTitle='Close'
                                        isPrimaryBtn={fieldInspection?.inspectionCheckListData === null}
                                        onClose={() => setIsShowInspReport(false)}
                                    />
                                </Form>
                            </div>
                        </FormikProvider>
                    </Formik>
                    <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                        <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                            <img src={activeMainIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeMainIndex === 2 && `rotate-class`}`} />
                        </span>
                        Page {activeMainIndex} of {totalPages}
                        <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                            <img src={activeMainIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeMainIndex === totalPages && `rotate-class`}`} />
                        </span>
                    </div>
                </div>
            </div>
            {
                <GenericFooterComponent
                    isSaveBtn={false}
                    onClickNext={() => {}}
                    onClickSave={() => {}}
                    onSubmit={() => submitForm()}
                    onCancel={() => navigateToDashboard()}
                    onClickPrev={() => {}}
                    stepperLength={stepperDetails.length}
                    activeIndex={activeIndex}
                    isSubmitBtn={false}
                    children={renderActionBtn()}
                />
            }
            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} />}
                        {!isPDF && <GenericImageViewer />}
                    </div>
                </GenericPopupModal>
            )}

            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => {
                    handleModalClose(modalDetails.type, modalDetails?.finalSubmit);
                    if (modalDetails.type === 'success') {
                        navigateToDashboard();
                    }
                }}
                children={modalDetails.children}
            />
        </>
    );
}
