// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container .button-box {
  border: dashed 2px rgba(69, 133, 46, 0.5019607843);
  border-radius: 8px;
  background-color: #ecf2ea;
  cursor: pointer;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.btn-container .button-box button {
  border: none;
  background: none;
  color: rgba(0, 0, 0, 0.6980392157);
  font-size: 14px;
  font-weight: 500;
}

.delete-btn {
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/features/modules/UserManagement/EmployeeModule/EmployeeDetailsPage.scss"],"names":[],"mappings":"AACI;EACI,kDAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AAAR;AAGI;EACI,YAAA;EACA,gBAAA;EACA,kCAAA;EACA,eAAA;EACA,gBAAA;AADR;;AAKA;EACI,aAAA;EACA,yBAAA;AAFJ","sourcesContent":[".btn-container {\n    .button-box {\n        border: dashed 2px #45852e80;\n        border-radius: 8px;\n        background-color: #ecf2ea;\n        cursor: pointer;\n        height: 47px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        margin-bottom: 20px;\n    }\n\n    .button-box button {\n        border: none;\n        background: none;\n        color: #000000b2;\n        font-size: 14px;\n        font-weight: 500;\n    }\n}\n\n.delete-btn {\n    display: flex;\n    justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
