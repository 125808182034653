import React, { useEffect, useState } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { LESSEE_DETAILS, ARCHITECT_DETAILS, BUILDING_PLAN_DETAILS, DOCUMENTS_DETAILS } from './TechnicalClearanceFormPage.const';
import { useFormikContext } from 'formik';
import { Viewer, Worker, OpenFile } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import PaymentSubmissionStatus from './TechnicalClearencePayment/PaymentSubmissionStatus';
import { POST, devUrl, endpoints, getProgressBarData, requestInfo } from '../../../services/modulesServices/userManagement.api';
import TechnicalServices from '../../../services/technicalServices/Technical.service';
import ScrutinySteps from '../../StepsForPopUp/Steps';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { RoleInterface } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import ScruitnyPopupModal from '../../scrutinyFlowScreen/scrutinyPopUp/ScruitnyModalPopup.component';
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { getBPAList, getFeeDetailsList } from './TechnicalClearanceFormPage.controller';
import { useNavigate } from 'react-router-dom';
import BPASelectionTable from './BPASelectionTable.component';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericPopupModal from '../../../components/moduleCommonFormik/GenericModalPopup.component';
import ProgressBar from '../../../components/moduleCommonFormik/ProgressBar';
import { useTranslation } from 'react-i18next';
import PaymentDetails from '../../modules/EstateManagement/Allotment/PaymentDetails';
import { paymentColumnsForTC } from '../../modules/EstateManagement/Allotment/Allotment.const';
import { cross, download } from '../../../utils/Logo';
import GenericButtons from '../../../components/UI components/Buttons/GenericButtons';
import GenericActionTable from '../../../components/moduleCommonFormik/GenericActionTable';
import moment from 'moment';
import { CONSTANT_DATA_FIELD } from '../../../utils/constants';
import ToolTipDetails from '../../../components/ToolTipFile';
import fileDown from '../../../assets/logos/download.svg';
// import { TCTableColumns } from './TechnicalClearanceFormPage.module';

interface BuildingSubPage {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    index: number;
    // setFormikValues: React.Dispatch<any>;
    employeeList: any;
    formData: any;
    previewData: any;
    applicationNumber?: string;
    appStatus: boolean;
    showProcess: boolean;
    activeStepper: string;
    allchecked: any;
    setAllChecked: React.Dispatch<any>;
    bpaData: any;
    isCancelBtn: boolean;
    setShowProcess: React.Dispatch<React.SetStateAction<boolean>>;
    allBPADetails: any;
    allEdcrDetails: any;
    setIsSelectedBPA: React.Dispatch<React.SetStateAction<string>>;
    applicationFee: any;
    sanctionFee: any;
    paymentRowData: any;
    payVisible: boolean;
    handelPlayVisible: () => void;
    setSelectedGateway: React.Dispatch<any>;
    selectedGateway: any;
    onPay: () => void;
    signDocument: () => void;
    eSignFlag: boolean;
}

export default function ScrutinyFormSubPage(props: BuildingSubPage) {
    const { t, i18n } = useTranslation();
    const { values, resetForm, setValues, setFieldValue } = useFormikContext<any>();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [isMultiLessee, setIsMultiLessee] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(true);
    const [auditTrial, setAuditTrial] = useState<any>(null);
    const [planBlobData, setPlanBlobData] = useState<any>(null);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [selectedEDCRNo, setSelectedOneEDCRNo] = useState<null | string>(null);
    const [selectedEDCRDDetails, setSelectedEDCRDetails] = useState<any>({});

    const isRoleMD = !user?.roles?.filter((role: RoleInterface) => role.code === 'MD').length;
    const navigate = useNavigate();

    function handleChange(e: any) {
        if (e.target.checked) {
            props?.setAllChecked([...props?.allchecked, e?.target?.value]);
        } else {
            props?.setAllChecked(props?.allchecked.filter((item: any) => item !== e.target.value));
        }
    }

    const onEDCRSelect = (selectedEDCR: any) => {
        console.log('selectedEDCR', selectedEDCR);
        setSelectedOneEDCRNo(selectedEDCR.id);
        setSelectedEDCRDetails(selectedEDCR);
        let edcrNo = selectedEDCR.dcrNumber;
        setValues({
            ...values,
            edcrNo
        });
    };

    console.log(values);

    const TCTableColumns = [
        {
            colName: 'Select',
            fieldName: 'radio',
            clsName: 'col-sm-1',
            onRadioClick: onEDCRSelect,
            selectedValue: selectedEDCRNo,
            id: 1
        },
        {
            colName: 'DCR Number',
            fieldName: 'dcrNumber',
            clsName: 'col-sm-2',
            isClickable: false,
            id: 2
        },
        {
            colName: 'Estate',
            fieldName: 'estate',
            clsName: 'col-sm-2',
            id: 3
        },
        {
            colName: 'Plot Number',
            fieldName: 'plotNumber',
            clsName: 'col-sm-2',
            id: 4
        },
        {
            colName: 'Architect Name',
            fieldName: 'architectName',
            clsName: 'col-sm-2',
            id: 5
        },
        {
            colName: 'Shared Date',
            fieldName: 'sharedDate',
            clsName: 'col-sm-2',
            id: 6
        }
    ];

    const fetchGatewayData = async () => {
        try {
            const { data } = await POST(`/egov-mdms-service/v1/_search`, {
                RequestInfo: requestInfo(),
                MdmsCriteria: {
                    tenantId: 'ga',
                    moduleDetails: [
                        {
                            moduleName: 'egov-pg-service',
                            masterDetails: [
                                {
                                    name: 'Gateway',
                                    filter: '[?(@.active == true)]'
                                }
                            ]
                        }
                    ]
                }
            });
            let feeMasterDetails = data?.['MdmsRes']?.['egov-pg-service']?.['Gateway'];
            setGatewayData(feeMasterDetails || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const pdfUrlToBase64 = async (url: any) => {
        if (!url) return;
        var bodyFormData = new FormData();
        bodyFormData.append('url', url.replace(/^http:/, 'https:'));
        let data = '';
        TechnicalServices.getScrutinyFile(bodyFormData)
            .then((response: any) => {
                // handle the response
                if (response?.data) {
                    if (response?.data?.base64 !== '') {
                        setPlanBlobData(`data:application/pdf;base64, ${response?.data?.base64}`);
                        return;
                    }
                    setPlanBlobData('https://sujog-dev.odisha.gov.in/filestore/v1/files/id?fileStoreId=9450d85e-1768-49a5-93fb-32a8fffa98c2&tenantId=od');
                }
            })
            .catch(error => {});
    };

    const getAuditData = () => {
        if (!props?.applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.getAuditTrail(payload, props?.applicationNumber, 'ga')
            .then(res => {
                setAuditTrial(res?.data?.ProcessInstances);
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        if (!!props?.allBPADetails && props?.allBPADetails.length > 0) {
            setIsMultiLessee(props?.allBPADetails.length > 0);
        }
    }, [props?.allBPADetails]);

    useEffect(() => {
        console.log(props?.previewData);
        pdfUrlToBase64(props?.previewData?.additionalDetails?.planReport || props?.formData?.additionalDetails?.planReport);
    }, [!!(props?.activeStepper === 'BUILDING_PLAN_REPORT')]);

    useEffect(() => {
        getAuditData();
        fetchGatewayData();
    }, []);

    useEffect(() => {
        const fetchESignStatus = async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: values.appId
                };
                const data = await POST(endpoints.consolidatedDocSearch, payload);
                console.log(data);
                setESignedDocumentData(data?.data);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        if (props?.activeStepper === 'DECLARATION') {
            fetchESignStatus();
        }
    }, [props.activeStepper]);

    useEffect(() => {
        try {
            // getAuditData()
            if (!!props?.applicationNumber) getProgressBarData(props?.applicationNumber).then(res => setProgressBarDetails(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [props?.applicationNumber]);

    const renderBuildingPlanDetails = () => {
        if (props?.appStatus) {
            BUILDING_PLAN_DETAILS[0].isReadOnly = true;
        }
        return (
            <>
                {BUILDING_PLAN_DETAILS.map(val => (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <GenericFormCard {...val} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const renderBildingPlanReportDetails = () => {
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        <div className='row col-sm-12'>
                            <div className='row col-sm-3'>
                                <label>{t('TC_DOC_LBL_SERVICE_TYPE', 'Service Type')}</label>
                                <div className='fw-bold'>{t('TC_DOC_SERVICE_TYPE', 'New Construction')}</div>
                            </div>
                            <div className='row col-sm-3'>
                                <label>{t('TC_DOC_LBL_PLAN_FILE', 'Plan File')}</label>
                                <div>
                                    <a className='fw-bold' href={props?.previewData?.additionalDetails?.dxfFile || (props?.formData?.additionalDetails && props?.formData?.additionalDetails.dxfFile)}>
                                        {t('TC_DOC_PLAN_FILE', 'Download Building Plan')}{' '}
                                    </a>
                                </div>
                            </div>
                            <div className='row col-sm-3'>
                                <label>{t('ARC_DOC_LBL_SITE_PLAN_FILE', 'Site Plan')}</label>
                                <div className='fw-bold'>
                                    <a href={props?.previewData?.additionalDetails?.sitePlan || (props?.formData?.additionalDetails && props?.formData?.additionalDetails.sitePlan)}>
                                        {t('ARC_DOC_SITE_PLAN_FILE', 'Download Site Plan')}{' '}
                                    </a>
                                </div>
                            </div>
                            <div className='row col-sm-3'>
                                <label>{t('TC_DOC_LBL_SCRUTINY_STATUS', 'Scrutiny Status')}</label>
                                <div
                                    className={
                                        props?.formData?.additionalDetails?.status === 'Accepted' || props?.previewData?.additionalDetails?.status === 'Accepted' ? 'text-success' : 'text-danger'
                                    }
                                >
                                    {props?.formData?.additionalDetails?.status || props?.previewData?.additionalDetails?.status || 'Not Accepted'}
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className='card_border my-2 card_bg'>
                    <Card.Body>
                        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
                            <Viewer
                                fileUrl={planBlobData || []}
                                plugins={[
                                    // Register plugins
                                    getFilePluginInstance,
                                    defaultLayoutPluginInstance
                                ]}
                            />
                        </Worker>
                    </Card.Body>
                </Card>
            </>
        );
    };

    const onDownload = (url: any) => {
        window.open(url);
    };

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '');
        const month = String(today.getMonth() + 1).padStart(2, '');
        const year = today.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const currentDate = getCurrentDate();
    console.log(values?.additionalDetails);

    const renderBasicDetails = () => {
        const applicationData = {
            applicationNo: props?.applicationNumber,
            status: props?.previewData?.status,
            data: auditTrial,
            isRoleMD: isRoleMD,
            bpaData: props?.bpaData
        };

        return (
            <>
                {!!props.applicationNumber && (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                        </Card.Body>
                    </Card>
                )}
                <Card className='card_border my-2'>
                    <Card.Body>
                        {LESSEE_DETAILS.map((val, key) => (
                            <GenericFormCard {...{ ...val }} key={key} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderArchitectDetails = () => {
        const applicationData = {
            applicationNo: props?.applicationNumber,
            status: props?.previewData?.status,
            data: auditTrial,
            isRoleMD: isRoleMD,
            bpaData: props?.bpaData
        };

        const modifiedArchDetails = ARCHITECT_DETAILS;
        // values.isEditableFile = true;
        // modifiedArchDetails[0]?.formData.forEach(val => {
        //     if (val?.type === CONSTANT_DATA_FIELD.NEW_FILE_FIELD) val.isDisabled = true;
        // });

        const toolTipDetails = {
            position: 'bottom',
            content: '<p>This is system generated site plan from the scrutinized building plan</p>'
            // linkUrl: 'https://www.pollution.com',
            // linkText: 'Website Link'
        };

        console.log(!!values?.additionalDetails?.sitePlan, 'Siteplan');

        return (
            <>
                {!!props.applicationNumber && (!props?.previewData?.status || props?.previewData?.status !== 'APPL_STATUS_TC_SUBMITION_PENDING') && (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                        </Card.Body>
                    </Card>
                )}

                {modifiedArchDetails.map(val => (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <GenericFormCard {...val} />
                            <div className='ps-3 me-3'>
                                {!!values?.additionalDetails?.sitePlan && (
                                    <>
                                        <div className='d-flex'>
                                            Site Plan <span className=' text-danger '>*</span>
                                            <ToolTipDetails toolTipDetails={toolTipDetails} />
                                        </div>

                                        <div
                                            className={`btn btn-none fileUploadBox py-2 ps-2 p-4 m-1`}
                                            style={{
                                                borderRadius: '12px',
                                                justifyContent: 'space-between',
                                                backgroundColor: '#45852E',
                                                height: '5rem',
                                                width: '49%' // Adjusts width based on resolution
                                            }}
                                        >
                                            <div className='d-flex align-items-center ms-0 ps-0' onClick={() => onDownload(values?.additionalDetails?.sitePlan)}>
                                                <div className='ms-2 text-start' style={{ padding: '8px' }}>
                                                    <span>
                                                        <div
                                                            className='file-name uploaded-file-name text-white text-nowrap text-truncate'
                                                            style={{ fontSize: '14px', lineHeight: '18px', marginBottom: '10px' }}
                                                            title={'SitePlan.pdf'}
                                                        >
                                                            {`${values?.name}_SITEPLAN.PDF`}
                                                        </div>
                                                        <div className='text-white' style={{ fontSize: '12px', lineHeight: '18px' }}>
                                                            {'4 MB'} . {currentDate}
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='me- d-flex'>
                                                <Image src={fileDown} height={20} onClick={() => onDownload(values?.additionalDetails?.sitePlan)} />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const renderDocumentDetails = () => {
        let modifiedArray = DOCUMENTS_DETAILS;
        if (!!props?.appStatus) {
            modifiedArray.map((v: any) => {
                v.formData.map((h: any) => (h.isDisabled = true));
            });
        }

        return (
            <>
                {modifiedArray.map(val => (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <GenericFormCard {...{ ...val }} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const downloadApplicationDocument = () => {
        const fileStoreId = props?.previewData?.digitalSignedDoc || props?.bpaData?.digitalSignedDoc;
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${fileStoreId}`;
        window.open(downloadFileUrl);
    };

    const renderDeclarationDetails = () => {
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        <div className='row ps-2 py-2'>
                            <div className='formTitle m-0'>
                                {t('TC_CARD_HEADER_SELF_DECLARATION', 'Self Declaration')}
                                <span className='text-danger'>*</span>
                            </div>
                        </div>
                        <div className='row d-flex m-0'>
                            <p className='fw-bold'>The Lessee does hereby affirm and declare that:</p>

                            <label className='mb-3 checkbox-container'>
                                {!!props.previewData?.lesseeDigitalSignFlage ? (
                                    <input
                                        type='checkbox'
                                        id='myCheckbox'
                                        className={`me-2 ${props.previewData?.lesseeDigitalSignFlage && 'default-checked'} `}
                                        checked={props.previewData?.lesseeDigitalSignFlage}
                                        style={{ opacity: '0' }}
                                    />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='1' name='step_1' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                                )}
                                <span className={`${props.previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span>
                                    (i) The information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed
                                    herein.
                                </span>
                            </label>

                            <label className='mb-3 checkbox-container'>
                                {!!props.previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={props.previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='2' className='me-2' name='step_2' onChange={handleChange} style={{ opacity: '0' }} />
                                )}
                                <span className={`${props.previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span>
                                    (ii) It has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation
                                    proceedings.
                                </span>
                            </label>

                            <label className='mb-3 checkbox-container'>
                                {!!props.previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={props.previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='3' name='step_3' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                                )}
                                <span className={`${props.previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span>
                                    (iii) The technical clearance, construction license, as the case may be, shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the
                                    information submitted under or along with this application.
                                </span>
                            </label>
                        </div>
                        {!!props?.previewData?.lesseeDigitalSignFlage || props?.eSignFlag ? (
                            <>
                                <div className='ps-2 pt-2 font-14 font-inter fw-bold m-0'>Digitally Signed</div>
                                <div className='ps-2 pb-2 font-14 font-inter'>{eSignedDocumentData?.esignedDate}</div>
                                <div onClick={() => downloadApplicationDocument()} className='ps-2 d-flex align-items-center'>
                                    <Image src={download} height={14} />
                                    <div className='ps-2 font-14' style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>
                                        Download Application
                                    </div>
                                </div>
                            </>
                        ) : (
                            <GenericButtons text='Sign the document' ClassName='px-3' handleClick={props?.signDocument} />
                        )}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderPayment = () => {
        const FEE_DETAILS = getFeeDetailsList(props?.applicationFee) || [];
        const SANCTION_DETAILS = {
            txnId: props?.paymentRowData?.transactionNumber || '',
            auditDetails: { createdTime: props?.paymentRowData?.transactionDate || '' },
            txnStatus: 'SUCCESS',
            additionalDetails: { paymentReceiptDocRefId: props?.paymentRowData?.receiptId || '' }
        };

        // Function to format numbers with commas
        const formatNumberWithCommas = (number: any) => {
            return number.toLocaleString('en-IN');
        };

        // Format the BPAPaymentDetails array
        const formattedPaymentDetails = (props?.paymentRowData?.BPAPaymentDetails || []).map(
            (
                detail: {
                    principalAmount: any;
                    cgstPrincipal: any;
                    sgstPrincipal: any;
                    interestAmount: any;
                    penaltyAmount: any;
                    cgstPenalty: any;
                    sgstPenalty: any;
                    totalDueAmount: any;
                    paidAmount: any;
                },
                index: number
            ) => ({
                ...detail,
                index: index + 1,
                principalAmount: formatNumberWithCommas(detail.principalAmount),
                cgstPrincipal: formatNumberWithCommas(detail.cgstPrincipal),
                sgstPrincipal: formatNumberWithCommas(detail.sgstPrincipal),
                interestAmount: formatNumberWithCommas(detail.interestAmount),
                penaltyAmount: formatNumberWithCommas(detail.penaltyAmount),
                cgstPenalty: formatNumberWithCommas(detail.cgstPenalty),
                sgstPenalty: formatNumberWithCommas(detail.sgstPenalty),
                totalDueAmount: formatNumberWithCommas(detail.totalDueAmount),
                paidAmount: formatNumberWithCommas(detail.paidAmount)
            })
        );
        console.log(formattedPaymentDetails);
        return (
            <>
                <div className='my-2'>
                    <PaymentDetails
                        paymentData={formattedPaymentDetails}
                        title={t('TC_CARD_HEADER_PAYMENT_BREAKDOWN', 'Payment Breakdown')}
                        selectedGateway={props.selectedGateway}
                        setSelectedGateway={props.setSelectedGateway}
                        isPaid={props?.paymentRowData?.isPaymentRecived}
                        columns={paymentColumnsForTC}
                        gatewayData={gatewayData}
                        transactionDetails={SANCTION_DETAILS}
                        totalLabel={'totalDueAmount'}
                    />
                </div>
            </>
        );
    };

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            // `file.name` is the URL of opened file
            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            return `scrutiny_report.pdf`;
        }
    });

    const { DownloadButton } = getFilePluginInstance;
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Download: () => (
            <>
                <DownloadButton />
            </>
        )
    });

    const handelIsLesseeVisible = () => {
        setIsMultiLessee(false);
        navigate(-1);
    };

    const proceedToBPA = () => {
        props.setIsSelectedBPA(values?.edcrNo);
        setIsMultiLessee(false);
    };

    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar
    });
    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    const renderBPASelection = () => {
        if (!props.allBPADetails || props.allBPADetails.length <= 0)
            return (
                <>
                    <p className='text-center fw-bold pt-2'>No Data Available</p>
                </>
            );
        // Get the BPA list and add a unique ID to each row
        const bpaList = getBPAList(props?.allEdcrDetails).map((item: any, index: any) => ({
            ...item,
            id: index + 1, // Assigning a unique ID starting from 1
            sharedDate: moment(item.sharedDate).format('DD/MM/YYYY') // Format the date
        }));

        console.log(bpaList);
        return (
            <div className='mx-4 my-2'>
                <GenericActionTable
                    columns={TCTableColumns}
                    rowData={bpaList}
                    hideSearchBar={false}
                    itemsPerPage={10}
                    onCheckHandler={(e: any) => {
                        setFieldValue('edcrNo', e.value);
                    }}
                    checkType={'singleCheck'}
                    searchBarPlaceholder='Search by DCR No'
                />
            </div>

            // <BPASelectionTable
            //     data={bpaList}
            //     selectedRow={values?.edcrNo}
            //     onSelectRow={row => {
            //         setFieldValue('edcrNo', row.value);
            //     }}
            // />
        );
    };
    // console.log(values?.edcrNo);
    return (
        <>
            {props?.activeStepper === 'LESSEE_DETAITLS' && renderBasicDetails()}
            {props?.activeStepper === 'ARCH_DETAITLS' && renderArchitectDetails()}
            {props?.activeStepper === 'BUILD_PLAN' && renderBuildingPlanDetails()}
            {!!props?.showProcess && (
                <ScruitnyPopupModal
                    isVisible={props?.showProcess}
                    primaryAction={() => {}}
                    size='xl'
                    secondaryAction={() => {
                        props?.setShowProcess(true);
                    }}
                    display='d-none'
                    children={ScrutinySteps(props?.formData)}
                    isCancel={props?.isCancelBtn}
                    secondaryBtnTitle='Close'
                />
            )}
            {props?.activeStepper === 'BUILDING_PLAN_REPORT' && renderBildingPlanReportDetails()}
            {/* {props?.activeStepper === 'DOCUMENTS' && renderDocumentDetails()} */}
            {props?.activeStepper === 'DECLARATION' && renderDeclarationDetails()}
            {props?.activeStepper === 'PAYMENT' && renderPayment()}
            <GenericPopupModal
                children={renderBPASelection()}
                isPrimaryDisabled={!values?.edcrNo}
                isVisible={isMultiLessee}
                title='Scrutiny Report'
                primaryAction={proceedToBPA}
                secondaryAction={handelIsLesseeVisible}
                primaryBtnTitle='Proceed'
                secondaryBtnTitle='Cancel'
                size='xl'
            />
            {
                <GenericPopupModal
                    children={renderPayment()}
                    isVisible={props.payVisible}
                    title='Payment Details'
                    secondaryAction={props?.handelPlayVisible}
                    primaryBtnTitle='Pay Now'
                    primaryAction={props?.onPay}
                    secondaryBtnTitle='Cancel'
                    size='xl'
                />
            }
        </>
    );
}
