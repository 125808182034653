import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DASHBOARD_DOCUMNET_DETAILS } from './Dashboard.const';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import viewAllIcon from '../../../../assets/logos/view all.svg';
import { devUrl, endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { dateToEpochWithTime, epochToDate, getEpoch } from '../../../../utils/utils';
import { cross } from '../../../../utils/Logo';
import { useTranslation } from 'react-i18next';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { ADDENDUM_FOR_CHANGE_IN_OWNERSHIP, ADDENDUM_OF_CHANGE, DEED_OF_EXTENSION, DEED_OF_SURRENDER, LEASE_DEED, TRIPARTITE_DEED } from '../../../../utils/constants';
import { entityDocumentData } from '../../EstateManagement/EstateManagement.controller';

export const DocumentSection = (props: any) => {
    const [documnetCardIndex, setDocumnetCardIndex] = useState<any>(null);
    const closeCard = useRef<any>(null);
    const buttonRefs = useRef<any>([]);
    const { currentPlotDetails, modalDetails, setModalDetails, allDocuments, plotData } = props;

    const [documentList, setDocumentList] = useState<any>([]);
    const [validActions, setValidActions] = useState<any>([]);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [addButton, setAddButton] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const convertToTitleCase = (str: any) => {
        return str
            .toLowerCase()
            .split('_')
            .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    useEffect(() => {
        (async () => {
            try {
                // setIsLoading(true);
                if (allDocuments) {
                    // const { data: documentData } = await POST(endpoints.allotmentDetailsSearch + `?plotIds=${currentPlotDetails?.plotId}&entityIds=${entityId}`, { RequestInfo: requestInfo() });
                    // console.log('documentData', documentData);

                    // const { data: deedDetails } = await POST(endpoints.leaseDeedSearch + `?entityIds=${entityId}&plotIds=${currentPlotDetails?.plotId}`, { RequestInfo: requestInfo() });
                    // console.log('deedDocument', deedDetails);

                    const tempDeedDocument = allDocuments?.deedDetails?.filter((val: any) => val?.applicationStatus !== 'PENDING');
                    const tempDeedDocumentFiltered = tempDeedDocument?.map((val: any) => {
                        return val.applicationStatus === 'PENDING'
                            ? []
                            : val.registeredIndentureRefId
                            ? [
                                  {
                                      allotmentId: val.additionalDetails.allotmentOrderId,
                                      documents: [
                                          {
                                              documentTypeDisplayName: `Executed Indenture - ${t(val.deedType)}`,
                                              documentType: val.deedType,
                                              docReferenceId: val.executedIndentureRefId,
                                              auditDetails: { ...val.auditDetails, createdTime: val.deedExecutionDate && dateToEpochWithTime(val.deedExecutionDate) },
                                              documentName: 'EXECUTED_INDENTURE.pdf'
                                          }
                                      ]
                                  },
                                  {
                                      allotmentId: val.additionalDetails.allotmentOrderId,
                                      documents: [
                                          {
                                              documentTypeDisplayName: `Registered Indenture - ${t(val.deedType)}`,
                                              documentType: val.deedType,
                                              docReferenceId: val.registeredIndentureRefId,
                                              auditDetails: { ...val.auditDetails, createdTime: val.deedRegisterDate && dateToEpochWithTime(val.deedRegisterDate) },
                                              documentName: 'REGISTERED_INDENTURE.pdf'
                                          }
                                      ]
                                  }
                              ]
                            : [
                                  {
                                      allotmentId: val.additionalDetails.allotmentOrderId,
                                      documents: [
                                          {
                                              documentTypeDisplayName: `Executed Indenture - ${t(val.deedType)}`,
                                              documentType: val.deedType,
                                              docReferenceId: val.executedIndentureRefId,
                                              auditDetails: { ...val.auditDetails, createdTime: val.deedExecutionDate && dateToEpochWithTime(val.deedExecutionDate) },
                                              documentName: 'EXECUTED_INDENTURE.pdf'
                                          }
                                      ]
                                  }
                              ];
                    });

                    const tempDeedDocumentFilteredFlat = tempDeedDocumentFiltered.flat();

                    let tempBpaDocuments: any = [];

                    tempBpaDocuments = allDocuments?.bpaDocs?.map((val: any) => {
                        return {
                            documents: [
                                {
                                    documentTypeDisplayName: convertToTitleCase(val.documentType),
                                    documentType: val.documentType,
                                    docReferenceId: val.fileStoreId,
                                    auditDetails: { createdTime: val.createdTime },
                                    documentName: convertToTitleCase(val.documentType) + '.pdf'
                                }
                            ]
                        };
                    });

                    let tempEntityRefDetailsDocument: any = [];

                    tempEntityRefDetailsDocument = allDocuments?.entityReferenceDocuments?.map((val: any) => {
                        return {
                            documents: [
                                {
                                    documentTypeDisplayName: convertToTitleCase(val.documentType),
                                    documentType: val.documentType,
                                    docReferenceId: val.docReferenceId,
                                    auditDetails: val?.auditDetails,
                                    documentName: convertToTitleCase(val.documentType) + '.pdf'
                                }
                            ]
                        };
                    });

                    let tempAllotmentDocument: any = [];

                    tempAllotmentDocument = allDocuments?.allotmentDetails?.map((val: any) => ({
                        ...val,
                        documents: val.documents?.map((item: any) => ({ ...item, auditDetails: { ...item.auditDetails, createdTime: getEpoch(val?.allotmentDate) } }))
                    }));

                    const tempDocumentList = [...tempAllotmentDocument, ...tempDeedDocumentFilteredFlat, ...tempBpaDocuments, ...tempEntityRefDetailsDocument];

                    setDocumentList([...tempDocumentList].sort((a, b) => a.documents?.[0]?.auditDetails?.createdTime - b.documents?.[0]?.auditDetails?.createdTime).reverse());
                }
                // setIsLoading(false);
            } catch (err: any) {
                // setIsLoading(false);
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: errMsg, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [allDocuments]);

    const handleDocumentCard = async (index: any, items: any) => {
        if (items?.allotmentId) {
            try {
                // setIsLoading(true);
                setDocumnetCardIndex(documnetCardIndex === index ? null : index);
                const { data: documentValidAction } = items?.allotmentId && (await POST(endpoints.allotmentActionSearch + `?allotmentId=${items?.allotmentId}`, { RequestInfo: requestInfo() }));
                setValidActions([...documentValidAction?.validActions, 'DOWNLOAD']);
                // setIsLoading(false);
            } catch (err: any) {
                // setIsLoading(false);
                let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: errMsg, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } else {
            setDocumnetCardIndex(documnetCardIndex === index ? null : index);
            setValidActions(['DOWNLOAD']);
        }
    };

    useEffect(() => {
        const handleOutsideClick = (e: any) => {
            if (closeCard.current && !closeCard.current.contains(e.target) && !buttonRefs.current.some((ref: any) => ref && ref.contains(e.target))) {
                setDocumnetCardIndex(null);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handleNavigation = () => {
        navigate('/goa-idc/document-downloads', { state: { plotId: currentPlotDetails?.plotId, plotData: plotData } });
    };

    const handleOnClick = (items: any) => {
        console.log('items', items);
        const docName = items?.documents[0].documentType;
        console.log('docName', docName, items);
        if (
            docName === 'PROVISIONAL_ALLOTMENT_ORDER' ||
            docName === 'TRANSFER_ORDER' ||
            docName === 'SPECIAL_TRANSFER_ORDER' ||
            docName === 'MORTGAGE_NOC_ORDER' ||
            docName === 'CHANGE_IN_OWNERSHIP_INTEREST_OR_NAME_OR_TRADE' ||
            docName === 'EXTENSION_ORDER' ||
            docName === 'SURRENDER_ORDER' ||
            docName === 'SHOW_CAUSE_NOTICE'
        ) {
            navigate(`/goa-idc/estate/order?plotId=${items?.entityRefDetails?.plotId}&applicationId=${items?.applicationId}&type=${items.allotmentType}`);
        } else if (docName === 'PROVISIONAL_SUBLEASE_ORDER' || docName === 'SUBLEASE_ORDER') {
            navigate(
                `/goa-idc/estate/sublease-order?plotId=${currentPlotDetails?.plotId}&applicationNumber=${items.allotmentId}&applicationId=${items.applicationId}&docRefId=${items.documents[0].docReferenceId}&entityId=${items.entityRefDetails.entityId}&allotmentType=${items?.allotmentType}`
            );
        } else if (
            docName === 'TECHNICAL_CLEARANCE_ORDER' ||
            docName === 'CONSTRUCTION_LICENSE_ORDER' ||
            docName === 'OCCUPANCY_CERTIFICATE_ORDER' ||
            docName === 'LEASE_DEED' ||
            docName === 'TRANSFER_ADDENDUM' ||
            docName === 'TRIPARTITE_DEED' ||
            docName === 'DEED_OF_SURRENDER' ||
            docName === 'DEED_OF_EXTENSION' ||
            docName === 'ADDENDEM_OF_CHANGE' ||
            docName === 'INSPECTION_REPORT'
        ) {
            navigate(`/goa-idc/estate/order?docRef=${items?.documents[0]?.docReferenceId}&docType=${items?.documents[0].documentType}`);
        }
    };

    const handleSPV = (items: any) => {
        navigate('/goa-idc/estate/spv/application', { state: { plotId: currentPlotDetails?.plotId, allotmentId: items?.allotmentId } });
    };

    const handleApplyForLease = () => {
        setAddButton(true);
    };

    const ApplyForLease = (param: any) => {
        navigate(`/goa-idc/estate/indenture?type=${param}`, { state: { plotId: currentPlotDetails?.plotId, type: 'ALLOTTEE' } });
    };

    const handleProceedToPay = (items: any) => {
        navigate(`/goa-idc/estate/order?plotId=${currentPlotDetails?.plotId}&applicationId=${currentPlotDetails?.applicationId}&activeIndex=2`);
    };

    const handleProceedToPayForSublease = (items: any) => {
        navigate(
            `/goa-idc/estate/sublease-order?plotId=${currentPlotDetails?.plotId}&applicationNumber=${items.allotmentId}&applicationId=${items.applicationId}&docRefId=${items.documents[0].docReferenceId}&entityId=${items.entityRefDetails.entityId}&activeIndex=2`
        );
    };

    const handleOnExtension = (param: string) => {
        navigate('/goa-idc/estate/extension', { state: { plotId: currentPlotDetails?.plotId, type: param } });
    };

    const handleDownload = async (id: string, documentName: string) => {
        const fileExtension: string = documentName.split('.').pop()?.toLowerCase() || '';

        let contentType = '';
        if (fileExtension === 'pdf') {
            contentType = 'application/pdf';
        } else if (fileExtension === 'jpeg' || fileExtension === 'jpg') {
            contentType = 'image/jpeg';
        }

        fetch(devUrl + `/filestore/v1/files/id?tenantId=ga&fileStoreId=${id}`, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(response => {
                return response.arrayBuffer().then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link: any = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${documentName}`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
            })

            .catch(error => console.error('Error downloading file:', error));
    };

    const handleOnCardClick = (cardText: any, items: any) => {
        console.log('cardtext============', cardText, items);
        switch (cardText) {
            case 'APPLY_LEASE_DEED':
                if (validActions.includes('PROCEED_TO_PAY')) {
                    handleApplyForLease();
                } else {
                    ApplyForLease(LEASE_DEED);
                }
                break;
            case 'PROCEED_TO_PAY':
                if (items.allotmentType === 'PROVISIONAL_SUB_LEASE') {
                    handleProceedToPayForSublease(items);
                } else {
                    handleProceedToPay(items);
                }
                break;
            case 'TIMELINE_EXTENSION':
                handleOnExtension(items?.allotmentType);
                break;
            case 'OPT_FOR_SPV':
                handleSPV(items);
                break;
            case 'APPLY_TRIPATIDE_DEED':
                ApplyForLease(TRIPARTITE_DEED);
                break;
            case 'APPLY_ADDENDEM_OF_CHANGE':
                ApplyForLease(ADDENDUM_OF_CHANGE);
                break;
            case 'APPLY_DEED_OF_SURRENDER':
                ApplyForLease(DEED_OF_SURRENDER);
                break;
            case 'APPLY_DEED_OF_EXTENSION':
                ApplyForLease(DEED_OF_EXTENSION);
                break;
            case 'ADDENDUM_FOR_CHANGE_IN_OWNERSHIP':
                ApplyForLease(ADDENDUM_FOR_CHANGE_IN_OWNERSHIP);
                break;
            case 'DOWNLOAD':
                handleDownload(items?.documents[0].docReferenceId, items?.documents[0].documentName);
                break;
            default:
                console.log('Unknown button');
        }
    };

    return (
        <>
            <div className='col-12 col-lg-4 col-md-12 col-sm-12 mt-3 mt-lg-0 pe-0 ps-lg-3 ps-0   pe-lg-1 mt-3' style={{ maxHeight: '20.123rem', overflowY: 'hidden', width: '50.5%' }}>
                <div className='pt-4 px-4 rounded-4 bg-white document-card overflow-auto border border-1'>
                    <div className='document-card-heading d-flex justify-content-between align-items-center'>
                        <span className='heading dashboard-text-color fw-semibold font-inter font-20 lh-25'>Document</span>
                        <div onClick={handleNavigation}>
                            <Link to=''>
                                {t('VIEW ALL', 'View All')}
                                <img src={viewAllIcon} alt='' />
                            </Link>
                        </div>
                    </div>
                    <div className='document-details mt-4 font-inter'>
                        {documentList?.map(
                            (items: any, ind: number) =>
                                items.documents?.[0]?.docReferenceId && (
                                    <>
                                        <div className='details d-flex justify-content-between align-items-center border-bottom mb-2 pb-2 pe-1 z'>
                                            <span>
                                                <div className='documnet-name font-18 fw-400 mb-2 lh-22 ls-02 ' onClick={() => handleOnClick(items)} style={{ cursor: 'pointer' }}>
                                                    {items?.documents?.[0]?.documentTypeDisplayName}
                                                </div>
                                                <div className='document-date font-14 fw-400 mb-2 lh-19'>
                                                    {items?.documents?.[0]?.auditDetails?.createdTime
                                                        ? epochToDate(items?.documents?.[0]?.auditDetails?.createdTime)
                                                        : items?.documents?.[0]?.auditDetails?.createdDate?.split(' ')[0]}
                                                </div>
                                            </span>
                                            <span
                                                className='three-dot cursor-pointer position-relative d-flex justify-content-center'
                                                ref={el => (buttonRefs.current[ind] = el)}
                                                onClick={e => {
                                                    handleDocumentCard(ind, items);
                                                }}
                                                style={{ width: '1rem', height: '1rem' }}
                                            >
                                                <FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#45852e' }} />
                                                {documnetCardIndex === ind && (
                                                    <span
                                                        className={`option-list bg-white border-rad-12 py-3 position-absolute end-100 z-1`}
                                                        onClick={e => e.stopPropagation()}
                                                        ref={closeCard}
                                                        style={{ minWidth: '10.5rem' }}
                                                    >
                                                        {validActions?.map((cardText: any, index: number) => (
                                                            <div className='px-3 py-2 fw-500 font-16 dashboard-text-color lh-22' onClick={() => handleOnCardClick(cardText, items)}>
                                                                {t(cardText)}
                                                            </div>
                                                        ))}
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </>
                                )
                        )}
                    </div>
                </div>
            </div>
            <GenericPopupModal
                title='Disclaimer'
                size='md'
                primaryAction={() => {
                    setAddButton(false);
                    ApplyForLease(LEASE_DEED);
                }}
                secondaryAction={() => {}}
                isVisible={addButton}
                children={
                    <div>
                        <div className='mx-4 my-3'>
                            <div className='mt-4'>
                                <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                    {t(
                                        'APP_OF_LEASE_DEED',
                                        'Your application of lease deed shall be considered by the corporation. However, the execution of the lease deed shall happen on successful payment of minimum Land Premium & Lease Rent.'
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                primaryBtnTitle='OK'
                isSecondaryBtn={false}
                isSecondaryDisabled
                onClose={() => setAddButton(false)}
            />
        </>
    );
};
