import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';

export const GSR_POPUP_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'GSR Name',
                field: 'projectType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Year',
                field: 'gsrYear',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            }
        ]
    }
];

export const GSR_MASTER_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '100px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'GSR_NAME',
        colName: 'GSR Name',
        fieldName: 'gsrName',
        cellstyle: { width: '350px' },
        clsName: 'column-text-start',
        type: 'text',
        isClickable: true
    },
    {
        titleCode: 'YEAR',
        colName: 'Year',
        fieldName: 'gsrYear',
        cellstyle: { width: '350px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'DATE_AND_TIME',
        colName: 'Date of Creation',
        fieldName: 'date',
        cellstyle: { width: '545px' },
        clsName: 'column-text-start',
        type: 'text',
        id: 3
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['edit', 'view', 'delete']
    }
];

export const GSR_BOOK_TABLE_COLUMNS: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        cellstyle: { width: '100px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'GSR_ITEM_NO',
        colName: 'GSR Item No.',
        fieldName: 'gsrItemNo',
        cellstyle: { width: '190px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'ITEM_DESCRIPTION',
        colName: 'Item Description',
        fieldName: 'ItemDesc',
        cellstyle: { width: '500px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'GSR_UNIT_RATE',
        colName: 'GSR Unit Rate (INR)',
        fieldName: 'gsrUnitRate',
        cellstyle: { width: '200px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        titleCode: 'GSR_UNIT',
        colName: 'GSR Unit',
        fieldName: 'gsrUnit',
        cellstyle: { width: '100px' },
        clsName: 'column-text-start',
        type: 'text'
    },
    {
        colName: 'Action',
        type: 'action',
        cellstyle: { margin: '-16px  0px -16px 290px' },
        clsName: 'fixed-action-column fixed-action-column-bg',
        actions: ['view', 'edit', 'delete']
    }
];

export const GSR_BOOK_POPUP_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'GSR_ITEM_NO',
                title: 'GSR Item No',
                field: 'gsrItemNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'GSR_UNIT_RATE',
                title: 'GSR Unit Rate (INR)',
                field: 'gsrUnitRate',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                titleCode: 'GSR_UNIT',
                title: 'GSR unit',
                field: 'gsrUnit',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                titleCode: 'ITEM_DESCRIPTION',
                title: 'Item Descriptioin',
                field: 'itemDescriptioin',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-12',
                isRequired: true
            }
        ]
    }
];
