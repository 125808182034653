import React, { useEffect, useState, useRef } from 'react';
import './EnterpriseStyles.scss';
import style from '../../UserManagement/moduleComponents/table.module.css';
import _ from 'lodash';
import editIcon from '../../../../assets/icons/edit.svg';
import viewIcon from '../../../../assets/icons/view.svg';
import deleteIcon from '../../../../assets/icons/delete.svg';
import addIcon from '../../../../assets/icons/Plus.svg';
import { download } from '../../../../utils/Logo';
import GenericExportDropdown from '../../../../components/export/GenericExport';
import { useTranslation } from 'react-i18next';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import { Link } from 'react-router-dom';
import ToolTipDetails from '../../../../components/ToolTipFile';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { convertToINR } from '../../EstateManagement/EstateManagement.controller';
import GenericImportButton from '../../../../components/export/GenericImportButton';

interface IOwnershipTableProps {
    tableRowData?: any;
    additionalMethod?: any;
    updateOwnershipDataStatus?: any;
    column?: any;
    isDisabled?: boolean;
    setTotalOwnershipPercentage?: (e: any) => void;
    deleteOwnershipDetails?: (e: any, rowData?: any) => void;
    addOwnershipDetails?: (id: any, rowData?: any) => void;
    hideSearchBar?: boolean;
    searchBarPlaceholder?: any;
    showIndex?: true;
    scrollNotRequired?: boolean;
    checkType?: string;
    onCheckHandler?: ((selectedPlot: any, column: any) => void) | any;
    selectedItems?: any;
    renderButton?: any;
    onClickPopUp?: any;
    itemsPerPage?: number;
    setCalculatedTotal?: any;
    totalData?: any;
    totalRow?: any;
    totalRowText?: any;
    extraButton?: any;
    addButton?: string;
    onEdit?: any;
    userType?: any;
    hideExportBtn?: boolean;
    updatable?: boolean;
    hideTotalCost?: boolean;
    gsrTitle?: any;
    content?: any;
}

export const OwnershipTable = (props: IOwnershipTableProps) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchedData, setSearchedData] = useState(props?.tableRowData || []);
    const totalLengthOfData = searchedData?.length;
    const [itemsPerPage, setItemPerPage] = useState(10);
    const { t } = useTranslation();
    const [sortedColumn, setSortedColumn] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<string>('');
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>({});
    const [isPDF, setIsPDF] = useState(true);
    const [searchedText, setSearchedText] = useState<string>('');
    const [selected, setSelected] = useState<any[]>([]);

    const sortByColumn = (column: string) => {
        if (sortedColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedColumn(column);
            setSortOrder('asc');
        }
    };

    const filteredColumn = props.column?.filter((item: any) => item.fieldName !== 'checkbox' && item.type !== 'action');

    useEffect(() => {
        props.selectedItems?.length && setSelected(props.selectedItems);
    }, [props.selectedItems]);

    const showSortedColumnIcon = (columnName: string) => {
        return sortedColumn === columnName ? (
            sortOrder === 'asc' ? (
                <i className='fa fa-long-arrow-up table-arrow' aria-hidden='true' />
            ) : (
                <i className='fa fa-long-arrow-down table-arrow' aria-hidden='true'></i>
            )
        ) : (
            <></>
        );
    };

    useEffect(() => {
        if (sortOrder && sortedColumn) {
            const sortedData = [...searchedData]?.sort((a, b) => {
                const columnValueA = _.get(a, sortedColumn);
                const columnValueB = _.get(b, sortedColumn);

                if (sortOrder === 'asc') {
                    return typeof columnValueA === 'string' ? columnValueA.localeCompare(columnValueB) : columnValueA - columnValueB;
                } else {
                    return typeof columnValueA === 'string' ? columnValueB.localeCompare(columnValueA) : columnValueB - columnValueA;
                }
            });
            setSearchedData(sortedData);
        }
    }, [sortOrder, sortedColumn]);

    useEffect(() => {
        if (props.showIndex) {
            const data = props?.tableRowData?.map((item: any, id: number) => ({ ...item, index: id + 1 }));
            setSearchedData(data);
        } else {
            const updatedRowData = props?.tableRowData?.filter((item: any, _: number) => !item['isDelete']);
            setSearchedData(updatedRowData);
        }
        setCurrentPage(1);
        setSearchedText('');
    }, [props?.tableRowData]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = searchedData?.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(props?.tableRowData?.length / itemsPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage: any) => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage: any) => Math.max(prevPage - 1, 1));
    };
    const onRowClick = (event: any, rowData: any, isView: boolean = false) => {
        if (!isView) {
            props?.additionalMethod && props?.additionalMethod(rowData);
        } else {
            props.updateOwnershipDataStatus && props.updateOwnershipDataStatus(isView, rowData);
        }
        //     props?.onEdit && props?.onEdit(rowData);
    };

    const handleOnSearch = (e: any) => {
        if (e.target.value) {
            setSearchedText(e.target.value);
            const searchValue = e.target.value.toLowerCase();

            const filterObject = (obj: any): boolean => {
                return Object.values(obj).some(value => {
                    if (typeof value === 'object' && value !== null) {
                        return filterObject(value);
                    }
                    return value?.toString().toLowerCase().includes(searchValue);
                });
            };

            const filtered = props.tableRowData?.filter((item: any) => filterObject(item));
            const data = filtered.map((item: any, id: number) => ({ ...item, index: id + 1 }));
            setSearchedData(data);
            setCurrentPage(1);
        } else {
            const data = props.tableRowData.map((item: any, id: number) => ({ ...item, index: id + 1 }));
            setSearchedData(data);
            setSearchedText('');
        }
    };

    const viewDocument = (id: string, name: string, url?: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        const extensionArray = name?.split('.');
        const extension = extensionArray[extensionArray?.length - 1];
        setModalData({ id: id, name: name, url: url || '' });
        if (extension?.toLowerCase() === 'pdf') {
            setIsPDF(true);
        } else if (
            extension?.toLowerCase() === 'png' ||
            extension?.toLowerCase() === 'jpg' ||
            extension?.toLowerCase() === 'jpeg' ||
            extension?.toLowerCase() === 'gif' ||
            extension?.toLowerCase() === 'bmp' ||
            extension?.toLowerCase() === 'tiff' ||
            extension?.toLowerCase() === 'webp'
        ) {
            setIsPDF(false);
        }
    };
    const onRowCheck = (event: any, id: any, col: any) => {
        if (props?.checkType === 'multiCheck') {
            const selectedIndex: any = selected.indexOf(id as never);
            let newSelected: any = [];
            const NOCHECK_ITEM = -1;
            if (selectedIndex === NOCHECK_ITEM) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
            }
            setSelected(newSelected);
            console.log(newSelected);
            props?.onCheckHandler(newSelected, col);
        } else {
            const selectedIndex: any = selected.indexOf(id as never);
            let newSelected: any = [];
            const NOCHECK_ITEM = -1;
            if (selectedIndex === NOCHECK_ITEM) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            }
            setSelected(newSelected);
            props?.onCheckHandler(newSelected, col);
        }
    };
    const isSelected = (id: any) => {
        return selected.indexOf(id as never) !== -1;
    };

    const sum = (arr: any[], key: string) => {
        if (props?.totalData !== undefined && props?.totalData !== null) {
            props?.setCalculatedTotal && props?.setCalculatedTotal(props?.totalData);
            return convertToINR(props?.totalData);
        }
        let data = convertToINR(
            +arr
                .reduce((acc: number, cur: any) => {
                    let value: any = cur[key];

                    if (typeof value === 'string') {
                        value = value.replace(/,/g, '');
                    } else if (typeof value === 'number') {
                        value = value;
                    }
                    return acc + +value;
                }, 0)
                .toFixed(2)
        );
        props?.setCalculatedTotal && props?.setCalculatedTotal(data);
        return data;
    };

    const PaginationOptions = () => {
        const [isShow, setIsShow] = useState(false);
        const exportRef = useRef<any>();

        useEffect(() => {
            const handleOutsideClick = (e: any) => {
                if (exportRef.current && !exportRef.current.contains(e.target)) {
                    setIsShow(false);
                }
            };
            document.addEventListener('click', handleOutsideClick);

            return () => {
                document.removeEventListener('click', handleOutsideClick);
            };
        }, []);

        return (
            <div id='export_button' ref={exportRef} className={`d-inline-flex flex-row align-items-center justify-content-around stickyCard my-1 `}>
                <div className='ps-3'>{t('ROWS_PER_PAGES', 'Rows per pages')}</div>
                <button onClick={() => setIsShow(!isShow)} type={'button'} className={`Button_small me-3 pagination-count-button`} style={{ minWidth: '50px', minHeight: '0' }}>
                    <span className='font-14'>{itemsPerPage}</span>
                </button>
                {isShow && (
                    <div className='dropdown-content userprofile-content' style={{ top: '-160px', right: '15px', width: 50, borderRadius: 4, cursor: 'pointer' }}>
                        {[10, 25, 50, 100].map(item => (
                            <div onClick={() => setItemPerPage(item)} style={{ borderBottom: '1px solid #ccc', borderRadius: 0 }}>
                                {item}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <div className='container font-inter ownership-table'>
                {!props.hideSearchBar && (
                    <div className={`col-sm-12 position-relative ${style.application_search} d-flex ${props?.hideSearchBar ? 'justify-content-end mb-2' : 'justify-content-between'}`}>
                        {!props.hideSearchBar && (
                            <input
                                type='search'
                                value={searchedText}
                                onChange={handleOnSearch}
                                placeholder={t(props.searchBarPlaceholder)}
                                className={`col-sm-6 my-3 p-2 ${props?.hideSearchBar && 'd-none'}`}
                            />
                        )}
                        {/* <GenericExportDropdown columns={filteredColumn} rowData={searchedData} /> */}

                        {props?.renderButton && props?.updatable !== false ? (
                            <GenericButtons ClassName='my-2 mb-2' text={props?.renderButton()} variant='primary' handleClick={props.onClickPopUp} />
                        ) : (
                            <></>
                        )}

                        {!props?.renderButton ? (
                            <div className='d-flex py-3'>
                                {props?.extraButton && <>{props?.extraButton}</>}
                                {!props?.hideExportBtn && <GenericExportDropdown columns={filteredColumn} rowData={searchedData} userType={props?.userType} gsrTitle={props?.gsrTitle} />}
                                {props?.addButton && <GenericButtons text={props?.addButton} variant='primary' handleClick={props.onClickPopUp} />}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                )}

                <table className='table_outer_area '>
                    {/* <div style={props.scrollNotRequired ? {} : { overflowX: 'scroll', borderRadius: '16px 16px 0px 0px', scrollbarWidth: 'thin' }}> */}
                    <div style={{ overflowX: 'auto', borderRadius: '16px 16px 0px 0px', scrollbarWidth: 'thin' }}>
                        <thead className='table_header_part '>
                            <tr className='py-3 text-start ps-4 d-flex align-items-center'>
                                {props?.column.map(
                                    (data: any) =>
                                        !(data.type === 'action' && props?.isDisabled) && (
                                            <th
                                                style={data.cellstyle}
                                                className={`${data.clsName} table-column-seprator cursor-pointer ${data.toolTipDetails ? ' d-flex justify-content-end align-items-center' : ''}`}
                                                onClick={() => sortByColumn(data.fieldName)}
                                            >
                                                {data.colName}
                                                {showSortedColumnIcon(data.fieldName)}
                                                {data.toolTipDetails && <ToolTipDetails toolTipDetails={data.toolTipDetails} />}
                                            </th>
                                        )
                                )}
                            </tr>
                        </thead>
                        <tbody className='table_body_part'>
                            {!totalLengthOfData ? (
                                <div className='text-center fw-bold mt-3'>
                                    <h5>{t('SORRY', "Sorry, we couldn't find any results")}</h5>
                                </div>
                            ) : (
                                currentItems?.map((rowData: any, rowIndex: number) => {
                                    const rowId = rowData.id;
                                    const isItemSelected = isSelected(rowId);

                                    return (
                                        <>
                                            <tr className='py-3 text-start ps-4  d-flex   '>
                                                {props?.column.map((data: any) => (
                                                    <>
                                                        {data.type !== 'action' ? (
                                                            <>
                                                                {data.fieldName === 'serialNo' ? (
                                                                    <td style={data?.cellstyle} className={`${data.clsName}  ${data.type === 'document' && 'documentStyle'} tabel_data px-3`}>
                                                                        {rowIndex + 1}
                                                                    </td>
                                                                ) : data.fieldName === 'checkbox' ? (
                                                                    <td className={`${data.clsName}`} style={data?.cellstyle}>
                                                                        <input
                                                                            type='checkbox'
                                                                            key={Math.random()}
                                                                            checked={isItemSelected || rowData.isChecked}
                                                                            name='row-check'
                                                                            onChange={event => onRowCheck(event, rowId, rowData)}
                                                                            disabled={rowData.isDisabled}
                                                                            className={rowData.isChecked ? 'default-checked' : ''}
                                                                        />
                                                                    </td>
                                                                ) : (
                                                                    <td
                                                                        style={data?.cellstyle}
                                                                        className={`${data.clsName} ${data?.isClickable && 'navigateStyle'} ${
                                                                            data.type === 'document' && 'documentStyle'
                                                                        } tabel_data px-3`}
                                                                        onClick={data?.isClickable && (e => onRowClick(e, rowData))}
                                                                    >
                                                                        {data.type === 'document' ? (
                                                                            <Link
                                                                                to=''
                                                                                onClick={event => {
                                                                                    event.preventDefault();
                                                                                    viewDocument(
                                                                                        _.get(rowData, data.fieldName)?.docReferenceId,
                                                                                        _.get(rowData, data.fieldName)?.documentName,
                                                                                        _.get(rowData, data.fieldName)?.documentUrl
                                                                                    );
                                                                                }}
                                                                            >
                                                                                {_.get(rowData, data.fieldName)?.documentName || '--'}
                                                                            </Link>
                                                                        ) : data.type === 'select' ? (
                                                                            _.get(rowData, data.fieldName).name
                                                                        ) : (
                                                                            _.get(rowData, data.fieldName) || '--'
                                                                        )}
                                                                    </td>
                                                                )}
                                                            </>
                                                        ) : (
                                                            !props?.isDisabled && (
                                                                <td className='fixed-action-column tabel_data' style={data?.cellstyle}>
                                                                    <div className='d-flex justify-content-around table-actions'>
                                                                        {data.actions.includes('edit') && (
                                                                            <img
                                                                                src={editIcon}
                                                                                alt='edit'
                                                                                className='table-action-icon'
                                                                                onClick={e => {
                                                                                    props?.onEdit ? props?.onEdit(rowData) : onRowClick(e, rowData);
                                                                                }}
                                                                            />
                                                                        )}

                                                                        {data.actions.includes('add') && (
                                                                            <img
                                                                                src={addIcon}
                                                                                alt='add'
                                                                                className='table-action-icon'
                                                                                onClick={() => {
                                                                                    props.addOwnershipDetails && props.addOwnershipDetails(rowData.rowId, rowData);
                                                                                }}
                                                                            />
                                                                        )}

                                                                        {data.actions.includes('view') && (
                                                                            <img
                                                                                src={viewIcon}
                                                                                alt='view'
                                                                                className='table-action-icon'
                                                                                onClick={e => {
                                                                                    // props?.onEdit ? props?.onEdit(rowData) : onRowClick(e, rowData, true);
                                                                                    onRowClick(e, rowData, true);
                                                                                }}
                                                                            />
                                                                        )}

                                                                        {data.actions.includes('download') && (
                                                                            <img
                                                                                src={download}
                                                                                alt='download'
                                                                                className='table-action-icon'
                                                                                onClick={e => {
                                                                                    onRowClick(e, rowData, true);
                                                                                }}
                                                                            />
                                                                        )}

                                                                        {data.actions.includes('delete') && (
                                                                            <img
                                                                                src={deleteIcon}
                                                                                alt='delete'
                                                                                className='table-action-icon'
                                                                                onClick={() => {
                                                                                    console.log(rowData);

                                                                                    props.deleteOwnershipDetails && props.deleteOwnershipDetails(rowData.rowId, rowData);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            )
                                                        )}
                                                    </>
                                                ))}
                                            </tr>
                                        </>
                                    );
                                })
                            )}
                        </tbody>
                    </div>
                    {props?.content && props?.content}
                    {!props?.hideTotalCost && props?.totalRow && currentItems?.length > 0 ? (
                        <>
                            <div className={`col-sm-12 py-3 ps-3 d-flex justify-content-end `} style={{ borderTop: `1px solid #33333314`, paddingRight: '31px' }}>
                                <span>{props?.totalRowText} </span>
                                {/* {props?.totalTooltip && <ToolTipDetails toolTipDetails={props?.totalTooltip} />} */}
                                <span>&nbsp;:&nbsp;</span>
                                <span className='fw-medium'>{sum(currentItems, props?.totalRow)}</span>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    <tfoot className={`table_footer_part py-2 d-flex justify-content-between align-items-center px-4 ${style.table_footer}`}>
                        <p>
                            {t('ENTRIES_FOUND', 'Entries found')} : {totalLengthOfData}
                        </p>
                        <div className='d-flex align-items-center' style={{ marginRight: 30 }}>
                            <div>
                                <PaginationOptions />
                            </div>
                            <div>
                                <button onClick={handlePrevPage} disabled={currentPage === 1} className={`${style.arrow}`}>
                                    <i className='fa fa-arrow-left' aria-hidden='true'></i>
                                </button>

                                <span className={` mx-1 ${style.pagenumber}`}>{currentPage}</span>

                                <button onClick={handleNextPage} disabled={currentPage === totalPages} className={`${style.arrow}`}>
                                    <i className='fa fa-arrow-right' aria-hidden='true'></i>
                                </button>
                            </div>
                        </div>
                    </tfoot>
                </table>
            </div>
            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? t('PDF_VIEWER', 'PDF Viewer') : t('IMAGE_VIEWER', 'Image Viewer')}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData?.id} documentName={modalData?.name} />}
                        {!isPDF && <GenericImageViewer imageUrl={modalData?.url} />}
                    </div>
                </GenericPopupModal>
            )}
        </>
    );
};
