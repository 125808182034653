import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { ASSIGNMENT_DETAILS_SEARCH, DOCUMENTS_DETAILS_SEARCH, EDUCATIONAL_DETAILS_SEARCH, EMPLOYEE_DETAILS_PAGE, PROFESSIONAL_DETAILS_SEARCH } from '../UserManagement.const';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormikProvider, useFormik } from 'formik';
import { POST, endpoints, getDropdownOptions, requestInfo, updateEmployee } from '../../../../services/modulesServices/userManagement.api';
import { empDocumentData, getProfileInitData, handleUpdates } from '../UserMangement.controller';
import HeaderComponent from '../../../../components/header/Header.component';
import './EmployeeDetailsPage.scss';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { ActivateEmployee } from './ActivateEmployee';
import { DE_RE_ACTIVATION, EMPLOYEE_UPDATE_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import Loading from '../../../../components/portalComponents/Loading.component';
import { TENANT_ID } from '../../../../utils/constants';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { check, cross } from '../../../../utils/Logo';
import { isEqual } from 'lodash';
import PopUps from '../../../../components/PopUps';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';

export const EmployeeDetailsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const empData = location?.state?.data.Employees[0] || [];
    const active_deactive = location?.state?.active_deactive || false;
    const [enabledSection, setEnableSection] = useState<any>([]);
    const { t } = useTranslation();
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const isCreatorUser = getUserDetails?.roles?.find((user: any) => user.code === 'EMP_CREATOR');
    const [documents, setDocuments] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [activateDetails, setActivateDetails] = useState<any>(null);
    const [showActionModal, setShowActionModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initalValues, setInitialValues] = useState<any>(null);
    const [disabled, setDisabled] = useState<any>(true);
    const [modalDetails, setModalDetails] = useState({});
    const [hideCheckbox, setHideCheckbox] = useState<boolean>(true);
    const [employeeDetailsI, setEmployeeDetailsI] = useState<any>(EMPLOYEE_DETAILS_PAGE);

    const formik = useFormik<any>({
        initialValues: {
            educational: [
                {
                    tenantid: 'ga',
                    qualification: null,
                    stream: null,
                    yearOfPassing: null,
                    university: null,
                    remarks: null
                }
            ],
            assignments: [
                {
                    tenantid: null,
                    fromDate: null,
                    isCurrentAssignment: null,
                    department: null,
                    designation: null,
                    isPrimary: false,
                    roles: null
                }
            ],
            isUpdatable: true
        },
        validationSchema: EMPLOYEE_UPDATE_VALIDATION,
        onSubmit: (value: any) => {}
    });

    const { values, setValues } = formik;
    console.log(formik, 'formik');

    useEffect(() => {
        const updatedDetails = handleUpdates(values, employeeDetailsI, EMPLOYEE_DETAILS_PAGE, 'Present Address', 'Permanent Address');
        setEmployeeDetailsI(updatedDetails);
    }, [values?.isPermanentPincodeDetailsPresent, values?.isPincodeDetailsPresent, values?.copyOnCheck]);

    const defaultDetails = {
        basicDetails: employeeDetailsI,
        educational: EDUCATIONAL_DETAILS_SEARCH,
        professional: PROFESSIONAL_DETAILS_SEARCH,
        assignments: ASSIGNMENT_DETAILS_SEARCH,
        documents: DOCUMENTS_DETAILS_SEARCH
    };
    const [employeeDetails, setEmployeeDetails] = useState<any>(defaultDetails);

    const setInitialData = async () => {
        try {
            setIsLoading(true);
            const primaryAssignmentDetailsIndex = empData.assignments.findIndex((item: any) => item.isPrimary);
            const otherAssignments = empData.assignments.filter((item: any) => !item.isPrimary);
            const initData = await getProfileInitData(empData, dropdownData, primaryAssignmentDetailsIndex, otherAssignments);
            const documentData = await empDocumentData(empData.documents);
            setDocuments(documentData);

            const educationalData = empData?.education?.map((oneEducation: any) => {
                oneEducation.qualification = dropdownData?.qualification?.find((oneOption: any) => oneOption.code === oneEducation.qualification);
                oneEducation.stream = dropdownData?.stream?.find((oneOption: any) => oneOption.code === oneEducation.stream);

                return oneEducation;
            });

            const allEducationSections = educationalData?.map((_: any, index: number) => {
                let sectionDetails = JSON.parse(JSON.stringify(EDUCATIONAL_DETAILS_SEARCH[0]));
                if (index > 0) {
                    delete sectionDetails.heading;
                    sectionDetails.deleteSection = true;
                    if (index === 1) {
                        sectionDetails.titleCode = 'Other Education Details';
                        sectionDetails.title = 'Other Education Details';
                    }
                }

                return sectionDetails;
            });

            const assignmentData = empData?.assignments?.map((oneItem: any) => {
                oneItem.department = dropdownData?.department?.find((oneOption: any) => oneOption.code === oneItem.department);
                oneItem.designation = dropdownData?.designation?.find((oneOption: any) => oneOption.code === oneItem.designation);
                let isCurrentAssignmentStatue = oneItem.isCurrentAssignment ? 'Yes' : 'No';
                oneItem.isCurrentAssignment = dropdownData?.isCurrentAssignment?.find((oneOption: any) => oneOption.code === isCurrentAssignmentStatue);
                oneItem.fromDate = new Date(oneItem.fromDate).toISOString().split('T')[0];
                oneItem.toDate = oneItem.toDate && new Date(oneItem.toDate).toISOString().split('T')[0];
                return oneItem;
            });

            const allAssignmentSections = assignmentData?.map((_: any, index: number) => {
                let sectionDetails = JSON.parse(JSON.stringify(ASSIGNMENT_DETAILS_SEARCH[0]));
                if (index > 0) {
                    delete sectionDetails.heading;
                    sectionDetails.deleteSection = true;
                    if (index === 1) {
                        sectionDetails.titleCode = 'Other Assignment';
                        sectionDetails.title = 'Other Assignment';
                    }
                }

                return sectionDetails;
            });

            setEmployeeDetails({ ...employeeDetails, educational: allEducationSections, assignments: allAssignmentSections });
            setValues((prev: any) => ({ ...prev, ...initData, educational: educationalData, assignments: assignmentData, uuid: empData.uuid }));
            setInitialValues({ ...initData, isUpdatable: true, educational: educationalData, assignments: assignmentData });
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    console.log(empData);

    useEffect(() => {
        if (active_deactive || !isCreatorUser) {
            let newEmployeeDetails: any = JSON.parse(JSON.stringify(employeeDetails));
            Object.keys(newEmployeeDetails)?.map((oneKey: any) => {
                let value = newEmployeeDetails[oneKey]?.map((item: any) => {
                    item.isEditable = false;
                    return item;
                });
                newEmployeeDetails[oneKey] = value;
            });
            setEmployeeDetails(newEmployeeDetails);
        }
    }, [active_deactive, isCreatorUser]);

    useEffect(() => {
        if (!isEqual(initalValues, values)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [values]);

    const handleOnSubmit = async () => {
        try {
            await EMPLOYEE_UPDATE_VALIDATION.validate(values, { abortEarly: false });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        const documentTypes = [
            { key: 'addressProof', referenceType: 'ADDRESS_PROOF' },
            { key: 'identityProof', referenceType: 'IDENTITY_PROOF' },
            { key: 'joiningLetter', referenceType: 'JOINING_APPOINTMENT_LETTER' }
        ];
        let docs: any = [];
        if (documents?.length) {
            docs = documents.map((item: DocumentInfo) => {
                const { documentSize, lastModified, uploadedDate, documentUrl, ...doc } = item;
                return doc;
            });
        } else {
            documentTypes.map((doc: any) => {
                if (values[doc.key]) {
                    let oneDoc = empData.documents?.find((d: any) => d.referenceType === doc.referenceType);
                    if (oneDoc) {
                        oneDoc = {
                            ...oneDoc,
                            documentName: values[doc.key]?.documentName || oneDoc.documentName,
                            documentType: '',
                            documentId: values[doc.key]?.referenceId || oneDoc.documentId,
                            referenceType: doc.referenceType,
                            referenceId: values[doc.key]?.referenceId || oneDoc.referenceId
                        };
                        docs.push(oneDoc);
                    }
                }
            });
        }

        const newEmpData = JSON.parse(JSON.stringify(empData));
        const newValues = JSON.parse(JSON.stringify(values));
        const payload = {
            RequestInfo: requestInfo(),
            Employees: [
                {
                    ...newEmpData,
                    dateOfAppointment: Date.parse(newValues.appointmentDate) || newEmpData.dateOfAppointment,
                    employeeStatus: newValues.status?.code || newEmpData.status,
                    employeeType: newValues.empType?.code || newEmpData.employeeType,
                    isActive: newValues.status?.active || newEmpData.isActive,
                    code: values.empId,
                    user: {
                        ...newEmpData.user,
                        dob: new Date(newValues.dob).getTime(),
                        firstName: newValues.firstname,
                        gender: newValues.gender.code,
                        lastName: newValues.lastname,
                        mobileNumber: newValues.profilePhoneNo || newEmpData.user.mobileNumber,
                        emailId: newValues.profileEmailId || newEmpData.user.emailId,
                        permanentAddress1: newValues.peraddress1 || newEmpData.user.permanentAddress1,
                        permanentAddress2: newValues.peraddress2 || newEmpData.user.permanentAddress2,
                        permanentDistrict: newValues.perdistrict || newEmpData.user.permanentDistrict,
                        permanentState: newValues.perstate || newEmpData.user.permanentState,
                        permanentCountry: newValues.percountry || newEmpData.user.permanentCountry,
                        permanentPinCode: newValues.perpincode || newEmpData.user.permanentPinCode,
                        correspondenceAddress1: newValues.address1 || newEmpData.user.correspondenceAddress1,
                        correspondenceAddress2: newValues.address2 || newEmpData.user.correspondenceAddress2,
                        correspondenceDistrict: newValues.district || newEmpData.user.correspondenceDistrict,
                        correspondenceState: newValues.state || newEmpData.user.correspondenceState,
                        correspondenceCountry: newValues.country || newEmpData.user.correspondenceCountry,
                        correspondencePinCode: newValues.pincode || newEmpData.user.correspondencePinCode
                    },
                    documents: docs,
                    assignments: newValues.assignments?.map((oneAssignment: any) => {
                        oneAssignment.designation = oneAssignment.designation.code;
                        oneAssignment.department = oneAssignment.department.code;
                        oneAssignment.isCurrentAssignment = oneAssignment.isCurrentAssignment?.code === 'Yes';
                        oneAssignment.fromDate = Date.parse(oneAssignment.fromDate);
                        if (oneAssignment.toDate) oneAssignment.toDate = Date.parse(oneAssignment.toDate);

                        return oneAssignment;
                    }),
                    education: newValues.educational?.map((oneEducation: any) => {
                        oneEducation.qualification = oneEducation.qualification.code;
                        oneEducation.stream = oneEducation.stream.code;
                        return oneEducation;
                    })
                }
            ]
        };

        try {
            const { data }: any = await POST(endpoints.updateEmpProfile, payload);
            setModalDetails({
                show: true,
                title: t('EMP_PROFILE_UPDATED_SUCCESSFULLY', 'Employee Profile has been updated successfully!'),
                para: '',
                img: check,
                type: 'success',
                reUpload: 'OK'
            });
        } catch (err: any) {
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('EMP_PROFILE_UPDATION_FAILED', 'Employee Profile Update Failed'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        // modalDetails?.show(false);
        if (type === 'success') navigate('/goa-idc/dashboard');
    };

    useEffect(() => {
        setInitialData();
    }, [empData, dropdownData]);

    useEffect(() => {
        getDropdownOptions()
            .then(res => {
                setDropdownData(res);
            })
            .catch(err => {});
    }, []);

    const handleOnClick = () => {
        setShowActionModal(true);
    };

    const handlePrimaryBtnTitle = empData?.employeeStatus === 'INACTIVE' ? t('ACTIVATE', 'Activate') : t('DEACTIVATE', 'Deactivate');
    const handleActivateDeactivate = () => {
        const newEmpData = {
            ...empData,
            assignments:
                empData?.assignments.length &&
                empData?.assignments.map((val: any) => {
                    return {
                        ...val,
                        fromDate: new Date(val?.fromDate).getTime() || null,
                        isCurrentAssignment: val?.isCurrentAssignment?.name === 'Yes' ? true : false,
                        department: val?.department?.code,
                        designation: val?.designation?.code
                    };
                })
        };
        const { comments, effectiveDate, isUpdatable, joiningAppointmentLetter, joiningAppointmentLettername, reasonForReActivating, ...modifiedVal } = activateDetails;
        modifiedVal.tenantId = TENANT_ID;
        modifiedVal.effectiveFrom = Date.parse(effectiveDate);

        if (empData?.employeeStatus === 'ACTIVE') {
            modifiedVal.reasonForDeactivation = reasonForReActivating.code;
            newEmpData.deactivationDetails = [...newEmpData.deactivationDetails, modifiedVal];
        } else {
            modifiedVal.reasonForReActivation = reasonForReActivating.code;
            newEmpData.reactivationDetails = [...newEmpData.reactivationDetails, modifiedVal];
            newEmpData.reActivateEmployee = true;
        }

        let payload = {
            RequestInfo: requestInfo(),
            Employees: [newEmpData]
        };

        updateEmployee(payload, endpoints.employeeUpdation)
            .then(response => {
                let msg = empData?.employeeStatus === 'ACTIVE' ? 'Employee De-Activated' : 'Employee Re-Activated';
                setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'active success', reUpload: 'OK' });
                navigate(-1);
            })
            .catch(error => {
                let errMsg = error.response.data.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            });
    };

    const handlePrimaryActionClick = async () => {
        try {
            await DE_RE_ACTIVATION.validate(activateDetails, { abortEarly: false });
            handleActivateDeactivate();
        } catch (error) {
            setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOnEditSection = (heading: any, isEditing: boolean, section: string) => {
        let newData = [];
        if (isEditing) {
            newData = employeeDetails[section].map((item: any) => {
                if (item.type === heading) {
                    const formData = item.formData.map((fieldItem: any) => {
                        return {
                            ...fieldItem,
                            isDisabled: !fieldItem.isEditable
                        };
                    });
                    if (item.title === 'Present Address') {
                        setHideCheckbox(false);
                    }
                    return { ...item, formData };
                }
                return item;
            });
            setEnableSection([...enabledSection, heading]);
        } else {
            newData = employeeDetails[section].map((item: any) => {
                if (item.type === heading) {
                    const formData = item.formData.map((fieldItem: any) => ({
                        ...fieldItem,
                        isDisabled: true
                    }));
                    if (item.title === 'Present Address') {
                        setHideCheckbox(true);
                    }
                    return { ...item, formData };
                }
                return item;
            });
            const editableSection = enabledSection.filter((item: string) => item !== heading);
            setEnableSection(editableSection);
        }
        setEmployeeDetails({ ...employeeDetails, [section]: newData });
    };

    const handleOnEductionalChange = (index: number, field: string, value: any) => {
        const val = values;
        val.educational && (val.educational[index][field] = value);
        setValues({ ...values, ...val });
    };

    const handleOnAssignmentChange = (index: number, field: string, value: any) => {
        const val = values;
        val.assignments && (val.assignments[index][field] = value);
        setValues({ ...values, ...val });
    };

    const handleOnAddNewAssignment = () => {
        let sectionDetails = JSON.parse(JSON.stringify(ASSIGNMENT_DETAILS_SEARCH[0]));
        delete sectionDetails.heading;
        sectionDetails.deleteSection = true;
        if (employeeDetails.assignments?.length === 1) {
            sectionDetails.titleCode = 'Other Assignment';
            sectionDetails.title = 'Other Assignment';
        }

        const formData = sectionDetails.formData.map((fieldItem: any) => {
            return {
                ...fieldItem,
                isDisabled: !fieldItem.isEditable
            };
        });

        sectionDetails = { ...sectionDetails, formData };

        setEmployeeDetails({ ...employeeDetails, assignments: [...employeeDetails.assignments, sectionDetails] });
        setValues({
            ...values,
            assignments: [
                ...values.assignments,
                {
                    tenantid: 'ga',
                    fromDate: null,
                    isCurrentAssignment: null,
                    department: null,
                    designation: null,
                    isPrimary: false,
                    roles: null
                }
            ]
        });
    };

    const handleOnRemoveAssignment = (index: number) => {
        if (employeeDetails.assignments.length > 1) {
            let qualificationSection = JSON.parse(JSON.stringify(employeeDetails.assignments));
            qualificationSection = qualificationSection.filter((_: any, ind: number) => ind !== index);
            if (index === 1 && qualificationSection?.length > 1) {
                qualificationSection[1].titleCode = 'Other Assignment';
                qualificationSection[1].title = 'Other Assignment';
            }
            setEmployeeDetails({ ...employeeDetails, assignments: qualificationSection });

            let qualificationData = _.cloneDeep(values.assignments);
            qualificationData = qualificationData.filter((_: any, ind: number) => ind !== index);
            setValues({ ...values, assignments: [...qualificationData] });
        }
    };
    const handleOnAddNewQualification = () => {
        let sectionDetails = JSON.parse(JSON.stringify(EDUCATIONAL_DETAILS_SEARCH[0]));
        delete sectionDetails.heading;
        sectionDetails.deleteSection = true;
        if (employeeDetails.educational?.length === 1) {
            sectionDetails.titleCode = 'Other Education Details';
            sectionDetails.title = 'Other Education Details';
        }

        const formData = sectionDetails.formData.map((fieldItem: any) => {
            return {
                ...fieldItem,
                isDisabled: !fieldItem.isEditable
            };
        });

        sectionDetails = { ...sectionDetails, formData };

        setEmployeeDetails({ ...employeeDetails, educational: [...employeeDetails.educational, sectionDetails] });
        setValues({
            ...values,
            educational: [
                ...values.educational,
                {
                    tenantid: 'ga',
                    qualification: null,
                    stream: null,
                    yearOfPassing: null,
                    university: null,
                    remarks: null
                }
            ]
        });
    };

    const handleOnRemoveQualification = (index: number) => {
        if (employeeDetails.educational.length > 1) {
            let qualificationSection = JSON.parse(JSON.stringify(employeeDetails.educational));
            qualificationSection = qualificationSection.filter((_: any, ind: number) => ind !== index);
            if (index === 1 && qualificationSection?.length > 1) {
                qualificationSection[1].titleCode = 'Other Education Details';
                qualificationSection[1].title = 'Other Education Details';
            }
            setEmployeeDetails({ ...employeeDetails, educational: qualificationSection });

            let qualificationData = _.cloneDeep(values.educational);
            qualificationData = qualificationData.filter((_: any, ind: number) => ind !== index);
            setValues({ ...values, educational: [...qualificationData] });
        }
    };

    const sectionHandlers: any = {
        educational: { btnText: 'Education Details', change: handleOnEductionalChange, add: handleOnAddNewQualification, remove: handleOnRemoveQualification },
        assignments: { btnText: 'Assignment', change: handleOnAssignmentChange, add: handleOnAddNewAssignment, remove: handleOnRemoveAssignment }
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader text={t('EMPLOYEE_DETAILS', 'Employee Details')} bottomSubText={`${t('EMPLOYEE_ID', 'Employee Id')}- ${empData?.code}`} />
                <Card className='card_border'>
                    <FormikProvider value={formik}>
                        <Card.Body>
                            {Object.keys(employeeDetails)?.map((section: string) => {
                                return employeeDetails[section]?.map((val: any, id: number) => (
                                    <>
                                        <GenericFormCard
                                            {...val}
                                            dropdownOptions={dropdownData}
                                            handleOnEdit={(heading: any, isEditing: any) => handleOnEditSection(heading, isEditing, section)}
                                            enabledSection={enabledSection}
                                            hideAddressCheckbox={hideCheckbox}
                                            module='usermanagement'
                                            index={id}
                                            additionalSection={val.multisection}
                                            handleOnAssignmentChange={sectionHandlers[section]?.change}
                                            onDeleteAction={val.deleteSection && enabledSection.includes(val.type) ? () => sectionHandlers[section]?.remove(id) : null}
                                            documents={documents}
                                            setDocuments={setDocuments}
                                        />
                                        <div className='btn-container'>
                                            {val.multisection && enabledSection.includes(val.type) && val.multisection === section && employeeDetails[section].length === id + 1 && (
                                                <div id={`${val.nocType}${id}`} onClick={sectionHandlers[section]?.add} className='button-box'>
                                                    <button type='button'>+ {sectionHandlers[section]?.btnText}</button>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ));
                            })}
                        </Card.Body>
                    </FormikProvider>
                </Card>
            </div>
            {active_deactive ? (
                <div className='Notification_footer d-flex justify-content-end align-items-center position-fixed bottom-0'>
                    {empData?.employeeStatus === 'INACTIVE' ? (
                        <GenericButtons text={t('ACTIVATE', 'Activate')} variant='primary' ClassName='me-5' handleClick={handleOnClick} />
                    ) : (
                        <GenericButtons text={t('DEACTIVATE', 'Deactivate')} variant='primary' ClassName='me-5' handleClick={handleOnClick} />
                    )}
                </div>
            ) : isCreatorUser ? (
                <footer className='footer fixed-bottom footerBtnPatch'>
                    <div className='d-flex'>
                        <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                            <GenericButtons
                                handleClick={() => {
                                    setInitialData();
                                }}
                                text={t('RESET', 'Reset')}
                            />
                            <GenericButtons variant='primary' disabled={disabled} handleClick={handleOnSubmit} text={t('SUBMIT', 'Submit')} />
                        </div>
                    </div>
                </footer>
            ) : (
                ''
            )}
            <GenericPopupModal
                title={empData?.employeeStatus === 'INACTIVE' ? t('ACTIVATE_EMPLOYEE', 'Activate Employee') : t('DEACTIVATE_EMPLOYEE', 'Deactivate Employee')}
                subTitle={`Emp ID:${empData.code}`}
                size='xl'
                primaryAction={handlePrimaryActionClick}
                secondaryAction={() => {
                    setShowActionModal(false);
                }}
                isVisible={showActionModal}
                children={<ActivateEmployee setActivateDetails={setActivateDetails} empStatus={empData?.employeeStatus} />}
                primaryBtnTitle={handlePrimaryBtnTitle}
                secondaryBtnTitle='Close'
                onClose={() => {
                    setShowActionModal(false);
                }}
                class='activate-modal'
            />
        </>
    );
};
