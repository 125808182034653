import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
export const LEASE_EXTENSION_FORM_STEPS = [
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'EXTENSION_DETAILS',
        name: 'Extension Details',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'PAYMENT',
        name: 'Payment Details',
        index: 4,
        isActive: false
    }
];

export const LESSEE_DETAILS_SEARCH: FormDataDTO[] = [
    {
        title: 'Name',
        formData: [
            {
                title: 'Name',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Email ID',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Postal Address',
        title: 'Postal Address',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                field: 'pinCode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false,
                isDisabled: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State/UT',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const PAYMENT_DETAILS: any[] = [
    {
        titleCode: 'PAYMENT_DETAILS',
        title: 'Payment Details',
        formData: [
            {
                title: 'Payment Gateway',
                field: 'paymentGateway',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            }
        ]
    }
];

export const PLOT_DETAILS: any[] = [
    {
        titleCode: 'PLOT_DETAILS',
        title: 'Plot Details',
        formData: [
            {
                title: 'Name of Industrial Estate',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            },
            {
                title: 'Phase',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            },
            {
                title: 'Plot No',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.SELECT_WITH_SEARCH_FIELD
            }
        ]
    }
];

export const EXTENSION_DETAILS_FORM: any[] = [
    {
        titleCode: 'EXTENSION_DETAILS',
        title: 'Extension Details',
        formData: [
            {
                title: 'Duration of Extension (in years)',
                titleCode: 'DURATION_OF_EXTENSION',
                field: 'extensionDuration',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3'
            },
            {
                title: 'Lease Deed/Tripartite Deed/Deed of Extension',
                titleCode: 'DEED_DOCUMENT',
                field: 'leaseDeedExtensiondoc',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            },
            {
                title: 'Certificate of Commercial Operations from CA as on date',
                titleCode: 'CERTIFICATE_OF_CO_FROM_CA',
                field: 'commericalyCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const extensionDeclarationList = [
    '(i) it shall only use the Plot for the purpose provided in the original Lease Deed,',
    '(ii) it is not in breach of any of the terms and conditions of the Lease Deed,',
    '(iii) it has cleared all outstanding amounts due and payable to the Corporation, and',
    '(iv) the information provided above is true and correct to the best of the Lessee’s knowledge and belief and nothing material has been concealed herein.'
];

export const LEASE_EXTENSION_CORRESPONDENCE_TAB = [
    {
        titleCode: 'A_LESSEE_DETAILS',
        title: 'A - Lessee Details',
        field: 'lesseeDetails'
    },
    {
        titleCode: 'B_PLOT_DETAILS',
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        titleCode: 'C_EXTENSION_DETAILS',
        title: 'C - Extension Details',
        field: 'extensionDetails'
    },
    {
        titleCode: 'D_SELF_DECLARATION',
        title: 'D - Self Declaration',
        field: 'selfDeclaration'
    },
    {
        titleCode: 'E_PAYMENT_DETAILS',
        title: 'E - Payment Details',
        field: 'paymentDetails'
    },
    {
        titleCode: 'F_EXTENSION_OF_LEASE_TERM_NOTES',
        title: 'F - Extension of Lease Term Notes',
        field: 'leaseTermExtensionNotes'
    }
];
