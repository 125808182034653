import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { DPR_DETAILS_TABLE_COLUMN, NIT_CORRESPONDENCE, TENDER_DETAILS_FIELDS } from './NITscreen.const';
import Editor from '../../../../components/ContentEditor';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { HEADER_FIELDS } from '../DPR creation screen/DPR.const';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { convertDateTimeToISOFormate, getFirstErrorKey } from '../../../../utils/utils';
import { cross } from '../../../../utils/Logo';
import { NIT_SCREEN_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { useFormikContext } from 'formik';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';

const NitCorrespondence = (props: any) => {
    const {
        setContent,
        content,
        readOnly,
        tenderData,
        setModalDetails,
        t,
        responseData,
        setReminderPopUp,
        isViewOnly,
        onRowClick,
        tableData,
        dprDetailsTableData,
        tenderType,
        openSection,
        setOpenSection
    } = props;

    const { errors, touched, setFieldTouched, values, setTouched } = useFormikContext<any>();

    const onSectionChange = async (currentSection: string) => {
        try {
            let previousSection = openSection;
            if (previousSection === currentSection) setOpenSection('');
            else {
                if (currentSection === 'nitOrders') {
                    const keys = ['tenderDocumentAvailabilityDate', 'lastDateOfOnlineApplication', 'submissionEndDate', 'bidOpenStartDate', 'publicationDate'];

                    const isNotEqual = keys.some(key => (values[key] ? convertDateTimeToISOFormate(values[key]) : values[key]) !== responseData[key]);
                    if (isNotEqual && !isViewOnly && values.isUpdatable && Object.keys(touched).length) {
                        setReminderPopUp(true);
                    }
                }
                setOpenSection(currentSection);
            }
        } catch (validationError) {
            console.log(validationError);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const renderDprDetails = () => {
        const tempDprDetails = HEADER_FIELDS.map((val: any) => {
            return {
                ...val,
                formData: [
                    ...val.formData.map((value: any) => {
                        return { ...value, isDisabled: true };
                    }),
                    {
                        titleCode: 'ESTIMATE_COST',
                        title: 'Estimate Cost (INR)',
                        field: 'estimateCost',
                        type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                        isDisabled: true
                    }
                ]
            };
        });
        const dprDetails = !isViewOnly
            ? tempDprDetails
            : tempDprDetails.map((val: any) => ({
                  ...val,
                  formData: val.formData.map((item: any) => {
                      if (item.field !== 'projectName') {
                          return { ...item, class: 'col-sm-4' };
                      } else {
                          return { ...item };
                      }
                  })
              }));
        return (
            <div className='px-4 pt-4'>
                {/* {dprDetails.map((val: any, id: number) => (
                    <GenericFormCard {...val} />
                ))} */}
                <GenericActionTable columns={DPR_DETAILS_TABLE_COLUMN} rowData={dprDetailsTableData} hideExportBtn={true} hideSearchBar={true} itemsPerPage={5} />
            </div>
        );
    };

    const tenderDetailsFields =
        tenderType === 'NORMAL'
            ? TENDER_DETAILS_FIELDS.map((val: any) => ({
                  ...val,
                  formData: [
                      ...val.formData,
                      {
                          titleCode: 'DATE_OF_PUBLICATION',
                          title: 'Date of Publication',
                          field: 'publicationDate',
                          type: CONSTANT_DATA_FIELD.DATE_FIELD,
                          isRequired: true,
                          showTimeSelect: true,
                          enableFutureDate: true
                      }
                  ]
              }))
            : TENDER_DETAILS_FIELDS;

    console.log('tenderDetailsFields', tenderDetailsFields);

    const renderTenderDetails = () => {
        const tempTenderDetails = tenderDetailsFields?.map((val: any) => {
            return {
                ...val,
                formData: val?.formData.map((value: any) => {
                    if (value.field === 'totalTenderFormCost') {
                        return {
                            ...value,
                            toolTipDetails: {
                                position: 'right',
                                content: `<div class='mb-2'>Cost of Tender Breakdown</div><div>Cost of Tender Form : <span class='fw-bold'>${
                                    tenderData?.tenderFormCost || responseData?.tenderFormCost
                                }</span></div> <div>SGST (9%) : <span class='fw-bold'>${tenderData?.tenderFormSgst || responseData?.tenderFormSgst}</span> <div>CGST (9%) : <span class='fw-bold'>${
                                    tenderData?.tenderFormCgst || responseData?.tenderFormCgst
                                }</span></div> `
                            }
                        };
                    }
                    return value;
                })
            };
        });

        const tenderDetails = !isViewOnly ? tempTenderDetails : tempTenderDetails.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));
        return (
            <>
                <div className='px-3'>
                    {tenderDetails.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}
                </div>
            </>
        );
    };

    const renderNitOrders = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setContent} content={content} readOnly={readOnly} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };
    const renderDocTable = () => {
        return (
            <>
                <div className='px-4 pt-4'>
                    <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tableData} additionalMethod={onRowClick} />
                </div>
            </>
        );
    };

    const NIT_CORRESPONDENCE_UPDATED = [
        ...NIT_CORRESPONDENCE,
        {
            title: `${isViewOnly ? '(C) Documents' : '(C) NIT Orders'}`,
            field: 'nitOrders'
        }
    ];
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {NIT_CORRESPONDENCE_UPDATED.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === '(A) DPR Details' && openSection === 'dprDetails' && renderDprDetails()}
                                {val.title === '(B) Tender Details' && openSection === 'tenderDetails' && renderTenderDetails()}
                                {val.title === '(C) NIT Orders' && openSection === 'nitOrders' && renderNitOrders()}
                                {val.title === '(C) Documents' && openSection === 'nitOrders' && renderDocTable()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default NitCorrespondence;
