import { Formik, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import './EstateListPageStyles.css';
import HeaderComponent from '../../../../components/header/Header.component';
import { ESTATE_FIELDOFFICE_DETAILS, ESTATE_REGISTRY_DETAILS, ESTATE_REGISTRY_FIELDOFFICE_TABLE_COLUMNS, ESTATE_REGISTRY_TABLE_COLUMNS } from './EstateRigistry.const';
import { ESTATE_REGISTRY_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { toast } from 'react-toastify';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import GenericBackButton from '../../../../components/UI components/Buttons/GenericBackButton';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';

export default function EstateListPage() {
    const formIsUpdatable: any = true;
    const fieldOfficeColumns: any = ESTATE_REGISTRY_FIELDOFFICE_TABLE_COLUMNS;
    const columns: any = ESTATE_REGISTRY_TABLE_COLUMNS;
    const rowData: any = [
        {
            estateCode: 'VE',
            estateName: 'Verna',
            taluka: 'Salcete',
            district: 'South Goa',
            state: 'Goa',
            noOfPlots: '3'
        },
        {
            estateCode: 'VE',
            estateName: 'Verna',
            taluka: 'Salcete',
            district: 'District 2',
            state: 'Goa',
            noOfPlots: '11'
        },
        {
            estateCode: 'VT',
            estateName: 'Verna2',
            taluka: 'Salcete',
            district: 'District 2',
            state: 'Goa',
            noOfPlots: '7'
        },
        {
            estateCode: 'WE',
            estateName: 'Werna',
            taluka: 'Salcete',
            district: 'North Goa',
            state: 'Goa',
            noOfPlots: '4'
        }
    ];
    const [show, setShow] = useState(false);
    const dropdownData: any = {
        estateName: [
            {
                code: '1',
                name: 'E-state 1',
                active: true
            },
            {
                code: '2',
                name: 'E-state 2',
                active: true
            }
        ],
        taluka: [
            {
                code: '1',
                name: 'Taluka 1',
                active: true
            },
            {
                code: '2',
                name: 'Taluka 2',
                active: true
            }
        ],
        district: [
            {
                code: '1',
                name: 'District 1',
                active: true
            },
            {
                code: '2',
                name: 'District 2',
                active: true
            }
        ],
        state: [
            {
                code: '1',
                name: 'State 1',
                active: true
            },
            {
                code: '2',
                name: 'State 2',
                active: true
            }
        ]
    };

    const SEARCH_FORM_DETAILS: any = [
        {
            formData: [
                {
                    title: 'Estate Name',
                    field: 'estateName',
                    type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                    isRequired: true
                }
            ]
        }
    ];
    const navigate = useNavigate();
    const fieldOfficeFormik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},
        validationSchema: ESTATE_REGISTRY_VALIDATION['estateForm']
        // validateOnMount: false,
        // validateOnBlur: true,
        // validateOnChange: true
    });
    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},
        validationSchema: ESTATE_REGISTRY_VALIDATION['estateForm']
        // validateOnMount: false,
        // validateOnBlur: true,
        // validateOnChange: true
    });
    const formikSearch = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},
        validationSchema: null
        // validateOnMount: false,
        // validateOnBlur: true,
        // validateOnChange: true
    });
    const { values, setValues, setFieldValue } = formik;
    const onSearch = () => {
        console.log('formikSearch==', formikSearch);
    };
    const renderSearchFormDetails = () => {
        return (
            <>
                {SEARCH_FORM_DETAILS.map((val: any, id: number) => (
                    <Card className='card_border' key={id}>
                        <Card.Body>
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        </Card.Body>
                        <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                            <Button
                                variant='bg-light outline-success btn btn-light me-3 font-karla-med p-10'
                                style={{ borderColor: '#45852e', color: '#45852e', width: 125, borderRadius: 8 }}
                                onClick={() => onSearch()}
                            >
                                Search
                            </Button>
                        </div>
                    </Card>
                ))}
            </>
        );
    };
    const handleCloseModal = () => {
        formik.resetForm();
        setShow(false);
    };
    const handleSubmit = async () => {
        try {
            await ESTATE_REGISTRY_VALIDATION[`estateForm`].validate(values, { abortEarly: false });
        } catch (e) {
            window.scrollTo(0, 0);
            toast('Fill in all the required details', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                type: 'warning'
            });
        }
    };
    const showModal = () => {
        setShow(true);
    };
    const handleEditRow = (event: any) => {
        console.log('handleEditRow==', event);
        event.district = {
            code: '2',
            name: 'District 2',
            active: true
        };
        const initData = event;
        setValues((prev: any) => ({
            ...prev,
            ...initData,
            isUpdatable: formIsUpdatable,
            underProcess: true
        }));
        showModal();
    };
    const [FieldOfficeFormShow, setFieldOfficeFormShow] = useState(false);
    const addFieldOffice = () => {
        setFieldOfficeFormShow(true);
    };
    const handleCloseFieldOfficeForm = () => {
        setFieldOfficeFormShow(false);
    };
    const renderEstateRegistryDetails = () => {
        return (
            <>
                {ESTATE_REGISTRY_DETAILS.map((val, id: number) => (
                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                ))}
            </>
        );
    };
    const renderFieldOfficeDetails = () => {
        return (
            <>
                {ESTATE_FIELDOFFICE_DETAILS.map((val, id: number) => (
                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                ))}
            </>
        );
    };
    const onEditFieldOffice = (e: any) => {
        console.log('onEditFieldOffice==', e);
    };

    return (
        <>
            <HeaderComponent />

            <Modal className='estate-registry-modal' show={show} onHide={handleCloseModal} backdrop='static' size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-vcenter'>E-State Registry</Modal.Title>
                </Modal.Header>
                {/* <div className="contents m-5" style={{ maxWidth: "100%" }}> */}
                {/* <Modal.Title className="fs-5">{'E-State Registry'}</Modal.Title> */}
                <Modal.Body>
                    <div className='col-12 form-section'>
                        <FormikProvider value={formik}>
                            <Form>{renderEstateRegistryDetails()}</Form>
                        </FormikProvider>
                    </div>
                    <div className='col-12 form-section'>
                        <section className='estate-fieldoffice-hdr employee-creation-container'>
                            Field Offices
                            <Button title='Add E-State Registry' className='mx-2 btn border-0 text-black back_square pull-right m-t-13' onClick={() => addFieldOffice()}>
                                <i className='fa fa-plus' />
                            </Button>
                        </section>
                        {FieldOfficeFormShow ? (
                            <div className='col-12 form-section'>
                                <FormikProvider value={fieldOfficeFormik}>
                                    <Form>
                                        {renderFieldOfficeDetails()}
                                        <div style={{ display: 'inline-flex', float: 'right', marginRight: '14px' }}>
                                            <Button style={{ marginRight: '10px' }} variant='btn' onClick={() => handleCloseFieldOfficeForm()} className='error-modal'>
                                                {'Close'}
                                            </Button>
                                            <Button style={{ display: 'inline' }} variant='btn' onClick={() => handleSubmit()} className={'success-modal'}>
                                                {'Save'}
                                            </Button>
                                        </div>
                                    </Form>
                                </FormikProvider>
                            </div>
                        ) : (
                            <div>
                                <GenericActionTable
                                    columns={fieldOfficeColumns}
                                    rowData={rowData}
                                    handleEditRow={(e: any) => onEditFieldOffice(e)}
                                    searchBarPlaceholder='SEARCH_ESTATE_LIST'
                                ></GenericActionTable>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: 'inline-flex' }}>
                        {/* <Button style={{ marginRight: '10px' }} variant="btn" onClick={() => handleClose()} className="error-modal">
                            {'Close'}
                        </Button> */}
                        <Button style={{ display: 'inline' }} variant='btn' onClick={() => handleSubmit()} className={'success-modal'}>
                            {'Save'}
                        </Button>
                    </div>
                </Modal.Footer>
                {/* </div> */}
            </Modal>
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader
                    actionButton={
                        <Button title='Add E-State Registry' className='mx-2 btn border-0 text-black back_square pull-right m-t-50' onClick={() => showModal()}>
                            <i className='fa fa-plus' />
                        </Button>
                    }
                    subText={'HOME / E-STATE'}
                    text={'E-state registry'}
                />

                <div className='col-sm-12'>
                    <div className='employee-creation-container bg-light m-auto row'>
                        <div className='col-12 form-section'>
                            <FormikProvider value={formikSearch}>
                                <Form>{renderSearchFormDetails()}</Form>
                            </FormikProvider>
                        </div>
                    </div>
                </div>
                <GenericActionTable columns={columns} rowData={rowData} handleEditRow={(e: any) => handleEditRow(e)} searchBarPlaceholder='SEARCH_ESTATE_LIST'></GenericActionTable>
            </div>
        </>
    );
}
