import axios from 'axios';
import { devUrl } from '../../../../services/modulesServices/userManagement.api';
import { DocumentDetails } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { changeDateFormat } from '../../../../utils/utils';

export const getAllotteeInitData = async (data: any) => {
    const documentData = data?.executedIndentureRefId && (await entityDocumentData(data?.executedIndentureRefId));

    const permanentAddress = data.entityDetails?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');

    console.log('documentData', documentData);
    const initialData: any = {};
    initialData['allotteeName'] = data.entityDetails.entityName || '';
    initialData['emailID'] = data.entityDetails.email || '';
    initialData['phoneNo'] = data.entityDetails.phoneNumber || '';
    initialData['address1'] = permanentAddress.address1 || '';
    initialData['address2'] = permanentAddress.address2 || '';
    initialData['pinCode'] = permanentAddress.pinCode || '';
    initialData['district'] = permanentAddress.district || '';
    initialData['state'] = permanentAddress.state || '';
    initialData['country'] = permanentAddress.country || '';
    initialData['executedLeaseDeedDate'] = (data?.deedExecutionDate && convertDateFormatddMMyyyytoyyyyMMdd(data?.deedExecutionDate)) || '';
    initialData['signedLeaseDeed'] = (documentData && documentData[0]) || '';
    return initialData;
};

export const indentureDetailsInitialValues = async (resData: any) => {
    const executedIndentureDocument = resData?.executedIndentureRefId && (await entityDocumentData(resData?.executedIndentureRefId));
    const registeredIndentureDocument = resData?.additionalDetails?.registerIndentureId && (await entityDocumentData(resData?.additionalDetails?.registerIndentureId));

    const initialData: any = {};
    initialData['executedIndentureDate'] = resData?.deedExecutionDate || '';
    initialData['executedIndenture'] = (executedIndentureDocument && executedIndentureDocument[0]) || '';
    initialData['registeredIndentureDate'] = resData?.additionalDetails?.registerDateString || '';
    initialData['registeredIndenture'] = (registeredIndentureDocument && registeredIndentureDocument[0]) || '';
    return initialData;
};

export const entityDocumentData = async (refID: any) => {
    const { data } = await axios.get(devUrl + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: refID } });
    let formattedDocumentData;
    if (data) {
        formattedDocumentData = data.fileStoreIds.map((val: any) => ({
            documentName: val.tag,
            documentSize: val.fileSize,
            documentType: '',
            docReferenceId: val.id,
            documentUrl: val.url,
            referenceType: '',
            uploadedDate: convertDateFromWordToNum(val.uploadedDate)
        }));
    }
    return formattedDocumentData;
};

export const convertDateFromWordToNum = (inputDate: string) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDateString = `${day}/${month}/${year}`;
    return formattedDateString;
};

export const convertDateFormatddMMyyyytoyyyyMMdd = (inputDate: any) => {
    if (!inputDate) {
        return;
    }
    // dd/mm/yyyy to yyyy-mm-dd
    if (inputDate.includes('-')) {
        return inputDate;
    }
    const [datePart] = inputDate.split(' ');
    const [day, month, year] = datePart.split('/');
    const formattedDateString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    return formattedDateString;
};

export const indentureApplicationhInitialValues = async (resData: any) => {
    const executedIndentureDocument = resData?.executedIndentureRefId && (await entityDocumentData(resData?.executedIndentureRefId));
    const registeredIndentureDocument = resData?.registeredIndentureRefId && (await entityDocumentData(resData?.registeredIndentureRefId));

    const initialData: any = {};
    initialData['executedIndentureDate'] = resData?.deedExecutionDate || '';
    initialData['executedIndenture'] = (executedIndentureDocument && executedIndentureDocument[0]) || '';
    initialData['registeredIndentureDate'] = resData?.deedRegisterDate || '';
    initialData['registeredIndenture'] = (registeredIndentureDocument && registeredIndentureDocument[0]) || '';
    return initialData;
};
