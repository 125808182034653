import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../../components/header/Header.component';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import SideCard from '../../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { useTranslation } from 'react-i18next';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import PaymentDetailInstallments from '../PaymentDetailInstallments';
import { POST, POST2, endpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { PAYMENT_INSTALLMENT_VALIDATION } from '../../../../../utils/formikValidation/FormikValidation';
import { FormikProvider, useFormik } from 'formik';
import { changeDateFormatToddMMyyyy, convertDateFormat, getFirstErrorKey, getNavigateTo } from '../../../../../utils/utils';
import Loading from '../../../../../components/portalComponents/Loading.component';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { cross, check } from '../../../../../utils/Logo';
import { convertToINR, getOptions, getQueryParams, loadRazorpay } from '../../EstateManagement.controller';
import { timestampToDate } from '../Allotment.controller';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { PROVISIONAL_ALLOTMENT_STEPS, stepsDetails } from '../Allotment.const';
import { RazorpayOptions } from '../../../UserManagement/types';
import {
    ADDENDUM_FOR_CHANGE_IN_OWNERSHIP,
    ADDENDUM_OF_CHANGE,
    DEED_OF_EXTENSION,
    DEED_OF_SURRENDER,
    KEY_ID,
    LEASE_DEED,
    TRIPARTITE_DEED,
    WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY
} from '../../../../../utils/constants';

const ProvisionalAllotment = () => {
    const location: any = useLocation();
    // const searchParams: any = new URLSearchParams(location.search);
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();

    const [activeIndex, setActiveIndex] = useState(1);
    const { t } = useTranslation();
    const [paymentData, setPaymentData] = useState<any>([]);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [isPaid, setIsPaid] = useState<any>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [docRefId, setDocRefId] = useState<any>(null);
    const [plotId, setPlotId] = useState<any>(null);
    const [type, setType] = useState<any>(null);
    const [dueDate, setDueDate] = useState<any>(null);
    const [allotmentAppNo, setAllotmentAppNo] = useState<any>(null);
    const [allotmentDate, setAllotmentDate] = useState<any>(null);
    const [installmentData, setInstallmentData] = useState<any>([]);
    const [paymentTried, setPaymentTried] = useState<any>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [finalPaymentData, setFinalPaymentData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [deposit, setDeposit] = useState<any>([]);
    const [allotmentId, setAllotmentId] = useState<any>(null);
    const [addButton, setAddButton] = useState(false);
    const [footerButtons, setFooterButtons] = useState<any>(null);
    const [allotmentSearchRes, setAllotmentSearchRes] = useState<any>(null);
    const [applicationId, setApplicationId] = useState<any>(null);
    const [tabText, setTabText] = useState<any>(null);
    const [stepsDetail, setStepsDetail] = useState(PROVISIONAL_ALLOTMENT_STEPS);
    const [documentName, setDocumentName] = useState<string>('');
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const formik = useFormik<any>({
        initialValues: {
            amount: totalPaidAmount,
            paymentType: { name: 'Full Payment', code: 'full_payment' },
            isUpdatable: true
        },
        validationSchema: PAYMENT_INSTALLMENT_VALIDATION[`page${activeIndex}`],
        onSubmit: () => {}
    });
    const { values, setFieldValue, setFieldTouched, errors } = formik;

    useEffect(() => {
        const stepData = [
            {
                titleCode: tabText,
                name: tabText,
                index: 1,
                isActive: true
            }
        ];

        const paymentTab =
            activeIndex === 2
                ? [
                      {
                          titleCode: 'PAYMENT_DETAILS',
                          name: 'Payment Details',
                          index: 2,
                          isActive: false
                      }
                  ]
                : [];

        setStepsDetail([...stepData, ...paymentTab]);
    }, [tabText]);

    useEffect(() => {
        const fetchGatewayData = async () => {
            try {
                setIsLoading(true);
                const { data } = await POST(`/egov-mdms-service/v1/_search`, {
                    RequestInfo: requestInfo(),
                    MdmsCriteria: {
                        tenantId: 'ga',
                        moduleDetails: [
                            {
                                moduleName: 'egov-pg-service',
                                masterDetails: [
                                    {
                                        name: 'Gateway',
                                        filter: '[?(@.active == true)]'
                                    }
                                ]
                            }
                        ]
                    }
                });
                let feeMasterDetails = data?.['MdmsRes']?.['egov-pg-service']?.['Gateway'];
                setGatewayData(feeMasterDetails || []);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };
        //fetchData();
        fetchGatewayData();
    }, []);

    useEffect(() => {
        if (allotmentAppNo) {
            (async () => {
                try {
                    const { data } = await POST(endpoints.allotmentActionSearch + `?allotmentId=${allotmentAppNo}`, { RequestInfo: requestInfo() });
                    setFooterButtons(data);
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [allotmentAppNo]);

    const queryParams = getQueryParams(searchParams);

    useEffect(() => {
        if (!queryParams?.['docRef']) {
            if (queryParams?.['plotId']) {
                setPlotId(queryParams?.['plotId']);
            }
            if (queryParams?.['applicationId']) {
                setApplicationId(queryParams?.['applicationId']);
            }
            if (queryParams?.['type']) {
                setType(queryParams?.['type']);
            }
            if (queryParams?.['applicationNumber']) {
                setAllotmentAppNo(queryParams?.['applicationNumber']);
                setAllotmentDate(+queryParams?.['allotmentDate']);
                let params = { ...queryParams };
                setPaymentIsLoading(true);
                handleProceedToPay();
                if (queryParams?.['eg_pg_txnid']) {
                    getTransactionUpdate(params);
                    setPaymentTried(true);
                }
            }
        }

        if (+queryParams?.['activeIndex'] === 2) {
            handleButtonClick('PROCEED_TO_PAY');
        }
    }, [location.search]);

    useEffect(() => {
        if (queryParams?.['plotId']) {
            paymentCheck();

            fetchAllotmentDetails();
            fetchPaymentDetails();
            // fetchDueDetails();
        } else if (queryParams?.['docRef'] && queryParams?.['docType']) {
            setTabText(queryParams?.['docType']);
            setDocRefId(queryParams?.['docRef']);
            setDocumentName(queryParams?.['docType']?.toLowerCase());
        }
    }, [activeIndex]);

    const paymentCheck = async () => {
        try {
            setIsLoading(true);
            const userId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
            const { data } = await POST(endpoints.paymentDetailsSearch + `?paymentTypes=LAND_PREMIUM,LEASE_RENT&entityIds=${userId}&plotIds=${queryParams?.['plotId']}`, {
                RequestInfo: requestInfo()
            });
            if (data.payments?.length) {
                let paymentDetails = data?.payments?.[0]?.payment;
                setIsPaid(true);
                setPaymentTried(true);
                setTransactionDetails({
                    txnId: paymentDetails.transactionNumber,
                    auditDetails: { createdTime: paymentDetails.transactionDate },
                    txnStatus: 'SUCCESS'
                });
            }
            setIsLoading(false);
            setPaymentIsLoading(false);
            return { isPaid: !!data?.payments?.[0]?.payment };
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
            setPaymentIsLoading(false);
        }
    };

    const fetchAllotmentDetails = async () => {
        try {
            setIsLoading(true);
            const userId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
            const { data } = await POST(
                endpoints.allotmentDetailsSearch +
                    `?plotIds=${queryParams?.['plotId']}${type === 'PROVISIONAL_SUB_LEASE' || type === 'SUB_LEASE' ? '' : `&entityIds=${userId}`}&applicationIds=${queryParams?.applicationId}${
                        type ? `&type=${type}` : ''
                    }`,
                {
                    RequestInfo: requestInfo()
                }
            );
            const entity = data.allotmentDetails?.[0];
            setAllotmentSearchRes(entity);
            setTabText(entity.allotmentType);
            setDocRefId(entity?.documents?.[0]?.docReferenceId || '');
            setDocumentName(entity?.documents?.[0]?.documentName || '');
            setAllotmentAppNo(entity?.allotmentId);
            entity?.allotmentDate && setAllotmentDate(new Date(convertDateFormat(entity?.allotmentDate)).getTime());
            setAllotmentId(entity?.allotmentId);
            fetchDueDetails(entity?.expiryDate);
            // setDueDate(entity.expiryDate);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const fetchPaymentDetails = async () => {
        try {
            setIsLoading(true);
            const userId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
            const { data } = await POST(endpoints.scheduledPaymentDetailsSearch + `?paymentTypes=LEASE_RENT%2CLAND_PREMIUM&plotIds=${queryParams?.['plotId']}&entityIds=${userId}`, {
                RequestInfo: requestInfo()
            });
            let feeMasterDetails = data.paymentDetailsResponse
                .filter((e: any) => e.paymentType === 'LEASE_RENT')
                .map((obj: any, i: any) => {
                    return {
                        ...obj,
                        feeTypeDisplayName: obj.paymentTypeDisplayName,
                        cgstAmount: +obj.cgstPrincipal === 0 ? '--' : convertToINR(obj.cgstPrincipal),
                        sgstAmount: +obj.sgstPrincipal === 0 ? '--' : convertToINR(obj.sgstPrincipal),
                        amount: convertToINR(obj.principalAmount),
                        total: convertToINR(obj.totalDueAmount),
                        index: i + 1
                    };
                });
            setPaymentData(feeMasterDetails || []);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const fetchDueDetails = async (estimatedDate: any) => {
        try {
            setIsLoading(true);
            // const { data } = await POST(`/egov-common-masters/utils/calculate-due-date`, {
            //     RequestInfo: requestInfo(),
            //     currentDate: timestampToDate(new Date()),
            //     numberOfDays: '30'
            // });
            if (estimatedDate) {
                const dateString = estimatedDate;
                const parts = dateString.split('/');
                setDueDate(new Date(parts[2], parts[1] - 1, parts[0]));
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const fetchSecurityPaymentDetails = async () => {
            try {
                setIsLoading(true);
                const userId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
                const { data: feeDetails } = await POST(endpoints.feeDetailsSearch + `?feeTypes=LAND_PREMIUM,EMD&entityIds=${userId}&plotIds=${plotId}`, {
                    RequestInfo: requestInfo()
                });

                const landPremiumAmount = (feeDetails?.estateApplicationFeeDetails?.find((item: any) => item.feeType === 'LAND_PREMIUM')).amount || 0;
                setTotalPaidAmount(+landPremiumAmount);
                setFieldValue('amount', landPremiumAmount.toFixed(2) || 0);

                const EMDAmount = feeDetails.estateApplicationFeeDetails?.find((item: any) => item.feeType === 'EMD')?.amount;
                if (EMDAmount) {
                    setDeposit(+EMDAmount);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        const fetchDeposit = async () => {
            try {
                const { data } = await POST(`/idc-estate-service/fee-master-details/_search?feeTypes=SECURITY_DEPOSIT`, {
                    RequestInfo: requestInfo()
                });
                setDeposit(+data.totalAmount || 0);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (plotId) {
            fetchSecurityPaymentDetails();
            allotmentSearchRes && allotmentSearchRes?.allotmentSubType !== 'AUCTION' && fetchDeposit();
        }
    }, [allotmentSearchRes, plotId]);

    useEffect(() => {
        loadRazorpay();
    }, []);

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/');
    };

    const getTransactionUpdate = async (params: any) => {
        try {
            const paid: any = await paymentCheck();
            if (!paid.isPaid && params.eg_pg_txnid) {
                console.log('ispaid', isPaid, transactionDetails);
                setIsLoading(true);
                const payload = { RequestInfo: requestInfo() };
                const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                    setTransactionDetails(data?.Transaction?.[0]);
                    if (data?.Transaction?.[0]?.txnId) {
                        try {
                            const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                            if (resp.data.status === 'SUCCESSFUL') {
                                setModalDetails({ show: true, title: 'Success!', para: 'Payment successful', img: check, type: 'sucess', reUpload: 'OK' });

                                setIsPaid(true);
                            } else setIsPaid(false);
                            setPaymentIsLoading(false);
                            setIsLoading(false);
                        } catch (e: any) {
                            setIsPaid(false);
                            setPaymentIsLoading(false);
                            setIsLoading(false);
                        }
                    }
                    setIsLoading(false);
                } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                    setModalDetails({
                        show: true,
                        title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                        para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    // window.location.reload();
                }
                setIsLoading(false);
                if (data?.Transaction?.[0]?.additionalDetails) {
                    const dat = data?.Transaction?.[0]?.additionalDetails;
                    const instDat = dat.payLaterPaymentDetails.map((e: any, i: any) => ({
                        ...e,
                        premiumDue: convertToINR(e.principalAmount),
                        interest: convertToINR(e.interestAmount),
                        total: convertToINR(e.totalDueAmount),
                        index: i + 1
                    }));
                    const payData = dat.payNowPaymentDetails.map((e: any, i: any) => ({
                        ...e.scheduledPaymentDetails,
                        feeTypeDisplayName:
                            e.scheduledPaymentDetails?.paymentType === 'LEASE_RENT' ? 'Lease Rent' : params?.['paymentType'] === 'full_payment' ? 'Land Premium' : 'Land Premium (Down Payment)',
                        cgstAmount: +e.scheduledPaymentDetails?.cgstPrincipal === 0 ? '--' : convertToINR(e.scheduledPaymentDetails?.cgstPrincipal),
                        sgstAmount: +e.scheduledPaymentDetails?.sgstPrincipal === 0 ? '--' : convertToINR(e.scheduledPaymentDetails?.sgstPrincipal),
                        amount: convertToINR(e.scheduledPaymentDetails?.principalAmount),
                        total: convertToINR(e.scheduledPaymentDetails?.totalDueAmount),
                        index: i + 1
                    }));
                    const deductionAmount = dat.payNowPaymentDeductions?.map((val: any) => ({
                        ...val,
                        feeTypeDisplayName: val.paymentType === 'SECURITY_DEPOSIT' ? 'Security Deposit' : val.paymentType,
                        cgstAmount: +val.cgstPrincipal === 0 ? '--' : convertToINR(val.cgstPrincipal),
                        sgstAmount: +val.sgstPrincipal === 0 ? '--' : convertToINR(val.sgstPrincipal),
                        amount: convertToINR(val.feeAmount),
                        total: '-' + convertToINR(-val.feeAmount),
                        index: dat.payNowPaymentDetails?.length + 1,
                        totalDueAmount: -val.feeAmount
                    }));
                    const displayData = payData.concat(deductionAmount);
                    setPaymentData(displayData || []);
                    setFinalPaymentData(displayData || []);
                    setInstallmentData(instDat || []);
                    console.log('b', displayData);
                }
                setPaymentIsLoading(false);
            }
        } catch (e: any) {
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Transaction Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleProceedToPay = () => {
        const paymentDetail = {
            titleCode: 'PAYMENT_DETAILS',
            name: 'Payment Details',
            index: 2,
            isActive: false
        };

        const isAlreadyAdded = stepsDetail.some(step => step.titleCode === paymentDetail.titleCode);

        if (!isAlreadyAdded) {
            const newStepsDetails = [...stepsDetail, paymentDetail];
            setStepsDetail(newStepsDetails);
        }

        setActiveIndex(2);
    };

    const onCancel = () => {
        navigate(getNavigateTo('ENTERPRISE'));
    };

    const handlePayNow = async (key?: any) => {
        const deductableAmount = finalPaymentData.filter((val: any) => val.paymentType === 'SECURITY_DEPOSIT' || val.paymentType === 'EMD');
        let url =
            window.location.origin +
            `/goa-idc/estate/order?plotId=${plotId}&activeIndex=${activeIndex}&applicationId=${applicationId}&applicationNumber=${allotmentAppNo}&allotmentDate=${allotmentDate}&paymentType=${values?.paymentType?.code}`;
        const oneYearLaterDate = new Date(allotmentDate);
        oneYearLaterDate.setFullYear(oneYearLaterDate.getFullYear() + 1);
        const periodTo = oneYearLaterDate.getTime();
        const payload = {
            RequestInfo: requestInfo(),
            applicationNumber: allotmentAppNo,
            plotId: plotId,
            totalPaidAmount: finalPaymentData.reduce((acc: any, cur: any) => acc + +cur['totalDueAmount'], 0).toFixed(2),
            callbackUrl: url,
            productInfo: 'Land Permium & Lease Rent',
            gateway: selectedGateway,
            periodFrom: allotmentDate,
            periodTo: periodTo,
            payNowPaymentDetails: finalPaymentData
                .filter((val: any) => val.paymentType !== 'SECURITY_DEPOSIT' && val.paymentType !== 'EMD')
                .map((obj: any) => ({
                    scheduledPaymentDetails: {
                        paymentType: obj.paymentType,
                        principalAmount: obj.principalAmount,
                        cgstPrincipal: obj.cgstPrincipal,
                        sgstPrincipal: obj.sgstPrincipal,
                        totalDueAmount: ((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2),
                        paidAmount: ((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2),
                        uuid: obj.uuid || null
                    }
                })),
            payLaterPaymentDetails: installmentData.map((obj: any) => ({
                paymentType: obj.paymentType,
                dueDate: obj.dueDate,
                principalAmount: parseFloat((+obj.principalAmount || 0).toFixed(2)),
                totalDueAmount: parseFloat((+obj.totalDueAmount || 0).toFixed(2)),
                interestAmount: parseFloat((+obj.interestAmount || 0).toFixed(2))
            })),
            applicationId: applicationId,
            payNowPaymentDeductions: deductableAmount
                ? deductableAmount?.map((item: any) => ({
                      paymentType: item.paymentType,
                      feeAmount: parseFloat((+item.principalAmount || 0).toFixed(2))
                  }))
                : null
        };
        try {
            await PAYMENT_INSTALLMENT_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
            try {
                if (!totalPaidAmount) {
                    setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });

                    return;
                }
                if (!selectedGateway) {
                    setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                    return;
                }
                if (allotmentAppNo && selectedGateway && totalPaidAmount > 0) {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.paymentGateway, payload);

                    if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                        const options: RazorpayOptions = getOptions(data.txnAmount, user, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                        const rzp1: any = (window as any).Razorpay(options);
                        rzp1.open();
                    } else if (selectedGateway === 'ICICI') {
                        console.log(data.additionalDetails);
                        navigate(
                            `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                        );
                    } else if (data && data.redirectUrl) {
                        window.open(data.redirectUrl, '_self');
                    } else {
                        setIsLoading(false);
                    }
                    setIsLoading(false);
                }
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            console.log(errors, validationError);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleApplyForLease = () => {
        setAddButton(true);
    };

    const ApplyForLease = (param: any) => {
        navigate(`/goa-idc/estate/indenture?type=${param}`, { state: { plotId: plotId, type: 'ALLOTTEE' } });
    };

    const handleOnExtension = () => {
        navigate('/goa-idc/estate/extension', { state: { plotId: plotId } });
    };

    const handleSPV = () => {
        navigate('/goa-idc/estate/spv/application', { state: { plotId: plotId, allotmentId: allotmentId } });
    };

    const handleButtonClick = (type: string) => {
        switch (type) {
            case 'APPLY_LEASE_DEED':
                !isPaid && handleApplyForLease();
                isPaid && ApplyForLease(LEASE_DEED);
                break;
            case 'PROCEED_TO_PAY':
                handleProceedToPay();
                break;
            case 'TIMELINE_EXTENSION':
                handleOnExtension();
                break;
            case 'OPT_FOR_SPV':
                handleSPV();
                break;
            case 'APPLY_TRIPATIDE_DEED':
                ApplyForLease(TRIPARTITE_DEED);
                break;
            case 'APPLY_ADDENDEM_OF_CHANGE':
                ApplyForLease(ADDENDUM_OF_CHANGE);
                break;
            case 'APPLY_DEED_OF_SURRENDER':
                ApplyForLease(DEED_OF_SURRENDER);
                break;
            case 'APPLY_DEED_OF_EXTENSION':
                ApplyForLease(DEED_OF_EXTENSION);
                break;
            case 'ADDENDUM_FOR_CHANGE_IN_OWNERSHIP':
                ApplyForLease(ADDENDUM_FOR_CHANGE_IN_OWNERSHIP);
                break;
            default:
                console.log('Unknown button');
        }
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                await PAYMENT_INSTALLMENT_VALIDATION[`page${activeIndex}`].validate(values, { abortEarly: false, context: values });
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const onClose = () => {
        navigate('/goa-idc/enterprisedashboard');
    };

    console.log('formik', formik);
    console.log('finalPaymentDetails', finalPaymentData);
    console.log('stepsDetail', stepsDetail);

    return (
        <>
            {isLoading ? <Loading /> : <></>}
            <HeaderComponent />
            <GenericPopupModal
                title='Disclaimer'
                size='md'
                primaryAction={() => {
                    setAddButton(false);
                    ApplyForLease(LEASE_DEED);
                }}
                secondaryAction={() => {}}
                isVisible={addButton}
                children={
                    <div>
                        <div className='mx-4 my-3'>
                            <div className='mt-4'>
                                <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                    Your application of lease deed shall be considered by the corporation. However, the execution of the lease deed shall happen on successful payment of minimum Land
                                    Premium & Lease Rent.
                                </span>
                            </div>
                        </div>
                    </div>
                }
                primaryBtnTitle='OK'
                isSecondaryBtn={false}
                isSecondaryDisabled
                onClose={() => setAddButton(false)}
            />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader
                    text={t(allotmentSearchRes?.allotmentType ? `${allotmentSearchRes?.allotmentType}` : queryParams?.['docType'])}
                    additionalClass='sub-text-style'
                    bottomSubText={allotmentAppNo ? `Type : ${t(allotmentSearchRes?.allotmentType)}, No : ${allotmentAppNo}` : ''}
                />
                <div className='col-3 stickyCard-section font-inter'>
                    <div className='stickyCard'>
                        <GenericHeader
                            text={allotmentSearchRes?.allotmentType ? t(`${t(allotmentSearchRes?.allotmentType)}`) : t(queryParams?.['docType'])}
                            sideHeader={true}
                            additionalClass='sub-text-style'
                            bottomSubText={allotmentAppNo ? `Type : ${t(allotmentSearchRes?.allotmentType)}, No : ${allotmentAppNo}` : ''}
                        />
                        <Card className='card_border p-3'>
                            <Card.Body>
                                <Card.Text className=' d-flex flex-column row-gap-3  '>
                                    <SideCard steps={stepsDetail} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className='col-9 form-section'>
                    <FormikProvider value={formik}>
                        {activeIndex === 1 && (
                            <Card className='card_border'>
                                <Card.Body>{!docRefId ? <Loading /> : <GenericPDFViewer fileStoreId={docRefId} documentName={documentName} />}</Card.Body>
                            </Card>
                        )}
                        {activeIndex === 2 &&
                            (!paymentIsLoading ? (
                                <PaymentDetailInstallments
                                    paymentData={paymentData}
                                    selectedGateway={selectedGateway}
                                    setSelectedGateway={setSelectedGateway}
                                    installmentData={installmentData}
                                    setInstallmentData={setInstallmentData}
                                    isPaid={isPaid}
                                    gatewayData={gatewayData}
                                    transactionDetails={transactionDetails}
                                    total={totalPaidAmount}
                                    dueDate={dueDate}
                                    finalPaymentData={finalPaymentData}
                                    setFinalPaymentData={setFinalPaymentData}
                                    totalPrincipalAmount={totalPaidAmount}
                                    deposit={deposit}
                                    allotmentSearchRes={allotmentSearchRes}
                                ></PaymentDetailInstallments>
                            ) : (
                                <Loading />
                            ))}
                    </FormikProvider>
                </div>
            </div>
            <div className='position-fixed bottom-0 py-3 bg-white w-100 border border-1 d-flex justify-content-end pe-5'>
                {footerButtons && footerButtons.validActions.length ? (
                    <>
                        {footerButtons?.validActions?.map((btn: string) => (
                            <>
                                {activeIndex === 1 && <GenericButtons text={t(btn)} ClassName='px-3 py-2' handleClick={() => handleButtonClick(btn)} />}
                                {activeIndex === 2 && btn !== 'PROCEED_TO_PAY' && <GenericButtons text={t(btn)} ClassName='px-3 py-2' handleClick={() => handleButtonClick(btn)} />}
                            </>
                        ))}

                        {activeIndex === 2 && !isPaid && <GenericButtons text={paymentTried ? `Retry Payment` : `Pay Now`} variant='primary' ClassName='px-3 py-2' handleClick={handlePayNow} />}
                    </>
                ) : (
                    <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onClose} />
                )}
            </div>
        </>
    );
};

export default ProvisionalAllotment;
