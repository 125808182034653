import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../../../../components/header/Header.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import NotingComponent from '../../../../../components/Workflow/NotingComponent';
import { POST, devUrl, endpoints, estateEndpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { CORRESPONDENCE_TABLE, CORRESPONDENCE_TABLE_APP_FORM, EXECUTED_DEED } from '../LeaseDeed.const';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LeaseDeedCorrespondence } from './LeaseDeedCorrespondence';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import { LEASE_DEED, TENANT_ID } from '../../../../../utils/constants';
import { getAllotteeInitData, indentureDetailsInitialValues } from '../LeaseDeed.controller';
import { autoPopulatePlotDetails, getNextActions, getSuccessMessage } from '../../EstateManagement.controller';
import NotingDocDownload from '../../../../../components/Workflow/NotingDocDownload';
import CorrespondenceDocDownload from '../../../../../components/Workflow/CorrespondenceDocDownload';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { cross, check, warning } from '../../../../../utils/Logo';
import Loading from '../../../../../components/portalComponents/Loading.component';
import { changeDateFormatToIST, convertMonDDYYYYtoDDmmYYYY, currentTime, getFirstErrorKey } from '../../../../../utils/utils';
import { EXECUTED_DEED_VALIDATION } from '../../../../../utils/formikValidation/FormikValidation';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import GenericDocViewer from '../../../../../components/fileviewer/GenericDocViewer';
import axios from 'axios';

export default function LeaseDeedWorkflow() {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [comment, setcomment] = useState('');
    const [activeIndex, setActiveIndex] = useState(1);
    const [searchParams] = useSearchParams();
    const leaseDeedAppNo = searchParams.get('applicationNumber') ? searchParams.get('applicationNumber') : '';
    const totalPages = leaseDeedAppNo ? 3 : 1;
    const [rowData, setRowData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const user = localStorage.getItem('user');
    const designation = user ? String(JSON.parse(user)?.designation) : '';
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [modalData, setModalData] = useState<any>(null);
    const [content, setContent] = useState('');
    let navigate = useNavigate();
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [deedDetails, setDeedDetails] = useState<any>(null);
    const [checklistTableData, setChecklistTableData] = useState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [updateDeedDetails, setUpdateDeedDetails] = useState<any>(null);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [leaseDeedSearchRes, setLeaseDeedSearchRes] = useState<any>(null);
    const [type, setType] = useState<any>('');
    const currentUserAssignment = localStorage.getItem('currentUserAssignment');
    const designationName = currentUserAssignment ? String(JSON.parse(currentUserAssignment)?.designation?.name) : '';
    const [leaseDeedDocuments, setLeaseDeedDocuments] = useState<any>([]);
    const [openSection, setOpenSection] = useState<string>('userDetails');
    const signedFileStoreId = searchParams.get('signedFileStoreId');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: EXECUTED_DEED_VALIDATION
    });

    const { values, setValues, errors, setFieldTouched } = formik;

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        // const docs = leaseDeedAppNo && file.referenceId && (await onAttachDocument(leaseDeedAppNo, file.referenceId, designation, nextValidAction.businessService, historyDetails[0].id));

        const payload: any = {
            RequestInfo: requestInfo(),
            idcWfDocument: {
                businessId: leaseDeedAppNo,
                docRefId: file.referenceId,
                uploadedUserRole: designationName,
                processInstanceId: historyDetails[0].id
            }
        };

        try {
            const createdDoc = await POST(estateEndpoints.documentCreate, payload);
            const res = await POST(endpoints.legalDeedDocumentSearch + `?businessId=${leaseDeedAppNo}&businessService=${nextValidAction.businessService}`, {
                RequestInfo: requestInfo()
            });
            setDocumentDetails(res?.data?.documents);
            setDocumentsUuid([...documentsUuid, createdDoc.data.uuid]);
        } catch (err) {
            console.log(err);
        }

        handleNextPage();
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) {
            setActiveIndex(prevInd => prevInd + 1);
        }
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) {
            setActiveIndex(prevInd => prevInd - 1);
        }
    };

    const correspondenceDocument = async () => {
        const formatedEntityDocuments: any = [];
        const refIDs = documentDetails?.map((docItem: any, _: number) => docItem.docRefId).filter(Boolean);
        if (refIDs && refIDs.length) {
            const { data } = await axios.get(devUrl + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: refIDs?.join() } });
            documentDetails?.map((doc: any, index: number) => {
                data.fileStoreIds.map((baseDoc: any) => {
                    if (baseDoc.id === doc.docRefId) {
                        formatedEntityDocuments.push({
                            ...doc,
                            documentUrl: baseDoc.url
                        });
                    }
                });
            });
        }
        return formatedEntityDocuments;
    };

    useEffect(() => {
        (async () => {
            const formatedEntityDocuments = await correspondenceDocument();

            const reverseDocumentDetails = formatedEntityDocuments.reverse();
            const tableData = async () => {
                const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${ind + 1}` };
                });
                const reverseData = responseTableData.reverse();
                setRowData(reverseData);
                console.log('reversedoc', reverseData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        })();
    }, [documentDetails, activeIndex]);

    const onRowClick = (row: any) => {
        console.log('rooo', row);
        const extension = (row?.docFileName || row?.tag)?.split('.').pop().toLowerCase();
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        if (extension === 'pdf') {
            setIsPDF(true);
        } else {
            setIsPDF(false);
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    console.log('deed details', deedDetails);

    // const docsUuid = searchParams.get('docsUuid');
    // const note: any = searchParams.get('note');

    // useEffect(() => {
    //     if (note && leaseDeedSearchRes && nextValidAction) {
    //         onHandlePrimaryAction({ action: 'APPROVE' }, note);
    //     }
    // }, [leaseDeedSearchRes, nextValidAction]);

    // const esign = async () => {
    //     try {
    //         if (window.location.protocol !== 'http:') {
    //             let url = window.location.origin + `/goa-idc/estate/allotment/workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}`;
    //             url = btoa(url);
    //             const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${leaseDeedSearchRes.attachmentRefId}`;
    //             await axios.get(esignUrl);

    //             window.open(esignUrl, '_self');
    //         } else {
    //             let url =
    //                 window.location.origin +
    //                 `/goa-idc/estate/allotment/workflow?${searchParams.toString()}&signedFileStoreId=${leaseDeedSearchRes.attachmentRefId}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}`;
    //             console.log('url', url, documentsUuid);
    //             window.open(url, '_self');
    //         }
    //     } catch (e) {
    //         console.log('esign error', e);
    //     }
    // };

    const onSectionChange = (currentSection: string) => {
        setOpenSection(currentSection);
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const emails = values.emails?.map((item: any) => item.emailId);
        const emailIds = emails ? { emailIds: emails } : null;
        const payload = {
            RequestInfo: requestInfo(),
            isStateUpdatable: nextValidAction.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction.businessService,
                    businessId: leaseDeedAppNo,
                    moduleName: nextValidAction.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes
                }
            ],
            legalDeedRequest:
                deedDetails?.applicationStatus === 'REGISTRATION_PENDING'
                    ? {
                          ...deedDetails,
                          uuid: values.uuid,
                          entityRefId: values.entityRefId,
                          plotId: values.plotId,
                          deedType: updateDeedDetails?.deedType,
                          content: content,
                          applicationNumber: leaseDeedAppNo
                      }
                    : {
                          ...deedDetails,
                          uuid: values.uuid,
                          entityRefId: values.entityRefId,
                          plotId: values.plotId,
                          deedType: updateDeedDetails?.deedType,
                          content: content,
                          applicationNumber: leaseDeedAppNo,
                          additionalDetails: {
                              ...deedDetails.additionalDetails,
                              notificationDetails: emailIds
                          },
                          deedExecutionDate: values?.executedLeaseDeedDate && changeDateFormatToIST(values?.executedLeaseDeedDate) + ' ' + currentTime(),
                          executedIndentureRefId: values?.signedLeaseDeed?.docReferenceId,
                          legalDocumentDetail: [
                              ...deedDetails?.legalDocumentDetail,
                              {
                                  docRefId: values?.indenture?.docReferenceId
                              }
                          ]
                          // remarks: values?.remarks
                      },
            workFlowDocumentIds: documentsUuid.filter(Boolean) //documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',')
        };

        console.log('payload', payload);

        try {
            if (designation === 'LA' && deedDetails?.applicationStatus === 'APPROVED' && status?.action !== 'INITIATE_REVISION') {
                await EXECUTED_DEED_VALIDATION.validate(values, { abortEarly: false });
            }
            if (!values.notes) {
                // if (!comment) {
                setModalDetails({ show: true, title: 'Error!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'ok' });
                return;
                // } else {
                // }

                // if ((!comment && !status.isEmployeeAssignee) || errors.emails) {
                //     setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'ok' });
                //     return;
                // }
            }

            // if (designation === 'MD' && !signedFileStoreId) {
            //     esign();
            //     return;
            // }

            try {
                setIsLoading(true);
                await POST(endpoints.leaseDeedWorkflow, payload);
                setIsLoading(false);
                const successMessage = getSuccessMessage(status?.action, leaseDeedAppNo);
                setModalDetails({ show: true, title: 'Success!', para: successMessage, img: check, type: 'success', reUpload: 'OK', action: status?.action });
            } catch (err: any) {
                setIsLoading(false);
                let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            onSectionChange('indentureExecution');
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill all the required details', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log(nextValidAction, 'nextvalidaction');

    const fetchApplicationDetailsAndNextValidAction = async () => {
        try {
            setIsLoading(true);
            if (leaseDeedAppNo) {
                const nextActions = await getNextActions(leaseDeedAppNo);
                const { data } = await POST(endpoints.leaseDeedSearch + `?applicationNumbers=${leaseDeedAppNo}`, { RequestInfo: requestInfo() });
                // const { data: legalNotesDocument } = await POST(endpoints.leaseDeedSearch + `?uuids=${data?.deedDetails[0]?.legalDocumentDetail[0]?.legalUuid}`, { RequestInfo: requestInfo() });
                // console.log('Legal notesss', legalNotesDocument);
                const reverseDocumentsList = data?.deedDetails[0]?.legalDocumentDetail?.reverse();
                // const tempLeaseDeedDocument = reverseDocumentsList?.map((val: any, index: number) => ({
                //     ...val,
                //     serialNo: index + 1,
                //     indenture: `Deed ${index + 1}`,
                //     version: `v${index + 1}`,
                //     uploadedDateTime: val?.auditDetails?.createdDate
                // }));
                // setLeaseDeedDocuments(tempLeaseDeedDocument.reverse());

                const formatedEntityDocuments: any = [];
                const refIDs = reverseDocumentsList?.map((docItem: any, _: number) => docItem.docRefId).filter(Boolean);
                if (refIDs && refIDs.length) {
                    const { data } = await axios.get(devUrl + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: refIDs?.join() } });
                    reverseDocumentsList?.map((doc: any, index: number) => {
                        data.fileStoreIds.map((baseDoc: any) => {
                            if (baseDoc.id === doc.docRefId) {
                                formatedEntityDocuments.push({
                                    ...doc,
                                    ...baseDoc,
                                    documentSize: baseDoc.fileSize,
                                    documentUrl: baseDoc.url,
                                    serialNo: index + 1,
                                    indenture: `Deed ${index + 1}`,
                                    version: `v${index + 1}`,
                                    uploadedDateTime: `${convertMonDDYYYYtoDDmmYYYY(baseDoc?.uploadedDate)}, ${baseDoc?.uploadedTime.split(':').slice(0, 2).join(':')}`
                                });
                            }
                        });
                    });
                }
                setLeaseDeedDocuments(formatedEntityDocuments?.reverse());

                console.log('Dataaaaa', data);
                if (data.deedDetails[0]) {
                    setLeaseDeedSearchRes({ ...data?.deedDetails[0], attachmentRefId: signedFileStoreId ? signedFileStoreId : data.deedDetails[0]?.attachmentRefId });
                    const checklistData = await POST(
                        endpoints.checklistValidationSearch +
                            `?entityId=${data.deedDetails[0].entityDetails.uuid}&plotId=${data.deedDetails[0].plotDetails.uuid}&businessServices=${nextActions?.businessService}&subTypes=${data.deedDetails[0].deedType}`,
                        {
                            RequestInfo: requestInfo()
                        }
                    );
                    const rowDataWithSerialNumber = checklistData.data.checklistValidationDetails.map((val: any, ind: number) => {
                        return { serialNo: ind + 1, ...val };
                    });
                    // const checklistData = await getCheckList(data.deedDetails[0].entityDetails.uuid, data.deedDetails[0].plotDetails.uuid, 'LEGAL_DEED');
                    const res = await getAllotteeInitData(data?.deedDetails[0]);
                    const plotInitData = await autoPopulatePlotDetails(data?.deedDetails[0].plotId);
                    const indentureDetails = await indentureDetailsInitialValues(data?.deedDetails[0]);
                    const emailIds =
                        data.deedDetails[0]?.additionalDetails?.notificationDetails?.emailIds &&
                        data.deedDetails[0].additionalDetails.notificationDetails.emailIds.map((val: any) => ({ emailId: val }));
                    setChecklistTableData(rowDataWithSerialNumber);
                    setNextValidAction(nextActions);
                    setValues({
                        ...values,
                        ...res,
                        ...plotInitData,
                        ...indentureDetails,
                        uuid: data?.deedDetails[0].uuid,
                        plotId: data?.deedDetails[0].plotId,
                        entityRefId: data?.deedDetails[0].entityRefId,
                        emails: emailIds,
                        isEmailUpdatable: nextActions?.isUpdatable && data?.deedDetails[0].applicationStatus === 'PENDING',
                        designation: designation,
                        applicationStatus: data?.deedDetails[0].applicationStatus
                        // isUpdatable: nextActions.isUpdatable && data?.deedDetails[0].applicationStatus === 'PENDING'
                    });
                    const { content, applicationNumber, ...UpdateDeedDetailsPayload } = data?.deedDetails[0];
                    const { entityRefId, plotId, deedType, uuid, ...deedDetailsPayload } = UpdateDeedDetailsPayload;
                    setUpdateDeedDetails(UpdateDeedDetailsPayload);
                    setDeedDetails(deedDetailsPayload);
                    setContent(content);
                    setIsLoading(false);
                }
            }
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        fetchApplicationDetailsAndNextValidAction();
        fetchHistoryData();
    }, []);

    useEffect(() => {
        if (activeIndex === 2) {
            const fetchDocData = async () => {
                let payload = {
                    RequestInfo: requestInfo()
                };
                try {
                    setIsLoading(true);
                    const res = await POST(endpoints.legalDeedDocumentSearch + `?businessId=${leaseDeedAppNo}&businessService=${nextValidAction?.businessService}`, payload);
                    setDocumentDetails(res?.data?.documents);
                    const relatedApplicationDocument = await POST(
                        estateEndpoints.relatedApplicationSearch + `?entityIds=${deedDetails.entityId}&plotIds=${values.plotId}&applicationNos=${leaseDeedAppNo}`,
                        payload
                    );
                    setRelatedApplicationDocument(relatedApplicationDocument);
                    setIsLoading(false);
                    console.log('related application document', relatedApplicationDocument);
                } catch (err: any) {
                    setIsLoading(false);
                    let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            };
            fetchDocData();
        }
    }, [activeIndex, deedDetails]);

    const updateLeaseDeed = async () => {
        const emails = values.emails?.map((item: any) => item.emailId);
        const emailIds = emails ? { emailIds: emails } : null;
        let payload = {
            RequestInfo: requestInfo(),
            ...updateDeedDetails,
            // content: content,
            applicationNumber: leaseDeedAppNo,
            additionalDetails: {
                ...deedDetails.additionalDetails,
                notificationDetails: emailIds
            },
            deedExecutionDate: values?.executedLeaseDeedDate && changeDateFormatToIST(values?.executedLeaseDeedDate) + ' ' + currentTime(),
            executedIndentureRefId: values?.signedLeaseDeed?.docReferenceId,
            legalDocumentDetail: [
                ...updateDeedDetails?.legalDocumentDetail,
                {
                    docRefId: values?.indenture?.docReferenceId
                }
            ]
            // remarks: values?.remarks
        };

        console.log('content', payload);
        try {
            setIsLoading(true);
            await POST(endpoints.leaseDeedUpdate, payload);
            setModalDetails({ show: true, title: 'Success!', para: 'Data saved successfully', img: check, type: 'success', reUpload: 'OK' });
            setIsLoading(false);
        } catch (err: any) {
            setIsLoading(false);
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Application for Lease Deed Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const fetchHistoryData = async () => {
        if (leaseDeedAppNo) {
            try {
                const { data } = await POST(`${endpoints.workflowSearch}&businessIds=${leaseDeedAppNo}`, { RequestInfo: requestInfo() });
                setHistoryDetails(data.ProcessInstances);

                console.log(data);
            } catch (err: any) {
                let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const handleModalClose = (type: string, action: string) => {
        setModalDetails({});
        if (type === 'success') {
            if (action === 'INITIATE_REVISION') {
                window.location.reload();
            } else {
                navigate('/goa-idc/dashboard');
            }
        }
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    useEffect(() => {
        switch (leaseDeedSearchRes?.deedType) {
            case 'TRIPARTITE_DEED':
                setType('Tripartite Deed');
                return;
            case 'LEASE_DEED':
                setType('Lease Deed');
                return;
            default:
                return;
        }
    }, [leaseDeedSearchRes]);

    console.log(formik, 'formik');

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                        <GenericHeader text={'Indenture Application'} additionalClass='sub-text-style' bottomSubText={`File : ${values.allotteeName}, Type : ${type}, No : ${leaseDeedAppNo}`} />
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard'>
                                    <GenericHeader text={'Indenture Application'} sideHeader={true} additionalClass='sub-text-style' bottomSubText={`Type : ${type}, No : ${leaseDeedAppNo}`} />
                                    {/* <div className='d-flex justify-content-between align-items-center'>
                                        <p className='noting-heading my-1'>Noting</p>
                                        <NotingDocDownload applicationNumber={leaseDeedAppNo} />
                                    </div>
                                    <NotingComponent history={historyDetails} text={comment} handleOnFileUpload={handleOnFileUpload} /> */}
                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={leaseDeedAppNo}
                                        history={historyDetails}
                                        disable={!nextValidAction?.nextValidAction.length}
                                        text={comment}
                                        handleOnFileUpload={handleOnFileUpload}
                                        applicationType={'LEASE_DEED'}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                {/* <div className='d-flex justify-content-between align-items-center'>
                                    <p className='correspondence-heading my-1'>Correspondence</p>
                                    <CorrespondenceDocDownload applicationNumber={leaseDeedAppNo} />
                                </div> */}

                                <CorrespondenceWrapper applicationNumber={leaseDeedAppNo} designation={designation} applicationType={'LEASE_DEED'} nextValidAction={nextValidAction} />

                                <Card className='card_border p-1'>
                                    {activeIndex === 1 && (
                                        <LeaseDeedCorrespondence
                                            content={content}
                                            setContent={setContent}
                                            checkListRowData={checklistTableData}
                                            applicationStatus={deedDetails?.applicationStatus}
                                            designation={designation}
                                            leaseDeedSearchRes={leaseDeedSearchRes}
                                            nextValidAction={nextValidAction}
                                            leaseDeedDocuments={leaseDeedDocuments}
                                            onRowClick={onRowClick}
                                            openSection={openSection}
                                            setOpenSection={setOpenSection}
                                        />
                                    )}
                                    {activeIndex === 2 && (
                                        <div className='p-4'>
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideSearchBar={true} rowData={rowData} additionalMethod={onRowClick} />
                                        </div>
                                    )}
                                    {activeIndex === 3 && (
                                        <div className='p-4'>
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        </div>
                                    )}
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                        </FormikProvider>
                    </div>
                    <GenericFooterComponent
                        onCancel={onCancel}
                        onClickPrev={onChangePrevIndex}
                        activeIndex={activeIndex}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={() => {}}
                        applicationNo={leaseDeedAppNo || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                        extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                        extraButtonAction={updateLeaseDeed}
                    />
                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Document Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericDocViewer url={modalData.documentUrl} extension={'docx'} fileName={modalData.indenture} />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails.action)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.action)}
                    />
                </>
            )}
        </>
    );
}
