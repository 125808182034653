import { useState } from "react";

interface BPASelectionTableProps {
    data: any[]; // Data for the table rows
    selectedRow: any; // Currently selected row
    onSelectRow: (row: any) => void; // Function to handle row selection
}
export default function BPASelectionTable(props: BPASelectionTableProps) {
    const [searchedData, setSearchedData] = useState(props?.data);

    const handleOnSearch = (e: any) => {
        if (e.target.value) {
            const filtered = props?.data.filter((item: any) => Object.values(item).some(value => value?.toString().toLowerCase().includes(e.target.value.toLowerCase())));
            setSearchedData(filtered);
        } else setSearchedData(props?.data);
    };

    return (
        <div style={{ maxHeight: "20rem", overflow: "scroll", overflowX: "hidden" }} >
                    <div className={`col-sm-12 position-relative ms-2`}>
                    <input type='search' onChange={handleOnSearch} placeholder={"Search by application No"} className={`col-sm-6 my-3 p-2 `} />
                </div>
            <table className="table table-striped table-hover table-condensed">
                <thead>
                    <tr>
                        <th style={{ backgroundColor: "#45852e", color: "#fff"}}>Select</th>
                        <th style={{ backgroundColor: "#45852e", color: "#fff"}}>DCR Number</th>
                        <th style={{ backgroundColor: "#45852e", color: "#fff"}}>Estate</th>
                        <th style={{ backgroundColor: "#45852e", color: "#fff"}}>Plot Number</th>
                        <th style={{ backgroundColor: "#45852e", color: "#fff"}}>Architect Name</th>
                        <th style={{ backgroundColor: "#45852e", color: "#fff"}}>Shared Date</th>
                    </tr>
                </thead>
                <tbody>
                    {searchedData.map((row, index) => {
                        console.log("selectedRow:", props.selectedRow);
                        console.log("row:", row);
                        return (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="radio"
                                        value={row.dcrNumber}
                                        checked={props.selectedRow === row.code}
                                        onChange={() => props.onSelectRow(row)}
                                    />
                                </td>
                                <td>{row?.dcrNumber || '-'}</td>
                                <td>{row?.estate || '-'}</td>
                                <td>{row?.plotNumber || '-'}</td>
                                <td>{row?.architectName || '-'}</td>
                                <td>{row?.sharedDate || '-'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
