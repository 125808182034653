import { FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Card, CardSubtitle, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../features/modules/UserManagement/UserManagement.scss';
import { useTranslation } from 'react-i18next';
import {
    PROFILE_ASSIGNMENT_DETAILS,
    PROFILE_DOCUMENT_DETAILS,
    PROFILE_EDUCATIONAL_DETAILS,
    PROFILE_PERSONAL_DETAILS,
    PROFILE_PROFESSIONAL_DETAILS,
    PROFILE_STEPS
} from '../../features/modules/UserManagement/UserManagement.const';
import GenericBackButton from '../UI components/Buttons/GenericBackButton';
import { COMMENTS, EMPLOYEE_PROFILE_VALIDATION, PasswordChangeSchema } from '../../utils/formikValidation/FormikValidation';
import GenericFormCard from '../moduleCommonFormik/GenericFormCard.component';
import HeaderComponent from '../header/Header.component';
import SideCard from '../moduleCommonFormik/SideCardGenericComponent';
import ScrollToTop from '../ScrollToTop';
import ProfileHeader from './ProfileHeader';
import { POST, endpoints, getDropdownOptions, getProgressBarData, devUrl, requestInfo, updateEmployee, updatePassword } from '../../services/modulesServices/userManagement.api';
import { getProfileInitData } from '../../features/modules/UserManagement/UserMangement.controller';
import GenericButtons from '../UI components/Buttons/GenericButtons';
import ForgotPassword from '../../features/loginPage/ForgotPassword';
import { CONSTANT_DATA_FIELD, FormDataDTO, TENANT_ID } from '../../utils/constants';
import { DocumentDetails, FileEnum, NextValidActionItem } from '../moduleCommonFormik/GenericFormCard.constants';
import { formatedDocumentDetails } from '../../utils/utils';
import Loading from '../portalComponents/Loading.component';
import axios from 'axios';
import GenericHeader from '../moduleCommonFormik/GenericHeader';
import StatusAlertPopup from '../moduleCommonFormik/StatusAlertPopup';
import { check, cross } from '../../utils/Logo';
import GenericPopupModal from '../moduleCommonFormik/GenericModalPopup.component';
import GenericFooterComponent from '../moduleCommonFormik/GenericFooter.component';
import PaymentSubmissionStatus from '../../features/technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../moduleCommonFormik/ProgressBar';
import { isEqual } from 'lodash';
import { EmudraPage } from '../../features/commonPages/EmudraPage';
import { KYCRegistration } from './KYCRegistration';

export default function ProfileComponent() {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [dropdownData, setDropdownData] = useState<any>([]);
    const [profileData, setProfileData] = useState<any>(PROFILE_PERSONAL_DETAILS);
    const [enabledSection, setEnableSection] = useState<any>([]);
    const [otherAssignmentFormData, setOtherAssignmentFormData] = useState<any>([]);
    const [responseData, setResponseData] = useState<any>({});
    const [profilePhoto, setProfilePhoto] = useState<any>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [primaryAssignmentisChecked, setPrimaryAssignmentIsChecked] = useState(true);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [hideCheckbox, setHideCheckbox] = useState<boolean>(true);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [initalValues, setInitialValues] = useState<any>(null);
    const [profileEducationDetails, setProfileEducationDetails] = useState<any>([]);

    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const updateWorkflow = params.get('update');
    const regNo = params.get('applicationNumber');

    const userInfo = JSON.parse(localStorage.getItem('user') || 'null');
    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},
        validationSchema: activeIndex === 1 ? EMPLOYEE_PROFILE_VALIDATION : PasswordChangeSchema
    });
    const { values, setValues, setFieldValue } = formik;

    const handleScroll = () => {
        if (window.scrollY > 110) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        getDropdownOptions()
            .then((res: any) => {
                setDropdownData(res);
            })
            .catch((err: any) => console.log(err));

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                let response;
                if (updateWorkflow) {
                    response = await POST(endpoints.updateEmpProfileSearch + `?registrationNumber=${regNo}`, { RequestInfo: requestInfo() });
                } else {
                    response = await POST(endpoints.searchEmployee + `&uuids=${userInfo.uuid}`, { RequestInfo: requestInfo() });
                }
                const primaryAssignmentDetailsIndex = response.data.Employees[0].assignments.findIndex((item: any) => item.isPrimary);
                const otherQualificationDetails = response.data.Employees[0].education.filter((item: any, index: number) => index !== 0);
                const otherAssignments = response.data.Employees[0].assignments.filter((item: any) => !item.isPrimary);
                const initData = await getProfileInitData(response.data.Employees[0], dropdownData, primaryAssignmentDetailsIndex, otherAssignments, otherQualificationDetails);
                setValues((prev: any) => ({ ...prev, ...initData }));
                setInitialValues({ ...values, ...initData });
                appendMultiSectionForm(response.data.Employees[0]);
                appendMultiSectionEducation(response.data.Employees[0]);
                setResponseData(response.data.Employees[0]);

                const photo = initData['profilePic'];
                setProfilePhoto(photo);
                setIsLoading(false);

                if (regNo) {
                    const { data } = await POST(endpoints.nextValidAction + `?businessId=${regNo}&tenantId=ga`, { RequestInfo: requestInfo() });
                    setNextValidAction(data);
                }
            } catch (error) {
                setIsLoading(false);
            }
        })();
    }, [dropdownData, regNo]);

    useEffect(() => {
        if (!!regNo) {
            getProgressBarData(regNo)
                .then((res: any) => setProgressBarDetails(res.data))
                .then(err => console.log(err));
        }
    }, [responseData]);

    const appendMultiSectionForm = (res: any) => {
        const assignment = [];
        for (let i = 0; i < res.assignments.length - 1; i++) {
            assignment.push(PROFILE_ASSIGNMENT_DETAILS[0]);
        }
        setOtherAssignmentFormData(assignment);
    };

    const appendMultiSectionEducation = (res: any) => {
        const educationDetails = [];
        for (let i = 0; i < res.education.length - 1; i++) {
            educationDetails.push(PROFILE_EDUCATIONAL_DETAILS[0]);
        }
        setProfileEducationDetails(educationDetails);
    };

    const handleOnEditSection = (heading: any, isEditing: boolean) => {
        let newData = [];
        if (isEditing) {
            newData = profileData.map((item: any) => {
                if (item.type === heading) {
                    const formData = item.formData.map((fieldItem: FormDataDTO) => ({
                        ...fieldItem,
                        isDisabled: !fieldItem.isEditable
                    }));
                    if (item.title === 'Present Address') {
                        let addressProof = { titleCode: 'UPLOAD_ADDRESS_PROOF', title: 'Upload address proof', field: 'addressProofDocument', type: CONSTANT_DATA_FIELD.FILE_FIELD, isEditable: true };
                        formData.push(addressProof);
                        setHideCheckbox(false);
                    }
                    return { ...item, formData };
                }
                return item;
            });
            setEnableSection([...enabledSection, heading]);
        } else {
            newData = profileData.map((item: any) => {
                if (item.type === heading) {
                    const formData = item.formData.map((fieldItem: FormDataDTO) => ({
                        ...fieldItem,
                        isDisabled: true
                    }));
                    if (item.title === 'Present Address') {
                        let addressProof = { titleCode: 'UPLOAD_ADDRESS_PROOF', title: 'Upload address proof', field: 'addressProof', type: CONSTANT_DATA_FIELD.FILE_FIELD, isEditable: true };
                        formData.pop(addressProof);
                        setHideCheckbox(true);
                    }
                    return { ...item, formData };
                }
                return item;
            });
            const editableSection = enabledSection.filter((item: string) => item !== heading);
            setEnableSection(editableSection);
        }
        setProfileData(newData);
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                let errMsg = t('FILL_REQUIRED_DETAILS', 'Fill in all the required details');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const handleOnSubmit = async (selectedAction?: any, comment?: string) => {
        if (!regNo && isEqual(initalValues, values)) {
            let errMsg = 'No changes detected';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (
            (values.profilePhoneNo !== responseData.user.mobileNumber && !values.profilePhoneNoOtpReferenceId) ||
            (values.profileEmailId !== responseData.user.emailId && !values.profileEmailIdOtpReferenceId)
        ) {
            let errMsg = 'Verification Needed';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        let docs = responseData.documents.map((doc: DocumentDetails) => {
            if (doc.referenceType === 'ADDRESS_PROOF' && values.addressProofDocument) {
                return {
                    documentName: values.addressProofDocument?.documentName,
                    documentType: doc.documentType || '',
                    documentId: values.addressProofDocument.referenceId,
                    referenceType: FileEnum['Address Proof'],
                    referenceId: values.addressProofDocument.referenceId, //id
                    documentSize: values.addressProofDocument?.documentSize,
                    uploadedDate: values.addressProofDocument?.uploadedDate,
                    tenantid: TENANT_ID
                };
            } else return doc;
        });

        if (profilePhoto) {
            let profileImg = {
                documentName: profilePhoto?.documentName,
                documentId: profilePhoto?.referenceId,
                referenceType: FileEnum['profile photo'],
                referenceId: profilePhoto?.referenceId, //id
                documentSize: profilePhoto?.documentSize,
                uploadedDate: profilePhoto?.uploadedDate,
                tenantid: TENANT_ID
            };

            let newDocumentsWithProfileImg = docs.filter((doc: DocumentDetails) => doc.referenceType !== 'PROFILE_PICTURE');
            docs = [...newDocumentsWithProfileImg, profileImg];
        }

        const payload = {
            RequestInfo: requestInfo(),
            Employees: [
                {
                    ...responseData,
                    user: {
                        ...responseData.user,
                        mobileNumber: (values.profilePhoneNoOtpReferenceId && values.profilePhoneNo) || responseData.user.mobileNumber,
                        emailId: (values.profileEmailIdOtpReferenceId && values.profileEmailId) || responseData.user.emailId,
                        permanentAddress1: values.peraddress1 || responseData.user.permanentAddress1,
                        permanentAddress2: values.peraddress2 || responseData.user.permanentAddress2,
                        permanentDistrict: values.perdistrict || responseData.user.permanentDistrict,
                        permanentState: values.perstate || responseData.user.permanentState,
                        permanentCountry: values.percountry || responseData.user.permanentCountry,
                        permanentPinCode: values.perpincode || responseData.user.permanentPinCode,
                        correspondenceAddress1: values.address1 || responseData.user.correspondenceAddress1,
                        correspondenceAddress2: values.address2 || responseData.user.correspondenceAddress2,
                        correspondenceDistrict: values.district || responseData.user.correspondenceDistrict,
                        correspondenceState: values.state || responseData.user.correspondenceState,
                        correspondenceCountry: values.country || responseData.user.correspondenceCountry,
                        correspondencePinCode: values.pincode || responseData.user.correspondencePinCode
                    },
                    documents: docs
                }
            ]
        };

        if (!updateWorkflow) {
            try {
                const { data }: any = await POST(endpoints.updateEmpProfile, payload);
                setModalDetails({
                    show: true,
                    title: 'Profile Updated Successfully',
                    // para: data.ResponseInfo.resMsgId,
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
            } catch (err: any) {
                let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Employee Profile Update Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } else {
            const workflowPayload = {
                RequestInfo: requestInfo(),
                ProcessInstances: [
                    {
                        tenantId: TENANT_ID,
                        businessService: nextValidAction?.businessService,
                        businessId: regNo,
                        moduleName: nextValidAction?.moduleName,
                        action: selectedAction?.action,
                        comment: comment
                    }
                ],
                Employees: payload.Employees,
                isStateUpdatable: nextValidAction?.isUpdatable
            };
            if (!comment) {
                let errMsg = 'Please fill in all the required details!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            try {
                const res = await POST(endpoints.employeeWorkflow, workflowPayload);
                const msg = res.data.processInstances[0]?.assignes?.[0]?.name
                    ? `${t('ACTION_SUCCESS_SENT', 'Action Successful! Application send to')} ${res.data.processInstances[0]?.assignes[0]?.name}`
                    : `${t('ACTION_SUCCESS', 'Action Successful!')}`;
                setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'success', reUpload: 'OK' });
                navigate('/goa-idc/dashboard');
            } catch (e) {
                let errMsg = t('ACTION_UNSUCCESS', 'Action Unsuccessful! Try again');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const onEditProfilePhoto = async (e: any) => {
        const file: any = e.target.files && e.target.files[0];

        try {
            if (!file) {
                console.error('No file selected.');
                return;
            }

            const response: any = await axios.post(
                devUrl + '/filestore/v1/files',
                {
                    file: file,
                    tenantId: 'ga',
                    tag: file.name,
                    module: 'hrms-upload'
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            let newProfilePhoto = await formatedDocumentDetails(response.data.files[0].fileStoreId);
            setFieldValue('profilePhoto', newProfilePhoto);
            setProfilePhoto(newProfilePhoto);
        } catch (e) {
            console.log(e);
        }
    };

    const assignmentChecked = () => {
        setPrimaryAssignmentIsChecked(!primaryAssignmentisChecked);
    };

    const onSubmitChangePassword = async () => {
        if (values.oldPassword === values.newPassword) {
            let errMsg = t('OLD_PASSWORD_WARNING', 'For security reasons, please select a new password that is different from your previous one.');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            await updatePassword(responseData?.code, values);
            let msg = t('PASSWORD_UPDATED_SUCCESS', 'Password updated successfully');
            setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'Update success', reUpload: 'OK' });
        } catch (e) {
            let errMsg = t('PASSWORD_UPDATE_FAILED', 'Password update failed');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({});
        // modalDetails?.show(false);
        if (type === 'success') navigate('/goa-idc/dashboard');
    };

    const renderProfilePersonalDetails = () => {
        const status: any = progressBarDetails?.progressStatus.find((item: any) => item.isCurrent === true);
        const applicationData = {
            applicationNo: regNo,
            status: progressBarDetails?.currentStatus,
            data: progressBarDetails?.auditTrails,
            bpaData: '',
            pendingWith: status?.actor || '-'
        };
        return (
            <>
                {!!regNo && (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                        </Card.Body>
                    </Card>
                )}
                <Card className='card_border'>
                    <ProfileHeader
                        name={`${values?.name}`}
                        designation={`${values?.designation?.name}`}
                        onEditProfilePhoto={onEditProfilePhoto}
                        showEditIcon={!(nextValidAction?.isUpdatable === false)}
                    />
                    <Card.Body>
                        {profileData.map((val: any, id: number) => (
                            <GenericFormCard
                                {...val}
                                isEditable={!(nextValidAction?.isUpdatable === false) ? val.isEditable : false}
                                dropdownOptions={dropdownData}
                                handleOnEdit={handleOnEditSection}
                                enabledSection={enabledSection}
                                module='usermanagement'
                                hideAddressCheckbox={hideCheckbox}
                            />
                        ))}

                        <label htmlFor='Educational Details' className='formHeading'>
                            Educational Details
                        </label>

                        {PROFILE_EDUCATIONAL_DETAILS.map((val: any, index: number) => (
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        ))}

                        {profileEducationDetails.map((val: any, index: number) => (
                            <>
                                <hr />
                                <GenericFormCard {...val} dropdownOptions={dropdownData} index={index} additionalSection='qualificationDetails' />
                            </>
                        ))}

                        {PROFILE_PROFESSIONAL_DETAILS.map((val: any, id: number) => (
                            <GenericFormCard
                                {...val}
                                isEditable={!(nextValidAction?.isUpdatable === false) ? val.isEditable : false}
                                dropdownOptions={dropdownData}
                                handleOnEdit={handleOnEditSection}
                                enabledSection={enabledSection}
                                module='usermanagement'
                                hideAddressCheckbox={hideCheckbox}
                            />
                        ))}

                        <div className='row'>
                            <div className='col-sm-12 collapsible_box  align-items-center pb-2 '>
                                <input type='checkbox' id='Primary Assignment' name='assignments' checked={primaryAssignmentisChecked} />
                                <label htmlFor='Primary Assignment' className='d-flex collapsible_box_label align-items-center justify-content-between   ' onClick={assignmentChecked}>
                                    Primary Assignment
                                </label>

                                <div className='form_fields '>
                                    {PROFILE_ASSIGNMENT_DETAILS.map((val: any, id: number) => (
                                        <GenericFormCard {...val} dropdownOptions={dropdownData} module='usermanagement' />
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 collapsible_box  align-items-center pb-2 '>
                                <input type='checkbox' id='Other Assignment' name='assignments' />
                                <label htmlFor={'Other Assignment'} className='d-flex collapsible_box_label align-items-center justify-content-between   '>
                                    {'Other Assignment'}
                                </label>
                                <div className='form_fields'>
                                    {otherAssignmentFormData.map((val: any, index: number) => (
                                        <>
                                            <GenericFormCard {...val} dropdownOptions={dropdownData} additionalSection={'assignments'} index={index} module='usermanagement' />
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {PROFILE_DOCUMENT_DETAILS.map((val: any, id: number) => (
                            <GenericFormCard {...val} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const changePassword = () => {
        return (
            <>
                <Card className='card_border mb-4'>
                    <Card.Body>
                        <div className='container'>
                            <h3 className='change_password_heading mt-3'>Change Password</h3>
                            <ForgotPassword />
                        </div>
                        <div className='col-sm-12 ps-3 my-3'>
                            <h1 className='password_policy_heading'>Password Policy</h1>
                            <p className='col-sm-6 pe-2'>Password should be minimum 8 characters long, one lowercase, one uppercase, one digit, one special character.</p>
                        </div>
                    </Card.Body>
                </Card>
            </>
        );
    };

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />

            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader
                    text={updateWorkflow ? t('EMPLOYEE_PROFILE_UPDATE', 'Employee Profile Update') : t('MY_PROFILE', 'My Profile')}
                    registrationNo={updateWorkflow ? regNo : ''}
                    bottomSubText={!updateWorkflow ? `${t('EMPLOYEE_ID', 'Employee Id')} - ${responseData?.code}` : ''}
                />
                <div className='col-3 stickyCard-section'>
                    <div className='stickyCard' style={{ top: '110px' }}>
                        <Card className={`card_border  hiddenNav  ${isVisible ? '' : 'scrolledNav'}`}>
                            <Card.Body className=''>
                                <div className='d-flex align-items-center'>
                                    <GenericBackButton />
                                    <div className='ps-1'>
                                        <Card.Text className='font-inter-med fs-5 mb-0 form-header-text'>
                                            {updateWorkflow ? `${t('EMPLOYEE_PROFILE_APPLICATION', 'Employee Profile Application')} - ${regNo}` : t('MY_PROFILE', 'My Profile')}
                                        </Card.Text>
                                        <CardSubtitle className='text-muted text-nav font-inter p-0 m-0'>
                                            {!updateWorkflow ? `${t('EMPLOYEE_ID', 'Employee Id')} - ${responseData?.code}` : ''}
                                        </CardSubtitle>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className='card_border p-3'>
                            <Card.Body>
                                <Card.Text className=' d-flex flex-column  row-gap-3'>
                                    <SideCard steps={!updateWorkflow ? PROFILE_STEPS : PROFILE_STEPS.slice(0, 1)} activeIndex={activeIndex} handleStepClick={handleStepClick} />
                                </Card.Text>
                            </Card.Body>
                        </Card>

                        {/* <Card className='card_border note'>
                            <Card.Body>
                                <Card.Text>
                                    <span>
                                        <i className='fa fa-exclamation-circle' aria-hidden='true'></i>
                                    </span>
                                    <b> {t('NOTE', 'Note :')} </b>
                                    {t('PLEASE_PROVIDE_ALL_DETAILS', 'Please provide all the details.')}
                                </Card.Text>
                            </Card.Body>
                        </Card> */}
                    </div>
                </div>
                <div className='col-9 form-section'>
                    <FormikProvider value={formik}>
                        <Form>
                            {activeIndex === 1 && renderProfilePersonalDetails()}
                            {activeIndex === 2 && changePassword()}
                            {activeIndex === 3 && <EmudraPage />}
                            {/* {activeIndex === 4 && <KYCRegistration />} */}
                        </Form>
                    </FormikProvider>
                </div>
            </div>
            <ScrollToTop />
            <div className='common-footer'>
                {updateWorkflow ? (
                    <GenericFooterComponent
                        onCancel={() => navigate(-1)}
                        stepperLength={1}
                        activeIndex={1}
                        onSubmit={() => {}}
                        nextValidAction={nextValidAction}
                        applicationNo={regNo || null}
                        handleOnPrimaryAction={handleOnSubmit}
                    />
                ) : (
                    <GenericButtons text={t('SUBMIT', 'Submit')} type='submit' variant='primary' ClassName='me-5' handleClick={activeIndex === 1 ? handleOnSubmit : onSubmitChangePassword} />
                )}
            </div>
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
        </>
    );
}
