import React, { useEffect, useState } from 'react';
import HeaderComponent from '../header/Header.component';
import { Card } from 'react-bootstrap';
import { NOTIFICATION_COLUMN_DATA } from './NotificationData';
import GenericActionTable from '../moduleCommonFormik/GenericActionTable';
import GenericBackButton from '../UI components/Buttons/GenericBackButton';
import { POST, requestInfo } from '../../services/modulesServices/userManagement.api';
import { useNavigate } from 'react-router-dom';
import GenericButtons from '../UI components/Buttons/GenericButtons';
import { useDispatch } from 'react-redux';
import { setNotificationCount } from '../../redux/notificationSlice';
import { useTranslation } from 'react-i18next';
import GenericHeader from '../moduleCommonFormik/GenericHeader';

const NotificationPage = () => {
    const { t } = useTranslation();
    const columns = NOTIFICATION_COLUMN_DATA;
    const [rowData, setRowData] = useState([]);
    const navigate = useNavigate();
    const [selectedIds, setSelectedIds] = useState([]);
    const dispatch = useDispatch();

    const getUser = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        (async () => {
            try {
                const { data } = getUser.uuid && (await POST(`/idc-notification-service/usernotification/details/_search?notificationType=SYSTEM`, { RequestInfo: requestInfo() }));
                setRowData(data.userNotificationDetails);
            } catch (error) {
                console.log('need to login again');
            }
        })();
    }, []);

    const handleNotificationRowClick = async (rowData: any) => {
        try {
            const { data } = await POST(`/idc-notification-service/usernotification/details/_markasread?userNotificationIds=${rowData.id}`, { RequestInfo: requestInfo() });
            navigate(rowData.notificationDetails.navUrl);
            dispatch(setNotificationCount(data.unreadCount));
        } catch (error) {
            navigate('/goa-idc/');
        }
    };

    const onCheckNotification = (selected: any) => {
        setSelectedIds(selected);
    };

    const clickToMarkRead = async () => {
        try {
            const { data } = await POST(`/idc-notification-service/usernotification/details/_markasread?userNotificationIds=${selectedIds}`, { RequestInfo: requestInfo() });
            dispatch(setNotificationCount(data.unreadCount));
            setRowData(data.userNotificationDetails);
            window.location.reload();
        } catch (error) {
            // alert('need to login again');
        }
    };

    return (
        <div style={{ backgroundColor: ' #F5F5F5' }}>
            <HeaderComponent />
            <div className='container py-5'>
                <div className='row'>
                    <GenericHeader text={'Notifications'} />
                </div>
                <div className='col-sm-12 mt-4'>
                    <GenericActionTable
                        columns={columns}
                        rowData={rowData}
                        additionalMethod={handleNotificationRowClick}
                        checkType='multiCheck'
                        onCheckHandler={onCheckNotification}
                        searchBarPlaceholder='SEARCH_BY'
                    />
                </div>
            </div>
            <div className='Notification_footer d-flex justify-content-end align-items-center position-fixed bottom-0     '>
                <GenericButtons text={t('MARK_AS_READ', 'Mark as Read')} variant='primary' ClassName='me-5' handleClick={clickToMarkRead} disabled={selectedIds.length === 0 ? true : false} />
            </div>
        </div>
    );
};

export default NotificationPage;
