import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import './commonStyles.scss';
import { useTranslation } from 'react-i18next';
import * as bootstrap from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default function SliderComponent() {
    const [index, setIndex] = useState(1);
    const { t } = useTranslation();
    const nextBtnRef: any = useRef(null);

    useEffect(() => {
        if (nextBtnRef.current) {
            nextBtnRef.current.click();
        }
    }, []);

    // useEffect(() => {
    //     // const myCarousel = document.querySelector('#carouselExampleAutoplaying');
    //     const myCarousel = document.getElementById('carouselExampleAutoplaying');

    //     const carousel = myCarousel
    //         ? new bootstrap.Carousel(myCarousel, {
    //               ride: 'carousel',
    //               pause: false,
    //               interval: 5000
    //           })
    //         : null;

    //     // Optionally, return a cleanup function if needed
    //     return () => {
    //         if (carousel) {
    //             carousel.dispose();
    //         }
    //     };
    // }, []);

    const onclickNext = () => {
        if (index < 4) {
            setIndex(prevIndex => prevIndex + 1);
        }
        if (index === 4) {
            setIndex(1);
        }
    };

    const onclickPrev = () => {
        if (index > 1) {
            setIndex(prevIndex => prevIndex - 1);
        }
        if (index === 1) {
            setIndex(4);
        }
    };

    return (
        <div id='carouselExampleAutoplaying' className='carousel slide ' data-bs-ride='carousel' data-bs-pause='false'>
            <div className='carousel-inner'>
                <div className='carousel-item active' data-bs-interval='5000'>
                    <div className=' texts_heading_image_4'>
                        <h1 className='text-start m-0'>
                            <span className='text-start texts_heading fw-normal fst-italic'>{t('INDIA', "India's")}</span> {t('LARGEST_PHARMA', '4th Largest Pharma ')}
                            <span className='text-start fw-normal fst-italic'>{t('EXPORTER', 'exporter')}</span>{' '}
                        </h1>
                        <p className=' w-50  image_4_paragraph'>
                            {t(
                                'ABOUT_PHARMA',
                                "Goa is India's 4th largest Pharma Exporter, exemplifying excellence in pharmaceutical manufacturing, contributing significantly to the nation's healthcare sector and global pharmaceutical supply chains."
                            )}
                        </p>
                    </div>
                    <div className='carousal-bg bg4'></div>
                </div>
                <div className='carousel-item' data-bs-interval='5000'>
                    <div className=' texts_heading_image_2'>
                        <h1 className=' m-0'>{t('BUSINESS', 'Business')}</h1>
                        <h1 className=' m-0'>{t('BEACHES', 'Beaches')}</h1>
                        <h1 className='text-start fw-normal fst-italic'>{t('BALANCE', 'Balance')}</h1>
                        <p className=' image_2_paragraph'>
                            {t(
                                'ABOUT_BBB',
                                "Discover a tranquil work-life balance in Goa, amidst lush landscapes and a rich cultural blend. Enjoy a fulfilling professional life against the backdrop of nature's serenity."
                            )}
                        </p>
                    </div>

                    <div className='carousal-bg bg2'></div>
                </div>
                <div className='carousel-item' data-bs-interval='5000'>
                    <div className=' texts_heading_image_3'>
                        <h1 className='text-start m-0 col-7'>
                            <span className='text-start fw-normal fst-italic'>{t('BEST_IN_CLASS', 'Best in class ')}</span> {t('INFRASTRUCTURE', 'Infrastructure')}{' '}
                        </h1>
                        <p className=' w-75  image_3_paragraph'>
                            {t(
                                'ABOUT_INFRASTRUCTURE',
                                'With state-of-the-art facilities, our industrial estates offer best-in-class infrastructure, fostering innovation and efficiency for businesses to thrive in a dynamic and collaborative environment.'
                            )}
                        </p>
                    </div>

                    <div className='carousal-bg bg3'></div>
                </div>
                <div className='carousel-item' data-bs-interval='5000'>
                    <div className=' texts_heading_image_1'>
                        <h1 className=' m-0'>
                            {t('INVEST_GOA', 'Invest Goa 2024 ')}
                            <span className='text-start fw-normal fst-italic'>{t('SUMMIT', 'Summit')}</span>{' '}
                        </h1>
                        <p className=' w-75  image_1_paragraph'>
                            {t(
                                'ABOUT_INVEST_GOA',
                                'Goa opens its doors to endless business opportunities. In collaboration with Goa Investment Promotion & Facilitation Board (Goa-IPB) and Confederation of India and Industry (CII), Goa Industrial Development Corporation.'
                            )}
                        </p>
                    </div>

                    <div className='carousal-bg bg1'></div>
                </div>
            </div>
            <div className='carousal-control-container'>
                {/* <div className=" fs-1 index">
          0{index} <span>/ 04 </span>
        </div> */}

                <button className=' prev py-3 px-3 ' type='button' data-bs-target='#carouselExampleAutoplaying' data-bs-slide='prev' onClick={onclickPrev}>
                    <span className='carousel-control-prev-icon ' aria-hidden='true'></span>
                    {/* <!-- <span className="visually-hidden">Previous</span> --> */}
                </button>
                <button className=' next py-3 px-3' ref={nextBtnRef} type='button' data-bs-target='#carouselExampleAutoplaying' data-bs-slide='next' onClick={onclickNext}>
                    <span className='carousel-control-next-icon' aria-hidden='true'></span>
                    {/* <!-- <span className="visually-hidden">Next</span> --> */}
                </button>
            </div>
        </div>
    );
}
