import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { Card, CardBody } from 'react-bootstrap';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { getFirstErrorKey } from '../../../../utils/utils';
import { cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import { endpoints, estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import { useNavigate } from 'react-router-dom';
import { LOA_APPLICATION_SEARCH_COLUMN, LOA_APPLICATION_SEARCH_FIELDS } from './Loa.const';
import Loading from '../../../../components/portalComponents/Loading.component';

const LoaApplicationSearch = () => {
    const { t } = useTranslation();
    const [modalDetails, setModalDetails] = useState<any>({});
    const [searchData, setSearchData] = useState<[]>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {}
    });
    const { values, errors, setValues, setFieldTouched } = formik;

    const toGetEstateDropdown = async () => {
        const { data } = await POST(estateEndpoints.estateSearch, { RequestInfo: requestInfo() });
        const estateDropdown = data?.estateMasterDetails?.map((val: any) => {
            return { name: val?.estateName, code: val?.uuid };
        });
        setDropdownData((prev: any) => ({ ...prev, estate: estateDropdown }));
    };

    useEffect(() => {
        dprDropdownDataOptions()
            .then(res => {
                setDropdownData(res);
            })
            .catch(err => console.log(err));
        toGetEstateDropdown();
    }, []);
    const handleSearchDpr = async () => {
        try {
            try {
                setIsLoading(true);
                const { data } = await POST(endpoints.loaSearch + `${values.loaNumber ? `?loaApplicationNos=${values.loaNumber}&` : '?'}estateId=${values?.estate?.code || ''}`, {
                    RequestInfo: requestInfo()
                });
                console.log('loaData', data);
                if (!data?.loaDetailsModelList?.length || (data.loaDetailsModelList.length === 1 && Object.keys(data.loaDetailsModelList[0]).length === 0)) {
                    setModalDetails({
                        show: true,
                        title: 'No Data Found',
                        para: t('NO_DATA_AVAILABLE', 'There is no data available for the selected project Type.'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    setSearchData([]);
                    return;
                }
                const tableData =
                    data?.loaDetailsModelList?.length &&
                    data?.loaDetailsModelList?.map((val: any, index: number) => {
                        const projectType = getDropdownSelectedObject(val?.dprHeaderDetails?.projectType, dropdownData['projectType'] || []);

                        return {
                            ...val,
                            loaNumber: val?.applicationNo,
                            projectName: val?.dprHeaderDetails?.projectName,
                            industrialName: val?.dprHeaderDetails?.estateMasterDetails?.estateName,
                            projectType: projectType?.name,
                            estimateAmount: val?.dprHeaderDetails?.estimatedTotalCost,
                            id: val?.uuid,
                            serialNo: index + 1
                        };
                    });
                setSearchData(tableData);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something Went Wrong'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            setIsLoading(false);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const resetLoaFormFields = () => {
        setValues({
            ...values,
            estate: '',
            loaNumber: ''
        });
        setSearchData([]);
    };

    const onRowClick = (row: any) => {
        navigate(`/goa-idc/letter-of-acceptance?applicationNumber=${row?.applicationNo}&isViewOnly=true`);
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={t('SEARCH_LOA', `Search LOA`)} additionalClass='sub-text-style' />
                        <FormikProvider value={formik}>
                            <div>
                                <Card className='card_border my-4 pb-4 pe-4'>
                                    <CardBody>
                                        {LOA_APPLICATION_SEARCH_FIELDS.map((val: any) => {
                                            return (
                                                <>
                                                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                                </>
                                            );
                                        })}
                                    </CardBody>
                                    <div className='d-flex justify-content-end'>
                                        <GenericButtons text={'Reset'} handleClick={resetLoaFormFields} />
                                        <GenericButtons text={'Search'} variant='primary' handleClick={handleSearchDpr} />
                                    </div>
                                </Card>
                                <Card className='card_border row mx-1 p-4 pb-0'>
                                    <div className='font-20 project-primary-color lh-base mb-3'>Search Result</div>
                                    <GenericActionTable
                                        columns={LOA_APPLICATION_SEARCH_COLUMN}
                                        rowData={searchData}
                                        additionalMethod={onRowClick}
                                        searchBarPlaceholder={t('LOA_SEARCH_PARAM', 'Search by LOA No., Project Name...')}
                                    />
                                </Card>
                            </div>
                        </FormikProvider>
                    </div>
                </>
            )}
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
        </>
    );
};

export default LoaApplicationSearch;
