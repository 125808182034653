import { ErrorMessage, Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { formFieldDTO } from './GenericFormCard.constants';
import { useTranslation } from 'react-i18next';
import ToolTipDetails from '../ToolTipFile';

interface textFieldProps {
    isRequired?: boolean;
    field: string;
    title: string;
    type?: string;
    titleCode?: any;
    toolTipDetails?: any;
    isDisabled?: boolean;
    clsName?: string;
    disableCondition?: (val: any, index: any) => boolean;
    index?: any;
}

export default function GenericCommonTextAreaField(props: textFieldProps) {
    const { t } = useTranslation();
    const { values, errors, touched, setFieldValue, setFieldTouched, handleChange, setValues, handleBlur } = useFormikContext<any>();
    const [isDisable, setIsDisable] = useState(false);

    useEffect(() => {
        if (props.disableCondition && props.disableCondition(values, props.index)) {
            setIsDisable(false);
        } else if (props.disableCondition && !props.disableCondition(values, props.index)) {
            setIsDisable(true);
        }
    }, [values, errors]);

    return (
        <>
            <div className='d-flex'>
                <label htmlFor={props.field} className={`${props?.title === '' && 'd-none'} pb-2 file-form-label`} style={props.title.length > 50 ? { width: '95%' } : { width: 'fit-content' }}>
                    {props.titleCode ? t(props.titleCode) : props.title}
                </label>
                {props.isRequired && <span className='mandatory-indicator'>*</span>}
                {props?.toolTipDetails && <ToolTipDetails toolTipDetails={props?.toolTipDetails} />}
            </div>
            <Field
                name={props.field}
                as='textarea'
                style={props.clsName ? {} : { height: '100px' }}
                type='textarea'
                id={props?.field}
                className={`form-control was-validated ${errors.hasOwnProperty(props?.field) ? 'is-invalid' : ''} ${props.clsName ? props.clsName : ''}`}
                disabled={props.isDisabled || isDisable}
            />
            <ErrorMessage name={props.field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
        </>
    );
}
