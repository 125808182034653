import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CHECKLIST_TABLE, paymentColumns } from '../../EstateManagement/Allotment/Allotment.const';
import {
    MORTGAGE_CHANGES_FINANCIAL_DETAILS,
    MORTGAGE_CORRESPONDENCE,
    MORTGAGE_DECLARATION_LABEL,
    MORTGAGE_DETAILS,
    MORTGAGE_EXISTING_MORTGAGE_DETAILS,
    MORTGAGE_LESSEE_DETAILS
} from '../Mortgage.const';
import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import PlotDetails from '../../Migration/pages/PlotDetails';
import _ from 'lodash';
import trash from '../../../../assets/logos/greenTrash.svg';
import GenericFileUploadField from '../../../../components/moduleCommonFormik/GenericFileUpload.component';
import GenericCommonTextField from '../../../../components/moduleCommonFormik/GenericCommonTextField.component';
import Declaration from '../../EstateManagement/Allotment/Declaration';
import { WorkflowPaymentDetails } from '../../EstateManagement/WorkflowPaymentDetails';
import Editor from '../../../../components/ContentEditor';

const MortgageCorrespondence = ({
    dropdownData,
    dropdownDataPlot,
    setDropdownDataPlot,
    ownershipDetails,
    setOwnershipDetails,
    isChecked,
    handleCheckboxChange,
    nicDetails,
    setNicDetails,
    controllingOwnership,
    checkListRowData,
    vacantPlotData,
    rowData,
    setRowData,
    applicationNumber,
    status,
    content,
    setContent,
    paymentRowData,
    disabled,
    designation,
    auctionType,
    fileStoreId,
    isCheckedSubLeaseDetails,
    setIsCheckedSubLeaseDetails,
    handleCheckboxChangeSubLeaseDetails,
    transactionDetails,
    readOnly,
    subleaseOrder,
    bankDetails,
    existingMortgageDetails
}: any) => {
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userDesignationGM = getUserDetails.designation === 'GM';
    const [openSection, setOpenSection] = useState<string>('lesseeDetails');
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);

    const { values, touched, errors, setFieldValue } = useFormikContext<any>();

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const renderLesseeDetails = () => {
        return (
            <div className='px-3'>
                {MORTGAGE_LESSEE_DETAILS.map((val: any, id: number) => (
                    <GenericFormCard {...val} />
                ))}
            </div>
        );
    };

    const renderPlotDetails = () => {
        return (
            <div className='px-3'>
                <PlotDetails dropdownData={dropdownDataPlot} isFieldDisable={disabled} setDropdownData={setDropdownDataPlot} hideCard={true} />
            </div>
        );
    };

    const renderMortgageDetails = () => {
        values.loansSanctioned = values?.bankDetails?.length;
        return (
            <div className='px-3'>
                {MORTGAGE_DETAILS?.map((val: any) => (
                    <GenericFormCard {...val} />
                ))}

                <div className='formTitle m-0 p-3'>Details of Financial Institution/Bank</div>
                <div className='col-6 p-3'>
                    <GenericCommonTextField field='loansSanctioned' title='No of Financial Institution/Banks from where loan(s) is(are) sanctioned' isDisabled={true} />
                </div>
                <form>
                    <FieldArray name='bankDetails'>
                        {({ insert, remove, push }) => (
                            <div>
                                {bankDetails?.length > 0 &&
                                    bankDetails.map((data: any, bank_index: number) => (
                                        <div className='row' key={bank_index}>
                                            <div className='col-sm-12 align-items-center pb-2'>
                                                <div className='formHeading'>{`Financial Institution/Bank - ${bank_index + 1}`}</div>
                                                <div className=' ps-1'>
                                                    {bank_index !== 0 && (
                                                        <div className='d-flex justify-content-end  mx-3'>
                                                            {!applicationNumber && (
                                                                <img
                                                                    style={{ cursor: 'pointer', zIndex: 1, background: '#45852E1F', padding: '6px', height: '35px', borderRadius: '6px' }}
                                                                    onClick={() => remove(bank_index)}
                                                                    src={trash}
                                                                    alt='delete'
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='row d-flex m-0'>
                                                    {MORTGAGE_CHANGES_FINANCIAL_DETAILS[0]?.formData.map((formField: any, id: number) => (
                                                        <div className={`py-3 col-sm-6`} key={id}>
                                                            <label htmlFor={`bankDetails.${bank_index}.${formField.field}`} className='pb-2 file-form-label'>
                                                                {formField.title}
                                                                {formField.isRequired && <span className='mandatory-indicator'> *</span>}
                                                            </label>

                                                            <Field
                                                                name={`bankDetails.${bank_index}.${formField.field}`}
                                                                type={'text'}
                                                                id={`bankDetails.${bank_index}.${formField.field}`}
                                                                className={` form-control was-validated`}
                                                                // onChange={(e: any) => {
                                                                //     const newValue =
                                                                //         formField.type === 'number'
                                                                //             ? parseInt(e.target.value)
                                                                //             : formField.field === 'bankIFSC'
                                                                //             ? e.target.value.toUpperCase()
                                                                //             : e.target.value;
                                                                //     setFieldValue(`bankDetails.${bank_index}.${formField.field}`, newValue);
                                                                //     formField.field === 'bankIFSC' && handleSearchIFSCMortgage(newValue, bank_index);
                                                                // }}
                                                                value={_.get(values, `bankDetails.${bank_index}.${formField.field}`)}
                                                                disabled={true}
                                                            />
                                                            <ErrorMessage name={`bankDetails.${bank_index}.${formField.field}`}>
                                                                {msg => <span className='text-danger font-12'>{msg}</span>}
                                                            </ErrorMessage>
                                                        </div>
                                                    ))}
                                                    <div className='col-6 py-3'>
                                                        <GenericFileUploadField
                                                            field={`bankDetails.${bank_index}.bankLetter`}
                                                            title={`Bank Letter`}
                                                            entityFileData={{ documentNamePrefix: `${bankDetails[bank_index].bankIFSC}_Bank Letter `, documentType: '' }}
                                                            isDisabled={designation !== 'AM' ? true : false}
                                                            isRequired={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {!applicationNumber && (
                                    <div className='ms-3'>
                                        <button className='col-sm-12 mt-2 mb-4  justify-content-center align-items-center noc_add_fields' type='button' onClick={() => push('')}>
                                            + Add Bank
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </FieldArray>
                </form>
                {/* </Card.Body> */}
                {/* </Card> */}

                {existingMortgageDetails?.length > 0 && (
                    <>
                        <div className='formTitle m-0 p-3'>Existing Mortgage Details</div>

                        <form>
                            <FieldArray name='existingMortgageDetails'>
                                {({ insert, remove, push }) => (
                                    <div>
                                        {existingMortgageDetails?.length > 0 &&
                                            existingMortgageDetails.map((data: any, index_p: number) => (
                                                <div className='row' key={index_p}>
                                                    <div className='col-sm-12  align-items-center pb-2'>
                                                        <div className='formHeading'>{`Financial Institution/Bank - ${index_p + 1}`}</div>
                                                        <div className=' ps-1'>
                                                            {MORTGAGE_EXISTING_MORTGAGE_DETAILS.map((val: any, _: number) => (
                                                                <GenericFormCard fieldPath={`existingMortgageDetails.${index_p}`} {...val} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </FieldArray>
                        </form>
                    </>
                )}
            </div>
        );
    };

    const renderDeclarationDetails = () => {
        return (
            <Declaration
                isChecked={isChecked}
                hideCard={true}
                declarationList={MORTGAGE_DECLARATION_LABEL}
                handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                fileStoreId={fileStoreId}
            ></Declaration>
        );
    };

    const renderPaymentDetails = () => {
        return (
            <>
                <div className='px-4 py-4'>
                    <WorkflowPaymentDetails transactionDetails={transactionDetails} paymentColumns={paymentColumns} paymentRowData={paymentRowData} />
                </div>
            </>
        );
    };
    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setContent} content={content} readOnly={readOnly} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                Condition/Check list
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {MORTGAGE_CORRESPONDENCE.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A1 - Lessee Details' && openSection === 'lesseeDetails' && renderLesseeDetails()}
                                {val.title === 'A2 - Mortgage Details' && openSection === 'mortgageDetails' && renderMortgageDetails()}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                {val.title === 'C - Self Declaration' && openSection === 'selfDeclaration' && renderDeclarationDetails()}
                                {val.title === 'D - Payment Details' && openSection === 'paymentDetails' && renderPaymentDetails()}
                                {val.title === 'E - Mortgage Notes' && openSection === 'mortgageNotes' && renderNoteDetails()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default MortgageCorrespondence;
