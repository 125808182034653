import { Card, Form } from 'react-bootstrap';
import { useState } from 'react';
import PlotDetails from '../../../Migration/pages/PlotDetails';
import {
    ALLOTMENT_CORRESPONDENCE,
    APPLICANT_DETAILS_I,
    APPLICANT_DETAILS_II,
    CHECKLIST_TABLE,
    DECLARATION_DETAILS,
    ENTERPRISE_DETAILS,
    PROJECT_DETAILS,
    PROMOTER_DETAILS,
    SUBLEASE_OTHER_DETAIL,
    SUB_LESSEE_DETAILS_II,
    paymentColumns
} from '../../Allotment/Allotment.const';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import Editor from '../../../../../components/ContentEditor';
import { POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import GenericCommonTextField from '../../../../../components/moduleCommonFormik/GenericCommonTextField.component';
import _ from 'lodash';
import Declaration from '../../Allotment/Declaration';
import ApplicantDetails from '../../Allotment/ApplicantDetails';
import EnterpriseDetails from '../../Allotment/EnterpriseDetails';
import { LESSE_APPLICANT_DETAILS_I, plotOtherField, SUB_LEASE_CORRESPONDENCE, SUB_LEASE_ORDER_CORRESPONDENCE } from '../SubLeaseConst';
import LesseeDetails from '../LesseeDetails';
import SubLesseeDetailsSecond from '../SubLesseeDetailsA1';
import GenericFileUploadField from '../../../../../components/moduleCommonFormik/GenericFileUpload.component';
import { WorkflowPaymentDetails } from '../../WorkflowPaymentDetails';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';

export const SubLeaseWorkflowCorrespondence = ({
    dropdownData,
    dropdownDataPlot,
    setDropdownDataPlot,
    ownershipDetails,
    setOwnershipDetails,
    isChecked,
    handleCheckboxChange,
    nicDetails,
    setNicDetails,
    controllingOwnership,
    checkListRowData,
    vacantPlotData,
    rowData,
    setRowData,
    applicationNumber,
    status,
    content,
    setContent,
    paymentRowData,
    disabled,
    designation,
    auctionType,
    fileStoreId,
    isCheckedSubLeaseDetails,
    setIsCheckedSubLeaseDetails,
    handleCheckboxChangeSubLeaseDetails,
    transactionDetails,
    readOnly,
    subleaseOrder
}: any) => {
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userDesignationGM = getUserDetails.designation === 'GM';
    const [openSection, setOpenSection] = useState<string>('subLeasetDetails');
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);

    const { values, touched, errors, setFieldValue } = useFormikContext<any>();

    const SUB_LEASE_CORRESPONDENCE_ALL =
        status === 'PENDING' || status === 'SCRUTINY_DONE'
            ? [
                  ...(!subleaseOrder ? SUB_LEASE_CORRESPONDENCE : SUB_LEASE_ORDER_CORRESPONDENCE),
                  {
                      title: !subleaseOrder ? 'F - Sublease Order Notes' : 'E - Sublease Order Notes',
                      field: 'subleaseOrderNotes'
                  }
              ]
            : status === 'REJECTION_PENDING'
            ? [
                  ...(!subleaseOrder ? SUB_LEASE_CORRESPONDENCE : SUB_LEASE_ORDER_CORRESPONDENCE),
                  {
                      title: !subleaseOrder ? 'F - Rejection Order' : 'E - Sublease Order Notes',
                      field: 'subleaseOrderNotes'
                  }
              ]
            : !subleaseOrder
            ? SUB_LEASE_CORRESPONDENCE
            : SUB_LEASE_ORDER_CORRESPONDENCE;

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const renderDeclarationDetails = () => {
        const declarationList = [
            '(i) the information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed herein.',
            '(ii) it has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy or liquidation proceeding.',
            '(iii) The provision allotment order or the lease dead,as the case may be,shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the information submitted under or along with this application.'
        ];
        return <Declaration isChecked={isChecked} hideCard={true} declarationList={declarationList} handleCheckboxChange={(e: any) => handleCheckboxChange(e)} fileStoreId={fileStoreId}></Declaration>;
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setContent} content={content} readOnly={readOnly} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    const renderPaymentDetails = () => {
        return (
            <>
                <div className='px-4 py-4'>
                    <WorkflowPaymentDetails transactionDetails={transactionDetails} paymentColumns={paymentColumns} paymentRowData={paymentRowData} />
                </div>
            </>
        );
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numericValue = e.target.value.replace(/[^0-9.-]/g, '');
        e.target.value = numericValue;
        setFieldValue('highestBidRate', numericValue);
    };

    const newApplicantDetailII = APPLICANT_DETAILS_II.map((val: any) => ({
        ...val,
        formData: val.formData?.map((field: any) => {
            const { isDisabled, ...rest } = field;
            return rest;
        })
    }));

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                Condition/Check list
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {SUB_LEASE_CORRESPONDENCE_ALL.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A1 - Sub-Lessee Details' && openSection === 'subLeasetDetails' && (
                                    <ApplicantDetails
                                        dropdownOptions={dropdownData}
                                        setOwnershipDetails={setOwnershipDetails}
                                        controllingOwnershipResponse={controllingOwnership}
                                        ownershipDetails={ownershipDetails}
                                        showOwnershipModal={showOwnershipModal}
                                        setShowOwnershipModal={setShowOwnershipModal}
                                        applicantDetails1={LESSE_APPLICANT_DETAILS_I}
                                        applicantDetails2={SUB_LESSEE_DETAILS_II}
                                        promoterDetails={SUBLEASE_OTHER_DETAIL}
                                        hideBankDetails={true}
                                        isWorkflow={true}
                                    ></ApplicantDetails>
                                )}
                                {val.title === 'A2 - Lessee Details' && openSection === 'lesseDetails' && (
                                    <Card.Body>
                                        <LesseeDetails />
                                    </Card.Body>
                                )}
                                {val.title === 'A3 - Sub-Lease Details' && openSection === 'subLeasetDetails2' && (
                                    <Card.Body>
                                        <SubLesseeDetailsSecond
                                            isCheckedSubLeaseDetails={isCheckedSubLeaseDetails}
                                            setIsCheckedSubLeaseDetails={setIsCheckedSubLeaseDetails}
                                            handleCheckboxChange={handleCheckboxChangeSubLeaseDetails}
                                        />
                                    </Card.Body>
                                )}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && (
                                    <>
                                        <Card.Body>
                                            {/* <div className='typeof-plot-details ps-2 fs-5 mt-4 mb-2'>Applied Plot</div> */}
                                            <PlotDetails dropdownData={dropdownDataPlot} isFieldDisable={!values.isUpdatable} setDropdownData={setDropdownDataPlot} hideCard={true} />
                                            {plotOtherField?.map((val: any) => (
                                                <GenericFormCard {...val} />
                                            ))}
                                        </Card.Body>
                                    </>
                                )}
                                {val.title === 'C - Enterprise Details' && openSection === 'enterpriseDetails' && (
                                    <EnterpriseDetails
                                        enterpriseDetails={ENTERPRISE_DETAILS}
                                        enterpriseProjectDetails={PROJECT_DETAILS}
                                        declarationDetails={DECLARATION_DETAILS}
                                        dropdownOptions={dropdownData}
                                        nicDetails={nicDetails}
                                        setNicDetails={setNicDetails}
                                        isDisabled={!values.isUpdatable}
                                    ></EnterpriseDetails>
                                )}
                                {!subleaseOrder && val.title === 'D - Self Declaration' && openSection === 'selfDeclaration' && renderDeclarationDetails()}
                                {(!subleaseOrder ? val.title === 'E - Payment Details' : val.title === 'D - Payment Details') && openSection === 'paymentDetails' && renderPaymentDetails()}
                                {(!subleaseOrder ? val.title === 'F - Sublease Order Notes' : val.title === 'E - Sublease Order Notes') && openSection === 'subleaseOrderNotes' && renderNoteDetails()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};
