import React, { useEffect, useState } from 'react';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import HeaderComponent from '../../../../components/header/Header.component';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { Formik, FormikProvider, useFormik, useFormikContext } from 'formik';
import { Card } from 'react-bootstrap';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { epochToDate, getFirstErrorKey } from '../../../../utils/utils';

import { GSR_MASTER_TABLE_COLUMNS, GSR_POPUP_FIELDS } from './GSR.const';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import { useNavigate } from 'react-router-dom';
import { check, cross, DeleteWarning } from '../../../../utils/Logo';

import PopUps from '../../../../components/PopUps';
import { GSR_MASTER_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import DeletePopUpModal from '../../../../components/moduleCommonFormik/DeletePopUp.component';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/portalComponents/Loading.component';

export default function GsrScreen() {
    const [showPopUp, setShowPopUp] = useState<any>(false);
    const [isEditing, setIsEditing] = useState<any>(false);
    const [gsrData, setGsrData] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [openIndex, setOpenIndex] = useState<any>(null);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [rowDelId, setRowDelId] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { t } = useTranslation();

    const navigate = useNavigate();
    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: showPopUp ? GSR_MASTER_VALIDATION : null
    });
    const { values, setValues, setTouched, setFieldTouched, errors } = formik;

    const renderButton = () => {
        return <>+ Add</>;
    };

    useEffect(() => {
        fetchGsrData();
        dprDropdownDataOptions()
            .then((dropdownOption: any) => {
                setDropdownData(dropdownOption);

                const currentYear = new Date().getFullYear();
                const yearDropdown: { name: string; code: string }[] = [];

                for (let year = currentYear - 3; year <= currentYear + 1; year++) {
                    yearDropdown.push({ name: year.toString(), code: year.toString() });
                }
                setDropdownData((prev: any) => ({ ...prev, gsrYear: yearDropdown }));
            })
            .catch(error => {
                let errMsg = 'Something went wrong';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            });
    }, []);
    const fetchGsrData = async () => {
        try {
            setIsLoading(true);
            const gsrResponse = await POST('/idc-works-service/gsr/_search?isActive=true', { RequestInfo: requestInfo() });
            // const Data = gsrResponse?.data?.gsrMasterModel;
            const gsrResData = gsrResponse?.data?.gsrMasterModel.map((val: any, ind: number) => ({
                gsrName: val?.gsrName,
                gsrYear: val?.gsrYear,
                date: epochToDate(val?.auditDetails?.createdTime),
                rowId: val?.uuid,
                isActive: val?.isActive,
                auditDetails: val?.auditDetails
            }));
            setGsrData(gsrResData);
            setIsLoading(false);
        } catch (e: any) {
            let errMsg = 'Something went wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handlePrimaryAction = async () => {
        const updatePayload = {
            RequestInfo: requestInfo(),
            gsrMaster: {
                uuid: values?.rowId,
                gsrYear: values?.gsrYear?.code,
                gsrName: values?.projectType?.name,
                isActive: values?.isActive,
                auditDetails: values?.auditDetails
            }
        };
        const payload = {
            RequestInfo: requestInfo(),
            gsrMaster: {
                gsrYear: values?.gsrYear?.code,
                gsrName: values?.projectType?.name
            }
        };
        try {
            await GSR_MASTER_VALIDATION.validate(values, { abortEarly: false });
            try {
                if (!isEditing) {
                    await POST(endpoints.createGsr, payload);
                    setModalDetails({ show: true, title: 'Success!', para: 'GSR Item Added Successfully', img: check, type: 'added', reUpload: 'OK' });
                } else {
                    await POST(endpoints.updateGsr, updatePayload);
                    setModalDetails({ show: true, title: 'Success!', para: 'GSR Item Updated Successfully', img: check, type: 'added', reUpload: 'OK' });
                }
                const newUuid = uuidv4();
                const gsrRowData: any = {
                    gsrYear: values?.gsrYear?.code,
                    gsrName: values?.projectType?.name,
                    rowId: isEditing ? values.rowId : newUuid,
                    isActive: true
                };
                if (isEditing) {
                    const updatedData = gsrData.map((data: any) => {
                        if (data.rowId === isEditing) return gsrRowData;
                        else return data;
                    });
                    setGsrData([...updatedData]);
                } else {
                    setGsrData([...gsrData, gsrRowData]);
                }
                fetchGsrData();
                setShowPopUp(false);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                await setFieldTouched(errorField, true, true);
            }
            let errMsg = 'Please fill in all the required details!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        setShowPopUp(false);
    };

    const addGsrMaster = () => {
        const val = _.cloneDeep(values);

        setIsEditing(false);
        const keysToDelete = ['projectType', 'gsrYear'];
        keysToDelete.forEach(key => {
            delete val[key];
        });
        setValues({ ...val });
        setTouched({});
        setShowPopUp(true);
        setDisabled(false);
        setOpenIndex(null);
    };

    const gsrBookFields = fieldDisabled
        ? GSR_POPUP_FIELDS.map((val: any) => ({
              ...val,
              formData: [
                  ...val.formData.map((value: any) => ({
                      ...value,
                      isDisabled: true
                  }))
              ]
          }))
        : GSR_POPUP_FIELDS;

    const onEdit = (data: any, status: boolean = false) => {
        // setFieldDisabled(false);
        const detailObj: any = gsrData.find((item: any, _: number) => item.rowId === data.rowId);
        const index: any = gsrData.findIndex((item: any, _: number) => item.rowId === data.rowId);
        setOpenIndex(index > -1 ? index + 1 : null);
        if (!detailObj) return;
        const formData = {
            gsrYear: { code: detailObj?.gsrYear, name: detailObj?.gsrYear },
            projectType: { code: detailObj?.gsrName, name: detailObj?.gsrName },
            rowId: detailObj.rowId ? detailObj.rowId : null,
            isActive: detailObj?.isActive,
            auditDetails: detailObj?.auditDetails
        };

        setIsEditing(detailObj.rowId);
        // setValues({ ...values, ...formData });
        setValues((prevVal: any) => ({ ...prevVal, ...formData }));
        setShowPopUp(true);
        setDisabled(status);
    };
    const onView = (status: boolean, data: any) => {
        setDisabled(status);
        setFieldDisabled(true);
        onEdit(data, status);
    };
    const onclickGsrName = (data: any) => {
        navigate('/goa-idc/gsr-master-details', { state: { data: data } });
    };

    const onDeleteGsrData = async (rowId: any) => {
        setShowDeleteModal(true);
        setRowDelId(rowId);
    };
    const handleDelete = async (rowId: any) => {
        const deleteObj = gsrData.find((val: any) => val?.rowId === rowId); //will get single object using find
        const payload = {
            RequestInfo: requestInfo(),
            gsrMaster: {
                uuid: deleteObj?.rowId,
                gsrYear: deleteObj?.gsrYear,
                gsrName: deleteObj?.gsrName,
                isActive: false,
                auditDetails: deleteObj?.auditDetails
            },
            isDelete: true
        };
        setShowDeleteModal(false);

        try {
            await POST(endpoints.updateGsr, payload);
            const updatedData = gsrData.filter((val: any) => val?.rowId !== rowId);
            setShowDeleteModal(false);
            fetchGsrData();
            setGsrData(updatedData);
        } catch (e: any) {
            // let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            let errMsg = 'GSR Details is already in used';
            setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const resetForm = () => {
        setValues({
            ...values,
            gsrYear: '',
            projectType: ''
        });
    };
    console.log('values??', values);
    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader text={'GSR Master'} />
                <div className='col-12 form-section'>
                    <FormikProvider value={formik}>
                        <Card className='card_border'>
                            <Card.Body>
                                <div className='row'>
                                    <OwnershipTable
                                        column={GSR_MASTER_TABLE_COLUMNS}
                                        renderButton={renderButton}
                                        onClickPopUp={addGsrMaster}
                                        tableRowData={gsrData}
                                        onEdit={onEdit}
                                        additionalMethod={onclickGsrName}
                                        deleteOwnershipDetails={onDeleteGsrData}
                                        updateOwnershipDataStatus={onView}
                                        searchBarPlaceholder={'Search'}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                        <GenericPopupModal
                            size={'lg'}
                            isVisible={showPopUp}
                            primaryAction={handlePrimaryAction}
                            secondaryAction={() => {
                                resetForm();
                                setFieldDisabled(false);
                            }}
                            onClose={() => {
                                setFieldDisabled(false);
                                setShowPopUp(false);
                            }}
                            isPrimaryBtn={!fieldDisabled ? true : false}
                            isSecondaryBtn={!fieldDisabled ? true : false}
                            primaryBtnTitle={t('SAVE', 'Save')}
                            secondaryBtnTitle={t('RESET', 'Reset')}
                            title={`${openIndex ? (disabled ? 'View' : 'Edit') : 'Create'} GSR`}
                            children={
                                <>
                                    {gsrBookFields?.map((val: any) => {
                                        return <GenericFormCard {...val} dropdownOptions={dropdownData} styles={{ background: '#F5F5F6', padding: '10px 16px' }} />;
                                    })}
                                </>
                            }
                        />
                        <DeletePopUpModal
                            secondaryAction={() => {
                                setShowDeleteModal(false);
                            }}
                            primaryAction={() => {
                                handleDelete(rowDelId);
                            }}
                            isVisible={showDeleteModal}
                            title={'Delete File?'}
                            img={DeleteWarning}
                            primaryBtnTitle={t('DELETE', 'Delete')}
                            secondaryBtnTitle={t('CANCEL', 'Cancel')}
                            children={
                                <>
                                    <div className='p-4' style={{ backgroundColor: '#F5F5F6' }}>
                                        <div className='font-16 fw-bold lh-22'>
                                            Are you sure you want to delete this file? <br />
                                            You can't undo this action!
                                        </div>
                                    </div>
                                </>
                            }
                        />
                    </FormikProvider>
                </div>
            </div>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
        </>
    );
}

// useEffect(() => {
//     const typeDropdown = [
//         { name: 'Tender', code: 'Tender' },
//         { name: 'Linkedin', code: 'Linkedin' },
//         { name: 'Instagram', code: 'Instagram' },
//         { name: 'Site Plan', code: 'SitePlan' },
//         { name: "What's New", code: 'WhatNew' }
//     ];

//     setDropdownData({ type: typeDropdown });
//     setFieldValue('type', { name: 'Tender', code: 'Tender' });
// }, []);
