

export const PAYMENT_DATA: any = [
    {
        heading: 'Amount Details',
        paymentdata: [
            {
                title: 'Infrastructure Tax',
                value: '0',
            },
            {
                title: 'GST on Infrastructure Tax (@18%)',
                value: '0',
            },
            {
                title: 'Construction License Fee',
                value: '0',
            },
            {
                title: 'GST on Construction License Fee (@18%)',
                value: '0',
            },
            {
                title: 'Labor Cess',
                value: '0',
            },
            {
                title: 'GST on Labor Cess (@18%)',
                value: '0',
            },
        ]

    }]