import axios from 'axios';
import { devUrl } from '../modulesServices/userManagement.api';

let siteUrl = (process.env && process.env.REACT_APP_BASE_URL) || '';
const baseRoute = `${devUrl}`;

export const mainRoute = `https://idc-stage.goa.gov.in`;

const scrutinyRoute = `https://idc-stage.goa.gov.in`; //Dont Changes for DEV Deployment
// const scrutinyRoute = `http://20.0.129.245:8080`;

const getHeaders = () => {
    return {
        headers: { 'Content-Type': 'application/json' }
    };
};

const getFormFileHeaders = () => {
    return {
        headers: { 'Content-Type': 'multipart/form-data' }
    };
};

const getEmployeeSearch = (payload: any) => {
    return axios.post(`${baseRoute}/egov-hrms/employees/_search?roles=ARCHITECT&tenantId=ga`, payload, getHeaders());
};

const createTechnicalClearance = (payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/${!!payload?.BPA?.id ? '_update' : '_create'}`, payload, getHeaders());
};

const createScrutinize = (payload: any) => {
    return axios.post(`${scrutinyRoute}/edcr/rest/dcr/scrutinize`, payload, getFormFileHeaders());
};

const getBpaSearch = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/_search?applicationNo=${applicationNumber}&tenantId=ga`, payload, getHeaders());
};

const getBpaActions = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v1/wf/_action?businessId=${applicationNumber}&tenantId=ga`, payload, getHeaders());
};

const getUserData = (payload: any) => {
    return axios.post(`${baseRoute}/user/v1/_search`, payload, getHeaders());
};

const getAuditTrail = (payload: any, id: string, tenantId: string) => {
    return axios.post(`${baseRoute}/egov-workflow-v2/egov-wf/process/_search?businessIds=${id}&history=true&tenantId=${tenantId}`, payload, getHeaders());
};

const getSharedScrutinyPlans = (payload: any, uuid: string) => {
    return axios.post(`${mainRoute}/bpa-services/v2/dcr/share/_search?archId=${uuid}`, payload, getHeaders());
};

const getScrutinyFile = (payload: any) => {
    return axios.post(`${scrutinyRoute}/edcr/rest/dcr/convert`, payload, getFormFileHeaders());
};

const getProgressDetails = (payload: any, id: string, tenantId: string) => {
    return axios.post(`${baseRoute}/egov-workflow-v2/egov-wf/process/progress-bar?businessIds=${id}&tenantId=${tenantId}`, payload, getHeaders());
};

const getLesseeSearch = (payload: any, entityName: string) => {
    return axios.post(`${mainRoute}/bpa-services/enterprise/util?lesseeName=${entityName}`, payload, getHeaders());
};

const shareLessee = (payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/dcr/share/_create`, payload, getHeaders());
};

const mdmsSearch = (payload: any) => {
    return axios.post(`${baseRoute}/egov-mdms-service/v1/_search`, payload, getHeaders());
};

const applyTechnicalClearance = (payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/apply/technicalClearance`, payload, getHeaders());
};

const applyConstructionLicence = (payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/apply/constructionLicence`, payload, getHeaders());
};

const fetchEstimatePayDetails = (payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/_estimate`, payload, getHeaders());
};

const getBpaSearchForRefNo = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/_search?applicationNo=${applicationNumber}&tenantId=ga`, payload, getHeaders());
};

const createFieldInspection = (payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/_createFieldInspectionReport`, payload, getHeaders());
};

const searchFieldInspection = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/_searchFieldInspectionReport?applicationNo=${applicationNumber}&tenantId=ga`, payload, getHeaders());
};

export const getTransferCertificate = (uuid: string, plotUuid: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/findCertificate?applicationType=OCCUPANCY_CERTIFICATE&lesseeuuid=${uuid}&plotId=${plotUuid}`, payload, getHeaders());
};

export const getTransferBuildUpArea = (uuid: string, appliedPlotId: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/findUtilization?applicationType=OCCUPANCY_CERTIFICATE&lesseeuuid=${uuid}&plotId=${appliedPlotId}`, payload, getHeaders());
};

const searchAllDocuments = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/document/_search?applicationNo=${applicationNumber}`, payload, getHeaders());
};

const searchPaymentDetails = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/payment/_search?applicationNo=${applicationNumber}&tenantId=ga`, payload, getHeaders());
};

const fetchBillDetails = (applicationNumber: string, payload: any) => {
    return axios.post(`${baseRoute}/billing-service/bill/v2/_fetchbill?consumerCode=${applicationNumber}&tenantId=ga&businessService=BPA.NC_APP_FEE`, payload, getHeaders());
};

const addTransaction = (payload: any, isUpdate: boolean = false, urlParam: any) => {
    return axios.post(`${baseRoute}/pg-service/transaction/v1/${!!isUpdate ? '_update?' + urlParam : '_create'}`, payload, getHeaders());
};

const bpaCheckList = (payload: any, applicationNo: string, service: string) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa-checklist/_search?applicationNo=${applicationNo}&businessServices=${service}`, payload, getHeaders());
};

const updateFieldInspection = (payload: any, isUpdate: boolean = false) => {
    return axios.post(`${mainRoute}/bpa-services/v1/fieldInspection/${!!isUpdate ? '_update?' : '_create'}`, payload, getHeaders());
};

const searchFieldInspectionData = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v1/fieldInspection/_search?applicationNo=${applicationNumber}`, payload, getHeaders());
};

const digitalSignDocument = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/digitalsign/_sign?applicationNo=${applicationNumber}`, payload, getHeaders());
};

const updatePayment = (applicationNumber: string, transactionDate: number, transactionNumber: string, payload: any) => {
    return axios.post(
        `${mainRoute}/bpa-services/v2/bpa/payment/_update?applicationNo=${applicationNumber}&transactionNumber=${transactionNumber}&transactionDate=${transactionDate}`,
        payload,
        getHeaders()
    );
};

const manageQuestionnaire = (payload: any, isUpdate: boolean = false) => {
    return axios.post(`${mainRoute}/bpa-services/v1/bpa/questionnaire/${!!isUpdate ? '_update?' : '_create'}`, payload, getHeaders());
};

const searchQuestionnaire = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v1/bpa/questionnaire/_search?applicationNo=${applicationNumber}`, payload, getHeaders());
};

const markAsCompleteFlow = (applicationNumber: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v1/bpa/questionnaire/_markAsCompleted?applicationNo=${applicationNumber}`, payload, getHeaders());
};

const generateDemand = (applicationNumber: string, gateway: string, payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/_generateDemand?applicationNo=${applicationNumber}&selectedGateway=${gateway}`, payload, getHeaders());
};

const generateCertificate = (payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/_generateCertificate`, payload, getHeaders());
};

const getRenewalCL = (payload: any) => {
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/apply/renewal/constructionLicence`, payload, getHeaders());
};

export const getBuiltUpArea = (plotId: any, entityId: any, payload: any) => {
    //api call for displaying built-up area in the dashboard
    return axios.post(`${mainRoute}/bpa-services/v2/bpa/findOcBuildupArea?plotId=${plotId}&lesseeuuid=${entityId}`, payload, getHeaders());
};

const TechnicalServices = {
    getEmployeeSearch,
    createTechnicalClearance,
    createScrutinize,
    getBpaSearch,
    getBpaActions,
    getUserData,
    getAuditTrail,
    getScrutinyFile,
    getProgressDetails,
    getLesseeSearch,
    shareLessee,
    getSharedScrutinyPlans,
    mdmsSearch,
    applyTechnicalClearance,
    fetchEstimatePayDetails,
    getBpaSearchForRefNo,
    applyConstructionLicence,
    createFieldInspection,
    searchFieldInspection,
    searchAllDocuments,
    searchPaymentDetails,
    fetchBillDetails,
    addTransaction,
    bpaCheckList,
    updateFieldInspection,
    searchFieldInspectionData,
    updatePayment,
    digitalSignDocument,
    manageQuestionnaire,
    searchQuestionnaire,
    markAsCompleteFlow,
    generateDemand,
    generateCertificate,
    getRenewalCL
};

export default TechnicalServices;
