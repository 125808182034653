import React from "react";
import { Image } from "react-bootstrap";
import { airplaneicon, fullScreen, industrialestateicon, kokanrailicon, map2, nhicon, seaporticon, southcenrailwayicon, stateboundaryicon, stateboundarylineicon } from "../../utils/Images";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { POST, endpoints, getFileUrl } from "../../services/modulesServices/userManagement.api";
import { useTranslation } from "react-i18next";
import "./commonStyles.scss";

export default function MapInfoComponent() {
    const { t } = useTranslation();

    const position: any = [15.4954, 73.8354];
    const defaultProps = {
        center: {
            lat: 15.4954,
            lng: 73.8354,
        },
        zoom: 11,
    };
    const maptxt = [
        { titleCode: "AIRPORTS", text: "Airports", imageSrc: airplaneicon },
        { titleCode: "SEAPORTS", text: "Seaports", imageSrc: seaporticon },
        { titleCode: "KONKAN_RAILWAYS", text: "Kokan railways", imageSrc: kokanrailicon },
        { titleCode: "SOUTH_CENTRAL_RAILWAYS", text: "South Central Railways", imageSrc: southcenrailwayicon },
        { titleCode: "NATIONAL_HIGHWAYS", text: "National Highway", imageSrc: nhicon },
        { titleCode: "INDUSTRIAL_ESTATE", text: "Industrial Estate", imageSrc: industrialestateicon },
        { titleCode: "STATE_BOUNDARY", text: "State Boundary", imageSrc: stateboundarylineicon },
        { titleCode: "STATE_BOUNDARY", text: "State Boundary", imageSrc: stateboundaryicon },
    ];
    const AnyReactComponent = ({ text }: any) => (
        <div>
            {text}
            <i className="fa fa-map" />
        </div>
    );

    const onViewFullScreen = async () => {
        try {
            const { data } = await POST(endpoints.getCommonDocument + `?documentCategories=IDC_PORTAL&documentNames=Goa_Industrial_Estate_Locations_MAP`, { RequestInfo: {} });
            const fileUrl = await getFileUrl(data.documentDetails[0].documentReferenceId);
            window.open(fileUrl, "_blank");
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="container-fluid" style={{ background: "#F5F5F6", position: "relative" }}>
            <div className="px-5 ">
                <div className="py-5 text-start ">
                    <h2>{t("MAP_INFO", "Map Information")}</h2>
                </div>
            </div>

            <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                        <TransformComponent>
                            <img src={map2} alt="test" height={500} style={{ width: "100%" }} />
                        </TransformComponent>
                    </>
                )}
            </TransformWrapper>
            <Image src={fullScreen} height="50" style={{ bottom: "25%", right: "5%", position: "absolute", cursor: "pointer" }} onClick={onViewFullScreen} />
            {/* <MapContainer center={[15.4954, 73.8354]} zoom={13} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='<a href="https://www.openstreetmap.org/copyright"></a>'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[15.4954, 73.8354]}>
                        <Popup className="font-inter-reg">
                            Panji <br/>
                            Lorem ipsum jfbjrfh jhjfrjf
                            ehfbrhbfrh rfrfrh
                        </Popup>
                    </Marker>
                </MapContainer> */}
            {/* <div style={{ height: '80vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyCUbBDiWQKbnE_SknmD88LDx1Fc4_jvS0g" }}
                    yesIWantToUseGoogleMapApiInternals
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    <AnyReactComponent
                        lat={15.4954}
                        lng={73.8354}
                        text="My Marker"
                    />
                </GoogleMapReact>
            </div> */}
            <div className="bg-white">
                <div className="px-5 bg-white">
                    <div className="row py-5">
                        <div className="col-sm-12 text-start fs-6 font-karla-med" style={{ paddingRight: "80px" }}>
                            {maptxt.map((data, index) => (
                                <div className="d-inline me-2">
                                    <span style={{ display: "inline-block", width: "10px", height: "10px", borderRadius: "3px", marginRight: "5px" }}></span>
                                    <img className="me-2" style={{ fontSize: "16px", fontWeight: 400, letterSpacing: "-0.48px" }} src={data.imageSrc} alt={`Image of ${data.imageSrc} `} />
                                    {data.titleCode ? t(data.titleCode) : data.text}
                                </div>
                            ))}
                        </div>
                        <div className="col-sm-3"></div>
                        {/* <div className="col-sm-3 font-karla-reg" style={{ color: "#8B8B8B" }}>
                            <div className="text-start">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
