import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { NocClarificationModal } from './NOC_ClarificationModal';
import GenericFileUploadField from '../../../../components/moduleCommonFormik/GenericFileUpload.component';
import { Field, useFormikContext } from 'formik';
import { formatedDocumentDetails } from '../../../../utils/utils';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { USER_TYPE } from '../../../../utils/constants';
import PopUps from '../../../../components/PopUps';
import { cross } from '../../../../utils/Logo';

interface clarificationProps {
    showResetSubmitBtn: any;
    isReset: boolean;
    nocDetails: any;
    clarificationHistoryDetails: any;
    isReply?: any;
    setIsReply?: any;
    applicationNo?: any;
}

const ClarificationSection = (props: clarificationProps) => {
    const { showResetSubmitBtn, nocDetails, clarificationHistoryDetails, isReply, setIsReply, applicationNo } = props;
    const { t } = useTranslation();

    const [showActionModal, setShowActionModal] = useState<boolean>(false);
    const [nocDocumentDetails, setNocDocumentDetails] = useState<any>([]);
    const [documents, setDocuments] = useState([]);
    const [modalDetails, setModalDetails] = useState({});
    const { setValues, values, setFieldValue } = useFormikContext<any>();
    const userType = JSON.parse(localStorage.getItem('type') || '{}');

    const handleOnClick = () => {
        setShowActionModal(true);
    };

    useEffect(() => {
        const nocDocumentFileStoreIds = nocDetails
            .flatMap((val: any) => val.comments.flatMap((msg: any) => msg.replies.map((com: any) => com.nocDocumentFileStoreId)))
            .filter((id: any) => id !== null);
        const fetchData = async () => {
            try {
                const updatedDetails = await nocDocDetails(nocDocumentFileStoreIds);
                setNocDocumentDetails(updatedDetails);
            } catch (error) {
                let errMsg = t('ERROR_WHILE_FETCHING_DATA', 'Error while fetching data');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };
        fetchData();
    }, [nocDetails]);

    const nocDocDetails = async (nocDocumentFileStoreIds: any) => {
        const documentDetailsArray: any = {};
        try {
            const nocData = await formatedDocumentDetails(nocDocumentFileStoreIds.join(), true);
            nocData.forEach((doc: DocumentInfo) => (documentDetailsArray[`docDetails_${doc.referenceId}`] = doc));
            setValues({ ...documentDetailsArray, ...values });

            const updatedDetails = nocDetails.map((val: any) => ({
                ...val,
                comments: val.comments.map((msg: any, id: number) => ({
                    ...msg,
                    replies: msg.replies.map((com: any, id: number) => {
                        const matchedDocItem = nocData.find((docItem: any) => com.nocDocumentFileStoreId === docItem.referenceId);
                        if (matchedDocItem) {
                            return {
                                ...com,
                                documentDetails: { ...matchedDocItem }
                            };
                        }
                        return com;
                    })
                }))
            }));
            return updatedDetails;
        } catch (error) {}
    };

    const onChangeReply = (event: any, replyFromInput: any, index: number) => {
        const tempVal = {
            parentId: replyFromInput.id,
            nocType: replyFromInput.nocType,
            comment: event.target.value,
            docReferenceName: `file${replyFromInput.nocType}${index}`
        };
        setFieldValue('replyPayload', { ...values.replyPayload, [replyFromInput.id]: tempVal });
    };

    const handleOnReplyClick = (main_id: number, id: number) => {
        setIsReply({ ...isReply, [`key_${main_id}_${id}`]: true });
        showResetSubmitBtn(true, nocDetails[main_id].comments[id]);
        const nocDetailsObject = nocDetails[main_id];
        const nocDetailsComment = nocDetailsObject.comments[id];
        if (nocDetailsComment.replies.length === 0 || nocDetailsComment.replies[nocDetailsComment.replies.length - 1].commentedBy === 'Area Manager') {
            setFieldValue('replyCountLessee', +values.replyCountLessee + 1);
        }
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <div className='p-2 font-inter'>
                <div className='d-flex flex-row-reverse align-items-center justify-content-between p-'>
                    <GenericButtons size='sm' text={t('HISTORY', ' History')} handleClick={handleOnClick} />
                    <GenericPopupModal
                        title={`${t('CLARIFICATION_HISTORY', 'Clarification History')}`}
                        subTitle={`${t('APPLICATION_NO', 'Application No.')}: ${applicationNo}`}
                        size='xl'
                        isPrimaryBtn={false}
                        isSecondaryBtn={false}
                        primaryAction={() => {}}
                        secondaryAction={() => {
                            setShowActionModal(false);
                        }}
                        isVisible={showActionModal}
                        children={<NocClarificationModal clarificationHistoryDetails={clarificationHistoryDetails} />}
                        onClose={() => {
                            setShowActionModal(false);
                        }}
                        class='clarification-modal'
                    />
                </div>

                <div className='font-inter'>
                    {nocDetails.map((val: any, main_id: number) => {
                        return val.comments.length ? (
                            <div key={main_id}>
                                <div className='formTitle font-18'>{t(val.nocType)}</div>
                                <div>
                                    {val.comments.map((msg: any, id: number) => (
                                        <div className='d-flex justify-content-between'>
                                            <div className='col-sm-3 p-0'>
                                                <p className='fw-500' style={{ fontSize: '14px' }}>
                                                    {msg.intialClarificationDate}
                                                </p>
                                            </div>
                                            <div className='col-sm-9 p-0 mb-3'>
                                                <div>
                                                    <p>
                                                        <span className='font-14'>{msg.commentedBy}</span> - <i className='text-muted font-14'>{msg.createdTime}</i>
                                                    </p>
                                                    <p className='mb-3'>{msg.comment}</p>
                                                </div>

                                                {msg.replies.length > 0 && (
                                                    <div className='col-sm-12 p-0 mb-3'>
                                                        <p>
                                                            <span className='font-14'>{msg.replies[msg.replies.length - 1].commentedBy}</span> -{' '}
                                                            <i className='text-muted font-14'>{msg.replies[msg.replies.length - 1].createdTime}</i>
                                                        </p>
                                                        <p className='mb-0'>{msg.replies[msg.replies.length - 1].comment}</p>
                                                        <div className={`${val?.class ? val?.class : 'col-sm-6'}`}>
                                                            {msg.replies[msg.replies.length - 1].nocDocumentFileStoreId ? (
                                                                <GenericFileUploadField
                                                                    field={`docDetails_${msg.replies[msg.replies.length - 1].nocDocumentFileStoreId}`}
                                                                    title='nocDocument'
                                                                    isDisabled={true}
                                                                />
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                )}

                                                {!isReply[`key_${main_id}_${id}`] && (
                                                    <div
                                                        id={`${val.nocType}${id}`}
                                                        onClick={() => {
                                                            handleOnReplyClick(main_id, id);
                                                        }}
                                                        className='p-3 text-center mb-5'
                                                        style={{ border: 'dashed 2px #45852e80', borderRadius: '8px', backgroundColor: '#ecf2ea', cursor: 'pointer' }}
                                                    >
                                                        <button
                                                            style={{ fontWeight: 600, fontSize: 11.85, lineHeight: '12.5px', border: '0px', background: 'none', color: '#000000B2' }}
                                                            className='button add'
                                                            type='button'
                                                        >
                                                            + {t('REPLY', 'Reply')}
                                                        </button>
                                                    </div>
                                                )}

                                                {isReply[`key_${main_id}_${id}`] && (
                                                    <>
                                                        <label className='pb-2'>
                                                            Reply<span className='mandatory-indicator'>*</span>
                                                        </label>
                                                        <>
                                                            <Field
                                                                disabled={!values.isUpdatable}
                                                                name={`${val.nocType}${id}`}
                                                                as='textarea'
                                                                id={`${val.nocType}${id}`}
                                                                style={{ height: '100px' }}
                                                                className={`form-control was-validated`}
                                                                onChange={(event: any) => {
                                                                    onChangeReply(event, nocDetails[main_id].comments[id], id);
                                                                }}
                                                            ></Field>
                                                        </>
                                                        {userType === USER_TYPE.ENTERPRISE && (
                                                            <>
                                                                <div className='formTitle mt-3'>Supporting Documents</div>
                                                                <div className='row p-0 m-0 '>
                                                                    <div className='col-sm-6 p-0 m-0'>
                                                                        <div style={{ marginRight: 4 }}>
                                                                            <GenericFileUploadField
                                                                                field={`file${val.nocType}${id}`}
                                                                                documents={documents}
                                                                                setDocuments={setDocuments}
                                                                                title='Upload Image'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {clarificationHistoryDetails.length > 1 ? <hr /> : <></>}
                            </div>
                        ) : (
                            <></>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default ClarificationSection;
