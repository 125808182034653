import React from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import dashboardImage from '../../.../../../../assets/logos/human.svg';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { NEW_USER_DASBOARD_BUTTON } from '../UserManagement.const';
import FooterComponent from '../../../../components/portalComponents/Footer.component';
import { useTranslation } from 'react-i18next';
import mapImage from '../../../../assets/logos/mapimg.svg';

const NewUserDashboard = () => {
    const userData = useSelector(selectUser);
    const userName = userData?.firstName;
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <>
            <div className='row new-dashboard px-5 gx-0 justify-content-center min-vh-100 position-fixed w-100 ' style={{ backgroundColor: '#F5F5F5' }}>
                {/* <div className='col-12 mt-4 ' style={{ height: '70px' }}>
                    <div className='font-16 fw-400 font-inter greeting '>Welcome</div>
                    <div className='font-28  font-inter user-name fw-bold '>{userName}</div>
                </div> */}
                <div className='col-6  d-flex flex-column justify-content-center text-center position-absolute ' style={{ height: 'fit-content' }}>
                    <img src={mapImage} alt='dashboardImage' height={260} />
                    <div className='content  mt-4 '>
                        <div className='content-heading font-24 fw-semibold font-inter text-center mb-3'>Start with applying for plot.</div>
                        <div className='content-details text-center font-14 fw-500 font-inter mb-3'>
                            {t('THANKYOU_REG_GOA_PORTAL', ' Thank you for registering to Goa IDC portal. Please apply for a plot via these 3 below options.')}
                        </div>
                        <div className='content-button d-flex justify-content-center'>
                            {NEW_USER_DASBOARD_BUTTON.map((val: any, ind: number) => (
                                <GenericButtons
                                    text={t(val?.titleCode, val?.btnTitle)}
                                    variant='primary'
                                    ClassName=' px-2 py-2 fw-normal font-14 new-dashboard-button'
                                    handleClick={() => navigate(val?.btnURL)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='position-fixed bottom-0  w-100 d-non'>
                <FooterComponent onlyCopyright={true} />
            </div>
        </>
    );
};

export default NewUserDashboard;
