import React, { useEffect, useState } from 'react';
import right_paginatioin_arrow from '../../../../assets/logos/right paginatioin arrow .svg';
import left_paginatioin_arrow from '../../../../assets/logos/left paginatioin arrow .svg';
import { Link } from 'react-router-dom';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { cross } from '../../../../utils/Logo';
import { useTranslation } from 'react-i18next';
import { TENANT_ID } from '../../../../utils/constants';
import { convertToINR } from '../../EstateManagement/EstateManagement.controller';
import viewAllIcon from '../../../../assets/logos/view all.svg';
import { getDateRangeForPayment } from '../../EstateManagement/Payment/EstatePayment.const';

const SubleaseSection = (props: any) => {
    const { t } = useTranslation();
    const { iconProps, currentPlotDetails, modalDetails, setModalDetails } = props;
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    const [isSubLease, setIsSubLease] = useState<boolean>(false);
    const [subleaseDetails, setSubleaseDetails] = useState<any>([]);
    const [totalWaterCharges, setTotalWaterCharges] = useState<any>(0);
    const [subleaseCharges, setSubleaseCharges] = useState<any>(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setIsLoading(true);
                if (entityId && currentPlotDetails?.plotId) {
                    const { data: subleaseData } = await POST(endpoints.searchSubLease + `?lesseeEntityIds=${entityId}&plotIds=${currentPlotDetails?.plotId}&statuses=APPROVED`, {
                        RequestInfo: requestInfo()
                    });
                    setIsSubLease(subleaseData?.subLeaseDetails?.length > 0);

                    const promises = subleaseData?.subLeaseDetails?.map(async (val: any) => {
                        const { data: subleaseFeeDetails } = await POST(
                            endpoints.scheduledPaymentDetailsSearch + `?paymentTypes=SUBLEASE_FEE,WATER_CHARGES&plotIds=${currentPlotDetails?.plotId}&entityIds=${val?.subLesseeEntityDetails?.uuid}`,
                            { RequestInfo: requestInfo() }
                        );

                        console.log('subleaseeee', subleaseFeeDetails);

                        const sumWaterCharges = subleaseFeeDetails?.paymentDetailsResponse
                            ?.filter((item: any) => item.paymentType === 'WATER_CHARGES')
                            ?.reduce((sum: any, payment: any) => {
                                if (!payment.paymentDate && getDateRangeForPayment(payment.dueDate) !== 'FUTURE_DATE') {
                                    return sum + (payment.totalDueAmount || 0);
                                }
                                return sum;
                            }, 0);

                        const subleaseCharges = subleaseFeeDetails?.paymentDetailsResponse
                            ?.filter((item: any) => item.paymentType === 'SUBLEASE_FEE')
                            ?.reduce((sum: any, payment: any) => {
                                if (!payment.paymentDate && getDateRangeForPayment(payment.dueDate) !== 'FUTURE_DATE') {
                                    return sum + (payment.totalDueAmount || 0);
                                }
                                return sum;
                            }, 0);

                        setSubleaseCharges(+subleaseCharges.toFixed(2));
                        setTotalWaterCharges(+sumWaterCharges.toFixed(2));

                        return {
                            ...val,
                            subleaseFeeDetails: [
                                subleaseFeeDetails?.paymentDetailsResponse?.find((item: any) => item.paymentType === 'SUBLEASE_FEE') || {},
                                subleaseFeeDetails?.paymentDetailsResponse?.find((item: any) => item.paymentType === 'WATER_CHARGES') || {}
                            ]
                        };
                    });

                    const tempSubLeaseDisplayData = await Promise.all(promises);
                    setSubleaseDetails(tempSubLeaseDisplayData);
                }

                // setIsLoading(false);
            } catch (err: any) {
                // setIsLoading(false);
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message || t('ERROR_OCCURED', 'An error occurred');
                setModalDetails({ show: true, title: errMsg, para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        fetchData();
    }, [entityId, currentPlotDetails?.plotId]);

    const compareDate = (date: any) => {
        const currentDate = new Date();
        const dueDate = new Date(date);
        if (currentDate <= dueDate) {
            return 'Due';
        }
        if (currentDate > dueDate) {
            return 'Overdue';
        } else return 'NA';
    };

    console.log('subleaseDetails', subleaseDetails);

    return (
        <>
            {isSubLease ? (
                <div className='p-4 rounded-4 bg-white payment-details border border-1'>
                    <div className='d-flex justify-content-between pe-3'>
                        <span className='font-20 fw-semibold lh-25 dashboard-text-color'>Sub-Lease</span>
                        <span>
                            <img src={left_paginatioin_arrow} alt='' className='me-4 cursor pointer' />
                            <img src={right_paginatioin_arrow} alt='rightarrow' className='cursor pointer' />
                        </span>
                    </div>
                    <div className='row gx-3 payment-card-parents pt-1 flex-lg-nowrap overflow-x-auto'>
                        {subleaseDetails?.map((val: any, ind: number) => (
                            <div className='col-12 col-lg-4 col-md-6 mt-3'>
                                <div className='bg-light border-rad-12 p-3 pb-2 '>
                                    <div className='d-flex justify-content-between  align-items-center'>
                                        <span className='fw-semibold font-18 lh-25 dashboard-text-color'>{val?.subLesseeEntityDetails?.entityName}</span>
                                        <Link
                                            to={
                                                val?.applicationDetails?.applicationNo
                                                    ? `/goa-idc/estate/sublease?applicationNumber=${val?.applicationDetails?.applicationNo}&tenantId=${TENANT_ID}&subleaseInfo=true&readOnly=true`
                                                    : ''
                                            }
                                            className='fw-500 font-14 lh-14'
                                        >
                                            {t('MORE_INFO', 'More Info')}
                                        </Link>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        {val?.subleaseFeeDetails?.map((subVal: any, ind: number) => (
                                            <Link
                                                to={
                                                    subVal?.totalDueAmount
                                                        ? `/goa-idc/estate/payment?type=${subVal.paymentType}&plotId=${currentPlotDetails?.plotId}&applicationNumber=${val?.applicationDetails?.applicationNo}&applicationId=${val?.applicationDetails?.uuid}&subleaseEntityId=${val.subLesseeEntityDetails?.uuid}`
                                                        : ''
                                                }
                                                className=' rounded-3 mt-3 p-4 mb-3'
                                                style={{ backgroundColor: '#fff', width: '14rem' }}
                                            >
                                                <span className={`sub-lease-status  mb-3`}>
                                                    <div className='d-flex align-items-start flex-column'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className=' cursor-pointer font-14 fw-500 lh-14 mb-3' style={{ color: '#70777E', width: '7rem' }}>
                                                                {subVal?.paymentTypeDisplayName ? subVal?.paymentTypeDisplayName : ind === 0 ? 'Sublease Fee' : 'Water Charges'}
                                                            </div>
                                                            <div>
                                                                {Object.keys(subVal).length && !subVal?.paymentDate ? (
                                                                    <Link
                                                                        to={`/goa-idc/estate/payment?type=${subVal.paymentType}&plotId=${currentPlotDetails?.plotId}&applicationNumber=${val?.applicationDetails?.applicationNo}&applicationId=${val?.applicationDetails?.uuid}&subleaseEntityId=${val.subLesseeEntityDetails?.uuid}`} //}${subVal.paymentType === 'SUBLEASE_FEE' ? `&subleaseEntityId=${val.subLesseeEntityDetails?.uuid}` : ''}
                                                                        className=' rounded-pill px-2 '
                                                                        style={{ border: '.75px solid #0763B8', color: '#0763B8' }}
                                                                    >
                                                                        {t('PAY', 'Pay')}
                                                                        <img src={viewAllIcon} alt='' />
                                                                    </Link>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className='font-20 fw-semibold lh-14 dashboard-text-color mb-3'>
                                                            {subVal?.totalDueAmount
                                                                ? subVal.paymentType === 'WATER_CHARGES'
                                                                    ? convertToINR(totalWaterCharges)
                                                                    : subleaseCharges
                                                                    ? convertToINR(subleaseCharges)
                                                                    : '--'
                                                                : '--'}
                                                        </div>
                                                        <div>
                                                            <span className={`py-1 rounded-pill px-2 icon-background  ${subVal?.paymentDate ? 'Paid' : compareDate(subVal?.dueDate)}`}>
                                                                <img
                                                                    className='me-1 '
                                                                    src={
                                                                        subVal?.paymentDate
                                                                            ? iconProps[0]
                                                                            : compareDate(subVal?.dueDate) === 'Due'
                                                                            ? iconProps[1]
                                                                            : compareDate(subVal?.dueDate) === 'Overdue'
                                                                            ? iconProps[2]
                                                                            : ''
                                                                    }
                                                                    alt=''
                                                                />
                                                                {subVal?.paymentDate ? t('PAID', 'Paid') : compareDate(subVal?.dueDate)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default SubleaseSection;
