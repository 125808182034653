import React, { useEffect, useState } from 'react';
import {
    BILL_GENERATION_DETAILS_FORM,
    WATER_CONNECTION_FORM_STEPS,
    billPaymentColumns,
    modifyMDMSDataList,
    modifyPlotList,
    paymentPayloadforWaterBill,
    paymentPayloadforWaterConnection
} from './WaterConnection.const';
import HeaderComponent from '../../components/header/Header.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { convertToINR, fetchFeeData, fetchGatewayData, getOptions, getQueryParams, loadRazorpay, paymentCheck, paymentPayload } from '../modules/EstateManagement/EstateManagement.controller';
import { BPArequestInfo, POST, POST2, endpoints, getNextValidAction, getUserDataOnRefresh, requestInfo } from '../../services/modulesServices/userManagement.api';
import { check, cross } from '../../utils/Logo';
import { WATER_CONNECTION_VALIDATION } from '../../utils/formikValidation/FormikValidation';
import { toast } from 'react-toastify';
import { getFirstErrorKey, getNavigateTo } from '../../utils/utils';
import { RazorpayOptions } from '../modules/UserManagement/types';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { Card } from 'react-bootstrap';
import Loading from '../../components/portalComponents/Loading.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import GenericBackButton from '../../components/UI components/Buttons/GenericBackButton';
import SideCard from '../../components/moduleCommonFormik/SideCardGenericComponent';
import { Field, FormikProvider, useFormik } from 'formik';
import PaymentDetails from '../modules/EstateManagement/Allotment/PaymentDetails';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import { KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../utils/constants';

const WaterConnectionPayment = () => {
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [visited, setVisited] = useState(false);
    const [transferorDetails, setTransferorDetails] = useState<any>({});
    const [transferDetails, setTransferDetails] = useState<any>(null);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [waterConnectionData, setWaterConnectionData] = useState<any>(null);
    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    let navigate = useNavigate();
    const stepsDetails = WATER_CONNECTION_FORM_STEPS;
    const [paymentData, setPaymentData] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const [paymentTried, setPaymentTried] = useState<boolean>(false);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [allotmentDetails, setAllotmentDetails] = useState<any>(null);
    const [plotDetails, setPlotDetails] = useState<any>(null);
    const [pipeDiameterData, setPipeDiameterData] = useState<any>([]);
    const [waterTypesData, setWaterTypesData] = useState<any>([]);
    const [waterCharges, setWaterCharges] = useState<any>(0);
    const [isEditable, setIsEditable] = useState(true);
    const [plotList, setPlotList] = useState<any>([]);
    const [certificateData, setCertificateData] = useState<any>([]);
    const [searchParams] = useSearchParams();
    const [selectedCertificate, setSelectedCertificate] = useState<any>(null);
    const [selectedOneCert, setSelectedOneCert] = useState<any>(null);
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNumber = decodeURIComponent(urlParams.get('applicationNumber') || '');
    const [actionsData, setActionsData] = useState<any>(null);

    const [waterConnectionBillData, setWaterConnectionBillData] = useState<any>([]);
    const [waterConnectionBillHistoryData, setWaterConnectionBillHistoryData] = useState<any>([]);
    let stepperIndex = searchParams.get('stepper');
    const [paymentRowData, setPaymentRowData] = useState<any>([]);

    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    let applicationNo = searchParams.get('applicationNumber');
    const formik: any = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            isSpecialProject: false
        },
        onSubmit: value => {},
        validationSchema: WATER_CONNECTION_VALIDATION[`page${activeIndex}`]
    });
    const { values, setValues, errors, setFieldTouched } = formik;

    const onCertSelect = (selectedValue: any) => {
        console.log('selectedValue', selectedValue);
        setSelectedOneCert(selectedValue.id);
        setSelectedCertificate(selectedValue);
    };
    console.log(applicationNo);
    const checkRole = async () => {
        try {
            const { data } = await getUserDataOnRefresh();
            if (data) {
                if (data.type === 'EMPLOYEE') {
                    navigate(`/goa-idc/e/waterconnection?applicationNumber=${applicationNo}&tenantId=ga`);
                }
            }
        } catch (error) {}
    };

    useEffect(() => {
        if (applicationNo) {
            const fetchWaterDetails = async () => {
                try {
                    // setIsLoading(true);
                    const { data } = await POST(endpoints.waterConnectionSearch + `?applicationNo=${applicationNo}`, { RequestInfo: requestInfo() });
                    setWaterConnectionData(data[0]?.applicationDetails);
                    values.plotNo = data[0]?.applicationDetails?.plotDetails?.uuid;
                    fetchDetails(data[0]?.applicationDetails?.plotDetails?.uuid);

                    // setIsLoading(false);
                } catch (error) {
                    // setIsLoading(false);
                    console.error('Error fetching data:', error);
                }
            };
            if (applicationNo) {
                setIsEditable(false);
                fetchWaterDetails();
                getActionsData();
                checkRole();
            }
        }
    }, [applicationNo]);

    useEffect(() => {
        if (applicationNumber) {
            const fetchWaterBillDetails = async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.waterConnectionBillSearch + `?billNo=${applicationNumber}`, { RequestInfo: requestInfo() });
                    // setWaterConnectionBillData(data);
                    console.log('data:', data);
                    // values.plotNo = data?.meterReadings[0]?.connectionDetails?.plotDetails?.uuid;
                    fetchDetails(data?.meterReadings[0]?.connectionDetails?.plotDetails?.uuid);
                    getPaymentData(data?.meterReadings[0]?.uuid);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    console.error('Error fetching data:', error);
                }
            };
            if (applicationNumber) {
                fetchWaterBillDetails();
                // getActionsData();
            }
        }
    }, [applicationNumber]);

    console.log('**************************');
    useEffect(() => {
        if (applicationNumber) {
            const fetchWaterBillHistoryDetails = async () => {
                try {
                    // setIsLoading(true);
                    const { data } = await POST(endpoints.waterConnectionBillSearch + `?billNo=${applicationNumber}&isHistoryRequired=true&limit=11`, { RequestInfo: requestInfo() });
                    setWaterConnectionBillHistoryData(data);
                    setWaterConnectionBillData(data);
                    console.log('data:', data);
                    // values.plotNo = data?.meterReadings[0]?.connectionDetails?.plotDetails?.uuid;
                    // getPaymentData(data[0]?.applicationDetails?.uuid);
                    // setIsLoading(false);
                } catch (error) {
                    // setIsLoading(false);
                    console.error('Error fetching data:', error);
                }
            };
            if (applicationNumber) {
                fetchWaterBillHistoryDetails();
                // getActionsData();
            }
        }
    }, [applicationNumber]);

    useEffect(() => {
        (async () => {
            // setIsLoading(true);
            try {
                const { data } = await POST(endpoints.searchSubLease + `?lesseeEntityIds=${entityId}&plotIds=${values?.plotNo}`, { RequestInfo: requestInfo() });
                // setSubLeaseResponse(data?.subLeaseDetails[0]);
                console.log(data, 'MYDATA');
                // setIsLoading(false);
            } catch (error: any) {
                let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();

        const fetchPipeDiameter = async () => {
            try {
                const { data } = await POST('/egov-mdms-service/v1/_search', {
                    RequestInfo: requestInfo(),
                    MdmsCriteria: {
                        tenantId: 'ga',
                        moduleDetails: [
                            {
                                moduleName: 'ws-services-masters',
                                masterDetails: [
                                    {
                                        name: 'PipeDiameter',
                                        filter: '[?(@.active == true)]'
                                    }
                                ]
                            }
                        ]
                    }
                });
                let pipeDiameter = data?.['MdmsRes']?.['ws-services-masters']?.['PipeDiameter'];
                const modifiedPipeDia = modifyMDMSDataList(pipeDiameter, 'size', 'code') || [];
                setPipeDiameterData(modifiedPipeDia);
                console.log(pipeDiameter, 'PIPE_DIAMETER');
            } catch (error: any) {
                let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        const fetchWaterTypes = async () => {
            try {
                const { data } = await POST('/egov-mdms-service/v1/_search', {
                    RequestInfo: requestInfo(),
                    MdmsCriteria: {
                        tenantId: 'ga',
                        moduleDetails: [
                            {
                                moduleName: 'ws-services-masters',
                                masterDetails: [
                                    {
                                        name: 'WaterType',
                                        filter: '[?(@.active == true)]'
                                    }
                                ]
                            }
                        ]
                    }
                });
                let waterTypes = data?.['MdmsRes']?.['ws-services-masters']?.['WaterType'];

                const modifiedPipeDia = modifyMDMSDataList(waterTypes, 'code', 'name') || [];
                setWaterTypesData(modifiedPipeDia);
            } catch (error: any) {
                let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (values?.plotNo) {
            fetchPipeDiameter();
            fetchWaterTypes();
        }
    }, [values?.plotNo]);

    const fetchDetails = (plotID: any) => {
        fetchAllotmentDetails(plotID);
        fetchPlotDetails();
    };

    const fetchAllotmentDetails = async (plotID: any) => {
        try {
            // setIsLoading(true);
            const { data } = await POST(endpoints.allotmentDetailsSearch + `?plotIds=${plotID}&entityIds=${entityId}`, { RequestInfo: requestInfo() });
            setAllotmentDetails(data.allotmentDetails[0]);
            // values.plotNo = plotDetails?.plotNo;
            values.allotmentOrderNo = data.allotmentDetails[0]?.allotmentId;
            values.allotmentOrderDate = data.allotmentDetails[0]?.allotmentDate;

            // setIsLoading(false);
        } catch (error) {
            // setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const fetchPlotDetails = async () => {
        try {
            // setIsLoading(true);
            const { data } = await POST(endpoints.entityRefDetails + `?isPlotDetailsRequired=true&types=ALLOTTEE,LESSEE,TRANSFEROR&entityIds=${entityId}`, { RequestInfo: requestInfo() });
            setPlotDetails(data.entityRefDetails[0]?.plotDetails);
            values.estate = data.entityRefDetails[0]?.plotDetails?.estateDetails?.estateName;
            values.plotType = data.entityRefDetails[0]?.plotDetails?.plotType;
            values.phase = data.entityRefDetails[0]?.plotDetails?.phase;
            values.plotNo = data.entityRefDetails[0]?.plotDetails?.plotNo;
            // setIsLoading(false);
        } catch (error) {
            // setIsLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (values?.plotNo) {
            fetchDetails(values?.plotNo);
        }
    }, [values?.plotNo]);

    const onChangeNextIndex = async () => {
        const stepperLength = stepsDetails.length;
        try {
            if (activeIndex < stepperLength) {
                setActiveIndex(activeIndex + 1);
                window.scrollTo(0, 0);
            }
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required details!', img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const fetchPayDetails = () => {
        let billData = waterConnectionBillData?.meterReadings ? waterConnectionBillData?.meterReadings[0] : null;
        return [
            {
                paymentType: 'WATER_CHARGES',
                principalAmount: +billData?.monthlyWaterCharges,
                cgstPrincipal: null,
                sgstPrincipal: null,
                totalDueAmount: +billData?.monthlyWaterCharges,
                feeTypeDisplayName: '',
                cgstAmount: '0',
                sgstAmount: '0',
                amount: convertToINR(billData?.monthlyWaterCharges),
                total: convertToINR(billData?.monthlyWaterCharges),
                billNo: billData?.billNo,
                date: billData?.currentReadingDate,
                lastMeterReading: billData?.lastReading,
                currentMeterReading: billData?.currentReading,
                index: 1
            }
        ];
    };

    useEffect(() => {
        const fetchWaterTypes = async () => {
            try {
                const apiurl = endpoints.waterChargesByDiameter;
                const { data } = await POST(`${apiurl}?diaSize=${values?.connectionPipeDia}`, {
                    RequestInfo: BPArequestInfo()
                });
                setWaterCharges(data?.waterConnectionFeeMasters[0]?.rate || 0);
                console.log(data, 'WATER_CHARGES');
            } catch (error: any) {
                let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (!!values?.connectionPipeDia) fetchWaterTypes();
    }, [values?.connectionPipeDia]);

    const onChangePrevIndex = () => {
        const stepperLength = stepsDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
        if (stepperLength <= activeIndex && activeIndex > 1) {
            setVisited(true);
        }
    };

    useEffect(() => {
        (async () => {
            // const payCheck = await paymentCheck('PROCESSING_FEE', values.plotUuid, transferDetails?.applicationDetails.uuid);
            // console.log(payCheck);
            // setIsPaid(false);
            // setPaymentTried(payCheck?.paymentTried);
            // setPaymentData(payCheck?.paymentData);
            // setTotalPaidAmount(payCheck?.totalPaidAmount);
            // setTransactionDetails(payCheck?.transactionDetails);

            const gateway = await fetchGatewayData();
            setGatewayData(gateway);

            // setPaymentData(feeData?.feeMasterDetails);
            // setTotalPaidAmount(feeData?.totalPaidAmount);
            setPaymentIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (activeIndex === 1) {
                try {
                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);
                    setPaymentIsLoading(false);
                } catch (e) {
                    console.log(e);
                    setPaymentIsLoading(false);
                }
            }
        })();
    }, [activeIndex]);

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const lastSubmit = (isSaveOnly?: boolean, status?: string, comment?: string) => {
        if (activeIndex === 1 && !!applicationNo) {
            setActiveIndex(activeIndex + 1);
            return;
        }
        if (activeIndex === 2) {
            // onPay();
            setActiveIndex(activeIndex + 1);
            return;
        }
        if (activeIndex === 3 && !applicationNo) {
            onLastSubmit(waterConnectionBillData);
            return;
        } else if (activeIndex === 3 && !!applicationNo) {
            navigateToDashboard();
        }
        onSubmit();
    };

    const onSubmit = async (isSaveOnly?: boolean) => {
        if (isEditable && isEditable !== undefined) {
            // if (activeIndex === 3 && isPaid) {
            //     // setActiveIndex(4);
            //     return;
            // }
            setIsLoading(true);
            const payload: any = {
                RequestInfo: BPArequestInfo(),
                applicationDetails: {
                    leasseeDetails: {
                        uuid: entityId
                    },
                    plotDetails: {
                        uuid: values?.plotNo
                    },
                    applicationType: values?.connectionType,
                    applicationDate: values?.allotmentOrderDate,
                    waterType: values?.waterType,
                    pipeDiaMm: +values?.connectionPipeDia,
                    monthlyWaterConsumptionM3: +values?.monthlyConsumption,
                    waterConnectionCharge: +waterCharges,
                    documents: [selectedCertificate]
                }
            };

            if (!!values?.subLessee) {
                payload.subLeasseeDetails = {
                    uuid: values?.subLessee
                };
            }

            const apiEndPoint = endpoints.createWaterConnection;

            try {
                await WATER_CONNECTION_VALIDATION[`page${activeIndex}`]?.validate(values, { abortEarly: false, context: values });

                try {
                    const { data } = await POST(apiEndPoint, payload);
                    setWaterConnectionData(data?.applicationDetails);
                    setIsLoading(false);
                    if (activeIndex === 1) {
                        toast('Application Created', {
                            position: toast.POSITION.TOP_RIGHT,
                            className: 'foo-bar',
                            type: 'success'
                        });
                    } else if (activeIndex === 2) {
                        toast('Data saved successfully', {
                            position: toast.POSITION.TOP_RIGHT,
                            className: 'foo-bar',
                            type: 'success'
                        });
                    }
                    !isSaveOnly && onChangeNextIndex();
                    if (activeIndex === 4) {
                        setModalDetails({ show: true, title: 'Success!', para: 'Application assigned successfully', img: check, type: 'success', reUpload: 'OK' });
                    }
                } catch (error: any) {
                    setIsLoading(false);
                    let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            } catch (e) {
                let errorField = getFirstErrorKey(errors);
                setIsLoading(false);
                if (errorField) {
                    const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                    await setFieldTouched(errorField, true, true);
                    let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                    getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const onLastSubmit = async (waterConnectionBillData: any) => {
        let billData = waterConnectionBillData?.meterReadings ? waterConnectionBillData?.meterReadings[0] : null;
        // setIsLoading(true);
        const payload: any = {
            RequestInfo: BPArequestInfo(),
            meterReadingRequest: {
                meterReading: billData
            },
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: 'WATER_CONNECTION_BILLING',
                    businessId: billData?.billNo,
                    moduleName: 'Water Connection Billing',
                    action: 'PAYMENT',
                    paymentType: 'WATER_CHARGES',
                    comment: 'submitted'
                }
            ]
        };

        const apiEndPoint = endpoints.waterConnectionInitiate;

        try {
            const { data } = await POST(apiEndPoint, payload);
            // setIsLoading(false);
            // setModalDetails({ show: true, title: 'Success!', para: 'Application submitted successfully', img: check, type: 'success', reUpload: 'OK' });
        } catch (error: any) {
            // setIsLoading(false);
            let errMsg = error?.response?.data?.errorMessage || 'Something went wrong!';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getActionsData = () => {
        if (!!applicationNo) {
            // setIsLoading(true);

            getNextValidAction(applicationNo)
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        console.log(response?.data);
                        setActionsData(response?.data);
                    }

                    // setIsLoading(false);
                })
                .catch((error: any) => {
                    // setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        (async () => {
            if (stepperIndex === '1' && !!waterConnectionBillData) {
                // setIsLoading(true);
                const queryParams = getQueryParams(searchParams);
                if (queryParams?.['applicationNumber']) {
                    console.log(waterConnectionBillData, '*******=====>');
                    getTransactionUpdate(waterConnectionBillData);
                }
                // setIsLoading(false);
            }
        })();
    }, [waterConnectionBillData]);

    useEffect(() => {
        loadRazorpay();
    }, []);

    const onPay = async () => {
        let billData = waterConnectionBillData?.meterReadings ? waterConnectionBillData?.meterReadings[0] : null;

        setIsLoading(true);
        setTotalPaidAmount(billData?.monthlyWaterCharges);
        const totalPaidAmount = billData?.monthlyWaterCharges;
        let url = window.location.origin + `goa-idc/waterconnection/payment?applicationNumber=${applicationNo}&stepper=${activeIndex}`;
        const periodTo = new Date(billData?.auditDetails?.createdTime).setFullYear(new Date(billData?.auditDetails?.createdTime).getFullYear() + 1);
        const payload = paymentPayloadforWaterBill(
            billData,
            billData?.connectionDetails?.plotDetails?.uuid,
            totalPaidAmount,
            url,
            'Water Connection Bill Fees',
            selectedGateway,
            billData.auditDetails.createdTime,
            periodTo,
            fetchPayDetails()
        );

        try {
            if (!totalPaidAmount) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            if (!selectedGateway) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            if (billData?.billNo && selectedGateway && totalPaidAmount > 0) {
                const { data } = await POST(endpoints.paymentGateway, payload);
                const data1 = data;
                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, getUser, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    console.log(data1.additionalDetails);
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data1.additionalDetails.walletClientCode}&walletRequestMessage=${data1.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
                setIsLoading(false);
            }
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getPaymentData = async (appNumber: any) => {
        try {
            const data = await POST(endpoints.paymentDetailsSearch + `?applicationIds=${appNumber || waterConnectionBillData?.meterReadings[0]?.uuid}`, { RequestInfo: requestInfo() });
            // setTransactionDetails(data?.data?.payments[0]);
            if (data?.data?.payments.length) {
                let paymentDetails = data?.data?.payments[0]?.payment;

                setTransactionDetails({
                    txnId: paymentDetails?.transactionNumber || '',
                    auditDetails: { createdTime: paymentDetails?.transactionDate || '' },
                    txnStatus: paymentDetails?.paymentStatus || '',
                    invoiceRefId: data?.data?.payments[0]?.invoiceRefId || ''
                });
            }
            let payment = data?.data?.payments?.map((val: any, index: number) => ({
                feeTypeDisplayName: val.paymentTypeDisplayName,
                amount: convertToINR(val.principalAmount),
                cgstAmount: val.cgstPrincipal === 0 ? '--' : convertToINR(val.cgstPrincipal),
                sgstAmount: val.sgstPrincipal === 0 ? '--' : convertToINR(val.sgstPrincipal),
                total: convertToINR(val.paidAmount),
                index: index + 1
            }));
            setPaymentRowData(payment);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getTransactionUpdate = async (waterConnectionBillData: any) => {
        try {
            // const paid: any = await paymentCheck('PROCESSING_FEE', params['plotId'], applicationId);
            // if (!paid.isPaid) {
            setIsLoading(true);
            const queryParams = getQueryParams(searchParams);
            // const retainList = ['msg'];
            // for (const key of [...queryParams.keys()]) {
            //     if (retainList.includes(key)) {
            //         queryParams.delete(key);
            //     }
            // }
            const payload = { RequestInfo: requestInfo() };
            const { data } = await POST2(endpoints.transactionUpdate, payload, queryParams);
            if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                setTransactionDetails(data?.Transaction?.[0]);

                if (data?.Transaction?.[0]?.txnId) {
                    try {
                        const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                        if (resp.data.status === 'SUCCESSFUL') {
                            setModalDetails({ show: true, title: 'Success!', para: 'Payment successful', img: check, type: 'success', reUpload: 'OK' });
                            setTimeout(() => {
                                onLastSubmit(waterConnectionBillData);
                            }, 500);

                            setIsPaid(true);
                        } else setIsPaid(false);
                        setIsLoading(false);
                        setPaymentIsLoading(false);
                    } catch (e: any) {
                        setIsPaid(false);
                        setIsLoading(false);
                        setPaymentIsLoading(false);
                    }
                }
            } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                setModalDetails({
                    show: true,
                    title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                    para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
                // window.location.reload();
            }
            setIsLoading(false);
            setPaymentIsLoading(false);
            // }
        } catch (e: any) {
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            if (e.response?.data?.Errors?.[0]?.code === 'BILL_ALREADY_PAID') {
                return;
            }
            setModalDetails({ show: true, title: 'Transaction Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleModalClose = (type: string) => {
        setModalDetails({ show: false });
        if (type === 'success') {
            navigate('/goa-idc/enterprisedashboard');
        }
    };

    const onCheckHandler = (checkedItems: any) => {
        setSelectedCertificate(checkedItems);
    };

    const PlotDetails = () => {
        const connection_details_formData = BILL_GENERATION_DETAILS_FORM;

        return (
            <div>
                <>
                    {/* <Card className='card_border'>
                        <Card.Body> */}
                    {connection_details_formData?.map(val => (
                        <GenericFormCard {...val} />
                    ))}
                </>
            </div>
        );
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('', 'Payment')}
                            bottomSubText={transferDetails ? `Type : Water Connection, No : ${transferDetails?.applicationDetails?.applicationNo}` : ''}
                            additionalClass={transferDetails && 'sub-text-style'}
                        />
                        <div className='col-12 form-section'>
                            <FormikProvider value={formik}>
                                {!paymentIsLoading ? (
                                    <PaymentDetails
                                        paymentData={paymentRowData.length ? paymentRowData : fetchPayDetails()}
                                        selectedGateway={selectedGateway}
                                        setSelectedGateway={setSelectedGateway}
                                        isPaid={paymentRowData.length ? true : false}
                                        gatewayData={paymentRowData.length === 0 ? gatewayData : []}
                                        transactionDetails={transactionDetails}
                                        title={'Water Connection Fee'}
                                        columns={billPaymentColumns}
                                        isPaymentOption={paymentRowData.length === 0}
                                    />
                                ) : (
                                    <Loading />
                                )}
                                {PlotDetails()}
                            </FormikProvider>
                        </div>
                    </div>

                    {actionsData?.nextValidAction.length && (
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                                    {activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={onChangePrevIndex} />}
                                </div>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    <GenericButtons text={activeIndex === 1 ? `Pay Now` : `Close`} variant='primary' handleClick={() => (activeIndex === 1 ? onPay() : lastSubmit())} />
                                </div>
                            </div>
                        </footer>
                    )}
                </>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
        </>
    );
};

export default WaterConnectionPayment;
