import React from 'react'
import { Link } from 'react-router-dom'
import HeaderComponent from '../../components/header/Header.component'
import FooterComponent from '../../components/portalComponents/Footer.component'
import { useTranslation } from 'react-i18next'

export const PrivacyPolicy = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <HeaderComponent />
            <div className='mx-5 p-5 PrivacyPolicy-container font-inter'>
                <div style={{ width: '764px', height: '14px' }}>
                    <p className='breadcrumbs'><Link className='text-decoration-none' to="/goa-idc/">{t("HOME", "Home")}</Link> / {t("PRIVACY_POLICY", "Privacy Policy")}</p>
                </div>
                <div className='col-md-8 mb-5' style={{ fontWeight: 400, fontSize: '48px', lineHeight: '62.4px', letterSpacing: '-2%' }}>
                    <p>{t("PRIVACY_POLICY", "Privacy Policy")}</p>
                </div>
                <div>
                    <p style={{ fontWeight: 400, fontSize: '16px', lineHeight: '22.4px', letterSpacing: '-0.32px', color: 'rgba(0, 0, 0, 0.90)' }}>{t("PRIVACY_PARA1", "We maintain full privacy of your personal information shared with us. We never misuse any of your personal information. Our privacy policy is as given below:")}</p>
                </div>
                <div className='col-sm-9' style={{ borderBottom: '1px solid #E0E0E0' }}>
                    <p style={{ fontWeight: 500, fontSize: '20px', lineHeight: '28px', letterSpacing: '-0.2px', color: '#45852E' }}>{t("PRIVACY_PARA2", "Collection and sharing of customer information")}</p>
                    <p style={{ fontWeight: 400, fontSize: '16px', lineHeight: '22.4px', letterSpacing: '-0.32px', color: 'rgba(0, 0, 0, 0.90)' }}>{t("PRIVACY_PARA3", "We collect information from you only after you agree to provide it. Any information you give us is held with care and security. This information is collected primarily to ensure that we ship your products without any hassles.")}</p>
                    <p style={{ fontWeight: 400, fontSize: '16px', lineHeight: '22.4px', letterSpacing: '-0.32px', color: 'rgba(0, 0, 0, 0.90)' }}>{t("PRIVACY_PARA4", "We may collect your title, name, gender, email address, postal address, delivery address (if different), telephone number, mobile number, fax number, payment details or bank account details. All this information is processed securely for your protection.")}
                    </p>
                    <p style={{ fontWeight: 400, fontSize: '16px', lineHeight: '22.4px', letterSpacing: '-0.32px', color: 'rgba(0, 0, 0, 0.90)' }}>{t("PRIVACY_PARA5", "Your actual order details may be stored with us and you may access this information by logging into your account on the Site. You undertake to treat the personal access data confidentially and not make it available to unauthorized third parties.")}
                    </p>
                    <p style={{ fontWeight: 400, fontSize: '16px', lineHeight: '22.4px', letterSpacing: '-0.32px', color: 'rgba(0, 0, 0, 0.90)' }}>{t("PRIVACY_PARA6", "Under no circumstances do we rent, trade or share your personal information that we have collected with any other company for their marketing purposes without your consent. We reserve the right to communicate your personal information to any third party that makes a legally-compliant request for its disclosure.")}</p>
                </div>
                <div className='col-sm-9 mb-4' style={{ marginTop: '20px', borderBottom: '1px solid #E0E0E0' }}>
                    <p style={{ fontWeight: 500, fontSize: '20px', lineHeight: '28px', letterSpacing: '-0.2px', color: '#45852E' }}>{t("PRIVACY_PARA7", "Intellectual Property Right")}</p>
                    <p style={{ fontWeight: 400, fontSize: '16px', lineHeight: '22.4px', letterSpacing: '-0.32px', color: 'rgba(0, 0, 0, 0.90)' }}>{t("PRIVACY_PARA8", "All content included on this site, such as text, graphics, logos, button icons, images and software, is the property of Goa Industrial Development Corporation. All content on the Site that is not the property of Goa Industrial Development Corporation is used with permission.")}</p>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}
