export const banner_image = require('../assets/images/banner.jfif');
// export const login_background_image = require("../assets/images/bg-image.png");
export const logo_img = require('../assets/images/goa logo.png');
export const map_image = require('../assets/images/mapPic.jpg');
export const sigin_image1 = require('../assets/images/construction.png');
export const sigin_image2 = require('../assets/images/scene.png');
export const DP = require('../assets/images/profile.png');
// export const pdf = require("../assets/sample.pdf")
export const img1 = require('../assets/images/img1.jpg');
export const img2 = require('../assets/images/img2.jpg');
export const img3 = require('../assets/images/img3.jpg');
export const img4 = require('../assets/images/img4.jpg');
export const img5 = require('../assets/images/img5.jpg');
export const img6 = require('../assets/images/img6.jpg');
export const img7 = require('../assets/images/img7.jpg');
export const img8 = require('../assets/images/img8.jpg');
export const img9 = require('../assets/images/img9.jpg');
export const img10 = require('../assets/images/img10.jpg');
export const img11 = require('../assets/images/img11.jpg');
export const img12 = require('../assets/images/img12.jpg');
export const img_first = require('../assets/images/Image_first.png');
export const img_third = require('../assets/images/Image_third.png');
export const about_us = require('../assets/images/about_us.png');
export const person1 = require('../assets/images/who1.png');
export const person2 = require('../assets/images/who2.png');
export const person3 = require('../assets/images/who3.png');
export const person4 = require('../assets/images/who4.png');
export const person5 = require('../assets/images/who6.png');
export const person6 = require('../assets/images/who7.png');
export const person7 = require('../assets/images/who8.png');
export const login_background_image = require('../assets/images/login_background_image.png');
export const map2 = require('../assets/images/map3.png');
export const bellicon = require('../assets/logos/Bell.png');
export const placeholdericon = require('../assets/logos/Placeholder.png');
export const warningicon = require('../assets/logos/Warning.png');
export const checkcircleicon = require('../assets/logos/CheckCircle.png');
export const dropicon = require('../assets/logos/Drop.png');
export const houselineicon = require('../assets/logos/HouseLine.png');
export const handcoinsicon = require('../assets/logos/HandCoins.png');
export const git_logo = require('../assets/logos/GitCommit.png');
export const carousal1 = require('../assets/images/carousal1.png');
export const carousal3 = require('../assets/images/carousal3.png');
export const airplaneicon = require('../assets/logos/aiplane.png');
export const seaporticon = require('../assets/logos/seaport.png');
export const kokanrailicon = require('../assets/logos/KokanRailway.png');
export const southcenrailwayicon = require('../assets/logos/SouthCenRailway.png');
export const nhicon = require('../assets/logos/NationalHighway.png');
export const industrialestateicon = require('../assets/logos/IndustrialEState.png');
export const stateboundarylineicon = require('../assets/logos/stateBoundaryline.png');
export const stateboundaryicon = require('../assets/logos/stateBoundary.png');
export const gauge = require('../assets/logos/Gauge.png');
export const userSwitch = require('../assets/logos/UserSwitch.png');
export const fullScreen = require('../assets/logos/full_screen.png');
export const advertisementPopup = require('../assets/images/advertisementPopup.png');
export const arrowUpRight = require('../assets/logos/ArrowUpRight.png');
export const pro_photo = require('../assets/images/profile-photo.png');
export const hdfc = require('../assets/images/hdfc.png');
export const pnb = require('../assets/images/pnb.png');
export const icici = require('../assets/images/icici.png');
export const axis = require('../assets/images/axis.png');

// export const app_icon = require("../assets/logos/application_icon.png");
// export const building_icon = require("../assets/logos/building_icon.png");
// export const lessee_icon = require("../assets/logos/lessee_icon.jpg");
// export const scrutiny_icon = require("../assets/logos/scrutiny_icon.png");
// export const emp_create_icon = require("../assets/logos/emp_create_icon.png");
// export const arch_reg_icon = require("../assets/logos/arch_reg_icon.png");
// export const emp_search_icon = require("../assets/logos/emp_search_icon.webp");
// export const emp_act_icon = require("../assets/logos/emp_act_icon.jpg");
