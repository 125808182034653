import { Editor } from '@tinymce/tinymce-react';
import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { tinyMCEApiKey } from '../../utils/utils';

interface RenderNotesProps {
    onContentChange: (content: string) => void;
    editorContent: string; // Add editorContent prop
    isEditable?: boolean;
}

const RenderNotes: React.FC<RenderNotesProps> = ({ onContentChange, editorContent, isEditable }) => {
    const handleEditorChange = (e: any) => {
        const content = e.target.getContent();
        onContentChange(content);
    };
    return (
        <div className='col-lg-12 my-1'>
            <Card className='card_border my-1' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Editor
                    apiKey={tinyMCEApiKey}
                    init={{
                        plugins: 'link image code',
                        toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code',
                        statusbar: false, // Hide status bar including the paragraph indicator
                        placeholder: 'Enter your text here...' // Placeholder text
                    }}
                    initialValue={editorContent}
                    disabled={!isEditable || false}
                    onChange={handleEditorChange}
                />
            </Card>
        </div>
    );
};

export default RenderNotes;
