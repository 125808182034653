import React, { useEffect, useState } from 'react';
import './App.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import './i18n/config';
import { publicRoutes, routes } from './routes/routeConfig';
import LoginPage from './features/loginPage/LoginPage.component';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.min.css';
import { useDispatch } from 'react-redux';
import { getUserData, isAuthenticated } from './utils/utils';
import { setUser } from './redux/authSlice';
import ScrollToTop from './components/ScrollToTop';

// import ModalForm from "./components/new header/ModalForm";
function App() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const isLoggedIn = isAuthenticated();

    useEffect(() => {
        isLoggedIn && fetchUserData();
    }, [isLoggedIn]);

    useEffect(() => {
        const publicPaths = publicRoutes.map(item => item.path).includes(location.pathname);
        const privatePaths = routes.map(item => item.path).includes(location.pathname);
        window.scrollTo(0, 0);

        if (location.pathname === '/') {
            navigate('/goa-idc/');
        }
    }, [location]);

    const fetchUserData = async () => {
        const userData = await getUserData();
        dispatch(setUser(userData));
    };

    return (
        <div className='wrapper'>
            <ToastContainer autoClose={2000} style={{ color: 'black' }} />
            {/* <HeaderComponent /> */}

            <Routes>
                {isLoggedIn && routes.map(route => <Route key={route.path} path={route.path} Component={route?.component} />)}
                {publicRoutes.map(route => (
                    <Route key={route.path} path={route.path} Component={route?.component} />
                ))}
            </Routes>
            <ScrollToTop />
        </div>
    );
}

export default App;
