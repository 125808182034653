import { toast } from 'react-toastify';
import { devUrl, getUserDataOnRefresh, getUserPrimaryDesignation } from '../services/modulesServices/userManagement.api';
import { HierarchyNode, HierarchyNodeCard, OriginalMenuObject } from '../features/modules/UserManagement/types';
import { redirect } from 'react-router';
import axios from 'axios';
import { download, download_logo, file_preview, file_upload, jpgFormat, logo, pdfFormat, trash } from '../utils/Logo';
import { DocumentDetails, IconMap } from '../components/moduleCommonFormik/GenericFormCard.constants';
import _ from 'lodash';

export const saveToStorage = (key: string, data: any) => {
    const existingData = localStorage.getItem(key);
    if (!existingData) localStorage.setItem(key, JSON.stringify(data));
};

export const getUserData = async () => {
    try {
        const { data } = await getUserDataOnRefresh();

        if (data) {
            try {
                if (data.type === 'EMPLOYEE') {
                    const res = data && (await getUserPrimaryDesignation({ department: data?.departmentCode, designation: data?.designation }, data.type));
                    const primaryAssignment = { department: res?.Department?.[0], designation: res?.Designation?.[0] };
                    saveToStorage('currentUserAssignment', primaryAssignment);
                } else {
                    const res = data && (await getUserPrimaryDesignation({ designation: data?.designation }, data.type));
                    const primaryAssignment = { designation: res?.roles?.[0] };
                    saveToStorage('currentUserAssignment', primaryAssignment);
                }

                return data;
            } catch (error) {
                console.error('Error fetching user designation:', error);
            }
        }
    } catch (e: any) {
        const msg = e.response?.data?.Errors[0].code === 'BadRequest' && 'Token Expired';
        toast(msg, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo-bar',
            type: 'error'
        });
        localStorage.clear();
        window.location.href = '/goa-idc/';
    }
};

export const isAuthenticated = () => {
    const isAuth = localStorage.getItem('token');
    return isAuth;
};

export const getNavigateTo = (userType: string) => {
    switch (userType) {
        case 'EMPLOYEE':
            return '/goa-idc/dashboard';
        case 'ENTERPRISE':
            return '/goa-idc/enterprisedashboard';
        case 'EXTERNAL_EMPLOYEE':
            return '/goa-idc/architectdashboard';
        default:
            return '/goa-idc/';
    }
};

export function convertToHierarchy(data: OriginalMenuObject): HierarchyNode[] {
    const result: HierarchyNode[] = [];

    const pathMap = new Map<string, HierarchyNode>();

    function createObject(path: string, displayName: string, url: string, navigationURL: string): HierarchyNode {
        const pathArray = path.split('.');
        let currentLevel: HierarchyNode[] = result;

        pathArray.forEach((key, index) => {
            const existingObject = pathMap.get(key);

            if (existingObject) {
                currentLevel = existingObject.children;
            } else {
                const newObj: HierarchyNode = {
                    id: key,
                    name: key,
                    displayName: index === pathArray.length - 1 ? displayName : key,
                    url,
                    navigationURL,
                    path: path,
                    children: [],
                    moduleName: ''
                };

                pathMap.set(key, newObj);
                currentLevel.push(newObj);
                currentLevel = newObj.children;
            }
        });

        return currentLevel[0];
    }

    data.menus.forEach(menu => {
        createObject(menu.path, menu.displayName, menu.url, menu.navigationURL);
    });

    return result;
}

export function convertToHierarchyCard(cards: any[]): HierarchyNodeCard[] {
    const result: HierarchyNodeCard[] = [];
    const pathMap = new Map<string, HierarchyNodeCard>();

    cards.forEach(card => {
        const { id, name, displayName, path, ...rest } = card;
        const pathArray = path.split('.');
        let currentLevel: HierarchyNodeCard[] = result;

        pathArray.forEach((key: any, index: number) => {
            const existingObject = pathMap.get(key);

            if (existingObject) {
                currentLevel = existingObject.children;
            } else {
                const newObj: HierarchyNodeCard = {
                    id: key,
                    name: key,
                    displayName: index === pathArray.length - 1 ? displayName : key,
                    children: [],
                    path,
                    ...rest
                };

                pathMap.set(key, newObj);
                currentLevel.push(newObj);
                currentLevel = newObj.children;
            }
        });
    });

    return result;
}

export const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength) + '...';
};

export const tinyMCEApiKey = 'nxksuonqcrv3lw1014u5gu1lux8b4vmek7m8xofuhg9tszna';

export const extractFileUrl = (url: string) => {
    const validUrl = url.split('https://').filter(Boolean);
    if (validUrl.length > 1) {
        return 'https://' + validUrl[0].slice(0, -1);
    } else {
        return 'https://' + validUrl[0];
    }
};

export const formatedDocumentDetails = async (id: string, multi: boolean = false) => {
    if (id) {
        try {
            const res = await axios.get(devUrl + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: id } });
            const ids = res.data.fileStoreIds.map((item: DocumentDetails, index: number) => ({
                documentName: res?.data?.fileStoreIds[index].tag,
                documentSize: res?.data?.fileStoreIds[index].fileSize,
                referenceId: res?.data?.fileStoreIds[index].id,
                documentUrl: extractFileUrl(res.data.fileStoreIds[index].url),
                uploadedDate: res?.data?.fileStoreIds[index].uploadedDate
            }));
            if (multi) return ids;
            else return ids[0];
        } catch (e) {
            toast('Failed to fetch the document, Please try again after some time', {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo-bar',
                type: 'error'
            });
            return null;
        }
    }
};

export const getFileIcon = (documentName: string): string => {
    const fileExtension: string = documentName ? (documentName.split('.').pop()?.toLowerCase() as string) : 'png';
    return iconMap[fileExtension] || iconMap.default;
};

export const iconMap: IconMap = {
    pdf: pdfFormat,
    doc: 'doc-icon.png',
    docx: 'doc-icon.png',
    txt: 'txt-icon.png',
    jpg: jpgFormat,
    jpeg: jpgFormat,
    png: jpgFormat,
    default: 'default-icon.png'
};

export const setDataFromPrefix = (propertiesToSet: { [key: string]: string }, prefix: string, values: any): any => {
    return Object.entries(propertiesToSet).reduce((acc, [key, value]) => {
        const propertyName = (prefix ?? '') + key;
        _.set(acc, propertyName, value);
        return acc;
    }, values);
};

const getDirtyValues = (values: any, initialObject: any): object => {
    const data = { ...values };
    const keyValues = Object.keys(data);

    const dirtyValues = keyValues.filter(keyValue => data[keyValue] !== initialObject[keyValue]);

    keyValues.forEach(key => {
        if (!dirtyValues.includes(key)) delete data[key];
    });

    return data;
};

export { getDirtyValues };

export const changeDateFormat = (date: any) => {
    //yyyy-MM-dd
    const formattedDate = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
    return formattedDate;
};

export const changeDateFormatToddMMyyyy = (date: any) => {
    //dd/MM/yyyy
    const formattedDate = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + date.getFullYear();
    return formattedDate;
};

export function getFirstErrorKey(obj: any) {
    function traverse(current: any) {
        if (typeof current === 'string') {
            return path.join('.');
        }

        if (Array.isArray(current)) {
            for (let i = 0; i < current.length; i++) {
                path.push(i);
                const result: any = traverse(current[i]);
                if (result) return result;
                path.pop();
            }
        } else if (typeof current === 'object' && current !== null) {
            for (let key of Object.keys(current)) {
                path.push(key);
                const result: any = traverse(current[key]);
                if (result) return result;
                path.pop();
            }
        }

        return null;
    }

    let path: any = [];
    return traverse(obj);
}

export const getUniqueArray = (arr: any, property: string) => {
    const uniqueArray = arr.filter(
        (obj: any, index: number) =>
            index ===
            arr.findIndex(
                (t: any) => t[property] === obj[property] // Customize this condition based on your object structure
            )
    );
    return uniqueArray;
};

export function convertTableDateFormat(inputDate: string) {
    // Split the input date into date and time parts
    if (inputDate) {
        let [datePart, timePart] = inputDate.split(' ');

        // Split the date part into year, month, and day
        let [year, month, day] = datePart.split('-');

        // Format the date in DD/MM/YYYY format
        let formattedDate = `${day}/${month}/${year}` || '-';

        // Combine the formatted date with the time part

        return formattedDate;
    } else return '-';
}

export function convertDateFormat(inputDate: any) {
    //dd/mm/yyyy to mm/dd/yyyy
    const parts = inputDate.split('/');
    const dd = parts[0];
    const mm = parts[1];
    const yyyy = parts[2];
    const newDate = new Date(`${mm}/${dd}/${yyyy}`);
    const formattedDate = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
    return formattedDate;
}

export function changeDateFormatToIST(inputDate: any) {
    if (!inputDate) return inputDate;
    //yyyy-mm-dd to dd/mm/yyyy
    const [year, month, day] = inputDate?.split('-');
    const formattedDateStr = `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
    return formattedDateStr;
}
export const getDiffBWDates = (date1: any, date2: any) => {
    date1 = new Date(date1);
    date2 = new Date(date2);

    if (date1 > date2) {
        return false;
    }

    let diffYears = date2.getFullYear() - date1.getFullYear();
    let diffMonths = date2.getMonth() - date1.getMonth();
    let diffDays = date2.getDate() - date1.getDate();

    if (diffDays < 0) {
        diffMonths--;
        diffDays += new Date(date2.getFullYear(), date2.getMonth(), 0).getDate();
    }
    if (diffMonths < 0) {
        diffYears--;
        diffMonths += 12;
    }

    // return `${diffYears} Year(s) ${diffMonths} Month(s)  ${diffDays} Day(s)`;
    let result = `${diffYears} Year(s) ${diffMonths} Month(s)`;
    if (diffDays > 0) {
        result += ` ${diffDays} Day(s)`;
    }
    return result;
};

export function epochToDate(epoch: any) {
    let date = new Date(epoch);

    // Extract day, month, year
    let day = date.getDate();
    let month = date.getMonth() + 1; // getMonth() returns 0-based month
    let year = date.getFullYear();

    // Ensure day and month have two digits
    let formattedDay = day < 10 ? '0' + day : day;
    let formattedMonth = month < 10 ? '0' + month : month;

    // Return formatted date
    return formattedDay + '/' + formattedMonth + '/' + year;
}

export function convertMonDDYYYYtoDDmmYYYY(inputDate: any) {
    //USE convertDateToDDmmYYYY INSTEAD
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, '0'); // Day of the month (1-31)
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month (1-12)
    const year = date.getFullYear(); // Full year (YYYY)
    return `${day}/${month}/${year}`;
}

export function convertTime24to12(time: any) {}

export function dateToEpoch(inputDate: any) {
    //if date format is yyyy-MM-dd
    const date = new Date(inputDate);
    const epochTimestamp = date.getTime();
    return epochTimestamp;
}

export function dateToEpochWithTime(inputDate: any) {
    const [datePart, timePart, period] = inputDate.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes] = timePart.split(':');

    let hour = parseInt(hours, 10);
    if (period === 'PM' && hour !== 12) {
        hour += 12;
    } else if (period === 'AM' && hour === 12) {
        hour = 0;
    }

    const date = new Date(year, month - 1, day, hour, minutes);
    const epochTimestamp = Math.floor(date.getTime());
    return epochTimestamp;
}

export function currentTime() {
    const timeStamp = Date.now();
    const dateUTC = new Date(timeStamp);

    const day = dateUTC.getDate();
    const month = dateUTC.getMonth() + 1;
    const year = dateUTC.getFullYear();

    let hour = dateUTC.getHours();
    const minute = dateUTC.getMinutes();
    const ampm = hour >= 12 ? 'PM' : 'AM';

    hour = hour % 12;
    hour = hour ? hour : 12;

    const dayStr = day < 10 ? `0${day}` : day;
    const monthStr = month < 10 ? `0${month}` : month;
    const minuteStr = minute < 10 ? `0${minute}` : minute;

    const formattedDate = `${hour}:${minuteStr} ${ampm}`;

    return formattedDate;
}

export function currentDate_ddMMyyyy() {
    const timeStamp = Date.now();
    const dateUTC = new Date(timeStamp);

    const day = dateUTC.getDate();
    const month = dateUTC.getMonth() + 1;
    const year = dateUTC.getFullYear();

    const dayStr = day < 10 ? `0${day}` : day;
    const monthStr = month < 10 ? `0${month}` : month;
    const yearStr = `${year}`;

    const currentDate = `${dayStr}/${monthStr}/${yearStr}`;
    return currentDate;
}

export const getEpoch = (inputDate: string) => {
    //if date format is dd/MM/yyyy
    const [day, month, year] = inputDate?.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    return date.getTime();
};

export const getEpochyyyyMMdd = (inputDate: string) => {
    //if date format is yyyy-MM-dd
    const [year, month, day] = inputDate?.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    return date.getTime();
};

export const getCorrectDateFormate = (dateValue: any) => {
    // Unix timestamp in milliseconds
    const timestamp = dateValue;

    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Extract the year, month, and day
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');

    // Format as yyyy-MM-dd
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
};

export const changeDateToYYYYMMDD = (dateVal: any) => {
    const date = new Date(dateVal);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
};

export const convertDateTimeToISOFormate = (date: any) => {
    // Input date string
    if (date) {
        const dateString = date;
        const parsedDate = new Date(dateString.replace(' ', 'T'));

        // Step 2: Manually format the Date object to YYYY-MM-DDTHH:mm:ss (local time)
        const year = parsedDate.getFullYear();
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(parsedDate.getDate()).padStart(2, '0');
        const hours = String(parsedDate.getHours()).padStart(2, '0');
        const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
        const seconds = String(parsedDate.getSeconds()).padStart(2, '0');

        // Combine into the desired format
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

        return formattedDate;
    }
};

export const convertDateToDDmmYYYY = (dateString: any) => {
    // Regular expression to match the DD/MM/YYYY format
    const ddmmyyyyFormat = /^\d{2}\/\d{2}\/\d{4}$/;

    // If the date is already in DD/MM/YYYY format, return it as-is
    if (ddmmyyyyFormat.test(dateString)) {
        return dateString;
    }

    // Otherwise, convert from MM-DD-YYYY to DD/MM/YYYY format
    if (dateString) {
        const [year, month, day] = dateString?.split('-');
        return `${day}/${month}/${year}`;
    }
};

export const getAbsoluteMonthsBetweenTwoDates = (date1: any, date2: any) => {
    // this function returns the month difference between two different dates
    const start = new Date(date1);
    const end = new Date(date2);

    const yearsDifference = end.getFullYear() - start.getFullYear();
    const monthsDifference = end.getMonth() - start.getMonth();

    return Math.abs(yearsDifference * 12 + monthsDifference);
};

export const isAfter = (date1: any, date2: any) => {
    // this function will take two dates to compare the greatest among them
    const formatToISO = (date: any) => {
        // in this the date will be checked if it is in dd/mm/yyyy formate. if it is it will convert it as yyyy-mm-dd formate
        if (!date) return null;
        const dateStr = String(date);
        if (dateStr.includes('/')) {
            const [day, month, year] = dateStr.split('/');
            return `${year}-${month}-${day}`;
        }
        return dateStr;
    };

    const formattedDate1 = formatToISO(date1); //the date will be converted in yyyy-mm-dd
    const formattedDate2 = formatToISO(date2);

    // Compare the dates if both are present
    return formattedDate1 && formattedDate2 ? new Date(formattedDate1) > new Date(formattedDate2) : true; // Consider it valid if one date is missing
};

export const dateWithTimeToddMMyyyy = (date: string) => {
    if (date) {
        const dateString = date;
        const newDate = new Date(dateString);
        const formattedDate = `${newDate.getDate().toString().padStart(2, '0')}/${(newDate.getMonth() + 1).toString().padStart(2, '0')}/${newDate.getFullYear()}`;
        return formattedDate;
    }
};
