import React from 'react';
import './GenericStyles.scss';
import { ProgressCircle, ProgressCircleCheck, ProgressErrorCircle, ProgressRectangleBar, ProgressWarningCircle } from './ProgressBarIcons';
import { useTranslation } from 'react-i18next';

const ProgressBar = ({ progressBarDetails }: any) => {
    // const indexLength: number = Math.floor(progressBarDetails?.progressStatus?.length / 3);
    // const inProgress: any = progressBarDetails?.progressStatus?.find((item: any) => item?.isCurrent === true);
    // const widthOfProgress: string = progressBarDetails ? `${+progressBarDetails?.percentage + 6}%` : '0%';
    const { t } = useTranslation();

    console.log('progressBar', progressBarDetails?.progressStatus?.length + 3);

    const getWidth = (items: number) => {
        switch (items) {
            // case 1:
            //     return 100;
            // case 2:
            //     return 100;
            default:
                return 100;
        }
    };

    const getGradWidth = (items: number) => {
        switch (items) {
            case 1:
                return 100;
            case 2:
                return 100;
            case 3:
                return 50;
            case 4 || 5 || 6:
                return 50;
            default:
                return 10;
        }
    };

    const getStatusCircle = (currentItem: any) => {
        console.log(currentItem);
        switch (true) {
            case currentItem.isDeskPendency:
                return <ProgressWarningCircle />;
            case currentItem.isRejected:
                return <ProgressErrorCircle />;
            case currentItem.isCurrentState:
                return <ProgressCircle color='#3A7425' />;
            case currentItem.isVisited:
                return <ProgressCircleCheck color='#3A7425' />;
            case !currentItem.isVisited:
                return <ProgressCircle color='#737B84' />;
            default:
                return <ProgressCircle color='#3A7425' />;
        }
    };

    const getStatusBar = (currentItem: any) => {
        console.log(currentItem);
        const dataLength = progressBarDetails?.progressStatus?.length;
        switch (true) {
            case currentItem.isDeskPendency:
                return <ProgressRectangleBar color='#3A7425' width={`${getWidth(dataLength)}%`} gradWidth={`${getGradWidth(dataLength)}%`} secondaryColor={'#BC9633'} />;
            case currentItem.isRejected:
                return <ProgressRectangleBar color='#3A7425' width={`${getWidth(dataLength)}%`} gradWidth={`${getGradWidth(dataLength)}%`} secondaryColor={'#C44744'} />;
            case currentItem.isCurrentState:
                return <ProgressRectangleBar color='#3A7425' width={`${getWidth(dataLength)}%`} gradWidth={`${getGradWidth(dataLength)}%`} secondaryColor={'#3A7425'} />;
            case currentItem.isVisited:
                return <ProgressRectangleBar color='#3A7425' width={`${getWidth(dataLength)}%`} gradWidth={`${getGradWidth(dataLength)}%`} secondaryColor={'#3A7425'} />;
            case !currentItem.isVisited:
                return <ProgressRectangleBar color='#737B84' width={`${getWidth(dataLength)}%`} gradWidth={`${getGradWidth(dataLength)}%`} secondaryColor={'#737B84'} />;
            default:
                return <ProgressRectangleBar color='#737B84' width={`${getWidth(dataLength)}%`} gradWidth={`${getGradWidth(dataLength)}%`} secondaryColor={'#737B84'} />;
        }
    };

    return (
        <>
            <div className='container progressbar_parents font-inter' style={{ marginBottom: '10%' }}>
                <div className='row'>
                    <div className='col-sm-12 d-flex align-items-center '>
                        {progressBarDetails?.progressStatus?.map((data: any, index: any) => (
                            <>
                                {index ? getStatusBar(data) : <></>}
                                <div className='position-relative' style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    {getStatusCircle(data)}
                                    <div className='position-absolute progress_checkout_person' style={{ marginTop: '10px' }}>
                                        <p className=' name_of_person mb-0 p-0'>{t(data.stage, `${data.stage}`)}</p>
                                        <p className=' designation_of_person mb-0 fst-italic'>{data.actor}</p>
                                        <p className='date_of_checkout  mb-0' style={{ color: `${data.isCurrent ? '#45852E' : '#00000099'}` }}>
                                            {data.date || '--'}
                                        </p>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                {/* <div className='row '>
                    <div className='progress progressbar ' role='progressbar'>
                        <div className='progress-bar inner_bar' style={{ width: widthOfProgress }}></div>
                        {!inProgress ? <Image src={progress_check} className='progress-check' title='Task Completed' /> : <Image src={progress_uncheck} className='progress-uncheck' />}
                    </div>

                    <div className={`col-sm-12 mt-4 d-flex justify-content-between`}>
                        {progressBarDetails?.progressStatus?.map((data: any, index: number) => (
                            <div
                                className={`${progressBarDetails?.progressStatus.length > 6 ? 'col-1' : 'col-2'}  text-center d-flex flex-column align-items-center progress_checkout_person ${
                                    data.isCurrent ? 'active' : ''
                                }  `}
                                key={index}
                            >
                                <div className={` ${data.isCurrent ? 'arrow' : ''}`} style={{ backgroundColor: `${index < indexLength && data.isCurrent ? '#72A635' : '#45852E'}` }}></div>
                                <p className='name_of_person mb-0 p-0'>{t(data.stage, '')}</p>
                                <p className='designation_of_person mb-0 fst-italic '>{data.actor}</p>
                                <p className='date_of_checkout  mb-0' style={{ color: `${data.isCurrent ? '#45852E' : '#00000099'}` }}>
                                    {data.date}
                                </p>
                            </div>
                        ))}
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default ProgressBar;
