import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Card, CardBody } from 'react-bootstrap';
import calculator_logo from '../../../../assets/logos/calculator.svg';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { CALCULATED_OWNERSHIP_TABLE_COLUMNS, ENTER_OTP, OWNERSHIP_DETAILS, OWNERSHIP_TABLE_COLUMNS, WELCOME_BACK } from '../UserManagement.const';
import { useFormikContext } from 'formik';
import { ENTERPRISE_REGISTRATION_VALIDATION, OWNERSHIP_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { v4 as uuidv4 } from 'uuid';
import { IOwnershipDetails, IOwnershipTable } from '../types';
import { getDropdownSelectedObject } from '../UserMangement.controller';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import './EnterpriseStyles.scss';
import { OwnershipTable } from './OwnershipTable';
import { TENANT_ID } from '../../../../utils/constants';
import { setEntityDocumentDetails } from '../../Migration/Migration.controller';
import axios from 'axios';
import { devUrl } from '../../../../services/modulesServices/userManagement.api';
import { DocumentDetails } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import ToolTipDetails from '../../../../components/ToolTipFile';
import { getFirstErrorKey } from '../../../../utils/utils';
import { cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import { isDisabled } from '@testing-library/user-event/dist/utils';

interface IEnterOtpProps {
    data: string;
    onVerifyBtnClick?: any;
}
interface IOwnershipProps {
    setOwnershipDetails: (data: any) => void;
    ownershipDetails: IOwnershipDetails[];
    dropdownOptions: any;
    calculateOwnership: () => void;
    setShowCalculatedTable: (data: boolean) => void;
    showCalculatedTable: boolean;
    setShowOwnershipModal: (data: boolean) => void;
    showOwnershipModal: boolean;
    controllingOwnership?: IOwnershipDetails[];
    totalOwnershipPercentage?: number;
    setTotalOwnershipPercentage?: (data: any) => any;
    controllingOwnershipResponse?: any;
    toolTipDetails?: any;
    isDisabled?: boolean;
    hideCard?: boolean;
    scrollNotRequired?: boolean;
    skipFullOwnershipPercent?: boolean;
    isWorkflow?: boolean;
}

export const OwnershipDetails = (props: IOwnershipProps) => {
    const { t } = useTranslation();
    const { values, setValues, setTouched, setFieldValue, errors, setFieldTouched } = useFormikContext<any>();
    const [ownershipDetailsView, setOwnershipDetailsView] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState({});
    const [openIndex, setOpenIndex] = useState<any>(null);

    // const [ownershipDetailsDisabled, setOwnershipDetailsDisabled] = useState(OWNERSHIP_DETAILS);
    const [ownershipTable, setOwnershipTable] = useState<any>([]);
    const [ownershipDetailsData, setOwnershipDetailsData] = useState<any>(null);

    useEffect(() => {
        let tempOwnershipData;
        if (props.isWorkflow || !values?.isUpdatable) {
            const tempOwnershipTableColumn = OWNERSHIP_TABLE_COLUMNS?.filter((val: any) => val?.type !== 'action');
            setOwnershipTable(tempOwnershipTableColumn);
            tempOwnershipData = OWNERSHIP_DETAILS;
        } else if (props.isDisabled) {
            const tempOwnershipTableColumn = OWNERSHIP_TABLE_COLUMNS?.map((val: any) => (val?.type === 'action' ? { ...val, actions: ['edit', 'view'] } : { ...val }));
            setOwnershipTable(tempOwnershipTableColumn);

            tempOwnershipData = OWNERSHIP_DETAILS?.map((item: any) => ({
                ...item,
                formData: item.formData?.map((val: any) => (val.field === 'isLocalPerson' || val.field === 'ownershipLocalPersonDocument' ? { ...val } : { ...val, isDisabled: props.isDisabled }))
            }));
            // console.log('tempOwnershipData', tempOwnershipData);
            // setOwnershipDetailsData(tempOwnershipData);
        } else {
            tempOwnershipData = OWNERSHIP_DETAILS;
            setOwnershipTable(OWNERSHIP_TABLE_COLUMNS);
        }

        let tempIndOrOrgOwnershipData;
        if (values?.individualOrOrganization === 'Organization') {
            tempIndOrOrgOwnershipData = tempOwnershipData?.map((item: any) => {
                if (values.individualOrOrganization === 'Organization') {
                    item = {
                        ...item,
                        formData: item.formData?.filter(
                            (oneField: any) =>
                                oneField.field !== 'ownershipHighestQualification' &&
                                oneField.field !== 'ownershipGender' &&
                                oneField.field !== 'ownershipLocalPersonDocument' &&
                                oneField.field !== 'isLocalPerson' &&
                                oneField.field !== 'ownershipEducationDocument'
                        )
                    };
                    setValues({ ...values, isLocalPerson: false, ownershipGender: undefined });
                }

                return item;
            });
        } else {
            tempIndOrOrgOwnershipData = tempOwnershipData;
        }

        console.log('tempIndOrOrgOwnershipData', tempIndOrOrgOwnershipData);
        let ownershipDataWithAddress;

        if (values?.isOwnershipPincodeDetailsPresent) {
            ownershipDataWithAddress = tempIndOrOrgOwnershipData?.map((val: any) => ({
                ...val,
                formData: val?.formData?.map((item: any) =>
                    item?.field === 'ownershipdistrict' || item?.field === 'ownershipstate' || item?.field === 'ownershipcountry' ? { ...item, isDisabled: true } : { ...item }
                )
            }));
        } else {
            ownershipDataWithAddress = tempIndOrOrgOwnershipData?.map((val: any) => ({
                ...val,
                formData: val?.formData?.map((item: any) =>
                    item?.field === 'ownershipdistrict' || item?.field === 'ownershipstate' || item?.field === 'ownershipcountry' ? { ...item, isDisabled: false, isRequired: true } : { ...item }
                )
            }));
        }

        let tempMigrationOwnershipData;
        if (values.isMigration) {
            let formData = ownershipDataWithAddress[0]?.formData?.map((item: any) => {
                if (item.field === 'ownershipContactNo') {
                    item.isRequired = false;
                }
                return item;
            });
            tempMigrationOwnershipData = [{ ...ownershipDataWithAddress[0], formData }];
        } else {
            tempMigrationOwnershipData = ownershipDataWithAddress;
        }

        setOwnershipDetailsData(tempMigrationOwnershipData);
    }, [props.isDisabled, values.individualOrOrganization, values?.isOwnershipPincodeDetailsPresent, values.isMigration]);

    const updateOwnershipDataStatus = (status: boolean = true, row?: any) => {
        let formData;
        if (props?.isDisabled && !status) {
            formData = ownershipDetailsData[0].formData;
        } else {
            formData = ownershipDetailsData[0]?.formData?.map((item: any) => {
                item.isDisabled = status;
                return item;
            });
        }

        setOwnershipDetailsData([{ ...ownershipDetailsData[0], formData }]);
        setOwnershipDetailsView(status);
        status && onClickSerialNumber(row);
    };

    // useEffect(() => {
    //     if (ownershipDetailsData) {
    //         const ownershipDetails = ownershipDetailsData?.map((item: any) => {
    //             if (values.individualOrOrganization === 'Organization') {
    //                 item = {
    //                     ...item,
    //                     formData: item.formData?.filter(
    //                         (oneField: any) =>
    //                             oneField.field !== 'ownershipHighestQualification' &&
    //                             oneField.field !== 'ownershipGender' &&
    //                             oneField.field !== 'ownershipLocalPersonDocument' &&
    //                             oneField.field !== 'isLocalPerson' &&
    //                             oneField.field !== 'ownershipEducationDocument'
    //                     )
    //                 };
    //                 setValues({ ...values, isLocalPerson: false, ownershipGender: undefined });
    //             }

    //             return item;
    //         });

    //         console.log(ownershipDetails, 'ownershipDetails11');
    //         setOwnershipDetailsData(ownershipDetails);
    //     }
    // }, [values.individualOrOrganization, ownershipDetailsData]);

    const {
        setOwnershipDetails,
        ownershipDetails,
        dropdownOptions,
        calculateOwnership,
        showCalculatedTable,
        setShowCalculatedTable,
        setShowOwnershipModal,
        showOwnershipModal,
        controllingOwnership,
        totalOwnershipPercentage,
        setTotalOwnershipPercentage,
        controllingOwnershipResponse,
        hideCard,
        scrollNotRequired
    } = props;
    const [isEditing, setIsEditing] = useState<any>(false);

    useEffect(() => {
        controllingOwnershipResponse?.length && setShowCalculatedTable(true);
    }, [controllingOwnershipResponse]);

    const ownershipDetailsSerailNumber = useMemo(() => {
        return ownershipDetails?.filter(data => !data.isDelete).length + 1;
    }, [ownershipDetails]);

    const addOwnershipDetails = async () => {
        try {
            await OWNERSHIP_VALIDATION.validate(values, { abortEarly: false });
            setShowOwnershipModal(false);
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else setTouched({ ownershipHighestQualification: true, ownershipGender: true });
            if (values.isMigration && errorField === 'ownershipContactNo') {
                setModalDetails({ show: true, title: 'Error!', para: t('VALID_MOB_NO', 'Please enter valid mobile number!'), img: cross, type: 'error', reUpload: 'OK' });
            } else {
                setModalDetails({ show: true, title: 'Error!', para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
            return;
        }
        const newUuid = uuidv4();

        const ownershipPayload = {
            tenantId: TENANT_ID,
            // serialNo: !isEditing ? values.ownership.length + 1 : values.serialNo,
            salutation: values.ownershipFirstnamesalutation,
            name: values.ownershipName,
            ownershipInterest: +values.ownershipInterest, //@NumberFormat "##0.00"
            gender: values.ownershipGender?.code,
            mobileNumber: values.ownershipContactNo || null,
            contactNo: values.ownershipContactNo || null,
            email: values.ownershipEmail, //mobile and mail can't be duplicate of other owner
            address1: values.ownershipAddress1,
            address2: values?.ownershipAddress2 || '',
            pinCode: values.ownershippincode,
            district: values.ownershipdistrict,
            state: values.ownershipstate,
            country: values.ownershipcountry,
            pan: values.ownershipPanNo, // format "[A-Z0-9]{10}$"
            panReferenceId: values.ownershipPanCardDocument,
            isLocalPerson: values.isLocalPerson,
            ownerDocuments: [
                setEntityDocumentDetails(values?.ownershipPanCardDocument) || null,
                setEntityDocumentDetails(values?.ownershipEducationDocument) || null,
                setEntityDocumentDetails(values?.ownershipLocalPersonDocument) || null
            ].filter(Boolean),

            serialNo: !isEditing ? ownershipDetails.length + 1 : values.serialNo,
            genderValue: values.ownershipGender?.name,
            highestQualificationValue: values.ownershipHighestQualification?.name,
            residentialAddress:
                (values.ownershipAddress1 || '') +
                ', ' +
                (values.ownershipAddress2 || '') +
                `${values.ownershipAddress2 ? ', ' : ''}` +
                (values.ownershipdistrict || '') +
                ', ' +
                (values.ownershipstate || ''),
            highestQualification: values.ownershipHighestQualification?.code,
            ownershipLocalPersonDocument: values.isLocalPerson ? values.ownershipLocalPersonDocument : undefined,
            individualOwner: values.individualOrOrganization === 'Individual' ? true : false,
            uuid: values.uuid,
            entityId: values.entityId,
            rowId: isEditing ? values.rowId : newUuid
        };

        // console.log('ownership payload', ownershipPayload);

        if (isEditing) {
            const updatedData = ownershipDetails?.map((data: IOwnershipDetails) => {
                if (data.rowId === isEditing) return ownershipPayload;
                else return data;
            });
            setOwnershipDetails([...updatedData]);
        } else {
            setOwnershipDetails([...ownershipDetails, ownershipPayload]);
        }
        setShowOwnershipModal(false);
        updateOwnershipDataStatus(false);

        // setFieldValue('totalOnwership', values.totalOnwership + +values.ownershipInterest);
    };

    const onDeleteData = (rowId = null) => {
        // let newData = ownershipDetails?.filter((item: any) => item.rowId !== isEditing);
        // newData = newData?.map((item, index: number) => {
        //     item.serialNo = index + 1;
        //     return item;
        // });

        let newData = ownershipDetails?.map((data, _: number) => {
            if (data.rowId === rowId) {
                data['isDelete'] = true;
            } else if (data.rowId === isEditing) {
                data['isDelete'] = true;
            }
            return data;
        });

        setOwnershipDetails(newData);
        setShowCalculatedTable(false);
        setShowOwnershipModal(false);
        setIsEditing(false);
        setValues({ ...values, ownership: [] });
    };

    const showForm = () => {
        const val = _.cloneDeep(values);
        setIsEditing(false);
        setOpenIndex(null);
        const keysToDelete = [
            'ownershipInterest',
            'ownershipName',
            'ownershipGender',
            'ownershipAddress1',
            'ownershipAddress2',
            'ownershipContactNo',
            'ownershipEmail',
            'ownershippincode',
            'ownershipdistrict',
            'ownershipstate',
            'ownershipPanNo',
            'ownershipPanCardDocument',
            'ownershipHighestQualification',
            'ownershipStream',
            'ownershipYearOfPassing',
            'ownershipUniversity',
            'ownershipLocalPersonDocument',
            'ownershipEducationDocument',
            'rowId',
            'uuid',
            'entityId'
            // 'individualOrOrganization'
        ];
        keysToDelete.forEach(key => {
            delete val[key];
        });

        const totalOwnership = ownershipDetails
            ?.filter((data: any) => !data.isDelete)
            .reduce((total: any, item: any) => {
                return total + +item.ownershipInterest;
            }, 0);
        console.log('call', ownershipDetails, totalOwnership);
        setValues({ ...val, isLocalPerson: false, ownership: [], totalOwnership: totalOwnership });
        setTouched({});
        setShowOwnershipModal(true);
        setShowCalculatedTable(false);
    };

    const onClickSerialNumber = async (data: IOwnershipTable) => {
        const entityDocumentData = async (docs: any) => {
            const formatedEntityDocuments: any = [];
            const refIDs = docs?.map((docItem: any, _: number) => docItem.docReferenceId);
            if (refIDs) {
                const { data } = await axios.get(devUrl + '/filestore/v1/files/url', { params: { tenantId: 'ga', fileStoreIds: refIDs?.join() } });

                docs?.map((doc: any, index: number) => {
                    data.fileStoreIds.map((baseDoc: DocumentDetails) => {
                        if (baseDoc.id === doc.docReferenceId) {
                            formatedEntityDocuments.push({ ...doc, ...baseDoc });
                        }
                    });
                });

                return formatedEntityDocuments;
            }
        };

        const index: any = ownershipDetails.find((item, _) => item.rowId === data.rowId);
        // const ind: any = ownershipDetails.findIndex((item, _) => item.rowId === data.rowId);
        const formIndex: any = ownershipDetails.filter(item => item.isDelete !== true).findIndex((item, _) => item.rowId === data.rowId);
        console.log(formIndex);
        setOpenIndex(formIndex > -1 ? formIndex + 1 : null);
        if (!index) return;

        const documentData: any = await entityDocumentData(index?.ownerDocuments);

        const formData = {
            // ...data,
            tenantId: 'ga',
            ownershipFirstnamesalutation: index.salutation,
            ownershipName: index.name,
            ownershipInterest: +index.ownershipInterest, //@NumberFormat "##0.00"
            ownershipGender: getDropdownSelectedObject(index.gender, dropdownOptions['gender']),
            ownershipContactNo: index.mobileNumber,
            ownershipEmail: index.email, //mobile and mail can't be duplicate of other owner
            ownershipAddress1: index.address1,
            ownershipAddress2: index.address2,
            ownershippincode: index.pinCode,
            ownershipdistrict: index.district,
            ownershipstate: index.state,
            ownershipcountry: index.country,
            ownershipPanNo: index.pan, // format "[A-Z0-9]{10}$"
            ownershipPanCardDocument: documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.PAN),
            ownershipHighestQualification: getDropdownSelectedObject(index?.highestQualification, dropdownOptions['ownershipHighestQualification']),
            ownershipStream: index?.education?.stream,
            ownershipYearOfPassing: index?.education?.yearOfPassing,
            ownershipUniversity: index?.education?.university,
            ownershipLocalPersonDocument: documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.LOCALPERSON),
            ownershipEducationDocument: documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.EDUCATION),
            uuid: index.uuid,
            entityId: index.entityId,
            rowId: index.rowId ? index.rowId : null,
            isUpdate: index.rowId ? true : null,
            serialNo: index.serialNo,
            isActive: index.isActive,
            isDelete: index.isDelete,
            auditDetails: index.auditDetails,
            ownerDocuments: index.ownerDocuments,
            isLocalPerson: index.isLocalPerson,
            individualOrOrganization: index.individualOwner ? 'Individual' : 'Organization'
        };

        const totalOwnership = ownershipDetails
            ?.filter((data: any) => !data.isDelete)
            .reduce((total: any, item: any) => {
                return total + +item.ownershipInterest;
            }, 0);

        console.log('call', ownershipDetails, totalOwnership - +index.ownershipInterest);
        setIsEditing(index.rowId);
        setValues({ ...values, ...formData, ownership: [], totalOwnership: totalOwnership - +index.ownershipInterest });
        setShowOwnershipModal(true);
        values.isUpdatable && setShowCalculatedTable(false);
    };

    // useEffect(() => {
    //     if (values.isMigration && ownershipDetailsData) {
    //         let formData = ownershipDetailsData[0]?.formData?.map((item: any) => {
    //             if (item.field === 'ownershipContactNo') {
    //                 item.isRequired = false;
    //             }
    //             return item;
    //         });
    //         setOwnershipDetailsData([{ ...ownershipDetailsData[0], formData }]);
    //     }
    // }, [ownershipDetailsData]);

    const renderOwnership = () => {
        return (
            <>
                <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                <Card.Body>
                    <div className=' container ownership'>
                        <div className='row justify-content-between row-gap-4 '>
                            <div className='col-9'>
                                <p className='ownership_heading d-flex'>
                                    {t('OWNERSHIP_INTEREST_DETAILS', 'Ownership Interest')}
                                    <span className='text-danger'>*</span>
                                    <span>{props?.toolTipDetails && <ToolTipDetails toolTipDetails={props?.toolTipDetails['ownershipDetails']} />}</span>
                                </p>
                            </div>
                            {/* <Button variant=" text-white outline-success btn btn-light me-3 font-karla-med fw-normal   add_button" style={{ borderColor: "#45852e", width: 125, height: 50 }} onClick={showForm}>
                                    {t("ADD", "+ ADD")}
                                </Button> */}
                            <GenericButtons
                                ClassName='mb-2'
                                text={t('ADD', '+ ADD')}
                                handleClick={showForm}
                                variant='primary'
                                disabled={!values.isUpdatable || totalOwnershipPercentage === 100 || props?.isDisabled}
                            />
                        </div>
                        <div className='ownership_row'>
                            <>
                                <OwnershipTable
                                    column={ownershipTable}
                                    tableRowData={ownershipDetails}
                                    additionalMethod={onClickSerialNumber}
                                    updateOwnershipDataStatus={updateOwnershipDataStatus}
                                    setTotalOwnershipPercentage={setTotalOwnershipPercentage}
                                    deleteOwnershipDetails={rowId => onDeleteData(rowId)}
                                    // isDisabled={props?.isDisabled}
                                    hideSearchBar={true}
                                    scrollNotRequired={scrollNotRequired}
                                />
                            </>
                        </div>
                    </div>
                    <GenericPopupModal
                        title={`${openIndex ? (ownershipDetailsView ? t('VIEW', 'View') : t('EDIT', 'Edit')) : t('ADD', 'Add')} Ownership Interest Details - ${
                            openIndex ? openIndex : ownershipDetailsSerailNumber
                        }`}
                        subTitle={t('ENTERPRISE_OWNERSHIP_DETAILS', 'Ownership details for the Enterprise')}
                        size='lg'
                        primaryAction={addOwnershipDetails}
                        secondaryAction={() => {
                            onDeleteData();
                        }}
                        isVisible={showOwnershipModal}
                        children={<DETAILS_DATA ownershipDetailsData={ownershipDetailsData} dropdownOptions={dropdownOptions} />}
                        primaryBtnTitle={ownershipDetailsView ? t('CLOSE', 'Close') : t('SAVE', 'Save')}
                        secondaryBtnTitle={t('CLOSE', 'Close')}
                        onClose={() => {
                            setShowOwnershipModal(false);
                            updateOwnershipDataStatus(false);
                        }}
                        isPrimaryBtn={values.isUpdatable}
                        isSecondaryBtn={values.isUpdatable && !ownershipDetailsView && !isEditing}
                    />
                </Card.Body>
                <Card.Body>
                    <div className='container controlling_ownership'>
                        <div className='row justify-content-between'>
                            <div className='col-9 px-3 d-flex align-items-center'>
                                <p className='controlling_ownership_heading'>{t('PROMOTERS_DETAILS', 'Promoter(s) Details')}</p>
                                <span>{props?.toolTipDetails && <ToolTipDetails toolTipDetails={props?.toolTipDetails['promoterDetails']} />}</span>
                            </div>
                            {!showCalculatedTable ? (
                                // eslint-disable-next-line eqeqeq
                                <button
                                    type='button'
                                    className='col-sm-12 py-2 px-3 rounded controlling_ownership_button'
                                    onClick={calculateOwnership}
                                    disabled={props?.skipFullOwnershipPercent ? totalOwnershipPercentage == 0 : totalOwnershipPercentage != 100}
                                >
                                    <img src={calculator_logo} alt='calculator' className='me-2' /> {t('CLACULATE_CONTROLLING_OWNERSHIP', 'Calculate controlling ownership')}
                                </button>
                            ) : (
                                <OwnershipTable column={CALCULATED_OWNERSHIP_TABLE_COLUMNS} tableRowData={controllingOwnership} hideSearchBar={true} scrollNotRequired={true} />
                            )}
                        </div>
                    </div>
                </Card.Body>
            </>
        );
    };

    return <>{!hideCard ? <Card className='card_border'>{renderOwnership()}</Card> : renderOwnership()}</>;
};

const DETAILS_DATA = ({ dropdownOptions, ownershipDetailsData }: any) => {
    return (
        <>
            {ownershipDetailsData?.map((val: any) => (
                <Card className=' mb-4'>
                    <Card.Body>
                        <GenericFormCard module='usermanagement' {...val} dropdownOptions={dropdownOptions} />
                    </Card.Body>
                </Card>
            ))}
        </>
    );
};

export const EnterOtp = (props: IEnterOtpProps) => {
    const { t } = useTranslation();
    const { onVerifyBtnClick } = props;
    const [timer, setTimer] = useState(60);

    const { values, setValues } = useFormikContext<any>();

    useEffect(() => {
        setValues({ ...values, otp: '' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let interval: any;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    const handleResendOtp = () => {
        onVerifyBtnClick();
        setTimer(60);
    };
    return (
        <>
            <div className='container'>
                <div className='row px-3'>
                    {ENTER_OTP.map((val: any) => (
                        <GenericFormCard module='usermanagement' {...val} />
                    ))}

                    {timer > 0 ? (
                        <p className='resend-otp pe-4'>
                            {t('RESEND_OTP_IN', 'Resend OTP in ')} &nbsp;{timer} {t('SECONDS', 'seconds')}
                        </p>
                    ) : (
                        <div className='resend-otp pe-4' onClick={handleResendOtp}>
                            {t('RESEND_OTP', 'Resend OTP')}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export const WelcomePopUp = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='container'>
                <div className='row px-3'>
                    {WELCOME_BACK.map((val: any) => (
                        <GenericFormCard module='usermanagement' {...val} />
                    ))}
                </div>
                <Card className=' welcome_notes mx-4 '>
                    <CardBody className='row'>
                        <span className='col-2 p-0'>
                            <i className='fa fa-info-circle' aria-hidden='true' style={{ color: 'red' }}></i> {t('NOTE', 'Note :')}
                        </span>
                        <span className='col-10 p-0'>
                            {t(
                                'PREVIOUS_APPLICATION_WARNING',
                                'At this point, if you choose not to proceed with the previous application, the information submitted to the Goa Industrial Development Corporation will be deleted. You will need to begin a completely new application.'
                            )}
                        </span>
                    </CardBody>
                </Card>
            </div>
        </>
    );
};
