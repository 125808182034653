import React from 'react';
import { LESSEE_DETAILS } from './SubLeaseConst';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';

const LesseeDetails = () => {
    return (
        <>
            {LESSEE_DETAILS.map((val: any, id: number) => (
                <GenericFormCard {...val} />
            ))}
        </>
    );
};

export default LesseeDetails;
