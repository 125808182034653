import React from 'react';
import { Card } from 'react-bootstrap';
import arrowRight from '../../../assets/logos/ArrowUpRight1.png';

const CustomCard = (props: any) => {
    const { compScore } = props;

    function getRating(eachCardCompScore: any) {
        const score = parseFloat(eachCardCompScore);
        if (score >= 91 && score <= 100) {
            return 'A+';
        } else if (score >= 81 && score <= 90) {
            return 'A';
        } else if (score >= 71 && score <= 80) {
            return 'B+';
        } else if (score >= 61 && score <= 70) {
            return 'C';
        } else {
            return 'C';
        }
    }

    const rating = getRating(compScore);

    function renderGradeBackgroundColor(rating: any) {
        console.log('rating>>>>>>>>>>>', rating);
        if (rating === 'A+' || rating === 'A') {
            return '#E4EEE0'; // Light green for A+ and A
        } else if (rating === 'B' || rating === 'B+') {
            return '#F4EED9'; // Light yellow for B and B+
        } else if (rating === 'C') {
            return '#FFE6D3'; // Light orange for C
        } else {
            return '#FFE6D3'; // Default to light orange if no match
        }
    }

    return (
        <Card className='m-3 card-container card-con' style={{ width: '100%', height: '18rem', borderRadius: '0px', border: '1px solid #E6E8E9' }}>
            <Card.Body className='row' style={{ borderRadius: '0px', height: '12.5rem' }}>
                <div>
                    <div className='row' style={{ height: '6rem' }}>
                        <div className='col-6'>
                            <p className='text-style' style={{ color: 'rgba(50, 62, 74, 1)' }}>
                                {props.title}
                            </p>
                        </div>
                        <div className='col-6 d-flex justify-content-end'>
                            <div style={{ width: '24px', height: '24px', marginRight: '8px' }}>
                                <img src={arrowRight} alt='' className='arr_icon card-arr' />
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ height: '6rem' }}>
                        <div className='col'>
                            <div className='d-flex'>
                                <p className='text-style2'>{'Compliance score'}</p>
                            </div>
                            <div>
                                <p className='text-style3'>{props.compScore.toString() + '%'}</p>
                            </div>
                        </div>
                        <div style={{ width: '68px', height: '64px', background: `${renderGradeBackgroundColor(rating)}`, marginRight: '1.2rem' }}>
                            <div>
                                <p className='rate-style1' style={{ marginTop: '0.5rem', marginBottom: '0rem' }}>
                                    {'Rating'}
                                </p>
                            </div>
                            <div>
                                <p className='grade-style'>{rating}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>
            <Card.Footer className='card-footer' style={{ background: props.footerColor || '#f8f9fa', height: '5.5rem', borderRadius: '0px' }}>
                <div>
                    <div className='row'>
                        <div className='col'>
                            <div className='d-flex' style={{ marginTop: '0.5rem' }}>
                                <p className='text-style4' style={{ color: '#323E4A', marginBottom: '0.5rem' }}>
                                    {props.application}
                                </p>
                            </div>
                            <div>
                                <p className='text-style5'>{'Total Applications'}</p>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex' style={{ marginTop: '0.5rem' }}>
                                <p className='text-style4' style={{ color: '#323E4A', marginBottom: '0.5rem' }}>
                                    {props.approved}
                                </p>
                            </div>
                            <div>
                                <p className='text-style5'>{'Approved'}</p>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex' style={{ marginTop: '0.5rem' }}>
                                <p className='text-style4' style={{ color: '#323E4A', marginBottom: '0.5rem' }}>
                                    {props.percentage.toString() + '%'}
                                </p>
                            </div>
                            <div>
                                <p className='text-style5'>{'Approved On Time'}</p>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='d-flex' style={{ marginTop: '0.5rem' }}>
                                <p className='text-style4' style={{ color: '#323E4A', marginBottom: '0.5rem' }}>
                                    {props.feeInc}
                                </p>
                            </div>
                            <div>
                                <p className='text-style5'>{'Fee Incurred'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Footer>
        </Card>
    );
};

export default CustomCard;
