import React, { useEffect, useRef, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/authSlice';
import { DASHBOARD_DOCUMNET_DETAILS, DASHBOARD_PAYMENT_DETAIL, PLOT_INFO, PLOT_LIST } from './Dashboard.const';
import { Link } from 'react-router-dom';
import paid from '../../../../assets/logos/check Circle.svg';
import due from '../../../../assets/logos/pay due.svg';
import overdue from '../../../../assets/logos/pay over due.svg';
import { DocumentSection } from './DocumentSection';
import { PlotInfoSection } from './PlotInfoSection';
import SubleaseSection from './SubleaseSection';
import PaymentSection from './PaymentSection';
import { POST, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { cross } from '../../../../utils/Logo';
import { getBuiltUpArea, getTransferBuildUpArea } from '../../../../services/technicalServices/Technical.service';

const NewEnterpriseDashboard = ({ data, modalDetails, setModalDetails }: any) => {
    const userData = useSelector(selectUser);
    const userName = `${userData?.firstName ? userData?.firstName : ''} ${userData?.lastName ? userData?.lastName : ''}`;
    const userId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;

    const [activePlot, setActivePlot] = useState(0);
    const [entityRefDetails, setEntityRefDetails] = useState<any>(null);
    const [currentPlotDetails, setCurrentPlotDetails] = useState<any>(null);
    const [plotId, setPlotId] = useState<any>(null);
    const [allDocuments, setAllDocuments] = useState<any>(null);
    const [coExpiryDate, setCoExpiryDate] = useState<any>(null);
    const [deedExecutedDate, setDeedExecutedDate] = useState<any>(null);
    const [builtUpPercentage, setBuiltUpPercentage] = useState<any>(null);
    const [builtUpArea, setBuiltUpArea] = useState<any>(null);
    const [migartedPlot, setMigratedPlot] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            if (data) {
                try {
                    // setIsLoading(true);
                    // const { data } = await POST(endpoints.entityRefDetails + `?isPlotDetailsRequired=true&types=ALLOTTEE,LESSEE,TRANSFEROR&entityIds=${userId}`, { RequestInfo: requestInfo() });
                    setEntityRefDetails(data);
                    // setCurrentPlotDetails(data?.[0]);
                    setPlotId(data?.[0].plotId);
                    setMigratedPlot(data?.[0]?.auditDetails?.createdBy === 'DATA_MIGRATION_API' ? true : false);

                    const {
                        data: { deedDetails }
                    } = await POST(endpoints.leaseDeedSearch + `?entityIds=${userId}&plotIds=${data?.[0]?.plotId}`, { RequestInfo: requestInfo() });
                    data?.[0] && setCurrentPlotDetails({ ...data?.[0], leaseTermEndDate: deedDetails[0]?.termEndDate });
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        })();
    }, [data]);

    useEffect(() => {
        if (currentPlotDetails?.plotId) {
            (async () => {
                try {
                    const { data: docs } = await POST(endpoints.dashboardDocumentSearch + `?entityIds=${userId}&plotIds=${currentPlotDetails?.plotId}`, { RequestInfo: requestInfo() });
                    setAllDocuments(docs);
                    const tempDocData = docs?.deedDetails?.filter((val: any) => val.deedType === 'LEASE_DEED' || val.deedType === 'TRIPARTITE_DEED');
                    const exeDate = tempDocData?.[0]?.deedExecutionDate?.split(' ')[0];
                    setDeedExecutedDate(exeDate);

                    const { data: commercialCertificate } = await POST(endpoints.commercialCertificateSearch + `?entityIds=${userId}&plotIds=${currentPlotDetails?.plotId}&statuses=APPROVED`, {
                        RequestInfo: requestInfo()
                    });
                    setCoExpiryDate(commercialCertificate?.commercialOpsDetails[0]?.expiryDate);

                    const builtUp = await getTransferBuildUpArea(userId, currentPlotDetails?.plotId, { RequestInfo: requestInfo() });
                    setBuiltUpPercentage(builtUp?.data);

                    const builtUpArea = await getBuiltUpArea(currentPlotDetails?.plotId, userId, { RequestInfo: requestInfo() });
                    setBuiltUpArea(builtUpArea?.data?.builtUpArea);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [currentPlotDetails]);

    const onChangePlot = async (plot: any, index: number) => {
        const deedData = await POST(endpoints.leaseDeedSearch + `?entityIds=${userId}&plotIds=${plot?.plotId}`, { RequestInfo: requestInfo() });
        plot && setCurrentPlotDetails({ ...plot, leaseTermEndDate: deedData.data.deedDetails[0]?.termEndDate });

        setActivePlot(index);
        setPlotId(plot.plotId);
        setMigratedPlot(plot?.auditDetails?.createdBy === 'DATA_MIGRATION_API' ? true : false);
    };

    // const plotsToShow = entityRefDetails?.filter((val: any) => val.type === 'ALLOTTEE' || val.type === 'LESSEE');

    // const codesToCheck = ['ALLOTTEE', 'LESSEE'];

    // console.log(documnetCard)
    return (
        <>
            <div className='row new-enterprise-dashboard new-dashboard px-5 pb-5 gx-0 justify-content-center ' style={{ backgroundColor: '#F5F5F5' }}>
                {/* <div className='col-12 mt-4 d-flex justify-content-between align-items-center ' style={{ height: '50px' }}> */}
                {/* <div className='font-18 fw-400 font-inter greeting '>Welcome</div> */}
                {/* <div className='font-28  font-inter user-name fw-bold '>{`${userName}`}</div> */}

                {/* <span className='finacial-year fw-bold font-inter font-14 bg-white px-3 py-2 rounded-pill align-self-center lh-19 ls-02 '>FY 2023-24</span> */}
                {/* </div> */}

                {/* {userData?.roles?.some((item: any) => codesToCheck.includes(item.code)) && ( */}
                <>
                    <div className='col-12 mt-3'>
                        <div className='plot-list d-flex px-3 rounded-pill align-items-center gap-2  '>
                            {entityRefDetails?.map((item: any, ind: number) => (
                                <div
                                    className={`plot-items font-inter font-18 fw-500 p-2 cursor-pointer ${activePlot === ind ? 'project-primary-color bg-white rounded-pill ' : ''}`}
                                    onClick={() => onChangePlot(item, ind)}
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title={item?.auditDetails?.createdBy === 'DATA_MIGRATION_API' ? 'Migrated Plot' : ''}
                                >
                                    {`Plot-${ind + 1}`}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='col-12 px-2 mt-3 font-inter new-dashboard-details'>
                        <div className='row px-1'>
                            <PlotInfoSection
                                currentPlotDetails={currentPlotDetails}
                                allDocuments={allDocuments}
                                coExpiryDate={coExpiryDate}
                                deedExecutedDate={deedExecutedDate}
                                builtUpPercentage={builtUpPercentage}
                                builtUpArea={builtUpArea}
                                migartedPlot={migartedPlot}
                            />
                            <div className='col-12 col-lg-8 col-md-12 col-sm-12 plot-cards mt-3 p-0'>
                                <div className='d-flex gy-3 '>
                                    <PaymentSection iconProps={[paid, due, overdue]} currentPlotDetails={currentPlotDetails} />
                                    <DocumentSection
                                        currentPlotDetails={currentPlotDetails}
                                        modalDetails={modalDetails}
                                        setModalDetails={setModalDetails}
                                        allDocuments={allDocuments}
                                        plotData={data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 px-2 mt-3 font-inter new-dashboard-details'>
                        <SubleaseSection
                            iconProps={[paid, due, overdue]}
                            currentPlotDetails={currentPlotDetails}
                            modalDetails={modalDetails}
                            setModalDetails={setModalDetails}
                            // setIsLoading={setIsLoading}
                        />
                    </div>
                </>
                {/* )} */}
            </div>
        </>
    );
};

export default NewEnterpriseDashboard;
