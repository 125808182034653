// ScrollButton.js
import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";
import { arrowUp } from "../utils/Logo";

const ScrollToTop = ({ type }: any) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    return (
        <>
            <div className={`scroll-button ${isVisible ? "visible" : ""}`} onClick={scrollToTop}>
                <Image src={arrowUp} height={25} />
            </div>
        </>
    );
};

export default ScrollToTop;

// import React, { useState, useEffect } from "react";
// import "./commonStyles.scss"; // Import your CSS file
// import { Image } from "react-bootstrap";
// import { arrowUp } from "../utils/Logo";

// interface IscrollToTopProps {
//   type?: string
// }

// const ScrollToTop = (props: IscrollToTopProps) => {
//   const { type } = props

//   const [isVisible, setIsVisible] = useState<boolean>(false);

//   // Show the button when the user scrolls down
//   const handleScroll = () => {
//     const scrolled = document.documentElement.scrollTop;
//     if (document.body.scrollTop > 800 || document.documentElement.scrollTop > 800) {
//       setIsVisible(true);
//     } else {
//       setIsVisible((_) => false);
//     }
//   };

//   // Scroll to the top when the button is clicked
//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: "smooth" // Add smooth scrolling behavior
//     });
//   };

//   // Add scroll event listener when component mounts
//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <>
//       {!type ? <div className={`scroll-to-top ${isVisible ? "visible" : ""}`} onClick={scrollToTop}>
//         &#9650; {/* Unicode character for an upward arrow */}
//       </div>
//         : <button type="button" className={`btn btn-success scroll-up pull-right border-0 ${isVisible ? "visible" : ""}`} onClick={scrollToTop} style={{ borderRadius: 0, height: 50, width: 50, backgroundColor: "rgb(128, 176, 54)" }} >
//           <Image src={arrowUp} height={25} />
//         </button>}
//     </>
//   );
// };

// export default ScrollToTop;
