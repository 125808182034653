import React from 'react';
import { file_csv, file_excel, file_pdf } from '../../utils/Logo';
import { useTranslation } from 'react-i18next';

function ExportMenu(props: any) {
    const { t } = useTranslation();
    const { onCSVExport, onExcelExport, onExportPdf } = props;

    return (
        <>
            <div className='dropdown-content userprofile-content' style={{ top: '50px', width: 150, borderRadius: 8 }}>
                <div>
                    <button onClick={onExcelExport} className='dropdown-item'>
                        <img src={file_excel} alt='' style={{ height: 20, width: 20, marginLeft: 8 }} className='me-3' />
                        {t('EXCEL', 'Excel')}
                    </button>
                </div>

                <div>
                    <button onClick={onExportPdf} className='dropdown-item'>
                        <img src={file_pdf} alt='' style={{ height: 20, width: 20, marginLeft: 10 }} className='me-3' />
                        {t('PDF', 'Pdf')}
                    </button>
                </div>

                <div>
                    <button onClick={onCSVExport} className='dropdown-item'>
                        <img src={file_csv} alt='' style={{ height: 20, width: 20, marginLeft: 10 }} className='me-3' />
                        {t('CSV', 'CSV')}
                    </button>
                </div>
            </div>
        </>
    );
}

export default ExportMenu;
