import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { cross, download, file_preview, file_upload, jpgFormat, pdfFormat, trash } from '../../../utils/Logo';
import { useFormikContext } from 'formik';
import { Image } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import fileDown from '../../../assets/logos/download.svg';
import trashLogo from '../../../assets/logos/trash-white.svg';
import _ from 'lodash';
import GenericPopupModal from '../GenericModalPopup.component';
import GenericPDFViewer from '../../fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../fileviewer/GenericImageViewer';
import { ThreeDots } from 'react-loader-spinner';
import PopUps from '../../PopUps';
import { devUrl } from '../../../services/modulesServices/userManagement.api';
import ToolTipDetails from '../../ToolTipFile';
import NoDocumentAvailable from '../../../assets/logos/Vector_No_Document.svg';

interface textFieldProps {
    field: string;
    title: string;
    isRequired?: boolean;
    size?: string;
    fileTypes?: string[];
    isDisabled?: boolean;
    titleCode?: any;
    width?: string;
    entityFileData?: any;
    toolTipDetails?: any;
    viewOnly?: boolean;
}

interface IconMap {
    [key: string]: string;
}

export default function GenericSingleFileUploadField(props: textFieldProps) {
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const [fileStroreId, setFileStoreId] = useState<string>('');
    const [fileSize, setFileSize] = useState<number>(0);
    const { setFieldValue, values } = useFormikContext<any>();
    const [uploadedDate, setUploadedDate] = useState<string>('');
    const [tag, setTag] = useState<string>('');
    const [fileSizeFor, setFileSizeFor] = useState<number>(0);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>({});

    const { field } = props;
    const iconMap: IconMap = {
        pdf: pdfFormat,
        doc: 'doc-icon.png',
        docx: 'doc-icon.png',
        txt: 'txt-icon.png',
        jpg: jpgFormat,
        jpeg: jpgFormat,
        png: jpgFormat,
        dxf: file_preview,
        default: file_preview
    };

    const truncateText = (text: string, length: number) => {
        if (text.length <= length) {
            return text;
        }
        return `${text.substring(0, length)}...`;
    };

    const handleImageChange = (e: any) => {
        const file = e;
        if (file) {
            if (!file.name.includes('.dxf') && !file.name.includes('.pdf')) {
                let errMsg = t('INVALID_DOCUMENT_TYPE', 'Invalid document type');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            const formData = new FormData();
            setFileName(file.name);
            setFileSize(file.size);
            if (file.name.includes('.dxf')) {
                setFieldValue(props.field, [{ documentName: `${file.name} (${file.size} bytes)`, id: null, file: file }]);
                return;
            }
            setIsLoading(true);

            formData.append('file', file);
            formData.append('tenantId', 'ga');
            formData.append('module', 'BPA');
            // formData.append('tag', props.field);
            const documentName = props?.entityFileData?.documentNamePrefix ? `${props?.entityFileData?.documentNamePrefix}_${props.field}.pdf` : `${props.field}.pdf`;

            formData.append('tag', truncateText(documentName, 32)); // Adjust the length as needed

            axios
                .post(devUrl + '/filestore/v1/files', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response: any) => {
                    getFileName(response?.data?.files[0]?.fileStoreId, file, true);
                    const fileDate = new Date(response?.data?.files[0]?.uploadedDate);
                    const formattedDate = `${fileDate.getDate()}/${fileDate.getMonth() + 1}/${fileDate.getFullYear()}`;
                    // setUploadedDate(formattedDate);
                    setIsLoading(false);

                    // toast(t('FILE_UPLOADED_SUCCESSFUL', 'File uploaded successfully'), {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'success'
                    // });
                })
                .catch(error => {
                    setIsLoading(false);

                    if (!file.name.includes('.dxf')) setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
                    // toast(error?.message, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'error'
                    // });
                });
        }
    };
    const getFileName = (id: string, file: any, isValid: boolean) => {
        if (!id) return;
        axios
            .get(devUrl + `/filestore/v1/files/url?tenantId=ga&fileStoreIds=${id}`)
            .then((response: any) => {
                setSelectedImage(response?.data?.fileStoreIds[0]?.url);

                const fileDate = new Date(response?.data?.fileStoreIds[0].uploadedDate);

                const day = fileDate.getDate(),
                    month = fileDate.getMonth() + 1,
                    year = fileDate.getFullYear();

                const formattedDate = `${day}/${month}/${year}`;
                setUploadedDate(formattedDate);
                setTag(`${response?.data?.fileStoreIds[0]?.tag}`);
                setFileSizeFor(response?.data?.fileStoreIds[0]?.fileSize);
                setFileStoreId(response?.data?.fileStoreIds[0]?.id);

                const allDocs = values?.[props.field] || [];
                allDocs.push({
                    fileStoreId: response?.data?.fileStoreIds[0]?.id,
                    documentType: props.field,
                    documentName: `${response?.data?.fileStoreIds[0]?.tag} (${response?.data?.fileStoreIds[0]?.fileSize})`,
                    url: response?.data?.fileStoreIds[0]?.url,
                    documentSize: response?.data?.fileStoreIds[0]?.fileSize,
                    uploadedDate: formattedDate,
                    tag: response?.data?.fileStoreIds[0]?.tag
                });

                setFieldValue(props.field, allDocs);
            })
            .catch(error => {});
    };

    const downloadFile = (id: any) => {
        axios
            .get(devUrl + `/filestore/v1/files/url?tenantId=ga&fileStoreIds=${id}`)
            .then((response: any) => {
                window.open(response?.data?.fileStoreIds[0]?.url);
            })
            .catch(error => {});
    };

    const downloadUploadedFile = async (id: string, documentName: string) => {
        const fileExtension: string = documentName.split('.').pop()?.toLowerCase() || '';

        let contentType = '';
        if (fileExtension === 'pdf') {
            contentType = 'application/pdf';
        } else if (fileExtension === 'jpeg' || fileExtension === 'jpg') {
            contentType = 'image/jpeg';
        }

        fetch(devUrl + `/filestore/v1/files/id?tenantId=ga&fileStoreId=${id}`, {
            headers: {
                'Content-Type': contentType
            }
        })
            .then(response => {
                return response.arrayBuffer().then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link: any = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${documentName}`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
            })

            .catch(error => console.error('Error downloading file:', error));
    };

    useEffect(() => {
        getFileName(values[props?.field][0]?.fileStoreId, '', true);
    }, [!!values[props?.field]?.length && values[props?.field][0]?.fileStoreId]);

    const viewDocument = (id: string) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(id);
        setIsPDF(isPDF);
    };

    const removeFile = (key: any) => {
        let allArrayList = values[props.field];
        allArrayList = allArrayList.filter((item: any, index: any, self: any) => index === self.findIndex((t: any) => t.fileStoreId === item.fileStoreId));

        if (!!allArrayList) {
            allArrayList.forEach((item: any, index: number) => {
                if (item?.fileStoreId === key) {
                    allArrayList.splice(index, 1);
                }
            });
            setFieldValue(props.field, allArrayList);
            setFileName('');
            setFileSize(0);
            setSelectedImage('');
        }
    };

    const getFileIcon = (documentName: string): string => {
        const fileExtension: string = documentName ? (documentName.split('.').pop()?.split(' ')[0]?.toLowerCase() as string) : 'png';
        return iconMap[fileExtension] || iconMap.default;
    };

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const formatFileSize = (size: number) => {
        if (size < 1024 * 1024) {
            return `${Math.floor(size / 1024)} KB`;
        } else {
            return `${Math.floor(size / (1024 * 1024))} MB`;
        }
    };

    const currentDate = getCurrentDate();
    const renderFileInput = () => {
        return (
            <div style={{ width: props?.width === 'half' ? '50%' : '100%' }}>
                <div className='d-flex'>
                    {props.titleCode ? t(props.titleCode) : props.title}
                    {props.isRequired ? <span className=' text-danger '>*</span> : <></>}
                    {props.toolTipDetails && <ToolTipDetails toolTipDetails={props.toolTipDetails} />}
                </div>
                {(!!values?.isEditableFile || (props.fileTypes && props.fileTypes.includes('DXF'))) && (
                    <div key={props?.field}>
                        {!selectedImage && !fileName && !values[props?.field][0]?.id && (
                            <div className='btn fileUploadBox p-3 m-1' style={{ backgroundColor: '#ecf2e9', borderRadius: 20 }}>
                                <FileUploader multiple={false} handleChange={handleImageChange} name='file' types={props?.fileTypes} size={props?.size} disabled={props?.isDisabled}>
                                    <div className='d-flex justify-content-center align-items-center font-10 fw-thin'>
                                        <Image src={file_upload} height={40} className='px-3' />
                                        <div className='font-12 fw-thin'>
                                            <div className='file-name text-truncate' title={`Upload ${props.title}`}>
                                                {t('UPLOAD', `Upload ${props.title}`)}
                                            </div>
                                            <div style={{ fontSize: 10 }} className='text-muted'>
                                                &nbsp; {props.fileTypes && props.fileTypes.includes('DXF') ? t('ONLY_DXF_FILE', 'only .dxf file') : t('ONLY_PDF_JPEGG', 'only .pdf file')} <br />
                                                {`${props.fileTypes && props.fileTypes.includes('DXF') ? '30' : props?.size} MB max size`}
                                            </div>
                                        </div>
                                    </div>
                                </FileUploader>
                            </div>
                        )}
                        {(selectedImage || fileName) && !values[props?.field][0]?.id && (
                            <div className={`btn btn-none fileUploadBox py-2 ps-2 p-4 m-1`} style={{ borderRadius: '12px', justifyContent: 'space-between', backgroundColor: '#45852E' }}>
                                <div className='d-flex align-items-center ms-0 ps-0' onClick={() => viewDocument(fileStroreId)}>
                                    {/* <span>
                                        <Image src={getFileIcon(fileName)} height={40} />
                                    </span> */}
                                    <div className='ms-2 text-start' style={{ padding: '8px' }}>
                                        <span>
                                            <div
                                                className='file-name uploaded-file-name text-white text-nowrap text-truncate'
                                                style={{ fontSize: '14px', lineHeight: '18px', marginBottom: '10px' }}
                                                title={tag || fileName}
                                            >
                                                {tag || fileName}
                                            </div>
                                            <div className='text-white' style={{ fontSize: '12px', lineHeight: '18px' }}>
                                                {fileSizeFor || formatFileSize(fileSize)} . {uploadedDate || currentDate}
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='me- d-flex'>
                                    <Image src={fileDown} height={20} onClick={() => downloadUploadedFile(fileStroreId, tag)} className='me-3' />
                                    {!props?.viewOnly && <Image src={trashLogo} height={20} className='ms-2' onClick={() => removeFile(fileStroreId)} />}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {!!values[props?.field] && !!values[props?.field].length && !!values[props?.field][0]?.id && (
                    <div className={`btn btn-none fileUploadBox py-2 ps-2 p-4 m-1`} style={{ borderRadius: '12px', justifyContent: 'space-between', backgroundColor: '#45852E' }}>
                        <div className='d-flex align-items-center ms-0 ps-0' onClick={() => viewDocument(values[props.field][0]?.fileStoreId)}>
                            <div className='ms-2 text-start' style={{ padding: '8px' }}>
                                <span>
                                    <div
                                        className='file-name uploaded-file-name text-white text-nowrap text-truncate'
                                        style={{ fontSize: '14px', lineHeight: '18px', marginBottom: '10px' }}
                                        title={tag || fileName}
                                    >
                                        {tag || fileName}
                                    </div>
                                    <div className='text-white' style={{ fontSize: '12px', lineHeight: '18px' }}>
                                        {`${fileSizeFor} . ${uploadedDate}`}
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div className='me- d-flex'>
                            <Image src={fileDown} height={20} onClick={() => downloadUploadedFile(values[props.field][0]?.fileStoreId, tag)} />
                        </div>
                    </div>
                )}
                {!values[props.field].length && !!props?.isDisabled && (
                    // <div className='p-2'>
                    //     <label className='text-danger'>{t('NO_DOC_AVAILABLE', 'No document available')}</label>
                    // </div>
                    <div className='btn fileUploadBox p-3 m-1' style={{ backgroundColor: '#e9ecef', borderRadius: 20 }}>
                        <div className='d-flex justify-content-center align-items-center font-10 fw-thin'>
                            <Image src={NoDocumentAvailable} height={35} className='px-3' />
                            <div className='font-12 fw-thin'>
                                <div className='file-name text-truncate text-danger' title={`No document uploaded`} style={{ fontSize: '18px' }}>
                                    {t('SINGLE_FILE_UPLD_LBL_NO_DOC_AVAILABLE', `No document uploaded`)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {modalData && isFilePreviewModal && (
                    <GenericPopupModal
                        title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                        primaryAction={() => {}}
                        secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                        isVisible={isFilePreviewModal}
                        isPrimaryBtn={false}
                        isSecondaryBtn={false}
                        size={'xl'}
                        class='file-viewer-popup'
                    >
                        <div className='popup-children'>
                            {isPDF && <GenericPDFViewer fileStoreId={modalData} />}
                            {!isPDF && <GenericImageViewer />}
                        </div>
                    </GenericPopupModal>
                )}
            </div>
        );
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            {isLoading ? <ThreeDots visible={true} height='80' width='80' color='#45852e' radius='9' ariaLabel='three-dots-loading' wrapperStyle={{}} wrapperClass='' /> : renderFileInput()}
        </>
    );
}

GenericSingleFileUploadField.defaultProps = {
    size: '2',
    fileTypes: ['PDF'],
    viewOnly: false
};
