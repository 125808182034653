import * as Yup from 'yup';

export const ADEVERTISMENT_WORKFLOW: { [pageno: string]: any } = {
    page1: Yup.object({
        publicationDate: Yup.string().required().label('Date of Publication'),
        // .label('Date of Publication cannot be less than Submission Start Date')
        // .test('publicationDate', (value: any, context) => {
        //     if (new Date(value).getTime() > new Date(context.parent.submissionStartDate).getTime()) {
        //         return false;
        //     } else return true;
        // }),
        submissionStartDate: Yup.string()
            .label('Submission Start Date cannot be less than Date of Publication')
            .required('Submission Start Date is a required field')
            .test('submissionStartDate error', 'Submission Start Date cannot be less than Date of Publication', (value: any, context) => {
                if (new Date(value).getTime() < new Date(context.parent.publicationDate).getTime()) {
                    return false;
                } else return true;
            }),
        submissionEndDate: Yup.string()
            .label('Submission End Date cannot be less than Submission Start Date')
            .required('Submission End Date is a required field')
            .test('submissionEndDate error', 'Submission End Date cannot be less than Submission Start Date', (value: any, context) => {
                if (new Date(value).getTime() < new Date(context.parent.submissionStartDate).getTime()) {
                    return false;
                } else return true;
            }),
        emails: Yup.array().of(
            Yup.object().shape({
                emailId: Yup.string().matches(/\S+@\S+\.\S+/, 'Enter valid email')
            })
        )
    })
};
