import { ReactElement, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './popup.css';
import { faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface modalProps {
    title: string;
    children?: ReactElement;
    secondaryBtnTitle?: string;
    primaryBtnTitle?: string;
    secondaryAction(): void;
    primaryAction(): void;
    isVisible: boolean;
    subTitle?: string;
    size?: any;
    display?: string;
    isCancel?: boolean;
}

export default function ScruitnyPopupModal(props: modalProps) {
    return (
        <>
            <Modal className='scrutinypopup' size={props?.size ? props?.size : 'sm'} show={props?.isVisible} backdrop='static' onHide={props?.secondaryAction}>
                <Modal.Header closeButton>
                    <div>
                        <div className='fw-bold fs-5'>{props?.title}</div>
                        <div className='font-15'>{props?.subTitle}</div>
                    </div>
                </Modal.Header>
                <Modal.Body>{props?.children}</Modal.Body>

                {!!props?.isCancel && (
                    <Modal.Footer>
                        <div className='d-flex justify-content-center  container' style={{ padding: 15 }}>
                            <Button
                                variant='bg-light outline-success btn btn-light me-3 font-karla-med p-10 font-12'
                                style={{ borderColor: '#45852e', color: '#45852e', width: 'max-content', borderRadius: 8 }}
                                onClick={props?.secondaryAction}
                            >
                                <FontAwesomeIcon className='text-secondary' icon={faCircleXmark} size='xl' />
                            </Button>
                        </div>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
}

ScruitnyPopupModal.defaultProps = {
    title: '',
    subTitle: '',
    size: 'md'
};
