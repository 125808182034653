import { requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getEntityDocumentDetails, setEntityDocumentDetails } from '../../Migration/Migration.controller';
import { DOCUMENT_TYPE } from './Transferee.const';

const processDocuments = (values: any, documentData: any) => {
    const initialData: any = {};
    initialData['entityProprietorshipFirm'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entityProprietorIdentity'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['trustDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['partnershipDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['certificateOfRegistrationFirms'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfIncorporation'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfRegistrationSocieties'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['copyRelevantNotification'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['applicantGstdocument'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GST && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['applicantPancard'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.PAN && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['letter'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY && doc.referenceType === DOCUMENT_TYPE.AUTHUSER);
    initialData['RegCertificate'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION && doc.referenceType === DOCUMENT_TYPE.OTHER);

    values.transfereeDetails.transfereeDocuments = { ...initialData };

    return values;
};

export default processDocuments;

export const getTransfereePayload = (values: any, activeIndex: number, transferDetailsRes: any, ownershipDetails: any, isFinal: boolean, transfereeNicDetails: any, isSpecialTransfer: boolean) => {
    let docForCategories: any = {
        PROP_FIRM: ['entityProprietorshipFirm', 'entityProprietorIdentity'],
        TRUST: ['trustDeed'],
        PARTNERSHIP: ['partnershipDeed', 'certificateOfRegistrationFirms'],
        LIMITED_LIABILITY_PARTNERSHIP: ['certificateOfIncorporation'],
        LTD_COMPANY: ['certificateOfIncorporation'],
        COOP_SOCIETY: ['certificateOfRegistrationSocieties'],
        GOV_STAT_CORP: ['copyRelevantNotification']
    };
    let docs: any = [];
    docForCategories[values?.category?.code]?.forEach((ele: any) => {
        docs.push(setEntityDocumentDetails(values?.[ele]) || null);
    });

    docs = [
        ...docs,
        setEntityDocumentDetails(values?.applicantGstdocument) || null,
        setEntityDocumentDetails(values?.entitygstUndertaking) || null,
        setEntityDocumentDetails(values?.applicantPancard) || null,
        setEntityDocumentDetails(values?.letter) || null,
        setEntityDocumentDetails(values?.RegCertificate) || null,
        // setEntityDocumentDetails(values?.RegCertificate) || null,
        setEntityDocumentDetails(values?.DPIITCertificate) || null,
        // setEntityDocumentDetails(values?.residence) || null,
        // setEntityDocumentDetails(values?.womenEntreprenuershipDocument) || null,
        setEntityDocumentDetails(values?.experienceCertificate) || null
        // setEntityDocumentDetails(values?.educationCertificate) || null,
        // setEntityDocumentDetails(values?.letter) || null
    ].filter(Boolean);

    const transfereeEnterpriseDetails =
        activeIndex >= 5 && !isSpecialTransfer
            ? {
                  enterpriseSubCategories: values.subCategory?.map((e: any) => e.code),
                  specialProject: values.specialProject,
                  projectType: values.projectType?.code,
                  pollutionCategory: values.pollutionCategory?.code,
                  estimatedWaterRequirement: values.waterRequirement?.code,
                  estimatedPowerRequirement: values.powerRequirement?.code,
                  estimatedNumberOfPersonsToBeEmployed: values.employement?.code,
                  estimatedProjectCost: values.projectCost?.toString().replace(/,/g, ''),
                  meansOfFinance: values.meansOfFinance && values.meansOfFinance?.map((e: any) => e.code),
                  isFdi: values.confirmation,
                  enterpriseCategory: values.enterpriseCategory?.code,
                  nicEnterpriseMappingRequests: transfereeNicDetails.map((e: any) => {
                      return { nicMstId: e.nicMstId, proposedActivity: e.proposedActivity };
                  }),
                  documents: [
                      values.specialProject ? setEntityDocumentDetails(values?.inPrincipleApproval) || null : null,
                      setEntityDocumentDetails(values?.detailedProjectReport) || null,
                      values.confirmation ? setEntityDocumentDetails(values?.file3) || null : null
                  ].filter(Boolean)
              }
            : null;

    const transfereeEnterpriseDetailsFinal = {
        ...(transferDetailsRes.transfereeEnterpriseDetails && Object.keys(transferDetailsRes.transfereeEnterpriseDetails).length > 0 ? transferDetailsRes.transfereeEnterpriseDetails : null),
        ...(transfereeEnterpriseDetails && Object.keys(transfereeEnterpriseDetails).length > 0 ? transfereeEnterpriseDetails : null)
    };

    let payload = {
        RequestInfo: requestInfo(),
        uuid: transferDetailsRes.uuid,
        applicationDetails: transferDetailsRes.applicationDetails,
        isUpdateTransfereeDetails: activeIndex === 1,
        transfereeDetails: {
            ...transferDetailsRes.transfereeDetails,
            entityName: values.applicantName,
            category: values.category?.code,
            email: values.emailId,
            phoneNumber: values.phoneNumber,
            panNumber: values.applicantPanNo,
            gstApplicable: values.applicantGst,
            gstNumber: values.applicantGstno,
            addresses: [
                {
                    addressType: 'CORRESPONDENCE',
                    address1: values.address1,
                    address2: values.address2,
                    pinCode: values.pincode,
                    district: values.district,
                    state: values.state,
                    country: values.country
                },
                {
                    addressType: 'PERMANENT',
                    address1: values.peraddress1,
                    address2: values.peraddress2,
                    pinCode: values.perpincode,
                    district: values.perdistrict,
                    state: values.perstate,
                    country: values.percountry
                }
            ],
            documents: docs,
            owners: ownershipDetails,
            entityBankDetail: {
                accountNumber: values.accountNumber,
                ifscCode: values.ifscCode,
                bankName: values.bankName,
                branchAddress: values.branchAddress
            },
            miscDetails: {
                isUdhyamRegistered: values.udhyam,
                udyamNumber: values.udyamNo,
                isDpiitCertified: values.isStartup,
                isExperienced: values.priorExperience,
                experience: values?.experienceCategory?.code
            },
            authUsers: [
                {
                    ...transferDetailsRes?.transfereeDetails?.authUsers[0],
                    firstName: values.authorizedPersonFirstname,
                    lastName: values.authorizedPersonLastName,
                    primaryMobileNo: values.pmobileNumber,
                    alternateMobileNo: values.alternateMobileNumber,
                    email: values.authorizedEmailId,
                    documents: docs.filter((item: any) => item.referenceType === 'AUTHUSER')
                }
            ]
        },
        transferorDetails: transferDetailsRes.transferorDetails,
        status: transferDetailsRes.status,
        isBuiltUpAreaUtilised: transferDetailsRes.isBuiltUpAreaUtilised,
        isTransferProhibited: transferDetailsRes.isTransferProhibited,
        isTransfereeDisqualified: transferDetailsRes.isTransfereeDisqualified,
        isIndustrialCommercial: transferDetailsRes.isIndustrialCommercial,
        plotIds: !isSpecialTransfer ? [transferDetailsRes.plotDetails[0].uuid] : transferDetailsRes?.plotDetails?.map((plot: any) => plot.uuid),
        isSpecialTransfer: Boolean(isSpecialTransfer),
        isUpdateTransfereeEnterpriseDetails: activeIndex === 5 && !isSpecialTransfer,
        transfereeEnterpriseDetails: Object.keys(transfereeEnterpriseDetailsFinal).length > 0 && !isSpecialTransfer ? transfereeEnterpriseDetailsFinal : null,
        isTransfereeFinalSubmit: isFinal,
        modificationDetails: isSpecialTransfer ? transferDetailsRes?.modificationDetails : null
    };
    return payload;
};

export const getTransferorDetails = (data: any) => {
    let initialData: any = {};
    initialData['transferorEntityName'] = data?.transferorDetails?.entityName;
    initialData['transferorEmail'] = data?.transferorDetails?.email;
    initialData['transferorPhoneNumber'] = data?.transferorDetails?.phoneNumber;
    initialData['transferorAddress1'] = data?.transferorDetails?.addresses[0].address1;
    initialData['transferorAddress2'] = data?.transferorDetails?.addresses[0].address2;
    initialData['transferorPinCode'] = data?.transferorDetails?.addresses[0].pinCode;
    initialData['transferorDistrict'] = data?.transferorDetails?.addresses[0].district;
    initialData['transferorState'] = data?.transferorDetails?.addresses[0].state;
    initialData['transferorCountry'] = data?.transferorDetails?.addresses[0].country;
    // initialData['termReamainingDuration']=
    // initialData['leaseDeed']=
    // initialData['occupancyCertificate']=
    // initialData['transferOrLandPremium']:
    // initialData['isSpecialProject']:
    // initialData['specialProject']:
    return initialData;
};

export const getTransferDetails = async (data: any) => {
    let initialData: any = {};
    initialData['isBuiltUpAreaUtilised'] = data?.isBuiltUpAreaUtilised;
    initialData['isTransferProhibited'] = data?.isTransferProhibited;
    initialData['isTransfereeDisqualified'] = data?.isTransfereeDisqualified;
    initialData['isIndustrialCommercial'] = data?.isIndustrialCommercial;
    initialData['nocFromGoaIpb'] = data?.nocSpecialProjectDocument && (await getEntityDocumentDetails({ docReferenceId: data?.nocSpecialProjectDocument }));
    return initialData;
};
