import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { useState, useEffect } from 'react';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { useTranslation } from 'react-i18next';
import { FormikProvider, useFormik } from 'formik';
import { Card, Form } from 'react-bootstrap';
import ScrollToTop from '../../../../components/ScrollToTop';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { dropdownDataOptions } from '../../Migration/Migration.controller';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { POST, endpoints, estateEndpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../../utils/Logo';
import { PLOT_REGISTRY_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { PLOT_DETAILS, PLOT_EXTRA_DETAILS, getLesseeDetails, getPlotDetailsPayload, setPlotDetails } from './Plotmaster.const';
import { estateDropdown } from '../Estate_Master/master.const';

export const NewPlot = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const estateName = searchParams.get('estateName');

    let navigate = useNavigate();

    const data = location?.state?.data || null;
    const disable = location?.state?.disable || null;
    const viewEdit = location?.state?.viewEdit || null;
    const user = localStorage.getItem('user');
    const userType = user ? String(JSON.parse(user)?.type) : '';

    console.log('data111', data);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [applicationNo, setApplicationNo] = useState<any>('');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: !disable
        },
        onSubmit: () => {},
        validationSchema: PLOT_REGISTRY_VALIDATION
    });

    const { values, setValues, setFieldValue, setTouched, errors } = formik;

    // let NEW_PLOT_DETAILS = PLOT_DETAILS.map((detail: any) => ({ ...detail, formData: detail.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));
    let NEW_PLOT_DETAILS = PLOT_DETAILS.map((detail: any) => ({
        ...detail,
        formData: detail.formData.map((item: any) => ({
            ...item,
            class: 'col-sm-4',
            ...(item.field === 'estateName' && !estateName && !data
                ? { isDisabled: false, isRequired: true }
                : (item.field === 'plotAreaSqm' || item.field === 'plotNo' || item.field === 'phase' || item.field === 'plotType' || item.field === 'taluka' || item.field === 'village') && viewEdit
                ? { isDisabled: true, isRequired: false }
                : {}) // Add `isDisabled` conditionally
        }))
    }));

    useEffect(() => {
        (async () => {
            if (data) {
                const lesseeDetails = getLesseeDetails(data);
                const updatedValues = { ...values, ...lesseeDetails, estateName: estateName ? { code: estateName, name: estateName } : null };
                setValues(updatedValues);
            } else {
                setValues({ ...values, estateName: estateName ? { code: estateName, name: estateName } : null });
            }

            try {
                const dropdownOption = await estateDropdown(userType);
                setDropdownData(dropdownOption);
            } catch (err: any) {
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (values?.estateName?.code) {
                    const { data } = await POST(estateEndpoints.estateSearch + `?estateNames=${values?.estateName?.code}`, { RequestInfo: requestInfo() });
                    setFieldValue('estateUuid', data.estateMasterDetails[0]?.uuid);
                }
            } catch (err: any) {
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [values?.estateName?.code]);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                if (data && Object.keys(data).length && dropdownData) {
                    const plotMasterData = await setPlotDetails(data, dropdownData, data && Object.keys(data).length ? 'MODIFICATION' : 'NEW');
                    setValues({ ...values, ...plotMasterData });
                }
                setIsLoading(false);
            } catch (err: any) {
                setIsLoading(false);
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [data, dropdownData]);

    useEffect(() => {
        if (values.village && dropdownData.length) {
            const selectedVillages = values.village?.flatMap((item: any) => dropdownData['village'].filter((subItem: any) => subItem.code === item.code));
            setFieldValue('villageMasterDetailsList', selectedVillages);
        }
    }, [values.village, dropdownData]);

    useEffect(() => {
        (async () => {
            if (values.taluka?.code) {
                const { data } = await POST(`/idc-estate-service/estate-master-details/taluka/_search?talukaCodes=${values.taluka?.code}&talukaNames=${values.taluka?.name}`, {
                    RequestInfo: requestInfo()
                });

                const options =
                    data.talukaMasterDetails.length && data.talukaMasterDetails[0].villageMasterDetails.map((item: any) => ({ code: item.villageCode, name: item.villageName, uuid: item.uuid }));
                setDropdownData({ ...dropdownData, village: options });
                setFieldValue('village', []);
            }
        })();
    }, [values.taluka?.code]);

    const handleModalClose = (type: string, finalSubmit?: true) => {
        setModalDetails({});
        if (type === 'success' && finalSubmit) {
            navigate(`/goa-idc/estate/plot-registry-workflow?applicationNumber=${applicationNo}`);
        }
    };

    const onSubmit = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            refType: 'PLOT',
            type: data && Object.keys(data).length ? 'MODIFICATION' : 'NEW',
            existingEstateDetails: null,
            newEstateDetails: null,
            existingPlotDetails: data && Object.keys(data).length ? [getPlotDetailsPayload(data, values.estateUuid, dropdownData)] : null,
            newPlotDetails: [getPlotDetailsPayload(values, values.estateUuid, dropdownData)],
            status: null
        };

        try {
            await PLOT_REGISTRY_VALIDATION.validate(values, { abortEarly: false, context: values });
            try {
                const { data } = await POST(endpoints.estateCreation, payload);
                setApplicationNo(data.applicationNo);
                setModalDetails({
                    show: true,
                    title: 'Success!',
                    para: `Plot Registry Application${data.applicationNo ? ' Number ' + data.applicationNo : ''} Initiated Successfully`,
                    img: check,
                    type: 'success',
                    reUpload: 'OK',
                    finalSubmit: true
                });
            } catch (err: any) {
                console.log(err);
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (err: any) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('FILL_ALL_REQUIRED_DETAILS', 'Please fill in all the required details'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onActionClick = () => {
        if (viewEdit) {
            navigate('/goa-idc/estate/plot-registry', { state: { estateNameReturn: values?.estateName?.code } });
        } else {
            navigate(-1);
        }
    };
    console.log(formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={t('PLOT_MASTER_REGISTRY', 'Plot Master Registry')} additionalClass='sub-text-style' onActionClick={onActionClick} />
                        <FormikProvider value={formik}>
                            <div className='col-sm-12 mt-3 form-section '>
                                <Form>
                                    <Card className='card_border p-1'>
                                        <div className='ps-2 py-2 d-flex justify-content-between'>
                                            <div className='formTitle m-0 ps-1'>Basic details</div>
                                        </div>
                                        {NEW_PLOT_DETAILS.map((val, index) => (
                                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                        ))}

                                        {disable ? (
                                            <>
                                                <div className='ps-2 py-2 d-flex justify-content-between'>
                                                    <div className='formTitle m-0 ps-1'>{t('ADDITIONAL_DETAILS', 'Additional details')}</div>
                                                </div>
                                                {PLOT_EXTRA_DETAILS.map((val: any) => (
                                                    <GenericFormCard {...val} />
                                                ))}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Card>
                                </Form>
                            </div>
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    {!disable ? (
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    <GenericButtons text={t('INITIATE', 'Initiate')} variant='primary' handleClick={() => onSubmit()} />
                                </div>
                            </div>
                        </footer>
                    ) : (
                        <></>
                    )}
                </>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
            />
        </>
    );
};
