interface BPASelectionTableProps {
    data: any[]; // Data for the table rows
    selectedRow: any; // Currently selected row
    onSelectRow: (row: any) => void; // Function to handle row selection
}
export default function CLASelectionTable(props: BPASelectionTableProps) {
    return (
        <div style={{ maxHeight: "20rem", overflow: "scroll", overflowX: "hidden" }} >
            <table className="table table-striped table-hover table-condensed">
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>CL Application No</th>
                        <th>Estate</th>
                        <th>Plot Number</th>
                        <th>Valid Upto</th>
                        <th>Obtained Occupancy %</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((row, index) => {
                        return (
                            <tr key={index} onClick={() => props.onSelectRow(row)}>
                                <td>
                                    <input
                                        type="radio"
                                        value={row.dcrNumber}
                                        checked={props.selectedRow === row.code}
                                        onChange={() => props.onSelectRow(row)}
                                    />
                                </td>
                                <td>{row?.dcrNumber || '-'}</td>
                                <td>{row?.estate || '-'}</td>
                                <td>{row?.plotNumber || '-'}</td>
                                <td>{row?.sharedDate || '-'}</td>
                                <td>{row?.appliedCount}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
