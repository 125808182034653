import React, { useEffect, useState } from 'react';
import { Card, Image } from 'react-bootstrap';
import { convertToHierarchyCard } from '../../../../utils/utils';
import { HierarchyNode } from '../types';
import { right_arrow, right_arrow_white, right_arrow_green } from '../../../../utils/Logo';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { SingleValue, ActionMeta, InputActionMeta } from 'react-select';

interface IDashboardSectionProps {
    dashboardDetails: { gidcWorklists?: any; gidcCards: any };
    setTableData: (data: any) => void;
    tableData: any;
}

export default function MDDashboardSection({ dashboardDetails, setTableData, tableData }: IDashboardSectionProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const cardHierarchicalArray: HierarchyNode[] = dashboardDetails?.gidcCards && convertToHierarchyCard(dashboardDetails?.gidcCards);

    const options = [
        { value: '2022-23', label: 'FY 2022-23' },
        { value: '2023-24', label: 'FY 2023-24' },
        { value: '2024-25', label: 'FY 2024-25' }
    ];

    const classes = {
        control: (state: any) => ' border rounded-pill border-1 py-3 px-4',
        option: (state: any) => (state.isFocused ? 'fw-bold p-2 fy-options rounded-3' : 'fw-bold p-2 '),
        menu: (state: any) => 'dashboard-select-menu-style bg-white border mt-2 rounded-3 text-center',
        singleValue: (state: any) => 'fw-bold'
    };

    useEffect(() => {
        if (dashboardDetails) {
            // const data = dashboardDetails.cards[0].inboxs
            // setTableData(data);
        }
    }, [dashboardDetails]);

    const widthPercentage = 100 / cardHierarchicalArray.length;

    const navigatoToSearchAppliaction = (data: any) => {
        navigate('/goa-idc/usermanagement/searchapplication', { state: { module: data.moduleName } });
    };

    return (
        <>
            <div className='pt-4 row md-dashboard font-inter g-4'>
                <div className='d-flex justify-content-between mb-4  align-items-center'>
                    <h2 className='General_manag_dashboard_heading mb-0 fw-bold'>{t('DASHBOARD', 'Dashboard')}</h2>
                    <div>
                        <Select options={options} unstyled={true} classNames={classes} defaultValue={{ value: '2024-25', label: 'FY 2024-25' }} />
                    </div>
                </div>

                {cardHierarchicalArray.map((item: any) => (
                    <>
                        <div className='main col-sm-6 col-xl-1' style={{ width: `calc(${widthPercentage}%)` }}>
                            <div>
                                <Card className='upper-div'>
                                    <Card.Body>
                                        <div className='card-title m-0 card-truncate' title={item.displayName}>
                                            {t(item.displayName)}
                                        </div>
                                        <div className='card-main-content' style={{ color: '#45852E' }}>
                                            {item.displayName1Count}
                                        </div>
                                        <p className='m-0'>{t(item.displayName1)}</p>
                                        <Image
                                            src={right_arrow_green}
                                            style={{ backgroundColor: '#45852E29' }}
                                            className='arrow_icon cursor-pointer'
                                            onClick={() => navigatoToSearchAppliaction(item)}
                                        />
                                    </Card.Body>
                                </Card>

                                <Card className='lower-div'>
                                    <Card.Body>
                                        <div className='card-main-content'>{item.displayName2Count}</div>
                                        <p className='m-0 text-sm'>{t(item.displayName2)}</p>
                                    </Card.Body>
                                </Card>

                                <Card className='third-div'>
                                    <Card.Body>
                                        <div className='card-main-content'>{item.displayName3Count}</div>
                                        <p className='m-0 text-sm'>{t(item.displayName3)}</p>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </>
    );
}
