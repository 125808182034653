import React, { useEffect, useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { endpoints, estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { Card, CardBody } from 'react-bootstrap';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import PopUps from '../../../../components/PopUps';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { cross } from '../../../../utils/Logo';
import { RABILL_DATA_COLUMN, SEARCH_CONTRACT_FIELDS } from '../RA Bill/RaBill.const';

const SearchContract = () => {
    const { t } = useTranslation();
    const [modalDetails, setModalDetails] = useState<any>({});
    const [searchData, setSearchData] = useState<[]>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [estateData, setEstateData] = useState<any>([]);
    const [selectRowId, setSelectRowId] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rowData, setRowData] = useState<any>(null);

    const navigate = useNavigate();
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userUuid = getUserDetails?.uuid;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: null
    });
    const { values, errors, setFieldTouched, setValues, setFieldValue } = formik;

    const toGetEstateDropdown = async () => {
        // this endpoint will fetch the estate data
        const { data } = await POST(estateEndpoints.estateSearch, { RequestInfo: requestInfo() });

        // this variable is used to get the estate name and Id. id will be used in search API call for contract application
        const estateValues = data?.estateMasterDetails?.map((val: any) => {
            return { estateName: val?.estateName, estateId: val?.uuid };
        });
        setEstateData(estateValues); //setting value of estate

        // this is where eatate name is used to create a dropdown of estate
        const estateDropdown = estateValues?.map((value: any) => {
            return { name: value?.estateName, code: value?.estateName };
        });
        setDropdownData((prev: any) => ({ ...prev, industrialEstate: estateDropdown }));

        (async () => {
            try {
                setIsLoading(true);
                const estateManagerSearch = await POST(endpoints.managerSearch + `?userUuids=${userUuid}`, { RequestInfo: requestInfo() });
                if (estateManagerSearch?.data?.estateManagers?.[0]?.estateId) {
                    const estateData = await POST(estateEndpoints.estateSearch + `?uuid=${estateManagerSearch?.data?.estateManagers?.[0]?.estateId}`, { RequestInfo: requestInfo() });
                    if (estateData?.data?.estateMasterDetails?.[0]?.estateName) {
                        setFieldValue('industrialEstate', { name: estateData?.data?.estateMasterDetails?.[0]?.estateName, code: estateData?.data?.estateMasterDetails?.[0]?.estateName });
                    }
                }
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.Errors?.[0]?.message || e.response?.data?.errorMessage || 'Something Went Wrong';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    };

    useEffect(() => {
        dprDropdownDataOptions()
            .then(res => {
                setDropdownData(res);
            })
            .catch(err => console.log(err));

        toGetEstateDropdown();
    }, []);

    const handleSearch = async () => {
        // This variable will have a value of estated id based on user selection of estate name from industrialEstate dDown Value
        const getEstateId = estateData?.find((val: any) => val?.estateName === values?.industrialEstate?.name);
        if (!values.workContract && !values?.industrialEstate) {
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('REQUIRED_DETAILS_ERROR', 'Fill the Required Details to Search'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            setIsLoading(true);
            // search api call to get the Contract approved applications to show on the table in search contract UI
            const { data } = await POST(
                endpoints.searchContract + `?applicationNos=${values.workContract ? values.workContract : ''}&estateIds=${getEstateId ? getEstateId?.estateId : ''}&status=APPROVED`,
                { RequestInfo: requestInfo() }
            );
            console.log(data);
            const tableData =
                data?.contractModelList?.length &&
                data?.contractModelList?.map((val: any) => {
                    // the project type will have the project type name from dropdown of project type. This is neccessary bcz the value from api comes in code form which is all in caps .
                    const projectType = getDropdownSelectedObject(val?.workOrderDetails?.loaDetails?.dprHeaderDetails?.projectType, dropdownData['projectType'] || []);

                    return {
                        // Here it will create a table data of each row this (...val) part will have all the data from each object of api and the rest key value pair is created to show the table column value.
                        ...val,
                        contractNo: val?.applicationNo,
                        projectName: val?.workOrderDetails?.loaDetails?.dprHeaderDetails?.projectName,
                        industrialName: val?.workOrderDetails?.loaDetails?.dprHeaderDetails?.estateMasterDetails?.estateName,
                        projectType: projectType?.name,
                        estimateAmount: val?.workOrderDetails?.loaDetails?.dprHeaderDetails?.estimatedTotalCost,
                        id: val?.uuid,
                        vendorName: val?.workOrderDetails?.loaDetails?.bidderDetails?.name
                    };
                });
            setSearchData(tableData);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('NO_DATA_FOUND', 'No Data Found'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const onReset = () => {
        // on click of reset button this will be called and it will empty the search fields
        const initValue = { industrialEstate: '', workContract: '' };
        setValues((prev: any) => ({ ...prev, ...initValue }));
        setSearchData([]);
    };

    const handleRowClick = (row: any) => {
        // this function will select the particular row which we will select in the table and give that row data will all the details
        setSelectRowId(row?.id); // setting the row id in state for further use
        setRowData(row);
    };

    const updatedColumn = RABILL_DATA_COLUMN?.map((value: any) => {
        // this updatedColumn variable will have the new column of RA bill which will have the function in radio column which will be used to get the row data on select
        if (value?.fieldName === 'radio') {
            return {
                ...value,
                selectedValue: selectRowId,
                onRadioClick: handleRowClick
            };
        } else return value;
    });
    console.log(rowData);

    const onInitiate = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            contractExtensionDetails: {
                contractApplicationNo: rowData.applicationNo
            }
        };

        if (!selectRowId) {
            setModalDetails({ show: true, title: 'Error!', para: t('NO_SELCTED_APPLICATION', 'Select Application from Table to Create'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            const { data } = await POST(endpoints.contractExtensionCreate, payload);
            if (data.contractExtensionDetails.length) {
                navigate(`/goa-idc/extension-contract?applicationNumber=${data?.contractExtensionDetails[0]?.applicationNo}`);
            }
        } catch (e: any) {
            console.log(e);
            let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={t('SEARCH_CONTRACT', `Search Contract`)} additionalClass='sub-text-style' />
                        <FormikProvider value={formik}>
                            <div>
                                <Card className='card_border my-4 pb-4 pe-4'>
                                    <CardBody>
                                        {SEARCH_CONTRACT_FIELDS.map((val: any) => {
                                            return (
                                                <>
                                                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                                </>
                                            );
                                        })}
                                    </CardBody>
                                    <div className='d-flex justify-content-end'>
                                        <GenericButtons text={'Reset'} handleClick={onReset} />
                                        <GenericButtons text={'Search'} variant='primary' handleClick={handleSearch} />
                                    </div>
                                </Card>
                                <Card className='card_border row mx-1 p-4 pb-0'>
                                    <div className='font-20 project-primary-color lh-base mb-3'>Search Result</div>
                                    <GenericActionTable
                                        columns={updatedColumn}
                                        rowData={searchData}
                                        hideExportBtn={true}
                                        searchBarPlaceholder={t('CONTRACT_SEARCH_PARAM', 'Search by Contract No., Vendor Name...')}
                                        additionalMethod={() => {}}
                                    />
                                </Card>
                            </div>
                        </FormikProvider>
                    </div>
                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {<GenericButtons text={t('CONTRACT_EXTENSION', 'Contract Extension')} variant='primary' ClassName='fw-500 px-3' handleClick={onInitiate} />}
                            </div>
                        </div>
                    </footer>
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                </>
            )}
        </>
    );
};

export default SearchContract;
