import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';

export const ILLEGALITY_CARD = [
    {
        title: 'A - Lessee Details',
        field: 'lesseeDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Illegalities',
        field: 'illegalities'
    }
];

export const ILLEGALITIES = [
    {
        formData: [
            {
                titleCode: 'REASON',
                title: 'Reason',
                field: 'reason',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: 'DUE_DATE',
                title: 'Due Date',
                field: 'dueDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            }
        ]
    }
];
