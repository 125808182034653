import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../UI components/Buttons/GenericButtons';

interface FooterPropsData {
    onClickNext(key: number): void;
    onCancel(): void;
    onClickPrev(): void;
    stepperLength?: number;
    activeIndex?: number;
    onSubmit?(key?: number): void;
    onClickSave?(key: number): void;
    secondaryBtnLabel?: string;
    children?: ReactElement;
    isSaveBtn?: boolean;
    disable?: boolean;
    isSubmitBtn?: boolean;
}

export default function GenericFooterComponent(props: FooterPropsData) {
    const { t } = useTranslation();

    return (
        <footer className='footer fixed-bottom footerBtnPatch'>
            <div className='d-flex'>
                <div className='d-flex justify-content-start  container me-3' style={{ padding: 15 }}>
                    {props.activeIndex !== 1 && <GenericButtons text={t('PREVIOUS', 'Previous')} handleClick={props.activeIndex === 1 ? props?.onCancel : props.onClickPrev} />}
                </div>
                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                    {/* {props?.isSaveBtn && <Button variant="bg-light outline-success new_btn btn btn-light me-3 font-karla-med p-10" style={{ borderColor: "#45852e", color: "#45852e", width: 125, borderRadius: 8 }} onClick={() => props?.onClickNext(0)}>
            
          </Button>} */}
                    {/* {props?.isSaveBtn && <GenericButtons text={t('SAVE', "Save")} handleClick={() => props?.onClickNext(0)} ClassName="me-3" />} */}
                    {
                        props?.isSaveBtn && <GenericButtons text={t('SAVE', 'Save')} handleClick={() => props?.onClickNext(0)} ClassName='me-3' />
                        // :
                        // <GenericButtons text={t('CLOSE', "Close")} handleClick={() => props?.onClickNext(0)} ClassName="me-3" />
                    }
                    {props?.children}
                    {/* <Button
            variant="disabled outline-success new_btn btn btn-light me-3 font-karla-med p-10"
            style={{ backgroundColor: "#45852e", borderColor: "#45852e", color: "#f5f5f6", width: "max-content", borderRadius: 8 }}
            onClick={() => props.activeIndex !== props.stepperLength ? props?.onClickNext(1) : (props?.onSubmit && props?.onSubmit())}
          >
            {props?.secondaryBtnLabel || t("SUBMIT", "Submit")}
          </Button> */}
                    {props?.isSubmitBtn && (
                        <GenericButtons
                            text={props?.secondaryBtnLabel || t('SUBMIT', 'Submit')}
                            variant='primary'
                            handleClick={() => (props.activeIndex !== props.stepperLength ? props?.onClickNext(1) : props?.onSubmit && props?.onSubmit())}
                            disabled={props.disable}
                        />
                    )}
                </div>
            </div>
        </footer>
    );
}

GenericFooterComponent.defaultProps = {
    isSaveBtn: true,
    isSubmitBtn: true
};
