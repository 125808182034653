import React from 'react';
import logo from '../../assets/images/goa_name_image.svg';
import { login_background_image } from '../../utils/Images';

export default function LoginSubPage() {
    return (
        <>
            <div className='row outer_row d-flex justify-content-center  '>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-6  bg-white d-flex align-items-center ' style={{ height: '75vh' }}>
                            <img src={logo} alt='GIDC logo' className='ms-0 ms-md-5' style={{ height: '27.2vh' }} />
                        </div>
                        <div className='col-6 image-column   '></div>
                        <div className='col-6 image-column2 '></div>
                        <div className='col-2 bg-light-green '></div>
                        <div className='col-2 bg-green_lighter '></div>
                        <div className='col-2 bg-green '></div>
                    </div>
                </div>
            </div>
        </>
    );
}
