import { ErrorMessage, Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { formOptionsDTO } from './GenericFormCard.constants';
import { useTranslation } from 'react-i18next';
import ToolTipDetails from '../ToolTipFile';

interface textFieldProps {
	isRequired?: boolean;
	titleCode?: any;
	field: string;
	title: string;
	type?: string;
	options?: formOptionsDTO[];
	module?: string;
	isDisabled?: boolean;
	toolTipDetails?: any;
	disableCondition?: (val: any, index: number) => boolean;
	index?: any;
	// onChangeAction?: (e: any, field: string) => void;
	onChangeRadioAction?: (e: any, field: string) => void;
}

export default function GenericCommonRadioField(props: textFieldProps) {
	const { field, onChangeRadioAction } = props;
	const { values, setFieldValue, handleChange } = useFormikContext<any>();
	const { t } = useTranslation();
	const [isDisable, setIsDisable] = useState(false);

	useEffect(() => {
		if (props?.disableCondition && props?.disableCondition(values, props?.index)) {
			setIsDisable(false);
		} else if (props?.disableCondition && !props?.disableCondition(values, props?.index)) {
			setIsDisable(true);
		}
	}, [values]);

	const handleOnChange = (e: any) => {
		if (e.target.value === 'true' || e.target.value === 'false') {
			setFieldValue(props.field, JSON.parse(e.target.value));
		} else handleChange(e);
	};

	return (
		<>
			<div className='d-flex font-inter'>
				<label htmlFor={props.field} title={props.title} className='pb-2 file-form-label' style={props.title.length > 50 ? { width: '95%' } : { width: 'fit-content' }}>
					{props.titleCode ? t(props.titleCode) : props.title}
				</label>
				{props.isRequired && <span className='mandatory-indicator'>*</span>}
				{props?.toolTipDetails && <ToolTipDetails toolTipDetails={props?.toolTipDetails} />}
			</div>
			<div role='group' aria-labelledby='my-radio-group' className='d-flex' style={{ width: '80%' }}>
				{!!props?.options?.length &&
					props?.options.map(
						(val: any) =>
							!val?.isDisabled && (
								<label
									className={`pe-4 m-2 w-100 cursor-pointer font-inter ${val?.isDisabled || props.isDisabled || !values.isUpdatable || isDisable ? 'radio-disabled' : ''}`}
									key={val?.value}
								>
									<Field
										type='radio'
										name={props?.field}
										onChange={(e: any) => {
											props.onChangeRadioAction && props.onChangeRadioAction(e, props.field);
											handleOnChange(e);
										}}
										key={val?.value}
										value={val?.value}
										disabled={val?.isDisabled || props.isDisabled || !values.isUpdatable || isDisable}
										className={'cursor-pointer'}
									/>
									{val?.title}
								</label>
							)
					)}
			</div>
			<ErrorMessage name={props.field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
		</>
	);
}
