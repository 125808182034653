import { useState } from 'react';
import { Card, CardBody, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import Editor from '../../../../components/ContentEditor';
import PlotDetails from '../../Migration/pages/PlotDetails';
import { PlotDropdownData } from '../../UserManagement/types';
import { CANCELLATION_TAB, CANCEL_ORDER_DETAILS } from './CancellationController';
import { LEASE_DEED_APPLICATION } from '../LeaseDeed/LeaseDeed.const';
import ApplicantDetailsWorkflow from '../ApplicantDetailsWorkflow';

interface ICorrespondenceProps {
    content?: any;
    setContent?: any;
    formData?: any;
}

const CancellationCorrespondence = (props: ICorrespondenceProps) => {
    const { content, setContent, formData } = props;

    const [openSection, setOpenSection] = useState<string>('isExtDetails');
    const { t } = useTranslation();
    const [dropdownDataPlot, setDropdownDataPlot] = useState<PlotDropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    // const EXTENSION_TAB_CONDITIONAL =
    //     formData?.applicationSubType === 'ALLOTMENT'
    //         ? EXTENSION_TAB
    //         : [
    //               ...EXTENSION_TAB,
    //               {
    //                   title: 'C - Extension Order Notes',
    //                   field: 'c ancellationOrderNotes'
    //               }
    //           ];

    const renderApplicantDetails = () => {
        return (
            <div className='px-05'>
                <ApplicantDetailsWorkflow />
            </div>
        );
    };

    const renderPlotDetails = () => {
        return (
            <div className='px-05'>
                <PlotDetails dropdownData={dropdownDataPlot} isFieldDisable={true} setDropdownData={setDropdownDataPlot} hideCard={true} />
            </div>
        );
    };

    const renderOrderDetails = () => {
        return (
            <div className='px-05'>
                {CANCEL_ORDER_DETAILS.map(val => (
                    <GenericFormCard {...val} />
                ))}
            </div>
        );
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div className='px-3 py-4'>
                    <Form.Group>
                        <div className='col-lg-12 my-1'>
                            <Editor setContent={setContent} content={content} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <CardBody>
                    <div className='row'>
                        {CANCELLATION_TAB.map((val: any, index: number) => {
                            return (
                                <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                    <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                    <label
                                        htmlFor={`${val.field}Form`}
                                        className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                    >
                                        {val.title}
                                    </label>
                                    {val.title === 'A - Applicant Details' && openSection === 'applicantDetails' && renderApplicantDetails()}
                                    {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                    {val.title === 'C - Order Details' && openSection === 'cancellationOrderDetails' && renderOrderDetails()}
                                    {val.title === 'D - Cancellation Order Notes' && openSection === 'cancellationOrderNotes' && renderNoteDetails()}
                                </div>
                            );
                        })}
                    </div>
                </CardBody>
            </Card>
        </>
    );
};
export default CancellationCorrespondence;
