import React, { useEffect, useState } from 'react';
import './TechnicalClearanceFormPage.css';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Formik, FormikProvider, useFormik } from 'formik';
import { Clearance_Form_Initial_Data, VALIDATION_CLEARANCE_FORM, stepsDetailsForTechnicalClearance, stepsDetailsForTechnicalClearanceWithout } from './TechnicalClearanceFormPage.module';
import { TechnicalClearanceFormJson, edcrRequestDta, esignForDocuments, getDocsData, getFileurl } from './TechnicalClearanceFormPage.controller';
import { toast } from 'react-toastify';
import Loading from '../../../components/portalComponents/Loading.component';
import { check, close, cross, warning } from '../../../utils/Logo';
import TechnicalClearanceFormSubPage from './TechnicalClearanceFormSubPage.wrapper';
import TechnicalServices from '../../../services/technicalServices/Technical.service';
import { BPArequestInfo, devUrl, endpoints, getUserDataOnRefresh, POST, requestInfo } from '../../../services/modulesServices/userManagement.api';
import { BPA_ACTIONS_DETAILS } from './TechnicalClearanceFormPage.const';
import HeaderComponent from '../../../components/header/Header.component';
import { getNavigateTo } from '../../../utils/utils';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericPopupModal from '../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericFooterComponent from '../../../components/moduleCommonFormik/GenericFooterStepper.component';
import SideCard from '../../../components/moduleCommonFormik/SideCardGenericComponent';
import { useTranslation } from 'react-i18next';
import StatusAlertPopup from '../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericHeader from '../../../components/moduleCommonFormik/GenericHeader';
import axios from 'axios';
import { RazorpayOptions } from '../../modules/UserManagement/types';
import { getOptions, loadRazorpay } from '../../modules/EstateManagement/EstateManagement.controller';
import { KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../utils/constants';

export default function TechnicalClearanceFormPage() {
    const initialValues = Clearance_Form_Initial_Data;
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [activeStepper, setActiveStepper] = useState<string>('ARCH_DETAITLS');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showStatus, setShowStatus] = useState<boolean>(false);
    const [showProcess, setShowProcess] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [applId, setAppId] = useState<string>('');
    const [bodydata, setBodydata] = useState<string>('');
    const [employeeData, setEmployeeData] = useState(null);
    const [previewData, setPreviewData] = useState<any>(null);
    const [actionsData, setActionsData] = useState<any>(null);
    const [appStatus, setAppStatus] = useState<any>(false);
    const [isCancelBtn, setCancelBtn] = useState<any>(false);
    const [isShowActionPopup, setIsShowActionPopup] = useState<boolean>(false);
    const [bpaData, setBpaData] = useState<any>(null);
    const [allBPADetails, setAllBPADetails] = useState<any>(null);
    const [allEdcrDetails, setAllEdcrDetails] = useState<any>(null);
    const [selectedAction, setSelectedAction] = useState<any>('');
    const [allchecked, setAllChecked] = React.useState<any>([]);
    var userinfo = window.localStorage.getItem('user');
    const user_uuid = !!userinfo && JSON.parse(userinfo).uuid;
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNumber = decodeURIComponent(urlParams.get('applicationNumber') || '');
    const transactionStatus = urlParams.get('transactionStatus') || '';
    const stepperIndex = urlParams.get('stepper') || '';
    const signedFileStoreId = urlParams.get('signedFileStoreId') || '';
    const [payVisible, setPayVisible] = useState<boolean>(false);
    const [isLesseeEmpty, setIsLesseeEmpty] = useState<boolean>(false);
    const [isTransaction, setIsTransaction] = useState<boolean>(false);
    const [transactionId, setTransactionId] = useState<boolean>(false);
    const [eSignFlag, setESignFlag] = useState<boolean>(false);
    const [selectedBPA, setIsSelectedBPA] = useState<string>('');
    const [applicationFee, setApplicationFee] = useState<any>(null);
    const [sanctionFee, setSanctionFee] = useState<any>(null);
    const [paymentRowData, setPaymentRowData] = useState<any>(null);
    const [billDetails, setBillDetails] = useState<any>([]);
    const { t, i18n } = useTranslation();
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    let stepperDetails =
        !!paymentRowData?.isPaymentRecived && previewData?.status !== 'APPL_STATUS_TC_SUBMITION_PENDING' ? stepsDetailsForTechnicalClearanceWithout : stepsDetailsForTechnicalClearance;
    stepperDetails.forEach((val: any) => {
        if (!!previewData && previewData?.status !== 'APPL_STATUS_TC_SUBMITION_PENDING') val.isVisited = true;
        if (val?.index < 3) val.isVisited = true;
        if (val?.code === 'DECLARATION' && !!previewData?.lesseeDigitalSignFlage) val.isVisited = true;
        if (val?.code === 'PAYMENT' && !!paymentRowData?.isPaymentRecived) val.isVisited = true;
    });
    const [searchParams] = useSearchParams();
    const getUser = JSON.parse(localStorage.getItem('user') || '{}');

    const formik = useFormik<any>({
        validateOnBlur: true,
        validateOnChange: true,
        initialValues: initialValues,
        onSubmit: value => {},
        validationSchema: VALIDATION_CLEARANCE_FORM[`step_${activeIndex}`],
        validateOnMount: true
    });
    const { values, resetForm, setFieldValue } = formik;

    if (previewData?.status === 'APPL_FEE_PAYMENT_PENDING1') {
        stepperDetails = [
            ...stepsDetailsForTechnicalClearance,
            {
                name: 'Payment',
                code: 'PAYMENT',
                index: 6,
                isActive: false,
                titleCode: 'PAYMENT'
            }
        ];
    }

    const handleClose = () => {
        setShowPopup(false);
        setShowStatus(false);
        setIsShowActionPopup(false);
    };

    useEffect(() => {
        if (!!allBPADetails && allBPADetails.length === 0) {
            setModalDetails({ show: true, title: 'Warning!', para: t('TC_NO_EDCR_SHARED', 'No EDCR Shared'), img: warning, type: 'warning', reUpload: 'OK' });
        }
    }, [allBPADetails]);

    const signDocument = async () => {
        // if (eSignedDocumentData) {

        if (activeStepper === 'DECLARATION' && allchecked.length !== 3) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please accept all the terms', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        try {
            const payload = { RequestInfo: BPArequestInfo() };
            TechnicalServices.digitalSignDocument(values.appId, payload)
                .then(res => {
                    if (res?.data) {
                        esignSelf(res?.data?.fileStoreId);
                    }
                })
                .catch((e: any) => {
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                });

            // setESignedDocumentData(data?.data);
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const esignSelf = async (fileStoreId: string) => {
        const redirectURL = `/goa-idc/obpas/technicalClearance?${searchParams.toString()}&applicationNumber=${values?.appId || applicationNumber}&note=${
            values.notes
        }&docsUuid=${fileStoreId}&stepper=3`;
        let url = window.location.origin + redirectURL;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${fileStoreId}&callbackUrl=${url}`);
        // esignForDocuments(fileStoreId, redirectURL);
    };

    const handleSubmit = () => {
        setShowPopup(false);
        setShowStatus(false);
        if (activeStepper === 'PAYMENT') navigateToDashboard();
    };

    const handleStatusSubmit = () => {
        setShowStatus(false);
        navigateToDashboard();
    };

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    const getActionsData = () => {
        if (!!applicationNumber) {
            setIsLoading(true);

            TechnicalServices.getBpaActions(applicationNumber, {
                RequestInfo: BPArequestInfo()
            })
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        setActionsData(response?.data);
                        response?.data?.nextValidAction?.forEach((val: any) => {
                            if (val?.action === 'Pay' && !isTransaction) {
                                // setSelectedAction(val?.action);
                                setPayVisible(true);
                                return;
                            }
                        });
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const fetchPayEstimation = (data: any, type: string) => {
        if (!!applicationNumber) {
            setIsLoading(true);
            const payload = {
                RequestInfo: BPArequestInfo(),
                CalulationCriteria: [
                    {
                        applicationNo: applicationNumber,
                        riskType: 'High',
                        feeType: type,
                        tenantId: 'ga',
                        applicationType: 'TECHNICAL_CLEARANCE',
                        serviceType: 'NEW_CONSTRUCTION'
                    }
                ]
            };
            TechnicalServices.fetchEstimatePayDetails(payload)
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        if (type === 'ApplicationFee') setApplicationFee(response?.data?.Calculations && response?.data?.Calculations[0]);
                        if (type === 'SanctionFee') setSanctionFee(response?.data?.Calculations && response?.data?.Calculations[0]);
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const getPreviewDetails = () => {
        setIsLoading(true);
        if (!!applicationNumber) {
            TechnicalServices.getBpaSearch(applicationNumber, { RequestInfo: BPArequestInfo() })
                .then((response: any) => {
                    // handle the response
                    setIsLoading(false);
                    if (!response?.data?.BPA.length) {
                        setShowStatus(true);
                        setBodydata('No Application Found');
                        return;
                    }
                    setPreviewData(response?.data?.BPA[0]);
                    console.log(response?.data?.BPA[0]);
                    fetchInitialData(response?.data?.BPA[0]);
                    fetchPayEstimation(response?.data?.BPA[0], 'ApplicationFee');
                    fetchPayEstimation(response?.data?.BPA[0], 'SanctionFee');
                    const bpaValue = response?.data?.BPA[0];
                    if (bpaValue?.lesseeDigitalSignFlage) setAllChecked([{}, {}, {}]);
                    if (!((bpaValue?.status === null || bpaValue?.status === 'Draft') && bpaValue?.createdBy === user_uuid)) setBpaData(response?.data?.BPA[0]);
                    // setAppStatus((bpaValue?.status === null || bpaValue?.status === 'Draft') && bpaValue?.createdBy === user_uuid ? false : true);
                    if (!stepperIndex) setAppStatus(!bpaValue?.editable);
                    getActionsData();
                    // toast(t('DATA_FETCHED_SUCCESS', 'Data Fetched Successfully!'), {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'success'
                    // });
                })
                .catch(error => {
                    setShowStatus(true);
                    setBodydata('No Application Found');
                    setIsLoading(false);
                });
        } else {
            setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something Went Wrong!'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('SOMETHING_WENT_WRONG', 'Something Went Wrong!'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'error'
            // });
            setIsLoading(false);
        }
    };

    const onChangeNextIndex = async (key: number) => {
        setCancelBtn(false);
        const checkWithoutValidation = ['DECLARATION', 'BUILDING_PLAN_REPORT'];
        const stepperLength = stepperDetails.length;
        if (activeIndex < stepperLength) {
            if (activeStepper === 'DECLARATION' && !bpaData?.lesseeDigitalSignFlage) {
                submitForm(key);
                return;
            }

            if (!!appStatus) {
                setActiveIndex(activeIndex + 1);
                return;
            }
            try {
                // if (activeStepper !== "BUILDING_PLAN_REPORT") {
                if (!checkWithoutValidation.includes(activeStepper)) await VALIDATION_CLEARANCE_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
                // if (activeStepper === 'LESSEE_DETAITLS' && !!previewData) {
                //     setActiveIndex(activeIndex + 1);
                //     return;
                // }

                if (activeStepper === 'BUILD_PLAN') {
                    setIsLoading(true);
                    /// api call
                    let edcrRequest = edcrRequestDta();
                    var bodyFormData = new FormData();
                    let tenantId = 'ga';
                    let applicantName = 'tester';
                    let appliactionType = 'BUILDING_PLAN_SCRUTINY';
                    let applicationSubType = 'NEW_CONSTRUCTION';
                    let transactionNumber = new Date().getTime();
                    edcrRequest = { ...edcrRequest, tenantId };
                    edcrRequest = { ...edcrRequest, transactionNumber };
                    edcrRequest = { ...edcrRequest, applicantName };
                    edcrRequest = { ...edcrRequest, appliactionType };
                    edcrRequest = { ...edcrRequest, applicationSubType };
                    bodyFormData.append('edcrRequest', JSON.stringify(edcrRequest));
                    bodyFormData.append('planFile', values?.buildingPlan[0]?.file);
                    TechnicalServices.createScrutinize(bodyFormData)
                        .then(response => {
                            setFormData(response?.data);
                            setIsLoading(false);
                            setShowProcess(true);
                            setTimeout(() => {
                                setCancelBtn(true);
                            }, 18000);
                            setTimeout(() => {
                                setShowProcess(false);
                                setActiveIndex(activeIndex + 1);
                            }, 22000);
                        })
                        .catch(error => {
                            // handle errors
                            setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
                            // toast(error?.message, {
                            //     position: toast.POSITION.TOP_RIGHT,
                            //     className: 'foo-bar',
                            //     type: 'error'
                            // });
                            setIsLoading(false);
                        });
                } else {
                    submitForm(key);
                }
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
                // toast(t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
            }
        }
        setTimeout(() => {
            console.log(activeIndex, stepperDetails.length, appStatus);
        }, 5000);
    };

    const onChangePrevIndex = () => {
        const stepperLength = stepperDetails.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const handleStepClick = async (index: number) => {
        const checkWithoutValidation = ['DECLARATION', 'BUILDING_PLAN_REPORT', 'PAYMENT'];
        if (!!appStatus) {
            setActiveIndex(index);
            return;
        }
        try {
            if (!checkWithoutValidation.includes(activeStepper)) await VALIDATION_CLEARANCE_FORM[`step_${activeIndex}`].validate(values, { abortEarly: false });
            setActiveIndex(index);
            window.scrollTo(0, 0);
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
        }
    };

    const onActionClick = (action: string) => {
        setFieldValue('comments', '');
        setSelectedAction(action);
        if (action === 'Pay') {
            setPayVisible(true);
            return;
        }
        setIsShowActionPopup(true);
    };

    useEffect(() => {
        loadRazorpay();
    }, []);

    const onPay = async (key?: any) => {
        if (!selectedGateway) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });
            // toast('Please select a payment gateway', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'warning' });
            return;
        }
        try {
            TechnicalServices.generateDemand(applicationNumber, selectedGateway, { RequestInfo: BPArequestInfo() }).then(async (response: any) => {
                const payload_for = { RequestInfo: BPArequestInfo() };
                TechnicalServices.fetchBillDetails(values?.appId || applicationNumber, payload_for)
                    .then(async res => {
                        if (res?.data) {
                            setBillDetails(res?.data?.Bill);
                            const billings = res?.data?.Bill[0];
                            const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
                            setIsLoading(true);
                            let url = window.location.origin + `/goa-idc/obpas/technicalClearance?applicationNumber=${values?.appId || applicationNumber}&tenantId=ga&transactionStatus=1&stepper=4`;

                            const payload = {
                                RequestInfo: requestInfo(),
                                applicationType: 'Technical Clearance',
                                applicationStatus: null,
                                applicationId: values?.appId || applicationNumber,
                                tenantId: 'ga',
                                auditDetails: {
                                    createdBy: 323,
                                    lastModifiedBy: 323
                                },
                                Transaction: {
                                    tenantId: 'ga',
                                    billId: billings?.id,
                                    txnAmount: billings?.totalAmount,
                                    module: 'Technical Clearance',
                                    taxAndPayments: billings?.billDetails[0]?.billAccountDetails.map((obj: any) => ({
                                        amountPaid: obj?.amount,
                                        billId: billings?.id
                                    })),
                                    consumerCode: values?.appId || applicationNumber,
                                    additionalDetails: { paymentReceiptDetails: paymentRowData?.paymentReceiptDetails },
                                    productInfo: 'Technical Clearance',
                                    gateway: selectedGateway,
                                    user: userInfo,
                                    callbackUrl: url
                                }
                            };
                            try {
                                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS' || selectedGateway === 'ICICI') {
                                    if ((values?.appId || applicationNumber) && selectedGateway && billings?.totalAmount > 0) {
                                        const { data } = await POST(endpoints.initiateTransaction, payload);
                                        const data1 = data?.Transaction;
                                        console.log(data1, 'NEW_DATA');
                                        if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                                            console.log(data, 'MYDATA');
                                            const options: RazorpayOptions = getOptions(
                                                data1.txnAmount,
                                                getUser,
                                                'description',
                                                data1?.additionalDetails?.ORDER_ID,
                                                data1.callbackUrl,
                                                KEY_ID[selectedGateway]
                                            );
                                            console.log(options);
                                            const rzp1: any = (window as any).Razorpay(options);
                                            rzp1.open();
                                        } else if (selectedGateway === 'ICICI') {
                                            console.log(data1.additionalDetails);
                                            setTimeout(() => {
                                                navigate(
                                                    `/goa-idc/icici-payment?walletClientCode=${data1.additionalDetails.walletClientCode}&walletRequestMessage=${data1.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                                                );
                                            }, 500);
                                            console.log(data1, 'oldDATA___1');
                                            return;
                                        } else if (data1 && data1.redirectUrl) {
                                            window.open(data1.redirectUrl, '_self');
                                        }
                                        setIsLoading(false);
                                    }
                                } else {
                                    if ((values?.appId || applicationNumber) && selectedGateway) {
                                        TechnicalServices.addTransaction(payload, false, '')
                                            .then(res => {
                                                if (res?.data?.Transaction) {
                                                    window.open(res?.data?.Transaction?.redirectUrl, '_self');
                                                }
                                            })
                                            .catch((e: any) => {
                                                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                                                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                                                setIsLoading(false);
                                            });
                                        setIsLoading(false);
                                    }
                                }
                            } catch (e: any) {
                                setIsLoading(false);
                                console.log(JSON.stringify(e), 'ERROR!!');
                                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                                // toast(errMsg, {
                                //     position: toast.POSITION.TOP_RIGHT,
                                //     className: 'foo-bar',
                                //     type: 'error'
                                // });
                                // setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                                // setShowAlert(_ => true);
                            }
                        }
                    })
                    .catch(err => {
                        setModalDetails({ show: true, title: 'Error!', para: err?.response?.data?.Errors[0]?.message, img: cross, type: 'error', reUpload: 'OK' });
                        // toast(err?.response?.data?.Errors[0]?.message, {
                        //     position: toast.POSITION.TOP_RIGHT,
                        //     className: 'foo-bar',
                        //     type: 'error'
                        // });
                    });
            });
        } catch (error) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!!transactionStatus) {
            const payload = { RequestInfo: BPArequestInfo() };
            // const redirectUrl = urlParams
            const retainList = ['msg'];
            for (const key of [...urlParams.keys()]) {
                if (retainList.includes(key)) {
                    urlParams.delete(key);
                }
            }
            TechnicalServices.addTransaction(payload, true, urlParams)
                .then((data: any) => {
                    if (data) {
                        if (data?.data?.Transaction[0]?.txnStatus === 'FAILURE') {
                            setTransactionId(false);
                            setPayVisible(false);
                            setModalDetails({ show: true, title: 'Error!', para: 'Payment failed', img: cross, type: 'error', reUpload: 'OK' });
                            // toast('Payment failed', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'error' });
                        } else {
                            TechnicalServices.updatePayment(data?.data?.Transaction[0]?.consumerCode, new Date().getSeconds(), data?.data?.Transaction[0]?.txnId, payload).then((data: any) => {
                                setIsTransaction(true);
                                setTransactionId(true);
                                setPayVisible(false);
                                // toast('Payment successful', { position: toast.POSITION.TOP_RIGHT, className: 'foo-bar', type: 'success' });
                                getPaymentData();
                            });
                        }
                    }
                })
                .catch(() => {});
        }
    }, [transactionStatus]);

    const submitForm = (key: number, action?: string, storeId?: any) => {
        if (activeStepper === 'DECLARATION' && allchecked.length !== 3) {
            setModalDetails({ show: true, title: 'Error!', para: t('CHECK_DECLARATION_PROCEED', 'Please check all declarations and proceed'), img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeStepper === 'DECLARATION' && !bpaData?.lesseeDigitalSignFlage && action !== 'eSign') {
            setModalDetails({ show: true, title: 'E-Sign Pending', para: 'Please sign the document', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (activeStepper === 'PAYMENT' && !paymentRowData?.isPaymentRecived) {
            setModalDetails({ show: true, title: 'Payment Pending', para: 'Please proceed for the payment', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if (!!appStatus && !action && previewData?.status !== 'APPL_STATUS_TC_SUBMITION_PENDING') {
            navigateToDashboard();
            return;
        }

        setIsLoading(true);
        const allDocs = [
            ...values?.CERTIFICATE_ARCHITECTURE,
            ...values?.CERTIFICATE_LIABILITY,
            ...values?.SITEPLAN,
            ...values?.DOCUMENT_QUESTIONNAIRE,
            ...values?.DOCUMENT_AFFIDAVIT,
            ...values?.DOCUMENT_POWER_ATTORNEY
        ];
        const uniqueData = allDocs.filter((item, index, self) => index === self.findIndex(t => t.fileStoreId === item.fileStoreId));

        values.documents = uniqueData;

        const payload: any = TechnicalClearanceFormJson(values, bpaData, !!bpaData?.accountId);

        payload.BPA.edcrNumber = formData?.edcrNumber || payload?.BPA?.edcrNumber || null;
        if (!!bpaData?.accountId) {
            payload.BPA.documents = getDocsData(uniqueData, values?.appId);
        }

        if (activeStepper === 'PAYMENT' && action !== 'eSign') {
            payload.BPA.workflow = {
                action: 'ACT_TC_SUBMIT',
                assignes: null,
                notes: null,
                varificationDocuments: null
            };
        } else {
            payload.BPA.workflow = null;
        }

        if ((!!selectedAction || !!action) && action !== 'eSign')
            payload.BPA.workflow = {
                action: selectedAction || action,
                assignes: null,
                notes: values?.comments,
                varificationDocuments: null
            };

        if (action === 'eSign') {
            payload.BPA.lesseeDigitalSignFlage = true;
            payload.BPA.digitalSignedDoc = storeId;
        }

        TechnicalServices.createTechnicalClearance(payload)
            .then((response: any) => {
                setIsLoading(false);
                if (action === 'Initiate Construction Licence') {
                    navigate(`/goa-idc/obpas/constructionLicense?applicationNumber=${response?.data?.BPA[0]?.applicationNo}&tenantId=ga`);
                    return;
                }
                if (action === 'Pay') {
                    navigate('/goa-idc/obpas/paymentSkip');
                    return;
                }

                // toast(`${t('APPLICATION', 'Application')} ${!!values?.id ? t('SAVED', 'Saved') : t('CREATED', 'Created')} ${t('SUCCESSFULLY', 'Successfully!')}`, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'success'
                // });
                setAppId(response?.data?.BPA[0]?.applicationNo);
                setBpaData(response?.data?.BPA[0]);
                fetchInitialDocuments(response?.data?.BPA[0]);
                values.appId = response?.data?.BPA[0]?.applicationNo;
                values.id = response?.data?.BPA[0]?.id;
                if (action === 'eSign') {
                    setESignFlag(true);
                    return;
                }

                if (!!selectedAction || !!action) {
                    navigateToDashboard();
                    return;
                }
                if (activeStepper === 'PAYMENT') setShowPopup(true);
                if (key === 1) setActiveIndex(activeIndex + 1);
                if (!!selectedAction) {
                    setIsShowActionPopup(false);
                    getPreviewDetails();
                }
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
                // toast(error?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'error'
                // });
            });
    };

    const renderActionBtn = () =>
        actionsData?.nextValidAction.map((val: any) => {
            // eslint-disable-next-line array-callback-return
            if (val?.action === 'Submit' || val?.action === 'ACT_TC_SUBMIT' || (val?.action === 'Pay' && activeStepper !== 'PAYMENT')) return;
            return (
                <>
                    <Button
                        variant='disabled outline-success new_btn btn btn-light me-3 font-karla-med p-10 fs-6'
                        style={{ borderColor: '#45852e', color: '#45852e', width: 'max-content', borderRadius: 8 }}
                        onClick={() => {
                            val?.action === 'Pay' ? onPay() : onActionClick(val?.action);
                        }}
                    >
                        {val?.action === 'Pay' ? 'Pay now' : val?.action.replace(/_/g, ' ')}
                    </Button>
                </>
            );
        });

    const renderActionForm = () => {
        return (
            <>
                {BPA_ACTIONS_DETAILS.map(val => (
                    <Card.Body>
                        <GenericFormCard {...val} />
                    </Card.Body>
                ))}
            </>
        );
    };

    const getActiveStepper = () => {
        stepperDetails.forEach(val => {
            if (val?.index === activeIndex) {
                setActiveStepper(val?.code);
            }
        });
    };

    const checkRelaod = () => {
        const reloadCount = sessionStorage.getItem('count') || '1';
        if (!!reloadCount && +reloadCount < 2) {
            sessionStorage.setItem('count', reloadCount + 1);
            window.location.reload();
        } else {
            sessionStorage.removeItem('count');
            getTechnicalClearanceData();
        }
    };

    const handelPlayVisible = () => {
        setPayVisible(false);
    };
    const handelIsLesseeVisible = () => {
        setIsLesseeEmpty(false);
        navigate(-1);
    };

    const handelIsTransactionVisible = () => {
        setIsTransaction(false);
        // window.location.reload();
    };

    const checkRole = async () => {
        try {
            const { data } = await getUserDataOnRefresh();
            if (data) {
                if (data.type === 'EMPLOYEE') {
                    navigate(`/goa-idc/obpas/e/technicalClearance?applicationNumber=${applicationNumber}&tenantId=ga`);
                }
            }
        } catch (error) {}
    };
    useEffect(() => {
        checkRole();
        getPaymentData();
    }, []);

    useEffect(() => {
        getPaymentData(values?.appId);
    }, [activeStepper === 'PAYMENT']);

    useEffect(() => {
        setTimeout(() => {
            if (!!stepperIndex && stepperIndex === '4') {
                setActiveIndex(4);
                setActiveStepper('PAYMENT');
            }

            if (!!stepperIndex && stepperIndex === '3') {
                setActiveIndex(3);
                setActiveStepper('DECLARATION');
                setAllChecked([{}, {}, {}]);
                submitForm(0, 'eSign', signedFileStoreId);
                setTimeout(() => {
                    getPreviewDetails();
                    setAllChecked([{}, {}, {}]);
                }, 500);
            }
        }, 1000);
    }, [stepperIndex]);

    useEffect(() => {
        getActiveStepper();
    }, [activeIndex]);

    useEffect(() => {
        if (!!applicationNumber) getPreviewDetails();
        if (!applicationNumber) checkRelaod();
        setTimeout(() => {
            // getUserData();
        }, 500);
    }, [applicationNumber]);

    const handleScroll = () => {
        if (window.scrollY > 110) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const getPaymentData = async (appId = null) => {
        if (!applicationNumber && !appId) return;
        try {
            const payload = { RequestInfo: BPArequestInfo() };
            TechnicalServices.searchPaymentDetails(appId || applicationNumber, payload)
                .then(res => {
                    if (res?.data) {
                        setPaymentRowData(res?.data);
                    } else {
                        throw new Error('No data found');
                    }
                })
                .catch(error => {
                    let errorMessage = 'Failed to retrieve payment data';
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        errorMessage += `: ${error.response.data.message || error.response.statusText}`;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage += ': No response received from server';
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage += `: ${error.message}`;
                    }
                    setModalDetails({ show: true, title: 'Error!', para: errorMessage, img: cross, type: 'error', reUpload: 'OK' });
                    // toast(errorMessage, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'error'
                    // });
                });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Unexpected error occurred', img: cross, type: 'error', reUpload: 'OK' });
            // toast('Unexpected error occurred', {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'error'
            // });
        }
    };

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({ show: false });
        if (type === 'warning') {
            navigateToDashboard();
        }
    };

    const getTechnicalClearanceData = () => {
        const payload = { RequestInfo: BPArequestInfo() };
        TechnicalServices.applyTechnicalClearance(payload)
            .then(res => {
                if (res?.data) {
                    setAllBPADetails(res?.data?.BPA);
                    setAllEdcrDetails(res?.data?.sharedEdcr);
                    if (res?.data?.BPA.length > 0) {
                        fetchInitialData(res?.data?.BPA[0]);
                        setFormData(res?.data?.BPA[0]);
                    }
                }
            })
            .catch(err => {
                setIsLesseeEmpty(true);
                setModalDetails({ show: true, title: 'Error!', para: err?.response?.data?.Errors[0]?.message, img: cross, type: 'error', reUpload: 'OK' });
                // toast(err?.response?.data?.Errors[0]?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'error'
                // });
            });
    };

    const fetchBiilingDetails = () => {
        const payload = { RequestInfo: BPArequestInfo() };
        TechnicalServices.fetchBillDetails(values?.appId || applicationNumber, payload)
            .then(res => {
                if (res?.data) {
                    console.log(res?.data, 'DETAILS');
                    setBillDetails(res?.data?.Bill);
                    // setAllBPADetails(res?.data?.BPA);
                }
            })
            .catch(err => {
                setModalDetails({ show: true, title: 'Error!', para: err?.response?.data?.Errors[0]?.message, img: cross, type: 'error', reUpload: 'OK' });
                // toast(err?.response?.data?.Errors[0]?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'error'
                // });
            });
    };

    useEffect(() => {
        if (!!selectedGateway) fetchBiilingDetails();
    }, [selectedGateway]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (allBPADetails) {
            const mainBPADetails = allBPADetails.filter((val: any) => val?.edcrNumber === selectedBPA);
            fetchInitialData(mainBPADetails[0]);
            setFormData(mainBPADetails[0]);
        }
    }, [selectedBPA]);

    const fetchInitialDocuments = (preData: any) => {
        values.SITEPLAN = getFileurl(preData?.documents, 'SITEPLAN');
        values.DOCUMENT_QUESTIONNAIRE = getFileurl(preData?.documents, 'DOCUMENT_QUESTIONNAIRE');
        values.DOCUMENT_AFFIDAVIT = getFileurl(preData?.documents, 'DOCUMENT_AFFIDAVIT');
        values.DOCUMENT_POWER_ATTORNEY = getFileurl(preData?.documents, 'DOCUMENT_POWER_ATTORNEY');
        values.CERTIFICATE_ARCHITECTURE = getFileurl(preData?.documents, 'CERTIFICATE_ARCHITECTURE');
        values.CERTIFICATE_LIABILITY = getFileurl(preData?.documents, 'CERTIFICATE_LIABILITY');
    };

    const fetchInitialData = (preData: any) => {
        values.name = preData?.lesseeDetails?.name;
        values.gender = preData?.lesseeDetails?.gender;
        values.phone = preData?.lesseeDetails?.phoneNo;
        values.panNo = preData?.lesseeDetails?.pan;
        values.email = preData?.lesseeDetails?.email;
        values.addressLine1 = preData?.lesseeDetails?.address?.addressLine1 || '';
        values.addressLine2 = preData?.lesseeDetails?.address?.addressLine2 || '';
        values.country = preData?.lesseeDetails?.address?.country;
        values.state = preData?.lesseeDetails?.address?.state;
        values.district = preData?.lesseeDetails?.address?.district;
        values.pincode = preData?.lesseeDetails?.address?.pincode;
        values.id = preData?.id;
        values.appId = preData?.applicationNo;
        values.archName = preData?.archName;
        values.archId = preData?.archRegId;
        values.serviceType = preData?.serviceType || 'NEW_CONSTRUCTION';
        values.licenseeType = '';
        values.CouncilArcNo = '';
        values.buildingPlan = [];
        values.token = '';
        values.cert = '';
        values.pin = '';
        values.documents = preData?.documents;
        values.forwardTo = preData?.lesseeDetails?.name;
        values.comments = '';
        values.additionalDetails = preData?.additionalDetails;
        values.SITEPLAN = getFileurl(preData?.documents, 'SITEPLAN');
        values.DOCUMENT_QUESTIONNAIRE = getFileurl(preData?.documents, 'DOCUMENT_QUESTIONNAIRE');
        values.DOCUMENT_AFFIDAVIT = getFileurl(preData?.documents, 'DOCUMENT_AFFIDAVIT');
        values.DOCUMENT_POWER_ATTORNEY = getFileurl(preData?.documents, 'DOCUMENT_POWER_ATTORNEY');
        values.CERTIFICATE_ARCHITECTURE = getFileurl(preData?.documents, 'CERTIFICATE_ARCHITECTURE');
        values.CERTIFICATE_LIABILITY = getFileurl(preData?.documents, 'CERTIFICATE_LIABILITY');
        values.isEditableFile = preData?.editable;
        values.edcrNumber = preData?.edcrNumber;
        values.lesseeDetails = preData?.lesseeDetails;
        values.accountId = preData?.accountId;
    };

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const gotoSkipProcess = () => {
        submitForm(0, selectedAction);
    };

    // const checkVisitedStatus = () => {
    //     if(bpaData?.status !== "APPL_STATUS_TC_SUBMITION_PENDING") return true;
    //     if(activeIndex < 3) return true;
    //     if (activeStepper === "DECLARATION" && !!bpaData?.lesseeDigitalSignFlage) return true;
    //     if (activeStepper === "PAYMENT" && !!bpaData?.isPaymentRecived) return true;
    // }
    const successMessageTC = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} Submitted successfully`;

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <StatusAlertPopup
                img={check}
                title={'Success!'}
                // para={t('POPUP_MSG_SUCCESS_APPLICATION_SUBMITTED', 'Technical Clearance Application Submitted successfully')}
                para={successMessageTC}
                fontsize={'fontsize'}
                // children={
                //     <div>
                //         <span className='fw-bold font-14'>Your Application Number</span>: <b className='font-15 text-success'>{applId}</b>
                //     </div>
                // }
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                show={showPopup}
            />
            <StatusAlertPopup title='' para={bodydata} img={close} handleClose={handleStatusSubmit} handleSubmit={handleStatusSubmit} show={showStatus} />
            <div className={`applicationPage m-auto px-5 py-4 ${activeStepper === 'BUILDING_PLAN_REPORT' ? 'h-100' : 'vh-100'}`}>
                <GenericHeader
                    text={t('TC_HEADER_APPLICATION_FOR_TECHNICAL_CLEARANCE', 'Application for Technical Clearance')}
                    additionalClass='sub-text-style'
                    bottomSubText={`File : ${values?.name}, Type : ${t('TC_HEADER_TYPE', 'Technical Clearance')}, No : ${applicationNumber || values.appId || ''}`}
                />
                <div className='row d-flex my-4'>
                    <div className='col-lg-3 position-sticky align-self-start' style={{ top: '90px' }}>
                        <div className='stickyCard'>
                            <GenericHeader
                                text={t('TC_SUBHEADER_APPLICATION_FOR_TECHNICAL_CLEARANCE', 'Application for Technical Clearance')}
                                sideHeader={true}
                                additionalClass='sub-text-style'
                                bottomSubText={`Type : ${t('TC_SUBHEADER_TYPE', 'Technical Clearance')}, No : ${applicationNumber || values.appId || ''}`}
                            />
                            <Card className='card_border p-3 mt-2 mb-4'>
                                <Card.Body>
                                    <Card.Text className=' d-flex flex-column row-gap-3  '>
                                        <SideCard indexType='alphabets' steps={stepperDetails} handleStepClick={handleStepClick} activeIndex={activeIndex} />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            {/* <Card className='card_border note mb-4'>
                                <Card.Body>
                                    <Card.Text>
                                        <span>
                                            <i className='fa fa-exclamation-circle' aria-hidden='true'></i>
                                        </span>
                                        <b> Note : </b>
                                        {t('PLEASE_PROVIDE_ALL_THE_DETAILS', 'Please provide all the details.')}
                                    </Card.Text>
                                </Card.Body>
                            </Card> */}
                        </div>
                    </div>
                    <div className='col-lg-9 me-auto mb-5'>
                        <Formik initialValues={initialValues} onSubmit={value => {}} validationSchema={VALIDATION_CLEARANCE_FORM}>
                            <FormikProvider value={formik}>
                                <Form>
                                    <TechnicalClearanceFormSubPage
                                        applicationNumber={applicationNumber}
                                        index={activeIndex}
                                        activeStepper={activeStepper}
                                        setActiveIndex={setActiveIndex}
                                        employeeList={employeeData}
                                        formData={formData}
                                        previewData={previewData}
                                        appStatus={appStatus}
                                        showProcess={showProcess}
                                        allchecked={allchecked}
                                        setAllChecked={setAllChecked}
                                        bpaData={bpaData}
                                        isCancelBtn={isCancelBtn}
                                        setShowProcess={setShowProcess}
                                        allBPADetails={allBPADetails}
                                        allEdcrDetails={allEdcrDetails}
                                        setIsSelectedBPA={setIsSelectedBPA}
                                        applicationFee={applicationFee}
                                        sanctionFee={sanctionFee}
                                        paymentRowData={paymentRowData}
                                        payVisible={payVisible}
                                        handelPlayVisible={handelPlayVisible}
                                        selectedGateway={selectedGateway}
                                        setSelectedGateway={setSelectedGateway}
                                        onPay={onPay}
                                        signDocument={signDocument}
                                        eSignFlag={eSignFlag}
                                    />
                                    <GenericPopupModal
                                        title=''
                                        subTitle={`Application No: ${previewData?.applicationNo}`}
                                        isVisible={isShowActionPopup}
                                        primaryBtnTitle={selectedAction?.replace(/_/g, ' ')}
                                        secondaryAction={handleClose}
                                        primaryAction={() => {
                                            submitForm(0, selectedAction);
                                        }}
                                        secondaryBtnTitle='Close'
                                        children={renderActionForm()}
                                    />
                                </Form>
                            </FormikProvider>
                        </Formik>
                    </div>
                </div>
            </div>
            {
                <GenericFooterComponent
                    isSaveBtn={false}
                    onClickNext={onChangeNextIndex}
                    onClickSave={onChangeNextIndex}
                    onSubmit={() => {
                        !paymentRowData?.isPaymentRecived ? onPay() : submitForm(stepperDetails.length === 4 ? 1 : 0);
                    }}
                    onCancel={() => navigateToDashboard()}
                    onClickPrev={onChangePrevIndex}
                    stepperLength={stepperDetails.length}
                    activeIndex={activeIndex}
                    secondaryBtnLabel={
                        !bpaData?.lesseeDigitalSignFlage && activeStepper === 'DECLARATION'
                            ? t('SAVE_AND_NEXT', 'Save & Next')
                            : activeStepper !== 'PAYMENT' && activeIndex === stepperDetails.length
                            ? `${t('CLOSE', 'Close')}`
                            : activeStepper === 'PAYMENT' && activeIndex === stepperDetails.length
                            ? !paymentRowData?.isPaymentRecived
                                ? `${t('PAYNOW', 'Pay Now')}`
                                : `${t('ACT_TC_SUBMIT', 'Submit')}`
                            : !!appStatus
                            ? `${t('NEXT', 'Next')}`
                            : `${t('NEXT', 'Save & Next')}`
                    }
                    children={renderActionBtn()}
                />
            }
            <StatusAlertPopup
                img={cross}
                title={'Failed to find the lessee'}
                para={''}
                fontsize={'fontsize'}
                scrutiny=''
                scrutinyPara={'scrutinyPara'}
                S_number=''
                reUpload='Close'
                handleClose={handelIsLesseeVisible}
                handleSubmit={handelIsLesseeVisible}
                show={isLesseeEmpty}
            />
            <StatusAlertPopup
                img={!!transactionId ? check : cross}
                title={!!transactionId ? 'Success!' : 'Error!'}
                para={!!transactionId ? 'Payment successful' : 'Payment failed'}
                fontsize={'fontsize'}
                scrutiny=''
                scrutinyPara={'scrutinyPara'}
                S_number=''
                reUpload='Close'
                handleClose={handelIsTransactionVisible}
                handleSubmit={handelIsTransactionVisible}
                show={isTransaction}
            />
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.finalSubmit)}
            />
        </>
    );
}
