import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import { t } from 'i18next';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import { Card } from 'react-bootstrap';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { ASSIGN_FORM, MANAGER_TABLE } from './EmployeeMapping.const';
import { FormikProvider, useFormik } from 'formik';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import { check, cross } from '../../../../../utils/Logo';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import { endpoints, POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';

export function EmployeeMappingEdit() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openIndex, setOpenIndex] = useState<any>(null);
    const [employeeDataView, setEmployeeDataView] = useState<boolean>(false);
    const [showAssignEmployeeModal, setShowAssignEmployeeModal] = useState<boolean>(false);
    const [managerType, setManagerType] = useState<string>('');
    const [modalDetails, setModalDetails] = useState<any>({});
    const [dropdownData, setDropDownData] = useState<any>({});
    const [areaManagerEmployee, setAreaManagerEmployee] = useState<any>([]);
    const [fieldManagerEmployee, setFieldManagerEmployee] = useState<any>([]);

    const navigate = useNavigate();
    let userObject = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = userObject?.type;
    const location: any = useLocation();
    const employeeData = location?.state?.data;
    const isView = location?.state?.isView;

    const managerTable = isView ? MANAGER_TABLE?.filter((val: any) => val.fieldName !== 'action') : MANAGER_TABLE;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {}
    });

    const { values, setValues } = formik;

    const showForm = (manager: string) => {
        setOpenIndex(null);
        setShowAssignEmployeeModal(true);
        setManagerType(manager);
    };

    useEffect(() => {
        if (userType !== 'EMPLOYEE') {
            setModalDetails({
                show: true,
                title: t('ERROR', 'Error!'),
                para: t('UNAUTHORIZED_ACCESS', 'Unauthorized access'),
                img: cross,
                type: 'error',
                reUpload: 'OK',
                isUnauthorized: true
            });
        }

        if (userType === 'EMPLOYEE') {
            const tempAreaManager = employeeData?.areaManagerEmployees?.map((val: any, index: number) => {
                return { serialNo: index + 1, employeeId: val?.code, employeeName: val?.user?.name };
            });

            const tempFieldManager = employeeData?.fieldManagerEmployees?.map((val: any, index: number) => {
                return { serialNo: index + 1, employeeId: val?.code, employeeName: val?.user?.name };
            });

            setAreaManagerEmployee(tempAreaManager);
            setFieldManagerEmployee(tempFieldManager);
        }
    }, []);

    useEffect(() => {
        if (userType === 'EMPLOYEE') {
            try {
                const fetchData = async (designation: any) => {
                    setIsLoading(true);
                    const { data } = await POST(`${endpoints.searchEmployee}&designations=${designation}`, { RequestInfo: requestInfo() });

                    const employeeId = data?.Employees?.map((val: any) => ({
                        name: val?.code,
                        code: val?.user?.name,
                        userUuid: val?.uuid
                    }));

                    const employeeName = data?.Employees?.map((val: any) => ({
                        name: val?.user?.name,
                        code: val?.code,
                        userUuid: val?.uuid
                    }));

                    setDropDownData({ employeeId, employeeName });
                    setIsLoading(false);
                };

                (async () => {
                    if (managerType === 'AreaManager') {
                        await fetchData('ARM');
                    } else if (managerType === 'FieldManagerSite') {
                        await fetchData('FM_SITE');
                    }
                })();
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    }, [managerType]);

    const addEmployeeData = async () => {
        const selectedEmployee = dropdownData?.employeeId?.find((val: any) => val?.name === values?.employeeId.name);
        const payload = {
            RequestInfo: requestInfo(),
            userUuid: selectedEmployee?.userUuid,
            estateId: employeeData?.estateDetails?.estateId
        };

        try {
            await POST(endpoints.managerCreate, payload);
            setShowAssignEmployeeModal(false);
            setModalDetails({ show: true, title: 'Success!', para: 'Manager mapped successfully', img: check, type: 'success', reUpload: 'OK' });
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string, isUnauthorized: boolean) => {
        setModalDetails({});
        if (type === 'success' && !isUnauthorized) {
            navigate('/goa-idc/employee-mapping');
        } else if (isUnauthorized) {
            if (userType === 'ENTERPRISE') {
                navigate('/goa-idc/enterprisedashboard');
            } else if (userType === 'EXTERNAL_EMPLOYEE') {
                navigate('/goa-idc/architectdashboard');
            }
        }
    };

    const customHandleChange = (e: any, field: any) => {
        if (field === 'employeeId') {
            const currentEmployeeName = dropdownData?.employeeName?.find((val: any) => val?.name === e.value);
            setValues((prev: any) => ({ ...prev, employeeName: currentEmployeeName, employeeId: { name: e.label, code: e.value } }));
        }
        if (field === 'employeeName') {
            const currentEmployeeId = dropdownData?.employeeId?.find((val: any) => val?.name === e.value);
            setValues((prev: any) => ({ ...prev, employeeName: { name: e.label, code: e.value }, employeeId: currentEmployeeId }));
        }
    };

    const onView = (row: any) => {
        setShowAssignEmployeeModal(true);
        setEmployeeDataView(true);
        setValues((prev: any) => ({ ...prev, employeeId: { name: row?.employeeId, code: row?.employeeName }, employeeName: { name: row?.employeeName, code: row?.employeeId }, isUpdatable: false }));
    };

    const onClose = () => {
        setShowAssignEmployeeModal(false);
        setDropDownData({});
        setValues((prev: any) => ({ ...prev, employeeId: '', employeeName: '', isUpdatable: true }));
        setManagerType('');
        setEmployeeDataView(false);
    };

    const onDelete = async (row: any) => {
        const combinedEmployee = [...(employeeData?.areaManagerEmployees ?? []), ...(employeeData?.fieldManagerEmployees ?? [])];
        const selectedEmployee = combinedEmployee?.find((val: any) => val?.code === row?.employeeId);

        try {
            setIsLoading(true);
            const { data: deleteEmployee } = await POST(endpoints.managerSearch + `?userUuids=${selectedEmployee?.uuid}&estateIds=${employeeData?.estateDetails?.estateId}`, {
                RequestInfo: requestInfo()
            });

            const payload = {
                RequestInfo: requestInfo(),
                ...deleteEmployee?.estateManagers?.[0],
                isActive: false
            };

            await POST(endpoints.managerUpdate, payload);
            setModalDetails({ show: true, title: 'Success!', para: 'Manager deleted successfully', img: check, type: 'success', reUpload: 'OK' });
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log('values', values);

    return (
        <>
            <HeaderComponent />

            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <FormikProvider value={formik}>
                        <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                            <GenericHeader text={employeeData?.estateName} />
                            <div className='col-13 form-section'>
                                <Card className='card_border'>
                                    <Card.Body>
                                        <div className='row justify-content-between align-items-center row-gap-4 pe-2'>
                                            <div className='col-9'>
                                                <p className='font-inter font-20 fw-700 d-flex'>{t('AREA_MANAGER', 'Area Manager')}</p>
                                            </div>
                                            {!isView && (
                                                <GenericButtons
                                                    ClassName='mb-2'
                                                    text={t('ASSIGN', '+ ASSIGN')}
                                                    handleClick={() => showForm('AreaManager')}
                                                    variant='primary'
                                                    // disabled={!values.isUpdatable || totalOwnershipPercentage === 100 || props?.isDisabled}
                                                />
                                            )}
                                        </div>
                                        <GenericActionTable columns={managerTable} hideSearchBar={true} itemsPerPage={5} rowData={areaManagerEmployee} handleView={onView} onDeleteRow={onDelete} />
                                    </Card.Body>
                                </Card>

                                <Card className='card_border'>
                                    <Card.Body>
                                        <div className='row justify-content-between align-items-center row-gap-4 pe-2'>
                                            <div className='col-9'>
                                                <p className='font-inter font-20 fw-700 d-flex'>{t('FIELD_MANAGER_SITE', 'Field Manager Site')}</p>
                                            </div>
                                            {!isView && (
                                                <GenericButtons
                                                    ClassName='mb-2'
                                                    text={t('ASSIGN', '+ ASSIGN')}
                                                    handleClick={() => showForm('FieldManagerSite')}
                                                    variant='primary'
                                                    // disabled={!values.isUpdatable || totalOwnershipPercentage === 100 || props?.isDisabled}
                                                />
                                            )}
                                        </div>
                                        <GenericActionTable columns={managerTable} hideSearchBar={true} itemsPerPage={5} rowData={fieldManagerEmployee} handleView={onView} onDeleteRow={onDelete} />
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        <GenericPopupModal
                            title={`${employeeDataView ? t('VIEW_EMPLOYEE', 'View Employee') : t('ASSIGN_EMPLOYEE', 'Assign Employee')}`}
                            size='lg'
                            primaryAction={addEmployeeData}
                            secondaryAction={onClose}
                            isVisible={showAssignEmployeeModal}
                            children={<RENDER_ASSIGN_FORM dropdownData={dropdownData} customHandleChange={customHandleChange} />}
                            primaryBtnTitle={employeeDataView ? t('CLOSE', 'Close') : t('SAVE', 'Save')}
                            secondaryBtnTitle={t('CLOSE', 'Close')}
                            onClose={onClose}
                            isPrimaryBtn={values.isUpdatable}
                        />
                    </FormikProvider>

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.isUnauthorized)}
                    />
                </>
            )}
        </>
    );
}

const RENDER_ASSIGN_FORM = ({ dropdownData, customHandleChange }: any) => {
    return (
        <>
            {ASSIGN_FORM?.map((val: any) => (
                <GenericFormCard {...val} dropdownOptions={dropdownData} customHandleChange={customHandleChange} />
            ))}
        </>
    );
};
