import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { FieldArray, useFormikContext } from 'formik';
import Editor from '../../../../components/ContentEditor';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { CONTRACT_DETAILS_VALIDATION, PBG_DETAILS_VALIDATION, WO_DETAILS_VALIDATION, WORK_CATEGORY_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { convertDateToDDmmYYYY, getAbsoluteMonthsBetweenTwoDates, getFirstErrorKey } from '../../../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { cross, DeleteWarning } from '../../../../utils/Logo';
import _ from 'lodash';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import DeletePopUpModal from '../../../../components/moduleCommonFormik/DeletePopUp.component';
import { ABSTRACT_TABLE_COLUMNS, DPR_MEASUREMENT, HEADER_FIELDS, MEASUREMENT_DETAILS } from '../DPR creation screen/DPR.const';
import { PBG_DATA_COLUMN } from '../WorkOrderScreens/WorkOrder.const';
import {
    CONTRACTOR_DETAILS,
    PROJECT_MILESTONE_DATA_COLUMN,
    HEADER_FIELDS_II,
    WO_CORRESPONDENCE,
    PROJECT_MILESTONE_FIELDS,
    PROJECT_WORK_CATEGORY_FIELDS,
    workCategoryInitialFields
} from './ContractCreation.const';
import DeleteIcon from '../../../../assets/logos/delete.svg';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { convertToINR } from '../../EstateManagement/EstateManagement.controller';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import { EXTENSION } from '../ContractClosure/ContractClosure.const';

const ContractCreationCorrespondence = (props: any) => {
    const {
        abstractData,
        t,
        dropdownData,
        pbgTableData,
        setModalDetails,
        pmPopUp,
        setPmPopUp,
        milestoneData,
        setMilestoneData,
        applicationStatus,
        actionIsUpdatable,
        bidPrice,
        isViewOnly,
        tabelData,
        onRowClick,
        estimatedCost,
        isPBGRequired
    } = props;
    const [openSection, setOpenSection] = useState<string>('headerDetails');
    const [isEditing, setIsEditing] = useState<any>(false);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [rowDelId, setRowDelId] = useState<any>(null);

    const { values, setValues, setTouched, setFieldTouched, errors } = useFormikContext<any>();

    const onSectionChange = async (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else {
            setOpenSection(currentSection);
        }
    };
    const GetBetweenMonths = (startValue: any, EndValue: any) => {
        const formatToISO = (date: any) => {
            const dateStr = String(date);
            if (dateStr.includes('/')) {
                const [day, month, year] = dateStr.split('/');
                return `${year}-${month}-${day}`;
            }
            return dateStr;
        };
        const start = new Date(formatToISO(startValue));
        const end = new Date(formatToISO(EndValue));

        const betweenMonths = getAbsoluteMonthsBetweenTwoDates(start, end);
        return !!startValue && !!EndValue ? betweenMonths : null;
    };

    const onChangeOfDate = (e: any, field: string) => {
        if (
            field === 'defectLiabilityStartDate' ||
            field === 'defectLiabilityEndDate' ||
            field === 'maintenancePeriodStartDate' ||
            field === 'maintenancePeriodEndDate' ||
            field === 'projectStartDate' ||
            field === 'milestoneTargetDate'
        ) {
            // const defectLiabilityPeriodMonths = GetBetweenMonths(values.defectLiabilityStartDate, values.defectLiabilityEndDate);
            const maintenancePeriodDays = GetBetweenMonths(values.maintenancePeriodStartDate, values.maintenancePeriodEndDate);
            const monthsfromCommencement = GetBetweenMonths(values.projectStartDate, values.milestoneTargetDate);
            // console.log(defectLiabilityPeriodMonths);
            setValues((prev: any) => ({
                ...prev,
                // defectLiabilityPeriodMonths: defectLiabilityPeriodMonths !== null ? defectLiabilityPeriodMonths : '',
                maintenancePeriodDays: maintenancePeriodDays !== null ? maintenancePeriodDays : '',
                monthsfromCommencement: monthsfromCommencement !== null ? monthsfromCommencement : ''
            }));
        }

        if (field === 'rebatePercentage') {
            if (e.target.value <= 100) {
                const rebateAmount = ((+e.target.value * +estimatedCost) / 100).toFixed(2);
                const tempProjectCost = (+estimatedCost - +rebateAmount).toFixed(2).toString();
                setValues((prevVal: any) => ({ ...prevVal, rebatePercentage: e.target.value, totalProjectCost: tempProjectCost, rebateAmount: rebateAmount.toString() }));
            } else {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('REBATE_PERCENT', 'Rebate Percentage cannont be more than 100%'), img: cross, type: 'error', reUpload: 'OK' });
                setValues((prevVal: any) => ({ ...prevVal, rebatePercentage: '', totalProjectCost: '', rebateAmount: '' }));
            }
        }
    };

    const onChangeRadioAction = (e: any, field: string) => {
        if (field === 'rebate') {
            if (e.target.value === 'false' || e.target.value === false) {
                setValues((prevVal: any) => ({ ...prevVal, rebate: false, totalProjectCost: estimatedCost, rebateAmount: '', rebatePercentage: '' }));
            }
        }

        if (field === 'defectLiability') {
            if (e.target.value === 'false' || e.target.value === false) {
                setValues((prevVal: any) => ({ ...prevVal, defectLiability: false, defectLiabilityPeriodMonths: '' }));
            }
        }
    };
    const renderHeaderDetails = () => {
        const tempDprDetails = HEADER_FIELDS.map((val: any) => {
            return {
                ...val,
                formData: [
                    ...val.formData.map((value: any) => {
                        return { ...value, isDisabled: true, isRequired: false };
                    })
                ]
            };
        });

        const dprDetails = !isViewOnly
            ? tempDprDetails
            : tempDprDetails.map((val: any) => ({
                  ...val,
                  formData: val.formData.map((item: any) => {
                      if (item.field !== 'projectName') {
                          return { ...item, class: 'col-sm-4' };
                      } else {
                          return { ...item };
                      }
                  })
              }));

        const tempHeaderFieldsII =
            applicationStatus === 'APPROVED'
                ? HEADER_FIELDS_II.map((val: any) => {
                      return {
                          ...val,
                          formData: [
                              ...val.formData.map((value: any) => {
                                  return { ...value, isDisabled: true, isRequired: false };
                              }),
                              {
                                  titleCode: 'SIGNED_COPY_OF_CONTRACT',
                                  title: 'Signed copy of Contract',
                                  field: 'signedCopyofContract',
                                  type: CONSTANT_DATA_FIELD.FILE_FIELD,
                                  entityFileData: {
                                      documentNamePrefix: 'Signed copy of Contract',
                                      documentType: ''
                                  }
                              }
                          ]
                      };
                  })
                : HEADER_FIELDS_II;

        const HeaderFieldsII = !isViewOnly ? tempHeaderFieldsII : tempHeaderFieldsII.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));
        return (
            <>
                <Card.Body>
                    {dprDetails.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                    {HeaderFieldsII.map((val: any, id: number) => (
                        <GenericFormCard {...val} onChangeAction={onChangeOfDate} onChangeRadioAction={onChangeRadioAction} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const renderContractorDetails = () => {
        const tempContractorFields = CONTRACTOR_DETAILS.map((val: any) => {
            return {
                ...val,
                formData: val.formData.map((value: any) => {
                    return { ...value, isDisabled: true, isRequired: false };
                })
            };
        });

        const contractorFields = !isViewOnly ? tempContractorFields : tempContractorFields.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));
        return (
            <>
                <div className='px-3'>
                    {contractorFields.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                </div>
            </>
        );
    };

    const renderAbstractCost = () => {
        const measurementFields = MEASUREMENT_DETAILS.map((val: any) => ({
            ...val,
            formData: [
                ...val.formData.map((value: any) => ({
                    ...value,
                    isDisabled: true
                }))
            ]
        }));
        const updatedFields = DPR_MEASUREMENT.map((val: any) => ({
            ...val,
            formData: [
                ...val.formData.map((value: any) => ({
                    ...value,
                    isDisabled: true,
                    isRequired: false
                }))
            ]
        }));

        const onMeasurementDataEdit = async (data: any, status: boolean = false) => {
            const detailObj: any = abstractData.find((item: any, _: number) => item.rowId === data.rowId);
            const totalQuantity = detailObj.dprItemMeasurementDetailsModelList
                .map((val: any) => val.quantity)
                .reduce((totalQuantity: any, quantity: any) => {
                    totalQuantity += quantity;
                    return totalQuantity;
                }, 0);
            console.log(totalQuantity);
            if (!detailObj) return;
            const formData = {
                ...detailObj,
                lbdOrNonlbd: detailObj?.lbdMeasurementType,
                gsrMarketRate: detailObj?.gsrMasterDetailsModel ? 'GSR' : 'MarketRate',
                measurementType: getDropdownSelectedObject(detailObj?.measurementType, dropdownData['measurementType']),
                gsrItemNo: { code: detailObj?.gsrItemNo, name: detailObj?.gsrItemNo },
                gsrYear: detailObj?.gsrMasterDetailsModel?.gsrYear,
                gsrName: { code: detailObj?.gsrMasterDetailsModel?.gsrName, name: detailObj?.gsrMasterDetailsModel?.gsrName },
                itemDesc: detailObj?.itemDesc,
                gsrUuid: detailObj?.gsrUuid,
                rowId: detailObj?.rowId || detailObj.uuid || null,
                itemType: detailObj?.itemType,
                mvUnitRate: detailObj?.mvUnitRate,
                gsrUnitRate: detailObj?.gsrMasterDetailsModel?.gsrUnitRate,
                itemNo: detailObj?.itemNo,
                unitOfMeasurement: detailObj?.unitOfMeasurement,
                measurements: detailObj?.dprItemMeasurementDetailsModelList,
                document: detailObj?.document,
                remarks: detailObj?.remarks,
                totalQuantity: totalQuantity
            };
            setIsEditing(detailObj.rowId || detailObj.uuid);
            setValues({ ...values, ...formData });
            setShow(true);
        };

        const onMeasurementView = (status: boolean, data: any) => {
            onMeasurementDataEdit(data, status);
        };
        const updatedColumns = [
            ...ABSTRACT_TABLE_COLUMNS,
            {
                colName: 'Action',
                type: 'action',
                clsName: 'fixed-action-column fixed-action-column-bg',
                actions: ['view']
            }
        ];
        return (
            <>
                <Card.Body className='px-3 pt-4'>
                    <OwnershipTable
                        column={updatedColumns}
                        hideSearchBar={true}
                        tableRowData={abstractData}
                        totalRow={'cost'}
                        totalRowText={t('TOTAL_COST_A', 'Total Cost (A) ')}
                        updateOwnershipDataStatus={onMeasurementView}
                    />
                </Card.Body>

                <GenericPopupModal
                    isVisible={show}
                    primaryAction={() => {}}
                    secondaryAction={() => {
                        setShow(false);
                    }}
                    size={'lg'}
                    secondaryBtnTitle={t('CLOSE', 'Close')}
                    isPrimaryBtn={false}
                    title={'View Abstract cost'}
                    children={
                        <>
                            {measurementFields?.map((val: any) => {
                                return (
                                    <div style={{ padding: '10px 16px' }}>
                                        <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                        <Form>
                                            <FieldArray name='measurements'>
                                                {({ remove, push }) => (
                                                    <div>
                                                        {values.measurements?.length > 0 &&
                                                            values.measurements.map((data: any, index_p: number) => (
                                                                <div className='row' key={index_p}>
                                                                    <div className='col-sm-12 collapsible_box align-items-center pb-2'>
                                                                        <input
                                                                            type='checkbox'
                                                                            id={`measurements.${index_p}`}
                                                                            name={`measurements.${index_p}`}
                                                                            defaultChecked={true}
                                                                            disabled={fieldDisabled}
                                                                        />
                                                                        <label
                                                                            htmlFor={`measurements.${index_p}`}
                                                                            className='d-flex mx-3 collapsible_box_label align-items-center justify-content-between'
                                                                        >
                                                                            New Measurement - {index_p + 1}
                                                                        </label>
                                                                        <div className='form_fields ps-1'>
                                                                            {updatedFields.map((val: any, _: number) => (
                                                                                <GenericFormCard fieldPath={`measurements.${index_p}`} {...val} />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </FieldArray>
                                            <div className='py-3 mt-1 rounded-2 pe-3  ' style={{ background: '#F4F4F4' }}>
                                                <div className='me-5 d-flex justify-content-end '>
                                                    <div className='font-16 lh-21 text-dark-emphasis '>{t('TOTAL_QUANTITY', `Total Quantity`)}</div>
                                                    <div className='font-16 lh-21 text-dark-emphasis ms-5'>:</div>
                                                    <div className='font-16 lh-21 text-dark-emphasis ms-5 '>{values.totalQuantity ? convertToINR(values.totalQuantity) : 0}</div>
                                                </div>
                                                <div className='me-5 d-flex justify-content-end '>
                                                    <div className='font-16 lh-21 fw-500 text-dark-emphasis '>{t('ROUND_OFF_TOTAL_QUANTITY', `Round off Total Quantity`)}</div>
                                                    <div className='font-16 lh-21 fw-500 text-dark-emphasis ms-5'>:</div>
                                                    <div className='font-16 lh-21 fw-500 text-dark-emphasis ms-5 '>{values.totalQuantity ? convertToINR(Math.ceil(values.totalQuantity)) : 0}</div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                );
                            })}
                        </>
                    }
                />
            </>
        );
    };
    const renderPbgDetails = () => {
        return (
            <>
                <Card.Body className='px-3 pt-4'>
                    <OwnershipTable column={PBG_DATA_COLUMN} hideSearchBar={true} tableRowData={pbgTableData} isDisabled={true} additionalMethod={onRowClick} />
                </Card.Body>
            </>
        );
    };
    const renderPmDetails = () => {
        const onAddMilestone = () => {
            const val = _.cloneDeep(values);

            setIsEditing(false);
            const keysToDelete = ['milestoneNo', 'projectStartDate', 'milestoneTargetDate', 'monthsfromCommencement'];
            keysToDelete.forEach(key => {
                delete val[key];
            });
            setValues({ ...val, contractWorkCategoryList: [workCategoryInitialFields], projectStartDate: values.projectStartDate });
            setTouched({});
            setPmPopUp(true);
        };
        const hanldePrimaryAction = async () => {
            try {
                const totalValuePercentage = values.contractWorkCategoryList
                    .map((val: any) => +val.value)
                    .reduce((total: any, value: number) => {
                        return (total += value);
                    }, 0);
                console.log(totalValuePercentage);

                if (totalValuePercentage > 100) {
                    setModalDetails({ show: true, title: 'Error!', para: 'Value should not be greater than 100 after adding each Value % field', img: cross, type: 'error', reUpload: 'OK' });
                    return;
                }

                await WORK_CATEGORY_VALIDATION.validate(values, { abortEarly: false, context: values });
                setPmPopUp(false);
            } catch (validationError) {
                let errorField = getFirstErrorKey(errors);
                if (errorField) {
                    await setFieldTouched(errorField, true, true);
                }
                let errMsg = 'Please fill in all the required details!';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                return;
            }

            const contractWorkCategoryList = values.contractWorkCategoryList.map((val: any) => {
                return { ...val, workCategory: val?.contractWorkCategory?.code };
            });
            const newUuid = uuidv4();
            const workCategorydata = {
                milestoneNo: values.milestoneNo,
                projectStartDate: values.projectStartDate,
                milestoneTargetDate: convertDateToDDmmYYYY(values.milestoneTargetDate),
                monthsFromCommencement: values.monthsfromCommencement,
                contractWorkCategoryList: contractWorkCategoryList,
                rowId: isEditing ? values.rowId || values.uuid : newUuid
            };
            if (isEditing) {
                const updatedData = milestoneData.map((data: any) => {
                    if (data.rowId === isEditing) {
                        return { ...data, ...workCategorydata };
                    } else return data;
                });
                console.log('upfagaghah', updatedData);
                setMilestoneData([...updatedData]);
            } else {
                setMilestoneData([...milestoneData, workCategorydata]);
            }
            // setVisible(false);
            setPmPopUp(false);
        };

        const onMilestoneRowEdit = (data: any, status: boolean) => {
            const selectedRowData = milestoneData.find((val: any) => val.rowId === data.rowId);

            if (!selectedRowData) return;

            const setRowFileds = {
                milestoneNo: selectedRowData.milestoneNo,
                projectStartDate: selectedRowData.projectStartDate,
                milestoneTargetDate: selectedRowData.milestoneTargetDate,
                monthsfromCommencement: selectedRowData.monthsFromCommencement,
                contractWorkCategoryList: selectedRowData.contractWorkCategoryList,
                rowId: selectedRowData.rowId
            };
            setIsEditing(selectedRowData.rowId || selectedRowData.uuid);
            setValues({ ...values, ...setRowFileds });
            setPmPopUp(true);
        };

        const onMilestoneRowDelete = async (rowId = null) => {
            setShowDeleteModal(true);
            setRowDelId(rowId);
        };

        const handleModalDelete = async (rowId: any) => {
            const updatedRowData = milestoneData.filter((val: any) => val.rowId !== rowId);
            setMilestoneData(updatedRowData);
            setShowDeleteModal(false);
        };

        const onMilestoneRowView = (status: boolean, data: any) => {
            setFieldDisabled(true);
            onMilestoneRowEdit(data, status);
        };
        const fieldsI = fieldDisabled
            ? PROJECT_MILESTONE_FIELDS.map((val: any) => {
                  return {
                      ...val,
                      formData: val.formData.map((value: any) => {
                          return { ...value, isDisabled: true, isRequired: false };
                      })
                  };
              })
            : PROJECT_MILESTONE_FIELDS;
        const fieldsII = fieldDisabled
            ? PROJECT_WORK_CATEGORY_FIELDS.map((val: any) => {
                  return {
                      ...val,
                      formData: val.formData.map((value: any) => {
                          return { ...value, isDisabled: true, isRequired: false };
                      })
                  };
              })
            : PROJECT_WORK_CATEGORY_FIELDS;

        const updatedColumns = PROJECT_MILESTONE_DATA_COLUMN.map((col: any) => {
            if (col.type === 'action') {
                return {
                    ...col,
                    actions: actionIsUpdatable ? ['edit', 'view', 'delete'] : ['view']
                };
            }
            return col;
        });

        return (
            <>
                <Card.Body className='px-3 pt-4'>
                    {!!actionIsUpdatable && (
                        <div className='text-end'>
                            <GenericButtons text={'+ Milestone'} variant='primary' ClassName='fw-500' handleClick={onAddMilestone} />
                        </div>
                    )}
                    <OwnershipTable
                        column={updatedColumns}
                        hideSearchBar={true}
                        tableRowData={milestoneData}
                        additionalMethod={onMilestoneRowEdit}
                        deleteOwnershipDetails={onMilestoneRowDelete}
                        updateOwnershipDataStatus={onMilestoneRowView}
                        isDisabled={!!actionIsUpdatable}
                    />
                </Card.Body>

                <GenericPopupModal
                    title={`${isEditing ? 'Edit' + ' ' : 'Add '}Project Milestone`}
                    size={'lg'}
                    secondaryAction={() => {
                        setPmPopUp(false);
                        setFieldDisabled(false);
                    }}
                    primaryAction={hanldePrimaryAction}
                    isVisible={pmPopUp}
                    primaryBtnTitle='Save'
                    secondaryBtnTitle='Close'
                    isPrimaryBtn={!fieldDisabled}
                    children={
                        <>
                            {fieldsI.map((val: any, id: number) => (
                                <GenericFormCard {...val} styles={{ background: '#F5F5F6', borderRadius: '0px', padding: '0px 16px' }} dropdownOptions={dropdownData} onChangeAction={onChangeOfDate} />
                            ))}
                            <div style={{ background: '#F5F5F6', borderRadius: '0px', padding: '0px 16px' }}>
                                <div className='fw-semibold font-18 m-0 p-3'>Work Category</div>
                                <form>
                                    <FieldArray name='contractWorkCategoryList'>
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {values.contractWorkCategoryList?.length > 0 &&
                                                    values.contractWorkCategoryList.map((data: any, index_p: number) => (
                                                        <div className='row' key={index_p}>
                                                            <div className='col-sm-12 collapsible_box align-items-center pb-2'>
                                                                <input type='checkbox' id={`contractWorkCategoryList.${index_p}`} name={`contractWorkCategoryList.${index_p}`} defaultChecked={true} />
                                                                <label
                                                                    htmlFor={`contractWorkCategoryList.${index_p}`}
                                                                    className='d-flex mx-3 collapsible_box_label align-items-center justify-content-between'
                                                                >
                                                                    Work Category - {index_p + 1}
                                                                </label>
                                                                <div className='form_fields ps-1'>
                                                                    {index_p !== 0 && !fieldDisabled && (
                                                                        <div className='d-flex justify-content-end mx-3'>
                                                                            <img style={{ cursor: 'pointer', zIndex: 1 }} onClick={() => remove(index_p)} src={DeleteIcon} alt='delete' />
                                                                        </div>
                                                                    )}
                                                                    {fieldsII.map((val: any, _: number) => (
                                                                        <GenericFormCard
                                                                            fieldPath={`contractWorkCategoryList.${index_p}`}
                                                                            {...val}
                                                                            dropdownOptions={props.dropdownData}
                                                                            customHandleChange={props.onChangeAction}
                                                                            styles={{ background: '#F5F5F6', borderRadius: '0px' }}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {!fieldDisabled && (
                                                    <div className='ms-3'>
                                                        <button
                                                            className='col-sm-12 mt-2 mb-4  justify-content-center align-items-center noc_add_fields'
                                                            type='button'
                                                            onClick={() => push(workCategoryInitialFields)}
                                                        >
                                                            + Work Category
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </FieldArray>
                                </form>
                            </div>
                        </>
                    }
                />
                <DeletePopUpModal
                    secondaryAction={() => {
                        setShowDeleteModal(false);
                    }}
                    primaryAction={() => {
                        handleModalDelete(rowDelId);
                    }}
                    isVisible={showDeleteModal}
                    title={'Delete File?'}
                    img={DeleteWarning}
                    primaryBtnTitle={t('DELETE', 'Delete')}
                    secondaryBtnTitle={t('CANCEL', 'Cancel')}
                    children={
                        <>
                            <div className='p-4' style={{ backgroundColor: '#F5F5F6' }}>
                                <div className='font-inter fw-bold lh-22'>
                                    Are you sure you want to delete this file? <br />
                                    You can't undo this action!
                                </div>
                            </div>
                        </>
                    }
                />
            </>
        );
    };

    const renderDocuments = () => {
        return (
            <>
                <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
            </>
        );
    };

    const renderExtensionDetails = () => {
        const extension = EXTENSION?.map((val: any) => {
            const updatedFormData = [
                ...val.formData,
                {
                    title: 'Compensation Amount',
                    titleCode: 'COMPENSATION_AMOUNT',
                    field: 'compensationAmount',
                    type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                    isRequired: true,
                    showInr: true
                }
            ];

            return {
                ...val,
                formData: updatedFormData
            };
        });

        const updatedExtension = !isViewOnly
            ? extension
            : extension.map((val: any) => ({
                  ...val,
                  formData: val?.formData?.map((item: any) => {
                      if (item?.field !== 'reason') {
                          return { ...item, class: 'col-sm-4' };
                      } else {
                          return { ...item };
                      }
                  })
              }));
        return (
            <>
                <Card.Body>
                    {updatedExtension.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const updataedCorrespondence = !isViewOnly
        ? isPBGRequired
            ? WO_CORRESPONDENCE
            : WO_CORRESPONDENCE?.filter((val: any) => val.field !== 'pbgDetails')
        : !isPBGRequired
        ? [
              ...WO_CORRESPONDENCE,
              ...(values.extensionDate
                  ? [
                        {
                            title: '(E) Contract Extension Details',
                            field: 'contractExtDetails'
                        },
                        {
                            title: '(F) Documents',
                            field: 'documents'
                        }
                    ]
                  : [
                        {
                            title: '(E) Documents',
                            field: 'documents'
                        }
                    ])
          ]
        : [
              ...WO_CORRESPONDENCE?.filter((val: any) => val.field !== 'pbgDetails'),
              ...(values.extensionDate
                  ? [
                        {
                            title: '(D) Contract Extension Details',
                            field: 'contractExtDetails'
                        },
                        {
                            title: '(E) Documents',
                            field: 'documents'
                        }
                    ]
                  : [
                        {
                            title: '(D) Documents',
                            field: 'documents'
                        }
                    ])
          ];

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {updataedCorrespondence.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === '(A) Header' && openSection === 'headerDetails' && renderHeaderDetails()}
                                {val.title === '(B) Abstract Cost' && openSection === 'abstractCost' && renderAbstractCost()}
                                {val.title === '(C) Contractor Details' && openSection === 'contractorDetails' && renderContractorDetails()}
                                {val.title === '(D) PBG Details' && openSection === 'pbgDetails' && renderPbgDetails()}
                                {/* {val.title === '(E) Project MileStone' && openSection === 'projectMileStone' && renderPmDetails()} */}
                                {(val.title === '(E) Contract Extension Details' || val.title === '(D) Contract Extension Details') && openSection === 'contractExtDetails' && renderExtensionDetails()}
                                {val.title === '(F) Documents' && openSection === 'documents' && renderDocuments()}
                                {val.title === '(E) Documents' && openSection === 'documents' && renderDocuments()}
                                {val.title === '(D) Documents' && openSection === 'documents' && renderDocuments()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default ContractCreationCorrespondence;
