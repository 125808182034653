import React, {useEffect, useState} from "react"
import {Card} from "react-bootstrap"
import {FormikProvider, useFormik} from "formik"
import GenericFormCard from "../../../../components/moduleCommonFormik/GenericFormCard.component"
import {ACTIVATE_EMPLOYEE, REASON_FOR_DEACTIVATE, REASON_FOR_REACTIVATE} from "../UserManagement.const"
import {IActivateEmployee} from "../types"
import {fetchDropdownData} from "../../../../services/modulesServices/userManagement.api"
import {DE_RE_ACTIVATION} from "../../../../utils/formikValidation/FormikValidation"

interface ActivateEmployeeProps {
    setActivateDetails: (details: any) => void
    empStatus: string
}

export const ActivateEmployee = (props: ActivateEmployeeProps) => {
    const [dropdownData, setDropdownData] = useState<any>(null)
    const {setActivateDetails, empStatus} = props

    const formik = useFormik<IActivateEmployee>({
        initialValues: {
            reasonForReActivating: null,
            orderNo: "",
            effectiveDate: new Date().toISOString().slice(0, 10),
            joiningAppointmentLetter: "",
            comments: "",
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: DE_RE_ACTIVATION
    })

    const {values} = formik
    console.log(values)

    useEffect(() => {
        const dropdownPayload = {
            RequestInfo: {},
            MdmsCriteria: {
                tenantId: "ga",
                moduleDetails: [
                    {
                        moduleName: "egov-hrms",
                        masterDetails: [
                            {
                                name: "DeactivationReason",
                                filter: "[?(@.active == true)]"
                            }
                        ]
                    }
                ]
            }
        }
        fetchDropdownData(dropdownPayload)
            .then((res: any) => {
                const dropdownOptions = {
                    reasonForReActivating: res["egov-hrms"]["DeactivationReason"]
                }
                setDropdownData(dropdownOptions)
            })
            .catch((err: any) => console.log(err))
    }, [])

    useEffect(() => {
        if (!ACTIVATE_EMPLOYEE[0].formData.some(item => item.field === "reasonForReActivating")) {
            if (empStatus === "ACTIVE") {
                ACTIVATE_EMPLOYEE[0].formData.splice(0, 0, REASON_FOR_DEACTIVATE)
                ACTIVATE_EMPLOYEE[0].formData[2]["isDisabled"] = true
            } else if (empStatus === "INACTIVE") {
                ACTIVATE_EMPLOYEE[0].formData.splice(0, 0, REASON_FOR_REACTIVATE)
            }
        }
    }, [empStatus])

    useEffect(() => {
        return () => {
            ACTIVATE_EMPLOYEE[0].formData[2]["isDisabled"] = false
            ACTIVATE_EMPLOYEE[0].formData.shift()
        }
    }, [])

    useEffect(() => {
        setActivateDetails(values)
    }, [setActivateDetails, values])

    return (
        <>
            <FormikProvider value={formik}>
                {ACTIVATE_EMPLOYEE.map((val: any, index: number) => (
                    <Card className="card_border" key={index}>
                        <Card.Body>
                            <GenericFormCard {...val} dropdownOptions={dropdownData} />
                        </Card.Body>
                    </Card>
                ))}
            </FormikProvider>
        </>
    )
}
