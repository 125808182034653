import { useEffect, useState } from 'react';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import { POST, devUrl, endpoints, estateEndpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../../../utils/Logo';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { convertToINR, fetchLeaseDurationDoc, getCheckList, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument, paymentCheck } from '../../EstateManagement.controller';
import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../Allotment/Allotment.const';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../LeaseDeed/LeaseDeed.const';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import { TransferWorkflowCorrespondence } from './TransferWorkflowCorrespondence';
import { dropdownDataOptions, getFormatedOwnershipDetails } from '../../../Migration/Migration.controller';
import { PlotDropdownData } from '../../../UserManagement/types';
import { getEnterpriseData, getEntityData, getFormatedNICDetails, getInitPlotData } from '../../Allotment/Allotment.controller';
import { getTransferDetails, getTransferorDetails } from '../../Transferee/Transferee.controller';
import { LEASER_RENT_RATE, TENANT_ID } from '../../../../../utils/constants';
import { specialTransferDetails } from '../../../UserManagement/UserMangement.controller';
import axios from 'axios';
import { ESIGN_COLUMNS } from '../Transfer.const';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import { EsignModal } from '../../../../commonPages/EsignModal';

export default function TransferWorkflow() {
    const [searchParams] = useSearchParams();
    const transferApplicationNo = searchParams.get('applicationNumber') ? decodeURIComponent(searchParams.get('applicationNumber') || '') : '';

    const user = localStorage.getItem('user');
    let designation = user ? String(JSON.parse(user)?.designation) : '';
    let navigate = useNavigate();
    const totalPages = transferApplicationNo ? 3 : 1;
    const isSpecialTransfer = JSON.parse((searchParams.get('isSpecialTransfer') as any) || null);
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [comment, setcomment] = useState('');
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [activeIndex, setActiveIndex] = useState(1);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [rowData, setRowData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalData, setModalData] = useState<any>(null);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [checklistTableData, setChecklistTableData] = useState();
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [transfereeNicDetails, setTransfereeNicDetails] = useState<any>([]);
    const [isChecked, setIsChecked] = useState([true, true, true]);
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [content, setContent] = useState<any>(null);
    const [ownershipDetails, setOwnershipDetails] = useState<any>([]);
    const [controllingOwnershipResponse, setControllingOwnershipResponse] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [dropdownDataPlot, setDropdownDataPlot] = useState<PlotDropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [applicationSearchResponse, setApplicationSearchResponse] = useState<any>(null);
    const [tableRowData, setTableRowData] = useState<any>([]);
    const [plotIds, setPlotIds] = useState<any>([]);
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);
    const [finalOwnerShipDetails, setFinalOwnerShipDetails] = useState({
        applicantDetails: []
    });
    const [calculated, setCalculated] = useState<boolean>(false);
    const [isTransferChecked, setIsTransferChecked] = useState<any>([true, true, true, true, true]);
    const [transactionDetails, setTransactionDetails] = useState<any>(null);
    const [showOwnershipModalTransferor, setShowOwnershipModalTransferor] = useState(false);
    const [ownershipDetailsTransferor, setOwnershipDetailsTransferor] = useState<any>([]);
    const [controllingOwnershipResponseTransferor, setControllingOwnershipResponseTransferor] = useState<any>([]);
    const [esignRowdata, setEsignRowdata] = useState<any>([]);
    const [isEsignPending, setIsEsignPending] = useState<any>(true);
    const signedFileStoreId: any = searchParams.get('signedFileStoreId');
    const currentRefId: any = searchParams.get('currentRefId');

    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [refId, setRefId] = useState<any>('');
    const [signedIdsBeforeEsign, setSignedIdsBeforeEsign] = useState<any>(null);
    const [approvedEsignData, setApprovedEsignData] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: false,
            isSpecialProject: false,
            esignOption: 'DSC'
        },
        onSubmit: () => {}
    });

    const { values, setValues, setFieldValue } = formik;

    const fetchDropdownNextValidActionAndHistoryData = async () => {
        try {
            setIsLoading(true);
            if (transferApplicationNo) {
                const dropdownOption = await dropdownDataOptions();
                setDropdownData(dropdownOption);
                const nextActions = await getNextActions(transferApplicationNo);
                setNextValidAction(nextActions);
                const { data } = await POST(`${endpoints.workflowSearch}&businessIds=${transferApplicationNo}`, { RequestInfo: requestInfo() });
                setHistoryDetails(data.ProcessInstances);
            }
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            console.log('error 1:', e);
        }
    };

    useEffect(() => {
        fetchDropdownNextValidActionAndHistoryData();
    }, []);

    const fetchApplicationDatas = async () => {
        try {
            if (dropdownData) {
                setIsLoading(true);
                const { data: applicationDataResponse } = await POST(endpoints.transferSearch + `?applicationNos=${transferApplicationNo}`, { RequestInfo: requestInfo() });
                const details = applicationDataResponse?.transferDetails[0];
                if (applicationDataResponse) {
                    setApplicationSearchResponse({
                        ...details
                        // attachmentRefId: signedFileStoreId ? signedFileStoreId : applicationDataResponse.transferDetails[0].applicationDetails.attachmentRefId
                    });
                    setFileStoreId(details?.signedConsolidatedDocRefId);
                    //common for Transfer and Special Transfer
                    const entityDetails = await getEntityData(details?.transfereeDetails, dropdownData);
                    const transferorDetails = details && getTransferorDetails(details);
                    const newOwnership = details?.transfereeDetails?.owners && (await getFormatedOwnershipDetails(details?.transfereeDetails?.owners, dropdownData));
                    setOwnershipDetails(newOwnership);
                    setControllingOwnershipResponse(details?.transfereeDetails?.owners);
                    const enterpriseDetails = details?.transfereeEnterpriseDetails && (await getEnterpriseData(details.transfereeEnterpriseDetails, dropdownData));
                    const newProposed =
                        details?.transfereeEnterpriseDetails?.nicEnterpriseMappings && (await getFormatedNICDetails(details.transfereeEnterpriseDetails.nicEnterpriseMappings || [], dropdownData));
                    newProposed && setTransfereeNicDetails(newProposed);

                    //For Transfer Application
                    let transferDetails;
                    let plotData;
                    let revisedLeaseRent;
                    let displayRevisedLeaseRent;
                    let transfer;
                    if (!isSpecialTransfer) {
                        transferDetails = details && (await getTransferDetails(details));
                        plotData = getInitPlotData(details?.plotDetails[0]);
                        revisedLeaseRent = (+LEASER_RENT_RATE * +details?.plotDetails[0].plotRate * +details?.plotDetails[0].plotAreaSqm).toFixed(2).toString();
                        displayRevisedLeaseRent = convertToINR(revisedLeaseRent);
                        transfer = details && (await fetchLeaseDurationDoc(details?.plotDetails[0].uuid, details?.transferorDetails.uuid));
                        // setIsTransferChecked([
                        //     transfer?.isBuiltUpAreaUtilised,
                        //     transfer?.transferOrLandPremium,
                        //     !details?.isTransfereeDisqualified,
                        //     !details?.isIndustrialCommercial,
                        //     !details?.isTransferProhibited
                        // ]);
                        setContent(details?.applicationDetails?.content);
                    } else {
                        setContent(details?.modificationDetails?.requestDetails?.plotOrderRequests);
                    }

                    //For Special Transfer Application
                    let specialTransferInitData;
                    let isAppliedUnderSpecialProject;
                    if (isSpecialTransfer) {
                        const tempPlotIds = details.modificationDetails.requestDetails.plotOrderRequests?.map((val: any) => val.plotId);
                        setPlotIds(tempPlotIds);
                        console.log('plotIds', tempPlotIds);
                        // const { data: plotDataDetails } = tempPlotIds.length && (await POST(estateEndpoints.plotSearch + `?plotUuids=${tempPlotIds}`, { RequestInfo: requestInfo() }));
                        // const updatedPlotDetails = plotDataDetails?.plots?.map((val: any) => ({ ...val, estateName: val?.estateDetails.estateName, id: val.uuid }));
                        // setTableRowData(updatedPlotDetails);

                        specialTransferInitData = details?.modificationDetails && dropdownData && (await specialTransferDetails(details?.modificationDetails, dropdownData));
                        if (specialTransferInitData.nocGoaIPB) {
                            isAppliedUnderSpecialProject = true;
                        } else {
                            isAppliedUnderSpecialProject = false;
                        }
                        if (details?.modificationDetails.requestDetails.type === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST') {
                            const newOwnership =
                                details?.applicationDetails.modificationDetails.requestDetails.owners &&
                                (await getFormatedOwnershipDetails(details?.applicationDetails.modificationDetails.requestDetails.owners, dropdownData));
                            setOwnershipDetails(newOwnership);
                            setControllingOwnershipResponse(details?.applicationDetails.modificationDetails.requestDetails.owners);
                        }
                        const newOwnershipTransferor = details?.transferorDetails?.owners && (await getFormatedOwnershipDetails(details?.transferorDetails?.owners, dropdownData));
                        setOwnershipDetailsTransferor(newOwnershipTransferor);
                        setControllingOwnershipResponseTransferor(details?.transferorDetails?.owners);
                    }

                    setValues({
                        ...values,
                        transferorEntityId: details?.transferorDetails?.uuid,
                        ...transferorDetails,
                        ...transferDetails,
                        ...plotData,
                        plotUuid: details?.plotDetails?.[0]?.uuid,
                        ...transfer,
                        revisedLeaseRent: displayRevisedLeaseRent,
                        revisedLeaseRentString: revisedLeaseRent,
                        ...entityDetails,
                        ...specialTransferInitData,
                        appliedUnderSpecialProject: isAppliedUnderSpecialProject,
                        ...enterpriseDetails
                    });
                }
                setIsLoading(false);
            }
        } catch (err: any) {
            console.log(err);
            setIsLoading(false);
            console.log('error 2:', err);
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        fetchApplicationDatas();
    }, [dropdownData]);

    const getPaymentData = async () => {
        try {
            if (applicationSearchResponse && (applicationSearchResponse?.plotDetails[0]?.uuid || plotIds)) {
                const payCheck = await paymentCheck(
                    'PROCESSING_FEE',
                    !isSpecialTransfer ? applicationSearchResponse?.plotDetails[0]?.uuid : plotIds,
                    applicationSearchResponse?.applicationDetails?.uuid,
                    applicationSearchResponse?.transferorDetails?.uuid,
                    isSpecialTransfer
                );
                setPaymentRowData(payCheck?.paymentData);
                setTransactionDetails(payCheck?.transactionDetails);
            }
        } catch (e: any) {
            console.log('error 3:', e);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        getPaymentData();
    }, [applicationSearchResponse, plotIds]);

    const handleModalClose = (type: string) => {
        setModalDetails({});
        if (type === 'success') navigate('/goa-idc/dashboard');
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            setIsLoading(true);
            const docs: any =
                transferApplicationNo && file.referenceId && (await onAttachDocument(transferApplicationNo, file?.referenceId, designation, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs.documents);
            setDocumentsUuid([...documentsUuid, docs.uuid]);
            setIsLoading(false);
            setActiveIndex(2);
        } catch (e: any) {
            console.log('error 4:', e);
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) {
            setActiveIndex(prevInd => prevInd + 1);
        }
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) {
            setActiveIndex(prevInd => prevInd - 1);
        }
    };

    useEffect(() => {
        const reverseDocumentDetails = documentDetails.reverse();
        const tableData = async () => {
            const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${ind + 1}` };
            });
            const reverseData = responseTableData.reverse();
            setRowData(reverseData);
        };
        if (activeIndex === 2) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    useEffect(() => {
        (async () => {
            try {
                if (applicationSearchResponse && nextValidAction) {
                    setIsLoading(true);
                    console.log('application', applicationSearchResponse);
                    const plotId = isSpecialTransfer ? applicationSearchResponse?.modificationDetails.requestDetails.plotOrderRequests?.map((val: any) => val.plotId) : null;
                    const checklistData =
                        applicationSearchResponse?.transferorDetails.uuid &&
                        (applicationSearchResponse?.plotDetails[0]?.uuid || plotId) &&
                        nextValidAction.businessService &&
                        (await getCheckList(applicationSearchResponse?.transferorDetails.uuid, applicationSearchResponse?.plotDetails[0]?.uuid || plotId, nextValidAction.businessService));
                    setChecklistTableData(checklistData);
                    setIsLoading(false);
                }
            } catch (e: any) {
                console.log('error 5:', e);
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, [nextValidAction, applicationSearchResponse]);

    useEffect(() => {
        const fetchDocumentData = async () => {
            try {
                setIsLoading(true);
                if (transferApplicationNo) {
                    const docs = await getWorkflowDocumentSearch(transferApplicationNo, nextValidAction?.businessService);
                    setDocumentDetails(docs);
                    const relatedApplicationDocument = await POST(
                        estateEndpoints.relatedApplicationSearch +
                            `?entityIds=${applicationSearchResponse?.transferorDetails.uuid}&plotIds=${applicationSearchResponse?.plotDetails[0].uuid}&applicationNos=${transferApplicationNo}`,
                        { RequestInfo: requestInfo() }
                    );
                    setRelatedApplicationDocument(relatedApplicationDocument);
                }
                setIsLoading(false);
            } catch (e: any) {
                console.log('error 6:', e);
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (activeIndex === 2) {
            fetchDocumentData();
        }
    }, [activeIndex, applicationSearchResponse, nextValidAction]);

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    const handleCheckboxChange = (i: any) => {
        let checks = isChecked;
        checks[i] = !isChecked[i];
        setIsChecked([...checks]);
    };

    const updateNotes = async () => {
        const { applicationDetails, ...rest } = applicationSearchResponse;
        let payload = {
            RequestInfo: requestInfo(),
            ...rest,
            applicationDetails: {
                ...applicationDetails,
                content: !isSpecialTransfer ? content : null,
                modificationDetails: isSpecialTransfer
                    ? { ...applicationSearchResponse?.modificationDetails, requestDetails: { ...applicationSearchResponse?.modificationDetails?.requestDetails, plotOrderRequests: content } }
                    : null
            },
            plotIds: !isSpecialTransfer ? [applicationSearchResponse?.plotDetails[0].uuid] : null,
            revisedLeaseRent: values?.revisedLeaseRentString || null
            // modificationDetails: isSpecialTransfer
            //     ? { ...applicationSearchResponse?.modificationDetails, requestDetails: { ...applicationSearchResponse?.modificationDetails?.requestDetails, plotOrderRequests: content } }
            //     : null
        };

        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.transferUpdate, payload);
            if (!isSpecialTransfer) {
                setContent(data?.transferDetails[0]?.applicationDetails?.content);
            } else {
                setContent(data?.transferDetails[0]?.modificationDetails?.requestDetails?.plotOrderRequests);
            }
            setIsLoading(false);
        } catch (e: any) {
            console.log('error 7:', e);
            setIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const signedIds: any = searchParams.get('signedIds');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    useEffect(() => {
        // for transfer only
        if (signedFileStoreId && action && applicationSearchResponse && nextValidAction && !isSpecialTransfer) {
            onHandlePrimaryAction({ action: action }, '');
        }
    }, [applicationSearchResponse, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        // esign for transfer workflow

        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);
            setRefId(approveEsignRes.data.entityRefDocuments[0].docReferenceId);
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.map((item: any) => `${item.uuid}=${item.docReferenceId}`);
            setApprovedEsignData(approvedEsignData);
            setActionAfterEsign(action);
            setEsignModal(true);

            // if (window.location.protocol !== 'http:') {
            //     let url =
            // window.location.origin +
            // `/goa-idc/estate/transfer/workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
            //     ','
            // )}&action=${action}`;
            //     url = btoa(url);
            //     const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
            //         userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
            //     }`;
            //     await axios.get(esignUrl);

            //     window.open(esignUrl, '_self');
            // } else {
            //     let url =
            //         window.location.origin +
            //         `/goa-idc/estate/transfer/workflow?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
            //             ','
            //         )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
            //     console.log('url', url, documentsUuid);
            //     window.open(url, '_self');
            // }
        } catch (e) {
            console.log('esign error', e);
        }
    };

    const getPayload = (status?: string, comment?: string) => {
        const { applicationDetails, ...rest } = applicationSearchResponse;
        // const approvedEsignData = esignRowdata.filter((item2: any) => signedIds.some((item1: any) => item2.docReferenceId === item1));
        // const currentEsignData = esignRowdata.filter((item: any) => item.docReferenceId === signedFileStoreId);

        let payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: transferApplicationNo,
                    moduleName: nextValidAction?.moduleName,
                    action: status,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            transferRequest: {
                ...rest,
                applicationDetails: {
                    ...applicationDetails,
                    content: !isSpecialTransfer ? content : null,
                    modificationDetails: isSpecialTransfer
                        ? { ...applicationSearchResponse?.modificationDetails, requestDetails: { ...applicationSearchResponse?.modificationDetails?.requestDetails, plotOrderRequests: content } }
                        : null
                },
                modificationDetails: isSpecialTransfer
                    ? { ...applicationSearchResponse?.modificationDetails, requestDetails: { ...applicationSearchResponse?.modificationDetails?.requestDetails, plotOrderRequests: content } }
                    : null,
                plotIds: !isSpecialTransfer ? [applicationSearchResponse?.plotDetails[0].uuid] : applicationSearchResponse.plotDetails.map((plot: any) => plot.uuid),
                revisedLeaseRent: values?.revisedLeaseRentString || null
            },
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : (docsUuid && docsUuid?.split(',')) || null,
            signedDocDetails: isSpecialTransfer
                ? esignRowdata.length
                    ? esignRowdata.map((item: any) => ({ [`${item.uuid}`]: item.newDocRefID }))
                    : undefined
                : approvedEsign?.split(',').map((item: any) => {
                      const esignData = item.split('=');
                      return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                  }) || null
        };

        return payload;
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const payload = getPayload(status?.action, comment);

        console.log('formik', formik);

        if (!values.notes && !note) {
            if (!comment) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ENTER_NOTES', 'Please enter notes!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
        }

        if (designation === 'MD' && isSpecialTransfer && !esignRowdata.length && status.isEsignRequired) {
            showEsignTable(payload);
            return;
        }

        if (isSpecialTransfer && status.isEsignRequired && isEsignPending) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please E-Sign all the documents!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        } else if (!signedFileStoreId && !isSpecialTransfer && status.isEsignRequired) {
            esign(payload, status.action);
            return;
        }
        try {
            setIsLoading(true);
            await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, transferApplicationNo);
            setModalDetails({ show: true, title: t('SUCCESS', 'Success!'), para: successMessage, img: check, type: 'success', reUpload: 'OK' });
            setIsLoading(false);
        } catch (e: any) {
            setIsLoading(false);
            console.log('error 9:', e);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        if (applicationSearchResponse?.status === 'APPROVED' && nextValidAction && isSpecialTransfer) {
            const payload = getPayload('APPROVE', comment);
            showEsignTable(payload);
        }
    }, [content, applicationSearchResponse, nextValidAction]);

    useEffect(() => {
        if (values?.category?.code) {
            if (values?.category?.code === 'PROP_FIRM') {
                if (ownershipDetails?.length) {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: ownershipDetails[0]?.name }));
                } else {
                    setValues((prev: any) => ({ ...prev, nameOfAccountHolder: '' }));
                }
            } else {
                setValues((prev: any) => ({ ...prev, nameOfAccountHolder: values?.applicantName }));
            }
        }
    }, [values?.category?.code, ownershipDetails, values?.applicantName]);

    const showEsignTable = async (payload: any) => {
        try {
            if (applicationSearchResponse) {
                let approveEsignRes = await POST(endpoints.esignApprove, payload);
                const approvedDocData = approveEsignRes?.data.entityRefDocuments;

                const oldsignedIds = signedIds?.split(',').map((item: any) => item.split('=')[0]) || [];
                const newsignedIds = signedIds?.split(',').map((item: any) => item.split('=')[1]) || [];

                if (isSpecialTransfer && designation === 'MD' && content) {
                    const tableData = approvedDocData?.map((item: any, index: number) => {
                        console.log('newIds', oldsignedIds, newsignedIds);
                        if (item.docReferenceId === currentRefId || oldsignedIds?.indexOf(item.docReferenceId) > -1) {
                            const indexOfId = oldsignedIds?.indexOf(item.docReferenceId);
                            return {
                                ...item,
                                serialNo: index + 1,
                                order: `Special Transfer Order Notes - ${index + 1}`,
                                verification: 'DONE',
                                newDocRefID: item.docReferenceId === currentRefId ? signedFileStoreId : newsignedIds[indexOfId]
                            };
                        } else {
                            return { ...item, serialNo: index + 1, order: `Special Transfer Order Notes - ${index + 1}`, verification: `PENDING` };
                        }
                    });

                    setEsignRowdata(tableData);

                    const esignPending = tableData.filter((item: any) => item.verification !== 'DONE');
                    setIsEsignPending(!!esignPending.length);
                }
            }
        } catch (err: any) {
            console.log(err);

            let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleOtherAction = async (row: any) => {
        console.log('rowData', row);
        let signedIds = esignRowdata
            .map((item: any) => {
                if (item.verification === 'DONE') {
                    return `${item.docReferenceId}=${item.newDocRefID}`;
                }
            })
            .filter(Boolean);

        setRefId(row.docReferenceId);
        setSignedIdsBeforeEsign(signedIds);
        setEsignModal(true);
        console.log(signedIds);

        // try {
        //     const userRequestInfo = requestInfo();

        //     if (window.location.protocol !== 'http:') {
        //         let url =
        // window.location.origin +
        // `/goa-idc/estate/transfer/workflow?isSpecialTransfer=${isSpecialTransfer}&applicationNumber=${searchParams.get('applicationNumber')}&tenantId=ga&note=${
        //     values.notes || ''
        // }&docsUuid=${documentsUuid?.join(',') || ''}&signedIds=${signedIds.join(',')}&currentRefId=${row.docReferenceId}`;
        //         url = btoa(url);
        //         const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${row.docReferenceId}&accessToken=${userRequestInfo.authToken}&userType=${
        //             userRequestInfo.userType
        //         }${userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''}`;
        //         await axios.get(esignUrl);

        //         window.open(esignUrl, '_self');
        //     } else {
        //         let url =
        //             window.location.origin +
        //             `/goa-idc/estate/transfer/workflow?isSpecialTransfer=${isSpecialTransfer}&applicationNumber=${searchParams.get('applicationNumber')}&tenantId=ga&signedFileStoreId=${
        //                 row.docReferenceId
        //             }&note=${values.notes || ''}&docsUuid=${documentsUuid?.join(',') || ''}&signedIds=${signedIds.join(',')}&currentRefId=${row.docReferenceId}`;
        //         console.log('url', url, documentsUuid);
        //         window.open(url, '_self');
        //     }
        // } catch (e) {
        //     console.log('esign error', e);
        // }
    };

    const signDocument = () => {
        let url = isSpecialTransfer
            ? window.location.origin +
              `/goa-idc/estate/transfer/workflow?isSpecialTransfer=${isSpecialTransfer}&applicationNumber=${searchParams.get('applicationNumber')}&tenantId=ga&note=${values.notes || ''}&docsUuid=${
                  documentsUuid?.join(',') || ''
              }&signedIds=${signedIdsBeforeEsign?.join(',')}&currentRefId=${refId}`
            : window.location.origin +
              `/goa-idc/estate/transfer/workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
                  ','
              )}&action=${actionAfterEsign}`;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
    };

    const onApprovalSubmit = () => {
        if (isEsignPending) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please E-Sign all the documents!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }
        onHandlePrimaryAction({ action: 'APPROVE' }, '');
    };

    console.log('formik', formik);
    console.log('content', content);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                        <GenericHeader
                            text={t(`${isSpecialTransfer ? 'SPECIAL_TRANSFER ' : 'TRANSFER'}`, 'Transfer')}
                            additionalClass='sub-text-style'
                            bottomSubText={`File : ${applicationSearchResponse?.transferorDetails?.entityName}, Type : ${
                                isSpecialTransfer ? 'Special Transfer' : 'Transfer'
                            } , No : ${transferApplicationNo}`}
                        />
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard'>
                                    <GenericHeader
                                        text={t(`${isSpecialTransfer ? 'SPECIAL_TRANSFER ' : 'TRANSFER'}`, 'Transfer')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : Transfer, No : ${transferApplicationNo}`}
                                    />
                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={transferApplicationNo}
                                        history={historyDetails}
                                        text={comment}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                        disable={!nextValidAction?.nextValidAction.length}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={transferApplicationNo} designation={designation} nextValidAction={nextValidAction} />
                                {activeIndex === 1 && designation === 'MD' && isSpecialTransfer && esignRowdata.length ? (
                                    <Card className='card_border p-1 mb-3'>
                                        <Card className='checklist-outer mb-4 mx-3'>
                                            <div className='row  my-3 px-3 py-2'>
                                                <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                                                    <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                                                    <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                                        {t('E_VERIFICATION', 'e-Verification')}
                                                    </label>
                                                    <div className='form_fields px-2'>
                                                        <GenericActionTable columns={ESIGN_COLUMNS} hideSearchBar={true} rowData={esignRowdata} handleOtherAction={handleOtherAction} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Card>
                                ) : (
                                    <></>
                                )}
                                <Card className='card_border p-1'>
                                    {activeIndex === 1 && (
                                        <TransferWorkflowCorrespondence
                                            checkListRowData={checklistTableData}
                                            dropdownData={dropdownData}
                                            nicDetails={transfereeNicDetails}
                                            setNicDetails={setTransfereeNicDetails}
                                            isChecked={isChecked}
                                            handleCheckboxChange={handleCheckboxChange}
                                            fileStoreId={fileStoreId}
                                            content={content}
                                            setContent={setContent}
                                            setOwnershipDetails={setOwnershipDetails}
                                            ownershipDetails={ownershipDetails}
                                            controllingOwnership={controllingOwnershipResponse}
                                            setDropdownDataPlot={setDropdownDataPlot}
                                            dropdownDataPlot={dropdownDataPlot}
                                            paymentRowData={paymentRowData}
                                            nextValidAction={nextValidAction}
                                            isSpecialTransfer={isSpecialTransfer}
                                            applicationSearchResponse={applicationSearchResponse}
                                            showOwnershipModal={showOwnershipModal}
                                            setShowOwnershipModal={setShowOwnershipModal}
                                            finalOwnerShipDetails={finalOwnerShipDetails}
                                            setFinalOwnerShipDetails={setFinalOwnerShipDetails}
                                            setControllingOwnershipResponse={setControllingOwnershipResponse}
                                            setCalculated={setCalculated}
                                            isTransferChecked={isTransferChecked}
                                            transactionDetails={transactionDetails}
                                            showOwnershipModalTransferor={showOwnershipModalTransferor}
                                            setShowOwnershipModalTransferor={setShowOwnershipModalTransferor}
                                            setOwnershipDetailsTransferor={setOwnershipDetailsTransferor}
                                            ownershipDetailsTransferor={ownershipDetailsTransferor}
                                            controllingOwnershipTransferor={controllingOwnershipResponseTransferor}
                                            designation={designation}
                                        />
                                    )}
                                    {activeIndex === 2 && (
                                        <div className='p-4'>
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideSearchBar={true} rowData={rowData} additionalMethod={onRowClick} />
                                        </div>
                                    )}
                                    {activeIndex === 3 && (
                                        <div className='p-4'>
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        </div>
                                    )}
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>

                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    {esignRowdata.length || (applicationSearchResponse?.status === 'APPROVED' && isSpecialTransfer) ? (
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    <GenericButtons text={t('SUBMIT', 'submit')} handleClick={onApprovalSubmit} />
                                </div>
                            </div>
                        </footer>
                    ) : (
                        <GenericFooterComponent
                            onCancel={onCancel}
                            onClickPrev={onChangePrevIndex}
                            activeIndex={activeIndex}
                            handleOnPrimaryAction={onHandlePrimaryAction}
                            onSubmit={() => {}}
                            applicationNo={transferApplicationNo || null}
                            nextValidAction={nextValidAction}
                            stepperLength={0}
                            extraButton={designation === 'AM' ? 'Save' : null}
                            extraButtonAction={updateNotes}
                        />
                    )}
                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    />
                </>
            )}
        </>
    );
}
