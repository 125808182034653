import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface textFieldProps {
    titleCode?: any;
    field: string;
    title: string;
    type?: string;
}

export default function GenericCommonTextReadOnlyField(props: textFieldProps) {
    const { t } = useTranslation();

    return (
        <>
            <Form.Group>
                <Form.Label>{props.titleCode ? t(props.titleCode) : props.title}</Form.Label>
                <Form.Control className="border border-0 border-none ps-0" type={props?.type} placeholder="" name={props?.field} readOnly />
            </Form.Group>
        </>
    );
}
