import React from 'react';
import StatusAlertPopup from './moduleCommonFormik/StatusAlertPopup';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { getNavigateTo } from '../utils/utils';

const PopUps = (props: any) => {
    const { modalDetails, setModalDetails } = props;
    const navigate = useNavigate();

    const handleModalClose = (type: string, msg?: string, navigateUrl?: string, resetPaidPremium?: boolean) => {
        setModalDetails({});
        if (type === 'success') {
            if (msg === 'Existing user data erased') {
                window.location.reload();
            } else {
                // navigateUrl ? navigate(navigateUrl) : navigate('/goa-idc/dashboard');
                const userType = localStorage.getItem('type');
                if (userType) {
                    navigate(getNavigateTo(JSON.parse(userType)));
                }
            }
        }
    };
    return (
        <>
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.para, modalDetails.navigateUrl, modalDetails?.resetPaidPremium)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.para, modalDetails.navigateUrl, modalDetails?.resetPaidPremium)}
            />
        </>
    );
};

export default PopUps;
