import { FormikProvider, useFormik, Form, FormikProps } from 'formik';
import { useState, ReactElement, ChangeEvent, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import HeaderComponent from '../../../../../components/header/Header.component';
import GenericBackButton from '../../../../../components/UI components/Buttons/GenericBackButton';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import { ADVERTISEMENT_SEARCH_COLUMNS } from './searchAdv.const';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { POST, estateEndpoints, getDropdownOptions, devUrl, requestInfo } from '../../../../../services/modulesServices/userManagement.api';

import { convertTableDateFormat } from '../../../../../utils/utils';
import GenericCommonSelector from '../../../../../components/moduleCommonFormik/GenericCommonSelector.component';
import GenericCommonDatePicker from '../../../../../components/moduleCommonFormik/GenericCommonDatePicker.component';
import { warning } from '../../../../../utils/Logo';

export default function FindAdvertisement() {
    const [rowData, setRowData] = useState<any>(null);
    const [statusState, setStatusState] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const navigate = useNavigate();

    const { t } = useTranslation();

    const initialData = {
        dateFrom: '',
        dateTo: '',
        applicationStatus: '',
        isUpdatable: true
    };

    const status: any = {
        applicationStatus: statusState
    };

    const formik = useFormik<any>({
        initialValues: initialData,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const queryParams = new URLSearchParams();
                if (values.applicationStatus.code) queryParams.append('status', values.applicationStatus.code);
                if (values.dateFrom) queryParams.append('publicationFromDate', new Date(values.dateFrom).getTime().toString());
                if (values.dateTo) queryParams.append('publicationToDate', new Date(values.dateTo).getTime().toString());

                console.log('Query Params:', queryParams.toString());

                const { data } = await POST(estateEndpoints.advSearch + `&${queryParams.toString()}`, { RequestInfo: requestInfo() });
                console.log('API Response:', data);
                if (data.advertisementsDetails.length === 0) {
                    handleOnReset();

                    setModalDetails({ show: true, title: t('ERROR','Error!'), para: t('NO_DATA_FOUND','No Data Found'), img: warning, type: 'error', reUpload: 'OK' });

                    return;
                }

                const newData = data.advertisementsDetails.map((e: any) => ({
                    ...e,
                    publicationDate: convertTableDateFormat(e.publicationDate),
                    submissionStartDate: convertTableDateFormat(e.submissionStartDate),
                    submissionEndDate: convertTableDateFormat(e.submissionEndDate),
                    status: status.applicationStatus.find((status: any) => status.code === e.status)?.name || e.status
                }));
                setRowData(newData);
            } catch (error) {
                console.error('API Error:', error);

                setModalDetails({ show: true, title: t('ERROR','Error!'), para: t('SOMETHING_WENT_WRONG','Something went wrong!'), img: warning, type: 'error', reUpload: 'OK' });
            } finally {
                setSubmitting(false);
            }
        }
    });
    const { values, setValues } = formik;
    console.log('values', values);

    const handleRowClick = (rowData: any) => {
        navigate(`/goa-idc/createadvertisement?applicationNumber=${rowData.advNo}&tenantId=ga&search=true`);
    };

    const handleOnReset = () => {
        setValues(initialData);
        setRowData([]);
    };

    const firstApicall = async () => {
        const payload: any = {
            RequestInfo: requestInfo(),
            MdmsCriteria: {
                tenantId: 'ga',
                moduleDetails: [
                    {
                        moduleName: 'Estate',
                        masterDetails: [
                            {
                                name: 'AdvertisementStatus',
                                filter: '[?(@.active == true)]'
                            }
                        ]
                    }
                ]
            }
        };

        const res3 = await POST('/egov-mdms-service/v1/_search', payload);
        const statusArray = res3.data?.MdmsRes?.Estate?.AdvertisementStatus || [];
        const status = statusArray.map((status: any) => ({
            code: status.code,
            name: status.name
        }));
        setStatusState(status);
    };

    const fieldValues = {
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        applicationStatus: formik.values.applicationStatus
    };
    const allValuesProvided = fieldValues.dateFrom || fieldValues.dateTo || fieldValues.applicationStatus;

    useEffect(() => {
        firstApicall();
    }, []);

    return (
        <>
            <HeaderComponent />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <div className='col-sm-12'>
                    <Card className='card_border'>
                        <Card.Body>
                            <div className='d-flex align-items-center'>
                                <GenericBackButton />
                                <div className='ps-2'>
                                    <Card.Text className='font-inter-med fs-4'>{t('SEARCH_ADVERTISEMENTS','Search Advertisements')}</Card.Text>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

                <div className='col-sm-12'>
                    <FormikProvider value={formik}>
                        <Form>
                            <Card className='card_border'>
                                <Card.Body>
                                    <div className='row gx-3 gy-4 px-3 py-1'>
                                        <div className='col-sm-4 d-flex flex-column'>
                                            <GenericCommonSelector field={'applicationStatus'} title={'Status'} dropdownOptions={status} />
                                        </div>

                                        <div className='col-md-4'>
                                            <div className='row gx-3 font-inter'>
                                                <div className='col-sm-6 '>
                                                    <label className='search_application_label'>{'Advertisement Publish From Date'}</label>
                                                    <GenericCommonDatePicker field={'dateFrom'} title={''} enableFutureDate={true} />
                                                </div>
                                                <div className='col-sm-6'>
                                                    <label className='search_application_label'>{'To Date'}</label>
                                                    <GenericCommonDatePicker field={'dateTo'} title={''} enableFutureDate={true} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                                            <GenericButtons text='Reset' handleClick={handleOnReset} />
                                            <GenericButtons type='submit' text={t('SEARCH', 'Search')} variant='primary' disabled={!allValuesProvided} />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Form>
                    </FormikProvider>
                </div>

                <div className='col-sm-12'>
                    <div className='mt-0'>
                        <GenericActionTable columns={ADVERTISEMENT_SEARCH_COLUMNS} rowData={rowData} additionalMethod={handleRowClick} hideSearchBar={true} hideExportBtn={true} />
                    </div>
                </div>
            </div>
        </>
    );
}
