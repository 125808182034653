import React, { useEffect, useState } from 'react';
import ScrollToTop from '../../../../../components/ScrollToTop';
import { FormikProvider, useFormik } from 'formik';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../Allotment/Allotment.const';
import { useTranslation } from 'react-i18next';
import { Card, Form } from 'react-bootstrap';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { getHistory, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../../EstateManagement.controller';
import PlotMasterCorrespondence from './PlotMasterCorrespondence';
import { dropdownDataOptions } from '../../../Migration/Migration.controller';
import { PLOT_DETAILS, setPlotDetails } from '../Plotmaster.const';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { estateDropdown } from '../../Estate_Master/master.const';
import { POST, endpoints, estateEndpoints, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { getDropdownSelectedObject } from '../../../UserManagement/UserMangement.controller';
import { check, cross } from '../../../../../utils/Logo';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import { TENANT_ID } from '../../../../../utils/constants';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import GenericButtons from '../../../../../components/UI components/Buttons/GenericButtons';
import StatusAlertPopup from '../../../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';

export const PlotMasterWorkflow = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();

    const totalPages = 2;

    const applicationNumber: any = searchParams.get('applicationNumber');
    let user = localStorage.getItem('currentUserAssignment');
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    const userObject = localStorage.getItem('user');
    const userType = userObject ? String(JSON.parse(userObject)?.type) : '';

    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [searchResponse, setSearchResponse] = useState<any>(null);
    const [tabelData, setTabelData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: ''
        },
        onSubmit: () => {}
    });

    const { values, setValues, setTouched, errors } = formik;

    useEffect(() => {
        (async () => {
            if (dropdownData && nextValidAction) {
                try {
                    const { data }: any = await POST(`/idc-estate-service/master-application/_search?applicationNo=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setSearchResponse(data[0]);

                    if (!data[0]) return;
                    const formData = await setPlotDetails(data[0].newPlotDetails[0], dropdownData);
                    const updatedValues = { ...values, ...formData, isUpdatable: nextValidAction?.isUpdatable };
                    setValues(updatedValues);
                } catch (e: any) {
                    console.log(e);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
        })();
    }, [dropdownData, nextValidAction]);

    useEffect(() => {
        if (activeIndex === 2 && documentDetails) {
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails.length - ind}` };
                });
                setTabelData(responseTableData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        (async () => {
            try {
                const dropdownOption = await estateDropdown(userType);
                setDropdownData(dropdownOption || []);

                const nextActions = await getNextActions(applicationNumber);
                setNextValidAction(nextActions);

                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    useEffect(() => {
        if (activeIndex === 2 && documentDetails) {
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails.length - ind}` };
                });
                setTabelData(responseTableData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs.documents);
            setDocumentsUuid([...documentsUuid, docs.uuid]);
            setActiveIndex(2);
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string, finalSubmit?: true) => {
        setModalDetails({});
        type === 'success' && finalSubmit && navigate('/goa-idc/dashboard');
    };

    const handleNextPage = async () => {
        const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction.businessService);
        setDocumentDetails(docs);
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const renderEstateCorrespondence = () => {
        const NEW_PLOT_DETAILS = PLOT_DETAILS.map((detail: any) => ({ ...detail, formData: detail.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));

        return (
            <>
                {NEW_PLOT_DETAILS.map((val, index) => (
                    <GenericFormCard {...val} dropdownOptions={dropdownData} />
                ))}
            </>
        );
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.filter(Boolean),
            masterApplicationRequest: searchResponse
        };

        // if (!comment && !status.isEmployeeAssignee) {
        //     setModalDetails({ show: true, title: t('ERROR','Error!'), para: t('FILL_ALL_REQUIRED_DETAILS','Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        //     return;
        // }

        if (!values.notes) {
            if (!comment) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ENTER_NOTES', 'Please enter notes!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }

        try {
            await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, applicationNumber);
            // designation !== 'DGM' ? `Master Application Created Successfully Application Number : ${applicationNumber}` : `Plot Created Successfully`;
            setModalDetails({ show: true, title: 'Success!', para: successMessage, img: check, type: 'success', reUpload: 'OK', finalSubmit: true });
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={t('PLOT_MASTER', 'Plot Master')} additionalClass='sub-text-style' bottomSubText={`Application No : ${applicationNumber}`} />
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader text={t('ESTATE', 'Estate')} sideHeader={true} additionalClass='sub-text-style' bottomSubText={`Application No : ${applicationNumber}`} />
                                    <NotingWrapper
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        handleOnFileUpload={handleOnFileUpload}
                                        // applicationNumber={applicationNumber}
                                        // disable={!nextValidAction?.nextValidAction.length}
                                        // designation={designation}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />

                                <Form>
                                    {activeIndex === 1 && <Card className='card_border p-1'>{renderEstateCorrespondence()}</Card>}
                                    {activeIndex === 2 && (
                                        <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                    )}
                                    {/* {activeIndex === 3 && (
                                        <GenericActionTable
                                            columns={CORRESPONDENCE_TABLE_APP_FORM}
                                            hideSearchBar={true}
                                            rowData={relatedApplicationDocument?.data.applicationInfos}
                                            additionalMethod={onRowClickRedirect}
                                        />
                                    )} */}
                                </Form>
                            </div>
                        </FormikProvider>
                        <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                            <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                            </span>
                            Page {activeIndex} of {totalPages}
                            <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                            </span>
                        </div>
                    </div>
                    <ScrollToTop />
                    {applicationNumber ? (
                        <GenericFooterComponent
                            onCancel={() => {}}
                            onClickPrev={onChangePrevIndex}
                            activeIndex={activeIndex}
                            handleOnPrimaryAction={onHandlePrimaryAction}
                            onSubmit={() => {}}
                            applicationNo={applicationNumber || null}
                            nextValidAction={nextValidAction}
                            stepperLength={0}
                        />
                    ) : (
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex'>
                                <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                    <GenericButtons text={t('SUBMIT', 'Submit')} variant='primary' handleClick={() => {}} />
                                </div>
                            </div>
                        </footer>
                    )}
                </>
            )}
            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                        {!isPDF && <GenericImageViewer />}
                    </div>
                </GenericPopupModal>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
            />
        </>
    );
};
