import React, { useEffect } from 'react';
import './LoginPage.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import LoginSubPage from './LoginSubPage.wrapper';
import { getStringResource } from '../../i18n/config';
import { isAuthenticated } from '../../utils/utils';
import { CONSTANT_REGEX_SAMPLE } from '../../utils/constants';
import { getPasswordChangeOtp } from '../../services/modulesServices/userManagement.api';
import { useTranslation } from 'react-i18next';
import { check, cross } from '../../utils/Logo';
import PopUps from '../../components/PopUps';
// import { getNavigateTo, isAuthenticated, navigateTo } from "../../utils/utils";

export default function ResetPassword() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const location = useLocation();
    const userType = location?.state?.userType || null;

    const [userData, setUserData] = useState<any>({ tenantId: 'ga' });
    const [modalDetails, setModalDetails] = useState({});

    useEffect(() => {
        if (isAuthenticated()) navigate('/goa-idc/');
        loadCaptchaEnginge(6);
    }, []);

    const handleOnInputChange = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const doSubmit = () => {
        const payload = {
            otp: {
                tenantId: 'ga',
                identity: userData?.username && userData.username.trim(),
                type: 'passwordreset',
                userType: userType
            }
        };

        let user_captcha = (document.getElementById('user_captcha_input') as HTMLInputElement)?.value ?? '';
        if (validateCaptcha(user_captcha) === true) {
            loadCaptchaEnginge(6);
            (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';

            userData.username &&
                getPasswordChangeOtp(payload)
                    .then(res => {
                        if (res.data.isSuccessful) {
                            // localStorage.setItem("username", userLoginData.username);
                            let msg = t('OTP_SENT', 'OTP sent');
                            setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'Sent success', reUpload: 'OK' });
                            navigate('/goa-idc/validate', { state: { payload: payload.otp } });
                        }
                    })
                    .catch(err => {
                        let errMsg = t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                        setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    });
        } else {
            let errMsg = t('CAPTCHA_NOT_MATCH', 'Captcha Does Not Match');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
        }
    };

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <div className='loginPage bg-light'>
                <div className='row column-gap-5 gx-0    '>
                    <div className='col-lg-4 login-container'>
                        <div className=''>
                            <div className='row full-height '>
                                <div className='col-md-5 pe-0 bg-white w-100 '>
                                    <div className='form-left h-100 py-5 px-5 d-flex align-items-center'>
                                        <div className='my-form bg-white font-inter-reg'>
                                            <div className='mb-5 d-flex align-items-center'>
                                                <div>
                                                    <Link to='/goa-idc/login' className='col-1 px-4 py-3 back_arrow  me-3 '></Link>
                                                </div>
                                                <div className='py-2 fw-bold d-inline fs-2 text-black credentials-title mb-0'>{t('FORGOT_PASS', 'Forgot Password')}</div>
                                            </div>

                                            <>
                                                <label className='form-label'>{t('USERNAME', 'Username')}</label>
                                                <input
                                                    type='text'
                                                    placeholder={t('USER_EMAIL_MOB', 'Username or Email or Mobile no.')}
                                                    className='form-control login'
                                                    onChange={handleOnInputChange}
                                                    name='username'
                                                />
                                                {/* <label className="form-label">Password</label>
                                                <div className="password-input-group">
                                                    <input type={showPassword ? "text" : "password"} placeholder="****" className="form-control login" onChange={handleOnInputChange} name="password" />
                                                    {showPassword ? (
                                                        <i className="fa fa-eye password-eye-btn" onClick={handleTogglePassword} aria-hidden="true"></i>
                                                    ) : (
                                                        <i className="fa fa-eye-slash password-eye-btn" aria-hidden="true" onClick={handleTogglePassword}></i>
                                                    )}
                                                </div> */}
                                                <label className='form-label'>{t('CAPTCHA', 'Captcha')}</label>
                                                <div className='d-flex'>
                                                    <div className='w-50'>
                                                        <input
                                                            type='text'
                                                            placeholder={t('ENTER_CAPTCHA', 'Enter captcha')}
                                                            id='user_captcha_input'
                                                            name='user_captcha_input'
                                                            className='form-control login'
                                                        />
                                                    </div>
                                                    <div className='w-50 px-2 captcha-container'>
                                                        <LoadCanvasTemplate reloadColor='blue' reloadText="<div class='reload-icon' title='Refresh Captcha'>&#x21bb;</div>" />
                                                    </div>
                                                </div>

                                                <button type='submit' className='btn login-btn btn-color mt-3' onClick={doSubmit}>
                                                    {getStringResource(t('SEND_OTP', 'Send OTP'))}
                                                </button>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-7 me-auto mt-3  outer_part align-self-center  '>
                        <LoginSubPage />
                    </div>
                </div>
            </div>
        </>
    );
}
