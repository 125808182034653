import { dropdownOptionsInterface } from "../../../../components/moduleCommonFormik/GenericFormCard.constants";
import { CONSTANT_DATA_FIELD, FormDataDTO } from "../../../../utils/constants";

export const ESTATE_REGISTRY_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: "Estate Code",
                field: "estateCode",
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: "Estate Name",
                field: "estateName",
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: "Taluka",
                field: "taluka",
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: "District",
                field: "district",
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: "Survey No.",
                field: "survayNo",
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: "State",
                field: "state",
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            }
        ]
    }
];

export const ESTATE_REGISTRY_TABLE_COLUMNS: any = [

    {
        colName: "Estate Code",
        fieldName: "estateCode",
        clsName: "col-sm-1"
    },
    {
        colName: "Estate Name",
        fieldName: "estateName",
        clsName: "col-sm-2"
    },
    {
        colName: "Taluka",
        fieldName: "taluka",
        clsName: "col-sm-2"
    },
    {
        colName: "District",
        fieldName: "district",
        clsName: "col-sm-2"
    },
    {
        colName: "State",
        fieldName: "state",
        clsName: "col-sm-2"
    },
    {
        colName: "No. of Plots",
        fieldName: "noOfPlots",
        clsName: "col-sm-1"
    },
    {
        colName: "Action",
        fieldName: "action",
        clsName: "col-sm-2",
        actions: "edit"
    }

];

export const ESTATE_REGISTRY_FIELDOFFICE_TABLE_COLUMNS: any = [

    {
        colName: "Field Office Name",
        fieldName: "fieldOfficeName",
        clsName: "col-sm-3",
        id: 1
    },
    {
        colName: "Manager Name",
        fieldName: "managerName",
        clsName: "col-sm-2",
        id: 2
    },
    {
        colName: "Address",
        fieldName: "address",
        clsName: "col-sm-4",
        id: 3
    },
    {
        colName: "Action",
        fieldName: "action",
        clsName: "col-sm-3",
        actions: "edit",
        id: 4
    }

];
export const ESTATE_FIELDOFFICE_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: "Field Office Address",
                field: "officeAddress",
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: true
            },
            {
                title: "Field Manager's Name",
                field: "managerName",
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: "Mobile Number",
                field: "phoneNo",
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: "Email ID",
                field: "emailId",
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
        ]
    }
];