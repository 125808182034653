import { BPArequestInfo } from '../../services/modulesServices/userManagement.api';

export const ConstructionLicenseFormJson = (values: any, extValue: any, isUpdate: boolean) => {
    var userinfo = window.localStorage.getItem('user');
    console.log(isUpdate, 'isUpdate');
    if (!isUpdate) {
        return {
            RequestInfo: BPArequestInfo(),
            BPA: {
                id: extValue?.id ? extValue?.id : null,
                tenantId: 'ga',
                applicationNo: values?.id ? values?.appId : null,
                approvalNo: null,
                edcrNumber: values?.edcrNumber,
                riskType: 'High',
                businessService: 'WF_BPA_CL',
                applicationType: 'CONSTRUCTION_LICENCE',
                serviceType: values?.serviceType,
                approvalDate: null,
                validUpTo: null,
                applicationDate: null,
                status: null,
                accountId: null,
                lesseeId: null,
                archRegId: values?.archId,
                archName: values?.archName,
                noteId: null,
                notes: null,
                additionalDetails: values?.additionalDetails,
                lesseeDetails: {
                    id: null,
                    name: values?.name,
                    gender: values?.gender,
                    phoneNo: values?.phone,
                    pan: values?.panNo,
                    address: {
                        id: null,
                        tenantId: 'ga.goa',
                        pincode: values?.pincode,
                        district: values?.district,
                        state: values?.state,
                        country: values?.country,
                        addressLine1: values?.addressLine1,
                        addressLine2: values?.addressLine2
                    }
                },
                documents: values.documents,
                workflow: null
            }
        };
    } else {
        const merged = Object.assign({}, extValue);

        for (const key of Object.keys(values)) {
            if (key in merged) {
                merged[key] = values[key];
            }
        }
        merged.archRegId = values?.archRegId || extValue?.archRegId;
        merged.id = extValue?.id;
        merged.notes = {
            content: values?.notes,
            notificationDetails: values?.emails
        };
        const modifiedPayload = {
            RequestInfo: BPArequestInfo(),
            BPA: merged
        };
        return modifiedPayload;
    }
};

export const getDocsData = (values: any, appId: string) => {
    const referredArray: any = [];
    if (values && !!values.length) {
        values.map((val: any, ind: number) =>
            referredArray.push({
                id: val?.id || null,
                applicationNumber: appId,
                documentType: val?.documentType,
                fileStoreId: val?.fileStoreId,
                tenantId: 'ga',
                documentName: val?.documentName,
                documentUid: val?.documentUid || '',
                additionalDetails: {}
            })
        );

        return referredArray;
    }
};
export const getFileurl = (allDocs: any, key: string) => {
    if (!allDocs) return [];
    const filteredDocs = allDocs.filter((element: any) => element.documentType === key);
    const newArray: any = [];
    filteredDocs.forEach((ele: any) => {
        newArray.push({
            id: ele?.id,
            applicationNumber: ele?.applicationNumber,
            documentType: ele?.documentType,
            fileStoreId: ele?.fileStoreId,
            tenantId: 'ga',
            documentName: ele?.documentName,
            documentUid: ele?.documentUid,
            additionalDetails: {}
        });
    });
    return newArray;
};

export const PayloadJson = () => {
    var userinfo = window.localStorage.getItem('userData');
    return {
        RequestInfo: {
            apiId: '1',
            ver: '1',
            ts: null,
            action: 'create',
            did: 'jh',
            key: '',
            msgId: 'gfcfc',
            correlationId: 'wefiuweiuff897',
            authToken: window.localStorage.getItem('authToken'),
            userInfo: userinfo !== null ? JSON.parse(userinfo) : null
        }
    };
};

export const getEmployeeList = (data: any) => {
    let empList: any = [];
    if (!!data.length) {
        data.map((val: any) => {
            empList.push({
                name: `${val?.user?.firstName} ${val?.user?.lastName}`,
                title: `${val?.user?.firstName} ${val?.user?.lastName}`,
                value: val?.user?.uuid
            });
        });
        return empList;
    }
};

export const getFeeDetailsList = (data: any) => {
    let feeList: any = [];
    if (!!data?.taxHeadEstimates?.length) {
        data?.taxHeadEstimates.map((val: any) => {
            feeList.push({
                name: data?.feeType,
                title: val?.taxHeadCode.replace(/_/g, ' '),
                value: val?.estimateAmount
            });
        });
        return [
            {
                heading: data?.feeType,
                paymentdata: feeList
            }
        ];
    }
};

export const edcrRequestDta = () => {
    var userinfo = window.localStorage.getItem('user');
    return {
        transactionNumber: 0,
        edcrNumber: 'TRA2N222S12242a3',
        planFile: null,
        tenantId: '',
        applicantName: '',
        appliactionType: '',
        applicationSubType: '',
        RequestInfo: {
            apiId: '',
            ver: '',
            ts: '',
            action: '',
            did: '',
            authToken: null,
            key: '',
            msgId: '',
            correlationId: '',
            userInfo: userinfo !== null ? JSON.parse(userinfo) : null
        }
    };
};

export const getBPAList = (data: any) => {
    let newList: any = [];
    if (!!data && !!data.length) {
        data.map((val: any) => {
            newList.push({
                name: `${val?.edcrNumber}`,
                title: ` ${val?.edcrNumber}  (Arch_Name - ${val?.archName})`,
                value: val?.edcrNumber,
                code: val?.edcrNumber,
                subcategory: [],
                // Add additional fields
                dcrNumber: val?.edcrNumber,
                estate: val?.estateName,
                plotNumber: val?.plotNo,
                architectName: val?.archName,
                sharedDate: new Date(val?.createdTime).toISOString().split('T')[0]
            });
        });
        return newList;
    }
};
