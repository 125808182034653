export const NOTIFICATION_COLUMN_DATA = [
    {
        colName: '',
        fieldName: 'checkbox',
        clsName: 'col-sm-1 column-text-start  '
    },
    {
        colName: 'Module',
        fieldName: 'notificationDetails.module',
        clsName: 'col-sm-2 column-text-start  '
    },
    {
        colName: 'Notification Details',
        fieldName: 'notificationDetails.message',
        clsName: 'col-sm-6 column-text-start ',
        isClickable: true
    },
    {
        colName: 'Date & Time',
        fieldName: 'notificationDetails.auditDetails.createdDate',
        clsName: 'col-sm-2 column-text-start '
    }
];
