import _ from 'lodash';
import { CONSTANT_DATA_FIELD, CORRESPONDENCE, ENTERPRISE, FormDataDTO, PERMANENT, TENANT_ID } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { setEntityDocumentDetails } from '../../Migration/Migration.controller';
import { enterpriseDetailsPayload, getApplicantDocuments } from '../Allotment/Allotment.controller';
import { APPLICANT_DETAILS_I } from '../Allotment/Allotment.const';
import { entityDocumentData } from '../EstateManagement.controller';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';

export const DECLARATION_LABEL = [
    '(i) the information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed herein.',
    '(ii) it has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation proceedings.',
    '(iii) the proposed Sub-Lease does not amount to prohibited Sub-Lease under the Regulations, as may be amended from time to time;',
    '(iv) the Lessee agrees to pay annual Sub-Lease Fee to Corporation as defined under the Regulations; and',
    '(v) it is not in breach of any term and condition of the Lease Deed.'
];

export const PLOT_DETAILS_TABLE = [
    {
        colName: '',
        fieldName: 'radio',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Plot No',
        fieldName: 'plotNo',
        clsName: 'col-sm-2',
        id: 2
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col-sm-2',
        id: 3
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateName',
        clsName: 'col-sm-2',
        id: 4
    },
    {
        colName: 'Plot Type',
        fieldName: 'plotType',
        clsName: 'col-sm-3',
        id: 5
    },
    {
        colName: 'Plot Area (Sqm)',
        fieldName: 'plotAreaSqm',
        clsName: 'col-sm-2',
        id: 6
    }
];

export const SUB_LEASE_STEPS = [
    // {
    //     titleCode: 'LESSEE_DETAILS',
    //     name: 'Lessee Details',
    //     index: 2,
    //     IndexKey: 'A2',
    //     isActive: false
    // },
    {
        titleCode: 'SUB_LESSEE_DETAILS',
        name: 'Sub-Lessee Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: ' PLOT_DETAILS',
        name: 'Plot Details',
        index: 2,
        isActive: false
    },
    {
        titleCode: 'SUB_LEASE_DETAILS',
        name: 'Sub-Lease Details',
        index: 3,
        isActive: false
    },
    {
        titleCode: ' ENTERPRISE_DETAILS',
        name: 'Enterprise Details',
        index: 4,
        isActive: false
    },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        index: 5,
        isActive: false
    },
    {
        titleCode: 'PAYMENT_DETAILS',
        name: 'Payment Details',
        index: 6,
        isActive: false
    }
];

export const SUB_LEASE_READ_ONLY_ASTEPS = [
    {
        titleCode: 'SUB_LESSEE_DETAILS',
        name: 'Sub-Lessee Details',
        index: 1,
        isActive: true
    },
    {
        titleCode: ' PLOT_DETAILS',
        name: 'Plot Details',
        index: 2,
        isActive: false
    },
    {
        titleCode: 'SUB_LEASE_DETAILS',
        name: 'Sub-Lease Details',
        index: 3,
        isActive: false
    },
    {
        titleCode: ' ENTERPRISE_DETAILS',
        name: 'Enterprise Details',
        index: 4,
        isActive: false
    }
];
export const plotOtherField = [
    {
        formData: [
            {
                title: 'Plan showing the area of Sub-Lease',
                field: 'areaOfSubLease',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.AREA_OF_SUB_LEASE
                },
                hideFileSize: true,
                isRequired: true
            }
        ]
    }
];

export const options = [
    { value: true, title: 'Yes' },
    { value: false, title: 'No' }
];

export const LESSE_APPLICANT_DETAILS_I = [
    {
        formData: [
            {
                title: 'Sub-Lessee Name',
                field: 'applicantName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Sub-Lessee Category',
                field: 'category',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            ...APPLICANT_DETAILS_I[0].formData.slice(2)
        ]
    }
];

export const LESSEE_DETAILS = [
    {
        formData: [
            {
                titleCode: 'LESSEE_NAME',
                title: 'Lessee Name',
                field: 'lesseetName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'EMAIL_ID',
                title: 'Email ID',
                field: 'lesseeEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'PHONE_NO',
                title: 'Phone No',
                field: 'lesseePhoneNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'ADDRESS_OF_LESSEE',
        title: 'Address of the Lessee',
        formData: [
            {
                title: 'Address Line 1',
                titleCode: 'ADDRESS_LINE_1',
                field: 'lesseeAddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                titleCode: 'ADDRESS_LINE_2',
                field: 'lesseeAddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                titleCode: 'PIN_CODE',
                field: 'lesseePincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'District',
                titleCode: 'DISTRICT',
                field: 'lesseeDistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State',
                titleCode: 'STATE',
                field: 'lesseeState',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                titleCode: 'COUNTRY',
                field: 'lesseeCountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];
export const SUB_LESSEE_DETAILS_A3 = [
    {
        formData: [
            {
                titleCode: '',
                title: 'Sub-Lease Start Date',
                field: 'subLeaseStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true,
                isRequired: true
            },
            {
                titleCode: '',
                title: 'Sub-Lease End Date',
                field: 'subLeaseEndDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                enableFutureDate: true,
                isRequired: true
            },
            {
                titleCode: '',
                title: 'Duration of Sub-Lease',
                field: 'durationSubLease',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                titleCode: '',
                title: 'Sub-Lease Built-up Area (in sqm)',
                field: 'subLeaseBuiltUpArea',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },

            {
                titleCode: '',
                title: 'Lease Deed/Tripartite Deed/Deed of Extension',
                field: 'lesseeDeedOfExtension',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            },
            {
                titleCode: '',
                title: 'Occupancy Certificate',
                field: 'occupancyCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            },
            // {
            //     titleCode: '',
            //     title: 'Whether the Lessee is making a sub-lease to a Disqualified Entity',
            //     field: 'disqualifiedEntity',
            //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
            //     options: options
            // },
            // {
            //     titleCode: '',
            //     title: 'Whether the Lessee is making a sub-lease from an Industrial Undertaking to a Commercial Undertaking',
            //     field: 'industrialToCommercial',
            //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
            //     options: options
            // },
            // {
            //     titleCode: '',
            //     title: 'Whether the Lessee has utilised the Built-Up Area, such that it covers at least 30% of the plot area.',
            //     field: 'utilisedBuiltUpArea',
            //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
            //     options: options
            // },
            // {
            //     titleCode: '',
            //     title: 'Whether the Lessee has any outstanding dues payable to the Corporation.',
            //     field: 'lesseeDuesPayable',
            //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
            //     options: options
            // },
            {
                titleCode: '',
                title: 'Whether the Lessee is making a sub-lease of a Special Project allotted through Goa-IPB',
                field: 'subLeaseSpecialProject',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: options,
                isDisabled: true
            },
            {
                titleCode: '',
                title: 'NOC from Goa-IPB',
                field: 'nocDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                hideTitle: true,
                class: 'me-2 col-sm-6',
                renderCondition: (value: any) => _.get(value, `subLeaseSpecialProject`) === true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.NOC_SPECIAL_PROJECT
                }
            }
        ]
    }
];

export const SUB_LEASE_CORRESPONDENCE = [
    {
        title: 'A1 - Sub-Lessee Details',
        field: 'subLeasetDetails'
    },
    {
        title: 'A2 - Lessee Details',
        field: 'lesseDetails'
    },
    {
        title: 'A3 - Sub-Lease Details',
        field: 'subLeasetDetails2'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Enterprise Details',
        field: 'enterpriseDetails'
    },
    {
        title: 'D - Self Declaration',
        field: 'selfDeclaration'
    },
    {
        title: 'E - Payment Details',
        field: 'paymentDetails'
    }
    // {
    //     title: 'F - Sublease Order Notes',
    //     field: 'subleaseOrderNotes'
    // }
];

export const SUB_LEASE_ORDER_CORRESPONDENCE = [
    {
        title: 'A1 - Sub-Lessee Details',
        field: 'subLeasetDetails'
    },
    {
        title: 'A2 - Lessee Details',
        field: 'lesseDetails'
    },
    {
        title: 'A3 - Sub-Lease Details',
        field: 'subLeasetDetails2'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Enterprise Details',
        field: 'enterpriseDetails'
    },
    {
        title: 'D - Payment Details',
        field: 'paymentDetails'
    }
];

export const SUBLEASE_PAYLOAD = (
    values: any,
    nicDetails: any,
    controllingOwnershipResponse: any,
    activeIndex: any,
    searchedData: any,
    subLeaseResData: any,
    isFinalSubmit: boolean,
    subLesseeEntityDetails: any,
    subLeaseUuid: any,
    applicationNo: any,
    existingUser: any,
    enterpriseDetails: any
) => {
    console.log(subLesseeEntityDetails);
    const docs = getApplicantDocuments(values);

    const subLeaseDataCreate = {
        RequestInfo: requestInfo(),
        plotId: values.plotUuid || null,
        subLeaseDetails: [
            {
                uuid: subLeaseUuid || null,
                applicationDetails: null,
                lesseeEntityDetails: searchedData,
                isUpdateSubLesseeEntityDetails: activeIndex === 1 && !existingUser?.uuid ? true : false,
                isUpdateSubLesseeEnterpriseDetails: activeIndex === 4 ? true : false,
                subLesseeEntityDetails: {
                    ...subLesseeEntityDetails,
                    entityName: values.applicantName,
                    category: values?.category?.code,
                    email: values.subLeaseEmailId,
                    phoneNumber: values.phoneNumber,
                    panNumber: values.applicantPanNo,
                    gstApplicable: values.applicantGst,
                    gstNumber: values?.applicantGstno || null,
                    addresses: [
                        {
                            ...subLesseeEntityDetails?.addresses?.[0],
                            addressType: PERMANENT,
                            address1: values.address1,
                            address2: values.address2 || null,
                            pinCode: values.pincode,
                            district: values.district,
                            city: null,
                            state: values.state,
                            country: values.country
                        },
                        {
                            ...subLesseeEntityDetails?.addresses?.[1],
                            addressType: CORRESPONDENCE,
                            address1: values.peraddress1,
                            address2: values.peraddress2 || null,
                            pinCode: values.perpincode,
                            district: values.perdistrict,
                            city: null,
                            state: values.perstate,
                            country: values.percountry
                        }
                    ],
                    authUsers: [
                        {
                            ...subLesseeEntityDetails?.authUsers?.[0],
                            salutation: values.authorizedPersonFirstnamesalutation || null,
                            firstName: values.authorizedPersonFirstname,
                            lastName: values.authorizedPersonLastName,
                            primaryMobileNo: values.pmobileNumber,
                            alternateMobileNo: values.alternateMobileNumber || null,
                            email: values.authorizedEmailId,
                            tenantId: TENANT_ID,
                            type: ENTERPRISE,
                            documents: docs.filter((item: any) => item.referenceType === 'AUTHUSER')
                        }
                    ],
                    owners: controllingOwnershipResponse,
                    // entityBankDetail: {
                    //     ...subLesseeEntityDetails?.entityBankDetail,
                    //     accountNumber: values?.accountNumber,
                    //     ifscCode: values?.ifscCode,
                    //     bankName: values?.bankName,
                    //     branchAddress: values?.branchAddress
                    // },
                    documents: docs,
                    miscDetails: {
                        ...subLesseeEntityDetails?.miscDetails,
                        isUdhyamRegistered: values?.udhyam,
                        isDpiitCertified: values?.isStartup,
                        isWomenEntreprenueur: null,
                        isLocalPerson: null,
                        isExperienced: values?.priorExperience,
                        experience: values?.experienceCategory?.code,
                        udyamNumber: values?.udyamNo
                    }
                }
            }
        ]
    };

    const existingEnterpriseDetails = enterpriseDetailsPayload(values, nicDetails, { enterpriseDetails });

    const extraDetails: any = {
        subLeaseTermStartDate: values?.subLeaseStartDate,
        subLeaseTermEndDate: values?.subLeaseEndDate,
        duration: values?.durationSubLease,
        area: values?.subLeaseBuiltUpArea,
        isBuiltUpAreaUtilised: values?.utilisedBuiltUpArea,
        isLesseeOutstandingDuePayable: values?.lesseeDuesPayable,
        isDisqualifiedEntity: values?.disqualifiedEntity,
        isChangeToCommercial: values?.industrialToCommercial,
        isSpecialProject: values.subLeaseSpecialProject
    };

    const subLeaseDataUpdate = {
        RequestInfo: requestInfo(),
        plotId: values.plotUuid || null,
        subLeaseDetails: [
            {
                // ...(subLeaseResData ? subLeaseResData : {}),
                uuid: subLeaseUuid || null,
                applicationDetails: subLeaseResData?.applicationDetails || null,
                lesseeEntityDetails: searchedData,
                isUpdateSubLesseeEntityDetails: activeIndex === 1 ? true : false,
                subLesseeEntityDetails: subLeaseResData?.applicationDetails?.enterpriseEntity,
                isUpdateSubLesseeEnterpriseDetails: activeIndex === 4 ? true : false,
                subLesseeEnterpriseDetails: activeIndex === 4 ? { ...existingEnterpriseDetails } : null,
                // documents: (values?.documents && Object.values(values?.documents).map(setEntityDocumentDetails)) || null,
                isFinalSubmit: isFinalSubmit ? true : false,
                status: subLeaseResData?.applicationDetails?.status,
                planForSubLeaseAreaDocRefId: values?.areaOfSubLease?.docReferenceId,
                nocForSpecialProjectDocRefId: values?.nocDocument?.docReferenceId || undefined,
                ...extraDetails
            }
        ]
    };
    if (!applicationNo) return subLeaseDataCreate;
    else {
        // if (isFinalSubmit) extraDetails.isFinalSubmit = true;
        return subLeaseDataUpdate;
    }
};

const getDocumentDetails = async (docRefId: any, docType: any) => {
    const documentData = (await entityDocumentData([{ docReferenceId: docRefId, documentType: docType }]))[0];
    return { ...documentData, documentName: documentData?.tag };
};

export const getSubleaseDocs = async (res: any) => {
    const initialData: any = {};

    initialData['areaOfSubLease'] = res?.planForSubLeaseAreaDocRefId && (await getDocumentDetails(res?.planForSubLeaseAreaDocRefId, DOCUMENT_TYPE.AREA_OF_SUB_LEASE));
    initialData['nocDocument'] = res?.nocForSpecialProjectDocRefId && (await getDocumentDetails(res?.nocForSpecialProjectDocRefId, DOCUMENT_TYPE.NOC_SPECIAL_PROJECT));

    return initialData;
};

export const getLesseeInitData = (data: any, dropdownData?: any) => {
    const permanentAddress = data.enterpriseEntity?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');

    const initialData: any = {};
    initialData['lesseetName'] = data?.enterpriseEntity?.entityName;
    // initialData['category'] = getDropdownSelectedObject(data?.enterpriseEntity?.category, dropdownData['category'] || []);
    initialData['lesseeEmailId'] = data?.enterpriseEntity?.email;
    initialData['lesseePhoneNumber'] = data?.enterpriseEntity?.phoneNumber;
    initialData['lesseeAddress1'] = permanentAddress.address1;
    initialData['lesseeAddress2'] = permanentAddress.address2;
    initialData['lesseePincode'] = permanentAddress.pinCode;
    initialData['lesseeDistrict'] = permanentAddress.district;
    initialData['lesseeState'] = permanentAddress.state;
    initialData['lesseeCountry'] = permanentAddress.country;
    return initialData;
};

export const subLeaseDetailsDeclarationList = [
    {
        label: '(i) The Lessee has utilized the Built-Up Area such that it covers at least 30% of the plot area',
        disabled: true
    },
    {
        label: '(ii) The Lessee has no outstanding dues payable to the Corporation.',
        disabled: true
    },
    // {
    //     label: '(iii) The Lessee is not making a sub-lease of a Special Project allotted through Goa-IPB.',
    //     disabled: true
    // },
    {
        label: '(iv) The Lessee is not making a sub-lease to a Disqualified Entity.',
        disabled: false
    },
    {
        label: '(v) The Lessee is not making a sub-lease from an Industrial Undertaking to a Commercial Undertaking.',
        disabled: false
    }
];

export const subleaseOrderSteps = [
    {
        titleCode: 'PROVISIONAL_SUBLEASE',
        name: 'Provisional Sublease',
        index: 1,
        isActive: true
    }
    // {
    //     titleCode: 'PAYMENT_DETAILS',
    //     name: 'Payment Details',
    //     index: 2,
    //     isActive: false
    // }
];
