import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';

export const stepsDetails = [
    {
        titleCode: 'USER_DETAILS',
        name: "User's Details",
        index: 1,
        isActive: true
    },
    {
        titleCode: 'EXTENSION_DETAILS',
        name: 'Extension Details',
        index: 2,
        isActive: true
    }
];

export const PLOT_DETAILS_TABLE_LD = [
    {
        colName: '',
        fieldName: 'radio',
        clsName: 'col-sm-1 column-text-start',
        id: 1
    },
    {
        colName: 'Plot No',
        fieldName: 'plotNo',
        clsName: 'col-sm-2 column-text-start',
        id: 2
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col-sm-2 column-text-start',
        id: 3
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateDetails.estateName',
        clsName: 'col-sm-2 column-text-start',
        id: 4
    },
    {
        colName: 'Plot Type',
        fieldName: 'plotType',
        clsName: 'col-sm-3 column-text-start',
        id: 5
    },
    {
        colName: 'Plot Area (Sqm)',
        fieldName: 'plotAreaSqm',
        clsName: 'col-sm-2 column-text-start',
        id: 6
    }
];

export const cardstepsDetails = [
    {
        titleCode: 'LEASE_DEED_EXECUTION',
        name: 'Lease Deed Execution /Payment',
        index: 1,
        isActive: true
    },
    {
        titleCode: 'TRANSFER_ORDER',
        name: 'Transfer Order',
        index: 2,
        isActive: true
    },
    {
        titleCode: 'PROVISIONAL_SUBLEASE_ORDER',
        name: 'Provisional Sub-Lease Order',
        index: 3,
        isActive: true
    },
    {
        titleCode: 'SPECIAL_TRASFER_ORDER',
        name: 'Special Treansfer Order',
        index: 4,
        isActive: true
    }
];

export const EXTENSION_DETAILS = [
    {
        formData: [
            {
                title: 'Name',
                field: 'name',
                fieldname: 'extname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Address of the User',
        title: 'Address Details',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-6'
            },
            {
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const USER_DETAILS = [
    {
        titleCode: 'Timeline Extension Details',
        title: 'Extension Details',
        formData: [
            {
                title: 'Order No.',
                field: 'allotmentId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Expiry date',
                field: 'expiryDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            },
            {
                title: 'Comments',
                field: 'comments',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: true,
                class: 'col-sm-12'
            },
            {
                title: 'Supporting Document',
                field: 'supportingdocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Document',
                    documentType: DOCUMENT_TYPE.EXTENSION,
                    referenceType: DOCUMENT_TYPE.EXTENSION
                }
            }
        ]
    }
];

export const MENU_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Timeline Extension Type',
                field: 'extensionType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-5 m-0',
                isRequired: true
            }
        ]
    }
];

export const EXTWORKFLOW_DETAILS: any[] = [
    {
        formData: [
            {
                title: 'Timeline Extension Type',
                field: 'extensionType',
                isRequired: true,
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-6 me-3',
                isDisabled: true
            },
            {
                title: 'Comments',
                field: 'comments',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                isRequired: true,
                class: 'col-sm-12',
                isDisabled: true
            },
            {
                title: 'Order No',
                titleCode: 'ORDER_NO',
                field: 'orderNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Expiry Date',
                titleCode: 'EXPIRY_DATE',
                field: 'expiryDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                isDisabled: true,
                enableFutureDate: true
            },
            {
                title: 'Supporting Document',
                field: 'supportingdocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'Supporting_Document',
                    documentType: DOCUMENT_TYPE.EXTENSION,
                    referenceType: DOCUMENT_TYPE.EXTENSION
                },
                isDisabled: true
            }
        ]
    }
];

export const EXTWORKFLOW_COLUMNS = [
    {
        colName: 'Plot No.',
        fieldName: 'plotNo',
        clsName: 'col-sm-2',
        isClickable: false
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateDetails.estateName',
        clsName: 'col-sm-3'
    },
    {
        colName: 'Plot Type',
        fieldName: 'plotType',
        clsName: 'col-sm-3'
    },
    {
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotAreaSqm',
        clsName: 'col-sm-2'
    },
    {
        colName: 'Action',
        fieldName: 'action',
        actions: ['delete'],
        clsName: 'col-sm-2'
    }
];
export const EXTWORKFLOW_COLUMNS1 = [
    {
        colName: 'Plot No.',
        fieldName: 'plotNo',
        clsName: 'col-sm-2',
        isClickable: false
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateDetails.estateName',
        clsName: 'col-sm-3'
    },
    {
        colName: 'Plot Type',
        fieldName: 'plotType',
        clsName: 'col-sm-3'
    },
    {
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotAreaSqm',
        clsName: 'col-sm-2'
    }
];

export const COMMENT_DETAILS = [
    {
        formData: [],
        disabled: true
    }
];

export const EXTENSION_TAB = [
    {
        title: 'A - User Details',
        field: 'userDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Timeline Extension Details',
        field: 'timelineExtensionDetails'
    }
    // {
    //     title: 'D - Extension Order Notes',
    //     field: 'extensionOrderNotes'
    // }
];

export const INITIATE_EXTENSION_SEARCH: any = [
    {
        titleCode: 'ENTERPRISE_SEARCH',
        title: 'Enterprise Search',
        formData: [
            {
                title: 'Name',
                field: 'entityName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                class: 'col-sm-4'
            },
            {
                title: 'Mobile No',
                field: 'mobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4'
            },
            {
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: false,
                class: 'col-sm-4'
            }
        ]
    }
];

export const INITIATE_EXTENSION_ENTITY_TABLE = [
    {
        colName: '',
        fieldName: 'radio',
        clsName: 'col-sm-1',
        id: 1
    },
    {
        colName: 'Name',
        fieldName: 'entityName',
        clsName: 'col-sm-4 column-text-start',
        id: 2
    },
    {
        colName: 'Email ID',
        fieldName: 'email',
        clsName: 'col-sm-4 column-text-start',
        id: 3
    },
    {
        colName: 'Mobile No',
        fieldName: 'phoneNumber',
        clsName: 'col-sm-4 column-text-start',
        id: 4
    }
];
