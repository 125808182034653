import CorrespondenceDocDownload from './CorrespondenceDocDownload';
import NotingComponent from './NotingComponent';
import NotingDocDownload from './NotingDocDownload';

export const NotingWrapper = (props: any) => {
    console.log('nextValidAction', props.nextValidAction);
    return (
        <>
            <div className='d-flex justify-content-between align-items-center'>
                <p className='noting-heading my-1'>Noting</p>
                {(props.designation === 'MD' || (props.nextValidAction?.businessService === 'ADV_EST_ALLOTMENT' && props.designation === 'GM')) && (
                    <NotingDocDownload applicationNumber={props.applicationNumber} nextValidAction={props.nextValidAction} />
                )}
            </div>
            <NotingComponent history={props.history} disable={props.disable} handleOnFileUpload={props.handleOnFileUpload} nextValidAction={props.nextValidAction} />
        </>
    );
};

export const CorrespondenceWrapper = (props: any) => {
    return (
        <div className='d-flex justify-content-between align-items-center'>
            <p className='correspondence-heading my-1'>{props?.isViewOnly ? `` : `Correspondence`}</p>
            {(props.designation === 'MD' || (props.nextValidAction?.businessService === 'EST_ADV_ALLOTMENT' && props.designation === 'GM')) && (
                <CorrespondenceDocDownload applicationNumber={props.applicationNumber} nextValidAction={props.nextValidAction} />
            )}
        </div>
    );
};
