import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import NotingComponent from '../../../../components/Workflow/NotingComponent';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import ScrollToTop from '../../../../components/ScrollToTop';
import NotingDocDownload from '../../../../components/Workflow/NotingDocDownload';
import CorrespondenceDocDownload from '../../../../components/Workflow/CorrespondenceDocDownload';
import { CORRESPONDENCE_TABLE } from '../Allotment/Allotment.const';
import { POST, devUrl, endpoints, estateEndpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { getCheckList, getHistory, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../EstateManagement.controller';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { check, cross, warning } from '../../../../utils/Logo';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { TENANT_ID } from '../../../../utils/constants';
import CancellationCorrespondence from './CancellationCorrespondence';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../LeaseDeed/LeaseDeed.const';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { getApplicantInitData } from '../ApplicantDetailsWorkflow';
import { dropdownDataOptions } from '../../Migration/Migration.controller';
import { getCancellationData } from './CancellationController';
import axios from 'axios';

export default function Cancellation(props: any) {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const { t } = useTranslation();
    const [tabelData, setTabelData] = useState<any>([]);
    const [searchParams] = useSearchParams();
    const [content, setContent] = useState('');
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>(null);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);

    const applicationNumber: any = searchParams.get('applicationNumber');
    let user = localStorage.getItem('currentUserAssignment');
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';

    let navigate = useNavigate();

    // const totalPages = applicationNumber ? 3 : 1;
    const totalPages = 3;

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: ''
        },
        onSubmit: () => {}
    });

    useEffect(() => {
        const ApplicanAndPlotDetails = async () => {
            if (applicationNumber && dropdownData) {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.searchAllotment + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    const estateData = data.estateApplications[0];
                    setFormData(estateData);
                    const historyData = await getHistory(applicationNumber);
                    setHistoryDetails(historyData);
                    const initData = await getApplicantInitData(estateData, dropdownData);
                    const orderData = await getCancellationData(estateData, dropdownData);

                    setValues((prev: any) => ({
                        ...prev,
                        ...initData,
                        ...orderData,
                        applicationSubType: estateData.applicationSubType,
                        plotNo: { name: estateData?.appliedPlotDetails?.plotNo, code: estateData?.appliedPlotDetails?.plotNo },
                        phase: { name: estateData?.appliedPlotDetails?.phase, code: estateData?.appliedPlotDetails?.phase } || [],
                        plotType: { name: estateData?.appliedPlotDetails?.plotType, code: estateData?.appliedPlotDetails?.plotType },
                        estateName: { name: estateData?.appliedPlotDetails?.estateDetails.estateName, code: estateData?.appliedPlotDetails?.estateDetails.estateName },
                        plotArea: estateData?.appliedPlotDetails?.plotAreaSqm,
                        plotRate: estateData?.appliedPlotDetails?.plotRate
                    }));
                    setContent(estateData.content);
                    setIsLoading(false);
                } catch (e: any) {
                    console.log(e);
                    let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                    setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    setIsLoading(false);
                }
            }
        };
        ApplicanAndPlotDetails();
    }, [dropdownData]);

    useEffect(() => {
        (async () => {
            try {
                const dropdownOption = await dropdownDataOptions();
                setDropdownData(dropdownOption);

                const nextActions = await getNextActions(applicationNumber);
                setNextValidAction(nextActions);
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    useEffect(() => {
        const reverseDocumentDetails = documentDetails.reverse();
        const tableData = async () => {
            const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${ind + 1}` };
            });
            const reverseData = responseTableData.reverse();
            setTabelData(reverseData);
        };
        if (activeIndex === 2) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction.businessService);
                setDocumentDetails(docs);
            } catch (e) {
                console.log(e);
            }
        };
        formData && getCorrespondenceDocument();
    }, [nextValidAction, formData]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction.businessService);
                setDocumentDetails(docs);
                const relatedApplicationDocument = await POST(
                    estateEndpoints.relatedApplicationSearch + `?entityIds=${formData.entityRefDetails.entityId}&plotIds=${formData.entityRefDetails.plotId}&applicationNos=${applicationNumber}`,
                    { RequestInfo: requestInfo() }
                );
                setRelatedApplicationDocument(relatedApplicationDocument);
            } catch (e) {
                console.log(e);
            }
        };
        formData && getCorrespondenceDocument();
    }, [nextValidAction, formData]);

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const { values, setValues } = formik;

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const handleModalClose = (type: string, finalSubmit?: true) => {
        setModalDetails({});
        type === 'success' && finalSubmit && navigate('/goa-idc/dashboard');
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs.documents);
            setDocumentsUuid([...documentsUuid, docs.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    const onChangePrevIndex = () => {
        if (activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const onCancel = () => {
        navigate(-1);
    };

    const updateNotes = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            ...formData,
            appliedPlotId: formData?.appliedPlotDetails.uuid,
            assignedPlotId: formData?.assignedPlotDetails?.uuid || values.allotedPlotId,
            content: content
        };

        try {
            const { data } = await POST(endpoints.updateAllotment, payload);
            setContent(data.estateApplications[0].content);

            setModalDetails({ show: true, title: 'Success!', para: t('DATA_SAVED_SUCCESSFULLY', 'Data saved successfully'), img: check, type: 'success', reUpload: 'OK' });
        } catch (err: any) {
            let errMsg = err.response.data?.errorMessage || err.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const action: any = searchParams.get('action');

    useEffect(() => {
        if (note && action && formData && nextValidAction) {
            onHandlePrimaryAction({ action: action }, '');
        }
    }, [formData, nextValidAction]);

    const esign = async (payload: any, action: string) => {
        const userRequestInfo = requestInfo();
        try {
            let approveEsignRes = await POST(endpoints.esignApprove, payload);
            const refId = approveEsignRes.data.entityRefDocuments[0].docReferenceId;
            const approvedEsignData = approveEsignRes.data.entityRefDocuments.map((item: any) => `${item.uuid}=${item.docReferenceId}`);

            console.log('refId', refId, approveEsignRes.data.entityRefDocuments, 'data', approvedEsignData);

            // return;

            if (window.location.protocol !== 'http:') {
                let url =
                    window.location.origin +
                    `/goa-idc/estate/cancellation-workflow?${searchParams.toString()}&note=${values.notes}&docsUuid=${documentsUuid?.join(',')}&approvedEsign=${approvedEsignData.join(
                        ','
                    )}&action=${action}`;
                url = btoa(url);
                const esignUrl = `${devUrl}${endpoints.esignInitiate}?authType=1&callBackUrl=${url}&fileStoreId=${refId}&accessToken=${userRequestInfo.authToken}&userType=${userRequestInfo.userType}${
                    userRequestInfo.departmentCode ? `&departmentCode=${userRequestInfo.departmentCode}` : ''
                }`;
                await axios.get(esignUrl);

                window.open(esignUrl, '_self');
            } else {
                let url =
                    window.location.origin +
                    `/goa-idc/estate/cancellation-workflow?${searchParams.toString()}&signedFileStoreId=${refId}&note=${values.notes}&docsUuid=${documentsUuid?.join(
                        ','
                    )}&approvedEsign=${approvedEsignData.join(',')}&action=${action}`;
                console.log('url', url, documentsUuid);
                window.open(url, '_self');
            }
        } catch (e) {
            console.log('esign error', e);
        }
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            estateApplicationRequest: {
                ...formData,
                appliedPlotId: formData?.appliedPlotDetails.uuid,
                assignedPlotId: formData?.assignedPlotDetails?.uuid || values.allotedPlotId,
                content: content
            },
            signedDocDetails:
                approvedEsign?.split(',').map((item: any) => {
                    const esignData = item.split('=');
                    return { [`${esignData[0]}`]: `${signedFileStoreId}` };
                }) || null
        };

        if (!values.notes) {
            if (!comment) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('ENTER_NOTES', 'Please enter notes!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }

        if (designation === 'MD' && !signedFileStoreId && status.isEsignRequired) {
            esign(payload, status.action);
            return;
        }

        try {
            await POST(endpoints.estateWorkflowInitiate, payload);
            const successMessage = getSuccessMessage(status?.action, applicationNumber);
            setModalDetails({ show: true, title: 'Success!', para: successMessage, img: check, type: 'success', reUpload: 'OK', finalSubmit: true });
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={t('EXTENSION', 'Application for Cancellation')}
                            additionalClass='sub-text-style'
                            bottomSubText={`${t('File', `File : ${formData?.enterpriseEntity.entityName}`)},  ${t('Type', `Type : Cancellation`)} ${
                                applicationNumber ? t('', `, No : ${applicationNumber}`) : ''
                            }`}
                        />
                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('EXTENSION', 'Application for Cancellation')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : Cancellation, No : ${applicationNumber}`}
                                    />
                                    <NotingWrapper
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={!nextValidAction?.nextValidAction.length}
                                        handleOnFileUpload={handleOnFileUpload}
                                        designation={designation}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />

                                <Form>
                                    {activeIndex === 1 && (
                                        <Card className='card_border p-1'>
                                            <CancellationCorrespondence content={content} setContent={setContent} formData={formData} />
                                        </Card>
                                    )}
                                    {activeIndex === 2 && (
                                        <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                    )}
                                    {activeIndex === 3 && (
                                        <GenericActionTable
                                            columns={CORRESPONDENCE_TABLE_APP_FORM}
                                            hideSearchBar={true}
                                            rowData={relatedApplicationDocument?.data.applicationInfos}
                                            additionalMethod={onRowClickRedirect}
                                        />
                                    )}
                                </Form>
                            </div>
                        </FormikProvider>
                        <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                            <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === 3 && `rotate-class`}`} />
                            </span>
                            Page {activeIndex} of {totalPages}
                            <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                            </span>
                        </div>
                    </div>
                    <ScrollToTop />
                    <GenericFooterComponent
                        onCancel={onCancel}
                        onClickPrev={onChangePrevIndex}
                        activeIndex={activeIndex}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={() => {}}
                        applicationNo={applicationNumber || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                        extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                        extraButtonAction={updateNotes}
                    />
                </>
            )}

            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                        {!isPDF && <GenericImageViewer />}
                    </div>
                </GenericPopupModal>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type, modalDetails.finalSubmit)}
            />
        </>
    );
}
