import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HeaderComponent from '../../components/header/Header.component';
import { FormikProvider, useFormik } from 'formik';
import { Card } from 'react-bootstrap';
import GenericCommonSelector from '../../components/moduleCommonFormik/GenericCommonSelector.component';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { requestInfo } from '../../services/modulesServices/userManagement.api';
import axios from 'axios';
import { BASE_URL_LOCAL, EMUDRA_LOCALHOST_URL, TENANT_ID } from '../../utils/constants';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { DSC_REGISTRATION_FIELDS } from '../modules/EstateManagement/DSCRegistration/DscRegistration.const';
import { useTranslation } from 'react-i18next';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import Loading from '../../components/portalComponents/Loading.component';

export const EmudraEsignPage = () => {
    const [searchParams] = useSearchParams();
    const fileStoreId = searchParams.get('fileStoreId');
    const callbackUrl: any = searchParams.get('callbackUrl');
    let url = atob(callbackUrl);

    let navigate = useNavigate();
    let { t } = useTranslation();
    console.log(fileStoreId);

    const [dropdownData, setDropdownData] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            keyStorePassPhrase: ''
        },
        onSubmit: () => {}
    });
    const { values, setValues, errors, setFieldValue, setFieldTouched, setTouched } = formik;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const tokenInput = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_getTokenInput', { RequestInfo: requestInfo(), tenantId: TENANT_ID, responseData: '' });
                console.log(tokenInput);
                const listToken = await axios.post(EMUDRA_LOCALHOST_URL + '/DSC/ListToken', {
                    encryptionKeyId: tokenInput.data.input.encryptionKeyId,
                    encryptedRequest: tokenInput.data.input.encryptedRequest
                });
                const getTokens = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_getTokens', {
                    RequestInfo: requestInfo(),
                    tenantId: TENANT_ID,
                    responseData: listToken.data.responseData
                });
                const tokenDropdown = getTokens.data.tokens.map((item: any) => ({ code: item, name: item }));
                setDropdownData({ ...dropdownData, token: tokenDropdown });
                setIsLoading(false);
            } catch (err: any) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const onChange = async (e: any, field: string) => {
        try {
            setIsLoading(true);
            if (field === 'token') {
                setIsLoading(true);
                const getInputCertificate = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_getInputCertificate', {
                    RequestInfo: requestInfo(),
                    tokenDisplayName: values.token?.code,
                    responseData: ''
                });
                const listCertificate = await axios.post(EMUDRA_LOCALHOST_URL + '/DSC/ListCertificate', {
                    encryptionKeyId: getInputCertificate.data.input.encryptionKeyId,
                    encryptedRequest: getInputCertificate.data.input.encryptedRequest
                });
                const getCerticate = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_getCertificate', {
                    RequestInfo: requestInfo(),
                    responseData: listCertificate.data.responseData,
                    tokenDisplayName: values.token?.code
                });
                const certifcateDropdown = getCerticate.data.certificates.map((item: any) => ({ code: item.keyId, name: item.commonName, certificateDate: item.certificateDate }));
                setDropdownData({ ...dropdownData, certificate: certifcateDropdown });
            }
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    };

    const handleOnClick = async () => {
        try {
            setIsLoading(true);
            const pdfSignInput = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_pdfSignInput', {
                RequestInfo: requestInfo(),
                tokenDisplayName: values.token?.code,
                keyStorePassPhrase: values.keyStorePassPhrase || '',
                keyId: values.certificate.code,
                file: fileStoreId,
                fileName: 'test_2.pdf',
                tenantId: TENANT_ID
            });
            const pkcsBulkSign = await axios.post(EMUDRA_LOCALHOST_URL + '/DSC/PKCSBulkSign', {
                encryptionKeyId: pdfSignInput.data.input.encryptionKeyId,
                encryptedRequest: pdfSignInput.data.input.encryptedRequest
            });
            const pdfSign = await axios.post(BASE_URL_LOCAL + '/dsc-services/dsc/_pdfSign', {
                RequestInfo: requestInfo(),
                responseData: pkcsBulkSign.data.responseData,
                tempFilePath: pdfSignInput.data.input.tempFilePath,
                tenantId: TENANT_ID,
                moduleName: 'esign'
            });
            setIsLoading(false);
            if (pdfSign.data.responseString === 'Success') {
                window.location.href = url + `&signedFileStoreId=${pdfSign?.data?.fileStoreId}&success=true`;
            } else {
                window.location.href = url + `&signedFileStoreId=${pdfSign?.data?.fileStoreId}&success=false`;
            }
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            // window.location.href = url + `&signedFileStoreId=null&success=false`;
        }
    };

    console.log(formik);

    return (
        <>
            {isLoading ? <Loading /> : <></>}
            <HeaderComponent />
            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <GenericHeader text={t('SIGN', 'Sign')} additionalClass='sub-text-style' />
                <div className='col-12 form-section'>
                    <FormikProvider value={formik}>
                        <Card className='card_border'>
                            <Card.Body>
                                {/* <div className='row d-flex'>
							<div className='col-sm-6'>
								<GenericCommonSelector field='token' title='Token' dropdownOptions={dropdownData} customHandleChange={onChange} />
							</div>
							<div className='col-sm-6'>
								<GenericCommonSelector field='certificate' title='Certificate' dropdownOptions={dropdownData} customHandleChange={onChange} />
							</div>
						</div>
						<div className='d-flex justify-content-end mt-3'>
							<GenericButtons text='Esign' handleClick={handleOnClick} />
						</div> */}
                                {DSC_REGISTRATION_FIELDS?.map((val: any) => (
                                    <GenericFormCard {...val} dropdownOptions={dropdownData} customHandleChange={onChange} />
                                ))}
                            </Card.Body>
                        </Card>
                    </FormikProvider>
                </div>
                <footer className='footer fixed-bottom footerBtnPatch'>
                    <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                        <GenericButtons text={t('SIGN', 'Sign')} disabled={!values.certificate} variant='primary' handleClick={handleOnClick} />
                    </div>
                </footer>
            </div>
        </>
    );
};
