import { useTranslation } from 'react-i18next';
import HeaderComponent from '../../../../components/header/Header.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import Loading from '../../../../components/portalComponents/Loading.component';
import PaymentDetails from '../Allotment/PaymentDetails';
import React, { useState, useEffect } from 'react';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { convertToINR, fetchFeeData, fetchGatewayData, getOptions, getQueryParams, loadRazorpay, paymentCheck, paymentPayload } from '../EstateManagement.controller';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { POST, POST2, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { RazorpayOptions } from '../../UserManagement/types';
import { check, cross } from '../../../../utils/Logo';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getFormatedPaymentData } from '../Payment/EstatePayment.const';
import { Card } from 'react-bootstrap';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { subleaseOrderSteps } from './SubLeaseConst';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import { KEY_ID, WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY } from '../../../../utils/constants';

export const SubleaseOrder = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const location: any = useLocation();

    const applicationNumber: any = searchParams.get('applicationNumber') || null;
    const plotId: any = searchParams.get('plotId') || null;
    const applicationId: any = searchParams.get('applicationId') || null;
    const docRefId: any = searchParams.get('docRefId') || null;
    const entityId: any = searchParams.get('entityId') || null;
    const type: any = searchParams.get('allotmentType') || null;

    const getUser = JSON.parse(localStorage.getItem('user') || '{}');
    // const userId = getUser?.entityId;
    // const userId = '4ca62690-cadc-48df-a538-06e056664bf4';

    const [isPaid, setIsPaid] = useState<any>(false);
    const [paymentTried, setPaymentTried] = useState<any>(false);
    const [paymentData, setPaymentData] = useState<any>([]);
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [selectedGateway, setSelectedGateway] = useState<any>(null);
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    const [transactionDetails, setTransactionDetails] = useState<any>({});
    const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [activeIndex, setActiveIndex] = useState(1);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [footerButtons, setFooterButtons] = useState<any>(null);
    const [stepDetails, setStepDetails] = useState<any>(subleaseOrderSteps);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const queryParams = getQueryParams(searchParams);
            if (applicationNumber && plotId && queryParams.eg_pg_txnid) {
                setPaymentIsLoading(true);
                getTransactionUpdate(queryParams);
            }

            if (+queryParams?.['activeIndex'] === 2) {
                setActiveIndex(2);
            }

            if (applicationId) {
                try {
                    const { data: documentValidAction } = applicationNumber && (await POST(endpoints.allotmentActionSearch + `?allotmentId=${applicationNumber}`, { RequestInfo: requestInfo() }));
                    setHasValidAction(documentValidAction?.validActions?.length > 0);
                    setFooterButtons(documentValidAction);
                    documentValidAction?.validActions?.length &&
                        setStepDetails([
                            ...stepDetails,
                            {
                                titleCode: 'PAYMENT_DETAILS',
                                name: 'Payment Details',
                                index: 2,
                                isActive: false
                            }
                        ]);
                } catch (e: any) {
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }

            setIsLoading(false);
        })();
    }, [location.search]);

    useEffect(() => {
        loadRazorpay();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (plotId) {
                    const payCheck = await paymentCheck('SUBLEASE_FEE', plotId, applicationId, entityId);
                    setIsPaid(payCheck?.isPaid);
                    setPaymentTried(payCheck?.paymentTried);
                    setPaymentData(payCheck?.paymentData);
                    setTotalPaidAmount(payCheck?.totalPaidAmount);
                    setTransactionDetails(payCheck?.transactionDetails);

                    const gateway = await fetchGatewayData();
                    setGatewayData(gateway);

                    // const feeData = await fetchFeeData('SUB_LEASE', `PROCESSING_FEE`, plotId);
                    // setPaymentData(feeData?.feeMasterDetails);
                    // setTotalPaidAmount(feeData?.totalPaidAmount);

                    const { data } = await POST(endpoints.scheduledPaymentDetailsSearch + `?paymentTypes=SUBLEASE_FEE&plotIds=${plotId}&entityIds=${entityId}&applicationIds=${applicationId}`, {
                        RequestInfo: requestInfo()
                    });
                    if (data.paymentDetailsResponse.length) {
                        let feeMasterDetails = data.paymentDetailsResponse.map((obj: any, i: any) => {
                            return {
                                ...obj,
                                feeTypeDisplayName: obj.paymentTypeDisplayName,
                                cgstAmount: +obj.cgstPrincipal === 0 ? '--' : convertToINR(obj.cgstPrincipal),
                                sgstAmount: +obj.sgstPrincipal === 0 ? '--' : convertToINR(obj.sgstPrincipal),
                                amount: convertToINR(obj.principalAmount),
                                total: convertToINR(obj.totalDueAmount),
                                index: i + 1
                            };
                        });
                        setPaymentData(feeMasterDetails || []);
                        setTotalPaidAmount(data?.paymentDetailsResponse?.[0]?.totalDueAmount);
                    }

                    setIsLoading(false);
                    setPaymentIsLoading(false);
                }
            } catch (e: any) {
                console.log(e);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                setPaymentIsLoading(false);
            }
        })();
    }, [plotId]);

    const getTransactionUpdate = async (params: any) => {
        try {
            const paid: any = await paymentCheck('SUBLEASE_FEE', plotId, applicationId, entityId);
            if (!paid.isPaid) {
                setIsLoading(true);
                const payload = { RequestInfo: requestInfo() };
                const { data } = await POST2(endpoints.transactionUpdate, payload, { eg_pg_txnid: params.eg_pg_txnid });
                if (data?.Transaction?.[0]?.txnStatus === 'SUCCESS') {
                    setTransactionDetails(data?.Transaction?.[0]);
                    if (data?.Transaction?.[0]?.txnId) {
                        try {
                            const resp = await POST(endpoints.paymentDetails + `?eg_pg_txnid=${data?.Transaction?.[0]?.txnId}`, payload);
                            if (resp.data.status === 'SUCCESSFUL') {
                                setModalDetails({ show: true, title: 'Success!', para: 'Payment successful', img: check, type: 'sucess', reUpload: 'OK' });
                                setIsPaid(true);
                            } else setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        } catch (e: any) {
                            setIsPaid(false);
                            setIsLoading(false);
                            setPaymentIsLoading(false);
                        }
                    }
                    setIsLoading(false);
                    setPaymentIsLoading(false);
                } else if (data?.Transaction?.[0]?.txnStatus === 'PENDING') {
                    setModalDetails({
                        show: true,
                        title: t('TRANSACTION_PENDING', 'Transaction Pending'),
                        para: t('PAYMENT_STATUS_PENDING_MSG', 'Your payment is under process; please revisit later for updates.'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    // window.location.reload();
                }
                setIsLoading(false);
                setPaymentIsLoading(false);
            }
        } catch (e: any) {
            console.log(e);
            setIsPaid(false);
            setIsLoading(false);
            setPaymentIsLoading(false);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Transaction Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onPay = async () => {
        setIsLoading(true);
        let url =
            window.location.origin +
            `/goa-idc/estate/sublease-order?plotId=${plotId}&entityId=${entityId}&activeIndex=${activeIndex}&applicationNumber=${applicationNumber}&applicationId=${applicationId}`;
        const periodTo = paymentData[0]?.dueDate ? new Date(paymentData[0]?.dueDate).setFullYear(new Date(paymentData[0]?.dueDate).getFullYear() + 1) : null;
        const periodFrom = paymentData[0]?.dueDate ? new Date(paymentData[0]?.dueDate).getTime() : null;

        const payload = paymentPayload({ uuid: applicationId, applicationNo: applicationNumber }, plotId, totalPaidAmount, url, 'Sublease Order', selectedGateway, periodFrom, periodTo, paymentData);

        try {
            if (!totalPaidAmount && activeIndex === 2) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Total amount must be greater than zero', img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
            if (!selectedGateway && activeIndex === 2) {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: 'Please select a payment gateway', img: cross, type: 'error', reUpload: 'OK' });

                return;
            }
            if (applicationNumber && selectedGateway && totalPaidAmount) {
                const { data } = await POST(endpoints.paymentGateway, payload);

                if (selectedGateway === 'HDFC' || selectedGateway === 'AXIS') {
                    const options: RazorpayOptions = getOptions(data.txnAmount, getUser, 'description', data?.additionalDetails.ORDER_ID, data.callbackUrl, KEY_ID[selectedGateway]);
                    console.log(options);
                    const rzp1: any = (window as any).Razorpay(options);
                    rzp1.open();
                } else if (selectedGateway === 'ICICI') {
                    navigate(
                        `/goa-idc/icici-payment?walletClientCode=${data.additionalDetails?.walletClientCode}&walletRequestMessage=${data.additionalDetails[WALLET_REQUEST_MESSAGE_MAKE_PAYMENT_KEY]}`
                    );
                } else if (data && data.redirectUrl) {
                    window.open(data.redirectUrl, '_self');
                }
                setIsLoading(false);
            }
        } catch (e: any) {
            console.log(e);
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setIsLoading(false);
            setModalDetails({ show: true, title: 'Payment Initiation Failed', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onInitiateSublease = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            applicationType: 'SUB_LEASE',
            appliedPlotId: plotId,
            parentOrderNumber: applicationNumber
        };
        try {
            const { data } = await POST(endpoints.estateApplicationCreate, payload);
            setModalDetails({ show: true, title: 'Success', para: t('SUBLEASE_INITIATED_SUCCESSFULLY', 'Sublease Initiated Successfully'), img: check, type: 'success', reUpload: 'OK' });
        } catch (e) {
            console.log(e);
            setModalDetails({ show: true, title: 'Error!', para: 'Sublease Order Initiation failed', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handleModalClose = (type: string) => {
        setModalDetails({ show: false });
        if (type === 'success') {
            navigate('/goa-idc/enterprisedashboard');
        }
    };

    const handleStepClick = async (index: number) => {
        if (index > activeIndex) {
            try {
                setActiveIndex(index);
                window.scrollTo(0, 0);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
            }
        } else setActiveIndex(index);
    };

    const onClose = () => {
        navigate('/goa-idc/enterprisedashboard');
    };

    const handleButtonClick = (btn: string) => {
        if (btn === 'TIMELINE_EXTENSION') {
            navigate('/goa-idc/estate/extension', { state: { plotId: plotId, type: type } });
        } else if (btn === 'PROCEED_TO_PAY') {
            setActiveIndex(activeIndex + 1);
        }
    };

    console.log('paymentData', paymentData);

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <HeaderComponent />
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={'Sublease Order'} additionalClass='sub-text-style' bottomSubText={applicationNumber ? `Type : Sublease Order, No : ${applicationNumber}` : ''} />
                        <div className='col-3 stickyCard-section font-inter'>
                            <div className='stickyCard'>
                                <GenericHeader
                                    text={t(`SUBLEASE_ORDER`, `Sublease Order`)}
                                    sideHeader={true}
                                    additionalClass='sub-text-style'
                                    bottomSubText={`Type : Sublease Order, No : ${applicationNumber}`}
                                />
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column row-gap-3  '>
                                            <SideCard steps={stepDetails} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-9 form-section'>
                            {activeIndex === 1 && (
                                <Card className='card_border'>{<Card.Body>{!docRefId ? <Loading /> : <GenericPDFViewer fileStoreId={docRefId} documentName={'documentName'} />}</Card.Body>}</Card>
                            )}
                            {activeIndex === 2 &&
                                (!paymentIsLoading ? (
                                    <PaymentDetails
                                        paymentData={paymentData}
                                        selectedGateway={selectedGateway}
                                        setSelectedGateway={setSelectedGateway}
                                        isPaid={isPaid}
                                        gatewayData={gatewayData}
                                        transactionDetails={transactionDetails}
                                        title={t('SUBLEASE_ORDER_FEES', 'Sublease Order Fees')}
                                    ></PaymentDetails>
                                ) : (
                                    <Loading />
                                ))}
                        </div>
                    </div>
                    <div className='position-fixed bottom-0 py-3 bg-white w-100 border border-1 d-flex justify-content-end pe-5'>
                        {hasValidAction ? (
                            isPaid ? (
                                <GenericButtons text={t('INITIATE_SUBMIT', 'Initiate Sublease')} variant='primary' handleClick={onInitiateSublease} />
                            ) : (
                                <>
                                    {footerButtons?.validActions?.map((btn: string) => (
                                        <>
                                            {activeIndex === 1 && <GenericButtons text={t(btn)} handleClick={() => handleButtonClick(btn)} />}
                                            {activeIndex === 2 && btn !== 'PROCEED_TO_PAY' && <GenericButtons text={t(btn)} handleClick={() => handleButtonClick(btn)} />}
                                        </>
                                    ))}
                                    {activeIndex === 2 && <GenericButtons text={isPaid === null ? 'Loading...' : paymentTried ? `Retry Payment` : `Pay Now`} variant='primary' handleClick={onPay} />}
                                </>
                            )
                        ) : (
                            <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onClose} />
                        )}
                        {/* {activeIndex === 1 && <GenericButtons text={t('NEXT', 'Next')} variant='primary' handleClick={() => setActiveIndex(2)} />}
                        {activeIndex === 2 &&
                            (hasValidAction ? (
                                <GenericButtons
                                    text={isPaid === null ? 'Loading...' : isPaid ? t('INITIATE_SUBMIT', 'Initiate Sublease') : paymentTried ? `Retry Payment` : `Pay Now`}
                                    variant='primary'
                                    handleClick={!isPaid ? onPay : onInitiateSublease}
                                />
                            ) : (
                                <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onClose} />
                            ))} */}
                    </div>
                </>
            )}
            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => handleModalClose(modalDetails.type)}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose(modalDetails.type)}
            />
        </>
    );
};
