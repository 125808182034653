// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialFeeds_subText__217sq {
  color: rgba(255, 255, 255, 0.60);
}

.SocialFeeds_noMargin__OAF4C {
  margin: 0px;
}

.SocialFeeds_square__UnsiA {
  width: 33.33%;
  height: 145px;
  border: #fff 2px solid;
  padding: 5px !important;
}

.SocialFeeds_linked_square__-VQPN {
  width: 33.33%;
  height: 216px;
  border: #fff 2px solid;
  padding: 5px !important;
}

.SocialFeeds_square_main__s6Dfx {
  height: 100%;
  border: #fff 2px solid;
  padding: 0px !important;

}`, "",{"version":3,"sources":["webpack://./src/components/portalComponents/SocialFeeds.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,uBAAuB;;AAEzB","sourcesContent":[".subText {\n  color: rgba(255, 255, 255, 0.60);\n}\n\n.noMargin {\n  margin: 0px;\n}\n\n.square {\n  width: 33.33%;\n  height: 145px;\n  border: #fff 2px solid;\n  padding: 5px !important;\n}\n\n.linked_square {\n  width: 33.33%;\n  height: 216px;\n  border: #fff 2px solid;\n  padding: 5px !important;\n}\n\n.square_main {\n  height: 100%;\n  border: #fff 2px solid;\n  padding: 0px !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subText": `SocialFeeds_subText__217sq`,
	"noMargin": `SocialFeeds_noMargin__OAF4C`,
	"square": `SocialFeeds_square__UnsiA`,
	"linked_square": `SocialFeeds_linked_square__-VQPN`,
	"square_main": `SocialFeeds_square_main__s6Dfx`
};
export default ___CSS_LOADER_EXPORT___;
