import React, { useEffect, useState } from 'react';
import { Card, CardBody, Form } from 'react-bootstrap';
import { FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import { SURRENDER_CORRESPONDENCE } from '../../../EstateManagement/Surrender/Surrender.const';
import { assignment, EMPLOYEE_CORRESPONDENCE, qualifications } from './EmployeeRegistration.const';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import { ASSIGNMENT_DETAILS, DOCUMENTS_DETAILS, EDUCATION_DETAILS, PERSONAL_DETAILS, PROFESSIONAL_DETAILS } from '../../UserManagement.const';
import { handleUpdates } from '../../UserMangement.controller';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../UserManagement.scss';
import { trash } from '../../../../../utils/Logo';
import { getUserRoles } from '../../../../../services/modulesServices/userManagement.api';

const EmployeeRegistrationCorrespondence = (props: any) => {
    const { dropdownData, setDocuments, documents, setDropdownData } = props;
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userDesignationGM = getUserDetails.designation === 'GM';
    const location = useLocation();

    const [openSection, setOpenSection] = useState<string>('personalDetails');
    const [personalDetails, setPersonalDetails] = useState<any>(PERSONAL_DETAILS);
    const [rolesDropdownData, setRolesDropdownData] = useState<any>(null);

    const { values, setValues, touched, errors, setFieldValue } = useFormikContext<any>();

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };
    useEffect(() => {
        const updatedDetails = handleUpdates(values, personalDetails, PERSONAL_DETAILS, 'Present Address', 'Permanent Address');
        setPersonalDetails(updatedDetails);
    }, [values?.isPermanentPincodeDetailsPresent, values?.isPincodeDetailsPresent, values?.copyOnCheck]);

    const renderpersonalDetails = () => {
        return (
            <>
                {personalDetails.map((val: any) => (
                    <Card.Body>
                        <GenericFormCard {...val} dropdownOptions={dropdownData} />
                    </Card.Body>
                ))}
            </>
        );
    };

    const renderEducationDetails = () => {
        return (
            <>
                <Card.Body>
                    <form>
                        <FieldArray name='qualifications'>
                            {({ insert, remove, push }) => (
                                <div>
                                    {values.qualifications?.length > 0 &&
                                        values.qualifications.map((data: any, q_index: number) => (
                                            <div className='row' key={q_index}>
                                                <div className='col-sm-12 align-items-center pb-2'>
                                                    <div className=' ps-1'>
                                                        {q_index >= 0 && (
                                                            <div className='d-flex justify-content-end  mx-3'>
                                                                {!!values.isUpdatable && (
                                                                    <img
                                                                        style={{ cursor: 'pointer', zIndex: 1, background: '#45852E1F', padding: '6px', height: '35px', borderRadius: '6px' }}
                                                                        onClick={() => remove(q_index)}
                                                                        src={trash}
                                                                        alt='delete'
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='row d-flex m-0'>
                                                        {EDUCATION_DETAILS.map((val: any, _: number) => (
                                                            <GenericFormCard fieldPath={`qualifications.${q_index}`} {...val} dropdownOptions={dropdownData} />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    {!!values.isUpdatable && (
                                        <div className='ms-3'>
                                            <button className='col-sm-12 mt-2 mb-4  justify-content-center align-items-center noc_add_fields' type='button' onClick={() => push(qualifications)}>
                                                + Add Education Details
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </FieldArray>
                    </form>
                </Card.Body>
            </>
        );
    };

    const professionalDetails = () => {
        return (
            <>
                {PROFESSIONAL_DETAILS.map((val: any) => (
                    <Card.Body>
                        <GenericFormCard {...val} dropdownOptions={dropdownData} />
                    </Card.Body>
                ))}
            </>
        );
    };

    const renderAssignmentDetails = () => {
        return (
            <>
                <Card.Body>
                    <form>
                        <FieldArray name='assignment'>
                            {({ insert, remove, push }) => (
                                <div>
                                    {values.assignment?.length > 0 &&
                                        values.assignment.map((data: any, a_index: number) => (
                                            <div className='row' key={a_index}>
                                                <div className='col-sm-12 align-items-center pb-2'>
                                                    <div className=' ps-1'>
                                                        {a_index !== 0 && (
                                                            <div className='d-flex justify-content-end  mx-3'>
                                                                {!!values.isUpdatable && (
                                                                    <img
                                                                        style={{ cursor: 'pointer', zIndex: 1, background: '#45852E1F', padding: '6px', height: '35px', borderRadius: '6px' }}
                                                                        onClick={() => remove(a_index)}
                                                                        src={trash}
                                                                        alt='delete'
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='row d-flex m-0'>
                                                        {ASSIGNMENT_DETAILS.map((val: any, _: number) => (
                                                            <GenericFormCard
                                                                fieldPath={`assignment.${a_index}`}
                                                                {...val}
                                                                index={a_index}
                                                                dropdownOptions={dropdownData}
                                                                customHandleChange={(e: any, field: any) => {
                                                                    if (field === `assignment.${a_index}.department`) {
                                                                        getUserRoles(e?.value)
                                                                            .then(res => {
                                                                                setRolesDropdownData(res.data);
                                                                                setDropdownData((prev: any) => ({ ...prev, roles: res?.data?.roles }));
                                                                            })
                                                                            .catch(err => console.log(err));
                                                                    }
                                                                }}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    {!!values.isUpdatable && (
                                        <div className='ms-3'>
                                            <button className='col-sm-12 mt-2 mb-4  justify-content-center align-items-center noc_add_fields' type='button' onClick={() => push(assignment)}>
                                                + Add Assignment Details
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </FieldArray>
                    </form>
                </Card.Body>
            </>
        );
    };

    const documentsDetails = () => {
        return (
            <>
                {DOCUMENTS_DETAILS.map((val: any) => (
                    <Card.Body>
                        <GenericFormCard {...val} module='usermanagement' dropdownOptions={dropdownData} documents={documents} setDocuments={setDocuments} />
                    </Card.Body>
                ))}
            </>
        );
    };
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {/* {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                Condition/Check list
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )} */}

                    {EMPLOYEE_CORRESPONDENCE.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A - Personal Details' && openSection === 'personalDetails' && renderpersonalDetails()}
                                {val.title === 'B - Education Details' && openSection === 'educationDetails' && renderEducationDetails()}
                                {val.title === 'C - Professional Details' && openSection === 'professionalDetails' && professionalDetails()}
                                {val.title === 'D - Assignment Details' && openSection === 'assignmentDetails' && renderAssignmentDetails()}
                                {val.title === 'E - Documents' && openSection === 'documents' && documentsDetails()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default EmployeeRegistrationCorrespondence;
