import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Worklist from '../../../../components/moduleCommonFormik/WorklistCard';
import EarthImages from '../../../../assets/logos/estateEarth.svg';
import vector from '../../../../assets/logos/Vector (1).svg';
import scale from '../../../../assets/logos/Scales.svg';
import wrench from '../../../../assets/logos/Wrench.svg';
import admin from '../../../../assets/logos/UserGear.svg';
import arrowimage from '../../../../assets/logos/arrowright.svg';
import { HierarchyNode, IWorklistItems, IWorklistOptions } from '../types';
import { convertToHierarchyCard } from '../../../../utils/utils';
import { useTranslation } from 'react-i18next';

interface IDashboardSectionProps {
    dashboardDetails: { worklists?: any; cards: any; gidcWorklists?: any };
    setTableData: (data: any) => void;
    tableData: any;
}

export default function WorklistDashboard({ dashboardDetails, setTableData, tableData }: IDashboardSectionProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const worklist = dashboardDetails.worklists ? dashboardDetails.worklists : dashboardDetails.gidcWorklists;

    useEffect(() => {
        if (dashboardDetails) {
            const tableData = worklist.filter((data: any) => data.displayName === 'INBOX').map((item: any) => item.inboxs);
            dashboardDetails.worklists && setTableData(tableData.flat());
        }
    }, [dashboardDetails]);

    useEffect(() => {
        if (tableData?.length) {
            const scrollPosition = window.scrollY + 700;
            window.scrollTo(0, scrollPosition);
        }
    }, [tableData]);

    const cardHierarchicalArray: HierarchyNode[] = worklist && convertToHierarchyCard(worklist);

    const handleOnInnerCardClick = (cardData: IWorklistItems) => {
        setTableData(cardData.inboxs);
    };

    const handleOnOptionClick = (item: IWorklistOptions) => {
        // let module = item.path.split('.')[0]
        if (item.displayName === 'SEARCH_APPLICATIONS') {
            navigate('/goa-idc/usermanagement/searchapplication', { state: { module: item.moduleName } });
        }

        if (item.displayName === 'INBOX') {
            setTableData(item.inboxs);
        }
    };

    // Map displayName or id to the corresponding logo
    const logoMapping: { [key: string]: string } = {
        ESTD: EarthImages,
        OBPAS: vector,
        LEGAL: scale,
        ENGINEERING: wrench,
        ADMINISTRATION: admin
    };

    return (
        <>
            <div className='row gx-0 justify-content-lg-between gx-lg-0 gx-1 gy-3 pt-4 font-inter'>
                <h3 className='py-3 px-0 fw-bold'>{t('MY_DESK', 'My Desk')}</h3>
                {cardHierarchicalArray?.map((item: any, index: number) => {
                    const worklistItems: IWorklistItems[] = item && item.children?.filter((item: IWorklistItems) => item.code === 'worklist');
                    const worklistOptionItems: IWorklistItems[] = item && item.children?.filter((item: IWorklistItems) => item.code === 'worklist_option');
                    // Access the corresponding logo for each displayName or id
                    const logo = logoMapping[item.id] || EarthImages;
                    return (
                        <Worklist
                            heading={item.displayName}
                            logo1={logo}
                            children={worklistItems.map((cardData: IWorklistItems, ind: number) => (
                                <div className={`col-sm-5 py-3 innercard${index + 1}${ind + 1}`} key={index} onClick={() => handleOnInnerCardClick(cardData)}>
                                    <h4 className=''>{cardData.count}</h4>
                                    <p className='m-0' style={`${index + 1}${ind + 1}` === '11' || `${index + 1}${ind + 1}` === '12' ? { color: '#45852e' } : { color: item.backgroundColor }}>
                                        {t(cardData.displayName)}
                                    </p>
                                </div>
                            ))}
                            footer={worklistOptionItems.map((item: IWorklistOptions, id: number) => (
                                <span
                                    className='py-1 px-2 rounded-pill footer cursor-pointer'
                                    style={
                                        `${index + 1}${id + 1}` === '11' || `${index + 1}${id + 1}` === '12'
                                            ? { color: '#45852e', backgroundColor: '#edf4eb', fontSize: '1ren' }
                                            : { color: item.backgroundColor, backgroundColor: '#fff', fontSize: '1ren' }
                                    }
                                    onClick={() => handleOnOptionClick(item)}
                                >
                                    {t(item.displayName)}
                                    <img src={svgToDataUrl(index === 0 ? '#45852e' : item.backgroundColor)} className='Earrow ms-2' alt='' />
                                </span>
                            ))}
                            cardStyle={item.backgroundColor}
                            headingColor={`heading-color-${index + 1}`}
                        />
                    );
                })}
            </div>
        </>
    );
}

const svgToDataUrl = (color: any) => `
  data:image/svg+xml;base64,${btoa(`
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25 8V21C25 21.2652 24.8947 21.5196 24.7071 21.7071C24.5196 21.8946 24.2652 22 24 22C23.7348 22 23.4804 21.8946 23.2929 21.7071C23.1054 21.5196 23 21.2652 23 21V10.4137L8.70751 24.7075C8.51987 24.8951 8.26537 25.0006 8.00001 25.0006C7.73464 25.0006 7.48015 24.8951 7.29251 24.7075C7.10487 24.5199 6.99945 24.2654 6.99945 24C6.99945 23.7346 7.10487 23.4801 7.29251 23.2925L21.5863 9H11C10.7348 9 10.4804 8.89464 10.2929 8.70711C10.1054 8.51957 10 8.26522 10 8C10 7.73478 10.1054 7.48043 10.2929 7.29289C10.4804 7.10536 10.7348 7 11 7H24C24.2652 7 24.5196 7.10536 24.7071 7.29289C24.8947 7.48043 25 7.73478 25 8Z'
        fill='${color}'
      />
    </svg>
  `)}
`;
