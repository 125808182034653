// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiddleBar_subText__LjgaZ {
    color: rgba(255, 255, 255, 0.60);
}

.MiddleBar_cardTitle_1__-LtuD {
    color: #B7CF53;
    font-size: 3.6rem;
}

.MiddleBar_cardTitle_2__gTE34 {
    color: #80B036;
    font-size: 3.6rem;
}

.MiddleBar_cardTitle_3__xzW6H {
    color: #45852E;
    font-size: 3.6rem;
}

.MiddleBar_cardTitle_4__A29Ux {
    color: #323E4A;
    font-size: 3.6rem;
}

.MiddleBar_cardSubText__sAkwb {
    color: #404040;
}

.MiddleBar_cardSubText_1__T5q2L {
    color: #6A6a6a;
    font-size: 0.9rem;
}

.MiddleBar_cardHover__dTJxP:hover {
    background-color: #fff;
    border-top: #B7CF53 5px solid;
}`, "",{"version":3,"sources":["webpack://./src/components/portalComponents/MiddleBar.module.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,6BAA6B;AACjC","sourcesContent":[".subText {\n    color: rgba(255, 255, 255, 0.60);\n}\n\n.cardTitle_1 {\n    color: #B7CF53;\n    font-size: 3.6rem;\n}\n\n.cardTitle_2 {\n    color: #80B036;\n    font-size: 3.6rem;\n}\n\n.cardTitle_3 {\n    color: #45852E;\n    font-size: 3.6rem;\n}\n\n.cardTitle_4 {\n    color: #323E4A;\n    font-size: 3.6rem;\n}\n\n.cardSubText {\n    color: #404040;\n}\n\n.cardSubText_1 {\n    color: #6A6a6a;\n    font-size: 0.9rem;\n}\n\n.cardHover:hover {\n    background-color: #fff;\n    border-top: #B7CF53 5px solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subText": `MiddleBar_subText__LjgaZ`,
	"cardTitle_1": `MiddleBar_cardTitle_1__-LtuD`,
	"cardTitle_2": `MiddleBar_cardTitle_2__gTE34`,
	"cardTitle_3": `MiddleBar_cardTitle_3__xzW6H`,
	"cardTitle_4": `MiddleBar_cardTitle_4__A29Ux`,
	"cardSubText": `MiddleBar_cardSubText__sAkwb`,
	"cardSubText_1": `MiddleBar_cardSubText_1__T5q2L`,
	"cardHover": `MiddleBar_cardHover__dTJxP`
};
export default ___CSS_LOADER_EXPORT___;
