import React, { useEffect } from 'react';
import './GenericStyles.scss';
import DeleteIcon from '../../assets/logos/delete.svg';
import { formFieldDTO } from './GenericFormCard.constants';
import GenericCommonTextField from './GenericCommonTextField.component';
import GenericFileUploadField from './GenericFileUpload.component';
import GenericCommonSelector from './GenericCommonSelector.component';
import GenericCommonDatePicker from './GenericCommonDatePicker.component';
import GenericCommonMultiSelector from './GenericCommonMultiSelector.component';
import { useFormikContext } from 'formik';
import GenericCheckboxField from './GenericCheckboxField.component';
import BpaCommonSelector from './BpaCommonSelector.component';
import GenericCommonTextReadOnlyField from './GenericCommonTextReadOnlyField.component';
import GenericCommonTextAreaField from './GenericCommonTextAreaField.component';
import GenericCommonRadioField from './GenericCommonRadioField.component';
import { useTranslation } from 'react-i18next';
import editIcon from '../../assets/logos/editDetails.svg';
import closeIcon from '../../assets/logos/closeEdit.svg';
import GenericCommonTimePicker from './GenericCommonTimePicker.component';
import _ from 'lodash';
import { setDataFromPrefix } from '../../utils/utils';
import GenericSelectorWithSearch from './GenericSelectorWithSearch';
import GenericSingleFileUploadField from './genericSingleFileUpload/GenericSingleFileUpload.subComponent';
import GenericFileUploadFieldMulti from './genericMultiFileUpload/GenericMultiFileUpload.component';
import ToggleSwitch from './GenericToggleSwitch.component';
import { dropdownDataOptions } from '../../features/modules/Migration/Migration.controller';
import ToolTipDetails from '../ToolTipFile';

interface genericFormProps {
    heading?: any;
    titleCode?: any;
    title: string;
    formData: formFieldDTO[];
    isReadOnly?: boolean;
    isSameAsAbove?: boolean;
    dropdownOptions?: any;
    setDocuments?: (e: any) => void;
    documents?: Array<string>;
    index?: any;
    handleDropdownChange?: (name: string, e: any, handleFormikChange: any) => void;
    handleOnAssignmentChange?: (index: number, field: string, value: any) => void;
    customHandleChange?: (e: any, field: any) => void;
    additionalSection?: string;
    module?: string;
    additionalData?: any;
    isEditable?: boolean;
    handleOnEdit?: any;
    enabledSection?: any;
    type?: any;
    styles?: any;
    hideAddressCheckbox?: boolean;
    fieldPath?: string;
    addressPath?: string;
    onDeleteAction?: any;
    onChangeAction?: (e: any, field: string) => void;
    // onRadioAction?: (e: any, field: string) => void;
    toolTipDetails?: any;
    onChangeRadioAction?: (e: any, field: any) => void;
}

export default function GenericFormCard(props: genericFormProps) {
    const { t } = useTranslation();
    const { values, setValues, handleChange } = useFormikContext<any>();
    const { fieldPath, addressPath = '', index, onChangeRadioAction } = props;

    const handleCheckboxChange = (e: any) => {
        let newValues;
        if (e.target.checked) {
            const propertiesToSet = {
                peraddress1: (addressPath ? _.get(values, addressPath + '.address') : values).address1,
                peraddress2: (addressPath ? _.get(values, addressPath + '.address') : values).address2,
                percountry: (addressPath ? _.get(values, addressPath + '.address') : values).country,
                perstate: (addressPath ? _.get(values, addressPath + '.address') : values).state,
                perdistrict: (addressPath ? _.get(values, addressPath + '.address') : values).district,
                perpincode: (addressPath ? _.get(values, addressPath + '.address') : values).pincode
            };
            newValues = setDataFromPrefix(propertiesToSet, addressPath ? addressPath + '.permanantAddress.' : '', values);
        } else {
            const propertiesToSet = {
                peraddress1: '',
                peraddress2: '',
                percountry: '',
                perstate: '',
                perdistrict: '',
                perpincode: ''
            };
            newValues = setDataFromPrefix(propertiesToSet, addressPath ? addressPath + '.permanantAddress.' : '', values);
        }
        setValues({ ...newValues, isPermanentPincodeDetailsPresent: values?.isPincodeDetailsPresent });
        handleChange(e);
    };

    return (
        <div className='card border-0 font-inter ' style={props?.styles}>
            {!!props?.heading && (
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='formHeading'>{props?.heading}</div>
                    {props?.isEditable &&
                        (props.enabledSection.includes(props.type) ? (
                            <span className='close me-3 d-flex align-items-center' onClick={() => props.handleOnEdit(props.type, false)}>
                                <img src={closeIcon} alt='close' className='me-1' /> Close
                            </span>
                        ) : (
                            <span className='edit me-3 pe-2 d-flex align-items-center' onClick={() => props.handleOnEdit(props.type, true)}>
                                <img src={editIcon} alt='edit' className='me-1' /> Edit
                            </span>
                        ))}
                </div>
            )}
            {t(props?.titleCode, props?.title) && (
                <div className='ps-2 py-2 d-flex justify-content-between'>
                    <div className='formTitle m-0 d-flex align-items-center '>
                        {props.titleCode ? t(props.titleCode) : props.title}
                        {props?.toolTipDetails && <ToolTipDetails toolTipDetails={props?.toolTipDetails} />}
                    </div>
                    {props.isSameAsAbove && values.isUpdatable && !props.hideAddressCheckbox && (
                        <label className='d-flex align-items-center'>
                            <input
                                type='checkbox'
                                id={`copyOnCheck${addressPath}`}
                                name={`copyOnCheck${addressPath}`}
                                checked={_.get(values, `copyOnCheck${addressPath}`)?.length}
                                onChange={handleCheckboxChange}
                                className='me-2'
                            />
                            {t('SAME_AS_ABOVE', 'Same as above')}
                        </label>
                    )}
                </div>
            )}
            {props.onDeleteAction && (
                <div className='d-flex justify-content-end' style={{ marginBottom: -20, marginTop: -20 }}>
                    <img style={{ cursor: 'pointer' }} onClick={props.onDeleteAction} src={DeleteIcon} alt='delete' />
                </div>
            )}

            <div className='row d-flex m-0'>
                {props?.formData.map(val => {
                    return (
                        <>
                            {props?.isReadOnly ? (
                                <div className='col-sm-6 py-3'>
                                    <GenericCommonTextReadOnlyField {...val} field={fieldPath ? `${fieldPath}.${val.field}` : val.field} />
                                </div>
                            ) : (
                                <>
                                    {(val?.type === 'text' || val?.type === 'number') && (!val.renderCondition || (val.renderCondition && val.renderCondition(values, index))) ? (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'} ${val.conditionalClass && val.conditionalClass(values)}`}>
                                            <GenericCommonTextField
                                                {...val}
                                                handleOnAssignmentChange={props.handleOnAssignmentChange}
                                                index={props?.index}
                                                additionalSection={props.additionalSection}
                                                module={props.module}
                                                field={fieldPath ? `${fieldPath}.${val.field}` : val.field}
                                                prefix={addressPath ? `${addressPath}.${val?.prefix ?? ''}` : val?.prefix ? val.prefix : ''}
                                                onChangeAction={props.onChangeAction}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {val?.type === 'select' && (!val.renderCondition || (val.renderCondition && val.renderCondition(values, index))) ? (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                            <GenericCommonSelector
                                                {...val}
                                                dropdownOptions={props.dropdownOptions}
                                                index={props?.index}
                                                handleDropdownChange={props.handleDropdownChange}
                                                additionalSection={props.additionalSection}
                                                handleOnAssignmentChange={props.handleOnAssignmentChange}
                                                customHandleChange={props.customHandleChange}
                                                module={props.module}
                                                field={fieldPath ? `${fieldPath}.${val.field}` : val.field}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {val?.type === 'searchSelect' && (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                            <GenericSelectorWithSearch
                                                {...val}
                                                dropdownOptions={props.dropdownOptions}
                                                index={props?.index}
                                                handleDropdownChange={props.handleDropdownChange}
                                                field={fieldPath ? `${fieldPath}.${val.field}` : val.field}
                                            />
                                        </div>
                                    )}
                                    {val?.type === 'multi' && (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                            <GenericCommonMultiSelector
                                                {...val}
                                                dropdownOptions={props.dropdownOptions}
                                                handleOnAssignmentChange={props.handleOnAssignmentChange}
                                                index={props?.index}
                                                additionalSection={props.additionalSection}
                                                module={props.module}
                                                field={fieldPath ? `${fieldPath}.${val.field}` : val.field}
                                            />
                                        </div>
                                    )}
                                    {val?.type === 'radio' && (!val.renderCondition || (val.renderCondition && val.renderCondition(values, index))) ? (
                                        <>
                                            {!!val?.break && <div className='col-12'></div>}
                                            <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                                <GenericCommonRadioField
                                                    {...val}
                                                    module={props.module}
                                                    field={fieldPath ? `${fieldPath}.${val.field}` : val.field}
                                                    onChangeRadioAction={props.onChangeRadioAction}
                                                    index={props?.index}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {val?.type === 'date' && (!val.renderCondition || (val.renderCondition && val.renderCondition(values, index))) ? (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                            <GenericCommonDatePicker
                                                {...val}
                                                handleOnAssignmentChange={props.handleOnAssignmentChange}
                                                index={props?.index}
                                                additionalSection={props.additionalSection}
                                                module={props.module}
                                                field={fieldPath ? `${fieldPath}.${val.field}` : val.field}
                                                onChangeAction={props.onChangeAction}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {val?.type === 'time' && (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                            <GenericCommonTimePicker
                                                {...val}
                                                handleOnAssignmentChange={props.handleOnAssignmentChange}
                                                index={props?.index}
                                                additionalSection={props.additionalSection}
                                                module={props.module}
                                            />
                                        </div>
                                    )}
                                    {val?.type === 'checkbox' && (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                            <GenericCheckboxField
                                                {...val}
                                                handleOnAssignmentChange={props.handleOnAssignmentChange}
                                                index={props?.index}
                                                additionalSection={props.additionalSection}
                                                module={props.module}
                                                field={fieldPath ? `${fieldPath}.${val.field}` : val.field}
                                            />
                                        </div>
                                    )}
                                    {val?.type === 'file' && (!val.renderCondition || (val.renderCondition && val.renderCondition(values, index))) ? (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}  ${val.conditionalClass && val.conditionalClass(values)}`}>
                                            <GenericFileUploadField
                                                {...val}
                                                setDocuments={props.setDocuments}
                                                documents={props.documents}
                                                module={props.module}
                                                additionalData={props.additionalData}
                                                field={fieldPath ? `${fieldPath}.${val.field}` : val.field}
                                                index={props?.index}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {val?.type === 'newfile' && (
                                        <div className={`py-3 ${val?.class ? 'col-sm-12' : 'col-sm-6'}`}>
                                            <GenericSingleFileUploadField {...val} field={fieldPath ? `${fieldPath}.${val.field}` : val.field} />{' '}
                                        </div>
                                    )}
                                    {val?.type === 'multifile' && (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                            <GenericFileUploadFieldMulti {...val} field={fieldPath ? `${fieldPath}.${val.field}` : val.field} />{' '}
                                        </div>
                                    )}
                                    {val?.type === 'textArea' && (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                            <GenericCommonTextAreaField {...val} field={fieldPath ? `${fieldPath}.${val.field}` : val.field} index={props?.index} />
                                        </div>
                                    )}
                                    {val?.type === 'bpa_select' && (
                                        <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                            <BpaCommonSelector {...val} field={fieldPath ? `${fieldPath}.${val.field}` : val.field} />
                                        </div>
                                    )}
                                    {val?.type === 'toggle' && (
                                        <>
                                            {!!val?.break && <div className='col-12'></div>}
                                            <div className={`py-3 ${val?.class ? val?.class : 'col-sm-6'}`}>
                                                <ToggleSwitch {...val} field={fieldPath ? `${fieldPath}.${val.field}` : val.field} onChangeAction={props.onChangeAction} />
                                            </div>
                                        </>
                                    )}
                                    {val?.type === 'none' && (!val.renderCondition || (val.renderCondition && val.renderCondition(values, index))) ? <div className='col-sm-6'></div> : <></>}
                                </>
                            )}
                        </>
                    );
                })}
            </div>
        </div>
    );
}

GenericFormCard.defaultProps = {
    title: '',
    formData: [],
    isReadOnly: false,
    dropdownOptions: {},
    setDocuments: () => {}
};
