import { Formik, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ARCHITECT_CREATE_FORM } from '../../utils/formikValidation/FormikValidation';
import { BPArequestInfo } from '../../services/modulesServices/userManagement.api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import HeaderComponent from '../../components/header/Header.component';
import activeArrow from '../../assets/logos/page arrow.svg';
import inactiveArrow from '../../assets/logos/page left arrow.svg';
import SideCard from '../../components/moduleCommonFormik/SideCardGenericComponent';
import GenericFooterComponent from '../../components/moduleCommonFormik/GenericFooterStepper.component';
import { t } from 'i18next';
import { getNavigateTo } from '../../utils/utils';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import Loading from '../../components/portalComponents/Loading.component';
import GenericBackButton from '../../components/UI components/Buttons/GenericBackButton';
import { InitiateConstSteps } from './ConstructionLicensePage.const';
import { ConstructionLicenseFormJson } from './ConstructionLicense.controller';
import GenericPopupModal from '../../components/moduleCommonFormik/GenericModalPopup.component';
import { BPA_ACTIONS_DETAILS } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.const';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import GenericHeader from '../../components/moduleCommonFormik/GenericHeader';
import NotingComponent from '../../components/Workflow/NotingComponent';
import { DocumentInfo } from '../../components/moduleCommonFormik/GenericFormCard.constants';
import ConstructionLicenseSubPage from './m_ConstructionLicenseSubPage.wrapper';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import { getHistory, onAttachDocument } from '../modules/EstateManagement/EstateManagement.controller';
import GenericPDFViewer from '../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../components/fileviewer/GenericImageViewer';
import { check, cross, warning } from '../../utils/Logo';
import { CorrespondenceWrapper, NotingWrapper } from '../../components/Workflow/NotingWrapper';
import { esignForDocuments } from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearanceFormPage.controller';

export default function M_ConstructionLicenseMainPage() {
    const [formValues, setFormValues] = useState([{ emailId: '' }]);
    const [editorContent, setEditorContent] = useState('');
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [activeStepper, setActiveStepper] = useState<string>('NOTES');
    const [appStatus, setAppStatus] = useState<any>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [actionsData, setActionsData] = useState<any>(null);
    let stepperDetails = InitiateConstSteps;
    const urlParams = new URLSearchParams(window.location.search);
    const applicationNumber = decodeURIComponent(urlParams.get('applicationNumber') || '');
    const [bpaData, setBpaData] = useState<any>(null);
    const [previewData, setPreviewData] = useState<any>(null);
    const [selectedAction, setSelectedAction] = useState<any>('');
    const [isShowActionPopup, setIsShowActionPopup] = useState<boolean>(false);
    const [sanctionFee, setSanctionFee] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [comment, setcomment] = useState('');
    const [paymentRowData, setPaymentRowData] = useState<any>([]);
    const [tabelData, setTabelData] = useState<any>([]);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [content, setContent] = useState('');
    const [documentDetails, setDocumentsDetails] = useState<any>([]);
    let user = localStorage.getItem('currentUserAssignment');
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    const [searchParams] = useSearchParams();
    const stepperIndex = urlParams.get('stepper') || '';
    const signedFileStoreId = urlParams.get('signedFileStoreId') || '';

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const DOC_TABLE_COLUMN = [
        {
            colName: 'SN',
            fieldName: 'serialNo',
            clsName: 'col-sm-2'
        },
        {
            colName: 'Uploaded By',
            fieldName: 'uploadedUserRole',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Date & Time',
            fieldName: 'uploadedDate',
            clsName: 'col-sm-3'
        },
        {
            colName: 'Document',
            fieldName: 'docFileName',
            clsName: 'col-sm-2',

            isClickable: true
        }
    ];

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const getHistoryData = async () => {
        if (applicationNumber) {
            try {
                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(!!historyData ? historyData : []);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
                // toast(t('SOMETHING_WENT_WRONG', 'Something went wrong!'), {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
            }
        }
    };

    const getDocuments = async () => {
        if (previewData) {
            try {
                const historyData: any = await TechnicalServices.searchAllDocuments(applicationNumber, { RequestInfo: BPArequestInfo() });
                setDocumentsDetails(historyData?.data?.documents);
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong!'), img: cross, type: 'error', reUpload: 'OK' });
                // toast(t('SOMETHING_WENT_WRONG', 'Something went wrong!'), {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
            }
        }
    };

    useEffect(() => {
        if (activeIndex === 2 && documentDetails) {
            const reverseDocumentDetails = documentDetails;
            const tableData = async () => {
                const responseTableData = reverseDocumentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${ind + 1}` };
                });
                const reverseData = responseTableData;
                setTabelData(reverseData);
            };
            if (activeIndex === 2) {
                tableData();
            }
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        getDocuments();
        getHistoryData();
        getPaymentData();
    }, [previewData]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let formIsUpdatable: boolean = true;

    useEffect(() => {
        getApplicationData();
    }, [applicationNumber]);

    useEffect(() => {
        setTimeout(() => {
            if (!!stepperIndex && stepperIndex === 'Esign' && !!bpaData) {
                values.notes = urlParams.get('note');
                submitForm(0, 'ACT_CL_APPROVE', true);
            }
        }, 1500);
    }, [bpaData]);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},
        validationSchema: !formIsUpdatable ? null : ARCHITECT_CREATE_FORM[`page${activeIndex}`]
    });

    const { values, resetForm, setFieldValue } = formik;

    const handleStepClick = async (index: number) => {
        if (editorContent.trim() === '') {
            setModalDetails({ show: true, title: 'Error!', para: t('ADD_A_NOTE_TO_CONTINUE', 'Add a note to continue.'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('ADD_A_NOTE_TO_CONTINUE', 'Add a note to continue.'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'error'
            // });
            return;
        }

        setActiveIndex(index);
    };

    const onChangeNextIndex = () => {
        // Check if the editorContent in RenderNotes is empty
        if (!!appStatus) {
            setActiveIndex(activeIndex + 1);
            return;
        }
        if (editorContent.trim() === '') {
            // Show toast message for empty editor content
            setModalDetails({ show: true, title: 'Error!', para: t('ADD_A_NOTE_TO_CONTINUE', 'Add a note to continue.'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('ADD_A_NOTE_TO_CONTINUE', 'Add a note to continue.'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'error'
            // });
            return;
        } else {
            setModalDetails({ show: true, title: 'Success!', para: t('SUCCESSFULLY_FILLED', 'Successfully filled.'), img: check, type: 'success', reUpload: 'OK' });
            // toast(t('SUCCESSFULLY_FILLED', 'Successfully filled.'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'success'
            // });
            return setActiveIndex(activeIndex + 1);
        }
    };

    const navigateToDashboard = () => {
        const userType = localStorage.getItem('type');
        if (userType) navigate(getNavigateTo(JSON.parse(userType)));
    };

    const fetchPayEstimation = (data: any, type: string) => {
        if (!!applicationNumber) {
            setIsLoading(true);
            const payload = {
                RequestInfo: BPArequestInfo(),
                CalulationCriteria: [
                    {
                        applicationNo: applicationNumber,
                        riskType: 'High',
                        feeType: type,
                        tenantId: 'ga',
                        applicationType: 'TECHNICAL_CLEARANCE',
                        serviceType: 'NEW_CONSTRUCTION'
                    }
                ]
            };
            TechnicalServices.fetchEstimatePayDetails(payload)
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        if (type === 'SanctionFee') setSanctionFee(response?.data?.Calculations && response?.data?.Calculations[0]);
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const getApplicationData = () => {
        if (!!applicationNumber) {
            setIsLoading(true);

            TechnicalServices.getBpaSearchForRefNo(applicationNumber, {
                RequestInfo: BPArequestInfo()
            })
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        if (!response?.data?.BPA[0]?.refApplication) {
                            setIsLoading(false);
                            setModalDetails({ ...modalDetails, title: 'No Application Found' });
                            setShowAlert(true);
                            return;
                        }
                        setPreviewData(response?.data?.BPA[0]);
                        setBpaData(response?.data?.BPA[0]);
                        setAppStatus(!response?.data?.BPA[0]?.editable);
                        setEditorContent(response?.data?.BPA[0]?.notes?.content || '');
                        setFormValues(response?.data?.BPA[0]?.notes?.notificationDetails);
                        setContent(response?.data?.BPA[0]?.provisionalCertificateContent);
                        getActionsData();
                        fetchPayEstimation(response?.data?.BPA[0], 'SanctionFee');
                        // setTimeout(() => {
                        //     if (!!stepperIndex && stepperIndex === 'Esign') {
                        //         values.notes = urlParams.get('note');
                        //         submitForm(0, 'ACT_CL_APPROVE', true);
                        //     }
                        // }, 1000);
                    }
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    const handleSubmit = () => {
        if (!!appStatus) {
            navigateToDashboard();
            return;
        }
        const isEmpty = formValues.some((element: any) => element.emailId.trim() === '');
        if (isEmpty) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all email fields before submission.', img: cross, type: 'error', reUpload: 'OK' });
            // toast('Please fill in all email fields before submission.', {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
            return;
        }

        for (let i = 0; i < formValues.length - 1; i++) {
            const currentEmail = formValues[i].emailId.trim();
            for (let j = i + 1; j < formValues.length; j++) {
                const nextEmail = formValues[j].emailId.trim();
                if (currentEmail === nextEmail) {
                    setModalDetails({
                        show: true,
                        title: 'Error!',
                        para: `Please enter different email addresses. Email ${i + 1} and Email ${j + 1} have the same address.`,
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                    // toast(`Please enter different email addresses. Email ${i + 1} and Email ${j + 1} have the same address.`, {
                    //     position: toast.POSITION.TOP_RIGHT,
                    //     className: 'foo-bar',
                    //     type: 'warning'
                    // });
                    return;
                }
            }
        }
        submitForm(0);
    };

    const validateEmailForms = (): boolean => {
        // Check if formValues is defined and is an array
        if (!Array.isArray(formValues) || formValues.length === 0) {
            setModalDetails({
                show: true,
                title: 'Error!',
                para: t('EMPTY_FORM', 'The form values are not available or empty. Please add a note and email id.'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            // toast(t('EMPTY_FORM', 'The form values are not available or empty. Please add a note and email id.'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
            return false;
        }

        const lastIndex = formValues.length - 1;
        const lastEmail = formValues[lastIndex].emailId.trim();

        if (!lastEmail || !validateEmail(lastEmail)) {
            setModalDetails({ show: true, title: 'Error!', para: t('VALID_EMAIL', 'Please enter a valid email address before adding a new one.'), img: cross, type: 'error', reUpload: 'OK' });
            // toast(t('VALID_EMAIL', 'Please enter a valid email address before adding a new one.'), {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'warning'
            // });
            return false;
        }

        for (let i = 0; i < lastIndex; i++) {
            if (formValues[i].emailId.trim() === lastEmail) {
                setModalDetails({
                    show: true,
                    title: 'Error!',
                    para: `${t('DIFFERENT_EMAIL', 'Please enter a different email address.')} ${t('EMAIL', 'Email')} ${i + 1} ${t('SAME_ADDRESS', 'has the same address.')}`,
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
                // toast(`${t('DIFFERENT_EMAIL', 'Please enter a different email address.')} ${t('EMAIL', 'Email')} ${i + 1} ${t('SAME_ADDRESS', 'has the same address.')}`, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'warning'
                // });
                return false;
            }
        }
        return true;
    };

    const validateEmail = (email: string) => {
        const regex = /\S+@\S+\.\S+/;
        return regex.test(email);
    };

    const esignSelf = async (fileStoreId: string) => {
        const redirectURL = `/goa-idc/obpas/e/constructionLicense?${searchParams.toString()}&note=${values.notes}&docsUuid=${fileStoreId}&stepper=Esign`;
        let url = window.location.origin + redirectURL;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${fileStoreId}&callbackUrl=${url}`);
        // esignForDocuments(fileStoreId, redirectURL);
    };

    const submitForm = (key: number, action?: string, isEsign?: boolean) => {
        if (!!appStatus && !action) {
            navigateToDashboard();
            return;
        }

        if (!!action && !values?.notes) {
            setModalDetails({
                show: true,
                title: 'Error!',
                para: t('OC_MSG_CHECK_NOTING', 'Please add the noting and proceed'),
                img: cross,
                type: 'error',
                reUpload: 'OK'
            });
            return;
        }

        if (!!action && action === 'ACT_CL_APPROVE' && !isEsign) {
            TechnicalServices.generateCertificate({
                RequestInfo: BPArequestInfo(),
                applicationNo: applicationNumber,
                provisionalCertificateContent: content
            }).then((response: any) => {
                if (!!response?.data?.documents[0]?.fileStoreId) {
                    esignSelf(response?.data?.documents[0]?.fileStoreId);
                } else {
                    setModalDetails({
                        show: true,
                        title: 'Error!',
                        para: t('OC_MSG_CHECK_DOCUMENT', 'No Document Found!'),
                        img: cross,
                        type: 'error',
                        reUpload: 'OK'
                    });
                }
                return;
            });
            return;
        }

        setIsLoading(true);
        values.id = bpaData?.id;
        values.emails = formValues;

        const payload: any = ConstructionLicenseFormJson(values, bpaData, !!bpaData?.accountId);

        // Initialize BPA object and its documents array if not already done
        payload.BPA = payload.BPA || {};
        payload.BPA.documents = payload.BPA.documents || [];

        if (!!selectedAction || !!action) {
            payload.BPA.workflow = {
                action: selectedAction || action,
                assignes: null,
                comments: values?.notes,
                varificationDocuments: null
            };
        }

        if (action === 'ACT_CL_APPROVE' && !!isEsign) {
            payload.BPA.documents.push({
                id: null,
                applicationNumber: applicationNumber,
                documentType: 'CONSTRUCTION_LICENSE_ORDER',
                fileStoreId: signedFileStoreId,
                tenantId: 'ga',
                documentName: 'CONSTRUCTION_LICENSE_ORDER.pdf',
                documentUid: '',
                additionalDetails: {}
            });
        }

        payload.BPA.notes = {
            id: null, // Assuming it should have an ID, set as null or any specific value if needed
            content: values?.notes || '',
            notificationDetails: null // Assuming no specific notification details
        };

        payload.BPA.provisionalCertificateContent = content;

        TechnicalServices.createTechnicalClearance(payload)
            .then((response: any) => {
                setIsLoading(false);

                // toast(`Application ${!!values?.id ? 'Saved' : 'Created'} Successfully!`, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'success'
                // });
                const successMessageCLApprove = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} approved Successfully`;

                setModalDetails({
                    show: true,
                    // para: t('POPUP_MSG_APPROVED_CL', 'Construction License Application Approved Successfully'),
                    para: successMessageCLApprove,
                    title: 'Success!',
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                    // children: (
                    //     <div>
                    //         <span className='fw-bold font-14'>Application Number</span>: <b className='font-15 text-success'>{applicationNumber}</b>
                    //     </div>
                    // )
                });

                // setModalDetails({ show: true, para: t('POPUP_MSG_APPROVED_CL', 'Application Approved Successfully'), title: 'Success!', img: check, type: 'success', reUpload: 'OK' });

                setShowAlert(true);

                // navigateToDashboard();
            })
            .catch(error => {
                setIsLoading(false);
                setModalDetails({ show: true, title: 'Error!', para: error?.message, img: cross, type: 'error', reUpload: 'OK' });
                // toast(error?.message, {
                //     position: toast.POSITION.TOP_RIGHT,
                //     className: 'foo-bar',
                //     type: 'error'
                // });
            });
    };

    const getActiveStepper = () => {
        stepperDetails.forEach(val => {
            if (val?.index === activeIndex) {
                setActiveStepper(val?.code);
            }
        });
    };

    const getActionsData = () => {
        if (!!applicationNumber) {
            setIsLoading(true);

            TechnicalServices.getBpaActions(applicationNumber, {
                RequestInfo: BPArequestInfo()
            })
                .then((response: any) => {
                    // handle the response
                    if (response?.data) {
                        setActionsData(response?.data);
                    }

                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {
        getActiveStepper();
    }, [activeIndex]);

    const onChangePrevIndex = () => {
        const stepperLength = InitiateConstSteps.length;
        if (activeIndex <= stepperLength && activeIndex > 1) {
            setActiveIndex(activeIndex - 1);
            formik.setTouched({ back: true });
        }
    };

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({});
        setShowAlert(false);
        if (type === 'success') navigateToDashboard();
    };

    const onActionClick = (action: string) => {
        setFieldValue('comments', '');
        setSelectedAction(action);
        submitForm(0, action);
        // setIsShowActionPopup(true);
    };

    const handleClose = () => {
        setIsShowActionPopup(false);
    };

    // const handleOnFileUpload = async (file: DocumentInfo) => {
    //     try {
    //         const docs = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, '', ''));
    //         getDocuments();
    //         setActiveIndex(2);
    //     } catch (e) {}
    // };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, '', null));
            getDocuments();
            setActiveIndex(2);
        } catch (e) {}
    };

    const totalPages = true ? 2 : 1;

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const getPaymentData = async () => {
        try {
            const payload = { RequestInfo: BPArequestInfo() };
            TechnicalServices.searchPaymentDetails(previewData?.refApplication || applicationNumber, payload).then(res => {
                if (res?.data) {
                    setPaymentRowData(res?.data);
                }
            });
        } catch (e) {
            setModalDetails({ show: true, title: 'Error!', para: 'Failed to retrieve payment data', img: cross, type: 'error', reUpload: 'OK' });
            // toast('Failed to retrieve payment data', {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'foo-bar',
            //     type: 'error'
            // });
        }
    };

    const renderActionForm = () => {
        return (
            <>
                {BPA_ACTIONS_DETAILS.map(val => (
                    <Card.Body>
                        <GenericFormCard {...val} />
                    </Card.Body>
                ))}
            </>
        );
    };

    const renderActionBtn = () =>
        actionsData?.nextValidAction.map((val: any) => {
            if (val?.action === 'Submit') return;
            return (
                <>
                    <Button
                        variant='disabled outline-success new_btn btn btn-light me-3 font-karla-med p-10 fs-6'
                        style={{ borderColor: '#45852e', color: '#FFFF', backgroundColor: '#45852e', width: 'max-content', borderRadius: 8 }}
                        onClick={() => onActionClick(val?.action)}
                    >
                        {/* {val?.action.replace(/_/g, ' ')} */}
                        {t(val?.action, 'Localisation Not Working')}
                    </Button>
                </>
            );
        });

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <StatusAlertPopup
                title={modalDetails.title}
                para=''
                img={modalDetails.img}
                show={showAlert}
                handleClose={() => handleModalClose('success')}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => handleModalClose('success')}
            />

            <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative'>
                    <GenericHeader
                        subText={t('', '')}
                        onActionClick={navigateToDashboard}
                        text={t('CL_EMP_HEADER_APPLICATION_FOR_CONSTRUCTION_LICENSE', 'Application for Construction License')}
                        additionalClass='sub-text-style'
                        bottomSubText={`Type : ${t('CL_EMP_HEADER_TYPE', 'Construction License')}, No : ${applicationNumber}`}
                    />
                    <FormikProvider value={formik}>
                        <div className='col-sm-3 mt-3 stickyCard-section '>
                            <div className='stickyCard' style={{ top: '15%' }}>
                                <GenericHeader
                                    text={t('CL_EMP_SUBHEADER_APPLICATION_FOR_CONSTRUCTION_LICENSE', 'Application for Construction License')}
                                    sideHeader={true}
                                    onActionClick={navigateToDashboard}
                                    additionalClass='sub-text-style'
                                    bottomSubText={`Type : ${t('CL_EMP_SUBHEADER_TYPE', 'Construction License')}, No : ${applicationNumber}`}
                                />
                                <NotingWrapper
                                    text={comment}
                                    designation={designation}
                                    applicationNumber={applicationNumber}
                                    history={historyDetails}
                                    disable={!values.isUpdatable}
                                    handleOnFileUpload={handleOnFileUpload}
                                />
                            </div>
                        </div>

                        <div className='col-sm-9 mt-3 form-section '>
                            <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={{ businessService: previewData?.businessService }} />
                            <Card className='card_border p-1'>
                                <Form>
                                    {activeIndex === 1 && (
                                        <ConstructionLicenseSubPage
                                            applicationNumber={applicationNumber}
                                            previewData={previewData}
                                            bpaData={bpaData}
                                            activeStepper={activeStepper}
                                            setEditorContent={setEditorContent}
                                            setFormValues={setFormValues}
                                            editorContent={editorContent}
                                            sanctionFee={sanctionFee}
                                            formValues={formValues}
                                            paymentRowData={paymentRowData}
                                            content={content}
                                            setContent={setContent}
                                        />
                                    )}
                                    {activeIndex === 2 && <GenericActionTable columns={DOC_TABLE_COLUMN} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />}

                                    <GenericPopupModal
                                        title=''
                                        subTitle={`Application No: ${previewData?.applicationNo}`}
                                        isVisible={isShowActionPopup}
                                        primaryBtnTitle={selectedAction?.replace(/_/g, ' ')}
                                        secondaryAction={handleClose}
                                        primaryAction={() => {
                                            submitForm(0, selectedAction);
                                        }}
                                        secondaryBtnTitle='Close'
                                        children={renderActionForm()}
                                    />
                                </Form>
                            </Card>
                        </div>
                    </FormikProvider>
                    <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                        <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                            <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === 2 && `rotate-class`}`} />
                        </span>
                        Page {activeIndex} of {totalPages}
                        <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                            <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                        </span>
                    </div>
                </div>
            </div>
            {/* <GenericFooterComponent
                onClickNext={onChangeNextIndex}
                onClickSave={onChangeNextIndex}
                onCancel={() => navigate(-1)}
                onClickPrev={onChangePrevIndex}
                onSubmit={() => handleSubmit()}
                stepperLength={stepperDetails.length}
                activeIndex={activeIndex}
                isSaveBtn={false}
                children={renderActionBtn()}
                secondaryBtnLabel={
                    !!appStatus && activeIndex === stepperDetails.length
                        ? `${t('CLOSE', 'Close')}`
                        : !appStatus && activeIndex === stepperDetails.length
                            ? `${t('SUBMIT', 'Submit')}`
                            : !!appStatus
                                ? `${t('NEXT', 'Next')}`
                                : `${t('SAVE_AND_NEXT', 'Save & Next')}`
                }
            /> */}
            <GenericFooterComponent
                onClickNext={() => {}}
                onSubmit={() => {}}
                onCancel={() => navigateToDashboard()}
                onClickPrev={() => {}}
                stepperLength={0}
                activeIndex={activeIndex}
                children={renderActionBtn()}
                isSaveBtn={false}
                isSubmitBtn={false}
            />
            {modalData && isFilePreviewModal && (
                <GenericPopupModal
                    title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                    primaryAction={() => {}}
                    secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                    isVisible={isFilePreviewModal}
                    isPrimaryBtn={false}
                    isSecondaryBtn={false}
                    size={'xl'}
                    class='file-viewer-popup'
                >
                    <div className='popup-children'>
                        {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} />}
                        {!isPDF && <GenericImageViewer />}
                    </div>
                </GenericPopupModal>
            )}

            <StatusAlertPopup
                title={modalDetails.title}
                para={modalDetails.para}
                img={modalDetails.img}
                show={modalDetails.show}
                handleClose={() => {
                    handleModalClose(modalDetails.type);
                    navigateToDashboard();
                }}
                type={modalDetails.type}
                reUpload={modalDetails.reUpload}
                handleSubmit={() => {
                    handleModalClose(modalDetails.type, modalDetails?.finalSubmit);
                    if (modalDetails.type === 'success') {
                        navigateToDashboard();
                    }
                }}
                children={modalDetails.children}
            />
        </>
    );
}
