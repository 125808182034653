import { useState } from 'react';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { FormikProvider, useFormik } from 'formik';
import { DSC_REGISTRATION_FIELDS } from './DscRegistration.const';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { Card } from 'react-bootstrap';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { E_SIGN_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';

export default function DscRegistration() {
    const [isLoading, setIsLoadin] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: () => {},
        validationSchema: E_SIGN_VALIDATION
    });

    const { values, setFieldValue } = formik;

    const handleOnInputChange = (e: any) => {
        if (e.target.value?.length > 0) {
            setIsError(false);
        } else {
            setIsError(true);
        }
        setFieldValue('password', e.target.value);
    };

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleOnBlur = () => {
        if (values?.password?.length > 0) {
            setIsError(false);
        } else {
            setIsError(true);
        }
    };

    const onCancel = () => {};

    const onRegister = () => {};

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader text={t('DSC_REGISTRATION', 'DSC Registration')} additionalClass='sub-text-style' />
                        <div className='col-12 form-section'>
                            <FormikProvider value={formik}>
                                <Card className='card_border'>
                                    <Card.Body>
                                        {DSC_REGISTRATION_FIELDS?.map((val: any) => (
                                            <GenericFormCard {...val} />
                                        ))}
                                        <div className='row d-flex m-0'>
                                            <div className='col-sm-6 password-input-group pb-3'>
                                                <label htmlFor={'password'} className='pb-2 file-form-label' style={{ width: 'fit-content' }}>
                                                    {t('PASSWORD', 'Password')}
                                                </label>
                                                <span className='mandatory-indicator'>*</span>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder='Enter Password'
                                                    className='form-control'
                                                    name='password'
                                                    onChange={handleOnInputChange}
                                                    onBlur={handleOnBlur}
                                                />
                                                <div style={{ position: 'absolute', top: isError ? `43%` : `52%`, right: '5%' }}>
                                                    {showPassword ? (
                                                        <i className='fa fa-eye password-eye-btn' onClick={handleTogglePassword} aria-hidden='true'></i>
                                                    ) : (
                                                        <i className='fa fa-eye-slash password-eye-btn' aria-hidden='true' onClick={handleTogglePassword}></i>
                                                    )}
                                                </div>
                                                {isError ? <span className='text-danger font-12'>{'Password is a required field'}</span> : <></>}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </FormikProvider>
                        </div>
                        <footer className='footer fixed-bottom footerBtnPatch'>
                            <div className='d-flex justify-content-end  container me-3 ' style={{ padding: 15 }}>
                                <GenericButtons text={t('CANCEL', 'Cancel')} handleClick={onCancel} />
                                <GenericButtons text={t('REGISTER', 'Register')} variant='primary' handleClick={onRegister} />
                            </div>
                        </footer>
                    </div>
                </>
            )}
        </>
    );
}
