import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'react-bootstrap';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import { EXISTING_PLOT_DETAILS } from '../Plotmaster.const';
import DeleteIcon from '../../../../../assets/logos/delete.svg';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { convertToINR } from '../../EstateManagement.controller';
import { estateDropdown } from '../../Estate_Master/master.const';
import { getDropdownSelectedObject } from '../../../UserManagement/UserMangement.controller';
import { useTranslation } from 'react-i18next';


const PlotMasterCorrespondence = (props: any) => {
    const { t } = useTranslation();
    const [openSection, setOpenSection] = useState<string>('plotDetails');
    const { values } = useFormikContext<any>();
    const location = useLocation();
    const data = location?.state?.data || props.existingPlotDetails;

    const existingPlotDetails = (plotDetails: any) => {
        const existingPlot = plotDetails?.map((plot: any) => {
            return {
                ...plot,
                id: plot?.uuid,
                plotAreaSqm: typeof plot.plotAreaSqm === 'number' ? plot.plotAreaSqm?.toFixed(2) : plot.plotAreaSqm,
                plotRate: typeof plot.plotRate === 'number' && convertToINR(plot?.plotRate),
                plotType: getDropdownSelectedObject(plot?.plotType, props.dropdownData?.['plotType']).name,
                village: plot?.plotDetails?.villageDetails?.map((val: any) => val.villageName).join(', ')
            };
        });

        return (
            <>
                {existingPlot?.map((plotVal: any, plotInd: number) => (
                    <div className='row my-3 existing-plot-details gy-3 pb-4 '>
                        {EXISTING_PLOT_DETAILS.map((val: any, ind: number) => (
                            <div className='col-3'>
                                <div className='col-12 font-14 fw-500 font-inter title'>{val?.title}</div>
                                <div className='col-12 font-inter font-16 fw-bold lh-base'>{_.get(plotVal, val?.field) || 'NA'}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </>
        );
    };

    const plotDetails = () => {
        return (
            <>
                <div className='font-inter project-primary-color fw-bold font-18 mt-3 ps-3 '>{t('EXISTING_PLOT_DETAILS', 'Existing Plot Details')}</div>
                <div className='col-12 ps-3'>{existingPlotDetails(data)}</div>
                <div className='font-inter project-primary-color fw-bold font-18 mb-1 ps-3 pb-3'>{data === null ? '' : data?.length === 1 ? t('SPLIT_PLOT_DETAILS','Split Plot Details') : t('MERGE_PLOT_DETAILS', 'Merge Plot Details')}</div>

                <form>
                    <FieldArray name='plotDetails'>
                        {({ insert, remove, push }) => (
                            <div>
                                {values.plotDetails?.length > 0 &&
                                    values.plotDetails.map((data: any, index_p: number) => (
                                        <div className='row' key={index_p}>
                                            <div className='col-sm-12 collapsible_box align-items-center pb-2'>
                                                <input type='checkbox' id={`plotDetails.${index_p}`} name={`plotDetails.${index_p}`} defaultChecked={true} />
                                                <label htmlFor={`plotDetails.${index_p}`} className='d-flex mx-3 collapsible_box_label align-items-center justify-content-between'>
                                                    New Plot - {index_p + 1}
                                                </label>
                                                <div className='form_fields ps-1'>
                                                    {index_p !== 0 && values.isUpdatable && (
                                                        <div className='d-flex justify-content-end mx-3'>
                                                            <img style={{ cursor: 'pointer', zIndex: 1 }} onClick={() => remove(index_p)} src={DeleteIcon} alt='delete' />
                                                        </div>
                                                    )}
                                                    {props.PLOT_DETAILS.map((val: any, _: number) => (
                                                        <GenericFormCard fieldPath={`plotDetails.${index_p}`} {...val} dropdownOptions={props.dropdownData} customHandleChange={props.onChangeAction} />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {data?.length === 1 && values.isUpdatable && (
                                    <div className='ms-3'>
                                        <button className='col-sm-12 mt-2 mb-4  justify-content-center align-items-center noc_add_fields' type='button' onClick={() => push('')}>
                                            + Add Plot
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </FieldArray>
                </form>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-2'>
                <CardBody>
                    <div className='row'>
                        <div className='col-sm-12 collapsible_box noting-collapsible-box plot pad-0 align-items-center'>
                            {/* <input type='checkbox' id='advDetailsCheckbox' name='advDetailsForm' checked={openSection === 'plotDetails'} /> */}
                            {/* <label
                                htmlFor='advDetailsCheckbox'
                                className='d-flex collapsible-box-label col-sm-12 ps-3 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                Plot Details
                            </label> */}
                            {openSection === 'plotDetails' ? plotDetails() : <></>}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default PlotMasterCorrespondence;
