import React, { useState } from 'react';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import { LEASE_DEED_FORWARD_COMMENT } from '../modules/EstateManagement/NOC/NOCManagement.const';
import { Form } from 'react-bootstrap';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import { useTranslation } from 'react-i18next';
import { COMMENTS } from '../../utils/formikValidation/FormikValidation';

interface IleaseDeedProps {
    receivedChildData(comments: any): unknown;
    name?: string;
}
export const LeaseDeedForwardPopUp = (props: IleaseDeedProps) => {
    const { t } = useTranslation();
    const [childData, setChildData] = useState(undefined);

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true
        },
        onSubmit: value => {},

        validationSchema: COMMENTS
    });
    const { values, setValues, setFieldValue } = formik;
    const sendData = () => {
        props?.receivedChildData(childData);
    };
    const handleKeyUp = (e: any) => {
        setChildData(e.target.value);
        sendData();
    };
    return (
        <>
            <div className='container' style={{ background: '#F5F5F6' }}>
                <div className='row px-2 justify-content-center  '>
                    <p className='noc_heading mt-3 mb-0 ps-3 '>{t('FORWARD_TO', 'Forward to')}</p>
                    <h4 className='noc_title m-0 mb-3 ps-3'>{props?.name}</h4>
                    <FormikProvider value={formik}>
                        <Form>
                            <label htmlFor='comments ' className='pb-2 ps-1'>
                                Comments <span className='mandatory-indicator'>*</span>
                            </label>

                            <Field name='comments' as='textarea' className='form-control mb-2' style={{ height: '100px' }} onKeyUp={handleKeyUp} />
                            <ErrorMessage name='comments' component='div' className='text-danger font-12' />
                        </Form>
                    </FormikProvider>
                </div>
            </div>
        </>
    );
};
