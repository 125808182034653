import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import HeaderComponent from "../../../components/header/Header.component";
import { useTranslation } from "react-i18next";
// import { app_icon, arch_reg_icon, building_icon, emp_act_icon, emp_create_icon, emp_search_icon, lessee_icon, scrutiny_icon } from "../../../utils/Images";

export default function UserManagementPage() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <HeaderComponent />
            <div className="bg-light m-auto px-5 py-4 row">
                <div className="col-sm-12">
                    <p className="fs-1 fw-500">{t("USER_MANAGEMENT", "User Management")}</p>
                    <div className="card_border">
                        <Card.Body className="border border-success rounded-4 mb-4 bg-white">
                            <p className="fs-4 fw-500 p-4">{t("SERVICES", "Services")}</p>
                            <div className="row m-auto px-3 gx-4 service-card">
                                <div className="col-4">
                                    <Card className="card_border">
                                        <Card.Title className="fs-6 pb-2">{t("EMPLOYEE_CREATION", "Employee Creation")}</Card.Title>
                                        <Link to="/usermanagement/employeecreation">
                                            <Button className="my-2 btn forward-btn ratio ratio-1x1">{/* <img src={emp_create_icon} alt="emp-create-icon" /> */}</Button>
                                        </Link>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card_border">
                                        <Card.Title className="fs-6 pb-2">{t("ARCHITECT_REGISTRATION", "Architect Registration")}</Card.Title>
                                        <Link to="/usermanagement/architectCreation">
                                            <Button className="my-2 btn forward-btn ratio ratio-1x1">{/* <img src={arch_reg_icon} alt="arch-reg-icon" /> */}</Button>
                                        </Link>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card_border">
                                        <Card.Title className="fs-6 pb-2">{t("EMPLOYEE_SEARCH", "Employee Search")}</Card.Title>
                                        <Button className="my-2 btn forward-btn ratio ratio-1x1">{/* <img src={emp_search_icon} alt="emmp-search-icon" /> */}</Button>
                                    </Card>
                                </div>
                                <div className="col-4">
                                    <Card className="card_border">
                                        <Card.Title className="fs-6 pb-2">{t("EMPLOYEE_ACTIVATION_DEACTIVATION", "Employee Activation/Deactivation")}</Card.Title>
                                        <Button className="my-2 btn forward-btn ratio ratio-1x1">{/* <img src={emp_act_icon} alt="emp-act-icon" /> */}</Button>
                                    </Card>
                                </div>
                            </div>
                        </Card.Body>
                    </div>
                </div>
            </div>
        </>
    );
}
