import React, { useEffect } from 'react';
import './LoginPage.css';
import { check, cross, logo } from '../../utils/Logo';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import LoginSubPage from './LoginSubPage.wrapper';
import { getStringResource } from '../../i18n/config';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/authSlice';
import { getNavigateTo } from '../../utils/utils';
import { enterpriseValidateOtp, getMenuData, getOtp, getPasswordChangeOtp, validateUserOtp } from '../../services/modulesServices/userManagement.api';
import PasswordChangeModal from './PasswordChangeModal';
import warning from '../../assets/logos/Warning2.png';
import StatusAlertPopup from '../../components/moduleCommonFormik/StatusAlertPopup';
import { useTranslation } from 'react-i18next';
import PopUps from '../../components/PopUps';

// interface OtpValidateProps {
//   setIsAuthenticated: (isAuth: boolean) => void;
// }

export default function OtpValidate() {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const userLoginData = location?.state?.userLoginData || null;
    let changePasswordPayload = location?.state?.payload || null;

    const [otp, setOtp] = useState<number>(0);
    const [otpRef, setOtpRef] = useState<number>(0);
    const [timer, setTimer] = useState(60);
    const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
    const [showIsAuthorizedModal, setShowIsAuthorizedModal] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState({});
    const [userType, setUserType] = useState<string>('');

    useEffect(() => {
        let interval: any;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    const handleResendOtp = () => {
        if (changePasswordPayload) {
            getPasswordChangeOtp({ otp: changePasswordPayload })
                .then(res => {
                    if (res.data.isSuccessful) {
                        if (res.data.isSuccessful) {
                            let msg = t('OTP_SENT', 'OTP sent');
                            setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'Sent success', reUpload: 'OK' });
                        }
                    }
                })
                .catch(err => {
                    let errMsg = t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                    setModalDetails({ show: true, title: t('ERROR','Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                });
        } else {
            userLoginData?.username &&
                getOtp(userLoginData)
                    .then(res => {
                        if (res.data.isSuccessful) {
                            let msg = t('OTP_SENT', 'OTP sent');
                            setModalDetails({ show: true, title: 'Success', para: msg, img: check, type: 'Sent success', reUpload: 'OK' });
                        }
                    })
                    .catch(err => {
                        const errMsg = err.response.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong!');
                        setModalDetails({ show: true, title: t('ERROR','Error!'), para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                    });
        }

        setTimer(60);
    };

    const getPath: any = localStorage.getItem('routeTo');

    const redirectTo = (authenticatedUserMenu: any, userType: string) => {
        const requestPath = authenticatedUserMenu.data?.validMenu?.[getPath];
        const routeTo = getNavigateTo(userType);

        if (getPath && !requestPath) {
            setShowIsAuthorizedModal(true);
        } else if (getPath && requestPath) {
            navigate(requestPath);
        } else navigate(routeTo);
    };

    const doSubmit = async () => {
        if (changePasswordPayload) {
            const requestBody = {
                otp: {
                    tenantId: 'ga',
                    identity: changePasswordPayload.identity,
                    otp: otp
                }
            };

            try {
                const res = await validateUserOtp(requestBody);
                setOtpRef(res.data.otp.UUID);
                setShowPasswordChangeModal(true);
            } catch (e) {
                console.log(e);
            }
            return;
        } else {
            const username = userLoginData?.username;

            try {
                const res = await enterpriseValidateOtp({ otp, username });

                if (res.data) {
                    setUserType(res.data.UserRequest.type);
                    localStorage.setItem('user', JSON.stringify(res.data.UserRequest));
                    localStorage.setItem('token', JSON.stringify(res.data.access_token));
                    localStorage.setItem('type', JSON.stringify(res.data.UserRequest.type));
                    dispatch(setUser(res.data));

                    const authenticatedUserMenu: any = await getMenuData();
                    redirectTo(authenticatedUserMenu, res.data.UserRequest.type);
                }
            } catch (err) {
                // alert("Invalid username or Password");
                let errMsg = t('INVALID_OTP', 'Invalid OTP');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        }
    };

    const handleOnInputChange = (e: any) => {
        setOtp(e.target.value);
    };

    return (
        <>
            <PasswordChangeModal
                title= {t('RESET_PASSWORD','Reset Password')}
                // para="Please update your password"
                show={showPasswordChangeModal}
                handleClose={() => setShowPasswordChangeModal(false)}
                data={{ ...changePasswordPayload, otpReference: otpRef }}
                setShowPasswordChangeModal={setShowPasswordChangeModal}
                type='forgotPassword'
            />
            <StatusAlertPopup
                show={showIsAuthorizedModal}
                handleSubmit={() => navigate(getNavigateTo(userType))}
                handleClose={() => setShowIsAuthorizedModal(false)}
                title={t('UNAUTHORIZED_ACTION','Unauthorized Action')}
                para= {t('AUTHORIZATION_FAILED','Your account lack the necessary authorization to access this particular information.')}
                reUpload='Go to Dashboard'
                img={warning}
            />
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <div className='loginPage bg-light'>
                <div className='row column-gap-5 gx-0    '>
                    <div className='col-lg-4 login-container'>
                        <div className=''>
                            <div className='row full-height '>
                                <div className='col-md-5 pe-0 bg-white w-100 '>
                                    <div className='form-left h-100 py-5 px-5'>
                                        <div className='my-form bg-white font-inter-reg'>
                                            <div className='mb-5 d-flex align-items-center'>
                                                <div>
                                                    <Link to='/goa-idc/login' className='col-1 px-4 py-3 back_arrow  me-3 '></Link>
                                                </div>
                                                <div className='py-2 fw-bold d-inline fs-2 text-black credentials-title mb-0'>{t('LOG_IN', 'Log In')}</div>
                                            </div>
                                            <div className='py-2 fw-bold text-black credentials-title'>{t('ENTER_OTP', 'Enter OTP')}</div>
                                            <input type='text' className='form-control login' onChange={handleOnInputChange} name='otp' />

                                            {showPasswordChangeModal ? (
                                                <>
                                                    <div className='resend-otp' onClick={handleResendOtp}>
                                                        {t('OTP_VERIFIED', 'OTP Verified')}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {timer > 0 ? (
                                                        <p className='resend-otp'>
                                                            {t('RESEND_OTP_IN', 'Resend OTP in ')}
                                                            &nbsp;{timer}
                                                            {t('SECONDS', 'seconds')}
                                                        </p>
                                                    ) : (
                                                        <div className='resend-otp' onClick={handleResendOtp}>
                                                            {t('RESEND_OTP', 'Resend OTP')}
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                            <button type='submit' className='btn login-btn btn-color mt-3' onClick={doSubmit}>
                                                {t('SUBMIT', 'Submit')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-7 me-auto mt-3  outer_part align-self-center  '>
                        <LoginSubPage />
                    </div>
                </div>
            </div>
        </>
    );
}
