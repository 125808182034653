import { Card } from 'react-bootstrap';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import ProposedActivityComponent from './ProposedActivityDetails';
import ToolTipDetails from '../../../../components/ToolTipFile';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export default function EnterpriseDetails(props: any) {
    const { t } = useTranslation();
    const { values, errors, touched, setFieldValue, setFieldTouched, setValues } = useFormikContext<any>();

    const toolTipDetails = {
        position: 'bottom',
        content:
            'If yes, please attach a copy of the approval required from the Ministry of Home Affairs for Foreign Direct Investment (FDI) made by: (i) any entity based in any bordering country of India; or (ii) any beneficial owner of the investment situated in or citizen of any bordering country of India.'
    };

    useEffect(() => {
        setFieldValue('enterpriseCategory', values.plotType);
    }, [values.plotType]);

    return (
        <>
            <div className=''>
                <>
                    <Card.Body>
                        {props?.enterpriseDetails.map((val: any) => (
                            <GenericFormCard {...val} dropdownOptions={props.dropdownOptions} />
                        ))}
                        <ProposedActivityComponent
                            isDisabled={props.isDisabled}
                            nicDetails={props.nicDetails}
                            setNicDetails={props.setNicDetails}
                            showPopUp={props?.showPopUp}
                            setShowPopUp={props?.setShowPopUp}
                        />
                        {props?.enterpriseProjectDetails.map((val: any) => (
                            <GenericFormCard {...val} dropdownOptions={props.dropdownOptions} />
                        ))}
                    </Card.Body>
                    <Card.Body>
                        {props?.declarationDetails.map((val: any) => (
                            <>
                                <div className='d-flex flex-row'>
                                    <span style={{ paddingLeft: '12px', fontSize: '15px' }}>
                                        <label>{t('WHETHER :', 'Whether :')}</label>
                                    </span>
                                    <ToolTipDetails toolTipDetails={toolTipDetails} />
                                </div>
                                <br />
                                <span style={{ paddingLeft: '12px', fontSize: '15px' }}>
                                    <label>i) the {props.applicantName || 'Applicant'} is</label>
                                    {/* <label>{t('THE','i) the')} `{props.applicantName || t('APPLICANT','Applicant')} `{t('IS', 'is')}</label> */}
                                </span>
                                <br /> <span style={{ paddingLeft: '12px', fontSize: '15px' }}> or</span>
                                <span style={{ paddingLeft: '12px', fontSize: '15px' }}>
                                    <label>
                                        {t('BENEFICIAL_OWNERSHIP', 'ii) the beneficial ownership of the')} `{props.applicantName || t('APPLICANT', 'Applicant')} `
                                        {t(
                                            'BENIFICIAL_OWNERSHIP_BODY',
                                            'lies with, an entity which is, from a country which shares a land border with India in accordance with O.M. issued by the Department of Expenditure, Ministry of Finance, Government of India, vide No. 7/10/2021-PPD(1) dated 23rd February, 2023 and subsequent amendments and clarifications thereto?'
                                        )}
                                    </label>
                                </span>
                                <GenericFormCard {...val} dropdownOptions={props.dropdownOptions} setDocuments={props.setDocuments} documents={props.documents} />
                            </>
                        ))}
                    </Card.Body>
                </>
            </div>
        </>
    );
}
