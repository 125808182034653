import React, { useEffect, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { formOptionsDTO } from './GenericFormCard.constants';
import Select from 'react-select';
import { getUserRoles } from '../../services/modulesServices/userManagement.api';
import { useTranslation } from 'react-i18next';

interface selectFieldProps {
    titleCode?: any;
    field: string;
    title: string;
    type?: string;
    options?: formOptionsDTO[];
    dropdownOptions?: any;
    isRequired?: boolean;
    handleDropdownChange?: (name: string, e: any, handleFormikChange: any) => void;
    additionalSection?: any;
    handleOnAssignmentChange?: (index: number, field: string, value: any) => void;
    index?: any;
    module?: string;
    isDisabled?: boolean;
}

interface itemProps {
    code?: string;
    name?: string;
    value?: string;
    label?: string;
}

export default function GenericCommonMultiSelector({
    titleCode,
    field,
    title,
    type,
    index,
    dropdownOptions,
    isRequired,
    additionalSection,
    handleOnAssignmentChange,
    module,
    isDisabled
}: selectFieldProps) {
    const { t } = useTranslation();
    const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<any>();

    const [rolesDropdownData, setRolesDropdownData] = useState<any>([]);

    useEffect(() => {
        if (module === 'usermanagement') {
            if (additionalSection && values[additionalSection][index]?.department?.code) {
                getUserRoles(values[additionalSection][index]?.department?.code)
                    .then(res => setRolesDropdownData(res.data))
                    .catch(err => console.log(err));
            }
        }
    }, [values[additionalSection][index]?.department?.code]);

    const customNoOptionsMessage = () => null; // Remove the "No options" message
    const customIndicatorSeparator = () => null; // Remove the separator
    const customDropdownIndicator = () => null;

    let selectedOptions;
    if (module === 'usermanagement' && additionalSection && type === 'multi' && values[additionalSection]?.[index]?.[field]) {
        selectedOptions = values[additionalSection][index][field]?.map((item: itemProps) => ({
            value: item.code,
            label: item.name
        }));
    } else {
        selectedOptions = values[field]?.map((item: itemProps) => ({
            value: item.code,
            label: item.name
        }));
    }

    const options = (rolesDropdownData as any)[field]?.map((item: itemProps) => {
        return { value: item.code, label: item.name };
    });

    const handleOnChange = (values: any) => {
        const selectedValues = values.map((item: itemProps) => ({ code: item.value, name: item.label }));

        if (additionalSection) {
            handleOnAssignmentChange && handleOnAssignmentChange(index, field, selectedValues);
        } else setFieldValue(field, selectedValues);
    };

    const customStyles = {
        menu: (provided: any) => ({
            ...provided,
            right: '0',
            fontSize: '14px',
            fontFamily: 'Inter',
            color: 'black'
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#E9ECEF' : 'white', // Change background color when disabled
            cursor: state.isDisabled ? 'not-allowed' : 'pointer', // Change cursor when disabled
            borderRadius: '10px',
            width: '100%'
        }),
        multiValue: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isDisabled ? 'white' : '#E9ECEF' // Change background color when disabled
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            fontSize: '16px'
        }),
        placeholder: (provided: any) => ({
            ...provided,
            // color: '#AAAAAA',
            paddingLeft: '15px'
        })
    };

    return (
        <>
            <label htmlFor={field} className='pb-2'>
                {titleCode ? t(titleCode) : title}
            </label>
            {isRequired && <span className='mandatory-indicator'>*</span>}
            {!additionalSection ? (
                <>
                    <Select
                        name={field}
                        options={options}
                        onBlur={() => {
                            setFieldTouched(field, true);
                        }}
                        className={!values.isUpdatable || isDisabled ? 'disable-multi-select' : 'multi-select-form'}
                        onChange={handleOnChange}
                        isMulti
                        value={selectedOptions}
                        isDisabled={!values.isUpdatable || isDisabled}
                        styles={customStyles}
                    />

                    <ErrorMessage name='selectedOptions' component='div' />
                </>
            ) : (
                <>
                    <Select
                        onChange={handleOnChange}
                        name={field}
                        options={options}
                        className={`form-control was-validated ${!values.isUpdatable || isDisabled ? 'disable-multi-select' : 'multi-select-form'} ${
                            (touched as any)?.[additionalSection]?.[index]?.[field] && (errors as any)?.[additionalSection]?.[index]?.[field] ? 'is-invalid' : ''
                        }  ${(touched as any)?.[additionalSection]?.[index]?.[field] && !(errors as any)?.[additionalSection]?.[index]?.[field] && isRequired && values.isUpdatable ? 'is-valid' : ''}`}
                        onBlur={() => setFieldTouched(`${additionalSection}[${index}].${field}`, true)}
                        isMulti
                        value={selectedOptions}
                        noOptionsMessage={customNoOptionsMessage}
                        placeholder='Please Select'
                        components={{
                            IndicatorSeparator: customIndicatorSeparator,
                            DropdownIndicator: customDropdownIndicator
                        }}
                        styles={customStyles}
                        isDisabled={!values.isUpdatable || isDisabled}
                    />

                    <ErrorMessage name={`${additionalSection}[${index}].${field}`}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                </>
            )}
        </>
    );
}
