import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";
import { formOptionsDTO } from "./GenericFormCard.constants";
import { useTranslation } from "react-i18next";

interface selectFieldProps {
    titleCode?: any;
    field: string;
    title: string;
    type?: string;
    options?: formOptionsDTO[];
    isRequired?: boolean;
    index?: any;
    handleOnAssignmentChange?: (index: number, field: string, value: any) => void;
    additionalSection?: string;
    module?: string;
    isDisabled?: boolean;
}

export default function GenericCommonTimePicker(props: selectFieldProps) {
    const { handleOnAssignmentChange, index, additionalSection, field, module, isDisabled } = props;
    const { t } = useTranslation();
    const { values, errors, touched, handleChange } = useFormikContext<any>();

    const handleOnTimeChange = (e: any) => {
        if (additionalSection) {
            handleOnAssignmentChange && handleOnAssignmentChange(index, props.field, e.target.value);
        } else handleChange(e);
    };

    return (
        <>
            <label htmlFor={props.field} className="pb-1">
                {props.titleCode ? t(props.titleCode) : props.title}
            </label>
            {props.isRequired && <span className="mandatory-indicator">*</span>}
            <Field
                type="time"
                name={props.field}
                id={props?.field}
                clearable
                inputVariant="outlined"
                className={`form-control was-validated date-picker mt-1 ${touched[props.field] && errors[props.field] ? "is-invalid" : ""}  ${touched[props.field] && props.isRequired && !errors[props.field] && values.isUpdatable ? "is-valid checkmark-position" : ""
                    }`}
                disabled={isDisabled}
                onChange={handleOnTimeChange}
            />
            <ErrorMessage name={props.field}>{(msg) => <span className="text-danger font-12">{msg}</span>}</ErrorMessage>
        </>
    );
}