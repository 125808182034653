import React, { useEffect, useState } from 'react';
import { Card, CardBody, Form, Image } from 'react-bootstrap';
import { LESSEE_DETAILS, ARCHITECT_DETAILS, DOCUMENTS_DETAILS, DIGITAL_SIGN_DETAILS } from './TechnicalClearanceFormPage.const';
import { useFormikContext } from 'formik';
import { Viewer, Worker, OpenFile } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import GenericPaymentStatus from './TechnicalClearencePayment/GenericPaymentStatus';
import { POST, devUrl, getProgressBarData, requestInfo } from '../../../services/modulesServices/userManagement.api';
import TechnicalServices from '../../../services/technicalServices/Technical.service';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { RoleInterface } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { getBPAList } from './TechnicalClearanceFormPage.controller';
import { useNavigate } from 'react-router-dom';
import BPASelectionTable from './BPASelectionTable.component';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericPopupModal from '../../../components/moduleCommonFormik/GenericModalPopup.component';
import { useTranslation } from 'react-i18next';
import PaymentDetails from '../../modules/EstateManagement/Allotment/PaymentDetails';
import GenericActionTable from '../../../components/moduleCommonFormik/GenericActionTable';
import { CHECKLIST_TABLE, CHECKLIST_TABLE_TC, paymentColumnsForTC } from '../../modules/EstateManagement/Allotment/Allotment.const';
import Editor from '../../../components/ContentEditor';
import GenericButtons from '../../../components/UI components/Buttons/GenericButtons';
import { download } from '../../../utils/Logo';
import ToolTipDetails from '../../../components/ToolTipFile';
import fileDown from '../../../assets/logos/download.svg';
interface BuildingSubPage {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    index: number;
    // setFormikValues: React.Dispatch<any>;
    employeeList: any;
    formData: any;
    previewData: any;
    applicationNumber?: string;
    appStatus: boolean;
    showProcess: boolean;
    activeStepper: string;
    allchecked: any;
    setAllChecked: React.Dispatch<any>;
    bpaData: any;
    isCancelBtn: boolean;
    setShowProcess: React.Dispatch<React.SetStateAction<boolean>>;
    allBPADetails: any;
    allEdcrDetails: any;
    setIsSelectedBPA: React.Dispatch<React.SetStateAction<string>>;
    applicationFee: any;
    sanctionFee: any;
    paymentRowData: any;
    content: any;
    setContent: React.Dispatch<React.SetStateAction<string>>;
}

export default function M_ScrutinyFormSubPage(props: BuildingSubPage) {
    const { t, i18n } = useTranslation();
    const { values, resetForm, setValues, setFieldValue } = useFormikContext<any>();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [isMultiLessee, setIsMultiLessee] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(true);
    const [auditTrial, setAuditTrial] = useState<any>(null);
    const [planBlobData, setPlanBlobData] = useState<any>(null);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [openSection, setOpenSection] = useState<string>('LESSEE_DETAITLS');
    const [gatewayData, setGatewayData] = useState<any>([]);
    const [checklistTableData, setChecklistTableData] = useState();

    const isRoleMD = !user?.roles?.filter((role: RoleInterface) => role.code === 'MD').length;
    const navigate = useNavigate();

    function handleChange(e: any) {
        if (e.target.checked) {
            props?.setAllChecked([...props?.allchecked, e?.target?.value]);
        } else {
            props?.setAllChecked(props?.allchecked.filter((item: any) => item !== e.target.value));
        }
    }

    const fetchGatewayData = async () => {
        try {
            const { data } = await POST(`/egov-mdms-service/v1/_search`, {
                RequestInfo: requestInfo(),
                MdmsCriteria: {
                    tenantId: 'ga',
                    moduleDetails: [
                        {
                            moduleName: 'egov-pg-service',
                            masterDetails: [
                                {
                                    name: 'Gateway',
                                    filter: '[?(@.active == true)]'
                                }
                            ]
                        }
                    ]
                }
            });
            let feeMasterDetails = data?.['MdmsRes']?.['egov-pg-service']?.['Gateway'];
            setGatewayData(feeMasterDetails || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const pdfUrlToBase64 = async (url: any) => {
        var bodyFormData = new FormData();
        bodyFormData.append('url', url);
        let data = '';
        TechnicalServices.getScrutinyFile(bodyFormData)
            .then((response: any) => {
                // handle the response
                if (response?.data) {
                    if (response?.data?.base64 !== '') {
                        setPlanBlobData(`data:application/pdf;base64, ${response?.data?.base64}`);
                        return;
                    }
                    setPlanBlobData('https://sujog-dev.odisha.gov.in/filestore/v1/files/id?fileStoreId=9450d85e-1768-49a5-93fb-32a8fffa98c2&tenantId=od');
                }
            })
            .catch(error => {});
    };

    const handleClose = () => {
        setIsShow(false);
    };

    const getAuditData = () => {
        if (!props?.applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.getAuditTrail(payload, props?.applicationNumber, 'ga')
            .then(res => {
                setAuditTrial(res?.data?.ProcessInstances);
            })
            .catch(err => console.log(err));
    };

    const getCheckListData = () => {
        if (!props?.applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.bpaCheckList(payload, props?.applicationNumber, 'WF_BPA_TC')
            .then(res => {
                const checklistDataWithIndex = res?.data?.checklistValidationDetails?.map((item: any, index: any) => ({
                    ...item,
                    index: index + 1
                }));
                setChecklistTableData(checklistDataWithIndex);
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        if (!!props?.allBPADetails) setIsMultiLessee(props?.allBPADetails.length >= 0);
    }, [props?.allBPADetails]);

    useEffect(() => {
        console.log(props?.previewData);
        if (!!props?.previewData?.additionalDetails?.planReport) {
            setTimeout(() => {
                pdfUrlToBase64(props?.previewData?.additionalDetails?.planReport || props?.formData?.additionalDetails?.planReport);
            }, 500);
        }
    }, [props?.previewData?.additionalDetails?.planReport]);

    useEffect(() => {
        fetchGatewayData();
        getAuditData();
        getCheckListData();
    }, []);

    useEffect(() => {
        try {
            // getAuditData()
            if (!!props?.applicationNumber) getProgressBarData(props?.applicationNumber).then(res => setProgressBarDetails(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [props?.applicationNumber]);

    const renderBildingPlanReportDetails = () => {
        return (
            <>
                {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                <div className='mt-3 ms-4 ps-4 d-flex justify-content-center '>
                    <div className='row col-sm-12'>
                        <div className='row col-sm-3'>
                            <label>{t('TC_EMP_DOC_LBL_SERVICE_TYPE', 'Service Type')}</label>
                            <div className='fw-bold'>{t('TC_EMP_DOC_SERVICE_TYPE', 'New Construction')}</div>
                        </div>
                        <div className='row col-sm-3'>
                            <label>{t('TC_EMP_DOC_LBL_PLAN_FILE', 'Plan File')}</label>
                            <div className='fw-bold'>
                                <a href={props?.previewData?.additionalDetails?.dxfFile || (props?.formData?.additionalDetails && props?.formData?.additionalDetails.updatedDxfFile)}>
                                    {t('TC_EMP_DOC_PLAN_FILE', 'Download Building Plan')}{' '}
                                </a>
                            </div>
                        </div>
                        <div className='row col-sm-3'>
                            <label>{t('ARC_DOC_LBL_SITE_PLAN_FILE', 'Site Plan')}</label>
                            <div className='fw-bold'>
                                <a href={props?.previewData?.additionalDetails?.sitePlan || (props?.formData?.additionalDetails && props?.formData?.additionalDetails.sitePlan)}>
                                    {t('ARC_DOC_SITE_PLAN_FILE', 'Download Site Plan')}{' '}
                                </a>
                            </div>
                        </div>
                        <div className='row col-sm-3'>
                            <label>{t('TC_EMP_DOC_LBL_SCRUTINY_STATUS', 'Scrutiny Status')}</label>
                            <div className={props?.formData?.additionalDetails?.status === 'Accepted' || props?.previewData?.additionalDetails?.status === 'Accepted' ? 'text-success' : 'text-danger'}>
                                {props?.formData?.additionalDetails?.status || props?.previewData?.additionalDetails?.status || 'Not Accepted'}
                            </div>
                        </div>
                    </div>
                    {/* </Card.Body>
                </Card> */}
                </div>
                <Card className='mt-3 card_border my-2 card_bg'>
                    <Card.Body>
                        <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js'>
                            <Viewer
                                fileUrl={planBlobData || []}
                                plugins={[
                                    // Register plugins
                                    getFilePluginInstance,
                                    defaultLayoutPluginInstance
                                ]}
                            />
                        </Worker>
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderBasicDetails = () => {
        return (
            <>
                {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                {LESSEE_DETAILS.map((val, key) => (
                    <GenericFormCard {...{ ...val }} key={key} />
                ))}
                {/* </Card.Body>
                </Card> */}
            </>
        );
    };

    const onDownload = (url: any) => {
        window.open(url);
    };

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '');
        const month = String(today.getMonth() + 1).padStart(2, '');
        const year = today.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const currentDate = getCurrentDate();

    const renderArchitectDetails = () => {
        const toolTipDetails = {
            position: 'bottom',
            content: '<p>This is system generated site plan from the scrutinized building plan</p>'
            // linkUrl: 'https://www.pollution.com',
            // linkText: 'Website Link'
        };

        console.log(!!values?.additionalDetails?.sitePlan, 'Siteplan');
        return (
            <>
                {ARCHITECT_DETAILS.map(val => (
                    // <Card className='card_border my-2'>
                    //     <Card.Body>
                    <GenericFormCard {...val} />
                    //     </Card.Body>
                    // </Card>
                ))}
                <div className='ps-3 me-3'>
                    {!!values?.additionalDetails?.sitePlan && (
                        <>
                            <div className='d-flex'>
                                Site Plan <span className=' text-danger '>*</span>
                                <ToolTipDetails toolTipDetails={toolTipDetails} />
                            </div>

                            <div
                                className={`btn btn-none fileUploadBox py-2 ps-2 p-4 m-1`}
                                style={{
                                    borderRadius: '12px',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#45852E',
                                    height: '5rem',
                                    width: window.innerWidth >= 1920 ? '645px' : '485px' // Adjusts width based on resolution
                                }}
                            >
                                <div className='d-flex align-items-center ms-0 ps-0' onClick={() => onDownload(values?.additionalDetails?.sitePlan)}>
                                    <div className='ms-2 text-start' style={{ padding: '8px' }}>
                                        <span>
                                            <div
                                                className='file-name uploaded-file-name text-white text-nowrap text-truncate'
                                                style={{ fontSize: '14px', lineHeight: '18px', marginBottom: '10px' }}
                                                title={'SitePlan.pdf'}
                                            >
                                                {`${values?.name}_SITEPLAN.PDF`}
                                            </div>
                                            <div className='text-white' style={{ fontSize: '12px', lineHeight: '18px' }}>
                                                {'4 MB'} . {currentDate}
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='me- d-flex'>
                                    <Image src={fileDown} height={20} onClick={() => onDownload(values?.additionalDetails?.sitePlan)} />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    };

    const downloadApplicationDocument = () => {
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${props?.previewData?.digitalSignedDoc}`;
        window.open(downloadFileUrl);
    };

    const renderDocumentDetails = () => {
        let modifiedArray = DOCUMENTS_DETAILS;
        if (!!props?.appStatus) {
            modifiedArray.map((v: any) => {
                v.formData.map((h: any) => (h.isDisabled = true));
            });
        }
        return (
            <>
                {modifiedArray.map(val => (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <GenericFormCard {...{ ...val }} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const renderDeclarationDetails = () => {
        return (
            <>
                <div className='ms-2 mt-2'>
                    {/* <Card className='card_border my-2'>
                    <Card.Body> */}
                    <div className='row ps-2 py-2'>
                        {/* <div className='formTitle m-0'>
                            {t('TC_EMP_LBL_SELF_DECLARATION', 'Self Declaration')}
                            <span className='text-danger'>*</span>
                        </div> */}
                    </div>
                    <div className='row d-flex m-0'>
                        <p className='fw-bold'>The Lessee does hereby affirm and declare that:</p>

                        <label className='mb-3 checkbox-container'>
                            {props.appStatus ? (
                                <input type='checkbox' id='myCheckbox' className='me-2' checked={props.appStatus} style={{ opacity: '0' }} />
                            ) : (
                                <input type='checkbox' id='myCheckbox' value='1' name='step_1' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                            )}
                            <span className={`${props.appStatus ? 'checkmarkDefault' : 'checkmark'} `}></span>
                            <span>
                                (i) The information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed
                                herein.
                            </span>
                        </label>

                        <label className='mb-3 checkbox-container'>
                            {props.appStatus ? (
                                <input type='checkbox' id='myCheckbox' className='me-2' checked={props.appStatus} style={{ opacity: '0' }} />
                            ) : (
                                <input type='checkbox' id='myCheckbox' value='2' className='me-2' name='step_2' onChange={handleChange} style={{ opacity: '0' }} />
                            )}
                            <span className={`${props.appStatus ? 'checkmarkDefault' : 'checkmark'} `}></span>
                            <span>
                                (ii) It has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation
                                proceedings.
                            </span>
                        </label>

                        <label className='mb-3 checkbox-container'>
                            {props.appStatus ? (
                                <input type='checkbox' id='myCheckbox' className='me-2' checked={props.appStatus} style={{ opacity: '0' }} />
                            ) : (
                                <input type='checkbox' id='myCheckbox' value='3' name='step_3' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                            )}
                            <span className={`${props.appStatus ? 'checkmarkDefault' : 'checkmark'} `}></span>
                            <span>
                                (iii) The technical clearance, construction license, as the case may be, shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the
                                information submitted under or along with this application.
                            </span>
                        </label>
                    </div>
                    {!!props?.previewData?.lesseeDigitalSignFlage ? (
                        <>
                            <div className='ps-2 pt-2 font-14 font-inter fw-bold m-0'>Digitally Signed</div>
                            <div className='ps-2 pb-2 font-14 font-inter'></div>
                            <div onClick={() => downloadApplicationDocument()} className='ps-2 d-flex align-items-center'>
                                <Image src={download} height={14} />
                                <div className='ps-2 font-14' style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>
                                    Download Application
                                </div>
                            </div>
                        </>
                    ) : (
                        <GenericButtons text='Sign the document' ClassName='px-3' />
                    )}
                    {/* </Card.Body>
                </Card> */}
                </div>
            </>
        );
    };

    const renderDigitalSign = () => {
        return (
            <>
                {DIGITAL_SIGN_DETAILS.map(val => (
                    <Card.Body>
                        <GenericFormCard {...val} />
                    </Card.Body>
                ))}
            </>
        );
    };

    const renderPayment = () => {
        const SANCTION_DETAILS = {
            txnId: props?.paymentRowData?.transactionNumber || '',
            auditDetails: { createdTime: props?.paymentRowData?.transactionDate || '' },
            txnStatus: 'SUCCESS',
            additionalDetails: { paymentReceiptDocRefId: props?.paymentRowData?.receiptId || '' }
        };

        // Function to format numbers with commas
        const formatNumberWithCommas = (number: any) => {
            return number.toLocaleString('en-IN');
        };

        // Format the BPAPaymentDetails array
        const formattedPaymentDetails = (props?.paymentRowData?.BPAPaymentDetails || []).map(
            (
                detail: {
                    principalAmount: any;
                    cgstPrincipal: any;
                    sgstPrincipal: any;
                    interestAmount: any;
                    penaltyAmount: any;
                    cgstPenalty: any;
                    sgstPenalty: any;
                    totalDueAmount: any;
                    paidAmount: any;
                },
                index: number
            ) => ({
                ...detail,
                index: index + 1,
                principalAmount: formatNumberWithCommas(detail.principalAmount),
                cgstPrincipal: formatNumberWithCommas(detail.cgstPrincipal),
                sgstPrincipal: formatNumberWithCommas(detail.sgstPrincipal),
                interestAmount: formatNumberWithCommas(detail.interestAmount),
                penaltyAmount: formatNumberWithCommas(detail.penaltyAmount),
                cgstPenalty: formatNumberWithCommas(detail.cgstPenalty),
                sgstPenalty: formatNumberWithCommas(detail.sgstPenalty),
                totalDueAmount: formatNumberWithCommas(detail.totalDueAmount),
                paidAmount: formatNumberWithCommas(detail.paidAmount)
            })
        );

        return (
            <>
                <PaymentDetails
                    columns={paymentColumnsForTC}
                    paymentData={formattedPaymentDetails}
                    // title={''}
                    selectedGateway={''}
                    setSelectedGateway={() => {}}
                    isPaid={props?.paymentRowData?.isPaymentRecived}
                    gatewayData={gatewayData}
                    transactionDetails={SANCTION_DETAILS}
                    totalLabel={'totalDueAmount'}
                    isVisiblePaid={true}
                    hideTitle={true}
                    isCardDisable={true}
                />
                {visible ? '' : <GenericPaymentStatus />}
            </>
        );
    };

    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            // `file.name` is the URL of opened file
            const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
            return `scrutiny_report.pdf`;
        }
    });

    const { DownloadButton } = getFilePluginInstance;
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Download: () => (
            <>
                <DownloadButton />
            </>
        )
    });

    const handelIsLesseeVisible = () => {
        setIsMultiLessee(false);
        navigate(-1);
    };

    const proceedToBPA = () => {
        props.setIsSelectedBPA(values?.edcrNo);
        setIsMultiLessee(false);
    };

    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar
    });
    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    const renderBPASelection = () => {
        if (!props.allBPADetails || props.allBPADetails.length <= 0)
            return (
                <>
                    <p className='text-center fw-bold pt-2'>No Data Available</p>
                </>
            );
        const bpaList = getBPAList(props?.allEdcrDetails);
        console.log(bpaList);
        return (
            <BPASelectionTable
                data={bpaList}
                selectedRow={values?.edcrNo}
                onSelectRow={row => {
                    setFieldValue('edcrNo', row.value);
                }}
            />
        );
    };

    const onSectionChange = (currentSection: string) => {
        setOpenSection(currentSection);
    };

    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={props?.setContent} content={props?.content} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <CardBody>
                    <div className='row'>
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3    align-items-center pb-2 '>
                            <input type='checkbox' id='Primary Assignment' name='assignments' defaultChecked={true} />
                            <label htmlFor='Primary Assignment' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold  '>
                                {t('TC_EMP_CONDITION_CHECKLIST_HEADING', 'Condition/Check list')}
                            </label>

                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE_TC} hideSearchBar={true} hideFooter={true} rowData={checklistTableData} />
                            </div>
                        </div>
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input
                                type='checkbox'
                                id='LESSEE_DETAITLS_Checkbox'
                                name='LESSEE_DETAITLS'
                                checked={openSection === 'LESSEE_DETAITLS'}
                                onChange={() => onSectionChange('LESSEE_DETAITLS')}
                            />
                            <label
                                htmlFor='LESSEE_DETAITLS_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('TC_EMP_HEADING_LESSEE_DETAILS', 'A - Lessee Details')}
                            </label>
                            {openSection === 'LESSEE_DETAITLS' && renderBasicDetails()}
                        </div>
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='ARCH_DETAITLS_Checkbox' name='ARCH_DETAITLS' checked={openSection === 'ARCH_DETAITLS'} onChange={() => onSectionChange('ARCH_DETAITLS')} />
                            <label
                                htmlFor='ARCH_DETAITLS_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('TC_EMP_HEADING_ARCH_DETAILS', "B - Architect's Details")}
                            </label>
                            {openSection === 'ARCH_DETAITLS' && renderArchitectDetails()}
                        </div>
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='BUILD_PLAN_Checkbox' name='BUILD_PLAN' checked={openSection === 'BUILD_PLAN'} onChange={() => onSectionChange('BUILD_PLAN')} />
                            <label
                                htmlFor='BUILD_PLAN_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('TC_EMP_HEADING_BUILDING_PLAN_SCRUTINY_REPORT', 'C - Building Plan Scrutiny Report')}
                            </label>
                            {openSection === 'BUILD_PLAN' && renderBildingPlanReportDetails()}
                        </div>
                        {/* <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='DOCUMENTS_Checkbox' name='DOCUMENTS' checked={openSection === 'DOCUMENTS'} onChange={() => onSectionChange('DOCUMENTS')} />
                            <label
                                htmlFor='DOCUMENTS_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('TC_EMP_HEADING_SUPPORTING_DOC', 'D - Supporting Documents')}
                            </label>
                            {openSection === 'DOCUMENTS' && renderDocumentDetails()}
                        </div> */}
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='PAYMENT_Checkbox' name='PAYMENT' checked={openSection === 'PAYMENT'} onChange={() => onSectionChange('PAYMENT')} />
                            <label
                                htmlFor='PAYMENT_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('TC_EMP_HEADING_PAYMENT_DETAILS', 'E - Payment Details')}
                            </label>
                            {openSection === 'PAYMENT' && renderPayment()}
                        </div>
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='DECLARATION_Checkbox' name='DECLARATION' checked={openSection === 'DECLARATION'} onChange={() => onSectionChange('DECLARATION')} />
                            <label
                                htmlFor='DECLARATION_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('TC_EMP_HEADING_SELF_DECLARATION', 'F - Self Declaration')}
                            </label>
                            {openSection === 'DECLARATION' && renderDeclarationDetails()}
                        </div>
                        <div className='col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3'>
                            <input type='checkbox' id='NOTES_Checkbox' name='NOTES' checked={openSection === 'NOTES'} onChange={() => onSectionChange('NOTES')} />
                            <label
                                htmlFor='NOTES_Checkbox'
                                className='d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                            >
                                {t('TC_EMP_HEADING_TC_ORDER', 'G - Technical Clearance Order')}
                            </label>
                            {openSection === 'NOTES' && renderNoteDetails()}
                        </div>
                    </div>
                </CardBody>
            </Card>
            <GenericPopupModal
                children={renderBPASelection()}
                isPrimaryDisabled={!values?.edcrNo}
                isVisible={isMultiLessee}
                title='Select eDCR Report'
                primaryAction={proceedToBPA}
                secondaryAction={handelIsLesseeVisible}
                primaryBtnTitle='Proceed'
                secondaryBtnTitle='Cancel'
                size='lg'
            />
        </>
    );
}
