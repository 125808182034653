import React from 'react'
import { Link } from 'react-router-dom'
import HeaderComponent from '../../components/header/Header.component'
import FooterComponent from '../../components/portalComponents/Footer.component'
import { useTranslation } from 'react-i18next'

export const TermsandConditionPage = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <HeaderComponent />
            <div className='mx-5 p-5 Termsandcondtion-container'>
                <div style={{ width: '764px', height: '14px' }}>
                    <p className='breadcrumbs'><Link className='text-decoration-none' to='/goa-idc' >{t("HOME", "Home")}</Link> / {t("TERMS_AND_CONDITIONS", "Terms & Conditions")}</p>
                </div>
                <div >
                    <p style={{ fontWeight: 400, fontSize: '48px', lineHeight: '62.4px', letterSpacing: '-0.96px', marginBottom: '32px' }}>{t("TERMS_AND_CONDITIONS", "Terms & Conditions")}</p>
                </div>
                <div className='col-8'>
                    <p className='descriptive-text'>{t("TAC_PARA1", "To avail the services offered at Goa Industrial development Corporation (Goa-IDC), you must agree to the following terms and conditions:")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA2", "Please read the term and conditions carefully. While using any current or future services offered by Goa-IDC, you will abide by these Terms & conditions, applicable to such service or business.")}</p>
                </div>
                <div className='col-8' style={{ borderBottom: '1px solid #E0E0E0' }}>
                    <p className='  label-text' >{t("PRIVACY_POLICY", "Privacy Policy")}</p>
                    <p className='descriptive-text'>{t("PLEASE_REVIEW_OUR", "Please review our ")}<Link to={'/goa-idc/privacypolicy'} style={{ textDecoration: 'underline' }}>{t("PRIVACY_POLICY", "Privacy Policy")}</Link>{t("TO_FULLY_UNDERSTAND", " to fully understand our practices.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("ELECTRONIC_COMMUNICATION", "Electronic Communication")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA3", "When you visit or send e-mails to us, you are communicating with us electronically. By communicating with us, you consent to receive communication from us electronically. We will communicate with you by e-mail or by posting notices on our website. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communication be in writing.")}</p>
                </div>
                <div className='col-8 divmargin' >
                    <p className='  label-text' >{t("PRICES", "Prices")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA4", "All prices posted on this website are subject to change without notice. Prices prevailing at commencement of placing the order will apply. Posted prices do not include convenience fee. In case there are any additional charges or taxes the same will be mentioned on the website.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("PAYMENTS", "Payments")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA5", "Payments can be done by Online through various payment gateways.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("CHANGES_AND_CANCELLTION", "Changes and Cancellations")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA6", "Changes and Cancellations are not allowed.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("REFUND", "Refund")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA7", "All types of payments are non-refundable.")}</p>
                </div>
                <p className='my-3 greenlabel-text'>{t("LICENSE_AND_WEBSITE_ACCESS", "License and Website Access ")}</p>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("GENERAL", "General")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA8", "Our website grants you a limited license to access and make personal use of this website and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of Goa.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("NO_LICENSE_FOR_COMMERCIAL_SALE", "No license for commercial sale")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA9", "This license does not include any resale or commercial use of this website or its content; any collection and use of any product listing, description, or pricing; copying of account information for the benefit of another merchant; or any use of data mining, or similar data gathering and extraction tools.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("NO_REPRODUCTION", "No Reproduction")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA10", "This website or any portion of this website may not be reproduced, duplicated, copies, sold, visited, or otherwise exploited for any commercial purpose without express written consent of our website.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("NO_FRAMING", "No Framing")}</p>
                    <p className='descriptive-text'>{t("TAC_PAR11", "You may not frame or utilize framing technologies to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of our website without the express written consent.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("META_TAGS", "Meta tags")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA12", "You may not use any meta tags or any other 'hidden text' utilizing Supreme Enterprises name or trademarks without the express written consent of our website. Any unauthorized use terminates the permission or license granted by our website.")}</p>
                </div>
                <p className='my-3 greenlabel-text'>{t("OTHER_CONTENTS", "Reviews, Comments, Communications, and other content ")}</p>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("NATURE_OF_CONTENTS", "Nature of Content")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA13", "Visitors to our website may post content and other communications; and submit suggestions, ideas, comments, questions or other information, as long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights to otherwise injuries to third party or objectionable and does not consist of or contains software virus, political campaigning, commercial solicitation, mass mailing or any form of spam.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("FALSE_INFORMATION", "False Information")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA14", "You may not use false email address, impersonate any person or entity, or otherwise mislead as to the origin of a card or other content.  Our website reserves the right (but not the obligation) to remove or edit such content but does not regularly review posted contents.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("RIGHTS_GRANTED", "Rights Granted")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA15", "If you do post content or submit material and unless we indicate otherwise, you grant our website a non- exclusive, royalty free, perpetual, irrevocable, and fully sub-licensed right to use, reproduce, modify, adapt, publish, translate, create derivative work from, distribute, and display such content throughout the world in any media. You grant our website the right to use the name that you submit in connection with such content if our website chooses.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("RIGHT_OWNED", "Right Owned")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA16I", "You represent and warrant that you own all the rights or otherwise or control all of the rights to the content that you post; that the content is accurate; that the use of the content to supply does not violate this policy and will not cause injury to any person or entity and that you will indemnify our website for all claims resulting from the content you supply. ")} {t("TAC_PARA16II", "Our website has the right but not the obligation to monitor and edit or remove any activity or content. Our website takes no responsibility and assumes no liability for any content posted by you or any third party.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("SITE_POLICIES", "Site Policies, Modification, and Severability")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA17", "Please review our other policies. We reserve the right to make changes to our website, policies, and these Terms and Conditions at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and will not affect the validity and enforceability of any remaining conditions.")}</p>
                </div>
                <p className='my-3 greenlabel-text'>{t("INTELLECTUAL_PROPERTY_RIGHTS", "Intellectual Property Rights ")}</p>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("COPYRIGHT_PROTECTION", "Copyright Protection:")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA18", "All content included on this site, such as text, graphics, logos, button icons, audio clips, digital downloads, data compilations and software, is the property of our website and protected by the Indian Copyright law. The compilation of all the content on this site is the exclusive property if our website and protected by Indian Copyright law. All software used in this site is the property of our website and is protected under the Indian Copyright law.")}</p>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("TRADEMARK", "Trademarks")}</p>
                    <div>
                        <span className='boldlabel-text'>{t("PROTECTED_MARK", "i. Protected Marks")}</span>
                        <p className='descriptive-text'>{t("TAC_PARA19", "Our website, and other marks indicated on our website are registered trademarks of our website")}</p>
                    </div>
                    <div>
                        <span className='boldlabel-text'>{t("PROTECTED_GRAPHICS", "ii. Protected Graphics")}</span>
                        <p className='descriptive-text'>{t("TAC_PARA20", "All our website graphics, logos, page headers, button icons, scripts and service names are trademarks or trade dress of our website. Our website trademarks and trade dress may not be used in connections with any product or service that is not of our website.")}</p>
                    </div>
                </div>
                <div className='col-8 divmargin'>
                    <p className='  label-text' >{t("GOVERNING_LAW", "Governing Law and Jurisdiction")}</p>
                    <p className='descriptive-text'>{t("TAC_PARA21", "These terms and conditions will be construed only in accordance with the laws of India. In respect of all matters/disputes arising out of, in connection with or in relation to these terms and conditions or any other conditions on this website, only the competent Courts at Nashik, Nashik shall have jurisdiction, to the exclusion of all other courts.")}</p>
                </div>
            </div>
            <FooterComponent />
        </>
    )
}
