import axios from 'axios';
import { DocumentDetails, dropdownOptionsInterface } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import { endpoints, devUrl, estateEndpoints, hdfcPaymentCheckout } from '../../../services/modulesServices/userManagement.api';
import { DOCUMENT_TYPE } from '../Migration/Migration.const';
import { getDropdownSelectedObject } from '../UserManagement/UserMangement.controller';
import { POST, requestInfo } from '../../../services/modulesServices/userManagement.api';
import { ALLOTTEE, TENANT_ID } from '../../../utils/constants';
import { toast } from 'react-toastify';
import { getDiffBWDates } from '../../../utils/utils';
import { convertDateFormatddMMyyyytoyyyyMMdd } from './LeaseDeed/LeaseDeed.controller';
import { getEntityDocumentDetails } from '../Migration/Migration.controller';
import { getTransferBuildUpArea, getTransferCertificate } from '../../../services/technicalServices/Technical.service';
import { RazorpayOptions } from '../UserManagement/types';

export const getLeaseDeedInitialData = async (res: any, resData: any) => {
    const initialData: any = {};
    initialData['plotNo'] = res?.plotNo;
    initialData['estateName'] = res?.estateName;
    initialData['phase'] = res?.phase;
    initialData['propertyType'] = res?.propertyType;
    initialData['plotArea'] = res?.plotArea;
    initialData['entityName'] = resData?.entityDetails?.entityName;
    initialData['email'] = resData?.entityDetails?.email;
    initialData['phoneNumber'] = resData?.entityDetails?.phoneNumber;
    initialData['panNumber'] = resData?.entityDetails?.panNumber;
    initialData['address1'] = resData?.entityDetails?.addresses[1]?.address1;
    initialData['address2'] = resData?.entityDetails?.addresses[1]?.address2;
    initialData['pinCode'] = resData?.entityDetails?.addresses[1]?.pinCode;
    initialData['district'] = resData?.entityDetails?.addresses[1]?.district;
    initialData['state'] = resData?.entityDetails?.addresses[1]?.state;
    initialData['country'] = resData?.entityDetails?.addresses[1]?.country;

    return initialData;
};

export const entityDocumentData = async (docs: any) => {
    const formatedEntityDocuments: any = [];
    try {
        if (typeof docs === 'string' && docs) {
            const { data } = await axios.get(devUrl + endpoints.fileStoreApi, { params: { tenantId: 'ga', fileStoreIds: docs } });
            return {
                ...data.fileStoreIds[0],
                documentSize: data.fileStoreIds[0].fileSize,
                documentUrl: data.fileStoreIds[0].url,
                documentName: data.fileStoreIds[0].tag,
                docReferenceId: data.fileStoreIds[0].id
            };
        } else {
            const refIDs = docs?.map((docItem: any, _: number) => docItem.docReferenceId).filter(Boolean);
            if (refIDs && refIDs.length) {
                const { data } = await axios.get(devUrl + endpoints.fileStoreApi, { params: { tenantId: 'ga', fileStoreIds: refIDs?.join() } });
                docs?.map((doc: any, index: number) => {
                    data.fileStoreIds.map((baseDoc: DocumentDetails) => {
                        if (baseDoc.id === doc.docReferenceId) {
                            formatedEntityDocuments.push({ ...doc, ...baseDoc, documentSize: baseDoc.fileSize, documentUrl: baseDoc.url });
                        }
                    });
                });
            }

            return formatedEntityDocuments;
        }
    } catch (e) {
        console.log(e);
    }
};

export const autoPopulatePlotDetails = async (plotId: any) => {
    const plotDetails = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotId}`, { RequestInfo: requestInfo() });
    const initialData: any = {};
    initialData['estateName'] = { code: plotDetails.data.plots[0].estateDetails.estateName || '', name: plotDetails.data.plots[0].estateDetails.estateName || '' };
    initialData['phase'] = { code: plotDetails.data.plots[0].phase || '', name: plotDetails.data.plots[0].phase || '' };
    initialData['plotNo'] = { code: plotDetails.data.plots[0].plotNo || '', name: plotDetails.data.plots[0].plotNo || '' };
    initialData['plotType'] = { code: plotDetails.data.plots[0].plotType || '', name: plotDetails.data.plots[0].plotType || '' };
    initialData['plotRate'] = plotDetails.data.plots[0].plotRate || '';
    initialData['plotArea'] = plotDetails.data.plots[0].plotAreaSqm || '';
    initialData['plotId'] = plotDetails.data.plots[0].uuid || '';
    initialData['enterpriseCategory'] = { code: plotDetails.data.plots[0].plotType || '', name: plotDetails.data.plots[0].plotType || '' };
    return initialData;
};

export const getVacantPlots = async (plotStatus?: string) => {
    try {
        const res = await POST(`/idc-estate-service/plot/_search_vacant_plot${plotStatus ? `?plotStatus=${plotStatus}` : ''}`, { RequestInfo: requestInfo() });
        if (res && res.data && res.data.plots.length > 0) {
            const plots = res.data.plots.map((e: any) => {
                e.id = e.uuid;
                return e;
            });
            return plots;
        }
    } catch (err) {
        console.log(err);
    }
};

export const convertToINR = (val: any) => {
    if (val === 'NaN' || val === undefined || val === null) {
        return 0;
    }

    if (typeof val === 'string' && val?.includes(',')) {
        return val;
    } else if (typeof val === 'number') {
        // for ceiling the value using toFixed here
        let value = +val?.toFixed(2);

        let [integerPart, decimalPart] = value?.toString().split('.') ?? ['', ''];
        let lastThree = integerPart.slice(-3);
        let otherNumbers = integerPart.slice(0, -3);
        if (otherNumbers !== '') {
            lastThree = ',' + lastThree;
        }
        let formattedNumber = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree || 0;
        if (decimalPart) {
            formattedNumber += '.' + decimalPart.padEnd(2, '0');
        } else {
            formattedNumber += '.00';
        }
        return formattedNumber;
    } else {
        return val;
    }
};

export const strToNum = (val: any) => {
    if (!val) return 0;
    if (typeof val === 'number') {
        return val;
    } else if (typeof val === 'string') {
        return +val.replace(/,/g, '');
    } else return val;
};

export const getCheckList = async (applicantUuid: string, plotUuid: string, buisnessService: string, spvNo?: any) => {
    const ChecklistData = await POST(
        endpoints.checklistValidationSearch + `?entityId=${applicantUuid}&plotId=${plotUuid}&businessServices=${buisnessService}${spvNo ? `&applicationNo=${spvNo}` : ''}`,
        {
            RequestInfo: requestInfo()
        }
    );
    const rowDataWithSerialNumber = ChecklistData.data.checklistValidationDetails.map((val: any, ind: number) => {
        return { serialNo: ind + 1, ...val };
    });
    return rowDataWithSerialNumber;
};

export const getNextActions = async (appNo: string) => {
    try {
        if (appNo) {
            const nextAction = await POST(endpoints.nextValidAction + `?businessId=${appNo}&tenantId=${TENANT_ID}`, { RequestInfo: requestInfo() });
            return nextAction.data;
        }
    } catch (e) {
        toast('Error while fetching data', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo-bar',
            type: 'error'
        });
    }
};

export const onAttachDocument = async (appNo: string, refId: string, designation: string, businessService: string, processInstanceId: any, works?: boolean) => {
    const payload: any = {
        RequestInfo: requestInfo(),
        idcWfDocument: {
            businessId: appNo,
            docRefId: refId,
            uploadedUserRole: designation,
            processInstanceId: processInstanceId
        }
    };

    try {
        const createdDoc = await POST(estateEndpoints.documentCreate, payload);
        const res = await POST((works ? endpoints.worksCorrespondenceDocument : endpoints.documentSeach) + `?businessId=${appNo}&businessService=${businessService}`, { RequestInfo: requestInfo() });
        return { documents: res?.data?.documents, uuid: createdDoc.data.uuid };
    } catch (err) {
        console.log(err);
    }
};

export const getHistory = async (appNo: string) => {
    if (appNo) {
        try {
            const { data } = await POST(`${endpoints.workflowSearch}&businessIds=${appNo}`, { RequestInfo: requestInfo() });
            return data.ProcessInstances;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
};

export const getWorkflowDocumentSearch = async (appNo: string, buisnessService: string) => {
    if (appNo) {
        try {
            const response = await POST(endpoints.documentSeach + `?businessId=${appNo}&businessService=${buisnessService}`, { RequestInfo: requestInfo() });
            return response?.data?.documents || [];
        } catch (e) {}
    }
};

export const fetchFeeData = async (processType: any, feeTypes: any, plotId: any) => {
    const { data } = await POST(endpoints.feeMasterDetailsSearch + `?processTypes=${processType}&feeTypes=${feeTypes}&plotId=${plotId}`, {
        RequestInfo: requestInfo()
    });
    let feeMasterDetails = data.feeMasterDetails.map((obj: any, i: any) => {
        const total = (+obj.amount || 0) + (+obj.cgstAmount || 0) + (+obj.sgstAmount || 0);
        return {
            ...obj,
            index: i + 1,
            principalAmount: obj.amount,
            sgstPrincipal: obj.sgstAmount,
            cgstPrincipal: obj.cgstAmount,
            totalDueAmount: total,
            amount: convertToINR(obj.amount),
            sgstAmount: +obj.sgstAmount === 0 ? '--' : convertToINR(obj.sgstAmount),
            cgstAmount: +obj.cgstAmount === 0 ? '--' : convertToINR(obj.cgstAmount),
            total: convertToINR(total)
        };
    });
    return { feeMasterDetails: feeMasterDetails, totalPaidAmount: data.totalAmount };
};

export const fetchGatewayData = async () => {
    const { data } = await POST('/egov-mdms-service/v1/_search', {
        RequestInfo: requestInfo(),
        MdmsCriteria: {
            tenantId: 'ga',
            moduleDetails: [
                {
                    moduleName: 'egov-pg-service',
                    masterDetails: [
                        {
                            name: 'Gateway',
                            filter: '[?(@.active == true)]'
                        }
                    ]
                }
            ]
        }
    });
    let feeMasterDetails = data?.['MdmsRes']?.['egov-pg-service']?.['Gateway'];
    return feeMasterDetails;
};

export const paymentCheck = async (paymentTypes: any, plotId: any, applicationId?: any, entityId: any = null, isMultipleOrder?: boolean) => {
    //isMultipleOrder: Incase of multiple order, params to be paased are paymentTypes and application ID only, for special transfer and misc application
    let paymentCheck: any = {};
    let transactionDetails;
    let newplotId = Array.isArray(plotId) ? plotId.join(',') : plotId;

    const userId = !entityId ? JSON.parse(localStorage.getItem('user') || '{}')?.entityId : entityId;

    console.log(
        'payment url',
        endpoints.paymentDetailsSearch +
            `?paymentTypes=${paymentTypes}${!isMultipleOrder ? `&entityIds=${userId}` : ''}${!isMultipleOrder ? `&plotIds=${newplotId}` : ''}${applicationId ? `&applicationIds=${applicationId}` : ''}`
    );
    console.log('isMultiple', isMultipleOrder);

    const { data } = await POST(
        endpoints.paymentDetailsSearch +
            `?paymentTypes=${paymentTypes}${!isMultipleOrder ? `&entityIds=${userId}` : ''}${!isMultipleOrder ? `&plotIds=${newplotId}` : ''}${
                applicationId ? `&applicationIds=${applicationId}` : ''
            }`,
        {
            RequestInfo: requestInfo()
        }
    );

    if (data.payments?.length) {
        let paymentDetails = data?.payments || [];
        let total = 0;
        const paidData = paymentDetails?.map((obj: any, i: any) => {
            total += obj.totalDueAmount || 0;
            return {
                ...obj,
                index: i + 1,
                feeType: obj.paymentType,
                // feeTypeDisplayName: val.paymentTypeDisplayName,
                amount: convertToINR(obj.principalAmount),
                sgstAmount: +obj.sgstPrincipal === 0 ? '--' : convertToINR(obj.sgstPrincipal),
                cgstAmount: +obj.cgstPrincipal === 0 ? '--' : convertToINR(obj.cgstPrincipal),
                feeTypeDisplayName: obj.paymentTypeDisplayName,
                total: convertToINR(obj.totalDueAmount)
            };
        });

        transactionDetails = {
            txnId: paymentDetails?.[0]?.payment?.transactionNumber,
            auditDetails: { createdTime: paymentDetails?.[0]?.payment?.transactionDate },
            txnStatus: 'SUCCESS',
            invoiceRefId: paymentDetails?.[0]?.invoiceRefId
        };

        paymentCheck = {
            isPaid: true,
            paymentTried: true,
            totalPaidAmount: total,
            transactionDetails: transactionDetails,
            paymentData: paidData
        };
        return paymentCheck;
    }
    return paymentCheck;
};

export const paymentPayload = (applicationDetails: any, plotUuid: any, totalPaidAmount: any, url: any, info: any, selectedGateway: any, periodFrom: any, periodTo: any, paymentData: any) => {
    return {
        RequestInfo: requestInfo(),
        applicationNumber: applicationDetails?.applicationNo,
        plotId: plotUuid,
        totalPaidAmount: +totalPaidAmount,
        callbackUrl: url,
        productInfo: info,
        gateway: selectedGateway,
        periodFrom: periodFrom,
        periodTo: periodTo,
        payNowPaymentDetails: paymentData.map((obj: any) => ({
            scheduledPaymentDetails: {
                ...obj,
                paymentType: obj.feeType || obj.paymentType,
                principalAmount: obj.principalAmount,
                cgstPrincipal: obj.cgstPrincipal,
                sgstPrincipal: obj.sgstPrincipal,
                totalDueAmount: parseFloat(((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2)),
                paidAmount: parseFloat(((+obj.principalAmount || 0) + (+obj.cgstPrincipal || 0) + (+obj.sgstPrincipal || 0)).toFixed(2))
            }
        })),

        applicationId: applicationDetails?.uuid
    };
};

export const getOptions = (amount: number, user: any, description: string, orderId: string, callbackUrl: string, key: any) => {
    const options: RazorpayOptions = {
        key: key, // Enter the Key ID generated from the Dashboard
        amount: amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: 'INR',
        name: (user?.firstName || '') + ' ' + (user?.lastName || ''), // your business user.
        description: description,
        image: 'https://example.com/your_logo',
        order_id: orderId, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: callbackUrl,

        // prefill: {
        //     // We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        //     name: (user?.firstName || '') + ' ' + (user?.lastName || ''), // your customer's name
        //     email: user?.emailId,
        //     contact: user?.mobileNumber // Provide the customer's phone number for better conversion rates
        // },
        notes: {
            address: 'Razorpay Corporate Office'
        },
        theme: {
            color: '#3399cc'
        }
    };
    return options;
};

export const loadRazorpay = () => {
    const script = document.createElement('script');
    script.src = hdfcPaymentCheckout();
    script.async = true;
    document.body.appendChild(script);
};

export const fetchLeaseDurationDoc = async (plotUuid: string, uuid: string) => {
    //Fetch Lease Duration and Doc.
    try {
        let { data }: any = plotUuid && (await POST(endpoints.leaseDeedSearch + `?entityIds=${uuid}&plotIds=${plotUuid}`, { RequestInfo: requestInfo() }));
        let remainingTerm: any, deedDoc: any, deedExecutionDate: any;

        if (data?.deedDetails[0]?.termEndDate) {
            remainingTerm = getDiffBWDates(new Date(), convertDateFormatddMMyyyytoyyyyMMdd(data?.deedDetails[0]?.termEndDate));
            if (!remainingTerm) remainingTerm = 'Expired';
            deedDoc = data?.deedDetails[0]?.executedIndentureRefId && (await getEntityDocumentDetails({ docReferenceId: data?.deedDetails[0]?.executedIndentureRefId }));
            deedExecutionDate = data?.deedDetails[0]?.deedExecutionDate;
        }

        const { data: isSpecialProject } = await POST(endpoints.leaseSpecialProject + `?entityIds=${uuid}&plotIds=${plotUuid}`, { RequestInfo: requestInfo() });
        const { data: buildUpArea }: any = plotUuid && (await getTransferBuildUpArea(uuid, plotUuid, { RequestInfo: requestInfo() }));

        const certificate = await getTransferCertificate(uuid, plotUuid, {
            RequestInfo: requestInfo()
        });
        const certificateDoc = certificate.data?.documents[0]?.fileStoreId && (await getEntityDocumentDetails({ docReferenceId: certificate.data.documents[0].fileStoreId }));

        const landPremiumAmt = await POST(endpoints.scheduledPaymentDetails + `?entityId=${uuid}&plotId=${plotUuid}`, {
            RequestInfo: requestInfo()
        });

        return {
            termReamainingDuration: remainingTerm,
            leaseDeed: deedDoc,
            isSpecialProject: isSpecialProject?.enterpriseDetails[0]?.specialProject,
            isBuiltUpAreaUtilised: Number(buildUpArea) > 30 || null,
            occupancyCertificate: certificateDoc || null,
            transferOrLandPremium: !landPremiumAmt.data.isOveralPaymentPending,
            deedExecutionDate: deedExecutionDate
        };
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const getQueryParams = (searchParams: any) => {
    const params: any = {};
    for (const [key, value] of searchParams.entries()) {
        params[key] = value;
    }
    return params;
};

export const getSuccessMessage = (action: string, applicationNumber: any) => {
    let successMessage;
    if (
        action === 'FORWARD_TO_DGM' ||
        action === 'FORWARD_TO_GM' ||
        action === 'FORWARD_TO_MD' ||
        action === 'FORWARD_TO_AM' ||
        action === 'FORWARD_TO_LA' ||
        action === 'FORWARD_TO_ARM' ||
        action === 'FORWARD_TO_DGM_ENG' ||
        action === 'APPROVE_FORWARD_TO_CAO' ||
        action === 'APPROVE_FORWARD_TO_MD' ||
        action === 'FORWARD_TO_GME' ||
        action === 'FORWARD_TO_CAO' ||
        action === 'FORWARD_TO_ASST_ACC' ||
        action === 'FORWARD_TO_ACC_AM'
    ) {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} forwarded successfully`;
    } else if (
        action === 'RETURN_TO_AM' ||
        action === 'RETURN_TO_DGM' ||
        action === 'RETURN_TO_GM' ||
        action === 'RETURN_TO_LA' ||
        action === 'SEND_BACK_TO_DGM' ||
        action === 'SEND_BACK_TO_GM' ||
        action === 'SEND_BACK_TO_AM' ||
        action === 'RETURN_TO_FM' ||
        action === 'RETURN_TO_ARM' ||
        action === 'RETURN_TO_FM_HQ' ||
        action === 'RETURN_TO_ARM_HQ' ||
        action === 'RETURN_TO_FM_SITE' ||
        action === 'RETURN_TO_ACC_AM' ||
        action === 'FORWARD_TO_ASST_ACC' ||
        action === 'RETURN_TO_CAO'
    ) {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} sent back successfully`;
    } else if (action === 'APPROVE') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} approved successfully`;
    } else if (action === 'REJECT') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} rejected successfully`;
    } else if (action === 'SUBMIT') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} submitted successfully`;
    } else if (action === 'ACCEPT_REJECTION') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} for rejection accepted`;
    } else if (action === 'CANCEL') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} cancelled successfully`;
    } else if (action === 'FORWARD') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} created successfully`;
    } else if (action === 'DISQUALIFY') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} disqualified successfully`;
    } else if (action === 'INITIATE_REVISION') {
        successMessage = `Revision initiated for Application${applicationNumber ? ' Number ' + applicationNumber : ''} successfully`;
    } else if (action === 'CREATE') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} created successfully`;
    } else if (action === 'ACCEPT') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} accepted successfully`;
    } else if (action === 'CLOSE') {
        successMessage = `Application${applicationNumber ? ' Number ' + applicationNumber : ''} closed successfully`;
    }
    return successMessage;
};

export const downloadPaymentReceipt = async (refId?: string) => {
    const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${refId}`;
    window.location.href = downloadFileUrl;
};
