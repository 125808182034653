import * as Yup from 'yup';
import { CONSTANT_REGEX_SAMPLE } from '../../utils/constants';

export const Clearance_Form_Initial_Data = {
    name: '',
    email: '',
    id: '',
    appId: '',
    gender: '',
    phone: '',
    panNo: '',
    pincode: '',
    district: '',
    state: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    occupancyType: '',
    occupancy: '',
    plotNo: '',
    estate: '',
    phase: '',
    propertType: '',
    plotArea: '',
    arcName: '',
    certificate2: [],
    certificate1: [],
    serviceType: '',
    licenseeType: '',
    licenseeSubType: '',
    CouncilArcNo: '',
    buildingPlan: [],
    sitePlan: [],
    document1: [],
    document2: [],
    document3: [],
    token: '',
    cert: '',
    pin: '',
    documents: [],
    forwardTo: '',
    comments: '',
    CONSTRUCTION_LICENSE_ORDER: [],
    TECHNICAL_CLEARANCE_ORDER: [],
    COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER: [],
    REVISED_PLANS_INCORPORATING_DEVIATIONS_MADE_DURING_THE_EXECUTION: [],
    STRUCTURAL_STABILITY_CERTIFICATE: [],
    FIELD_INSP_SITEPLAN: [],
    FIELD_INSP_SITEPLAN_OTHER: [],
    isEditableFile: true,
    FIRE_NOC: [],
    FACTORIES_AND_BOILERS_NOC: [],
    planapprovedNo: '',
    planapprovedDate: '',
    clvalidation: true,
    notvalidreason: '',
    appliedforoccupancy: false,
    clearedthedump: false,
    sscertificatesubmission: false,
    ccsubmission: false,
    otherremark: ''
};

export const CLEARANCE_INITIAL_DATA = {
    id: '',
    appId: '',
    name: '',
    gender: '',
    phone: '',
    panNo: '',
    pincode: '',
    district: '',
    state: '',
    country: '',
    addressLine1: '',
    addressLine2: '',
    arcName: '',
    certificate2: [],
    certificate1: [],
    serviceType: '',
    licenseeType: '',
    licenseeSubType: '',
    CouncilArcNo: '',
    buildingPlan: [],
    sitePlan: [],
    document1: [],
    document2: [],
    document3: [],
    token: '',
    cert: '',
    pin: '',
    documents: [],
    forwardTo: '',
    comments: ''
};

export const VALIDATION_ORDER_COMPLETION_FORM: { [index: string]: any } = {
    step_1: Yup.object({
        far: Yup.string().required('FAR is required'),
        coverageArea: Yup.number().required('Coverage Area is required').positive().integer(),
        coveragePercent: Yup.number().required('Coverage % is required').positive().integer().max(100),
        blocks: Yup.array().of(
            Yup.object({
                floors: Yup.array().of(
                    Yup.object({
                        floorType: Yup.string().required('Floor Type is required'),
                        floorNumber: Yup.string().required('Floor Number is required'),
                        builtUpArea: Yup.string().required('Built Up Area is required')
                    })
                )
            })
        )
    }),
    step_3: Yup.object({
        CONSTRUCTION_LICENSE_ORDER: Yup.array().min(1).required(),
        TECHNICAL_CLEARANCE_ORDER: Yup.array().min(1).required(),
        COMPLETION_CERTIFICATE_ISSUED_BY_ARCHITECT_ENGINEER: Yup.array().min(1).required(),
        STRUCTURAL_STABILITY_CERTIFICATE: Yup.array().min(1).required()
    })
};

export const stepsDetailsForOrderCompletionforFI = [
    {
        name: 'Occupancy Details',
        code: 'OCCUPANCY_DETAILS',
        titleCode: 'OCCUPANCY_DETAILS',
        index: 1,
        isActive: true
    },
    // {
    //     name: 'Lessee Details',
    //     code: 'LESSEE_DETAITLS',
    //     titleCode: 'LESSEE_DETAITLS',
    //     index: 2,
    //     isActive: false
    // },
    {
        name: 'Plot Details',
        code: 'PLOT_DETAILS',
        titleCode: 'PLOT_DETAILS',
        index: 2,
        isActive: false
    },
    {
        name: 'Supporting Documents',
        code: 'DOCUMENTS',
        titleCode: 'Supporting Documents',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'Self Declaration',
        name: 'Self Declaration',
        code: 'DECLARATION',
        index: 4,
        isActive: false
    },
    {
        name: 'Field Inspection Details',
        code: 'INSPECTION_DETAILS',
        titleCode: 'INSPECTION_DETAILS',
        index: 5,
        isActive: false
    },
    {
        name: 'Questionnaire',
        code: 'QUESTIONNAIRE',
        titleCode: 'QUESTIONNAIRE',
        index: 6,
        isActive: false
    }
    // {
    //   name: "NOC Details",
    //   code: "NOC_DETAILS",
    //   titleCode: "NOC_DETAILS",
    //   index: 7,
    //   isActive: false
    // },
];

export const stepsDetailsForOrderCompletion = [
    {
        name: 'Occupancy Details',
        code: 'OCCUPANCY_DETAILS',
        titleCode: 'OCCUPANCY_DETAILS',
        index: 1,
        isActive: true
    },
    // {
    //     name: 'Lessee Details',
    //     code: 'LESSEE_DETAITLS',
    //     titleCode: 'LESSEE_DETAITLS',
    //     index: 2,
    //     isActive: false
    // },
    {
        name: 'Plot Details',
        code: 'PLOT_DETAILS',
        titleCode: 'PLOT_DETAILS',
        index: 2,
        isActive: false
    },
    {
        name: 'Supporting Documents',
        code: 'DOCUMENTS',
        titleCode: 'Supporting Documents',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'Self Declaration',
        name: 'Self Declaration',
        code: 'DECLARATION',
        index: 4,
        isActive: false
    }
];

export const stepsDetailsForFieldInspection = [
    {
        name: 'Inspection Details',
        code: 'INSPECTION_DETAILS',
        titleCode: 'INSPECTION_DETAILS',
        index: 1,
        isActive: true
    },
    {
        name: 'Notification Details',
        code: 'NOTIFICATION_DETAILS',
        titleCode: 'NOTIFICATION_DETAILS',
        index: 2,
        isActive: true
    }
];
