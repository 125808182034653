import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import HeaderComponent from '../../../../../components/header/Header.component';
import Loading from '../../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../../components/moduleCommonFormik/GenericHeader';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../../components/Workflow/NotingWrapper';
import { endpoints, getArchitectData, POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../../../utils/Logo';
import { dropdownDataOptions } from '../../../Migration/Migration.controller';
import { DocumentInfo } from '../../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { TENANT_ID } from '../../../../../utils/constants';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';
import GenericPopupModal from '../../../../../components/moduleCommonFormik/GenericModalPopup.component';
import ScrollToTop from '../../../../../components/ScrollToTop';
import GenericFooterComponent from '../../../../../components/moduleCommonFormik/GenericFooter.component';
import GenericPDFViewer from '../../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../../components/fileviewer/GenericImageViewer';
import PopUps from '../../../../../components/PopUps';
import activeArrow from '../../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../../assets/logos/page left arrow.svg';
import ArchitectCorrespondence from './ArchitectCorrespondence';
import { getHistory, getNextActions, getWorkflowDocumentSearch, onAttachDocument } from '../../../EstateManagement/EstateManagement.controller';
import { CORRESPONDENCE_TABLE } from '../../../EstateManagement/Allotment/Allotment.const';
import { getArcInitialValue } from '../ArchitectData';

const ArchitectWorkflowMain = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const totalPages = 2;
    const [modalData, setModalData] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [architectData, setArchitectData] = useState<any>(null);

    const [dropdownData, setDropdownData] = useState<any>(null);
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [tabelData, setTabelData] = useState<any>([]);

    let navigate = useNavigate();
    const { t } = useTranslation();
    const location: any = useLocation();
    let user = localStorage.getItem('currentUserAssignment');
    const [searchParams] = useSearchParams();
    const applicationNumber: any = searchParams.get('applicationNumber');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: false,
            noting: {},
            text: ''
        },
        onSubmit: () => {},
        validationSchema: null
    });
    const { values, setValues, errors } = formik;

    // for details
    useEffect(() => {
        if (note && action && architectData && nextValidAction) {
            onHandlePrimaryAction({ action: action }, note);
        }
    }, [architectData, nextValidAction]);

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    setIsLoading(true);
                    const response = await getArchitectData(applicationNumber);
                    console.log(response);
                    // setUserRegistrationNo(response.data.Employees[0].user.registrationNumber);
                    setArchitectData(response.data.Employees[0]);
                    if (response) {
                        // setFileStoreId(response?.data?.Employees[0]?.consolidatedDocRefId);
                    }
                    setIsLoading(false);
                } catch (e: any) {
                    console.log(e);
                    setIsLoading(false);
                    let errMsg = e?.response?.data?.errorMessage || 'Something Went Wrong';
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [location.search]);

    useEffect(() => {
        const reverseDocumentDetails = documentDetails?.reverse();
        const tableData = async () => {
            const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${ind + 1}` };
            });
            const reverseData = responseTableData.reverse();
            setTabelData(reverseData);
        };
        if (activeIndex === 2) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                setIsLoading(true);
                const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction?.businessService);
                setDocumentDetails(docs);

                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                console.log(e);
                let errMsg = e?.response?.data?.errorMessage || 'Something Went Wrong';
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (architectData && nextValidAction) {
            getCorrespondenceDocument();
            setApplicationResponseData(architectData, applicationNumber);
        }
    }, [nextValidAction, architectData]);

    const setApplicationResponseData = async (architectResponse: any, applicationNo: any) => {
        try {
            if (architectResponse) {
                console.log('surrwnderRes', architectResponse);

                const initData = await getArcInitialValue(architectResponse, dropdownData);
                console.log('initdata', initData);

                setValues((prev: any) => ({
                    ...prev,
                    ...initData
                }));
            }
            console.log(values);
        } catch (e) {
            console.log(e);
            // let errMsg = e?.response?.data?.errorMessage || e?.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: e, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const dropdownOption = await dropdownDataOptions();
                setDropdownData(dropdownOption);

                const nextActions = await getNextActions(applicationNumber);
                setNextValidAction(nextActions);

                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const payload = {
            RequestInfo: requestInfo(),
            isStateUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            Employees: [architectData]
        };

        if (!values.notes && !note) {
            if (!comment) {
                setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }

        try {
            const res = await POST(endpoints.employeeWorkflow, payload);
            const msg = res.data.processInstances[0]?.assignes?.[0]?.name
                ? `${t('ACTION_SUCCESS_SENT', 'Action Successful! Application send to')} ${res.data.processInstances[0]?.assignes[0]?.name}`
                : `${t('ACTION_SUCCESS', 'Action Successful!')}`;
            setModalDetails({ show: true, title: 'SUCCESS', para: msg, img: check, type: 'success', reUpload: 'OK' });
        } catch (e: any) {
            console.log('errorrrrr', e);
            let errMsg = e?.response?.data?.errorMessage || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={t('ARCHITECT_ONBOARDING', 'Architect Onboarding')}
                            additionalClass='sub-text-style'
                            bottomSubText={`Type : Architect Onboarding, No : ${applicationNumber} `}
                        />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('ARCHITECT_ONBOARDING', 'Architect Onboarding')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : Architect Onboarding, No : ${applicationNumber} `}
                                    />

                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={!nextValidAction?.nextValidAction.length}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && <ArchitectCorrespondence dropdownData={dropdownData} fileStoreId={fileStoreId} />}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    <GenericFooterComponent
                        activeIndex={activeIndex}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={() => {}}
                        applicationNo={applicationNumber || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                        extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                    />

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                </>
            )}
        </>
    );
};

export default ArchitectWorkflowMain;
