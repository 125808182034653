import { requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { AUTHORIZED_PERSON_TITLE, CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { entityDocumentDataMigration } from '../../Migration/Migration.controller';
import { getPlotDetails } from '../Allotment/Allotment.controller';
import { options } from '../SubLease/SubLeaseConst';

const userType = JSON.parse(localStorage.getItem('user') || '{}')?.type;

export const SURRENDER_STEPS_DETAILS = [
    {
        titleCode: 'PLOT_DETAILS',
        name: 'Plot Details',
        index: 1,
        isActive: false
    },
    {
        titleCode: 'SURRENDER_DETAILS',
        name: 'Surrender Details',
        index: 2,
        isActive: false
    },
    {
        titleCode: 'ENTERPRISE_DETAILS',
        name: 'Enterprise Details',
        index: 3,
        isActive: false
    },
    {
        titleCode: 'SELF_DECLARATION',
        name: 'Self Declaration',
        index: 4,
        isActive: false
    },
    {
        titleCode: 'PAYMENT_DETAILS',
        name: 'Payment Details',
        index: 5,
        isActive: false
    }
];

export const SURRENDER_LESSEE_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Name',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: '',
        title: 'Postal Address',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                field: 'pinCode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Details of the Authorized Person',
        title: "Authorized Person's Details",
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                title: 'First Name',
                field: 'authorizedPersonFirstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Last Name',
                field: 'authorizedPersonLastName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Primary Mobile Number',
                field: 'pmobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'Alternative Mobile Number',
                field: 'alternateMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },

            {
                title: 'Email ID',
                field: 'authorizedEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6 me-3',
                isDisabled: true
            },

            {
                title: 'The letter / resolution / power of attorney authorising the authorised person',
                titleCode: 'Please attach the letter/resolution/power of attorny ',
                field: 'letter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                entityFileData: {
                    documentNamePrefix: 'authorizedPersonFirstname',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                }
            }
        ]
    }
];

export const SURRENDER_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'SURRENDER_REASON',
                title: 'Reason for seeking the Surrender',
                field: 'surrenderReason',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-12',
                isRequired: true,
                isDisabled: userType !== 'ENTERPRISE' ? true : false
            },
            {
                titleCode: 'DEED',
                title: 'Lease Deed/Extension of Lease/Tripartite Deed/Addendum',
                field: 'lesseeDeedOfExtension',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isDisabled: true
            }
        ]
    }
];
export const ENTERPRISE_DETAILS: FormDataDTO[] = [
    {
        title: 'Enterprise Details',
        formData: [
            {
                titleCode: 'DATE_OF_POSSESSION',
                title: 'Date of Possession',
                field: 'possessionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                class: 'me-1 col-6'
            },
            {
                titleCode: 'LESSEE_FINANCING_OBTAINED',
                title: 'Lessee has obtained any financing for the Enterprise from any Financing Institution',
                field: 'financingObtained',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                class: 'me-1 col-6',
                isDisabled: true,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            }
        ]
    }
];

export const SURRENDER_PAYLOAD = (values: any, isFinalSubmit: boolean, plotUuid: any, userData: any, resData: any = [], applicationNo: string, exisBankResData: any) => {
    const mortgageClosureData = exisBankResData.map((val: any, ind: number) => {
        return {
            bankMortgageDetails: { ...val },
            document: {
                documentName: values?.existingMortgageDetails?.[ind]?.existingNocDocument && values?.existingMortgageDetails[ind]?.existingNocDocument?.documentName,
                docReferenceId: values?.existingMortgageDetails?.[ind]?.existingNocDocument && values?.existingMortgageDetails[ind]?.existingNocDocument?.docReferenceId
            }
        };
    });
    const onCreateApplication = {
        RequestInfo: requestInfo(),
        applicationType: 'SURRENDER',
        appliedPlotId: plotUuid,
        enterpriseEntity: { ...userData },
        ...resData,
        isFinalSubmit: isFinalSubmit
    };

    const applicationUpdatePayload = {
        ...resData,
        modificationDetails: {
            ...resData?.modificationDetails,
            modificationType: 'SURRENDER',
            uuid: resData ? resData?.modificationDetails?.uuid : null,
            requestDetails: {
                reason: values?.surrenderReason,
                mortgageClosureRequests: [...(mortgageClosureData?.length ? mortgageClosureData : resData?.modificationDetails?.requestDetails?.mortgageClosureRequests ?? [])]
            }
        }
    };

    const update = {
        ...onCreateApplication,
        ...applicationUpdatePayload
    };
    if (!applicationNo) {
        return onCreateApplication;
    } else {
        return update;
    }
};
export const SURRENDER_CORRESPONDENCE = [
    {
        title: 'A1 - Lessee Details',
        field: 'lesseeDetails'
    },
    {
        title: 'A2 - Surrender Details',
        field: 'surrenderDetails'
    },
    {
        title: 'B - Plot Details',
        field: 'plotDetails'
    },
    {
        title: 'C - Enterprise Details',
        field: 'enterpriseDetails'
    },
    {
        title: 'D - Self Declaration',
        field: 'selfDeclaration'
    },
    {
        title: 'E - Payment Details',
        field: 'paymentDetails'
    },
    {
        title: 'F - Surrender Order Notes',
        field: 'surrenderNotes'
    }
];

export const setSurrenderLesseeData = async (resData: any) => {
    const permanentAddress = resData?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');
    const documentData: any = await entityDocumentDataMigration(resData, resData?.authUsers[0]?.documents);
    let initialData: any = {};
    initialData['name'] = resData?.entityName;
    initialData['emailId'] = resData?.email;
    initialData['phoneNumber'] = resData?.phoneNumber;
    initialData['address1'] = permanentAddress?.address1;
    initialData['address2'] = permanentAddress?.address2;
    initialData['pinCode'] = permanentAddress?.pinCode;
    initialData['district'] = permanentAddress?.district;
    initialData['state'] = permanentAddress?.state;
    initialData['country'] = permanentAddress?.country;
    initialData['authorizedPersonFirstname'] = resData?.authUsers[0]?.firstName;
    initialData['authorizedPersonLastName'] = resData?.authUsers[0]?.lastName;
    initialData['pmobileNumber'] = resData?.authUsers[0]?.primaryMobileNo;
    initialData['alternateMobileNumber'] = resData?.authUsers[0]?.alternateMobileNo;
    initialData['authorizedEmailId'] = resData?.authUsers[0]?.email;
    initialData['letter'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY && doc.referenceType === DOCUMENT_TYPE.AUTHUSER);

    return initialData;
};

export const setSurrenderPlotDetails = async (resData: any) => {
    let initialData: any = {};
    initialData['estateName'] = getPlotDetails(resData?.estateDetails?.estateName);
    initialData['phase'] = getPlotDetails(resData?.phase);
    initialData['plotNo'] = getPlotDetails(resData?.plotNo);
    initialData['plotType'] = getPlotDetails(resData?.plotType);
    initialData['plotRate'] = resData?.plotRate;
    initialData['plotArea'] = resData?.plotAreaSqm;
    return initialData;
};

export const SURRENDER_EXISTING_MORTGAGE_DETAILS = [
    {
        formData: [
            {
                title: 'IFSC Code',
                field: 'existingIfscCode',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Bank Name',
                field: 'existingBankName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Branch Address',
                field: 'existingBranchAddress',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'NOC from Financial Institution',
                field: 'existingNocDocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                isDisabled: userType !== 'ENTERPRISE' ? true : false,
                entityFileData: {
                    documentNamePrefix: 'Financial Institution',
                    documentType: DOCUMENT_TYPE.NOC
                }
            }
        ]
    }
];
