import { Card, Form } from 'react-bootstrap';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CHECKLIST_TABLE, PLOT_DETAILS } from '../Allotment/Allotment.const';
import { ILLEGALITIES, ILLEGALITY_CARD } from './Illegality.const';
import { useState } from 'react';
import LesseeDetails from '../SubLease/LesseeDetails';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import Editor from '../../../../components/ContentEditor';
import { INSPECTION_DETAILS, TABLE_COLUMN_IR, UNAUTHORIZED_CONSTRUCTION, UNAUTHORIZED_SUB_LEASE } from '../InspectionReport/InspectionReportConst';
import { useFormikContext } from 'formik';
import { t } from 'i18next';

export function IllegalityApplicationCorrespondence({ checkListRowData, designation, content, setContent, readOnly, response, rowDocData, onRowClick, isInspectionByFm, nextValidAction }: any) {
    const [openSection, setOpenSection] = useState<string>('lesseeDetails');

    const plotData = [{ formData: PLOT_DETAILS[0]?.formData }];

    const tempColumnIR = TABLE_COLUMN_IR?.filter((val: any) => val.fieldName !== 'action');
    const tableIR = tempColumnIR?.map((val: any) => {
        if (val.fieldName === 'documentName') {
            return { ...val, clsName: 'col-sm-6 column-text-start' };
        } else {
            return { ...val };
        }
    });

    const inspectionDetails = INSPECTION_DETAILS?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) }));
    const unAuthorizedSubLease = UNAUTHORIZED_SUB_LEASE?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) }));
    const unAuthorizedConstruction = UNAUTHORIZED_CONSTRUCTION?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) }));

    const { values, setValues, setFieldValue } = useFormikContext<any>();

    const illegalityCard = response?.status !== 'PENDING_FOR_ACCEPTANCE' ? [...ILLEGALITY_CARD, { title: 'D - Show Cause Notice', field: 'showCauseNotice' }] : ILLEGALITY_CARD;

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const renderLesseeDetails = () => {
        return (
            <>
                <Card.Body>
                    <LesseeDetails />
                </Card.Body>
            </>
        );
    };

    const renderPlotDetails = () => {
        return (
            <>
                <Card.Body>
                    {plotData?.map((val: any) => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const renderIllegalities = () => {
        return (
            <>
                {!isInspectionByFm ? (
                    <Card.Body>
                        {ILLEGALITIES?.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                    </Card.Body>
                ) : (
                    <Card.Body>
                        {inspectionDetails?.map((val: any) => (
                            <GenericFormCard {...val} />
                        ))}
                        {(values?.unauthorizedSubLease || values?.unauthorizedConstruction) && (
                            <div className='font-18 font-inter fw-bolder' style={{ paddingLeft: '0.6rem' }}>
                                {t('PENALTY_DETAILS', 'Penalty Details')}
                            </div>
                        )}

                        {values?.unauthorizedSubLease && unAuthorizedSubLease?.map((val: any) => <GenericFormCard {...val} />)}
                        {values?.unauthorizedConstruction && unAuthorizedConstruction?.map((val: any) => <GenericFormCard {...val} />)}
                        <div className='font-18 font-inter fw-bolder' style={{ paddingLeft: '0.6rem' }}>
                            {t('ATTACHMENTS', 'Attachments')}
                        </div>
                        {/* {nextValidAction?.isUpdatable ? (
                        <>
                            {UPLOADING_DOCUMENT?.map((val: any) => (
                                <GenericFormCard {...val} dropdownOptions={dropdownOptions} />
                            ))}
                            {uploadDocument?.map((val: any) => (
                                <GenericFormCard {...val} />
                            ))}
                        </>
                    ) : (
                        <></>
                    )} */}

                        <div style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}>
                            <GenericActionTable columns={tableIR} hideSearchBar={true} rowData={rowDocData} additionalMethod={onRowClick} itemsPerPage={5} />
                        </div>
                    </Card.Body>
                )}
            </>
        );
    };

    const renderShowCauseNotes = () => {
        return (
            <>
                <div>
                    <Card.Body>
                        <Form.Group>
                            <div className='col-lg-12 my-1 px-4 py-4'>
                                <Editor setContent={setContent} content={content} readOnly={readOnly} />
                            </div>
                        </Form.Group>
                    </Card.Body>
                </div>
            </>
        );
    };

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                Condition/Check list
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {illegalityCard.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A - Lessee Details' && openSection === 'lesseeDetails' && renderLesseeDetails()}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                {val.title === 'C - Illegalities' && openSection === 'illegalities' && renderIllegalities()}
                                {val.title === 'D - Show Cause Notice' && openSection === 'showCauseNotice' && renderShowCauseNotes()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
}
