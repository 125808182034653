// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrutinypopup .modal-content {
   
    pointer-events: auto;
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    border-radius: 0px;
    outline: 0;
}
.scrutinypopup .modal-content .card{
    background-color: transparent;
    border: none;
}
.scrutinypopup .modal-content .modal-header{
    visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/features/scrutinyFlowScreen/scrutinyPopUp/popup.css"],"names":[],"mappings":"AAAA;;IAEI,oBAAoB;IACpB,6BAA6B;IAC7B,4BAA4B;IAC5B,YAAY;IACZ,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,6BAA6B;IAC7B,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".scrutinypopup .modal-content {\n   \n    pointer-events: auto;\n    background-color: transparent;\n    background-clip: padding-box;\n    border: none;\n    border-radius: 0px;\n    outline: 0;\n}\n.scrutinypopup .modal-content .card{\n    background-color: transparent;\n    border: none;\n}\n.scrutinypopup .modal-content .modal-header{\n    visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
