import React from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

interface IDocViewer {
    url: string;
    fileName: string;
    extension: string;
}

const GenericDocViewer = (props: IDocViewer) => {
    const { url, fileName, extension } = props;

    const docs = [
        {
            uri: url,
            fileName: fileName,
            fileType: extension
        }
    ];

    return (
        <div>
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} style={{ height: 1000 }} />
        </div>
    );
};

export default GenericDocViewer;
