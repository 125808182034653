import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../../utils/constants';

export const ADVERTISEMENT_SEARCH_COLUMNS = [
    {
        colName: 'Advertisement No',
        fieldName: 'advNo',
        clsName: 'col-sm-2 column-text-start',
        isClickable: true
    },
    {
        colName: 'Date of Publication',
        fieldName: 'publicationDate',
        clsName: 'col-sm-2 column-text-start'
    },
    {
        colName: 'Status',
        fieldName: 'status',
        clsName: 'col-sm-2 '
    },
    {
        colName: 'Submission Start Date',
        fieldName: 'submissionStartDate',
        clsName: 'col-sm-3 ms-2 column-text-start'
    },
    {
        colName: 'Submission End Date',
        fieldName: 'submissionEndDate',
        clsName: 'col-sm-3 column-text-start'
    }
];
