import React, { useState } from 'react';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';

import { Form } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { COMMENTS } from '../../../../utils/formikValidation/FormikValidation';
import GenericFileUploadField from '../../../../components/moduleCommonFormik/GenericFileUpload.component';
import GenericFileUploadFieldMulti from '../../../../components/moduleCommonFormik/genericMultiFileUpload/GenericMultiFileUpload.component';

interface IleaseDeedProps {
    receivedChildData(comments: any): unknown;
    name?: string;
}
export const MassAllotmentPopUp = (props: IleaseDeedProps) => {
    const { t } = useTranslation();
    const [childData, setChildData] = useState(undefined);

    const sendData = () => {
        props?.receivedChildData(childData);
    };
    const handleKeyUp = (e: any) => {
        setChildData(e.target.value);
        sendData();
    };

    return (
        <>
            <div className='container' style={{ background: '#F5F5F6' }}>
                <div className='row p-3 justify-content-center  '>
                    <Form>
                        <label htmlFor='comments ' className='pb-2 ps-1 font-14 font-karla'>
                           {t('COMMENTS','Comments')} <span className='mandatory-indicator'>*</span>
                        </label>

                        <Field name='comments' as='textarea' className='form-control mb-4' style={{ height: '100px' }} onKeyUp={handleKeyUp} />
                        <ErrorMessage name='comments' component='div' className='text-danger font-12' />
                        <GenericFileUploadFieldMulti field='file' title={'Supporting Documents'} hideTitle={true} />
                        {/* <GenericFileUploadField field={'file'} title={''} /> */}
                        <br />
                    </Form>
                </div>
            </div>
        </>
    );
};
