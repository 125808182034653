import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calender.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GenericPopupModal from '../moduleCommonFormik/GenericModalPopup.component';
import { useTranslation } from 'react-i18next';
import { CreateHolidayPopup } from './CreateHolidayPopup';
import HolidayPopUp from './HolidayPopUp';

const localizer = momentLocalizer(moment);

export const MyCalendar = (props: any) => {
    const { t } = useTranslation();
    const [showCreateHolidayPopup, setShowCreateHolidayPopup] = useState(false);
    const [showHolidayPopup, setShowHolidayPopup] = useState(false);
    const [events, setEvents] = useState<any>([]);
    const [occasion, setOccasion] = useState('');
    const [slotInfo, setSlotInfo] = useState({});
    const [selectedEvent, setSelectedEvent] = useState<any>(null);

    // const [isOpen, setIsOpen] = useState(false);
    // const cardRef = useRef<any>(null);

    const { messages } = useMemo(
        () => ({
            messages: {
                week: 'Week',
                day: 'Day',
                month: 'Month',
                previous: '<',
                next: '>',
                today: `${moment().format('DD MMMM YYYY')}`
            }
        }),
        []
    );

    const onSelectSlot = useCallback((slotInfo: any) => {
        console.log('clicked', slotInfo);
        setShowCreateHolidayPopup(true);
        setSlotInfo({ ...slotInfo });
    }, []);

    const onCreateHoliday = () => {
        setEvents((prev: any) => [...prev, { ...slotInfo, title: occasion, allDay: true }]);
        setShowCreateHolidayPopup(false);
        console.log([...events, { ...slotInfo, occasion }]);
    };

    const onSelectEvent = (selectedEvt: any) => {
        console.log(selectedEvt);
        // if (selectedEvent === selectedEvt) setIsOpen(!isOpen);
        setSelectedEvent(selectedEvt);
        setShowHolidayPopup(true);
    };

    // useEffect(() => {
    //     function handleClickOutside(event: any) {
    //         if (cardRef.current && !cardRef.current.contains(event.target)) {
    //             setIsOpen(false);
    //         }
    //     }

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    return (
        <>
            <div className='calendar-container'>
                <Calendar
                    localizer={localizer}
                    views={['month', 'week', 'day']}
                    events={events}
                    startAccessor='start'
                    endAccessor='end'
                    style={{ minHeight: 500 }}
                    messages={messages}
                    onSelectSlot={onSelectSlot}
                    onSelectEvent={onSelectEvent}
                    timeslots={1}
                    selectable
                    popup
                />
            </div>
            <GenericPopupModal
                isVisible={showCreateHolidayPopup}
                primaryAction={() => onCreateHoliday()}
                secondaryAction={() => setShowCreateHolidayPopup(false)}
                primaryBtnTitle={t('CREATE_HOLIDAY', 'Create Holiday')}
                title={t('CREATE_HOLIDAY', 'Create Holiday')}
                subTitle={t('HOLIDAY_DESCRIPTION', 'Provide Description for the same')}
                children={<CreateHolidayPopup setOccasion={setOccasion} />}
                isSecondaryBtn={false}
            />
            <GenericPopupModal
                isVisible={showHolidayPopup}
                primaryAction={() => {}}
                secondaryAction={() => setShowHolidayPopup(false)}
                title={t('HOLIDAY', 'Holiday')}
                subTitle={moment(selectedEvent?.start).format('Do MMMM, dddd')}
                children={<HolidayPopUp selectedEvent={selectedEvent} />}
                isSecondaryBtn={false}
                isPrimaryBtn={false}
            />
            {/* {
                <Card className='holiday-popup border' style={{ zIndex: '99', top: `calc(${selectedEvent?.box?.y}px - 38%)`, left: `calc(${selectedEvent?.box?.x}px + 2%)` }}>
                    <Card.Title className=''>Holi</Card.Title>
                    <Card.Body>
                        <div className='d-flex event-bg'>
                            <div>
                                <div>Holika Dahan</div>
                                <div>Holika Dahan</div>
                                <div>Holika Dahan</div>
                            </div>
                            <div>
                                <span className='edit me-3 pe-2 d-flex align-items-center' onClick={() => props.handleOnEdit(props.type, true)}>
                                    <img src={editIcon} alt='edit' className='me-1' /> Edit
                                </span>
                                <span className='edit me-3 pe-2 d-flex align-items-center' onClick={() => props.handleOnEdit(props.type, true)}>
                                    <img src={editIcon} alt='edit' className='me-1' /> Edit
                                </span>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            } */}
        </>
    );
};
