import { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
// import "./ArchitectStyles.scss";
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { BPArequestInfo } from '../../../services/modulesServices/userManagement.api';
import { ArchTableResponseInfo } from '../../modules/UserManagement/types';
import HeaderComponent from '../../../components/header/Header.component';
import { git_logo } from '../../../utils/Images';
import { right_arrow_green2 } from '../../../utils/Logo';
import TechnicalServices from '../../../services/technicalServices/Technical.service';
import { DASHBOARD_TABLE_COLUMNS } from '../../modules/UserManagement/UserManagement.const';
import { SCRUTINY_DASHBOARD_TABLE_COLUMNS } from '../ScrutinyFormPage.const';
import GenericActionTable from '../../../components/moduleCommonFormik/GenericActionTable';
import GenericBackButton from '../../../components/UI components/Buttons/GenericBackButton';
import { t } from 'i18next';

function ScrutinySharedPlans() {
    const columns = SCRUTINY_DASHBOARD_TABLE_COLUMNS;
    const navigate = useNavigate();
    const [rowData, setRowData] = useState<any>([]);
    const userData = useSelector(selectUser);

    const handleRowClick = (rowData: any) => {
        navigate(`/goa-idc/obpas/scrutinyTool?edcr=${rowData.edcrNumber}`, { state: { rowData } });
        console.log(rowData);
    };

    const getScrutinyPlanDetails = () => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const padZero = (num: any) => (num < 10 ? '0' + num : num);

        const toCamelCase = (str: string) => {
            return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
                return match.toUpperCase();
            });
        };
        const payload = { RequestInfo: BPArequestInfo() };
        TechnicalServices.getSharedScrutinyPlans(payload, user?.uuid)
            .then(res => {
                console.log(res);
                const updatedData = res.data?.EDCRShare.map((item: any) => ({
                    ...item,
                    archName: toCamelCase(item?.archName) || '-',
                    serviceType: (toCamelCase(item?.serviceType) || '-').replace(/_/g, ' '),
                    applicationType: (toCamelCase(item?.applicationType) || '-').replace(/_/g, ' '),
                    edcrNumber: item?.edcrNumber || '-',
                    createdTime: `${padZero(new Date(item?.createdTime || '-').getHours())}:${padZero(new Date(item?.createdTime || '-').getMinutes())}:${padZero(
                        new Date(item?.createdTime || '-').getSeconds()
                    )} (${padZero(new Date(item?.createdTime || '-').getDate())}/${padZero(new Date(item?.createdTime || '-').getMonth() + 1)}/${new Date(item?.createdTime || '-').getFullYear()})`
                }));
                setRowData(updatedData);
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        getScrutinyPlanDetails();
    }, [userData]);

    return (
        <>
            <HeaderComponent />
            <div className='bg-light m-auto px-5 py-4 row architect-dashboard'>
                <div className='table-container parents'>
                    <div className='row gap-3 pt-5 ps-2 mb-4'>
                        <div className='d-flex align-items-end'>
                            <GenericBackButton />
                            <div className='ps-2'>
                                <h2 className='dashboard_heading'>{t('ARC_HEADER_SCRUTINY_SHARED_PLANS', 'Scrutiny Shared Plans')}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <GenericActionTable columns={columns} rowData={rowData} additionalMethod={handleRowClick} searchBarPlaceholder='SEARCH_SCRUTINY_DB' />
            </div>
        </>
    );
}

export default ScrutinySharedPlans;
