import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';
import _ from 'lodash';
import trash from '../../../../assets/logos/greenTrash.svg';
import { ENTERPRISE_DETAILS, SURRENDER_CORRESPONDENCE, SURRENDER_DETAILS, SURRENDER_EXISTING_MORTGAGE_DETAILS, SURRENDER_LESSEE_DETAILS } from '../Surrender.const';
import GenericFormCard from '../../../../../components/moduleCommonFormik/GenericFormCard.component';
import PlotDetails from '../../../Migration/pages/PlotDetails';
import Declaration from '../../Allotment/Declaration';
import { MORTGAGE_DECLARATION_LABEL } from '../../../Mortgage Lessee/Mortgage.const';
import { WorkflowPaymentDetails } from '../../WorkflowPaymentDetails';
import { CHECKLIST_TABLE, paymentColumns } from '../../Allotment/Allotment.const';
import Editor from '../../../../../components/ContentEditor';
import GenericActionTable from '../../../../../components/moduleCommonFormik/GenericActionTable';

const SurrenderCorrespondence = ({
    dropdownData,
    dropdownDataPlot,
    setDropdownDataPlot,
    ownershipDetails,
    setOwnershipDetails,
    isChecked,
    handleCheckboxChange,
    nicDetails,
    setNicDetails,
    controllingOwnership,
    checkListRowData,
    vacantPlotData,
    rowData,
    setRowData,
    applicationNumber,
    status,
    content,
    setContent,
    paymentRowData,
    disabled,
    designation,
    auctionType,
    fileStoreId,
    isCheckedSubLeaseDetails,
    setIsCheckedSubLeaseDetails,
    handleCheckboxChangeSubLeaseDetails,
    transactionDetails,
    readOnly,
    subleaseOrder,
    bankDetails,
    existingMortgageDetails
}: any) => {
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userDesignationGM = getUserDetails.designation === 'GM';
    const [openSection, setOpenSection] = useState<string>('lesseeDetails');
    const [showOwnershipModal, setShowOwnershipModal] = useState(false);

    const { values, touched, errors, setFieldValue } = useFormikContext<any>();

    const onSectionChange = (currentSection: string) => {
        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else setOpenSection(currentSection);
    };

    const renderLesseeDetails = () => {
        return (
            <div className='px-3'>
                {SURRENDER_LESSEE_DETAILS.map((val: any, id: number) => (
                    <GenericFormCard {...val} />
                ))}
            </div>
        );
    };

    const renderSurrenderDetails = () => {
        return (
            <>
                {SURRENDER_DETAILS.map((val: any) => (
                    <GenericFormCard {...val} />
                ))}
            </>
        );
    };
    const renderPlotDetails = () => {
        return (
            <div className='px-3'>
                <PlotDetails dropdownData={dropdownDataPlot} isFieldDisable={disabled} setDropdownData={setDropdownDataPlot} hideCard={true} />
            </div>
        );
    };

    const renderEnterpriseDetails = () => {
        return (
            <>
                {ENTERPRISE_DETAILS.map((val: any) => (
                    <GenericFormCard {...val} />
                ))}
                {!!values.financingObtained && (
                    <form>
                        <FieldArray name='existingMortgageDetails'>
                            {({ insert, remove, push }) => (
                                <div>
                                    {values.existingMortgageDetails?.length > 0 &&
                                        values.existingMortgageDetails.map((data: any, index_p: number) => (
                                            <div className='row' key={index_p}>
                                                <div className='col-sm-12  align-items-center pb-2'>
                                                    <div className='formHeading'>{`Financial Institution/Bank - ${index_p + 1}`}</div>
                                                    <div className=' ps-1'>
                                                        {SURRENDER_EXISTING_MORTGAGE_DETAILS.map((val: any, _: number) => (
                                                            <GenericFormCard
                                                                fieldPath={`existingMortgageDetails.${index_p}`}
                                                                {...val}
                                                                // onChangeAction={val?.formData?.map((formField: any, ind: number) => formField)}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </FieldArray>
                    </form>
                )}
            </>
        );
    };

    const renderDeclarationDetails = () => {
        return (
            <Declaration
                isChecked={isChecked}
                hideCard={true}
                declarationList={MORTGAGE_DECLARATION_LABEL}
                handleCheckboxChange={(e: any) => handleCheckboxChange(e)}
                fileStoreId={fileStoreId}
            ></Declaration>
        );
    };

    const renderPaymentDetails = () => {
        return (
            <>
                <div className='px-4 py-4'>
                    <WorkflowPaymentDetails transactionDetails={transactionDetails} paymentColumns={paymentColumns} paymentRowData={paymentRowData} />
                </div>
            </>
        );
    };
    const renderNoteDetails = () => {
        return (
            <>
                <div>
                    <Form.Group>
                        <div className='col-lg-12 my-1 px-4 py-4'>
                            <Editor setContent={setContent} content={content} readOnly={readOnly} />
                        </div>
                    </Form.Group>
                </div>
            </>
        );
    };
    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {checkListRowData?.length ? (
                        <div className='col-sm-12 collapsible_box collapsible_box_checklist pb-3 align-items-center pb-2'>
                            <input type='checkbox' id='checklists' name='checklists' defaultChecked={true} />
                            <label htmlFor='checklists' className='d-flex collapsible_box_label align-items-center pb-0 justify-content-between text-black  fw-bold'>
                                Condition/Check list
                            </label>
                            <div className='form_fields px-2'>
                                <GenericActionTable columns={CHECKLIST_TABLE} hideSearchBar={true} rowData={checkListRowData} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}

                    {SURRENDER_CORRESPONDENCE.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === 'A1 - Lessee Details' && openSection === 'lesseeDetails' && renderLesseeDetails()}
                                {val.title === 'A2 - Surrender Details' && openSection === 'surrenderDetails' && renderSurrenderDetails()}
                                {val.title === 'B - Plot Details' && openSection === 'plotDetails' && renderPlotDetails()}
                                {val.title === 'C - Enterprise Details' && openSection === 'enterpriseDetails' && renderEnterpriseDetails()}
                                {val.title === 'D - Self Declaration' && openSection === 'selfDeclaration' && renderDeclarationDetails()}
                                {val.title === 'E - Payment Details' && openSection === 'paymentDetails' && renderPaymentDetails()}
                                {val.title === 'F - Surrender Order Notes' && openSection === 'surrenderNotes' && renderNoteDetails()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default SurrenderCorrespondence;
