import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Button, Card, CardBody, Image } from 'react-bootstrap';
import {
    LESSEE_DETAILS,
    DOCUMENTS_DETAILS,
    OCCUPANCY_DETAILS,
    PLOT_DETAILS,
    FIELD_INSPECTION_DETAILS_II,
    NOC_DOCUMENTS_DETAILS,
    FIELD_INSPECTION_DETAILS,
    FIELD_INSPECTION_DETAILS_DEFAULT,
    PART_OCCUPANCY_DETAILS
} from './OrderCompletionFormPage.const';
import { Field, FieldArray, Form, Formik, useFormikContext } from 'formik';
import { POST, devUrl, endpoints, getProgressBarData, requestInfo } from '../../services/modulesServices/userManagement.api';
import TechnicalServices from '../../services/technicalServices/Technical.service';
import { CLEARANCE_INITIAL_DATA } from './OrderCompletionFormPage.module';
import { RoleInterface } from '../../components/moduleCommonFormik/GenericFormCard.constants';
import GenericFormCard from '../../components/moduleCommonFormik/GenericFormCard.component';
import GenericPopupModal from '../../components/moduleCommonFormik/GenericModalPopup.component';
import { useNavigate } from 'react-router-dom';
import { getCLAList } from './OrderCompletionFormPage.controller';
import PaymentSubmissionStatus from '../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../components/moduleCommonFormik/ProgressBar';
import GenericButtons from '../../components/UI components/Buttons/GenericButtons';
import { t } from 'i18next';
import { check, download } from '../../utils/Logo';
import GenericActionTable from '../../components/moduleCommonFormik/GenericActionTable';
import moment from 'moment';
import DeleteIconFloor from '../../assets/logos/deleteButton.svg';
import DeleteIconBlock from '../../assets/logos/delete.svg';
import GenericCommonTextField from '../../components/moduleCommonFormik/GenericCommonTextField.component';
import BpaCommonSelector from '../../components/moduleCommonFormik/BpaCommonSelector.component';
import { CONSTANT_DATA_FIELD } from '../../utils/constants';

interface OrderCompletionFormSubPageProps {
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
    index: number;
    employeeList: any;
    formData: any;
    previewData: any;
    applicationNumber?: string;
    appStatus: boolean;
    showProcess: boolean;
    activeStepper: string;
    allchecked: any;
    setAllChecked: React.Dispatch<any>;
    bpaData: any;
    isCancelBtn: boolean;
    setShowProcess: React.Dispatch<React.SetStateAction<boolean>>;
    setEditorContent: Dispatch<SetStateAction<string>>;
    setIsSelectedBPA: React.Dispatch<React.SetStateAction<string>>;
    editorContent: string;
    setFormValues: Dispatch<
        SetStateAction<
            {
                emailId: string;
            }[]
        >
    >;
    formValues: {
        emailId: string;
    }[];
    searchBPA: (val: any) => void;
    allBPADetails: any;
    allEdcrDetails: any;
    fieldInspection: any;
    onActionClick: (value: any) => void;
    eSignFlag: boolean;
    signDocument: () => void;
    isScheduleActive: boolean;
    setIsScheduleActive: React.Dispatch<React.SetStateAction<boolean>>;
    questionnaireData: any;
    setQuestionnaireData: React.Dispatch<any>;
    renderQuestionnaireDetails: () => void;
    questionnaireDetails: any;
}

export default function OrderCompletionFormSubPage(props: OrderCompletionFormSubPageProps) {
    const { values, resetForm, setValues, setFieldValue } = useFormikContext<any>();
    const [auditTrial, setAuditTrial] = useState<any>(null);
    const [isMultiLessee, setIsMultiLessee] = useState<boolean>(false);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const isRoleMD = !user?.roles?.filter((role: RoleInterface) => role.code === 'MD').length;
    const navigate = useNavigate();
    const [eSignedDocumentData, setESignedDocumentData] = useState<any>(null);
    const [selectedCLNo, setSelectedOneCLNo] = useState<null | string>(null);
    let userLocal = JSON.parse(localStorage.getItem('user') || '{}');

    function handleChange(e: any) {
        if (e.target.checked) {
            props?.setAllChecked([...props?.allchecked, e?.target?.value]);
        } else {
            props?.setAllChecked(props?.allchecked.filter((item: any) => item !== e.target.value));
        }
    }

    useEffect(() => {
        if (!!props?.allBPADetails && props?.allBPADetails.length > 0) {
            setIsMultiLessee(props?.allBPADetails.length > 0);
        }
    }, [props?.allBPADetails]);

    useEffect(() => {
        try {
            if (!!props?.applicationNumber) getProgressBarData(props?.applicationNumber).then(res => setProgressBarDetails(res.data));
        } catch (e) {
            console.log(e);
        }
    }, [props?.applicationNumber]);

    const proceedToBPA = () => {
        props.setIsSelectedBPA(values?.edcrNo);
        setIsMultiLessee(false);
    };

    const handelIsLesseeVisible = () => {
        setIsMultiLessee(false);
        navigate(-1);
    };

    useEffect(() => {
        const fetchESignStatus = async () => {
            try {
                let payload = {
                    RequestInfo: requestInfo(),
                    applicationNumber: values.appId
                };
                const data = await POST(endpoints.consolidatedDocSearch, payload);
                console.log(data);
                setESignedDocumentData(data?.data);
            } catch (e: any) {}
        };
        if (props?.activeStepper === 'DECLARATION') {
            fetchESignStatus();
        }
    }, [props.activeStepper]);

    const getAuditData = () => {
        if (!props?.applicationNumber) return;
        const payload = { RequestInfo: requestInfo() };
        TechnicalServices.getAuditTrail(payload, props?.applicationNumber, 'ga')
            .then(res => {
                setAuditTrial(res?.data?.ProcessInstances);
            })
            .catch(err => console.log(err));
    };

    const clearData = () => {
        resetForm();
        setValues(CLEARANCE_INITIAL_DATA);
    };

    useEffect(() => {
        getAuditData();
        return () => {
            clearData();
        };
    }, []);

    useEffect(() => {
        if (values?.occupancyType === 'FULL_OCCUPANCY') {
            setFieldValue('occupancy', '100');
        } else if (values?.occupancyType === 'PART_OCCUPANCY') {
            setFieldValue('occupancy', '');
        } else {
            if (!values?.occupancy) setFieldValue('occupancy', '');
        }
    }, [values?.occupancyType]);

    const renderBuildingPlanDetails = () => {
        let modifiedArray = PLOT_DETAILS;
        modifiedArray.map((v: any) => {
            v.formData.map((h: any) => (h.isDisabled = true));
        });

        return (
            <>
                {modifiedArray.map(val => (
                    <Card className='card_border my-2'>
                        <Card.Body>
                            <GenericFormCard {...val} />
                        </Card.Body>
                    </Card>
                ))}
            </>
        );
    };

    const onCLSelect = (selectedValue: any) => {
        console.log('selectedValue', selectedValue);
        setSelectedOneCLNo(selectedValue.id);
        setFieldValue('edcrNo', selectedValue?.value);
        setFieldValue('percentage', +selectedValue?.appliedCount);
    };

    const CLTableColumns = [
        {
            colName: 'Select',
            fieldName: 'radio',
            clsName: 'col-sm-1',
            onRadioClick: onCLSelect,
            selectedValue: selectedCLNo,
            id: 1
        },
        {
            colName: 'Construction License Application No',
            fieldName: 'dcrNumber',
            clsName: 'col-sm-2',
            isClickable: false,
            id: 2
        },
        {
            colName: 'Estate',
            fieldName: 'estate',
            clsName: 'col-sm-2',
            id: 3
        },
        {
            colName: 'Plot Number',
            fieldName: 'plotNumber',
            clsName: 'col-sm-2',
            id: 4
        },
        {
            colName: 'Valid Upto',
            fieldName: 'sharedDate',
            clsName: 'col-sm-2',
            id: 5
        },
        {
            colName: 'Obtained Occupancy %',
            fieldName: 'appliedCount',
            clsName: 'col-sm-2',
            id: 6
        }
    ];

    const renderBasicDetails = () => {
        const applicationData = {
            applicationNo: props?.applicationNumber,
            status: props?.previewData?.status,
            data: auditTrial,
            isRoleMD: isRoleMD,
            bpaData: props?.bpaData
        };

        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {LESSEE_DETAILS.map((val, key) => (
                            <GenericFormCard {...{ ...val }} key={key} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const downloadApplicationDocument = () => {
        const fileStoreId = props?.previewData?.digitalSignedDoc;
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${fileStoreId}`;
        window.open(downloadFileUrl);
    };

    const renderOccupancyDetails = () => {
        const occupancy_Details: any = OCCUPANCY_DETAILS;
        const part_Occupancy_Details: any = PART_OCCUPANCY_DETAILS;
        if (!!props.previewData && props.previewData?.status !== 'APPL_STATUS_OC_SUBMISSION_PENDING') {
            if (!!props.previewData?.ocPercentage) {
                values.occupancy = props.previewData?.ocPercentage;
                values.occupancyType = props.previewData?.ocPercentage == 100 ? 'FULL_OCCUPANCY' : 'PART_OCCUPANCY';
            } else {
                occupancy_Details[0].formData[0].options[0].isDisabled = !(values?.percentage === 0);
            }
        }

        occupancy_Details.forEach((v: any) => {
            v.formData.map(
                (h: any) =>
                    (h.isDisabled = h.field !== 'occupancy' ? props.previewData?.status !== 'APPL_STATUS_OC_SUBMISSION_PENDING' && props.previewData?.applicationType !== 'CONSTRUCTION_LICENCE' : true)
            );
        });

        part_Occupancy_Details.forEach((v: any) => {
            v.formData.map((h: any) => (h.isDisabled = props.previewData?.status !== 'APPL_STATUS_OC_SUBMISSION_PENDING' && props.previewData?.applicationType !== 'CONSTRUCTION_LICENCE'));
        });

        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {!!props?.previewData && occupancy_Details.map((val: any) => <GenericFormCard {...val} />)}
                        {/* <div>
                            <b>
                                {t('OC_LBL_OCCUPANCY_OBTAINED', 'Obtained Occupancy')} : {values?.percentage}%
                            </b>
                            
                        </div> */}
                        {!!props?.previewData && values?.occupancyType === 'PART_OCCUPANCY' && part_Occupancy_Details.map((val: any) => <GenericFormCard {...val} />)}

                        {values?.occupancyType === 'PART_OCCUPANCY' && blockComponent()}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const blockComponent = () => {
        const isDisableForm = props.previewData?.status !== 'APPL_STATUS_OC_SUBMISSION_PENDING' && props.previewData?.applicationType !== 'CONSTRUCTION_LICENCE';
        const floorTypes = [
            { name: 'Ground Floor', value: 'groundFloor', code: 'groundFloor', label: 'Ground Floor', title: 'Ground Floor', subcategory: [] },
            { name: 'Below Ground', value: 'belowFloor', code: 'belowFloor', label: 'Below Ground', title: 'Below Ground', subcategory: [] },
            { name: 'Above Ground', value: 'aboveFloor', code: 'aboveFloor', label: 'Above Ground', title: 'Above Ground', subcategory: [] },
            { name: 'Stilt Floor', value: 'stiltFloor', code: 'stiltFloor', label: 'Stilt Floor', title: 'Stilt Floor', subcategory: [] }
        ];
        return (
            // <Formik
            //     initialValues={{
            //         far: '',
            //         coverageArea: '',
            //         coveragePercent: '',
            //         blocks: [{ floors: [{ floorType: '', floorNumber: '', builtUpArea: '' }] }]
            //     }}
            //     // validationSchema={validationSchema}
            //     onSubmit={values => {
            //         console.log(values);
            //     }}
            // >
            <>
                {/* {({ values, errors, touched }: any) => ( */}
                <Form>
                    <FieldArray name='blocks'>
                        {({ push, remove }) => (
                            <>
                                {!!values?.blocks &&
                                    !!values?.blocks.length &&
                                    values?.blocks.map((block: any, blockIndex: any) => (
                                        <div className='my-3'>
                                            {/* <Card className='card_border my-2'>
                                             <Card.Body> */}
                                            <div key={blockIndex}>
                                                <div className='d-flex'>
                                                    <span className='font-inter' style={{ fontWeight: 600, fontSize: '22px', lineHeight: '32px', color: '#3a7425', marginLeft: '10px' }}>
                                                        Block - {blockIndex + 1}
                                                    </span>
                                                    <div className='d-flex justify-content-end mt-1' style={{ position: 'absolute', right: '2%' }}>
                                                        {blockIndex !== 0 && !isDisableForm && (
                                                            // <GenericButtons text={`Remove Block`} disabled={isDisableForm} ClassName='btn-danger mt-3' handleClick={() => remove(blockIndex)} />
                                                            <img
                                                                className=''
                                                                style={{ cursor: 'pointer', zIndex: 100, opacity: !!isDisableForm ? 0.5 : 1 }}
                                                                src={DeleteIconFloor}
                                                                onClick={() => (!!isDisableForm ? null : remove(blockIndex))}
                                                                alt='delete'
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <FieldArray name={`blocks.${blockIndex}.floors`}>
                                                    {({ push, remove }) => (
                                                        <>
                                                            {block.floors.map((floor: any, floorIndex: any) => (
                                                                <div className='mt-3'>
                                                                    {/* <Card className='card_border my-2'>
                                                                     <Card.Body> */}
                                                                    <div key={floorIndex}>
                                                                        <div className='d-flex'>
                                                                            <span className='font-inter' style={{ fontWeight: 600, fontSize: '16px', lineHeight: '25.6px', marginLeft: '10px' }}>
                                                                                Floor - {floorIndex + 1}
                                                                            </span>
                                                                            <div className='d-flex justify-content-end' style={{ position: 'absolute', right: '2%' }}>
                                                                                {floorIndex !== 0 &&
                                                                                    // <button className='align-self-center' disabled={isDisableForm} onClick={() => remove(floorIndex)} type='button'>
                                                                                    !isDisableForm && (
                                                                                        <img
                                                                                            className=''
                                                                                            style={{ cursor: 'pointer', zIndex: 100, opacity: !!isDisableForm ? 0.5 : 1 }}
                                                                                            src={DeleteIconFloor}
                                                                                            onClick={() => (!!isDisableForm ? null : remove(floorIndex))}
                                                                                            alt='delete'
                                                                                        />
                                                                                        // </button>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex justify-content-between mt-2'>
                                                                            <div className='col-sm-3 px-2 py-3'>
                                                                                <BpaCommonSelector
                                                                                    title={t('OC_FLOOR_TYPE', 'Floor Type')}
                                                                                    options={floorTypes}
                                                                                    isDisabled={isDisableForm}
                                                                                    // isRequired={true}
                                                                                    field={`blocks.${blockIndex}.floors.${floorIndex}.floorType`}
                                                                                />
                                                                            </div>
                                                                            <div className='col-sm-3 px-2 py-3'>
                                                                                <GenericCommonTextField
                                                                                    title={t('OC_FLOOR_NUMBER', 'Floor Number')}
                                                                                    isDisabled={isDisableForm}
                                                                                    // isRequired={true}
                                                                                    field={`blocks.${blockIndex}.floors.${floorIndex}.floorNumber`}
                                                                                    type={'number'}
                                                                                />
                                                                            </div>
                                                                            {/* </div>
                                                                        <div className='d-flex justify-content-between'> */}
                                                                            <div className='col-sm-3 px-2 py-3'>
                                                                                <GenericCommonTextField
                                                                                    title={t('OC_FLOOR_AREA', 'Floor Area')}
                                                                                    // isRequired={true}
                                                                                    isDisabled={isDisableForm}
                                                                                    field={`blocks.${blockIndex}.floors.${floorIndex}.floorArea`}
                                                                                    type={'number'}
                                                                                />
                                                                            </div>
                                                                            <div className='col-sm-3 px-2 py-3'>
                                                                                <>
                                                                                    <GenericCommonTextField
                                                                                        title={t('OC_BUILT_UP_AREA', 'Built Up Area')}
                                                                                        // isRequired={true}
                                                                                        isDisabled={isDisableForm}
                                                                                        field={`blocks.${blockIndex}.floors.${floorIndex}.builtUpArea`}
                                                                                        isRequired={true}
                                                                                        type={'number'}
                                                                                    />
                                                                                </>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* </Card.Body>
                                                                </Card> */}
                                                                </div>
                                                            ))}

                                                            <div className=''>
                                                                <button
                                                                    className='col-sm-12 mt-2  mb-2 d-flex justify-content-center align-items-center noc_add_fields'
                                                                    type='button'
                                                                    onClick={() => push({ floorType: '', floorNumber: '', floorArea: '', builtUpArea: '' })}
                                                                    disabled={isDisableForm}
                                                                >
                                                                    + Add Floor
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </div>
                                            {/* </Card.Body>
                                         </Card> */}
                                        </div>
                                    ))}
                                <hr></hr>
                                {/* <GenericButtons
                                    ClassName='mt-2'
                                    text={`Add Block`}
                                    disabled={isDisableForm}
                                    variant='primary'
                                    handleClick={() => push({ floors: [{ floorType: '', floorNumber: '', floorArea: '', builtUpArea: '' }] })}
                                /> */}
                                <button
                                    className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center add_fields'
                                    type='button'
                                    onClick={() => push({ floors: [{ floorType: '', floorNumber: '', floorArea: '', builtUpArea: '' }] })}
                                    disabled={isDisableForm}
                                >
                                    + Add Block
                                </button>
                            </>
                        )}
                    </FieldArray>
                </Form>
            </>
        );
    };

    const renderDeclarationDetails = () => {
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        <div className='row ps-2 py-2'>
                            <div className='formTitle m-0'>
                                {t('OC_LBL_SELF_DECLARATION', 'Self-Declaration')}
                                <span className='text-danger'>*</span>
                            </div>
                        </div>
                        <div className='row d-flex m-0'>
                            {/* <Button variant="bg-light outline-success btn btn-light font-karla-med p-10" onClick={() => { setIsShow(true) }} style={{ borderColor: "#45852e", color: "#45852e", width: "max-content", borderRadius: 8 }} >
                                Sign the Document
                            </Button> */}
                            <p className='fw-bold'>The Lessee does hereby affirm and declare that:</p>

                            <label className='mb-3 checkbox-container'>
                                {!!props.previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={props.previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='1' name='step_1' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                                )}
                                <span className={`${props.previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span>
                                    (i) The information provided above and in the enclosed documents is true and correct to the best of my knowledge and belief and nothing material has been concealed
                                    herein.
                                </span>
                            </label>
                            <label className='mb-3 checkbox-container'>
                                {!!props.previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={props.previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='2' className='me-2' name='step_2' onChange={handleChange} style={{ opacity: '0' }} />
                                )}
                                <span className={`${props.previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span>
                                    (ii) It has not taken any steps to take advantage of any bankruptcy or insolvency law or otherwise is not a party to any bankruptcy, insolvency or liquidation
                                    proceedings.
                                </span>
                            </label>
                            <label className='mb-3 checkbox-container'>
                                {!!props.previewData?.lesseeDigitalSignFlage ? (
                                    <input type='checkbox' id='myCheckbox' className='me-2' checked={props.previewData?.lesseeDigitalSignFlage} style={{ opacity: '0' }} />
                                ) : (
                                    <input type='checkbox' id='myCheckbox' value='3' name='step_3' onChange={handleChange} className='me-2' style={{ opacity: '0' }} />
                                )}
                                <span className={`${props.previewData?.lesseeDigitalSignFlage ? 'checkmarkDefault' : 'checkmark'} `}></span>
                                <span>
                                    (iii) The technical clearance, construction license, as the case may be, shall be liable to be cancelled if there is any misdeclaration or misrepresentation of the
                                    information submitted under or along with this application.
                                </span>
                            </label>
                        </div>
                        {!!props?.previewData?.lesseeDigitalSignFlage || props?.eSignFlag ? (
                            <>
                                <div className='ps-2 pt-2 font-14 font-inter fw-bold m-0'>Digitally Signed</div>
                                <div className='ps-2 pb-2 font-14 font-inter'>{eSignedDocumentData?.esignedDate}</div>
                                <div onClick={() => downloadApplicationDocument()} className='ps-2 d-flex align-items-center'>
                                    <Image src={download} height={14} />
                                    <div className='ps-2 font-14' style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>
                                        Download Application
                                    </div>
                                </div>
                            </>
                        ) : (
                            <GenericButtons text={t('OC_LBL_SELF_DECLARATION_BTN', 'Sign the Document')} ClassName='px-3' handleClick={props?.signDocument} />
                        )}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderDocumentDetails = () => {
        const documentNamePrefix = userLocal?.firstName;
        console.log(values?.additionalDetails, 'TESTING');
        // Map through DOCUMENTS_DETAILS to update documentNamePrefix
        const updatedDocumentDetails = DOCUMENTS_DETAILS.map(detail => {
            detail.formData.forEach(field => {
                if (field.type === CONSTANT_DATA_FIELD.NEW_FILE_FIELD && field.entityFileData && field.entityFileData.documentNamePrefix) {
                    field.entityFileData.documentNamePrefix = documentNamePrefix;
                }
            });
            return detail;
        });
        // let modifiedArray = DOCUMENTS_DETAILS;
        if (!!props?.applicationNumber && props?.previewData?.status !== 'APPL_STATUS_OC_SUBMISSION_PENDING') values.isEditableFile = false;

        if (!!props?.appStatus) {
            updatedDocumentDetails.map((v: any) => {
                v.formData.map((h: any) => (h.isDisabled = true));
            });
        }
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {updatedDocumentDetails.map(val => (
                            <GenericFormCard {...{ ...val }} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderBPASelection = () => {
        if (!props.allBPADetails || props.allBPADetails.length <= 0)
            return (
                <>
                    <p className='text-center fw-bold pt-2'>No Data Available</p>
                </>
            );
        const bpaList = getCLAList(props?.allBPADetails).map((item: any, index: any) => ({
            ...item,
            id: index + 1, // Assigning a unique ID starting from 1
            dcrNumber: item.dcrNumber || '-', // Default value for dcrNumber
            estate: item.estate || '-', // Default value for estate
            plotNumber: item.plotNumber || '-', // Default value for plotNumber
            sharedDate: item.sharedDate ? moment(item.sharedDate).format('DD/MM/YYYY') : '-', // Format the date
            appliedCount: item.appliedCount === 0 ? '0' : item.appliedCount
        }));
        console.log(bpaList);
        return (
            <div className='mx-4 my-2'>
                <GenericActionTable
                    columns={CLTableColumns}
                    rowData={bpaList}
                    hideSearchBar={false}
                    itemsPerPage={10}
                    onCheckHandler={(row: any) => {
                        setFieldValue('edcrNo', row?.value);
                        setFieldValue('percentage', +row?.appliedCount);
                    }}
                    checkType={'singleCheck'}
                    searchBarPlaceholder='Search by Construction License No'
                />
            </div>
        );
    };

    const renderInspectionList = () => {
        let modifiedArray = FIELD_INSPECTION_DETAILS_II;
        if (!!props?.appStatus) {
            modifiedArray.map((v: any) => {
                v.formData.map((h: any) => (h.isDisabled = false));
            });
        }
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {modifiedArray.map(val => (
                            <GenericFormCard {...{ ...val }} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const renderCheckList = () => {
        console.log(new Date(props?.fieldInspection?.requestForRescheduleData?.scheduledDate));
        let formattedDate;
        if (!!props?.fieldInspection) {
            const date = new Date(props?.fieldInspection?.scheduledDate);
            formattedDate = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0') + '/' + new Date(date).getFullYear();
        }
        const inspectionDetails = props?.fieldInspection;
        const inpsectionData = !!inspectionDetails ? [{ ...inspectionDetails, scheduledDate: formattedDate, lesseeAcepted: !!inspectionDetails?.lesseeAcepted ? 'Accepted' : '-' }] : [];
        values.sheduleDate = formattedDate;
        values.sheduleTime = props?.fieldInspection?.scheduledTime;

        if (!!props?.fieldInspection?.requestForReschedule && !!props?.fieldInspection?.requestForRescheduleData?.scheduledTime) {
            const scheduledate = new Date(props?.fieldInspection?.requestForRescheduleData?.scheduledDate);
            const formattedDateFor = String(scheduledate.getDate()).padStart(2, '0') + '/' + String(scheduledate.getMonth() + 1).padStart(2, '0') + '/' + new Date(scheduledate).getFullYear();
            values.inspDate = formattedDateFor;
            values.inspTime = props?.fieldInspection?.requestForRescheduleData?.scheduledTime;
        }
        return (
            <Card className='card_border my-2'>
                <Card.Body>
                    {formattedDate && props?.fieldInspection?.scheduledTime ? (
                        FIELD_INSPECTION_DETAILS_DEFAULT.map(val => (
                            <Card.Body>
                                <GenericFormCard {...val} />
                            </Card.Body>
                        ))
                    ) : (
                        <p className='text-center fw-bold pt-2'>Inspection details not available</p>
                    )}
                    {(!!props?.isScheduleActive || !!props?.fieldInspection?.requestForReschedule) &&
                        FIELD_INSPECTION_DETAILS.map(val => (
                            <Card.Body>
                                <GenericFormCard {...val} />
                            </Card.Body>
                        ))}

                    {!!inspectionDetails?.employeeAccepted && inspectionDetails?.requestForRescheduleData !== null && (
                        <Card.Body>
                            <div className={`ps-2 d-flex gap-2 border my-3 rounded warning`} style={{ fontSize: '16px', padding: '12px' }}>
                                <div className='fw-bold'>
                                    <img alt='' height={16} src={check} />
                                </div>
                                <span className='fw-medium'>The inspection request has been accepted by the GIDC.</span>
                            </div>
                        </Card.Body>
                    )}
                    {/* <InspectionDetailsTable
                column={INSPECTION_ACTIVITY_COLUMN_LESSEE}
                tableRowData={inpsectionData}
                additionalMethod={() => {}}
                deleteOwnershipDetails={() => {}}
                updateOwnershipDataStatus={() => {}}
                isDisabled={false}
                hideSearchBar
                children={renderActionBtn()}
            /> */}
                </Card.Body>
            </Card>
        );
    };

    const renderNOCDocumentDetails = () => {
        let modifiedArray = NOC_DOCUMENTS_DETAILS;
        values.isEditableFile = true;
        // if (!!props?.appStatus) {
        modifiedArray.map((v: any) => {
            v.formData.map((h: any) => (h.isDisabled = true));
        });
        // }
        return (
            <>
                <Card className='card_border my-2'>
                    <Card.Body>
                        {modifiedArray.map(val => (
                            <GenericFormCard {...{ ...val }} />
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    };

    const applicationData = {
        applicationNo: props?.applicationNumber,
        status: props?.previewData?.status,
        data: auditTrial,
        isRoleMD: true,
        bpaData: props?.previewData,
        pendingWith: ''
    };
    return (
        <>
            {!!props.applicationNumber && (!props?.previewData?.status || props?.previewData?.status !== 'APPL_STATUS_OC_SUBMISSION_PENDING') && (
                <Card className='card_border my-2'>
                    <Card.Body>
                        <PaymentSubmissionStatus data={applicationData} progressBarDetails={progressBarDetails} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                    </Card.Body>
                </Card>
            )}
            {props?.activeStepper === 'OCCUPANCY_DETAILS' && renderOccupancyDetails()}
            {props?.activeStepper === 'LESSEE_DETAITLS' && renderBasicDetails()}
            {props?.activeStepper === 'PLOT_DETAILS' && renderBuildingPlanDetails()}
            {props?.activeStepper === 'DOCUMENTS' && renderDocumentDetails()}
            {props.activeStepper === 'DECLARATION' && renderDeclarationDetails()}
            {props.activeStepper === 'INSPECTION_DETAILS' && renderCheckList()}
            {props.activeStepper === 'QUESTIONNAIRE' && props?.renderQuestionnaireDetails()}
            {/* {props.activeStepper === 'NOC_DETAILS' && renderNOCDocumentDetails()} */}
            <GenericPopupModal
                children={renderBPASelection()}
                isPrimaryDisabled={!values?.edcrNo}
                isVisible={isMultiLessee}
                title='Select Construction License Application'
                primaryAction={proceedToBPA}
                secondaryAction={handelIsLesseeVisible}
                primaryBtnTitle='Proceed'
                secondaryBtnTitle='Cancel'
                size='xl'
            />
        </>
    );
}
