import axios from 'axios';
import { devUrl, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { DocumentDetails, dropdownOptionsInterface } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { setEntityDocumentDetails } from '../../Migration/Migration.controller';
import { CORRESPONDENCE, ENTERPRISE, PERMANENT, TENANT_ID } from '../../../../utils/constants';
import { v4 as uuidv4 } from 'uuid';
import { entityDocumentData } from '../EstateManagement.controller';
import _ from 'lodash';
import { getCorrectDateFormate } from '../../../../utils/utils';

export const setEntityDocData = async (data: any) => {
    const initialData: any = {};
    const documentData: any = await entityDocumentData(data);

    // initialData['applicantGstdocument'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GST && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    // initialData['entitygstUndertaking'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.NON_GST && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['applicantPancard'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.PAN && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entityProprietorshipFirm'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entityProprietorIdentity'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['trustDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['partnershipDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['certificateOfRegistrationFirms'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfIncorporation'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfRegistrationSocieties'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['copyRelevantNotification'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['letter'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY && doc.referenceType === DOCUMENT_TYPE.AUTHUSER);

    initialData['RegCertificate'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.UDYAMREGISTRATION && doc.referenceType === DOCUMENT_TYPE.OTHER);
    initialData['DPIITCertificate'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.DPIIT && doc.referenceType === DOCUMENT_TYPE.OTHER);
    initialData['experienceCertificate'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.OTHER_CATEGORY.EXPERIENCED && doc.referenceType === DOCUMENT_TYPE.OTHER);
    return initialData;
};

export const setEnterpriseDocData = async (data: any) => {
    const documentData: any = await entityDocumentData(data);
    const initialData: any = {};
    initialData['inPrincipleApproval'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.IN_PRINCIPAL_APPROVAL);
    initialData['enterpriseSpecialProjectCase'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.IN_PRINCIPAL_APPROVAL);
    initialData['detailedProjectReport'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.DETAILED_PROJECT_REPORT);
    initialData['enterpriseProjectReport'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.DETAILED_PROJECT_REPORT);
    initialData['file3'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.MOHA_FDI_UNDERTAKING_APPROVAL);
    initialData['emdAmountSupportingDocument'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.EMD_DOC);
    initialData['applicantGstdocument'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GST);
    initialData['entitygstUndertaking'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.NON_GST);
    return initialData;
};

export const getApplicantDocuments = (values: any) => {
    let docForCategories: any = {
        PROP_FIRM: ['entityProprietorshipFirm', 'entityProprietorIdentity'],
        TRUST: ['trustDeed'],
        PARTNERSHIP: ['partnershipDeed', 'certificateOfRegistrationFirms'],
        LIMITED_LIABILITY_PARTNERSHIP: ['certificateOfIncorporation'],
        LTD_COMPANY: ['certificateOfIncorporation'],
        COOP_SOCIETY: ['certificateOfRegistrationSocieties'],
        GOV_STAT_CORP: ['copyRelevantNotification']
    };
    let docs: any = [];
    docForCategories[values?.category?.code]?.forEach((ele: any) => {
        docs.push(setEntityDocumentDetails(values?.[ele]) || null);
    });

    docs = [
        ...docs,
        setEntityDocumentDetails(values?.applicantPancard) || null,
        setEntityDocumentDetails(values?.RegCertificate) || null,
        setEntityDocumentDetails(values?.DPIITCertificate) || null,
        setEntityDocumentDetails(values?.residence) || null,
        setEntityDocumentDetails(values?.womenEntreprenuershipDocument) || null,
        setEntityDocumentDetails(values?.experienceCertificate) || null,
        setEntityDocumentDetails(values?.educationCertificate) || null,
        setEntityDocumentDetails(values?.letter) || null
    ].filter(Boolean);
    return docs;
};

export const getEntityData = async (res: any, dropdownOptions: dropdownOptionsInterface) => {
    const documentData: any = await entityDocumentData(res?.documents);
    const docs = await setEntityDocData(documentData);
    let initialData: any = {};
    const address1 = getAddressDetails(res?.addresses[0]);
    const address2 = getAddressDetails(res?.addresses[1]);
    const sameAsAboveCheck = _.isEqual(address1, address2);
    if (sameAsAboveCheck) {
        initialData['copyOnCheck'] = ['on'];
    }

    const permanentAddress = res?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');
    const correspondenceAddress = res?.addresses?.find((val: any) => val?.addressType === 'CORRESPONDENCE');

    initialData['uuid'] = res?.uuid;
    initialData['userUuid'] = res?.userUuid;
    initialData['applicantName'] = res?.entityName;
    initialData['category'] = getDropdownSelectedObject(res?.category, dropdownOptions['category'] || []);
    initialData['emailId'] = res?.email;
    initialData['phoneNumber'] = +res?.phoneNumber;
    initialData['applicantPanNo'] = res?.panNumber;
    // initialData['applicantGst'] = res?.gstApplicable;
    // initialData['applicantGstno'] = res?.gstNumber;
    initialData['address1'] = permanentAddress?.address1;
    initialData['address2'] = permanentAddress?.address2;
    initialData['pincode'] = permanentAddress?.pinCode;
    initialData['district'] = permanentAddress?.district;
    initialData['state'] = permanentAddress?.state;
    initialData['country'] = permanentAddress?.country;
    initialData['peraddress1'] = correspondenceAddress?.address1;
    initialData['peraddress2'] = correspondenceAddress?.address2;
    initialData['perpincode'] = correspondenceAddress?.pinCode;
    initialData['perdistrict'] = correspondenceAddress?.district;
    initialData['perstate'] = correspondenceAddress?.state;
    initialData['percountry'] = correspondenceAddress?.country;
    initialData['authorizedPersonFirstnamesalutation'] = res?.authUsers[0]?.salutation;
    initialData['authorizedPersonFirstname'] = res?.authUsers[0]?.firstName;
    initialData['authorizedPersonLastName'] = res?.authUsers[0]?.lastName;
    initialData['pmobileNumber'] = res?.authUsers[0]?.primaryMobileNo;
    initialData['alternateMobileNumber'] = res?.authUsers[0]?.alternateMobileNo;
    initialData['authorizedEmailId'] = res?.authUsers[0]?.email;
    initialData['accountNumber'] = res?.entityBankDetail?.accountNumber;
    initialData['ifscCode'] = res?.entityBankDetail?.ifscCode;
    initialData['bankName'] = res?.entityBankDetail?.bankName;
    initialData['branchAddress'] = res?.entityBankDetail?.branchAddress;
    initialData['udhyam'] = res?.miscDetails?.isUdhyamRegistered || false;
    initialData['isStartup'] = res?.miscDetails?.isDpiitCertified || false;
    initialData['womenEntrepreneur'] = res?.miscDetails?.isWomenEntreprenueur || false;
    initialData['localPerson'] = res?.miscDetails?.isLocalPerson || false;
    initialData['priorExperience'] = res?.miscDetails?.isExperienced || false;
    initialData['experienceCategory'] = getDropdownSelectedObject(res?.miscDetails?.experience, dropdownOptions['experienceCategory'] || []);
    initialData['udyamNo'] = res?.miscDetails?.udyamNumber;
    initialData['subLeaseEmailId'] = res?.email;
    initialData = { ...initialData, ...docs };
    //initialData['isUpdatable'] = true;
    //initialData['underProcess'] = true;
    return initialData;
};

export const getInitPlotData = (res: any) => {
    let initialData: any = {};
    initialData['estateName'] = getPlotDetails(res?.estateDetails?.estateName);
    initialData['phase'] = getPlotDetails(res?.phase);
    initialData['plotNo'] = getPlotDetails(res?.plotNo);
    initialData['plotType'] = getPlotDetails(res?.plotType);
    initialData['plotRate'] = res?.plotRate;
    initialData['plotArea'] = res?.plotAreaSqm;
    initialData['sameAsAboveAllottedPlotId'] = res?.uuid;

    return initialData;
};

export const getEnterpriseData = async (res: any, dropdownOptions: any) => {
    const documentData: any = await entityDocumentData(res?.documents);
    const docs = await setEnterpriseDocData(documentData);
    let initialData: any = {};
    initialData['applicantGst'] = res?.gstApplicable;
    initialData['applicantGstno'] = res?.gstNumber;
    initialData['employement'] = getDropdownSelectedObject(res?.estimatedNumberOfPersonsToBeEmployed, dropdownOptions['employement'] || []);
    initialData['powerRequirement'] = getDropdownSelectedObject(res?.estimatedPowerRequirement, dropdownOptions['powerRequirement'] || []);
    initialData['projectCost'] = res?.estimatedProjectCost;
    initialData['waterRequirement'] = getDropdownSelectedObject(res?.estimatedWaterRequirement, dropdownOptions['waterRequirement'] || []);
    initialData['confirmation'] = res?.isFdi || false;
    initialData['meansOfFinance'] = res?.meansOfFinance?.map((o: any) => {
        return { ...getDropdownSelectedObject(o, dropdownOptions['meansOfFinance']) };
    });
    initialData['enterpriseCategory'] = getDropdownSelectedObject(res?.enterpriseCategory, dropdownOptions['enterpriseCategory'] || []);
    initialData['pollutionCategory'] = getDropdownSelectedObject(res?.pollutionCategory, dropdownOptions['pollutionCategory'] || []);
    initialData['specialProject'] = res?.specialProject || false;
    initialData['projectType'] = getDropdownSelectedObject(res?.projectType, dropdownOptions['projectType'] || []);
    initialData['confirmation'] = res?.isFdi;
    initialData['subCategory'] = res?.enterpriseSubCategories.map((o: any) => {
        return { ...getDropdownSelectedObject(o, dropdownOptions['subCategory']) };
    });
    initialData['emdAmount'] = res?.emdAmount || '';
    initialData = { ...initialData, ...docs };
    return initialData;
};

export const enterpriseDetailsPayload = (values: any, nicDetails: any, estateData: any = {}) => {
    return {
        ...(estateData?.enterpriseDetails || {}),
        enterpriseSubCategories: values.subCategory?.map((e: any) => e.code),
        gstApplicable: values.applicantGst,
        gstNumber: values.applicantGst ? values?.applicantGstno : undefined,
        specialProject: values.specialProject,
        projectType: values.projectType?.code,
        pollutionCategory: values.pollutionCategory?.code,
        estimatedWaterRequirement: values.waterRequirement?.code,
        estimatedPowerRequirement: values.powerRequirement?.code,
        estimatedNumberOfPersonsToBeEmployed: values.employement?.code,
        estimatedProjectCost: values.projectCost?.toString().replace(/,/g, ''),
        meansOfFinance: values.meansOfFinance && values.meansOfFinance?.map((e: any) => e.code),
        isFdi: values.confirmation,
        enterpriseCategory: values.enterpriseCategory?.code,
        nicEnterpriseMappingRequests: nicDetails.map((e: any) => {
            return { nicMstId: e.nicMstId, proposedActivity: e.proposedActivity };
        }),
        emdAmount: values.emdAmount?.toString().replace(/,/g, '') || null,
        documents: [
            values.specialProject ? setEntityDocumentDetails(values?.inPrincipleApproval) || null : null,
            setEntityDocumentDetails(values?.detailedProjectReport) || null,
            values.confirmation ? setEntityDocumentDetails(values?.file3) || null : null,
            values.emdAmountSupportingDocument ? setEntityDocumentDetails(values.emdAmountSupportingDocument) || null : null,
            values?.entitygstUndertaking && !values?.applicantGst ? setEntityDocumentDetails(values?.entitygstUndertaking) || null : null,
            values?.applicantGstdocument && values?.applicantGst ? setEntityDocumentDetails(values?.applicantGstdocument) || null : null
        ].filter(Boolean)
    };
};

export const allotmentPayload = (
    values: any,
    advertisementNumber: any,
    plotId: any,
    formType: any,
    nicDetails: any,
    controllingOwnershipResponse: any,
    activeIndex: any,
    searchedData: any,
    estateData: any,
    isFinalSubmit: boolean
) => {
    const docs = getApplicantDocuments(values);
    const allotmentData = {
        RequestInfo: requestInfo(),
        advertisementNumber: advertisementNumber || null,
        applicationType: 'ALLOTMENT',
        applicationSubType: formType,
        appliedPlotId: (plotId ? plotId : values?.plotNo?.code) || null,
        assignedPlotId: formType !== 'IDC' ? (plotId ? plotId : values?.plotNo?.code) || null : null,
        isUpdateEnterpriseEntity: activeIndex === 1 ? true : false,
        isUpdateEnterpriseDetails: activeIndex === 3 ? true : false,
        enterpriseEntity: {
            ...searchedData,
            entityName: values.applicantName,
            category: values?.category?.code,
            email: values.emailId,
            phoneNumber: values.phoneNumber,
            panNumber: values.applicantPanNo,
            addresses: [
                {
                    ...searchedData?.addresses?.[0],
                    addressType: PERMANENT,
                    address1: values.address1,
                    address2: values.address2 || null,
                    pinCode: values.pincode,
                    district: values.district,
                    city: null,
                    state: values.state,
                    country: values.country
                },
                {
                    ...searchedData?.addresses?.[1],
                    addressType: CORRESPONDENCE,
                    address1: values.peraddress1,
                    address2: values.peraddress2 || null,
                    pinCode: values.perpincode,
                    district: values.perdistrict,
                    city: null,
                    state: values.perstate,
                    country: values.percountry
                }
            ],
            authUsers: [
                {
                    ...searchedData?.authUsers?.[0],
                    salutation: values.authorizedPersonFirstnamesalutation || null,
                    firstName: values.authorizedPersonFirstname,
                    lastName: values.authorizedPersonLastName,
                    primaryMobileNo: values.pmobileNumber,
                    alternateMobileNo: values.alternateMobileNumber || null,
                    email: values.authorizedEmailId,
                    tenantId: TENANT_ID,
                    type: ENTERPRISE,
                    documents: docs.filter((item: any) => item.referenceType === 'AUTHUSER')
                }
            ],
            owners: controllingOwnershipResponse,
            entityBankDetail: {
                ...searchedData?.entityBankDetail,
                accountNumber: values.accountNumber,
                ifscCode: values.ifscCode,
                bankName: values.bankName,
                branchAddress: values.branchAddress
            },
            documents: docs,
            miscDetails: {
                ...searchedData?.miscDetails,
                isUdhyamRegistered: values.udhyam,
                isDpiitCertified: values.isStartup,
                isExperienced: values.priorExperience,
                udyamNumber: values.udyamNo,
                experience: values?.experienceCategory?.code
            }
        }
    };

    const enterpriseDetails = enterpriseDetailsPayload(values, nicDetails, estateData);

    if (!values.applicationId) return allotmentData;
    else {
        let extra: any = {
            uuid: estateData.uuid,
            content: estateData.content,
            attachmentRefId: estateData.attachmentRefId,
            status: estateData.status
        };
        if (isFinalSubmit) extra.isFinalSubmit = true;
        return { ...allotmentData, ...extra, enterpriseDetails: estateData.enterpriseDetails || activeIndex === 3 ? enterpriseDetails : null };
    }
};

export const getFormatedNICDetails = async (activities: any, dropdownData: any) => {
    const activityDetails = await Promise.all(
        activities.map(async (item: any, ind: number) => {
            const det = item.nicMasterDetails;
            return {
                ...det,
                serialNo: ind + 1,
                rowId: uuidv4(),
                proposedActivity: item?.proposedActivity,
                subClassAndsubClassDescription: det?.subClassCode + '-' + det?.subClassDescription,
                description: { label: det?.subClassDescription, value: det?.subClassCode },
                subClass: det?.subClassCode,
                group: det?.groupCode,
                class: det?.classCode,
                nicMstId: det?.uuid
            };
        })
    );
    return activityDetails;
};

export const getPlotDetails = (resValue: any) => {
    return resValue ? { name: resValue, code: resValue } : '';
};

const getMultiSelectInitalData = (res: any, dropDown: any, category: string) => {
    return res?.map((val: any, ind: number) => getDropdownSelectedObject(res[ind], dropDown?.category || []));
};

export const getAllotmentWorkflowInitialData = async (res: any, dropdownOptions: any) => {
    const entityData = await getEntityData(res.enterpriseEntity, dropdownOptions);
    const enterpriseData = await getEnterpriseData(res.enterpriseDetails, dropdownOptions);
    const plotData = await getInitPlotData(res.appliedPlotDetails);
    const initialData: any = { ...entityData, ...enterpriseData, ...plotData };

    initialData['uuid'] = res?.appliedPlotDetails?.uuid;

    return initialData;
};

export const timestampToDate = (timestamp: any) => {
    if (!timestamp) return '-';
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

export const getAddressDetails = (data: any) => ({
    address1: data?.address1,
    address2: data?.address2,
    pinCode: data?.pinCode,
    state: data?.state,
    district: data?.district,
    country: data?.country
});
