import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GenericHeader from '../moduleCommonFormik/GenericHeader';
import HeaderComponent from '../header/Header.component';
import WaterConnectionImg from '../../assets/logos/waterConn.svg';
import arrow from '../../assets/logos/ArrowUpRight.svg';
import { Link } from 'react-router-dom';
import { WATER_CONNECTION_SCREEN_JSON } from './Screen.const';
import FooterComponent from '../portalComponents/Footer.component';
import { BPArequestInfo, endpoints, POST } from '../../services/modulesServices/userManagement.api';

const WaterConnectionScreen = () => {
    const { t } = useTranslation();
    const entityId = JSON.parse(localStorage.getItem('user') || '{}')?.entityId;
    const [entityDetails, setEntityDetails] = useState([]);
    const [isNewConnectionEnable, setIsNewConnectionEnable] = useState([]);
    const [isUpgradeConnectionEnable, setIsUpgradeConnectionEnable] = useState([]);
    const [isDisconnectConnectionEnable, setIsDisconnectConnectionEnable] = useState([]);
    // useEffect(() => {
    //     const validateWaterConnection = async () => {
    //         const apiurl = endpoints.validateWaterConnection;
    //         const { data } = await POST(`${apiurl}?leasseeId=${entityId}&applicationType=NEW_CONNECTION`, {
    //             RequestInfo: BPArequestInfo()
    //         });
    //         setEntityDetails(data.entityRefDetails?.entityRefDetails);
    //     };

    //     validateWaterConnection();
    // }, []);

    useEffect(() => {
        const validateWaterConnection = async () => {
            const apiurl = endpoints.validateWaterConnection;
            const { data } = await POST(`${apiurl}?leasseeId=${entityId}`, {
                RequestInfo: BPArequestInfo()
            });
            setIsNewConnectionEnable(data?.isNewConnectionEnable);
            setIsUpgradeConnectionEnable(data?.isUpgradeConnectionEnable);
            setIsDisconnectConnectionEnable(data?.isDisconnectConnectionEnable);
        };

        validateWaterConnection();
    }, []);

    return (
        <>
            <HeaderComponent />
            <div className=' pt-4 apply-plot-screen-parents font-inter ' style={{ background: '#F5F5F5' }}>
                <div className='row gx-0 px-5 '>
                    <div className='col-12'>
                        <GenericHeader text={t('WATER_CONNECTION', 'Water Connection')} />
                    </div>
                    <div className='col-12 border-rad-12 border mt-3 water-screen-main-card d-flex flex-column '>
                        <div className='map-image d-flex flex-column align-items-center '>
                            <img src={WaterConnectionImg} alt='water-connection' />
                            <div className='fw-bold screen-text-color font-24 lh-29 ls-2 text-center'>Water connection</div>
                        </div>
                        <div className='row border-top py-3 mt-3 mx-3 gx-3'>
                            {WATER_CONNECTION_SCREEN_JSON.map((val: any, ind: number) => (
                                <div className='col-4'>
                                    <Link
                                        className='action-cards d-flex flex-column border-rad-12 p-3'
                                        style={{
                                            opacity:
                                                (val.titleCode === 'NEW_CONNECTION' && !!isNewConnectionEnable) ||
                                                (val.titleCode === 'UPGRADATION' && !!isUpgradeConnectionEnable) ||
                                                (val.titleCode === 'DISCONNECTION' && !!isDisconnectConnectionEnable)
                                                    ? 1
                                                    : 0.5
                                        }}
                                        to={
                                            (val.titleCode === 'NEW_CONNECTION' && !!isNewConnectionEnable) ||
                                            (val.titleCode === 'UPGRADATION' && !!isUpgradeConnectionEnable) ||
                                            (val.titleCode === 'DISCONNECTION' && !!isDisconnectConnectionEnable)
                                                ? val?.btnURL
                                                : ''
                                        }
                                    >
                                        <img src={val?.logo} style={{ width: 'max-content' }} alt='' />
                                        <div className='mt-2 d-flex justify-content-between '>
                                            <span className='fw-semibold  dashboard-text-color lh-24 font-18'>{val?.btnTitle}</span>
                                            <span>
                                                <img src={arrow} alt='' />
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='mt-1'>
                    <FooterComponent onlyCopyright={true} />
                </div>
            </div>
        </>
    );
};

export default WaterConnectionScreen;
