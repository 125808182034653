import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import { CONSTANT_DATA_FIELD } from '../../../utils/constants';
import { getDropdownSelectedObject } from '../UserManagement/UserMangement.controller';

interface applicantDetailsProp {
    dropdownOptions?: any;
}

export default function ApplicantDetailsWorkflow(props: applicantDetailsProp) {
    const { dropdownOptions } = props;

    const APPLICANT_DETAILS_EXTENSION = [
        {
            formData: [
                {
                    title: 'User Name',
                    titleCode: 'APPLICANT_NAME',
                    field: 'applicantName',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                },
                {
                    title: 'User Category',
                    titleCode: 'APPLICANT_CATEGORY',
                    field: 'category',
                    type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                    isDisabled: true
                },
                {
                    title: 'Email ID',
                    titleCode: 'EMAIL_ID',
                    field: 'emailId',
                    type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                    isDisabled: true
                },
                {
                    title: 'Phone No',
                    titleCode: 'PHONE_NO',
                    field: 'phoneNumber',
                    type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                    isDisabled: true
                }
            ]
        },
        {
            title: 'Address Details',
            titleCode: 'ADDRESS_DETAILS',
            formData: [
                {
                    title: 'Address Line 1',
                    titleCode: 'ADDRESS_LINE_1',
                    field: 'addressLine1',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                },
                {
                    title: 'Address Line 2',
                    titleCode: 'ADDRESS_LINE_2',
                    field: 'addressLine2',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                },
                {
                    title: 'Pin Code',
                    titleCode: 'PINCODE',
                    field: 'pincode',
                    type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                    isDisabled: true
                },
                {
                    title: 'District',
                    titleCode: 'DISTRICT',
                    field: 'district',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                },
                {
                    title: 'State/UT',
                    titleCode: 'STATE_UT',
                    field: 'state',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                },
                {
                    title: 'Country',
                    titleCOde: 'COUNTRY',
                    field: 'country',
                    type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                    isDisabled: true
                }
            ]
        }
    ];

    return (
        <>
            {APPLICANT_DETAILS_EXTENSION.map((val: any) => (
                <GenericFormCard {...val} dropdownOptions={dropdownOptions} />
            ))}
        </>
    );
}

export const getApplicantInitData = (data: any, dropdownData?: any) => {
    const permanentAddress = data?.enterpriseEntity?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');
    const initialData: any = {};

    initialData['applicantName'] = data?.enterpriseEntity?.entityName;
    initialData['category'] = getDropdownSelectedObject(data?.enterpriseEntity?.category, dropdownData['category'] || []);
    initialData['emailId'] = data?.enterpriseEntity?.email;
    initialData['phoneNumber'] = data?.enterpriseEntity?.phoneNumber;
    initialData['addressLine1'] = permanentAddress?.address1;
    initialData['addressLine2'] = permanentAddress?.address2;
    initialData['pincode'] = permanentAddress?.pinCode;
    initialData['district'] = permanentAddress?.district;
    initialData['state'] = permanentAddress?.state;
    initialData['country'] = permanentAddress?.country;
    return initialData;
};
