import React, { useEffect, useState } from 'react';
import { POST, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { useParams } from 'react-router-dom';
import PopUps from '../../../../components/PopUps';
import { check, cross } from '../../../../utils/Logo';
import { useTranslation } from 'react-i18next';


export const PaymentStatus = () => {
    const { t } = useTranslation();
    const [url, setUrl] = useState<string>('');
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isPending, setIsPending] = useState<boolean>(false);
    const [isFailed, setIsFailed] = useState<boolean>(false);
    const [modalDetails, setModalDetails] = useState({});
    useEffect(() => {
        setUrl(window.location.search);
    }, []);

    const urlParams = new URLSearchParams(url);
    const egPgTxnid = urlParams.get('eg_pg_txnid');
    const msg = urlParams.get('msg');
    console.log('Param', egPgTxnid, msg);
    useEffect(() => {
        let payload = {
            RequestInfo: requestInfo()
        };
        egPgTxnid &&
            msg &&
            POST(endpoints.updateTransaction + `?eg_pg_txnid=${egPgTxnid}&msg=${msg}`, payload)
                .then(res => {
                    setModalDetails({ show: true, title: 'Success', para: t('PAYMENT_SUCCESSFULL','Payment successful'), img: check, type: 'Payment success', reUpload: 'OK' });
                    res.data.Transaction.map((val: any, index: number) => {
                        if (val.txnStatus === 'SUCCESS') {
                            setIsSuccess(true);
                        } else if (val.txnStatus === 'PENDING') {
                            setIsPending(true);
                        } else if (val.txnStatus === 'FAILED') {
                            setIsFailed(true);
                        }
                    });
                })
                .catch(e => {
                    setModalDetails({ show: true, title: 'Error!', para: t('PAYMENT_FAIL','Payment failed'), img: cross, type: 'error', reUpload: 'OK' });
                });
    }, [url]);
    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            {isSuccess && <p className='fw-bolder text-success font-20 font-italic'>{t('PAYMENT_SUCCESS_MSG','Payment Successful!!')}</p>}
            {isPending && <p className='fw-bolder text-warning font-20 font-italic'>{t('PAYMENT_PENDING_MSG','Payment Pending!!')}</p>}
            {isFailed && <p className='fw-bolder text-danger font-20 font-italic'>{t('PAYMENT_FAILED_MSG','Payment Failed!!')}</p>}
        </>
    );
};
