import { isDisabled } from '@testing-library/user-event/dist/utils';
import { POST, estateEndpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import _ from 'lodash';

export const getPlotDetailsPayload = (values: any, estateUuid: string, dropdownData?: any) => {
    console.log('values in payload func.', values);
    const villages = values.village?.flatMap((item: any) => dropdownData['village'].filter((subItem: any) => subItem.code === item.code));
    return {
        ...values,
        uuid: values.uuid || undefined,
        plotNo: values.plotNo,
        phase: values.phase,
        plotType: values.plotType?.code,
        plotAreaSqm: values.plotAreaSqm,
        estateDetails: {
            uuid: estateUuid
        },
        plotDetails: {
            uuid: values?.plotDetails?.uuid || undefined,
            plotStatus: null,
            plotBoundaryNorth: values.plotBoundaryNorth || values?.plotDetails?.plotBoundaryNorth,
            plotBoundarySouth: values.plotBoundarySouth || values?.plotDetails?.plotBoundarySouth,
            plotBoundaryEast: values.plotBoundaryEast || values?.plotDetails?.plotBoundaryEast,
            plotBoundaryWest: values.plotBoundaryWest || values?.plotDetails?.plotBoundaryWest,
            villageDetails: values.villageMasterDetailsList || villages || values?.plotDetails?.villageDetails
        }
    };
};

export const setPlotDetails = async (details: any, dropdownData: any, type?: string) => {
    const data = _.cloneDeep(details);
    const estateData = await POST(estateEndpoints.estateSearch + `?uuid=${data.estateId}`, { RequestInfo: requestInfo() });
    console.log('setValues', data);

    return {
        ...data,
        ...data.plotDetails,
        uuid: type === 'NEW' ? null : data.uuid,
        plotType: getDropdownSelectedObject(data.plotType, dropdownData['plotType']),
        village: data.plotDetails.villageDetails.length ? data.plotDetails.villageDetails?.map((item: any) => ({ code: item.villageCode, name: item.villageName })) : [{ code: 'NA', name: 'NA' }],
        villageMasterDetailsList: data.plotDetails.villageDetails?.map((item: any) => ({
            code: item.villageCode,
            name: item.villageName,
            uuid: item.uuid
        })),
        estateName: { code: estateData.data.estateMasterDetails[0].estateName, name: estateData.data.estateMasterDetails[0].estateName },
        district: dropdownData?.district?.find((item: any) => {
            if (item.code === data?.estateDetails.estateDetails.districtCode) {
                return item;
            }
        }) || { code: 'NA', name: 'NA' },
        taluka: data.plotDetails.villageDetails.length
            ? {
                  code: data.plotDetails.villageDetails?.[0].talukaCode,
                  name: data.plotDetails.villageDetails?.[0].talukaName
              }
            : { code: 'NA', name: 'NA' }
    };
};

export const SEARCH_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Estate Name',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-10 me-0',
                isRequired: true
            }
        ]
    }
];

export const plotInitialValues = {
    estateName: null,
    plotNo: '',
    phase: '',
    plotType: null,
    plotAreaSqm: '',
    village: null,
    plotBoundarySouth: '',
    plotBoundaryWest: '',
    plotBoundaryEast: '',
    plotBoundaryNorth: ''
};

export const PLOT_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Plot No.',
                field: 'plotNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true
            },
            {
                title: 'Phase.',
                field: 'phase',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true
            },
            {
                title: 'Estate Name',
                field: 'estateName',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-4 me-0',
                isDisabled: true
            },
            {
                title: 'Plot Type',
                field: 'plotType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true
            },
            {
                title: 'Plot Area (Sq.m.)',
                field: 'plotAreaSqm',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true
            },
            {
                title: 'Taluka',
                field: 'taluka',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Village',
                field: 'village',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true,
                multiSelect: true
            },

            {
                title: 'Plot boundary on or by North',
                field: 'plotBoundaryNorth',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true
            },
            {
                title: 'Plot boundary on or by East',
                field: 'plotBoundaryEast',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true
            },
            {
                title: 'Plot boundary on or by West',
                field: 'plotBoundaryWest',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true
            },
            {
                title: 'Plot boundary on or by South',
                field: 'plotBoundarySouth',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-4 me-0',
                isRequired: true
            }
            // {
            //     title: 'Survey No',
            //     field: 'surveyNo',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0',
            //     isRequired: true
            // },

            // {
            //     title: 'Taluka',
            //     field: 'talukaRevenueOfficeRegistrationNo',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0',
            //     isRequired: true
            // },
            // {
            //     title: 'Land Registration No.',
            //     field: 'landRegistrationNo',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0',
            //     isRequired: true
            // },
            // {
            //     title: 'Water availability',
            //     field: 'isWaterAvailable',
            //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
            //     class: 'col-sm-4 me-2',
            //     isRequired: true,
            //     options: [
            //         { value: true, title: 'Yes' },
            //         { value: false, title: 'No' }
            //     ]
            // },
            // {
            //     title: 'Road availability',
            //     field: 'isRoadAvailable',
            //     isRequired: true,
            //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
            //     class: 'col-sm-4 me-2',
            //     options: [
            //         { value: true, title: 'Yes' },
            //         { value: false, title: 'No' }
            //     ]
            // },
            // {
            //     title: 'Electricity availability',
            //     field: 'isElectricityAvailable',
            //     isRequired: true,
            //     type: CONSTANT_DATA_FIELD.RADIO_FIELD,
            //     class: 'col-sm-3 me-3',
            //     options: [
            //         { value: true, title: 'Yes' },
            //         { value: false, title: 'No' }
            //     ]
            // },
            // {
            //     title: 'Distance from Port (in km)',
            //     field: 'portDistanceKm',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0'
            // },
            // {
            //     title: 'Distance from Airport (in km)',
            //     field: 'airportDistanceKm',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0'
            // },
            // {
            //     title: 'Distance from Fire Station (in km)',
            //     field: 'fireStationDistanceKm',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0'
            // },
            // {
            //     title: 'Distance from Rest (in km)',
            //     field: 'restAreaDistanceKm',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0'
            // },
            // {
            //     title: 'Distance from Fire Parking (in km)',
            //     field: 'parkingDistanceKm',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0'
            // },
            // {
            //     title: 'Distance from Police Station (in km)',
            //     field: 'policeStationDistanceKm',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0'
            // },
            // {
            //     title: 'Distance from Fire School (in km)',
            //     field: 'schoolDistanceKm',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0'
            // },
            // {
            //     title: 'Distance from Hospital (in km)',
            //     field: 'hospitalDistanceKm',
            //     type: CONSTANT_DATA_FIELD.TEXT_FIELD,
            //     class: 'col-sm-4 me-0'
            // }
        ]
    }
];

// export const PLOT_APPLICATION = [
//     {
//         titleCode: 'PLOT_NO.',
//         colName: 'Plot No.',
//         fieldName: 'plotNo',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 3
//     },
//     {
//         titleCode: 'Name of Estate',
//         colName: 'Name of Estate',
//         fieldName: 'estateName',
//         cellstyle: { width: '120px' },
//         type: 'text',
//         id: 2
//     },
//     {
//         titleCode: 'PHASE_NO',
//         colName: 'Phase No.',
//         fieldName: 'phase',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 3
//     },
//     {
//         titleCode: 'PLOT_CATEGORY',
//         colName: 'Plot Category',
//         fieldName: 'plotType',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 4
//     },
//     {
//         titleCode: 'PLOT_SIZE',
//         colName: 'Plot Size',
//         fieldName: 'plotType',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 4
//     },
//     {
//         titleCode: 'PLOT_STATUS',
//         colName: 'Plot Status',
//         fieldName: 'estateName',
//         cellstyle: { width: '120px' },
//         type: 'text',
//         id: 2
//     },
//     {
//         titleCode: 'PLOT_TYPE',
//         colName: 'Plot Type',
//         fieldName: 'plotType',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 4
//     },
//     {
//         titleCode: 'PLOT_AREA',
//         colName: 'Plot Area (Sq.m.)',
//         fieldName: 'plotAreaSqm',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 5
//     },
//     {
//         titleCode: 'PLOT_RATE',
//         colName: 'Plot Rate (INR/Sq.m.)',
//         fieldName: 'plotRate',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 6
//     },
//     {
//         titleCode: 'ISACTIVE',
//         colName: 'Status',
//         fieldName: 'isActiveName',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 7
//     },
//     {
//         titleCode: 'PLOT_BOUNDARY_NORTH',
//         colName: 'Plot boundary on or by North',
//         fieldName: 'plotBoundaryOnOrByNorth',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 8
//     },
//     {
//         titleCode: 'PLOT_BOUNDARY_EAST',
//         colName: 'Plot boundary on or by East',
//         fieldName: 'plotBoundaryOnOrByEast',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 9
//     },
//     {
//         titleCode: 'PLOT_BOUNDARY_WEST',
//         colName: 'Plot boundary on or by West',
//         fieldName: 'plotBoundaryOnOrByWest',
//         cellstyle: { width: '200px' },
//         clsName: 'column-text-start',
//         type: 'text',
//         id: 10
//     },
//     {
//         titleCode: 'PLOT_BOUNDARY_SOUTH',
//         colName: 'Plot boundary on or by South',
//         fieldName: 'plotBoundaryOnOrBySouth',
//         cellstyle: { width: '200px' },
//         clsName: 'column-text-start',
//         type: 'text',
//         id: 11
//     },
//     {
//         titleCode: 'SURVEY_NO',
//         colName: 'Survey No',
//         fieldName: 'surveyNo',
//         cellstyle: { width: '200px' },
//         clsName: 'column-text-start',
//         type: 'text',
//         id: 12
//     },
//     {
//         titleCode: 'VILLAGE',
//         colName: 'Village',
//         fieldName: 'village',
//         cellstyle: { width: '200px' },
//         clsName: 'column-text-start',
//         type: 'text',
//         id: 13
//     },
//     {
//         titleCode: 'TALUKA',
//         colName: 'Taluka',
//         fieldName: 'talukaRevenueOfficeRegistrationNo',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 14
//     },
//     {
//         titleCode: 'LAND_REGISTRATION_NO',
//         colName: 'Land Registration No',
//         fieldName: 'landRegistrationNo',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 15
//     },
//     {
//         titleCode: 'DISTANCE_FROM_AIRPORT',
//         colName: 'Distance from Airport (in km)',
//         fieldName: 'airportDistanceKm',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 16
//     },
//     {
//         titleCode: 'DISTANCE_FROM_PORT',
//         colName: 'Distance from Port (in km)',
//         fieldName: 'portDistanceKm',
//         cellstyle: { width: '200px' },
//         clsName: 'column-text-start',
//         type: 'text',
//         id: 17
//     },
//     {
//         titleCode: 'DISTANCE_FROM_RESTAREA',
//         colName: 'Distance from Rest (in km)',
//         fieldName: 'restAreaDistanceKm',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 18
//     },
//     {
//         titleCode: 'DISTANCE_FROM_FIRESTATION',
//         colName: 'Distance from Fire Station (in km)',
//         fieldName: 'fireStationDistanceKm',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 19
//     },
//     {
//         titleCode: 'DISTANCE_FROM_POLICESTATION',
//         colName: 'Distance from Police Station (in km)',
//         fieldName: 'policeStationDistanceKm',
//         cellstyle: { width: '200px' },
//         clsName: 'column-text-start',
//         type: 'text',
//         id: 20
//     },
//     {
//         titleCode: 'DISTANCE_FROM_FIREPARKING',
//         colName: 'Distance from Fire Parking (in km)',
//         fieldName: 'parkingDistanceKm',
//         cellstyle: { width: '200px' },
//         clsName: 'column-text-start',
//         type: 'text',
//         id: 21
//     },
//     {
//         titleCode: 'DISTANCE_FROM_HOSPITAL',
//         colName: 'Distance from Hospital (in km)',
//         fieldName: 'hospitalDistanceKm',
//         cellstyle: { width: '200px' },
//         clsName: 'column-text-start',
//         type: 'text',
//         id: 22
//     },
//     {
//         titleCode: 'DISTANCE_FROM_FIRESCHOOL',
//         colName: 'Distance from Fire School (in km)',
//         fieldName: 'schoolDistanceKm',
//         clsName: 'column-text-start',
//         cellstyle: { width: '200px' },
//         type: 'text',
//         id: 23
//     }
// ];

export const MASTER_TABLE_COLUMNS: any = [
    // {
    //     colName: '',
    //     fieldName: 'checkbox',
    //     // cellstyle: { width: '50px', padding: '13px 0px;' },
    //     cellstyle: { width: '2%', padding: '13px 0px;' },
    //     clsName: 'text-start py-3',
    //     id: 1
    // },
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'index',
        clsName: 'column-text-start',
        // cellstyle: { width: '100px' },
        cellstyle: { width: '8%' },
        id: 1
    },
    {
        titleCode: 'PLOT_NO.',
        colName: 'Plot No.',
        fieldName: 'plotNo',
        clsName: 'column-text-start',
        cellstyle: { width: '10%' },
        // cellstyle: { width: '200px' },
        type: 'text',
        id: 3
    },
    {
        titleCode: 'Name of Estate',
        colName: 'Name of Estate',
        fieldName: 'estateDetails.estateName',
        cellstyle: { width: '18%' },
        clsName: 'column-text-start',
        // cellstyle: { width: '120px' },
        type: 'text',
        id: 2
    },
    {
        titleCode: 'PHASE_NO',
        colName: 'Phase No.',
        fieldName: 'phase',
        clsName: 'column-text-start',
        cellstyle: { width: '12%' },
        // cellstyle: { width: '200px' },
        type: 'text',
        id: 3
    },
    {
        titleCode: 'PLOT_CATEGORY',
        colName: 'Plot Category',
        fieldName: 'plotType',
        clsName: 'column-text-start',
        cellstyle: { width: '15%' },
        // cellstyle: { width: '200px' },
        type: 'text',
        id: 4
    },
    {
        titleCode: 'PLOT_AREA',
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotAreaSqm',
        clsName: 'column-text-start',
        cellstyle: { width: '15%' },
        // cellstyle: { width: '200px' },
        type: 'text',
        id: 4
    },
    {
        titleCode: 'PLOT_STATUS',
        colName: 'Plot Status',
        fieldName: 'status',
        cellstyle: { width: '10%' },
        // cellstyle: { width: '120px' },
        type: 'text',
        id: 2
    }
    // {
    //     colName: 'Action',
    //     type: 'action',
    //     clsName: 'fixed-action-column fixed-action-column-bg',
    //     cellstyle: { width: '12%' },
    //     actions: ['edit', 'view']
    // }
];

export const MASTER_TABLE_COLUMNS_VACANT: any = [
    {
        titleCode: 'SL_NO',
        colName: 'SN',
        fieldName: 'index',
        clsName: 'column-text-start',
        cellstyle: { width: '8%' },
        id: 1
    },
    {
        titleCode: 'PLOT_NO.',
        colName: 'Plot No.',
        fieldName: 'plotNo',
        clsName: 'column-text-start',
        cellstyle: { width: '15%' },
        type: 'text',
        id: 3
    },
    {
        titleCode: 'Name of Estate',
        colName: 'Name of Estate',
        fieldName: 'estateDetails.estateName',
        cellstyle: { width: '20%' },
        clsName: 'column-text-start',
        type: 'text',
        id: 2
    },
    {
        titleCode: 'PHASE_NO',
        colName: 'Phase No.',
        fieldName: 'phase',
        clsName: 'column-text-start',
        cellstyle: { width: '17%' },
        type: 'text',
        id: 3
    },
    {
        titleCode: 'PLOT_CATEGORY',
        colName: 'Plot Category',
        fieldName: 'plotType',
        clsName: 'column-text-start',
        cellstyle: { width: '20%' },
        type: 'text',
        id: 4
    },
    {
        titleCode: 'PLOT_AREA',
        colName: 'Plot Area (Sq.m.)',
        fieldName: 'plotAreaSqm',
        clsName: 'column-text-start',
        cellstyle: { width: '20%' },
        type: 'text',
        id: 4
    }
];

export const EXISTING_PLOT_DETAILS = [
    // {
    //     title: 'S No.',
    //     field: 'serialNumber'
    // },
    {
        title: 'Plot No.',
        field: 'plotNo'
    },
    {
        title: 'Phase',
        field: 'phase'
    },
    {
        title: 'Estate Name',
        field: 'estateDetails.estateName'
    },
    {
        title: 'Plot Area (sqm)',
        field: 'plotAreaSqm'
    },
    {
        title: 'Plot Rate (in INR)',
        field: 'plotRate'
    },
    {
        title: 'Plot Type',
        field: 'plotType'
    },
    {
        title: 'Taluka',
        field: 'plotDetails.villageDetails.[0].talukaName'
    },
    {
        title: 'Village',
        field: 'village'
    }
];

export const PLOT_EXTRA_DETAILS = [
    {
        // titleCode: 'PLOT_ALLOTMENT_DETAILS',
        // title: 'Plot Allotment Details',
        formData: [
            {
                title: 'Plot Status',
                titleCode: 'PLOT_STATUS',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                field: 'plotStatus',
                class: 'col-sm-4'
            },
            {
                title: 'Owner Name',
                titleCode: 'OWNER_NAME',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                field: 'ownerName',
                class: 'col-sm-4'
            },
            {
                title: 'Owner Phone No.',
                titleCode: 'OWNER_PHONE',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                field: 'ownerPhone',
                class: 'col-sm-4'
            },
            {
                title: 'Owner Email',
                titleCode: 'OWNER_EMAIL',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                field: 'ownerEmail',
                class: 'col-sm-4'
            },
            {
                title: 'Project Name',
                titleCode: 'PROJECT_NAME',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                field: 'projectName',
                class: 'col-sm-4'
            }
        ]
    }
];

export const getLesseeDetails = (data: any) => {
    let initialData: any = {};
    initialData['ownerName'] = (data?.plotDetails?.enterPriseEntity?.authUsers[0].firstName || '') + ' ' + (data?.plotDetails?.enterPriseEntity?.authUsers[0].lastName || '');
    initialData['ownerPhone'] = data?.plotDetails?.enterPriseEntity?.authUsers[0].primaryMobileNo;
    initialData['ownerEmail'] = data?.plotDetails?.enterPriseEntity?.authUsers[0].email;
    initialData['plotStatus'] = data?.plotDetails?.plotStatus;
    initialData['projectName'] = data?.plotDetails?.enterPriseEntity?.entityName;
    return initialData;
};
