import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { file_preview } from "../../../../utils/Logo";
import { FileEnum } from "../../../../components/moduleCommonFormik/GenericFormCard.constants";
import { getFileUrl } from "../../../../services/modulesServices/userManagement.api";
import { useTranslation } from "react-i18next";
import { getFileIcon } from "../../../../utils/utils";

interface itemProps {
  value: string;
  label: string;
}

interface GenericPreviewProps {
  details: any;
  sectionTitle: string[];
  documents?: any;
  titleCode?: any;
}

export default function GenericPreview({ details, sectionTitle, documents, titleCode }: GenericPreviewProps) {
  const { t, i18n } = useTranslation();
  const { values, errors, touched, setFieldValue } = useFormikContext<any>();



  console.log(values, details[0]);
  return (
    <>
      <div className="font-inter fs-4">{t(titleCode, `${sectionTitle}`)}</div>
      {details[0].map((section: any) => {
        if (!!section?.multisection) {
          return values[`${section?.multisection}`].map((assignSection: any, index: number) => (
            <div className="row multisection">
              {section.title && <div className="formTitle mt-1">{titleCode ? t(titleCode) : sectionTitle}</div>}
              {section.formData.map((val: any) => (
                <div className={`py-3 ${val?.class ? val?.class : "col-sm-6"}`}>
                  <label>{val.titleCode ? t(val.titleCode) : val.title}</label>
                  {val.title === "Roles" ? (
                    values[section.multisection][index][val.field]?.map((role: any) => <div className="fw-500">{role.name}</div>)
                  ) : val.type === "select" ? (
                    <div className="fw-500">{values[section.multisection][index][val.field]?.name}</div>
                  ) : (
                    <div className="fw-500">
                      {val.field !== "isPrimary" ? values[section.multisection][index][val.field]?.toString() : values[section.multisection][index][val.field] === true ? "Yes" : "No"}
                    </div>
                  )}
                  { }
                </div>
              ))}
            </div>
          ));
        } else {
          return (
            <>
              <div className="row">
                {section.title && <div className="formTitle mt-1">{t(titleCode)}</div>}
                {section.formData.map((val: any) => {
                  if (val.type !== "file") {
                    return (
                      <>
                        <div className={`py-3 ${val?.class ? val?.class : "col-sm-6"}`}>
                          <label>{val.titleCode ? t(val.titleCode) : val.title}</label>
                          {val.type === "multi" ? (
                            <div className="fw-500">{values[val.field]?.map((selectedOption: itemProps) => <div className="fw-500">{selectedOption.value}</div>)}</div>
                          ) : val.type === "select" ? (
                            <div className="fw-500">{values[val.field]?.name}</div>
                          ) : (
                            <div className="fw-500">{values[val.field]}</div>
                          )}
                        </div>
                      </>
                    );
                  } else {
                    return (
                      <div className={`py-3 ${val?.class ? val?.class : "col-sm-6"}`}>
                        <label>{val.titleCode ? t(val.titleCode) : val.title}</label>
                        {documents?.map((doucmentItem: any, index: number) => {
                          if (doucmentItem["referenceType"] === FileEnum[`${val.title}`]) {
                            // let docUrl = await getFileUrl(doucmentItem.referenceId);

                            return (
                              <div className="d-flex" key={doucmentItem.id}>
                                <Link to={doucmentItem?.documentUrl} target="_blank">
                                  <Image src={getFileIcon(doucmentItem?.documentName)} height="80%" />
                                </Link>
                                <div>
                                  <div className="font-14">{doucmentItem.documentName}</div>
                                  <div className="font-14">{doucmentItem.documentSize}</div>
                                  <div className="font-14">{doucmentItem.uploadedDate}</div>
                                </div>
                              </div>
                            );
                          }

                        })}

                      </div>
                    );
                  }
                })}
              </div>
            </>
          );
        }
      })}
    </>
  );
}
