// store.ts
import { configureStore } from '@reduxjs/toolkit';
import notificationReducer from '../src/redux/notificationSlice';
import authReducer from "./redux/authSlice";

const store = configureStore({
  reducer: {
    notification: notificationReducer,
    auth: authReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

