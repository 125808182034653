import React, { useState, useEffect } from 'react';
import { logout, setting } from '../../utils/Logo';
import { useNavigate } from 'react-router-dom';
import { mappedUserInterface, userInterface } from '../../features/modules/UserManagement/types';
import { getMappedRoles, userLogout } from '../../services/modulesServices/userManagement.api';
// import { Intersect } from "@phosphor-icons/react/dist/ssr";
import { Link } from 'react-router-dom';
import { gauge, userSwitch } from '../../utils/Images';
import { getNavigateTo } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { USER_TYPE } from '../../utils/constants';

interface UserMenuProps {
    user: userInterface[];
    setDropdownOpen: (e: boolean) => void;
    isDropdownOpen: boolean;
    isMultiRole?: boolean;
}

function UserAssignmentMenu(props: UserMenuProps) {
    const getUserType = JSON.parse(localStorage.getItem('type') || '');
    const { t } = useTranslation();
    const { setDropdownOpen, user, isMultiRole } = props;
    console.log('User', user);
    const currentRole: mappedUserInterface = JSON.parse(localStorage.getItem('currentUserAssignment') || '{}');

    const [isUserAssignmentsModalOpen, setIsUserAssignmentsModalOpen] = useState(false);
    const [userRoles, setUserRoles] = useState<mappedUserInterface[]>([]);
    let navigate = useNavigate();

    const loggedInUserType = JSON.parse(localStorage.getItem('type') ?? 'null');

    useEffect(() => {
        (async () => {
            if (user?.length) {
                const mappedRoles: mappedUserInterface[] = await getMappedRoles(user);
                setUserRoles(mappedRoles);
            }
        })();
    }, []);

    const handleOnSwitchUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsUserAssignmentsModalOpen(true);
    };

    const handleOnLogout = () => {
        userLogout();
        localStorage.clear();
        window.location.href = '/goa-idc/';
    };

    const handleUserProfileClick = async () => {
        if (getUserType === USER_TYPE.EMP) {
            navigate('/goa-idc/emp-profile');
        } else if (getUserType === USER_TYPE.ENTERPRISE) {
            navigate('/goa-idc/enterprise-profile');
        }

        //
    };

    const handleUserAssignmentSwitching = (e: any, selectedRole: mappedUserInterface) => {
        localStorage.setItem('currentUserAssignment', JSON.stringify(selectedRole));
        setIsUserAssignmentsModalOpen(false);
        setDropdownOpen(false);
        navigate('/goa-idc/dashboard');
        window.location.reload();
    };

    const onMouseLeave = (e: any) => {
        setIsUserAssignmentsModalOpen(false);
    };

    return (
        <>
            <div className='dropdown-content userprofile-content'>
                <div onMouseEnter={onMouseLeave}>
                    <button className='dropdown-item' onClick={handleUserProfileClick}>
                        <img src={setting} alt='' style={{ height: 20, width: 20 }} className='me-3' />
                        {t('MY_PROFILE', 'My Profile')}
                    </button>
                </div>
                <div onMouseEnter={onMouseLeave}>
                    <Link to={getNavigateTo(loggedInUserType)} style={{ color: 'black' }}>
                        <button className='dropdown-item'>
                            {/* <Intersect color="green" size={20} className="me-3" weight="light" onClick={() => navigate('/')} /> */}
                            <img src={gauge} alt='' style={{ height: 20, width: 20 }} className='me-3' />
                            {t('DASHBOARD', 'Dashboard')}
                        </button>
                    </Link>
                </div>
                {loggedInUserType === 'EMPLOYEE' && isMultiRole && (
                    <>
                        <div>
                            <button className='dropdown-item' onMouseEnter={handleOnSwitchUser}>
                                <img src={userSwitch} alt='' style={{ height: 20, width: 20 }} className='me-3' />
                                {t('SWITCH_ROLE', 'Switch Role')} <span className='ms-4'>&raquo;</span>
                            </button>
                        </div>
                    </>
                )}
                <div onMouseEnter={onMouseLeave}>
                    <button className='dropdown-item' onClick={handleOnLogout}>
                        <img src={logout} alt='' style={{ height: 20, width: 20 }} className='me-3' />
                        {t('LOGOUT', 'Logout')}
                    </button>
                </div>
            </div>

            {isUserAssignmentsModalOpen && userRoles.length > 1 && (
                <div className='submenu-content'>
                    {userRoles?.map((role: mappedUserInterface) => {
                        if (currentRole?.department.code !== role.department.code) {
                            return (
                                <>
                                    <div onClick={(e: any) => handleUserAssignmentSwitching(e, role)}>{`${role.designation.name} - ${role.department.name}`}</div>
                                </>
                            );
                        } else {
                            <></>;
                        }
                    })}
                </div>
            )}
        </>
    );
}

export default UserAssignmentMenu;
