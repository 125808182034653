import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { APPLICANT_DETAILS_I, APPLICANT_DETAILS_II, BANK_DETAILS, PROMOTER_DETAILS } from './Allotment.const';
import { useEffect, useState } from 'react';
import GenericOwnerShipDetails from '../../../../components/Ownership/GenericOwnership';
import { useLocation } from 'react-router-dom';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { Card } from 'react-bootstrap';
import { POST, endpoints, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import _ from 'lodash';
import { cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';

export default function ApplicantDetails(props: any) {
    const location: any = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const otpReferenceId = searchParams.get('otpReferenceId');
    const { values, setFieldValue, setValues } = useFormikContext<any>();
    const [modalDetails, setModalDetails] = useState({});
    const [ifscData, setIfscData] = useState<any>(null);

    const [finalOwnerShipDetails, setFinalOwnerShipDetails] = useState({
        applicantDetails: []
    });
    const [time, setTime] = useState<any>(0);
    const tooltipDetails: any = {
        ownershipDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Ownership Interest" means:</div> <div>(i)	holding of voting rights in a body corporate as defined under the Companies Act, 2013 (Act No. 18 of 2013); or</div>
            <div>(ii)	ownership of or entitlement to the capital or profits in a limited liability partnership, partnership firm, society or trust;</div></div>
            `
        },
        promoterDetails: {
            position: 'right',
            content: `<div class="d-flex flex-column font-inter" style="gap:5px;"><div class="mb-2">"Promoter" means:</div> <div>(i)	with respect to a body corporate, Person(s) holding, individually or collectively, Controlling Ownership Interest; or</div>
            <div>(ii)	with respect to a limited liability partnership or partnership firm, partner(s) collectively holding Controlling Ownership Interest; or</div>
            <div>(iii)	with respect to any other legal entity recognized under law, the constituent Person(s) collectively holding Controlling Ownership Interest;</div></div>
            `
        }
    };

    useEffect(() => {
        if (props?.isDisabled) {
            props.applicantDetails1[0].formData.forEach((obj: any) => {
                obj.isDisabled = props?.isDisabled ? true : false;
            });
            // props.promoterDetails[0].formData.forEach((obj: any) => {
            //     obj.isDisabled = props?.isDisabled ? true : false;
            // });
            props.applicantDetails2.forEach((form: any, i: any) => {
                form.formData.forEach((obj: any) => {
                    obj.isDisabled = props?.isDisabled ? true : false;
                });
                if (i === 2) {
                    form.isSameAsAbove = props?.isDisabled ? false : true;
                }
            });
        }
    }, [props?.isDisabled]);

    const onOwnershipDetailsChange = (key: string, value: any) => {
        setFinalOwnerShipDetails({ ...finalOwnerShipDetails, [key]: value });
    };

    const handleSearchIFSC = (e: any) => {
        if (e.length === 11) {
            if (time) clearTimeout(time);
            setTime(
                setTimeout(async () => {
                    try {
                        const { data } = await POST(`${endpoints.getBankDetails}?ifsc=${e}`, { RequestInfo: requestInfo() });
                        setIfscData(data);
                        setValues({
                            ...values,
                            bankName: data?.bank || '',
                            branchAddress: data?.address || '',
                            ifscCode: e
                        });
                    } catch (error) {
                        let errMsg = 'Failed to retrieve bank details, Please check IFSC code';
                        setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                        setValues({
                            ...values,
                            bankName: '',
                            branchAddress: '',
                            ifscCode: e
                        });
                    }
                }, 800)
            );
        } else {
            setValues({
                ...values,
                bankName: '',
                branchAddress: '',
                ifscCode: e
            });
        }
    };

    const NEW_BANK_DETAILS =
            ifscData !== null && ifscData?.address === '' && ifscData?.bank === ''
                ? BANK_DETAILS[0]?.formData.map((val: any, index: number) => {
                      if (index === 3 || index === 4) {
                          return { ...val, isDisabled: false };
                      }
                      return val;
                  })
                : BANK_DETAILS[0]?.formData;
    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <Card.Body>
                {props.applicantDetails1.map((val: any) => (
                    <GenericFormCard {...val} dropdownOptions={props?.dropdownOptions} />
                ))}
                {props.applicantDetails2.map((val: any) => (
                    <GenericFormCard {...val} dropdownOptions={props?.dropdownOptions} onChangeAction={props.onChangeAction} module='usermanagement' />
                ))}
            </Card.Body>

            <GenericOwnerShipDetails
                showOwnershipModal={props.showOwnershipModal}
                setShowOwnershipModal={props.setShowOwnershipModal}
                changeOwnershipDetails={(values: any) => onOwnershipDetailsChange('applicantDetails', values)}
                values={values}
                otpReferenceId={otpReferenceId}
                dropdownData={props?.dropdownOptions}
                setControllingOwnershipResponse={props?.setControllingOwnershipResponse}
                controllingOwnershipResponse={props?.controllingOwnershipResponse}
                allOwnerShipDetails={finalOwnerShipDetails}
                setOwnershipDetails={props?.setOwnershipDetails}
                setCalculated={props?.setCalculated}
                ownershipDetails={props?.ownershipDetails}
                toolTipDetails={tooltipDetails}
                isDisabled={props?.isDisabled || props.ownershipDisable}
                hideCard={true}
                scrollNotRequired={props.scrollNotRequired}
                skipFullOwnershipPercent={props?.skipFullOwnershipPercent}
                isWorkflow={props.isWorkflow}
            />

            {!props?.hideBankDetails ? (
                <Card.Body>
                    <div className='ps-2 py-2 d-flex justify-content-between'>
                        <div className='formTitle m-0'>{'Bank Account Details'}</div>
                    </div>
                    <div className='row d-flex m-0'>
                        {NEW_BANK_DETAILS?.map((formField: any, index: number) => {
                            return (
                                <div className={formField.class ? `py-3 ${formField.class}` : 'py-3 col-sm-6'} key={index}>
                                    <label htmlFor={formField.field} className='pb-2 file-form-label'>
                                        {formField.title}
                                        {formField.isRequired && <span className='mandatory-indicator'> *</span>}
                                    </label>
                                    <Field
                                        name={formField.field}
                                        type={'text'}
                                        id={formField.field}
                                        className={formField.class ? `form-control was-validated ${formField.class}` : 'form-control was-validated'}
                                        onChange={(e: any) => {
                                            const newValue =
                                                formField.type === 'number' ? e.target.value.replace(/[^0-9.-]/g, '') : formField.field === 'ifscCode' ? e.target.value.toUpperCase() : e.target.value;
                                            setFieldValue(formField.field, newValue);
                                            formField.field === 'ifscCode' && handleSearchIFSC(newValue);
                                        }}
                                        value={_.get(values, formField.field)}
                                        disabled={formField.isDisabled || !values.isUpdatable || props.isDisabled || props.ownershipDisable}
                                    />
                                    <ErrorMessage name={formField.field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                                </div>
                            );
                        })}
                    </div>
                </Card.Body>
            ) : (
                <></>
            )}

            <Card.Body>
                {props.promoterDetails.map((val: any) => (
                    <GenericFormCard {...val} dropdownOptions={props?.dropdownOptions} />
                ))}
            </Card.Body>
        </>
    );
}
