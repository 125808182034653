import { useState, useEffect } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import { FormDataDTO } from '../../../../utils/constants';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import download from '../../../../assets/logos/download_green.svg';
import { devUrl } from '../../../../services/modulesServices/userManagement.api';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import noteIcon from '../../../../assets/logos/red Note.svg';

export default function Declaration(props: any) {
    const [disable, setDisable] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const allTrue = props.isChecked.every((value: boolean) => value);

        if (!props?.eSignedDocumentData?.isEsignRequired && allTrue) {
            setDisable(true);
        } else setDisable(false);
    }, [props?.eSignedDocumentData?.isEsignRequired]);

    const SELF_DECLARATION_DETAILS: FormDataDTO[] = [
        {
            formData: []
        }
    ];

    const { values } = useFormikContext<any>();

    const downloadApplicationDocument = () => {
        const fileStoreId = props.fileStoreId ? props.fileStoreId : props?.eSignedDocumentData?.filestoreId;
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${fileStoreId}`;
        window.open(downloadFileUrl);
    };

    const renderDeclarations = () => {
        return (
            <>
                <div className='px-3'>
                    <div className='ps-2 d-flex flex-column fw-medium'>
                        <p className='mt-3'>The {props.applicantName || 'applicant'} does hereby affirm and declare that : </p>
                        {props?.declarationList.map((val: any, i: any) => (
                            <>
                                <label className='mb-3 d-flex gap-1'>
                                    <input
                                        type='checkbox'
                                        checked={props.isChecked[i]}
                                        onChange={() => props.handleCheckboxChange(i)}
                                        disabled={props.isDisabled || disable}
                                        className={props.isDisabled || disable ? 'default-checked' : ''}
                                    />
                                    <div className='flex-grow-0'>{val}</div>
                                </label>
                            </>
                        ))}
                    </div>
                    {props?.eSignedDocumentData?.isEsignRequired ? (
                        <GenericButtons text={t('SIGN_DOCUMENT', 'Sign the document')} ClassName='px-3' handleClick={props.signDocument} disabled={!values.isUpdatable} />
                    ) : (
                        <>
                            <div className='ps-2 pt-2 font-14 font-inter fw-bold m-0'>{t('DIGITALLY_SIGNED', 'Digitally Signed')}</div>
                            <div className='ps-2 pb-2 font-14 font-inter'>{props?.eSignedDocumentData?.esignedDate}</div>
                            <div onClick={() => downloadApplicationDocument()} className='ps-2 d-flex align-items-center'>
                                <Image src={download} height={14} />
                                <div className='ps-2 font-14' style={{ cursor: 'pointer', color: '#619050', textDecoration: 'underline' }}>
                                    {t('DOWNLOAD_APPLICATION', 'Download Application')}
                                </div>
                            </div>
                        </>
                    )}
                    {/* <div className='declaration-note rounded-2 p-3 w-50 mt-4 d-flex '>
                        <img src={noteIcon} alt="" className='me-1'/><span className='font-14 lh-22 fw-bold me-2'>{t('NOTE', 'Note')}</span><span className='font-14 lh-22 fw-bold'>{t('DIGITAL_SIGNATURE_AUTHORIZED_PERSON', 'The digital signature should be done by Authorized Person')}</span>
                    </div> */}
                </div>
            </>
        );
    };

    return (
        <>
            {!props.hideCard ? (
                <Card className='card_border font-inter' style={{ fontSize: '12px' }}>
                    <Card.Body>
                        <div className='ps-2 py-2 d-flex justify-content-between'>
                            <div className='formTitle m-0'>
                                {`${props.declarationTitle ? props.declarationTitle + ' ' : ''}Self Declaration`} <span className='text-danger'>*</span>
                            </div>
                        </div>
                        {renderDeclarations()}
                    </Card.Body>
                </Card>
            ) : (
                <div className=''> {renderDeclarations()}</div>
            )}
        </>
    );
}
