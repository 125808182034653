import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { git_logo, right_arrow_green2, gitCommitBlack } from '../../../../utils/Logo';
import './Dashboard.scss';
import GenericCard from '../../../../components/moduleCommonFormik/GenericCard';
import { HierarchyNode, HierarchyNodeCard, IWorklistItems } from '../types';
import { convertToHierarchyCard } from '../../../../utils/utils';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../../assets/logos/search vec.svg';

interface IDashboardSectionProps {
    dashboardDetails: { worklists: any; cards: any };
    setTableData: (data: any) => void;
    tableData: any;
}

const GeneralManagDashboard = ({ dashboardDetails, setTableData, tableData }: IDashboardSectionProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const cardHierarchicalArray: HierarchyNode[] = dashboardDetails?.cards && convertToHierarchyCard(dashboardDetails?.cards);
    const moduleName = cardHierarchicalArray[0]?.moduleName;

    useEffect(() => {
        if (dashboardDetails) {
            const data = dashboardDetails.cards[0].inboxs;
            setTableData(data);
        }
    }, [dashboardDetails]);

    const handleOnCardClick = (card: HierarchyNodeCard) => {
        setTableData(card.inboxs);
    };

    const handleOnOptionClick = (item: IWorklistItems) => {
        navigate('/goa-idc/usermanagement/searchapplication', { state: { module: item.moduleName } });
    };

    console.log('cardHierarchicalArray', cardHierarchicalArray);
    return (
        <>
            <div className='table-container General_manag_parents pt-4'>
                <h2 className='General_manag_dashboard_heading mb-4 py-3 fw-bold'>{t('MY_DESK', 'My Desk')}</h2>
                <div className='row mb-4  '>
                    {cardHierarchicalArray?.map((data: any, index: number) => (
                        <>
                            <div className=' col-12 col-lg-6 col-xl-5 col-md-8 rounded-4 bg-white border border-1 py-3 px-3'>
                                <div className='Gm-module-name fw-bold font-28 lh-30 mb-2 pb-1'>{moduleName}</div>
                                <div className='row gx-2'>
                                    {data.children.map((value: any, index: number) => (
                                        <GenericCard
                                            handleOnCardClick={() => handleOnCardClick(value)}
                                            heading={`${data.displayName}`}
                                            textColor={value.iconColor}
                                            CardStyle={value.backgroundColor}
                                            count={value.count}
                                            subheading={value.displayName}
                                            Cardlogo={gitCommitBlack}
                                            CardLogoStyle={value.iconColor}
                                            index={index}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className='col-5 col-lg-6 col-xl-7 col-md-4 position-relative d-flex align-items-end  py-3 '>
                                <div className={` px-3 py-4 d-flex flex-column justify-content-between pb-0 gm_search_application  ms-md-auto`} onClick={() => handleOnOptionClick(data)}>
                                    <p className={``}>
                                        <img src={searchIcon} alt='' />
                                    </p>
                                    <h1 className={`text-start gm_title`}>{t('SEARCH_APPLICATION', 'Search Application')}</h1>
                                    <p className={`text-start gm_logo px-3 py-2  d-flex justify-content-center align-items-center`}>
                                        <img src={right_arrow_green2} width={'20px'} alt='' />
                                    </p>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </>
    );
};

export default GeneralManagDashboard;
