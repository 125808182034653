import * as Yup from "yup";
import { CONSTANT_REGEX_SAMPLE } from "../../utils/constants";

export const Clearance_Form_Initial_Data = {
  id: "",
  appId: "",
  name: "",
  gender: "",
  phone: "",
  panNo: "",
  pincode: "",
  district: "",
  state: "",
  country: "",
  addressLine1: "",
  addressLine2: "",
  arcName: "",
  serviceType: "",
  licenseeType: "",
  licenseeSubType: "",
  CouncilArcNo: "",
  token: "",
  cert: "",
  pin: "",
  documents: [],
  forwardTo: "",
  comments: "",
  buildingPlan: [],
  CERTIFICATE_ARCHITECTURE: [],
  CERTIFICATE_LIABILITY: [],
  SITEPLAN: [],
  DOCUMENT_QUESTIONNAIRE: [],
  DOCUMENT_AFFIDAVIT: [],
  DOCUMENT_POWER_ATTORNEY: []
}

export const VALIDATION_CLEARANCE_FORM: { [index: string]: any } = {
  step_1: Yup.object({
    name: Yup.string().required().label("Name"),
    phone: Yup.string()
      .min(10, "Phone number should be at least 10 digit")
      .max(10, "Phone number should not be more than 10 digit")
      .required()
      .label("Phone number")
      .matches(CONSTANT_REGEX_SAMPLE.NUMERIC, "Enter valid Number "),
    gender: Yup.string().required().label("Gender"),
    panNo: Yup.string()
      .min(10, "PAN Number should be at least 10 digit")
      .max(10, "PAN Number should not be more than 10 digit")
      .required()
      .label("PAN Number")
      .matches(CONSTANT_REGEX_SAMPLE.ALPHANUMERIC, "Only alphanumeric characters are allowed"),

    addressLine1: Yup.string().required().label("Address Line 1"),
    addressLine2: Yup.string().required().label("Address Line 2"),
    district: Yup.string().required().label("District"),
    state: Yup.string().required().label("State"),
    country: Yup.string().required().label("Country"),
    pincode: Yup.string().min(6, "Pincode should be at least 6 digit").max(6, "Pincode should not be more than 6 digit").required().label("Valid Pincode"),
  }),

  step_2: Yup.object({
    arcName: Yup.string().required().label("Name"),
    certificate1: Yup.string().required().label("Certificate1"),
    certificate2: Yup.string().required().label("Certificate2"),
  }),

  step_3: Yup.object({
    serviceType: Yup.string().required().label("Service Type"),
    buildingPlan: Yup.string().required().label("Bulding Plan"),
  }),

  step_5: Yup.object({
    adhar: Yup.string().required().label("Adhar Card"),
    passport: Yup.string().required(),
    license: Yup.string().required(),
    document1: Yup.string().required(),
    document2: Yup.string().required(),
  }),
};

export const VALIDATION_SCRUTINY_FORM: { [index: string]: any } = {
  step_1: Yup.object({
    serviceType: Yup.string().required().label("Service Type"),
    buildingPlan: Yup.array().min(1).required().label("Bulding Plan"),
  }),
  step_3: Yup.object({
    CERTIFICATE_ARCHITECTURE: Yup.array().min(1).required(),
    CERTIFICATE_LIABILITY: Yup.array().min(1).required(),
  }),

};

