import React, { useEffect, useState } from "react";
import { IdocumentTemplate } from "../features/modules/UserManagement/types";

interface IDownloadTemplateProps {
    doc: any;
    getSelectedDocuments: (data: IdocumentTemplate[]) => void;
}

const DownloadTemplate = (props: IDownloadTemplateProps) => {
    const [documents, setDocuments] = useState<IdocumentTemplate[]>([]);

    const { doc, getSelectedDocuments } = props;
    useEffect(() => {
        setDocuments(doc);
    }, []);

    const handlechange = (e: any) => {
        let currDocument;
        const { name, checked } = e.target;
        if (name === "selectAll") {
            currDocument = documents.map((docs: any) => {
                return { ...docs, isSelected: checked };
            });
        } else {
            currDocument = documents.map((docs: any) => (docs.name === name ? { ...docs, isSelected: checked } : docs));
        }
        setDocuments(currDocument);
        getSelectedDocuments(currDocument);
    };

    return (
        <>
            <div className="container download_template_parents">
                <div className="row p-3">
                    <div className="col-sm-12 mt-3 mb-2 d-flex  justify-content-between  ">
                        <span className="download_template_heading">
                            List of documents
                            <span className=" fst-italic" style={{ fontSize: "14px", fontWeight: 400 }}>
                                (select that apply)
                            </span>
                        </span>
                        <span>
                            <input type="checkbox" name="selectAll" id="selectAll" onChange={handlechange} checked={documents.filter((docs: any) => docs?.isSelected !== true).length < 1} />
                            <label htmlFor="selectAll" className="select_all">
                                Select All
                            </label>
                        </span>
                    </div>
                    {documents.map((docname: any) => (
                        <div className="col-sm-12 my-2  ">
                            <input type="checkbox" checked={docname?.isSelected || false} onChange={handlechange} name={docname.name} id={docname.name} />
                            <label htmlFor={docname.name} className="common_label">
                                {docname.title}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default DownloadTemplate;
