import React, { useEffect, useState } from 'react';
import activeArrow from '../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../assets/logos/page left arrow.svg';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import QueryEntryCorrespondence from './QueryEntryCorrespondence';
import { QUERY_RESPONSE_VALIDATION } from '../../../utils/formikValidation/FormikValidation';
import { PlotDropdownData } from '../../modules/UserManagement/types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { devUrl, endpoints, estateEndpoints, POST, requestInfo } from '../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../utils/Logo';
import { getHistory, getNextActions, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument } from '../../modules/EstateManagement/EstateManagement.controller';
import { dropdownDataOptions } from '../../modules/Migration/Migration.controller';
import { DocumentInfo } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import { TENANT_ID } from '../../../utils/constants';
import { getFirstErrorKey } from '../../../utils/utils';
import GenericHeader from '../../../components/moduleCommonFormik/GenericHeader';
import HeaderComponent from '../../../components/header/Header.component';
import Loading from '../../../components/portalComponents/Loading.component';
import { CorrespondenceWrapper, NotingWrapper } from '../../../components/Workflow/NotingWrapper';
import { Card, Form } from 'react-bootstrap';
import GenericActionTable from '../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../modules/EstateManagement/Allotment/Allotment.const';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../modules/EstateManagement/LeaseDeed/LeaseDeed.const';
import ScrollToTop from '../../../components/ScrollToTop';
import GenericPopupModal from '../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../components/fileviewer/GenericImageViewer';
import PopUps from '../../../components/PopUps';
import GenericFooterComponent from '../../../components/moduleCommonFormik/GenericFooter.component';
import { getDropdownSelectedObject } from '../../modules/UserManagement/UserMangement.controller';
import { plotInitialData, setQueryInitValue } from '../Query.const';
import { entityDocumentData } from '../../modules/EstateManagement/LeaseDeed/LeaseDeed.controller';

const QueryEntryWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const totalPages = 2;
    const [modalData, setModalData] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [isPDF, setIsPDF] = useState(true);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [queryData, setQueryData] = useState<any>(null);
    const [dropdownDataPlot, setDropdownDataPlot] = useState<PlotDropdownData>({
        plotNo: [],
        phase: [],
        plotType: [],
        estateName: []
    });
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [checklistTableData, setChecklistTableData] = useState();
    const [tabelData, setTabelData] = useState<any>([]);
    const [tabelDocData, setTabelDocData] = useState<any>([]);
    const [applicationStatus, setApplicationStatus] = useState<any>('');

    let navigate = useNavigate();
    const { t } = useTranslation();
    const location: any = useLocation();
    let user = localStorage.getItem('currentUserAssignment');
    const [searchParams] = useSearchParams();
    const applicationNumber: any = decodeURIComponent(searchParams.get('applicationNumber') || '');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const approvedEsign: any = searchParams.get('approvedEsign');
    const action: any = searchParams.get('action');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: ''
        },
        onSubmit: () => {},
        validationSchema: null
    });
    const { values, setValues, errors, setFieldTouched } = formik;

    // for details
    useEffect(() => {
        if (note && action && queryData && nextValidAction) {
            onHandlePrimaryAction({ action: action }, note);
        }
    }, [queryData, nextValidAction]);

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.searchQueryEntry + `?queryNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                    if (data) {
                        console.log(data);
                        setQueryData(data?.queryDetails[0]);
                        setPlotDetails(data?.queryDetails[0]?.plotId);
                        setTableDocData(data?.queryDetails[0]?.queryDocDetails);
                        setApplicationStatus(data?.queryDetails[0]?.status);

                        // setFileStoreId(data.MortgageDetails[0]?.estateApplications?.consolidatedDocRefId);
                    }
                    setIsLoading(false);
                } catch (e: any) {
                    console.log(e);
                    setIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || 'Something Went Wrong';
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [location.search]);

    const setPlotDetails = async (plotId: any) => {
        const { data: plotData } = await POST(estateEndpoints.plotSearch + `?plotUuids=${plotId}`, { RequestInfo: requestInfo() });
        const setPlotInitValue = await plotInitialData(plotData?.plots[0]);
        setValues((prev: any) => ({ ...prev, ...setPlotInitValue }));
    };

    const setTableDocData = async (docData: any) => {
        const tableRowData = await Promise.all(
            docData.map(async (val: any) => {
                const docDetails = val?.docRefId && (await entityDocumentData(val?.docRefId));
                return {
                    documentName: docDetails[0]?.documentName,
                    dateAndTime: docDetails[0]?.uploadedDate,
                    rowId: docDetails[0]?.docReferenceId
                };
            })
        );
        setTabelDocData(tableRowData);
    };
    console.log('querydataaaaaaa', queryData);

    useEffect(() => {
        const reverseDocumentDetails = documentDetails?.reverse();
        const tableData = async () => {
            const responseTableData = reverseDocumentDetails.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${ind + 1}` };
            });
            const reverseData = responseTableData.reverse();
            setTabelData(reverseData);
        };
        if (activeIndex === 2) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                setIsLoading(true);
                const docs = await getWorkflowDocumentSearch(applicationNumber, nextValidAction.businessService);
                setDocumentDetails(docs);
                setIsLoading(false);
            } catch (e: any) {
                console.log(e);
                setIsLoading(false);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (queryData && nextValidAction) {
            setApplicationResponseData(queryData);
            getCorrespondenceDocument();
        }
    }, [nextValidAction, queryData]);

    const setApplicationResponseData = async (queryResponse: any) => {
        try {
            if (queryResponse) {
                const queryInitValue = await setQueryInitValue(queryResponse, dropdownData);
                setValues((prev: any) => ({
                    ...prev,
                    ...queryInitValue
                }));
            }
        } catch (e) {}
    };
    console.log(values);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const dropdownOption = await dropdownDataOptions();
                setDropdownData(dropdownOption);

                const nextActions = await getNextActions(applicationNumber);
                setNextValidAction(nextActions);

                const historyData = await getHistory(applicationNumber);
                setHistoryDetails(historyData);
                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        })();
    }, []);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any = file.referenceId && (await onAttachDocument(applicationNumber, file?.referenceId, designationName, nextValidAction.businessService, historyDetails[0].id));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {
            console.log(e);
        }
    };
    const onHandlePrimaryAction = async (status: any, comment: string) => {
        const responseDoc: any = {
            docRefId: values?.responseAttachment && values?.responseAttachment?.referenceId,
            docType: 'RESPONSE'
        };
        const payload = {
            RequestInfo: requestInfo(),
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    comment: comment,
                    notes: values.notes || note
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean),
            queryDetailsRequest: {
                ...queryData,
                queryDocDetails: values.responseAttachment ? [...queryData.queryDocDetails, responseDoc] : [...queryData.queryDocDetails],
                queryResponse: values.responseDescription ? values.responseDescription : null
            }
        };

        if (!values.notes && !note) {
            if (!comment) {
                setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
                return;
            } else {
            }
        }

        try {
            if (applicationStatus === 'APPROVED') {
                await QUERY_RESPONSE_VALIDATION.validate(values, { abortEarly: false, context: values });
            }
            try {
                await POST(endpoints.initiateQueryEntry, payload);

                const successMessage = getSuccessMessage(status?.action, applicationNumber);
                setModalDetails({
                    show: true,
                    title: 'Success',
                    para: applicationStatus === 'APPROVED' ? t('QUERY_CLOSED', 'Query Closed Successfully') : successMessage,
                    img: check,
                    type: 'success',
                    reUpload: 'OK'
                });
            } catch (e: any) {
                let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            console.log(validationError);
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const updateQuery = async () => {
        const responseDoc: any = {
            docRefId: values?.responseAttachment?.referenceId,
            docType: 'RESPONSE'
        };
        const payload = {
            RequestInfo: requestInfo(),
            ...queryData,
            queryDocDetails: values.responseAttachment ? [...queryData.queryDocDetails, responseDoc] : [...queryData.queryDocDetails],
            queryResponse: values.responseDescription
        };

        try {
            if (applicationStatus === 'APPROVED') {
                await QUERY_RESPONSE_VALIDATION.validate(values, { abortEarly: false, context: values });
            }
            try {
                const { data } = await POST(endpoints.updateQueryEntry, payload);

                setModalDetails({ show: true, title: 'Success!', para: 'Data saved successfully', img: check, type: 'update success', reUpload: 'OK', isSaveOnly: true });
            } catch (err: any) {
                let errMsg = err.response?.data?.errorMessage || err.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const onDocnameClick = (row: any) => {
        console.log(row);
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const handleNextPage = () => {
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader text={t('QUERY_ENTRY', 'Query Entry')} additionalClass='sub-text-style' bottomSubText={`Type : Query, No : ${applicationNumber} `} />

                        <FormikProvider value={formik}>
                            <div className='col-sm-3 mt-3 stickyCard-section '>
                                <div className='stickyCard' style={{ top: '15%' }}>
                                    <GenericHeader
                                        text={t('QUERY_ENTRY', 'Query Entry')}
                                        sideHeader={true}
                                        additionalClass='sub-text-style'
                                        bottomSubText={`Type : Query, No : ${applicationNumber} `}
                                    />

                                    <NotingWrapper
                                        designation={designation}
                                        applicationNumber={applicationNumber}
                                        history={historyDetails}
                                        disable={!nextValidAction?.nextValidAction.length}
                                        handleOnFileUpload={handleOnFileUpload}
                                        nextValidAction={nextValidAction}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-9 mt-3 form-section '>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <QueryEntryCorrespondence
                                                dropdownData={dropdownData}
                                                dropdownDataPlot={dropdownDataPlot}
                                                setDropdownDataPlot={setDropdownDataPlot}
                                                tabelDocData={tabelDocData}
                                                applicationNumber={applicationNumber}
                                                fileStoreId={fileStoreId}
                                                checkListRowData={checklistTableData}
                                                readOnly={!nextValidAction?.isUpdatable}
                                                status={queryData?.estateApplications?.status}
                                                disabled={disabled}
                                                showDocument={onDocnameClick}
                                                applicationStatus={applicationStatus}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                    <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                </span>
                                Page {activeIndex} of {totalPages}
                                <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                    <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                </span>
                            </div>
                        </FormikProvider>
                    </div>
                    <ScrollToTop />
                    <GenericFooterComponent
                        activeIndex={activeIndex}
                        handleOnPrimaryAction={onHandlePrimaryAction}
                        onSubmit={() => {}}
                        applicationNo={applicationNumber || null}
                        nextValidAction={nextValidAction}
                        stepperLength={0}
                        extraButton={nextValidAction?.isUpdatable ? 'Save' : null}
                        extraButtonAction={updateQuery}
                    />

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId || modalData.rowId} documentName={modalData.docFileName || modalData.documentName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                </>
            )}
        </>
    );
};

export default QueryEntryWorkflow;
