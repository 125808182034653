import _ from 'lodash';
import { Assignments, DocumentDetails, dropdownOptionsInterface, FileEnum, formOptionsDTO, Qualifications } from '../../../components/moduleCommonFormik/GenericFormCard.constants';
import { formatedDocumentDetails } from '../../../utils/utils';
import { getAddressDetails } from '../EstateManagement/Allotment/Allotment.controller';
import { entityDocumentData } from '../EstateManagement/EstateManagement.controller';
import { DOCUMENT_TYPE } from '../Migration/Migration.const';

export const empDocumentData = async (docs: any) => {
    const refIDs = docs?.map((docItem: DocumentDetails) => docItem.documentId);
    const data = await formatedDocumentDetails(refIDs?.join(), true);

    const documentData: any = [];
    data?.map((item: DocumentDetails) => {
        docs.map((subItem: DocumentDetails) => {
            if (item.referenceId === subItem.documentId) {
                documentData.push({ ...subItem, ...item });
            }
        });
    });

    return documentData;
};
export const getProfileInitData = async (res: any, dropdownOptions: dropdownOptionsInterface, primaryIndex: number, otherAssignment?: any, otherQualificationDetails?: any) => {
    const documentData = await empDocumentData(res.documents);

    console.log(documentData);
    const initialData: any = {};
    initialData['name'] = res.user.firstName + ' ' + res.user.lastName;
    initialData['firstnamesalutation'] = res.user.salutation;
    initialData['firstname'] = res.user.firstName;
    initialData['lastname'] = res.user.lastName;
    initialData['dob'] = new Date(res.user.dob).toISOString().split('T')[0];
    initialData['profilePhoneNo'] = +res.user.mobileNumber;
    initialData['profileEmailId'] = res.user.emailId;
    initialData['gender'] = getDropdownSelectedObject(res.user.gender, dropdownOptions['gender'] || []);
    initialData['address1'] = res.user.correspondenceAddress1;
    initialData['address2'] = res.user.correspondenceAddress2;
    initialData['pincode'] = res.user.correspondencePinCode;
    initialData['district'] = res.user.correspondenceDistrict;
    initialData['state'] = res.user.correspondenceState;
    initialData['country'] = res.user.correspondenceCountry;
    initialData['peraddress1'] = res.user.permanentAddress1;
    initialData['peraddress2'] = res.user.permanentAddress2;
    initialData['perpincode'] = res.user.permanentPinCode;
    initialData['perdistrict'] = res.user.permanentDistrict;
    initialData['perstate'] = res.user.permanentState;
    initialData['percountry'] = res.user.permanentCountry;

    initialData['qualification'] = res.education.length ? getDropdownSelectedObject(res.education?.[0].qualification, dropdownOptions['qualification'] || []) : '';
    initialData['stream'] = res.education.length ? getDropdownSelectedObject(res.education?.[0].stream, dropdownOptions['stream'] || []) : '';
    initialData['yearOfPassing'] = res.education.length ? res.education?.[0].yearOfPassing : '';
    initialData['university'] = res.education.length ? res.education?.[0].university : '';
    initialData['remarks'] = res.education.length ? res.education?.[0].remarks : '';

    initialData['employeeID'] = res.code;
    initialData['appointmentDate'] = new Date(res.dateOfAppointment).toISOString().split('T')[0];
    initialData['empType'] = getDropdownSelectedObject(res.employeeType, dropdownOptions['empType'] || []) || '';
    initialData['status'] = getDropdownSelectedObject(res.employeeStatus, dropdownOptions['status'] || []) || '';

    initialData['identityProof'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'IDENTITY_PROOF', 'EMP'));
    initialData['addressProof'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'ADDRESS_PROOF', 'EMP'));
    initialData['joiningLetter'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'JOINING_APPOINTMENT_LETTER', 'EMP'));
    initialData['profilePic'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'PROFILE_PICTURE', 'EMP'));

    initialData['fromDate'] = new Date(res.assignments[primaryIndex].fromDate).toISOString().split('T')[0];
    initialData['toDate'] = res.assignments[primaryIndex].toDate && new Date(res.assignments[primaryIndex].toDate).toISOString().split('T')[0];
    initialData['isCurrentAssignment'] = res.assignments[primaryIndex].isCurrentAssignment ? { code: 'Yes', name: 'Yes' } : { code: 'No', name: 'No' };
    initialData['department'] = getDropdownSelectedObject(res.assignments[primaryIndex].department, dropdownOptions['department'] || []);
    initialData['designation'] = getDropdownSelectedObject(res.assignments[primaryIndex].designation, dropdownOptions['designation'] || []);
    initialData['roles'] = res.assignments[primaryIndex].roles;

    initialData.assignments = (otherAssignment.length && getAssignmentsInitData(otherAssignment, dropdownOptions)) || [
        {
            tenantid: null,
            fromDate: null,
            isCurrentAssignment: null,
            department: null,
            designation: null,
            isPrimary: false,
            roles: null
        }
    ];

    initialData.qualificationDetails = (otherQualificationDetails && getQualificationInitData(otherQualificationDetails, dropdownOptions)) || [
        {
            qualification: null,
            stream: null,
            yearOfPassing: null,
            university: null,
            remarks: null
        }
    ];

    return initialData;
};

export const getInitialData = async (res: any, dropdownOptions: dropdownOptionsInterface) => {
    const documentData = await empDocumentData(res.documents);

    console.log(documentData);
    const initialData: any = {};
    initialData['firstnamesalutation'] = res.user.salutation;
    initialData['firstname'] = res.user.firstName;
    initialData['lastname'] = res.user.lastName;
    initialData['phoneNo'] = +res.user.mobileNumber;
    initialData['emailId'] = res.user.emailId;
    initialData['gender'] = getDropdownSelectedObject(res.user.gender, dropdownOptions['gender'] || []);
    initialData['dob'] = new Date(res.user.dob).toISOString().split('T')[0];
    initialData['address1'] = res.user.correspondenceAddress1;
    initialData['address2'] = res.user.correspondenceAddress2;
    initialData['pincode'] = res.user.correspondencePinCode;
    initialData['district'] = res.user.correspondenceDistrict;
    initialData['state'] = res.user.correspondenceState;
    initialData['country'] = res.user.correspondenceCountry;
    initialData['peraddress1'] = res.user.permanentAddress1;
    initialData['peraddress2'] = res.user.permanentAddress2;
    initialData['perpincode'] = res.user.permanentPinCode;
    initialData['perdistrict'] = res.user.permanentDistrict;
    initialData['perstate'] = res.user.permanentState;
    initialData['percountry'] = res.user.permanentCountry;

    initialData['empId'] = res.code || '';
    initialData['appointmentDate'] = new Date(res.dateOfAppointment).toISOString().split('T')[0];
    initialData['empType'] = getDropdownSelectedObject(res.employeeType, dropdownOptions['empType'] || []);
    initialData['status'] = getDropdownSelectedObject(res.employeeStatus, dropdownOptions['status'] || []);

    initialData['identityDetailsDropdown'] = getDropdownSelectedObject(getDropdownName(res.documents, 'IDENTITY_PROOF'), dropdownOptions['identityDetailsDropdown'] || []);
    initialData['addressDetailsDropdown'] = getDropdownSelectedObject(getDropdownName(res.documents, 'ADDRESS_PROOF'), dropdownOptions['addressDetailsDropdown'] || []);
    initialData['letterDetailsDropdown'] = getDropdownSelectedObject(getDropdownName(res.documents, 'JOINING_APPOINTMENT_LETTER'), dropdownOptions['letterDetailsDropdown'] || []);
    initialData['identityProofname'] = getDocumentFileName(res.documents, 'Identity Proof');
    initialData['addressProofname'] = getDocumentFileName(res.documents, 'Address Proof');
    initialData['joiningLettername'] = getDocumentFileName(res.documents, 'Joining/Appointment Letter');
    initialData['documentNo'] = res?.user?.pan || res?.user?.passport;

    initialData['identityProof'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'IDENTITY_PROOF', 'EMP'));
    initialData['addressProof'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'ADDRESS_PROOF', 'EMP'));
    initialData['joiningLetter'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'JOINING_APPOINTMENT_LETTER', 'EMP'));

    initialData.assignment = (res.assignments.length && getAssignmentsInitData(res.assignments, dropdownOptions)) || [
        {
            tenantid: null,
            fromDate: null,
            isCurrentAssignment: null,
            department: null,
            designation: null,
            isPrimary: false,
            roles: null
        }
    ];
    initialData.qualifications = (res.education.length && getQualificationInitData(res.education, dropdownOptions)) || [];

    return initialData;
};

export const getArcInitialData = async (res: any, dropdownOptions: dropdownOptionsInterface) => {
    const refIDs = res.documents.map((docItem: DocumentDetails) => docItem.referenceId);
    const documentData = await formatedDocumentDetails(refIDs.join(), true);

    const initialData: any = {};
    initialData['firstnamesalutation'] = res.user.salutation;
    initialData['firstname'] = res.user.firstName;
    initialData['lastname'] = res.user.lastName;
    initialData['phoneNo'] = +res.user.mobileNumber;
    initialData['emailId'] = res.user.emailId;
    initialData['gender'] = getDropdownSelectedObject(res.user.gender, dropdownOptions['gender'] || []);
    initialData['dob'] = new Date(res.user.dob).toISOString().split('T')[0];
    initialData['address1'] = res.user.correspondenceAddress1;
    initialData['address2'] = res.user.correspondenceAddress2;
    initialData['pincode'] = res.user.correspondencePinCode;
    initialData['district'] = res.user.correspondenceDistrict;
    initialData['state'] = res.user.correspondenceState;
    initialData['country'] = res.user.correspondenceCountry;
    initialData['peraddress1'] = res.user.permanentAddress1;
    initialData['peraddress2'] = res.user.permanentAddress2;
    initialData['perpincode'] = res.user.permanentPinCode;
    initialData['perdistrict'] = res.user.permanentDistrict;
    initialData['perstate'] = res.user.permanentState;
    initialData['percountry'] = res.user.permanentCountry;
    initialData['validFrom'] = new Date(res.licenseDetail.validFrom).toISOString().split('T')[0];
    initialData['validTo'] = new Date(res.licenseDetail.validTo).toISOString().split('T')[0];
    initialData['licenseeType'] = res.licenseDetail.technicalPersonCode === 'Architect' ? 'C.A' : '';
    initialData['CouncilArcNo'] = res.licenseDetail.councilOfArchitectNo;
    initialData['adharname'] = getDocumentFileName(res.documents, 'Identity Proof');
    initialData['licenseCopyname'] = getDocumentFileName(res.documents, 'License Copy');
    initialData['adhar'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'IDENTITY_PROOF', 'ARCH'));
    initialData['licenseCopy'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'LICENSE_COPY', 'ARCH'));

    return initialData;
};

export const getEnterpriseInitialData = async (res: any, dropdownOptions: dropdownOptionsInterface) => {
    // const refIDs = res.documents.map((docItem: DocumentDetails) => docItem.referenceId);
    // refIDs.push(res.user?.powerOfAttorneyReferenceId, res.user?.panAttachmentReferenceId);
    // const documentData = await formatedDocumentDetails(refIDs.join(), true);

    // console.log('refIDs', documentData);

    const permanentAddress = res?.addresses?.find((val: any) => val?.addressType === 'PERMANENT');
    const correspondenceAddress = res?.addresses?.find((val: any) => val?.addressType === 'CORRESPONDENCE');

    const documentData: any = await entityDocumentData(res?.documents);

    const initialData: any = {};
    initialData['entityname'] = res.entityName;
    initialData['entitycategory'] = getDropdownSelectedObject(res.category, dropdownOptions['entitycategory'] || []);
    initialData['entityemailId'] = res.email;
    initialData['entityPhoneNo'] = +res.phoneNumber;
    initialData['gender'] = getDropdownSelectedObject(res.user?.gender, dropdownOptions['gender'] || []);

    initialData['entitypanNo'] = res.panNumber;
    initialData['gstapplicable'] = res.gstApplicable.toString();
    initialData['entityGstNo'] = res.gstNumber || '';
    initialData['UdhyamRegisteredEnity'] = res.udhyamRegistered.toString();
    initialData['certificateForStartup'] = res.dpiitCertified.toString();

    initialData['entitypanCard'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.PAN && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entitygstDocument'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GST && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    // initialData['entitycertificateForStartup'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'DPIIT', 'ENTERPRISE'));
    // initialData['entityudyamCertificate'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'UDYAMREGISTRATION', 'ENTERPRISE'));

    initialData['entityProprietorshipFirm'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entityProprietorIdentity'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['trustDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['partnershipDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['certificateOfRegistrationFirms'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfIncorporation'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfRegistrationSocieties'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['copyRelevantNotification'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION && doc.referenceType === DOCUMENT_TYPE.ENTITY);

    initialData['address1'] = permanentAddress?.address1;
    initialData['address2'] = permanentAddress?.address2;
    initialData['pincode'] = permanentAddress?.pinCode;
    initialData['district'] = permanentAddress?.district;
    initialData['state'] = permanentAddress?.state;
    initialData['country'] = permanentAddress?.country;
    initialData['peraddress1'] = correspondenceAddress?.address1;
    initialData['peraddress2'] = correspondenceAddress?.address2;
    initialData['perpincode'] = correspondenceAddress?.pinCode;
    initialData['perdistrict'] = correspondenceAddress?.district;
    initialData['perstate'] = correspondenceAddress?.state;
    initialData['percountry'] = correspondenceAddress?.country;

    initialData['authorizedPersonFirstname'] = res.user?.firstName;
    initialData['authorizedPersonLastname'] = res.user?.lastName;
    initialData['AuthorizedPersonGender'] = res.user?.gender;
    initialData['authorizedPersonPrimaryMobileNumber'] = res.user?.mobileNumber;
    initialData['authorizedPersonAlternativeMobileNumber'] = res.user?.altContactNumber;
    initialData['authorizedPersonEmailId'] = res.user?.emailId;
    initialData['authorizedPersonpanNo'] = res.user?.pan;
    initialData['authorizedPersonFirstnamesalutation'] = res.user?.salutation;

    // initialData['authorizedPersonPowerOfAttorneyDocument'] = getDocumentDetails(documentData, res.user?.powerOfAttorneyReferenceId);
    // initialData['authorizedPersonPanCardDocument'] = getDocumentDetails(documentData, res.user?.panAttachmentReferenceId);

    // initialData['womenEntrepreneur'] = res.womenEntreprenueur;
    // initialData['localPerson'] = res.localPerson?.toString();
    // initialData['womenEntrepreneurDocuments'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'WOMENENTREPRENUEUR', 'ENTERPRISE'));
    // initialData['localPersonDocumnets'] = getDocumentDetails(documentData, getDocumentDetailsID(res.documents, 'LOCALPERSON', 'ENTERPRISE'));

    initialData['firstname'] = res.entityBankDetail?.firstName;
    initialData['lastname'] = res.entityBankDetail?.lastName;
    initialData['firstnamesalutation'] = res.entityBankDetail?.salutation;
    initialData['accountNo'] = res.entityBankDetail?.accountNumber;
    initialData['ifscCode'] = res.entityBankDetail?.ifscCode;
    initialData['bankName'] = res.entityBankDetail?.bankName;
    initialData['branchAddress'] = res.entityBankDetail?.branchAddress;

    initialData['otp'] = res;
    initialData['previousApplicationCheck'] = res;

    return initialData;
};

export const getDropdownSelectedObject = (seletedCode: string, fieldDropdownOptions: formOptionsDTO[]) => {
    return fieldDropdownOptions?.find(item => item.code === seletedCode || item.name === seletedCode) || {};
};

export const getAssignmentsInitData = (assignments: Assignments[], dropdownOptions: dropdownOptionsInterface) => {
    return assignments.map(item => ({
        designation: getDropdownSelectedObject(item.designation, dropdownOptions['designation'] || []),
        department: getDropdownSelectedObject(item.department, dropdownOptions['department'] || []),
        fromDate: new Date(item.fromDate).toISOString().split('T')[0],
        toDate: item.toDate && new Date(item.toDate).toISOString().split('T')[0],
        tenantid: item.tenantid,
        isCurrentAssignment: item.isCurrentAssignment ? { code: 'Yes', name: 'Yes' } : { code: 'No', name: 'No' },
        isPrimary: item.isPrimary,
        roles: item.roles
    }));
};

export const getQualificationInitData = (qualifications: Qualifications[], dropdownOptions: dropdownOptionsInterface) => {
    return qualifications.map(item => ({
        qualification: getDropdownSelectedObject(item?.qualification, dropdownOptions['qualification'] || []),
        stream: getDropdownSelectedObject(item?.stream, dropdownOptions['stream'] || []),
        yearOfPassing: item?.yearOfPassing || '',
        university: item?.university || '',
        remarks: item?.remarks || ''
    }));
};

export const getDropdownName = (documents: DocumentDetails[], type: string) => {
    return documents.find(item => item.referenceType === type)?.documentType || '';
};

export const getDocumentFileName = (documents: DocumentDetails[], type: string) => {
    return documents.find(item => item.referenceType === FileEnum[type])?.documentName || '';
};

export const getDocumentDetailsID = (documents: DocumentDetails[], type: string, userType: string) => {
    if (userType === 'ENTERPRISE') {
        return documents.find(item => item.documentType === type)?.referenceId || '';
    } else if (userType === 'EMP') {
        return documents.find(item => item.referenceType === type)?.documentId || '';
    } else return documents.find(item => item.referenceType === type)?.referenceId || '';
};

export const getDocumentDetails = (documents: DocumentDetails[], id: string) => {
    return documents.find(item => item.referenceId === id) || '';
};

export const getEnterpriseData = async (res: any, dropdownOptions: dropdownOptionsInterface) => {
    const documentData = await empDocumentData(res.documents);

    console.log(documentData);
    const initialData: any = {};
    initialData['enterpriseCategory'] = res.enterpriseDetails.enterpriseCategory;
    initialData['enterpriseSubcategory'] = res.enterpriseDetails.enterpriseSubcategory;
    initialData['nationalIndustrialCode'] = res.enterpriseDetails.nationalIndustrialCode;
    initialData['specialProject'] = getDropdownSelectedObject(res.enterpriseDetails.specialProject, dropdownOptions['specialProject'] || []);
    initialData['projectType'] = getDropdownSelectedObject(res.enterpriseDetails.projectType, dropdownOptions['projectType'] || []);
    initialData['expansion'] = getDropdownSelectedObject(res.enterpriseDetails.expansionExistingUnit, dropdownOptions['expansion'] || []);
    initialData['pollutionCategory'] = getDropdownSelectedObject(res.enterpriseDetails.pollutionCategory, dropdownOptions['pollutionCategory'] || []);
    initialData['waterRequirement'] = getDropdownSelectedObject(res.enterpriseDetails.estimatedWaterRequirement, dropdownOptions['waterRequirement'] || []);
    initialData['powerRequirement'] = getDropdownSelectedObject(res.enterpriseDetails.estimatedPowerRequirement, dropdownOptions['powerRequirement'] || []);
    initialData['employement'] = getDropdownSelectedObject(res.enterpriseDetails.estimatedNumberOfPersonsToBeEmployed, dropdownOptions['employement'] || []);
    initialData['meansOfFinance'] = getDropdownSelectedObject(res.enterpriseDetails.meansOfFinance, dropdownOptions['meansOfFinance'] || []);
    initialData['estimatedProjectCost'] = res.enterpriseDetails.enterpriseProjectCost;

    return initialData;
};

// export const getAdvertisementDetails = (data: any) => {
//     const initialData: any = {};
//     initialData['publicationDate'] = new Date(data?.publicationDate.split('/').reverse().join('-')).toISOString().split('T')[0];
//     return initialData;
// };

export const getEnterpriseInitData = async (resData: any, dropdownData: any) => {
    const documentData = await entityDocumentData(resData.enterpriseEntity[0].documents);

    console.log('Documentdata', documentData);

    const initialData: any = {};
    const address1 = getAddressDetails(resData?.enterpriseEntity[0].addresses[0]);
    const address2 = getAddressDetails(resData?.enterpriseEntity[0].addresses[1]);
    const sameAsAboveCheck = _.isEqual(address1, address2);
    if (sameAsAboveCheck) {
        initialData['copyOnCheck'] = ['on'];
    }
    initialData['entityname'] = resData.enterpriseEntity[0].entityName;
    initialData['entitycategory'] = getDropdownSelectedObject(resData.enterpriseEntity[0].category, dropdownData['entitycategory'] || []);
    initialData['entityProprietorshipFirm'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entityProprietorIdentity'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['trustDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['partnershipDeed'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['certificateOfRegistrationFirms'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfIncorporation'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['certificateOfRegistrationSocieties'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES && doc.referenceType === DOCUMENT_TYPE.ENTITY
    );
    initialData['copyRelevantNotification'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entityemailId'] = resData.enterpriseEntity[0].email;
    initialData['entityPhoneNo'] = resData.enterpriseEntity[0].phoneNumber;
    initialData['gstapplicable'] = resData.enterpriseEntity[0].gstApplicable;
    initialData['entityGstNo'] = resData.enterpriseEntity[0].gstNumber;
    initialData['entitypanNo'] = resData.enterpriseEntity[0].panNumber;
    initialData['entitygstDocument'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.GST && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entitygstUndertaking'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.ENTITY_CATEGORY.NON_GST && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['entitypanCard'] = documentData?.find((doc: any) => doc.documentType === DOCUMENT_TYPE.PAN && doc.referenceType === DOCUMENT_TYPE.ENTITY);
    initialData['address1'] = resData.enterpriseEntity[0].addresses[0].address1;
    initialData['address2'] = resData.enterpriseEntity[0].addresses[0].address2;
    initialData['pincode'] = resData.enterpriseEntity[0].addresses[0].pinCode;
    initialData['district'] = resData.enterpriseEntity[0].addresses[0].district;
    initialData['state'] = resData.enterpriseEntity[0].addresses[0].state;
    initialData['country'] = resData.enterpriseEntity[0].addresses[0].country;
    initialData['peraddress1'] = resData.enterpriseEntity[0].addresses[1].address1;
    initialData['peraddress2'] = resData.enterpriseEntity[0].addresses[1].address2;
    initialData['perpincode'] = resData.enterpriseEntity[0].addresses[1].pinCode;
    initialData['perdistrict'] = resData.enterpriseEntity[0].addresses[1].district;
    initialData['perstate'] = resData.enterpriseEntity[0].addresses[1].state;
    initialData['percountry'] = resData.enterpriseEntity[0].addresses[1].country;
    initialData['authorizedPersonFirstname'] = resData.enterpriseEntity[0].authUsers[0]?.firstName;
    initialData['authorizedPersonLastname'] = resData.enterpriseEntity[0].authUsers[0]?.lastName;
    initialData['authorizedPersonPrimaryMobileNumber'] = resData.enterpriseEntity[0].authUsers[0]?.primaryMobileNo;
    initialData['authorizedPersonAlternativeMobileNumber'] = resData.enterpriseEntity[0].authUsers[0]?.alternateMobileNo;
    initialData['authorizedPersonEmailId'] = resData.enterpriseEntity[0].authUsers[0]?.email;
    initialData['authorizedPersonPowerOfAttorneyDocument'] = documentData?.find(
        (doc: any) => doc.documentType === DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY && doc.referenceType === DOCUMENT_TYPE.AUTHUSER
    );

    return initialData;
};

export const specialTransferDetails = async (data: any, dropdownData: any) => {
    console.log('+++++++++++++++++data+++++++++++++++++++', data);
    const documentData = await entityDocumentData(data?.requestDetails.documents);
    let initialData: any = {};
    initialData['specialTransferType'] = getDropdownSelectedObject(data?.requestDetails.type, dropdownData['specialTransferType'] || []);
    initialData['approvalOfRegistrarOfCompanies'] = documentData?.find((doc: any) => doc.documentType === 'APPROVAL_OF_REGISTRAR');
    initialData['memorandumOfAssociation'] = documentData?.find((doc: any) => doc.documentType === 'MEMORANDUM_OF_ASSOCIATION');
    initialData['relevantCourtOrder'] = documentData?.find((doc: any) => doc.documentType === 'COURT_ORDER');
    initialData['corporateInsolvencyResolutionPlan'] = documentData?.find((doc: any) => doc.documentType === 'CORPORATE_INSOLVANCY_RESOLUTION_PLAN');
    initialData['LLPRegistration'] = documentData?.find((doc: any) => doc.documentType === 'LLP_REGISTRATION');
    initialData['deathCertificate'] = documentData?.find((doc: any) => doc.documentType === 'DEATH_CERTIFICATE');
    initialData['successionCertificate'] = documentData?.find((doc: any) => doc.documentType === 'SUCCESSION_CERTIFICATE');
    initialData['nocGoaIPB'] = documentData?.find((doc: any) => doc.documentType === 'NOC_SPECIAL_PROJECT');
    return initialData;
};

export const updateSectionFields = (sections: any[], sectionTitle: string, fieldsToUpdate: string[], isDisabled: boolean, isRequired: boolean) => {
    return sections.map(section => {
        if (section.title === sectionTitle) {
            return {
                ...section,
                formData: section.formData.map((field: any) => {
                    if (fieldsToUpdate.includes(field?.field)) {
                        return { ...field, isDisabled, isRequired };
                    }
                    return field;
                })
            };
        }
        return section;
    });
};

export const handleUpdates = (values: any, updateJSON: any, actualJSON: any, address1: string, address2: string) => {
    let updatedEnterpriseDetails = updateJSON; //enterprisedetailsII;

    if (values?.copyOnCheck?.[0] === 'on') {
        if (values?.isPincodeDetailsPresent) {
            updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address1, ['district', 'state', 'country'], true, false);
            if (values?.isPermanentPincodeDetailsPresent) {
                updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address2, ['perdistrict', 'perstate', 'percountry'], true, false);
            } else {
                // updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, 'Address Details', ['district', 'state', 'country'], false, true);
                updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address2, ['perdistrict', 'perstate', 'percountry'], false, true);
            }
        } else {
            updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address1, ['district', 'state', 'country'], false, true);
            if (values?.isPermanentPincodeDetailsPresent) {
                updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address2, ['perdistrict', 'perstate', 'percountry'], true, false);
            } else {
                updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address2, ['perdistrict', 'perstate', 'percountry'], false, true);
            }
        }
    } else {
        if (values?.isPincodeDetailsPresent) {
            updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address1, ['district', 'state', 'country'], true, false);
            if (values?.isPermanentPincodeDetailsPresent) {
                updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address2, ['perdistrict', 'perstate', 'percountry'], true, false);
            } else {
                updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address2, ['perdistrict', 'perstate', 'percountry'], false, true);
            }
        } else if (!values?.isPincodeDetailsPresent) {
            updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address1, ['district', 'state', 'country'], false, true);
            if (values?.isPermanentPincodeDetailsPresent) {
                updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address2, ['perdistrict', 'perstate', 'percountry'], true, false);
            } else {
                updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address2, ['perdistrict', 'perstate', 'percountry'], false, true);
            }
        } else if (values?.isPermanentPincodeDetailsPresent) {
            updatedEnterpriseDetails = updateSectionFields(updatedEnterpriseDetails, address2, ['perdistrict', 'perstate', 'percountry'], true, false);
        } else {
            updatedEnterpriseDetails = actualJSON; //ENTERPRISE_DETAILS_II;
        }
    }

    return updatedEnterpriseDetails;
};
