// notificationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NotificationState {
    count: number;
}

const initialState: NotificationState = {
    count: 0,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotificationCount(state, action: PayloadAction<number>) {
            state.count = action.payload;
        },
    },
});

export const { setNotificationCount } = notificationSlice.actions;
export const getNotificationCount = (state: { notification: NotificationState }) => state.notification.count;
export default notificationSlice.reducer;
