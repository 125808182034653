import { ErrorMessage, Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { formOptionsDTO } from './GenericFormCard.constants';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { selectFieldProps } from '../../features/modules/UserManagement/types';
import ToolTipDetails from '../ToolTipFile';

export default function GenericCommonSelector({
    field,
    title,
    type,
    dropdownOptions,
    isReadOnly,
    handleOnAssignmentChange,
    index,
    additionalSection,
    module,
    titleCode,
    isDisabled,
    condition1,
    isRequired,
    multiSelect,
    toolTipDetails,
    customHandleChange
}: selectFieldProps) {
    const { t } = useTranslation();
    const { values, errors, touched, setFieldValue, setFieldTouched, setValues } = useFormikContext<any>();
    const [isDisable, setIsDisable] = useState(false);
    const [requiredField, setRequiredField] = useState(false);

    let dropdownFieldName = field.split('.').slice(-1)[0];

    useEffect(() => {
        if (module === 'usermanagement') {
            if ((values.gstapplicable === 'false' && field === 'entityGstNo') || isReadOnly) {
                setIsDisable(true);
            } else if (values.gstapplicable === 'true' && field === 'entityGstNo') setIsDisable(false);
        }

        if (condition1 && condition1(values, index)) {
            setIsDisable(false);
            setRequiredField(true);
        } else if (condition1 && !condition1(values, index)) {
            setIsDisable(true);
            setRequiredField(false);
        }
    }, [values, errors]);

    useEffect(() => {
        if (multiSelect && _.get(values, field)?.length === 0) {
            setFieldValue(field, '');
        } else if (!_.get(values, field)?.code && !multiSelect) {
            setFieldValue(field, '');
        }
    }, [field]);

    const onCustomHandleDropdown = (e: any) => {
        if (additionalSection) {
            handleOnAssignmentChange && handleOnAssignmentChange(index, field, { code: e.value, name: e.label });
        }

        if (module === 'usermanagement') {
            const currAssign = values?.assignments && [...values?.assignments];
            if (field === 'isCurrentAssignment' && e.label === 'Yes') {
                currAssign[index].toDate = '';
                setValues((prev: any) => ({ ...prev, assignments: currAssign }));
            } else if (field === 'department') {
                currAssign[index].roles = [];
                setValues((prev: any) => ({ ...prev, assignments: currAssign }));
            }
        }

        if (multiSelect) {
            const fieldValue = e.map((val: any) => {
                return { code: val.value, name: val.label };
            });
            setFieldValue(field, fieldValue);
        } else if (!e.value) {
            console.log('field3', field, _.get(values, field), e);
            setFieldValue(field, '');
        } else setFieldValue(field, { code: e?.value || '', name: e?.label || '' });

        customHandleChange && customHandleChange(e, field);
    };

    const fieldOptions =
        dropdownOptions && (dropdownOptions as any)[dropdownFieldName]
            ? (dropdownOptions as any)[dropdownFieldName]?.map((item: formOptionsDTO) => {
                  return { value: item?.code, label: item?.name || item?.value };
              })
            : [];

    const options = !multiSelect ? [{ value: null, label: 'Please Select' }, ...fieldOptions] : fieldOptions;

    let selectedOptions;
    if (multiSelect) {
        const selectedValue = _.get(values, field);
        if (Array.isArray(selectedValue)) {
            selectedOptions = selectedValue?.map((item: formOptionsDTO) => ({
                value: item.code,
                label: item.name
            }));
        }
    }

    const classes = {
        control: (state: any) =>
            state.isDisabled
                ? 'select-disabled form-control was-validated form-border-rad ps-0 mt-0'
                : `form-control was-validated form-border-rad ps-0 mt-0 ${_.get(touched, field) && _.get(errors, field) && 'is-invalid'}  ${
                      _.get(values, field) && isRequired && !_.get(errors, field) && values.isUpdatable && 'is-valid'
                  }`,
        placeholder: (state: any) => 'placeholder-style',
        indicatorSeparator: (state: any) => 'd-none',
        clearIndicator: (state: any) => 'select-clear-indicator pe-3',
        dropdownIndicator: (state: any) => 'd-block pe-3 select-dropdown-indicator',
        option: (state: any) => (state.isFocused ? 'option-on-focus' : 'option-style' || state.isSelected ? 'bg-transparent option-style text-black' : ''),
        menu: (state: any) => 'menu-style my-0',
        menuList: (state: any) => 'py-0 rounded',
        singleValue: (state: any) => 'single-option-style ps-0',
        valueContainer: (state: any) => 'ps-0',
        multiValue: (state: any) => 'select-multi-value',
        multiValueLabel: (state: any) => 'select-multi-value-label',
        multiValueRemove: (state: any) => 'select-multi-value-remove'
    };

    return (
        <>
            <div className='d-flex'>
                <label htmlFor={field} className='pb-2 file-form-label' style={title.length > 50 ? { maxWidth: '95%' } : { width: 'fit-content' }} title={title}>
                    {titleCode ? t(titleCode, title) : title}
                </label>
                {(isRequired || requiredField) && <span className='mandatory-indicator'>*</span>}
                {toolTipDetails && <ToolTipDetails toolTipDetails={toolTipDetails} />}
            </div>
            {!additionalSection ? (
                <>
                    <Select
                        name={field}
                        id={field}
                        options={options}
                        isMulti={multiSelect}
                        classNamePrefix='react-select'
                        classNames={classes}
                        unstyled={true}
                        placeholder='Please Select'
                        onChange={onCustomHandleDropdown}
                        onBlur={() => setFieldTouched(field, true)}
                        value={
                            !selectedOptions
                                ? _.get(values, field)?.code
                                    ? { value: _.get(values, field)?.code || _.get(values, field), label: _.get(values, field)?.name || _.get(values, field) }
                                    : null
                                : selectedOptions
                        }
                        // value={_.get(values, field)?.code || _.get(values, field)}
                        isDisabled={!values.isUpdatable || isDisable || isDisabled}
                    />
                    <ErrorMessage name={field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                </>
            ) : (
                <>
                    <div>
                        <div style={{ position: 'relative' }}>
                            <div>
                                {/* <Field
                                    name={field}
                                    as={type}
                                    id={field}
                                    isValid={false}
                                    isInvalid={true}
                                    onChange={onHandleUsermanagementDropdown}
                                    className={`form-control was-validated ${
                                        (touched as any)?.[additionalSection]?.[index]?.[field] && (errors as any)?.[additionalSection]?.[index]?.[field] ? 'is-invalid' : ''
                                    }  ${
                                        (touched as any)?.[additionalSection]?.[index]?.[field] && !(errors as any)?.[additionalSection]?.[index]?.[field] && isRequired && values.isUpdatable
                                            ? 'is-valid'
                                            : ''
                                    }`}
                                    onBlur={() => setFieldTouched(`${additionalSection}[${index}].${field}`, true)}
                                    value={values[additionalSection]?.[index]?.[field]?.code}
                                    disabled={!values.isUpdatable || isDisabled}
                                >
                                    <option value='0' disabled selected hidden>
                                        {t('PLEASE_SELECT', 'Please Select')}
                                    </option>
                                    {!!dropdownOptions &&
                                        (dropdownOptions as any)[field]?.map((val: any) => (
                                            // <option value={val?.code || "0"}>{val?.title}</option>
                                            <option value={val.code}>{val.name}</option>
                                        ))}
                                </Field> */}
                                <Select
                                    name={field}
                                    id={field}
                                    options={options}
                                    isMulti={multiSelect}
                                    classNamePrefix='react-select'
                                    classNames={{
                                        control: (state: any) =>
                                            `form-control was-validated ${
                                                (touched as any)?.[additionalSection]?.[index]?.[field] && (errors as any)?.[additionalSection]?.[index]?.[field] ? 'is-invalid' : ''
                                            }  ${
                                                (touched as any)?.[additionalSection]?.[index]?.[field] && !(errors as any)?.[additionalSection]?.[index]?.[field] && isRequired && values.isUpdatable
                                                    ? 'is-valid'
                                                    : ''
                                            }`,
                                        placeholder: (state: any) => 'placeholder-style',
                                        indicatorsContainer: (state: any) => 'd-none',
                                        indicatorSeparator: (state: any) => 'd-none',
                                        dropdownIndicator: (state: any) => 'd-block',
                                        option: (state: any) => (state.isFocused ? 'option-on-focus' : 'option-style' || state.isSelected ? 'bg-transparent option-style text-black' : ''),
                                        menu: (state: any) => 'menu-style my-0',
                                        menuList: (state: any) => 'py-0 rounded'
                                    }}
                                    placeholder='Please Select'
                                    onChange={onCustomHandleDropdown}
                                    onBlur={() => setFieldTouched(field, true)}
                                    value={
                                        values[additionalSection]?.[index]?.[field]?.code
                                            ? { value: values[additionalSection]?.[index]?.[field]?.code, label: values[additionalSection]?.[index]?.[field]?.name }
                                            : null
                                    }
                                    isDisabled={!values.isUpdatable || isDisable || isDisabled}
                                />
                            </div>
                            <div className='icon-container'>
                                <FontAwesomeIcon className='text-secondary' style={{ zIndex: 1 }} icon={faChevronDown} size='lg' />
                            </div>
                        </div>
                    </div>
                    <ErrorMessage name={`${additionalSection}[${index}].${field}`}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
                </>
            )}
        </>
    );
}
