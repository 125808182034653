import React, { useState, useEffect, useRef } from 'react';
import '../portalComponents/commonStyles.scss';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { logo, logo_white, right_arrow_white, opneLogo, greenLogo } from '../../utils/Logo';
import { Image } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { right_arrow } from '../../utils/Logo';
import { isAuthenticated } from '../../utils/utils';
import ProfileSection from './ProfileSection';
import { POST, getFileUrl, getMenuData, requestInfo } from '../../services/modulesServices/userManagement.api';
import { useTranslation } from 'react-i18next';
import Notification from '../NotificationComponent/Notification';
import { useDispatch } from 'react-redux';
import { setNotificationCount } from '../../redux/notificationSlice';
import { useSelector } from 'react-redux';
import { getNotificationCount } from '../../redux/notificationSlice';

export default function HeaderComponent() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const notificationCount = useSelector(getNotificationCount);
    const getUser = JSON.parse(localStorage.getItem('user') || '{}');

    const location = useLocation();
    const ref = useRef<HTMLDivElement>(null);

    const [headerStyles, setHeaderStyles] = useState('');
    const [currentOpenMenu, setCurrentOpenMenu] = useState('');
    const [menuData, setMenuData] = useState([]);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationData, setNotificationData] = useState(null);
    const popupRef = useRef<any>(null);
    let navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const { data } = getUser.uuid && (await POST(`/idc-notification-service/usernotification/details/_search?notificationType=SYSTEM`, { RequestInfo: requestInfo() }));
                dispatch(setNotificationCount(data.unreadCount));
            } catch (error) {
                console.log('need to login again');
            }
        })();
    }, [getUser.uuid]);

    useEffect(() => {
        const handleOutsideClick = (e: any) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                setShowNotification(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const handelNotification = async () => {
        try {
            const { data } = await POST(`/idc-notification-service/usernotification/details/_search?notificationType=SYSTEM&limit=4`, { RequestInfo: requestInfo() });
            setNotificationData(data);
        } catch (error) {
            console.log('need to login again');
        }
        setShowNotification(!showNotification);
    };

    // const scrollItem () =

    useEffect(() => {
        switch (location.pathname) {
            case '/goa-idc/':
                setHeaderStyles('');
                break;
            case '/goa-idc':
                setHeaderStyles('');
                break;
            default:
                setHeaderStyles('dark-nav-header');
        }
    }, [location.pathname]);

    useEffect(() => {
        (async () => {
            try {
                // const menuData: any = await getMenuData();
                let menuData: any = await getMenuData();

                let estate: any = [
                    {
                        id: '2431',
                        url: 'menu',
                        displayName: 'E-state',
                        orderNumber: 7,
                        queryParams: '',
                        parentModule: '',
                        serviceCode: '',
                        navigationURL: '',
                        leftIcon: '',
                        rightIcon: '',
                        path: 'E-state',
                        enabled: true
                    },
                    {
                        id: '2432',
                        name: 'estateRegistry',
                        url: 'menu',
                        displayName: 'E-state registry',
                        orderNumber: 71,
                        queryParams: '',
                        parentModule: '',
                        serviceCode: '',
                        navigationURL: '/goa-idc/estate/estateRegistry',
                        leftIcon: '',
                        rightIcon: '',
                        path: 'E-state.estateRegistry',
                        enabled: true
                    }
                ];
                if (menuData.data.menus && menuData.data.menus.length > 0) {
                    menuData.data.menus.map((item: any, ind: any) => {
                        if (item.displayName === 'E-Services') {
                            item.child.push(estate);
                        }
                    });
                }
                setMenuData(menuData.data.menus);
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    const onClickDownloadItem = async (referenceID: number | string) => {
        const url = await getFileUrl(referenceID);
        window.open(url, '_blank');
    };

    const onMouseEnter = (menuItem: any) => {
        if (!menuItem.child) {
            setCurrentOpenMenu('');
        } else {
            setCurrentOpenMenu(menuItem.displayName);
        }
    };

    const onMouseLeave = (event: any) => {
        setCurrentOpenMenu('');
    };

    const handleOnRoute = (navItem: any, isPublicMenu: boolean, url: string) => {
        !isPublicMenu && localStorage.setItem('routeTo', navItem);
        window.location.href = !isPublicMenu && !isAuthenticated() ? '/goa-idc/login' : url;
    };

    const renderMenu = (item: any) => {
        return item.displayStatus === 'DISABLED' ? (
            <span style={{ color: 'grey' }}>{t(item.displayName)}</span>
        ) : item.documentReferenceId ? (
            <Link to='' onClick={() => onClickDownloadItem(item.documentReferenceId)} className={`text-decoration-none ${headerStyles ? 'sub-menu-links' : 'sub-menu-links-main'}`}>
                {t(item.displayName)}
            </Link>
        ) : (
            <Link
                // to={!item.isPublicMenu && !isAuthenticated() ? '/goa-idc/login' : item.navigationURL}
                to={''}
                onClick={() => handleOnRoute(item.displayName, item.isPublicMenu, item.navigationURL)}
                className={`text-decoration-none ${headerStyles ? 'sub-menu-links' : 'sub-menu-links-main'}`}
            >
                {t(item.displayName)}
            </Link>
        );
    };

    const renderOtherItems = (subItems: any, subIndex: number): React.ReactNode => {
        return (
            <>
                {
                    <div key={subIndex} className='col-sm-2 px-0' style={{ marginLeft: '-4rem' }}>
                        <div className='mt-6'>
                            {subItems.slice(1, 11).map((item: any, itemIndex: number) => (
                                <div key={itemIndex} className='fw-normal py-1'>
                                    {/* <a className={`text-decoration-none ${headerStyles ? "sub-menu-links" : "sub-menu-links-main"}`}>{item.displayName}</a> */}
                                    {renderMenu(item)}
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {subItems.length > 10 && renderOtherItems(subItems.slice(10), subIndex)}
            </>
        );
    };
    let localStorageLang: any = localStorage.getItem('i18nextLng') || 'en_IN';
    const [currentLang, setCurrentLang] = useState(localStorageLang);

    const changeLang = (lang: any) => {
        setCurrentLang(lang);
    };

    const visitHomepage = () => {
        navigate(`/goa-idc/`);
    };

    return (
        <>
            <Navbar className={`nav-parents font-karla ${headerStyles}`} bg-light expand='lg' sticky='top' onMouseEnter={onMouseLeave}>
                <div className='col-sm-4 d-flex align-items-center'>
                    <div className='text-start ps-5 pe-3 '>
                        <div className='navbar-image' onClick={visitHomepage} style={{ cursor: 'pointer' }}>
                            {headerStyles ? <Image src={logo_white} width={140} /> : <Image src={logo} width={140} />}
                        </div>
                    </div>
                    <div className='nav-img2'>
                        <div onClick={visitHomepage} style={{ cursor: 'pointer' }}>
                            {headerStyles ? <Image src={opneLogo} width={140} /> : <Image src={greenLogo} width={140} />}
                        </div>
                    </div>
                </div>

                <div className='col-sm-5 d-flex justify-content-center '>
                    <Navbar.Toggle aria-controls='navbarScroll' />
                    <Navbar.Collapse id='navbarScroll'>
                        <Nav className='my-lg-0 pt-3' style={{ maxHeight: '100px' }} navbarScroll>
                            {menuData?.map((menuItem: any, index: number) =>
                                !menuItem.child ? (
                                    <Nav.Link key={index} className='nav_home' onMouseEnter={() => onMouseEnter(menuItem)} style={headerStyles ? { color: '#e1e1e1' } : { color: '#000' }}>
                                        <Link to={menuItem.navigationURL} style={headerStyles ? { color: '#e1e1e1' } : { color: '#000' }}>
                                            {t(menuItem.displayName)}
                                        </Link>
                                    </Nav.Link>
                                ) : (
                                    <NavDropdown
                                        key={index}
                                        // show={true}
                                        show={currentOpenMenu === menuItem.displayName}
                                        title={
                                            <div style={headerStyles ? { color: '#e1e1e1' } : { color: '#000' }}>
                                                {t(menuItem.displayName)}
                                                {/* <CaretDown /> */}
                                                <i className='fa fa-angle-down ps-2' aria-hidden='true'></i>
                                            </div>
                                        }
                                        id='navbarScrollingDropdown'
                                        ref={ref}
                                        onMouseEnter={() => onMouseEnter(menuItem)}
                                        className={`nav_home nav_eservice`}
                                    >
                                        <div className='text-center text-lg-start text-white container pb-2 p-4' onMouseLeave={onMouseLeave} style={{ marginLeft: '18%' }}>
                                            <div className='row justify-content-center'>
                                                {menuItem.child &&
                                                    menuItem.child.map((subItems: any, subIndex: number) => (
                                                        <>
                                                            <div key={subIndex} className={subItems.length > 10 ? 'col-sm-2' : 'col-sm-3'}>
                                                                <div className='mt-3'>
                                                                    {subItems.slice(0, 11).map((item: any, itemIndex: number) =>
                                                                        itemIndex === 0 ? (
                                                                            <h6
                                                                                className={`mb-4 fw-bold  col-12 ${headerStyles ? '' : 'text-dark'} ${
                                                                                    item.displayName === 'EMPTY' ? 'opacity-0' : 'opacity-100'
                                                                                }`}
                                                                                style={{ width: '360px' }}
                                                                            >
                                                                                {t(item.displayName)}
                                                                            </h6>
                                                                        ) : (
                                                                            <div key={itemIndex} className='fw-normal py-1'>
                                                                                {renderMenu(item)}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {subItems.length > 11 && renderOtherItems(subItems.slice(10), subIndex)}
                                                        </>
                                                    ))}
                                            </div>
                                        </div>
                                    </NavDropdown>
                                )
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </div>
                <div className='d-flex col-sm-3 justify-content-end'>
                    {!isAuthenticated() ? (
                        <div>
                            <Link to='/goa-idc/login' className='fs-6 ms-5 login-btn  ' style={{ backgroundColor: '#80B036', marginRight: '30px', border: 0, borderRadius: 0, maxWidth: 150 }}>
                                {t('cs.foo')} <Image src={headerStyles ? right_arrow_white : right_arrow} className='arrow_icon' />
                            </Link>
                        </div>
                    ) : (
                        <>
                            <div className=' position-relative cursor-pointer'>
                                <div className={`nav_notification d-flex justify-content-center   position-relative rounded-circle  ${headerStyles} `} onClick={handelNotification}>
                                    <i className='fa fa-bell-o fs-4  position-relative ' aria-hidden='true' />
                                    <span className=' position-absolute notification_count rounded-pill bg-danger '>{notificationCount}</span>
                                </div>
                                <div className=' position-absolute  ' id='notification_tab' style={{ zIndex: '999', left: '-310px', top: '70px' }} ref={popupRef}>
                                    {showNotification && <Notification notificationData={notificationData} setNotificationCount={setNotificationCount} setShowNotification={setShowNotification} />}
                                </div>
                            </div>
                            <ProfileSection headerStyles={headerStyles} />
                        </>
                    )}
                </div>
            </Navbar>
        </>
    );
}
