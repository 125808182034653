import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import './ArchitectStyles.scss';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/authSlice';
import { BPArequestInfo, getArchitectSearchTableData, getEmployeeSearchTableData } from '../../../../services/modulesServices/userManagement.api';
import { ArchTableResponseInfo, tableData } from '../types';
import HeaderComponent from '../../../../components/header/Header.component';
import { arrowUpRight, git_logo } from '../../../../utils/Images';
import { right_arrow_green2, scrutinyToolIcon, gitCommitBlack } from '../../../../utils/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { DASHBOARD_TABLE_COLUMNS } from '../UserManagement.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { useTranslation } from 'react-i18next';
import { SCRUTINY_DASHBOARD_TABLE_COLUMNS } from '../../../scrutinyFlowScreen/ScrutinyFormPage.const';
import TechnicalServices from '../../../../services/technicalServices/Technical.service';
import ScrutinyToolLogo from '../../../../assets/logos/Scrutiny Icon 1 1.svg';
import ScrutinyToolArrow from '../../../../assets/logos/Vector_upword_arrow.svg';
import styled from '@emotion/styled';
import { epochToDate } from '../../../../utils/utils';
function ArchitectDashboard() {
    const { t, i18n } = useTranslation();
    const [architectSearchTableData, setArchitectSearchTableData] = useState<ArchTableResponseInfo>({});
    const userData = useSelector(selectUser);
    const navigate = useNavigate();
    const columns = SCRUTINY_DASHBOARD_TABLE_COLUMNS;
    const [rowData, setRowData] = useState<any>([]);
    const [countData, setCountData] = useState<any>([]);

    useEffect(() => {
        getArchitectSearchTableData(userData)
            .then(res => {
                setArchitectSearchTableData(res.data);
                console.log(res.data);
            })
            .catch(err => console.log(err));
    }, [userData]);

    // const handleRowClick = (rowData: tableData) => {
    //     const url = rowData.navUrl;
    //     navigate(url, { state: { rowData } });
    // };

    const handleRowClick = (rowData: any) => {
        navigate(`/goa-idc/obpas/scrutinyTool?edcr=${rowData.edcrNumber}`, { state: { rowData } });
        console.log(rowData);
    };

    const getScrutinyPlanDetails = () => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const padZero = (num: any) => (num < 10 ? '0' + num : num);

        const toCamelCase = (str: string) => {
            return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
                return match.toUpperCase();
            });
        };
        const payload = { RequestInfo: BPArequestInfo() };
        TechnicalServices.getSharedScrutinyPlans(payload, user?.uuid)
            .then(res => {
                console.log(res);
                setCountData(res.data);
                const updatedData = res.data?.EDCRShare.map((item: any) => ({
                    ...item,
                    archName: toCamelCase(item?.archName) || '-',
                    serviceType: (toCamelCase(item?.serviceType) || '-').replace(/_/g, ' '),
                    applicationType: (toCamelCase(item?.applicationType) || '-').replace(/_/g, ' '),
                    edcrNumber: item?.edcrNumber || '-',
                    lesseeName: toCamelCase(item?.lesseeName) || '-',
                    // createdTime: `${padZero(new Date(item?.createdTime || '-').getHours())}:${padZero(new Date(item?.createdTime || '-').getMinutes())}:${padZero(
                    //     new Date(item?.createdTime || '-').getSeconds()
                    // )} (${padZero(new Date(item?.createdTime || '-').getDate())}/${padZero(new Date(item?.createdTime || '-').getMonth() + 1)}/${new Date(item?.createdTime || '-').getFullYear()})`
                    createdTime: epochToDate(item.createdTime)
                }));
                setRowData(updatedData);
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        getScrutinyPlanDetails();
    }, [userData]);

    const ARCHITECT_DATA = [
        {
            obpasCode: 'OBPAS',
            statusCode: 'TOTAL_COUNT',
            obpas: 'OBPAS',
            designNo: countData.count,
            status: 'Total Count'
        },
        {
            obpasCode: 'OBPAS',
            statusCode: 'TOTAL_APPLIED',
            obpas: 'OBPAS',
            designNo: countData.totalApplied,
            status: 'Total Applied'
        },
        {
            obpasCode: 'OBPAS',
            statusCode: 'TOTAL_APPROVED',
            obpas: 'OBPAS',
            designNo: countData.totalApproved,
            status: 'Total Approved'
        }
    ];

    return (
        <>
            <HeaderComponent />
            <div className='bg-light m-auto px-5 py-4 row architect-dashboard'>
                <div className='table-container parents'>
                    <div className='row pt-5 ps-2 mb-4'>
                        <h2 className='dashboard_heading'>{t('MY_DESK', 'My Desk')}</h2>
                        <div className='col-12 col-lg-5  col-md-7 rounded-4 mt-3 bg-white border border-1 py-3 ps-3'>
                            <div className='Gm-module-name fw-bold font-28 lh-30 mb-2 pb-1'>{'OBPAS'}</div>

                            <div className='row gx-2'>
                                {ARCHITECT_DATA.map((value: any, index: number) => (
                                    <div className={`col-4 `}>
                                        <div className={` p-4 pb-2 design${index + 1}`}>
                                            {/* <p className={`text-start obpas${index + 1} mb-0`}>{t(value.obpasCode, `${value.obpas}`)}</p> */}
                                            <p
                                                className={`text-start logo${index + 1} d-flex justify-content-center align-items-center`}
                                                style={{ height: '32px', width: '32px', background: '#FFFFFF8F' }}
                                            >
                                                <img src={gitCommitBlack} alt='' />
                                            </p>
                                            <h1 className={`text-start  py-2 gm-dashboard-${index + 1}`}>{value.designNo}</h1>
                                            <p className={`text-start gm-dashboard-${index + 1}`}>{t(value.statusCode, `${value.status}`)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* <div className={`col-2 p-3 d-flex flex-column justify-content-between pb-0 scrutiny position-relative ms-auto`}>
                            <h1 className={`text-start title py-2`} style={{ paddingLeft: '14px' }}>
                                {t('SCRUTINY_SHARED_PLANS', 'Plan Shared with Lessee')}
                            </h1>
                            <Link to='/goa-idc/scrutinysharedplans' className='cursor-pointer' style={{ paddingLeft: '14px' }}>
                                <p className={`text-start logo d-flex justify-content-center align-items-center`}>
                                    <img src={right_arrow_green2} width={'20px'} alt='' />
                                </p>
                            </Link>
                        </div> */}
                        <div className='col-12 col-lg-7 col-md-5  d-flex flex-column justify-content-md-end py-3'>
                            <div className='row'>
                                <div
                                    className={`col-5 col-lg-2 col-sm-4 p-3 d-flex flex-column justify-content-between pb-0 scrutiny position-relative ms-md-auto`}
                                    style={{ background: '#323E4A', height: '14.5rem', width: '12.5rem' }}
                                >
                                    <img src={ScrutinyToolLogo} style={{ height: '60px', width: '60px' }} alt='' />
                                    <h1 className={`text-start title py-2`} style={{ paddingLeft: '14px', color: '#ffffff', width: '90px', fontSize: '1.5rem' }}>
                                        {t('SCRUTINY_TOOL', 'Scrutiny Tool')}
                                    </h1>
                                    <Link to='/goa-idc/obpas/scrutinyTool' className='cursor-pointer' style={{ paddingLeft: '14px' }}>
                                        <p
                                            className={`text-start d-flex justify-content-center align-items-center`}
                                            style={{ width: '32px', height: '20px', borderRadius: '36px', background: '#FFFFFF52' }}
                                        >
                                            <img src={ScrutinyToolArrow} width={'20px'} alt='' />
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <GenericActionTable
                    columns={DASHBOARD_TABLE_COLUMNS}
                    rowData={architectSearchTableData?.inboxs}
                    additionalMethod={handleRowClick}
                    searchBarPlaceholder='SEARCH_BY'
                ></GenericActionTable> */}
                <GenericActionTable columns={columns} rowData={rowData} additionalMethod={handleRowClick} searchBarPlaceholder='SEARCH_SCRUTINY_DB' />
            </div>
        </>
    );
}

export default ArchitectDashboard;
