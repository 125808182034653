import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { formOptionsDTO } from './GenericFormCard.constants';
import { useTranslation } from 'react-i18next';
import ToolTipDetails from '../ToolTipFile';
import _ from 'lodash';
import Select from 'react-select';

interface selectFieldProps {
    isRequired?: boolean;
    field: any;
    title: string;
    type?: string;
    options?: formOptionsDTO[];
    isDisabled?: boolean;
    titleCode?: any;
    toolTipDetails?: any;
}

export default function BpaCommonSelector(props: selectFieldProps) {
    const { t } = useTranslation();
    const { errors, values, setFieldValue } = useFormikContext<any>();
    const fieldOptions = props?.options
        ? (props?.options as any)?.map((item: formOptionsDTO) => {
              return { value: item?.value || item?.code, label: item?.label || item?.name };
          })
        : [];

    const options = fieldOptions;

    const classes = {
        control: (state: any) =>
            state.isDisabled
                ? 'select-disabled form-control was-validated form-border-rad ps-0 mt-0'
                : `form-control was-validated form-border-rad ps-0 mt-0 ${_.get(props?.field) && _.get(errors, props?.field) && 'is-invalid'}  ${
                      _.get(values, props?.field) && props?.isRequired && !_.get(errors, props?.field) && values.isUpdatable && 'is-valid'
                  }`,
        placeholder: (state: any) => 'placeholder-style',
        indicatorSeparator: (state: any) => 'd-none',
        clearIndicator: (state: any) => 'select-clear-indicator pe-3',
        dropdownIndicator: (state: any) => 'd-block pe-3 select-dropdown-indicator',
        option: (state: any) => (state.isFocused ? 'option-on-focus' : 'option-style' || state.isSelected ? 'bg-transparent option-style text-black' : ''),
        menu: (state: any) => 'menu-style my-0',
        menuList: (state: any) => 'py-0 rounded',
        singleValue: (state: any) => 'single-option-style ps-0',
        valueContainer: (state: any) => 'ps-0',
        multiValue: (state: any) => 'select-multi-value',
        multiValueLabel: (state: any) => 'select-multi-value-label',
        multiValueRemove: (state: any) => 'select-multi-value-remove'
    };

    const onCustomHandleDropdown = (e: any) => {
        setFieldValue(props?.field, e?.value);
    };

    const selectedOptions = options?.filter((item: formOptionsDTO) => item?.value === _.get(values, props?.field));

    return (
        <>
            <div className='d-flex'>
                <label htmlFor={props.field} className='pb-2'>
                    {props.titleCode ? t(props.titleCode) : props.title}
                    {props.isRequired ? <span className='text-danger'>*</span> : <></>}
                    {props?.toolTipDetails && <ToolTipDetails toolTipDetails={props?.toolTipDetails} />}
                </label>
            </div>
            {/* <select className="form-select"> */}
            {/* <Field
                disabled={props?.isDisabled}
                name={props.field}
                as='select'
                id={props?.field}
                className={`form-select ${errors.hasOwnProperty(props?.field) && values[props?.field] !== '' && 'is-invalid'} ${!!values[props?.field] && 'is-valid'}`}
                style={{ height: 50 }}
            >
                <option value='' disabled selected hidden>
                    Please Select
                </option>
                {!!props?.options && props?.options.map(val => <option value={val?.code || val?.value || ''}>{val?.title}</option>)}
            </Field> */}
            <Select
                name={props.field}
                id={props.field}
                options={options}
                classNamePrefix='react-select'
                placeholder='Please Select'
                classNames={classes}
                onChange={onCustomHandleDropdown}
                value={selectedOptions}
                isDisabled={props?.isDisabled}
            />
            {/* </select> */}
            <ErrorMessage name={props.field}>{msg => <span className='text-danger font-12'>{msg}</span>}</ErrorMessage>
        </>
    );
}
