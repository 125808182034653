import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import HeaderComponent from '../../../../components/header/Header.component';
import { useEffect, useState } from 'react';
import Loading from '../../../../components/portalComponents/Loading.component';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { t } from 'i18next';
import { Card } from 'react-bootstrap';
import SideCard from '../../../../components/moduleCommonFormik/SideCardGenericComponent';
import { FormikProvider, useFormik } from 'formik';
import { endpoints, estateEndpoints, getProgressBarData, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { check, cross } from '../../../../utils/Logo';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { EXISTING_CO_DETAILS, NEW_CO_DETAILS } from './CommercialOperation.const';
import { entityDocumentData, getNextActions } from '../EstateManagement.controller';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { COMMERCIAL_OPS_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { getFirstErrorKey } from '../../../../utils/utils';
import { PLOT_DETAILS } from '../Allotment/Allotment.const';
import PaymentSubmissionStatus from '../../../technicalFlowScreen/technicalClearanceForm/TechnicalClearencePayment/PaymentSubmissionStatus';
import ProgressBar from '../../../../components/moduleCommonFormik/ProgressBar';

export function CommercialOperation() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [existingCOCertificate, setExistingCOCertificate] = useState<any>([]);
    const [hasValidAction, setHasValidAction] = useState<boolean>(true);
    const [progressBarDetails, setProgressBarDetails] = useState<any>(null);
    const [previousData, setPreviousData] = useState<any>(null);

    const location: any = useLocation();
    const data = location?.state?.data;
    const [searchParams] = useSearchParams();
    const applicationNumber = searchParams.get('applicationNumber');
    const stepsDetails = [{ titleCode: 'COMMERCIAL_OPERATION', name: 'Certificate of Commercial Operation', index: 1, isActive: true }];
    let navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            applicantName: user?.firstName
        },
        onSubmit: () => {},
        validationSchema: COMMERCIAL_OPS_VALIDATION
    });

    const { values, setValues, setFieldTouched } = formik;

    useEffect(() => {
        if (data && !applicationNumber) {
            getTableData(data?.plotDetails);

            searchCommercialOps(data?.entityId, data?.plotId);
        }
    }, [data]);

    useEffect(() => {
        if (applicationNumber) {
            (async () => {
                try {
                    setIsLoading(true);

                    getProgressBarData(applicationNumber)
                        .then(res => setProgressBarDetails(res.data))
                        .catch(e => console.log(e));

                    const { data: applicationSearch } = await POST(endpoints.commercialCertificateSearch + `?applicationNumbers=${applicationNumber}`, { RequestInfo: requestInfo() });
                    const details = applicationSearch?.commercialOpsDetails[0];

                    const nextActions = await getNextActions(applicationNumber);
                    setHasValidAction(nextActions?.nextValidAction?.length > 0);
                    setValues((prev: any) => ({ ...prev, isUpdatable: nextActions?.nextValidAction?.length > 0 }));

                    const { data: plotData } = await POST(estateEndpoints.plotSearch + `?plotUuids=${details?.entityRefDetails?.plotId}`, {
                        RequestInfo: requestInfo()
                    });

                    getTableData(plotData?.plots[0]);

                    const newDoc = details?.docRefId && (await getDocumentData(details?.docRefId));
                    const oldDoc = details?.previousCommercialOpsDetailsDocRefId && (await getDocumentData(details?.previousCommercialOpsDetailsDocRefId));
                    const oldExpiryDate = details?.previousCommercialOpsDetailsExpiryDate ? details?.previousCommercialOpsDetailsExpiryDate : '';
                    setPreviousData(details?.previousCommercialOpsDetailsExpiryDate);

                    setValues((prev: any) => ({ ...prev, newCertificateOfCO: newDoc, existingCertificateOfCO: oldDoc, expiryDate: oldExpiryDate }));

                    setIsLoading(false);
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            })();
        }
    }, [applicationNumber]);

    const status: any = progressBarDetails?.progressStatus?.find((item: any) => item.isCurrent === true);
    const applicationData = {
        applicationNo: applicationNumber,
        status: progressBarDetails?.currentStatus,
        data: progressBarDetails?.auditTrails,
        bpaData: '',
        pendingWith: status?.actor || '-'
    };

    const getTableData = (plotData: any) => {
        console.log('plotData', plotData);
        setValues((prev: any) => ({
            ...prev,
            estateName: plotData?.estateDetails?.estateName,
            phase: plotData?.phase,
            plotNo: plotData?.plotNo,
            plotType: plotData?.plotType,
            plotUuid: plotData?.uuid
        }));
    };

    const searchCommercialOps = async (entityId: any, plotId: any) => {
        try {
            const { data: commercialCertificate } = await POST(endpoints.commercialCertificateSearch + `?entityIds=${entityId}&plotIds=${plotId}&statuses=APPROVED`, {
                RequestInfo: requestInfo()
            });
            setExistingCOCertificate(commercialCertificate?.commercialOpsDetails);
            const doc = await getDocumentData(commercialCertificate?.commercialOpsDetails[0]?.docRefId);
            setValues((prev: any) => ({ ...prev, existingCertificateOfCO: doc, expiryDate: commercialCertificate?.commercialOpsDetails[0]?.expiryDate }));
        } catch (e: any) {
            let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const getDocumentData = async (refId: any) => {
        const certificateOfCO = refId && (await getDocumentDetails(refId, 'COMMERCIAL_CERTIFICATE'));
        return certificateOfCO;
    };

    const getDocumentDetails = async (docRefId: any, docType: any) => {
        const documentData = (await entityDocumentData([{ docReferenceId: docRefId, documentType: docType }]))[0];
        return { ...documentData, documentName: documentData?.tag };
    };

    const handleStepClick = () => {};

    const handleModalClose = (type: string, final?: boolean) => {
        setModalDetails({ show: false });
        if (type === 'success') {
            navigate('/goa-idc/enterprisedashboard');
        }
    };

    const onSubmit = async () => {
        const payload = {
            RequestInfo: requestInfo(),
            plotId: values?.plotUuid,
            docRefId: values?.newCertificateOfCO?.docReferenceId
        };

        try {
            await COMMERCIAL_OPS_VALIDATION.validate(values, { abortEarly: false, context: values });
            try {
                await POST(endpoints.commercialCertificateCreate, payload);
                setModalDetails({ show: true, title: 'Success!', para: 'Certificate of Commercial Certificate submitted successfully', img: check, type: 'success', reUpload: 'OK' });
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e: any) {
            let errorField = getFirstErrorKey(e);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setModalDetails({ show: true, title: 'Error!', para: 'Please fill in all the required fields', img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onClose = () => {
        navigate('/goa-idc/enterprisedashboard');
    };

    console.log('formik', formik);

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='employee-creation-container bg-light m-auto px-5 py-4 row'>
                        <GenericHeader
                            text={t('COMMERCIAL_OPERATION', 'Certificate of Commercial Operation')}
                            bottomSubText={applicationNumber ? `Type : ${t('COMMERCIAL_OPERATION', 'Certificate of Commercial Operation')}, No : ${applicationNumber}` : ''}
                            additionalClass={'sub-text-style'}
                        />

                        <div className='col-3 stickyCard-section'>
                            <div className='stickyCard' style={{ top: '110px' }}>
                                <GenericHeader
                                    text={t('COMMERCIAL_OPERATION', 'Certificate of Commercial Operation')}
                                    sideHeader={true}
                                    additionalClass='sub-text-style'
                                    bottomSubText={applicationNumber ? `Type :${t('COMMERCIAL_OPERATION', 'Certificate of Commercial Operation')} , No : ${applicationNumber}` : ``}
                                />
                                <Card className='card_border p-3'>
                                    <Card.Body>
                                        <Card.Text className=' d-flex flex-column  row-gap-3'>
                                            <SideCard steps={stepsDetails} visited={true} activeIndex={activeIndex} handleStepClick={handleStepClick} indexType={'alphabets'} />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                        <div className='col-9 form-section'>
                            {!!applicationNumber && applicationData.status !== 'Draft' && (
                                <Card className='card_border my-2'>
                                    <Card.Body>
                                        <PaymentSubmissionStatus data={applicationData} children={<ProgressBar progressBarDetails={progressBarDetails} />} />
                                    </Card.Body>
                                </Card>
                            )}

                            <FormikProvider value={formik}>
                                <Card className='card_border'>
                                    <Card.Body>
                                        <div className='mb-3'>
                                            {PLOT_DETAILS?.map((val: any) => (
                                                <GenericFormCard {...val} />
                                            ))}
                                        </div>
                                        {existingCOCertificate.length || previousData ? EXISTING_CO_DETAILS?.map((val: any) => <GenericFormCard {...val} />) : <></>}
                                        {NEW_CO_DETAILS?.map((val: any) => (
                                            <GenericFormCard {...val} />
                                        ))}
                                    </Card.Body>
                                </Card>
                            </FormikProvider>
                        </div>
                    </div>

                    <footer className='footer fixed-bottom footerBtnPatch'>
                        <div className='d-flex'>
                            <div className='d-flex justify-content-end  container me-3' style={{ padding: 15 }}>
                                {hasValidAction ? (
                                    <GenericButtons text={t('SUBMIT', 'Submit')} variant='primary' handleClick={onSubmit} />
                                ) : (
                                    <GenericButtons text={t('CLOSE', 'Close')} variant='primary' handleClick={onClose} />
                                )}
                            </div>
                        </div>
                    </footer>

                    <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type, modalDetails?.finalSubmit)}
                    />
                </>
            )}
        </>
    );
}
