import { useLocation, useSearchParams } from 'react-router-dom';
import PlotUpdate from '../PlotUpdate';
import { PlotMasterWorkflow } from './PlotMasterWorkflow';
import { useEffect, useState } from 'react';
import { POST, requestInfo } from '../../../../../services/modulesServices/userManagement.api';

export const PlotWorkflow = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();

    const plotUpdate = JSON.parse((searchParams.get('plotUpdate') as any) || null);
    console.log('plottt', searchParams.get('plotUpdate'), plotUpdate);
    const applicationNumber = searchParams.get('applicationNumber') || '';
    const data = location?.state?.data || null;

    const [type, setType] = useState(null);

    useEffect(() => {
        (async () => {
            if (applicationNumber) {
                try {
                    const { data }: any = await POST(`/idc-estate-service/master-application/_search?applicationNo=${applicationNumber}`, { RequestInfo: requestInfo() });
                    setType(data[0].type);
                } catch (e) {
                    console.log(e);
                }
            }
        })();
    }, [applicationNumber]);

    return plotUpdate || type === 'MERGE' || type === 'SPLIT' ? <PlotUpdate data={data} /> : <PlotMasterWorkflow />;
};
