import _ from 'lodash';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../utils/constants';
import { requestInfo } from '../../services/modulesServices/userManagement.api';
import { useSearchParams } from 'react-router-dom';
import { getDropdownSelectedObject } from '../modules/UserManagement/UserMangement.controller';
import { getPlotDetails } from '../modules/EstateManagement/Allotment/Allotment.controller';
import { entityDocumentData } from '../modules/EstateManagement/LeaseDeed/LeaseDeed.controller';


export const QUERY_STEPS_DETAILS = [
    {
        titleCode: 'QUERY_DETAILS',
        name: 'Query Details',
        index: 1,
        isActive: true
    }
];
export const GRIEVANCE_STEPS_DETAILS = [
    {
        titleCode: 'GRIEVANCE_DETAILS',
        name: 'Grievance Details',
        index: 1,
        isActive: true
    }
];

export const QUERY_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'QUERY_NO',
                title: 'Query No',
                field: 'queryNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (values: any) => !!_.get(values, `queryNo`),
                isDisabled: true
            },
            {
                titleCode: 'QUERY_DATE',
                title: 'Query Date',
                field: 'queryDate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (values: any) => !!_.get(values, `queryDate`),
                isDisabled: true
            },
            {
                titleCode: 'QUERY_TYPE',
                title: 'Query Type',
                field: 'queryType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'DIVISION',
                title: 'Division',
                field: 'division',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true,
                isDisabled: true
            }
        ]
    }
];
export const QUERY_DETAILS_SECOND: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'TITLE',
                title: 'Title',
                field: 'title',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-12',
                isRequired: true,
                isDisabled: true
            },
            {
                titleCode: 'DESCRIPTION',
                title: 'Description',
                field: 'description',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-12',
                isRequired: true,
                isDisabled: true
            }
        ]
    }
];
export const QUERY_RESPONSE_SECOND: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'DESCRIPTION',
                title: 'Description',
                field: 'responseDescription',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD,
                class: 'col-12',
                isRequired: true,
            },
            {
                titleCode: 'ATTACHMENT',
                title: 'Attachment',
                field: 'responseAttachment',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
            }
        ]
    }
];

export const QUERY_TABLE_COLUMNS: any = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-3 column-text-start',
        isClickable: false,
        cellstyle: { width: '80px' }
    },
    {
        colName: 'Document Name',
        fieldName: 'documentName',
        clsName: 'col-sm-2 column-text-start',
        cellstyle: { width: '40%' },
        isClickable: true
    },
    {
        colName: 'Date and Time',
        fieldName: 'dateAndTime',
        clsName: 'col-sm-1 column-text-start',
        cellstyle: { width: '40%' }
    }
];
export const QUERY_ENTRY_CORRESPONDENCE = [
    {
        title: 'A - Query Details',
        field: 'queryDetails'
    }
];

export const setQueryInitValue = async (queryResponse: any, dropdownData: any) => {
    let initialData: any = {};
    initialData['queryNo'] = queryResponse?.queryNo;
    initialData['queryDate'] = queryResponse?.queryDate;
    initialData['title'] = queryResponse?.title;
    initialData['description'] = queryResponse?.description;
    initialData['queryType'] = getDropdownSelectedObject(queryResponse?.queryType, dropdownData['queryType'] || []);
    initialData['division'] = getDropdownSelectedObject(queryResponse?.division, dropdownData['division'] || []);

    return initialData;
};
export const setQueryApplicationInitValue = async (queryResponse: any, dropdownData: any) => {
    const filteredDocData = queryResponse?.queryDocDetails.filter((val: any) => val?.docType === 'RESPONSE');
    const exisResDocument = filteredDocData?.[0]?.docRefId && (await entityDocumentData(filteredDocData?.[0]?.docRefId));

    let initialData: any = {};
    initialData['queryNo'] = queryResponse?.queryNo;
    initialData['queryDate'] = queryResponse?.queryDate;
    initialData['title'] = queryResponse?.title;
    initialData['description'] = queryResponse?.description;
    initialData['responseDescription'] = queryResponse?.queryResponse;
    initialData['responseAttachment'] = exisResDocument && exisResDocument?.[0];
    initialData['queryType'] = getDropdownSelectedObject(queryResponse?.queryType, dropdownData['queryType'] || []);
    initialData['division'] = getDropdownSelectedObject(queryResponse?.division, dropdownData['division'] || []);

    return initialData;
};
export const plotInitialData = async (plotRes: any) => {
    let initialData: any = {};
    initialData['estateName'] = getPlotDetails(plotRes?.estateDetails?.estateName);
    initialData['phase'] = getPlotDetails(plotRes?.phase);
    initialData['plotNo'] = getPlotDetails(plotRes?.plotNo);
    initialData['plotType'] = getPlotDetails(plotRes?.plotType);

    return initialData;
};
