import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericHeader from '../moduleCommonFormik/GenericHeader';
import HeaderComponent from '../header/Header.component';
import userManagement from '../../assets/logos/userManag.svg';
import arrow from '../../assets/logos/ArrowUpRight.svg';
import { Link } from 'react-router-dom';
import { USER_MANAGEMENT_SCREEN_JSON } from './Screen.const';
import FooterComponent from '../portalComponents/Footer.component';
const UserManagScreen = () => {
    const { t } = useTranslation();

    return (
        <>
            <HeaderComponent />
            <div className=' pt-4 apply-plot-screen-parents font-inter ' style={{ background: '#F5F5F5' }}>
                <div className='row gx-0 px-5 '>
                    <div className='col-12'>
                        <GenericHeader text={t('EMPLOYEE_MANAGEMENT', 'Employee Management')} />
                    </div>
                    <div className='col-12 border-rad-12 border mt-3 user-screen-main-card d-flex flex-column '>
                        <div className='map-image d-flex flex-column align-items-center '>
                            <img src={userManagement} alt='water-connection' />
                            <div className='fw-bold screen-text-color font-24 lh-29 ls-2 text-center'>{t('EMPLOYEE_MANAGEMENT', 'Employee Management')}</div>
                        </div>
                        <div className='row border-top py-3 mt-3 mx-3 gx-3'>
                            {USER_MANAGEMENT_SCREEN_JSON.map((val: any, ind: number) => (
                                <div className='col-3'>
                                    <Link className='action-cards d-flex flex-column border-rad-12 p-3' to={val?.btnURL}>
                                        <img src={val?.logo} style={{ width: 'max-content' }} alt='' />
                                        <div className='mt-2 d-flex justify-content-between '>
                                            <span className='fw-semibold  dashboard-text-color lh-24 font-18'>{val?.btnTitle}</span>
                                            <span>
                                                <img src={arrow} alt='' />
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='mt-1'>
                    <FooterComponent onlyCopyright={true} />
                </div>
            </div>
        </>
    );
};

export default UserManagScreen;
