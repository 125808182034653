import { ReactElement, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './GenericStyles.scss';
import { useTranslation } from 'react-i18next';
import GenericButtons from '../UI components/Buttons/GenericButtons';

interface ModalProps {
    titleCode?: any;
    handleClose(): void;
    show: boolean;
    img?: any;
    title: string;
    para?: string;
    fontsize?: string;
    reUpload: string;
    handleSubmit?: (e: any) => void;
    type?: string;
    displayInput?: boolean;
    children?: ReactElement;
    btnLabel?: string;
    isDelete?: boolean;
    rowData?: any;
}

function StatusAlertPopup(props: ModalProps) {
    const { t } = useTranslation();
    const [comment, setComment] = useState('');
    const { show, handleClose, img, title, fontsize, para, reUpload, type, displayInput, titleCode, btnLabel } = props;

    return (
        <>
            <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
                <div className='custom-close-button' onClick={handleClose}></div>
                {img && <img className='modal-img' src={props?.img} alt='' width='100%' />}

                <Modal show={show} onHide={handleClose} backdrop='static'>
                    <div className='contents m-5 font-karla' style={{ maxWidth: '100%' }}>
                        <Modal.Title className='fs-4'>{titleCode ? t(titleCode) : title}</Modal.Title>
                        <Modal.Body>
                            <p className={`${fontsize} font-karla`}>{para}</p>
                            {displayInput && <input className='form-control' onChange={e => setComment(e.target.value)} />}
                            {props?.children}
                        </Modal.Body>
                        <Modal.Footer>
                            {!props?.isDelete ? (
                                <>
                                    <Button
                                        variant='btn'
                                        onClick={() => props?.handleSubmit && props.handleSubmit(comment)}
                                        className={type === 'error' ? 'error-modal' : type === 'warning' ? 'warning-modal' : 'success-modal'}
                                    >
                                        {btnLabel || reUpload}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <GenericButtons handleClick={handleClose} text={'Cancel'} />
                                    <GenericButtons handleClick={() => props?.handleSubmit && props.handleSubmit(props?.rowData)} text={'Delete'} variant='primary' />
                                </>
                            )}
                        </Modal.Footer>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default StatusAlertPopup;

StatusAlertPopup.defaultProps = {
    para: '',
    fontsize: '',
    scrutinyPara: '',
    scrutiny: '',
    S_number: '',
    reUpload: '',
    btnLabel: 'Close'
};
