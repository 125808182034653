import type { ToolbarProps, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { pdfjs } from 'react-pdf';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { OpenFile, Viewer, Worker } from '@react-pdf-viewer/core';
import { devUrl } from '../../services/modulesServices/userManagement.api';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
interface GenericPDFViewerProps {
    fileStoreId?: string;
    documentName?: string;
}
const GenericPDFViewer = (props: GenericPDFViewerProps) => {
    const { fileStoreId, documentName } = props;
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            const fileName = documentName ? documentName : file.name.substring(file.name.lastIndexOf('/') + 1);
            return documentName ? fileName : `${fileName}.pdf`;
        }
    });

    const { DownloadButton } = getFilePluginInstance;
    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Download: () => (
            <>
                <DownloadButton />
            </>
        )
    });

    const renderToolbar = (Toolbar: (props: ToolbarProps) => React.ReactElement) => <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>;
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar
    });
    const { activateTab } = defaultLayoutPluginInstance;
    activateTab(0);

    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    const pdfUrl = devUrl + `/filestore/v1/files/id?tenantId=ga&fileStoreId=${fileStoreId}`;

    return (
        <>
            {fileStoreId ? (
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                    <Viewer
                        fileUrl={pdfUrl}
                        defaultScale={1}
                        plugins={[
                            // Register plugins
                            getFilePluginInstance,
                            defaultLayoutPluginInstance
                        ]}
                        withCredentials={false}
                    />
                </Worker>
            ) : (
                <></>
            )}
        </>
    );
};

export default GenericPDFViewer;
