import React, { useEffect, useState } from 'react';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { FormikProvider, useFormik } from 'formik';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
// import NitCorrespondence from './NitCorrespondence';
import { Card, Form } from 'react-bootstrap';
// import { tenderDetails } from './NITscreen.const';
import { endpoints, estateEndpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { getHistory, getSuccessMessage, getWorkflowDocumentSearch, onAttachDocument, strToNum } from '../../EstateManagement/EstateManagement.controller';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { check, cross } from '../../../../utils/Logo';
import PopUps from '../../../../components/PopUps';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
// import { LOA_DETAILS_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { convertDateTimeToISOFormate, convertDateToDDmmYYYY, convertMonDDYYYYtoDDmmYYYY, getFirstErrorKey } from '../../../../utils/utils';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import LoaCorrespondence from './LoaCorrespondence';
import { loaDetailValues } from './Loa.const';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../EstateManagement/LeaseDeed/LeaseDeed.const';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import StatusAlertPopup from '../../../../components/moduleCommonFormik/StatusAlertPopup';
import { BIDDER_DETAILS_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { EsignModal } from '../../../commonPages/EsignModal';
import { setDprHeaderData } from '../DPR creation screen/DPR.const';
import * as Yup from 'yup';

const LoaWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [content, setContent] = useState('');
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [tabelData, setTabelData] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [resData, setResData] = useState<any>(null);
    const [estCost, setEstCost] = useState<number>(0);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [reminderPopUp, setReminderPopUp] = useState<boolean>(false);
    const [refId, setRefId] = useState<any>(null);
    const [actionAfterEsign, setActionAfterEsign] = useState<any>(null);
    const [esignModal, setEsignModal] = useState<boolean>(false);
    const [fileStoreId, setFileStoreId] = useState<any>('');
    const [dropdownOptions, setDropdowOptions] = useState<any>([]);
    const [populateDropdown, setPopulateDropdown] = useState<any>(null);
    const [openSection, setOpenSection] = useState<string>('dprDetails');
    const [eSignTry, setESignTry] = useState<boolean>(false);

    const [dropdownValue, setDropdownValue] = useState(null);
    const totalPages = 3;
    const reqInfo = { RequestInfo: requestInfo() };

    const { t } = useTranslation();
    const location: any = useLocation();
    const loaDetailsList = location?.state?.loaDetailsList;
    const dropdownData = location?.state?.dropdownData;
    const loaRowData = location?.state?.selectedLoaRowData;
    let user = localStorage.getItem('currentUserAssignment');
    const getUserDetails = JSON.parse(localStorage.getItem('user') || '{}');
    const userUuid = getUserDetails?.uuid;
    const [searchParams] = useSearchParams();
    const applicationNumber: any = searchParams.get('applicationNumber');
    const isViewOnlyString = searchParams.get('isViewOnly');
    const isViewOnly = JSON.parse(isViewOnlyString ? isViewOnlyString : 'false');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const docsUuid = searchParams.get('docsUuid');
    const action: any = searchParams.get('action');
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const note: any = searchParams.get('note');
    const navigate = useNavigate();
    const eSignTried: boolean = JSON.parse(searchParams.get('eSignTried') || 'false');
    const success: boolean = JSON.parse(searchParams.get('success') || 'true');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            esignOption: 'DSC'
        },
        onSubmit: () => {},
        validationSchema: BIDDER_DETAILS_VALIDATION[`page${openSection === 'dprDetails' ? 1 : openSection === 'bidderDetails' ? 2 : openSection === 'loaDetails' ? 3 : 4}`]
    });
    const { values, setValues, errors, setFieldTouched, setTouched, setFieldValue } = formik;

    const combinedValidationSchema = Yup.object().shape(
        Object.keys(BIDDER_DETAILS_VALIDATION).reduce((acc, page) => {
            return { ...acc, ...BIDDER_DETAILS_VALIDATION[page].fields };
        }, {})
    );

    useEffect(() => {
        setFieldValue('periodConsumed', loaRowData?.dprHeaderDetails?.periodConsumed);
        (async () => {
            const dropdownOption = await dprDropdownDataOptions();
            setPopulateDropdown(dropdownOption);
        })();
    }, []);

    useEffect(() => {
        if (note && action && nextValidAction && resData && signedFileStoreId) {
            setFieldValue('notes', note);
            if (eSignTried && !success && !eSignTry) {
                setModalDetails({ show: true, title: t('ERROR', 'Error!'), para: t('E_SIGN_FAILED', 'e-Sign Failed'), img: cross, type: 'error', reUpload: 'OK' });
            } else if (success) {
                handlePrimaryAction({ action: action }, note);
            }
        }
        // if (nextValidAction && resData) {
        //     setFieldInitialValues(resData);
        // }
    }, [nextValidAction, resData]);

    const signDocument = () => {
        const eSignParam = new URLSearchParams(searchParams);
        eSignParam.delete('note');
        eSignParam.delete('action');
        eSignParam.delete('eSignTried');
        eSignParam.delete('signedFileStoreId');
        eSignParam.delete('success');
        let url = window.location.origin + `/goa-idc/letter-of-acceptance?${eSignParam.toString()}&note=${values.notes}&action=${actionAfterEsign}&eSignTried=true`;
        url = btoa(url);
        navigate(`/goa-idc/emudra-esign?fileStoreId=${refId}&callbackUrl=${url}`);
    };
    const setFieldInitialValues = async (resData: any, tempDropdownOptions: any) => {
        if (loaDetailsList || applicationNumber) {
            let dprHeaderDetails: any = {};

            if (tempDropdownOptions?.length === 1) {
                const selectedObject = resData?.worksTenderDetails?.dprHeaderDetails?.find((val: any) => val.dprNo === tempDropdownOptions?.[0]?.name);
                dprHeaderDetails = await setDprHeaderData(selectedObject, populateDropdown);
                dprHeaderDetails = { ...dprHeaderDetails, dprNumber: { name: tempDropdownOptions?.[0]?.name, code: tempDropdownOptions?.[0]?.code } };
            }
            let initData: any = {};
            if (resData?.dprHeaderDetails) {
                initData = await setDprHeaderData(resData?.dprHeaderDetails, populateDropdown);
            }
            setIsLoading(true);
            const fieldsInitialValues = loaDetailValues(resData);
            setValues((prevVal: any) => {
                return {
                    ...prevVal,
                    ...fieldsInitialValues,
                    ...initData,
                    dprNumber: { name: resData?.dprHeaderDetails?.dprNo, code: resData?.dprHeaderDetails?.dprNo },
                    ...dprHeaderDetails,
                    tenderType: resData?.worksTenderDetails?.tenderType
                };
            });
            setRefId(resData?.docReferenceId);
            setContent(resData?.content);
            setIsLoading(false);
        }
    };
    const getCorrespondenceDocument = async () => {
        try {
            setIsLoading(true);
            const { data } = await POST(
                endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber || loaDetailsList?.applicationNo}&businessService=${nextValidAction.businessService}`,
                {
                    RequestInfo: requestInfo()
                }
            );
            setDocumentDetails(data?.documents);

            const relatedApplicationDocument = await POST(endpoints.worksRelatedDocument + `?applicationNos=${applicationNumber || loaDetailsList?.applicationNo}`, { RequestInfo: requestInfo() });
            setRelatedApplicationDocument(relatedApplicationDocument);
            setIsLoading(false);
        } catch (e: any) {
            console.log(e);
            setIsLoading(false);
            let errMsg = e?.response?.data?.errorMessage || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };
    useEffect(() => {
        (async () => {
            if ((applicationNumber || loaDetailsList?.applicationNo) && !resData && populateDropdown) {
                try {
                    setIsLoading(true);
                    const { data } = await POST(endpoints.loaSearch + `?loaApplicationNos=${applicationNumber || loaDetailsList?.applicationNo}`, reqInfo);
                    setResData(data?.loaDetailsModelList[0]);

                    const tempDropdownOptions = data?.loaDetailsModelList?.[0]?.worksTenderDetails?.dprHeaderDetails?.map((val: any) => ({ name: val?.dprNo, code: val?.dprNo }));
                    setDropdowOptions({ dprNumber: tempDropdownOptions });

                    setContent(data?.loaDetailsModelList[0].content);
                    setRefId(data?.loaDetailsModelList[0]?.docReferenceId);
                    setIsLoading(false);
                    setFieldInitialValues(data?.loaDetailsModelList[0], tempDropdownOptions);
                } catch (e) {
                    console.log(e);
                    setIsLoading(false);
                }
            }
        })();
    }, [applicationNumber, loaDetailsList?.applicationNo, populateDropdown]);

    useEffect(() => {
        if (nextValidAction) {
            getCorrespondenceDocument();
        }
    }, [nextValidAction, applicationNumber, loaDetailsList?.applicationNo]);

    const commonApiCall = async (endpoint: string, payload: any) => {
        try {
            const { data } = await POST(endpoint, payload);
            return data;
        } catch (e: any) {
            let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message || 'Something Went Wrong';
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    console.log(values);
    console.log('formik>>', formik);

    // common update payload
    const payload = {
        loaDetails: {
            ...loaDetailsList,
            ...resData,
            bidPrice: values.bidPrice,
            bidAmountDeviation: values?.bidAmountDeviation,
            deviationPercentage: +values?.deviation,
            bidSubmissionDate: values?.bidSubmissionDate ? convertDateToDDmmYYYY(values?.bidSubmissionDate) : null, //ternary operartore
            perfGuaranteeAmount: values.perfGuaranteeAmount,
            perfGuaranteeValidityDate: values.perfGuaranteeValidityDate ? convertDateToDDmmYYYY(values.perfGuaranteeValidityDate) : null,
            docReferenceId: signedFileStoreId ? signedFileStoreId : resData?.docReferenceId,
            content: content,
            bidderDetails: {
                ...values,
                name: values.name,
                mobileNo: values.mobileNo,
                emailId: values.emailId,
                addressLine1: values.addressLine1,
                addressLine2: values.addressLine2 || '',
                district: values.district,
                state: values.state,
                country: values.country,
                pin: values.pincode
            },
            dprNo: values?.dprNumber?.code
        }
    };
    const onUpdateLoa = async (isEsignUpdate?: boolean) => {
        const updatePayload = {
            ...reqInfo,
            loaDetails: { ...payload.loaDetails, isTsDocUpdateRequired: isEsignUpdate ? isEsignUpdate : false }
        };
        try {
            await combinedValidationSchema.validate(values, { abortEarly: false, context: values });

            const response: any = await commonApiCall(endpoints.loaUpdate, updatePayload);
            if (response) {
                setResData(response?.loaDetailsModelList[0]);
                setContent(response?.loaDetailsModelList[0].content);
                if (response?.loaDetailsModelList[0].dprHeaderDetails) {
                    const initData = await setDprHeaderData(response?.loaDetailsModelList[0].dprHeaderDetails, populateDropdown);
                    setValues((prevVal: any) => ({
                        ...prevVal,
                        dprNumber: { name: response?.loaDetailsModelList[0].dprHeaderDetails?.dprNo, code: response?.loaDetailsModelList[0].dprHeaderDetails?.dprNo },
                        ...initData
                    }));
                }
                setRefId(response?.loaDetailsModelList[0]?.docReferenceId);
                setTouched({});
                isEsignUpdate && setESignTry(true);
                !isEsignUpdate && setModalDetails({ show: true, title: 'Success', para: 'Data Saved Successfully', img: check, type: 'save success', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setTouched({});
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const handlePrimaryAction = async (status: any, comment: string) => {
        const initiatePayload = {
            ...reqInfo,
            loaRequest: { ...payload },
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: 'ga',
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber ? applicationNumber : loaDetailsList?.applicationNo,
                    moduleName: nextValidAction?.moduleName,
                    action: action ? action : status?.action,
                    notes: values.notes || note,
                    comment: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean)
        };
        if (!values.notes && !note) {
            setModalDetails({ show: true, title: 'Warning!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        if ((!signedFileStoreId && status.isEsignRequired) || !success) {
            onUpdateLoa(status.isEsignRequired);
            setActionAfterEsign(status.action);
            setEsignModal(true);
            return;
        }
        try {
            await combinedValidationSchema.validate(values, { abortEarly: false, context: values });
            const response = await commonApiCall(endpoints.worksWorkflowInitiate, initiatePayload);
            if (response) {
                const successMessage = getSuccessMessage(action ? action : nextValidAction?.nextValidAction[0]?.action, applicationNumber);
                setTouched({});
                setModalDetails({ show: true, title: 'Success', para: successMessage, img: check, type: 'success', reUpload: 'OK' });
            }
        } catch (validationError) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setTouched({});
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    useEffect(() => {
        (async () => {
            if (applicationNumber || loaDetailsList?.applicationNo) {
                try {
                    setIsLoading(true);

                    const { data } = await POST(endpoints.dprValidActions + `?businessId=${applicationNumber || loaDetailsList?.applicationNo}&tenantId=ga`, {
                        RequestInfo: requestInfo()
                    });
                    setNextValidAction(data);
                    setFieldValue('isUpdatable', isViewOnly ? !isViewOnly : data?.isUpdatable);

                    const historyData = await getHistory(applicationNumber);
                    setHistoryDetails(historyData);
                    setIsLoading(false);
                } catch (e: any) {
                    setIsLoading(false);
                    let errMsg = e.response.data?.errorMessage || e.response.data?.Errors?.[0]?.message || t('SOMETHING_WENT_WRONG', 'Something Went Wrong');
                    setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
                }
            }
        })();
    }, [applicationNumber, loaDetailsList?.applicationNo]);

    const handleOnFileUpload = async (file: DocumentInfo) => {
        try {
            const docs: any =
                file.referenceId &&
                (await onAttachDocument(
                    applicationNumber ? applicationNumber : loaDetailsList?.applicationNo,
                    file?.referenceId,
                    designationName,
                    nextValidAction?.businessService,
                    historyDetails?.length ? historyDetails[0].id : null,
                    true
                ));
            setDocumentDetails(docs?.documents);
            setDocumentsUuid([...documentsUuid, docs?.uuid]);
            setActiveIndex(2);
        } catch (e) {}
    };

    useEffect(() => {
        const tableData = async () => {
            const responseTableData = documentDetails?.map((data: any, ind: number) => {
                return { ...data, serialNo: `C${documentDetails.length - ind}` };
            });
            setTabelData(responseTableData);
        };
        if (activeIndex === 2 || !!isViewOnly) {
            tableData();
        }
    }, [documentDetails, activeIndex]);

    const handleNextPage = async () => {
        try {
            const { data } = await POST(
                endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber || loaDetailsList?.applicationNo}&businessService=${nextValidAction.businessService}`,
                {
                    RequestInfo: requestInfo()
                }
            );
            setDocumentDetails(data?.documents);
        } catch (e) {
            console.log(e);
        }
        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };
    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };
    const primaryAction = () => {
        onUpdateLoa();
    };

    const onChangeAction = async (e: any, field: any) => {
        if (field === 'dprNumber') {
            const selectedObject = resData?.worksTenderDetails?.dprHeaderDetails?.find((val: any) => val.dprNo === e?.value);
            const initData = await setDprHeaderData(selectedObject, populateDropdown);
            setValues((prevVal: any) => ({ ...prevVal, dprNumber: e?.value ? { name: e?.value, code: e?.value } : '', ...initData }));
        }

        if (field === 'bidPrice') {
            let bidPrice = e.target.value;
            const perfGuaranteeAmount = (bidPrice * 0.05).toFixed(2).toString();
            const selectedObject = resData?.worksTenderDetails?.dprHeaderDetails?.find((val: any) => val.dprNo === values?.dprNumber?.code);

            // Update both bidPrice and perfGuaranteeAmount in one setValues call
            setValues((prevVal: any) => {
                const estimatedTotalCost = +selectedObject?.estimatedTotalCost;
                let bidAmountDeviation = 'NA';
                let deviation = 0;

                if (+bidPrice === 0) {
                    bidPrice = '';
                    bidAmountDeviation = '';
                    deviation = 0;
                } else if (estimatedTotalCost > +bidPrice) {
                    bidAmountDeviation = 'Below';
                    deviation = strToNum((((estimatedTotalCost - +bidPrice) / estimatedTotalCost) * 100).toFixed(2));
                } else if (estimatedTotalCost < +bidPrice) {
                    bidAmountDeviation = 'Above';
                    deviation = strToNum((((+bidPrice - estimatedTotalCost) / estimatedTotalCost) * 100).toFixed(2));
                }

                // Return the updated state
                return {
                    ...prevVal,
                    bidPrice: bidPrice,
                    perfGuaranteeAmount: values?.tenderType === 'NORMAL' ? perfGuaranteeAmount : '',
                    bidAmountDeviation: bidAmountDeviation,
                    deviation: deviation
                };
            });
        }
    };

    return (
        <>
            <HeaderComponent />
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                        <GenericHeader
                            text={isViewOnly ? t('LOA_VIEW_HEADING', 'Letter of Acceptance View') : t('LOA_HEADING', 'Letter of Acceptance')}
                            additionalClass='sub-text-style'
                            bottomSubText={`${isViewOnly ? '' : 'Type : LOA Application,'} No : ${applicationNumber ? applicationNumber : loaDetailsList?.applicationNo} `}
                        />

                        <FormikProvider value={formik}>
                            {!isViewOnly ? (
                                <>
                                    <div className='col-sm-3 mt-3 stickyCard-section '>
                                        <div className='stickyCard' style={{ top: '15%' }}>
                                            <GenericHeader
                                                text={t('LOA_HEADING', 'Letter of Acceptance')}
                                                sideHeader={true}
                                                additionalClass='sub-text-style'
                                                bottomSubText={`Type : LOA Application, No : ${applicationNumber ? applicationNumber : loaDetailsList?.applicationNo} `}
                                            />

                                            <NotingWrapper
                                                designation={designation}
                                                applicationNumber={applicationNumber}
                                                history={historyDetails}
                                                // disable={!nextValidAction?.nextValidAction?.length}
                                                handleOnFileUpload={handleOnFileUpload}
                                                nextValidAction={nextValidAction}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}

                            <div className={`${!isViewOnly ? 'col-sm-9' : 'col-sm-12'} mt-3 form-section `}>
                                <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} isViewOnly={isViewOnly} />
                                <Card className='card_border p-1'>
                                    <Form>
                                        {activeIndex === 1 && (
                                            <LoaCorrespondence
                                                content={content}
                                                setContent={setContent}
                                                loaRowData={loaRowData}
                                                t={t}
                                                loaDetailsList={loaDetailsList}
                                                readOnly={!nextValidAction?.isUpdatable || isViewOnly}
                                                modalDetails={modalDetails}
                                                setModalDetails={setModalDetails}
                                                setReminderPopUp={setReminderPopUp}
                                                isViewOnly={isViewOnly}
                                                onChangeAction={onChangeAction}
                                                onRowClick={onRowClick}
                                                tableData={tabelData}
                                                dropdownData={dropdownOptions}
                                                openSection={openSection}
                                                setOpenSection={setOpenSection}
                                            />
                                        )}
                                        {activeIndex === 2 && (
                                            <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tabelData} additionalMethod={onRowClick} />
                                        )}
                                        {activeIndex === 3 && (
                                            <GenericActionTable
                                                columns={CORRESPONDENCE_TABLE_APP_FORM}
                                                hideSearchBar={true}
                                                rowData={relatedApplicationDocument?.data.applicationInfos}
                                                additionalMethod={onRowClickRedirect}
                                            />
                                        )}
                                    </Form>
                                </Card>
                            </div>
                            {isViewOnly ? (
                                <></>
                            ) : (
                                <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                                    <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                        <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                                    </span>
                                    Page {activeIndex} of {totalPages}
                                    <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                        <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                                    </span>
                                </div>
                            )}
                            <GenericPopupModal
                                title={t('SIGN_THE_DOCUMENT', 'Sign the document')}
                                primaryBtnTitle={t('CONFIRM', 'Confirm')}
                                secondaryBtnTitle={t('CLOSE', 'Close')}
                                isSecondaryBtn={true}
                                secondaryAction={() => setEsignModal(false)}
                                primaryAction={signDocument}
                                isVisible={esignModal}
                                children={<EsignModal />}
                            />
                        </FormikProvider>
                    </div>
                    {!isViewOnly ? (
                        <>
                            <GenericFooterComponent
                                activeIndex={0}
                                stepperLength={0}
                                handleOnPrimaryAction={handlePrimaryAction}
                                onSubmit={() => {}}
                                nextValidAction={nextValidAction}
                                // extraButton={'Save'}
                                extraButton={applicationNumber ? false : 'Save'}
                                extraButtonAction={primaryAction}
                            />
                        </>
                    ) : (
                        <></>
                    )}

                    {modalData && isFilePreviewModal && (
                        <GenericPopupModal
                            title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                            primaryAction={() => {}}
                            secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                            isVisible={isFilePreviewModal}
                            isPrimaryBtn={false}
                            isSecondaryBtn={false}
                            size={'xl'}
                            class='file-viewer-popup'
                        >
                            <div className='popup-children'>
                                {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                                {!isPDF && <GenericImageViewer />}
                            </div>
                        </GenericPopupModal>
                    )}
                    <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                    <GenericPopupModal
                        secondaryAction={() => {
                            setReminderPopUp(false);
                        }}
                        primaryAction={() => {
                            setReminderPopUp(false);
                            primaryAction();
                        }}
                        isVisible={reminderPopUp}
                        title='Save Confirmation'
                        size='md'
                        children={
                            <div>
                                <div className='mx-4 my-3'>
                                    <div className='mt-4'>
                                        <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                            {t('SAVE_CHANGES', 'Looks like you have some unsaved changes. Would you like to save them before proceeding with the new data?')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        primaryBtnTitle='Yes'
                        secondaryBtnTitle='No'
                        // onClose={() => setReminderPopUp(false)}
                    />
                    {/* <StatusAlertPopup
                        title={modalDetails.title}
                        para={modalDetails.para}
                        img={modalDetails.img}
                        show={modalDetails.show}
                        handleClose={() => handleModalClose(modalDetails.type)}
                        type={modalDetails.type}
                        reUpload={modalDetails.reUpload}
                        handleSubmit={() => handleModalClose(modalDetails.type)}
                    /> */}
                </>
            )}
        </>
    );
};

export default LoaWorkflow;
