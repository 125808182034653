import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import {
    MEASUREMENT_ENTRY_FIELD,
    measurmentInitialField,
    MOP_CORRESPONDENCE,
    POPUP_FIELDS,
    RA_BILL_TYPE_FIELDS,
    RABILL_CORRESPONDENCE,
    RABILL_MEASURMENT_COLUMN,
    ROYALTY_FIELDS
} from './RaBill.const';
import { FieldArray, useFormikContext } from 'formik';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { CONTRACTOR_DETAILS, getDocumentDetails, HEADER_FIELDS_II } from '../ContractCreationScreens/ContractCreation.const';
import { CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { ABSTRACT_TABLE_COLUMNS, DPR_MEASUREMENT, HEADER_FIELDS, MEASUREMENT_DETAILS, PENDING_TABLE_COLUMNS } from '../DPR creation screen/DPR.const';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { OwnershipTable } from '../../UserManagement/EnterpriseModule/OwnershipTable';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import { convertToINR } from '../../EstateManagement/EstateManagement.controller';
import DeleteIcon from '../../../../assets/logos/delete.svg';
import _ from 'lodash';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { convertDateToDDmmYYYY, getFirstErrorKey } from '../../../../utils/utils';
import Editor from '../../../../components/ContentEditor';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import { MB_AND_BILL_MEASUREMENT_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { check, cross, DeleteWarning } from '../../../../utils/Logo';
import DeletePopUpModal from '../../../../components/moduleCommonFormik/DeletePopUp.component';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import { MOPSection } from './MOPSection';

const RunningAccountBillCorrespondence = (props: any) => {
    const {
        abstractData,
        dropdownData,
        t,
        newMeasurmentData,
        raBillRowData,
        setRaBillRowData,
        applicationNumber,
        searchRaBill,
        setReminderPopUp,
        setContent,
        content,
        addPopUp,
        setAddPopUp,
        setModalDetails,
        isViewOnly,
        tableData,
        onRowClick,
        isUpdatable,
        reclaimableRowData,
        setReclaimableRowData,
        taxRowData,
        setTaxRowData,
        previousReclaimableRowData,
        setPreviousReclaimableRowData,
        mopContent,
        setMopContent,
        setIsLoading,
        billNo,
        resData
    } = props;
    const [openSection, setOpenSection] = useState<string>('headerDetails');
    const [isEditing, setIsEditing] = useState<any>(false);
    const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [dprItemId, setDprItemId] = useState<any>(null);
    const [updateCall, setUpdateCall] = useState<boolean>(false);
    const [raItem, setRaItem] = useState<any>({});

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [rowDelData, setRowDelData] = useState<any>(null);

    const { values, setValues, setTouched, touched, setFieldTouched, errors, setFieldValue } = useFormikContext<any>();

    const onSectionChange = async (currentSection: string) => {
        if ((currentSection === 'notes' || currentSection === 'MOPNotes') && values.isUpdatable && Object.keys(touched).length) {
            setReminderPopUp(true);
        }

        let previousSection = openSection;
        if (previousSection === currentSection) setOpenSection('');
        else {
            setOpenSection(currentSection);
        }
    };

    useEffect(() => {
        const totalQuantity = values.measurementEntry.reduce((sum: number, entry: any) => {
            if (!entry.refRaBillId) {
                return sum + (+entry?.itemQuantity || 0);
            } else {
                return sum;
            }
        }, 0);

        if (totalQuantity === values.sincePreviousQuantity) {
            return;
        }
        const sincePreviousQuantity = +totalQuantity.toFixed(2);
        let upToDateQuantity = 0;

        if (isEditing) {
            const adjustedInitialUpToDateQuantity = (+values.upToDateQuantity || 0) - (+values.sincePreviousQuantity || 0);
            upToDateQuantity = sincePreviousQuantity + adjustedInitialUpToDateQuantity;
        } else {
            upToDateQuantity = sincePreviousQuantity + +(+values.intialUpToDateQuantity || 0).toFixed(2);
        }
        const balanceQuantity = +((+values.estimatedQuantity || 0) - upToDateQuantity).toFixed(2);
        const measuredValue = +((+values.mvUnitRate || 0) * upToDateQuantity).toFixed(2);

        const updatedValues = {
            sincePreviousQuantity,
            upToDateQuantity,
            balanceQuantity,
            measuredValue
        };

        setValues((prev: any) => ({ ...prev, ...updatedValues }));
    }, [values.measurementEntry]);

    const renderHeaderDetails = () => {
        const tempDprDetails = HEADER_FIELDS.map((val: any) => {
            return {
                ...val,
                formData: [
                    ...val.formData.map((value: any) => {
                        return { ...value, isDisabled: true, isRequired: false };
                    })
                ]
            };
        });

        const dprDetails = !isViewOnly
            ? tempDprDetails
            : tempDprDetails.map((val: any) => ({
                  ...val,
                  formData: val.formData?.map((item: any) => {
                      if (item.field !== 'projectName') {
                          return { ...item, class: 'col-sm-4' };
                      } else {
                          return { ...item };
                      }
                  })
              }));

        const tempHeaderFieldsII = HEADER_FIELDS_II.map((val: any) => {
            return {
                ...val,
                formData: [
                    ...val.formData.map((value: any) => {
                        return { ...value, isDisabled: true, isRequired: false };
                    }),
                    {
                        titleCode: 'SIGNED_COPY_OF_CONTRACT',
                        title: 'Signed copy of Contract',
                        field: 'signedCopyofContract',
                        type: CONSTANT_DATA_FIELD.FILE_FIELD,
                        // isRequired: true,
                        entityFileData: {
                            documentNamePrefix: 'Signed copy of Contract',
                            documentType: ''
                        },
                        isDisabled: true
                    }
                ]
            };
        });

        const HeaderFieldsII = !isViewOnly ? tempHeaderFieldsII : tempHeaderFieldsII.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));

        return (
            <>
                <Card.Body>
                    {dprDetails.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                    {HeaderFieldsII.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                </Card.Body>
            </>
        );
    };

    const renderContractorDetails = () => {
        const tempContractorFields = CONTRACTOR_DETAILS.map((val: any) => {
            return {
                ...val,
                formData: val.formData.map((value: any) => {
                    return { ...value, isDisabled: true, isRequired: false };
                })
            };
        });

        const contractorFields = !isViewOnly ? tempContractorFields : tempContractorFields.map((val: any) => ({ ...val, formData: val.formData.map((item: any) => ({ ...item, class: 'col-sm-4' })) }));
        return (
            <>
                <div className='px-3'>
                    {contractorFields.map((val: any, id: number) => (
                        <GenericFormCard {...val} />
                    ))}
                </div>
            </>
        );
    };

    const renderAbstractCost = (add?: boolean, handleAddingData?: any, hideTotalCost?: boolean, newRowData?: any) => {
        const measurementFields = MEASUREMENT_DETAILS.map((val: any) => ({
            ...val,
            formData: [
                ...val.formData.map((value: any) => ({
                    ...value,
                    isDisabled: true
                }))
            ]
        }));
        const updatedFields = DPR_MEASUREMENT.map((val: any) => ({
            ...val,
            formData: [
                ...val.formData.map((value: any) => ({
                    ...value,
                    isDisabled: true,
                    isRequired: false
                }))
            ]
        }));

        const onMeasurementDataEdit = async (data: any, status: boolean = false) => {
            const rowData = newRowData ? newRowData : abstractData;
            const detailObj: any = rowData.find((item: any, _: number) => item.rowId === data.rowId);
            const totalQuant = detailObj?.dprItemMeasurementDetailsModelList || detailObj?.dprItemDetails?.dprItemMeasurementDetailsModelList;
            const totalQuantity = totalQuant
                ?.map((val: any) => val.quantity)
                .reduce((totalQuantity: any, quantity: any) => {
                    totalQuantity += quantity;
                    return totalQuantity;
                }, 0);
            console.log(totalQuantity);
            if (!detailObj) return;
            const formData = {
                ...detailObj,
                lbdOrNonlbd: detailObj?.lbdMeasurementType || detailObj?.dprItemDetails?.lbdMeasurementType,
                gsrMarketRate: detailObj?.gsrMasterDetailsModel || detailObj?.dprItemDetails?.gsrMasterDetailsModel ? 'GSR' : 'MarketRate',
                gsrYear: detailObj?.gsrMasterDetailsModel?.gsrYear || detailObj?.dprItemDetails?.gsrMasterDetailsModel?.gsrYear,
                measurementType: getDropdownSelectedObject(detailObj?.measurementType || detailObj?.dprItemDetails?.measurementType, dropdownData['measurementType']),
                gsrItemNo: { code: detailObj?.gsrItemNo, name: detailObj?.gsrItemNo },
                gsrName: {
                    code: detailObj?.gsrMasterDetailsModel?.gsrName || detailObj.dprItemDetails?.gsrMasterDetailsModel?.gsrName,
                    name: detailObj?.gsrMasterDetailsModel?.gsrName || detailObj.dprItemDetails?.gsrMasterDetailsModel?.gsrName
                },
                itemDesc: detailObj?.itemDesc,
                gsrUuid: detailObj?.gsrUuid,
                rowId: detailObj.rowId || detailObj.uuid || null,
                itemType: detailObj?.itemType,
                mvUnitRate: detailObj?.unitRate,
                gsrUnitRate: detailObj?.gsrMasterDetailsModel?.gsrUnitRate || detailObj?.dprItemDetails?.gsrMasterDetailsModel?.gsrUnitRate,
                itemNo: detailObj?.itemNo,
                unitOfMeasurement: detailObj?.unitOfMeasurement || detailObj?.dprItemDetails?.unitOfMeasurement,
                measurements: detailObj?.dprItemMeasurementDetailsModelList || detailObj?.dprItemDetails?.dprItemMeasurementDetailsModelList,
                document: detailObj?.document || detailObj?.dprItemDetails?.document,
                remarks: detailObj?.remarks || detailObj?.dprItemDetails?.remarks,
                totalQuantity: totalQuantity
            };
            console.log(formData);
            setIsEditing(detailObj.rowId || detailObj.uuid);
            setValues({ ...values, ...formData });
            setShow(true);
        };

        const onMeasurementView = (status: boolean, data: any) => {
            onMeasurementDataEdit(data, status);
        };
        const updatedColumns = [
            ...ABSTRACT_TABLE_COLUMNS,
            {
                colName: 'Action',
                type: 'action',
                clsName: 'fixed-action-column fixed-action-column-bg',
                actions: add ? ['add', 'view'] : ['view']
            }
        ];

        const updatedColumnsForPending = [
            ...PENDING_TABLE_COLUMNS,
            {
                colName: 'Action',
                type: 'action',
                clsName: 'fixed-action-column fixed-action-column-bg',
                actions: add ? ['add', 'view'] : ['view']
            }
        ];

        return (
            <>
                <Card.Body className='px-3 pt-4'>
                    <OwnershipTable
                        column={newRowData ? updatedColumnsForPending : updatedColumns}
                        hideSearchBar={true}
                        tableRowData={newRowData ? newRowData : abstractData}
                        totalRow={'cost'}
                        totalRowText={t('TOTAL_COST_A', 'Total Cost (A) ')}
                        updateOwnershipDataStatus={onMeasurementView}
                        addOwnershipDetails={handleAddingData}
                        hideTotalCost={hideTotalCost}
                    />
                </Card.Body>

                <GenericPopupModal
                    isVisible={show}
                    primaryAction={() => {}}
                    secondaryAction={() => {
                        setShow(false);
                    }}
                    size={'lg'}
                    secondaryBtnTitle={t('CLOSE', 'Close')}
                    isPrimaryBtn={false}
                    title={'View Abstract cost'}
                    children={
                        <>
                            {measurementFields?.map((val: any) => {
                                return (
                                    <div style={{ padding: '10px 16px' }}>
                                        <GenericFormCard {...val} dropdownOptions={dropdownData} />
                                        <Form>
                                            <FieldArray name='measurements'>
                                                {({ remove, push }) => (
                                                    <div>
                                                        {values.measurements?.length > 0 &&
                                                            values.measurements.map((data: any, index_p: number) => (
                                                                <div className='row' key={index_p}>
                                                                    <div className='col-sm-12 collapsible_box align-items-center pb-2'>
                                                                        <input
                                                                            type='checkbox'
                                                                            id={`measurements.${index_p}`}
                                                                            name={`measurements.${index_p}`}
                                                                            defaultChecked={true}
                                                                            disabled={fieldDisabled}
                                                                        />
                                                                        <label
                                                                            htmlFor={`measurements.${index_p}`}
                                                                            className='d-flex mx-3 collapsible_box_label align-items-center justify-content-between'
                                                                        >
                                                                            New Measurement - {index_p + 1}
                                                                        </label>
                                                                        <div className='form_fields ps-1'>
                                                                            {updatedFields.map((val: any, _: number) => (
                                                                                <GenericFormCard fieldPath={`measurements.${index_p}`} {...val} />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                )}
                                            </FieldArray>
                                            <div className='py-3 mt-1 rounded-2 pe-3  ' style={{ background: '#F4F4F4' }}>
                                                <div className='me-5 d-flex justify-content-end '>
                                                    <div className='font-16 lh-21 text-dark-emphasis '>{t('TOTAL_QUANTITY', `Total Quantity`)}</div>
                                                    <div className='font-16 lh-21 text-dark-emphasis ms-5'>:</div>
                                                    <div className='font-16 lh-21 text-dark-emphasis ms-5 '>{values.totalQuantity ? convertToINR(values.totalQuantity) : 0}</div>
                                                </div>
                                                <div className='me-5 d-flex justify-content-end '>
                                                    <div className='font-16 lh-21 fw-500 text-dark-emphasis '>{t('ROUND_OFF_TOTAL_QUANTITY', `Round off Total Quantity`)}</div>
                                                    <div className='font-16 lh-21 fw-500 text-dark-emphasis ms-5'>:</div>
                                                    <div className='font-16 lh-21 fw-500 text-dark-emphasis ms-5 '>{values.totalQuantity ? convertToINR(Math.ceil(values.totalQuantity)) : 0}</div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                );
                            })}
                        </>
                    }
                />
            </>
        );
    };
    const renderRaBill = () => {
        const handleAddingData = (row: any, rowData: any) => {
            const val = _.cloneDeep(values);

            setIsEditing(false);
            const keysToDelete = [
                'itemNo',
                'itemDesc',
                'lbdOrNonlbd',
                'type',
                'mvUnitRate',
                'estimatedQuantity',
                'upToDateQuantity',
                'intialUpToDateQuantity',
                'sincePreviousQuantity',
                'balanceQuantity',
                'measuredValue',
                'unitOfMeasurement'
            ];
            keysToDelete.forEach(key => {
                delete val[key];
            });
            const rowObject = raBillRowData?.find((val: any) => val.rowId === row);

            setDprItemId(rowObject.dprItemId);

            const RaBillValue = {
                itemNo: rowObject?.dprItemDetails?.itemOrderNo,
                itemDesc: rowObject?.dprItemDetails?.itemDesc,
                lbdOrNonlbd: rowObject?.dprItemDetails?.lbdMeasurementType,
                mvUnitRate: rowObject?.dprItemDetails?.unitRate,
                type: getDropdownSelectedObject(rowObject?.dprItemDetails?.measurementType, dropdownData['measurementType']),
                estimatedQuantity: rowObject?.dprItemDetails?.totalQuantity,
                unitOfMeasurement: rowObject?.dprItemDetails?.unitOfMeasurement,
                upToDateQuantity: rowObject?.upToDateQuantity,
                intialUpToDateQuantity: rowObject?.upToDateQuantity
            };
            setValues({ ...val, ...RaBillValue, measurementEntry: [_.cloneDeep(measurmentInitialField)] });

            setAddPopUp(true);
        };

        const onChangeAction = (e: any, fieldPath: string, setFieldValue: any, values: any) => {
            if (values?.lbdOrNonlbd !== 'Non-LBD') {
                const value = e?.target?.value ?? e;
                const [parentField, index, fieldName] = fieldPath.split('.');
                if (fieldName === 'measurementDate') {
                    return;
                }
                setFieldValue(fieldPath, value);

                const currentLength: any = Number(values.measurementEntry[index]?.lengthInMeters) || null;
                const currentBreadth: any = Number(values.measurementEntry[index]?.breadthInMeters) || null;
                const currentDepth: any = Number(values.measurementEntry[index]?.depthInMeters) || null;
                const currentItemNos: any = Number(values.measurementEntry[index]?.itemNos) || null;

                const updatedLength = fieldName === 'lengthInMeters' ? Number(value) : currentLength;
                const updatedBreadth = fieldName === 'breadthInMeters' ? Number(value) : currentBreadth;
                const updatedDepth = fieldName === 'depthInMeters' ? Number(value) : currentDepth;
                const updatedItemNos = fieldName === 'itemNos' ? Number(value) : currentItemNos;

                // const calculatedQuantity = updatedLength * updatedBreadth * updatedDepth * updatedItemNos;
                const calculatedQuantity =
                    (updatedLength !== null ? updatedLength : 1) *
                    (updatedBreadth !== null ? updatedBreadth : 1) *
                    (updatedDepth !== null ? updatedDepth : 1) *
                    (updatedItemNos !== null ? updatedItemNos : 1);
                setFieldValue(`measurementEntry.${index}.itemQuantity`, +calculatedQuantity.toFixed(2) || 0);
            }
        };

        const MeasuredEntryEdit = async (data: any, status: boolean = false) => {
            const rowObject: any = newMeasurmentData.find((item: any, _: number) => item.rowId === data.rowId);
            setRaItem(rowObject);
            const raBillItemMeasurementDetails = await Promise.all(
                rowObject?.raBillItemMeasurementDetails?.map(async (val: any) => {
                    const doc = val?.docReferenceId && (await getDocumentDetails(val?.docReferenceId, DOCUMENT_TYPE.OTHER));
                    return {
                        ...val,
                        document: doc
                    };
                })
            );
            setUpdateCall(true);
            const fieldInitval = {
                itemNo: rowObject?.dprItemDetails?.itemOrderNo,
                itemDesc: rowObject?.dprItemDetails?.itemDesc,
                lbdOrNonlbd: rowObject?.dprItemDetails?.lbdMeasurementType,
                mvUnitRate: rowObject?.dprItemDetails?.unitRate,
                unitOfMeasurement: rowObject?.dprItemDetails?.unitOfMeasurement,
                type: getDropdownSelectedObject(rowObject?.dprItemDetails?.measurementType, dropdownData['measurementType']),
                estimatedQuantity: rowObject?.dprItemDetails.totalQuantity,
                upToDateQuantity: rowObject?.upToDateQuantity,
                sincePreviousQuantity: rowObject.sincePreviousQuantity,
                intialUpToDateQuantity: rowObject?.upToDateQuantity,
                measurementEntry: raBillItemMeasurementDetails,
                measuredValue: rowObject?.measuredValue,
                balanceQuantity: rowObject?.balanceQuantity,
                rowId: rowObject.rowId
            };

            setValues((prev: any) => ({ ...prev, ...fieldInitval }));
            setIsEditing(true);
            setAddPopUp(true);
        };

        const onMeasuredEntryView = (status: boolean, data: any) => {
            MeasuredEntryEdit(data, status);
            setFieldDisabled(status);
        };

        const onMeasuredItemDelete = async (rowId: any, rowData: any) => {
            setRowDelData(rowData);
            setShowDeleteModal(true);
        };

        const handleModalDelete = async (rowData: any) => {
            const payload = {
                RequestInfo: requestInfo(),
                raBillItemDetails: rowData,
                isDelete: true
            };
            try {
                const { data } = await POST(endpoints.updateRaBillItem, payload);
                if (data) {
                    setShowDeleteModal(false);
                    setModalDetails({ show: true, title: 'Success!', para: t('ITEM_DELETED', 'Item Deleted Successfully'), img: check, type: 'delete', reUpload: 'OK' });
                    searchRaBill();
                }
            } catch (e) {
                setModalDetails({ show: true, title: 'Error!', para: t('SOMETHING_WENT_WRONG', 'Something went wrong'), img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        const handlePrimaryAction = async () => {
            const measurementEntry = values.measurementEntry.map((val: any) => {
                return {
                    ...val,
                    docReferenceId: val?.document?.docReferenceId,
                    measurementDate: convertDateToDDmmYYYY(val.measurementDate)
                };
            });

            if (+values.balanceQuantity < 0) {
                setModalDetails({
                    show: true,
                    title: 'Error!',
                    para: t('MEASURED_QUANTITY_SHOULD_NOT_EXCCED_ESTIMATED_QUANTITY', 'Measured Quantity should not exceed Estimated Quantity!'),
                    img: cross,
                    type: 'error',
                    reUpload: 'OK'
                });
                return;
            }

            const payload = {
                RequestInfo: requestInfo(),
                raBillItemDetails: {
                    ...(updateCall ? raItem : {}),
                    raBillApplicationNo: applicationNumber,
                    dprItemId: dprItemId ? dprItemId : raItem.dprItemId,
                    measuredValue: +values.measuredValue,
                    balanceQuantity: +values.balanceQuantity,
                    sincePreviousQuantity: +values.sincePreviousQuantity,
                    raBillItemMeasurementDetails: measurementEntry
                }
            };
            try {
                await MB_AND_BILL_MEASUREMENT_VALIDATION.validate(values, { abortEarly: false, context: values });

                try {
                    setIsLoading(true);
                    let data: any;
                    if (!updateCall) {
                        data = await POST(endpoints.createRaBillItem, payload);
                    } else {
                        data = await POST(endpoints.updateRaBillItem, payload);
                    }
                    if (data) {
                        console.log(data);
                        setAddPopUp(false);
                        searchRaBill();
                    }
                    setIsLoading(false);
                } catch (e) {
                    setIsLoading(false);
                }
            } catch (validationError) {
                setIsLoading(false);

                let errorField = getFirstErrorKey(errors);
                if (errorField) {
                    const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                    await setFieldTouched(errorField, true, true);
                    let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                    getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
                return;
            }
        };
        const measuredEntryFields = fieldDisabled
            ? MEASUREMENT_ENTRY_FIELD.map((val: any) => {
                  return {
                      ...val,
                      formData: val.formData.map((value: any) => ({ ...value, isDisabled: true }))
                  };
              })
            : MEASUREMENT_ENTRY_FIELD;

        const UPDATED_RABILL_MEASURMENT_COLUMN = RABILL_MEASURMENT_COLUMN.map((val: any) => {
            if (val?.type === 'action') {
                return {
                    ...val,
                    actions: isUpdatable ? ['edit', 'view', 'delete'] : ['view']
                };
            }
            return val;
        });

        const classnames = 'font-14 lh-21 fw-500 text-dark-emphasis mb-1';
        return (
            <>
                <div className='px-3'>
                    {RA_BILL_TYPE_FIELDS.map((val: any) => {
                        return (
                            <>
                                <GenericFormCard {...val} dropdownOptions={dropdownData} />
                            </>
                        );
                    })}
                </div>
                {renderAbstractCost(isUpdatable, handleAddingData, true, raBillRowData)}
                <div className='row px-4'>
                    <div className='font-18 lh-29 text-secondary-emphasis fw-semibold'>Measured Items</div>
                    <OwnershipTable
                        column={UPDATED_RABILL_MEASURMENT_COLUMN}
                        tableRowData={newMeasurmentData}
                        searchBarPlaceholder={'Search'}
                        hideExportBtn={true}
                        updateOwnershipDataStatus={onMeasuredEntryView}
                        additionalMethod={MeasuredEntryEdit}
                        deleteOwnershipDetails={onMeasuredItemDelete}
                        content={
                            <>
                                <div className='py-3 mt-3 rounded-2 pe-3 d-flex flex-column align-items-end' style={{ background: '#F4F4F4' }}>
                                    <div className='me-1 w-50 d-flex justify-content-between '>
                                        <div className={`${classnames}`}>Total Measured Value (A)</div>
                                        <div className={`${classnames} d-flex justify-content-between ms-5 w-25`}>
                                            <span>:</span>
                                            {convertToINR(values.totalMeasuredValueBeforeAdjustment)}
                                        </div>
                                    </div>
                                    <div className='me-1 w-50 d-flex justify-content-between '>
                                        <div className={`${classnames}`}>{`Contractors Quote ${
                                            resData.contractDetails?.workOrderDetails?.loaDetails?.bidAmountDeviation === 'Above' ? 'above' : 'below'
                                        } (${resData?.contractDetails?.workOrderDetails?.loaDetails?.deviationPercentage || 0}%) (B)`}</div>
                                        <div className={`${classnames} d-flex justify-content-between ms-5 w-25`}>
                                            <span>:</span>
                                            {convertToINR(values.contractorQuoteDeviationAmount)}
                                        </div>
                                    </div>

                                    <div className='me-1 w-50 d-flex justify-content-between '>
                                        <div className={`${classnames}`}>Sub Total (C=A+B)</div>
                                        <div className={`${classnames} d-flex justify-content-between ms-5 w-25`}>
                                            <span>:</span>
                                            {convertToINR(values.subTotal)}
                                        </div>
                                    </div>

                                    <div className='me-1 w-50 d-flex justify-content-between '>
                                        <div className={`${classnames}`}>{`Less Rebate(${resData.contractDetails?.rebatePercentage || 0}%) (D)`}</div>
                                        <div className={`${classnames} d-flex justify-content-between ms-5 w-25`}>
                                            <span>:</span>
                                            {convertToINR(values.lessRebateAmount)}
                                        </div>
                                    </div>
                                    {values.previousBillTotaMeasuredValue ? (
                                        <>
                                            <div className='me-1 w-50 d-flex justify-content-between '>
                                                <div className={`${classnames}`}>Sub Total (E=C-D)</div>
                                                <div className={`${classnames} d-flex justify-content-between ms-5 w-25`}>
                                                    <span>:</span>
                                                    {convertToINR(+values.subTotal - +values.lessRebateAmount)}
                                                </div>
                                            </div>

                                            <div className='me-1 w-50 d-flex justify-content-between '>
                                                <div className={`${classnames}`}>{`Less Value of work on R.A. Bill No.${+billNo - 1} (F)`}</div>
                                                <div className={`${classnames} d-flex justify-content-between ms-5 w-25`}>
                                                    <span>:</span>
                                                    {convertToINR(values.previousBillTotaMeasuredValue)}
                                                </div>
                                            </div>

                                            <div className='me-1 w-50 d-flex justify-content-between '>
                                                <div className={`${classnames}`}>Total Amount (G=E-F)</div>
                                                <div className={`${classnames} d-flex justify-content-between ms-5 w-25`}>
                                                    <span>:</span>
                                                    {convertToINR(+values.totalMeasuredValue - +values.previousBillTotaMeasuredValue)}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className='me-1 w-50 d-flex justify-content-between '>
                                            <div className={`${classnames}`}>Total Amount (E=C-D)</div>
                                            <div className={`${classnames} d-flex justify-content-between ms-5 w-25`}>
                                                <span>:</span>
                                                {convertToINR(values.totalMeasuredValue)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        }
                    />
                </div>
                <GenericPopupModal
                    isVisible={addPopUp}
                    primaryAction={handlePrimaryAction}
                    secondaryAction={() => {
                        setAddPopUp(false);
                        setFieldDisabled(false);
                        setUpdateCall(false);
                        setIsEditing(false);
                    }}
                    size={'lg'}
                    secondaryBtnTitle={t('CLOSE', 'Close')}
                    primaryBtnTitle='Save'
                    isPrimaryBtn={!fieldDisabled}
                    title={'Measurement'}
                    children={
                        <>
                            {POPUP_FIELDS.map((val: any) => {
                                return (
                                    <>
                                        <GenericFormCard {...val} styles={{ background: '#F8F8F8', padding: '10px 16px' }} />
                                    </>
                                );
                            })}

                            <div style={{ background: '#F5F5F6', borderRadius: '0px', padding: '0px 16px' }}>
                                <form>
                                    <FieldArray name='measurementEntry'>
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {values.measurementEntry?.length > 0 &&
                                                    values.measurementEntry.map((data: any, index_p: number) => {
                                                        return (
                                                            <div className='row' key={index_p}>
                                                                <div className='col-sm-12 collapsible_box align-items-center pb-2'>
                                                                    <input type='checkbox' id={`measurementEntry.${index_p}`} name={`measurementEntry.${index_p}`} defaultChecked={true} />
                                                                    <label
                                                                        htmlFor={`measurementEntry.${index_p}`}
                                                                        className='d-flex mx-3 collapsible_box_label align-items-center justify-content-between'
                                                                    >
                                                                        Measurement Entry - {index_p + 1}
                                                                    </label>
                                                                    <div className='form_fields ps-1' style={{ overflow: 'visible' }}>
                                                                        {(values?.measurementEntry?.[index_p]?.refRaBillId
                                                                            ? !values?.measurementEntry?.[index_p]?.refRaBillId
                                                                            : index_p !== 0 && !fieldDisabled) && (
                                                                            <div className='d-flex justify-content-end mx-3'>
                                                                                <img style={{ cursor: 'pointer', zIndex: 1 }} onClick={() => remove(index_p)} src={DeleteIcon} alt='delete' />
                                                                            </div>
                                                                        )}
                                                                        {measuredEntryFields.map((val: any, _: number) => (
                                                                            <GenericFormCard
                                                                                fieldPath={`measurementEntry.${index_p}`}
                                                                                {...val}
                                                                                dropdownOptions={dropdownData}
                                                                                onChangeAction={(e: any, fieldPath: string) => onChangeAction(e, fieldPath, setFieldValue, values)}
                                                                                styles={{ background: '#F5F5F6', borderRadius: '0px' }}
                                                                                index={index_p}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                {!fieldDisabled && (
                                                    <div className='ms-3'>
                                                        <button
                                                            className='col-sm-12 mt-2 mb-4  justify-content-center align-items-center noc_add_fields position-relative'
                                                            style={{ zIndex: '0' }}
                                                            type='button'
                                                            onClick={() => {
                                                                push(measurmentInitialField);
                                                            }}
                                                        >
                                                            + Add Measurement
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </FieldArray>
                                </form>
                            </div>
                        </>
                    }
                />

                <DeletePopUpModal
                    secondaryAction={() => {
                        setShowDeleteModal(false);
                    }}
                    primaryAction={() => {
                        handleModalDelete(rowDelData);
                    }}
                    isVisible={showDeleteModal}
                    title={'Delete File?'}
                    img={DeleteWarning}
                    primaryBtnTitle={t('DELETE', 'Delete')}
                    secondaryBtnTitle={t('CANCEL', 'Cancel')}
                    children={
                        <>
                            <div className='p-4' style={{ backgroundColor: '#F5F5F6' }}>
                                <div className='font-inter fw-bold lh-22'>
                                    Are you sure you want to delete this file? <br />
                                    You can't undo this action!
                                </div>
                            </div>
                        </>
                    }
                />
            </>
        );
    };

    const renderTechnicalSanctionDetails = () => {
        return (
            <>
                <Form.Group>
                    <div className='col-lg-12 my-1 px-4 py-4'>
                        <Editor setContent={setContent} content={content} readOnly={!values.isUpdatable} />
                    </div>
                </Form.Group>
            </>
        );
    };
    console.log(values);

    const renderroyaltyOnMinerals = () => {
        const handleChange = (e: any, field: any) => {
            if (field === 'alreadyPaidRoyaltyAmount') {
                setFieldValue('adjustedRoyaltyAmount', +values.totalRoyaltyOnMinerals - +e.target.value);
            }
            if (field === 'totalRoyaltyOnMinerals') {
                setFieldValue('adjustedRoyaltyAmount', +e.target.value - +values.alreadyPaidRoyaltyAmount);
            }
        };

        const newRoyaltyFields =
            resData.status !== 'PENDING' && values.isUpdatable
                ? ROYALTY_FIELDS.map((item: any) => ({ ...item, formData: item.formData.map((subItem: any) => ({ ...subItem, isDisabled: true })) }))
                : ROYALTY_FIELDS;

        return (
            <>
                <div className='px-3'>
                    {newRoyaltyFields.map((val: any, id: number) => (
                        <GenericFormCard {...val} onChangeAction={handleChange} />
                    ))}
                </div>
            </>
        );
    };

    const renderDocuments = () => {
        return <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tableData} additionalMethod={onRowClick} />;
    };

    const renderMOPNotes = () => {
        return (
            <>
                <Form.Group>
                    <div className='col-lg-12 my-1 px-4 py-4'>
                        <Editor setContent={setMopContent} content={mopContent} readOnly={!values.isUpdatable} />
                    </div>
                </Form.Group>
            </>
        );
    };

    const filteredCorrespondence = RABILL_CORRESPONDENCE?.filter((val: any) => val.field !== 'notes');
    const updatedRaBillCorrespondence = !isViewOnly
        ? resData?.status !== 'PENDING'
            ? [...RABILL_CORRESPONDENCE, ...MOP_CORRESPONDENCE]
            : RABILL_CORRESPONDENCE
        : resData?.status !== 'PENDING'
        ? [
              ...filteredCorrespondence,
              {
                  title: '(F) Memorandum of Payment',
                  field: 'MOP'
              },
              { title: '(G) Documents', field: 'documents' }
          ]
        : [...filteredCorrespondence, { title: '(F) Documents', field: 'documents' }];

    return (
        <>
            <Card className='checklist-outer mb-4 mx-3'>
                <div className='row my-3 px-3 py-2 '>
                    {updatedRaBillCorrespondence.map((val: any, index: number) => {
                        return (
                            <div className={`col-sm-12 collapsible_box noting-collapsible-box align-items-center py-3 px-0`}>
                                <input type='checkbox' id={`${val.field}Form`} name={`${val.field}Form`} checked={openSection === val.field} onChange={() => onSectionChange(val.field)} />
                                <label
                                    htmlFor={`${val.field}Form`}
                                    className='px-4 d-flex collapsible-box-label col-sm-12 checklist-heading noting-collapsible-box-label align-items-center justify-content-between'
                                >
                                    {val.title}
                                </label>
                                {val.title === '(A) Header' && openSection === 'headerDetails' && renderHeaderDetails()}
                                {val.title === '(B) Abstract Cost' && openSection === 'abstractCost' && renderAbstractCost()}
                                {val.title === '(C) Contractor Details' && openSection === 'contractorDetails' && renderContractorDetails()}
                                {val.title === '(D) MB & Bill' && openSection === 'mbAndBill' && renderRaBill()}

                                {val.title === '(E) Royalty On Minerals' && openSection === 'royaltyOnMinerals' && renderroyaltyOnMinerals()}
                                {val.title === '(F) Bill Notes' && openSection === 'notes' && renderTechnicalSanctionDetails()}
                                {(val.title === '(F) Documents' || val.title === '(G) Documents') && openSection === 'documents' && renderDocuments()}

                                {(val.title === '(G) Memorandum of Payment' || val.title === '(F) Memorandum of Payment') && openSection === 'MOP' && (
                                    <MOPSection
                                        isUpdatable={isUpdatable}
                                        dropdownData={dropdownData}
                                        reclaimableRowData={reclaimableRowData}
                                        setReclaimableRowData={setReclaimableRowData}
                                        taxRowData={taxRowData}
                                        setTaxRowData={setTaxRowData}
                                        previousReclaimableRowData={previousReclaimableRowData}
                                        setPreviousReclaimableRowData={setPreviousReclaimableRowData}
                                        resData={resData}
                                        isViewOnly={isViewOnly}
                                    />
                                )}
                                {val.title === '(H) Memorandum of Payment Notes' && openSection === 'MOPNotes' && renderMOPNotes()}
                            </div>
                        );
                    })}
                </div>
            </Card>
        </>
    );
};

export default RunningAccountBillCorrespondence;
