import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import {
    SUBLEASE_WATER_CHARGES_NOT_PAID,
    SUBLEASE_WATER_CHARGES_PAID,
    SUB_LEASE_DUE_FALSE,
    SUB_LEASE_DUE_TRUE,
    SUB_LEASE_FEE_DUE,
    SUB_LEASE_WATER,
    SUB_LEASE_WATER_FIELDS,
    WATER_CHARGES_NOT_PAID,
    WATER_CHARGES_PAID,
    initialSubLeaseDetailsData,
    initialSubleaseWater,
    subLeaseEnterpriseDetails,
    subLeaseEnterpriseDetailsII,
    subLesseeDetails,
    subLesseePrimaryDetails
} from './Migration.const';
import GenericFormCard from '../../../components/moduleCommonFormik/GenericFormCard.component';
import { Card } from 'react-bootstrap';
import DeleteIcon from '../../../assets/logos/delete.svg';
import { CGST_SGST, TENANT_ID } from '../../../utils/constants';
import { cross } from '../../../utils/Logo';
import PopUps from '../../../components/PopUps';
import GenericButtons from '../../../components/UI components/Buttons/GenericButtons';
import { POST, endpoints, requestInfo } from '../../../services/modulesServices/userManagement.api';
import { getFormatedOwnershipDetails, getSubleaseEntityDetails } from './Migration.controller';
import { toast } from 'react-toastify';
import _ from 'lodash';
import ProposedActivityComponent from '../EstateManagement/Allotment/ProposedActivityDetails';
import NestedProposedActivityComponent from '../EstateManagement/Allotment/NestedProposedActivity';

interface subLeaseProps {
    dropdownOptions?: any;
    otpReferenceId?: any;
    dropdownData?: any;
    setDropdownData?: any;
    setSubleaseNicDetails?: any;
    subleaseNicDetails?: any;
    isUpdate?: boolean;
}

export const SubLease = (props: subLeaseProps) => {
    const { dropdownOptions, setSubleaseNicDetails, subleaseNicDetails, isUpdate } = props;
    const { values, setValues } = useFormikContext<any>();
    const [count, setCount] = useState<number>(0);
    const [waterCount, setWaterCount] = useState<number>(0);
    const [modalDetails, setModalDetails] = useState({});
    // const [subleasePrimaryDetails, setSubleasePrimaryDetails] = useState(subLesseePrimaryDetails);
    const [sublesseeDetails, setSublesseeDetails] = useState(subLesseeDetails);
    const [hideCheckbox, setHideCheckBox] = useState<number[]>([]);
    // const [nicDetails, setNicDetails] = useState<any>([]);

    console.log('nicDetails', subleaseNicDetails);

    const handleAddSubLease = (push: any) => {
        push(initialSubLeaseDetailsData);
        setCount(count + 1);
        setSubleaseNicDetails({ ...subleaseNicDetails, [count + 1]: [] });
    };

    const handleAddWaterConnection = (push: any) => {
        push(initialSubleaseWater);
    };

    const handleRemove = (remove: any, index: number) => {
        remove(index);
        setCount(count - 1);
    };

    // useEffect(() => {
    //     setValues({ ...values, nicDetails: '' });
    // }, [nicDetails]);

    useEffect(() => {
        if (values?.specialProject?.code === 'true') {
            setValues({
                ...values,
                subLeaseDetailsdata: values?.subLeaseDetailsdata.map((val: any) => {
                    return {
                        ...val,
                        subLeaseEnterpriseDetails: {
                            ...val.subLeaseEnterpriseDetails,
                            specialProject: 'true'
                        }
                    };
                })
            });
        } else if (values?.specialProject?.code === 'false') {
            setValues({
                ...values,
                subLeaseDetailsdata: values?.subLeaseDetailsdata.map((val: any) => {
                    return {
                        ...val,
                        subLeaseEnterpriseDetails: {
                            ...val.subLeaseEnterpriseDetails,
                            specialProject: 'false'
                        }
                    };
                })
            });
        }
    }, [values.specialProject]);

    useEffect(() => {
        if (values.subLeaseDetailsdata?.length) {
            let subLeaseDetailsdata = values.subLeaseDetailsdata?.map((data: any) => {
                data.subLeaseEnterpriseDetails.enterpriseCategory =
                    values?.plotType?.code === 'Commercial'
                        ? { name: 'Commercial Undertaking', code: 'Commercial' }
                        : values?.plotType?.code === 'Industrial'
                        ? { name: 'Industrial Undertaking', code: 'Industrial' }
                        : '';
                data.subLeaseSpecialProject = values?.specialProject;
                return data;
            });
            setValues({ ...values, subLeaseDetailsdata });
        }
    }, [values.plotType, values.specialProject, values.subLeaseDetailsdata?.length]);

    const onChangeAction = (e: any, field: string) => {
        if (field.indexOf('subLeaseEndDate') !== -1 || field.indexOf('subLeaseStartDate') !== -1) {
            if (values.subLeaseDetailsdata?.length) {
                let subLeaseDetailsdata = values.subLeaseDetailsdata?.map((data: any) => {
                    if (data.subLeaseStartDate && data.subLeaseEndDate) {
                        const date1: Date = new Date(data.subLeaseStartDate);
                        const date2: Date = new Date(data.subLeaseEndDate);

                        if (date1 > date2) {
                            setModalDetails({ show: true, title: 'Error!', para: 'End date should not be less then start date', img: cross, type: 'error', reUpload: 'OK' });
                        } else {
                            let diffDays = 0;
                            let diffYears = date2.getFullYear() - date1.getFullYear();
                            let diffMonths = date2.getMonth() - date1.getMonth();
                            if (diffMonths < 0) {
                                diffYears--;
                                diffMonths += 12;
                            }
                            if (diffMonths === 0) {
                                diffDays = date2.getDate() - date1.getDate();
                            }
                            data.durationSubLease = diffMonths === 0 ? `${diffYears} Year(s) ${diffDays} Day(s)` : `${diffYears} Year(s) ${diffMonths} Month(s)`;
                        }
                    }
                    return data;
                });
                setValues({ ...values, subLeaseDetailsdata });
            }
        } else {
            let currentField = field.split('.').slice(-1)[0];
            let index = +field.split('.')[1];
            if (currentField === 'principalAmount') {
                setValues((prevValues: any) => ({
                    ...prevValues,
                    subLeaseDetailsdata: prevValues.subLeaseDetailsdata.map((item: any, idx: number) =>
                        idx === index
                            ? {
                                  ...item,
                                  paymentDetails: {
                                      ...item.paymentDetails,
                                      principalAmount: e.target.value,
                                      sgstPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      cgstPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      totalDueAmount: (
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.penaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPenaltyAmount || 0) +
                                          +e.target.value * (2 * CGST_SGST + 1)
                                      )
                                          .toFixed(2)
                                          .toString()
                                  }
                              }
                            : item
                    )
                }));
            } else if (currentField === 'penaltyAmount') {
                setValues((prevValues: any) => ({
                    ...prevValues,
                    subLeaseDetailsdata: prevValues.subLeaseDetailsdata.map((item: any, idx: number) =>
                        idx === index
                            ? {
                                  ...item,
                                  paymentDetails: {
                                      ...item.paymentDetails,
                                      penaltyAmount: e.target.value,
                                      sgstPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      cgstPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      totalDueAmount: (
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.principalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPenaltyAmount || 0) +
                                          +e.target.value * (2 * CGST_SGST + 1)
                                      )
                                          .toFixed(2)
                                          .toString()
                                  }
                              }
                            : item
                    )
                }));
            } else if (currentField === 'arrearPenaltyAmount') {
                setValues((prevValues: any) => ({
                    ...prevValues,
                    subLeaseDetailsdata: prevValues.subLeaseDetailsdata.map((item: any, idx: number) =>
                        idx === index
                            ? {
                                  ...item,
                                  paymentDetails: {
                                      ...item.paymentDetails,
                                      arrearPenaltyAmount: e.target.value,
                                      sgstArrearPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      cgstArrearPenaltyAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      totalDueAmount: (
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.principalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.penaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPrincipalAmount || 0) +
                                          +e.target.value * (2 * CGST_SGST + 1)
                                      )
                                          .toFixed(2)
                                          .toString()
                                  }
                              }
                            : item
                    )
                }));
            } else if (currentField === 'arrearPrincipalAmount') {
                setValues((prevValues: any) => ({
                    ...prevValues,
                    subLeaseDetailsdata: prevValues.subLeaseDetailsdata.map((item: any, idx: number) =>
                        idx === index
                            ? {
                                  ...item,
                                  paymentDetails: {
                                      ...item.paymentDetails,
                                      arrearPrincipalAmount: e.target.value,
                                      sgstArrearPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      cgstArrearPrincipalAmount: (CGST_SGST * e.target.value).toFixed(2).toString(),
                                      totalDueAmount: (
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.principalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPrincipalAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.penaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.arrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.cgstArrearPenaltyAmount || 0) +
                                          +(values.subLeaseDetailsdata[idx].paymentDetails.sgstArrearPenaltyAmount || 0) +
                                          +e.target.value * (2 * CGST_SGST + 1)
                                      )
                                          .toFixed(2)
                                          .toString()
                                  }
                              }
                            : item
                    )
                }));
            }
        }
    };

    const onClickSearchCall = async (ind: number) => {
        try {
            const queryParams = new URLSearchParams();
            // if (values.subLeaseDetailsdata[`${ind}`].subLeaseEntityDetails.entityName) queryParams.append('entityName', values.subLeaseDetailsdata[`${ind}`].subLeaseEntityDetails.entityName);
            // if (values.subLeaseDetailsdata[`${ind}`].subLeaseEntityDetails.emailId) queryParams.append('email', values.subLeaseDetailsdata[`${ind}`].subLeaseEntityDetails.emailId);
            // if (values.subLeaseDetailsdata[`${ind}`].subLeaseEntityDetails.phoneNumber) queryParams.append('phoneNumber', values.subLeaseDetailsdata[`${ind}`].subLeaseEntityDetails.phoneNumber);
            // if (values.subLeaseDetailsdata[`${ind}`].subLeaseEntityDetails.panNumber) queryParams.append('panNumber', values.subLeaseDetailsdata[`${ind}`].subLeaseEntityDetails.panNumber);

            const entityDetails = values.subLeaseDetailsdata[`${ind}`].subLeaseEntityDetails;

            _.forEach(['entityName', 'email', 'phoneNumber', 'panNumber'], (key: any) => {
                if (_.get(entityDetails, key)) {
                    queryParams.append(key, _.get(entityDetails, key));
                }
            });

            const { data } = await POST(endpoints.searchExistingUser + `?tenantId=${TENANT_ID}&${queryParams.toString()}`, { RequestInfo: requestInfo() });
            if (data.enterpriseEntity.length > 0) {
                const res = await getSubleaseEntityDetails(data.enterpriseEntity[0], dropdownOptions);

                const newState = _.cloneDeep(values);
                _.set(newState, `subLeaseDetailsdata[${ind}].subLeaseEntityDetails`, res);
                _.set(newState, `subLeaseDetailsdata[${ind}].isSearched`, true);
                setValues(newState);

                // TODO
                // const updatedSubLesseePrimaryDetails = subLesseePrimaryDetails.map(details => ({
                //     ...details,
                //     formData: details.formData.map(item => ({
                //         ...item,
                //         isDisabled: false
                //     }))
                // }));

                // setSubleasePrimaryDetails(updatedSubLesseePrimaryDetails);
                // const updatedSubLesseeDetails: any = subLesseeDetails.map(details => ({
                //     ...details,
                //     formData: details.formData.map(item => ({
                //         ...item,
                //         isDisabled: true
                //     }))
                // }));

                // setSublesseeDetails(updatedSubLesseeDetails);
                const hideAddressChckbox = hideCheckbox;
                hideAddressChckbox.push(ind);
                setHideCheckBox(hideAddressChckbox);

                console.log(newState);
            } else {
                toast('Application not found', {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo-bar',
                    type: 'warning'
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onChangeWaterCharges = (e: any, field: any) => {
        let newValues = _.cloneDeep(values);
        let currentField = field.split('.').slice(-1)[0];
        let index = +field.split('.')[1];
        let waterIndex = +field.split('.')[3];
        let currentPath = newValues.subLeaseDetailsdata[index].subLeaseWaterDetails[waterIndex];

        console.log(index, waterIndex);

        if (currentField === 'waterChargesPrincipalAmount') {
            let tempSum =
                +(currentPath.waterChargesPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.waterChargesArrearPrincipalAmount || 0) +
                +(currentPath.waterChargesArrearPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesArrearPenaltyAmount || 0);
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value).toFixed(2).toString();
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesPrincipalAmount`, e.target.value);
            setValues(newValues);
        }
        if (currentField === 'waterChargesPenaltyAmount') {
            let tempSum =
                +(currentPath.waterChargesPrincipalAmount || 0) +
                +(currentPath.waterChargesArrearPrincipalAmount || 0) +
                +(currentPath.waterChargesArrearPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesArrearPenaltyAmount || 0);
            const cgstOnWaterChargesPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const sgstOnWaterChargesPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value * (2 * CGST_SGST + 1)).toFixed(2).toString();
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].cgstOnWaterChargesPenaltyAmount`, cgstOnWaterChargesPenaltyAmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].sgstOnWaterChargesPenaltyAmount`, sgstOnWaterChargesPenaltyAmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesPenaltyAmount`, e.target.value);
            setValues(newValues);
            // setValues({ ...values, cgstOnWaterChargesPenaltyAmount, sgstOnWaterChargesPenaltyAmount, waterChargesTotalDueAmmount, waterChargesPenaltyAmount: e.target.value });
        }
        if (currentField === 'waterChargesArrearPrincipalAmount') {
            let tempSum =
                +(currentPath.waterChargesPrincipalAmount || 0) +
                +(currentPath.waterChargesPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.waterChargesArrearPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesArrearPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesArrearPenaltyAmount || 0);

            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value).toFixed(2).toString();
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesArrearPrincipalAmount`, e.target.value);
            setValues(newValues);

            // setValues({
            //     ...values,
            //     waterChargesTotalDueAmmount,
            //     waterChargesArrearPrincipalAmount: e.target.value
            // });
        }
        if (currentField === 'waterChargesArrearPenaltyAmount') {
            let tempSum =
                +(currentPath.waterChargesPrincipalAmount || 0) +
                +(currentPath.waterChargesPenaltyAmount || 0) +
                +(currentPath.cgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.sgstOnWaterChargesPenaltyAmount || 0) +
                +(currentPath.waterChargesArrearPrincipalAmount || 0);

            const cgstOnWaterChargesArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const sgstOnWaterChargesArrearPenaltyAmount = (CGST_SGST * +e.target.value).toFixed(2).toString();
            const waterChargesTotalDueAmmount = (+tempSum + +e.target.value * (2 * CGST_SGST + 1)).toFixed(2).toString();

            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].cgstOnWaterChargesArrearPenaltyAmount`, cgstOnWaterChargesArrearPenaltyAmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].sgstOnWaterChargesArrearPenaltyAmount`, sgstOnWaterChargesArrearPenaltyAmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesTotalDueAmmount`, waterChargesTotalDueAmmount);
            _.set(newValues, `subLeaseDetailsdata[${index}].subLeaseWaterDetails[${waterIndex}].waterChargesArrearPenaltyAmount`, e.target.value);
            setValues(newValues);
            // setValues({ ...values, cgstOnWaterChargesArrearPenaltyAmount, sgstOnWaterChargesArrearPenaltyAmount, waterChargesTotalDueAmmount, waterChargesArrearPenaltyAmount: e.target.value });
        }
    };

    console.log(values.subLeaseDetailsdata);

    return (
        <>
            <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
            <form>
                <FieldArray name='subLeaseDetailsdata'>
                    {({ insert, remove, push }) => (
                        <div>
                            {values.subLeaseDetailsdata?.length > 0 &&
                                values.subLeaseDetailsdata.map((_subLeaseDetailsdatas: any, index: number) => (
                                    <div className='row' key={index}>
                                        <div className='col-sm-12 collapsible_box  align-items-center pb-2 '>
                                            <input type='checkbox' id={`sublease${index}`} name={`sublease${index}`} defaultChecked={true} style={{ display: 'none' }} />
                                            <label htmlFor={`sublease${index}`} className='d-flex collapsible_box_label align-items-center justify-content-between'>
                                                Sub-Lease Details - {index + 1}
                                            </label>

                                            <div className='form_fields' style={{ position: 'relative' }}>
                                                <Card className='card_border mb-4'>
                                                    {index !== 0 && values?.isUpdatable && (
                                                        <div className='d-flex justify-content-end pt-3 pe-3' style={{ position: 'absolute', right: '2%' }}>
                                                            <img style={{ cursor: 'pointer', zIndex: 100 }} onClick={() => handleRemove(remove, index)} src={DeleteIcon} alt='delete' />
                                                        </div>
                                                    )}

                                                    {subLesseePrimaryDetails?.map((val: any, _: number) => {
                                                        return (
                                                            <Card.Body key={index}>
                                                                <GenericFormCard
                                                                    fieldPath={`subLeaseDetailsdata.${index}`}
                                                                    {...val}
                                                                    addressPath={`subLeaseDetailsdata.${index}.subLeaseEntityDetails`}
                                                                    index={index}
                                                                    dropdownOptions={dropdownOptions}
                                                                    onChangeAction={onChangeAction}
                                                                />
                                                            </Card.Body>
                                                        );
                                                    })}

                                                    {values?.isUpdatable ? (
                                                        <div className='d-flex justify-content-end'>
                                                            <GenericButtons text={'Search Application'} variant='primary' handleClick={() => onClickSearchCall(index)} />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {sublesseeDetails?.map((val: any, _: number) => {
                                                        return !val.renderCondition || (val.renderCondition && val.renderCondition(values, index)) ? (
                                                            <Card.Body className='py-0 '>
                                                                <GenericFormCard
                                                                    fieldPath={`subLeaseDetailsdata.${index}`}
                                                                    {...val}
                                                                    addressPath={`subLeaseDetailsdata.${index}.subLeaseEntityDetails`}
                                                                    index={index}
                                                                    dropdownOptions={dropdownOptions}
                                                                    onChangeAction={onChangeAction}
                                                                    hideAddressCheckbox={hideCheckbox.includes(index)}
                                                                    styles={{ className: 'mb-0' }}
                                                                />
                                                            </Card.Body>
                                                        ) : (
                                                            <></>
                                                        );
                                                    })}

                                                    {subLeaseEnterpriseDetails.map((val: any, _: number) => {
                                                        return !val.renderCondition || (val.renderCondition && val.renderCondition(values, index)) ? (
                                                            <Card.Body>
                                                                <GenericFormCard
                                                                    fieldPath={`subLeaseDetailsdata.${index}`}
                                                                    {...val}
                                                                    addressPath={`subLeaseDetailsdata.${index}.subLeaseEntityDetails`}
                                                                    index={index}
                                                                    dropdownOptions={dropdownOptions}
                                                                />
                                                            </Card.Body>
                                                        ) : (
                                                            <></>
                                                        );
                                                    })}
                                                    <div className='px-3' key={index}>
                                                        <NestedProposedActivityComponent
                                                            isDisabled={!values.isUpdatable}
                                                            subleaseId={index}
                                                            subleaseNicDetails={subleaseNicDetails}
                                                            setSubleaseNicDetails={setSubleaseNicDetails}
                                                        />
                                                    </div>
                                                    {subLeaseEnterpriseDetailsII.map((val: any, _: number) => {
                                                        return !val.renderCondition || (val.renderCondition && val.renderCondition(values, index)) ? (
                                                            <Card.Body>
                                                                <GenericFormCard
                                                                    fieldPath={`subLeaseDetailsdata.${index}`}
                                                                    {...val}
                                                                    addressPath={`subLeaseDetailsdata.${index}.subLeaseEntityDetails`}
                                                                    index={index}
                                                                    dropdownOptions={dropdownOptions}
                                                                />
                                                            </Card.Body>
                                                        ) : (
                                                            <></>
                                                        );
                                                    })}
                                                    {SUB_LEASE_FEE_DUE.map((val: any, ind: number) => (
                                                        <Card.Body>
                                                            <GenericFormCard {...val} index={index} fieldPath={`subLeaseDetailsdata.${index}`} />
                                                            {!!values.subLeaseDetailsdata[index].subleaseFeeDue ? (
                                                                <>
                                                                    {SUB_LEASE_DUE_TRUE.map((val: any, ind: number) => (
                                                                        <GenericFormCard
                                                                            fieldPath={`subLeaseDetailsdata.${index}`}
                                                                            addressPath={`subLeaseDetailsdata.${index}`}
                                                                            {...val}
                                                                            index={index}
                                                                            onChangeAction={onChangeAction}
                                                                        />
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {SUB_LEASE_DUE_FALSE.map((val: any, ind: number) => (
                                                                        <GenericFormCard
                                                                            fieldPath={`subLeaseDetailsdata.${index}`}
                                                                            addressPath={`subLeaseDetailsdata.${index}`}
                                                                            {...val}
                                                                            index={index}
                                                                            onChangeAction={onChangeAction}
                                                                        />
                                                                    ))}
                                                                </>
                                                            )}
                                                        </Card.Body>
                                                    ))}

                                                    {SUB_LEASE_WATER.map((val: any, ind: number) => (
                                                        <Card.Body>
                                                            <GenericFormCard {...val} index={index} fieldPath={`subLeaseDetailsdata.${index}`} />
                                                            {!!values.subLeaseDetailsdata[index].subleaseWater ? (
                                                                <>
                                                                    <FieldArray name={`subLeaseDetailsdata.${index}.subLeaseWaterDetails`}>
                                                                        {({ insert, remove, push }) => (
                                                                            <div>
                                                                                {values.subLeaseDetailsdata?.[`${index}`].subLeaseWaterDetails?.length > 0 &&
                                                                                    values.subLeaseDetailsdata?.[`${index}`].subLeaseWaterDetails.map(
                                                                                        (subLeaseDetailsdatas: any, waterIndex: number) => (
                                                                                            <div className='row' key={waterIndex}>
                                                                                                <div className='col-sm-12 collapsible_box  align-items-center pb-2 '>
                                                                                                    <input
                                                                                                        type='checkbox'
                                                                                                        id={`subleaseWater${waterIndex}`}
                                                                                                        name={`subleaseWater${waterIndex}`}
                                                                                                        defaultChecked={true}
                                                                                                        style={{ display: 'none' }}
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor={`subleaseWater${waterIndex}`}
                                                                                                        className='d-flex collapsible_box_label align-items-center justify-content-between'
                                                                                                    >
                                                                                                        Sub-Lease Water Connection Details - {waterIndex + 1}
                                                                                                    </label>

                                                                                                    <div className='form_fields' style={{ position: 'relative' }}>
                                                                                                        {waterIndex !== 0 && values?.isUpdatable && (
                                                                                                            <div
                                                                                                                className='d-flex justify-content-end pe-3'
                                                                                                                style={{ position: 'absolute', right: '0%' }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    style={{ cursor: 'pointer', zIndex: 100 }}
                                                                                                                    onClick={() => handleRemove(remove, waterIndex)}
                                                                                                                    src={DeleteIcon}
                                                                                                                    alt='delete'
                                                                                                                />
                                                                                                            </div>
                                                                                                        )}

                                                                                                        {SUB_LEASE_WATER_FIELDS.map((val: any, ind: number) => (
                                                                                                            <GenericFormCard
                                                                                                                fieldPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                addressPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                {...val}
                                                                                                                index={waterIndex}
                                                                                                                dropdownOptions={dropdownOptions}
                                                                                                                // onChangeAction={onChangeAction}
                                                                                                            />
                                                                                                        ))}

                                                                                                        {!values.subLeaseDetailsdata[index]?.subLeaseWaterDetails[waterIndex]?.subleaseWaterFeeDue
                                                                                                            ? SUBLEASE_WATER_CHARGES_PAID.map((val: any, ind: number) => (
                                                                                                                  <div style={{ marginTop: '-30px' }}>
                                                                                                                      <GenericFormCard
                                                                                                                          fieldPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                          addressPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                          {...val}
                                                                                                                          index={index}
                                                                                                                          //   dropdownOptions={dropdownOptions}
                                                                                                                          onChangeAction={onChangeWaterCharges}
                                                                                                                      />
                                                                                                                  </div>
                                                                                                              ))
                                                                                                            : SUBLEASE_WATER_CHARGES_NOT_PAID.map((val: any, ind: number) => (
                                                                                                                  <div style={{ marginTop: '-30px' }}>
                                                                                                                      <GenericFormCard
                                                                                                                          fieldPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                          addressPath={`subLeaseDetailsdata.${index}.subLeaseWaterDetails.${waterIndex}`}
                                                                                                                          {...val}
                                                                                                                          index={index}
                                                                                                                          onChangeAction={onChangeWaterCharges}
                                                                                                                          //   onChangeAction={onChangeAction}
                                                                                                                      />
                                                                                                                  </div>
                                                                                                              ))}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                {values.subLeaseDetailsdata?.[`${index}`].subLeaseWaterDetails?.length < 2 && values?.isUpdatable ? (
                                                                                    <button
                                                                                        className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center add_fields'
                                                                                        type='button'
                                                                                        onClick={() => handleAddWaterConnection(push)}
                                                                                    >
                                                                                        + Add Water Connection Details
                                                                                    </button>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </FieldArray>
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </Card.Body>
                                                    ))}
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            {values.isUpdatable && (
                                <button className='col-sm-12 mt-2  mb-4 d-flex justify-content-center align-items-center add_fields' type='button' onClick={() => handleAddSubLease(push)}>
                                    + Add Sub-Lease Details
                                </button>
                            )}
                        </div>
                    )}
                </FieldArray>
            </form>
        </>
    );
};
