import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { Card } from 'react-bootstrap';
import { PLOT_DETAILS } from '../Migration.const';
import _ from 'lodash';
import GenericSelectorWithSearch from '../../../../components/moduleCommonFormik/GenericSelectorWithSearch';
import GenericCommonTextField from '../../../../components/moduleCommonFormik/GenericCommonTextField.component';
import GenericButtons from '../../../../components/UI components/Buttons/GenericButtons';
import { getPlotDetails } from '../../EstateManagement/Allotment/Allotment.controller';

const PlotDetails = ({ dropdownData, setDropdownData, isFieldDisable = false, plotOtherDetails = true, hideCard, auction, ipb, OtherField, isUpdate }: any) => {
    const { values, setValues } = useFormikContext<any>();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const plotDetails = isUpdate ? PLOT_DETAILS?.map((val: any) => ({ ...val, formData: val?.formData?.map((item: any) => ({ ...item, isDisabled: true })) })) : PLOT_DETAILS;

    const onChangeDropdown = async (e: any, field: string) => {
        let val = _.cloneDeep(values);
        let res: any;
        // val[field] = { code: e?.value || '', name: e?.label || '' };
        _.set(val, field, { code: e?.value || '', name: e?.label || '' });

        if (field === 'estateName') {
            val = { ...val, plotNo: {}, plotType: {}, phase: {} };
        }

        const queryParams = new URLSearchParams();
        if (val.plotNo.code) queryParams.append('plotUuids', val.plotNo.code);
        if (val.phase.code) queryParams.append('phases', val.phase.code);
        // if (val.plotType.code) queryParams.append('plotTypes', val.plotType.code);
        if (val.estateName.code) queryParams.append('estateNames', val.estateName.code);

        try {
            res = await POST(`/idc-estate-service/plot/_metadata-search?plotStatus=VACANT&${queryParams.toString()}${ipb ? '&minPlotArea=3000' : ''}`, { RequestInfo: requestInfo() });

            setDropdownData({
                plotNo: res?.data?.plotNoDetails || [],
                phase: res?.data?.phases || [],
                plotType: res?.data?.plotTypes || [],
                estateName: res?.data?.estateNames || []
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        const tempPlotType = res?.data?.plotTypes?.length !== 0 && val['plotNo']?.code && getPlotDetails(res?.data?.plotTypes[0]?.value);
        console.log('temp plot type', tempPlotType);

        setValues({ ...val, plotRate: res?.data?.plotRate, plotArea: res?.data?.plotArea, plotType: tempPlotType });
    };

    useEffect(() => {
        if ((values?.estateApplicationId && values?.entityRefDetailsId) || isFieldDisable) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
        console.log(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFieldDisable]);

    const renderPlotDetails = () => {
        return (
            <>
                <div className='row d-flex m-0 p-0'>
                    {plotDetails[0]?.formData.map((formField: any, index: number) => {
                        return (
                            <div className={`py-3 col-sm-6`} key={index}>
                                <GenericSelectorWithSearch
                                    field={formField.field}
                                    title={formField.title}
                                    isRequired={formField.isRequired}
                                    isDisabled={formField.isDisabled || isDisabled}
                                    dropdownOptions={dropdownData}
                                    customHandleChange={onChangeDropdown}
                                    isClearable={true}
                                />
                            </div>
                        );
                    })}
                    {plotOtherDetails && (
                        <>
                            {!auction ? (
                                <div className={`py-3 col-sm-6`}>
                                    <GenericCommonTextField showInr={true} field={'plotRate'} title={'Plot Rate (INR/Sq.m.)'} titleCode={'PLOT_RATE'} isDisabled={true} />
                                </div>
                            ) : (
                                <></>
                            )}
                            <div className={`py-3 col-sm-6`}>
                                <GenericCommonTextField field={'plotArea'} title={'Plot Area (Sq.m.)'} isDisabled={true} titleCode={'PLOT_AREA'} />
                            </div>
                        </>
                    )}
                    {OtherField && <>{OtherField}</>}
                </div>
            </>
        );
    };

    return !hideCard ? (
        <Card className='card_border'>
            <Card.Body>
                <div className='ps-2 py-2 '>
                    <div className='formTitle m-0'>{'Plot Details'}</div>
                    {renderPlotDetails()}
                </div>
            </Card.Body>
        </Card>
    ) : (
        <div className='row d-flex m-0'>{renderPlotDetails()}</div>
    );
};

export default PlotDetails;
