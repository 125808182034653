import React from 'react';
import './Calender.scss';
import deleteLogo from '../../assets/logos/Trash.svg';
import editLogo from '../../assets/logos/Editor.svg';
import moment from 'moment';

interface IHolidayProps {
    holidayName?: string;
    holidayDate?: string;
    holidayType?: string;
    selectedEvent: any;
}

const HolidayPopUp = (props: IHolidayProps) => {
    const { holidayName, holidayDate, holidayType, selectedEvent } = props;
    return (
        <>
            <div className='container'>
                <div className='row p-4'>
                    <div className='col-sm-12 py-3 active_holidays rounded '>
                        <div className='row'>
                            <div className='col-sm-1 d-flex justify-content-center align-items-center   '>
                                <div className={`active_dot`}></div>
                            </div>
                            <div className='col-sm-8'>
                                <p className='holiday_details m-0'>{selectedEvent.title}</p>
                                <p className='holiday_details holiday_date m-0'>{moment(selectedEvent?.start).format('Do MMMM, dddd')}</p>
                                <p className='holiday_details holiday_date holiday_restrict m-0 fst-italic'>{'Restricted Holiday'}</p>
                            </div>
                            <div className='col-sm-3 d-flex justify-content-center align-items-center    '>
                                <img src={deleteLogo} alt='' className='holiday_icon me-3' />
                                <img src={editLogo} alt='' className='holiday_icon' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HolidayPopUp;
