import React, { useEffect, useState } from 'react';
import PopUps from '../../../../components/PopUps';
import GenericPopupModal from '../../../../components/moduleCommonFormik/GenericModalPopup.component';
import GenericFooterComponent from '../../../../components/moduleCommonFormik/GenericFooter.component';
import HeaderComponent from '../../../../components/header/Header.component';
import Loading from '../../../../components/portalComponents/Loading.component';
import { FormikProvider, useFormik } from 'formik';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { endpoints, POST, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import GenericHeader from '../../../../components/moduleCommonFormik/GenericHeader';
import { CorrespondenceWrapper, NotingWrapper } from '../../../../components/Workflow/NotingWrapper';
import { Card, Form } from 'react-bootstrap';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { CORRESPONDENCE_TABLE } from '../../EstateManagement/Allotment/Allotment.const';
import { CORRESPONDENCE_TABLE_APP_FORM } from '../../EstateManagement/LeaseDeed/LeaseDeed.const';
import GenericPDFViewer from '../../../../components/fileviewer/GenericPDFViewer';
import GenericImageViewer from '../../../../components/fileviewer/GenericImageViewer';
import activeArrow from '../../../../assets/logos/page arrow.svg';
import inactiveArrow from '../../../../assets/logos/page left arrow.svg';
import { dprDropdownDataOptions } from '../DPR creation screen/DPR.controller';
import { check, cross } from '../../../../utils/Logo';
import { tenderDetails } from '../NIT screens/NITscreen.const';
import { loaDetailValues } from '../LOAScreens/Loa.const';
import { extensionInitialValues, HeaderInitialValues } from '../ContractCreationScreens/ContractCreation.const';
import { v4 as uuidv4 } from 'uuid';
import { getDropdownSelectedObject } from '../../UserManagement/UserMangement.controller';
import { TENANT_ID } from '../../../../utils/constants';
import { getHistory, getSuccessMessage, onAttachDocument } from '../../EstateManagement/EstateManagement.controller';
import { DocumentInfo } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import ExtensionCorrespondence from './ExtensionCorrespondence';
import { convertDateToDDmmYYYY, getFirstErrorKey } from '../../../../utils/utils';
import axios from 'axios';
import { CONTRACT_EXTENSION_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { setDprHeaderData } from '../DPR creation screen/DPR.const';

const ExtensionWorkflow = () => {
    const [activeIndex, setActiveIndex] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [nextValidAction, setNextValidAction] = useState<any>(null);
    const [historyDetails, setHistoryDetails] = useState<any>([]);
    const [documentDetails, setDocumentDetails] = useState<any>([]);
    const [documentsUuid, setDocumentsUuid] = useState<any>([]);
    const [modalDetails, setModalDetails] = useState<any>({});
    const [resData, setResData] = useState<any>(null);
    const [relatedApplicationDocument, setRelatedApplicationDocument] = useState<any>(null);
    const [isFilePreviewModal, setIsFilePreviewModal] = useState(false);
    const [modalData, setModalData] = useState<any>(null);
    const [isPDF, setIsPDF] = useState(true);
    const [abstractData, setAbstractData] = useState<any>([]);
    const [dropdownData, setDropdownData] = useState<any>(null);
    const [newMeasurmentData, setNewMeasurmentData] = useState([]);
    const [raBillRowData, setRaBillRowData] = useState([]);
    const [reminderPopUp, setReminderPopUp] = useState<boolean>(false);
    const [content, setContent] = useState('');
    const [tableData, setTableData] = useState<any>([]);
    const [openSection, setOpenSection] = useState<string>('headerDetails');

    const totalPages = 3;
    const reqInfo = { RequestInfo: requestInfo() };

    const { t } = useTranslation();
    const location: any = useLocation();
    const RaBillApplicationNo = location?.state?.RaBillApplicationNo;
    let user = localStorage.getItem('currentUserAssignment');
    const [searchParams] = useSearchParams();
    const applicationNumber: any = searchParams.get('applicationNumber');
    let designation = user ? String(JSON.parse(user)?.designation?.code) : '';
    let designationName = user ? String(JSON.parse(user)?.designation?.name) : '';
    const navigate = useNavigate();
    const signedFileStoreId = searchParams.get('signedFileStoreId');
    const docsUuid = searchParams.get('docsUuid');
    const note: any = searchParams.get('note');
    const action: any = searchParams.get('action');

    const formik = useFormik<any>({
        initialValues: {
            isUpdatable: true,
            noting: {},
            text: '',
            designation: designation
        },
        onSubmit: () => {},
        validationSchema: CONTRACT_EXTENSION_VALIDATION
    });
    const { values, setValues, errors, setFieldTouched, setTouched, setFieldValue } = formik;

    useEffect(() => {
        dprDropdownDataOptions()
            .then((dropdownOption: any) => {
                setDropdownData(dropdownOption);
            })
            .catch(e => {
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Action Unsuccessful', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            });
        SearchRaBillDetails();
    }, []);

    useEffect(() => {
        (async () => {
            if (applicationNumber && resData) {
                try {
                    const nextActions = await POST(endpoints.dprValidActions + `?businessId=${applicationNumber}&tenantId=${TENANT_ID}`, {
                        RequestInfo: requestInfo()
                    });

                    setNextValidAction(nextActions.data);
                    setFieldValue('isUpdatable', nextActions.data.isUpdatable);

                    const historyData = await getHistory(applicationNumber);
                    setHistoryDetails(historyData);
                } catch (e) {
                    console.log(e);
                }
            }
        })();
    }, [resData]);

    useEffect(() => {
        if (activeIndex === 2 && documentDetails) {
            const tableData = async () => {
                const responseTableData = documentDetails?.map((data: any, ind: number) => {
                    return { ...data, serialNo: `C${documentDetails.length - ind}` };
                });
                setTableData(responseTableData);
            };

            tableData();
        }
    }, [documentDetails, activeIndex]);

    useEffect(() => {
        const getCorrespondenceDocument = async () => {
            try {
                setIsLoading(true);
                const { data } = await POST(endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber}&businessService=${nextValidAction?.businessService}`, {
                    RequestInfo: requestInfo()
                });
                setDocumentDetails(data?.documents);

                const relatedApplicationDocument = await POST(endpoints.worksRelatedDocument + `?applicationNos=${applicationNumber}`, { RequestInfo: requestInfo() });
                setRelatedApplicationDocument(relatedApplicationDocument);

                setIsLoading(false);
            } catch (e: any) {
                setIsLoading(false);
                let errMsg = e.response?.data?.errorMessage || e.response?.data?.Errors?.[0]?.message;
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        };

        if (nextValidAction) {
            getCorrespondenceDocument();
        }
    }, [nextValidAction]);

    const SearchRaBillDetails = async () => {
        try {
            setIsLoading(true);
            const { data } = await POST(endpoints.contractExtensionSearch + `?applicationNos=${applicationNumber}`, reqInfo);
            const resData = data.contractExtensionDetails[0];
            setResData(resData);
            setContent(resData.content);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                if (resData) {
                    const headerDetails = await setDprHeaderData(resData?.contractDetails?.workOrderDetails?.loaDetails?.dprHeaderDetails, dropdownData);
                    const headerInitialValue = await tenderDetails(resData?.contractDetails?.workOrderDetails?.loaDetails?.worksTenderDetails, dropdownData);
                    const loaInitValue = await loaDetailValues(resData?.contractDetails?.workOrderDetails?.loaDetails);
                    const contractFieldsInitVal = await HeaderInitialValues(resData?.contractDetails);
                    const extensionInitValue = await extensionInitialValues(resData);
                    const worksInitValue = {
                        workOrderNumber: resData?.contractDetails?.workOrderDetails?.workOrderNo,
                        workOrderDate: resData?.contractDetails?.workOrderDetails?.workOrderDate,
                        ...contractFieldsInitVal
                    };
                    setValues((prev: any) => ({ ...prev, ...headerInitialValue, ...loaInitValue, ...worksInitValue, ...extensionInitValue, ...headerDetails }));
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e);
                setIsLoading(false);
            }
        })();
    }, [resData]);

    const handleNextPage = async () => {
        try {
            const { data } = await POST(endpoints.worksCorrespondenceDocument + `?businessId=${applicationNumber}&businessService=${nextValidAction?.businessService}`, {
                RequestInfo: requestInfo()
            });
            setDocumentDetails(data?.documents);
        } catch (e) {
            console.log(e);
        }

        if (activeIndex < totalPages) setActiveIndex(prevInd => prevInd + 1);
    };

    const handlePrevPage = () => {
        if (activeIndex >= 2) setActiveIndex(prevInd => prevInd - 1);
    };

    const onRowClickRedirect = (row: any) => {
        const url = row.navUrl;
        navigate(url, { state: { row } });
    };
    const onRowClick = (row: any) => {
        setIsFilePreviewModal(!isFilePreviewModal);
        setModalData(row);
        setIsPDF(isPDF);
    };

    const handleOnFileUpload = async (file: DocumentInfo) => {
        if (applicationNumber) {
            try {
                const docs: any =
                    file.referenceId &&
                    (await onAttachDocument(
                        applicationNumber,
                        file?.referenceId,
                        designationName,
                        nextValidAction ? nextValidAction?.businessService : null,
                        historyDetails.length ? historyDetails[0].id : null,
                        true
                    ));
                setDocumentDetails(docs.documents);
                setDocumentsUuid([...documentsUuid, docs.uuid]);
                setActiveIndex(2);
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handelPrimaryAction = async (status: any) => {
        const initiatePayload = {
            ...reqInfo,
            contractExtensionRequest: {
                contractExtensionDetails: {
                    ...resData,
                    extensionReason: values.reason,
                    extendedDate: convertDateToDDmmYYYY(values.extensionDate),
                    contractorApplicationDocId: values?.supportingDocument?.docReferenceId,
                    compensationAmount: typeof values?.compensationAmount === 'string' ? values?.compensationAmount.replace(/,/g, '') : values?.compensationAmount
                }
            },
            isUpdatable: nextValidAction?.isUpdatable,
            ProcessInstances: [
                {
                    tenantId: TENANT_ID,
                    businessService: nextValidAction?.businessService,
                    businessId: applicationNumber,
                    moduleName: nextValidAction?.moduleName,
                    action: status?.action,
                    notes: values.notes,
                    comment: values.notes
                }
            ],
            workFlowDocumentIds: documentsUuid.length ? documentsUuid.filter(Boolean) : docsUuid?.split(',').filter(Boolean)
        };
        if (!values.notes) {
            setModalDetails({ show: true, title: 'Error!', para: 'Please enter notes!', img: cross, type: 'error', reUpload: 'OK' });
            return;
        }

        try {
            await CONTRACT_EXTENSION_VALIDATION.validate(values, { abortEarly: false, context: values });
            try {
                const response = await POST(endpoints.worksWorkflowInitiate, initiatePayload);
                if (response) {
                    const successMessage = getSuccessMessage(status?.action, applicationNumber);
                    setModalDetails({ show: true, title: 'Success', para: successMessage, img: check, type: 'success', reUpload: 'OK' });
                }
            } catch (e: any) {
                let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
                setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
            }
        } catch (e: any) {
            let errorField = getFirstErrorKey(errors);
            if (errorField) {
                const escapedErrorField = errorField.replace(/([[\].])/g, '\\$1');
                await setFieldTouched(errorField, true, true);
                let getErrorField: any = document.querySelector(`[name=${escapedErrorField}]`);
                getErrorField && getErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            setOpenSection('extensionDetails');
            setModalDetails({ show: true, title: 'Error!', para: t('ALL_REQUIRED_DETAILS', 'Please fill in all the required details!'), img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    const onUpdate = async () => {
        const payload = {
            ...reqInfo,
            contractExtensionDetails: {
                ...resData,
                extensionReason: values.reason,
                extendedDate: convertDateToDDmmYYYY(values.extensionDate),
                contractorApplicationDocId: values?.supportingDocument?.docReferenceId,
                compensationAmount: typeof values?.compensationAmount === 'string' ? values?.compensationAmount.replace(/,/g, '') : values?.compensationAmount
            }
        };

        try {
            const response = await POST(endpoints.contractExtensionUpdate, payload);
            setResData(response.data.contractExtensionDetails[0]);
        } catch (e: any) {
            console.log(e);
            let errMsg = e.response?.data?.errorMessage || t('SOMETHING_WENT_WRONG', 'Something went wrong');
            setModalDetails({ show: true, title: 'Error!', para: errMsg, img: cross, type: 'error', reUpload: 'OK' });
        }
    };

    return (
        <>
            <HeaderComponent />
            {/* {isLoading ? (
                <Loading />
            ) : ( */}
            <>
                <div className='row  px-5 mt-3 mb-5 m-auto justify-content-center position-relative font-inter'>
                    <GenericHeader
                        text={t('CONTRACT_EXTENSION', 'Contract Extension')}
                        additionalClass='sub-text-style'
                        bottomSubText={`Type : Contract Extension Application, No : ${applicationNumber ? applicationNumber : RaBillApplicationNo} `}
                    />

                    <FormikProvider value={formik}>
                        <div className='col-sm-3 mt-3 stickyCard-section '>
                            <div className='stickyCard' style={{ top: '15%' }}>
                                <GenericHeader
                                    text={t('CONTRACT_EXTENSION', 'Contract Extension')}
                                    sideHeader={true}
                                    additionalClass='sub-text-style'
                                    bottomSubText={`Type : Contract Extension Application, No : ${applicationNumber ? applicationNumber : RaBillApplicationNo} `}
                                />

                                <NotingWrapper
                                    designation={designation}
                                    applicationNumber={applicationNumber}
                                    history={historyDetails}
                                    disable={!values.isUpdatable}
                                    handleOnFileUpload={handleOnFileUpload}
                                    nextValidAction={nextValidAction}
                                />
                            </div>
                        </div>
                        <div className='col-sm-9 mt-3 form-section '>
                            <CorrespondenceWrapper applicationNumber={applicationNumber} designation={designation} nextValidAction={nextValidAction} />
                            <Card className='card_border p-1'>
                                <Form>
                                    {activeIndex === 1 && (
                                        <ExtensionCorrespondence
                                            t={t}
                                            dropdownData={dropdownData}
                                            abstractData={abstractData}
                                            newMeasurmentData={newMeasurmentData}
                                            setNewMeasurmentData={setNewMeasurmentData}
                                            raBillRowData={raBillRowData}
                                            setRaBillRowData={setRaBillRowData}
                                            applicationNumber={applicationNumber}
                                            searchRaBill={SearchRaBillDetails}
                                            setReminderPopUp={setReminderPopUp}
                                            content={content}
                                            setContent={setContent}
                                            openSection={openSection}
                                            setOpenSection={setOpenSection}
                                            resData={resData}
                                            designation={designation}
                                        />
                                    )}
                                    {activeIndex === 2 && (
                                        <GenericActionTable columns={CORRESPONDENCE_TABLE} hideExportBtn={true} hideSearchBar={true} rowData={tableData} additionalMethod={onRowClick} />
                                    )}
                                    {activeIndex === 3 && (
                                        <GenericActionTable
                                            columns={CORRESPONDENCE_TABLE_APP_FORM}
                                            hideSearchBar={true}
                                            rowData={relatedApplicationDocument?.data.applicationInfos}
                                            additionalMethod={onRowClickRedirect}
                                        />
                                    )}
                                </Form>
                            </Card>
                        </div>
                        <div className=' position-fixed  p-1  rounded-pill bg-white noting-pageination-step d-flex justify-content-between  align-items-center   ' style={{ bottom: '10%' }}>
                            <span className='col-3 rounded-circle pagination-right-arrow' onClick={handlePrevPage}>
                                <img src={activeIndex === 1 ? inactiveArrow : activeArrow} alt='' className={`${activeIndex !== 1 && `rotate-class`}`} />
                            </span>
                            Page {activeIndex} of {totalPages}
                            <span className='col-3 rounded-circle pagination-left-arrow' onClick={handleNextPage}>
                                <img src={activeIndex === totalPages ? inactiveArrow : activeArrow} alt='' className={`${activeIndex === totalPages && `rotate-class`}`} />
                            </span>
                        </div>
                    </FormikProvider>
                </div>
                <GenericFooterComponent
                    activeIndex={0}
                    stepperLength={0}
                    handleOnPrimaryAction={handelPrimaryAction}
                    onSubmit={onUpdate}
                    nextValidAction={nextValidAction}
                    extraButton={values.isUpdatable ? 'Save' : ''}
                    extraButtonAction={onUpdate}
                />
                {modalData && isFilePreviewModal && (
                    <GenericPopupModal
                        title={isPDF ? 'PDF Viewer' : 'Image Viewer'}
                        primaryAction={() => {}}
                        secondaryAction={() => setIsFilePreviewModal(!isFilePreviewModal)}
                        isVisible={isFilePreviewModal}
                        isPrimaryBtn={false}
                        isSecondaryBtn={false}
                        size={'xl'}
                        class='file-viewer-popup'
                    >
                        <div className='popup-children'>
                            {isPDF && <GenericPDFViewer fileStoreId={modalData.docRefId} documentName={modalData.docFileName} />}
                            {!isPDF && <GenericImageViewer />}
                        </div>
                    </GenericPopupModal>
                )}
                <PopUps modalDetails={modalDetails} setModalDetails={setModalDetails} />
                <GenericPopupModal
                    secondaryAction={() => {
                        setReminderPopUp(false);
                    }}
                    primaryAction={() => {
                        setReminderPopUp(false);
                        // handelPrimaryAction();
                    }}
                    isVisible={reminderPopUp}
                    title='Save Confirmation'
                    size='md'
                    children={
                        <div>
                            <div className='mx-4 my-3'>
                                <div className='mt-4'>
                                    <span className='font-inter' style={{ fontWeight: 400, fontSize: '16px', lineHeight: '25.6px' }}>
                                        {t('SAVE_CHANGES', 'Looks like you have some unsaved changes. Would you like to save them before proceeding with the new data?')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    primaryBtnTitle='Yes'
                    secondaryBtnTitle='No'
                    // onClose={() => setReminderPopUp(false)}
                />
            </>
            {/* )} */}
        </>
    );
};

export default ExtensionWorkflow;
