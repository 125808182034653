import { entityDocumentData } from '../LeaseDeed/LeaseDeed.controller';

export const options: any = {
    ALLOTMENT: { name: 'Lease Deed Execution/Payment', code: 'Lease Deed Execution/Payment' },
    TRANSFER: { name: 'Transfer Order', code: 'Transfer Order' },
    SUBLEASE: { name: 'Provisional Sub-Lease Order', code: 'Provisional Sub-Lease Order' },
    SPECIAL_TRANSFER: { name: 'Special Transfer Order', code: 'Special Transfer Order' }
};

export const getExtensionInitialData = async (res: any) => {
    const fileStoreId = res?.modificationDetails?.requestDetails?.documents[0]?.fileStoreId;
    const documentData = fileStoreId && (await entityDocumentData(fileStoreId));
    const initialData: any = {};
    initialData['comments'] = res?.modificationDetails?.requestDetails?.reason;
    initialData['extensionType'] = { name: options[`${res?.applicationSubType}`]?.name, code: options[`${res?.applicationSubType}`]?.name };
    initialData['supportingdocument'] = (documentData && documentData[0]) || '';
    initialData['allotmentId'] = res?.modificationDetails?.requestDetails?.orderNo;
    initialData['expiryDate'] = res?.modificationDetails?.requestDetails?.expiryDate;
    return initialData;
};
