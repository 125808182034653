import { isDisabled } from '@testing-library/user-event/dist/utils';
import { formFieldDTO } from '../../../../components/moduleCommonFormik/GenericFormCard.constants';
import { CONSTANT_DATA_FIELD, FormDataDTO } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';
import _ from 'lodash';
import { convertToINR, entityDocumentData } from '../../EstateManagement/EstateManagement.controller';
import { LOA_DATA_COLUMN } from '../WorkOrderScreens/WorkOrder.const';
import { BIDDER_FIELDS_I, BIDDER_FIELDS_II } from '../LOAScreens/Loa.const';
import { convertDateFormatddMMyyyytoyyyyMMdd } from '../../EstateManagement/LeaseDeed/LeaseDeed.controller';

export const SEARCH_WO_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'WO_NUMBER',
                title: 'Work Order Number',
                field: 'woNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5',
                isRequired: true
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'estate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];

export const CONTRACT_DATA_COLUMN: any = [
    LOA_DATA_COLUMN[0],
    {
        titleCode: 'WO_NO',
        colName: 'Work Order No.',
        fieldName: 'woNumber',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'VENDOR_NAME',
        colName: 'Vendor Name',
        fieldName: 'vendorName',
        clsName: 'column-text-start col-2'
    },
    ...LOA_DATA_COLUMN.slice(2)
];

export const PROJECT_MILESTONE_DATA_COLUMN: any = [
    {
        titleCode: 'SL_NO',
        colName: 'Milestone',
        fieldName: 'milestoneNo',
        cellstyle: { width: '136px' },
        clsName: 'column-text-start'
    },
    {
        titleCode: 'PROJECT_START_DATE',
        colName: 'Project Start Date',
        fieldName: 'projectStartDate',
        clsName: 'column-text-start',
        cellstyle: { width: '250px' }
    },
    {
        titleCode: 'MILESTONE_TARGET_DATE',
        colName: 'Milestone Target Date',
        fieldName: 'milestoneTargetDate',
        clsName: 'column-text-start',
        cellstyle: { width: '250px' }
    },
    {
        titleCode: 'COMMENCEMENT_MONTH',
        colName: 'Months from Commencement',
        fieldName: 'monthsFromCommencement',
        clsName: 'column-text-start',
        cellstyle: { width: '250px' }
    },
    {
        colName: 'Action',
        type: 'action',
        clsName: 'fixed-action-column fixed-action-column-bg',
        cellstyle: { marginLeft: 'auto' },
        actions: ['view', 'edit', 'delete']
    }
];

export const HEADER_FIELDS_II = [
    {
        titleCode: 'CONTRACT_DETAILS',
        title: 'Contract Details',
        formData: [
            {
                title: 'Work Order Number',
                field: 'workOrderNumber',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Work Order Date',
                field: 'workOrderDate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Project Start Date',
                field: 'projectStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                enableFutureDate: true
            },
            {
                title: 'Project End Date',
                field: 'projectEndDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true,
                enableFutureDate: true
            },
            {
                title: 'Defect Liability',
                field: 'defectLiability',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Applicable' },
                    { value: false, title: 'Not Applicable' }
                ]
            },
            {
                title: 'Defect Liability Period Months',
                field: 'defectLiabilityPeriodMonths',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, `defectLiability`) === true
            },
            {
                title: '',
                field: '',
                type: 'none',
                renderCondition: (values: any) => _.get(values, `defectLiability`) === false
            },
            {
                titleCode: 'REBATE',
                title: 'Rebate',
                field: 'rebate',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Applicable' },
                    { value: false, title: 'Not Applicable' }
                ]
            },
            {
                titleCode: 'REBATEPERCENT',
                title: 'Rebate Percentage',
                field: 'rebatePercentage',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, `rebate`) === true
            },
            {
                titleCode: 'REBATEAMOUNT',
                title: 'Rebate Amount',
                field: 'rebateAmount',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true,
                renderCondition: (values: any) => _.get(values, `rebate`) === true
            },
            {
                title: 'Total project cost',
                field: 'totalProjectCost',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const CONTRACTOR_DETAILS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Contractor Name',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            ...BIDDER_FIELDS_I[0].formData.slice(1)
        ]
    },
    BIDDER_FIELDS_II[0],
    {
        titleCode: 'BID_DETAILS',
        title: 'Bid Details',
        formData: [
            {
                title: 'Bid Price',
                field: 'bidPrice',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Bid Submission Date',
                field: 'bidSubmissionDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const PROJECT_MILESTONE_FIELDS = [
    {
        formData: [
            {
                title: 'Milestone No.',
                field: 'milestoneNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Project Start Date',
                field: 'projectStartDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Milestone Target Date',
                field: 'milestoneTargetDate',
                type: CONSTANT_DATA_FIELD.DATE_FIELD,
                isRequired: true,
                enableFutureDate: true
            },
            {
                title: 'Months from Commencement',
                field: 'monthsfromCommencement',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            }
        ]
    }
];
export const PROJECT_WORK_CATEGORY_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                title: 'Work Category',
                field: 'contractWorkCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                isRequired: true
            },
            {
                title: 'Value %',
                field: 'value',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true
            },
            {
                title: 'Comments',
                field: 'comments',
                type: CONSTANT_DATA_FIELD.TEXT_AREA_FIELD
            }
        ]
    }
];

export const WO_CORRESPONDENCE = [
    {
        title: '(A) Header',
        field: 'headerDetails'
    },
    {
        title: '(B) Abstract Cost',
        field: 'abstractCost'
    },
    {
        title: '(C) Contractor Details',
        field: 'contractorDetails'
    },
    {
        title: '(D) PBG Details',
        field: 'pbgDetails'
    }
    // {
    //     title: '(E) Project MileStone',
    //     field: 'projectMileStone'
    // }
];

export const workCategoryInitialFields = {
    contractWorkCategory: null,
    value: null,
    comments: null
};

export const getDocumentDetails = async (docRefId: any, docType: any) => {
    const documentData = (await entityDocumentData([{ docReferenceId: docRefId, documentType: docType }]))[0];
    return { ...documentData, documentName: documentData?.tag };
};

export const HeaderInitialValues = async (res: any) => {
    const initialValues = {
        projectStartDate: res?.workOrderDetails?.stipulatedDateCommencement,
        projectEndDate: res?.workOrderDetails?.stipulatedDateCompletion,
        defectLiability: res?.defectLiability ? res?.defectLiability : false,
        defectLiabilityPeriodMonths: res?.defectLiabilityPeriodMonths,
        // maintenancePeriod: res.maintenancePeriod !== null ? res.maintenancePeriod : true,
        // maintenancePeriodStartDate: res.maintenancePeriodStartDate,
        // maintenancePeriodEndDate: res.maintenancePeriodEndDate,
        // maintenancePeriodDays: res.maintenancePeriodDays,
        executionEndDate: res?.executionEndDate,
        totalProjectCost: res?.totalProjectCost && res?.totalProjectCost?.toFixed(2),
        signedCopyofContract: res?.signedContractDocRefId && (await getDocumentDetails(res?.signedContractDocRefId, DOCUMENT_TYPE.OTHER)),
        rebate: res?.rebateApplicable !== null ? res?.rebateApplicable : false,
        rebateAmount: res?.rebateAmount && res?.rebateAmount?.toFixed(2),
        rebatePercentage: res?.rebatePercentage
    };
    return initialValues;
};

export const extensionInitialValues = async (res: any) => {
    return {
        reason: res?.extensionReason,
        extensionDate: res?.extendedDate && convertDateFormatddMMyyyytoyyyyMMdd(res?.extendedDate),
        supportingDocument: res?.contractorApplicationDocId && (await getDocumentDetails(res?.contractorApplicationDocId, DOCUMENT_TYPE.OTHER)),
        compensationAmount: res?.compensationAmount === null ? '' : convertToINR(res?.compensationAmount)
    };
};

export const CONTRACT_SEARCH_TABLE = [
    {
        titleCode: 'SERIAL_NO',
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'column-text-start col-1'
    },
    {
        titleCode: 'CONTRACT_NO',
        colName: 'Contract No',
        fieldName: 'contractNo',
        clsName: 'column-text-start col-2',
        isClickable: true
    },
    {
        titleCode: 'VENDOR_NAME',
        colName: 'Vendor Name',
        fieldName: 'vendorName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'PROJECT_NAME',
        colName: 'Project Name',
        fieldName: 'projectName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'INDUSTRIAL_NAME',
        colName: 'Industrial Estate Name',
        fieldName: 'industrialName',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'PROJECT_TYPE',
        colName: 'Project Type',
        fieldName: 'projectType',
        clsName: 'column-text-start col-2'
    },
    {
        titleCode: 'ESTIMATE_AMOUNT',
        colName: 'Estimate Amount',
        fieldName: 'estimateAmount',
        clsName: 'column-text-start col-1'
    }
];

export const SEARCH_CONTRACT_APPLICATION_FIELDS: FormDataDTO[] = [
    {
        formData: [
            {
                titleCode: 'WORK_CONTRACT',
                title: 'Work Contract',
                field: 'workContract',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-5'
            },
            {
                titleCode: 'INDUSTRIAL_ESTATE',
                title: 'Industrial Estate',
                field: 'industrialEstate',
                class: 'col-5',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            }
        ]
    }
];
