import _ from 'lodash';
import { AUTHORIZED_PERSON_TITLE, CONSTANT_DATA_FIELD } from '../../../../utils/constants';
import { DOCUMENT_TYPE } from '../../Migration/Migration.const';

export const SPECIAL_TRANSFER_SIDECARD = [
    // {
    //     title: 'Transferor Details',
    //     titleCode: 'TRANSFEROR_DETAILS',
    //     index: 1,
    //     isActive: true
    // },
    {
        title: 'Special Transfer Dertails',
        titleCode: 'SPECIAL_TRANSFER_DETAILS',
        index: 1,
        isActive: true
    },
    {
        title: 'Plot Details',
        titleCode: 'PLOT_DETAILS',
        index: 2,
        isActive: true
    },
    {
        title: 'Payment',
        titleCode: 'PAYMENT',
        index: 3,
        isActive: true
    }
    // {
    //     title: 'Assign Transferee',
    //     titleCode: 'ASSIGN_TRANSFEREE',
    //     index: 3,
    //     isActive: true
    // }
];

export const TRANSFEROR_DETAILS = [
    {
        formData: [
            {
                title: 'Name',
                titleCode: 'NAME',
                field: 'name',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Email ID',
                titleCode: 'EMAIL_ID',
                field: 'email',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Phone No',
                titleCode: 'PHONE_NO',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'POSTAL_ADDRESS',
        title: 'Postal Address',
        formData: [
            {
                title: 'Address Line 1',
                titleCode: 'ADDRESS_LINE_1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Address Line 2',
                titleCode: 'ADDRESS_LINE_2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'Pin Code',
                titleCode: 'PINCODE',
                field: 'pinCode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isDisabled: true
            },
            {
                title: 'District',
                titleCode: 'DISTRICT',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },
            {
                title: 'State/UT',
                titleCode: 'STATE',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            },

            {
                title: 'Country',
                titleCode: 'COUNTRY',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    }
];

export const SPECIAL_TRANSFER_DETAILS = [
    {
        formData: [
            {
                title: 'Type of Special Transfer',
                titleCode: 'TYPE_SPECIAL_TRANSFER',
                field: 'specialTransferType',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD,
                class: 'col-sm-12',
                isRequired: true
            }
        ]
    }
];

export const SPECIAL_TRANSFER_DOCUMENTS = [
    {
        formData: [
            {
                title: 'Approval of Registrar of Companies',
                titleCode: 'APPROVAL_OF_REGISTRAR',
                field: 'approvalOfRegistrarOfCompanies',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return (
                        _.get(value, `specialTransferType`)?.code === 'SPL_CHANGE_CONTROLLING_OWNER_INTEREST' ||
                        _.get(value, `specialTransferType`)?.code === 'SPL_MERGER_DEMERGER' ||
                        _.get(value, `specialTransferType`)?.code === 'SPL_CONVERSION_TO_CORPORATE'
                    );
                },
                entityFileData: {
                    documentNamePrefix: 'Approval of Registrar of Companies',
                    documentType: DOCUMENT_TYPE.APPROVAL_OF_REGISTRAR,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            },
            {
                title: 'Memorandum of Association',
                titleCode: 'MEMORANDUM_OF_ASSOCIATION',
                field: 'memorandumOfAssociation',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return _.get(value, `specialTransferType`)?.code === 'SPL_MERGER_DEMERGER' || _.get(value, `specialTransferType`)?.code === 'SPL_CONVERSION_TO_CORPORATE';
                },
                entityFileData: {
                    documentNamePrefix: 'Memorandum of Association',
                    documentType: DOCUMENT_TYPE.MEMORANDUM_OF_ASSOCIATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            },
            {
                title: 'Relevant court order',
                titleCode: 'RELEVANT_COURT_ORDER',
                field: 'relevantCourtOrder',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return _.get(value, `specialTransferType`)?.code === 'SPL_MERGER_DEMERGER' || _.get(value, `specialTransferType`)?.code === 'SPL_CONVERSION_TO_CORPORATE';
                },
                entityFileData: {
                    documentNamePrefix: 'Relevant court order',
                    documentType: DOCUMENT_TYPE.COURT_ORDER,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            },
            {
                title: 'Corporate insolvency resolution plan',
                titleCode: 'CORPORATE_INSOLVENCY',
                field: 'corporateInsolvencyResolutionPlan',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return _.get(value, `specialTransferType`)?.code === 'SPL_MERGER_DEMERGER';
                },
                entityFileData: {
                    documentNamePrefix: 'Corporate insolvency resolution plan',
                    documentType: DOCUMENT_TYPE.CORPORATE_INSOLVANCY_RESOLUTION_PLAN,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            },

            {
                title: 'LLP Registration',
                titleCode: 'LLP_REGISTRATION',
                field: 'LLPRegistration',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return _.get(value, `specialTransferType`)?.code === 'SPL_CONVERSION_TO_LLP';
                },
                entityFileData: {
                    documentNamePrefix: 'LLP Registration',
                    documentType: DOCUMENT_TYPE.LLP_REGISTRATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            },
            {
                title: 'Death Certificate (self-certification not required)',
                titleCode: 'DEATH_CERTIFICATE',
                field: 'deathCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return _.get(value, `specialTransferType`)?.code === 'SPL_CONVEYANCE_LEASE_RIGHTS';
                },
                entityFileData: {
                    documentNamePrefix: 'Death Certificate',
                    documentType: DOCUMENT_TYPE.DEATH_CERTIFICATE,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            },
            {
                title: 'Succession Certificate',
                titleCode: 'SUCCESSION_CERTIFICATE',
                field: 'successionCertificate',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return _.get(value, `specialTransferType`)?.code === 'SPL_CONVEYANCE_LEASE_RIGHTS';
                },
                entityFileData: {
                    documentNamePrefix: 'Succession Certificate',
                    documentType: DOCUMENT_TYPE.SUCCESSION_CERTIFICATE,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            }
        ]
    }
];

export const SPECIAL_PROJECT_DOCUMENT = [
    {
        formData: [
            {
                title: 'Whether applied under Special Project category',
                titleCode: 'APPLIED_UNDER_SPECIAL_PROJECT',
                field: 'appliedUnderSpecialProject',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ],
                class: 'col-sm-6 me-3',
                isDisabled: true
            },
            {
                title: 'NOC from Goa-IPB',
                titleCode: 'NOC_GOA_IPB',
                field: 'nocGoaIPB',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                renderCondition: (values: any) => _.get(values, `appliedUnderSpecialProject`) === true,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'Special Project NOC',
                    documentType: DOCUMENT_TYPE.NOC_SPECIAL_PROJECT,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            }
        ]
    }
];

export const TRANSFEREE_DETAILS = [
    {
        formData: [
            {
                title: 'Name',
                titleCode: 'NAME',
                field: 'transfereeName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Email ID',
                titleCode: 'EMAIL_ID',
                field: 'transfereeEmail',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Phone No',
                titleCode: 'PHONE_NO',
                field: 'transfereePhoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD
            }
        ]
    }
];

export const TRANSFEREE_TABLE_COLUMNS = [
    {
        colName: 'singleCheck',
        fieldName: 'checkbox',
        clsName: 'col-sm-1'
    },
    {
        colName: 'Name',
        fieldName: 'name',
        clsName: 'col-sm-4 column-text-start'
    },
    {
        colName: 'Email ID',
        fieldName: 'emailId',
        clsName: 'col-sm-3 column-text-start'
    },
    {
        colName: 'Phone No',
        fieldName: 'phoneNumber',
        clsName: 'col-sm-4 column-text-start'
    }
];

export const CORRESPONDENCE_TABLE = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-2',
        id: 1
    },
    {
        colName: 'Uploaded By',
        fieldName: 'uploadedUserRole',
        clsName: 'col-sm-3',
        id: 2
    },
    {
        colName: ' Date & Time',
        fieldName: 'uploadedDate',
        clsName: 'col-sm-3',
        id: 3
    },
    {
        colName: ' Document',
        fieldName: 'docFileName',
        clsName: 'col-sm-4',
        isClickable: true,
        id: 4
    }
];

export const CHECKLIST_TABLE = [
    {
        colName: 'SN',
        fieldName: 'serialNo',
        clsName: 'col-sm-2',
        id: 1
    },
    {
        colName: 'Condition',
        fieldName: 'condition',
        clsName: 'col-sm-6',
        id: 2
    },
    {
        colName: 'Status',
        fieldName: 'status',
        clsName: 'col-sm-4',
        id: 3
    }
];

export const SPECIAL_TRANSFER_CORRESPONDENCE = [
    {
        title: 'A1 - Transferee Details',
        field: 'transfereeDetails'
    },
    {
        title: 'A2 - Transferor Details',
        field: 'transferorDetails'
    },
    {
        title: 'A3 - Special Transfer Details',
        field: 'specialTransferDetails'
    },
    {
        title: 'B - Self Declaration',
        field: 'selfDeclaration'
    },
    {
        title: 'C - Payment',
        field: 'payment'
    },
    {
        title: 'D - Special Transfer Notes',
        field: 'specialTransferNotes'
    }
];

export const TRANSFEREE_DETAILS_ST1 = [
    {
        formData: [
            {
                title: 'Transferee Name',
                titleCode: 'TRANSFEREE_NAME',
                field: 'transfereeName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Transferee Category',
                titleCode: 'TRANSFEREE_CATEGORY',
                field: 'transfereeCategory',
                type: CONSTANT_DATA_FIELD.SELECT_FIELD
            },
            {
                title: 'Letter stating the name of the Proprietorship firm',
                field: 'entityProprietorshipFirm',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PROPRIETORSHIPFIRM,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Identity of the proprietor',
                field: 'entityProprietorIdentity',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PROP_FIRM',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.IDENTITY,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Trust Deed',
                field: 'trustDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => {
                    return _.get(value, `category`)?.code === 'TRUST';
                },
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.TRUST_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Partnership Deed',
                field: 'partnershipDeed',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.PARTNERSHIP_DEED,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Certificate of registration from the Registrar of Firms',
                field: 'certificateOfRegistrationFirms',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'PARTNERSHIP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_REGISTRATION_FIRMS,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Certificate of incorporation from Registrar of Companies',
                field: 'certificateOfIncorporation',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'LIMITED_LIABILITY_PARTNERSHIP' || _.get(value, `category`)?.code === 'LTD_COMPANY',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.CERTIFICATE_OF_INCORPORATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Certificate of registration from the Registrar of Societies',
                field: 'certificateOfRegistrationSocieties',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'COOP_SOCIETY',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.REGISTRATION_OF_SOCITIES,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            },
            {
                title: 'Copy of relevant notification',
                field: 'copyRelevantNotification',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (value: any) => _.get(value, `category`)?.code === 'GOV_STAT_CORP',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GOVT_NOTIFICATION,
                    referenceType: DOCUMENT_TYPE.ENTITY
                },
                hideFileSize: true
            }
        ]
    }
];

export const TRANSFEREE_DETAILS_ST2 = [
    {
        formData: [
            {
                title: 'Email ID',
                field: 'emailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Phone No',
                field: 'phoneNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Is GST Applicable?',
                field: 'applicantGst',
                type: CONSTANT_DATA_FIELD.RADIO_FIELD,
                class: 'col-sm-6 me-3',
                options: [
                    { value: true, title: 'Yes' },
                    { value: false, title: 'No' }
                ]
            },
            {
                title: 'GST No',
                field: 'applicantGstno',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                renderCondition: (values: any) => _.get(values, `applicantGst`) === true,
                toolTipDetails: {
                    position: 'bottom',
                    content: '<p>Please provide GST specific to the mentioned business activities or trade.</p>'
                }
            },
            {
                title: 'PAN No',
                field: 'applicantPanNo',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                conditionalClass: (values: any) => (_.get(values, `applicantGst`) === false ? 'me-3' : '')
            },
            {
                title: 'GST Document',
                titleCode: 'GST Document',
                field: 'applicantGstdocument',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                renderCondition: (values: any) => _.get(values, `applicantGst`) === true,
                hideTitle: true,
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.ENTITY_CATEGORY.GST,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            },
            {
                title: 'PAN Card',
                titleCode: 'PAN Card',
                field: 'applicantPancard',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                hideTitle: true,
                class: 'col-sm-6 d-block',
                entityFileData: {
                    documentNamePrefix: 'applicantName',
                    documentType: DOCUMENT_TYPE.PAN,
                    referenceType: DOCUMENT_TYPE.ENTITY
                }
            }
        ]
    },
    {
        titleCode: 'Address of the Applicant',
        title: 'Address Details',
        formData: [
            {
                title: 'Address Line 1',
                field: 'address1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2',
                field: 'address2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'pincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'District',
                field: 'district',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true,
                class: 'col-sm-6'
            },
            {
                title: 'State U/T',
                field: 'state',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },

            {
                title: 'Country',
                field: 'country',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Mailing/Correspondence address of the Applicant',
        title: 'Mailing/Correspondence address of the Entity',
        isSameAsAbove: true,
        isRequired: true,
        formData: [
            {
                title: 'Address Line 1',
                field: 'peraddress1',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Address Line 2 ',
                field: 'peraddress2',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD
            },
            {
                title: 'Pin Code',
                field: 'perpincode',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                class: 'col-sm-6'
            },
            {
                title: 'District',
                field: 'perdistrict',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'State U/T',
                field: 'perstate',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                class: 'col-sm-6',
                isDisabled: true
            },
            {
                title: 'Country',
                field: 'percountry',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isDisabled: true
            }
        ]
    },
    {
        titleCode: 'Details of the Authorized Person',
        title: "Authorized Person's Details",
        toolTipDetails: {
            position: 'right',
            content: AUTHORIZED_PERSON_TITLE,
            variant: { height: '20px' }
        },
        formData: [
            {
                title: 'First Name',
                field: 'authorizedPersonFirstname',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Last Name',
                field: 'authorizedPersonLastName',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true
            },
            {
                title: 'Primary Mobile Number',
                field: 'pmobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: true,
                isDisabled: true
            },
            {
                title: 'Alternative Mobile Number',
                field: 'alternateMobileNumber',
                type: CONSTANT_DATA_FIELD.NUMERIC_FIELD,
                isRequired: false
            },

            {
                title: 'Email ID',
                field: 'authorizedEmailId',
                type: CONSTANT_DATA_FIELD.TEXT_FIELD,
                isRequired: true,
                class: 'col-sm-6 me-3',
                isDisabled: true
            },

            {
                title: 'The letter / resolution / power of attorney authorising the authorised person',
                titleCode: 'Please attach the letter/resolution/power of attorny ',
                field: 'letter',
                type: CONSTANT_DATA_FIELD.FILE_FIELD,
                isRequired: true,
                entityFileData: {
                    documentNamePrefix: 'authorizedPersonFirstname',
                    documentType: DOCUMENT_TYPE.AUTH_CATEGORY.POWEROFATTORNEY,
                    referenceType: DOCUMENT_TYPE.AUTHUSER
                }
            }
        ]
    }
];

export const PLOT_TABLE_COLUMNS = [
    {
        colName: 'multiCheck',
        fieldName: 'checkbox',
        clsName: 'col-sm-1',
        id: 2
    },
    {
        colName: 'Plot No',
        fieldName: 'plotNo',
        clsName: 'col',
        id: 2
    },
    {
        colName: 'Phase',
        fieldName: 'phase',
        clsName: 'col',
        id: 3
    },
    {
        colName: 'Estate Name',
        fieldName: 'estateName',
        clsName: 'col',
        id: 4
    },
    {
        colName: 'Plot Area (Sqm)',
        fieldName: 'plotAreaSqm',
        clsName: 'col',
        id: 5
    }
];
