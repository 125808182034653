import React, { useState, useEffect } from 'react';
import WorklistDashboardSection from './WorklistDashboardSection';
import { getDashboardDetails } from '../../../../services/modulesServices/userManagement.api';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/authSlice';
import GMDashboardSection from './GMDashboardSection';
import HeaderComponent from '../../../../components/header/Header.component';
import MDDashboardSection from './MDDashboardSection';
import Loading from '../../../../components/portalComponents/Loading.component';
import { tableData } from '../types';
import { useNavigate } from 'react-router';
import { DASHBOARD_TABLE_COLUMNS } from '../UserManagement.const';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import FooterComponent from '../../../../components/portalComponents/Footer.component';

function Dashboard() {
    const [dashboardDetails, setDashboardDetails] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableData, setTableData] = useState<any>(null);

    const userData = useSelector(selectUser);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        getDashboardDetails()
            .then(res => {
                setDashboardDetails(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            });
    }, [userData]);

    const handleRowClick = (rowData: tableData) => {
        // Use useNavigate to navigate to the details page and pass data
        const url = rowData.navUrl;
        navigate(url, { state: { rowData } });
    };

    console.log('dashboardDetails', dashboardDetails);

    return (
        <>
            {!!isLoading && <Loading />}
            <HeaderComponent />
            <div className='m-auto mx-5 px-5'>
                {dashboardDetails?.worklists?.length ? <WorklistDashboardSection dashboardDetails={dashboardDetails} setTableData={setTableData} tableData={tableData} /> : <></>}
                {dashboardDetails?.cards?.length ? <GMDashboardSection dashboardDetails={dashboardDetails} setTableData={setTableData} tableData={tableData} /> : <></>}
                {dashboardDetails?.gidcCards?.length ? <MDDashboardSection dashboardDetails={dashboardDetails} setTableData={setTableData} tableData={tableData} /> : <></>}
                {dashboardDetails?.gidcWorklists?.length ? <WorklistDashboardSection dashboardDetails={dashboardDetails} setTableData={setTableData} tableData={tableData} /> : <></>}

                {tableData ? (
                    <div className='mt-5'>
                        <GenericActionTable columns={DASHBOARD_TABLE_COLUMNS} rowData={tableData} additionalMethod={handleRowClick} searchBarPlaceholder='SEARCH_BY'></GenericActionTable>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className='mt-5'>
                <FooterComponent onlyCopyright={true} />
            </div>
        </>
    );
}

export default Dashboard;
