import { Card } from 'react-bootstrap';
import GenericActionTable from '../../../../components/moduleCommonFormik/GenericActionTable';
import { PAYMENT_TYPE_DETAILS, installmentColumns, paymentColumns, paymentOptions } from './Allotment.const';
import { useEffect, useState } from 'react';
import fileDown from '../../../../assets/logos/download_green.svg';
// import fileDown2 from '../../../../assets/logos/download_gray.svg';
import { Image } from 'react-bootstrap';
import { timestampToDate } from './Allotment.controller';
import GenericFormCard from '../../../../components/moduleCommonFormik/GenericFormCard.component';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { PAYMENT_INSTALLMENT_VALIDATION } from '../../../../utils/formikValidation/FormikValidation';
import { POST, devUrl, requestInfo } from '../../../../services/modulesServices/userManagement.api';
import { convertToINR } from '../EstateManagement.controller';
import { DOWNPAYMENT, LAND_PREMIUM_INTEREST } from '../../../../utils/constants';
import { useTranslation } from 'react-i18next';

export default function PaymentDetailInstallments(props: any) {
    const { t } = useTranslation();
    const payOptions: any = paymentOptions;
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0);
    // const [deductTooltip, setDeductTooltip] = useState<any>(null);

    const pay = (ele: any) => {
        props?.setSelectedGateway(ele.code);
    };
    const dloadReceipt = async (refId?: string) => {
        const downloadFileUrl = `${devUrl}/filestore/v1/files/download?tenantId=ga&fileStoreIds=${refId}`;
        window.location.href = downloadFileUrl;
    };

    const dropdownOptions = {
        paymentType: [
            { name: 'Full Payment', code: 'full_payment' },
            { name: 'Installment', code: 'installment' }
        ],
        installments: [
            { name: '1', code: 1 },
            { name: '2', code: 2 },
            { name: '3', code: 3 },
            { name: '4', code: 4 },
            { name: '5', code: 5 },
            { name: '6', code: 6 },
            { name: '7', code: 7 },
            { name: '8', code: 8 }
        ]
    };
    const { values, setFieldValue, setValues } = useFormikContext<any>();

    useEffect(() => {
        const paymentType = values?.paymentType?.code;
        const downpayment = values.downPayment;
        if (!props?.isPaid) {
            // props?.setFinalPaymentData(props?.paymentData);
            handleFinalPayment(paymentType, downpayment);
            console.log('a', props?.finalPaymentData);
        }
    }, [props?.paymentData, props?.isPaid, props.deposit, props.allotmentSearchRes]);

    useEffect(() => {
        const fetchSecurityDeposit = async () => {
            let totalPayment: any = props?.finalPaymentData.reduce((acc: any, cur: any) => acc + +cur['totalDueAmount'], 0).toFixed(2);
            // if (deposit > 0) {
            //     totalPayment = totalPayment - deposit;
            //     setTotalPaidAmount(totalPayment || 0);
            //     // setDeductTooltip({
            //     //     position: 'bottom',
            //     //     content: `<p>Security Deposit of INR ${convertToINR(deposit)} is deducted from the Total Payable Amount.</p>`
            //     // });
            // } else {
            //     // setDeductTooltip(null);
            // }
            setTotalPaidAmount(totalPayment || 0);
        };
        fetchSecurityDeposit();
    }, [props?.finalPaymentData, props.deposit]);

    const generateInstallments = (installments: number, totalAmount: number, downpayment: number) => {
        let data = [];
        console.log(totalAmount, downpayment);
        let balance = totalAmount - downpayment;
        let premium = balance / installments || 0;
        let due = new Date(props?.dueDate);
        for (let i = 0; i < installments; i++) {
            due.setFullYear(due.getFullYear() + 1);
            let interest = (balance - i * premium) * LAND_PREMIUM_INTEREST || 0;
            const obj: any = { index: i + 1 };
            obj.dueDate = timestampToDate(new Date(due));
            obj.premiumDue = convertToINR(+premium.toFixed(2));
            obj.principalAmount = premium;
            obj.interest = convertToINR(+interest.toFixed(2));
            obj.interestAmount = interest;
            obj.total = convertToINR((+premium + +interest).toFixed(2));
            obj.totalDueAmount = premium + interest;
            obj.paymentType = 'LAND_PREMIUM';
            data.push(obj);
        }
        props?.setInstallmentData(data);
    };

    const getDaysDifference = (givenDate: any) => {
        const dateObj = new Date(givenDate);
        const today = new Date();
        const differenceInMilliseconds = dateObj.getTime() - today.getTime();
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        return differenceInDays;
    };

    const handleInstallmentGeneration = (installments: any, downpayment: any) => {
        if (downpayment && downpayment > 0 && downpayment >= props?.total / 5 && downpayment <= props?.total) {
            generateInstallments(installments, props?.total, +downpayment);
        } else {
            props?.setInstallmentData([]);
        }
    };

    const handleFinalPayment = (paymentType: any, downpayment: any) => {
        let installment_downpayment: any = [];
        const payments = props?.paymentData;
        if (paymentType === 'installment') {
            if (downpayment && downpayment > 0 && downpayment >= props?.total / 5 && downpayment <= props?.total) {
                installment_downpayment = [
                    {
                        paymentType: 'LAND_PREMIUM',
                        principalAmount: +downpayment,
                        cgstPrincipal: null,
                        sgstPrincipal: null,
                        totalDueAmount: +downpayment,
                        feeTypeDisplayName: 'Land Premium (Down Payment)',
                        cgstAmount: '--',
                        sgstAmount: '--',
                        amount: convertToINR(downpayment),
                        total: convertToINR(downpayment),
                        index: payments.length + 1
                    }
                ];
            }
        } else {
            if (props?.totalPrincipalAmount) {
                installment_downpayment = [
                    {
                        paymentType: 'LAND_PREMIUM',
                        principalAmount: +props?.totalPrincipalAmount,
                        cgstPrincipal: null,
                        sgstPrincipal: null,
                        totalDueAmount: +props?.totalPrincipalAmount,
                        feeTypeDisplayName: 'Land Premium',
                        cgstAmount: '--',
                        sgstAmount: '--',
                        amount: convertToINR(props?.totalPrincipalAmount),
                        total: convertToINR(props?.totalPrincipalAmount),
                        index: payments.length + 1
                    }
                ];
            }
        }
        let securityDeposit = [
            {
                paymentType: props.allotmentSearchRes?.allotmentSubType !== 'AUCTION' ? 'SECURITY_DEPOSIT' : 'EMD',
                feeTypeDisplayName: props.allotmentSearchRes?.allotmentSubType !== 'AUCTION' ? 'Security Deposit' : 'EMD',
                amount: convertToINR(props.deposit),
                principalAmount: props.deposit,
                cgstAmount: '--',
                sgstAmount: '--',
                total: '-' + convertToINR(props.deposit),
                index: payments.length + 2,
                totalDueAmount: -props.deposit
            }
        ];
        props?.setFinalPaymentData([...payments, ...installment_downpayment, ...securityDeposit]);
    };

    const onFieldChange = (value: any, field: any) => {
        if (field === 'installments') {
            const installments = value.value;
            const downpayment = values.downPayment;
            handleInstallmentGeneration(installments, downpayment);
            handleFinalPayment(values?.paymentType?.code, values.downPayment);
        }
        if (field === 'paymentType') {
            setFieldValue('downPayment', (+values.amount * DOWNPAYMENT).toFixed(2));
            setFieldValue('installments', null);
            handleFinalPayment(value.value, values.downPayment);
        }
        if (field === 'downPayment') {
            const installments = values?.installments?.code;
            const downpayment = +value.target.value;
            const paymentType = values?.paymentType?.code;
            handleInstallmentGeneration(installments, downpayment);
            handleFinalPayment(paymentType, downpayment);
        }
    };

    return (
        <>
            {!props?.isPaid && (
                <Card className='card_border font-inter'>
                    <Card.Body>
                        <div className='ps-2 py-2 d-flex justify-content-between border-bottom'>
                            <div className='formTitle m-0'>{'Land Premium'}</div>
                        </div>
                        {PAYMENT_TYPE_DETAILS.map((val: any) => (
                            <GenericFormCard {...val} dropdownOptions={dropdownOptions} additionalSection={false} customHandleChange={onFieldChange} onChangeAction={onFieldChange} />
                        ))}
                    </Card.Body>
                </Card>
            )}
            {values?.installments && props?.installmentData?.length > 0 && !props?.isPaid && (
                <Card className='card_border font-inter'>
                    <Card.Body>
                        <div className='ps-2 py-2 d-flex justify-content-between'>
                            <div className='formTitle m-0'>{'Land Premium Installment Schedule'}</div>
                        </div>
                        <GenericActionTable
                            columns={installmentColumns}
                            rowData={props?.installmentData}
                            hideSearchBar={true}
                            itemsPerPage={10}
                            highlightRow={true}
                            hideFooter={true}
                        ></GenericActionTable>
                    </Card.Body>
                </Card>
            )}
            <Card className='card_border'>
                <Card.Body>
                    <div className='ps-2 py-2 d-flex justify-content-between'>
                        <div className='formTitle m-0'>{'Payment Breakdown'}</div>
                    </div>
                    <GenericActionTable
                        columns={paymentColumns}
                        rowData={props?.finalPaymentData}
                        hideSearchBar={true}
                        itemsPerPage={10}
                        highlightRow={true}
                        totalRow={'totalDueAmount'}
                        hideFooter={true}
                        totalRowText={props?.isPaid ? 'Total Paid Amount (in INR)' : 'Total Payable Amount (in INR)'}
                        // totalTooltip={deductTooltip}
                        totalData={totalPaidAmount}
                    ></GenericActionTable>
                    {!props?.isPaid && (
                        <div className='font-inter'>
                            <div
                                className={`ps-2 d-flex gap-2 border my-3 rounded ${
                                    getDaysDifference(props?.dueDate) >= 30 ? 'success' : getDaysDifference(props?.dueDate) >= 7 ? 'warning' : 'danger'
                                }`}
                                style={{ fontSize: '16px', padding: '12px' }}
                            >
                                <div className='fw-bold'>Due Date: </div>
                                <span className='fw-medium'>
                                    {timestampToDate(new Date(props?.dueDate))} ({getDaysDifference(props?.dueDate)} days left)
                                </span>
                            </div>
                            <div className='payment-options'>
                                <div className='ps-2 py-2 d-flex justify-content-between'>
                                    <div className='formTitle m-0'>{'Payment Options'}</div>
                                </div>
                                <div className='d-flex gap-3 flex-row'>
                                    {props?.gatewayData.map((ele: any) => (
                                        <>
                                            <div
                                                className={`flex-grow-0 payment-option ${props?.selectedGateway === ele.code ? 'active' : ''}`}
                                                onClick={() => {
                                                    pay(ele);
                                                }}
                                            >
                                                <img alt={ele.value} src={payOptions[ele.code].img} style={{ maxHeight: payOptions[ele.code].height }}></img>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {props?.isPaid && (
                        <div className='payment-done font-inter'>
                            <div className='ps-2 py-2 d-flex justify-content-between'>
                                <div className='formTitle m-0'>{'Payment Done'}</div>
                            </div>
                            <div className='row ps-3'>
                                <div className='details ps-2 col-12'>
                                    <div className='detail'>
                                        <div className='head'>Transaction ID</div>
                                        <div className='content'>{props?.transactionDetails?.txnId}</div>
                                    </div>
                                    <div className='detail'>
                                        <div className='head'>Status</div>
                                        <div className='content pill pill-success'>{props?.transactionDetails?.txnStatus === 'SUCCESS' ? 'Paid' : 'Failed'}</div>
                                    </div>
                                    <div className='detail'>
                                        <div className='head'>Payment Date</div>
                                        <div className='content'>{timestampToDate(props?.transactionDetails?.auditDetails?.createdTime)}</div>
                                    </div>
                                    <div className='detail'>
                                        <div className='head'>Receipt</div>
                                        <div className='content link d-flex gap-1 align-items-center' onClick={() => dloadReceipt(props.transactionDetails?.additionalDetails?.paymentReceiptDocRefId)}>
                                            <Image src={fileDown} height={20} />
                                            {t('DOWNLOAD', 'Download')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}
